import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { ObjectID } from "bson";
import { buildSetter, buildSetterWithoutParams } from "..";
import { useRealmApp } from "../../store/RealmApp";
import { sharedSpaceKeys } from "../Services/SharedSpace/spaces";
import { useAccessToken } from "../useAccessToken";

export type NotificationType =
  | "task"
  | "journey"
  | "shared space invite"
  | "thread"
  | "thread message"
  | "shared space timeline stage"
  | "vessel"
  | "instance invite"
  | "waiting room object"
  | "feedback"
  | "phaseForecast"
  | "templateDurations"
  | "integration"
  | "email log"

export type NotificationAction =
  | "CREATE"
  | "UPDATE"
  | "SYNC"
  | "STARTED"
  | "ENDED"
  | "completed"
  | "started"
  | "CRM_SYNC_COMPLETE";

export type NotificationSubAction =
  | NotificationTaskSubAction
  | NotificationJourneySubAction
  | NotificationSharedSpaceInviteSubAction
  | NotificationSharedSpaceTimeineSubAction
  | NotificationFeedbackSubAction
  | NotificationEmailLogSubAction;

type NotificationEmailLogSubAction = "bounced" | "delivery delayed";

type NotificationFeedbackSubAction = "status change";

type NotificationTaskSubAction =
  | "create"
  | "due date change"
  | "tasker change"
  | "approval added"
  | "approval required"
  | "blocker marked"
  | "blocker resolved"
  | "overdue"
  | "in progress"
  | "comment added";
type NotificationJourneySubAction = "create" | "completion";
type NotificationSharedSpaceInviteSubAction = "create" | "accepted";
type NotificationSharedSpaceTimeineSubAction = "start" | "completion";

export type NotificationData = {
  _id: string;
  objectId: string;
  type: NotificationType;
  action: NotificationAction;
  subAction: NotificationSubAction;
  isRead: boolean;
  message: string;
  createdAt: Date;
  updatedAt: Date;
  metadata: {
    phaseId?: string;
    threadId?: string;
    isVisible: boolean;
    dueDate?: Date;
    title?: string;
    taskTitle?: string;
    taskerName?: string;
    feedbackName?: string;
    threadName?: string;
    journeyName?: string;
    taskCount?: number;
    name?: string;
    toEmail?: string;
    instanceName?: string;
    userName?: string;
    approvalUserName?: string;
    issueResolverName?: string;
    previousTaskerName?: string;
    newTaskerName?: string;
    commenterName?: string;
    previousDueDate?: string;
    newDueDate?: string;
    createdByName?: string;
    createdBy?: string;
    status?: string;
    resolverName?: string;
    reporterName?: string;
    integrationId?: "hubspot" | "salesforce";
    fromEmail?: string;
    projectName?: string;
  };
};

export const notificationsKeys = {
  unreadNotifications: () => ["notifications", "unread"] as const,
  allNotifications: () => ["notifications", "all"] as const,
  sharedSpaceNotifications: (sharedSpaceId: string) =>
    ["notifications", "sharedSpace", sharedSpaceId] as const,
  sharedSpaceUnreadNotifications: (sharedSpaceId: string) =>
    ["notifications", "sharedSpace", sharedSpaceId, "unread"] as const,
};

type AllNotifications = {
  notifications: NotificationData[];
  currentPageNo: number;
  totalNoOfPages: number;
};

export const useGetAllNotifications = () => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    notificationsKeys.allNotifications(),
    async (): Promise<AllNotifications> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/notificationsForUser`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};

export const useGetUnreadNotifications = () => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    notificationsKeys.unreadNotifications(),
    async (): Promise<NotificationData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/unreadNotificationsForUser`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};

export const useGetNotificationsForSharedSpaceForUser = (
  sharedSpaceId: string
) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    notificationsKeys.sharedSpaceNotifications(sharedSpaceId),
    async (): Promise<AllNotifications> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/notificationsForUser`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            sharedSpaceId,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};

export const useGetUnreadNotificationsForSharedSpaceForUser = (
  sharedSpaceId: string
) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    notificationsKeys.sharedSpaceUnreadNotifications(sharedSpaceId),
    async (): Promise<NotificationData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/unreadNotificationsForUser`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            sharedSpaceId,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};

// export const useGetNotificationsForUser = () => {
//   const app = useRealmApp();
//   const meta = {
//     functionName: "getNotificationsForUser",
//     parameters: { instanceId: app.currentUser.customData.instanceId },
//   };

//   return useQuery<{
//     readNotifications: NotificationData[];
//     unreadNotifications: NotificationData[];
//   }>(notificationsKeys.notifications(), buildFetcher(app, meta), {
//     staleTime: Infinity,
//   });
// };

// export const useGetUnreadNotificationsCount = (): {
//   count: number | undefined;
// } => {
//   const app = useRealmApp();
//   const meta = {
//     functionName: "getUnreadNotificationsCount",
//     parameters: { instanceId: app.currentUser.customData.instanceId },
//   };

//   const { data } = useQuery<number>(
//     notificationsKeys.unreadNotificationsCount(),
//     buildFetcher(app, meta),
//     {
//       staleTime: Infinity,
//     }
//   );

//   return {
//     count: data,
//   };
// };

export const useMarkAllNotificationsAsRead = () => {
  const app = useRealmApp();
  const functionName = "markAllNotificationsAsRead";
  const queryClient = useQueryClient();

  const markAllNotificationsAsReadMutation = useMutation(
    buildSetterWithoutParams(app, functionName),
    {
      onMutate: async () => {
        // const queryKey = notificationsKeys.unreadNotificationsCount();
        // await queryClient.cancelQueries(queryKey);
        // const previousUnreadNotificationsCount =
        //   queryClient.getQueryData(queryKey);
        // queryClient.setQueryData(queryKey, 0);
        // return { previousUnreadNotificationsCount };
      },
      onError: (data, variables, context) => {
        // queryClient.setQueryData(
        //   notificationsKeys.unreadNotificationsCount(),
        //   context?.previousUnreadNotificationsCount
        // );
      },
      onSettled: () => {
        // queryClient.invalidateQueries(
        //   notificationsKeys.unreadNotificationsCount()
        // );
        queryClient.invalidateQueries(notificationsKeys.allNotifications());
      },
    }
  );
  return markAllNotificationsAsReadMutation;
};

export const useMarkNotificationAsRead = () => {
  const app = useRealmApp();
  const functionName = "markNotificationAsRead";
  const fieldName = "update";
  const queryClient = useQueryClient();

  const markNotificationsAsReadMutation = useMutation(
    buildSetter(app, functionName, fieldName),
    {
      onMutate: async ({ update }: { update: { _id: ObjectID } }) => {
        // await queryClient.cancelQueries(["notifications"]);
        // const previousNotifications = queryClient.getQueriesData<
        //   NotificationData[]
        // >(["notifications"]);
        // console.log(previousNotifications);
        // queryClient.setQueriesData(
        //   ["notifications"],
        //   (notifications: NotificationData[] = []) => {
        //     return notifications.map((n) =>
        //       n._id?.toString() === update._id.toString()
        //         ? { ...n, isRead: true }
        //         : n
        //     );
        //   }
        // );
        // return { previousNotifications };

        await queryClient.cancelQueries(notificationsKeys.allNotifications());
        await queryClient.cancelQueries(
          notificationsKeys.unreadNotifications()
        );
        const previousNotifications =
          queryClient.getQueryData<AllNotifications>(
            notificationsKeys.allNotifications()
          );
        const previousUnreadNotifications = queryClient.getQueryData<
          NotificationData[]
        >(notificationsKeys.unreadNotifications());
        queryClient.setQueryData(notificationsKeys.allNotifications(), {
          notifications: previousNotifications?.notifications.map((n) =>
            n._id === update._id.toString() ? { ...n, isRead: true } : n
          ),
          currentPageNo: previousNotifications?.currentPageNo,
          totalNoOfPages: previousNotifications?.totalNoOfPages,
        });
        queryClient.setQueryData(
          notificationsKeys.unreadNotifications(),
          previousUnreadNotifications?.filter(
            (n) => n._id !== update._id.toString()
          )
        );
        return { previousNotifications, previousUnreadNotifications };
      },
      onError: (data, variables, context) => {
        // context?.previousNotifications?.map((key, value) => {
        //   queryClient.setQueryData(key, value);
        // });
        queryClient.setQueryData(
          notificationsKeys.allNotifications(),
          context?.previousNotifications
        );
        queryClient.setQueryData(
          notificationsKeys.unreadNotifications(),
          context?.previousUnreadNotifications
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(notificationsKeys.allNotifications());
        queryClient.invalidateQueries(notificationsKeys.unreadNotifications());
      },
    }
  );
  return markNotificationsAsReadMutation;
};

export const useMarkSharedSpaceNotificationAsRead = (sharedSpaceId: string) => {
  const app = useRealmApp();
  const functionName = "markNotificationAsRead";
  const fieldName = "update";
  const queryClient = useQueryClient();

  const markNotificationsAsReadMutation = useMutation(
    buildSetter(app, functionName, fieldName),
    {
      onMutate: async ({ update }: { update: { _id: ObjectID } }) => {
        // await queryClient.cancelQueries(["notifications"]);
        // const previousNotifications = queryClient.getQueriesData<
        //   NotificationData[]
        // >(["notifications"]);
        // console.log(previousNotifications);
        // queryClient.setQueriesData(
        //   ["notifications"],
        //   (notifications: NotificationData[] = []) => {
        //     return notifications.map((n) =>
        //       n._id?.toString() === update._id.toString()
        //         ? { ...n, isRead: true }
        //         : n
        //     );
        //   }
        // );
        // return { previousNotifications };

        await queryClient.cancelQueries(
          notificationsKeys.sharedSpaceNotifications(sharedSpaceId)
        );
        await queryClient.cancelQueries(
          notificationsKeys.sharedSpaceUnreadNotifications(sharedSpaceId)
        );
        const previousNotifications =
          queryClient.getQueryData<AllNotifications>(
            notificationsKeys.sharedSpaceNotifications(sharedSpaceId)
          );
        const previousUnreadNotifications = queryClient.getQueryData<
          NotificationData[]
        >(notificationsKeys.sharedSpaceUnreadNotifications(sharedSpaceId));
        queryClient.setQueryData(
          notificationsKeys.sharedSpaceNotifications(sharedSpaceId),
          {
            notifications: previousNotifications?.notifications.map((n) =>
              n._id === update._id.toString() ? { ...n, isRead: true } : n
            ),
            currentPageNo: previousNotifications?.currentPageNo,
            totalNoOfPages: previousNotifications?.totalNoOfPages,
          }
        );
        queryClient.setQueryData(
          notificationsKeys.sharedSpaceUnreadNotifications(sharedSpaceId),
          previousUnreadNotifications?.filter(
            (n) => n._id !== update._id.toString()
          )
        );
        return { previousNotifications, previousUnreadNotifications };
      },
      onError: (data, variables, context) => {
        // context?.previousNotifications?.map((key, value) => {
        //   queryClient.setQueryData(key, value);
        // });
        queryClient.setQueryData(
          notificationsKeys.sharedSpaceNotifications(sharedSpaceId),
          context?.previousNotifications
        );
        queryClient.setQueryData(
          notificationsKeys.sharedSpaceUnreadNotifications(sharedSpaceId),
          context?.previousUnreadNotifications
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          notificationsKeys.sharedSpaceNotifications(sharedSpaceId)
        );
        queryClient.invalidateQueries(
          notificationsKeys.sharedSpaceUnreadNotifications(sharedSpaceId)
        );
        queryClient.invalidateQueries(
          sharedSpaceKeys.sharedSpacesForUser(app.currentUser.customData._id)
        );
      },
    }
  );
  return markNotificationsAsReadMutation;
};
