import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useRealmApp } from "src/store/RealmApp";
import { useAccessToken } from "../useAccessToken";

export const externalKeys = {
  externalMembers: () => ["external", "members"] as const,
  customerMembers: (customerId: string) =>
    [...externalKeys.externalMembers(), "customer", customerId] as const,
};

export type ExternalMember = {
  _id?: string;
  name?: string;
  email: string;
  status: "invited" | "member";
};

export const useGetExternalCustomerMembers = (customerId: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    externalKeys.customerMembers(customerId),
    async (): Promise<ExternalMember[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/externalSharedSpaceMembers`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            customerId,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: 1000 * 60 * 2, // 2 minutes
      cacheTime: Infinity,
    }
  );
};
