import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ObjectID } from "bson";
import { buildFetcher, buildSetterWithInstanceIdProvided } from "src/api";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { useRealmApp } from "src/store/RealmApp";

export const settingsKeys = {
  settings: () => ["settings", "all"] as const,
};

// TYPES

export type SettingsData = {
  _id: ObjectID;
  instanceId: ObjectID;
  services: {
    tasks?: boolean;
    sharedSpaces?: boolean;
    feedback?: boolean;
    knowledgeBase?: boolean;
    customerHealth?: boolean;
  };
  view: {
    // tabs: {
    //   dashboard?: boolean;
    //   tasks?: boolean;
    //   journeys?: boolean;
    //   sharedSpaces?: boolean;
    //   feedback?: boolean;
    //   knowledgeBase?: boolean;
    //   customerHealth?: boolean;
    //   customers?: boolean;
    //   threads?: boolean;
    //   forms?: boolean;
    //   support?: boolean;
    // },
    integrations: {
      slack: boolean;
      crm: boolean;
    };
    tasks: {
      new: {
        estimates: boolean;
        dueTime: boolean;
      };
    };
  };
  embed: {
    primaryColor?: string;
    secondaryColor?: string;
    font?: string;
    fontType?: "sans" | "serif" | "mono";
  };
  createdAt: Date;
  updatedAt: Date;
};

export type SettingsRequest = {
  // _id: ObjectID;
  // instanceId: ObjectID;
  services?: {
    tasks?: boolean;
    sharedSpaces?: boolean;
    feedback?: boolean;
    knowledgeBase?: boolean;
    customerHealth?: boolean;
  };
  view?: {
    tabs?: {
      dashboard?: boolean;
      tasks?: boolean;
      journeys?: boolean;
      sharedSpaces?: boolean;
      feedback?: boolean;
      knowledgeBase?: boolean;
      customerHealth?: boolean;
      customers?: boolean;
      threads?: boolean;
      forms?: boolean;
      support?: boolean;
    };
    tasks?: {
      new?: {
        estimates?: boolean;
      };
    };
  };
  embed?: {
    primaryColor?: string;
    secondaryColor?: string;
    font?: string;
    fontType?: "sans" | "serif" | "mono";
  };
};

// TEMPLATES

export const useGetSettings = () => {
  const app = useRealmApp();
  const meta = {
    functionName: "getInstanceSettings",
    parameters: {
      instanceId: app.currentUser.customData.instanceId,
    },
  };

  return useQuery<SettingsData>(
    settingsKeys.settings(),
    buildFetcher(app, meta),
    {
      staleTime: Infinity,
    }
  );
};

export const useGetSettingsForEmbeddedSharedSpace = (
  sharedSpaceId: string
): {
  settings: SettingsData | undefined;
} => {
  const app = useRealmApp();
  const meta = {
    functionName: "getInstanceSettings",
    parameters: {
      sharedSpaceId: new ObjectID(sharedSpaceId),
    },
  };

  const { data } = useQuery<SettingsData>(
    settingsKeys.settings(),
    buildFetcher(app, meta),
    {
      staleTime: Infinity,
    }
  );

  // console.log("Fetching settings");
  // if (data) console.log(data);

  return {
    settings: data,
  };
};

export const useGetInstanceSettingsFromSlug = (slug: string) => {
  const app = useRealmApp();
  const meta = {
    functionName: "getInstanceSettings",
    parameters: {
      slug,
    },
  };

  return useQuery<SettingsData>(
    settingsKeys.settings(),
    buildFetcher(app, meta),
    {
      staleTime: Infinity,
    }
  );
};

export const useUpdateSettings = () => {
  const app = useRealmApp();
  const functionName = "updateInstanceSettings";
  const fieldName = "update";

  const queryClient = useQueryClient();
  const snackbarCtx = useSnackBar();

  const updateSettingsMutation = useMutation(
    buildSetterWithInstanceIdProvided(app, functionName, fieldName),
    {
      onMutate: async ({ update }: { update: SettingsRequest }) => {
        // const queryKey = settingsKeys.settings();
        // await queryClient.cancelQueries(queryKey);
        // const previousSettings = queryClient.getQueryData<SettingsData>(queryKey);
        // const newSettings = previousSettings
        //   ? previousSettings.map((t) =>
        //       t._id?.toString() === update._id?.toString()
        //         ? { ...t, ...update }
        //         : t
        //     )
        //   : [];
        // queryClient.setQueryData(queryKey, newTags);
        // return { previousTags };
      },
      onError: (data, variables, context) => {
        snackbarCtx.showSnackbar("Error updating the settings", "error");
        // if (variables.type)
        //   queryClient.setQueryData(
        //     tagKeys.tags(variables.type),
        //     context?.previousTags
        //   );
      },
      onSettled: (data, error, variables) => {
        if (!variables.update.view)
          snackbarCtx.showSnackbar("New settings saved", "success");
        queryClient.invalidateQueries(settingsKeys.settings());
        // if (variables.type)
        //   queryClient.invalidateQueries(tagKeys.tags(variables.type));
      },
    }
  );

  return updateSettingsMutation;
};
