import { ParentStatus, StatusData } from "src/api/General/status-groups";

export type Logic = "AND" | "OR";

export type IdComparator = "eq" | "in" | "nin";

export type DateComparator = "eq" | "gt" | "gte" | "lt" | "lte";

// Task Status

export type TaskStatusFilter = {
  field: "task-status";
  comparator: IdComparator;
  value: {
    status: ParentStatus;
    customStatus: string;
  }[];
};

export type TaskStatusFilterModel = {
  field: "task-status";
  statuses: StatusData[];
};

// Project

export type ProjectFilter = {
  field: "project";
  comparator: IdComparator;
  value: string[];
};

export type ProjectFilterModel = {
  field: "project";
};

// Project Status

export type ProjectStatusFilter = {
  field: "project-status";
  comparator: IdComparator;
  value: string[];
};

export type ProjectStatusFilterModel = {
  field: "project-status";
  // statuses: StatusData[];
};

// Space

export type SpaceFilter = {
  field: "space";
  comparator: IdComparator;
  value: string[];
};

export type SpaceFilterModel = {
  field: "space";
};

// Company

export type CompanyFilter = {
  field: "company";
  comparator: IdComparator;
  value: string[];
};

export type CompanyFilterModel = {
  field: "company";
};

// Project Owner

export type ProjectOwnerFilter = {
  field: "project-owner";
  comparator: IdComparator;
  value: string[];
};

export type ProjectOwnerFilterModel = {
  field: "project-owner";
  projectOwners: {
    _id: string;
    name: string;
    email: string;
  }[];
};

// Task Due Date

export type TaskDueDateFilter = {
  field: "task-dueDate";
  comparator: DateComparator;
  value: string | null;
};

export type TaskDueDateFilterModel = {
  field: "task-dueDate";
};

// Task Assignee

export type TaskerFilter = {
  field: "tasker";
  comparator: IdComparator;
  value: string[];
};

export type TaskerFilterModel = {
  field: "tasker";
  taskers: {
    name: string;
    _id: string;
  }[];
};

// Project Template

export type ProjectTemplateFilter = {
  field: "project-template";
  comparator: IdComparator;
  value: string[];
};

export type ProjectTemplateFilterModel = {
  field: "project-template";
  projectTemplates: {
    _id: string;
    name: string;
  }[];
};

// Inactive To Do Tasks

export type InactiveToDoTasksFilter = {
  field: "inactive-todo-tasks";
  comparator: "eq";
  value: boolean;
};

// local storage

export function getMyTasksFilterKey() {
  return `filter_my_tasks`;
}

export function getSpaceTasksFilterKey(spaceId: string) {
  return `filter_space_tasks_${spaceId}`;
}

export function getSpaceProjectsFilterKey(spaceId: string) {
  return `filter_space_projects_${spaceId}`;
}

export function getSpaceProjectsColumnsToDisplayKey(spaceId: string) {
  return `columns_space_projects_${spaceId}`;
}
