import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  SpaceData,
  useDeleteSpace,
  useGetSpace,
  useUpdateSpace,
} from "src/api/Spaces/spaces";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import { Loading } from "src/components/ui/loading";
import { TextInput } from "src/components/ui/text-input";
import { SpaceNameAndSymbol } from "src/pages/spaces/spaces";

type Props = {};

export default function SpaceSettings({}: Props) {
  const { spaceId } = useParams();
  const { data: space } = useGetSpace(String(spaceId));
  const [deletingOpen, setDeletingOpen] = useState(false);
  const deleteSpaceMutation = useDeleteSpace();
  const [deleteConfirmationText, setDeleteConfirmationText] = useState("");
  useEffect(() => {
    if (deleteSpaceMutation.isSuccess) {
      setDeletingOpen(false);
    }
  }, [deleteSpaceMutation.isSuccess]);

  return (
    <div>
      <div className="flex h-12 items-center border-b border-gray-200/70 px-8">
        {space && <SpaceNameAndSymbol name={space.name} color={space.color} />}
      </div>
      <div className="mx-auto max-w-2xl pt-20">
        <div>
          <div className="border-b border-gray-200/70 pb-6 text-xl font-medium">
            General
          </div>
          {!!space ? (
            <div className="space-y-12 pt-8">
              <div className="space-y-2.5">
                <div className="space-y-0.5">
                  <div className="font-medium text-gray-800">Details</div>
                  <div className="text-sm font-light text-gray-600">
                    Name, identifier and color of the space
                  </div>
                </div>
                <div className="space-y-4 rounded-md bg-gray-50 p-6 pt-5 shadow-sm">
                  <div className="space-y-0.5">
                    <InputHeading heading="Name" />
                    <SpaceName space={space} />
                  </div>
                </div>
              </div>
              <div className="space-y-2.5">
                <div className="space-y-0.5">
                  <div className="font-medium text-gray-800">Delete</div>
                  {/* <div className="text-sm font-light text-gray-600">
                  Name, identifier and color of the space
                </div> */}
                </div>
                <div className="rounded-md bg-gray-50 p-6 shadow-sm">
                  <div className="flex items-center justify-between gap-6">
                    <div className="max-w-md text-sm font-light text-gray-800">
                      Deleting a space is a permanent action. All projects and
                      tasks in the space will be permanently deleted.
                    </div>
                    <div>
                      <Dialog
                        open={deletingOpen}
                        onOpenChange={(open) => {
                          setDeletingOpen(open);
                        }}
                      >
                        <DialogTrigger asChild>
                          <Button
                            size={"sm"}
                            variant={"destructive"}
                            onClick={() => {}}
                            className="text-white"
                          >
                            Delete
                          </Button>
                        </DialogTrigger>
                        <DialogContent>
                          <DialogHeader>
                            <DialogTitle>Delete Space</DialogTitle>
                          </DialogHeader>
                          <div className="space-y-6">
                            <div className="space-y-4">
                              <div className="text-sm">
                                Deleting a space is a permanent action. All
                                projects and tasks in the space will be
                                permanently deleted. Type{" "}
                                <span className="font-medium">
                                  "Delete {space.name}"
                                </span>{" "}
                                to confirm.
                              </div>
                              <TextInput
                                value={deleteConfirmationText}
                                onChange={(e) => {
                                  setDeleteConfirmationText(e.target.value);
                                }}
                              />
                            </div>
                            <div className="flex justify-end space-x-2">
                              <Button
                                variant={"secondary"}
                                onClick={() => {
                                  setDeletingOpen(false);
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant={"destructive"}
                                disabled={
                                  deleteConfirmationText !==
                                    `Delete ${space.name}` ||
                                  deleteSpaceMutation.isLoading
                                }
                                onClick={() => {
                                  if (
                                    deleteConfirmationText ===
                                    `Delete ${space.name}`
                                  ) {
                                    deleteSpaceMutation.mutate({
                                      _id: space._id,
                                    });
                                  }
                                }}
                              >
                                {deleteSpaceMutation.isLoading
                                  ? "Deleting.."
                                  : "Delete"}
                              </Button>
                            </div>
                          </div>
                        </DialogContent>
                      </Dialog>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Loading className="mt-20" />
          )}
        </div>
      </div>
    </div>
  );
}

function SpaceName({ space }: { space: SpaceData }) {
  const [name, setName] = useState(space.name);
  const updateSpaceMutation = useUpdateSpace();
  return (
    <TextInput
      className="w-72"
      value={name}
      onChange={(e) => {
        setName(e.target.value);
      }}
      onBlur={() => {
        if (name.trim() !== space.name.trim()) {
          updateSpaceMutation.mutate({
            _id: space._id,
            name: name,
          });
        }
      }}
    />
  );
}
