import Link from "@tiptap/extension-link";
import Typography from "@tiptap/extension-typography";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import clsx from "clsx";
import dayjs from "dayjs";
import { HTMLAttributes, forwardRef, useEffect, useRef, useState } from "react";
import { ThreadMessageData } from "src/api/Services/Threads/threads";
import { useRealmApp } from "src/store/RealmApp";
import { getTimeDifference } from "src/utils/functions/dateTime";

export { ChatMessages };

type Props = { messages: ThreadMessageData[] } & HTMLAttributes<HTMLDivElement>;

const ChatMessages = forwardRef<HTMLDivElement, Props>(
  ({ children, className, messages, ...props }, ref) => {
    const messagesDiv = useRef<HTMLDivElement>(null);
    const [firstUnreadMessageId, setFirstUnreadMessageId] = useState<
      string | undefined
    >(undefined);

    useEffect(() => {
      setTimeout(() => {
        if (messagesDiv.current) {
          messagesDiv.current.scrollTop = messagesDiv.current.scrollHeight;
        }
      }, 100); // Adjust delay as needed
    }, [messages]);
    const app = useRealmApp();

    useEffect(() => {
      const unreadMessage = messages?.find((m) => {
        return !!m.readReceipts?.filter(
          (r) =>
            r.userId?.toString() ===
              app.currentUser.customData._id.toString() && !r.read
        )?.length;
      });
      if (unreadMessage) {
        setFirstUnreadMessageId(unreadMessage._id.toString());
      }
    }, []);

    return (
      <div ref={messagesDiv} className={className}>
        {messages?.map((message, idx) => (
          <Message
            key={message._id.toString()}
            message={message}
            previousMessage={idx > 0 ? messages[idx - 1] : undefined}
            isFirstUnread={firstUnreadMessageId === message._id.toString()}
          />
        ))}
      </div>
    );
  }
);

function Message({
  message,
  previousMessage,
  isFirstUnread,
}: {
  message: ThreadMessageData;
  previousMessage?: ThreadMessageData;
  isFirstUnread?: boolean;
}) {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Typography,
      Link.configure({
        openOnClick: true,
      }),
    ],
    editorProps: {
      attributes: {
        class:
          "prose outline-none ring-0 focus:outline-none focus:ring-0 border-none focus:border-none",
      },
    },
    autofocus: true,
    content: message.text,
    editable: false,
  });
  const { absDifferenceInMs } = getTimeDifference(
    message.createdAt,
    previousMessage?.createdAt ?? new Date()
  );
  return (
    <>
      {(!!previousMessage?.createdAt && absDifferenceInMs > 1000 * 60 * 5) ||
      !previousMessage ||
      previousMessage.user._id.toString() !== message.user._id.toString() ? (
        <div>
          {isFirstUnread && (
            <div className="flex w-full items-center justify-center">
              <div className="mr-3 flex-1 border-t border-red-600" />
              <div className="mx-auto text-sm text-red-600">New Messages</div>
              <div className="ml-3 flex-1 border-t border-red-600" />
            </div>
          )}
          {/* {isMessageUnread && (<div>this message is not read</div>)} */}

          <div className={clsx("flex space-x-3", !!previousMessage && "mt-2")}>
            <div className="shrink-0">
              <div
                className={clsx(
                  "flex h-8 w-8 items-center justify-center rounded-md border shadow-sm",
                  message.user.type === "external"
                    ? "border-gray-300 bg-gray-200"
                    : "bg-white"
                )}
              >
                <div
                  className={clsx(
                    message.user.type === "external"
                      ? "text-gray-600"
                      : "text-gray-400"
                  )}
                >
                  {message.user.name
                    .toUpperCase()
                    .split(" ")
                    .map((w) => w[0])
                    .join("")}
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center space-x-2">
                <div className="text-sm font-medium leading-4 text-gray-400">
                  {message.user.name}
                </div>
                <div className="pt-[1px]">
                  <div className="text-xs leading-4 text-gray-400">
                    {dayjs(message.createdAt).format("h:mm A, MMM DD")}
                  </div>
                </div>
              </div>
              <div className="">
                <EditorContent editor={editor} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="group flex items-center space-x-3">
            <div className="w-8 shrink-0">
              <span
                className={clsx(
                  "text-xs text-white",
                  "group-hover:text-gray-400"
                )}
              >
                {dayjs(message.createdAt).format("h:mm")}
              </span>
            </div>
            <div className="">
              <EditorContent editor={editor} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
