import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Loading } from "src/components/ui/loading";
import { useRealmApp } from "src/store/RealmApp";
import * as Realm from "realm-web";
export default function LoginExternalUserUsingJWT() {
  // get search params
  const [searchParams] = useSearchParams();
  const jwt = searchParams.get("jwt");
  const token = searchParams.get("token");
  const tokenId = searchParams.get("tokenId");

  const app = useRealmApp();
  const navigate = useNavigate();

  async function loginExternalUser(jwt: string) {
    const credentials = Realm.Credentials.jwt(jwt);
    await app.emailPasswordAuth.confirmUser({
      token: token,
      tokenId: tokenId,
    });
    const user = await app.logIn(credentials);
    navigate(`/setup-external-user?token=${token}&tokenId=${tokenId}`);
  }

  useEffect(() => {
    if (jwt) {
      loginExternalUser(jwt);
    }
  }, []);

  return (
    <div>
      <Loading className="mt-[20vh]" />
    </div>
  );
}
