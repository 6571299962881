import { Button } from "src/components/ui/button";
import {
  NewTemplateContextProvider,
  useNewTemplate,
} from "./NewTemplateContextProvider";
import { NewTemplateModal } from "./NewTemplateModal";

export const NewTemplateButton = () => {
  return (
    <NewTemplateContextProvider>
      <>
        <NewJourneyTemplateButton />
        <NewTemplateModal />
      </>
    </NewTemplateContextProvider>
  );
};

const NewJourneyTemplateButton = () => {
  const newTemplateCtx = useNewTemplate();
  return (
    <Button
      variant={"secondary"}
      onClick={() => {
        newTemplateCtx.dispatch({ type: "modal_open" });
      }}
    >
      New Template
    </Button>
  );
};
