import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { ObjectID, ObjectId } from "bson";
import { useLocation, useNavigate } from "react-router-dom";
// import { useAccessToken } from "src/api/useAccessToken";
import { ParentStatus } from "src/api/General/status-groups";
import { useAccessToken } from "src/api/useAccessToken";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { Sort } from "src/pages/Services/Tasks/Tasks";
import { buildSetter } from "../..";
import { useRealmApp } from "../../../store/RealmApp";
import {
  MeetingVendor,
  relativeDueDateUnits,
} from "../Journeys/journeyTemplates";
import { journeyKeys } from "../Journeys/journeys";
import { KnowledgeResourceData } from "../KnowledgeBase/knowledgeBase";
import { FileData } from "../SharedSpace/files";
import { sharedSpaceKeys } from "../SharedSpace/spaces";
// import { useAccessToken } from "src/api/useAccessToken";
import { toast } from "sonner";
import {
  CompanyFilter,
  InactiveToDoTasksFilter,
  Logic,
  ProjectFilter,
  SpaceFilter,
  TaskStatusFilter,
  TaskerFilter,
} from "src/utils/filters";

export const taskerKeys = {
  sharedSpace: (sharedSpaceId: ObjectId) =>
    ["taskers", "sharedSpace", sharedSpaceId.toString()] as const,
  instance: (instanceId: ObjectId) =>
    ["taskers", "instance", instanceId.toString()] as const,
};

export const taskKeys = {
  taskGroups: (
    group: TaskGroup,
    sort: Sort,
    baselineFilter: NewTasksFilter,
    filter: NewTasksFilter
  ) => ["task-groups", group, "sort", sort, baselineFilter, filter] as const,
  tasksForGroup: (
    sort: Sort,
    baselineFilter: NewTasksFilter,
    filter: NewTasksFilter
  ) => ["tasks", "sort", sort, baselineFilter, filter] as const,
  subtasks: (parentTaskId: string) =>
    ["tasks", parentTaskId, "subtasks"] as const,
  journeyTasks: (journeyId: string) => ["tasks", "journey", journeyId] as const,
  journeyStageTasks: (journeyId: string, stageId: string) =>
    ["tasks", "journey", journeyId, "stages", stageId, "all"] as const,
  phaseTasks: (phaseId: string) => ["tasks", "phase", phaseId] as const,
  // these are "task" because "tasks" are iterated over in other optimistic updates
  task: (taskId: string) => ["task", taskId, "withType"] as const,
  logs: (taskId: string) => ["task", taskId, "logs"] as const,
};

export const relativeDueDateUnitArr = [
  "minute",
  "hour",
  "day",
  "week",
  "month",
  "quarter",
] as const;

export type TaskerType = "internal" | "external";

export type Status =
  | "To-do"
  | "In Progress"
  | "Stuck"
  | "Completed"
  | "Canceled"
  | "Backlog";

export interface TaskRequest {
  tasker?: TaskTasker;
  spaceId?: string;
  action: Action;
  _id?: string;
  title?: string;
  description?: string;
  parentTaskId?: string;
  startDate?: string;
  dueDate?: string;
  taskerId?: string;
  isVisible?: boolean;
  fileIds?: string[];
  relativeDueDate?: {
    unit: (typeof relativeDueDateUnitArr)[number];
    value: number;
  };
  journeyStageId?: string;
  dependentOn?: {
    _id: string;
    type: "task" | "stage";
  };
  // status?: Status;
  taskerType?: TaskerType;
  isMarkedForToday?: boolean;
  taskerEmail?: string;
  estimate?: {
    value: number;
    unit: (typeof relativeDueDateUnits)[number];
  };
  overrideDependency?: boolean;
  completionDate?: string;
  resolverUserId?: string;
  assignedCompletionDate?: string;
  customStatus?: string;
}

export interface OldTaskRequest {
  _id?: ObjectId;
  title?: string;
  description?: string;
  sharedSpaceId?: ObjectId;
  journeyId?: ObjectId;
  instanceId?: ObjectId;
  parentTaskId?: ObjectId;
  startDate?: undefined | Date;
  dueDate?: undefined | Date;
  customerId?: ObjectId;
  taskerId?: ObjectId;
  phaseId?: ObjectId;
  hasDueTime?: boolean;
  hasStartDate?: boolean;
  hasIssue?: boolean;
  isVisible?: boolean;
  fileIds?: ObjectId[];
  knowledgeResourceTemplateIds?: ObjectId[];
  relativeDueDate?: {
    unit: (typeof relativeDueDateUnitArr)[number];
    value: number;
  };
  teamId?: ObjectId;
  journeyStageId?: ObjectId;
  dependentOn?: {
    _id: ObjectId;
    type: "task" | "stage";
  };
  approvalId?: ObjectId;
  issue?: {
    resolverId: ObjectId;
    reason: string;
  };
  status?: Status;
  taskerType?: TaskerType;
  isMarkedForToday?: boolean;
  taskerEmail?: string;
  dueDateChangeReason?: string;
  estimate?: {
    value: number;
    unit: (typeof relativeDueDateUnits)[number];
  };
  overrideDependency?: boolean;
  completionDate?: Date;
  blocker?: {
    resolverUserId: ObjectId;
  };
}

type Action = "default" | "form" | "checklist" | "meeting" | "fileUpload";

export interface TaskData {
  _id?: string;
  action: Action;
  title: string;
  description: string;
  status: ParentStatus;
  statusGroup: {
    _id: string;
  };
  customStatus: string;
  taskerType: TaskerType;
  tasker: TaskTasker;
  estimate: {
    value: number;
    unit: (typeof relativeDueDateUnits)[number];
  };
  templateId?: string;
  phase?: { _id: string; name: string };
  instanceId?: string;
  journey?: {
    _id: string;
    name: string;
  };
  journeyStage?: {
    _id: string;
  };
  // tags
  customer?: {
    _id: string;
    name: string;
  };
  sharedSpaceId?: string;
  isVisible?: boolean;
  dueDate?: undefined | string;
  comments?: Comment[];
  knowledgeResources?: KnowledgeResourceData[];
  knowledgeResourceIds?: string[];
  parentTask?: {
    _id: string;
    title: string;
    status: Status;
    isMarkedForToday: boolean;
  };
  form?: {
    _id: string;
  };
  // tasker?: UserResponseObj;
  // tasker: {
  //   _id?: ObjectId;
  //   name?: string;
  //   email?: string;
  //   persona?: string;
  // };
  approval?: {
    _id: string;
    name: string;
    email: string;
    resolved?: boolean;
    completionDate?: string;
  };
  blocker?: {
    resolverUser: {
      _id: string;
      name: string;
    };
    reporterUser: {
      _id: string;
      name: string;
    };
    createdAt: string;
    resolvedAt?: string;
    resolutionNote?: string;
    description: string;
    resolved?: boolean;
  };
  files?: FileData[];
  createdAt?: string;
  updatedAt?: string;
  startedDate?: string;
  completionDate?: string;
  assignedCompletionDate?: string;

  // type?: string;
  startDate?: undefined | string;
  hasStartDate?: boolean;
  hasIssue?: boolean;
  hasDueTime?: boolean;
  dependentOn?: {
    name: string;
    type: "stage" | "task";
  };
  isMarkedForToday?: boolean;
  integrationId?: "hubspot" | "pipedrive" | "salesforce";
  relativeDueDate?: {
    unit: (typeof relativeDueDateUnitArr)[number];
    value: number;
  };
  dueDateChangeReasons?: {
    reason: string;
    changedAt: string;
    changedTo: string;
    changedBy: { name: string };
  }[];

  subTaskCount?: number;
  completedSubTaskCount?: number;
  readOnlyFiles?: {
    _id: string;
    name: string;
    url?: string;
    label: string;
    type: string;
    description: string;
  }[];
  meeting?: {
    vendor: MeetingVendor;
    link: string;
  };
  threadId?: string;
  // subtasks: {
  //   _id: string;
  //   title: string;
  //   status: Status;
  //   dueDate?: string;
  //   relativeDueDate?: {
  //     unit: (typeof relativeDueDateUnitArr)[number];
  //     value: number;
  //   };
  //   tasker: TaskTasker;
  //   subtaskCount: number;
  // }[];
  durations?: {
    forecasted: number;
    // planned: number;
  };
  // approval?: {
  //   _id: ObjectId;
  //   resolved?: boolean;
  //   completionDate?: Date;
  // };
  // issue?: {
  //   _id: ObjectId;
  //   resolved?: boolean;
  //   completionDate?: Date;
  // };
  // knowledgeResourceIds?: ObjectId[];
  // knowledgeResourceTemplateIds?: ObjectId[];
}

export interface ReducedTaskData {
  action: "default" | "form" | "checklist" | "meeting" | "fileUpload";
  _id?: string;
  templateId?: string;
  title: string;
  description: string;
  instanceId?: string;
  journey?: {
    _id: string;
    name: string;
  };
  journeyStage?: {
    _id: string;
  };
  status: ParentStatus;
  customer?: {
    _id: string;
    name: string;
  };
  blocker?: {
    resolverUser: {
      _id: string;
      name: string;
    };
    reporterUser: {
      _id: string;
      name: string;
    };
    createdAt: string;
    resolvedAt?: string;
    resolutionNote?: string;
    description: string;
    resolved?: boolean;
  };
  phase?: {
    _id: string;
    name: string;
  };
  sharedSpaceId?: string;
  isVisible?: boolean;
  taskerType: TaskerType;
  dueDate?: undefined | string;
  tasker: TaskTasker;

  createdAt?: string;
  updatedAt?: string;
  startedDate?: string;
  completionDate?: string;
  assignedCompletionDate?: string;
  startDate?: undefined | string;
  hasStartDate?: boolean;

  hasIssue?: boolean;
  hasDueTime?: boolean;
  // type?: string;

  isMarkedForToday?: boolean;
  integrationId?: "hubspot" | "pipedrive" | "salesforce";
  relativeDueDate?: {
    unit: (typeof relativeDueDateUnitArr)[number];
    value: number;
  };
  estimate?: {
    value: number;
    unit: (typeof relativeDueDateUnits)[number];
  };
  subTaskCount?: number;
  completedSubTaskCount?: number;
  parentTask?: {
    _id: string;
    title: string;
    status: Status;
    isMarkedForToday: boolean;
  };
  subtasks?: {
    _id: string;
    title: string;
    status: Status;
    dueDate?: string;
    relativeDueDate?: {
      unit: (typeof relativeDueDateUnitArr)[number];
      value: number;
    };
    tasker: TaskTasker;
    subtaskCount: number;
  }[];
  threadId?: string;
  statusGroup: { _id: string };
  customStatus: string;
  // approval?: {
  //   _id: ObjectId;
  //   resolved?: boolean;
  //   completionDate?: Date;
  // };
  // issue?: {
  //   _id: ObjectId;
  //   resolved?: boolean;
  //   completionDate?: Date;
  // };
  // knowledgeResourceIds?: ObjectId[];
  // knowledgeResourceTemplateIds?: ObjectId[];
}

export type TaskerData =
  | {
      userType: "fullUser";
      type: "internal" | "external";
      _id: string;
      name: string;
      email: string;
      role: "owner" | "member" | "subscriber";
    }
  // | {
  //     type: "external";
  //     userType: "fullUser";
  //     _id: ObjectId;
  //     name: string;
  //     email: string;
  //   }
  | {
      userType: "invitedUser";
      type: "external";
      email: string;
      role: "owner" | "member" | "subscriber";
    };

type ProjectInternalMember = {
  _id: string;
  name: string;
  email: string;
  role: "owner" | "member" | "subscriber";
};

type ProjectExternalMember =
  | {
      userType: "fullUser";
      _id: string;
      name: string;
      email: string;
      role: "owner" | "member" | "subscriber";
    }
  | {
      userType: "invitedUser";
      email: string;
      role: "owner" | "member" | "subscriber";
      invite: {
        _id: string;
      };
    };

type TaskTasker =
  | {
      userType: "fullUser";
      _id: string;
      name: string;
      email: string;
      assignedPersona?: string;
    }
  | {
      userType: "invitedUser";
      email: string;
      assignedPersona?: string;
    }
  | {
      userType: "persona";
      persona: string;
    };

export type NewProjectTaskRequest = {
  title: string;
  description: string;
  journeyId: string;
  journeyStageId?: string;
  dueDate?: Date;
  taskerId?: string;
  taskerEmail?: string;
  taskerType: TaskerType;
  estimate: {
    value: number;
    unit: (typeof relativeDueDateUnitArr)[number];
  };
  dependentOn?: {
    _id: string;
    type: "task" | "stage";
  };
  relativeDueDate?: {
    unit: (typeof relativeDueDateUnitArr)[number];
    value: number;
  };
  isVisible: boolean;
  status: ParentStatus;
  customStatus: string;
};

type TaskLogType = "task due date changed" | "task created";

export type TaskLogData =
  | {
      _id: string;
      createdAt: string;
    } & (
      | {
          type: "task created";
          metadata: {
            createdBy?: {
              name: string;
            };
            journey?: {
              _id: string;
              name: string;
            };
          };
        }
      | {
          type: "task status changed";
          metadata: {
            oldStatus: Status;
            newStatus: Status;
            tasker: { name: string; email: string };
          };
        }
      | {
          type: "task due date changed";
          metadata: { oldDueDate: string; newDueDate: string };
        }
      | {
          type: "task tasker changed";
          metadata: {
            oldTasker: { name?: string; email?: string };
            newTasker: { name: string; email?: string };
          };
        }
      | {
          type: "task title changed";
          metadata: { oldTitle: string; newTitle: string };
        }
      | {
          type: "task description changed";
          metadata: { oldDescription: string; newDescription: string };
        }
      | {
          type: "task blocker reported";
          metadata: {
            blocker: {
              reporterUser: { name: string };
              description: string;
            };
          };
        }
      | {
          type: "task blocker resolved";
          metadata: {
            blocker: {
              resolverUser: { name: string };
              resolutionNote: string;
            };
          };
        }
      | {
          type: "task activated";
        }
    );

export const useGetTaskLogs = (taskId: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    taskKeys.logs(taskId),
    async (): Promise<TaskLogData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/taskLogs`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            taskId,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: 1000 * 60 * 60 * 1,
      cacheTime: Infinity,
    }
  );
};

export const useAddTaskToProject = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();
  const location = useLocation();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async ({
      params,
      metadata,
    }: {
      params: { task: NewProjectTaskRequest };
      metadata: {
        phaseId: string;
        statusGroupId: string;
        journeyId?: string;
        journeyStageId?: string;
        tasker: TaskerData;
      };
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/addTaskToJourney`,
        params,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onMutate: async (variables) => {
      const projectTaskKey = taskKeys.phaseTasks(variables.metadata.phaseId);
      await queryClient.cancelQueries(projectTaskKey);
      const previousTasks =
        queryClient.getQueryData<TaskData[]>(projectTaskKey) ?? [];
      const newTasks: TaskData[] = [
        ...previousTasks,
        {
          action: "default",
          customStatus: variables.params.task.customStatus,
          status: variables.params.task.status,
          description: variables.params.task.description,
          title: variables.params.task.title,
          taskerType: variables.params.task.taskerType,
          estimate: {
            value: variables.params.task.estimate.value,
            unit: variables.params.task.estimate.unit,
          },
          statusGroup: {
            _id: variables.metadata.statusGroupId,
          },
          tasker:
            variables.metadata.tasker?.userType === "fullUser"
              ? {
                  userType: "fullUser",
                  _id: variables.metadata.tasker._id,
                  name: variables.metadata.tasker.name,
                  email: variables.metadata.tasker.email,
                }
              : {
                  userType: "invitedUser",
                  email: variables.metadata.tasker.email,
                },
          ...(variables.params.task.journeyStageId
            ? {
                journeyStage: {
                  _id: variables.params.task.journeyStageId,
                },
              }
            : {}),
          dueDate: variables.params.task.dueDate?.toISOString(),
          // tasker: {
          //   userType: variables.metadata.tasker?.userType ?? "fullUser",
          //   email: variables.metadata.tasker?.email,
          //   _id: variables.params.task.taskerId,
          // }
        },
      ];
      queryClient.setQueryData(projectTaskKey, newTasks);
      return { previousTasks };
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error adding task to project", "error");
    },
    onSuccess: (data, variables) => {
      toast("Task created", {
        action: {
          label: "View Task",
          onClick: () => {
            const searchParams = new URLSearchParams(location.search);
            searchParams.set("taskId", data.id);
            navigate({
              pathname: location.pathname,
              search: searchParams.toString(),
            });
          },
        },
      });
      if (variables.params.task.journeyId) {
        queryClient.invalidateQueries(
          journeyKeys.journey(new ObjectID(variables.params.task.journeyId))
        );
        queryClient.invalidateQueries(
          journeyKeys.stages(new ObjectID(variables.params.task.journeyId))
        );
      }
      if (variables.metadata?.phaseId) {
        queryClient.invalidateQueries(
          taskKeys.phaseTasks(variables.metadata.phaseId.toString())
        );
      }
      if (variables.metadata?.journeyStageId) {
        queryClient.invalidateQueries(
          taskKeys.journeyStageTasks(
            variables.params.task.journeyId,
            variables.metadata.journeyStageId
          )
        );
      }
      queryClient.invalidateQueries(["task-groups"]);
      queryClient.invalidateQueries(["tasks"]);
    },
  });
};

// export const useMarkBlockerForTask = () => {
//   const app = useRealmApp();
//   const snackbarCtx = useSnackBar();
//   const queryClient = useQueryClient();
//   const navigate = useNavigate();

//   return useMutation({
//     mutationFn: async ({
//       blocker,
//       metadata,
//     }: {
//       blocker: {
//         _id: string;
//         description: string;
//       };
//       metadata?: Partial<OldTaskRequest>;
//     }): Promise<{ message: string; status: number }> => {
//       const res = await axios.post(
//         `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/markBlockerForTask`,
//         blocker,
//         {
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${app.currentUser?.accessToken}`,
//           },
//         }
//       );
//       return res.data;
//     },
//     onError: () => {
//       snackbarCtx.showSnackbar("Error marking task as blocked", "error");
//     },
//     onSuccess: (data, variables) => {
//       if (variables.blocker._id) {
//         queryClient.invalidateQueries([
//           "tasks",
//           variables.blocker._id,
//           "withType",
//         ]);
//       }
//       if (variables.metadata?.journeyId) {
//         queryClient.invalidateQueries(
//           journeyKeys.journey(new ObjectID(variables.metadata.journeyId))
//         );
//         queryClient.invalidateQueries(
//           journeyKeys.stages(new ObjectID(variables.metadata.journeyId))
//         );
//       }
//       if (variables.metadata?.phaseId) {
//         queryClient.invalidateQueries(
//           phaseKeys.tasks(variables.metadata.phaseId.toString())
//         );
//       }
//     },
//   });
// };

// export const useResolveBlockerForTask = () => {
//   const app = useRealmApp();
//   const snackbarCtx = useSnackBar();
//   const queryClient = useQueryClient();
//   const navigate = useNavigate();

//   return useMutation({
//     mutationFn: async ({
//       blocker,
//       metadata,
//     }: {
//       blocker: {
//         _id: string;
//         resolutionNote: string;
//       };
//       metadata?: Partial<OldTaskRequest>;
//     }): Promise<{ message: string; status: number }> => {
//       const res = await axios.post(
//         `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/resolveBlockerForTask`,
//         blocker,
//         {
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${app.currentUser?.accessToken}`,
//           },
//         }
//       );
//       return res.data;
//     },
//     onError: () => {
//       snackbarCtx.showSnackbar("Error resolving blocked task", "error");
//     },
//     onSuccess: (data, variables) => {
//       if (variables.blocker._id) {
//         queryClient.invalidateQueries([
//           "tasks",
//           variables.blocker._id,
//           "withType",
//         ]);
//         // navigate(`/projects/${data.phaseId}`);
//       }
//       if (variables.metadata?.journeyId) {
//         queryClient.invalidateQueries(
//           journeyKeys.journey(new ObjectID(variables.metadata.journeyId))
//         );
//         queryClient.invalidateQueries(
//           journeyKeys.stages(new ObjectID(variables.metadata.journeyId))
//         );
//       }
//       if (variables.metadata?.phaseId) {
//         queryClient.invalidateQueries(
//           phaseKeys.tasks(variables.metadata.phaseId.toString())
//         );
//       }
//       // snackbarCtx.showSnackbar("Successfully duplicated journey template");
//     },
//   });
// };

export type InstanceMemberData = {
  _id: string;
  name: string;
  email: string;
};

export const useGetTaskersForInstance = () => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    taskerKeys.instance(app.currentUser?.customData?.instanceId.$oid),
    async (): Promise<InstanceMemberData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/instanceMembers`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            instanceId: app.currentUser?.customData?.instanceId.$oid,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: 1000 * 60 * 3 * 1,
      cacheTime: Infinity,
    }
  );
};

// export const useGetTaskersForInstance = (instanceId: ObjectId) => {
//   const app = useRealmApp();
//   const meta = {
//     functionName: "getTaskers",
//     parameters: {
//       type: "instance",
//       instanceId,
//     },
//   };

//   return useQuery<TaskerData[]>(
//     taskerKeys.instance(instanceId),
//     buildFetcher(app, meta)
//   );
// };

// export const useGetTaskersForSharedSpace = (sharedSpaceId: ObjectId) => {
//   const app = useRealmApp();
//   const meta = {
//     functionName: "getTaskers",
//     parameters: {
//       type: "sharedSpace",
//       sharedSpaceId,
//     },
//   };

//   return useQuery<TaskerData[]>(
//     taskerKeys.sharedSpace(sharedSpaceId),
//     buildFetcher(app, meta)
//   );
// };

// GETTERS

type GetTaskWithUserTypeHookReturn = {
  taskWithUserType: TaskWithUserType | undefined;
};

export type TaskWithUserType = {
  task: TaskData | undefined;
  type: "internal" | "external";
};

// export const useGetTaskWithUserType = (
//   _id: ObjectID | undefined
// ): GetTaskWithUserTypeHookReturn => {
//   const app = useRealmApp();
//   const meta = {
//     functionName: "getTaskWithUserType",
//     parameters: { _id },
//   };

//   const { data } = useQuery<TaskWithUserType>(
//     ["tasks", _id?.toString(), "withType"],
//     buildFetcher(app, meta)
//   );

//   return {
//     taskWithUserType: data,
//   };
// };

// export const useGetTask = (
//   _id: ObjectID | undefined
// ): {
//   task: TaskData | undefined;
//   isTaskError: boolean;
//   isTaskLoading: boolean;
//   taskError: any;
// } => {
//   const app = useRealmApp();
//   const meta = {
//     functionName: "getTask",
//     parameters: { _id },
//   };

//   const { isLoading, isError, error, data } = useQuery<TaskData>(
//     ["tasks", _id],
//     buildFetcher(app, meta)
//   );

//   return {
//     task: data,
//     isTaskError: isError,
//     isTaskLoading: isLoading,
//     taskError: error,
//   };
// };

// Get tasks // getTasksForInstance

type GetTasksFetchResponse = {
  tasks: TaskData[] | undefined;
  isLoading: boolean;
  isError: boolean;
  error: any;
};

export type PaginatedTasksData = {
  tasks: ReducedTaskData[];
  totalNumberOfPages: number;
  currentPageNo: number;
};

export type PaginatedAllTasksData = {
  tasks: ReducedTaskData[];
  toDoNoOfPages: number;
  inProgressNoOfPages: number;
  completedNoOfPages: number;
  canceledNoOfPages: number;
  backlogNoOfPages: number;
  stuckNoOfPages: number;
};

export type TasksFilter = {
  taskerType?: TaskerType;
  taskerIds?: Array<string>; // "none" also allowed
  statuses?: {
    status: ParentStatus;
    customStatus?: string;
  }[];
  phaseIds?: string[]; // "none" also allowed
  spaceIds?: string[]; // "none" also allowed
  // fromDueDate?: string;
  // toDueDate?: string;
  // fromCreatedAt?: string;
  // toCreatedAt?: string;
};

export type NewTasksFilter = {
  logic: Logic;
  children: NewTaskFilter[];
};

export type NewTaskFilter =
  | SpaceFilter
  | CompanyFilter
  | TaskerFilter
  | TaskStatusFilter
  | ProjectFilter
  | InactiveToDoTasksFilter;

export const useGetSubtasksForTask = (parentTaskId: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    taskKeys.subtasks(parentTaskId),
    async (): Promise<TaskData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/subtasks`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            parentTaskId,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: 1000 * 60 * 2,
    }
  );
};

export const useGetTaskWithUserType = (_id: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    taskKeys.task(_id),
    async (): Promise<{
      task: TaskData;
      type: TaskerType;
    }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/taskWithUserType`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            _id,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: 1000 * 60 * 2,
    }
  );
};

export type GroupDetails =
  | {
      group: "project";
      project: {
        _id: string | null;
        name?: string;
      };
    }
  | {
      group: "status";
      customStatus: string;
      status: ParentStatus;
    }
  | {
      group: "tasker";
      tasker: {
        _id: string | null;
        name: string | null;
        email: string | null;
      };
    };

export type TaskGroup = "project" | "status" | "tasker";

export type TaskGroupData = {
  groupDetails: GroupDetails;
  tasks: ReducedTaskData[];
};

export const useGetTasks = ({
  group,
  sort,
  baselineFilter,
  filter,
}: {
  group: TaskGroup;
  sort: Sort;
  baselineFilter: NewTasksFilter;
  filter: NewTasksFilter;
}) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    taskKeys.taskGroups(group, sort, baselineFilter, filter),
    async (): Promise<{
      groups: Array<TaskGroupData>;
      totalNoOfTasks: number;
    }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/tasks`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            instanceId: app.currentUser.customData.instanceId.$oid,
            newFilter: JSON.stringify(filter),
            group: group,
            sort: JSON.stringify(sort),
            ...(!!baselineFilter
              ? {
                  baselineFilter: JSON.stringify(baselineFilter),
                }
              : {}),
          },
        }
      );
      return res.data;
    },
    {
      staleTime: 1000 * 60 * 2,
    }
  );
};

export const useGetTasksForGroup = ({
  sort,
  baselineFilter,
  filter,
  initialData,
}: {
  sort: Sort;
  baselineFilter: NewTasksFilter;
  filter: NewTasksFilter;
  initialData?: ReducedTaskData[];
}) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    taskKeys.tasksForGroup(sort, baselineFilter, filter),
    async (): Promise<{
      tasks: ReducedTaskData[];
      totalNoOfTasks: number;
    }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/tasks`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            instanceId: app.currentUser.customData.instanceId.$oid,
            newFilter: JSON.stringify(filter),
            ...(!!baselineFilter
              ? {
                  baselineFilter: JSON.stringify(baselineFilter),
                }
              : {}),
            sort: JSON.stringify(sort),
          },
        }
      );
      return res.data;
    },
    {
      staleTime: 1000 * 60 * 2,
      cacheTime: Infinity,
      initialData: {
        tasks: initialData ?? [],
        totalNoOfTasks: initialData?.length ?? 0,
      },
      initialDataUpdatedAt: new Date().getTime() - 1000 * 60 * 3,
    }
  );
};

export const useGetPhaseTasks = (phaseId: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    taskKeys.phaseTasks(phaseId),
    async (): Promise<TaskData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/tasksForPhase`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            phaseId,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};

export const useGetJourneyStageTasks = (journeyId: string, stageId: string) => {
  const getValidAccessToken = useAccessToken();
  return useQuery(
    taskKeys.journeyStageTasks(journeyId, stageId),
    async (): Promise<TaskData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/tasksForJourneyStage`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            _id: stageId,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};

// export const useGetTasksForUserForInstance = () => {
//   const app = useRealmApp();

//   const meta = {
//     functionName: "getTasksForUserForInstance",
//     parameters: { instanceId: app.currentUser.customData.instanceId },
//   };

//   return useQuery<PaginatedTasksData>(
//     ["tasks", "user", app.currentUser.id, "all"],
//     buildFetcher(app, meta),
//     {
//       staleTime: 1000 * 60 * 2,
//     }
//   );
// };

// export const useGetTasksForApprovalForUser = (): {
//   approvalTasks: TaskData[] | undefined;
// } => {
//   const app = useRealmApp();
//   const meta = {
//     functionName: "getTasksForApprovalForUser",
//     parameters: {},
//   };

//   const { isSuccess, isLoading, isError, error, data } = useQuery<TaskData[]>(
//     ["tasks", "approvals", "all"],
//     buildFetcher(app, meta)
//   );

//   let tempData;

//   if (isSuccess && data) {
//     tempData = [...data];
//     tempData = sortTasks(tempData);
//   }

//   return {
//     approvalTasks: tempData,
//   };
// };

// SETTERS

export const useCreateTask = () => {
  const app = useRealmApp();
  const queryClient = useQueryClient();
  const snackbarCtx = useSnackBar();
  const getValidAccessToken = useAccessToken();
  const location = useLocation();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async (params: {
      task: TaskRequest;
      subTasks: {
        title: string;
        estimate: TaskRequest["estimate"];
      }[];
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/createTaskWithSubTasks`,
        {
          task: {
            ...params.task,
            instanceId: app.currentUser?.customData.instanceId.$oid,
          },
          subTasks: params.subTasks,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onMutate: async ({ task, subTasks }) => {
      // await queryClient.cancelQueries(["tasks", "all"]);
      // const previousTasks = queryClient.getQueryData(["tasks", "all"]);
      // const tempTask = { _id: undefined, ...task };
      // queryClient.setQueryData(["tasks", "all"], (tasks: TaskData[] = []) => [
      //   tempTask,
      //   ...tasks,
      // ]);
      // let previousSharedSpaceTasks;
      // if (task.sharedSpaceId) {
      //   const queryKey = sharedSpaceKeys.tasks(task.sharedSpaceId);
      //   await queryClient.cancelQueries(queryKey);
      //   previousSharedSpaceTasks = queryClient.getQueryData(queryKey);
      //   const tempTask = { _id: undefined, ...task };
      //   queryClient.setQueryData(queryKey, (tasks: TaskData[] = []) => [
      //     tempTask,
      //     ...tasks,
      //   ]);
      // }
      // return { previousTasks, previousSharedSpaceTasks };
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error creating task", "error");
    },
    onSuccess: (data, variables) => {
      toast("Task created", {
        action: {
          label: "View Task",
          onClick: () => {
            const searchParams = new URLSearchParams(location.search);
            searchParams.set("taskId", data.id);
            navigate({
              pathname: location.pathname,
              search: searchParams.toString(),
            });
          },
        },
      });

      queryClient.invalidateQueries(["tasks"]);
      queryClient.invalidateQueries(["task-groups"]);

      // queryClient.invalidateQueries(["tasks", "all"]);
      // if (variables.task.sharedSpaceId) {
      //   queryClient.invalidateQueries(
      //     sharedSpaceKeys.tasks(new ObjectID(variables.task.sharedSpaceId))
      //   );
      // }
    },
  });

  // const addTaskMutation = useMutation(
  //   buildSetterWithInstanceIdProvided(app, functionName, fieldName),
  //   {
  //     onMutate: async ({ params }: { params: TaskRequest }) => {
  //       // console.log(task);
  //       // await queryClient.cancelQueries(["tasks", "all"]);
  //       // const previousTasks = queryClient.getQueryData(["tasks", "all"]);
  //       // const tempTask = { _id: undefined, ...task };
  //       // queryClient.setQueryData(
  //       //   ["tasks", "all"],
  //       //   (tasks: TaskData[] = []) => [tempTask, ...tasks]
  //       // );
  //       // let previousSharedSpaceTasks;
  //       // if (task.sharedSpaceId) {
  //       //   const queryKey = sharedSpaceKeys.tasks(task.sharedSpaceId);
  //       //   await queryClient.cancelQueries(queryKey);
  //       //   previousSharedSpaceTasks = queryClient.getQueryData(queryKey);
  //       //   const tempTask = { _id: undefined, ...task };
  //       //   queryClient.setQueryData(
  //       //     queryKey,
  //       //     (tasks: TaskData[] = []) => [tempTask, ...tasks]
  //       //   );
  //       // }
  //       // return { previousTasks, previousSharedSpaceTasks };
  //     },
  //     onError: (data, variables, context) => {
  //       // queryClient.setQueryData(["tasks", "all"], context?.previousTasks);
  //       // if (variables.sharedSpaceId) {
  //       //   queryClient.setQueryData(
  //       //     sharedSpaceKeys.tasks(variables.sharedSpaceId),
  //       //     context?.previousSharedSpaceTasks
  //       //   );
  //       // }
  //     },
  //     onSettled: (data, error, variables) => {
  //       queryClient.invalidateQueries(["tasks"]);
  //       // queryClient.invalidateQueries(["tasks", "all"]);
  //       if (variables.params.sharedSpaceId) {
  //         queryClient.invalidateQueries(
  //           sharedSpaceKeys.tasks(variables.params.sharedSpaceId)
  //         );
  //       }
  //     },
  //   }
  // );
  // return addTaskMutation;
};

export const useDeleteTask = () => {
  const app = useRealmApp();
  const functionName = "deleteTask";
  const fieldName = "update";
  const queryClient = useQueryClient();
  const snackbarCtx = useSnackBar();
  const navigate = useNavigate();

  return useMutation(buildSetter(app, functionName, fieldName), {
    onMutate: async ({
      update,
      otherDetails,
    }: {
      update: { _id: ObjectID };
      otherDetails?: {
        journeyId?: ObjectID;
        stageId?: ObjectID;
        sharedSpaceId?: ObjectID;
      };
    }) => {
      // const queryKey = ["tasks", "all"];
      // await queryClient.cancelQueries(queryKey);
      // const previousTasks = queryClient.getQueryData(queryKey);
      // queryClient.setQueryData(queryKey, (tasks: TaskData[] = []) =>
      //   tasks.filter((t) => t._id !== update._id)
      // );
      // return { previousTasks };
    },
    onError: (data, variables, context) => {
      // queryClient.setQueryData(["tasks", "all"], context?.previousTasks);
      snackbarCtx.showSnackbar("Failed to delete task", "error");
    },
    onSuccess: (data: { status: number; message: string }, variables) => {
      // navigate(-1);
      queryClient.removeQueries([
        "tasks",
        variables.update._id?.toString(),
        "withType",
      ]);
      queryClient.invalidateQueries(["tasks"]);
      if (
        variables.otherDetails?.journeyId &&
        variables.otherDetails?.stageId
      ) {
        queryClient.invalidateQueries(
          taskKeys.journeyStageTasks(
            variables.otherDetails.journeyId.toString(),
            variables.otherDetails.stageId.toString()
          )
        );
      }
      if (variables.otherDetails?.sharedSpaceId) {
        queryClient.invalidateQueries(
          sharedSpaceKeys.tasks(variables.otherDetails.sharedSpaceId)
        );
      }
    },
  });
};

// export const useUpdateTaskStatus = () => {
//   const app = useRealmApp();
//   const snackbarCtx = useSnackBar();
//   type UpdateType = {
//     _id: ObjectID;
//     status: TaskData["status"];
//   };

//   const queryClient = useQueryClient();
//   const updateTaskMutation = useMutation(
//     buildSetter(app, "updateTask", "update"),
//     {
//       onMutate: async ({
//         update,
//         metadata,
//       }: {
//         update: UpdateType;
//         fromStatus: TaskData["status"];
//         metadata?: {
//           journeyId?: ObjectID;
//           phaseId?: ObjectID;
//           journeyStageId?: ObjectID;
//           sharedSpaceId?: ObjectID;
//         };
//         filter: TasksFilter;
//       }) => {
//         // first get the previous data

//         // await queryClient.cancelQueries([
//         //   "tasks",
//         //   "user",
//         //   app.currentUser.id,
//         //   "all",
//         // ]);
//         // await queryClient.cancelQueries(["tasks", "all"]);
//         // await queryClient.cancelQueries(
//         //   journeyKeys.tasks(
//         //     new ObjectID(metadata?.journeyId),
//         //     new ObjectID(metadata?.journeyStageId)
//         //   )
//         // );
//         // await queryClient.cancelQueries([
//         //   "tasks",
//         //   update._id?.toString(),
//         //   "withType",
//         // ]);
//         // await queryClient.cancelQueries(
//         //   phaseKeys.tasks(String(metadata?.phaseId?.toString()))
//         // );
//         // // my tasks
//         // const previousMyTasks = queryClient.getQueryData<PaginatedTasksData>([
//         //   "tasks",
//         //   "user",
//         //   app.currentUser.id,
//         //   "all",
//         // ]);
//         // console.log(previousMyTasks);
//         // const newMyTasks = !!previousMyTasks?.tasks
//         //   ? previousMyTasks.tasks.map((t) =>
//         //       t._id?.toString() === update._id?.toString()
//         //         ? { ...t, ...update }
//         //         : t
//         //     )
//         //   : [];
//         // console.log(newMyTasks);
//         // queryClient.setQueryData(["tasks", "user", app.currentUser.id, "all"], {
//         //   ...previousMyTasks,
//         //   tasks: newMyTasks,
//         // });
//         // const previousJourneyStageTasks = queryClient.getQueryData<TaskData[]>(
//         //   journeyKeys.tasks(
//         //     new ObjectID(metadata?.journeyId),
//         //     new ObjectID(metadata?.journeyStageId)
//         //   )
//         // );
//         // const newJourneyStageTasks = !!previousJourneyStageTasks
//         //   ? previousJourneyStageTasks.map((t) =>
//         //       t._id?.toString() == update._id?.toString()
//         //         ? { ...t, ...update }
//         //         : t
//         //     )
//         //   : [];
//         // queryClient.setQueryData(
//         //   journeyKeys.tasks(
//         //     new ObjectID(metadata?.journeyId),
//         //     new ObjectID(metadata?.journeyStageId)
//         //   ),
//         //   newJourneyStageTasks
//         // );
//         // const previousTasks = queryClient.getQueryData<PaginatedTasksData>([
//         //   "tasks",
//         //   "all",
//         // ]);
//         // const newTasks = !!previousTasks?.tasks
//         //   ? previousTasks.tasks.map((t) =>
//         //       t._id?.toString() == update._id?.toString()
//         //         ? { ...t, ...update }
//         //         : t
//         //     )
//         //   : [];
//         // queryClient.setQueryData(["tasks", "all"], {
//         //   ...previousTasks,
//         //   tasks: newTasks,
//         // });
//         // const previousTask = queryClient.getQueryData<TaskWithUserType>([
//         //   "tasks",
//         //   update._id?.toString(),
//         //   "withType",
//         // ]);
//         // const newTask = !!previousTask
//         //   ? {
//         //       ...previousTask,
//         //       task: {
//         //         ...previousTask.task,
//         //         ...update,
//         //       },
//         //     }
//         //   : {};
//         // queryClient.setQueryData(
//         //   ["tasks", update._id?.toString(), "withType"],
//         //   newTask
//         // );
//         // const previousPhaseTasks = queryClient.getQueryData<TaskData[]>(
//         //   phaseKeys.tasks(String(metadata?.phaseId?.toString()))
//         // );
//         // const newPhaseTasks = !!previousPhaseTasks
//         //   ? previousPhaseTasks.map((t) =>
//         //       t._id?.toString() == update._id?.toString()
//         //         ? { ...t, ...update }
//         //         : t
//         //     )
//         //   : [];
//         // queryClient.setQueryData(
//         //   phaseKeys.tasks(String(metadata?.phaseId?.toString())),
//         //   newPhaseTasks
//         // );
//         // return {
//         //   previousTasks,
//         //   previousJourneyStageTasks,
//         //   previousTask,
//         //   previousPhaseTasks,
//         //   previousMyTasks,
//         // };
//       },
//       // If the mutation fails, use the context returned from onMutate to roll back
//       onError: (err, update, context) => {
//         // queryClient.setQueryData(["tasks", "all"], context?.previousTasks);
//         // queryClient.setQueryData(
//         //   journeyKeys.tasks(
//         //     new ObjectID(update.metadata?.journeyId),
//         //     new ObjectID(update.metadata?.journeyStageId)
//         //   ),
//         //   context?.previousJourneyStageTasks
//         // );
//         // if (update.update._id) {
//         //   queryClient.setQueryData(
//         //     ["tasks", update.update._id?.toString(), "withType"],
//         //     context?.previousTask
//         //   );
//         // }
//         // queryClient.setQueryData(
//         //   phaseKeys.tasks(String(update.metadata?.phaseId?.toString())),
//         //   context?.previousPhaseTasks
//         // );
//         // queryClient.setQueryData(
//         //   ["tasks", "user", app.currentUser.id, "all"],
//         //   context?.previousMyTasks
//         // );
//         snackbarCtx.showSnackbar("Error updating task", "error");
//       },
//       // Always refetch after error or success:
//       onSuccess: (data, variables) => {
//         queryClient.invalidateQueries(["tasks"]);
//         // if (variables.update._id) {
//         //   queryClient.invalidateQueries(
//         //     taskKeys.logs(variables.update._id.toString())
//         //   );
//         // }
//         // // queryClient.invalidateQueries(["tasks", "approvals", "all"]);
//         // // queryClient.refetchQueries(["tasks", variables._id]);
//         // // queryClient.refetchQueries(["tasks", variables._id?.toString(), "withType"]);

//         // // console.log(variables.metadata);

//         // if (variables.metadata?.sharedSpaceId) {
//         //   queryClient.invalidateQueries(
//         //     sharedSpaceKeys.tasks(variables.metadata.sharedSpaceId)
//         //   );
//         //   queryClient.invalidateQueries(
//         //     sharedSpaceKeys.taskBreakdown(variables.metadata.sharedSpaceId)
//         //   );
//         // }
//         // if (variables.metadata?.journeyId) {
//         //   queryClient.invalidateQueries(
//         //     journeyKeys.journey(new ObjectID(variables.metadata.journeyId))
//         //   );
//         //   queryClient.invalidateQueries(
//         //     journeyKeys.stages(new ObjectID(variables.metadata.journeyId))
//         //   );
//         //   queryClient.invalidateQueries(
//         //     journeyKeys.keyDates(new ObjectID(variables.metadata.journeyId))
//         //   );
//         //   queryClient.invalidateQueries(
//         //     journeyKeys.tasksProgress(
//         //       new ObjectID(variables.metadata.journeyId)
//         //     )
//         //   );
//         // }
//         // if (variables.metadata?.phaseId) {
//         //   queryClient.invalidateQueries(
//         //     phaseKeys.tasks(variables.metadata.phaseId.toString())
//         //   );
//         // }
//       },
//     }
//   );
//   return updateTaskMutation;
// };

export const useUpdateTaskFromProjectView = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async ({
      update,
      metadata,
    }: {
      update: { _id: string } & Partial<TaskRequest>;
      metadata: {
        phaseId: string;
        journeyId?: string;
      };
    }): Promise<{ message: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/updateTask`,
        {
          ...update,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      // console.log(res);
      return res.data;
    },
    onMutate: async (variables) => {
      await queryClient.cancelQueries(["tasks"]);
      const previousPhaseTasks = queryClient.getQueryData<TaskData[]>(
        taskKeys.phaseTasks(variables.metadata.phaseId)
      );
      const newPhaseTasks = previousPhaseTasks?.map((t) =>
        t._id === variables.update._id ? { ...t, ...variables.update } : t
      );
      queryClient.setQueryData(
        taskKeys.phaseTasks(variables.metadata.phaseId),
        newPhaseTasks
      );
      return { previousPhaseTasks };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(
        taskKeys.phaseTasks(variables.metadata.phaseId),
        context?.previousPhaseTasks
      );
      snackbarCtx.showSnackbar("Error updating task", "error");
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["tasks"]);
      queryClient.invalidateQueries(taskKeys.task(variables.update._id));
      queryClient.invalidateQueries(taskKeys.logs(variables.update._id));
      queryClient.invalidateQueries(["task-groups"]);
      queryClient.invalidateQueries(
        taskKeys.phaseTasks(variables.metadata.phaseId)
      );
      if (variables.metadata?.journeyId) {
        queryClient.invalidateQueries(
          journeyKeys.journey(new ObjectID(variables.metadata.journeyId))
        );
      }
    },
  });
};

export const useUpdateTask = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async ({
      update,
      metadata,
    }: {
      update: { _id: string } & Partial<TaskRequest>;
      metadata?: {
        journeyId?: string;
        phaseId?: string;
        journeyStageId?: string;
        sharedSpaceId?: string;
        oldStatus?: string;
        task?: ReducedTaskData;
        newParentStatus?: ParentStatus;
      };
    }): Promise<{ message: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/updateTask`,
        {
          ...update,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      // console.log(res);
      return res.data;
    },
    onMutate: async (variables) => {
      // optimistic update for phase tasks
      // await queryClient.cancelQueries(taskKeys.phaseTasks(variables.metadata?.phaseId));
      // get all groups
      // const taskGroups = queryClient.getQueriesData(["tasks"]);
      // for (const group of taskGroups) {
      //   const key = group[0];
      //   const tasks = group[1] as ReducedTaskData[];
      //   const sort = key[2] as Sort;
      //   const filter = key[3] as TasksFilter;
      //   // updating status
      //   if (variables.update.customStatus) {
      //     // remove from group if there is a status filter of the old status and doesn't contain the new status
      //     if (filter.statuses === variables.metadata?.oldStatus) {
      //       queryClient.setQueryData(
      //         key,
      //         tasks.filter((t) => t._id !== variables.update._id)
      //       );
      //       console.log(
      //         "after",
      //         tasks.filter((t) => t._id !== variables.update._id)
      //       );
      //     }
      //     // add to group if there is a status filter of the new status and doesn't contain the old status
      //     if (filter.customStatus === variables.update.customStatus) {
      //       // add the task if it is the new status
      //       switch (sort.property) {
      //         case "dueDate":
      //           queryClient.setQueryData(
      //             key,
      //             [
      //               ...tasks,
      //               {
      //                 ...variables.metadata?.task,
      //                 ...variables.update,
      //               } as ReducedTaskData,
      //             ].sort((a, b) => {
      //               if (!a.dueDate || !b.dueDate) {
      //                 return 0;
      //               } else {
      //                 return sort.order === "asc"
      //                   ? new Date(a.dueDate).getTime() -
      //                       new Date(b.dueDate).getTime()
      //                   : new Date(b.dueDate).getTime() -
      //                       new Date(a.dueDate).getTime();
      //               }
      //             })
      //           );
      //           break;
      //       }
      //       // change the task status if it is in the group and there is no status filter
      //     }
      //   }
      // }
      // find which groups the task needs to be updated (update, add, remove) in
      // if group by project then only update in project
      // if group by status then remove in old status and add in new status
      // to check if new status should include the task or not, need to see if the sort and filter of that key match
      // if they do then add the task and re-sort
      // if group by tasker then remove in old tasker and add in new tasker
      // do the update in each group if need be
    },
    // onMutate: async (variables) => {
    //   await queryClient.cancelQueries(["tasks"]);
    //   const previousTasks = queryClient.getQueriesData(["tasks"]);
    //   const previousTask = queryClient.getQueryData<TaskWithUserType>(
    //     taskKeys.task(variables.update._id)
    //   );
    //   queryClient.setQueriesData(["tasks"], (tasks: TaskData[] = []) => {
    //     return tasks.map((t) =>
    //       t._id?.toString() === variables.update._id
    //         ? ({ ...t, ...variables.update } as TaskData)
    //         : t
    //     );
    //   });
    //   if (previousTask) {
    //     queryClient.setQueryData(taskKeys.task(variables.update._id), {
    //       ...previousTask,
    //       task: {
    //         ...previousTask?.task,
    //         ...variables.update,
    //       },
    //     });
    //   }
    //   return { previousTasks, previousTask };
    // },
    onError: (err, variables, context) => {
      // context?.previousTasks.map((key, value) => {
      //   queryClient.setQueryData(key, value);
      // });
      // queryClient.setQueryData(
      //   taskKeys.task(variables.update._id),
      //   context?.previousTask
      // );
      snackbarCtx.showSnackbar("Error updating task", "error");
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["tasks"]);
      queryClient.invalidateQueries(taskKeys.task(variables.update._id));
      queryClient.invalidateQueries(taskKeys.logs(variables.update._id));
      queryClient.invalidateQueries(["task-groups"]);
      // if (variables.metadata?.journeyId) {
      //   queryClient.invalidateQueries(
      //     journeyKeys.stages(new ObjectID(variables.metadata.journeyId))
      //   );
      // }
    },
  });

  // const app = useRealmApp();
  // const snackbarCtx = useSnackBar();
  // type UpdateType = Partial<OldTaskRequest>;

  // const queryClient = useQueryClient();
  // const updateTaskMutation = useMutation(
  //   buildSetter(app, "updateTask", "update"),
  //   {
  //     onMutate: async ({
  //       update,
  //       metadata,
  //     }: {
  //       update: UpdateType;
  //       metadata?: Partial<OldTaskRequest>;
  //     }) => {
  //       // first get the previous data
  //       // await queryClient.cancelQueries([
  //       //   "tasks",
  //       //   "user",
  //       //   app.currentUser.id,
  //       //   "all",
  //       // ]);
  //       // await queryClient.cancelQueries(["tasks", "all"]);
  //       // await queryClient.cancelQueries(
  //       //   journeyKeys.tasks(
  //       //     new ObjectID(metadata?.journeyId),
  //       //     new ObjectID(metadata?.journeyStageId)
  //       //   )
  //       // );
  //       // await queryClient.cancelQueries([
  //       //   "tasks",
  //       //   update._id?.toString(),
  //       //   "withType",
  //       // ]);
  //       // await queryClient.cancelQueries(
  //       //   phaseKeys.tasks(String(metadata?.phaseId?.toString()))
  //       // );
  //       // // my tasks
  //       // const previousMyTasks = queryClient.getQueryData<PaginatedTasksData>([
  //       //   "tasks",
  //       //   "user",
  //       //   app.currentUser.id,
  //       //   "all",
  //       // ]);
  //       // console.log(previousMyTasks);
  //       // const newMyTasks = !!previousMyTasks?.tasks
  //       //   ? previousMyTasks.tasks.map((t) =>
  //       //       t._id?.toString() === update._id?.toString()
  //       //         ? { ...t, ...update }
  //       //         : t
  //       //     )
  //       //   : [];
  //       // console.log(newMyTasks);
  //       // queryClient.setQueryData(["tasks", "user", app.currentUser.id, "all"], {
  //       //   ...previousMyTasks,
  //       //   tasks: newMyTasks,
  //       // });
  //       // const previousJourneyStageTasks = queryClient.getQueryData<TaskData[]>(
  //       //   journeyKeys.tasks(
  //       //     new ObjectID(metadata?.journeyId),
  //       //     new ObjectID(metadata?.journeyStageId)
  //       //   )
  //       // );
  //       // const newJourneyStageTasks = !!previousJourneyStageTasks
  //       //   ? previousJourneyStageTasks.map((t) =>
  //       //       t._id?.toString() == update._id?.toString()
  //       //         ? { ...t, ...update }
  //       //         : t
  //       //     )
  //       //   : [];
  //       // queryClient.setQueryData(
  //       //   journeyKeys.tasks(
  //       //     new ObjectID(metadata?.journeyId),
  //       //     new ObjectID(metadata?.journeyStageId)
  //       //   ),
  //       //   newJourneyStageTasks
  //       // );
  //       // const previousTasks = queryClient.getQueryData<PaginatedTasksData>([
  //       //   "tasks",
  //       //   "all",
  //       // ]);
  //       // const newTasks = !!previousTasks?.tasks
  //       //   ? previousTasks.tasks.map((t) =>
  //       //       t._id?.toString() == update._id?.toString()
  //       //         ? { ...t, ...update }
  //       //         : t
  //       //     )
  //       //   : [];
  //       // queryClient.setQueryData(["tasks", "all"], {
  //       //   ...previousTasks,
  //       //   tasks: newTasks,
  //       // });
  //       // const previousTask = queryClient.getQueryData<TaskWithUserType>([
  //       //   "tasks",
  //       //   update._id?.toString(),
  //       //   "withType",
  //       // ]);
  //       // const newTask = !!previousTask
  //       //   ? {
  //       //       ...previousTask,
  //       //       task: {
  //       //         ...previousTask.task,
  //       //         ...update,
  //       //       },
  //       //     }
  //       //   : {};
  //       // queryClient.setQueryData(
  //       //   ["tasks", update._id?.toString(), "withType"],
  //       //   newTask
  //       // );
  //       // const previousPhaseTasks = queryClient.getQueryData<TaskData[]>(
  //       //   phaseKeys.tasks(String(metadata?.phaseId?.toString()))
  //       // );
  //       // const newPhaseTasks = !!previousPhaseTasks
  //       //   ? previousPhaseTasks.map((t) =>
  //       //       t._id?.toString() == update._id?.toString()
  //       //         ? { ...t, ...update }
  //       //         : t
  //       //     )
  //       //   : [];
  //       // queryClient.setQueryData(
  //       //   phaseKeys.tasks(String(metadata?.phaseId?.toString())),
  //       //   newPhaseTasks
  //       // );
  //       // return {
  //       //   previousTasks,
  //       //   previousJourneyStageTasks,
  //       //   previousTask,
  //       //   previousPhaseTasks,
  //       //   previousMyTasks,
  //       // };
  //     },
  //     // If the mutation fails, use the context returned from onMutate to roll back
  //     onError: (err, update, context) => {
  //       // queryClient.setQueryData(["tasks", "all"], context?.previousTasks);
  //       // queryClient.setQueryData(
  //       //   journeyKeys.tasks(
  //       //     new ObjectID(update.metadata?.journeyId),
  //       //     new ObjectID(update.metadata?.journeyStageId)
  //       //   ),
  //       //   context?.previousJourneyStageTasks
  //       // );
  //       // if (update.update._id) {
  //       //   queryClient.setQueryData(
  //       //     ["tasks", update.update._id?.toString(), "withType"],
  //       //     context?.previousTask
  //       //   );
  //       // }
  //       // queryClient.setQueryData(
  //       //   phaseKeys.tasks(String(update.metadata?.phaseId?.toString())),
  //       //   context?.previousPhaseTasks
  //       // );
  //       // queryClient.setQueryData(
  //       //   ["tasks", "user", app.currentUser.id, "all"],
  //       //   context?.previousMyTasks
  //       // );
  //       snackbarCtx.showSnackbar("Error updating task", "error");
  //     },
  //     // Always refetch after error or success:
  //     onSuccess: (data, variables) => {
  //       queryClient.invalidateQueries(["tasks"]);
  //       // if (variables.update._id) {
  //       //   queryClient.invalidateQueries(
  //       //     taskKeys.logs(variables.update._id.toString())
  //       //   );
  //       // }
  //       // // queryClient.invalidateQueries(["tasks", "approvals", "all"]);
  //       // // queryClient.refetchQueries(["tasks", variables._id]);
  //       // // queryClient.refetchQueries(["tasks", variables._id?.toString(), "withType"]);

  //       // // console.log(variables.metadata);

  //       // if (variables.metadata?.sharedSpaceId) {
  //       //   queryClient.invalidateQueries(
  //       //     sharedSpaceKeys.tasks(variables.metadata.sharedSpaceId)
  //       //   );
  //       //   queryClient.invalidateQueries(
  //       //     sharedSpaceKeys.taskBreakdown(variables.metadata.sharedSpaceId)
  //       //   );
  //       // }
  //       // if (variables.metadata?.journeyId) {
  //       //   queryClient.invalidateQueries(
  //       //     journeyKeys.journey(new ObjectID(variables.metadata.journeyId))
  //       //   );
  //       //   queryClient.invalidateQueries(
  //       //     journeyKeys.stages(new ObjectID(variables.metadata.journeyId))
  //       //   );
  //       //   queryClient.invalidateQueries(
  //       //     journeyKeys.keyDates(new ObjectID(variables.metadata.journeyId))
  //       //   );
  //       //   queryClient.invalidateQueries(
  //       //     journeyKeys.tasksProgress(
  //       //       new ObjectID(variables.metadata.journeyId)
  //       //     )
  //       //   );
  //       // }
  //       // if (variables.metadata?.phaseId) {
  //       //   queryClient.invalidateQueries(
  //       //     phaseKeys.tasks(variables.metadata.phaseId.toString())
  //       //   );
  //       // }
  //     },
  //   }
  // );
};

// export const useUpdateTask = () => {
//   const app = useRealmApp();
//   const snackbarCtx = useSnackBar();
//   type UpdateType = Partial<OldTaskRequest>;

//   const queryClient = useQueryClient();
//   const updateTaskMutation = useMutation(
//     buildSetter(app, "updateTask", "update"),
//     {
//       onMutate: async ({
//         update,
//         metadata,
//       }: {
//         update: UpdateType;
//         metadata?: Partial<OldTaskRequest>;
//       }) => {
//         await queryClient.cancelQueries([
//           "tasks",
//           "user",
//           app.currentUser.id,
//           "all",
//         ]);
//         await queryClient.cancelQueries(["tasks", "all"]);
//         await queryClient.cancelQueries(
//           journeyKeys.tasks(
//             new ObjectID(metadata?.journeyId),
//             new ObjectID(metadata?.journeyStageId)
//           )
//         );
//         await queryClient.cancelQueries([
//           "tasks",
//           update._id?.toString(),
//           "withType",
//         ]);
//         await queryClient.cancelQueries(
//           phaseKeys.tasks(String(metadata?.phaseId?.toString()))
//         );

//         // my tasks
//         const previousMyTasks = queryClient.getQueryData<PaginatedTasksData>([
//           "tasks",
//           "user",
//           app.currentUser.id,
//           "all",
//         ]);
//         console.log(previousMyTasks);
//         const newMyTasks = !!previousMyTasks?.tasks
//           ? previousMyTasks.tasks.map((t) =>
//               t._id?.toString() === update._id?.toString()
//                 ? { ...t, ...update }
//                 : t
//             )
//           : [];
//         console.log(newMyTasks);
//         queryClient.setQueryData(["tasks", "user", app.currentUser.id, "all"], {
//           ...previousMyTasks,
//           tasks: newMyTasks,
//         });

//         const previousJourneyStageTasks = queryClient.getQueryData<TaskData[]>(
//           journeyKeys.tasks(
//             new ObjectID(metadata?.journeyId),
//             new ObjectID(metadata?.journeyStageId)
//           )
//         );

//         const newJourneyStageTasks = !!previousJourneyStageTasks
//           ? previousJourneyStageTasks.map((t) =>
//               t._id?.toString() == update._id?.toString()
//                 ? { ...t, ...update }
//                 : t
//             )
//           : [];
//         queryClient.setQueryData(
//           journeyKeys.tasks(
//             new ObjectID(metadata?.journeyId),
//             new ObjectID(metadata?.journeyStageId)
//           ),
//           newJourneyStageTasks
//         );

//         const previousTasks = queryClient.getQueryData<PaginatedTasksData>([
//           "tasks",
//           "all",
//         ]);
//         const newTasks = !!previousTasks?.tasks
//           ? previousTasks.tasks.map((t) =>
//               t._id?.toString() == update._id?.toString()
//                 ? { ...t, ...update }
//                 : t
//             )
//           : [];
//         queryClient.setQueryData(["tasks", "all"], {
//           ...previousTasks,
//           tasks: newTasks,
//         });

//         const previousTask = queryClient.getQueryData<TaskWithUserType>([
//           "tasks",
//           update._id?.toString(),
//           "withType",
//         ]);
//         const newTask = !!previousTask
//           ? {
//               ...previousTask,
//               task: {
//                 ...previousTask.task,
//                 ...update,
//               },
//             }
//           : {};
//         queryClient.setQueryData(
//           ["tasks", update._id?.toString(), "withType"],
//           newTask
//         );

//         const previousPhaseTasks = queryClient.getQueryData<TaskData[]>(
//           phaseKeys.tasks(String(metadata?.phaseId?.toString()))
//         );
//         const newPhaseTasks = !!previousPhaseTasks
//           ? previousPhaseTasks.map((t) =>
//               t._id?.toString() == update._id?.toString()
//                 ? { ...t, ...update }
//                 : t
//             )
//           : [];
//         queryClient.setQueryData(
//           phaseKeys.tasks(String(metadata?.phaseId?.toString())),
//           newPhaseTasks
//         );

//         return {
//           previousTasks,
//           previousJourneyStageTasks,
//           previousTask,
//           previousPhaseTasks,
//           previousMyTasks,
//         };
//       },
//       // If the mutation fails, use the context returned from onMutate to roll back
//       onError: (err, update, context) => {
//         queryClient.setQueryData(["tasks", "all"], context?.previousTasks);
//         queryClient.setQueryData(
//           journeyKeys.tasks(
//             new ObjectID(update.metadata?.journeyId),
//             new ObjectID(update.metadata?.journeyStageId)
//           ),
//           context?.previousJourneyStageTasks
//         );
//         if (update.update._id) {
//           queryClient.setQueryData(
//             ["tasks", update.update._id?.toString(), "withType"],
//             context?.previousTask
//           );
//         }
//         queryClient.setQueryData(
//           phaseKeys.tasks(String(update.metadata?.phaseId?.toString())),
//           context?.previousPhaseTasks
//         );
//         queryClient.setQueryData(
//           ["tasks", "user", app.currentUser.id, "all"],
//           context?.previousMyTasks
//         );
//         snackbarCtx.showSnackbar("Error updating task", "error");
//       },
//       // Always refetch after error or success:
//       onSuccess: (data, variables) => {
//         queryClient.invalidateQueries(["tasks"]);
//         if (variables.update._id) {
//           queryClient.invalidateQueries(
//             taskKeys.logs(variables.update._id.toString())
//           );
//         }
//         // queryClient.invalidateQueries(["tasks", "approvals", "all"]);
//         // queryClient.refetchQueries(["tasks", variables._id]);
//         // queryClient.refetchQueries(["tasks", variables._id?.toString(), "withType"]);

//         // console.log(variables.metadata);

//         if (variables.metadata?.sharedSpaceId) {
//           queryClient.invalidateQueries(
//             sharedSpaceKeys.tasks(variables.metadata.sharedSpaceId)
//           );
//           queryClient.invalidateQueries(
//             sharedSpaceKeys.taskBreakdown(variables.metadata.sharedSpaceId)
//           );
//         }
//         if (variables.metadata?.journeyId) {
//           queryClient.invalidateQueries(
//             journeyKeys.journey(new ObjectID(variables.metadata.journeyId))
//           );
//           queryClient.invalidateQueries(
//             journeyKeys.stages(new ObjectID(variables.metadata.journeyId))
//           );
//           queryClient.invalidateQueries(
//             journeyKeys.keyDates(new ObjectID(variables.metadata.journeyId))
//           );
//           queryClient.invalidateQueries(
//             journeyKeys.tasksProgress(
//               new ObjectID(variables.metadata.journeyId)
//             )
//           );
//         }
//         if (variables.metadata?.phaseId) {
//           queryClient.invalidateQueries(
//             phaseKeys.tasks(variables.metadata.phaseId.toString())
//           );
//         }
//       },
//     }
//   );
//   return updateTaskMutation;
// };

// export const useUpdateSharedSpaceTask = () => {
//   const app = useRealmApp();
//   const functionName = "updateTask";
//   const fieldName = "update";

//   type UpdateType = Partial<TaskData>;

//   const queryClient = useQueryClient();
//   const updateSharedSpaceTaskMutation = useMutation(
//     buildSetter(app, functionName, fieldName),
//     {
//       onMutate: async ({
//         update,
//         sharedSpaceId,
//       }: {
//         update: UpdateType;
//         sharedSpaceId: ObjectID;
//       }) => {
//         await queryClient.cancelQueries(["tasks", "all"]);
//         await queryClient.cancelQueries(sharedSpaceKeys.tasks(sharedSpaceId));

//         const previousTasks = queryClient.getQueryData<TaskData[]>([
//           "tasks",
//           "all",
//         ]);
//         const previousSharedSpaceTasks = queryClient.getQueryData<TaskData[]>(
//           sharedSpaceKeys.tasks(sharedSpaceId)
//         );

//         const newSharedSpaceTasks = previousSharedSpaceTasks
//           ? previousSharedSpaceTasks.map((t) =>
//               t._id == update._id ? { ...t, ...update } : t
//             )
//           : [];

//         const newTasks = previousTasks
//           ? previousTasks.map((t) =>
//               t._id == update._id ? { ...t, ...update } : t
//             )
//           : [];

//         queryClient.setQueryData(["tasks", "all"], newTasks);
//         queryClient.setQueryData(
//           sharedSpaceKeys.tasks(sharedSpaceId),
//           newSharedSpaceTasks
//         );

//         return { previousTasks, previousSharedSpaceTasks };
//       },
//       // If the mutation fails, use the context returned from onMutate to roll back
//       onError: (err, update, context) => {
//         queryClient.setQueryData(["tasks", "all"], context?.previousTasks);
//         queryClient.setQueryData(
//           sharedSpaceKeys.tasks(update.sharedSpaceId),
//           context?.previousSharedSpaceTasks
//         );
//       },
//       // Always refetch after error or success:
//       onSettled: (data, error, variables) => {
//         queryClient.invalidateQueries(["tasks", "all"]);
//         queryClient.invalidateQueries(
//           sharedSpaceKeys.tasks(variables.sharedSpaceId)
//         );
//         queryClient.refetchQueries(["tasks", variables.update._id]);
//       },
//     }
//   );
//   return updateSharedSpaceTaskMutation;
// };

// APPROVALS

// export const useApproveTask = () => {
//   const app = useRealmApp();
//   const functionName = "approveTask";
//   const fieldName = "update";
//   const queryClient = useQueryClient();

//   const approveTaskMutation = useMutation(
//     buildSetter(app, functionName, fieldName),
//     {
//       onMutate: async ({ update }: { update: { _id: ObjectID } }) => {
//         const queryKey = ["tasks", "approvals", "all"];
//         await queryClient.cancelQueries(queryKey);
//         const previousTasks = queryClient.getQueryData(queryKey);
//         queryClient.setQueryData(queryKey, (tasks: TaskData[] = []) =>
//           tasks.filter((t) => t._id !== update._id)
//         );

//         return { previousTasks };
//       },
//       onError: (data, variables, context) => {
//         queryClient.setQueryData(
//           ["tasks", "approvals", "all"],
//           context?.previousTasks
//         );
//       },
//       onSettled: () => {
//         queryClient.invalidateQueries(["tasks", "approvals", "all"]);
//         queryClient.invalidateQueries(["tasks", "all"]);
//       },
//     }
//   );
//   return approveTaskMutation;
// };

// export const useRejectTaskApproval = () => {
//   const app = useRealmApp();
//   const functionName = "rejectTask";
//   const fieldName = "update";
//   const queryClient = useQueryClient();

//   const rejectTaskApprovalMutation = useMutation(
//     buildSetter(app, functionName, fieldName),
//     {
//       onMutate: async ({ update }: { update: { _id: ObjectID } }) => {
//         const queryKey = ["tasks", "approvals", "all"];
//         await queryClient.cancelQueries(queryKey);
//         const previousTasks = queryClient.getQueryData(queryKey);
//         queryClient.setQueryData(queryKey, (tasks: TaskData[] = []) =>
//           tasks.filter((t) => t._id !== update._id)
//         );

//         return { previousTasks };
//       },
//       onError: (data, variables, context) => {
//         queryClient.setQueryData(
//           ["tasks", "approvals", "all"],
//           context?.previousTasks
//         );
//       },
//       onSettled: () => {
//         queryClient.invalidateQueries(["tasks", "approvals", "all"]);
//         queryClient.invalidateQueries(["tasks", "all"]);
//       },
//     }
//   );
//   return rejectTaskApprovalMutation;
// };
