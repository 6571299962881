import { ObjectID } from "bson";
import { JwtPayload, jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Realm from "realm-web";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { Loading } from "src/components/ui/loading";
import { useRealmApp } from "src/store/RealmApp";

// for magic link & embed authentication
export default function ExternalAuthentication() {
  const app = useRealmApp();
  const [searchParams] = useSearchParams();
  const jwt = searchParams.get("token");
  const name = searchParams.get("name");
  const sharedSpaceId = searchParams.get("sharedSpaceId");
  const phaseId = searchParams.get("phaseId");
  const chatId = searchParams.get("chatId");
  const navigate = useNavigate();
  const snackbarCtx = useSnackBar();

  function sendToRightRoute({ phaseId }: { phaseId: string | null }) {
    if (phaseId) {
      if (chatId) {
        navigate(`/projects/${phaseId}/chat?chatId=${chatId}`);
      } else {
        navigate(`/projects/${phaseId}`);
      }
    } else if (sharedSpaceId) {
      navigate(`/projects`);
    } else {
      navigate(`/`);
    }
  }

  async function loginUser() {
    if (jwt) {
      const decoded: JwtPayload & { user: { email: string } } = jwtDecode(jwt);

      // if some user is logged in
      if (app.currentUser) {
        // log out if they have a capitalized email
        if (!!app.currentUser?.customData?.email) {
          const email = app.currentUser?.customData?.email;
          if (email !== email.toLowerCase()) {
            await app.logOut();
            navigate(`/login`);
            return;
          }
        }
        // if the right user is logged in
        if (app.currentUser?.customData?.email === decoded.user.email) {
          // console.log("right user is logged in");
          if (app.currentUser?.id && sharedSpaceId) {
            try {
              app.currentUser?.functions
                .authenticateEmbedUser({
                  name: app.currentUser?.customData?.name ?? decoded.user.email,
                  sharedSpaceId: new ObjectID(sharedSpaceId),
                })
                .then(() => {
                  app.currentUser?.refreshCustomData().then(() => {
                    sendToRightRoute({ phaseId });
                  });
                });
            } catch (error) {
              app.logOut();
              console.log("error", error);
            }
          } else {
            sendToRightRoute({ phaseId });
          }
        }
        // if the wrong user is logged in
        else {
          // console.log("wrong user is logged in");
          await app.logOut();
          // if jwt is expired, redirect to login page
          if (!!decoded.exp && decoded.exp * 1000 < Date.now()) {
            // console.log("jwt is expired");
            snackbarCtx.showSnackbar(
              "Magic link has expired. You will need to login again.",
              "error"
            );
            navigate(`/login`);
          } else {
            const credentials = Realm.Credentials.jwt(jwt);
            const user = await app.logIn(credentials);
            if (user.id && sharedSpaceId) {
              try {
                user.functions
                  .authenticateEmbedUser({
                    name: name ?? decoded.user.email,
                    sharedSpaceId: new ObjectID(sharedSpaceId),
                  })
                  .then(() => {
                    user.refreshCustomData().then(() => {
                      sendToRightRoute({ phaseId });
                    });
                  });
              } catch (error) {
                app.logOut();
                console.log("error", error);
              }
            }
          }
        }
      }
      // if no user is logged in
      else {
        if (!!decoded.exp && decoded.exp * 1000 < Date.now()) {
          // console.log("jwt is expired");
          snackbarCtx.showSnackbar(
            "Magic link has expired. You will need to login again.",
            "error"
          );
          navigate(`/login`);
        } else {
          if (decoded.user.email !== decoded.user.email.toLowerCase()) {
            snackbarCtx.showSnackbar(
              "Magic link has expired. You will need to login again.",
              "error"
            );
            navigate(`/login`);
            return;
          }

          // console.log("jwt is not expired");
          const credentials = Realm.Credentials.jwt(jwt);
          const user = await app.logIn(credentials);
          // console.log("authenticating embed user");
          // console.log(user.id, sharedSpaceId);

          if (user.id && sharedSpaceId) {
            try {
              user.functions
                .authenticateEmbedUser({
                  name: name ?? decoded.user.email,
                  sharedSpaceId: new ObjectID(sharedSpaceId),
                })
                .then(() => {
                  console.log("refreshing custom data");
                  user.refreshCustomData().then(() => {
                    sendToRightRoute({ phaseId });
                  });
                });
            } catch (error) {
              app.logOut();
              console.log("error", error);
            }
          }
        }
      }
    } else {
      navigate("/login");
    }
  }

  useEffect(() => {
    loginUser();
  }, []);

  return (
    <div className="pt-[30vh]">
      <Loading />
    </div>
  );
}
