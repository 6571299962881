import { DndContext, DragEndEvent, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Transition } from "@headlessui/react";
import { PhoneIcon } from "@heroicons/react/20/solid";
import {
  ArrowLeftIcon,
  BoltIcon,
  DocumentTextIcon,
  EllipsisVerticalIcon,
  EyeIcon,
  EyeSlashIcon,
  FlagIcon,
  PaperClipIcon,
  TrashIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { CloudArrowUpIcon } from "@heroicons/react/24/solid";
import {
  CaretSortIcon,
  CheckIcon,
  DragHandleDots2Icon,
  OpenInNewWindowIcon,
  StopwatchIcon,
} from "@radix-ui/react-icons";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import Typography from "@tiptap/extension-typography";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { ObjectID } from "bson";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetFormTemplates } from "src/api/Forms/forms";
import {
  MeetingVendor,
  TaskTemplateData,
  TaskTemplateFileData,
  TaskTemplateUpdateRequest,
  relativeDueDateUnits,
  useCreateSubTaskTemplate,
  useDeleteTaskTemplate,
  useDeleteTaskTemplateConfirmation,
  useDeleteTaskTemplateFile,
  useGetSubTaskTemplates,
  useGetTaskTemplate,
  useUpdateTaskTemplate,
  useUploadTaskTemplateFile,
} from "src/api/Services/Journeys/journeyTemplates";
import {
  useGetJourneyTemplate,
  useGetStageAndTaskTemplatesList,
} from "src/api/Services/Tasks/journeys";
import { Title as ReusableTitle } from "src/components/Services/Reusable/Tasks/Elements/Title";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { Menu } from "src/components/ui/Menu/Menu";
import { Button } from "src/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "src/components/ui/command";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "src/components/ui/dialog";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import {
  Select as NewSelect,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/components/ui/tabs";
import { TextInput } from "src/components/ui/text-input";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "src/components/ui/tooltip";
import { estimateToMinutes } from "src/pages/Services/Tasks/Tables/TasksTable";
import { cn } from "src/utils/ui/ui";
import TiptapEditor from "../../../../../../components/ui/editor/tiptap-editor";
import {
  Action,
  SubtaskDurationSelect,
  SubtaskRoleCombobox,
  SubtaskVisibilityButtons,
  SubtasksPolledVisibility,
  estimateOptions,
  taskTemplateLaunchConditions,
} from "../NewTaskTemplate/NewTaskTemplateModal";
import { TaskTemplateLaunchCondition } from "../NewTaskTemplate/TaskTemplateContextProvider";
import { EditTaskTemplateContextProvider } from "./EditTaskTemplateContextProvider";
import { useTaskTemplatePanelContext } from "./TaskTemplatePanelContextProvider";

export const EditTaskTemplate = ({
  taskTemplateId,
}: {
  taskTemplateId: string;
}) => {
  const { taskTemplate } = useGetTaskTemplate(new ObjectID(taskTemplateId));
  const updateTaskTemplateMutation = useUpdateTaskTemplate();
  const [addSubtasks, setAddSubtasks] = useState(false);
  const [showFormTemplate, setShowFormTemplate] = useState(false);
  const taskTemplatePanelCtx = useTaskTemplatePanelContext();
  const [openRemoveFormConfirmation, setOpenRemoveFormConfirmation] =
    useState(false);
  const [showMeeting, setShowMeeting] = useState(false);
  const [showFileUpload, setShowFileUpload] = useState(false);

  return (
    <>
      {taskTemplate && (
        <EditTaskTemplateContextProvider taskTemplate={taskTemplate}>
          <Transition
            appear
            show
            className={"flex-1"}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
          >
            <div className="h-full">
              {!!taskTemplate && (
                <>
                  <div className="flex h-full flex-col">
                    <div className="p-6">
                      <div className="space-y-6">
                        {taskTemplate.parentTaskTemplate && (
                          <Button
                            variant={"secondary"}
                            className="space-x-2"
                            onClick={() => {
                              if (taskTemplate.parentTaskTemplate?._id)
                                taskTemplatePanelCtx.dispatch({
                                  type: "TASK_TEMPLATE_PANEL_UPDATE",
                                  taskTemplatePanel: {
                                    id: taskTemplate.parentTaskTemplate?._id.toString(),
                                    isOpen: true,
                                  },
                                });
                            }}
                          >
                            <ArrowLeftIcon className="h-4 w-4 text-gray-400" />
                            <div>
                              <span className="font-light">
                                Go to parent task&nbsp;
                              </span>
                              <span className="font-semibold">
                                {taskTemplate.parentTaskTemplate.title}
                              </span>
                            </div>
                          </Button>
                          // <div className="inline-flex cursor-pointer rounded-md border bg-gray-50 px-2 py-0.5 hover:bg-gray-100">
                          //   <ArrowLeftIcon className="inline-block h-4 w-4 text-gray-400" />
                          //   <span>Go to parent task</span>
                          //   <span className="font-bold"></span>
                          // </div>
                        )}
                        <div className="flex justify-between">
                          <div className="w-full space-y-4">
                            <Title taskTemplate={taskTemplate} />
                          </div>
                        </div>
                      </div>
                      {/* <ParentTask taskTemplate={taskTemplate} /> */}

                      <div className="flex items-start justify-between gap-6 pt-4">
                        <div className="space-y-2">
                          <div className="flex space-x-2">
                            {!taskTemplate.parentTaskTemplate && (
                              <StageCombobox taskTemplate={taskTemplate} />
                            )}
                            <RoleCombobox taskTemplate={taskTemplate} />
                            {taskTemplate.subTaskTemplateCount ? (
                              <>
                                <SubtasksDurationSum
                                  taskTemplate={taskTemplate}
                                />
                                <SubtasksPolledVisibility
                                  isVisible={taskTemplate.isVisible}
                                />
                              </>
                            ) : (
                              <>
                                <DurationSelect taskTemplate={taskTemplate} />
                                <Visibility taskTemplate={taskTemplate} />
                              </>
                            )}
                          </div>
                          {!taskTemplate.parentTaskTemplate ? (
                            <DueDateDependency taskTemplate={taskTemplate} />
                          ) : (
                            <div className="inline-flex h-8 items-center rounded-md border border-gray-300 bg-gray-100 px-2 text-sm">
                              <span className="text-gray-500">{`Due `}</span>
                              &nbsp;
                              <span className="font-medium">{`${
                                taskTemplate.relativeDueDate.value
                              } ${taskTemplate.relativeDueDate.unit}${
                                taskTemplate.relativeDueDate.value === 1
                                  ? ""
                                  : "s"
                              }`}</span>
                              &nbsp;
                              <span className="text-gray-500">{`after`}</span>
                              &nbsp;
                              {taskTemplate.dependentOn.type === "default" && (
                                <span className="font-medium">{`project start`}</span>
                              )}
                              {taskTemplate.dependentOn.type === "task" && (
                                <>
                                  <span className="text-gray-500">{`task`}</span>
                                  &nbsp;
                                  <span className="font-medium">
                                    {taskTemplate.dependentOn.name}
                                  </span>
                                </>
                              )}
                              {taskTemplate.dependentOn.type === "stage" && (
                                <>
                                  <span className="text-gray-500">{`stage`}</span>
                                  &nbsp;
                                  <span className="font-medium">
                                    {taskTemplate.dependentOn.name}
                                  </span>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="inline-flex items-center space-x-2">
                          <AttachmentsButton taskTemplate={taskTemplate} />

                          <Options taskTemplate={taskTemplate} />
                        </div>
                      </div>

                      <div
                        className={
                          "mt-4 rounded-md border bg-gray-50 px-3 py-2"
                        }
                      >
                        {!!taskTemplate._id && (
                          <div key={taskTemplate._id.toString()}>
                            <Description taskTemplate={taskTemplate} />
                          </div>
                        )}
                        {/* <Description
                          isEditing={true}
                          description={taskTemplate.description}
                          onBlur={(description) => {
                            if (
                              taskTemplate.description.trim() !==
                              description.trim()
                            ) {
                              updateTaskTemplateMutation.mutate({
                                journeyTemplateId:
                                  taskTemplate.journeyTemplate._id,
                                journeyStageTemplateId:
                                  taskTemplate.journeyStageTemplate._id,
                                params: {
                                  _id: taskTemplate._id,
                                  description,
                                },
                                parentTaskTemplateId:
                                  taskTemplate.parentTaskTemplate?._id,
                              });
                            }
                          }}
                        /> */}
                      </div>
                      {!!taskTemplate.files &&
                        taskTemplate.files.length > 0 && (
                          <Attachments taskTemplate={taskTemplate} />
                        )}
                    </div>
                    <div className="h-full flex-1 pt-6">
                      <Tabs
                        defaultValue={"extensions"}
                        className="flex h-full flex-col overflow-auto"
                      >
                        <TabsList className="mb-0 shrink-0 border-gray-300">
                          <TabsTrigger value="extensions" className="ml-6">
                            Extensions
                          </TabsTrigger>
                        </TabsList>
                        <div className="flex-1 overflow-auto">
                          <TabsContent
                            value="extensions"
                            className="flex h-full flex-col bg-gray-100 px-6"
                          >
                            {taskTemplate.action === "default" &&
                              !addSubtasks &&
                              !showFormTemplate &&
                              !taskTemplate.subTaskTemplateCount && (
                                <div className="flex gap-3 py-6">
                                  <SubtasksAction
                                    // taskTemplate={taskTemplate}
                                    setAddSubtasks={setAddSubtasks}
                                    // addSubtasks={addSubtasks}
                                    // showFormTemplate={showFormTemplate}
                                  />
                                  <FormAction
                                    taskTemplate={taskTemplate}
                                    setShowFormTemplate={setShowFormTemplate}
                                    showFormTemplate={showFormTemplate}
                                    addSubtasks={addSubtasks}
                                  />
                                  <MeetingAction
                                    setShowMeeting={setShowMeeting}
                                  />
                                  <FileUploadAction
                                    setShowFileUpload={setShowFileUpload}
                                    taskTemplate={taskTemplate}
                                  />
                                </div>
                              )}
                            {taskTemplate.action === "meeting" && (
                              <div className="h-full flex-1 space-y-2 pt-6">
                                <Meeting taskTemplate={taskTemplate} />
                              </div>
                            )}
                            {taskTemplate.action === "fileUpload" && (
                              <div className="h-full flex-1 space-y-2 pt-6">
                                <FileUpload taskTemplate={taskTemplate} />
                              </div>
                            )}
                            <AddMeetingDialog
                              taskTemplate={taskTemplate}
                              showMeeting={showMeeting}
                              setShowMeeting={setShowMeeting}
                            />
                            {(taskTemplate.action === "form" ||
                              showFormTemplate) && (
                              <div className="h-full flex-1 space-y-2 pt-6">
                                <div className="relative flex justify-between rounded-md border bg-white p-4">
                                  <div className="space-y-2">
                                    <InputHeading heading="Form" />
                                    <FormTemplateCombobox
                                      taskTemplate={taskTemplate}
                                    />
                                  </div>
                                  <div>
                                    <Button
                                      variant={"ghost"}
                                      className="absolute top-2 right-2 p-1.5 px-2"
                                      onClick={() => {
                                        if (taskTemplate.action !== "form") {
                                          setShowFormTemplate(false);
                                        } else {
                                          setOpenRemoveFormConfirmation(true);
                                        }
                                      }}
                                    >
                                      <TrashIcon className="h-4 w-4 text-gray-400" />{" "}
                                    </Button>
                                    <Dialog
                                      open={openRemoveFormConfirmation}
                                      onOpenChange={(open) => {
                                        setOpenRemoveFormConfirmation(open);
                                      }}
                                    >
                                      <DialogContent
                                      // onOpenAutoFocus={(e) => {
                                      //   e.preventDefault();
                                      // }}
                                      >
                                        <DialogHeader>
                                          <DialogTitle>Remove Form</DialogTitle>
                                          {/* <DialogDescription>Delete all subtasks</DialogDescription> */}
                                        </DialogHeader>
                                        <div className="grid gap-4 py-2">
                                          <p>
                                            Are you sure you want to remove the
                                            form?
                                          </p>
                                        </div>
                                        <DialogFooter>
                                          <Button
                                            onClick={() => {
                                              setOpenRemoveFormConfirmation(
                                                false
                                              );
                                            }}
                                            variant={"secondary"}
                                          >
                                            Cancel
                                          </Button>
                                          <Button
                                            onClick={() => {
                                              // remove form template
                                              updateTaskTemplateMutation.mutate(
                                                {
                                                  journeyTemplateId:
                                                    taskTemplate.journeyTemplate
                                                      ._id,
                                                  journeyStageTemplateId:
                                                    taskTemplate
                                                      .journeyStageTemplate._id,
                                                  params: {
                                                    _id: taskTemplate._id,
                                                    action: "default",
                                                  },
                                                }
                                              );
                                              setOpenRemoveFormConfirmation(
                                                false
                                              );
                                            }}
                                            variant={"destructive"}
                                          >
                                            Remove
                                          </Button>
                                        </DialogFooter>
                                      </DialogContent>
                                    </Dialog>
                                  </div>
                                </div>
                              </div>
                            )}

                            {taskTemplate.action === "default" &&
                              (addSubtasks ||
                                taskTemplate.subTaskTemplateCount) && (
                                <div className="pt-6">
                                  <Subtasks
                                    taskTemplate={taskTemplate}
                                    addSubtasks={addSubtasks}
                                    setAddSubtasks={setAddSubtasks}
                                  />
                                </div>
                              )}
                            {/* {!taskTemplate.subTaskTemplateCount &&
                              !addSubtasks &&
                              !taskTemplate.parentTaskTemplate && (
                                
                              )}
                            <>
                              {!addSubtasks &&
                                !taskTemplate.subTaskTemplateCount &&
                                !taskTemplate.parentTaskTemplate && (
                                  <SubtasksButton
                                    taskTemplate={taskTemplate}
                                    setAddSubtasks={setAddSubtasks}
                                    addSubtasks={addSubtasks}
                                  />
                                )}
                              {(addSubtasks ||
                                taskTemplate.subTaskTemplateCount) && (
                                <>
                                  
                                </>
                              )}
                            </> */}
                          </TabsContent>
                        </div>
                        {/* <TabsContent
                          value="subtasks"
                          className="h-full overflow-auto bg-gray-100 px-6 pt-8"
                        >
                          
                        </TabsContent> */}
                      </Tabs>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Transition>
        </EditTaskTemplateContextProvider>
      )}
    </>
  );
};

function Description({ taskTemplate }: { taskTemplate: TaskTemplateData }) {
  const updateTaskTemplateMutation = useUpdateTaskTemplate();
  const editor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
        autolink: true,
      }),
      Typography,
      Placeholder.configure({
        placeholder: "Add description...",
        showOnlyWhenEditable: false,
      }),
    ],
    content: taskTemplate.description,
    onBlur: ({ editor }) => {
      if (taskTemplate.description.trim() !== editor?.getHTML().trim()) {
        updateTaskTemplateMutation.mutate({
          journeyTemplateId: taskTemplate.journeyTemplate._id,
          journeyStageTemplateId: taskTemplate.journeyStageTemplate._id,
          params: {
            _id: taskTemplate._id,
            description: editor?.getHTML().trim(),
          },
          parentTaskTemplateId: taskTemplate.parentTaskTemplate?._id,
        });
      }
    },
  });

  return <TiptapEditor editor={editor} />;
}

function AddMeetingDialog({
  taskTemplate,
  showMeeting,
  setShowMeeting,
}: {
  taskTemplate: TaskTemplateData;
  showMeeting: boolean;
  setShowMeeting: (value: boolean) => void;
}) {
  const [vendor, setVendor] = useState<MeetingVendor>("calendly");
  const [link, setLink] = useState("");
  const updateTaskTemplateMutation = useUpdateTaskTemplate();
  return (
    <Dialog
      open={showMeeting}
      onOpenChange={(open) => {
        setShowMeeting(open);
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Meeting to Task</DialogTitle>
          <DialogDescription>
            Taskers will be able to book a meeting via the link you provide
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-6 py-6">
          <div className="space-y-1">
            <InputHeading heading="Meeting Booking Service" />
            <NewSelect
              value={vendor}
              onValueChange={(value) => {
                if (value) {
                  setVendor(value as MeetingVendor);
                }
              }}
            >
              <SelectTrigger className="w-fit gap-2">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value={"calendly"}>{"Calendly"}</SelectItem>
                  <SelectItem value={"hubspot"}>{"HubSpot"}</SelectItem>
                  <SelectItem value={"other"}>{"Other"}</SelectItem>
                </SelectGroup>
              </SelectContent>
            </NewSelect>
          </div>
          <div className="space-y-1">
            <InputHeading heading="Link" />
            <TextInput
              className="w-full max-w-[400px]"
              value={link}
              onChange={(e) => {
                setLink(e.target.value);
              }}
              placeholder={
                vendor === "hubspot"
                  ? "https://meetings.hubspot.com/meeting"
                  : "https://calendly.com/meeting"
              }
            />
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild className="">
            <div className="flex justify-end space-x-3">
              <Button
                variant={"secondary"}
                onClick={() => {
                  setShowMeeting(false);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  updateTaskTemplateMutation.mutate({
                    params: {
                      _id: taskTemplate._id,
                      action: "meeting",
                      meeting: {
                        vendor,
                        link,
                      },
                    },
                    journeyStageTemplateId:
                      taskTemplate.journeyStageTemplate._id,
                    journeyTemplateId: taskTemplate.journeyTemplate._id,
                  });
                  setShowMeeting(false);
                }}
                disabled={link.trim() === ""}
              >
                Add
              </Button>
            </div>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function Meeting({ taskTemplate }: { taskTemplate: TaskTemplateData }) {
  const updateTaskTemplateMutation = useUpdateTaskTemplate();
  const [openRemoveMeetingConfirmation, setOpenRemoveMeetingConfirmation] =
    useState(false);
  const [link, setLink] = useState(taskTemplate.meeting?.link ?? "");
  const [error, setError] = useState("");
  return (
    <div className="relative rounded-md border bg-white p-4">
      <Button
        variant={"ghost"}
        className="absolute top-2 right-2 p-1.5 px-2"
        onClick={() => {
          setOpenRemoveMeetingConfirmation(true);
        }}
      >
        <TrashIcon className="h-4 w-4 text-gray-400" />{" "}
      </Button>

      <div className="space-y-6">
        <div className="space-y-1">
          <InputHeading heading="Meeting Booking Service" />
          <NewSelect
            value={taskTemplate.meeting?.vendor}
            onValueChange={(value) => {
              if (value) {
                updateTaskTemplateMutation.mutate({
                  journeyTemplateId: taskTemplate.journeyTemplate._id,
                  journeyStageTemplateId: taskTemplate.journeyStageTemplate._id,
                  params: {
                    _id: taskTemplate._id,
                    action: "meeting",
                    meeting: {
                      vendor: value as MeetingVendor,
                      link: taskTemplate.meeting?.link ?? "",
                    },
                  },
                });
              }
            }}
          >
            <SelectTrigger className="w-fit gap-2">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem value={"calendly"}>{"Calendly"}</SelectItem>
                <SelectItem value={"hubspot"}>{"HubSpot"}</SelectItem>
                <SelectItem value={"other"}>{"Other"}</SelectItem>
              </SelectGroup>
            </SelectContent>
          </NewSelect>
        </div>
        <div className="space-y-1">
          <InputHeading heading="Link" />
          <div className="space-y-1">
            <TextInput
              className="w-full max-w-[300px]"
              value={link}
              onChange={(e) => {
                if (e.target.value.trim() !== "" && error.trim() !== "") {
                  setError("");
                }
                setLink(e.target.value);
              }}
              onBlur={() => {
                if (link.trim() === "") {
                  setLink(taskTemplate.meeting?.link ?? "");
                  setError("Meeting link cannot be empty");
                }
                if (link !== taskTemplate.meeting?.link && link.trim() !== "") {
                  updateTaskTemplateMutation.mutate({
                    journeyTemplateId: taskTemplate.journeyTemplate._id,
                    journeyStageTemplateId:
                      taskTemplate.journeyStageTemplate._id,
                    params: {
                      _id: taskTemplate._id,
                      action: "meeting",
                      meeting: {
                        vendor: taskTemplate.meeting?.vendor as MeetingVendor,
                        link,
                      },
                    },
                  });
                }
              }}
            />
            {error.trim() !== "" && (
              <div className="text-xs text-red-600">{error}</div>
            )}
          </div>
        </div>
      </div>

      <Dialog
        open={openRemoveMeetingConfirmation}
        onOpenChange={(open) => {
          setOpenRemoveMeetingConfirmation(open);
        }}
      >
        <DialogContent
        // onOpenAutoFocus={(e) => {
        //   e.preventDefault();
        // }}
        >
          <DialogHeader>
            <DialogTitle>Remove Meeting</DialogTitle>
            {/* <DialogDescription>Delete all subtasks</DialogDescription> */}
          </DialogHeader>
          <div className="grid gap-4 py-2">
            <p>Are you sure you want to remove the meeting?</p>
          </div>
          <DialogFooter>
            <Button
              onClick={() => {
                setOpenRemoveMeetingConfirmation(false);
              }}
              variant={"secondary"}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // remove form template
                updateTaskTemplateMutation.mutate({
                  journeyTemplateId: taskTemplate.journeyTemplate._id,
                  journeyStageTemplateId: taskTemplate.journeyStageTemplate._id,
                  params: {
                    _id: taskTemplate._id,
                    action: "default",
                  },
                });
                setOpenRemoveMeetingConfirmation(false);
              }}
              variant={"destructive"}
            >
              Remove
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function FileUpload({ taskTemplate }: { taskTemplate: TaskTemplateData }) {
  const updateTaskTemplateMutation = useUpdateTaskTemplate();
  const [openRemoveFileUploadConfirmation, setOpenRemoveMeetingConfirmation] =
    useState(false);
  return (
    <div className="relative rounded-md border bg-white p-4">
      <Button
        variant={"ghost"}
        className="absolute top-2 right-2 p-1.5 px-2"
        onClick={() => {
          setOpenRemoveMeetingConfirmation(true);
        }}
      >
        <TrashIcon className="h-4 w-4 text-gray-400" />{" "}
      </Button>

      <div className="space-y-6">
        <div>
          <InputHeading heading="File Upload" />
          <div className="text-xs text-gray-500">
            This task will require the user to upload a file
          </div>
        </div>
      </div>

      <Dialog
        open={openRemoveFileUploadConfirmation}
        onOpenChange={(open) => {
          setOpenRemoveMeetingConfirmation(open);
        }}
      >
        <DialogContent
        // onOpenAutoFocus={(e) => {
        //   e.preventDefault();
        // }}
        >
          <DialogHeader>
            <DialogTitle>Remove File Upload</DialogTitle>
            {/* <DialogDescription>Delete all subtasks</DialogDescription> */}
          </DialogHeader>
          <div className="grid gap-4 py-2">
            <p>Are you sure you want to remove the file upload requirement?</p>
          </div>
          <DialogFooter>
            <Button
              onClick={() => {
                setOpenRemoveMeetingConfirmation(false);
              }}
              variant={"secondary"}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // remove form template
                updateTaskTemplateMutation.mutate({
                  journeyTemplateId: taskTemplate.journeyTemplate._id,
                  journeyStageTemplateId: taskTemplate.journeyStageTemplate._id,
                  params: {
                    _id: taskTemplate._id,
                    action: "default",
                  },
                });
                setOpenRemoveMeetingConfirmation(false);
              }}
              variant={"destructive"}
            >
              Remove
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function Attachments({ taskTemplate }: { taskTemplate: TaskTemplateData }) {
  const [selectedFile, setSelectedFile] = useState<
    TaskTemplateFileData | undefined
  >(undefined);
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteTaskTemplateFileMutation = useDeleteTaskTemplateFile();
  return (
    <div className="pt-4">
      {/* <div className="flex items-center space-x-2">
        <div className="text-sm font-medium text-gray-600">Attachments</div>
        
      </div> */}
      <div className="flex flex-wrap items-center gap-2 text-gray-400">
        {taskTemplate.files?.map((f) => (
          <div className="flex h-8 cursor-default items-center rounded-full border pl-3 pr-1.5">
            <PaperClipIcon className="mr-2 h-4 w-4 shrink-0 text-gray-400" />
            <div className="pr-1 text-sm font-medium text-gray-500">
              {f.label}
            </div>
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger asChild>
                  <Button
                    variant={"ghost"}
                    className="h-5 w-5 rounded-full p-0.5"
                    onClick={() => {
                      setSelectedFile(f);
                    }}
                  >
                    <TrashIcon className="h-3 w-3 text-gray-400" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent sideOffset={8}>
                  <p>Delete attachment</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger asChild>
                  <Button
                    variant={"ghost"}
                    className="h-5 w-5 rounded-full p-0.5"
                    onClick={() => {
                      window.open(f.url, "_blank");
                    }}
                  >
                    <OpenInNewWindowIcon className="h-3 w-3 text-gray-400" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent sideOffset={8}>
                  <p>Open attachment</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        ))}
      </div>
      <Dialog
        open={!!selectedFile}
        onOpenChange={(open) => {
          if (!open) {
            setSelectedFile(undefined);
          }
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Confirmation</DialogTitle>
            {/* <DialogDescription>
              Confirm deletion of stage template
            </DialogDescription> */}
          </DialogHeader>
          <div className="space-y-5 pt-3 text-sm text-gray-600">
            <div>
              Are you sure you want to delete the file{" "}
              <span className="font-medium text-gray-900">
                {selectedFile?.name}
              </span>
              ?
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild className="">
              <div className="flex justify-end space-x-3">
                <Button
                  variant={"secondary"}
                  onClick={() => {
                    setSelectedFile(undefined);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant={"destructive"}
                  onClick={() => {
                    if (taskTemplate._id && selectedFile?.name)
                      deleteTaskTemplateFileMutation.mutate({
                        taskTemplateId: taskTemplate._id.toString(),
                        name: selectedFile?.name,
                      });
                  }}
                >
                  {deleteTaskTemplateFileMutation.isLoading
                    ? "Deleting..."
                    : "Delete"}
                </Button>
              </div>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function AttachmentsButton({
  taskTemplate,
}: {
  taskTemplate: TaskTemplateData;
}) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const uploadTaskTemplateFile = useUploadTaskTemplateFile();
  const [label, setLabel] = useState("");
  const [description, setDescription] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);

  useEffect(() => {
    if (uploadTaskTemplateFile.isSuccess) {
      setIsOpen(false);
    }
  }, [uploadTaskTemplateFile.isSuccess]);

  useEffect(() => {
    setLabel("");
    setDescription("");
    setFile(undefined);
  }, [isOpen]);

  return (
    <>
      <Dialog
        open={isOpen}
        onOpenChange={(open) => {
          setIsOpen(open);
        }}
      >
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant={"secondary"}
                className="w-8"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                <PaperClipIcon className="h-4 w-4 shrink-0 text-gray-400" />
              </Button>
            </TooltipTrigger>
            <TooltipContent sideOffset={8}>
              <p>Add attachment</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <DialogContent>
          <DialogHeader>
            <DialogTitle>New Attachment</DialogTitle>
            <DialogDescription>
              Upload a file up to 5MB in size
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-4 py-4">
            <div className="space-y-1">
              <InputHeading heading="Name" />
              <TextInput
                autoFocus
                className="max-w-[300px]"
                value={label}
                onChange={(e) => {
                  setLabel(e.target.value);
                }}
              />
            </div>
            <div className="space-y-1">
              <InputHeading heading="File" />
              <div className="flex space-x-3">
                <Button
                  size="fit"
                  variant={"link"}
                  onClick={() => {
                    fileInputRef.current?.click();
                  }}
                  // disabled={uploadLogoMutation.isLoading}
                >
                  <>{`${!!file ? "Change" : "Add"}`} file</>
                </Button>
                {!!file ? (
                  <div className="text-sm text-gray-800">{file.name}</div>
                ) : (
                  <div className="text-sm text-gray-400">No file selected</div>
                )}
              </div>
            </div>
            <input
              id="file-upload"
              name="file-upload"
              type="file"
              accept="application/pdf, image/jpg, image/jpeg, image/png, image/svg, image/gif, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .csv, .txt"
              className="sr-only"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={(e) => {
                const selectedFile = e.target.files?.[0];
                if (selectedFile) {
                  if (
                    !/^[a-zA-Z0-9_ -]+\.[a-zA-Z0-9]+$/.test(selectedFile.name)
                  ) {
                    alert(
                      "The file name must consist solely of letters, numbers, underscores, hyphens, and a single dot. Please ensure it adheres to this format."
                    );
                  } else if (selectedFile.size > 5 * 1024 * 1024) {
                    alert("File size should not exceed 5MB.");
                  } else {
                    setFile(selectedFile);
                  }
                }
              }}
            />
          </div>
          <DialogFooter>
            <Button
              disabled={!label || !file || uploadTaskTemplateFile.isLoading}
              onClick={() => {
                if (file && taskTemplate._id) {
                  uploadTaskTemplateFile.mutate({
                    description,
                    taskTemplateId: taskTemplate._id.toString(),
                    file,
                    label,
                  });
                }
              }}
            >
              {uploadTaskTemplateFile.isLoading ? "Uploading.." : "Upload"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}

function Subtasks({
  taskTemplate,
  addSubtasks,
  setAddSubtasks,
}: {
  taskTemplate: TaskTemplateData;
  addSubtasks: boolean;
  setAddSubtasks: (value: boolean) => void;
}) {
  const [isAddingSubtask, setIsAddingSubtask] = useState(addSubtasks);

  function handleAddSubtask(value: boolean) {
    setIsAddingSubtask(value);
    setAddSubtasks(value);
  }

  return (
    <div className="rounded-md border bg-white">
      <SubtasksView taskTemplate={taskTemplate} />
      {isAddingSubtask && (
        <AddSubtask
          taskTemplate={taskTemplate}
          handleAddSubtask={handleAddSubtask}
        />
      )}
      {!isAddingSubtask && (
        <div className="pl-3 pb-2">
          <button
            className="text-xs text-gray-400 hover:text-gray-600 focus:outline-primary"
            onClick={() => {
              setIsAddingSubtask(true);
            }}
          >
            Add Subtask
          </button>
        </div>
      )}
    </div>
  );
}

function AddSubtask({
  taskTemplate,
  handleAddSubtask,
}: {
  taskTemplate: TaskTemplateData;
  handleAddSubtask: (value: boolean) => void;
}) {
  const [isVisible, setIsVisible] = useState(true);
  const [tasker, setTasker] = useState<TaskTemplateData["tasker"]>(
    taskTemplate.tasker
  );
  const [title, setTitle] = useState("");
  const [estimate, setEstimate] = useState<TaskTemplateData["estimate"]>({
    value: 10,
    unit: "minute",
  });

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "0px"; // Reset height to shrink if needed
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + "px";
    }
  }, [title]);
  const createSubTaskTemplateMutation = useCreateSubTaskTemplate();

  function addSubtask() {
    if (tasker) {
      if (taskTemplate._id) {
        createSubTaskTemplateMutation.mutate({
          journeyStageTemplateId:
            taskTemplate.journeyStageTemplate._id.toString(),
          journeyTemplateId: taskTemplate.journeyTemplate._id.toString(),
          parentTaskTemplateId: taskTemplate._id?.toString(),
          subTaskTemplates: [
            {
              title,
              estimate,
              taskerPersona: tasker.persona,
              taskerType: tasker.type,
              isVisible,
            },
          ],
        });
      }
      // newTaskTemplateCtx.dispatch({
      //   type: "subtasks_update",
      //   subtasks: [
      //     ...newTaskTemplateCtx.state.subtasks,
      //     {
      //       title,
      //       estimate,
      //       id: Math.random(),
      //       tasker: tasker,
      //       isVisible,
      //     },
      //   ],
      // });
      // if (isVisible) {
      //   newTaskTemplateCtx.dispatch({
      //     type: "is_visible_update",
      //     isVisible: true,
      //   });
      // }
      setTitle("");
    }
  }
  return (
    <div
      className={clsx(
        "rounded-b-md border-gray-300/60 bg-white p-3 px-3",
        !taskTemplate.subTaskTemplateCount ? "rounded-md" : "mt-2 border-t"
      )}
    >
      <div className="flex items-center gap-1">
        <div className="text-sm text-gray-400">{`New Subtask`}</div>
        {/* <BoltIcon className="h-4 w-4 text-gray-400" />
        <div className="text-sm text-gray-400">
          {newTaskTemplateCtx.state.subtasks.length + 1}
        </div> */}
      </div>
      <div className="space-y-2 pt-1">
        <textarea
          autoFocus
          ref={textAreaRef}
          className="w-full resize-none border-none bg-transparent p-0 outline-none placeholder:text-gray-300 focus:border-none focus:outline-none focus:ring-0"
          value={title}
          onChange={(event) => {
            if (event.target.value.includes("\n")) {
              if (title.trim() !== "") {
                addSubtask();
              } else {
                return;
              }
            } else {
              setTitle(event.target.value);
            }
          }}
          placeholder={`Subtask title`}
        />
        <div className="flex justify-between gap-2">
          <div className="flex space-x-2">
            <SubtaskRoleCombobox
              tasker={tasker}
              onExternalTaskerChange={(externalTasker) => {
                setTasker({
                  type: "external",
                  persona: externalTasker,
                });
                setIsVisible(true);
              }}
              onInternalTaskerChange={(internalTasker) => {
                setTasker({
                  type: "internal",
                  persona: internalTasker,
                });
              }}
            />
            <SubtaskDurationSelect
              widthFit={true}
              estimate={estimate}
              value={estimate.value + " " + estimate.unit}
              onValueChange={(value: string) => {
                const e = estimateOptions.find(
                  (estimate) => estimate.value + " " + estimate.unit === value
                );
                if (e) {
                  setEstimate(e);
                }
                if (value === "0 hour") {
                  setEstimate({ value: 0, unit: "hour" });
                }
              }}
            />
            <SubtaskVisibilityButtons
              isVisible={isVisible}
              onValueChange={(value) => {
                setIsVisible(value);
              }}
              taskerType={tasker?.type ?? "internal"}
            />
            {/* <NewSelect
                value={estimate.value + " " + estimate.unit}
                onValueChange={(value) => {
                  const e = estimateOptions.find(
                    (estimate) => estimate.value + " " + estimate.unit === value
                  );
                  if (e) {
                    setEstimate(e);
                  }
                  if (value === "0 hour") {
                    setEstimate({ value: 0, unit: "hour" });
                  }
                }}
              >
                <SelectTrigger className="h-7 w-fit gap-2 bg-white px-2">
                  <ClockIcon className="h-4 w-4 text-gray-700" />
                  <SelectValue>
                    {estimate.value === 0
                      ? "Not sure"
                      : estimate.value +
                        " " +
                        (estimate.unit === "minute" ? "min" : "hr")}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value={"0 hour"}>Not sure</SelectItem>
                    {estimateOptions.map((estimate) => (
                      <SelectItem
                        key={estimate.value + estimate.unit}
                        value={estimate.value + " " + estimate.unit}
                      >
                        {estimate.value +
                          " " +
                          (estimate.unit === "minute" ? "min" : "hr")}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </NewSelect> */}
          </div>
          <div className="flex justify-end space-x-2">
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                handleAddSubtask(false);
              }}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              onClick={() => {
                addSubtask();
              }}
              disabled={title.trim() === "" || !tasker}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function SubtasksView({ taskTemplate }: { taskTemplate: TaskTemplateData }) {
  const { subTaskTemplates } = useGetSubTaskTemplates(
    new ObjectID(taskTemplate._id)
  );
  const updateTaskTemplateMutation = useUpdateTaskTemplate();
  const handleDragEnd = (event: DragEndEvent) => {
    const { over, active } = event;
    if (over && active) {
      const { id: overId } = over;
      const { id: activeId } = active;
      if (overId !== activeId && taskTemplate.subTaskTemplateOrder) {
        const oldIndex = taskTemplate.subTaskTemplateOrder.findIndex(
          (tid) => tid?.toString() === activeId
        );
        const newIndex = taskTemplate.subTaskTemplateOrder.findIndex(
          (tid) => tid?.toString() === overId
        );
        const newSubTaskTemplateOrder = arrayMove(
          taskTemplate.subTaskTemplateOrder,
          oldIndex,
          newIndex
        );
        updateTaskTemplateMutation.mutate({
          journeyTemplateId: taskTemplate.journeyTemplate._id,
          journeyStageTemplateId: taskTemplate.journeyStageTemplate._id,
          params: {
            _id: taskTemplate._id,
            subTaskTemplateOrder: newSubTaskTemplateOrder,
          },
        });
      }
    }
  };

  return (
    <div className="pt-3 pb-1">
      <div className="px-3 text-sm text-gray-400">{`Subtasks`}</div>
      {/* <BoltIcon className="h-4 w-4 text-gray-400" />
                <div className="text-sm text-gray-400">
                  {newTaskTemplateCtx.state.subtasks.length}
                </div> */}

      {taskTemplate.subTaskTemplateOrder && (
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={taskTemplate.subTaskTemplateOrder?.map((t) => t.toString())}
            strategy={verticalListSortingStrategy}
          >
            {taskTemplate.subTaskTemplateOrder.map((t) => {
              const subtaskTemplate = subTaskTemplates?.find(
                (tt) => tt._id?.toString() === t.toString()
              );
              if (subtaskTemplate) {
                return (
                  <SubtaskTemplate
                    subtaskTemplate={subtaskTemplate}
                    subtaskTemplateId={String(subtaskTemplate._id?.toString())}
                  />
                  // <TaskTemplate
                  //   key={subTaskTemplate._id?.toString() ?? Math.random()}
                  //   taskTemplate={subTaskTemplate}
                  //   isSubTask={true}
                  //   taskTemplateId={
                  //     subTaskTemplate._id?.toString() ??
                  //     Math.random().toString()
                  //   }
                  // />
                );
              }
              return null;
            })}

            {/* {isExpanded && (
          <div className="">
            {subTaskTemplates?.map((st) => (
              <TaskTemplate
                key={st._id?.toString() ?? Math.random()}
                taskTemplate={st}
                isSubTask={true}
                taskTemplateId={st._id?.toString() ?? Math.random().toString()}
              />
            ))}
          </div>
        )} */}
          </SortableContext>
        </DndContext>
      )}
    </div>
  );
}

function SubtaskTemplate({
  subtaskTemplate,
  subtaskTemplateId,
}: {
  subtaskTemplate: TaskTemplateData;
  subtaskTemplateId: string;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: subtaskTemplateId,
    });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };
  const taskTemplatePanelCtx = useTaskTemplatePanelContext();

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      className="group relative flex cursor-pointer items-center justify-between gap-2 px-3 py-1 pl-3 hover:bg-gray-200/70"
      onClick={() => {
        // navigate
        taskTemplatePanelCtx.dispatch({
          type: "TASK_TEMPLATE_PANEL_UPDATE",
          taskTemplatePanel: {
            id: subtaskTemplateId,
            isOpen: true,
          },
        });
      }}
      tabIndex={-1}
    >
      <DragHandleDots2Icon
        className="absolute right-full h-5 w-5 cursor-move text-transparent transition-colors group-hover:text-gray-300"
        {...listeners}
      />
      <div className="flex items-center gap-1">
        <div className="truncate">{subtaskTemplate.title}</div>
      </div>
      <div className="flex items-start gap-2">
        <div className="flex gap-1">
          <div className="inline-flex h-7 items-center gap-1 whitespace-nowrap rounded-md border border-gray-300 bg-gray-50 px-1.5 pr-2 text-sm">
            <UserIcon className="h-3.5 w-3.5 text-gray-700" />
            <div
              className={clsx(
                "mx-0.5 h-1.5 w-1.5 rounded-full",
                subtaskTemplate.tasker?.type === "internal"
                  ? "bg-gray-300"
                  : "bg-slate-700"
              )}
            />
            <span className="text-xs text-gray-700">
              {subtaskTemplate.tasker?.persona}
            </span>
          </div>
          <div className="inline-flex h-7 items-center gap-1 whitespace-nowrap rounded-md border border-gray-300 bg-gray-50 px-1.5 text-sm">
            <StopwatchIcon className="h-3.5 w-3.5 text-gray-700" />
            <span className="text-xs text-gray-700">
              {subtaskTemplate.estimate.value +
                " " +
                (subtaskTemplate.estimate.unit === "minute" ? "min" : "hr")}
            </span>
          </div>
          <div className="inline-flex h-7 w-7 items-center justify-center rounded-md border border-gray-300 bg-gray-50">
            {subtaskTemplate.isVisible ? (
              <EyeIcon className="h-4 w-4 text-gray-600" />
            ) : (
              <EyeSlashIcon className="h-4 w-4 text-gray-600" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function MeetingAction({
  setShowMeeting,
}: {
  setShowMeeting: (value: boolean) => void;
}) {
  return (
    <div>
      <Action
        icon={<PhoneIcon className="h-5 w-5 text-gray-400" />}
        onClick={() => {
          setShowMeeting(true);
        }}
        text="Meeting"
        // disabled={!!taskTemplate.subTaskTemplateCount || addSubtasks}
        // active={taskTemplate.action === "form" || showFormTemplate}
      />
    </div>
  );
}

function FileUploadAction({
  setShowFileUpload,
  taskTemplate,
}: {
  setShowFileUpload: (value: boolean) => void;
  taskTemplate: TaskTemplateData;
}) {
  const updateTaskTemplateMutation = useUpdateTaskTemplate();
  return (
    <div>
      <Action
        icon={<CloudArrowUpIcon className="h-5 w-5 text-gray-400" />}
        onClick={() => {
          setShowFileUpload(true);
          updateTaskTemplateMutation.mutate({
            journeyTemplateId: taskTemplate.journeyTemplate._id,
            journeyStageTemplateId: taskTemplate.journeyStageTemplate._id,
            params: {
              _id: taskTemplate._id,
              action: "fileUpload",
            },
          });
        }}
        text="File Upload"
        // disabled={!!taskTemplate.subTaskTemplateCount || addSubtasks}
        // active={taskTemplate.action === "form" || showFormTemplate}
      />
    </div>
  );
}

function SubtasksAction({
  setAddSubtasks,
}: {
  setAddSubtasks: (value: boolean) => void;
}) {
  return (
    <div>
      <Action
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            className="text-gray-400"
          >
            <path
              fill="currentColor"
              d="M17 16a2.99 2.99 0 0 0-2.816 2H11c-1.654 0-3-1.346-3-3v-3.025A4.954 4.954 0 0 0 11 13h3.184A2.99 2.99 0 0 0 17 15a3 3 0 1 0 0-6a2.99 2.99 0 0 0-2.816 2H11c-1.654 0-3-1.346-3-3v-.184A2.99 2.99 0 0 0 10 5a3 3 0 1 0-6 0a2.99 2.99 0 0 0 2 2.816V15c0 2.757 2.243 5 5 5h3.184A2.99 2.99 0 0 0 17 22a3 3 0 1 0 0-6zm0-5a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zM7 4a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zm10 16a1.001 1.001 0 0 1 0-2a1.001 1.001 0 0 1 0 2z"
              // stroke="currentColor"
              strokeWidth={0}
            />
          </svg>
        }
        onClick={() => {
          setAddSubtasks(true);
        }}
        text="Subtasks"
        // disabled={!!taskTemplate.subTaskTemplateCount || addSubtasks}
        // active={taskTemplate.action === "form" || showFormTemplate}
      />
    </div>
  );
}

function FormAction({
  taskTemplate,
  setShowFormTemplate,
  showFormTemplate,
  addSubtasks,
}: {
  taskTemplate: TaskTemplateData;
  setShowFormTemplate: (show: boolean) => void;
  showFormTemplate: boolean;
  addSubtasks: boolean;
}) {
  return (
    <>
      <Action
        icon={<DocumentTextIcon className="h-5 w-5 text-gray-400" />}
        onClick={() => {
          if (taskTemplate.action === "form") {
            // setOpenRemoveFormConfirmation(true);
          } else if (showFormTemplate) {
            setShowFormTemplate(false);
          } else {
            setShowFormTemplate(true);
          }
        }}
        text="Form"
        disabled={!!taskTemplate.subTaskTemplateCount || addSubtasks}
        active={taskTemplate.action === "form" || showFormTemplate}
      />
    </>
  );
}

function FormTemplateCombobox({
  taskTemplate,
}: {
  taskTemplate: TaskTemplateData;
}) {
  const updateTaskTemplateMutation = useUpdateTaskTemplate();
  const { data: formTemplates } = useGetFormTemplates();
  const [open, setOpen] = useState(false);
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="secondary"
          role="combobox"
          aria-expanded={open}
          className="space-x-2 truncate"
        >
          <DocumentTextIcon className="h-4 w-4 shrink-0" />
          {taskTemplate.formTemplate ? (
            <div className="truncate">
              {
                formTemplates?.find(
                  (ft) => ft._id === taskTemplate.formTemplate?._id.toString()
                )?.title
              }
            </div>
          ) : (
            <div className="flex space-x-1 text-gray-400/80">
              Form Template
              <span className="top-0 ml-0.5 text-primary-main">*</span>
            </div>
          )}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
        align="start"
      >
        <Command>
          <CommandInput placeholder="Search form template..." className="h-9" />
          <CommandEmpty>No form template found.</CommandEmpty>
          <CommandGroup>
            {formTemplates?.map((formTemplate) => (
              <CommandItem
                key={formTemplate._id}
                value={formTemplate.title}
                onSelect={() => {
                  if (
                    taskTemplate.formTemplate?._id.toString() !==
                    formTemplate._id.toString()
                  ) {
                    updateTaskTemplateMutation.mutate({
                      journeyTemplateId: taskTemplate.journeyTemplate._id,
                      journeyStageTemplateId:
                        taskTemplate.journeyStageTemplate._id,
                      params: {
                        _id: taskTemplate._id,
                        action: "form",
                        formTemplateId: new ObjectID(formTemplate._id),
                      },
                    });
                  }
                  setOpen(false);
                }}
                className="gap-2"
              >
                <span className="truncate">{formTemplate.title}</span>
                <CheckIcon
                  className={cn(
                    "ml-auto h-4 w-4 shrink-0",
                    taskTemplate.formTemplate?._id.toString() ===
                      formTemplate._id.toString()
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function Title({ taskTemplate }: { taskTemplate: TaskTemplateData }) {
  const updateTaskTemplateMutation = useUpdateTaskTemplate();
  const [title, setTitle] = useState(taskTemplate.title);

  useEffect(() => {
    setTitle(taskTemplate.title);
  }, [taskTemplate.title]);

  return (
    <ReusableTitle
      title={title}
      onChange={(e) => {
        setTitle(e.target.value);
      }}
      onBlur={(e) => {
        if (title.trim() !== taskTemplate.title) {
          updateTaskTemplateMutation.mutate({
            journeyTemplateId: taskTemplate.journeyTemplate._id,
            journeyStageTemplateId: taskTemplate.journeyStageTemplate._id,
            params: {
              _id: taskTemplate._id,
              title: title,
            },
            parentTaskTemplateId: taskTemplate.parentTaskTemplate?._id,
          });
        }
      }}
    />
  );
}

function SubtasksButton({
  taskTemplate,
  setAddSubtasks,
  addSubtasks,
}: {
  taskTemplate: TaskTemplateData;
  setAddSubtasks: (show: boolean) => void;
  addSubtasks: boolean;
}) {
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  return (
    <>
      <Button
        onClick={() => {
          setAddSubtasks(true);
        }}
        disabled={taskTemplate.action !== "default"}
        variant={"secondary"}
        className="space-x-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M17 16a2.99 2.99 0 0 0-2.816 2H11c-1.654 0-3-1.346-3-3v-3.025A4.954 4.954 0 0 0 11 13h3.184A2.99 2.99 0 0 0 17 15a3 3 0 1 0 0-6a2.99 2.99 0 0 0-2.816 2H11c-1.654 0-3-1.346-3-3v-.184A2.99 2.99 0 0 0 10 5a3 3 0 1 0-6 0a2.99 2.99 0 0 0 2 2.816V15c0 2.757 2.243 5 5 5h3.184A2.99 2.99 0 0 0 17 22a3 3 0 1 0 0-6zm0-5a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zM7 4a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zm10 16a1.001 1.001 0 0 1 0-2a1.001 1.001 0 0 1 0 2z"
            // stroke="currentColor"
            strokeWidth={0}
          />
        </svg>
        <span>{"Add Subtasks"}</span>
      </Button>
      {/* <Button
        onClick={() => {
          if (!!taskTemplate.subTaskTemplateCount) {
            // confirm delete of all subtasks
            setOpenDeleteConfirmation(true);
          } else if (addSubtasks) {
            setAddSubtasks(false);
          } else {
            setAddSubtasks(true);
          }
        }}
        disabled={taskTemplate.action !== "default"}
        variant={"secondary"}
      >
        {!!taskTemplate.subTaskTemplateCount || addSubtasks
          ? "Delete Subtasks"
          : "Add Subtasks"}
      </Button>
      <Dialog
        open={openDeleteConfirmation}
        onOpenChange={(open) => {
          setOpenDeleteConfirmation(open);
        }}
      >
        <DialogContent
        // onOpenAutoFocus={(e) => {
        //   e.preventDefault();
        // }}
        >
          <DialogHeader>
            <DialogTitle>Delete Subtasks</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-2">
            <p>Are you sure you want to delete all the subtasks?</p>
          </div>
          <DialogFooter>
            <Button
              onClick={() => {
                setOpenDeleteConfirmation(false);
              }}
              variant={"secondary"}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // delete all subtasks
              }}
              variant={"destructive"}
            >
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog> */}
    </>
  );
}

function Visibility({ taskTemplate }: { taskTemplate: TaskTemplateData }) {
  const updateTaskTemplateMutation = useUpdateTaskTemplate();
  return (
    <div className="inline-flex h-8 items-center gap-1 rounded-md border border-gray-300 px-1 shadow-sm">
      <TooltipProvider>
        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>
            <button
              className={clsx(
                "rounded p-1 outline-none focus-visible:ring-[1.25px] focus-visible:ring-primary",
                taskTemplate.isVisible
                  ? "bg-primary-100 text-primary-600"
                  : "text-gray-500 hover:bg-gray-100"
              )}
              onClick={() => {
                updateTaskTemplateMutation.mutate({
                  journeyTemplateId: taskTemplate.journeyTemplate._id,
                  params: {
                    _id: taskTemplate._id,
                    isVisible: true,
                  },
                  journeyStageTemplateId:
                    taskTemplate.journeyStageTemplate?._id,
                  parentTaskTemplateId: taskTemplate.parentTaskTemplate?._id,
                });
              }}
            >
              <EyeIcon className="h-4 w-4" />
            </button>
          </TooltipTrigger>
          <TooltipContent sideOffset={8}>
            <p>Externally visible</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <TooltipProvider>
        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>
            <button
              className={clsx(
                "rounded p-1 outline-none focus-visible:ring-[1.25px] focus-visible:ring-primary",
                !taskTemplate.isVisible
                  ? "bg-primary-100 text-primary-600"
                  : "text-gray-500 hover:bg-gray-100",
                taskTemplate.tasker?.type == "external" &&
                  "bg-gray-100 text-gray-300 hover:bg-gray-100"
              )}
              onClick={() => {
                updateTaskTemplateMutation.mutate({
                  journeyTemplateId: taskTemplate.journeyTemplate._id,
                  params: {
                    _id: taskTemplate._id,
                    isVisible: false,
                  },
                  journeyStageTemplateId:
                    taskTemplate.journeyStageTemplate?._id,
                  parentTaskTemplateId: taskTemplate.parentTaskTemplate?._id,
                });
              }}
              disabled={taskTemplate.tasker?.type == "external"}
            >
              <EyeSlashIcon
                className={`h-4 w-4 ${
                  taskTemplate.tasker?.type === "external"
                    ? "text-gray-300"
                    : ""
                }`}
              />
            </button>
          </TooltipTrigger>
          <TooltipContent sideOffset={8}>
            <p>Externally hidden</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
}
function DueDateDependency({
  taskTemplate,
}: {
  taskTemplate: TaskTemplateData;
}) {
  const updateTaskTemplateMutation = useUpdateTaskTemplate();
  const [launchCondition, setLaunchCondition] =
    useState<TaskTemplateLaunchCondition>(
      taskTemplate.dependentOn.type === "default"
        ? "project start"
        : taskTemplate.dependentOn.type === "task"
        ? "completion of task"
        : "completion of stage"
    );
  const [value, setValue] = useState(
    String(taskTemplate.relativeDueDate.value)
  );
  const [taskTemplatesComboboxOpen, setTaskTemplatesComboboxOpen] =
    useState(false);
  const [stageTemplatesComboboxOpen, setStageTemplatesComboboxOpen] =
    useState(false);
  const { stageAndTaskTemplateList } = useGetStageAndTaskTemplatesList(
    taskTemplate.journeyTemplate._id
  );

  useEffect(() => {
    setValue(String(taskTemplate.relativeDueDate.value));
  }, [taskTemplate.relativeDueDate.value]);

  return (
    <div className="flex items-center gap-2 text-sm text-gray-600">
      <span className="-mr-0.5 text-gray-500">Due</span>
      <div>
        <TextInput
          value={value}
          className="h-8 w-[70px]"
          onChange={(e) => {
            const rx = new RegExp(/^\d+$/);
            if (rx.test(e.target.value) || e.target.value === "") {
              setValue(e.target.value);
            }
          }}
          onBlur={(e) => {
            if (value === "") {
              updateTaskTemplateMutation.mutate({
                params: {
                  _id: taskTemplate._id,
                  relativeDueDate: {
                    ...taskTemplate.relativeDueDate,
                    value: 1,
                  },
                },
                journeyStageTemplateId: taskTemplate.journeyStageTemplate._id,
                journeyTemplateId: taskTemplate.journeyTemplate._id,
              });
            } else {
              updateTaskTemplateMutation.mutate({
                params: {
                  _id: taskTemplate._id,
                  relativeDueDate: {
                    ...taskTemplate.relativeDueDate,
                    value: parseInt(e.target.value),
                  },
                },
                journeyStageTemplateId: taskTemplate.journeyStageTemplate._id,
                journeyTemplateId: taskTemplate.journeyTemplate._id,
              });
            }
          }}
        />
      </div>
      <div>
        <NewSelect
          value={taskTemplate.relativeDueDate.unit}
          onValueChange={(value) => {
            updateTaskTemplateMutation.mutate({
              params: {
                _id: taskTemplate._id,
                relativeDueDate: {
                  ...taskTemplate.relativeDueDate,
                  unit: value as (typeof relativeDueDateUnits)[number],
                },
              },
              journeyStageTemplateId: taskTemplate.journeyStageTemplate._id,
              journeyTemplateId: taskTemplate.journeyTemplate._id,
            });
          }}
        >
          <SelectTrigger className="w-fit gap-2">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {relativeDueDateUnits
                .filter(
                  (unit) =>
                    unit !== "minute" && unit !== "month" && unit !== "quarter"
                )
                .map((unit) => (
                  <SelectItem value={unit}>{unit + "s"}</SelectItem>
                ))}
            </SelectGroup>
          </SelectContent>
        </NewSelect>
      </div>
      <span className="-mx-0.5 text-gray-500">after</span>
      <NewSelect
        value={launchCondition}
        onValueChange={(value) => {
          if (value === "project start") {
            console.log(taskTemplate.dependentOn);
            setLaunchCondition("project start");
            updateTaskTemplateMutation.mutate({
              journeyTemplateId: taskTemplate.journeyTemplate._id,
              params: {
                _id: taskTemplate._id,
                dependentOn: {
                  type: "default",
                  ...(!!taskTemplate.dependentOn.dependencyTemplateId
                    ? {
                        dependencyTemplateId:
                          taskTemplate.dependentOn.dependencyTemplateId,
                      }
                    : {}),
                },
              },
              journeyStageTemplateId: taskTemplate.journeyStageTemplate._id,
            });
          } else {
            setLaunchCondition(value as TaskTemplateLaunchCondition);
          }
        }}
      >
        <SelectTrigger className="w-fit gap-2">
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {taskTemplateLaunchConditions.map((condition) => (
              <SelectItem value={condition}>{condition}</SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </NewSelect>
      {launchCondition === "completion of task" && (
        <Popover
          open={taskTemplatesComboboxOpen}
          onOpenChange={setTaskTemplatesComboboxOpen}
        >
          <PopoverTrigger asChild>
            <Button
              variant="secondary"
              role="combobox"
              aria-expanded={taskTemplatesComboboxOpen}
              className="max-w-[200px] space-x-2 truncate"
            >
              <BoltIcon className="h-4 w-4 shrink-0" />
              {taskTemplate.dependentOn.type === "task" &&
              taskTemplate.dependentOn.name ? (
                <div className="truncate">{taskTemplate.dependentOn.name}</div>
              ) : (
                <div className="flex space-x-1 text-gray-400/80">
                  Task
                  <span className="top-0 ml-0.5 text-primary-main">*</span>
                </div>
              )}
              <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent
            className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
            align="end"
          >
            <Command>
              <CommandInput placeholder="Search task..." className="h-9" />
              <CommandEmpty>No task found.</CommandEmpty>
              <CommandGroup>
                {stageAndTaskTemplateList.taskTemplates.map((tt) => (
                  <TooltipProvider delayDuration={1200}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <CommandItem
                          key={tt._id.toString()}
                          value={tt.name}
                          onSelect={() => {
                            updateTaskTemplateMutation.mutate({
                              params: {
                                _id: taskTemplate._id,
                                dependentOn: {
                                  type: "task",
                                  _id: tt._id,
                                  ...(!!taskTemplate.dependentOn
                                    .dependencyTemplateId
                                    ? {
                                        dependencyTemplateId:
                                          taskTemplate.dependentOn
                                            .dependencyTemplateId,
                                      }
                                    : {}),
                                },
                              },
                              journeyStageTemplateId:
                                taskTemplate.journeyStageTemplate._id,
                              journeyTemplateId:
                                taskTemplate.journeyTemplate._id,
                            });
                            setTaskTemplatesComboboxOpen(false);
                          }}
                          className="gap-2"
                        >
                          <span className="truncate">{tt.name}</span>
                          <CheckIcon
                            className={cn(
                              "ml-auto h-4 w-4 shrink-0",
                              taskTemplate.dependentOn?._id?.toString() ===
                                tt._id.toString()
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                          />
                        </CommandItem>
                      </TooltipTrigger>
                      <TooltipContent align="start" side="left" sideOffset={12}>
                        <p>{tt.journeyStageTemplate.name}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ))}
              </CommandGroup>
            </Command>
          </PopoverContent>
        </Popover>
      )}
      {launchCondition === "completion of stage" && (
        <Popover
          open={stageTemplatesComboboxOpen}
          onOpenChange={setStageTemplatesComboboxOpen}
        >
          <PopoverTrigger asChild>
            <Button
              variant="secondary"
              role="combobox"
              aria-expanded={stageTemplatesComboboxOpen}
              className="max-w-[200px] space-x-2 truncate"
            >
              <FlagIcon className="h-4 w-4 shrink-0" />
              {taskTemplate.dependentOn.type === "stage" &&
              taskTemplate.dependentOn.name ? (
                <div className="truncate">{taskTemplate.dependentOn.name}</div>
              ) : (
                <div className="flex space-x-1 text-gray-400/80">
                  Stage
                  <span className="top-0 ml-0.5 text-primary-main">*</span>
                </div>
              )}
              <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent
            className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
            align="end"
          >
            <Command>
              <CommandInput placeholder="Search stage..." className="h-9" />
              <CommandEmpty>No stage found.</CommandEmpty>
              <CommandGroup>
                {stageAndTaskTemplateList.stageTemplates
                  .filter(
                    (s) =>
                      s._id.toString() !==
                      taskTemplate.journeyStageTemplate?._id?.toString()
                  )
                  .map((st) => (
                    <CommandItem
                      key={st._id.toString()}
                      value={st.name}
                      onSelect={() => {
                        updateTaskTemplateMutation.mutate({
                          params: {
                            _id: taskTemplate._id,
                            dependentOn: {
                              type: "stage",
                              _id: st._id,
                              ...(!!taskTemplate.dependentOn
                                .dependencyTemplateId
                                ? {
                                    dependencyTemplateId:
                                      taskTemplate.dependentOn
                                        .dependencyTemplateId,
                                  }
                                : {}),
                            },
                          },
                          journeyStageTemplateId:
                            taskTemplate.journeyStageTemplate._id,
                          journeyTemplateId: taskTemplate.journeyTemplate._id,
                        });
                        setStageTemplatesComboboxOpen(false);
                      }}
                      className="gap-2"
                    >
                      <span className="truncate">{st.name}</span>
                      <CheckIcon
                        className={cn(
                          "ml-auto h-4 w-4 shrink-0",
                          taskTemplate.dependentOn?._id?.toString() ===
                            st._id.toString()
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                    </CommandItem>
                  ))}
              </CommandGroup>
            </Command>
          </PopoverContent>
        </Popover>
      )}
    </div>
  );
}

function DurationSelect({ taskTemplate }: { taskTemplate: TaskTemplateData }) {
  const updateTaskTemplateMutation = useUpdateTaskTemplate();
  return (
    <NewSelect
      value={taskTemplate.estimate.value + " " + taskTemplate.estimate.unit}
      onValueChange={(value) => {
        const e = estimateOptions.find(
          (estimate) => estimate.value + " " + estimate.unit === value
        );
        if (e) {
          updateTaskTemplateMutation.mutate({
            params: {
              _id: taskTemplate._id,
              estimate: e,
            },
            journeyStageTemplateId: taskTemplate.journeyStageTemplate._id,
            journeyTemplateId: taskTemplate.journeyTemplate._id,
            parentTaskTemplateId: taskTemplate.parentTaskTemplate?._id,
          });
        }
      }}
    >
      <SelectTrigger className="w-fit gap-2">
        <StopwatchIcon className="h-4 w-4 text-gray-700" />
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {/* <SelectLabel>Fruits</SelectLabel> */}
          {estimateOptions.map((estimate) => (
            <SelectItem value={estimate.value + " " + estimate.unit}>
              {estimate.value +
                " " +
                (estimate.unit === "minute" ? "min" : "hr")}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </NewSelect>
  );
}

function SubtasksDurationSum({
  taskTemplate,
}: {
  taskTemplate: TaskTemplateData;
}) {
  const { subTaskTemplates } = useGetSubTaskTemplates(
    new ObjectID(taskTemplate._id)
  );
  const totalDuration = (subTaskTemplates ?? []).reduce((acc, task) => {
    if (task.estimate) {
      return acc + estimateToMinutes(task.estimate);
    }
    return acc;
  }, 0);
  // console.log(totalDuration, tasks[0].status);
  const hours = Math.floor(totalDuration / 60);
  const minutes = totalDuration % 60;

  if (totalDuration > 0) {
    if (minutes === 0) {
      return (
        <div className="flex h-8 items-center space-x-2 rounded-md border border-gray-300 bg-gray-100 px-2 text-sm">
          <StopwatchIcon className="h-4 w-4 text-gray-700" />
          <span>{`${hours} hr`}</span>
        </div>
      );
    } else {
      if (hours === 0) {
        return (
          <div className="flex h-8 items-center space-x-2 rounded-md border border-gray-300 bg-gray-100 px-2 text-sm">
            <StopwatchIcon className="h-4 w-4 text-gray-700" />
            <span>{`${minutes} min`}</span>
          </div>
        );
      }
      return (
        <div className="flex h-8 items-center space-x-2 rounded-md border border-gray-300 bg-gray-100 px-2 text-sm">
          <StopwatchIcon className="h-4 w-4 text-gray-700" />
          <span>{`${hours} hr, ${minutes} min`}</span>
        </div>
      );
    }
  } else {
    return (
      <div className="flex h-8 items-center space-x-2 rounded-md border border-gray-300 bg-gray-100 px-2 text-sm">
        <StopwatchIcon className="h-4 w-4 text-gray-700" />
        <span>{`0 hr`}</span>
      </div>
    );
  }
}

function StageCombobox({ taskTemplate }: { taskTemplate: TaskTemplateData }) {
  const { stageAndTaskTemplateList } = useGetStageAndTaskTemplatesList(
    taskTemplate.journeyTemplate._id
  );
  const updateTaskTemplateMutation = useUpdateTaskTemplate();
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="secondary"
          role="combobox"
          aria-expanded={open}
          className="max-w-[200px] space-x-2 truncate"
        >
          <FlagIcon className="h-4 w-4 shrink-0" />
          {taskTemplate.journeyStageTemplate ? (
            <div className="truncate">
              {taskTemplate.journeyStageTemplate.name}
            </div>
          ) : (
            <div className="text-gray-400/80">Stage</div>
          )}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
        align="start"
      >
        <Command>
          <CommandInput placeholder="Search stage..." className="h-9" />
          <CommandEmpty>No stage found.</CommandEmpty>
          <CommandGroup>
            {stageAndTaskTemplateList.stageTemplates.map((stageTemplate) => (
              <CommandItem
                key={stageTemplate._id.toString()}
                value={stageTemplate.name}
                onSelect={() => {
                  let params: Partial<TaskTemplateUpdateRequest> = {
                    _id: taskTemplate._id,
                    journeyStageTemplateId: stageTemplate._id,
                  };
                  if (
                    taskTemplate.journeyStageTemplate?._id?.toString() ===
                    stageTemplate._id?.toString()
                  ) {
                    params["dependentOn"] = {
                      name: "Project Start",
                      type: "default",
                    };
                  }

                  updateTaskTemplateMutation.mutate({
                    journeyTemplateId: taskTemplate.journeyTemplate._id,
                    previousJourneyStageId:
                      taskTemplate.journeyStageTemplate._id,
                    journeyStageTemplateId: stageTemplate._id,
                    params,
                  });
                  setOpen(false);
                }}
                className="gap-2"
              >
                <span className="truncate">{stageTemplate.name}</span>
                <CheckIcon
                  className={cn(
                    "ml-auto h-4 w-4 shrink-0",
                    taskTemplate.journeyStageTemplate?._id.toString() ===
                      stageTemplate._id.toString()
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function Options({
  taskTemplate,
}: {
  taskTemplate: TaskTemplateData | undefined;
}) {
  const deleteTaskTemplateConfirmationMutation =
    useDeleteTaskTemplateConfirmation();
  const deleteTaskTemplateMutation = useDeleteTaskTemplate();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  // const updateTaskMutation = useUpdateTask();
  const taskTemplatePanelCtx = useTaskTemplatePanelContext();

  const [deletions, setDeletions] = useState<
    { _id: ObjectID; title: string }[]
  >([]);
  const [dependencies, setDependencies] = useState<
    { _id: ObjectID; title: string }[]
  >([]);

  useEffect(() => {
    if (deleteTaskTemplateConfirmationMutation.data) {
      setDeletions(deleteTaskTemplateConfirmationMutation.data.deleted);
      setDependencies(deleteTaskTemplateConfirmationMutation.data.dependencies);
      setIsConfirmationModalOpen(true);
    }
  }, [deleteTaskTemplateConfirmationMutation.data]);

  return (
    <div>
      <Menu
        side="right"
        items={[
          {
            text: "Delete",
            onClick: () => {
              if (taskTemplate?._id) {
                deleteTaskTemplateConfirmationMutation.mutate({
                  params: {
                    _id: taskTemplate?._id,
                    force: false,
                  },
                  journeyStageTemplateId: taskTemplate.journeyStageTemplate._id,
                  journeyTemplateId: taskTemplate.journeyTemplate._id,
                });
              }
            },
            icon: TrashIcon,
            disabled: !taskTemplate,
          },
        ]}
      >
        <div className="group inline-flex h-[30px] w-[30px] items-center justify-center rounded-md border border-gray-300 hover:border-gray-400 hover:bg-gray-50">
          <EllipsisVerticalIcon className="h-5 w-5 text-gray-400" />
        </div>
      </Menu>
      <Dialog
        open={isConfirmationModalOpen}
        onOpenChange={(open) => {
          setIsConfirmationModalOpen(open);
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Confirmation</DialogTitle>
            {/* <DialogDescription>
              Confirm deletion of stage template
            </DialogDescription> */}
          </DialogHeader>
          <div className="space-y-5 pt-3 text-sm text-gray-600">
            <div>
              Are you sure you want to delete task template{" "}
              <span className="font-medium text-gray-900">
                {taskTemplate?.title}
              </span>
              ?
            </div>
            {deletions.length > 0 && (
              <div className="space-y-1">
                <div>
                  <span>The following tasks will also be deleted -</span>
                </div>
                <ul className="list-disc space-y-1 pl-6">
                  {deletions.map((d) => (
                    <li key={d._id.toString()}>{d.title}</li>
                  ))}
                </ul>
              </div>
            )}
            {dependencies.length > 0 && (
              <div className="space-y-1">
                <div>
                  <span>The following tasks that used to be dependent on </span>
                  <span className="font-medium text-gray-900">
                    {taskTemplate?.title}
                  </span>{" "}
                  <span>will now be dependent on </span>
                  <span className="font-medium text-gray-900">
                    {"Project Start -"}
                  </span>
                </div>
                <ul className="list-disc space-y-1 pl-6">
                  {dependencies.map((d) => (
                    <li key={d._id.toString()}>{d.title}</li>
                  ))}
                </ul>
              </div>
            )}
            <div></div>
          </div>
          <DialogFooter>
            <DialogClose asChild className="">
              <div className="flex justify-end space-x-3">
                <Button
                  variant={"secondary"}
                  onClick={() => {
                    setIsConfirmationModalOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant={"destructive"}
                  onClick={() => {
                    if (taskTemplate?._id) {
                      deleteTaskTemplateMutation.mutate({
                        params: {
                          _id: taskTemplate?._id,
                          force: true,
                        },
                        journeyStageTemplateId:
                          taskTemplate.journeyStageTemplate._id,
                        journeyTemplateId: taskTemplate.journeyTemplate._id,
                        parentTaskTemplateId:
                          taskTemplate.parentTaskTemplate?._id,
                      });
                      taskTemplatePanelCtx.dispatch({
                        type: "TASK_TEMPLATE_PANEL_UPDATE",
                        taskTemplatePanel: {
                          id: undefined,
                          isOpen: false,
                        },
                      });
                    }
                    setIsConfirmationModalOpen(false);
                  }}
                >
                  Delete
                </Button>
              </div>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      {/* <Modal
        isOpen={isConfirmationModalOpen}
        closeModal={() => {
          setIsConfirmationModalOpen(false);
        }}
      >
        <div className="p-6">
          <ModalHeading
            title={"Delete Confirmation"}
            // subtitle="Are you sure you want to delete this task?"
          />
          <div className="mt-6 space-y-4">
            <div className="text-sm">
              {otherDependentTaskTemplates.length > 1 ? (
                <>
                  <span className="font-light text-gray-600">{`You cannot delete task `}</span>
                  <span className="font-medium">{`"${taskTemplate?.title}"`}</span>
                  <span className="font-light text-gray-600">{`. The following tasks are dependent on it -`}</span>
                </>
              ) : (
                <>
                  <span className="font-light text-gray-600">{`Are you sure you want to delete task `}</span>
                  <span className="font-medium">{`"${taskTemplate?.title}"`}</span>
                  <span className="font-light text-gray-600">{`?`}</span>
                </>
              )}
            </div>
            <div className="space-y-3">
              {otherDependentTaskTemplates.length > 1 && (
                <ul className="list-disc pl-4">
                  {otherDependentTaskTemplates
                    .filter(
                      (t) => t._id.toString() !== taskTemplate?._id?.toString()
                    )
                    .map((task, index) => {
                      return (
                        <li key={task._id.toString()}>
                          <div className="text-sm">{task.title}</div>
                        </li>
                      );
                    })}
                </ul>
              )}
            </div>
            <div className="flex justify-end space-x-2 pt-4">
              <Button
                onClick={() => {
                  setIsConfirmationModalOpen(false);
                }}
                variant={"secondary"}
              >
                {otherDependentTaskTemplates.length === 0 ? "Cancel" : "Close"}
              </Button>
              {otherDependentTaskTemplates.length === 0 && (
                <Button
                  onClick={() => {
                    if (taskTemplate?._id) {
                      deleteTaskTemplateMutation.mutate({
                        params: {
                          _id: taskTemplate?._id,
                          force: true,
                        },
                        journeyStageTemplateId:
                          taskTemplate.journeyStageTemplate._id,
                        journeyTemplateId: taskTemplate.journeyTemplate._id,
                        parentTaskTemplateId:
                          taskTemplate.parentTaskTemplate?._id,
                      });
                      taskTemplatePanelCtx.dispatch({
                        type: "TASK_TEMPLATE_PANEL_UPDATE",
                        taskTemplatePanel: {
                          id: undefined,
                          isOpen: false,
                        },
                      });
                    }
                    setIsConfirmationModalOpen(false);
                  }}
                  variant={"destructive"}
                >
                  Delete
                </Button>
              )}
            </div>
          </div>
        </div>
      </Modal> */}
    </div>
  );
}

function RoleCombobox({ taskTemplate }: { taskTemplate: TaskTemplateData }) {
  const { journeyTemplateId } = useParams();
  const [open, setOpen] = useState(false);
  const { journeyTemplate } = useGetJourneyTemplate(String(journeyTemplateId));
  const updateTaskTemplateMutation = useUpdateTaskTemplate();

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="secondary"
          role="combobox"
          aria-expanded={open}
          className="max-w-[200px] space-x-2 truncate"
        >
          <UserIcon className="h-4 w-4 shrink-0" />
          {taskTemplate.tasker && (
            <div
              className={clsx(
                "h-2 w-2 shrink-0 rounded-full bg-gray-300",
                taskTemplate.tasker?.type === "internal"
                  ? "bg-gray-300"
                  : "bg-slate-700"
              )}
            />
          )}
          {taskTemplate.tasker ? (
            <div className="truncate">{taskTemplate.tasker.persona}</div>
          ) : (
            <div className="flex space-x-1 text-gray-400/80">
              Role
              <span className="top-0 ml-0.5 text-primary-main">*</span>
            </div>
          )}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
        align="start"
      >
        <Command>
          <CommandInput placeholder="Search role..." className="h-9" />
          <CommandEmpty>No role found.</CommandEmpty>
          <CommandGroup>
            {journeyTemplate?.externalPersonas.map((externalPersona) => (
              <CommandItem
                key={externalPersona}
                value={externalPersona}
                onSelect={() => {
                  let params: Partial<TaskTemplateUpdateRequest> = {
                    _id: taskTemplate._id,
                    taskerPersona: externalPersona,
                    taskerType: "external",
                    isVisible: true,
                  };
                  updateTaskTemplateMutation.mutate({
                    journeyTemplateId: taskTemplate.journeyTemplate._id,
                    journeyStageTemplateId:
                      taskTemplate.journeyStageTemplate._id,
                    params,
                    parentTaskTemplateId: taskTemplate.parentTaskTemplate?._id,
                  });
                  setOpen(false);
                }}
                className="gap-2"
              >
                <div className="flex items-center space-x-2">
                  <div
                    className={"h-2 w-2 shrink-0 rounded-full bg-slate-700"}
                  />
                  <span className="truncate">{externalPersona}</span>
                </div>
                <CheckIcon
                  className={cn(
                    "ml-auto h-4 w-4 shrink-0",
                    taskTemplate.tasker?.persona === externalPersona &&
                      taskTemplate.tasker?.type === "external"
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
              </CommandItem>
            ))}
            {journeyTemplate?.internalPersonas.map((internalPersona) => (
              <CommandItem
                key={internalPersona}
                value={internalPersona}
                onSelect={() => {
                  let params: Partial<TaskTemplateUpdateRequest> = {
                    _id: taskTemplate._id,
                    taskerPersona: internalPersona,
                    taskerType: "internal",
                  };
                  updateTaskTemplateMutation.mutate({
                    journeyTemplateId: taskTemplate.journeyTemplate._id,
                    journeyStageTemplateId:
                      taskTemplate.journeyStageTemplate._id,
                    params,
                    parentTaskTemplateId: taskTemplate.parentTaskTemplate?._id,
                  });
                  setOpen(false);
                }}
                className="gap-2"
              >
                <div className="flex items-center space-x-2">
                  <div
                    className={"h-2 w-2 shrink-0 rounded-full bg-gray-300"}
                  />
                  <span className="truncate">{internalPersona}</span>
                </div>
                <CheckIcon
                  className={cn(
                    "ml-auto h-4 w-4 shrink-0",
                    taskTemplate.tasker?.persona === internalPersona &&
                      taskTemplate.tasker?.type === "internal"
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
