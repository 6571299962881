import { cva, VariantProps } from "class-variance-authority";

const badgeStyles = cva(
  "inline-flex max-w-full truncate cursor-default items-center text-[color:var(--textColor)]",
  {
    variants: {
      color: {
        gray: "[--color:theme(colors.gray.200)] [--textColor:theme(colors.gray.800)] [--hoverColor:theme(colors.gray.300)]",
        red: "[--color:theme(colors.red.100)] [--textColor:theme(colors.red.800)] [--hoverColor:theme(colors.red.200)]",
        yellow:
          "[--color:theme(colors.yellow.100)] [--textColor:theme(colors.yellow.800)] [--hoverColor:theme(colors.yellow.200)]",
        green:
          "[--color:theme(colors.green.100)] [--textColor:theme(colors.green.800)] [--hoverColor:theme(colors.green.200)]",
        blue: "[--color:theme(colors.blue.100)] [--textColor:theme(colors.blue.800)] [--hoverColor:theme(colors.blue.200)]",
        indigo:
          "[--color:theme(colors.indigo.100)] [--textColor:theme(colors.indigo.800)] [--hoverColor:theme(colors.indigo.200)]",
        purple:
          "[--color:theme(colors.purple.100)] [--textColor:theme(colors.purple.800)] [--hoverColor:theme(colors.purple.200)]",
        pink: "[--color:theme(colors.pink.100)] [--textColor:theme(colors.pink.800)] [--hoverColor:theme(colors.pink.200)]",
        primary:
          "[--color:theme(colors.primary.200)] [--textColor:theme(colors.primary.800)] [--hoverColor:theme(colors.primary.300)]",
        slate:
          "[--color:theme(colors.slate.700)] [--textColor:theme(colors.slate.200)] [--hoverColor:theme(colors.slate.200)]",
      },
      rounded: {
        true: "rounded-full",
        false: "rounded",
      },
      fill: {
        solid: "bg-[color:var(--color)]",
        outline:
          "border border-[color:var(--textColor)] bg-white hover:bg-[color:var(--color)]",
      },
      size: {
        sm: "px-2 py-0.5 text-xs",
        md: "px-2.5 py-1 text-xs",
        lg: "px-4 py-1.5 text-sm",
      },
      // clickable: {
      //   true: "cursor-pointer",
      //   false: "cursor-default",
      // },
    },
    defaultVariants: {
      rounded: true,
      size: "sm",
      fill: "solid",
      // clickable: false,
    },
    // compoundVariants: [
    //   {
    //     fill: "outline",
    //     clickable: true,
    //     className: "hover:bg-[color:var(--color)]",
    //   },
    //   {
    //     fill: "solid",
    //     clickable: true,
    //     className: "hover:bg-[color:var(--hoverColor)]",
    //   },
    // ],
  }
);

const buttonStyles = cva(
  "ml-2 -mr-1 truncate inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full focus:outline-none",
  {
    variants: {
      color: {
        gray: "hover:bg-gray-300 hover:text-gray-700 [--color:theme(colors.gray.100)] [--textColor:theme(colors.gray.800)] [--hoverColor:theme(colors.gray.200)]",
        red: "[--color:theme(colors.red.100)] [--textColor:theme(colors.red.800)] [--hoverColor:theme(colors.red.200)]",
        yellow:
          "[--color:theme(colors.yellow.100)] [--textColor:theme(colors.yellow.800)] [--hoverColor:theme(colors.yellow.200)]",
        green:
          "[--color:theme(colors.green.100)] [--textColor:theme(colors.green.800)] [--hoverColor:theme(colors.green.200)]",
        blue: "[--color:theme(colors.blue.100)] [--textColor:theme(colors.blue.800)] [--hoverColor:theme(colors.blue.200)]",
        indigo:
          "[--color:theme(colors.indigo.100)] [--textColor:theme(colors.indigo.800)] [--hoverColor:theme(colors.indigo.200)]",
        purple:
          "[--color:theme(colors.purple.100)] [--textColor:theme(colors.purple.800)] [--hoverColor:theme(colors.purple.200)]",
        pink: "[--color:theme(colors.pink.100)] [--textColor:theme(colors.pink.800)] [--hoverColor:theme(colors.pink.200)]",
        primary:
          "[--color:theme(colors.primary.200)] [--textColor:theme(colors.primary.800)] [--hoverColor:theme(colors.primary.300)]",
        slate:
          "hover:bg-slate-500 hover:text-slate-200 [--color:theme(colors.slate.700)] [--textColor:theme(colors.slate.200)] [--hoverColor:theme(colors.slate.200)]",
      },
    },
    defaultVariants: {
      color: "gray",
    },
  }
);

type BadgeProps = {
  onClick?: () => void;
  clickable?: boolean;
  text: string;
  Icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  onIconClick?: () => void;
};

export interface Props extends BadgeProps, VariantProps<typeof badgeStyles> {}

export const Badge = ({
  text,
  onClick,
  size,
  fill,
  rounded,
  clickable = false,
  color,
  Icon,
  onIconClick,
  ...props
}: Props) => {
  return (
    <div
      className={badgeStyles({ rounded, size, fill, color })}
      {...props}
      onClick={onClick}
    >
      <div className="truncate">{text}</div>
      {!!Icon && clickable && (
        <button
          type="button"
          className={buttonStyles({ color })}
          onClick={onIconClick}
        >
          <Icon className="h-3 w-3" />
        </button>
      )}
    </div>
  );
};
