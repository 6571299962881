import {
  BoltIcon,
  EllipsisVerticalIcon,
  EyeIcon,
  EyeSlashIcon,
  RocketLaunchIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  CalendarIcon,
  CaretSortIcon,
  CheckIcon,
  ChevronDownIcon,
  SquareIcon,
} from "@radix-ui/react-icons";
import clsx from "clsx";
import dayjs from "dayjs";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useParams } from "react-router-dom";
import {
  ParentStatus,
  StatusGroupData,
  useGetStatusGroups,
} from "src/api/General/status-groups";
import { relativeDueDateUnits } from "src/api/Services/Journeys/journeyTemplates";
import {
  ProjectData,
  useGetProjectMembers,
  useGetProjects,
} from "src/api/Services/Projects/projects";
import {
  TaskData,
  TaskerData,
  useAddTaskToProject,
  useCreateTask,
  useGetPhaseTasks,
  useGetTaskersForInstance,
} from "src/api/Services/Tasks/tasks";
import { SpaceData, useGetSpaces } from "src/api/Spaces/spaces";
import { SpaceSymbol } from "src/components/Layout/Sidebar";
import Description from "src/components/Services/Reusable/Tasks/Elements/Description";
import { Button } from "src/components/ui/button";
import { Calendar } from "src/components/ui/calendar";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "src/components/ui/command";
import { Dialog, DialogContent } from "src/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import { Loading } from "src/components/ui/loading";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
} from "src/components/ui/select";
import { Switch } from "src/components/ui/switch";
import { TextInput } from "src/components/ui/text-input";
import { SpaceNameAndSymbol } from "src/pages/spaces/spaces";
import { useRealmApp } from "src/store/RealmApp";
import { cn } from "src/utils/ui/ui";
import { getStatusItem, getStatusSymbol } from "./Task";

//// Dialog Context ////
// type NewTaskDialogAction = {
//   type: "open_update";
//   isOpen: boolean;
// };

// type NewTaskDialogState = {
//   isOpen: boolean;
// };

// const NewTaskDialogContext = React.createContext<
//   | { state: NewTaskDialogState; dispatch: React.Dispatch<NewTaskDialogAction> }
//   | undefined
// >(undefined);

// function newTaskDialogReducer(
//   state: NewTaskDialogState,
//   action: NewTaskDialogAction
// ) {
//   switch (action.type) {
//     case "open_update": {
//       return { ...state, isOpen: action.isOpen };
//     }
//     default: {
//       return state;
//     }
//   }
// }

// export function NewTaskDialogContextProvider({
//   children,
// }: {
//   children: React.ReactNode;
// }) {
//   const [state, dispatch] = React.useReducer(newTaskDialogReducer, {
//     isOpen: false,
//   });

//   const value = { state, dispatch };

//   return (
//     <NewTaskDialogContext.Provider value={value}>
//       {children}
//     </NewTaskDialogContext.Provider>
//   );
// }

// export function useNewTaskForInternalUserDialog() {
//   const context = useContext(NewTaskDialogContext);
//   if (context === undefined) {
//     throw new Error(
//       "useNewTaskForInternalUserDialog must be used within a NewTaskDialogProvider"
//     );
//   }
//   return context;
// }

//// End Dialog Context ////

type Action =
  | {
      type: "title_update";
      title: string;
    }
  | {
      type: "project_update";
      project: ProjectData | undefined;
    }
  | {
      type: "stage_update";
      stage: ProjectData["stages"][0] | undefined;
    }
  | {
      type: "status_update";
      status: Status;
    }
  | {
      type: "due_date_update";
      dueDate: string | undefined;
    }
  | {
      type: "relative_due_date_update";
      relativeDueDate: RelativeDueDate;
    }
  | {
      type: "dependent_on_update";
      dependentOn: DependentOn;
    }
  | {
      type: "adding_dependency_update";
      addingDependency: boolean;
    }
  | {
      type: "tasker_update";
      tasker: TaskerData | undefined;
    }
  | {
      type: "visibility_update";
      isVisible: boolean;
    }
  | {
      type: "adding_subtasks_update";
      addingSubtasks: boolean;
    }
  | {
      type: "subtasks_open_update";
      subtasksOpen: boolean;
    }
  | {
      type: "description_update";
      description: string;
    }
  | {
      type: "state_update";
      state: Partial<State>;
    }
  | {
      type: "open_update";
      projectId?: string;
      stageId?: string;
      spaceId?: string;
      taskerId?: string;
    }
  | {
      type: "status_group_update";
      statusGroup: StatusGroupData | undefined;
    }
  | {
      type: "projects_update";
      projects: ProjectData[];
    }
  | {
      type: "status_groups_update";
      statusGroups: StatusGroupData[];
    }
  | { type: "close_update" }
  | { type: "is_create_more_update"; isCreateMore: boolean }
  | { type: "space_update"; space: SpaceData }
  | { type: "spaces_update"; spaces: SpaceData[] };

type State = {
  isOpen: boolean;
  title: string;
  description: string;
  // spaces
  space: SpaceData | undefined;
  spaces: SpaceData[];
  // projects
  projects: ProjectData[];
  project: ProjectData | undefined;
  // status groups
  statusGroup: StatusGroupData | undefined;
  statusGroups: StatusGroupData[];

  status: Status;
  stage: ProjectData["stages"][0] | undefined;
  dueDate: string | undefined;
  relativeDueDate: RelativeDueDate;
  dependentOn: DependentOn;
  addingDependency: boolean;
  tasker: TaskerData | undefined;
  isVisible: boolean;
  addingSubtasks: boolean;
  subtasksOpen: boolean;
  isCreateMore: boolean;
};

type RelativeDueDate = {
  unit: (typeof relativeDueDateUnits)[number];
  value: number;
};

type DependentOn = {
  _id?: string;
  type: "task" | "stage";
};

type Status =
  | {
      status: ParentStatus;
      customStatus: string;
    }
  | undefined;

const NewTaskForInternalUserContext = React.createContext<
  | {
      state: State;
      dispatch: React.Dispatch<Action>;
    }
  | undefined
>(undefined);

function newTaskForInternalUserReducer(state: State, action: Action) {
  switch (action.type) {
    case "title_update":
      return { ...state, title: action.title };
    case "description_update":
      return { ...state, description: action.description };
    case "project_update":
      const newStatusGroup = state.statusGroups?.find((sg) =>
        action.project ? action.project?.statusGroupId === sg._id : !sg.isCustom
      );
      const newStatus = newStatusGroup?.statuses.find(
        (s) => s.parentStatus === "To-do"
      );

      return {
        ...state,
        project: action.project,
        stage: undefined,
        tasker: undefined,
        ...(!!newStatus
          ? {
              status: {
                status: newStatus.parentStatus,
                customStatus: newStatus.name,
              },
            }
          : {}),
        statusGroup: newStatusGroup,
        dependentOn: {
          _id: undefined,
          type: "task" as "task",
        },
        addingDependency: false,
        relativeDueDate: {
          unit: "day" as "day",
          value: 1,
        },
      };
    case "stage_update":
      return { ...state, stage: action.stage };
    case "status_update":
      return { ...state, status: action.status };
    case "due_date_update":
      return { ...state, dueDate: action.dueDate };
    case "relative_due_date_update":
      return { ...state, relativeDueDate: action.relativeDueDate };
    case "dependent_on_update":
      return { ...state, dependentOn: action.dependentOn };
    case "adding_dependency_update":
      return { ...state, addingDependency: action.addingDependency };
    case "tasker_update":
      return { ...state, tasker: action.tasker };
    case "visibility_update":
      return { ...state, isVisible: action.isVisible };
    case "adding_subtasks_update":
      return { ...state, addingSubtasks: action.addingSubtasks };
    case "subtasks_open_update":
      return { ...state, subtasksOpen: action.subtasksOpen };
    case "open_update":
      const project = state.projects?.find((p) => p._id === action.projectId);
      let space = state.spaces?.find(
        (s) => s._id === action.spaceId || s._id === project?.spaceId
      );
      const stage = project?.stages?.find((s) => s._id === action.stageId);
      console.log(space);
      console.log(state.statusGroups);
      let statusGroup = state.statusGroups?.find(
        (sg) => sg._id === space?.statusGroupId
      );
      console.log(statusGroup);
      if (!statusGroup) {
        statusGroup = state.statusGroups?.find(
          (sg) => sg._id === project?.statusGroupId
        );
      }
      if (!statusGroup) {
        const firstSpace = state.spaces[0];
        statusGroup = state.statusGroups?.find(
          (sg) => sg._id === firstSpace.statusGroupId
        );
        space = firstSpace;
      }
      // if (!statusGroup) {
      //   statusGroup = state.statusGroups?.find((sg) => !sg.isCustom);
      // }
      const status = statusGroup?.statuses.find(
        (s) => s.parentStatus === "To-do"
      );
      return {
        ...state,
        space: space,
        project: project,
        stage: stage,
        statusGroup: statusGroup,
        ...(!!status
          ? {
              status: {
                status: status.parentStatus,
                customStatus: status.name,
              },
            }
          : {}),
        isOpen: true,
        tasker: undefined,
        dueDate: undefined,
        relativeDueDate: {
          unit: "day" as "day",
          value: 1,
        },
        dependentOn: {
          _id: undefined,
          type: "task" as "task",
        },
        addingDependency: false,
        taskerType: "internal" as "internal",
        isVisible: true,
        addingSubtasks: false,
        subtasksOpen: false,
      };
    case "state_update":
      return { ...state, ...action.state };
    case "status_group_update":
      return { ...state, statusGroup: action.statusGroup };
    case "projects_update":
      return { ...state, projects: action.projects };
    case "status_groups_update":
      return { ...state, statusGroups: action.statusGroups };
    case "close_update":
      return { ...state, isOpen: false };
    case "is_create_more_update":
      return { ...state, isCreateMore: action.isCreateMore };
    case "space_update":
      const sg = state.statusGroups?.find(
        (sg) => sg.spaceId === action.space._id
      );
      const s = sg?.statuses.find((s) => s.parentStatus === "To-do");
      return {
        ...state,
        space: action.space,
        project: undefined,
        stage: undefined,
        statusGroup: sg,
        ...(!!s
          ? {
              status: {
                status: s.parentStatus,
                customStatus: s.name,
              },
            }
          : {}),
        // tasker: undefined,
      };
    case "spaces_update":
      return { ...state, spaces: action.spaces };
    default:
      return state;
  }
}

export function NewTaskForInternalUserContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, dispatch] = React.useReducer(newTaskForInternalUserReducer, {
    isOpen: false,
    title: "",
    description: "",
    project: undefined,
    stage: undefined,
    status: undefined,
    addingDependency: false,
    dueDate: undefined,
    relativeDueDate: {
      unit: "day",
      value: 1,
    },
    dependentOn: {
      _id: undefined,
      type: "task",
    },
    tasker: undefined,
    isVisible: true,
    addingSubtasks: false,
    subtasksOpen: false,
    statusGroup: undefined,
    projects: [],
    statusGroups: [],
    isCreateMore: false,
    space: undefined,
    spaces: [],
  });
  const value = { state, dispatch };
  return (
    <NewTaskForInternalUserContext.Provider value={value}>
      {children}
    </NewTaskForInternalUserContext.Provider>
  );
}

export function useNewTaskForInternalUser() {
  const context = useContext(NewTaskForInternalUserContext);
  if (context === undefined) {
    throw new Error(
      "useNewTaskForInternalUser must be used within a NewTaskContextProvider"
    );
  }
  return context;
}

export default function NewTaskForInternalUser() {
  const newTaskCtx = useNewTaskForInternalUser();
  const { phaseId, slug, spaceId } = useParams();
  const { data: projects } = useGetProjects();
  const { data: statusGroups } = useGetStatusGroups();
  const addTaskToProjectMutation = useAddTaskToProject();
  const addTaskToInstaceMutation = useCreateTask();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [count, setCount] = useState(0);
  const { data: spaces } = useGetSpaces();

  useEffect(() => {
    if (spaces) {
      newTaskCtx.dispatch({
        type: "spaces_update",
        spaces: spaces,
      });
    }
  }, [spaces]);

  useHotkeys(
    "mod+enter",
    (e) => {
      buttonRef.current?.click();
    },
    {
      enableOnFormTags: ["INPUT", "TEXTAREA"],
    }
  );

  useEffect(() => {
    if (statusGroups) {
      newTaskCtx.dispatch({
        type: "status_groups_update",
        statusGroups: statusGroups,
      });
    }
  }, [statusGroups]);

  useEffect(() => {
    if (projects) {
      newTaskCtx.dispatch({
        type: "projects_update",
        projects: projects,
      });
    }
  }, [projects]);

  // useEffect(() => {
  //   if (addTaskToProjectMutation.isSuccess) {

  //   }
  // }, [addTaskToProjectMutation.isSuccess]);

  // let statusGroup = statusGroups?.find(
  //   (sg) => sg._id === newTaskCtx.state.project?.statusGroupId
  // );

  // useEffect(() => {
  //   if (statusGroup) {
  //     newTaskCtx.dispatch({
  //       type: "status_update",
  //       status: {
  //         status: statusGroup.statuses[0].parentStatus,
  //         customStatus: statusGroup.statuses[0].name,
  //       },
  //     });
  //   }
  // }, [statusGroup]);

  useHotkeys("c", (e) => {
    e.preventDefault();
    e.stopPropagation();
    // if this is an external view, do not open the dialog
    if (!!slug) return;

    if (phaseId) {
      newTaskCtx.dispatch({ type: "open_update", projectId: phaseId });
    } else {
      newTaskCtx.dispatch({ type: "open_update", spaceId: spaceId });
    }

    // if (phaseId) {
    //   const project = projects?.find((p) => p._id === phaseId);
    //   const statusGroup = statusGroups?.find(
    //     (sg) => sg._id === project?.statusGroupId
    //   );
    //   newTaskCtx.dispatch({
    //     type: "state_update",
    //     state: {
    //       project: project,
    //       statusGroup: statusGroup,
    //       ...(!!statusGroup
    //         ? {
    //             status: {
    //               status: statusGroup.statuses[0].parentStatus,
    //               customStatus: statusGroup.statuses[0].name,
    //             },
    //           }
    //         : {}),
    //       stage: undefined,
    //       tasker: undefined,
    //       dueDate: undefined,
    //       dependentOn: {
    //         _id: undefined,
    //         type: "task",
    //       },
    //       addingDependency: false,
    //       relativeDueDate: {
    //         unit: "day",
    //         value: 1,
    //       },
    //     },
    //   });
    // } else {
    //   const statusGroup = statusGroups?.find((sg) => !sg.isCustom);
    //   newTaskCtx.dispatch({
    //     type: "state_update",
    //     state: {
    //       statusGroup: statusGroup,
    //       project: undefined,
    //       stage: undefined,
    //       tasker: undefined,
    //       dueDate: undefined,
    //       dependentOn: {
    //         _id: undefined,
    //         type: "task",
    //       },
    //       addingDependency: false,
    //       relativeDueDate: {
    //         unit: "day",
    //         value: 1,
    //       },
    //       ...(!!statusGroup
    //         ? {
    //             status: {
    //               status: statusGroup.statuses[0].parentStatus,
    //               customStatus: statusGroup.statuses[0].name,
    //             },
    //           }
    //         : {}),
    //     },
    //   });
    // }
  });

  // useEffect(() => {
  //   if (newTaskDialogCtx.state.isOpen) {
  //     newTaskCtx.dispatch({
  //       type: "title_update",
  //       title: "",
  //     });
  //     newTaskCtx.dispatch({
  //       type: "project_update",
  //       project: undefined,
  //     });
  //     newTaskCtx.dispatch({
  //       type: "stage_update",
  //       stage: undefined,
  //     });
  //     newTaskCtx.dispatch({
  //       type: "tasker_update",
  //       tasker: undefined,
  //     });
  //     newTaskCtx.dispatch({
  //       type: "visibility_update",
  //       isVisible: true,
  //     });
  //     newTaskCtx.dispatch({
  //       type: "adding_dependency_update",
  //       addingDependency: false,
  //     });
  //     newTaskCtx.dispatch({
  //       type: "due_date_update",
  //       dueDate: undefined,
  //     });
  //     newTaskCtx.dispatch({
  //       type: "relative_due_date_update",
  //       relativeDueDate: {
  //         unit: "day",
  //         value: 1,
  //       },
  //     });
  //     newTaskCtx.dispatch({
  //       type: "dependent_on_update",
  //       dependentOn: {
  //         _id: undefined,
  //         type: "task",
  //       },
  //     });
  //     newTaskCtx.dispatch({
  //       type: "adding_subtasks_update",
  //       addingSubtasks: false,
  //     });
  //     newTaskCtx.dispatch({
  //       type: "subtasks_open_update",
  //       subtasksOpen: false,
  //     });
  //   }
  // }, [newTaskDialogCtx.state.isOpen]);

  return (
    <Dialog
      open={newTaskCtx.state.isOpen}
      onOpenChange={(open) => {
        if (open) {
          newTaskCtx.dispatch({
            type: "open_update",
          });
        } else {
          newTaskCtx.dispatch({
            type: "close_update",
          });
        }
      }}
    >
      <DialogContent
        className={clsx(
          "top-[18%] max-h-[80%] max-w-2xl translate-y-0 p-0 data-[state=closed]:pointer-events-none data-[state=open]:pointer-events-auto data-[state=closed]:slide-out-to-top-[17%] data-[state=open]:slide-in-from-top-[18%]"
        )}
        onPointerDownOutside={(e) => {
          e.preventDefault();
        }}
        // onEscapeKeyDown={(e) => {
        //   e.preventDefault();
        // }}
      >
        <div className="h-fit">
          <div className="flex items-center space-x-1.5 border-b border-gray-100 py-3 px-5">
            <SpaceCombobox />
            <ProjectComboboxWrapper />
          </div>
          <div className="p-4 px-5 pt-4">
            <TitleWrapper count={count} />
          </div>
          {/* <div className="flex flex-wrap items-start gap-2 pt-3 pb-2">
              <StageCombobox />
              <SharedSpaceTaskerCombobox />

              <DueDatePicker />
              {!journeyCtx.state.isExternal && <VisibilitySelect />}
            </div>

            <div className="pb-4 pt-1">
              <div className="rounded-md border-[1.5px] border-gray-200 bg-gray-50 p-2 px-2">
                <Description
                  description={newProjectTaskCtx.state.description}
                  onBlur={(description) => {
                    newProjectTaskCtx.dispatch({
                      type: "description_update",
                      description: description,
                    });
                  }}
                />
              </div>
            </div> */}

          {/* <div>
                <div className="pb-1.5 font-medium text-gray-500">
                  Extensions
                </div>
                <div className="flex items-center space-x-3 text-gray-400">
                  <Action
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        strokeWidth="1"
                      >
                        <path
                          fill="currentColor"
                          d="M17 16a2.99 2.99 0 0 0-2.816 2H11c-1.654 0-3-1.346-3-3v-3.025A4.954 4.954 0 0 0 11 13h3.184A2.99 2.99 0 0 0 17 15a3 3 0 1 0 0-6a2.99 2.99 0 0 0-2.816 2H11c-1.654 0-3-1.346-3-3v-.184A2.99 2.99 0 0 0 10 5a3 3 0 1 0-6 0a2.99 2.99 0 0 0 2 2.816V15c0 2.757 2.243 5 5 5h3.184A2.99 2.99 0 0 0 17 22a3 3 0 1 0 0-6zm0-5a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zM7 4a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zm10 16a1.001 1.001 0 0 1 0-2a1.001 1.001 0 0 1 0 2z"
                          // stroke="currentColor"
                          strokeWidth={0}
                        />
                      </svg>
                    }
                    onClick={() => {}}
                    text="Subtasks"
                    // disabled={
                    //   taskTemplateCtx.state.action === "form" ||
                    //   taskTemplateCtx.state.action === "checklist"
                    // }
                    // active={taskTemplateCtx.state.options.hasSubTasks}
                  />
                  <Action
                    icon={<DocumentTextIcon className="h-5 w-5" />}
                    onClick={() => {}}
                    text="Form"
                  />
                  <Action
                    icon={<PhoneIcon className="h-5 w-5" />}
                    onClick={() => {}}
                    text="Call"
                  />
                  <Action
                    icon={<CloudArrowUpIcon className="h-5 w-5" />}
                    onClick={() => {}}
                    text="File Upload"
                  />
                </div>
              </div> */}
          <div className="px-5 pb-3">
            <div className="flex gap-1.5">
              <StatusSelectWrapper />
              {!!newTaskCtx.state.project?._id ? (
                <ProjectTaskDueDateWrapper
                  projectId={newTaskCtx.state.project._id}
                />
              ) : (
                <InstanceTaskDueDateWrapper />
              )}
              {/* <div className="border">Status</div> */}
              {/* <div className="border">Priority</div> */}
              {/* {!newTaskCtx.state.project?.isExternal && ( */}
              <TaskerComboboxWrapper />
              {/* )} */}
              {/* <div className="border">Estimate</div> */}
              {newTaskCtx.state.project?.isExternal && (
                <VisibilitySelectWrapper />
                // <div className="flex gap-1.5 pt-1.5">
                //   <TaskerComboboxWrapper />
                // </div>
              )}
            </div>
          </div>
          <div className="px-5">
            <DescriptionWrapper />
          </div>
          {newTaskCtx.state.addingSubtasks && (
            <div className="space-y-4 rounded-md border p-4 shadow-sm">
              <div className="font-gray-700 text-xs font-light">
                New Subtask
              </div>
              <div className="text-gray-400">Rest of the form</div>
              <div className="flex items-center justify-end space-x-2">
                <Button
                  variant={"secondary"}
                  onClick={() => {
                    newTaskCtx.dispatch({
                      type: "adding_subtasks_update",
                      addingSubtasks: false,
                    });
                    newTaskCtx.dispatch({
                      type: "subtasks_open_update",
                      subtasksOpen: false,
                    });
                  }}
                  size={"sm"}
                >
                  Cancel
                </Button>
                <Button size={"sm"}>Add Subtask</Button>
              </div>
            </div>
          )}
        </div>
        {!newTaskCtx.state.subtasksOpen && (
          <div className="flex justify-between p-4 px-5">
            {/* <Options /> */}
            <div></div>
            <div className="flex items-center space-x-2">
              {/* <Button variant={"secondary"}>Cancel</Button> */}
              <div className="flex items-center space-x-4">
                <div className="flex items-center space-x-1.5">
                  <Switch
                    checked={newTaskCtx.state.isCreateMore}
                    onCheckedChange={(value) => {
                      newTaskCtx.dispatch({
                        type: "is_create_more_update",
                        isCreateMore: value,
                      });
                    }}
                  />
                  <div className="text-xs text-gray-400">Create more</div>
                </div>
                <Button
                  ref={buttonRef}
                  disabled={
                    !newTaskCtx.state.title.trim() ||
                    !newTaskCtx.state.tasker ||
                    (newTaskCtx.state.addingDependency
                      ? !newTaskCtx.state.dependentOn._id
                      : !newTaskCtx.state.dueDate)
                    // || addTaskToProjectMutation.isLoading
                  }
                  onClick={() => {
                    if (newTaskCtx.state.project) {
                      ////////////////////////
                      ///// project task /////
                      ////////////////////////
                      if (
                        newTaskCtx.state.project &&
                        newTaskCtx.state.status &&
                        newTaskCtx.state.tasker &&
                        newTaskCtx.state.statusGroup
                      ) {
                        const taskerType = newTaskCtx.state.tasker?.type;
                        let taskRequest = {
                          title: newTaskCtx.state.title,
                          description: newTaskCtx.state.description,
                          isVisible: newTaskCtx.state.isVisible,
                          taskerType: taskerType,
                          estimate: {
                            value: 0,
                            unit: "minute" as "minute",
                          },
                          status: newTaskCtx.state.status.status,
                          customStatus: newTaskCtx.state.status?.customStatus,
                          journeyId: newTaskCtx.state.project.journeyId,
                          ...(newTaskCtx.state.addingDependency &&
                          newTaskCtx.state.dependentOn._id
                            ? {
                                dependentOn: {
                                  _id: newTaskCtx.state.dependentOn?._id,
                                  type: newTaskCtx.state.dependentOn?.type,
                                },
                                relativeDueDate: {
                                  unit: newTaskCtx.state.relativeDueDate.unit,
                                  value: newTaskCtx.state.relativeDueDate.value,
                                },
                              }
                            : {
                                dueDate: !!newTaskCtx.state.dueDate
                                  ? new Date(newTaskCtx.state.dueDate)
                                  : undefined,
                              }),
                          ...(newTaskCtx.state.tasker?.userType === "fullUser"
                            ? {
                                taskerId: newTaskCtx.state.tasker?._id,
                              }
                            : {
                                taskerEmail: newTaskCtx.state.tasker?.email,
                              }),
                          ...(!!newTaskCtx.state.stage
                            ? {
                                journeyStageId: newTaskCtx.state.stage._id,
                              }
                            : {}),
                        };

                        addTaskToProjectMutation.mutate({
                          params: {
                            task: taskRequest,
                          },
                          metadata: {
                            journeyId: newTaskCtx.state.project.journeyId,
                            journeyStageId: newTaskCtx.state.stage?._id,
                            phaseId: newTaskCtx.state.project._id,
                            tasker: newTaskCtx.state.tasker,
                            statusGroupId: newTaskCtx.state.statusGroup._id,
                          },
                        });
                      }
                    } else {
                      /////////////////////////
                      ///// instance task /////
                      /////////////////////////
                      if (
                        !!newTaskCtx.state.dueDate &&
                        newTaskCtx.state.space
                      ) {
                        addTaskToInstaceMutation.mutate({
                          task: {
                            spaceId: newTaskCtx.state.space._id,
                            action: "default",
                            title: newTaskCtx.state.title,
                            description: newTaskCtx.state.description,
                            dueDate: newTaskCtx.state.dueDate,
                            taskerType: "internal",
                            taskerId:
                              newTaskCtx.state.tasker?.userType === "fullUser"
                                ? newTaskCtx.state.tasker?._id
                                : undefined,
                          },
                          subTasks: [],
                        });
                      }
                    }
                    if (!newTaskCtx.state.isCreateMore) {
                      newTaskCtx.dispatch({
                        type: "close_update",
                      });
                      newTaskCtx.dispatch({
                        type: "project_update",
                        project: undefined,
                      });
                      newTaskCtx.dispatch({
                        type: "stage_update",
                        stage: undefined,
                      });
                      newTaskCtx.dispatch({
                        type: "tasker_update",
                        tasker: undefined,
                      });
                      newTaskCtx.dispatch({
                        type: "visibility_update",
                        isVisible: true,
                      });
                      newTaskCtx.dispatch({
                        type: "adding_dependency_update",
                        addingDependency: false,
                      });
                      newTaskCtx.dispatch({
                        type: "due_date_update",
                        dueDate: undefined,
                      });
                      newTaskCtx.dispatch({
                        type: "relative_due_date_update",
                        relativeDueDate: {
                          unit: "day",
                          value: 1,
                        },
                      });
                      newTaskCtx.dispatch({
                        type: "dependent_on_update",
                        dependentOn: {
                          _id: undefined,
                          type: "task",
                        },
                      });
                      newTaskCtx.dispatch({
                        type: "adding_subtasks_update",
                        addingSubtasks: false,
                      });
                      newTaskCtx.dispatch({
                        type: "subtasks_open_update",
                        subtasksOpen: false,
                      });
                    }
                    // things to do after creating a task
                    newTaskCtx.dispatch({
                      type: "title_update",
                      title: "",
                    });
                    newTaskCtx.dispatch({
                      type: "description_update",
                      description: "",
                    });
                    setCount((prev) => prev + 1);
                  }}
                >
                  {"Create"}
                </Button>
              </div>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

function Space() {
  const newTaskCtx = useNewTaskForInternalUser();
  return (
    <div className="flex h-8 w-fit items-center space-x-2 rounded-md border border-input px-2.5">
      {newTaskCtx.state.space && (
        <div className="flex h-8 w-fit items-center space-x-2 rounded-md border border-input px-2.5">
          <SpaceSymbol color={newTaskCtx.state.space.color} />
          <div className="text-sm text-gray-800">
            {newTaskCtx.state.space.name}
          </div>
        </div>
      )}
    </div>
  );
}

function DescriptionWrapper() {
  const newTaskCtx = useNewTaskForInternalUser();
  return (
    <div className="rounded-md bg-gray-50 p-2 px-2">
      <Description
        description={newTaskCtx.state.description}
        onBlur={(description) => {
          newTaskCtx.dispatch({
            type: "description_update",
            description: description,
          });
        }}
      />
    </div>
  );
}

function Options() {
  const newTaskCtx = useNewTaskForInternalUser();
  const [open, setOpen] = useState(false);
  return (
    <DropdownMenu
      open={open}
      onOpenChange={(isOpen) => {
        setOpen(isOpen);
      }}
    >
      <DropdownMenuTrigger>
        <Button
          variant={"ghost"}
          size={"icon"}
          className={clsx(open && "bg-gray-100")}
        >
          <EllipsisVerticalIcon className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-44" align="start">
        <DropdownMenuGroup>
          <DropdownMenuItem
            // className="py-1 focus:bg-gray-50 focus:text-red-600"
            onClick={(e) => {
              newTaskCtx.dispatch({
                type: "adding_subtasks_update",
                addingSubtasks: true,
              });
              newTaskCtx.dispatch({
                type: "subtasks_open_update",
                subtasksOpen: true,
              });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="4 0 24 24"
              className="text-gray-700"
            >
              <path
                // strokeWidth={1}
                fill="currentColor"
                stroke="none"
                // fill="none"
                d="M17 16a2.99 2.99 0 0 0-2.816 2H11c-1.654 0-3-1.346-3-3v-3.025A4.954 4.954 0 0 0 11 13h3.184A2.99 2.99 0 0 0 17 15a3 3 0 1 0 0-6a2.99 2.99 0 0 0-2.816 2H11c-1.654 0-3-1.346-3-3v-.184A2.99 2.99 0 0 0 10 5a3 3 0 1 0-6 0a2.99 2.99 0 0 0 2 2.816V15c0 2.757 2.243 5 5 5h3.184A2.99 2.99 0 0 0 17 22a3 3 0 1 0 0-6zm0-5a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zM7 4a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zm10 16a1.001 1.001 0 0 1 0-2a1.001 1.001 0 0 1 0 2z"
              />
            </svg>
            <span className="ml-2 text-sm">Add sub-tasks</span>
          </DropdownMenuItem>

          {/* <DropdownMenuItem>Delete</DropdownMenuItem> */}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function TitleWrapper({ count }: { count: number }) {
  const newTaskCtx = useNewTaskForInternalUser();
  return (
    <Title
      count={count}
      title={newTaskCtx.state.title}
      onBlur={(e) => {
        newTaskCtx.dispatch({
          type: "title_update",
          title: e.target.value,
        });
      }}
    />
  );
}

export function Title({
  title,
  onBlur,
  count,
}: {
  title: string;
  onBlur: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  count: number;
}) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [value, setValue] = useState(title);
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "0px"; // Reset height to shrink if needed
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + "px";
    }
  }, [value]);

  useEffect(() => {
    setValue(title);
  }, [title]);

  useEffect(() => {
    textAreaRef.current?.focus();
  }, [count]);

  return (
    <textarea
      ref={textAreaRef}
      className="w-full resize-none border-none p-0 pr-5 text-xl font-medium outline-none placeholder:text-gray-300 focus:border-none focus:outline-none focus:ring-0"
      value={value}
      onChange={(e) => {
        if (e.target.value.includes("\n")) {
          return;
        }
        setValue(e.target.value);
      }}
      onBlur={(e) => {
        onBlur(e);
      }}
      placeholder="Title..."
    />
  );
}

function SpaceCombobox() {
  const newTaskCtx = useNewTaskForInternalUser();
  const [open, setOpen] = useState(false);
  return (
    <div className="">
      <Popover
        modal={true}
        open={open}
        onOpenChange={(open) => {
          setOpen(open);
        }}
      >
        <PopoverTrigger asChild>
          <Button
            variant="secondary"
            role="combobox"
            aria-expanded={open}
            className="space-x-2 truncate px-2"
          >
            {newTaskCtx.state.space && (
              <SpaceNameAndSymbol
                color={newTaskCtx.state.space.color}
                name={newTaskCtx.state.space.name}
              />
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          animateOut={false}
          className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
          align="start"
        >
          <Command>
            <CommandInput placeholder="Search space..." className="h-9" />
            <CommandEmpty>No space found.</CommandEmpty>
            <CommandGroup>
              {newTaskCtx.state.spaces.map((space) => (
                <CommandItem
                  key={space._id}
                  value={space.name}
                  onSelect={() => {
                    newTaskCtx.dispatch({
                      type: "space_update",
                      space: space,
                    });
                    setOpen(false);
                  }}
                  className="gap-2"
                >
                  <SpaceNameAndSymbol color={space.color} name={space.name} />
                  <CheckIcon
                    className={cn(
                      "ml-auto h-4 w-4 shrink-0",
                      space._id === newTaskCtx.state.space?._id
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}

function ProjectComboboxWrapper() {
  const { data: projects } = useGetProjects();
  const newTaskCtx = useNewTaskForInternalUser();
  const options = projects?.filter(
    (p) => p.spaceId === newTaskCtx.state.space?._id
  );
  return (
    <ProjectCombobox
      projects={options}
      selectedProject={newTaskCtx.state.project}
      selectedStage={newTaskCtx.state.stage}
      onProjectSelect={(project) => {
        newTaskCtx.dispatch({
          type: "project_update",
          project,
        });
      }}
      onStageSelect={(stage) => {
        newTaskCtx.dispatch({
          type: "stage_update",
          stage,
        });
      }}
      onProjectDelete={() => {
        newTaskCtx.dispatch({
          type: "project_update",
          project: undefined,
        });
        newTaskCtx.dispatch({
          type: "stage_update",
          stage: undefined,
        });
        newTaskCtx.dispatch({
          type: "tasker_update",
          tasker: undefined,
        });
        newTaskCtx.dispatch({
          type: "visibility_update",
          isVisible: true,
        });
        newTaskCtx.dispatch({
          type: "adding_dependency_update",
          addingDependency: false,
        });
        newTaskCtx.dispatch({
          type: "due_date_update",
          dueDate: undefined,
        });
        newTaskCtx.dispatch({
          type: "relative_due_date_update",
          relativeDueDate: {
            unit: "day",
            value: 1,
          },
        });
        newTaskCtx.dispatch({
          type: "dependent_on_update",
          dependentOn: {
            _id: undefined,
            type: "task",
          },
        });
        newTaskCtx.dispatch({
          type: "adding_subtasks_update",
          addingSubtasks: false,
        });
        newTaskCtx.dispatch({
          type: "subtasks_open_update",
          subtasksOpen: false,
        });
      }}
      onStageDelete={() => {
        newTaskCtx.dispatch({
          type: "stage_update",
          stage: undefined,
        });
      }}
    />
  );
}

function ProjectCombobox({
  projects,
  selectedProject,
  onProjectSelect,
  selectedStage,
  onStageSelect,
  onProjectDelete,
  onStageDelete,
}: {
  selectedProject: ProjectData | undefined;
  selectedStage: ProjectData["stages"][0] | undefined;
  projects: ProjectData[] | undefined;
  onProjectSelect: (project: ProjectData) => void;
  onStageSelect: (stage: ProjectData["stages"][0]) => void;
  onProjectDelete: () => void;
  onStageDelete: () => void;
}) {
  const [open, setOpen] = useState(false);
  const [stageOpen, setStageOpen] = useState(false);
  return (
    <div className="flex">
      <Popover open={open} onOpenChange={setOpen} modal={true}>
        <PopoverTrigger asChild>
          <Button
            variant="secondary"
            role="combobox"
            aria-expanded={open}
            className={clsx(
              "group relative truncate font-normal",
              selectedProject?.stages.length && "rounded-r-none border-r-0"
            )}
          >
            <RocketLaunchIcon
              className={clsx(
                "3.5 mr-2 w-3.5 shrink-0",
                selectedProject ? "text-gray-600" : "text-gray-400"
              )}
            />
            {selectedProject ? (
              <div className="max-w-[160px] truncate">
                {selectedProject.name}
              </div>
            ) : (
              <div className="text-gray-400/80">Project</div>
            )}
            {/* {!!selectedProject && (
              <div className="group absolute right-0 h-full w-20 bg-gradient-to-r from-transparent via-gray-50/70 to-gray-100 opacity-0 transition-all group-hover:opacity-100">
                <div
                  className="group absolute right-2 top-1/2 -translate-y-1/2 rounded-full bg-gray-200 p-[3px] opacity-0 transition-all group-hover:opacity-100"
                  onClick={(e) => {
                    e.stopPropagation();
                    onProjectDelete();
                  }}
                >
                  <XMarkIcon className="h-2.5 w-2.5 text-gray-400 group-hover:text-gray-600" />
                </div>
              </div>
            )} */}
            {/* <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" /> */}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          animateOut={false}
          className="pointer-events-auto w-60 p-0"
          align="start"
        >
          {!!projects ? (
            <>
              {projects?.length > 0 ? (
                <Command
                  filter={(value, search) => {
                    if (
                      projects
                        ?.find((p) => p._id === value)
                        ?.name.toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return 1;
                    } else {
                      return 0;
                    }
                  }}
                >
                  <CommandInput
                    placeholder="Search project..."
                    className="h-9"
                  />
                  <CommandEmpty>No project found.</CommandEmpty>
                  <CommandGroup>
                    {projects?.map((project) => (
                      <CommandItem
                        key={project._id}
                        value={project._id}
                        onSelect={() => {
                          onProjectSelect(project);
                          setOpen(false);
                        }}
                        className="gap-2"
                      >
                        <span className="truncate">{project.name}</span>
                        <div className="flex flex-1 shrink-0 items-center justify-end space-x-1">
                          {selectedProject?._id === project._id ? (
                            <div
                              className="cursor-pointer rounded-full p-1 transition-all hover:bg-gray-200"
                              onClick={(e) => {
                                e.stopPropagation();
                                onProjectDelete();
                                setOpen(false);
                              }}
                            >
                              <XMarkIcon
                                className={cn(
                                  "h-2.5 w-2.5 shrink-0",
                                  selectedProject?._id === project._id
                                    ? "opacity-100"
                                    : "opacity-0"
                                )}
                              />
                            </div>
                          ) : (
                            <div className="w-5" />
                          )}
                          <CheckIcon
                            className={cn(
                              "h-4 w-4 shrink-0",
                              selectedProject?._id === project._id
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                          />
                        </div>
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </Command>
              ) : (
                <div className="px-4 py-2.5 text-sm">
                  <div className="select-none">No Projects Created</div>
                  {/* <div className="select-none pt-0.5 text-xs text-gray-500">
                    Click{" "}
                    <span className="cursor-pointer text-gray-700 hover:text-gray-800">
                      here
                    </span>{" "}
                    to create a project
                  </div> */}
                </div>
              )}
            </>
          ) : (
            <Loading className="mx-auto my-3 h-4 w-4 border-[3px]" />
          )}
        </PopoverContent>
      </Popover>
      {!!selectedProject?.stages.length && (
        <Popover open={stageOpen} onOpenChange={setStageOpen} modal={true}>
          <PopoverTrigger asChild>
            <Button
              variant="secondary"
              role="combobox"
              aria-expanded={open}
              className="truncate rounded-l-none font-normal"
            >
              <SquareIcon
                className={clsx(
                  "mr-2 h-3.5 w-3.5 shrink-0",
                  !!selectedStage ? "text-gray-600" : "text-gray-400"
                )}
              />
              {!!selectedStage ? (
                <div className="max-w-[100px] truncate">
                  {selectedStage.name}
                </div>
              ) : (
                <div className="text-gray-400/80">Stage</div>
              )}
              {/* <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" /> */}
            </Button>
          </PopoverTrigger>
          <PopoverContent
            animateOut={false}
            className="pointer-events-auto w-fit min-w-[200px] max-w-[480px] p-0"
            align="start"
          >
            <Command
              filter={(value, search) => {
                if (
                  selectedProject?.stages
                    .find((s) => s._id === value)
                    ?.name.toLowerCase()
                    .includes(search.toLowerCase())
                ) {
                  return 1;
                } else {
                  return 0;
                }
              }}
            >
              <CommandInput placeholder="Search stage..." className="h-9" />
              <CommandEmpty>No stage found.</CommandEmpty>
              <CommandGroup>
                {selectedProject?.stages.map((stage) => (
                  <CommandItem
                    key={stage._id}
                    value={stage._id}
                    onSelect={() => {
                      onStageSelect(stage);
                      setStageOpen(false);
                    }}
                    className="gap-2"
                  >
                    <span className="truncate">{stage.name}</span>
                    <div className="flex flex-1 shrink-0 items-center justify-end space-x-1">
                      {selectedStage?._id === stage._id ? (
                        <div
                          className="cursor-pointer rounded-full p-1 transition-all hover:bg-gray-200"
                          onClick={(e) => {
                            e.stopPropagation();
                            onStageDelete();
                            setStageOpen(false);
                          }}
                        >
                          <XMarkIcon
                            className={cn(
                              "h-2.5 w-2.5 shrink-0",
                              selectedStage?._id === stage._id
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                          />
                        </div>
                      ) : (
                        <div className="w-5" />
                      )}
                      <CheckIcon
                        className={cn(
                          "ml-auto h-4 w-4 shrink-0",
                          selectedStage?._id === stage._id
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                    </div>
                  </CommandItem>
                ))}
              </CommandGroup>
            </Command>
          </PopoverContent>
        </Popover>
      )}
    </div>
  );
}

function StatusSelectWrapper() {
  const newTaskCtx = useNewTaskForInternalUser();

  if (!newTaskCtx.state.status || !newTaskCtx.state.statusGroup) {
    return (
      <Button className="font-normal" variant={"secondary"} disabled={true}>
        Status
      </Button>
    );
  }

  return (
    <StatusSelect
      type="secondaryButton"
      statusGroup={newTaskCtx.state.statusGroup}
      disabledStatusesTypes={[]}
      disabled={false}
      onSelect={(status, customStatus) => {
        newTaskCtx.dispatch({
          type: "status_update",
          status: {
            status: status,
            customStatus: customStatus,
          },
        });
      }}
      selectedStatus={newTaskCtx.state.status}
    />
  );
}

export function StatusSelect({
  type,
  statusGroup,
  disabledStatusesTypes = [],
  disabled = false,
  onSelect,
  selectedStatus,
}: {
  type: "tag" | "symbol" | "button" | "secondaryButton";
  statusGroup: StatusGroupData;
  disabledStatusesTypes?: ParentStatus[];
  disabled?: boolean;
  onSelect: (status: ParentStatus, customStatus: string) => void;
  selectedStatus: {
    status: ParentStatus;
    customStatus: string;
  };
}) {
  // const { data: statusGroups } = useGetStatusGroups();
  // const statusGroup = statusGroups?.find((sg) => sg._id === statusGroupId);
  // if (!statusGroup) {
  //   return <div>No match</div>;
  // }

  let statuses: {
    [key: string]: string[];
  } = {};

  statuses = {
    Backlog: statusGroup.statuses
      .filter(
        (s) =>
          s.parentStatus === "Backlog" &&
          !disabledStatusesTypes.includes("Backlog")
      )
      .map((s) => s.name),
    "To-do": statusGroup.statuses
      .filter(
        (s) =>
          s.parentStatus === "To-do" && !disabledStatusesTypes.includes("To-do")
      )
      .map((s) => s.name),
    "In Progress": statusGroup.statuses
      .filter(
        (s) =>
          s.parentStatus === "In Progress" &&
          !disabledStatusesTypes.includes("In Progress")
      )
      .map((s) => s.name),
    Completed: statusGroup.statuses
      .filter(
        (s) =>
          s.parentStatus === "Completed" &&
          !disabledStatusesTypes.includes("Completed")
      )
      .map((s) => s.name),
    Stuck: statusGroup.statuses
      .filter(
        (s) =>
          s.parentStatus === "Stuck" && !disabledStatusesTypes.includes("Stuck")
      )
      .map((s) => s.name),
    Canceled: statusGroup.statuses
      .filter(
        (s) =>
          s.parentStatus === "Canceled" &&
          !disabledStatusesTypes.includes("Canceled")
      )
      .map((s) => s.name),
  };

  return (
    <>
      <Select
        disabled={disabled}
        value={selectedStatus.customStatus}
        onValueChange={(customStatus: string) => {
          // find the new parent status
          const newParentStatus = Object.keys(statuses).find((key) =>
            statuses[key].includes(customStatus)
          ) as ParentStatus | undefined;
          if (!newParentStatus) return;
          onSelect(newParentStatus, customStatus);
        }}
      >
        {type === "symbol" ? (
          <>
            <SelectTrigger
              className={clsx(
                // task.status === "To-do" &&
                //   "border-amber-600 bg-amber-400 text-white hover:bg-amber-400",
                // task.status === "In Progress" &&
                //   "border-blue-600 bg-blue-400 text-white hover:bg-blue-400",
                // task.status === "Completed" &&
                //   "border-green-600 bg-green-400 text-white hover:bg-green-400",
                // task.status === "Stuck" &&
                //   "border-red-600 bg-red-400 text-white hover:bg-red-400",
                // task.status === "Canceled" &&
                //   "border-gray-600 bg-gray-400 text-white hover:bg-gray-400",
                // task.status === "Backlog" &&
                //   "border-dashed border-gray-500 bg-white text-gray-500 hover:bg-white",
                "h-fit border-0 p-0 shadow-none outline-none focus:border-0 focus:outline-none focus:ring-0 focus-visible:border-0 focus-visible:outline-none focus-visible:ring-0"
              )}
              showIcon={false}
            >
              {getStatusSymbol(selectedStatus.status, true)}
            </SelectTrigger>
          </>
        ) : type === "tag" ? (
          <SelectTrigger
            iconClassName="opacity-100 h-3 w-3 ml-0.5"
            className={clsx(
              selectedStatus.status === "To-do" &&
                "border-amber-600 bg-amber-400 text-white hover:bg-amber-400",
              selectedStatus.status === "In Progress" &&
                "border-blue-600 bg-blue-400 text-white hover:bg-blue-400",
              selectedStatus.status === "Completed" &&
                "border-green-600 bg-green-400 text-white hover:bg-green-400",
              selectedStatus.status === "Stuck" &&
                "border-red-600 bg-red-400 text-white hover:bg-red-400",
              selectedStatus.status === "Canceled" &&
                "border-gray-600 bg-gray-400 text-white hover:bg-gray-400",
              selectedStatus.status === "Backlog" &&
                "border-dashed border-gray-500 bg-white text-gray-500 hover:bg-white",
              "flex h-[22px] w-fit rounded-md px-1.5 text-xs"
            )}
            showIcon={false}
          >
            {selectedStatus.customStatus}
            <ChevronDownIcon
              className={clsx(
                "ml-1 h-3 w-3",
                selectedStatus.status === "Backlog"
                  ? "text-gray-600"
                  : "text-white"
              )}
            />
            {/* "h-8 w-fit rounded-md" */}
            {/* {getStatusTag(task.status, !disabled)} */}
          </SelectTrigger>
        ) : type === "button" ? (
          <SelectTrigger
            showIcon={false}
            className={clsx(
              selectedStatus.status === "To-do" &&
                "border-amber-600 bg-amber-400 text-white hover:bg-amber-400",
              selectedStatus.status === "In Progress" &&
                "border-blue-600 bg-blue-400 text-white hover:bg-blue-400",
              selectedStatus.status === "Completed" &&
                "border-green-600 bg-green-400 text-white hover:bg-green-400",
              selectedStatus.status === "Stuck" &&
                "border-red-600 bg-red-400 text-white hover:bg-red-400",
              selectedStatus.status === "Canceled" &&
                "border-gray-600 bg-gray-400 text-white hover:bg-gray-400",
              selectedStatus.status === "Backlog" &&
                "border-dashed border-gray-500 bg-white text-gray-500 hover:bg-white ",
              "h-8 w-fit rounded-md"
            )}
          >
            <div className="">{selectedStatus.customStatus}</div>
            <CaretSortIcon className={"h-4 w-4"} />
          </SelectTrigger>
        ) : (
          <SelectTrigger showIcon={false} className="w-fit space-x-2">
            {getStatusSymbol(selectedStatus.status, false)}
            <div>{selectedStatus.customStatus}</div>
          </SelectTrigger>
        )}
        <SelectContent>
          <SelectGroup>
            {statuses["Backlog"].map((status) => (
              <SelectItem key={status} value={status} className="h-[30px]">
                {getStatusItem("Backlog", status)}
              </SelectItem>
            ))}
            {statuses["To-do"].map((status) => (
              <SelectItem key={status} value={status} className="h-[30px]">
                {getStatusItem("To-do", status)}
              </SelectItem>
            ))}
            {statuses["In Progress"].map((status) => (
              <SelectItem key={status} value={status} className="h-[30px]">
                {getStatusItem("In Progress", status)}
              </SelectItem>
            ))}
            {statuses["Stuck"].map((status) => (
              <SelectItem key={status} value={status} className="h-[30px]">
                {getStatusItem("Stuck", status)}
              </SelectItem>
            ))}

            {statuses["Completed"].map((status) => (
              <SelectItem key={status} value={status} className="h-[30px]">
                {getStatusItem("Completed", status)}
              </SelectItem>
            ))}

            {statuses["Canceled"].map((status) => (
              <SelectItem key={status} value={status} className="h-[30px]">
                {getStatusItem("Canceled", status)}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </>
  );
}

function InstanceTaskDueDateWrapper() {
  const newTaskCtx = useNewTaskForInternalUser();
  return (
    <DueDatePicker
      dueDate={newTaskCtx.state.dueDate}
      addingDependency={newTaskCtx.state.addingDependency}
      dependentOn={newTaskCtx.state.dependentOn}
      onAddingDependencyChange={(value) => {
        newTaskCtx.dispatch({
          type: "adding_dependency_update",
          addingDependency: value,
        });
      }}
      onDueDateChange={(value) => {
        newTaskCtx.dispatch({
          type: "due_date_update",
          dueDate: value,
        });
      }}
      dependencyDisabled={!newTaskCtx.state.project}
    />
  );
}

function ProjectTaskDueDateWrapper({ projectId }: { projectId: string }) {
  const newTaskCtx = useNewTaskForInternalUser();
  const { data: tasks } = useGetPhaseTasks(projectId);
  return (
    <DueDatePicker
      tasks={tasks ?? []}
      dueDate={newTaskCtx.state.dueDate}
      addingDependency={newTaskCtx.state.addingDependency}
      dependentOn={newTaskCtx.state.dependentOn}
      onAddingDependencyChange={(value) => {
        newTaskCtx.dispatch({
          type: "adding_dependency_update",
          addingDependency: value,
        });
      }}
      onDueDateChange={(value) => {
        newTaskCtx.dispatch({
          type: "due_date_update",
          dueDate: value,
        });
      }}
      dependencyDisabled={!newTaskCtx.state.project}
    />
  );
}

export function DueDatePicker({
  dueDate,
  addingDependency,
  dependentOn,
  onAddingDependencyChange,
  onDueDateChange,
  dependencyDisabled,
  tasks = [],
}: {
  dueDate: string | undefined;
  addingDependency: boolean;
  dependentOn: DependentOn;
  onAddingDependencyChange: (value: boolean) => void;
  onDueDateChange: (value: string | undefined) => void;
  dependencyDisabled: boolean;
  tasks?: TaskData[];
}) {
  const newTaskCtx = useNewTaskForInternalUser();
  const [open, setOpen] = useState(false);
  return (
    <Popover
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
      }}
    >
      <PopoverTrigger asChild>
        <Button
          variant={"secondary"}
          className={cn(
            "h-8 justify-between text-left font-normal",
            !dueDate && "text-muted-foreground"
          )}
        >
          <div className="inline-flex items-center">
            <CalendarIcon className="mr-2 h-3.5 w-3.5" />
            {addingDependency ? (
              <>
                {!!dependentOn?._id ? (
                  <div>
                    {dependentOn.type === "task"
                      ? "Dependent on task"
                      : "Dependent on stage"}
                  </div>
                ) : (
                  <div className="flex space-x-1 text-gray-400/80">
                    Due Date
                    <span className="top-0 ml-0.5 text-primary-main">*</span>
                  </div>
                )}
              </>
            ) : dueDate ? (
              dayjs(dueDate).format("DD MMM")
            ) : (
              <div className="flex space-x-1 text-gray-400/80">
                Due Date
                <span className="top-0 ml-0.5 text-primary-main">*</span>
              </div>
            )}
          </div>
          {/* <CaretSortIcon className="ml-2 h-4 w-4 opacity-50" /> */}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        animateOut={false}
        className="w-[248px] p-0"
        align="start"
      >
        {!dependencyDisabled && (
          <div className="border-b p-3 px-4">
            <div className="flex items-center justify-between">
              <div
                className={clsx(
                  "text-sm transition-colors",
                  !addingDependency ? "text-gray-400/80" : "text-gray-500"
                )}
              >
                {"Dynamic Due Date"}
              </div>
              <Switch
                checked={addingDependency}
                onCheckedChange={(checked) => {
                  onAddingDependencyChange(checked);
                }}
              />
            </div>
          </div>
        )}
        {addingDependency ? (
          <div className="">
            <div className="flex items-center gap-2 p-3 px-4 pb-2 text-sm">
              <div className="text-gray-500">Due</div>
              <ValueInput
                onValueChange={(value) => {
                  newTaskCtx.dispatch({
                    type: "relative_due_date_update",
                    relativeDueDate: {
                      value: value,
                      unit: newTaskCtx.state.relativeDueDate.unit,
                    },
                  });
                }}
                value={newTaskCtx.state.relativeDueDate.value}
              />
              <UnitSelect
                onValueChange={(value) => {
                  newTaskCtx.dispatch({
                    type: "relative_due_date_update",
                    relativeDueDate: {
                      value: newTaskCtx.state.relativeDueDate.value,
                      unit: value as (typeof relativeDueDateUnits)[number],
                    },
                  });
                }}
                value={newTaskCtx.state.relativeDueDate.unit}
                isPlural={newTaskCtx.state.relativeDueDate.value > 1}
              />
            </div>
            <div className="flex items-center gap-2 px-4 pb-2 text-sm">
              <div className="text-gray-500">after</div>
              <ConditionSelect
                onSelect={(value) => {
                  newTaskCtx.dispatch({
                    type: "dependent_on_update",
                    dependentOn: {
                      type: value,
                      _id: undefined,
                    },
                  });
                }}
                value={newTaskCtx.state.dependentOn.type}
              />
            </div>

            <div className="px-4 pb-4">
              {newTaskCtx.state.dependentOn.type === "task" && (
                <TaskDependency
                  dependentOn={{
                    _id: newTaskCtx.state.dependentOn._id,
                  }}
                  onSelect={(value) => {
                    newTaskCtx.dispatch({
                      type: "dependent_on_update",
                      dependentOn: {
                        type: "task",
                        _id: value,
                      },
                    });
                  }}
                  tasks={tasks}
                />
              )}
              {newTaskCtx.state.dependentOn.type === "stage" && (
                <StageDependency
                  onSelect={(value) => {
                    newTaskCtx.dispatch({
                      type: "dependent_on_update",
                      dependentOn: {
                        type: "stage",
                        _id: value,
                      },
                    });
                  }}
                  dependentOn={{
                    _id: newTaskCtx.state.dependentOn._id,
                  }}
                  stages={newTaskCtx.state.project?.stages ?? []}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="">
            <Calendar
              mode="single"
              selected={dueDate ? new Date(dueDate) : undefined}
              onSelect={(date) => {
                if (date) {
                  const defaultTime = new Date(date);
                  defaultTime.setHours(23, 30, 0, 0);
                  onDueDateChange(defaultTime.toISOString());
                  setOpen(false);
                }
              }}
              initialFocus
            />
            {/* <div className="flex items-center gap-2 p-3">
              <Hour />
              <Minute />
              <AMPM />
            </div> */}
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
}

function StageDependency({
  stages,
  dependentOn,
  onSelect,
}: {
  stages: ProjectData["stages"];
  dependentOn: { _id?: string };
  onSelect: (value: string) => void;
}) {
  const [open, setOpen] = useState(false);
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="secondary"
          role="combobox"
          aria-expanded={open}
          className="max-w-[200px] space-x-2 truncate"
        >
          <SquareIcon className="h-4 w-4 shrink-0" />
          {!!dependentOn._id ? (
            <div className="truncate">
              {stages?.find((s) => s._id?.toString() === dependentOn._id)?.name}
            </div>
          ) : (
            <div className="flex space-x-1 text-gray-400/80">
              Stage
              <span className="top-0 ml-0.5 text-primary-main">*</span>
            </div>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        animateOut={false}
        className="pointer-events-auto max-h-[300px] w-fit min-w-[200px] max-w-[700px] p-0"
        align="start"
      >
        <Command>
          <CommandInput placeholder="Search stage..." className="h-9" />
          <CommandEmpty>No stage found.</CommandEmpty>
          <CommandGroup>
            {stages.map((s) => (
              <CommandItem
                key={s._id.toString()}
                value={s.name}
                onSelect={() => {
                  onSelect(s._id);
                  setOpen(false);
                }}
                className="gap-2"
              >
                <span className="truncate">{s.name}</span>
                <CheckIcon
                  className={cn(
                    "ml-auto h-4 w-4 shrink-0",
                    dependentOn._id?.toString() === s?._id?.toString()
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function TaskDependency({
  tasks,
  dependentOn,
  onSelect,
}: {
  tasks: TaskData[];
  dependentOn: { _id?: string };
  onSelect: (value: string) => void;
}) {
  const [open, setOpen] = useState(false);

  return (
    <Popover modal={true} open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="secondary"
          role="combobox"
          aria-expanded={open}
          className="max-w-[200px] space-x-2 truncate"
        >
          <BoltIcon className="h-4 w-4 shrink-0" />
          {!!dependentOn?._id ? (
            <div className="truncate">
              {tasks?.find((t) => t._id?.toString() === dependentOn._id)?.title}
            </div>
          ) : (
            <div className="flex space-x-1 text-gray-400/80">
              Task
              <span className="top-0 ml-0.5 text-primary-main">*</span>
            </div>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        animateOut={false}
        className="pointer-events-auto max-h-[300px] w-fit min-w-[200px] max-w-[700px] p-0"
        align="start"
      >
        <Command>
          <CommandInput placeholder="Search task..." className="h-9" />
          <CommandEmpty>No task found.</CommandEmpty>
          <CommandGroup>
            {tasks
              ?.filter(
                (t) =>
                  t.status === "To-do" ||
                  t.status === "In Progress" ||
                  t.status === "Stuck"
              )
              .map((t) => (
                <CommandItem
                  key={t?._id?.toString()}
                  value={t.title}
                  onSelect={() => {
                    if (t._id) {
                      onSelect(t._id?.toString());
                    }
                    setOpen(false);
                  }}
                  className="gap-2"
                >
                  {/* {t.journeyStage?._id} */}
                  <span className="truncate">{t.title}</span>
                  <CheckIcon
                    className={cn(
                      "ml-auto h-4 w-4 shrink-0",
                      dependentOn._id?.toString() === t?._id?.toString()
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                </CommandItem>
              ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function ConditionSelect({
  value,
  onSelect,
}: {
  onSelect: (value: "task" | "stage") => void;
  value: string;
}) {
  return (
    <Select
      value={value}
      onValueChange={(value) => {
        onSelect(value as "task" | "stage");
      }}
    >
      <SelectTrigger className="w-fit gap-2" showIcon={false}>
        {value === "task" ? "completion of task" : "completion of stage"}
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem value="task">completion of task</SelectItem>
          <SelectItem value="stage">completion of stage</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

function ValueInput({
  value,
  onValueChange,
}: {
  value: number;
  onValueChange: (value: number) => void;
}) {
  const [localValue, setLocalValue] = useState(String(value));
  useEffect(() => {
    setLocalValue(String(value));
  }, [value]);
  return (
    <TextInput
      value={localValue}
      className="h-8 w-[70px]"
      onChange={(e) => {
        const rx = new RegExp(/^\d+$/);
        if (rx.test(e.target.value) || e.target.value === "") {
          setLocalValue(e.target.value);
        }
      }}
      onBlur={(e) => {
        if (e.target.value === "") {
          onValueChange(1);
        } else {
          onValueChange(parseInt(e.target.value));
        }
      }}
    />
  );
}

function UnitSelect({
  value,
  onValueChange,
  isPlural,
}: {
  value: string;
  onValueChange: (value: string) => void;
  isPlural: boolean;
}) {
  return (
    <Select
      value={value}
      onValueChange={(value) => {
        onValueChange(value);
      }}
    >
      <SelectTrigger className="w-fit gap-2" showIcon={false}>
        {value + (isPlural ? "s" : "")}
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {relativeDueDateUnits
            .filter(
              (unit) =>
                unit !== "minute" && unit !== "month" && unit !== "quarter"
            )
            .map((unit) => (
              <SelectItem value={unit}>{unit + "s"}</SelectItem>
            ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

function TaskerComboboxWrapper() {
  const newTaskCtx = useNewTaskForInternalUser();
  return (
    <>
      {!!newTaskCtx.state.project ? (
        <ProjectTaskerComboboxWrapper
          projectId={newTaskCtx.state.project._id}
        />
      ) : (
        <InstanceTaskerComboboxWrapper />
      )}
    </>
  );
  // return (
  //   <TaskerCombobox
  //     tasker={newTaskCtx.state.tasker}
  //     onTaskerSelect={(tasker) => {
  //       newTaskCtx.dispatch({
  //         type: "tasker_update",
  //         tasker: tasker,
  //       });
  //       // if (newTaskCtx.state.tasker?.type === "external") {
  //       //   newTaskCtx.dispatch({
  //       //     type: "visibility_update",
  //       //     isVisible: true,
  //       //   });
  //       // }
  //     }}
  //   />
  // );
}

function InstanceTaskerComboboxWrapper() {
  const newTaskCtx = useNewTaskForInternalUser();
  const app = useRealmApp();
  const { data: taskers } = useGetTaskersForInstance();
  return (
    <TaskerCombobox
      required={true}
      selectedTasker={newTaskCtx.state.tasker}
      onTaskerSelect={(tasker) => {
        newTaskCtx.dispatch({
          type: "tasker_update",
          tasker: tasker,
        });
      }}
      taskers={taskers?.map((t) => {
        return {
          _id: t._id,
          name: t.name,
          email: t.email,
          userType: "fullUser",
          type: "internal",
          role: "subscriber",
        };
      })}
    />
  );
}

function ProjectTaskerComboboxWrapper({ projectId }: { projectId: string }) {
  const newTaskCtx = useNewTaskForInternalUser();
  const { data: projectMembers } = useGetProjectMembers(projectId);
  return (
    <TaskerCombobox
      required={true}
      selectedTasker={newTaskCtx.state.tasker}
      onTaskerSelect={(tasker) => {
        newTaskCtx.dispatch({
          type: "tasker_update",
          tasker: tasker,
        });
        if (tasker?.type === "external") {
          newTaskCtx.dispatch({
            type: "visibility_update",
            isVisible: true,
          });
        }
      }}
      taskers={[
        ...(projectMembers?.internal.map((im) => {
          return {
            _id: im._id,
            name: im.name,
            email: im.email,
            userType: "fullUser" as "fullUser",
            role: im.role,
            type: "internal" as "internal",
          };
        }) ?? []),
        ...(projectMembers?.external.map((em) => {
          if (em.userType === "fullUser") {
            return {
              _id: em._id,
              name: em.name,
              email: em.email,
              userType: "fullUser" as "fullUser",
              role: em.role,
              type: "external" as "external",
            };
          } else {
            return {
              name: em.email,
              email: em.email,
              userType: "invitedUser" as "invitedUser",
              role: em.role,
              type: "external" as "external",
            };
          }
        }) ?? []),
      ]}
    />
  );
}

export function TaskerCombobox({
  selectedTasker,
  taskers,
  onTaskerSelect,
  required,
}: {
  taskers: TaskerData[] | undefined;
  selectedTasker: TaskerData | undefined;
  onTaskerSelect: (tasker: TaskerData | undefined) => void;
  required: boolean;
}) {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="secondary"
          role="combobox"
          aria-expanded={open}
          className="max-w-[200px] space-x-1.5 truncate font-normal"
        >
          <UserIcon viewBox="4 0 20 24" className="h-3.5 shrink-0" />
          {/* {!!selectedTasker && (
            <div
              className={clsx(
                "h-1.5 w-1.5 shrink-0 rounded-full bg-gray-300",
                selectedTasker.type === "internal"
                  ? "bg-gray-300"
                  : "bg-slate-700"
              )}
            />
          )} */}
          {selectedTasker ? (
            <div className="truncate">
              {selectedTasker.userType === "fullUser"
                ? selectedTasker.name
                : selectedTasker.email}
            </div>
          ) : (
            <div className="flex space-x-1 text-gray-400/80">
              Assignee
              {required && (
                <span className="top-0 ml-0.5 text-primary-main">*</span>
              )}
            </div>
          )}
          {/* <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" /> */}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        animateOut={false}
        className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
        align="start"
      >
        <Command>
          <CommandInput placeholder="Search assignee..." className="h-9" />
          <CommandEmpty>No assignee found.</CommandEmpty>
          <CommandGroup>
            {taskers?.map((tasker) => (
              <CommandItem
                key={tasker.email}
                value={
                  tasker.userType === "fullUser" ? tasker.name : tasker.email
                }
                onSelect={() => {
                  onTaskerSelect(tasker);
                  setOpen(false);
                }}
                className="gap-2"
              >
                <div className="flex items-center space-x-2">
                  <div
                    className={clsx(
                      "h-2 w-2 shrink-0 rounded-full",
                      tasker.type === "internal"
                        ? "bg-gray-300"
                        : "bg-slate-700"
                    )}
                  />
                  <span className="truncate">
                    {tasker.userType === "fullUser"
                      ? tasker.name
                      : tasker.email}
                  </span>
                </div>
                <CheckIcon
                  className={cn(
                    "ml-auto h-4 w-4 shrink-0",
                    selectedTasker?.email === tasker.email
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function VisibilitySelectWrapper() {
  const newTaskCtx = useNewTaskForInternalUser();
  return (
    <VisibilitySelect
      isVisible={newTaskCtx.state.isVisible}
      onVisibilitySelect={(value) => {
        newTaskCtx.dispatch({
          type: "visibility_update",
          isVisible: value,
        });
      }}
      disabled={newTaskCtx.state.tasker?.type === "external"}
    />
  );
}

function VisibilitySelect({
  isVisible,
  onVisibilitySelect,
  disabled,
}: {
  isVisible: boolean;
  onVisibilitySelect: (value: boolean) => void;
  disabled: boolean;
}) {
  const [open, setOpen] = useState(false);
  return (
    <Select
      value={isVisible ? "true" : "false"}
      onValueChange={(value) => {
        onVisibilitySelect(value === "true");
        setOpen(false);
      }}
      disabled={disabled}
    >
      <SelectTrigger showIcon={false} className="w-fit">
        <div className="flex items-center space-x-2">
          {isVisible ? (
            <EyeIcon className="h-3.5 w-3.5" />
          ) : (
            <EyeSlashIcon className="h-3.5 w-3.5" />
          )}
          <div>{isVisible ? "Visible" : "Hidden"}</div>
        </div>
      </SelectTrigger>
      <SelectContent className="min-w-[120px]">
        <SelectGroup>
          <SelectItem value={"true"}>
            <div>
              <div className="flex items-start space-x-2">
                <div className="pt-1">
                  <EyeIcon className="h-3.5 w-3.5" />
                </div>
                <div>
                  <div>Visible</div>
                  <div className="text-xs text-gray-400">
                    Task is visible to external users
                  </div>
                </div>
              </div>
            </div>
          </SelectItem>
          <SelectItem value={"false"}>
            <div>
              <div className="flex items-start space-x-2">
                <div className="pt-1">
                  <EyeSlashIcon className="h-3.5 w-3.5" />
                </div>
                <div>
                  <div>Hidden</div>
                  <div className="text-xs text-gray-400">
                    Task is hidden from external users
                  </div>
                </div>
              </div>
            </div>
          </SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
