export const stringAvatar = (name: string) => {
  let tempName = "";
  for (const str of name.split(" ")) {
    tempName += str[0];
  }
  return tempName;
};

export const getFirstAndLastInitials = (name: string) => {
  const initials = name
    .split(" ")
    .map((n, i) => {
      if (i === 0) {
        return n[0];
      } else if (i === name.split(" ").length - 1) {
        return n[0];
      }
      return "";
    })
    .join("");
  return initials;
};
