import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { ObjectID } from "bson";
import { ObjectId } from "mongodb";
import { useNavigate } from "react-router-dom";
import {
  buildFetcher,
  buildSetter,
  buildSetterWithInstanceIdProvided,
} from "src/api";
import { useAccessToken } from "src/api/useAccessToken";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { FeedItem } from "src/components/Services/SharedSpace/Dashboard/types";
import {
  SharedSpace,
  SharedSpaceInvite,
} from "src/components/Services/SharedSpace/Spaces/types";
import { useRealmApp } from "src/store/RealmApp";
import { TaskerType } from "src/types/Services/Tasks";
import { UserResponseObj } from "src/types/Services/general";
import { sortByCreatedAt } from "src/utils/functions/sorts";
import { JourneyData } from "../Journeys/journeys";
import {
  InviteEmail,
  ProjectMembership,
  projectKeys,
} from "../Projects/projects";
import { TaskData } from "../Tasks/tasks";

export const sharedSpaceKeys = {
  sharedSpaces: () => ["sharedSpaces", "all"] as const,
  sharedSpacesForUser: (userId: string) =>
    ["sharedSpaces", "all", "user", userId] as const,
  sharedSpace: (sharedSpaceId: ObjectID) =>
    ["sharedSpaces", sharedSpaceId.toString()] as const,
  sharedSpaceFeed: (sharedSpaceId: ObjectID) =>
    ["sharedSpaces", sharedSpaceId.toString(), "feed"] as const,
  invites: (sharedSpaceId: ObjectID) =>
    [...sharedSpaceKeys.sharedSpace(sharedSpaceId), "invites", "all"] as const,
  invitesForInstance: () =>
    ["sharedSpaces", "invitesForInstance", "all"] as const,
  members: (sharedSpaceId: string) =>
    [
      ...sharedSpaceKeys.sharedSpace(new ObjectID(sharedSpaceId)),
      "members",
      "all",
    ] as const,
  invitedAndExistingMembers: (sharedSpaceId: ObjectID) =>
    [
      ...sharedSpaceKeys.sharedSpace(sharedSpaceId),
      "invitedAndExistingMembers",
      "all",
    ] as const,
  outsiders: (sharedSpaceId: ObjectID) =>
    [
      ...sharedSpaceKeys.sharedSpace(sharedSpaceId),
      "outsiders",
      "all",
    ] as const,
  journeys: (sharedSpaceId: ObjectID) =>
    [...sharedSpaceKeys.sharedSpace(sharedSpaceId), "journeys", "all"] as const,
  tasks: (sharedSpaceId: ObjectID) =>
    [...sharedSpaceKeys.sharedSpace(sharedSpaceId), "tasks", "all"] as const,
  taskBreakdown: (sharedSpaceId: ObjectID) =>
    [
      ...sharedSpaceKeys.sharedSpace(sharedSpaceId),
      "taskBreakdown",
      "all",
    ] as const,
  seroSharedSpace: () => ["sharedSpaces", "sero"] as const,
};

export type SharedSpaceData = {
  _id: ObjectId;
  name: string;
  customer: { _id: ObjectId; name: string; revenue?: number };
  instance: { _id: ObjectId; name: string; slug: string };
  isActive: boolean;
  externalMembersCanInvite: boolean;
  internalMembers: { _id: string; type: "user" | "team" };
  notificationsCount: number;
  // checkedForUpdatesAt: string;
  // updatesCount: number;
  // userTaskCount: number;
  // instanceTaskCount: number;
  // issuesCount: number;
  isPartOfSharedSpace: boolean;

  // instanceId: ObjectId;
  // toInstanceId?: ObjectId;
  createdAt: Date;
  updatedAt: Date;
  type: TaskerType;
};

// SERO SHARED SPACE
export const useGetSeroSharedSpace = () => {
  const app = useRealmApp();
  return useQuery(
    sharedSpaceKeys.seroSharedSpace(),
    async (): Promise<
      | {
          sharedSpaceExists: false;
        }
      | {
          sharedSpaceExists: true;
          sharedSpace: {
            _id: ObjectID;
            name: string;
          };
        }
    > => {
      // console.log(app.currentUser.customData.instanceId.$oid);
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/seroSharedSpace`,
        {
          headers: {
            Authorization: `Bearer ${app.currentUser?.accessToken}`,
            Accept: "application/json",
          },
          params: {
            instanceId: app.currentUser.customData.instanceId.$oid,
            seroInstanceId: process.env.REACT_APP_SERO_INSTANCE_ID,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};

// USERS

export const useGetIsSharedSpaceUser = (): {
  isSharedSpaceUser: boolean | undefined;
  isLoading: boolean;
} => {
  const app = useRealmApp();
  const queryClient = useQueryClient();
  const meta = {
    functionName: "isSharedSpaceUser",
    parameters: {},
  };

  const { isLoading, isError, error, data } = useQuery<boolean>(
    ["isSharedSpaceUser"],
    buildFetcher(app, meta)
  );

  return {
    isSharedSpaceUser: data,
    isLoading: isLoading,
  };
};

export const useGetUsersNotInSharedSpace = (
  sharedSpaceId: ObjectID,
  instanceId: ObjectID
): {
  usersNotInSharedSpace: UserResponseObj[] | undefined;
} => {
  const app = useRealmApp();
  const queryClient = useQueryClient();
  const meta = {
    functionName: "getUsersNotInSharedSpace",
    parameters: {
      sharedSpaceId: sharedSpaceId,
      instanceId: instanceId,
    },
  };

  const { isLoading, isError, error, data } = useQuery<UserResponseObj[]>(
    sharedSpaceKeys.outsiders(sharedSpaceId),
    buildFetcher(app, meta)
  );

  return {
    usersNotInSharedSpace: data,
  };
};

// export const useGetSharedSpaceMembers = (
//   sharedSpaceId: string
// ): {
//   sharedSpaceMembers: ProjectMe;
// } => {
//   const app = useRealmApp();
//   const queryClient = useQueryClient();
//   const meta = {
//     functionName: "getSharedSpaceMembers",
//     parameters: {
//       sharedSpaceId: new ObjectID(sharedSpaceId),
//       instanceId: app.currentUser.customData.instanceId,
//     },
//   };

//   const { isLoading, isError, error, data } = useQuery<UserResponseObj[]>(
//     sharedSpaceKeys.members(new ObjectID(sharedSpaceId)),
//     buildFetcher(app, meta)
//   );

//   return {
//     sharedSpaceMembers: data,
//   };
// };

export type TaskBreakdownPerTeam = {
  toDo: {
    overdue: number;
    total: number;
  };
  inProgress: {
    overdue: number;
    total: number;
  };
  completed: {
    late: number;
    onTime: number;
  };
};

type TaskBreakdown = {
  internal: TaskBreakdownPerTeam;
  external: TaskBreakdownPerTeam;
};

export const useGetTaskBreakdownForSharedSpace = (
  sharedSpaceId: string
): {
  taskBreakdown: TaskBreakdown | undefined;
} => {
  const app = useRealmApp();
  const queryClient = useQueryClient();
  const meta = {
    functionName: "getTaskBreakdownForSharedSpace",
    parameters: {
      sharedSpaceId: new ObjectID(sharedSpaceId),
    },
  };

  const { data } = useQuery<TaskBreakdown>(
    sharedSpaceKeys.taskBreakdown(new ObjectID(sharedSpaceId)),
    buildFetcher(app, meta)
  );

  return {
    taskBreakdown: data,
  };
};

export type SharedSpaceMember = {
  _id?: ObjectID;
  name: string;
  email: string;
  type: TaskerType;
};

export const useGetSharedSpaceMembersForJourneyLaunch = (
  sharedSpaceId: string
): {
  sharedSpaceMembers: SharedSpaceMember[] | undefined;
} => {
  const app = useRealmApp();
  const queryClient = useQueryClient();
  const meta = {
    functionName: "getSharedSpaceMembersForJourneyLaunch",
    parameters: {
      sharedSpaceId: new ObjectID(sharedSpaceId),
      instanceId: app.currentUser.customData.instanceId,
    },
  };

  const { data } = useQuery<UserResponseObj[]>(
    sharedSpaceKeys.invitedAndExistingMembers(new ObjectID(sharedSpaceId)),
    buildFetcher(app, meta)
  );

  return {
    sharedSpaceMembers: data,
  };
};

export const useAddInternalMemberToSharedSpace = () => {
  const app = useRealmApp();
  const functionName = "addInternalMemberToSharedSpace";
  const fieldName = "params";
  const queryClient = useQueryClient();
  const snackbarCtx = useSnackBar();

  const addInternalMemberToSharedSpaceMutation = useMutation(
    buildSetter(app, functionName, fieldName),
    {
      onMutate: async ({
        params,
      }: {
        params: {
          sharedSpaceId: ObjectID;
          member: {
            _id: ObjectID;
            type: "user" | "team";
          };
        };
      }) => {
        // const queryKey = journeyKeys.stages(params.journeyId);
        // await queryClient.cancelQueries(queryKey);
        // const previousStages = queryClient.getQueryData(queryKey);
        // const newStage: JourneyStageObj = {
        //   ...params,
        //   createdAt: new Date(),
        //   _id: undefined,
        // };
        // queryClient.setQueryData(queryKey, (stages: JourneyStageObj[] = []) => [
        //   ...stages,
        //   newStage,
        // ]);
        // return { previousStages };
      },
      onError: (data, variables, context) => {
        snackbarCtx.showSnackbar("Error adding internal user", "error");
        // queryClient.setQueryData(
        //   journeyTemplateKeys.stageTemplates(variables.params.journeyId),
        //   context?.previousStages
        // );
      },
      onSettled: (data, error, variables) => {
        snackbarCtx.showSnackbar("Successfuly added internal user", "success");
        queryClient.invalidateQueries(
          sharedSpaceKeys.members(variables.params.sharedSpaceId.toString())
        );
        queryClient.invalidateQueries(
          sharedSpaceKeys.outsiders(variables.params.sharedSpaceId)
        );
        // console.log(data)
        // navigate()
      },
    }
  );

  return addInternalMemberToSharedSpaceMutation;
};

// SPACES

export const useGetSharedSpacesForUser = (): {
  sharedSpaces: SharedSpaceData[] | undefined;
} => {
  const app = useRealmApp();
  const queryClient = useQueryClient();
  const meta = {
    functionName: "getSharedSpacesForUser",
    parameters: {
      instanceId: app.currentUser.customData.instanceId,
    },
  };

  const { isLoading, isError, error, data, isSuccess } = useQuery<
    SharedSpaceData[]
  >(
    sharedSpaceKeys.sharedSpacesForUser(app.currentUser.customData._id),
    buildFetcher(app, meta)
  );

  let sharedSpaces;
  if (isSuccess && data) {
    sharedSpaces = [...data];
    sharedSpaces = sortByCreatedAt(sharedSpaces);
  }

  return {
    sharedSpaces: sharedSpaces,
  };
};

export const useGetSharedSpacesForInstance = () => {
  const app = useRealmApp();
  const queryClient = useQueryClient();
  const meta = {
    functionName: "getSharedSpacesForInstance",
    parameters: {
      instanceId: app.currentUser.customData.instanceId,
    },
  };

  return useQuery<SharedSpaceData[]>(
    sharedSpaceKeys.sharedSpacesForUser(app.currentUser.customData._id),
    buildFetcher(app, meta),
    {
      staleTime: Infinity,
    }
  );

  // let sharedSpaces;
  // if (isSuccess && data) {
  //   sharedSpaces = [...data];
  //   sharedSpaces = sortByCreatedAt(sharedSpaces);
  // }

  // return {
  //   sharedSpaces: sharedSpaces,
  // };
};

// export const useGetSharedSpacesForInstance = () => {
//   const app = useRealmApp();
//   return useQuery(
//     sharedSpaceKeys.sharedSpaces(),
//     async (): Promise<SharedSpaceData[]> => {
//       const res = await axios.get(
//         // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
//         `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/getSharedSpacesForInstance`,
//         {
//           headers: {
//             Authorization: `Bearer ${app.currentUser?.accessToken}`,
//             Accept: "application/json",
//           },
//           params: {
//             instanceId: app.currentUser.customData.instanceId.$oid,
//           },
//         }
//       );
//       return res.data;
//     },
//     {
//       staleTime: Infinity,
//     }
//   );
// };

// SPACE

export const useGetSharedSpace = (
  _id: ObjectID
): {
  sharedSpace: SharedSpaceData | undefined;
} => {
  const app = useRealmApp();
  const queryClient = useQueryClient();
  const meta = {
    functionName: "getSharedSpace",
    parameters: {
      _id: _id,
    },
  };

  const { isLoading, isError, error, data } = useQuery<SharedSpaceData>(
    sharedSpaceKeys.sharedSpace(_id),
    buildFetcher(app, meta),
    {
      staleTime: Infinity,
    }
  );

  return {
    sharedSpace: data,
  };
};

export const useCreateSharedSpace = () => {
  const app = useRealmApp();
  const functionName = "createSharedSpace";
  const fieldName = "params";
  const queryClient = useQueryClient();
  const snackbarCtx = useSnackBar();
  const navigate = useNavigate();

  const createSharedSpaceMutation = useMutation(
    buildSetterWithInstanceIdProvided(app, functionName, fieldName),
    {
      onMutate: async ({
        params,
      }: {
        params: {
          customerId?: ObjectID;
          name: string;
        };
      }) => {},
      onError: (error: Error, variables, context) => {
        // show object keys
        console.error("Error:", error.message);
        // console.log(error);
        // console.error('Status Code:', error.response.status);

        // console.log(error.message)
        // console.log(typeof error);
        snackbarCtx.showSnackbar("Error creating shared space", "error");
      },
      onSuccess: (data: { id: string; message: string }, error, variables) => {
        if (data.id) {
          // snackbarCtx.showSnackbar("New shared space created", "success");
          navigate(`/shared-spaces/${data.id}`);
          queryClient.invalidateQueries(sharedSpaceKeys.sharedSpaces());
        }
      },
    }
  );

  return createSharedSpaceMutation;
};

export const useUpdateSharedSpace = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const functionName = "updateSharedSpace";
  const fieldName = "update";

  type UpdateType = Partial<SharedSpace>;

  const queryClient = useQueryClient();
  const updateSharedSpaceMutation = useMutation(
    buildSetter(app, functionName, fieldName),
    {
      onMutate: async ({
        update,
        sharedSpaceId,
      }: {
        update: UpdateType;
        sharedSpaceId: ObjectID;
      }) => {
        const queryKey = sharedSpaceKeys.sharedSpace(sharedSpaceId);
        const previousSharedSpace =
          queryClient.getQueryData<SharedSpace>(queryKey);
        await queryClient.cancelQueries(queryKey);

        const newSharedSpace = { ...previousSharedSpace, ...update };

        queryClient.setQueryData(queryKey, newSharedSpace);

        return { previousSharedSpace };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, update, context) => {
        if (update.sharedSpaceId)
          queryClient.setQueryData(
            sharedSpaceKeys.sharedSpace(update.sharedSpaceId),
            context?.previousSharedSpace
          );
        snackbarCtx.showSnackbar("Error updating settings", "error");
      },
      // Always refetch after error or success:
      onSettled: (data, error, variables) => {
        if (variables.sharedSpaceId)
          queryClient.invalidateQueries(
            sharedSpaceKeys.sharedSpace(variables.sharedSpaceId)
          );
      },
    }
  );
  return updateSharedSpaceMutation;
};

// FEED

export const useGetSharedSpaceFeed = (
  _id: ObjectID,
  view: TaskerType = "internal"
): {
  feed: FeedItem[] | undefined;
} => {
  const app = useRealmApp();
  const queryClient = useQueryClient();
  const meta = {
    functionName: "getFeedItems",
    parameters: {
      sharedSpaceId: _id,
    },
  };

  const { data } = useQuery<FeedItem[]>(
    sharedSpaceKeys.sharedSpaceFeed(_id),
    buildFetcher(app, meta)
  );

  let feed;
  if (!!data) {
    if (view === "external") {
      feed = data.filter((f) => f.metadata.isVisible);
    } else {
      feed = data;
    }
  }

  return {
    feed,
  };
};

// INVITES

export const useAddExternalMembersToPhase = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async ({
      members,
      metadata,
    }: {
      members: {
        sharedSpaceId: string;
        email: string;
        phaseId: string;
        sendInviteEmail: boolean;
        inviteEmail: InviteEmail;
        role: ProjectMembership;
      }[];
      metadata: {
        sharedSpaceId: string;
        phaseId: string;
      };
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/inviteUsersToSharedSpace`,
        members,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error adding users", "error");
    },
    onSuccess: (data, variables) => {
      snackbarCtx.showSnackbar("New members have been added!", "success");
      queryClient.invalidateQueries(
        sharedSpaceKeys.members(variables.metadata.sharedSpaceId)
      );
      queryClient.invalidateQueries(
        sharedSpaceKeys.invites(new ObjectID(variables.metadata.sharedSpaceId))
      );
      queryClient.invalidateQueries(
        projectKeys.members(variables.metadata.phaseId)
      );
    },
  });
};

export const useCreateSharedSpaceInviteForInstance = () => {
  const app = useRealmApp();
  const functionName = "createSharedSpaceInviteForInstance";
  const fieldName = "params";
  const queryClient = useQueryClient();
  const snackbarCtx = useSnackBar();
  const navigate = useNavigate();

  const inviteInstanceToSharedSpaceMutation = useMutation(
    buildSetter(app, functionName, fieldName),
    {
      onMutate: async ({
        params,
      }: {
        params: {
          instanceId: ObjectID;
          customerId: ObjectID;
          sharedSpaceId: ObjectID;
          slug: string;
        };
      }) => {
        // const queryKey = journeyKeys.stages(params.journeyId);
        // await queryClient.cancelQueries(queryKey);
        // const previousStages = queryClient.getQueryData(queryKey);
        // const newStage: JourneyStageObj = {
        //   ...params,
        //   createdAt: new Date(),
        //   _id: undefined,
        // };
        // queryClient.setQueryData(queryKey, (stages: JourneyStageObj[] = []) => [
        //   ...stages,
        //   newStage,
        // ]);
        // return { previousStages };
      },
      onError: (data, variables, context) => {
        snackbarCtx.showSnackbar(
          "Error inviting instance to shared space",
          "error"
        );
        // queryClient.setQueryData(
        //   journeyTemplateKeys.stageTemplates(variables.params.journeyId),
        //   context?.previousStages
        // );
      },
      onSettled: (data, error, variables) => {
        snackbarCtx.showSnackbar("Invite to join shared space sent", "success");
        // queryClient.invalidateQueries(
        //   sharedSpaceKeys.sharedSpaces()
        // );
        // queryClient.invalidateQueries(
        //   sharedSpaceKeys.invites(variables.params.sharedSpaceId)
        // );
        // console.log(data)
        // navigate()
      },
    }
  );

  return inviteInstanceToSharedSpaceMutation;
};

export const useResendSharedSpaceInviteEmail = () => {
  const app = useRealmApp();
  const functionName = "resendSharedSpaceInviteEmail";
  const fieldName = "params";
  const queryClient = useQueryClient();
  const snackbarCtx = useSnackBar();

  const resendSharedSpaceInviteEmailMutation = useMutation(
    buildSetter(app, functionName, fieldName),
    {
      onMutate: async ({
        params,
      }: {
        params: {
          sharedSpaceId: ObjectID;
          email: string;
          phaseId: ObjectID;
          inviteEmail: { subject: string; message: string; signature: string };
        };
      }) => {},
      onError: (data, variables, context) => {
        snackbarCtx.showSnackbar(
          "Error resending invite to the project",
          "error"
        );
      },
      onSuccess: (data, variables) => {
        snackbarCtx.showSnackbar(
          `An invite has been sent to ${variables.params.email}`,
          "success"
        );
      },
    }
  );
  return resendSharedSpaceInviteEmailMutation;
};

export const useGetPendingSharedSpaceInvites = (
  sharedSpaceId: ObjectID
): {
  sharedSpaceInvites: SharedSpaceInvite[] | undefined;
} => {
  const app = useRealmApp();
  const queryClient = useQueryClient();
  const meta = {
    functionName: "getPendingSharedSpaceInvites",
    parameters: {
      sharedSpaceId: sharedSpaceId,
    },
  };

  const { isLoading, isError, error, data } = useQuery<SharedSpaceInvite[]>(
    sharedSpaceKeys.invites(sharedSpaceId),
    buildFetcher(app, meta)
  );

  return {
    sharedSpaceInvites: data,
  };
};

export const useGetSharedSpaceInvitesForInstance = (): {
  sharedSpaceInvitesForInstance: any[] | undefined;
} => {
  const app = useRealmApp();
  const queryClient = useQueryClient();
  const meta = {
    functionName: "getSharedSpaceInvitesForInstance",
    parameters: {
      instanceId: app.currentUser.customData.instanceId,
    },
  };

  const { isLoading, isError, error, data } = useQuery<any[]>(
    sharedSpaceKeys.invitesForInstance(),
    buildFetcher(app, meta)
  );

  return {
    sharedSpaceInvitesForInstance: data,
  };
};

// JOURNEYS

//getJourneysForSharedSpace
export const useGetJourneysForSharedSpace = (
  _id: string
): {
  sharedSpaceJourneys: JourneyData[] | undefined;
} => {
  const app = useRealmApp();
  const queryClient = useQueryClient();
  const meta = {
    functionName: "getJourneysForSharedSpace",
    parameters: {
      _id: new ObjectID(_id),
    },
  };

  const { isLoading, isError, error, data } = useQuery<JourneyData[]>(
    sharedSpaceKeys.journeys(new ObjectID(_id)),
    buildFetcher(app, meta)
  );

  return {
    sharedSpaceJourneys: data,
  };
};

// TASKS

export const useGetTasksForSharedSpace = (_id: ObjectID) => {
  const app = useRealmApp();
  const queryClient = useQueryClient();
  const meta = {
    functionName: "getTasksForSharedSpace",
    parameters: {
      _id: _id,
    },
  };

  return useQuery<TaskData[]>(
    sharedSpaceKeys.tasks(_id),
    buildFetcher(app, meta)
  );
};
