import { Switch } from "@headlessui/react";
import { cva, VariantProps } from "class-variance-authority";
import clsx from "clsx";
import React from "react";

const toggleStyles = cva(
  "relative inline-flex items-center flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none ",
  {
    variants: {
      color: {
        green: "[--color:theme(colors.green.main)]",
        primary: "[--color:theme(colors.primary.main)]",
        embed: "[--color:theme(colors.embedPrimary)]",
      },
      size: {
        sm: "h-4 w-7",
        md: "h-6 w-11",
        lg: "h-8 w-14",
      },
      enabled: {
        true: "bg-[color:var(--color)]",
        false: "bg-gray-200",
      },
      disabled: {
        true: "bg-[color:var(--color)]/40 cursor-default",
        // false: "bg-[color:var(--color)]",
      },
      focus: {
        true: "focus:ring-[color:var(--color)] focus:ring-2  focus:ring-offset-2",
        false: "focus:ring-0",
      }
    },
    // compoundVariants: [
    //   {
    //     // color: "primary",
    //     disabled: true,
    //     enabled: true,
    //     className: "bg-[color:var(--color)]/40 cursor-default",
    //   },
    //   {
    //     // color: "primary",
    //     disabled: false,
    //     enabled: true,
    //     className: "bg-[color:var(--color)] cursor-pointer",
    //   }
    // ],
    defaultVariants: {
      color: "primary",
      size: "md",
      // enabled: false,
    },
  }
);

type ToggleProps = {
  setEnabled: (_: boolean) => void;
  rightSideLabel?: React.ReactNode;
  leftSideLabel?: React.ReactNode;
};

export interface Props extends ToggleProps, VariantProps<typeof toggleStyles> {}

export const VariantToggle = ({
  enabled,
  setEnabled,
  rightSideLabel,
  leftSideLabel,
  disabled = false,
  color,
  size,
  focus=false
}: Props) => {
  return (
    <Switch.Group as="div" className="flex items-center">
      {!!leftSideLabel && (
        <Switch.Label as="span" className="mr-3">
          {leftSideLabel}
        </Switch.Label>
      )}
      <Switch
        disabled={Boolean(disabled)}
        checked={Boolean(enabled)}
        onChange={setEnabled}
        className={toggleStyles({ disabled, color, size, enabled, focus })}
      >
        <span
          aria-hidden="true"
          className={clsx(
            enabled
              ? size === "sm"
                ? "translate-x-3"
                : size === "md"
                ? "translate-x-5"
                : "translate-x-7"
              : "translate-x-0",
            "pointer-events-none inline-block transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
            size === "sm" ? "h-3 w-3" : size === "md" ? "h-5 w-5" : "h-7 w-7"
          )}
        />
      </Switch>
      {!!rightSideLabel && (
        <Switch.Label as="span" className="ml-3">
          {rightSideLabel}
        </Switch.Label>
      )}
    </Switch.Group>
  );
};