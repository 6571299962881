import { XMarkIcon } from "@heroicons/react/24/outline";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { ObjectId } from "mongodb";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { journeyTemplateKeys } from "src/api/Services/Journeys/journeyTemplates";
import { Badge } from "src/components/ui/Badges/Badge";
import { Button } from "src/components/ui/Buttons/Button";
import InputHeading from "src/components/ui/Headings/InputHeading";
import TextArea from "src/components/ui/Inputs/Text/TextArea";
import { Modal } from "src/components/ui/Modals/Modal";
import { ModalCloseIcon } from "src/components/ui/Modals/ModalCloseIcon";
import ModalHeading from "src/components/ui/Modals/ModalHeading";
import { TextInput } from "src/components/ui/text-input";
import { useRealmApp } from "src/store/RealmApp";
import { TemplateType, useNewTemplate } from "./NewTemplateContextProvider";
import { ObjectID } from "bson";
type Props = {};

export const NewTemplateModal = (props: Props) => {
  const newTemplateCtx = useNewTemplate();
  return (
    <Modal
      isOpen={newTemplateCtx.state.isModalOpen}
      closeModal={() => {
        newTemplateCtx.dispatch({ type: "modal_close" });
      }}
    >
      <div className="w-[600px] p-6">
        <Steps />
      </div>
    </Modal>
  );
};

const Steps = () => {
  const [step, setStep] = useState(0);

  const nextStep = () => {
    setStep((prev) => prev + 1);
  };

  const prevStep = () => {
    setStep((prev) => prev - 1);
  };

  switch (step) {
    case 0:
      return <Step0 nextStep={nextStep} />;
    case 1:
      return <InternalRoles nextStep={nextStep} prevStep={prevStep} />;
    case 2:
      return <ExternalRoles nextStep={nextStep} prevStep={prevStep} />;
    case 3:
      return <Stages prevStep={prevStep} />;
  }
  return <></>;
};

const Stages = ({ prevStep }: { prevStep: () => void }) => {
  const [stage, setStage] = useState("");
  const newTemplateCtx = useNewTemplate();
  const app = useRealmApp();
  const navigate = useNavigate();
  const { spaceId } = useParams();

  const addStage = () => {
    const transformedStage = stage.trim();
    if (transformedStage !== "") {
      if (newTemplateCtx.state.stages.includes(transformedStage)) {
        // set error
      } else {
        // add stage
        newTemplateCtx.dispatch({
          type: "stages_add",
          stage: transformedStage,
        });
        setStage("");
      }
    }
  };
  const queryClient = useQueryClient();

  return (
    <div className="space-y-8">
      <div className="flex items-start justify-between">
        <div className="space-y-1">
          <div className="text-sm text-gray-400">Step 4 of 4</div>
          <ModalHeading
            title="Stages"
            subtitle="Projects are made up of stages and stages are made up of tasks. Define the stages that break down your project."
          />
        </div>
        <ModalCloseIcon
          onClick={() => {
            newTemplateCtx.dispatch({ type: "modal_close" });
          }}
        />
      </div>

      <div className="space-y-4">
        <div className="w-1/2 space-y-1">
          <div className="space-y-1">
            <InputHeading heading="Stage" />
            <TextInput
              autoFocus
              name="stage"
              placeholder="Kickoff, Installation, Review, etc."
              onChange={(e) => {
                setStage(e.target.value);
              }}
              value={stage}
              // RightIcon={ArrowLongLeftIcon}
              // rightIconOnClick={addStage}
              // rightIconDisabled={stage.trim() === ""}
              // rightIconTooltip="Click or press Enter to add"
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  addStage();
                }
              }}
            />
          </div>
          <div className="text-xs text-gray-500">
            Press{" "}
            <span
              className={clsx(
                "font-medium",
                stage.trim() !== "" && "text-primary"
              )}
            >
              Enter
            </span>{" "}
            to add
          </div>
        </div>
        <div className="space-y-2">
          {/* <p className="text-sm font-medium text-gray-600">Stages</p> */}
          {newTemplateCtx.state.stages.length === 0 ? (
            // <p className="text-sm text-gray-400">No stages added</p>
            <></>
          ) : (
            <div className="flex flex-wrap gap-2">
              {newTemplateCtx.state.stages.map((stage) => (
                <Badge
                  clickable={true}
                  text={stage}
                  key={stage}
                  onIconClick={() => {
                    newTemplateCtx.dispatch({
                      type: "stages_delete",
                      stage: stage,
                    });
                  }}
                  Icon={XMarkIcon}
                  color="gray"
                  size={"lg"}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="inline-flex w-full justify-end">
        <div className="mt-4 space-x-2">
          <Button text="Back" onClick={prevStep} size="xs" fill="outline" />

          <Button
            text="Create"
            onClick={() => {
              console.log(spaceId);
              const journeyTemplate = {
                name: newTemplateCtx.state.name,
                internalDescription: newTemplateCtx.state.internalDescription,
                type: newTemplateCtx.state.type.value,
                internalPersonas: newTemplateCtx.state.internalPersonas,
                externalPersonas: newTemplateCtx.state.externalPersonas,
                instanceId: app.currentUser.customData?.instanceId,
                stages: newTemplateCtx.state.stages,
                phaseType: "Customer Onboarding",
                spaceId: new ObjectID(spaceId),
              };
              try {
                app.currentUser.functions
                  .createJourneyTemplate(journeyTemplate)
                  .then(
                    (result: {
                      status: number;
                      message: string;
                      id: ObjectId;
                    }) => {
                      queryClient.invalidateQueries(
                        journeyTemplateKeys.journeyTemplates()
                      );
                      navigate(`/templates/projects/${result.id.toString()}`, {
                        state: { journeyTemplateId: result.id },
                      });
                    }
                  );
              } catch (error) {
                console.log(error);
              }
            }}
            size="xs"
          />
        </div>
      </div>
    </div>
  );
};

type PersonaType = "Internal" | "External";
const personaOptions: PersonaType[] = ["Internal", "External"];

const ExternalRoles = ({
  nextStep,
  prevStep,
}: {
  nextStep: () => void;
  prevStep: () => void;
}) => {
  const newTemplateCtx = useNewTemplate();
  const [persona, setPersona] = useState("");
  const [personaType, setPersonaType] = useState<PersonaType>("External");

  const addPersona = () => {
    const transformedPersona = persona.trim();
    if (transformedPersona === "") return;
    if (personaType === "Internal") {
      // make sure not duplicate
      if (newTemplateCtx.state.internalPersonas.includes(transformedPersona)) {
        // set error text
      } else {
        // add persona
        newTemplateCtx.dispatch({
          type: "internal_personas_add",
          persona: transformedPersona,
        });
        setPersona("");
      }
    } else {
      // make sure not duplicate
      if (newTemplateCtx.state.externalPersonas.includes(transformedPersona)) {
        // set error text
      } else {
        // add persona
        newTemplateCtx.dispatch({
          type: "external_personas_add",
          persona: transformedPersona,
        });
        setPersona("");
      }
    }
  };

  return (
    <div className="space-y-8">
      <div className="flex items-start justify-between">
        <div className="space-y-1">
          <div className="text-sm text-gray-400">Step 3 of 4</div>
          <ModalHeading
            title="External Roles"
            subtitle="Define the key stakeholders from other organizations that will be involved in this project. You will assign them tasks in upcoming sections."
          />
        </div>
        <ModalCloseIcon
          onClick={() => {
            newTemplateCtx.dispatch({ type: "modal_close" });
          }}
        />
      </div>

      {/* <p className="text-md font-medium text-gray-600">Roles</p> */}

      <div className="space-y-4">
        <div className="w-1/2 space-y-1">
          <div className="space-y-1">
            <InputHeading heading="Role" />
            <TextInput
              autoFocus
              name="role"
              placeholder={"Champion, Decision Maker, etc."}
              onChange={(e) => {
                setPersona(e.target.value);
              }}
              // rightIconDisabled={persona.trim() === ""}
              // rightIconOnClick={addPersona}
              value={persona}
              // RightIcon={ArrowLongLeftIcon}
              // rightIconTooltip="Click or press Enter to add"
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  addPersona();
                }
              }}
            />
          </div>
          <div className="text-xs text-gray-500">
            Press{" "}
            <span
              className={clsx(
                "font-medium",
                persona.trim() !== "" && "text-primary"
              )}
            >
              Enter
            </span>{" "}
            to add
          </div>
          {/* {newTemplateCtx.state.type.value === "external" && (
            <div className="w-[120px]">
              <Select
                options={personaOptions}
                onChange={(value) => {
                  setPersonaType(value);
                }}
                displayFn={(o) => o}
                keyFn={(o) => o}
                selected={personaType}
              />
            </div>
          )} */}
        </div>

        <div className="space-y-2">
          {newTemplateCtx.state.externalPersonas.length === 0 ? (
            // <p className="text-sm text-gray-400">No roles added</p>
            <></>
          ) : (
            <div className="flex flex-wrap gap-2">
              {newTemplateCtx.state.externalPersonas.map((p) => (
                <Badge
                  clickable={true}
                  text={p}
                  key={p}
                  onIconClick={() => {
                    newTemplateCtx.dispatch({
                      type: "external_personas_delete",
                      persona: p,
                    });
                  }}
                  Icon={XMarkIcon}
                  color="gray"
                  size={"lg"}
                />
              ))}
            </div>
          )}
        </div>
      </div>

      {/* {newTemplateCtx.state.type.value === "external" && (
        <div className="space-y-2">
          <p className="text-sm font-medium text-gray-600">External</p>
          {newTemplateCtx.state.externalPersonas.length === 0 ? (
            <p className="text-sm text-gray-400">No roles added</p>
          ) : (
            <div className="flex flex-wrap gap-2">
              {newTemplateCtx.state.externalPersonas.map((p) => (
                <Badge
                  clickable={true}
                  text={p}
                  key={p}
                  onIconClick={() => {
                    newTemplateCtx.dispatch({
                      type: "external_personas_delete",
                      persona: p,
                    });
                  }}
                  Icon={XMarkIcon}
                  color="gray"
                  size={"lg"}
                />
              ))}
            </div>
          )}
        </div>
      )} */}
      <div className="inline-flex w-full justify-end">
        <div className="space-x-2">
          <Button text="Back" onClick={prevStep} size="xs" fill="outline" />
          <Button text="Next" onClick={nextStep} size="xs" />
        </div>
      </div>
    </div>
  );
};

const InternalRoles = ({
  nextStep,
  prevStep,
}: {
  nextStep: () => void;
  prevStep: () => void;
}) => {
  const newTemplateCtx = useNewTemplate();
  const [persona, setPersona] = useState("");
  const [personaType, setPersonaType] = useState<PersonaType>("Internal");

  const addPersona = () => {
    const transformedPersona = persona.trim();
    if (transformedPersona === "") return;
    if (personaType === "Internal") {
      // make sure not duplicate
      if (newTemplateCtx.state.internalPersonas.includes(transformedPersona)) {
        // set error text
      } else {
        // add persona
        newTemplateCtx.dispatch({
          type: "internal_personas_add",
          persona: transformedPersona,
        });
        setPersona("");
      }
    } else {
      // make sure not duplicate
      if (newTemplateCtx.state.externalPersonas.includes(transformedPersona)) {
        // set error text
      } else {
        // add persona
        newTemplateCtx.dispatch({
          type: "external_personas_add",
          persona: transformedPersona,
        });
        setPersona("");
      }
    }
  };

  return (
    <div className="space-y-8">
      <div className="flex items-start justify-between">
        <div className="space-y-1">
          <div className="text-sm text-gray-400">Step 2 of 4</div>
          <ModalHeading
            title="Internal Roles"
            subtitle="Define the key stakeholders from your team that will be involved in this project. You will assign them tasks in upcoming sections."
          />
        </div>
        <ModalCloseIcon
          onClick={() => {
            newTemplateCtx.dispatch({ type: "modal_close" });
          }}
        />
      </div>

      {/* <p className="text-md font-medium text-gray-600">Roles</p> */}

      <div className="space-y-4">
        <div className="w-1/2 space-y-1">
          <div className="space-y-1">
            <InputHeading heading="Role" />
            <TextInput
              autoFocus
              name="role"
              placeholder={"Implementation Specialist, CSM, etc."}
              onChange={(e) => {
                setPersona(e.target.value);
              }}
              // rightIconDisabled={persona.trim() === ""}
              // rightIconOnClick={addPersona}
              value={persona}
              // RightIcon={ArrowLongLeftIcon}
              // rightIconTooltip="Click or press Enter to add"
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  addPersona();
                }
              }}
            />
          </div>
          <div className="text-xs text-gray-500">
            Press{" "}
            <span
              className={clsx(
                "font-medium",
                persona.trim() !== "" && "text-primary"
              )}
            >
              Enter
            </span>{" "}
            to add
          </div>
          {/* {newTemplateCtx.state.type.value === "external" && (
            <div className="w-[120px]">
              <Select
                options={personaOptions}
                onChange={(value) => {
                  setPersonaType(value);
                }}
                displayFn={(o) => o}
                keyFn={(o) => o}
                selected={personaType}
              />
            </div>
          )} */}
        </div>

        <div className="space-y-2">
          {newTemplateCtx.state.internalPersonas.length === 0 ? (
            // <p className="text-sm text-gray-400">No roles added</p>
            <></>
          ) : (
            <div className="flex flex-wrap gap-2">
              {newTemplateCtx.state.internalPersonas.map((p) => (
                <Badge
                  clickable={true}
                  text={p}
                  key={p}
                  onIconClick={() => {
                    newTemplateCtx.dispatch({
                      type: "internal_personas_delete",
                      persona: p,
                    });
                  }}
                  Icon={XMarkIcon}
                  color="gray"
                  size={"lg"}
                />
              ))}
            </div>
          )}
        </div>
      </div>

      {/* {newTemplateCtx.state.type.value === "external" && (
        <div className="space-y-2">
          <p className="text-sm font-medium text-gray-600">External</p>
          {newTemplateCtx.state.externalPersonas.length === 0 ? (
            <p className="text-sm text-gray-400">No roles added</p>
          ) : (
            <div className="flex flex-wrap gap-2">
              {newTemplateCtx.state.externalPersonas.map((p) => (
                <Badge
                  clickable={true}
                  text={p}
                  key={p}
                  onIconClick={() => {
                    newTemplateCtx.dispatch({
                      type: "external_personas_delete",
                      persona: p,
                    });
                  }}
                  Icon={XMarkIcon}
                  color="gray"
                  size={"lg"}
                />
              ))}
            </div>
          )}
        </div>
      )} */}
      <div className="inline-flex w-full justify-end">
        <div className="space-x-2">
          <Button text="Back" onClick={prevStep} size="xs" fill="outline" />
          <Button text="Next" onClick={nextStep} size="xs" />
        </div>
      </div>
    </div>
  );
};

const Step0 = ({ nextStep }: { nextStep: () => void }) => {
  const newTemplateCtx = useNewTemplate();
  const options: TemplateType[] = [
    { name: "Internal", value: "internal" },
    { name: "External", value: "external" },
  ];
  return (
    <div className="space-y-8">
      <div className="flex items-start justify-between">
        <div className="space-y-1">
          <div className="text-sm text-gray-400">Step 1 of 4</div>
          <ModalHeading
            title="New Project Template"
            subtitle="Streamline & standardize your work with customizable project templates. Save time and maintain consistent quality in all your projects."
          />
        </div>
        <ModalCloseIcon
          onClick={() => {
            newTemplateCtx.dispatch({ type: "modal_close" });
          }}
        />
      </div>
      <div className="space-y-4">
        <div className="w-1/2">
          <div className="space-y-1">
            <InputHeading heading="Name" />
            <TextInput
              // name="project name"
              name="ProjectName"
              autoComplete="off"
              value={newTemplateCtx.state.name}
              onChange={(e) => {
                newTemplateCtx.dispatch({
                  type: "name_update",
                  name: e.target.value,
                });
              }}
              placeholder="Onboarding"
            />
          </div>
        </div>
        <div>
          <TextArea
            name="description"
            label="Description"
            value={newTemplateCtx.state.internalDescription}
            onChange={(e) => {
              newTemplateCtx.dispatch({
                type: "internal_description_update",
                description: e.target.value,
              });
            }}
            placeholder="This template is used for..."
          />
        </div>
      </div>
      {/* <div className="max-w-[250px] space-y-1">
        <div className="inline-flex items-center space-x-1">
          <InputHeading heading="Type" />
          <ToolTip title="Internal templates are used for internal stakeholder journeys. External templates are used for customer journeys.">
            <InformationCircleIcon className="h-4 w-4 cursor-pointer text-gray-400 hover:text-primary-main" />
          </ToolTip>
        </div>
        <Select
          options={options}
          displayFn={(o) => {
            return o.name;
          }}
          onChange={(v) => {
            newTemplateCtx.dispatch({ type: "type_update", templateType: v });
          }}
          keyFn={(o) => {
            return o.value;
          }}
          selected={newTemplateCtx.state.type}
        />
      </div> */}
      <div className="inline-flex w-full justify-end">
        <Button
          text="Next"
          onClick={nextStep}
          size="xs"
          disabled={newTemplateCtx.state.name.trim() === ""}
        />
      </div>
    </div>
  );
};
