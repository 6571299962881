import { useRef } from "react";
import { useGetInstance } from "src/api/General/instance";
import { useGetSettings } from "src/api/General/settings";
import {
  useGetLogo,
  useUploadLogoForInstance,
} from "src/api/Misc/Settings/Embedding/embed";
import APIKey from "src/components/Misc/Settings/Embedding/APIKey";
import { FontType } from "src/components/Misc/Settings/Embedding/FontType";
import { PrimaryColor } from "src/components/Misc/Settings/Embedding/PrimaryColor";
import { Button } from "src/components/ui/button";

type Props = {};

const EmbeddingSettings = (props: Props) => {
  const { data: settings } = useGetSettings();
  const { instance } = useGetInstance();
  return (
    <div>
      <div className="flex h-12 items-center border-b border-gray-200/70 px-8 text-sm">
        {instance?.name}
      </div>
      <div className="mx-auto max-w-2xl pt-20">
        <div className="space-y-1 border-b border-gray-200/70 pb-6">
          <div className="text-xl font-medium">Embed</div>
          <div className="text-sm font-light text-gray-600">
            Settings for the external view of your projects
          </div>
        </div>
        <div className="space-y-12 pt-8">
          <div className="space-y-2.5">
            <div className="space-y-0.5">
              <div className="font-medium text-gray-800">Theme</div>
              <div className="text-sm font-light text-gray-600">
                Logo, color and font type
              </div>
            </div>
            <div className="mt-8 space-y-10">
              <div className="space-y-10 rounded-md bg-gray-50 p-6 shadow-sm">
                <div className="flex">
                  <label className="w-40 shrink-0 text-sm font-light text-gray-600">
                    Logo
                  </label>
                  <Logo />
                </div>
                <div className="flex">
                  <label className="w-40 shrink-0 text-sm font-light text-gray-600">
                    Primary Color
                  </label>
                  {!!settings && <PrimaryColor settings={settings} />}
                </div>
                <div className="flex">
                  <label className="w-40 shrink-0 text-sm font-light text-gray-600">
                    Font Type
                  </label>
                  {!!settings && <FontType settings={settings} />}
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-2.5">
            <div className="space-y-0.5">
              <div className="font-medium text-gray-800">Embed API</div>
              <div className="text-sm font-light text-gray-600">
                Embed Sero projects in your website via an iframe. Access your
                workspace API key here.
              </div>
            </div>
            <div className="space-y-10 rounded-md bg-gray-50 p-6 shadow-sm">
              <div className="flex">
                <label className="w-40 shrink-0 text-sm font-light text-gray-600">
                  API Key
                </label>
                <APIKey />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmbeddingSettings;

function Logo() {
  const uploadLogoMutation = useUploadLogoForInstance();
  const { logo, isLoading } = useGetLogo();
  const fileInputRef = useRef<HTMLInputElement>(null);

  return (
    <div className="space-y-3">
      <div className="flex items-center space-x-5">
        {isLoading ? (
          <div className="inline-flex h-14 w-14 animate-pulse items-center justify-center rounded-full bg-gray-300" />
        ) : (
          <>
            {!!logo ? (
              <img src={logo.url} className="h-[40px]" />
            ) : (
              <div className="inline-flex h-14 w-14 items-center justify-center rounded-full bg-gray-300 text-white">
                {/* capitalize first letters of the words */}
                <span>{"Logo"}</span>
              </div>
            )}
          </>
        )}
        <div className="space-y-">
          <Button
            size="fit"
            variant={"secondaryLink"}
            onClick={() => {
              fileInputRef.current?.click();
            }}
            disabled={uploadLogoMutation.isLoading}
            className="font-normal text-gray-500"
          >
            {uploadLogoMutation.isLoading ? (
              <>Uploading..</>
            ) : (
              <>{`${!isLoading && !!logo ? "Change" : "Add"}`} logo</>
            )}
          </Button>
          <p className="text-xs font-light text-gray-600">
            JPG or PNG up to 5MB
          </p>
          <input
            id="file-upload"
            name="file-upload"
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            className="sr-only"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={(e) => {
              const selectedFile = e.target.files?.[0];
              if (selectedFile) {
                if (
                  !/^[a-zA-Z0-9_ -]+\.[a-zA-Z0-9]+$/.test(selectedFile.name)
                ) {
                  alert(
                    "The file name must consist solely of letters, numbers, underscores, hyphens, and a single dot. Please ensure it adheres to this format."
                  );
                } else if (selectedFile.size > 5 * 1024 * 1024) {
                  alert("File size should not exceed 5MB.");
                } else {
                  uploadLogoMutation.mutate({
                    file: selectedFile,
                  });
                }
              }
            }}
          />
        </div>
      </div>

      {/* {(!isLoading && !logo) || isReplacing ? (
        <>
          <div className="min-w-[150px]">
            {!!file ? (
              <div className="space-y-3">
                <div className="inline-flex items-center space-x-2">
                  <img
                    src={`data:${file.type};base64,${encodedFile}`}
                    className="h-14 w-auto"
                  />
                  <span className="max-w-[250px] truncate rounded-md border border-primary-main px-2 py-1.5 text-xs text-primary-main">
                    {file.name}
                  </span>
                  <ToolTip title="Remove file">
                    <IconButton
                      iconComponent={<XMarkIcon className="h-4 w-4" />}
                      size="xs"
                      fill="outline"
                      color={"primary"}
                      onClick={() => {
                        setFile(undefined);
                        setEncodedFile("");
                      }}
                    />
                  </ToolTip>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      uploadLogoMutation.mutate({
                        file,
                      });
                      setIsReplacing(false);
                    }}
                  >
                    Upload
                  </Button>
                </div>
              </div>
            ) : (
              <div className="space-y-3">
                <div className="flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                  <div className="space-y-1 text-center">
                    <div className="flex text-sm text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-md bg-white text-center font-medium text-indigo-600  focus-within:outline-none hover:text-indigo-500"
                      >
                        <span>
                          {!!file ? "Select a new file" : "Select a file"}
                        </span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          accept="image/png, image/jpeg, image/jpg"
                          className="sr-only"
                          onChange={(e) => {
                            // console.log(e.target.files);
                            const selectedFile = e.target.files?.[0];
                            if (selectedFile) {
                              if (
                                !/^[a-zA-Z0-9_-]+\.[a-zA-Z0-9]+$/.test(
                                  selectedFile.name
                                )
                              ) {
                                alert(
                                  "The file name must consist solely of letters, numbers, underscores, hyphens, and a single dot. Please ensure it adheres to this format."
                                );
                              } else if (selectedFile.size > 10 * 1024 * 1024) {
                                alert("File size should not exceed 10MB.");
                              } else {
                                setFile(selectedFile);
                                // const reader = new FileReader();
                                // reader.readAsDataURL(selectedFile);
                                // reader.onload = () => {
                                //   const dataUrl = reader.result as string;
                                //   const match = dataUrl.match(
                                //     /^data:.+\/(.+);base64,(.*)$/
                                //   );

                                //   if (match) {
                                //     const contentType = match[1];
                                //     const base64String = match[2];
                                //     setEncodedFile(base64String);
                                //     // do something with the base64 string
                                //   }
                                // };
                              }
                            }
                          }}
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs text-gray-500">
                      JPG or PNG up to 10MB
                    </p>
                  </div>
                </div>
                {isReplacing && (
                  <Button
                    variant={"secondary"}
                    onClick={() => {
                      setIsReplacing(false);
                    }}
                  >
                    Cancel
                  </Button>
                )}
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          {logo && (
            <div className="inline-flex items-center space-x-2">
              <img src={logo.url} className="h-14 pr-4" />
              <ToolTip title="Replace logo">
                <IconButton
                  iconComponent={
                    <ArrowUpCircleIcon className="h-5 w-5 text-gray-300 hover:text-primary-main" />
                  }
                  size="xs"
                  fill="outline"
                  color={"primary"}
                  ring={false}
                  onClick={() => {
                    setIsReplacing(true);
                  }}
                />
              </ToolTip>
            </div>
          )}
        </>
      )} */}
      {/* {isEdited && (
        <Button
          text="Update Font Type"
          onClick={() => {
            updateSettingsMutation.mutate({
              update: {
                embed: {
                  fontType: selected.type,
                },
              },
            });
          }}
          size="xs"
          fill="outline"
        />
      )} */}
    </div>
  );
}
