import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useRealmApp } from "src/store/RealmApp";
import { useAccessToken } from "../useAccessToken";

export const projectAnalyticsKeys = {
  performance: () => ["analytics", "project", "performance"] as const,
  stageDistribution: (projectTemplateId: string) =>
    ["analytics", "project", projectTemplateId, "stage-distribution"] as const,
};

export type ProjectPerformanceData = {
  _id: string;
  name: string;
  type: "Customer Onboarding";
  revenue?: number;
  ownerUser: {
    _id: string;
    name: string;
    email: string;
  };
  isActive: boolean;
  createdAt: string;
  customer: {
    _id: string;
    name: string;
    revenue?: number;
  };
  sharedSpace: {
    _id: string;
    name: string;
  };
  journey: {
    _id: string;
    name: string;
  };
  template: {
    _id: string;
    name: string;
  };
  keyDates: {
    startedDate: string;
    plannedCompletionDate: string;
    forecastedCompletionDate: string;
    completionDate: string;
  };
  allTasksBreakdown: {
    internal: TasksBreakdown;
    external: TasksBreakdown;
  };
  userTasksBreakdown: TasksBreakdown;
};

type TasksBreakdown = {
  toDo: {
    total: number;
    overdue: number;
  };
  inProgress: {
    total: number;
    overdue: number;
  };
  completed: {
    onTime: number;
    late: number;
  };
  canceled: number;
};

export const useGetProjectPerformance = (isActive: boolean = true) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    projectAnalyticsKeys.performance(),
    async (): Promise<ProjectPerformanceData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/projectPerformance`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            // isActive,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};

type StageDistributionData = {
  name: string;
  projects: {
    _id: string;
    name: string;
    customer: string;
    revenue: number;
  }[]
}

export const useGetStageDistributionForProjectTemplate = (
  projectTemplateId: string
) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    projectAnalyticsKeys.stageDistribution(projectTemplateId),
    async (): Promise<StageDistributionData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/stageDistributionForProjectTemplate`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            projectTemplateId,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};
