import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useAccessToken } from "src/api/useAccessToken";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { useRealmApp } from "src/store/RealmApp";

export const statusGroupKeys = {
  statusGroups: (instanceId: string) =>
    ["status-groups", "instance", instanceId] as const,
};

export type ParentStatus =
  | "To-do"
  | "In Progress"
  | "Completed"
  | "Canceled"
  | "Backlog"
  | "Stuck";

export type StatusGroupData = {
  _id: string;
  name: string;
  isCustom: boolean;
  statuses: StatusData[];
  instanceId: string;
  createdAt: string;
  updatedAt: string;
  spaceId: string;
};

export type StatusData = {
  name: string;
  parentStatus: ParentStatus;
};

export const useGetStatusGroups = () => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    statusGroupKeys.statusGroups(app.currentUser.customData.instanceId.$oid),
    async (): Promise<StatusGroupData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/statusGroups`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            instanceId: app.currentUser.customData.instanceId.$oid,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};

export const useGetStatusGroupsFromSlug = (slug: string) => {
  const getValidAccessToken = useAccessToken();
  return useQuery(
    statusGroupKeys.statusGroups(slug),
    async (): Promise<StatusGroupData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/statusGroups`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            slug,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};

export const useCreateStatusGroup = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  // const navigate = useNavigate();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (statusGroup: {
      name: string;
      statuses: StatusData[];
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/statusGroup`,
        {
          instanceId: app.currentUser.customData.instanceId.$oid,
          ...statusGroup,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error creating custom status", "error");
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(
        statusGroupKeys.statusGroups(app.currentUser.customData.instanceId.$oid)
      );
    },
  });
};
