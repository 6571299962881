import { useForm } from "react-hook-form";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { TextInput } from "src/components/ui/text-input";
// import zod
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { Button } from "src/components/ui/button";
import { useRealmApp } from "src/store/RealmApp";
import { z } from "zod";
type Props = {};

const RegisterSchema = z.object({
  email: z
    .string({
      required_error: "Email is required",
    })
    // .min(1, "Email is required")
    .email("Must be a valid email address"),
  password: z
    .string()
    .min(1, "Password is required")
    .min(12, "Password must be at least 12 characters long")
    .max(128, "Password must be at most 128 characters long")
    .regex(
      /(?=.*[A-Z])/,
      "Password must contain at least one uppercase character"
    )
    .regex(
      /(?=.*[!@#$%^&*])/,
      "Password must contain at least one special character: !, @, #, $, %, ^, &, or *"
    ),
});

// const PasswordAndCodeSchema = z.object({
//   password: z
//     .string()
//     .min(1, "Password is required")
//     .min(12, "Password must be at least 12 characters long")
//     .max(128, "Password must be at most 128 characters long")
//     .regex(
//       /(?=.*[A-Z])/,
//       "Password must contain at least one uppercase character"
//     )
//     .regex(
//       /(?=.*[!@#$%^&*])/,
//       "Password must contain at least one special character: !, @, #, $, %, ^, &, or *"
//     ),
//   // confirmPassword: z.string().min(8),
// });

export default function Register({}: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<z.infer<typeof RegisterSchema>>({
    resolver: zodResolver(RegisterSchema),
  });
  const app = useRealmApp();
  // const checkUserTypeForEmailMutation = useCheckUserTypeForEmail();
  // const sendEmailToExternalUserForRegistrationMutation =
  //   useSendEmailToExternalUserForRegistration();
  // const [type, setType] = useState<"pending" | "none" | "external">("pending");
  const [isRegistered, setIsRegistered] = useState(false);
  // useEffect(() => {
  //   if (checkUserTypeForEmailMutation.isSuccess) {
  //     if (checkUserTypeForEmailMutation.data.type === "none") {
  //       setType("none");
  //     } else if (checkUserTypeForEmailMutation.data.type === "external") {
  //       setType("external");
  //       sendEmailToExternalUserForRegistrationMutation.mutate({
  //         email: getValues("email"),
  //       });
  //     } else {
  //       console.log("-");
  //     }
  //   }
  // }, [checkUserTypeForEmailMutation.isSuccess]);

  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <div className="mx-auto w-fit rounded-md border p-20 shadow-md">
        <img src="/static/symbolLogo.svg" alt="logo" className="mx-auto w-16" />
        <div className="mt-4 text-center text-2xl font-medium">
          {"Create an account"}
        </div>
        {!isRegistered ? (
          <div className="mt-12 w-80">
            <form
              className="mx-auto mt-6 space-y-8"
              onSubmit={handleSubmit(async (data) => {
                try {
                  // Register the user

                  await app.emailPasswordAuth.registerUser({
                    email: data.email,
                    password: data.password,
                  });
                  setIsRegistered(true);

                  // After successful registration, take user to verification screen
                } catch (err: any) {
                  // snackbarCtx.showSnackbar(
                  //   "Check your email for a verification link",
                  //   "success"
                  // );
                  setIsRegistered(true);
                  // if (typeof err.error === "string") {
                  //   snackbarCtx.showSnackbar(
                  //     err.error[0].toUpperCase() + err.error.substring(1),
                  //     "error"
                  //   );
                  // }
                  // console.log(err);
                }

                // checkUserTypeForEmailMutation.mutate({ email: data.email });
              })}
            >
              <div className="space-y-1">
                <InputHeading heading="Email" />
                <TextInput {...register("email")} autoComplete="off" />
                {errors.email && (
                  <div className="text-xs text-red-500">
                    {errors.email.message}
                  </div>
                )}
              </div>
              <div className="space-y-1">
                <InputHeading heading="Password" />
                <TextInput {...register("password")} type="password" />
                {errors.password && (
                  <div className="text-xs text-red-500">
                    {errors.password.message}
                  </div>
                )}
              </div>

              <Button
                className="w-full"
                type="submit"
                size={"lg"}
                // disabled={checkUserTypeForEmailMutation.isLoading}
              >
                Next
              </Button>
            </form>
          </div>
        ) : (
          <div className="mt-4 text-center">
            <div className="w-80 pt-2 text-sm text-gray-500">
              Almost there! You should have received an email with a
              confirmation link. Click{" "}
              <span
                className="cursor-pointer underline hover:text-gray-700"
                onClick={async () => {
                  await app.emailPasswordAuth.retryCustomConfirmation({
                    email: getValues().email,
                  });
                }}
              >
                here
              </span>{" "}
              to resend the email if you have any issues.
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

// function PasswordForm({ email }: { email: string }) {
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//   } = useForm<z.infer<typeof PasswordAndCodeSchema>>({
//     resolver: zodResolver(PasswordAndCodeSchema),
//   });
//   const app = useRealmApp();
//   const navigate = useNavigate();

//   async function handleRegistration(email: string, password: string) {
//     await app.emailPasswordAuth.registerUser({ email, password });
//     navigate("/verification", { state: { email: email } });
//   }

//   return (
//     <form
//       className="mx-auto mt-6 max-w-xs space-y-8"
//       onSubmit={handleSubmit((data) => {
//         handleRegistration(email, data.password);
//         navigate("/verification", { state: { email: email } });
//       })}
//     >
//       <div className="space-y-1">
//         <InputHeading heading="Password" />
//         <TextInput {...register("password")} type="password" />
//         {errors.password && (
//           <div className="text-xs text-red-500">{errors.password.message}</div>
//         )}
//       </div>
//       <Button className="w-full" type="submit" size={"lg"}>
//         Create account
//       </Button>
//     </form>
//   );
// }
