import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";

export const useCompleteTaskFromEmail = () => {
  const snackbarCtx = useSnackBar();
  return useMutation({
    mutationFn: async (params: {
      taskId: string;
      userId: string | null;
    }): Promise<{
      message: string;
      isAlreadyCompleted: boolean;
      taskTitle: string;
    }> => {
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/completeTaskForUserFromEmail`,
        params,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error completing task completion", "error");
    },
  });
};
