import * as React from "react"
 
import { cn } from "src/utils/ui/ui"
 
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}
 
const TextInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "h-9 placeholder:text-gray-300 w-full rounded-md border border-input shadow-sm text-sm p-2 px-2.5 focus:border-primary-main focus:ring-[0.5px] focus:ring-primary outline-none transition-colors",
          // "flex h-9 bg-transparent px-3 py-1  transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
TextInput.displayName = "Text Input"
 
export { TextInput }