import { HTMLAttributes, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "src/components/ui/Navigation/Breadcrumbs";
import { PageHeading } from "src/components/ui/heading";
import { cn } from "src/utils/ui/ui";

type Props = {};

export default function Guides({}: Props) {
  const navigate = useNavigate();
  return (
    <>
      <div className="space-y-1 px-8 py-6">
        <Breadcrumbs
          breadcrumbs={[
            { title: "Analytics", route: "/analytics" },
            {
              title: "Guides",
            },
          ]}
        />
        <PageHeading heading={"Guides"} />
      </div>
      <div className="grid gap-6 px-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <GuideCard
          onClick={() => {
            navigate("/analytics/guides/pipeline-review");
          }}
        >
          <div className="text-lg font-medium">Pipeline Review</div>
          <div className="pt-1 text-sm text-gray-500">
            View the distribution of your projects across different stages. See which ones are behind schedule.
          </div>
        </GuideCard>
        {/* <GuideCard
          className="cursor-not-allowed hover:bg-white"
          onClick={() => {
            // navigate("/analytics/guides/team-bandwidth");
          }}
        >
          <div className="flex items-center space-x-2">
            <div className="text-xl font-medium">Team Bandwidth</div>
            <div className="rounded-full bg-gray-100 px-2 py-1 text-xs shrink-0 whitespace-nowrap">
              Coming Soon
            </div>
          </div>
          <div className="pt-1 text-sm text-gray-500">
            See what your team's workload is looking like.
          </div>
        </GuideCard> */}
        <GuideCard
          className="cursor-not-allowed hover:bg-white"
          onClick={() => {
            // navigate("/analytics/guides/external-activity");
          }}
        >
          <div className="flex items-center space-x-2">
            <div className="text-lg font-medium">External Activity</div>
            <div className="rounded-full bg-gray-100 px-2 py-1 text-xs shrink-0 whitespace-nowrap">
              Coming Soon
            </div>
          </div>
          <div className="pt-1 text-sm text-gray-500">
            Keep tabs on the activity of external members in projects.
          </div>
        </GuideCard>
      </div>
    </>
  );
}

type GuideCardProps = {} & HTMLAttributes<HTMLDivElement>;

const GuideCard = forwardRef<HTMLDivElement, GuideCardProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={cn(
          "h-30 cursor-pointer rounded-md border p-5 py-4 shadow hover:bg-gray-50",
          className
        )}
      >
        {children}
      </div>
    );
  }
);
