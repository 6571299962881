type Color = "error" | "warning" | "secondary";

export const getDurationDifference = (
  dueDate: Date
): [timeLeft: string, color: Color, overdue: boolean] => {
  let overdue = true;
  let timeLeft = "";
  const comparisonDate = new Date();
  const hours = (dueDate.getTime() - comparisonDate.getTime()) / (1000 * 3600);
  if (hours < -24 || hours > 24) {
    const time = Math.abs(Math.floor(hours / 24));
    timeLeft = time.toString() + (time === 1 ? " day" : " days");
  } else if (hours < -1 || hours > 1) {
    const time = Math.abs(Math.floor(hours));
    timeLeft = time.toString() + (time === 1 ? " hr" : " hrs");
  } else if (hours < 0 || hours > 0) {
    const time = Math.abs(Math.floor(hours * 60));
    timeLeft = time.toString() + (time === 1 ? " min" : " mins");
  }
  let color: Color = "warning";
  if (hours > 0) {
    overdue = false;
    color = "secondary";
  } else {
    color = "error";
  }
  return [timeLeft, color, overdue];
};

export const getActiveDays = (
  startedDate: Date,
  completionDate: Date = new Date()
): string => {
  const Difference_In_Time = completionDate.getTime() - startedDate.getTime();
  return Math.ceil(Difference_In_Time / (1000 * 3600 * 24)).toFixed(0);
};

export const getTimeDifference = (
  date1: Date,
  date2: Date
): {
  unit: "min" | "mins" | "hours" | "hour" | "day" | "days";
  absValue: number;
  isDate2After: boolean;
  absDifferenceInMs: number;
} => {
  const Difference_In_Time = Math.abs(date2.getTime() - date1.getTime());
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  const Difference_In_Months = Difference_In_Days / 30;
  const Difference_In_Hours = Difference_In_Time / (1000 * 3600);
  const Difference_In_Minutes = Difference_In_Time / (1000 * 60);
  if (Difference_In_Minutes < 60) {
    return {
      unit: Difference_In_Minutes > 1 ? "mins" : "min",
      absValue: Math.ceil(Difference_In_Minutes),
      isDate2After: date2 > date1,
      absDifferenceInMs: Difference_In_Time,
    };
  } else if (Difference_In_Hours < 24) {
    return {
      unit: Difference_In_Hours > 1 ? "hours" : "hour",
      absValue: Math.ceil(Difference_In_Hours),
      isDate2After: date2 > date1,
      absDifferenceInMs: Difference_In_Time,
    };
  } else {
    return {
      unit: Difference_In_Days > 1 ? "days" : "day",
      absValue: Math.ceil(Difference_In_Days),
      isDate2After: date2 > date1,
      absDifferenceInMs: Difference_In_Time,
    };
  }
  // else {
  //   return {
  //     unit: Difference_In_Months > 1 ? "months" : "month",
  //     absValue: Math.ceil(Difference_In_Months),
  //     isDate2After: date2 > date1,
  //   };
  // }
};

export function convertMinutesToDuration(minutes: number): {
  minutes: number;
  hours: number;
  days: number;
} {
  if (minutes > 0) {
    const days = Math.floor(minutes / (60 * 24));
    const hours = Math.floor((minutes % (60 * 24)) / 60);
    const mins = minutes % 60;
    return {
      minutes: mins,
      hours,
      days,
    };
  }

  return {
    minutes: 0,
    hours: 0,
    days: 0,
  };
}
