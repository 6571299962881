import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAccessToken } from "../useAccessToken";

const pipelineKeys = {
  stageDistribution: (projectTemplateId: string) =>
    ["analytics", "pipeline", projectTemplateId, "stage-distribution"] as const,
  stageDurations: (projectTemplateId: string) =>
    ["analytics", "pipeline", projectTemplateId, "stage-durations"] as const,
};

export type PipelineProjectStageData = {
  _id: string;
  name: string;
  templateExists: boolean;
  projects: {
    _id: string;
    name: string;
    customer: {
      _id: string;
      name: string;
    };
    revenue?: number;
    plannedDurationInStage: number;
    actualDurationInStage: number;
    plannedCompletionDate: string;
    forecastedCompletionDate: string;
    createdAt: string;
    totalTasks: number;
    completedTasks: number;
    // completionDate: string;
  }[];
};

export const useGetPipelineStagesForProjectTemplate = (templateId: string) => {
  const getValidAccessToken = useAccessToken();
  return useQuery(
    pipelineKeys.stageDistribution(templateId),
    async (): Promise<PipelineProjectStageData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/projectTemplatePipelineView`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            templateId,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};

export type ProjectDurationInStageData = {
  _id: string;
  name: string;
  customer: {
    _id: string;
    name: string;
  };
  revenue?: number;
  plannedCompletionDate: string;
  createdAt: string;
  stages: {
    _id: string;
    name: string;
    templateExists: boolean;
    stageExists: boolean;
    plannedDuration: number;
    actualDuration: number;
    completedTasks: number;
    totalTasks: number;
  }[];
  completionDate: string;
  forecastedCompletionDate: string;
  completedTasks: number;
  totalTasks: number;
  previousCompletedTasks: number;
  previousTotalTasks: number;
};

export const useGetProjectDurationsInStage = (templateId: string) => {
  const getValidAccessToken = useAccessToken();
  return useQuery(
    pipelineKeys.stageDurations(templateId),
    async (): Promise<ProjectDurationInStageData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/projectDurationsInStageForTemplate`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            templateId,
          },
        }
      );
      return res.data;
    }
  );
};
