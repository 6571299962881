import { DndContext, DragEndEvent, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  BoltIcon,
  DocumentTextIcon,
  EyeIcon,
  EyeSlashIcon,
  FlagIcon,
  TrashIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import {
  CaretSortIcon,
  CheckIcon,
  Cross2Icon,
  DragHandleDots2Icon,
  StopwatchIcon,
} from "@radix-ui/react-icons";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import Typography from "@tiptap/extension-typography";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useGetFormTemplates } from "src/api/Forms/forms";
import {
  TaskTemplateAction,
  TaskTemplateCreateRequest,
  TaskTemplateData,
  relativeDueDateUnits,
  useCreateTaskTemplate,
} from "src/api/Services/Journeys/journeyTemplates";
import { Button } from "src/components/ui/Buttons/Button";
import InputHeading from "src/components/ui/Headings/InputHeading";
import Select from "src/components/ui/Selects/Select";
import { VariantToggle } from "src/components/ui/Toggle/VariantToggle";
import { Button as NewButton } from "src/components/ui/button";
import { estimateToMinutes } from "src/pages/Services/Tasks/Tables/TasksTable";

import {
  MeetingType,
  Subtask as SubtaskType,
  TaskTemplateLaunchCondition,
  useTaskTemplate,
} from "./TaskTemplateContextProvider";

import { CloudArrowUpIcon, PhoneIcon } from "@heroicons/react/20/solid";
import { ObjectID } from "bson";
import { useParams } from "react-router-dom";
import {
  useGetJourneyTemplate,
  useGetStageAndTaskTemplatesList,
} from "src/api/Services/Tasks/journeys";
import { TaskerType } from "src/api/Services/Tasks/tasks";
import { Title } from "src/components/Services/Reusable/Tasks/Elements/Title";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "src/components/ui/command";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "src/components/ui/dialog";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import {
  Select as NewSelect,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { TextInput } from "src/components/ui/text-input";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "src/components/ui/tooltip";
import { useRealmApp } from "src/store/RealmApp";
import { cn } from "src/utils/ui/ui";
import TiptapEditor from "../../../../../../components/ui/editor/tiptap-editor";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

export const NewTaskTemplateModal = ({ isOpen, closeModal }: Props) => {
  const [createMore, setCreateMore] = useState(false);
  const taskTemplateCtx = useTaskTemplate();
  const app = useRealmApp();
  const { journeyTemplateId } = useParams();
  const createTaskTemplateMutation = useCreateTaskTemplate();
  const [isUpdatingDescription, setIsUpdatingDescription] = useState(false);
  const [isDiscardingOpen, setIsDiscardingOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
        autolink: true,
      }),
      Typography,
      Placeholder.configure({
        placeholder: "Add description...",
        showOnlyWhenEditable: false,
      }),
    ],
    content: taskTemplateCtx.state.description,
    onBlur: ({ editor }) => {
      taskTemplateCtx.dispatch({
        type: "description_update",
        description: editor.getHTML(),
      });
    },
  });

  useEffect(() => {
    if (createTaskTemplateMutation.isSuccess) {
      taskTemplateCtx.dispatch({ type: "title_update", title: "" });
      taskTemplateCtx.dispatch({ type: "description_update", description: "" });
      taskTemplateCtx.dispatch({
        type: "subtasks_update",
        subtasks: [
          {
            title: "",
            estimate: {
              unit: "minute",
              value: 10,
            },
            id: Math.random(),
            tasker: undefined,
            isVisible: true,
          },
        ],
      });
      if (!createMore) {
        closeModal();
      }
    }
  }, [createTaskTemplateMutation.isSuccess]);
  return (
    <Dialog
      open={isOpen}
      onOpenChange={(o) => {
        if (!o) {
          closeModal();
        }
      }}
    >
      {/* <ModalHeading title="New Task" /> */}
      <DialogContent
        className={clsx(
          "top-[8%] max-w-fit translate-y-0 p-0 focus-visible:outline-none data-[state=open]:slide-in-from-top-8"
          // closeState === "escape" && "ring-2 ring-primary"
        )}
        onPointerDownOutside={(e) => {
          // e.preventDefault();
          if (
            taskTemplateCtx.state.title.trim() !== "" ||
            taskTemplateCtx.state.description.trim() !== "" ||
            taskTemplateCtx.state.subtasks.length > 0
          ) {
            e.preventDefault();
            setIsDiscardingOpen(true);
          }
        }}
        onEscapeKeyDown={(e) => {
          // e.preventDefault();
          // console.log(taskTemplateCtx.state.subtasks)
          if (
            taskTemplateCtx.state.title.trim() !== "" ||
            taskTemplateCtx.state.description.trim() !== "" ||
            taskTemplateCtx.state.subtasks.length > 0
          ) {
            e.preventDefault();
            setIsDiscardingOpen(true);
          }
        }}
      >
        <div className="w-[800px] divide-gray-200">
          <Dialog
            open={isDiscardingOpen}
            onOpenChange={(o) => {
              setIsDiscardingOpen(o);
            }}
          >
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Discard Changes</DialogTitle>
                {/* <DialogDescription>Deatails for your new stage</DialogDescription> */}
              </DialogHeader>
              <div className="grid gap-4 py-4">
                <p>
                  Are you sure you want to discard the changes you have made?
                </p>
              </div>
              <DialogFooter>
                <div className="flex justify-end space-x-2">
                  <NewButton
                    variant="secondary"
                    onClick={() => {
                      setIsDiscardingOpen(false);
                    }}
                  >
                    Cancel
                  </NewButton>
                  <NewButton
                    onClick={() => {
                      setIsDiscardingOpen(false);
                      closeModal();
                    }}
                  >
                    Discard
                  </NewButton>
                </div>
              </DialogFooter>
            </DialogContent>
          </Dialog>
          <div className="p-6 pb-4">
            <Title
              title={taskTemplateCtx.state.title}
              onChange={(e) => {
                if (!e.target.value.includes("\n")) {
                  taskTemplateCtx.dispatch({
                    type: "title_update",
                    title: e.target.value,
                  });
                }
              }}
            />
            <div className="flex items-start gap-2 pt-2 pb-2">
              {/* <Testing /> */}
              <StageCombobox />
              <RoleCombobox />
              {taskTemplateCtx.state.options.hasSubTasks ? (
                <SubtasksPolledVisibility
                  isVisible={taskTemplateCtx.state.isVisible}
                />
              ) : (
                <div className="inline-flex h-8 items-center gap-1 rounded-md border border-gray-300 px-1 shadow-sm">
                  <TooltipProvider>
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger asChild>
                        <button
                          className={clsx(
                            "rounded p-1 outline-none focus-visible:ring-[1.25px] focus-visible:ring-primary",
                            taskTemplateCtx.state.isVisible
                              ? "bg-primary-100 text-primary-600"
                              : "text-gray-500 hover:bg-gray-100"
                          )}
                          onClick={() => {
                            taskTemplateCtx.dispatch({
                              type: "is_visible_update",
                              isVisible: true,
                            });
                          }}
                        >
                          <EyeIcon className="h-4 w-4" />
                        </button>
                      </TooltipTrigger>
                      <TooltipContent sideOffset={8}>
                        <p>Externally visible</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  <TooltipProvider>
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger asChild>
                        <button
                          className={clsx(
                            "rounded p-1 outline-none focus-visible:ring-[1.25px] focus-visible:ring-primary",
                            !taskTemplateCtx.state.isVisible
                              ? "bg-primary-100 text-primary-600"
                              : "text-gray-500 hover:bg-gray-100",
                            taskTemplateCtx.state.tasker?.type == "external" &&
                              "bg-gray-100 text-gray-300 hover:bg-gray-100"
                          )}
                          onClick={() => {
                            taskTemplateCtx.dispatch({
                              type: "is_visible_update",
                              isVisible: false,
                            });
                          }}
                          disabled={
                            taskTemplateCtx.state.tasker?.type == "external"
                          }
                        >
                          <EyeSlashIcon
                            className={`h-4 w-4 ${
                              taskTemplateCtx.state.tasker?.type === "external"
                                ? "text-gray-300"
                                : ""
                            }`}
                          />
                        </button>
                      </TooltipTrigger>
                      <TooltipContent sideOffset={8}>
                        <p>Externally hidden</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              )}
              {taskTemplateCtx.state.options.hasSubTasks ? (
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger className="cursor-default">
                      <SubtasksDurationSum />
                    </TooltipTrigger>
                    <TooltipContent
                      align="start"
                      side="bottom"
                      sideOffset={4}
                      className="w-fit max-w-[200px]"
                    >
                      <p>
                        Parent task duration is the sum of durations of its
                        subtasks
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : (
                <DurationSelect />
              )}
            </div>
            <div className="space-y-1 rounded-md border-gray-300 pb-4">
              {/* <div className="text-sm text-gray-600">Dynamic Due Date</div> */}
              <div className="flex items-center gap-2 text-sm text-gray-600">
                <span className="-mr-0.5 text-gray-500">Due</span>
                <ValueInput />
                <UnitSelect />
                <span className="-mx-0.5 text-gray-500">after</span>
                <ConditionSelect />
                {taskTemplateCtx.state.launchCondition ===
                  "completion of task" && <TaskTemplatesDependency />}
                {taskTemplateCtx.state.launchCondition ===
                  "completion of stage" && <StageTemplatesDependency />}
              </div>
            </div>
            <div className="pb-6">
              <div
                className={clsx(
                  // isUpdatingDescription
                  //   ? ""
                  //   : "cursor-pointer",
                  "group rounded-md  bg-gray-100 p-3"
                )}
                onClick={() => {
                  setIsUpdatingDescription(true);
                }}
                ref={containerRef}
                // onBlur={(event) => {
                //   if (
                //     !containerRef.current?.contains(event.relatedTarget as Node)
                //   ) {
                //     setIsUpdatingDescription(false);
                //   }
                // }}
              >
                {/* <NewDescription
                  description={taskTemplateCtx.state.description}
                  setDescription={(newDescription) => {
                    taskTemplateCtx.dispatch({
                      type: "description_update",
                      description: newDescription,
                    });
                  }}
                /> */}
                <TiptapEditor editor={editor} />
                {/* <Description
                  description={taskTemplateCtx.state.description}
                  onBlur={(description) => {
                    if (
                      description.trim() !==
                      taskTemplateCtx.state.description.trim()
                    ) {
                      taskTemplateCtx.dispatch({
                        type: "description_update",
                        description,
                      });
                    }
                  }}
                  isEditing={true}
                /> */}
                {/* <div
                  className={clsx(
                    "text-xs text-transparent transition-colors",
                    !isUpdatingDescription && "group-hover:text-gray-500"
                  )}
                >
                  Click to edit description
                </div> */}
              </div>
            </div>
            <div className="pb-1.5">
              <div className="text-lg font-medium text-gray-600">
                Extensions
              </div>
            </div>
            {!taskTemplateCtx.state.options.hasSubTasks &&
              taskTemplateCtx.state.action === "default" && (
                <div className="">
                  <div className="flex items-center space-x-3 text-gray-400">
                    {/* <Action
                  icon={
                    <div className="inline-flex h-5 w-5 items-center justify-center rounded border border-inherit">
                      <CheckIcon className="h-4 w-4" />
                    </div>
                  }
                  onClick={() => {
                    taskTemplateCtx.dispatch({
                      type: "action_update",
                      action: "checklist",
                    });
                    if (taskTemplateCtx.state.action === "checklist") {
                      taskTemplateCtx.dispatch({
                        type: "action_update",
                        action: "default",
                      });
                    }
                  }}
                  text="Checklist"
                  disabled={
                    taskTemplateCtx.state.options.hasSubTasks ||
                    taskTemplateCtx.state.action === "form"
                  }
                  active={taskTemplateCtx.state.action === "checklist"}
                /> */}

                    <Action
                      icon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M17 16a2.99 2.99 0 0 0-2.816 2H11c-1.654 0-3-1.346-3-3v-3.025A4.954 4.954 0 0 0 11 13h3.184A2.99 2.99 0 0 0 17 15a3 3 0 1 0 0-6a2.99 2.99 0 0 0-2.816 2H11c-1.654 0-3-1.346-3-3v-.184A2.99 2.99 0 0 0 10 5a3 3 0 1 0-6 0a2.99 2.99 0 0 0 2 2.816V15c0 2.757 2.243 5 5 5h3.184A2.99 2.99 0 0 0 17 22a3 3 0 1 0 0-6zm0-5a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zM7 4a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zm10 16a1.001 1.001 0 0 1 0-2a1.001 1.001 0 0 1 0 2z"
                            // stroke="currentColor"
                            strokeWidth={0}
                          />
                        </svg>
                      }
                      onClick={() => {
                        taskTemplateCtx.dispatch({
                          type: "options_update",
                          options: {
                            ...taskTemplateCtx.state.options,
                            hasSubTasks:
                              !taskTemplateCtx.state.options.hasSubTasks,
                          },
                        });
                      }}
                      text="Subtasks"
                      // disabled={
                      //   taskTemplateCtx.state.action === "form" ||
                      //   taskTemplateCtx.state.action === "checklist"
                      // }
                      // active={taskTemplateCtx.state.options.hasSubTasks}
                    />
                    <Action
                      icon={<DocumentTextIcon className="h-5 w-5" />}
                      onClick={() => {
                        taskTemplateCtx.dispatch({
                          type: "action_update",
                          action: "form",
                        });
                        if (taskTemplateCtx.state.action === "form") {
                          taskTemplateCtx.dispatch({
                            type: "action_update",
                            action: "default",
                          });
                        }
                      }}
                      text="Form"
                    />
                    <Action
                      icon={<PhoneIcon className="h-5 w-5" />}
                      onClick={() => {
                        taskTemplateCtx.dispatch({
                          type: "action_update",
                          action: "meeting",
                        });
                      }}
                      text="Call"
                    />
                    <Action
                      icon={<CloudArrowUpIcon className="h-5 w-5" />}
                      onClick={() => {
                        taskTemplateCtx.dispatch({
                          type: "action_update",
                          action: "fileUpload",
                        });
                      }}
                      text="File Upload"
                    />
                    {/* <Action
                  icon={<BookOpenIcon className="h-5 w-5" />}
                  onClick={() => {
                    taskTemplateCtx.dispatch({
                      type: "options_update",
                      options: {
                        ...taskTemplateCtx.state.options,
                        hasLinkedKnowledgeResourceTemplate: true,
                      },
                    });
                    if (
                      taskTemplateCtx.state.options
                        .hasLinkedKnowledgeResourceTemplate
                    ) {
                      taskTemplateCtx.dispatch({
                        type: "options_update",
                        options: {
                          ...taskTemplateCtx.state.options,
                          hasLinkedKnowledgeResourceTemplate: false,
                        },
                      });
                    }
                  }}
                  text="Resource"
                  active={
                    taskTemplateCtx.state.options
                      .hasLinkedKnowledgeResourceTemplate
                  }
                /> */}
                  </div>
                </div>
              )}
            <div>
              {taskTemplateCtx.state.action === "form" && (
                <div className="space-y-">
                  <div className="relative rounded-md bg-gray-100 p-4">
                    {/* <div className="flex items-center space-x-1">
                    <DocumentTextIcon className="h-4 w-4 text-gray-400" />
                    <div className="text-gray-400">{`Form Task`}</div>
                  </div> */}
                    {/* <div className="absolute top-2 right-2">
                      <NewButton
                        variant={"ghost"}
                        className="p-1.5 px-2"
                        onClick={() => {
                          taskTemplateCtx.dispatch({
                            type: "action_update",
                            action: "default",
                          });
                        }}
                      >
                        <TrashIcon className="h-4 w-4 text-gray-400" />
                      </NewButton>
                    </div> */}
                    <div className="flex items-end justify-between">
                      <div className="space-y-2">
                        <div className="">
                          <div className="flex items-center space-x-1">
                            {/* <DocumentTextIcon className="h-3.5 w-3.5" /> */}
                            <InputHeading heading="Form" required />
                          </div>
                          <div className="text-xs text-gray-500">
                            Select a form template to attach to this task
                          </div>
                        </div>
                        <FormTemplateCombobox />
                      </div>
                    </div>
                  </div>
                  {/* <button className="text-xs text-gray-400 transition-colors hover:text-gray-600">
                    Remove form from this task
                  </button> */}
                  <NewButton
                    variant={"secondaryLink"}
                    className="p-0"
                    size="sm"
                    onClick={() => {
                      taskTemplateCtx.dispatch({
                        type: "action_update",
                        action: "default",
                      });
                    }}
                  >
                    Remove{" "}
                    <span className="whitespace-pre font-bold"> Form </span>{" "}
                    from this task
                  </NewButton>
                </div>
              )}
              {taskTemplateCtx.state.options.hasSubTasks && <Subtasks />}
              {taskTemplateCtx.state.action === "meeting" && <Meeting />}
              {taskTemplateCtx.state.action === "fileUpload" && <FileUpload />}
              {/* {taskTemplateCtx.state.action === "fileUpload" && (

              )} */}
              {/* {!taskTemplateCtx.state.options.isSubTask ? (
              <>
                <div className="space-y-1">
                  <Title />
                  <Description />
                </div>
                <div className="flex space-x-8">
                  <Stage />
                  <Role />
                  <Visibility />
                </div>
                <div className="space-y-1">
                  <InputHeading heading="Due Date" />
                  <DueDate />
                </div>
                <div className="flex space-x-8">
                  <Tags />
                  {taskTemplateCtx.state.options
                    .hasLinkedKnowledgeResourceTemplate && <LinkedKRT />}
                </div>
              </>
            ) : ( */}
              <>
                {/* {taskTemplateCtx.state.options.isSubTask && (
                <div>
                  <ParentTask />
                </div>
              )} */}
                {/* <div className="flex space-x-8">
                <Stage />
                <Role />
                <Visibility />
              </div>

              <div className="space-y-1">
                <InputHeading heading="Due Date" />
                <DueDate />
              </div> */}
                {/* {taskTemplateCtx.state.action === "default" && (
                <div className="flex space-x-8">
                  {!taskTemplateCtx.state.options.hasSubTasks ? (
                    <div className="space-y-1">
                      <div className="inline-flex items-center space-x-1">
                        <InputHeading heading="Duration" />
                        
                      </div>
                      <Estimate />
                    </div>
                  ) : (
                    <div className="space-y-1">
                      <div className="inline-flex items-center space-x-1">
                        <InputHeading heading="Total Duration" />
                      </div>
                      <div>{<SubTaskDuration />}</div>
                    </div>
                  )}
                  {taskTemplateCtx.state.options
                    .hasLinkedKnowledgeResourceTemplate && <LinkedKRT />}
                </div>
              )}
              {taskTemplateCtx.state.action === "form" && (
                <FormTemplateSelect />
              )} */}
                {/* {taskTemplateCtx.state.options
                .hasLinkedKnowledgeResourceTemplate && (
                <div className="flex space-x-8"></div>
              )} */}
                {/* <Actions /> */}
              </>
              {/* <>
                <div className="space-y-1">
                  <Title />
                  <Description />
                </div>
                <div className="flex space-x-8">
                  <ParentTask />
                  <Role />
                </div>
              </> */}
              {/* )} */}
            </div>
          </div>

          <div className="p-6 py-4">
            <div className="flex justify-between gap-6">
              <div className="space-x-2">
                {/* {!taskTemplateCtx.state.options.hasSubTasks && (
                  <NewButton
                    disabled={taskTemplateCtx.state.action !== "default"}
                    variant={"secondary"}
                    onClick={() => {
                      taskTemplateCtx.dispatch({
                        type: "options_update",
                        options: {
                          ...taskTemplateCtx.state.options,
                          hasSubTasks:
                            !taskTemplateCtx.state.options.hasSubTasks,
                        },
                      });
                    }}
                    className="space-x-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M17 16a2.99 2.99 0 0 0-2.816 2H11c-1.654 0-3-1.346-3-3v-3.025A4.954 4.954 0 0 0 11 13h3.184A2.99 2.99 0 0 0 17 15a3 3 0 1 0 0-6a2.99 2.99 0 0 0-2.816 2H11c-1.654 0-3-1.346-3-3v-.184A2.99 2.99 0 0 0 10 5a3 3 0 1 0-6 0a2.99 2.99 0 0 0 2 2.816V15c0 2.757 2.243 5 5 5h3.184A2.99 2.99 0 0 0 17 22a3 3 0 1 0 0-6zm0-5a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zM7 4a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zm10 16a1.001 1.001 0 0 1 0-2a1.001 1.001 0 0 1 0 2z"
                        // stroke="currentColor"
                        strokeWidth={0}
                      />
                    </svg>
                    <span>{`Add Subtasks`}</span>
                  </NewButton>
                )} */}
              </div>
              <div className="inline-flex items-center space-x-4">
                <CreateMore
                  createMore={createMore}
                  setCreateMore={setCreateMore}
                />
                <div className="inline-flex items-center space-x-2">
                  <NewButton
                    variant={"secondary"}
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    Cancel
                  </NewButton>
                  <NewButton
                    onClick={() => {
                      if (
                        !taskTemplateCtx.state.journeyStageTemplate?._id ||
                        !taskTemplateCtx.state.tasker ||
                        !taskTemplateCtx.state.dependentOn
                      ) {
                        return;
                      }
                      let taskTemplate: TaskTemplateCreateRequest = {
                        title: taskTemplateCtx.state.title,
                        description: taskTemplateCtx.state.description,
                        relativeDueDate: taskTemplateCtx.state.relativeDueDate,
                        instanceId: app.currentUser.customData.instanceId.$oid,
                        taskerPersona: taskTemplateCtx.state.tasker?.persona,
                        taskerType: taskTemplateCtx.state.tasker?.type,
                        dependentOn: {
                          type: taskTemplateCtx.state.dependentOn.type,
                          _id: taskTemplateCtx.state.dependentOn._id?.toString(),
                        },
                        estimate: taskTemplateCtx.state.estimate,
                        journeyTemplateId: String(journeyTemplateId),
                        journeyStageTemplateId: String(
                          taskTemplateCtx.state.journeyStageTemplate._id
                        ),
                        tags: taskTemplateCtx.state.tags,
                        isVisible: taskTemplateCtx.state.isVisible,
                        knowledgeResourceTemplateIds:
                          taskTemplateCtx.state.krts.map((krt) =>
                            krt._id.toString()
                          ),
                        action: taskTemplateCtx.state.action,
                      };

                      if (taskTemplateCtx.state.action === "form") {
                        taskTemplate.formTemplateId =
                          taskTemplateCtx.state.formTemplate?._id;
                      }
                      if (taskTemplateCtx.state.action === "meeting") {
                        taskTemplate.meeting = taskTemplateCtx.state.meeting;
                      }

                      const subTaskTemplates = taskTemplateCtx.state.subtasks
                        .filter(
                          (
                            s
                          ): s is SubtaskType & {
                            tasker: NonNullable<SubtaskType["tasker"]>;
                          } => !!s.tasker
                        )
                        .map((subtask) => {
                          return {
                            title: subtask.title,
                            estimate: subtask.estimate,
                            taskerPersona: subtask.tasker.persona,
                            taskerType: subtask.tasker.type,
                            isVisible: subtask.isVisible,
                          };
                        });

                      createTaskTemplateMutation.mutate({
                        taskTemplate: taskTemplate,
                        subTaskTemplates: taskTemplateCtx.state.options
                          .hasSubTasks
                          ? subTaskTemplates
                          : [],
                        journeyStageTemplateId:
                          taskTemplateCtx.state.journeyStageTemplate._id.toString(),
                        journeyTemplateId: String(journeyTemplateId),
                      });
                    }}
                    disabled={
                      createTaskTemplateMutation.isLoading ||
                      taskTemplateCtx.state.title === "" ||
                      !taskTemplateCtx.state.tasker ||
                      (taskTemplateCtx.state.options.hasSubTasks &&
                        taskTemplateCtx.state.subtasks.filter(
                          (s) => s.title.trim() === ""
                        ).length > 0) ||
                      (taskTemplateCtx.state.launchCondition ===
                        "completion of task" &&
                        !taskTemplateCtx.state.dependentOn._id &&
                        taskTemplateCtx.state.dependentOn.type !== "task") ||
                      (taskTemplateCtx.state.launchCondition ===
                        "completion of stage" &&
                        !taskTemplateCtx.state.dependentOn._id &&
                        taskTemplateCtx.state.dependentOn.type !== "stage") ||
                      (taskTemplateCtx.state.action === "form" &&
                        !taskTemplateCtx.state.formTemplate?._id) ||
                      (taskTemplateCtx.state.action === "meeting" &&
                        taskTemplateCtx.state.meeting.link.trim() === "")
                    }
                    // disabledText={"Please fill in all required fields"}
                  >
                    {createTaskTemplateMutation.isLoading
                      ? "Creating"
                      : "Create"}
                  </NewButton>
                </div>
                {/* <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <span>
                        
                      </span>
                    </TooltipTrigger>
                    <TooltipContent align="center" side="bottom">
                      <p>Add to library</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider> */}
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default NewTaskTemplateModal;

function FileUpload() {
  const taskTemplateCtx = useTaskTemplate();
  return (
    <div>
      <div className="relative rounded-md bg-gray-100 p-4">
        <div>
          <InputHeading heading="File Upload" />
          <div className="text-xs text-gray-500">
            This task will require the user to upload a file
          </div>
        </div>
        {/* <div className="space-y-1">
          <InputHeading heading="File Upload Requirements" />
          <TextInput
            value={taskTemplateCtx.state.meeting.link}
            onChange={(e) => {
              taskTemplateCtx.dispatch({
                type: "meeting_update",
                meeting: {
                  ...taskTemplateCtx.state.meeting,
                  link: e.target.value,
                },
              });
            }}
            placeholder={
              taskTemplateCtx.state.meeting.vendor === "hubspot"
                ? "https://meetings.hubspot.com/meeting"
                : "https://calendly.com/meeting"
            }
            className="w-[400px]"
          />
        </div> */}
      </div>
      <NewButton
        variant={"secondaryLink"}
        className="p-0"
        size="sm"
        onClick={() => {
          taskTemplateCtx.dispatch({
            type: "action_update",
            action: "default",
          });
        }}
      >
        Remove <span className="whitespace-pre font-bold"> File Upload </span>{" "}
        from this task
      </NewButton>
    </div>
  );
}

function Meeting() {
  const taskTemplateCtx = useTaskTemplate();
  return (
    <div>
      <div className="relative rounded-md bg-gray-100 p-4">
        {/* <NewButton
          variant={"ghost"}
          className="absolute top-2 right-2 p-1.5 px-2"
          onClick={() => {
            taskTemplateCtx.dispatch({
              type: "action_update",
              action: "default",
            });
          }}
        >
          <TrashIcon className="h-4 w-4 text-gray-400" />{" "}
        </NewButton> */}

        <div className="space-y-4">
          <div className="space-y-1">
            <InputHeading heading="Meeting Booking Service" />
            <NewSelect
              value={taskTemplateCtx.state.meeting?.vendor}
              onValueChange={(value) => {
                taskTemplateCtx.dispatch({
                  type: "meeting_update",
                  meeting: {
                    ...taskTemplateCtx.state.meeting,
                    vendor: value as MeetingType["vendor"],
                  },
                });
              }}
            >
              <SelectTrigger className="w-fit gap-2 bg-white">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value={"calendly"}>{"Calendly"}</SelectItem>
                  <SelectItem value={"hubspot"}>{"HubSpot"}</SelectItem>
                  <SelectItem value={"other"}>{"Other"}</SelectItem>
                </SelectGroup>
              </SelectContent>
            </NewSelect>
          </div>
          <div className="space-y-1">
            <InputHeading heading="Meeting Booking Link" required />
            <TextInput
              value={taskTemplateCtx.state.meeting.link}
              onChange={(e) => {
                taskTemplateCtx.dispatch({
                  type: "meeting_update",
                  meeting: {
                    ...taskTemplateCtx.state.meeting,
                    link: e.target.value,
                  },
                });
              }}
              placeholder={
                taskTemplateCtx.state.meeting.vendor === "hubspot"
                  ? "https://meetings.hubspot.com/meeting"
                  : "https://calendly.com/meeting"
              }
              className="w-[400px]"
            />
          </div>
        </div>
      </div>
      <NewButton
        variant={"secondaryLink"}
        className="p-0"
        size="sm"
        onClick={() => {
          taskTemplateCtx.dispatch({
            type: "action_update",
            action: "default",
          });
        }}
      >
        Remove <span className="whitespace-pre font-bold"> Meeting </span> from
        this task
      </NewButton>
    </div>
  );
}

function FormTemplateCombobox() {
  const { journeyTemplateId } = useParams();
  const { journeyTemplate } = useGetJourneyTemplate(String(journeyTemplateId));
  const { data: formTemplates } = useGetFormTemplates();
  const taskTemplateCtx = useTaskTemplate();
  const [open, setOpen] = useState(false);
  // console.log(journeyTemplate);
  // console.log(formTemplates);
  const filteredFormTemplates =
    formTemplates?.filter(
      (f) => f.spaceId?.toString() === journeyTemplate?.spaceId.toString()
    ) ?? [];
  // return (
  //   <div className="w-[250px] space-y-1">
  //     <InputHeading heading="Form Template" />
  //     <Select
  //       options={formTemplates ?? []}
  //       displayFn={(formTemplate) => formTemplate.title}
  //       keyFn={(formTemplate) => formTemplate._id}
  //       selected={taskTemplateCtx.state.formTemplate}
  //       onChange={(formTemplate) => {
  //         taskTemplateCtx.dispatch({
  //           type: "form_template_update",
  //           formTemplate,
  //         });
  //       }}
  //     />
  //   </div>
  // );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <NewButton
          variant="secondary"
          role="combobox"
          aria-expanded={open}
          className="min-w-[150px] justify-between space-x-2 truncate"
        >
          {/* <DocumentTextIcon className="h-4 w-4 shrink-0 text-gray-400" /> */}
          {taskTemplateCtx.state.formTemplate ? (
            <div className="truncate">
              {taskTemplateCtx.state.formTemplate.title}
            </div>
          ) : (
            <div className="flex space-x-1 text-gray-400/80">Form</div>
          )}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </NewButton>
      </PopoverTrigger>
      <PopoverContent
        className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
        align="start"
      >
        <Command>
          <CommandInput placeholder="Search form..." className="h-9" />
          {/* {formTemplates && formTemplates.length === 0 && (
            <div>No form template found.</div>
          )} */}
          <CommandEmpty>No form template found.</CommandEmpty>
          <CommandGroup>
            {filteredFormTemplates?.map((formTemplate) => (
              <CommandItem
                key={formTemplate._id}
                value={formTemplate.title}
                onSelect={() => {
                  taskTemplateCtx.dispatch({
                    type: "form_template_update",
                    formTemplate,
                  });
                  setOpen(false);
                }}
                className="gap-2"
              >
                <span className="truncate">{formTemplate.title}</span>
                <CheckIcon
                  className={cn(
                    "ml-auto h-4 w-4 shrink-0",
                    taskTemplateCtx.state.formTemplate?._id === formTemplate._id
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function ValueInput() {
  const taskTemplateCtx = useTaskTemplate();
  const [value, setValue] = useState(
    String(taskTemplateCtx.state.relativeDueDate.value)
  );

  useEffect(() => {
    setValue(String(taskTemplateCtx.state.relativeDueDate.value));
  }, [taskTemplateCtx.state.relativeDueDate.value]);

  return (
    <TextInput
      value={value}
      className="h-8 w-[70px]"
      onChange={(e) => {
        const rx = new RegExp(/^\d+$/);
        if (rx.test(e.target.value) || e.target.value === "") {
          setValue(e.target.value);
        }
      }}
      onBlur={(e) => {
        if (value === "") {
          taskTemplateCtx.dispatch({
            type: "relative_due_date_update",
            relativeDueDate: {
              value: 1,
              unit: taskTemplateCtx.state.relativeDueDate.unit,
            },
          });
        } else {
          taskTemplateCtx.dispatch({
            type: "relative_due_date_update",
            relativeDueDate: {
              value: parseInt(e.target.value),
              unit: taskTemplateCtx.state.relativeDueDate.unit,
            },
          });
        }
      }}
    />
  );
}

function TaskTemplatesDependency() {
  const { journeyTemplateId } = useParams();
  const { stageAndTaskTemplateList } = useGetStageAndTaskTemplatesList(
    new ObjectID(journeyTemplateId)
  );
  const taskTemplateCtx = useTaskTemplate();
  const [open, setOpen] = useState(false);
  return (
    <Popover open={open} onOpenChange={setOpen} modal={true}>
      <PopoverTrigger asChild>
        <NewButton
          variant="secondary"
          role="combobox"
          aria-expanded={open}
          className="max-w-[200px] space-x-2 truncate"
        >
          <BoltIcon className="h-4 w-4 shrink-0" />
          {taskTemplateCtx.state.dependentOn.type === "task" &&
          taskTemplateCtx.state.dependentOn.name ? (
            <div className="truncate">
              {taskTemplateCtx.state.dependentOn.name}
            </div>
          ) : (
            <div className="flex space-x-1 text-gray-400/80">
              Task
              <span className="top-0 ml-0.5 text-primary-main">*</span>
            </div>
          )}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </NewButton>
      </PopoverTrigger>
      <PopoverContent
        className="pointer-events-auto max-h-[300px] w-fit min-w-[200px] max-w-[700px] p-0"
        align="start"
      >
        <Command>
          <CommandInput placeholder="Search task..." className="h-9" />
          <CommandEmpty>No task found.</CommandEmpty>
          <CommandGroup>
            {stageAndTaskTemplateList.taskTemplates.map((taskTemplate) => (
              <TooltipProvider delayDuration={1200}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <CommandItem
                      key={taskTemplate._id.toString()}
                      value={taskTemplate.name}
                      onSelect={() => {
                        taskTemplateCtx.dispatch({
                          type: "dependent_on_update",
                          dependentOn: taskTemplate,
                        });
                        setOpen(false);
                      }}
                      className="gap-2"
                    >
                      <span className="truncate">{taskTemplate.name}</span>
                      <CheckIcon
                        className={cn(
                          "ml-auto h-4 w-4 shrink-0",
                          taskTemplateCtx.state.dependentOn?._id?.toString() ===
                            taskTemplate._id.toString()
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                    </CommandItem>
                  </TooltipTrigger>
                  <TooltipContent align="start" side="left" sideOffset={12}>
                    <p>{taskTemplate.journeyStageTemplate.name}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function StageTemplatesDependency() {
  const { journeyTemplateId } = useParams();
  const { stageAndTaskTemplateList } = useGetStageAndTaskTemplatesList(
    new ObjectID(journeyTemplateId)
  );
  const taskTemplateCtx = useTaskTemplate();
  const [open, setOpen] = useState(false);
  return (
    <Popover open={open} onOpenChange={setOpen} modal={true}>
      <PopoverTrigger asChild>
        <NewButton
          variant="secondary"
          role="combobox"
          aria-expanded={open}
          className="max-w-[200px] space-x-2 truncate"
        >
          <FlagIcon className="h-4 w-4 shrink-0" />
          {taskTemplateCtx.state.dependentOn.type === "stage" &&
          taskTemplateCtx.state.dependentOn.name ? (
            <div className="truncate">
              {taskTemplateCtx.state.dependentOn.name}
            </div>
          ) : (
            <div className="flex space-x-1 text-gray-400/80">
              Stage
              <span className="top-0 ml-0.5 text-primary-main">*</span>
            </div>
          )}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </NewButton>
      </PopoverTrigger>
      <PopoverContent
        className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
        align="start"
      >
        <Command>
          <CommandInput placeholder="Search stage..." className="h-9" />
          <CommandEmpty>No stage found.</CommandEmpty>
          <CommandGroup>
            {stageAndTaskTemplateList.stageTemplates
              .filter(
                (s) =>
                  s._id.toString() !==
                  taskTemplateCtx.state.journeyStageTemplate?._id?.toString()
              )
              .map((stageTemplate) => (
                <CommandItem
                  key={stageTemplate._id.toString()}
                  value={stageTemplate.name}
                  onSelect={() => {
                    taskTemplateCtx.dispatch({
                      type: "dependent_on_update",
                      dependentOn: stageTemplate,
                    });
                    setOpen(false);
                  }}
                  className="gap-2"
                >
                  <span className="truncate">{stageTemplate.name}</span>
                  <CheckIcon
                    className={cn(
                      "ml-auto h-4 w-4 shrink-0",
                      taskTemplateCtx.state.dependentOn?._id?.toString() ===
                        stageTemplate._id.toString()
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                </CommandItem>
              ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

const actionOptions: TaskTemplateAction[] = ["default", "form"];

function getActionLabel(action: TaskTemplateAction) {
  switch (action) {
    case "default":
      return (
        <div className="flex items-center space-x-2 text-gray-700">
          <div
            className={clsx(
              "h-[14px] w-[14px] rounded-full border-[1.5px] border-gray-400"
            )}
          />
          <div>To do</div>
        </div>
      );
    case "form":
      return (
        <div className="flex items-center space-x-2 text-gray-700">
          <DocumentTextIcon className="h-4 w-4 text-gray-700" />
          <span>Form</span>
        </div>
      );
  }
}

function UnitSelect() {
  const taskTemplateCtx = useTaskTemplate();
  return (
    <NewSelect
      value={taskTemplateCtx.state.relativeDueDate.unit}
      onValueChange={(value) => {
        taskTemplateCtx.dispatch({
          type: "relative_due_date_update",
          relativeDueDate: {
            ...taskTemplateCtx.state.relativeDueDate,
            unit: value as (typeof relativeDueDateUnits)[number],
          },
        });
      }}
    >
      <SelectTrigger className="w-fit gap-2">
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {relativeDueDateUnits
            .filter(
              (unit) =>
                unit !== "minute" && unit !== "month" && unit !== "quarter"
            )
            .map((unit) => (
              <SelectItem value={unit}>{unit + "s"}</SelectItem>
            ))}
        </SelectGroup>
      </SelectContent>
    </NewSelect>
  );
}

export const taskTemplateLaunchConditions: TaskTemplateLaunchCondition[] = [
  "project start",
  "completion of task",
  "completion of stage",
];

function ConditionSelect() {
  const taskTemplateCtx = useTaskTemplate();
  return (
    <NewSelect
      value={taskTemplateCtx.state.launchCondition}
      onValueChange={(value) => {
        taskTemplateCtx.dispatch({
          type: "launch_condition_update",
          launchCondition: value as TaskTemplateLaunchCondition,
        });
      }}
    >
      <SelectTrigger className="w-fit gap-2">
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {taskTemplateLaunchConditions.map((condition) => (
            <SelectItem value={condition}>{condition}</SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </NewSelect>
  );
}

function ActionSelect() {
  const taskTemplateCtx = useTaskTemplate();
  const [open, setOpen] = useState(false);
  return (
    <NewSelect
      value={taskTemplateCtx.state.action}
      onValueChange={(value) => {
        taskTemplateCtx.dispatch({
          type: "action_update",
          action: value as TaskTemplateAction,
        });
      }}
      open={open}
      onOpenChange={setOpen}
    >
      <SelectTrigger className="w-fit gap-2">
        <SelectValue />
      </SelectTrigger>
      <SelectContent
        onEscapeKeyDown={() => {
          setOpen(false);
        }}
      >
        <SelectGroup>
          {actionOptions.map((action) => (
            <SelectItem value={action}>{getActionLabel(action)}</SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </NewSelect>
  );
}

export const estimateOptions: TaskTemplateCreateRequest["estimate"][] = [
  { value: 5, unit: "minute" },
  { value: 10, unit: "minute" },
  { value: 30, unit: "minute" },
  { value: 45, unit: "minute" },
  { value: 1, unit: "hour" },
  { value: 2, unit: "hour" },
  { value: 4, unit: "hour" },
];

function DurationSelect() {
  const taskTemplateCtx = useTaskTemplate();
  return (
    <NewSelect
      value={
        taskTemplateCtx.state.estimate.value +
        " " +
        taskTemplateCtx.state.estimate.unit
      }
      onValueChange={(value) => {
        const e = estimateOptions.find(
          (estimate) => estimate.value + " " + estimate.unit === value
        );
        if (e) {
          taskTemplateCtx.dispatch({
            type: "estimate_update",
            estimate: e,
          });
        }
        if (value === "0 hour") {
          taskTemplateCtx.dispatch({
            type: "estimate_update",
            estimate: { value: 0, unit: "hour" },
          });
        }
      }}
    >
      <SelectTrigger className="w-fit gap-2">
        <StopwatchIcon className="h-4 w-4 text-gray-700" />
        <SelectValue>
          {taskTemplateCtx.state.estimate.value === 0
            ? "Not sure"
            : taskTemplateCtx.state.estimate.value +
              " " +
              (taskTemplateCtx.state.estimate.unit === "minute" ? "min" : "hr")}
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {/* <SelectLabel>Fruits</SelectLabel> */}
          <SelectItem value={"0 hour"}>Not sure</SelectItem>
          {estimateOptions.map((estimate) => (
            <SelectItem
              key={estimate.value + estimate.unit}
              value={estimate.value + " " + estimate.unit}
            >
              {estimate.value +
                " " +
                (estimate.unit === "minute" ? "min" : "hr")}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </NewSelect>
  );
}

function StageCombobox() {
  const { journeyTemplateId } = useParams();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const { stageAndTaskTemplateList } = useGetStageAndTaskTemplatesList(
    new ObjectID(journeyTemplateId)
  );
  const taskTemplateCtx = useTaskTemplate();

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <NewButton
          variant="secondary"
          role="combobox"
          aria-expanded={open}
          className="max-w-[200px] space-x-2 truncate"
        >
          <FlagIcon className="h-4 w-4 shrink-0" />
          {taskTemplateCtx.state.journeyStageTemplate ? (
            <div className="truncate">
              {taskTemplateCtx.state.journeyStageTemplate.name}
            </div>
          ) : (
            <div className="text-gray-400/80">Stage</div>
          )}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </NewButton>
      </PopoverTrigger>
      <PopoverContent
        className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
        align="start"
      >
        <Command>
          <CommandInput placeholder="Search stage..." className="h-9" />
          <CommandEmpty>No stage found.</CommandEmpty>
          <CommandGroup>
            {stageAndTaskTemplateList.stageTemplates.map((stageTemplate) => (
              <CommandItem
                key={stageTemplate._id.toString()}
                value={stageTemplate.name}
                onSelect={() => {
                  taskTemplateCtx.dispatch({
                    type: "stage_template_update",
                    journeyStageTemplate: stageTemplate,
                  });
                  if (
                    stageTemplate._id.toString() ===
                    taskTemplateCtx.state.dependentOn?._id?.toString()
                  ) {
                    taskTemplateCtx.dispatch({
                      type: "dependent_on_update",
                      dependentOn: { name: "Project Start", type: "default" },
                    });
                    taskTemplateCtx.dispatch({
                      type: "launch_condition_update",
                      launchCondition: "project start",
                    });
                  }
                  setOpen(false);
                }}
                className="gap-2"
              >
                <span className="truncate">{stageTemplate.name}</span>
                <CheckIcon
                  className={cn(
                    "ml-auto h-4 w-4 shrink-0",
                    taskTemplateCtx.state.journeyStageTemplate?._id.toString() ===
                      stageTemplate._id.toString()
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function RoleCombobox() {
  const { journeyTemplateId } = useParams();
  const [open, setOpen] = useState(false);
  const { journeyTemplate } = useGetJourneyTemplate(String(journeyTemplateId));
  const taskTemplateCtx = useTaskTemplate();

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <NewButton
          variant="secondary"
          role="combobox"
          aria-expanded={open}
          className="max-w-[200px] space-x-2 truncate"
        >
          <UserIcon className="h-4 w-4 shrink-0" />
          {taskTemplateCtx.state.tasker && (
            <div
              className={clsx(
                "h-2 w-2 shrink-0 rounded-full bg-gray-300",
                taskTemplateCtx.state.tasker?.type === "internal"
                  ? "bg-gray-300"
                  : "bg-slate-700"
              )}
            />
          )}
          {taskTemplateCtx.state.tasker ? (
            <div className="truncate">
              {taskTemplateCtx.state.tasker.persona}
            </div>
          ) : (
            <div className="flex space-x-1 text-gray-400/80">
              Role
              <span className="top-0 ml-0.5 text-primary-main">*</span>
            </div>
          )}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </NewButton>
      </PopoverTrigger>
      <PopoverContent
        className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
        align="start"
      >
        <Command>
          <CommandInput placeholder="Search role..." className="h-9" />
          <CommandEmpty>No role found.</CommandEmpty>
          <CommandGroup>
            {journeyTemplate?.externalPersonas.map((externalPersona) => (
              <CommandItem
                key={externalPersona}
                value={externalPersona}
                onSelect={() => {
                  taskTemplateCtx.dispatch({
                    type: "tasker_update",
                    tasker: {
                      type: "external",
                      persona: externalPersona,
                    },
                  });
                  taskTemplateCtx.dispatch({
                    type: "is_visible_update",
                    isVisible: true,
                  });
                  setOpen(false);
                }}
                className="gap-2"
              >
                <div className="flex items-center space-x-2">
                  <div
                    className={"h-2 w-2 shrink-0 rounded-full bg-slate-700"}
                  />
                  <span className="truncate">{externalPersona}</span>
                </div>
                <CheckIcon
                  className={cn(
                    "ml-auto h-4 w-4 shrink-0",
                    taskTemplateCtx.state.tasker?.persona === externalPersona &&
                      taskTemplateCtx.state.tasker?.type === "external"
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
              </CommandItem>
            ))}
            {journeyTemplate?.internalPersonas.map((internalPersona) => (
              <CommandItem
                key={internalPersona}
                value={internalPersona}
                onSelect={() => {
                  taskTemplateCtx.dispatch({
                    type: "tasker_update",
                    tasker: {
                      type: "internal",
                      persona: internalPersona,
                    },
                  });
                  setOpen(false);
                }}
                className="gap-2"
              >
                <div className="flex items-center space-x-2">
                  <div
                    className={"h-2 w-2 shrink-0 rounded-full bg-gray-300"}
                  />
                  <span className="truncate">{internalPersona}</span>
                </div>
                <CheckIcon
                  className={cn(
                    "ml-auto h-4 w-4 shrink-0",
                    taskTemplateCtx.state.tasker?.persona === internalPersona &&
                      taskTemplateCtx.state.tasker?.type === "internal"
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
export function SubtaskRoleCombobox({
  tasker,
  onInternalTaskerChange,
  onExternalTaskerChange,
}: {
  tasker: TaskTemplateData["tasker"];
  onInternalTaskerChange: (internalTasker: string) => void;
  onExternalTaskerChange: (externalTasker: string) => void;
}) {
  const { journeyTemplateId } = useParams();
  const [open, setOpen] = useState(false);
  const { journeyTemplate } = useGetJourneyTemplate(String(journeyTemplateId));

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <NewButton
          size="sm"
          variant="secondary"
          role="combobox"
          aria-expanded={open}
          className="h-7 max-w-[200px] space-x-2 truncate px-2"
        >
          <UserIcon className="h-4 w-4 shrink-0" />
          {tasker && (
            <div
              className={clsx(
                "h-1.5 w-1.5 shrink-0 rounded-full bg-gray-300",
                tasker?.type === "internal" ? "bg-gray-300" : "bg-slate-700"
              )}
            />
          )}
          {tasker ? (
            <div className="truncate">{tasker.persona}</div>
          ) : (
            <div className="flex space-x-1 text-gray-400/80">
              Role
              <span className="top-0 ml-0.5 text-primary-main">*</span>
            </div>
          )}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </NewButton>
      </PopoverTrigger>
      <PopoverContent
        className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
        align="start"
      >
        <Command>
          <CommandInput placeholder="Search role..." className="h-9" />
          <CommandEmpty>No role found.</CommandEmpty>
          <CommandGroup>
            {journeyTemplate?.externalPersonas.map((externalPersona) => (
              <CommandItem
                key={externalPersona}
                value={externalPersona}
                onSelect={() => {
                  onExternalTaskerChange(externalPersona);
                  setOpen(false);
                }}
                className="gap-2"
              >
                <div className="flex items-center space-x-2">
                  <div
                    className={"h-2 w-2 shrink-0 rounded-full bg-slate-700"}
                  />
                  <span className="truncate">{externalPersona}</span>
                </div>
                <CheckIcon
                  className={cn(
                    "ml-auto h-4 w-4 shrink-0",
                    tasker?.persona === externalPersona &&
                      tasker?.type === "external"
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
              </CommandItem>
            ))}
            {journeyTemplate?.internalPersonas.map((internalPersona) => (
              <CommandItem
                key={internalPersona}
                value={internalPersona}
                onSelect={() => {
                  onInternalTaskerChange(internalPersona);
                  setOpen(false);
                }}
                className="gap-2"
              >
                <div className="flex items-center space-x-2">
                  <div
                    className={"h-2 w-2 shrink-0 rounded-full bg-gray-300"}
                  />
                  <span className="truncate">{internalPersona}</span>
                </div>
                <CheckIcon
                  className={cn(
                    "ml-auto h-4 w-4 shrink-0",
                    tasker?.persona === internalPersona &&
                      tasker?.type === "internal"
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function FormTemplateSelect() {
  const { data: formTemplates } = useGetFormTemplates();
  const taskTemplateCtx = useTaskTemplate();
  return (
    <div className="w-[250px] space-y-1">
      <InputHeading heading="Form Template" />
      <Select
        options={formTemplates ?? []}
        displayFn={(formTemplate) => formTemplate.title}
        keyFn={(formTemplate) => formTemplate._id}
        selected={taskTemplateCtx.state.formTemplate}
        onChange={(formTemplate) => {
          taskTemplateCtx.dispatch({
            type: "form_template_update",
            formTemplate,
          });
        }}
      />
    </div>
  );
}

// function Actions() {
//   const taskTemplateCtx = useTaskTemplate();
//   return (
//     <div className="space-y-2">
//       <InputHeading heading="Task Type" />
//       {!taskTemplateCtx.state.options.hasSubTasks &&
//         !!taskTemplateCtx.state.action &&
//         taskTemplateCtx.state.action === "default" && (
//           <div className="flex flex-wrap gap-4">
//             <Action
//               text="Form"
//               Icon={DocumentTextIcon}
//               onClick={() => {
//                 taskTemplateCtx.dispatch({
//                   type: "action_update",
//                   action: "form",
//                 });
//               }}
//             />
//           </div>
//         )}
//       {!!taskTemplateCtx.state.action &&
//         taskTemplateCtx.state.action !== "default" && (
//           <HandleAction action={taskTemplateCtx.state.action} />
//         )}
//     </div>
//   );
// }

function HandleAction({
  action,
}: {
  action: TaskTemplateCreateRequest["action"];
}) {
  const taskTemplateCtx = useTaskTemplate();
  switch (action) {
    case "form":
      return (
        <div>
          <div>Handle form select</div>
          <button
            onClick={() => {
              taskTemplateCtx.dispatch({
                type: "action_update",
                action: "default",
              });
            }}
          >
            Remove Form
          </button>
        </div>
      );
    default:
      return <></>;
  }
}

export function Action({
  text,
  icon,
  onClick,
  disabled,
  active,
}: {
  text: string;
  icon: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  active?: boolean;
}) {
  return (
    <button
      className={clsx(
        "relative flex h-20 w-24 flex-col items-center justify-center gap-1 rounded-md border border-gray-200 bg-white shadow transition-colors duration-75 focus:ring-0 focus-visible:border-primary focus-visible:outline-none disabled:border-gray-100 disabled:bg-gray-100 disabled:text-gray-300",
        active ? "border-primary-100 text-primary-600" : "hover:bg-gray-50"
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {/* <div className="absolute top-0 flex w-full justify-start p-1">
        {active ? (
          <div className="inline-flex h-4 w-4 items-center justify-center rounded border border-primary">
            <CheckIcon className="h-3 w-3 text-primary-main" />
          </div>
        ) : (
          <>
            {disabled ? null : (
              <div className="inline-flex h-4 w-4 items-center justify-center rounded border" />
            )}
          </>
        )}
      </div> */}
      <div
        className={clsx(
          "pt-2",
          active
            ? "border-primary"
            : disabled
            ? "border-gray-300"
            : "border-gray-400"
        )}
      >
        {icon}
      </div>
      <span className="text-sm text-gray-500">{text}</span>
    </button>
  );
}
const CreateMore = ({
  createMore,
  setCreateMore,
}: {
  createMore: boolean;
  setCreateMore: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div className="inline-flex items-center space-x-2">
      <VariantToggle
        enabled={createMore}
        setEnabled={() => setCreateMore(!createMore)}
        size="sm"
        focus={false}
      />
      <span className="text-xs text-gray-400">Create more</span>
    </div>
  );
};

export function SubtasksPolledVisibility({
  isVisible,
}: {
  isVisible: boolean;
}) {
  return (
    <>
      <TooltipProvider>
        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>
            <div className="inline-flex h-8 w-8 items-center justify-center rounded-md border border-gray-300 bg-gray-100">
              {isVisible ? (
                <EyeIcon className="h-4 w-4 text-gray-600" />
              ) : (
                <EyeSlashIcon className="h-4 w-4 text-gray-600" />
              )}
            </div>
          </TooltipTrigger>
          <TooltipContent
            align="start"
            side="bottom"
            sideOffset={4}
            className="max-w-[250px]"
          >
            <p>
              Parent task is externally visible if any of its subtasks is
              externally visible
            </p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </>
  );
}

function Subtasks() {
  const newTaskTemplateCtx = useTaskTemplate();
  const [isAddingSubtask, setIsAddingSubtask] = useState(
    newTaskTemplateCtx.state.subtasks.length === 0
  );
  const [subtaskEditing, setSubtaskEditing] = useState<{
    isEditing: boolean;
    index: number;
  }>({
    isEditing: false,
    index: -1,
  });
  const handleDragEnd = (event: DragEndEvent) => {
    const { over, active } = event;
    if (over && active) {
      const { id: overId } = over;
      const { id: activeId } = active;
      if (overId !== activeId) {
        const oldIndex = newTaskTemplateCtx.state.subtasks.findIndex(
          (subtask) => subtask.id === activeId
        );
        const newIndex = newTaskTemplateCtx.state.subtasks.findIndex(
          (subtask) => subtask.id === overId
        );
        const newSubtasks = arrayMove(
          newTaskTemplateCtx.state.subtasks,
          oldIndex,
          newIndex
        );
        newTaskTemplateCtx.dispatch({
          type: "subtasks_update",
          subtasks: newSubtasks,
        });
      }
    }
  };
  return (
    <div>
      <div className="rounded-md border-[1.5px] bg-white">
        {/* <div className="pl-6">
          <div className="flex space-x-4">
            <div className="flex-1">
              <InputHeading heading="Subtasks" />
            </div>
            <div className="w-[204px]">
              <InputHeading heading="Duration" />
            </div>
          </div>
        </div> */}
        {subtaskEditing.isEditing && subtaskEditing.index >= 0 && (
          <SubtaskEditing
            index={subtaskEditing.index}
            setSubtaskEditing={setSubtaskEditing}
          />
        )}
        {newTaskTemplateCtx.state.subtasks.length > 0 &&
          !subtaskEditing.isEditing && (
            <div className="pt-2">
              <div className="flex items-center gap-1 px-3 pt-1 pb-1">
                <div className="text-sm text-gray-400">{`Subtasks`}</div>
                {/* <BoltIcon className="h-4 w-4 text-gray-400" />
                <div className="text-sm text-gray-400">
                  {newTaskTemplateCtx.state.subtasks.length}
                </div> */}
              </div>
              <DndContext
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={newTaskTemplateCtx.state.subtasks}
                  strategy={verticalListSortingStrategy}
                >
                  {newTaskTemplateCtx.state.subtasks.map((subtask, index) => (
                    <Subtask
                      key={index}
                      index={index}
                      setSubtaskEditing={setSubtaskEditing}
                    />
                  ))}
                </SortableContext>
              </DndContext>
            </div>
          )}
        {isAddingSubtask && !subtaskEditing.isEditing && (
          <AddSubtask setIsAddingSubtask={setIsAddingSubtask} />
        )}
        {!isAddingSubtask && !subtaskEditing.isEditing && (
          <div className="pl-3 pb-2">
            <button
              className="text-xs text-gray-400 hover:text-gray-600 focus:outline-primary"
              onClick={() => {
                setIsAddingSubtask(true);
              }}
            >
              Add Subtask
            </button>
          </div>
        )}
      </div>
      <NewButton
        variant={"secondaryLink"}
        className="p-0"
        size="sm"
        onClick={() => {
          newTaskTemplateCtx.dispatch({
            type: "options_update",
            options: {
              ...newTaskTemplateCtx.state.options,
              hasSubTasks: false,
            },
          });
        }}
      >
        Remove <span className="whitespace-pre font-bold"> Subtasks </span> from
        this task
      </NewButton>
    </div>
  );
}

function SubtaskEditing({
  index,
  setSubtaskEditing,
}: {
  index: number;
  setSubtaskEditing: React.Dispatch<
    React.SetStateAction<{
      isEditing: boolean;
      index: number;
    }>
  >;
}) {
  const newTaskTemplateCtx = useTaskTemplate();
  const [value, setValue] = useState(
    String(newTaskTemplateCtx.state.subtasks[index].estimate.value)
  );

  useEffect(() => {
    setValue(String(newTaskTemplateCtx.state.subtasks[index].estimate.value));
  }, [newTaskTemplateCtx.state.subtasks[index].estimate.value]);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "0px"; // Reset height to shrink if needed
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + "px";
    }
  }, [newTaskTemplateCtx.state.subtasks[index].title]);

  return (
    <div className="rounded-md p-3">
      <div className="flex items-center gap-1">
        <div className="text-sm text-gray-400">{`Editing Subtask`}</div>
        <BoltIcon className="h-4 w-4 text-gray-400" />
        <div className="text-sm text-gray-400">{`${index + 1} of ${
          newTaskTemplateCtx.state.subtasks.length
        }`}</div>
      </div>

      <div className="space-y-2 pt-1">
        <textarea
          ref={textAreaRef}
          className="w-full resize-none border-none bg-transparent p-0 pr-5 outline-none placeholder:text-gray-300 focus:border-none focus:outline-none focus:ring-0"
          value={newTaskTemplateCtx.state.subtasks[index].title}
          onChange={(event) => {
            newTaskTemplateCtx.dispatch({
              type: "subtasks_update",
              subtasks: newTaskTemplateCtx.state.subtasks.map((subtask, i) => {
                if (i === index) {
                  return {
                    ...subtask,
                    title: event.target.value,
                  };
                }
                return subtask;
              }),
            });
          }}
          placeholder={
            newTaskTemplateCtx.state.subtasks.length > 1
              ? `Subtask ${index + 1} title`
              : `Subtask title`
          }
        />
        <div className="flex justify-between gap-2">
          {index < newTaskTemplateCtx.state.subtasks.length && (
            <div className="flex gap-2">
              <SubtaskDurationSelect
                widthFit={true}
                estimate={newTaskTemplateCtx.state.subtasks[index].estimate}
                value={
                  newTaskTemplateCtx.state.subtasks[index].estimate.value +
                  " " +
                  newTaskTemplateCtx.state.subtasks[index].estimate.unit
                }
                onValueChange={(value) => {
                  const e = estimateOptions.find(
                    (estimate) => estimate.value + " " + estimate.unit === value
                  );
                  if (e) {
                    newTaskTemplateCtx.dispatch({
                      type: "subtasks_update",
                      subtasks: newTaskTemplateCtx.state.subtasks.map(
                        (s, i) => {
                          if (i === index) {
                            return {
                              ...s,
                              estimate: {
                                value: e.value,
                                unit: e.unit,
                              },
                            };
                          }
                          return s;
                        }
                      ),
                    });
                  }
                  if (value === "0 hour") {
                    newTaskTemplateCtx.dispatch({
                      type: "subtasks_update",
                      subtasks: newTaskTemplateCtx.state.subtasks.map(
                        (s, i) => {
                          if (i === index) {
                            return {
                              ...s,
                              estimate: {
                                value: 0,
                                unit: "hour",
                              },
                            };
                          }
                          return s;
                        }
                      ),
                    });
                  }
                }}
              />
              <SubtaskRoleCombobox
                tasker={newTaskTemplateCtx.state.subtasks[index].tasker}
                onExternalTaskerChange={(externalTasker) => {
                  newTaskTemplateCtx.dispatch({
                    type: "subtasks_update",
                    subtasks: newTaskTemplateCtx.state.subtasks.map((s, i) => {
                      if (i === index) {
                        return {
                          ...s,
                          tasker: {
                            type: "external",
                            persona: externalTasker,
                          },
                          isVisible: true,
                        };
                      }
                      return s;
                    }),
                  });
                  newTaskTemplateCtx.dispatch({
                    type: "is_visible_update",
                    isVisible: true,
                  });
                }}
                onInternalTaskerChange={(internalTasker) => {
                  newTaskTemplateCtx.dispatch({
                    type: "subtasks_update",
                    subtasks: newTaskTemplateCtx.state.subtasks.map((s, i) => {
                      if (i === index) {
                        return {
                          ...s,
                          tasker: {
                            type: "internal",
                            persona: internalTasker,
                          },
                        };
                      }
                      return s;
                    }),
                  });
                }}
              />
              <SubtaskVisibilityButtons
                isVisible={newTaskTemplateCtx.state.subtasks[index].isVisible}
                onValueChange={(isVisible) => {
                  newTaskTemplateCtx.dispatch({
                    type: "subtasks_update",
                    subtasks: newTaskTemplateCtx.state.subtasks.map((s, i) => {
                      if (i === index) {
                        return {
                          ...s,
                          isVisible,
                        };
                      }
                      return s;
                    }),
                  });

                  if (!isVisible) {
                    let hasVisibleSubtask = false;
                    newTaskTemplateCtx.state.subtasks.forEach((s, i) => {
                      if (i !== index && s.isVisible) {
                        hasVisibleSubtask = true;
                      }
                    });
                    if (!hasVisibleSubtask) {
                      newTaskTemplateCtx.dispatch({
                        type: "is_visible_update",
                        isVisible: false,
                      });
                    }
                  } else {
                    newTaskTemplateCtx.dispatch({
                      type: "is_visible_update",
                      isVisible: true,
                    });
                  }
                }}
                taskerType={
                  newTaskTemplateCtx.state.subtasks[index].tasker?.type ??
                  "internal"
                }
              />
              <NewButton
                className="h-7 px-1.5"
                variant={"secondary"}
                onClick={() => {
                  if (newTaskTemplateCtx.state.subtasks.length === 1) {
                    newTaskTemplateCtx.dispatch({
                      type: "options_update",
                      options: {
                        ...newTaskTemplateCtx.state.options,
                        hasSubTasks: false,
                      },
                    });
                  }
                  newTaskTemplateCtx.dispatch({
                    type: "subtasks_update",
                    subtasks: newTaskTemplateCtx.state.subtasks.filter(
                      (s, i) => i !== index
                    ),
                  });
                  setSubtaskEditing({
                    isEditing: false,
                    index: -1,
                  });
                }}
              >
                <TrashIcon className="h-4 w-4 text-gray-400" />
              </NewButton>
            </div>
          )}
          <div>
            <NewButton
              size="sm"
              variant={"secondary"}
              onClick={() => {
                setSubtaskEditing({
                  isEditing: false,
                  index: -1,
                });
              }}
            >
              Back to subtasks
            </NewButton>
          </div>
        </div>
      </div>
    </div>
  );
}

function AddSubtask({
  setIsAddingSubtask,
}: {
  setIsAddingSubtask: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const newTaskTemplateCtx = useTaskTemplate();
  const [isVisible, setIsVisible] = useState(true);
  const [tasker, setTasker] = useState<TaskTemplateData["tasker"]>(
    newTaskTemplateCtx.state.tasker
  );
  const [title, setTitle] = useState("");
  const [estimate, setEstimate] = useState<TaskTemplateData["estimate"]>({
    value: 10,
    unit: "minute",
  });

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "0px"; // Reset height to shrink if needed
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + "px";
    }
  }, [title]);

  function addSubtask() {
    if (tasker) {
      newTaskTemplateCtx.dispatch({
        type: "subtasks_update",
        subtasks: [
          ...newTaskTemplateCtx.state.subtasks,
          {
            title,
            estimate,
            id: Math.random(),
            tasker: tasker,
            isVisible,
          },
        ],
      });
      if (isVisible) {
        newTaskTemplateCtx.dispatch({
          type: "is_visible_update",
          isVisible: true,
        });
      } else {
        const hasVisibleSubtask = newTaskTemplateCtx.state.subtasks.some(
          (subtask) => subtask.isVisible
        );
        if (!hasVisibleSubtask) {
          newTaskTemplateCtx.dispatch({
            type: "is_visible_update",
            isVisible: false,
          });
        }
      }
      setTitle("");
    }
  }

  return (
    <div
      className={clsx(
        "relative rounded-b-md border-gray-300/60 bg-white p-3 px-3",
        newTaskTemplateCtx.state.subtasks.length === 0
          ? "rounded-md"
          : "mt-2 border-t"
      )}
    >
      <div className="flex items-center gap-1">
        <div className="text-sm text-gray-400">{`New Subtask`}</div>
        <button
          className="absolute right-3 top-3 rounded-sm opacity-50 ring-offset-white transition-opacity hover:opacity-70 focus:outline-none focus:ring-2 focus:ring-primary-main focus:ring-offset-2"
          onClick={() => {
            setIsAddingSubtask(false);
            if (newTaskTemplateCtx.state.subtasks.length === 0) {
              newTaskTemplateCtx.dispatch({
                type: "options_update",
                options: {
                  ...newTaskTemplateCtx.state.options,
                  hasSubTasks: false,
                },
              });
            }
          }}
        >
          <Cross2Icon className="h-3 w-3" />
        </button>
        {/* <BoltIcon className="h-4 w-4 text-gray-400" />
        <div className="text-sm text-gray-400">
          {newTaskTemplateCtx.state.subtasks.length + 1}
        </div> */}
      </div>
      <div className="space-y-2 pt-1">
        <textarea
          autoFocus
          ref={textAreaRef}
          className="w-full resize-none border-none bg-transparent p-0 outline-none placeholder:text-gray-300 focus:border-none focus:outline-none focus:ring-0"
          value={title}
          onChange={(event) => {
            if (event.target.value.includes("\n")) {
              if (title.trim() !== "") {
                addSubtask();
              } else {
                return;
              }
            } else {
              setTitle(event.target.value);
            }
          }}
          placeholder={`Subtask title`}
        />
        <div className="flex justify-between gap-2">
          <div className="flex space-x-2">
            <SubtaskRoleCombobox
              tasker={tasker}
              onExternalTaskerChange={(externalTasker) => {
                setTasker({
                  type: "external",
                  persona: externalTasker,
                });
                setIsVisible(true);
              }}
              onInternalTaskerChange={(internalTasker) => {
                setTasker({
                  type: "internal",
                  persona: internalTasker,
                });
              }}
            />
            <SubtaskDurationSelect
              widthFit={true}
              estimate={estimate}
              value={estimate.value + " " + estimate.unit}
              onValueChange={(value: string) => {
                const e = estimateOptions.find(
                  (estimate) => estimate.value + " " + estimate.unit === value
                );
                if (e) {
                  setEstimate(e);
                }
                if (value === "0 hour") {
                  setEstimate({ value: 0, unit: "hour" });
                }
              }}
            />
            <SubtaskVisibilityButtons
              isVisible={isVisible}
              onValueChange={(value) => {
                setIsVisible(value);
              }}
              taskerType={tasker?.type ?? "internal"}
            />
            {/* <NewSelect
                value={estimate.value + " " + estimate.unit}
                onValueChange={(value) => {
                  const e = estimateOptions.find(
                    (estimate) => estimate.value + " " + estimate.unit === value
                  );
                  if (e) {
                    setEstimate(e);
                  }
                  if (value === "0 hour") {
                    setEstimate({ value: 0, unit: "hour" });
                  }
                }}
              >
                <SelectTrigger className="h-7 w-fit gap-2 bg-white px-2">
                  <ClockIcon className="h-4 w-4 text-gray-700" />
                  <SelectValue>
                    {estimate.value === 0
                      ? "Not sure"
                      : estimate.value +
                        " " +
                        (estimate.unit === "minute" ? "min" : "hr")}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value={"0 hour"}>Not sure</SelectItem>
                    {estimateOptions.map((estimate) => (
                      <SelectItem
                        key={estimate.value + estimate.unit}
                        value={estimate.value + " " + estimate.unit}
                      >
                        {estimate.value +
                          " " +
                          (estimate.unit === "minute" ? "min" : "hr")}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </NewSelect> */}
          </div>
          <div className="flex justify-end space-x-2">
            {/* <NewButton
              size="icon"
              variant="secondary"
              onClick={() => {
                setIsAddingSubtask(false);
                if (newTaskTemplateCtx.state.subtasks.length === 0) {
                  newTaskTemplateCtx.dispatch({
                    type: "options_update",
                    options: {
                      ...newTaskTemplateCtx.state.options,
                      hasSubTasks: false,
                    },
                  });
                }
              }}
            >
              
            </NewButton> */}
            <NewButton
              size="sm"
              onClick={() => {
                addSubtask();
              }}
              disabled={title.trim() === "" || !tasker}
            >
              Add
            </NewButton>
          </div>
        </div>
      </div>
    </div>
  );
}

function Subtask({
  index,
  setSubtaskEditing,
}: {
  index: number;
  setSubtaskEditing: any;
}) {
  const newTaskTemplateCtx = useTaskTemplate();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: newTaskTemplateCtx.state.subtasks[index].id,
    });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      className="group relative flex cursor-pointer items-center justify-between gap-2 px-3 py-1 pl-3 hover:bg-gray-100"
      onClick={() => {
        setSubtaskEditing({
          isEditing: true,
          index: index,
        });
      }}
      tabIndex={-1}
    >
      <DragHandleDots2Icon
        className="absolute right-full h-5 w-5 cursor-move text-transparent group-hover:text-gray-300"
        {...listeners}
      />
      <div className="flex items-center gap-1">
        <div className="truncate">
          {newTaskTemplateCtx.state.subtasks[index].title}
        </div>
      </div>
      <div className="flex items-start gap-2">
        {index < newTaskTemplateCtx.state.subtasks.length && (
          <div className="flex gap-1">
            <div className="inline-flex h-7 items-center gap-1 whitespace-nowrap rounded-md border border-gray-300 bg-gray-50 px-1.5 pr-2 text-sm">
              <UserIcon className="h-3.5 w-3.5 text-gray-700" />
              <div
                className={clsx(
                  "mx-0.5 h-1.5 w-1.5 rounded-full",
                  newTaskTemplateCtx.state.subtasks[index].tasker?.type ===
                    "internal"
                    ? "bg-gray-300"
                    : "bg-slate-700"
                )}
              />
              <span className="text-xs text-gray-700">
                {newTaskTemplateCtx.state.subtasks[index].tasker?.persona}
              </span>
            </div>
            <div className="inline-flex h-7 items-center gap-1 whitespace-nowrap rounded-md border border-gray-300 bg-gray-50 px-1.5 text-sm">
              <StopwatchIcon className="h-3.5 w-3.5 text-gray-700" />
              <span className="text-xs text-gray-700">
                {newTaskTemplateCtx.state.subtasks[index].estimate.value +
                  " " +
                  (newTaskTemplateCtx.state.subtasks[index].estimate.unit ===
                  "minute"
                    ? "min"
                    : "hr")}
              </span>
            </div>
            <div className="inline-flex h-7 w-7 items-center justify-center rounded-md border border-gray-300 bg-gray-50">
              {newTaskTemplateCtx.state.subtasks[index].isVisible ? (
                <EyeIcon className="h-4 w-4 text-gray-600" />
              ) : (
                <EyeSlashIcon className="h-4 w-4 text-gray-600" />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export function SubtaskVisibilityButtons({
  isVisible,
  onValueChange,
  taskerType,
}: {
  isVisible: boolean;
  onValueChange: (value: boolean) => void;
  taskerType: TaskerType;
}) {
  return (
    <div className="inline-flex h-7 items-center gap-1 rounded-md border border-gray-300 bg-white p-1 shadow-sm">
      <TooltipProvider>
        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>
            <button
              className={clsx(
                "rounded p-0.5 outline-none focus-visible:ring-[1.25px] focus-visible:ring-primary",
                isVisible
                  ? "bg-primary-100 text-primary-600"
                  : "text-gray-500 hover:bg-gray-100"
              )}
              onClick={() => {
                onValueChange(true);
              }}
            >
              <EyeIcon className="h-4 w-4" />
            </button>
          </TooltipTrigger>
          <TooltipContent align="start" side="bottom" sideOffset={8}>
            <p>Externally visible</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <TooltipProvider>
        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>
            <button
              className={clsx(
                "rounded p-0.5 outline-none focus-visible:ring-[1.25px] focus-visible:ring-primary",
                !isVisible
                  ? "bg-primary-100 text-primary-600"
                  : "text-gray-500 hover:bg-gray-100",
                taskerType == "external" &&
                  "bg-gray-100 text-gray-300 hover:bg-gray-100"
              )}
              onClick={() => {
                onValueChange(false);
              }}
              disabled={taskerType == "external"}
            >
              <EyeSlashIcon
                className={`h-4 w-4 ${
                  taskerType === "external" ? "text-gray-300" : ""
                }`}
              />
            </button>
          </TooltipTrigger>
          <TooltipContent align="start" side="bottom" sideOffset={8}>
            <p>Externally hidden</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
}

export function SubtaskDurationSelect({
  widthFit = false,
  value,
  onValueChange,
  estimate,
}: {
  widthFit?: boolean;
  estimate: TaskTemplateData["estimate"];
  value: string;
  onValueChange: (value: string) => void;
}) {
  return (
    <NewSelect value={value} onValueChange={onValueChange}>
      <SelectTrigger
        className={`h-7 gap-2 bg-white px-2 ${widthFit ? "w-fit" : "w-32"}`}
      >
        <div className="inline-flex items-center gap-2">
          <StopwatchIcon className="h-4 w-4 text-gray-700" />
          <SelectValue>
            <span className="text-xs">
              {value === "0 hour"
                ? "Not sure"
                : estimate.value +
                  " " +
                  (estimate.unit === "minute" ? "min" : "hr")}
            </span>
          </SelectValue>
        </div>
      </SelectTrigger>
      <SelectContent className="w-32">
        <SelectGroup>
          <SelectItem value={"0 hour"}>Not sure</SelectItem>
          {estimateOptions.map((estimate) => (
            <SelectItem
              key={estimate.value + estimate.unit}
              value={estimate.value + " " + estimate.unit}
            >
              {estimate.value +
                " " +
                (estimate.unit === "minute" ? "min" : "hr")}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </NewSelect>
  );
}

function SubtasksDurationSum() {
  const newTaskTemplateCtx = useTaskTemplate();
  const totalDuration = newTaskTemplateCtx.state.subtasks.reduce(
    (acc, task) => {
      if (task.estimate) {
        return acc + estimateToMinutes(task.estimate);
      }
      return acc;
    },
    0
  );
  // console.log(totalDuration, tasks[0].status);
  const hours = Math.floor(totalDuration / 60);
  const minutes = totalDuration % 60;

  if (totalDuration > 0) {
    if (minutes === 0) {
      return (
        <div className="flex h-8 items-center space-x-2 rounded-md border border-gray-300 bg-gray-100 px-2 text-sm">
          <StopwatchIcon className="h-4 w-4 text-gray-700" />
          <span>{`${hours} hr`}</span>
        </div>
      );
    } else {
      if (hours === 0) {
        return (
          <div className="flex h-8 items-center space-x-2 rounded-md border border-gray-300 bg-gray-100 px-2 text-sm">
            <StopwatchIcon className="h-4 w-4 text-gray-700" />
            <span>{`${minutes} min`}</span>
          </div>
        );
      }
      return (
        <div className="flex h-8 items-center space-x-2 rounded-md border border-gray-300 bg-gray-100 px-2 text-sm">
          <StopwatchIcon className="h-4 w-4 text-gray-700" />
          <span>{`${hours} hr, ${minutes} min`}</span>
        </div>
      );
    }
  } else {
    return (
      <div className="flex h-8 items-center space-x-2 rounded-md border border-gray-300 bg-gray-100 px-2 text-sm">
        <StopwatchIcon className="h-4 w-4 text-gray-700" />
        <span>{`0 hr`}</span>
      </div>
    );
  }
}

function AddSubTasks() {
  const taskTemplateCtx = useTaskTemplate();
  return (
    <>
      {!taskTemplateCtx.state.options.hasSubTasks &&
        taskTemplateCtx.state.action === "default" && (
          <Button
            text={
              taskTemplateCtx.state.options.hasSubTasks
                ? "Remove Subtasks"
                : "Add Subtasks"
            }
            size="xs"
            fill="outline"
            leftIcon={<BoltIcon className="-ml-0.5 mr-2 h-4 w-4" />}
            ring={false}
            color="gray"
            onClick={() => {
              taskTemplateCtx.dispatch({
                type: "options_update",
                options: {
                  ...taskTemplateCtx.state.options,
                  hasSubTasks: !taskTemplateCtx.state.options.hasSubTasks,
                },
              });
            }}
          />
        )}
    </>
  );
}
