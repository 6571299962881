import React, { createContext, useContext, useReducer } from "react";

type Action =
  | { type: "VIEW_TEAM_UPDATE"; responsibility: Responsibility }
  | { type: "VIEW_STATE_UPDATE"; state: TaskState }
  | { type: "VIEW_STATUS_UPDATE"; status: Status }
  | { type: "VIEW_COMPLETED_UPDATE"; showCompleted: boolean }
  | { type: "VIEW_VIEW_UPDATE"; view: View }
  | { type: "VIEW_SHOW_SUBTASKS_UPDATE"; showSubtasks: boolean }
  | { type: "VIEW_SHOW_INACTIVE_UPDATE"; showInactive: boolean }
  | {
      type: "VIEW_SHOW_ACTIVE_AND_INCOMPLETE_TASKS_UPDATE";
      showOnlyActiveAndIncompleteTasks: boolean;
    }
  | { type: "FILTER_UPDATE"; filter: Filter }
  | { type: "GROUP_UPDATE"; group: ProjectGroup };

type Dispatch = (action: Action) => void;

type Responsibility = "my" | "internal" | "external" | "all";
type TaskState = "active" | "dependent" | "all";
type Status = "To Do" | "In Progress" | "Completed" | "Canceled" | "All";
type View = "list" | "kanban";

type Filter = {
  responsibility: Responsibility;
  state: TaskState;
  status: Status;
};

export type ProjectGroup = "status" | "stage";

type State = {
  view: {
    responsibility: Responsibility;
    state: TaskState;
    status: Status;
    view: View;
    showCompleted: boolean;
    showSubtasks: boolean;
    showInactive: boolean;
    showOnlyActiveAndIncompleteTasks: boolean;
  };
  group: ProjectGroup;
  filter: Filter;
  isExternal: boolean;
};

const JourneyContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function journeyReducer(state: State, action: Action): State {
  switch (action.type) {
    case "VIEW_TEAM_UPDATE": {
      return {
        ...state,
        view: { ...state.view, responsibility: action.responsibility },
      };
    }
    case "VIEW_STATE_UPDATE": {
      return {
        ...state,
        view: { ...state.view, state: action.state },
      };
    }
    case "VIEW_STATUS_UPDATE": {
      return {
        ...state,
        view: { ...state.view, status: action.status },
      };
    }
    case "VIEW_VIEW_UPDATE": {
      return {
        ...state,
        view: {
          ...state.view,
          view: action.view,
          showSubtasks: action.view === "list" ? true : false,
        },
      };
    }
    case "VIEW_SHOW_SUBTASKS_UPDATE": {
      return {
        ...state,
        view: { ...state.view, showSubtasks: action.showSubtasks },
      };
    }
    case "VIEW_COMPLETED_UPDATE": {
      return {
        ...state,
        view: { ...state.view, showCompleted: action.showCompleted },
      };
    }
    case "VIEW_SHOW_INACTIVE_UPDATE": {
      return {
        ...state,
        view: { ...state.view, showInactive: action.showInactive },
      };
    }
    case "VIEW_SHOW_ACTIVE_AND_INCOMPLETE_TASKS_UPDATE": {
      return {
        ...state,
        view: {
          ...state.view,
          showOnlyActiveAndIncompleteTasks:
            action.showOnlyActiveAndIncompleteTasks,
        },
      };
    }
    case "FILTER_UPDATE": {
      return {
        ...state,
        filter: action.filter,
      };
    }
    case "GROUP_UPDATE": {
      return {
        ...state,
        group: action.group,
      };
    }
    default:
      return state;
  }
}

type Props = {
  children: React.ReactNode;
  view?: {
    responsibility?: Responsibility;
    state?: TaskState;
    status?: Status;
  };
  isExternal?: boolean;
};

export const JourneyContextProvider = ({
  view,
  children,
  isExternal = false,
}: Props) => {
  const [state, dispatch] = useReducer(journeyReducer, {
    view: {
      responsibility: view?.responsibility || "all",
      state: view?.state || "all",
      status: view?.status || "All",
      view: "kanban",
      showCompleted: true,
      showSubtasks: false,
      showInactive: true,
      showOnlyActiveAndIncompleteTasks: false,
    },
    filter: {
      responsibility: "all",
      state: "all",
      status: "All",
    },
    group: "stage",
    isExternal,
  });
  const value = { state, dispatch };

  return (
    <JourneyContext.Provider value={value}>{children}</JourneyContext.Provider>
  );
};

export const useJourney = () => {
  const context = useContext(JourneyContext);
  if (context === undefined) {
    throw new Error("useJourney must be used within a JourneyProvider");
  }
  return context;
};
