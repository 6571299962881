type Props = {
  title: string;
  subtitle?: string;
};

const ModalHeading = ({ title, subtitle = "" }: Props) => {
  return (
    <div>
      <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
      {subtitle && <p className="mt-1 text-sm text-gray-400">{subtitle}</p>}
    </div>
  );
};

export default ModalHeading;
