import {
  CodeBracketIcon,
  ListBulletIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  DividerHorizontalIcon,
  FontBoldIcon,
  FontItalicIcon,
  Link2Icon,
  OpenInNewWindowIcon,
  Pencil2Icon,
  StrikethroughIcon,
} from "@radix-ui/react-icons";
import "./styles.scss";

import { BubbleMenu, Editor, EditorContent } from "@tiptap/react";
import React, { useCallback } from "react";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "src/components/ui/dialog";
import { TextInput } from "src/components/ui/text-input";
import { cn } from "src/utils/ui/ui";

export default function TiptapEditor({ editor }: { editor: Editor | null }) {
  const [isLinkModalOpen, setIsLinkModalOpen] = React.useState(false);
  const [url, setUrl] = React.useState("");
  const [urlErrorText, setUrlErrorText] = React.useState("");

  // useEffect(() => {
  //   if (editor) {
  //     editor.commands.setContent(content);
  //   }
  // }, [content, setContent]);

  const removeLink = useCallback(() => {
    editor?.chain().focus().extendMarkRange("link").unsetLink().run();
    closeModal();
  }, [editor, closeModal]);

  const saveLink = useCallback(() => {
    if (url) {
      editor
        ?.chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url, target: "_blank" })
        .run();
    } else {
      editor?.chain().focus().extendMarkRange("link").unsetLink().run();
    }
    editor?.commands.blur();
    closeModal();
  }, [editor, url, closeModal]);

  function closeModal() {
    setIsLinkModalOpen(false);
    setUrl("");
  }

  if (!editor) {
    return null;
  }

  return (
    <>
      <MenuBar
        editor={editor}
        setIsLinkModalOpen={setIsLinkModalOpen}
        setUrl={setUrl}
      />
      <EditorContent
        editor={editor}
        className="prose mt-2 h-full focus:border-none focus:outline-none focus:ring-0"
      />
      <BubbleMenu
        pluginKey="bubbleMenuLink"
        tippyOptions={{ duration: 150 }}
        editor={editor}
        shouldShow={({ editor, from, to }) => {
          // only show the bubble menu for links.
          return from === to && editor.isActive("link");
        }}
      >
        <div className="-mb-[6px] flex items-center space-x-0.5 rounded border-[0.5px] border-gray-300 bg-white p-0.5">
          <button
            onClick={() => {
              setUrl(editor.getAttributes("link").href);
              setIsLinkModalOpen(true);
            }}
            className="flex w-fit space-x-1 rounded-sm px-1.5 py-[3px] text-xs text-gray-500 hover:bg-gray-100 hover:text-gray-700"
          >
            <Pencil2Icon className="h-4 w-4" />
            <span>Edit</span>
          </button>
          <button
            className="flex w-fit space-x-1 rounded-sm px-1.5 py-[3px] text-xs text-gray-500 hover:bg-gray-100 hover:text-gray-700"
            onClick={() => {
              window.open(editor.getAttributes("link").href, "_blank");
            }}
          >
            <OpenInNewWindowIcon className="h-4 w-4" />
            <span>Open</span>
          </button>
        </div>
      </BubbleMenu>
      <BubbleMenu
        pluginKey="bubbleMenuNotLink"
        tippyOptions={{ duration: 150 }}
        editor={editor}
        shouldShow={({ editor, view, state, oldState, from, to }) => {
          // only show the bubble menu for links.
          return (
            from !== to &&
            !editor.isActive("link") &&
            !editor.isActive("horizontalRule")
          );
        }}
      >
        <div className="-mb-1 flex items-center justify-center rounded border-[0.5px] border-gray-300 bg-white p-0.5">
          <button
            onClick={() => {
              setIsLinkModalOpen(true);
            }}
            className="flex h-5 w-5 items-center justify-center rounded text-gray-500 hover:bg-gray-100 hover:text-gray-700"
          >
            <Link2Icon className="h-4 w-4" />
          </button>
        </div>
      </BubbleMenu>
      <Dialog open={isLinkModalOpen} onOpenChange={setIsLinkModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {editor.isActive("link") ? "Edit Link" : "Insert Link"}
            </DialogTitle>
          </DialogHeader>
          <div className="space-y-3">
            <div className="space-y-1 py-3">
              <InputHeading heading="URL" />
              <TextInput value={url} onChange={(e) => setUrl(e.target.value)} />
              <p className="text-xs text-red-500">{urlErrorText}</p>
            </div>
            <div className="flex justify-between">
              <div>
                {editor.isActive("link") && (
                  <Button
                    variant={"secondary"}
                    onClick={() => {
                      removeLink();
                    }}
                    className="flex w-8 items-center justify-center p-0"
                  >
                    <TrashIcon className="h-4 w-4" />
                  </Button>
                )}
              </div>
              <Button
                variant={"secondary"}
                onClick={() => {
                  if (!url) {
                    setUrlErrorText("URL cannot be empty");
                  } else if (
                    !url.startsWith("http://") &&
                    !url.startsWith("https://")
                  ) {
                    setUrlErrorText(
                      "URL should start with http:// or https://"
                    );
                  } else {
                    saveLink();
                    setUrlErrorText("");
                  }
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

const MenuBar = ({
  editor,
  setIsLinkModalOpen,
  setUrl,
}: {
  editor: Editor;
  setIsLinkModalOpen: (open: boolean) => void;
  setUrl: (url: string) => void;
}) => {
  return (
    <div className="flex items-center space-x-2">
      <MenuButton
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={editor.isActive("bold") ? "is-active" : ""}
      >
        <FontBoldIcon className="h-4" />
      </MenuButton>
      <MenuButton
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={editor.isActive("italic") ? "is-active" : ""}
      >
        <FontItalicIcon className="h-4 w-4" />
      </MenuButton>
      <MenuButton
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        className={editor.isActive("strike") ? "is-active" : ""}
      >
        <StrikethroughIcon className="h-4 w-4" />
      </MenuButton>
      {/* <MenuButton
        onClick={() => editor.chain().focus().toggleCode().run()}
        disabled={!editor.can().chain().focus().toggleCode().run()}
        className={editor.isActive("code") ? "is-active" : ""}
      >
        <CodeBracketIcon className="h-4 w-4" />
      </MenuButton> */}
      {/* <MenuButton onClick={() => editor.chain().focus().unsetAllMarks().run()}>
        clear marks
      </MenuButton> */}
      {/* <MenuButton onClick={() => editor.chain().focus().clearNodes().run()}>
        clear nodes
      </MenuButton> */}
      {/* <MenuButton
        onClick={() => editor.chain().focus().setParagraph().run()}
        className={editor.isActive("paragraph") ? "is-active" : ""}
      >
        paragraph
      </MenuButton> */}
      <MenuButton
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={editor.isActive("heading", { level: 1 }) ? "is-active" : ""}
      >
        <span className="font-mono">H</span>
      </MenuButton>
      {/* <MenuButton
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={editor.isActive("heading", { level: 2 }) ? "is-active" : ""}
      >
        h2
      </MenuButton>
      <MenuButton
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={editor.isActive("heading", { level: 3 }) ? "is-active" : ""}
      >
        h3
      </MenuButton>
      <MenuButton
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        className={editor.isActive("heading", { level: 4 }) ? "is-active" : ""}
      >
        h4
      </MenuButton>
      <MenuButton
        onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
        className={editor.isActive("heading", { level: 5 }) ? "is-active" : ""}
      >
        h5
      </MenuButton>
      <MenuButton
        onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
        className={editor.isActive("heading", { level: 6 }) ? "is-active" : ""}
      >
        h6
      </MenuButton> */}
      <MenuButton
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive("bulletList") ? "is-active" : ""}
      >
        <ListBulletIcon className="h-4 w-4" />
      </MenuButton>
      <MenuButton
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive("orderedList") ? "is-active" : ""}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          // className="bi bi-list-ol"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z"
          />
          <path d="M1.713 11.865v-.474H2c.217 0 .363-.137.363-.317 0-.185-.158-.31-.361-.31-.223 0-.367.152-.373.31h-.59c.016-.467.373-.787.986-.787.588-.002.954.291.957.703a.595.595 0 0 1-.492.594v.033a.615.615 0 0 1 .569.631c.003.533-.502.8-1.051.8-.656 0-1-.37-1.008-.794h.582c.008.178.186.306.422.309.254 0 .424-.145.422-.35-.002-.195-.155-.348-.414-.348h-.3zm-.004-4.699h-.604v-.035c0-.408.295-.844.958-.844.583 0 .96.326.96.756 0 .389-.257.617-.476.848l-.537.572v.03h1.054V9H1.143v-.395l.957-.99c.138-.142.293-.304.293-.508 0-.18-.147-.32-.342-.32a.33.33 0 0 0-.342.338v.041zM2.564 5h-.635V2.924h-.031l-.598.42v-.567l.629-.443h.635V5z" />
        </svg>
      </MenuButton>
      <MenuButton
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={editor.isActive("codeBlock") ? "is-active" : ""}
      >
        <CodeBracketIcon className="h-4 w-4" />
      </MenuButton>
      {/* <MenuButton
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={editor.isActive("blockquote") ? "is-active" : ""}
      >
        <QuoteIcon className="h-4 w-4" />
      </MenuButton> */}
      <MenuButton
        onClick={() => editor.chain().focus().setHorizontalRule().run()}
      >
        <DividerHorizontalIcon className="h-4 w-4" />
      </MenuButton>
      <MenuButton
        onClick={() => {
          const url = editor.getAttributes("link").href;
          if (url) {
            setUrl(url);
          }
          setIsLinkModalOpen(true);
        }}
        className={editor.isActive("link") ? "is-active" : ""}
      >
        <Link2Icon className="h-4 w-4" />
      </MenuButton>

      {/* <MenuButton onClick={() => editor.chain().focus().setHardBreak().run()}>
        hard break
      </MenuButton>
      <MenuButton
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().chain().focus().undo().run()}
      >
        undo
      </MenuButton>
      <MenuButton
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().chain().focus().redo().run()}
      >
        redo
      </MenuButton>
      <MenuButton
        onClick={() => editor.chain().focus().setColor("#958DF1").run()}
        className={
          editor.isActive("textStyle", { color: "#958DF1" }) ? "is-active" : ""
        }
      >
        purple
      </MenuButton> */}
    </div>
  );
};

type ButtonProps = {} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const MenuButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <button
        tabIndex={-1}
        className={cn(
          "text-gray-400 transition-colors hover:text-gray-600",
          className
        )}
        ref={ref}
        {...props}
      >
        {children}
      </button>
    );
  }
);
