import {
  AdjustmentsHorizontalIcon,
  BuildingOffice2Icon,
  ChevronRightIcon,
  FunnelIcon,
  RectangleStackIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import "@tanstack/react-table";
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CustomFieldData,
  CustomFieldMultiSelectData,
  CustomFieldSelectData,
  useGetCustomFields,
} from "src/api/CustomFields/custom-fields";
import {
  CustomerData,
  useGetCustomersWithDebouncing,
} from "src/api/General/customers";
import {
  ProjectDataV2,
  ProjectDetailsData,
  ProjectsV2Filter,
  ProjectsV2FilterGroup,
  useGetProjectsDetails,
  useGetProjectsV2,
} from "src/api/Services/Projects/projects";
import {
  InternalPhaseUpdateData,
  PhaseStatus,
} from "src/api/Services/SharedSpace/phases";
import { useGetJourneyTemplates } from "src/api/Services/Tasks/journeys";
import {
  useGetSpace,
  useGetSpaceMemberships,
  useGetSpaces,
} from "src/api/Spaces/spaces";
import { SpaceSymbol } from "src/components/Layout/Sidebar";
import { Button } from "src/components/ui/button";
import { Checkbox } from "src/components/ui/checkbox";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "src/components/ui/command";
import { Count } from "src/components/ui/count";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "src/components/ui/hover-card";
import { Loading } from "src/components/ui/loading";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { TextInput } from "src/components/ui/text-input";
import NewProjectButton from "src/pages/Services/Projects/NewProject/NewProject";
import { fuzzyFilter } from "src/pages/Services/Projects/ProjectsTable";
import {
  InternalUpdate,
  getPhaseStatus,
} from "src/pages/Services/SharedSpace/Phases/Tabs/Overview";
import useDebounce from "src/utils/customHooks/useDebounce";
import {
  CompanyFilter,
  CompanyFilterModel,
  IdComparator,
  Logic,
  ProjectOwnerFilter,
  ProjectOwnerFilterModel,
  ProjectStatusFilter,
  ProjectStatusFilterModel,
  ProjectTemplateFilter,
  ProjectTemplateFilterModel,
  SpaceFilterModel,
  getSpaceProjectsColumnsToDisplayKey,
  getSpaceProjectsFilterKey,
} from "src/utils/filters";
import { getFirstAndLastInitials } from "src/utils/functions/avatar";
import { getTimeDifference } from "src/utils/functions/dateTime";

export default function SpaceProjectsWrapper() {
  const { spaceId } = useParams();
  return <SpaceProjects key={spaceId} />;
}

type ColumnsToDisplay = {
  [key: string]: {
    id: string;
    visible: boolean;
    name: string;
    width: number;
    isCustomField: boolean;
  };
};

function SpaceProjects() {
  const { spaceId } = useParams();
  const { data: space } = useGetSpace(String(spaceId));
  const { data: spaces } = useGetSpaces();
  const { data: spaceMemberships } = useGetSpaceMemberships(String(spaceId));
  const { data: customFields } = useGetCustomFields("project");
  // console.log(customFields);
  const [columnsToDisplay, setColumnsToDisplay] = useState<ColumnsToDisplay>(
    () => {
      return x();
    }
  );

  function x(): ColumnsToDisplay {
    let initialColumnsToDisplay: ColumnsToDisplay = {
      company: {
        id: "company",
        visible: true,
        name: "Company",
        width: 208,
        isCustomField: false,
      },
      internalUpdates: {
        id: "internalUpdates",
        visible: true,
        name: "Updates",
        width: 208,
        isCustomField: false,
      },
      owner: {
        id: "owner",
        visible: true,
        name: "Owner",
        width: 208,
        isCustomField: false,
      },
      deltaToTarget: {
        id: "deltaToTarget",
        visible: true,
        name: "Delta to Target",
        width: 208,
        isCustomField: false,
      },
      forecastedCompletionDate: {
        id: "forecastedCompletionDate",
        visible: true,
        name: "Forecasted Completion Date",
        width: 208,
        isCustomField: false,
      },
      template: {
        id: "template",
        visible: true,
        name: "Template",
        width: 208,
        isCustomField: false,
      },
    };

    // add custom fields
    if (customFields) {
      for (const customField of customFields) {
        initialColumnsToDisplay[customField._id] = {
          id: customField._id,
          visible: false,
          name: customField.name,
          width: 160,
          isCustomField: true,
        };
      }
    }

    const savedColumnsToDisplay = localStorage.getItem(
      getSpaceProjectsColumnsToDisplayKey(String(spaceId))
    );
    if (savedColumnsToDisplay) {
      const parsedColumnsToDisplay = JSON.parse(savedColumnsToDisplay);
      // set visbility based on saved state
      for (const key of Object.keys(initialColumnsToDisplay)) {
        if (parsedColumnsToDisplay.hasOwnProperty(key)) {
          // console.log(savedColumnsToDisplay[column.id])
          initialColumnsToDisplay[key] = {
            ...initialColumnsToDisplay[key],
            visible: !!parsedColumnsToDisplay[key].visible,
          };
        }
      }
    }
    // console.log(initialColumnsToDisplay);
    return initialColumnsToDisplay;
  }

  useEffect(() => {
    if (customFields) {
      setColumnsToDisplay(x());
    }
  }, [customFields]);

  const [filterGroup, setFilterGroup] = useState<ProjectsV2FilterGroup>(() => {
    const savedFilterGroup = localStorage.getItem(
      getSpaceProjectsFilterKey(String(spaceId))
    );
    if (savedFilterGroup) {
      return JSON.parse(savedFilterGroup);
    }
    return {
      logic: "AND",
      children: [
        {
          field: "project-status",
          comparator: "in",
          value: ["In Progress"],
        },
      ],
    };
  });

  const { data: projects } = useGetProjectsV2(
    {
      logic: "AND",
      children: [
        {
          field: "space",
          comparator: "in",
          value: [String(spaceId)],
        },
      ],
    },
    clean(filterGroup)
  );

  const { data: projectDetails } = useGetProjectsDetails(
    {
      logic: "AND",
      children: [
        {
          field: "space",
          comparator: "in",
          value: [String(spaceId)],
        },
      ],
    },
    clean(filterGroup)
  );
  // console.log(projectDetails);
  const [search, setSearch] = useState("");
  const [noOfProjectsAfterSearch, setNoOfProjectsAfterSearch] = useState(0);
  const { journeyTemplates } = useGetJourneyTemplates();

  useEffect(() => {
    if (filterGroup.children.length === 0) {
      localStorage.removeItem(getSpaceProjectsFilterKey(String(spaceId)));
    } else {
      localStorage.setItem(
        getSpaceProjectsFilterKey(String(spaceId)),
        JSON.stringify(filterGroup)
      );
    }
  }, [filterGroup]);

  useEffect(() => {
    const savedColumnsToDisplay = localStorage.getItem(
      getSpaceProjectsColumnsToDisplayKey(String(spaceId))
    );

    if (savedColumnsToDisplay) {
      let parsedColumnsToDisplay = JSON.parse(savedColumnsToDisplay);
      // set visbility of localstorage based on columnsToDisplay
      for (const key of Object.keys(parsedColumnsToDisplay)) {
        if (columnsToDisplay.hasOwnProperty(key)) {
          parsedColumnsToDisplay[key].visible = columnsToDisplay[key].visible;
        }
      }
      // add new columns to localstorage
      for (const key of Object.keys(columnsToDisplay)) {
        if (!parsedColumnsToDisplay.hasOwnProperty(key)) {
          parsedColumnsToDisplay[key] = columnsToDisplay[key];
        }
      }
      localStorage.setItem(
        getSpaceProjectsColumnsToDisplayKey(String(spaceId)),
        JSON.stringify(parsedColumnsToDisplay)
      );
    } else {
      localStorage.setItem(
        getSpaceProjectsColumnsToDisplayKey(String(spaceId)),
        JSON.stringify(columnsToDisplay)
      );
    }
  }, [columnsToDisplay]);

  return (
    <div className="flex h-full flex-col overflow-y-auto">
      <div className="flex h-12 items-center border-b border-gray-200/70 px-8">
        <div className="flex items-center space-x-2 text-sm">
          <div className="flex cursor-default items-center space-x-1.5 text-gray-800">
            {space && <SpaceSymbol color={space?.color} />}
            <span>{space?.name}</span>
          </div>
          <ChevronRightIcon className="h-3 w-3 text-gray-500" />
          <div className="cursor-default text-gray-800">{"Projects"}</div>
        </div>
      </div>

      <div className="flex h-16 items-center justify-between border-b border-gray-200/70 px-8">
        <div className="flex items-center space-x-2">
          <Filter
            filterGroup={filterGroup}
            setFilterGroup={setFilterGroup}
            isLoading={!spaceMemberships}
            // localStorageKey="project-filter"
            filterModels={[
              {
                field: "project-owner",
                projectOwners:
                  spaceMemberships?.map((m) => {
                    return {
                      _id: m.user._id,
                      name: m.user.name,
                      email: m.user.email,
                    };
                  }) ?? [],
              },
              { field: "project-status" },
              { field: "company" },
              {
                field: "project-template",
                projectTemplates:
                  journeyTemplates
                    ?.filter((jt) => jt.spaceId?.toString() === spaceId)
                    .map((jt) => {
                      return {
                        _id: jt._id?.toString() ?? "",
                        name: jt.name,
                      };
                    }) ?? [],
              },
            ]}
          />
          <StatusFilter
            filterGroup={filterGroup}
            setFilterGroup={setFilterGroup}
          />
          <Display
            columnsToDisplay={columnsToDisplay}
            setColumnsToDisplay={setColumnsToDisplay}
          />
          <div className="relative">
            <TextInput
              className="h-8 w-72 pl-8"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search.."
              autoFocus
            />
            <div className="absolute inset-y-0 left-0 flex items-center pl-2.5">
              <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" />
            </div>
          </div>
          {/* {!!projects && (
            <div className="text-sm text-gray-600 h-8 rounded-md px-2 flex items-center">
              {`Showing ${noOfProjectsAfterSearch} of ${projects.noOfProjects} projects`}
            </div>
          )} */}
          {/* <Button variant={"secondary"}>Display</Button> */}
        </div>
        {spaces && <NewProjectButton spaces={spaces} />}
      </div>

      <div className="flex flex-1 flex-col overflow-y-auto">
        {!!projects && !!spaces ? (
          <>
            {projects.noOfProjects > 0 ? (
              <>
                <GeneralProjectsList
                  projects={projects.projects}
                  projectDetails={projectDetails?.projects}
                  columnsToDisplay={columnsToDisplay}
                  setColumnsToDisplay={setColumnsToDisplay}
                  // filterGroup={filterGroup}
                  search={search}
                  setSearch={setSearch}
                  setNoOfProjectsAfterSearch={setNoOfProjectsAfterSearch}
                />
              </>
            ) : (
              <div className="mx-auto mt-[10vh]">
                <div className="flex">
                  <div className="h-20 w-20"></div>
                  <div className="h-20 w-[420px] bg-gradient-to-t from-gray-200 to-transparent px-[1px]">
                    <div className="h-full w-full bg-white" />
                  </div>
                  <div className="h-20 w-20"></div>
                </div>
                <div className="flex">
                  <div className="w-20 bg-gradient-to-l from-gray-200 to-white py-[1px]">
                    <div className="h-full w-full bg-white" />
                  </div>
                  <div className="w-[420px] border bg-gray-50/70 p-12">
                    <div className="text-xl font-medium">Projects</div>
                    <div className="pt-2.5 font-light text-gray-700">
                      Projects are used to group together tasks that help
                      achieve a clear goal.
                    </div>
                    <div className="pt-6">
                      <NewProjectButton spaces={spaces} />
                    </div>
                  </div>
                  <div className="w-20 bg-gradient-to-r from-gray-200 to-white py-[1px]">
                    <div className="h-full w-full bg-white" />
                  </div>
                </div>
                <div className="flex">
                  <div className="h-20 w-20"></div>
                  <div className="h-20 w-[420px] bg-gradient-to-b from-gray-200 to-white px-[1px]">
                    <div className="h-full w-full bg-white" />
                  </div>
                  <div className="h-20 w-20"></div>
                </div>
              </div>
            )}
          </>
        ) : (
          <Loading className="mt-[20vh]" />
        )}
      </div>
    </div>
  );
}

function Display({
  columnsToDisplay,
  setColumnsToDisplay,
}: {
  columnsToDisplay: ColumnsToDisplay;
  setColumnsToDisplay: (value: ColumnsToDisplay) => void;
}) {
  const { data: customFields } = useGetCustomFields("project");
  // console.log(columnsToDisplay);
  return (
    <Popover modal={true}>
      <PopoverTrigger asChild>
        <Button variant="secondary">
          <AdjustmentsHorizontalIcon className="mr-2 h-3.5 w-3.5 border-gray-300" />
          Display
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="max-h-[60vh] w-60 space-y-0 overflow-y-auto p-2"
      >
        {Object.keys(columnsToDisplay).map((key) => {
          const column = columnsToDisplay[key];
          return (
            <div
              className="flex cursor-pointer items-center justify-between gap-6 rounded-md px-2 py-1.5 hover:bg-gray-100"
              onClick={() => {
                setColumnsToDisplay({
                  ...columnsToDisplay,
                  [key]: {
                    ...column,
                    visible: !column.visible,
                  },
                });
              }}
            >
              <div className="truncate text-sm">{column.name}</div>
              <div>
                <Checkbox
                  checked={column.visible}
                  // onCheckedChange={(checked) => {
                  //   setColumnsToDisplay({
                  //     ...columnsToDisplay,
                  //     [key]: {
                  //       ...column,
                  //       visible: !!checked,
                  //     },
                  //   });
                  // }}
                />
              </div>
            </div>
          );
        })}
        {/* <div className="flex items-center justify-between">
          <div>Company</div>
          <div>
            <Checkbox
              checked={columnsToDisplay["company"].visible}
              onCheckedChange={(checked) => {
                setColumnsToDisplay({
                  ...columnsToDisplay,
                  company: {
                    ...columnsToDisplay["company"],
                    visible: !!checked,
                  },
                });
              }}
            />
          </div>
        </div> */}
      </PopoverContent>
    </Popover>
  );
}

function clean(filterGroup: ProjectsV2FilterGroup) {
  return {
    logic: filterGroup.logic,
    children: filterGroup.children.filter((child) => {
      if (child.field === "project-owner") {
        return !!child.value.length;
      } else if (child.field === "project-status") {
        return !!child.value.length;
      } else if (child.field === "company") {
        return !!child.value.length;
      } else if (child.field === "project-template") {
        return !!child.value.length;
      } else if (!child.field) {
        return false;
      }
      return true;
    }),
  };
}

function StatusFilter({
  filterGroup,
  setFilterGroup,
}: {
  filterGroup: ProjectsV2FilterGroup;
  setFilterGroup: (filterGroup: ProjectsV2FilterGroup) => void;
}) {
  const statusFilter = filterGroup.children.find(
    (f) => f.field === "project-status"
  ) as ProjectStatusFilter;
  const statusFilterIndex = filterGroup.children.findIndex(
    (f) => f.field === "project-status"
  );

  useEffect(() => {
    const doesStatusFilterExist = filterGroup.children.find(
      (f) => f.field === "project-status"
    );
    if (!doesStatusFilterExist) {
      setFilterGroup({
        logic: "AND",
        children: [
          ...filterGroup.children,
          {
            field: "project-status",
            comparator: "in",
            value: ["In Progress"],
          },
        ],
      });
    }
  }, [filterGroup]);

  if (!statusFilter || statusFilterIndex === -1) {
    return null;
  }

  return (
    <StatusCombobox
      filter={statusFilter}
      index={statusFilterIndex}
      filterGroup={filterGroup}
      setFilterGroup={setFilterGroup}
    />
  );
}

function getPhaseStatusSymbol(
  status: PhaseStatus,
  isClickable: boolean = false
) {
  switch (status) {
    // case "Paused":
    //   return (
    //     <div className="flex items-center space-x-2">
    //       {/* <div className={clsx("group rounded-md", isClickable && "p-0")}> */}
    //       <div
    //         className={clsx(
    //           "h-4 w-4 rounded-full border-[2px] border-amber-400 transition-all",
    //           isClickable && "hover:border-amber-500"
    //         )}
    //       />
    //       {/* </div> */}
    //       {/* <div className="h-3.5 w-3.5 rounded border border-amber-600 bg-amber-400" />
    //       <div className="h-3.5 w-3.5 rounded-full border border-amber-600 bg-amber-400" />
    //       <div className="h-3.5 w-3.5 rounded-full border-[1.5px] border-amber-400 bg-amber-100" /> */}
    //     </div>
    //   );
    case "Paused":
      return (
        <div
          className={clsx(
            "h-4 w-4 rounded-full border-[2px] border-dashed border-gray-400 transition-all",
            isClickable && "hover:border-gray-500"
          )}
        />
      );

    case "Canceled":
      return (
        <div
          className={clsx(
            "flex h-4 w-4 items-center justify-center rounded-full bg-gray-500/60 text-white transition-all",
            isClickable && "hover:bg-gray-500/80"
          )}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={3.5}
            stroke="currentColor"
            className="h-2.5 w-2.5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
      );

    case "In Progress":
      return (
        <div
          className={clsx(
            "group flex h-4 w-4 items-center justify-center rounded-full border-[1.5px] border-blue-500 transition-all",
            isClickable && "hover:border-blue-600"
          )}
        >
          <div
            className={clsx(
              "h-2 w-2 rounded-full bg-blue-400",
              isClickable && "transition-all group-hover:bg-blue-500"
            )}
          />
        </div>
      );
    // case "Stuck":
    //   return (
    //     <div
    //       className={clsx(
    //         "group flex h-4 w-4 items-center justify-center rounded-full border-[1.5px] border-red-500 transition-all",
    //         isClickable && "hover:border-red-600"
    //       )}
    //     >
    //       <div
    //         className={clsx(
    //           "h-2 w-2 rounded-full bg-red-400",
    //           isClickable && "group-hover:bg-red-500 transition-all"
    //         )}
    //       />
    //     </div>
    //   );
    case "Completed":
      return (
        <div className="flex items-center space-x-2">
          <div
            className={clsx(
              "flex h-4 w-4 items-center justify-center rounded-full bg-green-500 text-white transition-all",
              isClickable && "hover:bg-green-600"
            )}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className="h-3 w-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m4.5 12.75 6 6 9-13.5"
              />
            </svg>
          </div>
        </div>
      );
    default:
      return <div>TBD</div>;
  }
}

function StatusCombobox({
  filter,
  index,
  filterGroup,
  setFilterGroup,
}: {
  filter: ProjectStatusFilter;
  index: number;
  filterGroup: ProjectsV2FilterGroup;
  setFilterGroup: (filterGroup: ProjectsV2FilterGroup) => void;
}) {
  const [valueOpen, setValueOpen] = useState(!filter.value?.length);

  const handleValueChange = (value: string) => {
    setFilterGroup({
      ...filterGroup,
      children: filterGroup.children.map((child, i) => {
        if (i === index) {
          return {
            ...child,
            value: child.value.includes(value)
              ? [...child.value.filter((v) => v !== value)]
              : [...child.value, value],
          };
        }
        return child;
      }),
    });
  };

  function getStatusDisplay() {
    if (filter.value.length === 1) {
      return (
        <div className="flex items-center space-x-2">
          {getPhaseStatusSymbol(filter.value[0] as PhaseStatus, false)}
          <div className="text-sm text-gray-600">{filter.value[0]}</div>
        </div>
      );
    } else {
      return (
        <div className="flex items-center space-x-2">
          {filter.value.map((status) =>
            getPhaseStatusSymbol(status as PhaseStatus, false)
          )}
        </div>
      );
    }
  }

  return (
    <Popover modal={true} open={valueOpen} onOpenChange={setValueOpen}>
      <PopoverTrigger asChild>
        <Button variant="secondary" className="py-0">
          <div className="flex h-full items-center border-r border-input pr-2.5">
            Status
          </div>
          <div className="pl-2.5">{getStatusDisplay()}</div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="pointer-events-auto w-60 p-0" align="start">
        <Command className="relative">
          <CommandGroup>
            {statuses.map((status) => (
              <CommandItem
                key={status}
                value={status}
                onSelect={() => {
                  if (filter.value.length === 1 && filter.value[0] === status) {
                    return;
                  }
                  handleValueChange(status);
                }}
                className={clsx(
                  "gap-2",
                  filter.value.length === 1 && filter.value[0] === status
                    ? "cursor-not-allowed"
                    : ""
                )}
              >
                <Checkbox
                  checked={filter.value.includes(status)}
                  disabled={
                    filter.value.length === 1 && filter.value[0] === status
                  }
                />
                <div className="flex items-center space-x-2">
                  <div>{getPhaseStatusSymbol(status)}</div>
                  <span className="truncate">{status}</span>
                </div>
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

type FilterModel =
  | ProjectStatusFilterModel
  | SpaceFilterModel
  | ProjectOwnerFilterModel
  | CompanyFilterModel
  | ProjectTemplateFilterModel;

function Filter({
  filterModels,
  filterGroup,
  setFilterGroup,
  isLoading,
}: {
  filterModels: FilterModel[];
  filterGroup: ProjectsV2FilterGroup;
  setFilterGroup: (filterGroup: ProjectsV2FilterGroup) => void;
  isLoading: boolean;
}) {
  const [open, setOpen] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const { spaceId } = useParams();

  useEffect(() => {
    // count the number of valid filters
    let count = 0;
    for (let i = 0; i < filterGroup.children.length; i++) {
      const child = filterGroup.children[i] as ProjectsV2Filter;
      if (child.field === "project-owner") {
        if (child.value.length > 0) {
          count++;
        }
      } else if (child.field === "company") {
        if (child.value.length > 0) {
          count++;
        }
      } else if (child.field === "project-template") {
        if (child.value.length > 0) {
          count++;
        }
      }
    }
    setFilterCount(count);
  }, [filterGroup]);

  useEffect(() => {
    if (!open) {
      setFilterGroup(clean(filterGroup));
    }
  }, [open]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="secondary" disabled={isLoading}>
          <FunnelIcon className="mr-2 h-3.5 w-3.5 border-gray-300" />
          Filter
          {filterCount > 0 && (
            <div className="ml-2">
              <Count variant={"primaryLight"}>{filterCount}</Count>
            </div>
          )}
          {filterCount > 0 && (
            <button
              tabIndex={-1}
              className="ml-1.5 shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-600"
              onClick={(e) => {
                e.stopPropagation();
                setFilterGroup({
                  logic: "AND",
                  children: filterGroup.children.filter(
                    (child) => child.field === "project-status"
                  ),
                });
              }}
            >
              <XMarkIcon className="h-2.5 w-2.5 " />
            </button>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="z-40 w-full min-w-[480px] space-y-3 p-5"
      >
        <FilterGroup
          filterGroup={filterGroup}
          setFilterGroup={setFilterGroup}
          level={0}
          filterModels={filterModels}
        />
      </PopoverContent>
    </Popover>
  );
}

function NoProjectsMeetFilter() {
  return (
    <div className="mx-auto mt-[10vh]">
      No projects meet the filter criteria
    </div>
  );
}

function FilterGroup({
  filterGroup,
  setFilterGroup,
  level,
  filterModels,
}: // phase,
{
  filterGroup: ProjectsV2FilterGroup;
  setFilterGroup: (filterGroup: ProjectsV2FilterGroup) => void;
  level: number;
  filterModels: FilterModel[];
}) {
  const handleLogicChange = (logic: Logic) => {
    setFilterGroup({ ...filterGroup, logic });
  };

  const addFilter = (field: ProjectsV2Filter["field"]) => {
    if (field === "project-owner") {
      setFilterGroup({
        ...filterGroup,
        children: [
          ...filterGroup.children,
          {
            field: field,
            comparator: "in",
            value: [],
          },
        ],
      });
    } else if (field === "company") {
      setFilterGroup({
        ...filterGroup,
        children: [
          ...filterGroup.children,
          {
            field: field,
            comparator: "in",
            value: [],
          },
        ],
      });
    } else if (field === "project-template") {
      setFilterGroup({
        ...filterGroup,
        children: [
          ...filterGroup.children,
          {
            field: field,
            comparator: "in",
            value: [],
          },
        ],
      });
    }
  };

  const [open, setOpen] = useState(false);

  return (
    <div className={clsx(level > 0 && "rounded-md border p-4")}>
      <div>
        <div className="flex items-start space-x-3">
          <div className="space-y-0.5">
            <div className="inline-flex h-8 w-[52px] items-center justify-center text-sm text-gray-500">
              Where
            </div>
            {filterGroup.children.length > 2 && (
              <Select
                value={filterGroup.logic}
                onValueChange={(value) => {
                  handleLogicChange(value as Logic);
                }}
              >
                <SelectTrigger
                  className="w-[52px] justify-center bg-white"
                  showIcon={false}
                >
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="AND">AND</SelectItem>
                    <SelectItem value="OR">OR</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            )}
            {/* show uneditable and/or for each of the filters */}
            {Array.from({ length: filterGroup.children.length - 3 }).map(
              (_, index) => (
                <div
                  key={index}
                  className="flex h-8 w-[52px] items-center justify-center text-sm text-gray-400"
                >
                  {filterGroup.logic}
                </div>
              )
            )}
          </div>
          <div className="space-y-0.5">
            {filterGroup.children.map((child, index) =>
              "field" in child ? (
                <>
                  {child.field === "project-owner" && (
                    <ProjectOwnerField
                      filter={child}
                      index={index}
                      filterGroup={filterGroup}
                      setFilterGroup={setFilterGroup}
                      projectOwnerFilterModel={
                        filterModels.find(
                          (f) => f.field === "project-owner"
                        ) as ProjectOwnerFilterModel
                      }
                      // phase={phase}
                    />
                  )}
                  {child.field === "project-template" && (
                    <ProjectTemplateField
                      filter={child}
                      index={index}
                      filterGroup={filterGroup}
                      setFilterGroup={setFilterGroup}
                      projectTemplateFilterModel={
                        filterModels.find(
                          (f) => f.field === "project-template"
                        ) as ProjectTemplateFilterModel
                      }
                      // phase={phase}
                    />
                  )}
                  {child.field === "company" && (
                    <CompanyField
                      filter={child}
                      index={index}
                      filterGroup={filterGroup}
                      setFilterGroup={setFilterGroup}
                    />
                  )}
                  {/* {child.field === "project-status" && (
                    <ProjectStatusField
                      filter={child}
                      index={index}
                      filterGroup={filterGroup}
                      setFilterGroup={setFilterGroup}
                    />
                  )} */}
                  {/* <div className="flex items-start space-x-2">
                    <FilterField
                      filter={child}
                      index={index}
                      filterGroup={filterGroup}
                      setFilterGroup={setFilterGroup}
                      phase={phase}
                    />
                  </div> */}
                </>
              ) : (
                // <div key={index} style={{ marginLeft: "20px" }}>
                //   <label>
                //     Field:
                //     <input
                //       type="text"
                //       value={child.field}
                //       onChange={(e) => handleFieldChange(index, e.target.value)}
                //     />
                //   </label>
                //   <label>
                //     Comparator:
                //     <input
                //       type="text"
                //       value={JSON.stringify(child.comparator)}
                //       onChange={(e) =>
                //         handleComparatorChange(
                //           index,
                //           JSON.parse(e.target.value)
                //         )
                //       }
                //     />
                //   </label>
                //   <button onClick={() => removeChild(index)}>Remove</button>
                // </div>
                // <div key={index}>
                //   <FilterGroup
                //     filterGroup={child}
                //     setFilterGroup={(updatedChild: FilterGroup) =>
                //       handleChildGroupChange(index, updatedChild)
                //     }
                //     level={level + 1}
                //   />
                //   <button onClick={() => removeChild(index)}>
                //     Remove Group
                //   </button>
                // </div>
                <></>
              )
            )}
            {filterGroup.children.length < 4 && (
              <div
                className={clsx(filterGroup.children.length > 1 && "pt-2.5")}
              >
                <DropdownMenu
                  open={open}
                  onOpenChange={(isOpen) => {
                    setOpen(isOpen);
                  }}
                >
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant={"secondary"}
                      className={clsx(
                        filterGroup.children.length === 1 &&
                          "font-normal text-gray-400/70"
                      )}
                    >
                      {filterGroup.children.length > 1
                        ? "Add Filter"
                        : "Select Filter"}
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="start">
                    <DropdownMenuGroup>
                      {filterModels.map((filterModel, index) => {
                        switch (filterModel.field) {
                          case "project-owner":
                            if (
                              filterGroup.children.find(
                                (f) => f.field === "project-owner"
                              )
                            ) {
                              return null;
                            }
                            return (
                              <DropdownMenuItem
                                onSelect={() => {
                                  addFilter("project-owner");
                                  setOpen(false);
                                }}
                              >
                                <UserIcon className="mr-2 h-3.5 w-3.5" />
                                <span>Owner</span>
                              </DropdownMenuItem>
                            );

                          case "company":
                            if (
                              filterGroup.children.find(
                                (f) => f.field === "company"
                              )
                            ) {
                              return null;
                            }
                            return (
                              <DropdownMenuItem
                                onSelect={() => {
                                  addFilter("company");
                                  setOpen(false);
                                }}
                              >
                                <BuildingOffice2Icon className="mr-2 h-3.5 w-3.5" />
                                Company
                              </DropdownMenuItem>
                            );
                          case "project-template":
                            if (
                              filterGroup.children.find(
                                (f) => f.field === "project-template"
                              )
                            ) {
                              return null;
                            }
                            return (
                              <DropdownMenuItem
                                onSelect={() => {
                                  addFilter("project-template");
                                  setOpen(false);
                                }}
                              >
                                <RectangleStackIcon className="mr-2 h-3.5 w-3.5" />
                                Template
                              </DropdownMenuItem>
                            );
                          default:
                            return null;
                        }
                      })}
                      {/* <DropdownMenuItem
                      onSelect={() => {
                        addFilter("status");
                        setOpen(false);
                      }}
                    >
                      Status
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onSelect={() => {
                        addFilter("dueDate");
                        setOpen(false);
                      }}
                    >
                      Due Date
                    </DropdownMenuItem> */}
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            )}
            {/* <Select
              value={undefined}
              onValueChange={(value) => {
                addFilter(value as Filter["field"]);
              }}
              open={open}
              onOpenChange={(isOpen) => {
                setOpen(isOpen);
              }}
            >
              <SelectTrigger
                className={clsx(
                  // "flex h-8 w-32 items-center bg-white px-3",
                  "w-fit"
                )}
                showIcon={false}
              >
                {"Add Filter"}
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="status">Status</SelectItem>
                  <SelectItem value="dueDate">Due Date</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select> */}
            {/* <Button variant={"secondary"} onClick={addFilter}>
              
            </Button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

const statuses: PhaseStatus[] = [
  "Paused",
  "In Progress",
  "Completed",
  "Canceled",
];

function CompanyField({
  filter,
  index,
  filterGroup,
  setFilterGroup,
}: // filterModels,
{
  filter: CompanyFilter;
  index: number;
  filterGroup: ProjectsV2FilterGroup;
  setFilterGroup: (filterGroup: ProjectsV2FilterGroup) => void;
}) {
  const [valueOpen, setValueOpen] = useState(!filter.value?.length);

  const removeChild = (index: number) => {
    const newChildren = filterGroup.children.filter((_, i) => i !== index);
    setFilterGroup({ ...filterGroup, children: newChildren });
  };

  const handleComparatorChange = (comparator: IdComparator) => {
    let newChildren = [...filterGroup.children];
    let currentChild = newChildren[index] as ProjectOwnerFilter;
    currentChild.comparator = comparator;
    newChildren[index] = currentChild;
    setFilterGroup({ ...filterGroup, children: newChildren });
  };

  const handleValueChange = (value: string) => {
    setFilterGroup({
      ...filterGroup,
      children: filterGroup.children.map((child, i) => {
        if (i === index) {
          return {
            ...child,
            value: child.value.includes(value)
              ? [...child.value.filter((v) => v !== value)]
              : [...child.value, value],
          };
        }
        return child;
      }),
    });
  };

  const [options, setOptions] = useState<CustomerData[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<CustomerData[]>(
    []
  );

  const [customerSearch, setCustomerSearch] = useState("");

  const { debouncedValue: debouncedSearch, isTyping } = useDebounce(
    customerSearch,
    500
  );

  const { paginatedCustomers, isError, isFetching, isLoading, isSuccess } =
    useGetCustomersWithDebouncing({
      search: debouncedSearch,
    });

  useEffect(() => {
    if (paginatedCustomers) {
      setOptions(paginatedCustomers.customers);
    }
  }, [paginatedCustomers]);

  return (
    <div className="flex items-center space-x-0 text-sm">
      <div className="flex h-8 w-32 items-center truncate rounded-l-md border px-3 text-sm">
        <BuildingOffice2Icon className="mr-1.5 h-3.5 w-3.5" />
        Company
      </div>
      <Select
        value={filter.comparator}
        onValueChange={(value) => {
          handleComparatorChange(value as IdComparator);
        }}
      >
        <SelectTrigger
          className="flex h-8 w-20 items-center truncate rounded-none border-y border-x-0 border-input bg-white px-3"
          showIcon={false}
        >
          {filter.comparator === "in"
            ? "is"
            : filter.comparator === "nin"
            ? "is not"
            : ""}
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="in">is</SelectItem>
            <SelectItem value="nin">is not</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>

      <Popover modal={true} open={valueOpen} onOpenChange={setValueOpen}>
        <PopoverTrigger className="flex h-8 w-40 items-center justify-start border-y border-l bg-white px-3 text-sm hover:bg-gray-50 focus:outline-none">
          {!!filter.value && filter.value.length > 0 ? (
            <div className="truncate">
              {filter.value.length > 1 ? (
                <div>Multiple companies</div>
              ) : (
                <div>
                  {
                    selectedCompanies.find(
                      (o) => o._id.toString() === filter.value[0]
                    )?.name
                  }
                </div>
              )}
            </div>
          ) : (
            <div className="text-sm font-normal text-gray-300">
              Select Company
            </div>
          )}
        </PopoverTrigger>
        <PopoverContent className="pointer-events-auto w-60 p-0" align="start">
          <Command className="relative">
            <CommandInput
              placeholder="Search company..."
              className="h-9"
              value={customerSearch}
              onValueChange={(s) => {
                setCustomerSearch(s);
              }}
            />
            {(isFetching || isTyping) && (
              <div className="absolute top-0 right-0 flex items-center pr-2.5 pt-2.5">
                <Loading className="h-4 w-4 border-[3px]" />
              </div>
            )}
            {!isFetching && !isTyping && (
              <CommandEmpty>No company found.</CommandEmpty>
            )}
            <CommandGroup>
              {options.map((company) => (
                <CommandItem
                  key={company._id.toString()}
                  value={company.name}
                  onSelect={() => {
                    handleValueChange(company._id.toString());
                    if (
                      selectedCompanies.find(
                        (o) => o._id.toString() === company._id.toString()
                      )
                    ) {
                      setSelectedCompanies((prev) =>
                        prev.filter(
                          (o) => o._id.toString() !== company._id.toString()
                        )
                      );
                    } else {
                      setSelectedCompanies([...selectedCompanies, company]);
                    }
                  }}
                  className="gap-2"
                >
                  <Checkbox
                    // onClick={(e) => {
                    //   // e.stopPropagation();
                    // }}
                    checked={filter.value.includes(company._id.toString())}
                    // onCheckedChange={(_) => {
                    //   handleValueChange(company._id.toString());
                    // }}
                  />

                  <div className="flex items-center space-x-2">
                    <span className="truncate">{company.name}</span>
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>

      <div
        className="flex h-8 w-fit cursor-pointer items-center rounded-r-md border bg-white px-2 transition-colors hover:bg-gray-100"
        onClick={() => {
          removeChild(index);
        }}
      >
        <XMarkIcon className="h-2.5 w-2.5 shrink-0" />
      </div>
    </div>
  );
}

function ProjectTemplateField({
  filter,
  index,
  filterGroup,
  setFilterGroup,
  projectTemplateFilterModel,
}: // filterModels,
{
  filter: ProjectTemplateFilter;
  index: number;
  filterGroup: ProjectsV2FilterGroup;
  setFilterGroup: (filterGroup: ProjectsV2FilterGroup) => void;
  // filterModels: FilterModel[];
  projectTemplateFilterModel: ProjectTemplateFilterModel;
}) {
  const [open, setOpen] = useState(!filter.field);
  const [valueOpen, setValueOpen] = useState(!filter.value?.length);
  const type = filter.field;

  const removeChild = (index: number) => {
    const newChildren = filterGroup.children.filter((_, i) => i !== index);
    setFilterGroup({ ...filterGroup, children: newChildren });
  };

  const handleComparatorChange = (comparator: IdComparator) => {
    let newChildren = [...filterGroup.children];
    let currentChild = newChildren[index] as ProjectOwnerFilter;
    currentChild.comparator = comparator;
    newChildren[index] = currentChild;
    setFilterGroup({ ...filterGroup, children: newChildren });
  };

  const handleValueChange = (value: string) => {
    setFilterGroup({
      ...filterGroup,
      children: filterGroup.children.map((child, i) => {
        if (i === index) {
          return {
            ...child,
            value: child.value.includes(value)
              ? [...child.value.filter((v) => v !== value)]
              : [...child.value, value],
          };
        }
        return child;
      }),
    });
  };

  return (
    <div className="flex items-center space-x-0 text-sm">
      <div className="flex h-8 w-32 items-center truncate rounded-l-md border px-3 text-sm">
        <RectangleStackIcon className="mr-1.5 h-3.5 w-3.5" />
        Template
      </div>
      <Select
        value={filter.comparator}
        onValueChange={(value) => {
          handleComparatorChange(value as IdComparator);
        }}
      >
        <SelectTrigger
          className="flex h-8 w-20 items-center truncate rounded-none border-y border-x-0 border-input bg-white px-3"
          showIcon={false}
        >
          {filter.comparator === "in"
            ? "is"
            : filter.comparator === "nin"
            ? "is not"
            : ""}
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="in">is</SelectItem>
            <SelectItem value="nin">is not</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>

      <Popover modal={true} open={valueOpen} onOpenChange={setValueOpen}>
        <PopoverTrigger className="flex h-8 w-40 items-center justify-start border-y border-l bg-white px-3 text-sm hover:bg-gray-50 focus:outline-none">
          {!!filter.value && filter.value.length > 0 ? (
            <div className="truncate">
              {filter.value.length > 1 ? (
                <div className="flex items-center space-x-0.5">
                  {filter.value.map((o) => {
                    return (
                      <div
                        key={o}
                        className="flex h-6 w-6 items-center justify-center rounded border bg-gray-100 text-xs uppercase text-gray-600 shadow-sm"
                      >
                        {projectTemplateFilterModel.projectTemplates.find(
                          (pt) => pt._id === o
                        )?.name ?? ""}
                      </div>
                    );
                  })}
                </div>
              ) : (
                projectTemplateFilterModel.projectTemplates.find(
                  (o) => o._id === filter.value[0]
                )?.name
              )}
            </div>
          ) : (
            <div className="text-sm font-normal text-gray-300">
              Select Template
            </div>
          )}
        </PopoverTrigger>
        <PopoverContent
          className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
          align="start"
        >
          <Command>
            <CommandInput placeholder="Search template..." className="h-9" />
            <CommandEmpty>No template found.</CommandEmpty>
            <CommandGroup>
              {projectTemplateFilterModel.projectTemplates.map(
                (projectTemplate) => (
                  <CommandItem
                    key={projectTemplate._id}
                    value={projectTemplate.name}
                    onSelect={() => {
                      handleValueChange(projectTemplate._id);
                    }}
                    className="gap-2"
                  >
                    <Checkbox
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      checked={filter.value.includes(projectTemplate._id)}
                      onCheckedChange={(_) => {
                        handleValueChange(projectTemplate._id);
                      }}
                    />

                    <div className="flex items-center space-x-2">
                      <span className="truncate">{projectTemplate.name}</span>
                    </div>
                  </CommandItem>
                )
              )}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>

      <div
        className="flex h-8 w-fit cursor-pointer items-center rounded-r-md border bg-white px-2 transition-colors hover:bg-gray-100"
        onClick={() => {
          removeChild(index);
        }}
      >
        <XMarkIcon className="h-2.5 w-2.5 shrink-0" />
      </div>
    </div>
  );
}
function ProjectOwnerField({
  filter,
  index,
  filterGroup,
  setFilterGroup,
  projectOwnerFilterModel,
}: // filterModels,
{
  filter: ProjectOwnerFilter;
  index: number;
  filterGroup: ProjectsV2FilterGroup;
  setFilterGroup: (filterGroup: ProjectsV2FilterGroup) => void;
  // filterModels: FilterModel[];
  projectOwnerFilterModel: ProjectOwnerFilterModel;
}) {
  const [open, setOpen] = useState(!filter.field);
  const [valueOpen, setValueOpen] = useState(!filter.value?.length);
  const type = filter.field;

  const removeChild = (index: number) => {
    const newChildren = filterGroup.children.filter((_, i) => i !== index);
    setFilterGroup({ ...filterGroup, children: newChildren });
  };

  const handleComparatorChange = (comparator: IdComparator) => {
    let newChildren = [...filterGroup.children];
    let currentChild = newChildren[index] as ProjectOwnerFilter;
    currentChild.comparator = comparator;
    newChildren[index] = currentChild;
    setFilterGroup({ ...filterGroup, children: newChildren });
  };

  const handleValueChange = (value: string) => {
    setFilterGroup({
      ...filterGroup,
      children: filterGroup.children.map((child, i) => {
        if (i === index) {
          return {
            ...child,
            value: child.value.includes(value)
              ? [...child.value.filter((v) => v !== value)]
              : [...child.value, value],
          };
        }
        return child;
      }),
    });
  };

  return (
    <div className="flex items-center space-x-0 text-sm">
      <div className="flex h-8 w-32 items-center truncate rounded-l-md border px-3 text-sm">
        <UserIcon className="mr-1.5 h-3.5 w-3.5" />
        Owner
      </div>
      <Select
        value={filter.comparator}
        onValueChange={(value) => {
          handleComparatorChange(value as IdComparator);
        }}
      >
        <SelectTrigger
          className="flex h-8 w-20 items-center truncate rounded-none border-y border-x-0 border-input bg-white px-3"
          showIcon={false}
        >
          {filter.comparator === "in"
            ? "is"
            : filter.comparator === "nin"
            ? "is not"
            : ""}
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="in">is</SelectItem>
            <SelectItem value="nin">is not</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>

      <Popover modal={true} open={valueOpen} onOpenChange={setValueOpen}>
        <PopoverTrigger className="flex h-8 w-40 items-center justify-start border-y border-l bg-white px-3 text-sm hover:bg-gray-50 focus:outline-none">
          {!!filter.value && filter.value.length > 0 ? (
            <div className="truncate">
              {filter.value.length > 1 ? (
                <div className="flex items-center space-x-0.5">
                  {filter.value.map((o) => {
                    return (
                      <div
                        key={o}
                        className="flex h-6 w-6 items-center justify-center rounded border bg-gray-100 text-xs uppercase text-gray-600 shadow-sm"
                      >
                        {getFirstAndLastInitials(
                          projectOwnerFilterModel.projectOwners.find(
                            (po) => po._id === o
                          )?.name ?? ""
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                projectOwnerFilterModel.projectOwners.find(
                  (o) => o._id === filter.value[0]
                )?.name
              )}
            </div>
          ) : (
            <div className="text-sm font-normal text-gray-300">
              Select Owner
            </div>
          )}
        </PopoverTrigger>
        <PopoverContent
          className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
          align="start"
        >
          <Command>
            <CommandInput placeholder="Search owner..." className="h-9" />
            <CommandEmpty>No owner found.</CommandEmpty>
            <CommandGroup>
              {projectOwnerFilterModel.projectOwners.map((projectOwner) => (
                <CommandItem
                  key={projectOwner._id}
                  value={projectOwner.name}
                  onSelect={() => {
                    handleValueChange(projectOwner._id);
                  }}
                  className="gap-2"
                >
                  <Checkbox
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    checked={filter.value.includes(projectOwner._id)}
                    onCheckedChange={(_) => {
                      handleValueChange(projectOwner._id);
                    }}
                  />

                  <div className="flex items-center space-x-2">
                    <span className="truncate">{projectOwner.name}</span>
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>

      <div
        className="flex h-8 w-fit cursor-pointer items-center rounded-r-md border bg-white px-2 transition-colors hover:bg-gray-100"
        onClick={() => {
          removeChild(index);
        }}
      >
        <XMarkIcon className="h-2.5 w-2.5 shrink-0" />
      </div>
    </div>
  );
}

type Project = ProjectDataV2 & ProjectDetailsData;

function GeneralProjectsList({
  projects,
  // filterGroup,
  columnsToDisplay,
  projectDetails,
  search,
  setSearch,
  setNoOfProjectsAfterSearch,
  setColumnsToDisplay,
}: {
  projects: ProjectDataV2[];
  // filterGroup: ProjectsV2FilterGroup;
  columnsToDisplay: ColumnsToDisplay;
  projectDetails: ProjectDetailsData[] | undefined;
  search: string;
  setSearch: (search: string) => void;
  setNoOfProjectsAfterSearch: React.Dispatch<React.SetStateAction<number>>;
  setColumnsToDisplay: React.Dispatch<React.SetStateAction<ColumnsToDisplay>>;
}) {
  // console.log(columnsToDisplay);
  const { data: customFields } = useGetCustomFields("project");
  const [sorting, setSorting] = useState<SortingState>([]);
  const data = useMemo<Project[]>(() => {
    if (!projects) return [];
    // return projects;
    return projects.map((project) => ({
      ...project,
      ...(projectDetails?.find(
        (projectDetails) => project._id === projectDetails._id
      ) || {}),
    }));
  }, [projects, projectDetails]);

  const table = useReactTable<Project>({
    data,
    columns: getColumns(columnsToDisplay, customFields ?? []),
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      globalFilter: search,
      columnVisibility: transformColumnsToDisplay(columnsToDisplay),
    },
    globalFilterFn: fuzzyFilter,
    onSortingChange: setSorting,
    onGlobalFilterChange: setSearch,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // onColumnVisibilityChange: setColumnsToDisplay,
  });
  const navigate = useNavigate();

  useEffect(() => {
    setNoOfProjectsAfterSearch(table.getRowModel().rows.length);
  }, [table.getRowModel().rows]);

  if (table.getRowModel().rows.length === 0) {
    return <NoProjectsMeetFilter />;
  }

  return (
    <div className="flex-1 overflow-auto">
      <div className="max-h-full max-w-full overflow-auto">
        <table
          style={{ borderCollapse: "separate", borderSpacing: 0 }}
          className="min-w-[600px]"
        >
          <thead className="overflow-auto">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    scope="col"
                    className={
                      "sticky top-0 z-10 h-11 whitespace-nowrap border-b border-r border-gray-200/70 bg-gray-50 px-6 text-left text-xs font-normal uppercase tracking-wide text-gray-400 first:left-0 first:z-20 first:w-full first:pl-8"
                    }
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {!projects ? (
              <tr>
                {/* <td className="inset-0 flex items-center justify-center p-4 pl-8"> */}
                <div className="sticky left-0 z-10 p-4 pl-8 text-gray-400">
                  Loading...
                </div>
                {/* </td> */}
              </tr>
            ) : (
              <>
                {projects.length === 0 ? (
                  <tr>
                    <div className="sticky left-0 z-10 whitespace-nowrap p-4 pl-8 text-gray-400">
                      No projects
                    </div>
                    {/* <td className="inset-0 flex items-center justify-center p-4">
                </td> */}
                  </tr>
                ) : (
                  <>
                    {table.getRowModel().rows.map((row) => (
                      <tr
                        key={row.id}
                        className="group relative rounded-lg hover:cursor-pointer hover:bg-gray-50"
                        onClick={() => {
                          navigate(`/projects/${row.original._id}/home`);
                        }}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            className={
                              "whitespace-nowrap border-r border-b border-gray-100 px-6 py-3 text-sm text-gray-500 first:sticky first:left-0 first:z-10 first:bg-white first:pr-6 first:font-medium first:text-gray-900 first:group-hover:bg-gray-50 first:sm:pl-8"
                            }
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function getColumns(
  columnsToDisplay: ColumnsToDisplay,
  customFields: CustomFieldData[]
) {
  let columns: ColumnDef<Project>[] = [
    {
      id: "project",
      header: () => {
        return <div className="w-72">Name</div>;
      },
      cell: ({ row }) => {
        return <div className="h-full w-72 truncate">{row.original.name}</div>;
      },
      accessorFn: ({ name }) => name,
    },
    {
      id: "company",
      header: ({ table }) => {
        return <div className="w-52">Company</div>;
      },
      cell: ({ row }) => {
        return (
          <div className="h-full w-52 truncate">
            {row.original.customer?.name}
          </div>
        );
      },
      enableSorting: false,
      enableHiding: false,
      accessorFn: ({ customer }) => customer?.name,
    },
    {
      id: "internalUpdates",
      header: ({ column }) => (
        <div className="flex items-center space-x-1">
          <div>Updates</div>
          {/* <SortToggle column={column} /> */}
        </div>
      ),
      cell: ({ row }) => {
        return (
          <HoverCard openDelay={100} closeDelay={100}>
            <HoverCardTrigger asChild>
              <div className="flex h-full w-32 items-center space-x-1">
                {row.original.internalUpdates?.map((update) => (
                  <InternalUpdateSymbol update={update} />
                ))}
              </div>
            </HoverCardTrigger>
            <HoverCardContent
              className="w-[480px] p-0"
              sideOffset={6}
              align="start"
            >
              {row.original.internalUpdates
                ?.filter((u) => u.isManual)
                .map((update) => (
                  <InternalUpdate update={update} />
                ))}
            </HoverCardContent>
          </HoverCard>
        );
      },
    },
    {
      id: "progress",
      header: ({ column }) => (
        <div className="flex items-center space-x-1">
          <div>Progress</div>
          {/* <SortToggle column={column} /> */}
        </div>
      ),
      cell: ({ row }) => {
        // return <div>hello??</div>
        // return <CellSkeleton width={56 * 4} />;
        if (!row.original.journey) return <CellSkeleton width={44 * 4} />;
        const progress = Math.round(
          (row.original.journey.completedTasks /
            row.original.journey.totalTasks) *
            100
        );
        const hasTasks = row.original.journey.totalTasks > 0;
        return (
          <>
            <div className="flex w-44 items-center space-x-4">
              <div className="flex items-center space-x-3">
                <div
                  className={clsx(
                    "h-1.5 w-20 shrink-0 overflow-hidden rounded-sm",
                    row.original.journey.completedTasks === 0
                      ? "border-gray-300 bg-gray-200/70"
                      : "border-green-200 bg-green-200/70"
                  )}
                >
                  <div
                    style={{ width: hasTasks ? progress + "%" : "0%" }}
                    className="h-full rounded-sm bg-green-500"
                  ></div>
                </div>
                <div className="w-6 text-sm font-medium text-gray-600">
                  {(hasTasks ? progress.toFixed(0) : "0") + "%"}
                </div>
              </div>
              <div className="flex h-5 w-fit items-center justify-center rounded-md bg-gray-50 px-1.5">
                <div className="text-[11px] text-gray-400">{`${row.original.journey.completedTasks}/${row.original.journey.totalTasks}`}</div>
              </div>
            </div>
          </>
        );
      },
    },
    {
      id: "status",
      header: () => {
        return (
          <div className="flex w-32 items-center space-x-2">
            <span>Status</span>
          </div>
        );
      },
      cell: ({ row }) => {
        const { status } = row.original;
        return (
          <div className="h-full">
            <span>{getPhaseStatus(status)}</span>
          </div>
        );
      },
    },
    {
      id: "owner",
      header: () => {
        return <div className="w-44">Owner</div>;
      },
      cell: ({ row }) => {
        return (
          <div className="h-full w-44 truncate">{row.original.owner.name}</div>
        );
      },
      enableSorting: false,
      enableHiding: false,
      accessorFn: ({ owner }) => owner?.name,
    },
    {
      id: "deltaToTarget",
      header: () => {
        return <div className="w-44 truncate">Delta to Target</div>;
      },
      cell: ({ row }) => {
        if (!row.original.keyDates) return null;
        // if (row.original.type === "Simple") {
        //   return <div className="text-gray-500">-</div>;
        // }
        const forecastedCompletionDate = new Date(
          row.original.keyDates.forecastedCompletionDate
        );
        const plannedCompletionDate = new Date(
          row.original.keyDates.plannedCompletionDate
        );
        let completionDate;
        let completionDelta;
        if (row.original.keyDates.completionDate) {
          completionDate = new Date(row.original.keyDates.completionDate);
          completionDelta = getTimeDifference(
            completionDate,
            plannedCompletionDate
          );
        }

        const delta = getTimeDifference(
          forecastedCompletionDate,
          plannedCompletionDate
        );
        return (
          <>
            {!!completionDate && !!completionDelta ? (
              <div>
                <div
                  className={clsx(
                    "text-sm",
                    delta.isDate2After ? "text-green-500" : "text-red-500"
                  )}
                >{`Completed ${delta.absValue} ${delta.unit} ${
                  delta.isDate2After ? "ahead of time" : "behind schedule"
                }`}</div>
              </div>
            ) : (
              <div className="space-y-1">
                <div
                  className={clsx(
                    "text-sm",
                    delta.isDate2After ? "text-green-500" : "text-red-500"
                  )}
                >{`${delta.absValue} ${delta.unit} ${
                  delta.isDate2After ? "ahead" : "behind"
                }`}</div>
                {/* <div
                  className={clsx(
                    "text-xs text-gray-500"
                    // delta.isDate2After ? "text-green-500" : "text-red-500"
                  )}
                >
                  <span>{dayjs(plannedCompletionDate).format("DD MMM")}</span>
                  <span className="text-gray-400"> vs </span>
                  <span>{dayjs(forecastedCompletionDate).format("DD MMM")}</span>
                </div> */}
              </div>
            )}
          </>
        );
      },
    },
    {
      id: "forecastedCompletionDate",
      header: () => {
        return <div className="w-44 truncate">Forecasted Completion Date</div>;
      },
      cell: ({ row }) => {
        if (!row.original.keyDates) return null;
        const forecastedCompletionDate = new Date(
          row.original?.keyDates?.forecastedCompletionDate
        );
        const plannedCompletionDate = new Date(
          row.original.keyDates.plannedCompletionDate
        );
        return (
          <div className="h-full">
            <span>
              {dayjs(forecastedCompletionDate).format("MMM DD, YYYY")}
            </span>
            {/* <span className="text-sm text-gray-500">
              {dayjs(plannedCompletionDate).format("MMM DD, YYYY")}
            </span> */}
          </div>
        );
      },
    },
    {
      id: "template",
      header: () => {
        return <div className="w-44">Template</div>;
      },
      cell: ({ row }) => {
        return (
          <div className="h-full w-44 truncate">
            {row.original.template?.name ?? ""}
          </div>
        );
      },
    },
  ];
  // add custom fields
  for (const key in columnsToDisplay) {
    const column = columnsToDisplay[key];
    if (column.isCustomField) {
      columns.push({
        id: column.id,
        header: () => {
          return (
            <div style={{ width: column.width }} className="truncate">
              {column.name}
            </div>
          );
        },
        cell: ({ row }) => {
          // find if the custom field exists in the row
          const customField = row.original.customFields?.find(
            (cf) => cf._id === column.id
          );
          if (customField) {
            if (customField.type === "date") {
              console.log(customField.value);
              if (!!customField.value) {
                return (
                  <div>{dayjs(customField.value).format("MMM DD, YYYY")}</div>
                );
              } else {
                return null;
              }
            } else if (customField.type === "multi-select") {
              const field = customFields.find(
                (cf) => cf._id === column.id
              ) as CustomFieldMultiSelectData;
              if (field) {
                return (
                  <div className="flex items-center space-x-1">
                    {customField.value?.map((v) => (
                      <div className="flex h-5 items-center rounded bg-gray-100 px-1.5 text-xs">
                        {field.options.find((o) => o.value === v)?.label}
                      </div>
                    ))}
                  </div>
                );
              }
            } else if (customField.type === "select") {
              const field = customFields.find(
                (cf) => cf._id === column.id
              ) as CustomFieldSelectData;
              if (field && !!customField.value) {
                return (
                  <div className="flex h-5 w-fit items-center rounded bg-gray-100 px-1.5 text-xs">
                    {
                      field.options.find((o) => o.value === customField.value)
                        ?.label
                    }
                  </div>
                );
              }
            } else if (customField.type === "currency") {
              if (customField.value) {
                return (
                  <div className="flex h-full items-center truncate text-sm">
                    <div className="text-gray-500">{"$"}</div>
                    <div>{customField.value?.toLocaleString()}</div>
                  </div>
                );
              }
            } else if (customField.type === "boolean") {
              if (customField.value) {
                return (
                  <div className="flex h-full items-center truncate text-sm">
                    <div className="text-gray-500">
                      {customField.value ? "Yes" : "No"}
                    </div>
                  </div>
                );
              }
            }
            return (
              <div className="h-full truncate">
                {customField.value?.toString()}
              </div>
            );
          }
        },

        enableSorting: false,
        enableHiding: false,
        // accessorFn: ({ customFields }) => {
        //   const customField = customFields?.find((cf) => cf._id === column.id);
        //   if (customField) {
        //     return customField.value;
        //   }
        // },
      });
    }
  }
  return columns.filter((column) => !!column);
}

function InternalUpdateSymbol({ update }: { update: InternalPhaseUpdateData }) {
  // if (!!update.isSkipped) {
  //   return (
  //     <div className="flex h-3 w-3 items-center justify-center rounded-full border border-gray-500 bg-gray-100">
  //       <XMarkIcon strokeWidth={2.5} className="h-3 w-3 text-gray-500" />
  //     </div>
  //   );
  // }
  switch (update.status) {
    case "On-Track":
      return (
        <div className="h-3 w-3 rounded-full border border-green-500 bg-green-200"></div>
      );
    case "Off-Track":
      return (
        <div className="h-3 w-3 rounded-full border border-red-500 bg-red-200"></div>
      );
    case "At Risk":
      return (
        <div className="h-3 w-3 rounded-full border border-yellow-500 bg-yellow-200"></div>
      );
    default:
      return null;
  }
}

function CellSkeleton({ width }: { width: number }) {
  return (
    <div className={`flex h-5 flex-col`} style={{ width }}>
      <div className={`w-full flex-1 animate-pulse rounded bg-gray-100`} />
    </div>
  );
}

function transformColumnsToDisplay(columnsToDisplay: ColumnsToDisplay): {
  [key: string]: boolean;
} {
  const transformedColumnsToDisplay: { [key: string]: boolean } = {};
  for (const columnId in columnsToDisplay) {
    transformedColumnsToDisplay[columnId] =
      !!columnsToDisplay[columnId].visible;
  }
  return transformedColumnsToDisplay;
}
