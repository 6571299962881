import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { ObjectID } from "bson";
import { buildFetcher, buildSetter } from "src/api";
import { useAccessToken } from "src/api/useAccessToken";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { useRealmApp } from "src/store/RealmApp";
import { journeyKeys } from "../Journeys/journeys";
import { taskKeys } from "../Tasks/tasks";
import { phaseKeys } from "./phases";

export const resourceKeys = {
  resources: (phaseId: string) => ["resources", "phase", phaseId] as const,
};

export type ResourceData =
  | FileResourceData
  | LinkResourceData
  | FormResourceData;

export type FormResourceData = {
  task: {
    _id: string;
    title: string;
    isVisible: boolean;
  };
  type: "form";
  name: string;
  _id: string;
};

export type LinkResourceData = {
  link: {
    url: string;
  };
  type: "link";
} & BaseResourceData;

export type FileResourceData = {
  taskId?: string;
  file: {
    _id: string;
    fileName: string;
    type: string;
    url: string;
  };
  type: "file";
} & BaseResourceData;

type BaseResourceData = {
  _id: string;
  name: string;
  description: string;
  phaseId: string;
  sharedSpaceId: string;
  instanceId: string;
  isVisible: boolean;
  createdBy: {
    _id: string;
    name: string;
    email: string;
  };
  createdAt: string;
  updatedAt: string;
};

export const useUpdatePhaseResource = (phaseId: string) => {
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (resource: {
      _id: string;
      name?: string;
      description?: string;
      isVisible?: boolean;
      url?: string;
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/updateResource`,
        {
          ...resource,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error updating resource", "error");
    },
    onSuccess: () => {
      snackbarCtx.showSnackbar("Resource updated", "success");
      queryClient.invalidateQueries(resourceKeys.resources(phaseId));
    },
  });
};

export const useCreatePhaseLinkResource = (phaseId: string) => {
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (link: {
      name: string;
      url: string;
      isVisible: boolean;
      description: string;
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/linkResource`,
        {
          phaseId,
          ...link,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error adding link as a resource", "error");
    },
    onSuccess: () => {
      queryClient.invalidateQueries(resourceKeys.resources(phaseId));
    },
  });
};

export const useGetPhaseResources = (phaseId: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    resourceKeys.resources(phaseId),
    async (): Promise<ResourceData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/resources`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            phaseId,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};

export const useDeleteResource = (phaseId: string) => {
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (resource: {
      _id: string;
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.delete(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/resource`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          params: resource,
        }
      );
      return res.data;
    },
    onMutate: async (variables) => {
      await queryClient.cancelQueries(resourceKeys.resources(phaseId));
      const previousResources = queryClient.getQueryData<ResourceData[]>(
        resourceKeys.resources(phaseId)
      );
      queryClient.setQueryData(
        resourceKeys.resources(phaseId),
        previousResources?.filter((r) => r._id !== variables._id)
      );
      return { previousResources };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(
        resourceKeys.resources(phaseId),
        context?.previousResources
      );
      snackbarCtx.showSnackbar("Error deleting resource", "error");
    },
    onSuccess: () => {
      queryClient.invalidateQueries(resourceKeys.resources(phaseId));
    },
  });
};

// TYPES

export type FileData = {
  _id: ObjectID;
  name: string;
  label: string;
  description: string;
  type: string;
  sharedSpaceId: ObjectID;
  isVisible: boolean;
  createdAt: Date;
  updatedAt: Date;
  encodedFile: string;
  url: string;
  createdBy: {
    _id: ObjectID;
    name: string;
    email: string;
  };
  readOnlyTaskId: ObjectID;
};

// FILES

export const useUploadFileForTaskInSharedSpace = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (fileDetails: {
      file: File;
      taskId: string;
      name: string;
      label: string;
      phaseId: string;
      isVisible: boolean;
      journeyId: string;
      description?: string;
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const formData = new FormData();

      formData.append("file", fileDetails.file);
      formData.append("label", fileDetails.label);
      formData.append("isVisible", JSON.stringify(fileDetails.isVisible));
      formData.append("phaseId", fileDetails.phaseId);
      formData.append("createdBy", app.currentUser?.id);
      formData.append("taskId", fileDetails.taskId);
      formData.append("description", fileDetails.description ?? "");

      // console.log(formData.getAll("file"));

      const res = await axios.post(
        `${process.env.REACT_APP_LAMBDA_URL}/uploadSharedSpaceFile
        `,
        formData,
        {
          headers: {
            Accept: "application/json",
            // "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: (err) => {
      console.log(err);
      snackbarCtx.showSnackbar("Error uploading file", "error");
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["tasks"]);
      if (variables.taskId) {
        queryClient.invalidateQueries(taskKeys.logs(variables.taskId));
        queryClient.invalidateQueries(taskKeys.task(variables.taskId));
      }
      if (variables.phaseId) {
        queryClient.invalidateQueries(phaseKeys.files(variables.phaseId));
      }
      // if (variables.sharedSpaceId) {
      //   queryClient.invalidateQueries(
      //     sharedSpaceKeys.tasks(new ObjectID(variables.sharedSpaceId))
      //   );
      //   queryClient.invalidateQueries(
      //     sharedSpaceKeys.taskBreakdown(new ObjectID(variables.sharedSpaceId))
      //   );
      // }
      if (variables.journeyId) {
        queryClient.invalidateQueries(
          journeyKeys.journey(new ObjectID(variables.journeyId))
        );
        queryClient.invalidateQueries(
          journeyKeys.stages(new ObjectID(variables.journeyId))
        );
        queryClient.invalidateQueries(
          journeyKeys.keyDates(new ObjectID(variables.journeyId))
        );
        queryClient.invalidateQueries(
          journeyKeys.tasksProgress(new ObjectID(variables.journeyId))
        );
      }
      if (variables.phaseId) {
        queryClient.invalidateQueries(
          taskKeys.phaseTasks(variables.phaseId.toString())
        );
        queryClient.invalidateQueries(
          resourceKeys.resources(variables.phaseId.toString())
        );
      }
      // snackbarCtx.showSnackbar("Successfully duplicated journey template");
    },
  });
};

export const useGetPhaseFile = (phaseId: string, _id: string) => {
  const app = useRealmApp();
  const meta = {
    functionName: "getSharedSpaceFileWithUserType",
    parameters: {
      _id: new ObjectID(_id),
    },
  };

  return useQuery<{ sharedSpaceFile: FileData; type: "internal" | "external" }>(
    phaseKeys.file(phaseId, _id),
    buildFetcher(app, meta),
    {
      staleTime: 1000 * 60 * 5,
    }
  );
};

export const useGetPhaseFiles = (phaseId: string) => {
  const app = useRealmApp();
  const meta = {
    functionName: "getSharedSpaceFiles",
    parameters: {
      phaseId: new ObjectID(phaseId),
    },
  };

  return useQuery<FileData[]>(
    phaseKeys.files(phaseId),
    buildFetcher(app, meta),
    {
      staleTime: 1000 * 60 * 5,
    }
  );
};

// export const useGetSharedSpaceFiles = (
//   sharedSpaceId: string
// ): {
//   files: FileData[] | undefined;
// } => {
//   const app = useRealmApp();
//   const queryClient = useQueryClient();
//   const meta = {
//     functionName: "getSharedSpaceFiles",
//     parameters: {
//       sharedSpaceId: new ObjectID(sharedSpaceId),
//     },
//   };

//   const { data } = useQuery<FileData[]>(
//     phaseKeys.files(phaseId, sharedSpaceId),
//     buildFetcher(app, meta),
//     {
//       staleTime: 1000 * 60 * 5,
//     }
//   );

//   return {
//     files: data,
//   };
// };

// export const useUploadSharedSpaceFile = () => {
//   const app = useRealmApp();
//   const functionName = "uploadSharedSpaceFile";
//   const fieldName = "params";
//   const queryClient = useQueryClient();
//   const snackbarCtx = useSnackBar();
//   const navigate = useNavigate();

//   const uploadSharedSpaceFileMutation = useMutation(
//     buildSetter(app, functionName, fieldName),
//     {
//       onMutate: async ({
//         params,
//       }: {
//         params: {
//           name: string;
//           type: string;
//           sharedSpaceId: ObjectID;
//           isVisible: boolean;
//           encodedFile: string;
//         };
//       }) => {},
//       onError: (data, variables, context) => {
//         snackbarCtx.showSnackbar("Error uploading file", "error");
//       },
//       onSettled: (data, error, variables) => {
//         queryClient.invalidateQueries(
//           fileKeys.sharedSpaceFiles(variables.params.sharedSpaceId)
//         );
//       },
//     }
//   );
//   return uploadSharedSpaceFileMutation;
// };

// export const useDeleteSharedSpaceFile = () => {
//   const app = useRealmApp();
//   const functionName = "deleteSharedSpaceFile";
//   const fieldName = "params";
//   const queryClient = useQueryClient();
//   const snackbarCtx = useSnackBar();
//   const navigate = useNavigate();

//   const deleteSharedSpaceFileMutation = useMutation(
//     buildSetter(app, functionName, fieldName),
//     {
//       onMutate: async ({
//         params,
//         sharedSpaceId,
//       }: {
//         params: {
//           _id: ObjectID;
//         };
//         sharedSpaceId: ObjectID;
//       }) => {
//         // const queryKey = fileKeys.sharedSpaceFiles(sharedSpaceId);
//         // await queryClient.cancelQueries(queryKey);
//         // const previousFiles = queryClient.getQueryData(queryKey);
//         // queryClient.setQueryData(queryKey, (threads: ThreadData[] = []) =>
//         //   threads.filter((t) => t._id.toString() !== params._id.toString())
//         // );
//         // return { previousThreads };
//       },
//       onError: (data, variables, context) => {
//         snackbarCtx.showSnackbar("Error deleting file", "error");
//         // queryClient.setQueryData(
//         //   threadKeys.threads(),
//         //   context?.previousThreads
//         // );
//       },
//       onSettled: (data, error, variables) => {
//         queryClient.invalidateQueries(
//           fileKeys.sharedSpaceFiles(variables.sharedSpaceId)
//         );
//       },
//     }
//   );

//   return deleteSharedSpaceFileMutation;
// };

export const useUpdatePhaseFile = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const functionName = "updateSharedSpaceFile";
  type UpdateType = Partial<FileData>;

  const queryClient = useQueryClient();
  const updateSharedSpaceFileMutation = useMutation(
    buildSetter(app, functionName, "update"),
    {
      onMutate: async (params: { update: UpdateType; phaseId: string }) => {
        // const queryKey = fileKeys.sharedSpaceFiles(params.sharedSpaceId);
        // await queryClient.cancelQueries(queryKey);
        // const previousFile = queryClient.getQueryData<FileData>(queryKey);
        // const newFile = { ...previousFile, ...params.update };
        // queryClient.setQueryData(queryKey, newThread);
        // return { previousThread };
      },
      onError: (err, update, context) => {
        // queryClient.setQueryData(
        //   threadKeys.thread(update._id),
        //   context?.previousThread
        // );
        snackbarCtx.showSnackbar("Error updating file", "error");
      },
      onSettled: (data, error, variables) => {
        if (variables.update._id) {
          queryClient.invalidateQueries(
            phaseKeys.file(variables.phaseId, variables.update._id.toString())
          );
          queryClient.invalidateQueries(phaseKeys.files(variables.phaseId));
        }
      },
    }
  );
  return updateSharedSpaceFileMutation;
};

// export const useUpdateThread = () => {
//   const app = useRealmApp();
//   const snackbarCtx = useSnackBar();
//   const functionName = "updateThread";
//   type UpdateType = Partial<ThreadData>;

//   const queryClient = useQueryClient();
//   const updateKRTMutation = useMutation(
//     buildSetter(app, functionName, "update"),
//     {
//       onMutate: async (params: { update: UpdateType; _id: ObjectID }) => {
//         const queryKey = threadKeys.thread(params._id);
//         await queryClient.cancelQueries(queryKey);
//         const previousThread = queryClient.getQueryData<ThreadData>(queryKey);
//         const newThread = { ...previousThread, ...params.update };
//         queryClient.setQueryData(queryKey, newThread);
//         return { previousThread };
//       },
//       onError: (err, update, context) => {
//         queryClient.setQueryData(
//           threadKeys.thread(update._id),
//           context?.previousThread
//         );
//         snackbarCtx.showSnackbar("Error updating the thread", "error");
//       },
//       onSettled: (data, error, variables) => {
//         queryClient.invalidateQueries(["threads"]);
//       },
//     }
//   );
//   return updateKRTMutation;
// };

// // THREAD MESSAGE

// export const useCreateThreadMessage = () => {
//   const app = useRealmApp();
//   const functionName = "createThreadMessage";
//   const fieldName = "params";
//   const queryClient = useQueryClient();
//   const snackbarCtx = useSnackBar();
//   const navigate = useNavigate();

//   const createThreadMessageMutation = useMutation(
//     buildSetter(app, functionName, fieldName),
//     {
//       onMutate: async ({
//         params,
//       }: {
//         params: {
//           text?: string;
//           threadId: ObjectID;
//           attachments?: string[];
//         };
//       }) => {},
//       onError: (data, variables, context) => {
//         snackbarCtx.showSnackbar("Error sending message", "error");
//       },
//       onSettled: (data, error, variables) => {
//         // snackbarCtx.showSnackbar("New knowledge resource created", "success");
//         queryClient.invalidateQueries(
//           threadKeys.threadMessages(variables.params.threadId)
//         );

//         // navigate(`/threads/${tempData.id.toString()}`, {
//         //   state: { isEditable: true },
//         // });
//       },
//     }
//   );

//   return createThreadMessageMutation;
// };
