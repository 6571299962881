import PhaseNotes from "src/pages/Services/Notes/PhaseNotes";
import { PhaseNotesContextProvider } from "src/pages/Services/Notes/PhaseNotesContextProvider";

export default function Notes() {
  return (
    <PhaseNotesContextProvider isExternal={false} isEmbed={false}>
      <PhaseNotes />
    </PhaseNotesContextProvider>
  );
}
