import React, { useReducer } from "react";

type Action = {
  type: "siderbar_is_open_update";
  isSidebarOpen: boolean;
}

type Dispatch = (action: Action) => void;

type State = {
  isSidebarOpen: boolean;
}

const SidebarContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function sidebarReducer(state: State, action: Action): State {
  switch (action.type) {
    case "siderbar_is_open_update": {
      return { ...state, isSidebarOpen: action.isSidebarOpen };
    }
    default: {
      return state;
    }
  }
}

export const SidebarContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(sidebarReducer, {
    isSidebarOpen: true,
  });

  const value = { state, dispatch };
  return (
    <SidebarContext.Provider value={value}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => {
  const context = React.useContext(SidebarContext);
  if (context === undefined) {
    throw new Error("useSidebar must be used within a SidebarProvider");
  }
  return context;
}