import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useCompleteTaskFromEmail } from "src/api/Automations/email";
import { Loading } from "src/components/ui/loading";

export default function TaskCompletion() {
  const [searchParams, setSearchParams] = useSearchParams();
  const taskId = searchParams.get("taskId");
  const userId = searchParams.get("userId");

  if (!taskId) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div>There was an error with the task completion.</div>
      </div>
    );
  }

  return (
    <div className="h-screen">
      <div className="mx-6 flex items-center justify-center pt-[20vh]">
        <RunTaskCompletion taskId={taskId} userId={userId} />
      </div>
    </div>
  );
}

function RunTaskCompletion({
  taskId,
  userId,
}: {
  taskId: string;
  userId: string | null;
}) {
  const completeTaskFromEmailMutation = useCompleteTaskFromEmail();
  useEffect(() => {
    completeTaskFromEmailMutation.mutate({
      taskId,
      userId,
    });
  }, [taskId, userId]);

  const [title, setTitle] = useState("");

  useEffect(() => {
    if (completeTaskFromEmailMutation.isSuccess) {
      setTitle(completeTaskFromEmailMutation.data?.taskTitle || "");
    }
  }, [completeTaskFromEmailMutation.isSuccess]);

  return (
    <div>
      {completeTaskFromEmailMutation.isLoading && <Loading />}
      {completeTaskFromEmailMutation.isSuccess && (
        <div className="flex w-full min-w-[400px] max-w-xl flex-col items-center rounded-md border p-6 shadow-md">
          <CheckCircleIcon className="h-10 w-10 text-green-500" />
          <div className="pt-2 text-sm text-gray-500">
            Task marked as complete
          </div>
          <div className="max-w-sm pt-3 text-center text-lg font-medium">
            {title}
          </div>
        </div>
      )}
    </div>
  );
}
