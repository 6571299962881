import React from "react";

export default function useDebounce(value: string, delay: number = 500) {
  const [debouncedValue, setDebouncedValue] = React.useState(value);
  const [isTyping, setIsTyping] = React.useState(false);
  React.useEffect(() => {
    setIsTyping(true);
    const handler: NodeJS.Timeout = setTimeout(() => {
      setDebouncedValue(value);
      setIsTyping(false);
    }, delay);
    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return {debouncedValue, isTyping};
}