import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAddSlackOAuth } from "src/api/Misc/Settings/Integrations/slack";
import { Loading } from "src/components/ui/ApplicationStates/Loading";

type Props = {};

export default function SlackAuthentication({}: Props) {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const addSlackOAuthMutation = useAddSlackOAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (code) {
      addSlackOAuthMutation.mutate({
        params: {
          code,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (addSlackOAuthMutation.isError) {
      navigate("/settings/integrations");
    }
  }, [addSlackOAuthMutation.isError]);

  return (
    <div>
      <Loading />
    </div>
  );
}
