import { XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon, SquareIcon } from "@radix-ui/react-icons";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "src/components/ui/command";
import { Dialog, DialogContent } from "src/components/ui/dialog";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";

import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useParams } from "react-router-dom";
import { ParentStatus, StatusGroupData } from "src/api/General/status-groups";
import {
  ProjectData,
  useGetProjectMembers,
  useGetProjectsForExternalUser,
} from "src/api/Services/Projects/projects";
import { TaskerData, useAddTaskToProject } from "src/api/Services/Tasks/tasks";
import Description from "src/components/Services/Reusable/Tasks/Elements/Description";
import { Button } from "src/components/ui/button";
import { Switch } from "src/components/ui/switch";
import { useStatusGroups } from "src/pages/status-groups/status-groups-context-provider";
import { cn } from "src/utils/ui/ui";
import { DueDatePicker, TaskerCombobox, Title } from "./NewTaskForInternalUser";

type Props = {};

type Status =
  | {
      status: ParentStatus;
      customStatus: string;
    }
  | undefined;

type Action =
  | {
      type: "title_update";
      title: string;
    }
  | {
      type: "project_update";
      project: ProjectData | undefined;
    }
  | {
      type: "stage_update";
      stage: ProjectData["stages"][0] | undefined;
    }
  | {
      type: "status_update";
      status: Status;
    }
  | {
      type: "due_date_update";
      dueDate: string | undefined;
    }
  // | {
  //     type: "relative_due_date_update";
  //     relativeDueDate: RelativeDueDate;
  //   }
  // | {
  //     type: "dependent_on_update";
  //     dependentOn: DependentOn;
  //   }
  // | {
  //     type: "adding_dependency_update";
  //     addingDependency: boolean;
  //   }
  | {
      type: "tasker_update";
      tasker: TaskerData | undefined;
    }
  // | {
  //     type: "visibility_update";
  //     isVisible: boolean;
  //   }
  // | {
  //     type: "adding_subtasks_update";
  //     addingSubtasks: boolean;
  //   }
  // | {
  //     type: "subtasks_open_update";
  //     subtasksOpen: boolean;
  //   }
  | {
      type: "description_update";
      description: string;
    }
  // | {
  //     type: "state_update";
  //     state: Partial<State>;
  //   }
  | {
      type: "open_update";
      projectId: string;
      stageId?: string;
    }
  | {
      type: "status_group_update";
      statusGroup: StatusGroupData | undefined;
    }
  | {
      type: "projects_update";
      projects: ProjectData[];
    }
  | {
      type: "status_groups_update";
      statusGroups: StatusGroupData[];
    }
  | { type: "is_open_update"; isOpen: boolean }
  | { type: "is_create_more_update"; isCreateMore: boolean };

type State = {
  isOpen: boolean;
  title: string;
  description: string;
  project: ProjectData | undefined;
  stage: ProjectData["stages"][0] | undefined;
  statusGroup: StatusGroupData | undefined;
  status: Status;
  dueDate: string | undefined;
  tasker: TaskerData | undefined;
  projects: ProjectData[];
  statusGroups: StatusGroupData[];
  isCreateMore: boolean;
};

const NewTaskForExternalUserContext = React.createContext<
  { state: State; dispatch: React.Dispatch<Action> } | undefined
>(undefined);

function newTaskForExternalUserReducer(state: State, action: Action) {
  switch (action.type) {
    case "title_update":
      return { ...state, title: action.title };
    case "description_update":
      return { ...state, description: action.description };
    case "project_update":
      const newStatusGroup = state.statusGroups?.find(
        (sg) => sg._id === action.project?.statusGroupId
      );
      const newStatus = newStatusGroup?.statuses.find(
        (s) => s.parentStatus === "To-do"
      );
      return {
        ...state,
        project: action.project,
        stage: undefined,
        tasker: undefined,
        ...(!!newStatus
          ? {
              status: {
                status: newStatus.parentStatus,
                customStatus: newStatus.name,
              },
            }
          : {}),
        statusGroup: newStatusGroup,
      };
    case "stage_update":
      return { ...state, stage: action.stage };
    case "status_update":
      return { ...state, status: action.status };
    case "due_date_update":
      return { ...state, dueDate: action.dueDate };
    case "tasker_update":
      return { ...state, tasker: action.tasker };
    case "open_update":
      const project = state.projects?.find((p) => p._id === action.projectId);
      const stage = project?.stages?.find((s) => s._id === action.stageId);
      let statusGroup = state.statusGroups?.find(
        (sg) => sg._id === project?.statusGroupId
      );
      if (!statusGroup) {
        statusGroup = state.statusGroups?.find((sg) => !sg.isCustom);
      }
      const status = statusGroup?.statuses.find(
        (s) => s.parentStatus === "To-do"
      );
      return {
        ...state,
        project: project,
        stage: stage,
        statusGroup: statusGroup,
        ...(!!status
          ? {
              status: {
                status: status.parentStatus,
                customStatus: status.name,
              },
            }
          : {}),
        isOpen: true,
        tasker: undefined,
        dueDate: undefined,
        taskerType: "internal" as "internal",
      };
    case "status_group_update":
      return { ...state, statusGroup: action.statusGroup };
    case "projects_update":
      return { ...state, projects: action.projects };
    case "status_groups_update":
      return { ...state, statusGroups: action.statusGroups };
    case "is_open_update":
      return { ...state, isOpen: action.isOpen };
    case "is_create_more_update":
      return { ...state, isCreateMore: action.isCreateMore };
    default:
      return state;
  }
}

export function NewTaskForExternalUserProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, dispatch] = React.useReducer(newTaskForExternalUserReducer, {
    isOpen: false,
    title: "",
    description: "",
    project: undefined,
    stage: undefined,
    status: undefined,
    dueDate: undefined,
    tasker: undefined,
    statusGroup: undefined,
    statusGroups: [],
    projects: [],
    isCreateMore: false,
  });
  const value = { state, dispatch };
  return (
    <NewTaskForExternalUserContext.Provider value={value}>
      {children}
    </NewTaskForExternalUserContext.Provider>
  );
}

export function useNewTaskForExternalUser() {
  const newTaskForExternalProjectCtx = React.useContext(
    NewTaskForExternalUserContext
  );
  if (newTaskForExternalProjectCtx === undefined) {
    throw new Error(
      "useNewTaskForExternalProject must be used within a NewTaskForExternalProjectProvider"
    );
  }
  return newTaskForExternalProjectCtx;
}

export default function NewTaskForExternalUser({ slug }: { slug: string }) {
  const newTaskForExternalProjectCtx = useNewTaskForExternalUser();
  const createNewProjectTaskMutation = useAddTaskToProject();
  const { phaseId } = useParams();
  useHotkeys("c", (e) => {
    if (!!phaseId) {
      e.preventDefault();
      e.stopPropagation();
      newTaskForExternalProjectCtx.dispatch({
        type: "open_update",
        projectId: phaseId,
      });
    }
  });
  const [count, setCount] = useState(0);
  const statusGroupsCtx = useStatusGroups();
  const { data: projects } = useGetProjectsForExternalUser(slug);

  useEffect(() => {
    if (!!statusGroupsCtx.state.statusGroups) {
      newTaskForExternalProjectCtx.dispatch({
        type: "status_groups_update",
        statusGroups: statusGroupsCtx.state.statusGroups,
      });
    }
  }, [statusGroupsCtx.state.statusGroups]);

  useEffect(() => {
    if (projects) {
      newTaskForExternalProjectCtx.dispatch({
        type: "projects_update",
        projects: projects,
      });
    }
  }, [projects]);

  return (
    <Dialog
      open={newTaskForExternalProjectCtx.state.isOpen}
      onOpenChange={(open) => {
        newTaskForExternalProjectCtx.dispatch({
          type: "is_open_update",
          isOpen: open,
        });
      }}
    >
      <DialogContent
        className={clsx(
          "top-[18%] max-h-[80%] max-w-2xl translate-y-0 p-6 pt-5 data-[state=closed]:pointer-events-none data-[state=open]:pointer-events-auto data-[state=closed]:slide-out-to-top-[17%] data-[state=open]:slide-in-from-top-[18%]"
        )}
        onPointerDownOutside={(e) => {
          e.preventDefault();
        }}
        // onEscapeKeyDown={(e) => {
        //   e.preventDefault();
        // }}
      >
        <TitleWrapper count={count} />
        <div className="flex gap-1.5">
          <StageWrapper />
          <DueDateWrapper />
          {!!newTaskForExternalProjectCtx.state.project && (
            <TaskerWrapper
              projectId={newTaskForExternalProjectCtx.state.project?._id}
            />
          )}
        </div>
        <DescriptionWrapper />
        <div className="flex items-center justify-end space-x-4">
          <div className="flex items-center space-x-1.5">
            <Switch
              checked={newTaskForExternalProjectCtx.state.isCreateMore}
              onCheckedChange={(value) => {
                newTaskForExternalProjectCtx.dispatch({
                  type: "is_create_more_update",
                  isCreateMore: value,
                });
              }}
            />
            <div className="text-xs text-gray-400">Create more</div>
          </div>
          <Button
            disabled={
              !newTaskForExternalProjectCtx.state.title.trim() ||
              !newTaskForExternalProjectCtx.state.tasker ||
              !newTaskForExternalProjectCtx.state.dueDate
            }
            onClick={() => {
              if (
                newTaskForExternalProjectCtx.state.project &&
                newTaskForExternalProjectCtx.state.tasker &&
                newTaskForExternalProjectCtx.state.statusGroup &&
                newTaskForExternalProjectCtx.state.dueDate &&
                newTaskForExternalProjectCtx.state.status
              ) {
                createNewProjectTaskMutation.mutate({
                  params: {
                    task: {
                      title: newTaskForExternalProjectCtx.state.title,
                      description:
                        newTaskForExternalProjectCtx.state.description,
                      dueDate: new Date(
                        newTaskForExternalProjectCtx.state.dueDate
                      ),
                      ...(newTaskForExternalProjectCtx.state.tasker
                        ?.userType === "fullUser"
                        ? {
                            taskerId:
                              newTaskForExternalProjectCtx.state.tasker?._id,
                          }
                        : {
                            taskerEmail:
                              newTaskForExternalProjectCtx.state.tasker?.email,
                          }),
                      taskerType:
                        newTaskForExternalProjectCtx.state.tasker?.type,
                      estimate: {
                        value: 0,
                        unit: "minute" as "minute",
                      },
                      status: newTaskForExternalProjectCtx.state.status.status,
                      customStatus:
                        newTaskForExternalProjectCtx.state.status?.customStatus,
                      journeyId:
                        newTaskForExternalProjectCtx.state.project.journeyId,
                      isVisible: true,
                      ...(!!newTaskForExternalProjectCtx.state.stage
                        ? {
                            journeyStageId:
                              newTaskForExternalProjectCtx.state.stage._id,
                          }
                        : {}),
                    },
                  },
                  metadata: {
                    phaseId: newTaskForExternalProjectCtx.state.project?._id,
                    statusGroupId:
                      newTaskForExternalProjectCtx.state.statusGroup._id,
                    tasker: newTaskForExternalProjectCtx.state.tasker,
                    journeyId:
                      newTaskForExternalProjectCtx.state.project.journeyId,
                    journeyStageId:
                      newTaskForExternalProjectCtx.state.stage?._id,
                  },
                });
              } else {
                console.log(newTaskForExternalProjectCtx.state);
              }
              if (!newTaskForExternalProjectCtx.state.isCreateMore) {
                newTaskForExternalProjectCtx.dispatch({
                  type: "is_open_update",
                  isOpen: false,
                });
                newTaskForExternalProjectCtx.dispatch({
                  type: "tasker_update",
                  tasker: undefined,
                });
              }
              // things to do after creating a task
              newTaskForExternalProjectCtx.dispatch({
                type: "title_update",
                title: "",
              });
              newTaskForExternalProjectCtx.dispatch({
                type: "description_update",
                description: "",
              });
              setCount((prev) => prev + 1);
            }}
          >
            Create
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

function StageWrapper() {
  const newTaskForExternalUserCtx = useNewTaskForExternalUser();
  const [stageOpen, setStageOpen] = useState(false);
  return (
    <Popover open={stageOpen} onOpenChange={setStageOpen} modal={true}>
      <PopoverTrigger asChild>
        <Button
          variant="secondary"
          role="combobox"
          className="truncate font-normal"
        >
          <SquareIcon
            className={clsx(
              "mr-2 h-3.5 w-3.5 shrink-0",
              !!newTaskForExternalUserCtx.state.stage
                ? "text-gray-600"
                : "text-gray-400"
            )}
          />
          {!!newTaskForExternalUserCtx.state.stage ? (
            <div className="max-w-[100px] truncate">
              {newTaskForExternalUserCtx.state.stage.name}
            </div>
          ) : (
            <div className="text-gray-400/80">Stage</div>
          )}
          {/* <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" /> */}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="pointer-events-auto w-fit min-w-[200px] max-w-[480px] p-0"
        align="start"
      >
        <Command
          filter={(value, search) => {
            if (
              newTaskForExternalUserCtx.state.project?.stages
                .find((s) => s._id === value)
                ?.name.toLowerCase()
                .includes(search.toLowerCase())
            ) {
              return 1;
            } else {
              return 0;
            }
          }}
        >
          <CommandInput placeholder="Search stage..." className="h-9" />
          <CommandEmpty>No stage found.</CommandEmpty>
          <CommandGroup>
            {newTaskForExternalUserCtx.state.project?.stages.map((stage) => (
              <CommandItem
                key={stage._id}
                value={stage._id}
                onSelect={() => {
                  newTaskForExternalUserCtx.dispatch({
                    type: "stage_update",
                    stage: stage,
                  });
                  setStageOpen(false);
                }}
                className="gap-2"
              >
                <span className="truncate">{stage.name}</span>
                <div className="flex flex-1 shrink-0 items-center justify-end space-x-1">
                  {newTaskForExternalUserCtx.state.stage?._id === stage._id ? (
                    <div
                      className="cursor-pointer rounded-full p-1 transition-all hover:bg-gray-200"
                      onClick={(e) => {
                        e.stopPropagation();
                        newTaskForExternalUserCtx.dispatch({
                          type: "stage_update",
                          stage: undefined,
                        });
                        setStageOpen(false);
                      }}
                    >
                      <XMarkIcon
                        className={cn(
                          "h-2.5 w-2.5 shrink-0",
                          newTaskForExternalUserCtx.state.stage?._id ===
                            stage._id
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                    </div>
                  ) : (
                    <div className="w-5" />
                  )}
                  <CheckIcon
                    className={cn(
                      "ml-auto h-4 w-4 shrink-0",
                      newTaskForExternalUserCtx.state.stage?._id === stage._id
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                </div>
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function TitleWrapper({ count }: { count: number }) {
  const newTaskForExternalProjectCtx = useNewTaskForExternalUser();
  return (
    <Title
      count={count}
      title={newTaskForExternalProjectCtx.state.title}
      onBlur={(e) => {
        newTaskForExternalProjectCtx.dispatch({
          type: "title_update",
          title: e.target.value,
        });
      }}
    />
  );
}

function DescriptionWrapper() {
  const newTaskForExternalUserCtx = useNewTaskForExternalUser();
  return (
    <div className="rounded-md bg-gray-50 p-2 px-2">
      <Description
        description={newTaskForExternalUserCtx.state.description}
        onBlur={(description) => {
          newTaskForExternalUserCtx.dispatch({
            type: "description_update",
            description: description,
          });
        }}
      />
    </div>
  );
}

function DueDateWrapper() {
  const newTaskForExternalUserCtx = useNewTaskForExternalUser();
  return (
    <DueDatePicker
      dueDate={newTaskForExternalUserCtx.state.dueDate}
      addingDependency={false}
      dependentOn={{
        _id: undefined,
        type: "task",
      }}
      onAddingDependencyChange={(_) => {}}
      onDueDateChange={(value) => {
        newTaskForExternalUserCtx.dispatch({
          type: "due_date_update",
          dueDate: value,
        });
      }}
      dependencyDisabled={true}
    />
  );
}

function TaskerWrapper({ projectId }: { projectId: string }) {
  const newTaskForExternalUserCtx = useNewTaskForExternalUser();
  const { data: projectMembers } = useGetProjectMembers(projectId);
  return (
    <TaskerCombobox
      required={true}
      selectedTasker={newTaskForExternalUserCtx.state.tasker}
      onTaskerSelect={(tasker) => {
        newTaskForExternalUserCtx.dispatch({
          type: "tasker_update",
          tasker: tasker,
        });
      }}
      taskers={[
        ...(projectMembers?.internal.map((im) => {
          return {
            _id: im._id,
            name: im.name,
            email: im.email,
            userType: "fullUser" as "fullUser",
            role: im.role,
            type: "internal" as "internal",
          };
        }) ?? []),
        ...(projectMembers?.external.map((em) => {
          if (em.userType === "fullUser") {
            return {
              _id: em._id,
              name: em.name,
              email: em.email,
              userType: "fullUser" as "fullUser",
              role: em.role,
              type: "external" as "external",
            };
          } else {
            return {
              name: em.email,
              email: em.email,
              userType: "invitedUser" as "invitedUser",
              role: em.role,
              type: "external" as "external",
            };
          }
        }) ?? []),
      ]}
    />
  );
}
