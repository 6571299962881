import { CalendarIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { Chip, Stack } from "@mui/material";
import clsx from "clsx";
import dayjs from "dayjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ReducedTaskData,
  TaskData,
  useUpdateTask,
} from "src/api/Services/Tasks/tasks";
import { Avatar } from "src/components/ui/Avatar/Avatar";
import { getTimeDifference } from "src/utils/functions/dateTime";
import { getIdentifier } from "src/utils/functions/tasks";
import { StatusSelect } from "../Task";

interface Props {
  title: string;
  tasks: ReducedTaskData[];
  allTasks: ReducedTaskData[];
  type:
    | "My Tasks"
    | "All Tasks"
    | "Today's Tasks"
    | "Today's Completed Tasks"
    | "Stage";
}

const TasksTable = ({ title, tasks, allTasks, type }: Props) => {
  const updateTaskMutation = useUpdateTask();
  // const deleteTaskMutation = useDeleteTask();
  // const { instanceHasCustomers } = useInstanceHasCustomers();
  const hoveredTask = useRef<ReducedTaskData | null>(null);
  const [selectedTasks, setSelectedTasks] = useState<string[]>([]);

  const addToTodaysTasks = useCallback(() => {
    if (hoveredTask.current && hoveredTask.current._id) {
      if (hoveredTask.current.isMarkedForToday) {
        updateTaskMutation.mutate({
          update: {
            _id: hoveredTask.current._id,
            isMarkedForToday: false,
          },
        });
      } else {
        updateTaskMutation.mutate({
          update: {
            _id: hoveredTask.current._id,
            isMarkedForToday: true,
          },
        });
      }
    }
  }, [hoveredTask]);

  // const deleteTask = useCallback(() => {
  //   if (hoveredTask.current && hoveredTask.current._id) {
  //     // deleteTaskMutation.mutate({
  //     //   update: {
  //     //     _id: hoveredTask.current._id,
  //     //   },
  //     // });
  //   }
  // }, [hoveredTask]);

  useEffect(() => {
    const p = (ev: KeyboardEvent) => {
      if (ev.key === "t") {
        addToTodaysTasks();
      }
      // else if (ev.key === "d") {
      //   deleteTask();
      // }
    };
    window.addEventListener("keydown", p);
    return () => window.removeEventListener("keydown", p);
  }, []);

  // task table in tanstack
  // const columnHelper = createColumnHelper<ReducedTaskData>();

  // const columns = [
  //   columnHelper.accessor("status", {
  //     cell: (info) => (
  //       <Status
  //         isButtonIcon={true}
  //         onChange={(status) => {
  //           updateTaskMutation.mutate({
  //             _id: info.row.original._id,
  //             status: status,
  //           });
  //         }}
  //         status={info.row.original.status}
  //         disabled={!info.row.original.dueDate}
  //       />
  //     ),
  //     header: () => <span>Status</span>,
  //   }),
  //   columnHelper.accessor("title", {
  //     cell: (info) => info.getValue(),
  //     header: () => <span>Title</span>,
  //   }),
  //   columnHelper.accessor("dueDate", {
  //     cell: (info) => {
  //       const dueDate = info.getValue();
  //       if (dueDate) {
  //         return <span>{dayjs(dueDate).format("MMM DD")}</span>;
  //       }
  //       return null;
  //     },
  //     header: () => <span>Due Date</span>,
  //   }),
  //   columnHelper.accessor("dueDate", {
  //     cell: (info) => {
  //       const task = info.row.original;
  //       return (
  //         <>
  //           {task.dueDate ? (
  //             <TimeLeft
  //               dueDate={task.dueDate}
  //               completionDate={task.completionDate}
  //               task={task}
  //             />
  //           ) : (
  //             <Chip label={"Dependent"} size="small" variant="outlined" />
  //           )}
  //         </>
  //       );
  //     },
  //     header: () => (
  //       <span>{title === "Completed" ? "Completed On" : "Time Left"}</span>
  //     ),
  //   }),
  //   columnHelper.accessor("tasker", {
  //     cell: (info) => {
  //       if (info.getValue()) {
  //         return <span>{getIdentifier(info.getValue()).identifier}</span>;
  //       }
  //       return null;
  //     },
  //     header: () => <span>Tasker</span>,
  //   }),
  //   {
  //     id: "journey",
  //     accessorFn: (row: ReducedTaskData) => {
  //       if (row.journey) {
  //         return row.journey.name;
  //       }
  //       return null;
  //     },
  //     cell: (info: CellContext<ReducedTaskData, string>) => {
  //       if (info.getValue()) {
  //         return <Chip label={info.getValue()} size="small" />;
  //       }
  //       return null;
  //     },
  //     header: () => <span>Journey</span>,
  //   },
  // ];

  // const table = useReactTable({
  //   data: tasks,
  //   columns,
  //   getCoreRowModel: getCoreRowModel(),
  //   // getRowProps: (row) => {
  //   //   return {
  //   //     onClick: () => {
  //   //       onRowClick(row.original);
  //   //     },
  //   //   };
  //   // },
  // });

  // const ClickableWrapper = ({
  //   children,
  //   disabled = false,
  //   task,
  // }: {
  //   children: React.ReactNode;
  //   disabled?: boolean;
  //   task: TaskData;
  // }) => {
  //   return (
  //     <div
  //       className={clsx()}
  //       onClick={() => {
  //         if (!disabled) {
  //           onCellClick(task);
  //         }
  //       }}
  //     >
  //       {children}
  //     </div>
  //   );
  // };

  return (
    <>
      {tasks && tasks.length === 0 && title === "Today's Completed Tasks" && (
        <div className="space-y-2 px-8">
          <div className="text-lg font-medium">{title}</div>
          <div className="text-sm text-gray-500">No tasks completed today</div>
        </div>
      )}
      {tasks && tasks.length > 0 && (
        <div className="divide-y divide-gray-100">
          <div className="flex items-center justify-between space-x-4 px-8 pb-2">
            <div className="text-lg font-medium">{title}</div>
            {(title === "To-do" ||
              title === "In Progress" ||
              title === "Today's Tasks" ||
              title === "Today's Completed Tasks") && (
              <div className="pr-[88px]">
                {/* <>
                  <WrenchIcon className="h-4 w-4 text-gray-500" />
                  &#x2211;
                </> */}

                <TotalTasksDuration tasks={tasks} />
              </div>
            )}
          </div>
          {tasks
            .filter(
              (t) => {
                if (type === "Today's Tasks") {
                  if (t.parentTask?.isMarkedForToday) {
                    // if (t.isMarkedForToday) {
                    //   return false
                    // } else {
                    //   return false
                    // }
                    return false;
                  } else {
                    if (t.isMarkedForToday) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  // if (t.parentTask?.isMarkedForToday && t.isMarkedForToday) {
                  // return true;
                  // }
                } else if (type === "All Tasks") {
                  return (
                    t.status !== t.parentTask?.status

                    // &&
                    // tasks.filter(
                    //   (ta) =>
                    //     ta.parentTask?._id?.toString() === t._id?.toString()
                    // ).length === 0
                  );
                } else if (type === "My Tasks") {
                  if (t.status !== t.parentTask?.status) {
                    return true;
                  } else {
                    if (
                      tasks.filter(
                        (ta) =>
                          t.parentTask?._id?.toString() === ta._id?.toString()
                      ).length === 0
                    ) {
                      return true;
                    }
                  }
                  return (
                    t.status !== t.parentTask?.status &&
                    tasks.filter(
                      (ta) =>
                        ta.parentTask?._id?.toString() === t._id?.toString()
                    ).length === 0
                  );
                } else if (type === "Stage") {
                  return !t.parentTask;
                } else {
                  return true;
                }
                // return true
              }
              // ;{
              //   return
              //   // if (t.isMarkedForToday) {
              //   //   return true;
              //   // } else {
              //   // }
              // }
            )
            .map((task) => {
              return (
                <TaskWithSubTasks
                  key={task._id?.toString() ?? Math.random()}
                  task={task}
                  hoveredTask={hoveredTask}
                  allTasks={allTasks}
                  selectedTasks={selectedTasks}
                  setSelectedTasks={setSelectedTasks}
                />
              );
            })}
          <div />
        </div>
      )}
      {/* {tasks && tasks.length > 0 && (
        <>
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        scope="col"
                        className="py-3 px-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 first:pl-4 first:pr-3 first:sm:pl-6"
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    className={clsx(
                      row.original._id &&
                        row.original.dueDate &&
                        "hover:cursor-pointer hover:bg-gray-50"
                    )}
                    onMouseEnter={() => {
                      if (row.original._id) {
                        hoveredTask.current = row.original;
                      }
                    }}
                    onMouseLeave={() => {
                      hoveredTask.current = null;
                    }}
                    onMouseOver={() => {}}
                    // hover={Boolean(row.original._id && row.original.dueDate)}
                    // key={row.original._id ? row.original._id.toString() : Math.random()}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className="whitespace-nowrap px-3 py-1.5 text-sm text-gray-500 first:py-1.5 first:pl-4 first:pr-3 first:font-medium first:text-gray-900 first:sm:pl-6"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          </>
      )} */}
      {/* <Grid item xs={12}>
            <Stack spacing={2}>
              <Typography variant="h6">
                {title[0].toUpperCase() + title.substring(1)}
              </Typography>
              <TableContainer sx={{ minWidth: 650 }} component={Paper}>
                <Table size="small" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: 70 }}></TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell align="left" sx={{ minWidth: 120 }}>
                        Tasker
                      </TableCell>
                      <TableCell align="left" sx={{ minWidth: 100 }}>
                        Due Date
                      </TableCell>
                      <TableCell align="left">
                        {title === "Completed" ? "Completed On" : "Time Left"}
                      </TableCell>
                      <TableCell align="left">Journey</TableCell>
                      {!!instanceHasCustomers && (
                        <TableCell align="left" sx={{ minWidth: 200 }}>
                          Customer
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tasks.map((task) => (
                      <TableRow
                        onMouseEnter={() => {
                          if (task._id) {
                            hoveredTask.current = task;
                          }
                        }}
                        onMouseLeave={() => {
                          hoveredTask.current = null;
                        }}
                        onMouseOver={() => {}}
                        hover={Boolean(task._id && task.dueDate)}
                        key={task._id ? task._id.toString() : Math.random()}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          align="left"
                          style={{ verticalAlign: "middle" }}
                        >
                          <Stack direction="row" alignItems="center">
                            <Status
                              isButtonIcon={true}
                              onChange={(status) => {
                                updateTaskMutation.mutate({
                                  _id: task._id,
                                  status: status,
                                });
                              }}
                              task={task}
                            />

                          </Stack>
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          onClick={() => onCellClick(task)}
                          style={{ width: "50%" }}
                        >
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <Typography variant="body2" alignItems={"center"}>
                              {task.title}
                            </Typography>
                            {!!task.comments && task.comments.length > 0 && (
                              <Stack direction="row" spacing={2}>
                                <CommentIcon color="disabled" />
                                
                              </Stack>
                            )}
                            {task.isMarkedForToday && (
                              <TodayIcon fontSize="small" color="primary" />
                            )}
                            {task.integrationId === "hubspot" && (
                              <Box
                                component="img"
                                src="/static/hubSpotIcon.png"
                                sx={{ height: 24 }}
                              />
                            )}
                            {task.issue?._id && (
                              <Chip
                                size="small"
                                variant="outlined"
                                color={"error"}
                                label={"Issue"}
                              />
                            )}
                          </Stack>
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => onCellClick(task)}
                        >
                          {task.tasker?.name}
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => onCellClick(task)}
                        >
                          {task.dueDate && dayjs(task.dueDate).format("MMM DD")}
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => onCellClick(task)}
                        >
                          {task.dueDate ? (
                            <TimeLeft
                              dueDate={task.dueDate}
                              completionDate={task.completionDate}
                              task={task}
                            />
                          ) : (
                            <Chip
                              label={"Dependent"}
                              size="small"
                              variant="outlined"
                            />
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => onCellClick(task)}
                        >
                          {task?.journey?._id?.toString() && (
                            <Chip label={task?.journey?.name} size="small" />
                          )}
                        </TableCell>
                        {!!instanceHasCustomers && (
                          <TableCell align="left">
                            {task.customer?.name}
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Grid> */}
    </>
  );
};

export default TasksTable;

export function estimateToMinutes(estimate: TaskData["estimate"]): number {
  if (estimate) {
    if (estimate.unit === "hour") {
      return estimate.value * 60;
    } else if (estimate.unit === "minute") {
      return estimate.value;
    }
  }
  return 0;
}

export function TotalTasksDuration({ tasks }: { tasks: ReducedTaskData[] }) {
  const totalDuration = tasks.reduce((acc, task) => {
    // console.log(acc, task.status);
    if (task.subTaskCount && task.subTaskCount > 0) {
      return acc;
    }
    if (task.estimate) {
      return acc + estimateToMinutes(task.estimate);
    }
    return acc;
  }, 0);
  return (
    <div className="flex items-center">
      {/* <div>&#x2211;</div> */}
      <span className="whitespace-pre text-sm">{`${totalDuration} `}</span>
      <div className="w-[100px]">
        <div className="text-xs text-gray-500">min</div>
      </div>
    </div>
  );
  // console.log(totalDuration, tasks[0].status);
  const hours = Math.floor(totalDuration / 60);
  const minutes = totalDuration % 60;

  if (totalDuration > 0) {
    if (minutes === 0) {
      return (
        <div className="tracking-wider">{`${hours} ${
          hours === 1 ? "hour" : "hours"
        }`}</div>
      );
    } else {
      if (hours === 0) {
        return (
          <div className="tracking-wider">{`${minutes} ${
            minutes === 1 ? "minute" : "minutes"
          }`}</div>
        );
      }
      return (
        <div className="tracking-wider">{`${hours} hours, ${minutes} minutes`}</div>
      );
    }
    // if (totalDuration > 59) {

    //   return (
    //     <div>{`${Math.floor(totalDuration / 60)} hrs, ${
    //       totalDuration % 60
    //     } mins`}</div>
    //   );
    // } else {
    //   return <div>{`${totalDuration} mins`}</div>;
    // }
  }
  return <></>;
}

function TaskWithSubTasks({
  task,
  hoveredTask,
  allTasks,
  selectedTasks,
  setSelectedTasks,
}: {
  task: ReducedTaskData;
  hoveredTask: React.MutableRefObject<ReducedTaskData | null>;
  allTasks: ReducedTaskData[];
  selectedTasks: string[];
  setSelectedTasks: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  return (
    <div className="divide-y divide-gray-50">
      <div>
        <Task
          task={task}
          hoveredTask={hoveredTask}
          selectedTasks={selectedTasks}
          setSelectedTasks={setSelectedTasks}
        />

        <>
          {task.subTaskCount && task.subTaskCount > 0 && (
            <div className="">
              {allTasks
                .filter(
                  (t) => t.parentTask?._id.toString() === task._id?.toString()
                )
                .map((t) => (
                  <Task
                    key={t._id?.toString() ?? Math.random()}
                    task={t}
                    hoveredTask={hoveredTask}
                    isSubTask={true}
                    selectedTasks={selectedTasks}
                    setSelectedTasks={setSelectedTasks}
                  />
                ))}
            </div>
          )}
        </>
      </div>
    </div>
  );
}

function Task({
  task,
  hoveredTask,
  isSubTask = false,
  selectedTasks,
  setSelectedTasks,
}: {
  task: ReducedTaskData;
  hoveredTask: React.MutableRefObject<ReducedTaskData | null>;
  isSubTask?: boolean;
  selectedTasks: string[];
  setSelectedTasks: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const updateTaskMutation = useUpdateTask();
  const isSelected = selectedTasks.includes(task._id?.toString() || "");
  return (
    // <ContextMenu
    //   onOpenChange={(open) => {
    //     if (open && !selectedTasks.includes(task._id?.toString() || "")) {
    //       if (task._id) {
    //         setSelectedTasks([task._id.toString()]);
    //       } else {
    //         setSelectedTasks([]);
    //       }
    //     }
    //   }}
    // >
    //   <ContextMenuTrigger>
    <div
      className={clsx(
        "flex items-center space-x-2 py-1.5 px-8",
        task.dueDate && "cursor-pointer",
        isSelected ? "bg-gray-100 hover:bg-gray-200" : "hover:bg-gray-50"
      )}
      onMouseEnter={() => {
        if (task._id) {
          hoveredTask.current = task;
        }
      }}
      onMouseLeave={() => {
        hoveredTask.current = null;
      }}
      key={task._id ? task._id.toString() : Math.random()}
    >
      {/* <div className="pr-2">
            <Checkbox
              checked={isSelected}
              onCheckedChange={(checked) => {
                if (task._id) {
                  const taskId = task._id;
                  if (checked) {
                    setSelectedTasks((ids) => [...ids, taskId.toString()]);
                  } else {
                    setSelectedTasks((ids) =>
                      ids.filter((id) => id !== taskId.toString())
                    );
                  }
                }
              }}
            />
          </div> */}
      <div className="flex items-center space-x-2">
        {isSubTask && <div className="w-8"></div>}

        <StatusSelect
          task={task}
          disabled={task.action === "form" || task.action === "fileUpload"}
          isTag={true}
        />
        {/* <Status
              isButtonIcon={true}
              onChange={(status) => {
                updateTaskMutation.mutate({
                  update: {
                    _id: task._id,
                    status: status,
                  },
                  ...(task.sharedSpaceId
                    ? {
                        metadata: {
                          sharedSpaceId: task.sharedSpaceId,
                        },
                      }
                    : {}),
                });
              }}
              status={task.status}
              disabled={!task.dueDate}
              isBacklogAvailable={!task.journey?._id}
            /> */}
      </div>

      <div
        className="flex items-center space-x-4"
        onClick={() => {
          if (task.dueDate && task._id) {
            const searchParams = new URLSearchParams(location.search);
            searchParams.set("taskId", task._id);
            navigate({
              pathname: location.pathname,
              search: searchParams.toString(),
            });
          }
        }}
      >
        <div
          className={clsx(
            "truncate text-sm",
            isSubTask ? "text-gray-500" : "text-gray-700"
          )}
        >
          {task.title}
        </div>
        {/* {task.isMarkedForToday && (
          <CalendarIcon className="h-5 w-5 text-primary-main" />
        )} */}
        {task?.subTaskCount && task.subTaskCount > 0 && (
          <>
            <div className="inline-flex items-center space-x-0.5 rounded-full border border-gray-400 py-0.5 px-1.5 text-gray-400">
              <span className="text-xs">{task.completedSubTaskCount}</span>
              <span className="text-xs">{`/`}</span>
              <span className="text-xs">{task.subTaskCount}</span>
              {/* <BoltIcon className="h-3 w-3" /> */}
            </div>
          </>
        )}
        {/* {task.subTaskCount && (
          <div
            onClick={() => {
              
              setOpen && setOpen(!open);
            }}
            className="border"
          >
            <ChevronDownIcon className="h-3 w-3 text-primary-main" />
          </div>
        )} */}
        {task.parentTask && !isSubTask && (
          <div className="flex items-center space-x-4">
            <ChevronRightIcon className="h-3 w-3 text-gray-400" />
            <span className="text-sm text-gray-400">
              {task.parentTask.title}
            </span>
          </div>
        )}
      </div>
      <div
        className="flex flex-grow items-center justify-end space-x-4"
        onClick={() => {
          if (task._id) {
            const searchParams = new URLSearchParams(location.search);
            searchParams.set("taskId", task._id);
            navigate({
              pathname: location.pathname,
              search: searchParams.toString(),
            });
          }
        }}
      >
        {/* {task.estimate && (
          <div className="inline-flex items-center space-x-2 rounded-full border px-2 py-1 text-xs text-gray-600">
            <>
              <WrenchIcon className="h-4 w-4 text-gray-500" />
            </>
            <div>{task.estimate}</div>
          </div>
        )} */}

        {task.phase && (
          <div
            className="truncate rounded-full border border-gray-400 px-2 py-1 text-xs text-gray-500 hover:border-gray-700 hover:bg-gray-700 hover:text-white"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/projects/${task.phase?._id.toString()}/journey`);
            }}
          >
            {task.journey?.name}
          </div>
        )}
        {/* {task.journey && (
          <Chip label={task.journey.name} size="small" variant="outlined" />
        )} */}
        {task.dueDate ? (
          <TimeLeft
            dueDate={task.dueDate}
            completionDate={task.completionDate}
            task={task}
          />
        ) : (
          <Chip label={"Dependent"} size="small" variant="outlined" />
        )}
        <div className="w-12">
          {task.estimate && !task.subTaskCount && (
            <TaskEstimate estimate={task.estimate} />
          )}
        </div>
        <div className="w-7">
          {task.status !== "Completed" && (
            <button
              className={clsx(
                "rounded-full p-1",
                task.isMarkedForToday
                  ? "text-primary-main"
                  : "text-gray-300 hover:text-primary-main"
              )}
              onClick={(e) => {
                e.stopPropagation();
                if (!task._id) return;
                updateTaskMutation.mutate({
                  update: {
                    _id: task._id,
                    isMarkedForToday: !task.isMarkedForToday,
                  },
                });
              }}
            >
              <CalendarIcon className={clsx("h-5 w-5")} />
            </button>
          )}
        </div>
        {task.dueDate && (
          <span className="w-14 text-right text-sm text-gray-500">
            {dayjs(task.dueDate).format("MMM DD")}
          </span>
        )}
        <Avatar name={getIdentifier(task.tasker).identifier} size="sm" />
      </div>
    </div>
    //   {/* </ContextMenuTrigger>
    //   <ContextMenuContent className="w-64 bg-white">
    //     <ContextMenuSub>
    //       <ContextMenuSubTrigger className="cursor-pointer rounded-md hover:bg-gray-100">
    //         Status
    //       </ContextMenuSubTrigger>
    //       <ContextMenuSubContent className="w-36 bg-white">
    //         {statuses.map((status) => (
    //           <ContextMenuItem
    //             key={status}
    //             onClick={() => {
    //               if (!task._id) return;
    //               updateTaskMutation.mutate({
    //                 update: {
    //                   _id: task._id,
    //                   status: status,
    //                 },
    //               });
    //             }}
    //             className="cursor-pointer rounded-md hover:bg-gray-100"
    //           >
    //             {getStatusTag(status)}
    //           </ContextMenuItem>
    //         ))}
    //       </ContextMenuSubContent>
    //     </ContextMenuSub>
    //   </ContextMenuContent>
    // </ContextMenu> */}
  );
}

function TaskEstimate({ estimate }: { estimate: TaskData["estimate"] }) {
  return (
    <div className="text-sm">
      <span>
        {estimate.unit === "hour" ? estimate.value * 60 : estimate.value}
      </span>
      <span className="text-xs text-gray-500"> min</span>
    </div>
  );
  // <div className="whitespace-nowrap text-sm text-gray-500">
  //   {/* <span className="truncate whitespace-nowrap tracking-widest">{`${
  //     task.estimate.value
  //   }${task.estimate.unit === "hour" ? "h" : "m"}`}</span> */}
  //   {/* <span className="tracking-wide">{``}</span> */}
  //   <span>
  //     {estimate.unit === "hour"
  //       ? estimate.value * 60
  //       : estimate.value}{" "}
  //   </span>
  //   <span className="text-xs text-gray-400">
  //     {"m"}
  //   </span>
  // </div>
}

export const TimeLeft = ({
  dueDate,
  completionDate,
  task,
}: {
  dueDate: string;
  completionDate: string | undefined;
  task: ReducedTaskData;
}) => {
  if (completionDate) {
    return <></>
    // const { absValue, unit } = getTimeDifference(
    //   new Date(completionDate),
    //   new Date(dueDate)
    // );
    // // if ("approval" in task && !task.approval?.resolved) {
    // //   return (
    // //     <Chip
    // //       size="small"
    // //       variant="outlined"
    // //       color={"primary"}
    // //       label={"Waiting for Approval"}
    // //     />
    // //   );
    // // }
    // if (new Date(completionDate) < new Date(dueDate)) {
    //   return (
    //     <div className="truncate rounded-full border border-gray-300 bg-white px-2 py-1 text-xs text-gray-400">
    //       {`Completed ${absValue} ${unit} early`}
    //     </div>
    //     // <Chip
    //     //   size="small"
    //     //   variant="outlined"
    //     //   color={"secondary"}
    //     //   label={`Completed ${absValue} ${unit} early`}
    //     // />
    //   );
    // } else {
    //   return (
    //     <div className="truncate rounded-full border border-gray-300 bg-white px-2 py-1 text-xs text-gray-400">
    //       {`Completed ${absValue} ${unit} late`}
    //     </div>
    //     // <Chip
    //     //   size="small"
    //     //   variant="outlined"
    //     //   color={"error"}
    //     //   label={`Completed ${absValue} ${unit} late`}
    //     // />
    //   );
    // }
  }

  const { absValue, isDate2After, unit } = getTimeDifference(
    new Date(),
    new Date(dueDate)
  );

  const chips = [];
  if (isDate2After) {
    chips.push(
      <div
        key={"timeLeft"}
        className={clsx(
          "truncate rounded-full border border-gray-300  bg-white px-2 py-1 text-xs text-gray-400"
          // unit === "hour" ||
          //   unit === "hours" ||
          //   unit === "min" ||
          //   unit === "mins"
          //   ? "border-amber-400 text-amber-500"
          //   : "border-green-400 text-green-500"
        )}
      >
        {`Due in ${absValue} ${unit}`}
      </div>
    );
  } else {
    chips.push(
      <div
        key={"overdue"}
        className="truncate rounded-full border border-red-400 bg-white px-2 py-1 text-xs text-red-500"
      >
        {`${absValue} ${unit} overdue`}
      </div>
    );
  }

  // chips.push(
  //   <Chip
  //     key="timeLeft"
  //     size="small"
  //     variant="outlined"
  //     color={
  //       isDate2After
  //         ? unit === "hour" ||
  //           unit === "hours" ||
  //           unit === "min" ||
  //           unit === "mins"
  //           ? "warning"
  //           : "secondary"
  //         : "error"
  //     }
  //     label={
  //       isDate2After
  //         ? `Due in ${absValue} ${unit}`
  //         : `${absValue} ${unit} overdue`
  //     }
  //   />
  // );

  // if (task.startDate && task.startDate instanceof Date && !overdue) {
  //   if (new Date().getTime() > task.startDate.getTime()) {
  //     chips.push(
  //       <Chip
  //         key="pastStartDate"
  //         size="small"
  //         variant="outlined"
  //         color={"error"}
  //         label={"Past Start Date"}
  //       />
  //     );
  //   }
  // }
  return (
    <Stack direction="row" spacing={1}>
      {chips}
    </Stack>
  );
};
// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     list: {
//       padding: "0",
//     },
//   })
// );

const statuses: TaskData["status"][] = [
  "To-do",
  "In Progress",
  "Completed",
  "Canceled",
];

// interface StatusIconProps {
//   task: ReducedTaskData;
// }

// export const StatusIcon = ({ task }: StatusIconProps) => {
//   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
//   const updateTaskMutation = useUpdateTask();
//   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
//     event.stopPropagation();
//     setAnchorEl(event.currentTarget);
//   };

//   const handleMenuItemClick = (
//     event: React.MouseEvent<HTMLElement>,
//     index: number
//   ) => {
//     setAnchorEl(null);
//     switch (index) {
//       case 0:
//         updateTaskMutation.mutate({ _id: task._id, status: "To-do" });
//         break;
//       case 1:
//         updateTaskMutation.mutate({
//           _id: task._id,
//           status: "In Progress",
//         });
//         break;
//       case 2:
//         updateTaskMutation.mutate({
//           _id: task._id,
//           status: "Completed",
//           // completionDate: new Date(),
//         });
//         break;
//       case 3:
//         updateTaskMutation.mutate({ _id: task._id, status: "Canceled" });
//         break;
//     }
//   };

//   const buttonType = (status: TaskData["status"]) => {
//     // console.log(task.dueDate?.toDateString());
//     let color: "disabled" | "secondary" | "error" = "disabled";
//     if (task.dueDate) {
//       if (task.completionDate) {
//         if (task.completionDate < task.dueDate) {
//           color = "secondary";
//         } else {
//           color = "error";
//         }
//       } else if (task.dueDate > new Date()) {
//         color = "secondary";
//       } else {
//         color = "error";
//       }
//     }

//     const size = "inherit";
//     switch (status) {
//       case "To-do":
//         return <RadioButtonUncheckedIcon color={color} fontSize={size} />;
//       case "In Progress":
//         return <RadioButtonCheckedIcon color={color} fontSize={size} />;
//       case "Completed":
//         return <CheckCircleIcon color={color} fontSize={size} />;
//       case "Canceled":
//         return <CancelIcon color={color} fontSize={size} />;
//     }
//   };

//   return (
//     <Box>
//       <IconButton
//         aria-label="delete"
//         size="small"
//         onClick={handleClick}
//         disabled={!task.dueDate || (task.issue && !task.issue.resolved)}
//         style={{ fontSize: 18 }}
//       >
//         {buttonType(task.status)}
//       </IconButton>
//       <Menu
//         anchorEl={anchorEl}
//         open={Boolean(anchorEl)}
//         onClose={() => setAnchorEl(null)}
//       >
//         <MenuList dense style={{ padding: 0 }}>
//           {statuses.map((s, index) => (
//             <MenuItem
//               key={s}
//               // dense={true}
//               onClick={(event) => handleMenuItemClick(event, index)}
//             >
//               <Stack
//                 direction="row"
//                 alignItems="center"
//                 spacing={2}
//                 style={{ fontSize: 18 }}
//               >
//                 {buttonType(s)} <Typography variant="body1">{s}</Typography>
//               </Stack>
//             </MenuItem>
//           ))}
//         </MenuList>
//       </Menu>
//     </Box>
//   );

//   return <>N/A</>;
// };
