import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import {
  ReducedTaskData,
  TaskData,
  useGetTasks,
} from "src/api/Services/Tasks/tasks";
import { PageHeading } from "src/components/ui/heading";
import { useRealmApp } from "src/store/RealmApp";

type Props = {};

export default function WorkspaceCleaner({}: Props) {
  const app = useRealmApp();
  // const { data: tasks } = useGetTasks(
  //   "none",
  //   {
  //     order: "asc",
  //     property: "dueDate",
  //   },
  //   {
  //     taskerType: "internal",
  //     taskerIds: [app.currentUser.id],
  //   }
  // );
  const tasks: ReducedTaskData[] = [];
  return (
    <>
      <div className="flex h-16 items-center border-b px-8">
        <PageHeading heading="Workspace Cleaner" />
      </div>
      <>
        <div className="pt-6">
          {tasks && (
            <TaskCleaner
              tasks={tasks
                .filter((task) => {
                  if (
                    task.dueDate &&
                    task._id &&
                    (task.status === "To-do" ||
                      task.status === "In Progress" ||
                      task.status === "Stuck")
                  ) {
                    return new Date(task.dueDate) < new Date();
                  } else {
                    return false;
                  }
                })
                .sort((t1, t2) => {
                  if (t1.dueDate && t2.dueDate) {
                    return (
                      new Date(t1.dueDate).getTime() -
                      new Date(t2.dueDate).getTime()
                    );
                  }
                  return 0;
                })}
            />
          )}
        </div>
      </>
    </>
  );
}

function TaskCleaner({ tasks }: { tasks: ReducedTaskData[] }) {
  const [overdueTasks, setOverdueTasks] = useState<ReducedTaskData[]>(tasks);
  const [newTaskDates, setNewTaskDates] = useState<
    {
      _id: string;
      newDueDate: string;
      saved: boolean;
      status: TaskData["status"];
    }[]
  >(
    [...tasks].map((task) => {
      return {
        _id: task._id ?? "",
        newDueDate: new Date().toISOString(),
        saved: false,
        status: task.status,
      };
    })
  );
  const taskRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [selectedTaskIndex, setSelectedTaskIndex] = useState(0);

  // Navigate between tasks
  const moveSelection = (direction: "up" | "down") => {
    if (!tasks) return;
    setSelectedTaskIndex((prevIndex) => {
      if (direction === "up") return Math.max(0, prevIndex - 1);
      if (direction === "down")
        return Math.min(tasks.length - 1, prevIndex + 1);
      return prevIndex;
    });
  };

  // Immutable update for changing due dates
  const changeDueDate = (direction: "left" | "right") => {
    setNewTaskDates((currentDates) =>
      currentDates.map((date, index) => {
        if (index !== selectedTaskIndex) return date;
        // dont allow date in the past

        const oneDay = 24 * 60 * 60 * 1000; // ms in one day
        const newDueDate = new Date(
          new Date(date.newDueDate).getTime() +
            (direction === "right" ? oneDay : -oneDay)
        );
        if (direction === "left" && new Date(date.newDueDate) < new Date())
          return date;
        return { ...date, newDueDate: newDueDate.toISOString() };
      })
    );
    // setOverdueTasks((currentTasks) =>
    //   currentTasks.map((task, index) => {
    //     if (index !== selectedTaskIndex || !task.dueDate) return task;
    //     const oneDay = 24 * 60 * 60 * 1000; // ms in one day
    //     const newDueDate = new Date(
    //       new Date(task.dueDate).getTime() +
    //         (direction === "right" ? oneDay : -oneDay)
    //     );
    //     return { ...task, dueDate: newDueDate.toISOString() };
    //   })
    // );
  };

  // Using useMemo to sort tasks
  // const sortedTasks = useMemo(() => {
  //   return [...overdueTasks].sort((t1, t2) => {
  //     if (t1.dueDate && t2.dueDate) {
  //       return new Date(t1.dueDate).getTime() - new Date(t2.dueDate).getTime();
  //     }
  //     return 0;
  //   });
  // }, [overdueTasks]);

  // const sortedTasks = useMemo(() => {
  //   return [...overdueTasks].sort((t1, t2) => {
  //     return new Date(t1.dueDate).getTime() - new Date(t2.dueDate).getTime();
  //   });
  // }, [overdueTasks]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      switch (event.key) {
        case "ArrowUp":
          moveSelection("up");
          break;
        case "ArrowDown":
          moveSelection("down");
          break;
        case "ArrowLeft":
          changeDueDate("left");
          break;
        case "ArrowRight":
          changeDueDate("right");
          break;
        default:
          break;
      }
    };
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [selectedTaskIndex, newTaskDates]);

  return (
    <div>
      <div className="mx-6 mb-2 flex justify-between border-b px-2 pb-2 text-gray-500">
        <div className="flex items-center space-x-2">
          <div className="w-32">Status</div>
          <div>Task</div>
        </div>
        <div className="flex items-center space-x-3">
          <div className="w-32">Days Overdue</div>
          <div className="w-32">New Due Date</div>
          <div className="w-32">+</div>
        </div>
      </div>
      {overdueTasks.map((t, i) => {
        return (
          <div className="px-6 pr-8">
            {/* <Popover open={selectedTaskIndex === i}>
              <PopoverTrigger asChild> */}
            <div
              className={clsx(
                "flex justify-between rounded-md border py-0.5",
                selectedTaskIndex === i
                  ? "border-primary"
                  : "border-transparent"
              )}
              ref={(el) => (taskRefs.current[i] = el)}
              key={t._id}
              onClick={() => setSelectedTaskIndex(i)}
            >
              <div className="flex items-center space-x-2 pl-2">
                <div className="w-32">
                  <div>{newTaskDates[i].status}</div>
                </div>
                <div className="text-gray-700">{t.title}</div>
              </div>
              <div className="flex items-center space-x-3">
                {/* {t.dueDate && (
                  <div
                    className={clsx(
                      "w-32",
                      Math.round(
                        (new Date(newTaskDates[i].newDueDate).getTime() -
                          new Date().getTime()) /
                          (24 * 60 * 60 * 1000)
                      ) === 0
                        ? "text-red-600"
                        : "text-gray-300"
                    )}
                  >
                    {dayjs(t.dueDate).format("MMM DD")}
                  </div>
                )} */}
                {t.dueDate && (
                  // difference in days
                  <div
                    className={clsx(
                      "w-32",
                      Math.round(
                        (new Date(newTaskDates[i].newDueDate).getTime() -
                          new Date().getTime()) /
                          (24 * 60 * 60 * 1000)
                      ) === 0
                        ? "text-red-600"
                        : "text-gray-300"
                    )}
                  >
                    {Math.round(
                      (new Date(t.dueDate).getTime() - new Date().getTime()) /
                        (24 * 60 * 60 * 1000)
                    )}
                  </div>
                )}
                {newTaskDates[i].newDueDate && (
                  <div
                    className={clsx(
                      "flex w-32 items-center space-x-2",
                      Math.round(
                        (new Date(newTaskDates[i].newDueDate).getTime() -
                          new Date().getTime()) /
                          (24 * 60 * 60 * 1000)
                      ) === 0
                        ? "text-gray-300"
                        : "text-gray-700"
                    )}
                  >
                    <div className="w-14">
                      {dayjs(newTaskDates[i].newDueDate).format("MMM DD")}
                    </div>
                    <div className="w-8">
                      {dayjs(newTaskDates[i].newDueDate).format("ddd")}
                    </div>
                  </div>
                )}
                {newTaskDates[i].newDueDate && (
                  // difference in days
                  <div
                    className={clsx(
                      "w-32",
                      Math.round(
                        (new Date(newTaskDates[i].newDueDate).getTime() -
                          new Date().getTime()) /
                          (24 * 60 * 60 * 1000)
                      ) === 0
                        ? "text-gray-300"
                        : "text-gray-700"
                    )}
                  >
                    {Math.round(
                      (new Date(newTaskDates[i].newDueDate).getTime() -
                        new Date().getTime()) /
                        (24 * 60 * 60 * 1000)
                    )}
                  </div>
                )}
              </div>
            </div>
            {/* </PopoverTrigger>
              <PopoverContent align="end" className="p-0 w-fit">
                <Calendar
                  selected={new Date(newTaskDates[i].newDueDate)}
                  disabled
                />
              </PopoverContent>
            </Popover> */}
          </div>
        );
      })}
    </div>
  );
}
