import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { useRealmApp } from "src/store/RealmApp";
import { PhaseStatus } from "../Services/SharedSpace/phases";
import { useAccessToken } from "../useAccessToken";

const roadmapKeys = {
  roadmaps: () => ["roadmaps"] as const,
  roadmap: (id: string) => ["roadmap", id] as const,
};

export type RoadmapData = {
  _id: string;
  name: string;
  description: string;
  instanceId: string;
  customerId: string;
  sharedSpaceId: string;
  stages: RoadmapStageData[];
  projects?: RoadmapProjectData[];
  stageOrder: string[];
  projectOrder: { _id: string; stageId: string }[];
  createdAt: string;
  updatedAt: string;
};

export type RoadmapStageData = {
  _id: string;
  name: string;
  roadmapId: string;
  customerId: string;
  instanceId: string;
  sharedSpaceId: string;
  isUnassigned: boolean;
  createdAt: string;
  updatedAt: string;
};

export type RoadmapProjectData = {
  _id: string;
  name: string;
  status: PhaseStatus;
  totalTasks: number;
  completedTasks: number;
  roadmapStageId: string;
};

export const useGetRoadmapsForInstance = () => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    roadmapKeys.roadmaps(),
    async (): Promise<RoadmapData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/roadmaps`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            instanceId: app.currentUser.customData.instanceId.$oid,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: 1000 * 60 * 60,
    }
  );
};

export const useGetRoadmapsForSharedSpace = (sharedSpaceId: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    roadmapKeys.roadmaps(),
    async (): Promise<RoadmapData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/roadmaps`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            sharedSpaceId,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};

export const useGetRoadmap = (_id: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(roadmapKeys.roadmap(_id), async (): Promise<RoadmapData> => {
    const accessToken = await getValidAccessToken();
    const res = await axios.get(
      // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
      `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/roadmap`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
        },
        params: {
          _id,
        },
      }
    );
    return res.data;
  });
};

export const useCreateRoadmap = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async (params: {
      name: string;
      description: string;
      customerId?: string;
      sharedSpaceId?: string;
      externalMembers?: string[];
      stages?: {
        name: string;
        projectOrder?: string[];
      }[];
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/roadmap`,
        { ...params, instanceId: app.currentUser.customData.instanceId.$oid },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error creating roadmap", "error");
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(roadmapKeys.roadmaps());
      if (data.id) {
        navigate(`/project-groups/${data.id}`);
      }
    },
  });
};

export const useCreateRoadmapStage = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async (params: {
      name: string;
      roadmapId: string;
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/roadmapStage`,
        { ...params },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error creating roadmap stage", "error");
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(roadmapKeys.roadmap(variables.roadmapId));
    },
  });
};

// export const useAddProjectToRoadmapStage = (roadmapId: string) => {
//   const app = useRealmApp();
//   const snackbarCtx = useSnackBar();
//   const queryClient = useQueryClient();
//   const getValidAccessToken = useAccessToken();
//   return useMutation({
//     mutationFn: async ({
//       params,
//     }: {
//       params: {
//         phaseIds: string[];
//         roadmapStageId: string;
//       };
//     }): Promise<{ message: string }> => {
//       const accessToken = await getValidAccessToken();
//       const res = await axios.post(
//         `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/addProjectsToRoadmap`,
//         { ...params },
//         {
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );
//       return res.data;
//     },
//     onError: () => {
//       snackbarCtx.showSnackbar(
//         "Error adding project to roadmap stage",
//         "error"
//       );
//     },
//     onSuccess: (data, variables) => {
//       queryClient.invalidateQueries(roadmapKeys.roadmap(roadmapId));
//     },
//   });
// };

export const useUpdateRoadmap = (roadmapId: string) => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();
  return useMutation({
    mutationFn: async (params: {
      _id: string;
      name?: string;
      description?: string;
      stageOrder?: string[];
      projectOrder?: { _id: string; stageId: string }[];
    }): Promise<{ message: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/updateRoadmap`,
        params,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onMutate: async (variables) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(roadmapKeys.roadmap(roadmapId));
      // Snapshot the previous value
      const previousRoadmap = queryClient.getQueryData<RoadmapData>(
        roadmapKeys.roadmap(roadmapId)
      );
      console.log(previousRoadmap);

      // New value
      let newStages = previousRoadmap?.stages ?? [];
      if (variables.stageOrder) {
        // re-arrange stages in the new order
        // const newStageOrder = arrayMove(
        //   previousRoadmap.stageOrder,
        //   variables.stageOrder.oldIndex,
        //   variables.stageOrder.newIndex
        // );
        // arrayMove()
        newStages = variables.stageOrder
          .map((stageId) => {
            return previousRoadmap?.stages.find((s) => s._id === stageId)!;
          })
          .filter((s) => !!s);
      }
      const newRoadmap = {
        ...previousRoadmap,
        ...variables,
        stages: newStages,
      };
      console.log(newRoadmap);
      // Optimistically update to the new value
      queryClient.setQueryData(roadmapKeys.roadmap(roadmapId), newRoadmap);
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error updating project group", "error");
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(roadmapKeys.roadmap(roadmapId));
    },
  });
};

export const useUpdateRoadmapStage = (roadmapId: string) => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();
  return useMutation({
    mutationFn: async (params: {
      _id: string;
      name?: string;
    }): Promise<{ message: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/updateRoadmapStage`,
        params,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    // onMutate: async (variables) => {
    //   // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
    //   await queryClient.cancelQueries(roadmapKeys.roadmap(roadmapId));
    //   // Snapshot the previous value
    //   const previousRoadmap = queryClient.getQueryData<RoadmapData>(
    //     roadmapKeys.roadmap(roadmapId)
    //   );
    //   console.log(previousRoadmap);

    //   // New value
    //   let newStages = previousRoadmap?.stages ?? [];
    //   if (variables.stageOrder) {
    //     // re-arrange stages in the new order
    //     // const newStageOrder = arrayMove(
    //     //   previousRoadmap.stageOrder,
    //     //   variables.stageOrder.oldIndex,
    //     //   variables.stageOrder.newIndex
    //     // );
    //     // arrayMove()
    //     newStages = variables.stageOrder
    //       .map((stageId) => {
    //         return previousRoadmap?.stages.find((s) => s._id === stageId)!;
    //       })
    //       .filter((s) => !!s);
    //   }
    //   const newRoadmap = {
    //     ...previousRoadmap,
    //     ...variables,
    //     stages: newStages,
    //   };
    //   console.log(newRoadmap);
    //   // Optimistically update to the new value
    //   queryClient.setQueryData(roadmapKeys.roadmap(roadmapId), newRoadmap);
    // },
    onError: () => {
      snackbarCtx.showSnackbar("Error updating project group", "error");
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(roadmapKeys.roadmap(roadmapId));
    },
  });
};

// export const useAddProjectsToRoadmap = (roadmapId: string) => {
//   const app = useRealmApp();
//   const snackbarCtx = useSnackBar();
//   const queryClient = useQueryClient();
//   const getValidAccessToken = useAccessToken();
//   return useMutation({
//     mutationFn: async ({
//       params,
//     }: {
//       params: {
//         phaseIds: string[];
//         roadmapStageId: string;
//       };
//     }): Promise<{ message: string }> => {
//       const accessToken = await getValidAccessToken();
//       const res = await axios.post(
//         `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/addProjectsToRoadmap`,
//         { ...params },
//         {
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );
//       return res.data;
//     },
//     onError: () => {
//       snackbarCtx.showSnackbar(
//         "Error adding projects to project group",
//         "error"
//       );
//     },
//     onSuccess: (data, variables) => {
//       queryClient.invalidateQueries(roadmapKeys.roadmap(roadmapId));
//     },
//   });
// };

// export const useUpdateProjectView = () => {
//   const app = useRealmApp();
//   const snackbarCtx = useSnackBar();
//   const queryClient = useQueryClient();
//   const getValidAccessToken = useAccessToken();

//   return useMutation({
//     mutationFn: async (params: {
//       _id: string;
//       name?: string;
//       filter?: {
//         customerIds?: string[];
//         internalMemberIds?: string[];
//         status: PhaseStatus[];
//         ownerUserIds?: string[];
//       };
//       isShared?: boolean;
//     }): Promise<{ message: string; status: number }> => {
//       const accessToken = await getValidAccessToken();
//       const res = await axios.post(
//         `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/updateProjectView`,
//         params,
//         {
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );
//       return res.data;
//     },
//     onError: () => {
//       snackbarCtx.showSnackbar("Error updating view", "error");
//     },
//     onSuccess: (data, variables) => {
//       queryClient.invalidateQueries(viewKeys.views());
//     },
//   });
// };

export const useDeleteRoadmapStage = (roadmapId: string) => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (params: {
      _id: string;
    }): Promise<{ message: string; status: number }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.delete(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/roadmapStage`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          params,
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error deleting sub-group", "error");
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(roadmapKeys.roadmap(roadmapId));
      // snackbarCtx.showSnackbar("View saved", "success");
    },
  });
};

export const useDeleteRoadmap = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async (params: {
      _id: string;
    }): Promise<{ message: string; status: number }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.delete(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/roadmap`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          params,
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error deleting project group", "error");
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(roadmapKeys.roadmaps());
      navigate("/project-groups");
      // snackbarCtx.showSnackbar("View saved", "success");
    },
  });
};
