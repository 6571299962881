import React, { createContext, useContext, useReducer } from "react";

type TaskTemplatePanel = {
  id: string | undefined;
  isOpen: boolean;
};

type Action = {
  type: "TASK_TEMPLATE_PANEL_UPDATE";
  taskTemplatePanel: TaskTemplatePanel;
};

type Dispatch = (action: Action) => void;

type State = {
  taskTemplatePanel: TaskTemplatePanel;
};

const TaskTemplatePanelContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function taskTemplatePanelContextReducer(state: State, action: Action): State {
  switch (action.type) {
    case "TASK_TEMPLATE_PANEL_UPDATE": {
      return {
        ...state,
        taskTemplatePanel: action.taskTemplatePanel,
      };
    }
    default:
      return state;
  }
}

type Props = {
  children: React.ReactNode;
};

export const TaskTemplatePanelContextProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(taskTemplatePanelContextReducer, {
    taskTemplatePanel: {
      id: undefined,
      isOpen: false,
    },
  });
  const value = { state, dispatch };

  return (
    <TaskTemplatePanelContext.Provider value={value}>
      {children}
    </TaskTemplatePanelContext.Provider>
  );
};

export function useTaskTemplatePanelContext() {
  const context = useContext(TaskTemplatePanelContext);
  if (context === undefined) {
    throw new Error(
      "useTaskTemplatePanelContext must be used within a TaskTemplatePanelContextProvider"
    );
  }
  return context;
}
