import { useState } from "react";
import { SettingsData, useUpdateSettings } from "src/api/General/settings";
import { Button } from "src/components/ui/button";
import { getRGBColor } from "src/utils/functions/embed";

type Props = { settings: SettingsData };

export const PrimaryColor = ({ settings }: Props) => {
  const updateSettingsMutation = useUpdateSettings();
  const [embedSelectColor, setEmbedSelectColor] = useState(
    settings.embed.primaryColor?.substring(1) + ""
  );
  const [isEdited, setIsEdited] = useState(false);

  return (
    <>
      {/* <head> */}
      <style>:root {`{${getRGBColor(embedSelectColor, "embedSelect")}}`}</style>
      {/* </head> */}
      <div className="flex items-center space-x-3">
        <div className="h-[38px] w-[38px] rounded bg-embedSelectColor" />
        <div className="relative rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span className="text-gray-500 sm:text-sm">#</span>
          </div>
          <input
            type="text"
            className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-primary-main focus:ring-primary-main sm:text-sm"
            value={embedSelectColor}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              let newColor = event.target.value;
              if (newColor.length < 7) {
                setEmbedSelectColor(event.target.value);
                setIsEdited(true);
              }
            }}
          />
        </div>

        {isEdited && (
          <Button
            onClick={() => {
              updateSettingsMutation.mutate({
                update: {
                  embed: {
                    primaryColor: "#" + embedSelectColor,
                  },
                },
              });
              setIsEdited(false);
            }}
            variant="link"
            size={"fit"}
            disabled={!isEdited}
          >
            Update Color
          </Button>
        )}
      </div>
    </>
  );
};
