import { XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import {
  Currency,
  CustomFieldObjectType,
  CustomFieldType,
  currencies,
  useCreateCustomField,
  useGetCustomFields,
} from "src/api/CustomFields/custom-fields";
import { useGetInstance } from "src/api/General/instance";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { Button } from "src/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "src/components/ui/dialog";
import { Loading } from "src/components/ui/loading";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
} from "src/components/ui/select";
import { TextInput } from "src/components/ui/text-input";

type Props = {};

export default function CustomFields({}: Props) {
  const { data: customFields } = useGetCustomFields("project");
  const [objectType, setObjectType] =
    useState<CustomFieldObjectType>("project");
  const filteredCustomFields =
    customFields?.filter((field) => field.objectType === objectType) ?? [];
  const { instance } = useGetInstance();
  return (
    <div>
      <div className="flex h-12 items-center border-b border-gray-200/70 px-8 text-sm">
        {instance?.name}
      </div>
      <div className="mx-auto max-w-2xl pt-20">
        <div className="space-y-1 border-b border-gray-200/70 pb-6">
          <div className="text-xl font-medium">Fields</div>
          <div className="text-sm font-light text-gray-600">
            Manage fields available in projects and companies
          </div>
        </div>
        <div className="space-y-6 pt-8">
          {!!customFields ? (
            <>
              <div className="flex justify-between">
                <Select
                  value={objectType}
                  onValueChange={(value) => {
                    setObjectType(value as CustomFieldObjectType);
                  }}
                >
                  <SelectTrigger className="w-40">
                    {objectType === "project" ? "Project" : "Customer"}
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="project">Project</SelectItem>
                      <SelectItem value="customer">Customer</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
                {objectType === "project" && <NewCustomFieldButton />}
              </div>
              {customFields.length > 0 ? (
                <>
                  <div className="rounded-md border border-gray-200 p-6 pb-3 shadow-sm">
                    <div className="flex items-center border-b border-gray-200 pb-3 font-normal text-gray-500">
                      <div className="flex-1 text-sm leading-none">Name</div>
                      <div className="w-40 text-sm leading-none">Type</div>
                      <div className="w-40 text-sm leading-none">
                        Created by
                      </div>
                    </div>
                    {filteredCustomFields
                      .filter((field) => field.seroDefined)
                      .map((field) => (
                        <CustomFieldRow
                          key={field._id}
                          name={field.name}
                          type={field.type}
                          createdBy={"Sero"}
                        />
                      ))}
                    {objectType === "customer" && (
                      <>
                        <CustomFieldRow
                          name={"Name"}
                          type={"text"}
                          createdBy={"Sero"}
                        />
                      </>
                    )}
                    {filteredCustomFields
                      .filter((field) => !field.seroDefined)
                      .map((field) => (
                        <CustomFieldRow
                          key={field._id}
                          name={field.name}
                          type={field.type}
                          createdBy={
                            field.seroDefined
                              ? "Sero"
                              : field.createdBy?.name ?? ""
                          }
                        />
                      ))}
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <Loading className="mt-20" />
          )}
        </div>
      </div>
    </div>
  );
}

function CustomFieldRow({
  name,
  type,
  createdBy,
}: {
  name: string;
  type: string;
  createdBy: string;
}) {
  return (
    <div className="flex h-10 items-center border-b border-gray-100 last:border-0">
      <div className="flex-1 truncate text-sm font-medium leading-none">
        {name}
      </div>
      <div className="w-40 text-sm leading-none">
        {type === "boolean"
          ? "Yes/No"
          : type.charAt(0).toUpperCase() + type.slice(1)}
      </div>
      <div className="w-40 truncate text-sm leading-none text-gray-500">
        {createdBy}
      </div>
    </div>
  );
}

function NewCustomFieldButton() {
  const [open, setOpen] = useState(false);
  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
      }}
    >
      <DialogTrigger asChild>
        <Button variant={"secondary"}>New Custom Field</Button>
      </DialogTrigger>
      <DialogContent className="max-w-3xl p-0">
        <Steps open={open} setOpen={setOpen} />
      </DialogContent>
    </Dialog>
  );
}

function Steps({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [type, setType] = useState<CustomFieldType | undefined>(undefined);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [description, setDescription] = useState("");
  const [options, setOptions] = useState(["", ""]);
  const optionRefs = useRef<(HTMLInputElement | null)[]>([]);
  const createCustomFieldMutation = useCreateCustomField();
  const [currency, setCurrency] = useState<Currency>(currencies[0]);

  useEffect(() => {
    if (open) {
      setStep(1);
      setName("");
      setType(undefined);
      setDescription("");
      setOptions(["", ""]);
    }
  }, [open]);

  useEffect(() => {
    if (createCustomFieldMutation.isSuccess) {
      setOpen(false);
    }
  }, [createCustomFieldMutation.isSuccess]);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [description]);

  useEffect(() => {
    if (
      (type === "select" || type === "multi-select") &&
      optionRefs.current[0]
    ) {
      setTimeout(() => {
        optionRefs.current[0]?.focus();
      }, 0);
    }
  }, [type]);

  return (
    <div>
      <div className="border-b p-4 px-6">
        <span className="font-medium">New Custom Field</span>
      </div>
      <div className="flex">
        <div className="w-52 border-r p-6">
          <div className="space-y-3">
            <div className="space-y-1.5">
              <div className="flex items-center space-x-2">
                <PanelStep step={1} title="Details" activeStep={step} />
                {/* <ChevronRightIcon className="h-3 w-3 text-gray-400" /> */}
              </div>
              {step > 1 && (
                <ul className="pl-6">
                  <li className="flex items-center space-x-2">
                    {/* <div className="h-[5px] w-[5px] shrink-0 rounded-full bg-gray-400" /> */}
                    {/* <div className="text-sm font-light text-gray-400/80">-</div> */}
                    <div className="text-xs font-light text-gray-400/80">
                      {`${name}`}
                    </div>
                  </li>
                </ul>
              )}
            </div>
            <PanelStep step={2} title="Type" activeStep={step} />
          </div>
        </div>
        <div className="min-h-[300px] flex-1 p-6 px-8">
          {step === 1 && (
            <div className="space-y-6">
              <div className="space-y-1">
                <InputHeading heading="Name" required />
                <TextInput
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  // placeholder="Naddme"
                  className="w-72"
                  autoComplete="off"
                />
              </div>
              <div className="space-y-1">
                <InputHeading heading="Description" />
                <textarea
                  ref={textAreaRef}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  // placeholder={"Write a description.."}
                  className="block min-h-[80px] w-full resize-none rounded-md border-input border-gray-300 p-2.5 py-2 text-sm shadow-sm outline-none transition-all placeholder:text-gray-300 focus:border-primary focus:outline-none focus:ring-[0.5px] focus:ring-primary"
                  // Spread additional props
                />
              </div>
              <div className="flex justify-end pt-2">
                <Button onClick={() => setStep(2)} disabled={!name}>
                  Next
                </Button>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="flex h-full flex-col justify-between gap-8">
              <div className="space-y-6">
                <div className="space-y-1">
                  <InputHeading heading="Type" required />
                  <Select
                    value={type}
                    onValueChange={(value) => {
                      setType(value as CustomFieldType);
                    }}
                  >
                    <SelectTrigger className="h-9 w-72">
                      {!type ? (
                        <span className="text-gray-300">Select type</span>
                      ) : type === "boolean" ? (
                        "Yes/No"
                      ) : (
                        type?.charAt(0).toUpperCase() + type.slice(1)
                      )}
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectLabel>Value</SelectLabel>
                        <SelectItem className="pl-6" value="text">
                          Text
                        </SelectItem>
                        <SelectItem className="pl-6" value="number">
                          Number
                        </SelectItem>
                        <SelectItem className="pl-6" value="currency">
                          Currency
                        </SelectItem>
                        <SelectItem className="pl-6" value="date">
                          Date
                        </SelectItem>
                      </SelectGroup>
                      <SelectSeparator />
                      <SelectGroup>
                        <SelectLabel>Options</SelectLabel>
                        <SelectItem className="pl-6" value="boolean">
                          Yes/No
                        </SelectItem>
                        <SelectItem className="pl-6" value="select">
                          Select
                        </SelectItem>
                        <SelectItem className="pl-6" value="multi-select">
                          Multi-Select
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
                {type === "select" || type === "multi-select" ? (
                  <div className="space-y-1">
                    <div>
                      <InputHeading heading="Options" />
                    </div>
                    <div className="space-y-1">
                      {options.map((option, index) => (
                        <div
                          className="group flex items-center space-x-1"
                          key={index}
                        >
                          <TextInput
                            ref={(el) => {
                              optionRefs.current[index] = el;
                            }}
                            value={option}
                            onChange={(e) => {
                              const newOptions = [...options];
                              newOptions[index] = e.target.value;
                              setOptions(newOptions);
                            }}
                            autoFocus
                            className="w-72"
                            autoComplete="off"
                            placeholder={`Option ${index + 1}`}
                          />
                          {options.length > 1 && (
                            <button
                              onClick={() => {
                                const newOptions = [...options];
                                newOptions.splice(index, 1);
                                setOptions(newOptions);
                                // focus on the same index if it's not the last one
                                if (index !== options.length - 1) {
                                  optionRefs.current[index]?.focus();
                                } else {
                                  optionRefs.current[index - 1]?.focus();
                                }
                              }}
                              tabIndex={-1}
                              className="rounded-full p-1 text-gray-400 text-transparent transition-colors hover:bg-gray-50 hover:text-gray-600 group-hover:text-gray-400"
                            >
                              <XMarkIcon className="h-3 w-3" />
                            </button>
                          )}
                        </div>
                      ))}
                      <div>
                        <Button
                          onClick={() => {
                            setOptions([...options, ""]);
                            // focus on the last one
                          }}
                          variant={"secondaryLink"}
                          size="sm"
                          className="h-fit px-0"
                        >
                          Add Option
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : null}
                {type == "currency" && (
                  <div className="space-y-1">
                    <InputHeading heading="Currency" />
                    <Select
                      value={currency.code}
                      onValueChange={(value) => {
                        const currency = currencies.find(
                          (c) => c.code === value
                        );
                        if (currency) {
                          setCurrency(currency);
                        }
                      }}
                    >
                      <SelectTrigger className="h-9 w-72 gap-2 truncate">
                        <div className="flex items-center justify-start space-x-2">
                          {currency ? (
                            <>
                              <div className="truncate">
                                {currency?.name ?? "Select currency"}
                              </div>
                            </>
                          ) : (
                            <div className="text-gray-400">Select currency</div>
                          )}
                        </div>
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {currencies.map((c) => (
                            <SelectItem
                              key={c.code}
                              value={c.code}
                              className="pr-12"
                            >
                              <div className="flex w-full items-center space-x-2">
                                <div className="w-8 text-gray-400">
                                  {c.code}
                                </div>
                                <div className="w-40 truncate">{c.name}</div>
                              </div>
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                )}
              </div>
              <div className="flex items-center justify-between pt-2">
                <Button onClick={() => setStep(1)} variant={"secondary"}>
                  Back
                </Button>
                <Button
                  onClick={() => {
                    if (type) {
                      createCustomFieldMutation.mutate({
                        name,
                        description,
                        objectType: "project",
                        type,
                        ...(type === "select" || type === "multi-select"
                          ? {
                              options: options.map((o) => {
                                return { label: o, value: o };
                              }),
                            }
                          : {}),
                        ...(type === "currency"
                          ? { currencyCode: currency.code }
                          : {}),
                      });
                    }
                  }}
                  disabled={!type || createCustomFieldMutation.isLoading}
                >
                  {createCustomFieldMutation.isLoading
                    ? "Creating.."
                    : "Create"}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function PanelStep({
  step,
  title,
  activeStep,
}: {
  step: number;
  title: string;
  activeStep: number;
}) {
  return (
    <div
      className={clsx(
        "flex items-center space-x-2",
        step == activeStep
          ? "font-medium text-gray-800"
          : step <= activeStep
          ? "text-gray-400/80"
          : "text-gray-400/80"
      )}
    >
      <div
        className={clsx(
          "flex h-4 w-4 items-center justify-center rounded-full border",
          step == activeStep
            ? "border-gray-800"
            : step <= activeStep
            ? "border-gray-400/80"
            : "border-gray-400/80"
        )}
      >
        {activeStep > step ? (
          <CheckIcon className="h-3 w-3" />
        ) : (
          <div className="text-[10px]">{step}</div>
        )}
      </div>
      <div className="text-sm">{title}</div>
    </div>
  );
}
