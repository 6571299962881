import clsx from "clsx";
import React from "react";

type Props = {
  children: React.ReactNode;
  title: string | undefined;
  isEmbed?: boolean;
};

export const ToolTip = ({ children, title, isEmbed = false }: Props) => {
  return (
    <>
      {title === undefined ? (
        children
      ) : (
        <div className="group/tooltip relative">
          {children}
          <div
            className={clsx(
              "invisible absolute z-50 mt-2 whitespace-nowrap rounded-md  font-normal text-white transition group-hover/tooltip:visible"
            )}
          >
            <div
              className={clsx(
                "px-2 py-1.5 rounded-md bg-slate-700 text-xs"
              )}
            >
              {title}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
