import {
  DocumentTextIcon,
  MagnifyingGlassIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import Typography from "@tiptap/extension-typography";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  NoteData,
  useCreatePhaseNote,
  useDeleteNote,
  useGetPhaseNotes,
  useUpdateNote,
} from "src/api/Notes/notes";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "src/components/ui/dialog";
import TiptapEditor from "src/components/ui/editor/tiptap-editor";
import { Loading } from "src/components/ui/loading";
import { TextInput } from "src/components/ui/text-input";
import { usePhaseNotesContext } from "./PhaseNotesContextProvider";
import clsx from "clsx";

type Props = {};

export default function PhaseNotes({}: Props) {
  const { phaseId } = useParams();
  const { data: notes } = useGetPhaseNotes(String(phaseId));
  const [search, setSearch] = useState("");
  const filteredNotes =
    notes
      ?.filter((n) => n.name.toLowerCase().includes(search.toLowerCase()))
      .sort(
        (n1, n2) =>
          new Date(n2.createdAt).getTime() - new Date(n1.createdAt).getTime()
      ) ?? [];
  return (
    <>
      {!!notes ? (
        <>
          {notes.length > 0 ? (
            <>
              <div className="flex h-20 items-center justify-between border-b px-8">
                <div className="relative">
                  <TextInput
                    className="w-72 pl-10"
                    placeholder="Search.."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
                  </div>
                </div>
                <div>
                  <NewNoteButton />
                  {/* {resourcesCtx.state.isExternal ?  */}
                </div>
              </div>
              <div className="border-b text-sm">
                <div className="flex h-11 items-center justify-between gap-6 bg-gray-50 px-8 text-gray-500">
                  <div className="flex items-center space-x-8">
                    {/* <div className="w-10">Type</div> */}
                    <div className="min-w-[160px] flex-1">Name</div>
                  </div>
                  <div className="flex items-center space-x-4">
                    <div className="w-40">Created By</div>
                    <div className="w-32">Created At</div>
                  </div>
                </div>
              </div>
              <div className="overflow-auto">
                {filteredNotes.map((n) => (
                  <NoteRow key={n._id} note={n} />
                ))}
                {filteredNotes.length === 0 && (
                  <div className="flex h-12 items-center border-b border-gray-100 px-8 text-sm text-gray-400">
                    <div>No notes found..</div>
                  </div>
                )}
              </div>
            </>
          ) : (
            // <EmptySection
            //   Icon={DocumentTextIcon}
            //   subtitle="Resources enable easy access to documents and links. Add your first resource by clicking the button below."
            //   title="Resources"
            //   cta={<AddResourceButton />}
            //   />

            <div className="relative mx-auto mt-[15vh] flex w-96 flex-col items-center p-8">
              <DocumentTextIcon className="h-7 w-7 text-gray-400" />
              <div className="mt-1 text-xl font-medium text-gray-700">
                Notes
              </div>
              <div className="mb-2 mt-1 text-center font-light text-gray-500">
                Notes help you keep track of project updates. They are hidden
                from external members.
              </div>
              <div className="mt-3">
                <NewNoteButton />
              </div>
            </div>
          )}
        </>
      ) : (
        <Loading className="mt-[20vh]" />
      )}
    </>
    // <div className="space-y-4">
    //   <div className="flex justify-end">

    //   </div>
    //   <div className="mx-auto max-w-5xl space-y-4 pb-6">
    //     {notes
    //       ?.sort(
    //         (n1, n2) =>
    //           new Date(n2.createdAt).getTime() -
    //           new Date(n1.createdAt).getTime()
    //       )
    //       .map((n) => (
    //         <NoteCard key={n._id} note={n} />
    //       ))}
    //   </div>
    // </div>
  );
}

function NewNoteButton() {
  const phaseNotesCtx = usePhaseNotesContext();
  const [isAddingNote, setIsAddingNote] = useState(false);
  return (
    <>
      <Button
        className="space-x-2"
        variant={phaseNotesCtx.state.isEmbed ? "embed" : "default"}
        onClick={() => {
          setIsAddingNote(true);
        }}
      >
        {/* <PlusIcon className="h-4 w-4" /> */}
        <span>Create Note</span>
      </Button>
      <NewNoteModal
        isAddingNote={isAddingNote}
        setIsAddingNote={setIsAddingNote}
      />
    </>
  );
}

function NewNoteModal({
  isAddingNote,
  setIsAddingNote,
}: {
  isAddingNote: boolean;
  setIsAddingNote: (isAddingNote: boolean) => void;
}) {
  const { phaseId } = useParams();
  const phaseNotesCtx = usePhaseNotesContext();
  const [name, setName] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  // const editor = useEditor({
  //   extensions: [
  //     StarterKit,
  //     Typography,
  //     Placeholder.configure({
  //       placeholder: "Enter your note..",
  //       showOnlyWhenEditable: false,
  //     }),
  //     Link.configure({
  //       openOnClick: false,
  //       validate: (href) => /^https?:\/\//.test(href),
  //     }),
  //   ],
  //   editorProps: {
  //     attributes: {
  //       class:
  //         "prose outline-none ring-0 focus:outline-none focus:ring-0 border-none focus:border-none",
  //     },
  //   },
  //   content: "",
  //   editable: true,
  // });
  const [content, setContent] = useState("");
  const createPhaseNoteMutation = useCreatePhaseNote();

  useEffect(() => {
    if (createPhaseNoteMutation.isSuccess) {
      setContent("");
      setName("");
      setIsVisible(true);
      setIsAddingNote(false);
    }
  }, [createPhaseNoteMutation.isSuccess]);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
        autolink: true,
      }),
      Typography,
      Placeholder.configure({
        placeholder: "Enter your note..",
        showOnlyWhenEditable: false,
      }),
    ],
    content: content,
    onBlur: ({ editor }) => {
      setContent(editor.getHTML());
    },
  });

  return (
    <Dialog
      open={isAddingNote}
      onOpenChange={(open) => {
        setIsAddingNote(open);
      }}
    >
      <DialogContent className="w-full max-w-2xl">
        <DialogHeader>
          <DialogTitle>New Note</DialogTitle>
        </DialogHeader>
        <div className="py-4">
          <div className="space-y-4">
            <div className="space-y-1">
              <InputHeading heading="Name" />
              <TextInput
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div
              className="max-h-[60vh] min-h-[400px] cursor-text overflow-auto rounded-md border border-gray-300 p-3 shadow-sm"
              onClick={() => {
                editor?.commands.focus();
              }}
            >
              {editor && <TiptapEditor editor={editor} />}
            </div>
          </div>
        </div>
        <DialogFooter>
          <div className="flex w-full justify-end">
            {/* {!phaseNotesCtx.state.isExternal && (
              <div className="inline-flex h-8 items-center gap-1 rounded-md border border-gray-300 px-1 shadow-sm">
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                      <button
                        className={clsx(
                          "rounded p-1 outline-none focus-visible:ring-[1.25px] focus-visible:ring-primary",
                          isVisible
                            ? "bg-primary-100 text-primary-600"
                            : "text-gray-500 hover:bg-gray-100"
                        )}
                        onClick={() => {
                          setIsVisible(true);
                        }}
                      >
                        <EyeIcon className="h-4 w-4" />
                      </button>
                    </TooltipTrigger>
                    <TooltipContent sideOffset={8}>
                      <p>Externally visible</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                      <button
                        className={clsx(
                          "rounded p-1 outline-none focus-visible:ring-[1.25px] focus-visible:ring-primary",
                          !isVisible
                            ? "bg-primary-100 text-primary-600"
                            : "text-gray-500 hover:bg-gray-100"
                          // taskTemplateCtx.state.tasker?.type == "external" &&
                          //   "bg-gray-100 text-gray-300 hover:bg-gray-100"
                        )}
                        onClick={() => {
                          setIsVisible(false);
                        }}
                        // disabled={
                        //   taskTemplateCtx.state.tasker?.type == "external"
                        // }
                      >
                        <EyeSlashIcon
                          className={
                            `h-4 w-4`
                            // ${
                            //   taskTemplateCtx.state.tasker?.type === "external"
                            //     ? "text-gray-300"
                            //     : ""
                            // }
                            // `
                          }
                        />
                      </button>
                    </TooltipTrigger>
                    <TooltipContent sideOffset={8}>
                      <p>Externally hidden</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            )} */}
            <div className="flex justify-end space-x-2.5">
              {/* <Button
                variant={"secondary"}
                onClick={() => {
                  setIsAddingNote(false);
                }}
              >
                Cancel
              </Button> */}
              <Button
                disabled={
                  createPhaseNoteMutation.isLoading || name.trim() === ""
                }
                onClick={() => {
                  // todo: create note
                  createPhaseNoteMutation.mutate({
                    params: {
                      name,
                      description: "",
                      content: content,
                      isVisible,
                      phaseId: String(phaseId),
                    },
                  });
                }}
              >
                {createPhaseNoteMutation.isLoading ? "Creating..." : "Create"}
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function NoteRow({ note }: { note: NoteData }) {
  const updateNoteMutation = useUpdateNote();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const deleteNoteMutation = useDeleteNote();
  const [name, setName] = useState(note.name);
  const [isDeleting, setIsDeleting] = useState(false);
  const editorDivRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "0px"; // Reset height to shrink if needed
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + "px";
    }
  }, [name]);

  useEffect(() => {
    setTimeout(() => {
      if (textAreaRef.current) {
        textAreaRef.current.style.height = "0px"; // Reset height to shrink if needed
        const scrollHeight = textAreaRef.current.scrollHeight;
        textAreaRef.current.style.height = scrollHeight + "px";
      }
      editor?.commands.focus("end");
      if (editorDivRef.current) {
        editorDivRef.current.scrollTop = editorDivRef.current.scrollHeight;
      }
    }, 50);
  }, [isEditing]);

  useEffect(() => {
    setName(note.name);
  }, [note.name]);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
        autolink: true,
      }),
      Typography,
      Placeholder.configure({
        placeholder: "Enter your note..",
        showOnlyWhenEditable: false,
      }),
    ],
    content: note.content,
    onBlur: ({ editor }) => {
      if (note.content.trim() !== editor.getHTML().trim()) {
        updateNoteMutation.mutate({
          params: {
            _id: note._id.toString(),
            content: editor.getHTML(),
          },
          metadata: {
            taskId: note.task?._id,
            phaseId: note.phaseId,
          },
        });
      }
    },
    autofocus: true,
  });

  return (
    <>
      <div
        className="flex h-12 cursor-pointer items-center justify-between gap-6 border-b border-gray-100 px-8 hover:bg-gray-50"
        onClick={() => {
          setIsEditing(true);
        }}
      >
        <div className={clsx("flex min-w-0 flex-1 items-center space-x-8")}>
          {/* <div className="inline-flex w-10 items-center">
            {!!note.task ? (
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger>
                    <BoltIcon className="h-[18px] w-[18px] text-gray-400" />
                  </TooltipTrigger>
                  <TooltipContent side="right">
                    <div>Task</div>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ) : (
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger>
                    <RocketLaunchIcon className="h-[18px] w-[18px] text-gray-400" />
                  </TooltipTrigger>
                  <TooltipContent side="right">
                    <div>Project</div>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </div> */}
          <div className="min-w-[160px] flex-1 truncate text-sm font-medium text-gray-600">
            {note.name}
          </div>
        </div>

        <div className="flex shrink-0 items-center space-x-4">
          <div className="w-40 truncate text-sm text-gray-400">
            {note.createdBy?.name}
          </div>
          <div className="w-32 text-sm text-gray-400">
            {dayjs(note.createdAt).format("MMM DD, h:mm A")}
          </div>
        </div>
      </div>
      <Dialog
        open={isEditing}
        onOpenChange={(open) => {
          setIsEditing(open);
        }}
      >
        <DialogContent className="w-full max-w-2xl">
          <div className="pb-2">
            <div className="space-y-4">
              <div className="space-y-1">
                <textarea
                  ref={textAreaRef}
                  className="w-full resize-none border-none p-0 pr-5 text-3xl font-medium outline-none placeholder:text-gray-300 focus:border-none focus:outline-none focus:ring-0"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  onBlur={() => {
                    if (name.trim() !== note.name.trim()) {
                      updateNoteMutation.mutate({
                        params: {
                          _id: note._id.toString(),
                          name,
                        },
                        metadata: {
                          taskId: note.task?._id,
                          phaseId: note.phaseId,
                        },
                      });
                    }
                  }}
                  placeholder="Title..."
                />
              </div>
              <div
                className="max-h-[400px] min-h-[300px] cursor-text overflow-auto rounded-md border border-gray-300 p-3 shadow-sm"
                onClick={() => {
                  editor?.commands.focus();
                }}
                ref={editorDivRef}
              >
                <TiptapEditor editor={editor} />
              </div>
              <div>
                <Button
                  variant={"secondary"}
                  size={"icon"}
                  onClick={() => {
                    setIsDeleting(true);
                  }}
                >
                  <TrashIcon className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isDeleting}
        onOpenChange={(open) => {
          setIsDeleting(open);
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Confirmation</DialogTitle>
          </DialogHeader>
          <div className="space-y-5 pt-3 text-sm text-gray-600">
            <div>
              Are you sure you want to delete the note{" "}
              <span className="font-medium text-gray-900">{note.name}</span>?
            </div>
          </div>
          <DialogFooter>
            <div className="flex justify-end space-x-3">
              <Button
                variant={"secondary"}
                onClick={() => {
                  setIsDeleting(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant={"destructive"}
                onClick={() => {
                  deleteNoteMutation.mutate({
                    note: {
                      _id: note._id.toString(),
                    },
                    metadata: {
                      taskId: note.task?._id,
                      phaseId: note.phaseId,
                    },
                  });
                }}
              >
                {deleteNoteMutation.isLoading ? "Deleting..." : "Delete"}
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
