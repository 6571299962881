import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { ObjectID } from "bson";
import { useNavigate } from "react-router-dom";
import {
  buildFetcher,
  buildLauncher,
  buildSetter,
  buildSetterWithInstanceIdProvided,
} from "../..";
import { useRealmApp } from "../../../store/RealmApp";
import {
  StageInList,
  StageTemplateObj,
  TaskInList,
  TaskTemplateObj,
} from "../../../types/Services/Tasks";
import { JourneyTemplateData } from "../Journeys/journeys";
import { phaseKeys } from "../SharedSpace/phases";
import { sharedSpaceKeys } from "../SharedSpace/spaces";
import { TaskerType, relativeDueDateUnitArr } from "./tasks";
import { useAccessToken } from "src/api/useAccessToken";

export const journeyTemplateKeys = {
  journeyTemplates: () => ["journeyTemplates", "all"] as const,
  journeyTemplate: (journeyTemplateId: ObjectID) =>
    ["journeyTemplates", journeyTemplateId.toString()] as const,
  stageTemplates: (journeyTemplateId: ObjectID) =>
    [
      ...journeyTemplateKeys.journeyTemplate(journeyTemplateId),
      "stageTemplates",
      "all",
    ] as const,
  // stageTemplate: (journeyTemplateId: ObjectID, stageTemplateId: ObjectID | undefined) =>
  //   [
  //     "journeyTemplates",
  //     "journeyTemplate",
  //     "stageTemplates",
  //     "stageTemplate",
  //     journeyTemplateId,
  //     stageTemplateId,
  //   ] as const,
  taskTemplates: (
    journeyTemplateId: ObjectID,
    stageTemplateId: ObjectID | undefined
  ) =>
    [
      ...journeyTemplateKeys.journeyTemplate(journeyTemplateId),
      "stageTemplates",
      stageTemplateId?.toString(),
      "taskTemplates",
      "all",
    ] as const,
  stageAndtaskTemplatesList: (journeyTemplateId: ObjectID) =>
    ["stageAndTaskTemplates", journeyTemplateId] as const,

  // taskTemplate: (
  //   journeyTemplateId: ObjectID,
  //   stageTemplateId: ObjectID | undefined,
  //   taskTemplateId: ObjectID | undefined
  // ) =>
  //   [
  //     "journeyTemplates",
  //     "journeyTemplate",
  //     "stageTemplates",
  //     "stageTemplate",
  //     "taskTemplates",
  //     "taskTemplate",
  //     journeyTemplateId,
  //     stageTemplateId,
  //     taskTemplateId,
  //   ] as const,
};

export const useGetJourneyTemplates = (): {
  journeyTemplates: JourneyTemplateData[] | undefined;
  isJourneyTemplatesLoading: boolean;
  isJourneyTemplatesError: boolean;
  journeyTemplatesError: any;
} => {
  const app = useRealmApp();
  const meta = {
    functionName: "getJourneyTemplates",
    parameters: {
      instanceId: app.currentUser.customData?.instanceId,
    },
  };

  const { isLoading, isError, error, data } = useQuery<JourneyTemplateData[]>(
    journeyTemplateKeys.journeyTemplates(),
    buildFetcher(app, meta)
  );

  // let journeyTemplates;
  // if (data) {
  //   journeyTemplates = sortByCreatedAt([...data])
  // }

  return {
    journeyTemplates: data,
    isJourneyTemplatesLoading: isLoading,
    isJourneyTemplatesError: isError,
    journeyTemplatesError: error,
  };
};

type GetJourneyTemplateHookReturn = {
  journeyTemplate: JourneyTemplateData | undefined;
  isJourneyTemplateLoading: boolean;
  isJourneyTemplateError: boolean;
  journeyTemplateError: any;
};

export const useGetJourneyTemplate = (
  _id: string
): GetJourneyTemplateHookReturn => {
  const app = useRealmApp();
  const queryClient = useQueryClient();
  const meta = {
    functionName: "getJourneyTemplate",
    parameters: {
      _id: new ObjectID(_id),
    },
  };

  const { isLoading, isError, error, data } = useQuery<JourneyTemplateData>(
    journeyTemplateKeys.journeyTemplate(new ObjectID(_id)),
    buildFetcher(app, meta),
    {
      staleTime: Infinity,
    }
  );

  return {
    journeyTemplate: data,
    isJourneyTemplateLoading: isLoading,
    isJourneyTemplateError: isError,
    journeyTemplateError: error,
  };
};

// Get tasks // getTasksForInstance

export const useUpdateJourneyTemplate = () => {
  const app = useRealmApp();
  const functionName = "updateJourneyTemplate";
  const fieldName = "params";
  const queryClient = useQueryClient();

  const updateJourneyTemplateMutation = useMutation(
    buildSetter(app, functionName, fieldName),
    {
      onMutate: async ({
        params,
        journeyTemplateId,
      }: {
        params: Partial<JourneyTemplateData> & {customFieldIds?: ObjectID[]};
        journeyTemplateId: ObjectID;
      }) => {
        const queryKey = journeyTemplateKeys.journeyTemplate(journeyTemplateId);
        await queryClient.cancelQueries(queryKey);

        const previousJourneyTemplate =
          queryClient.getQueryData<JourneyTemplateData>(queryKey);

        const newJourneyTemplate = { ...previousJourneyTemplate, ...params };
        queryClient.setQueryData(queryKey, newJourneyTemplate);
        return { previousJourneyTemplate };
      },
      onError: (data, variables, context) => {
        queryClient.setQueryData(
          journeyTemplateKeys.journeyTemplate(variables.journeyTemplateId),
          context?.previousJourneyTemplate
        );
      },
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries(
          journeyTemplateKeys.journeyTemplate(variables.journeyTemplateId)
        );
        queryClient.invalidateQueries(journeyTemplateKeys.journeyTemplates());
      },
    }
  );

  return updateJourneyTemplateMutation;
};

type GetJourneyStageTemplatesHookReturn = {
  stages: StageTemplateObj[] | undefined;
  isLoading: boolean;
  isError: boolean;
  error: any;
};

export type StageTemplateData = {
  _id: string;
  name: string;
  journeyTemplateId: string;
  instanceId: string;
  createdAt?: Date;
  updatedAt?: Date;
  taskTemplates?: TaskTemplateData[];
  taskTemplateOrder?: string[];
};

type TaskTemplateData = {
  _id?: string;
  title: string;
  description: string;
  relativeDueDate: {
    unit: (typeof relativeDueDateUnitArr)[number];
    value: number;
  };
  journeyStageTemplateId?: string;
  journeyTemplateId: string;
  taskerType?: TaskerType;
  dependentOn?:
    | {
        dependencyTemplateId?: string;
        _id: string | null | undefined;
        type: "task" | "stage";
      }
    | {};
  instanceId: string;
  createdAt: Date;
  updatedAt?: Date;
  taskerPersona: string;
  isVisible: boolean;
}

export const useGetStageAndTaskTemplateMap = (_id: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();

  return useQuery(
    journeyTemplateKeys.stageTemplates(new ObjectID(_id)),
    async (): Promise<StageTemplateData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/stageAndTaskTemplateMap`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            _id,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};

export const useGetStageTemplates = (
  _id: ObjectID
): GetJourneyStageTemplatesHookReturn => {
  const app = useRealmApp();
  const queryClient = useQueryClient();
  const meta = {
    functionName: "getStageAndTaskTemplateMap",
    parameters: {
      _id: _id,
    },
  };

  const { isSuccess, isLoading, isError, error, data } = useQuery<
    StageTemplateObj[]
  >(journeyTemplateKeys.stageTemplates(_id), buildFetcher(app, meta));

  // if (isSuccess) {
  //   // add task templates to the cache
  //   data.forEach((stage) => {
  //     queryClient.setQueryData(
  //       journeyTemplateKeys.taskTemplates(_id, stage._id),
  //       stage.taskTemplates
  //     );
  //   });
  // }

  return {
    stages: data,
    isLoading,
    isError,
    error,
  };
};

type GetJStageAndTaskTemplatesHookReturn = {
  stageAndTaskTemplateList: {
    stageTemplates: StageInList[];
    taskTemplates: TaskInList[];
  };
  isListLoading: boolean;
  isListError: boolean;
  error: any;
};

export const useGetStageAndTaskTemplatesList = (
  journeyTemplateId: ObjectID
): GetJStageAndTaskTemplatesHookReturn => {
  const app = useRealmApp();
  // const queryClient = useQueryClient();
  const meta = {
    functionName: "getStageAndTaskTemplates",
    parameters: {
      journeyTemplateId: journeyTemplateId,
    },
  };

  const { isSuccess, isLoading, isError, error, data } = useQuery<{
    stageTemplates: StageInList[];
    taskTemplates: TaskInList[];
  }>(
    journeyTemplateKeys.stageAndtaskTemplatesList(journeyTemplateId),
    buildFetcher(app, meta)
  );

  let modifiedData;
  if (isSuccess) {
    modifiedData = data;
    modifiedData.taskTemplates.forEach((t) => (t["name"] = t.title));
  }

  return {
    stageAndTaskTemplateList: modifiedData
      ? modifiedData
      : { stageTemplates: [], taskTemplates: [] },
    isListLoading: isLoading,
    isListError: isError,
    error,
  };
};

export const useDeleteStageTemplateConfirmation = () => {
  const app = useRealmApp();
  const functionName = "deleteJourneyStageTemplate";
  const fieldName = "params";
  const queryClient = useQueryClient();

  return useMutation(buildSetter(app, functionName, fieldName), {
    onMutate: async ({
      params,
      journeyTemplateId,
    }: {
      params: { _id: ObjectID; force: false };
      journeyTemplateId: ObjectID;
    }) => {
      // const queryKey = journeyTemplateKeys.stageTemplates(journeyTemplateId);
      // await queryClient.cancelQueries(queryKey);
      // const previousStageTemplates = queryClient.getQueryData(queryKey);
      // queryClient.setQueryData(queryKey, (stages: StageTemplateObj[] = []) =>
      //   stages.filter((s) => s._id?.toString() !== params._id.toString())
      // );
      // return { previousStageTemplates };
    },
    onError: (err: any, variables, context) => {
      // console.log(typeof err);
      // console.log(JSON.parse(err.error));
      // queryClient.setQueryData(
      //   journeyTemplateKeys.stageTemplates(variables.journeyTemplateId),
      //   context?.previousStageTemplates
      // );
    },
    onSuccess: (
      data: {
        deleted: { _id: ObjectID; title: string }[];
        dependencies: { _id: ObjectID; title: string }[];
      },
      variables
    ) => {
      console.log(data);
      // queryClient.invalidateQueries(
      //   journeyTemplateKeys.stageTemplates(variables.journeyTemplateId)
      // );
    },
  });
};

export const useDeleteStageTemplate = () => {
  const app = useRealmApp();
  const functionName = "deleteJourneyStageTemplate";
  const fieldName = "params";
  const queryClient = useQueryClient();

  return useMutation(buildSetter(app, functionName, fieldName), {
    onMutate: async ({
      params,
      journeyTemplateId,
    }: {
      params: { _id: ObjectID; force: true };
      journeyTemplateId: ObjectID;
    }) => {
      // const queryKey = journeyTemplateKeys.stageTemplates(journeyTemplateId);
      // await queryClient.cancelQueries(queryKey);
      // const previousStageTemplates = queryClient.getQueryData(queryKey);
      // queryClient.setQueryData(queryKey, (stages: StageTemplateObj[] = []) =>
      //   stages.filter((s) => s._id?.toString() !== params._id.toString())
      // );
      // return { previousStageTemplates };
    },
    onError: (err: any, variables, context) => {
      // console.log(typeof err);
      // console.log(JSON.parse(err.error));
      // queryClient.setQueryData(
      //   journeyTemplateKeys.stageTemplates(variables.journeyTemplateId),
      //   context?.previousStageTemplates
      // );
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(
        journeyTemplateKeys.stageTemplates(variables.journeyTemplateId)
      );
    },
  });
};

export const useUpdateJourneyStageTemplate = () => {
  const app = useRealmApp();
  const functionName = "updateJourneyStageTemplate";
  const fieldName = "params";
  const queryClient = useQueryClient();

  return useMutation(buildSetter(app, functionName, fieldName), {
    onMutate: async ({
      params,
      journeyTemplateId,
    }: {
      params: { _id: ObjectID; name?: string; taskTemplateOrder?: ObjectID[] };
      journeyTemplateId: ObjectID;
    }) => {
      const queryKey = journeyTemplateKeys.stageTemplates(journeyTemplateId);
      await queryClient.cancelQueries(queryKey);

      const previousStageTemplates = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (stages: StageTemplateObj[] = []) =>
        stages.map((s) => {
          if (s._id?.toString() === params._id.toString()) {
            return { ...s, ...params };
          }
          return s;
        })
      );

      return { previousStageTemplates };
    },
    onError: (data, variables, context) => {
      // console.log("ERROR");
      queryClient.setQueryData(
        journeyTemplateKeys.stageTemplates(variables.journeyTemplateId),
        context?.previousStageTemplates
      );
    },
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries(
        journeyTemplateKeys.stageTemplates(variables.journeyTemplateId)
      );
    },
  });
};

export const useAddStageTemplate = () => {
  const app = useRealmApp();
  const functionName = "createJourneyStageTemplate";
  const fieldName = "params";
  const queryClient = useQueryClient();

  const addStageTemplateMutation = useMutation(
    buildSetterWithInstanceIdProvided(app, functionName, fieldName),
    {
      onMutate: async ({
        params,
      }: {
        params: {
          name: string;
          journeyTemplateId: ObjectID;
        };
      }) => {
        const queryKey = journeyTemplateKeys.stageTemplates(
          params.journeyTemplateId
        );
        await queryClient.cancelQueries(queryKey);

        const previousStageTemplates = queryClient.getQueryData(queryKey);
        const newStageTemplate: StageTemplateObj = {
          name: params.name,
          journeyTemplateId: params.journeyTemplateId,
          instanceId: app.currentUser.customData?.instanceId,
          createdAt: new Date(),
          _id: new ObjectID(),
          taskTemplateOrder: [],
        };

        queryClient.setQueryData(
          queryKey,
          (stages: StageTemplateObj[] = []) => [...stages, newStageTemplate]
        );

        return { previousStageTemplates };
      },
      onError: (data, variables, context) => {
        queryClient.setQueryData(
          journeyTemplateKeys.stageTemplates(
            new ObjectID(variables.params.journeyTemplateId)
          ),
          context?.previousStageTemplates
        );
      },
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries(
          journeyTemplateKeys.journeyTemplate(
            new ObjectID(variables.params.journeyTemplateId)
          )
        );
        queryClient.invalidateQueries(
          journeyTemplateKeys.stageTemplates(
            new ObjectID(variables.params.journeyTemplateId)
          )
        );
        queryClient.invalidateQueries(
          journeyTemplateKeys.stageAndtaskTemplatesList(
            new ObjectID(variables.params.journeyTemplateId)
          )
        );
      },
    }
  );

  return addStageTemplateMutation;
};

// TASK TEMPLATES

// export const useAddTask = () => {
//   const app = useRealmApp();
//   const functionName = "createTask";

//   const queryClient = useQueryClient();

//   const addTaskMutation = useMutation(buildSetter(app, functionName), {
//     onMutate: async (task: TaskResponseObj) => {
//       console.log(task);

//       await queryClient.cancelQueries(["tasks"]);

//       const previousTasks = queryClient.getQueryData(["tasks"]);
//       console.log(previousTasks);

//       const tempTask = { _id: undefined, ...task };

//       queryClient.setQueryData(["tasks"], (tasks: TaskResponseObj[] = []) => [
//         tempTask,
//         ...tasks,
//       ]);

//       return { previousTasks };
//     },
//     onError: (data, variables, context) => {
//       queryClient.setQueryData(["tasks"], context?.previousTasks);
//     },
//     onSettled: () => {
//       queryClient.invalidateQueries(["tasks"]);
//     },
//   });

//   return addTaskMutation;
// };

export const useStartJourney = () => {
  const app = useRealmApp();
  const navigate = useNavigate();
  const functionName = "startJourney";
  const queryClient = useQueryClient();

  const startJourneyMutation = useMutation(buildLauncher(app, functionName), {
    onSuccess: (data, variables) => {
      // console.log(data);
      // console.log(variables);
      if (variables.sharedSpaceId) {
        queryClient.invalidateQueries(
          sharedSpaceKeys.journeys(new ObjectID(variables.sharedSpaceId))
        );
      }
      if (variables.phaseId) {
        queryClient.invalidateQueries(
          phaseKeys.phase(variables.phaseId.toString())
        );
        queryClient.invalidateQueries(
          phaseKeys.journeys(variables.phaseId.toString())
        );
      }

      queryClient.invalidateQueries(["journeys"]);

      if (variables.phaseId) {
        navigate(`phases/${variables.phaseId}`);
      }

      // queryClient.invalidateQueries(sharedSpaceKeys.journeys());
      // navigate(`journeys/active/${variables}`)
    },
  });

  return startJourneyMutation;
};
