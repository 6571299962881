import React, { createContext, useContext, useEffect, useReducer } from "react";
import { FormTemplateData } from "src/api/Forms/forms";
import { TagData } from "src/api/General/tags";
import {
  TaskTemplateAction,
  TaskTemplateCreateRequest,
  TaskTemplateData,
  useCreateTaskTemplate,
} from "src/api/Services/Journeys/journeyTemplates";
import { KnowledgeResourceTemplateData } from "src/api/Services/KnowledgeBase/knowledgeBase";
import { StageTemplateObj } from "src/types/Services/Tasks";

type Action =
  | { type: "create_task_template" }
  | { type: "title_update"; title: string }
  | { type: "description_update"; description: string }
  | { type: "is_visible_update"; isVisible: boolean }
  | {
      type: "stage_template_update";
      journeyStageTemplate: TaskTemplateData["journeyStageTemplate"];
    }
  | { type: "tasker_update"; tasker: TaskTemplateData["tasker"] }
  | { type: "tags_update"; tags: TaskTemplateData["tags"] }
  | {
      type: "relative_due_date_update";
      relativeDueDate: TaskTemplateData["relativeDueDate"];
    }
  | {
      type: "dependent_on_update";
      dependentOn: TaskTemplateData["dependentOn"];
    }
  | {
      type: "krts_update";
      krts: KnowledgeResourceTemplateData[];
    }
  | {
      type: "options_update";
      options: Options;
    }
  | {
      type: "parent_task_template_update";
      parentTaskTemplate: TaskTemplateData["parentTaskTemplate"];
    }
  | {
      type: "estimate_update";
      estimate: TaskTemplateData["estimate"];
    }
  | {
      type: "subtasks_update";
      subtasks: Subtask[];
    }
  | {
      type: "action_update";
      action: TaskTemplateData["action"];
    }
  | {
      type: "form_template_update";
      formTemplate: FormTemplateData;
    }
  | {
      type: "launch_condition_update";
      launchCondition: TaskTemplateLaunchCondition;
    }
  | {
      type: "meeting_update";
      meeting: NonNullable<TaskTemplateCreateRequest["meeting"]>;
    };

type Options = {
  hasLinkedKnowledgeResourceTemplate: boolean;
  hasSubTasks: boolean;
};

export type TaskTemplateLaunchCondition =
  | "project start"
  | "completion of task"
  | "completion of stage";

type Dispatch = (action: Action) => void;

export type Subtask = {
  title: string;
  estimate: TaskTemplateData["estimate"];
  id: number;
  tasker: TaskTemplateData["tasker"];
  isVisible: boolean;
};

export type MeetingType = {
  vendor: "calendly" | "hubspot" | "other";
  link: string;
};

type State = {
  taskTemplate?: TaskTemplateData;
  title: string;
  description: string;
  relativeDueDate: TaskTemplateData["relativeDueDate"];
  isVisible: boolean;
  // the following can be undefined to begin with
  journeyStageTemplate: TaskTemplateData["journeyStageTemplate"] | undefined;
  tasker: TaskTemplateData["tasker"];
  dependentOn: TaskTemplateData["dependentOn"];
  tags: TagData[];
  krts: KnowledgeResourceTemplateData[];
  options: Options;
  estimate: TaskTemplateData["estimate"];
  parentTaskTemplate?: TaskTemplateData["parentTaskTemplate"];
  subtasks: Subtask[];
  action: TaskTemplateAction;
  formTemplate: FormTemplateData | undefined;
  launchCondition: TaskTemplateLaunchCondition;
  meeting: NonNullable<TaskTemplateCreateRequest["meeting"]>;
  // parentTaskTemplateId?: ObjectID;
};

const TaskTemplateContext = createContext<
  | {
      state: State;
      dispatch: Dispatch;
      createTaskTemplate: () => void;
      // updateTaskTemplate: () => void;
      // deleteTaskTemplate: () => void;
    }
  | undefined
>(undefined);

function taskTemplateReducer(state: State, action: Action): State {
  switch (action.type) {
    case "title_update":
      return { ...state, title: action.title };
    case "description_update":
      return { ...state, description: action.description };
    case "is_visible_update":
      return { ...state, isVisible: action.isVisible };
    case "stage_template_update":
      return { ...state, journeyStageTemplate: action.journeyStageTemplate };
    case "tasker_update":
      return { ...state, tasker: action.tasker };
    case "tags_update":
      return { ...state, tags: action.tags };
    case "relative_due_date_update":
      return { ...state, relativeDueDate: action.relativeDueDate };
    case "dependent_on_update":
      return { ...state, dependentOn: action.dependentOn };
    case "options_update":
      return { ...state, options: action.options };
    case "krts_update":
      return { ...state, krts: action.krts };
    case "estimate_update":
      return { ...state, estimate: action.estimate };
    case "parent_task_template_update":
      return { ...state, parentTaskTemplate: action.parentTaskTemplate };
    case "subtasks_update":
      return { ...state, subtasks: action.subtasks };
    case "action_update":
      return { ...state, action: action.action };
    case "form_template_update":
      return { ...state, formTemplate: action.formTemplate };
    case "launch_condition_update":
      return { ...state, launchCondition: action.launchCondition };
    case "meeting_update":
      return { ...state, meeting: action.meeting };
    default:
      return state;
  }
}

type Props = {
  children: React.ReactNode;
  stage: StageTemplateObj;
};

export const TaskTemplateContextProvider = ({ children, stage }: Props) => {
  const [state, dispatch] = useReducer(taskTemplateReducer, {
    // stages: stages,
    title: "",
    description: "",
    isVisible: true,
    tags: [],
    relativeDueDate: { unit: "day", value: 1 },
    dependentOn: {
      name: "Project Start",
      type: "default",
    },
    estimate: {
      value: 10,
      unit: "minute",
    },
    // following can be undefined to begin with
    journeyStageTemplate: { _id: stage._id, name: stage.name },
    tasker: undefined,
    krts: [],
    // parentTaskTemplate: taskTemplate?.parentTaskTemplate,
    options: {
      hasLinkedKnowledgeResourceTemplate: false,
      hasSubTasks: false,
    },
    subtasks: [],
    action: "default",
    formTemplate: undefined,
    launchCondition: "project start",
    meeting: {
      vendor: "calendly",
      link: "",
    },
  });
  const createTaskTemplateMutation = useCreateTaskTemplate();

  useEffect(() => {
    if (createTaskTemplateMutation.isSuccess) {
      dispatch({ type: "title_update", title: "" });
      dispatch({ type: "description_update", description: "" });
      dispatch({
        type: "subtasks_update",
        subtasks: [
          {
            title: "",
            estimate: {
              unit: "minute",
              value: 10,
            },
            id: Math.random(),
            tasker: undefined,
            isVisible: true,
          },
        ],
      });
    }
  }, [createTaskTemplateMutation.isSuccess]);

  const createTaskTemplate = () => {
    // reset state
    // }
  };

  // const updateTaskTemplate = () => {
  //   if (
  //     !state.taskTemplate?._id ||
  //     !state.taskTemplate.journeyStageTemplate?._id
  //   ) {
  //     return;
  //   }
  //   let isTaskTemplateUpdated = false;
  //   let update: Partial<TaskTemplateData> = { _id: state.taskTemplate._id };
  //   // check if any of the fields have changed
  //   if (state.title !== state.taskTemplate.title) {
  //     update.title = state.title;
  //     isTaskTemplateUpdated = true;
  //   }
  //   if (state.description !== state.taskTemplate.description) {
  //     update.description = state.description;
  //     isTaskTemplateUpdated = true;
  //   }
  //   if (state.isVisible !== state.taskTemplate.isVisible) {
  //     update.isVisible = state.isVisible;
  //     isTaskTemplateUpdated = true;
  //   }
  //   if (
  //     state.journeyStageTemplate?._id &&
  //     state.journeyStageTemplate?._id.toString() !==
  //       state.taskTemplate.journeyStageTemplate._id.toString()
  //   ) {
  //     update.journeyStageTemplate = state.journeyStageTemplate;
  //     isTaskTemplateUpdated = true;
  //   }
  //   if (
  //     state.tasker?.persona !== state.taskTemplate.tasker?.persona ||
  //     state.tasker?.type !== state.taskTemplate.tasker?.type
  //   ) {
  //     update.tasker = state.tasker;
  //     isTaskTemplateUpdated = true;
  //   }
  //   if (state.tags !== state.taskTemplate.tags) {
  //     update.tags = state.tags;
  //     isTaskTemplateUpdated = true;
  //   }
  //   if (
  //     state.relativeDueDate.unit !== state.taskTemplate.relativeDueDate.unit ||
  //     state.relativeDueDate.value !== state.taskTemplate.relativeDueDate.value
  //   ) {
  //     update.relativeDueDate = state.relativeDueDate;
  //     isTaskTemplateUpdated = true;
  //   }
  //   if (
  //     state.dependentOn?.type !== state.taskTemplate.dependentOn?.type ||
  //     (state.dependentOn?._id &&
  //       state.dependentOn._id.toString() !==
  //         state.taskTemplate.dependentOn?._id?.toString())
  //   ) {
  //     update.dependentOn = state.dependentOn;
  //     isTaskTemplateUpdated = true;
  //   }

  //   if (state.options.hasLinkedKnowledgeResourceTemplate && state.krts.length) {
  //     // if there were no krt's linked to the task template before
  //     if (!state.taskTemplate.knowledgeResourceTemplates?.length) {
  //       update.knowledgeResourceTemplateIds = state.krts.map((krt) => krt._id);
  //       isTaskTemplateUpdated = true;
  //     } else {
  //       // if there were krt's linked to the task template before
  //       // need to check if the krts have changed
  //       if (
  //         state.taskTemplate.knowledgeResourceTemplates?.length &&
  //         state.krts[0]._id.toString() !==
  //           state.taskTemplate.knowledgeResourceTemplates[0]._id.toString()
  //       ) {
  //         update.knowledgeResourceTemplateIds = [state.krts[0]._id];
  //         isTaskTemplateUpdated = true;
  //       }
  //     }
  //   } else if (
  //     !state.options.hasLinkedKnowledgeResourceTemplate &&
  //     state.taskTemplate.knowledgeResourceTemplates?.length
  //   ) {
  //     update.knowledgeResourceTemplateIds = [];
  //     isTaskTemplateUpdated = true;
  //   }

  //   console.log(isTaskTemplateUpdated);
  //   if (isTaskTemplateUpdated) {
  //     console.log("updating task template");
  //     console.log(update);
  //     updateTaskTemplateMutation.mutate({
  //       params: update,
  //       journeyTemplateId: state.taskTemplate.journeyTemplateId,
  //       previousJourneyStageId: state.taskTemplate.journeyStageTemplate._id,
  //     });
  //   }
  // };

  // const deleteTaskTemplate = () => {
  //   if (
  //     !state.taskTemplate?._id ||
  //     !state.taskTemplate.journeyStageTemplate?._id
  //   ) {
  //     return;
  //   }
  //   deleteTaskTemplateMutation.mutate({
  //     params: { _id: state.taskTemplate._id },
  //     journeyStageTemplateId: state.taskTemplate.journeyStageTemplate._id,
  //     journeyTemplateId: state.taskTemplate.journeyTemplateId,
  //   });
  // };

  const value = {
    state,
    dispatch,
    createTaskTemplate,
    // updateTaskTemplate,
    // deleteTaskTemplate,
  };

  return (
    <TaskTemplateContext.Provider value={value}>
      {children}
    </TaskTemplateContext.Provider>
  );
};

export const useTaskTemplate = () => {
  const context = useContext(TaskTemplateContext);
  if (context === undefined) {
    throw new Error(
      "useTaskTemplate must be used within a TaskTemplateProvider"
    );
  }
  return context;
};

// import { ObjectID } from "bson";
// import React, { createContext, useContext, useReducer } from "react";
// import { useParams } from "react-router-dom";
// import { TagData } from "src/api/General/tags";
// import {
//   TaskTemplateData,
//   useAddTaskTemplate,
//   useDeleteTaskTemplate,
//   useUpdateTaskTemplate,
// } from "src/api/Services/Journeys/journeyTemplates";
// import { KnowledgeResourceTemplateData } from "src/api/Services/KnowledgeBase/knowledgeBase";
// import { useRealmApp } from "src/store/RealmApp";

// // interface TransformedStageTemplate {
// //   _id: ObjectId;
// //   name: string;
// //   type: "stage";
// // }

// // interface TransformedTaskTemplate {
// //   _id: ObjectId;
// //   name: string;
// //   type: "task";
// // }

// // type DependentOn =
// //   | TransformedStageTemplate
// //   | TransformedTaskTemplate
// //   | { name: "Journey Start"; type: "default" };

// type Action =
//   | { type: "create_task_template" }
//   | { type: "title_update"; title: string }
//   | { type: "description_update"; description: string }
//   | { type: "is_visible_update"; isVisible: boolean }
//   | {
//       type: "stage_template_update";
//       journeyStageTemplate: TaskTemplateData["journeyStageTemplate"];
//     }
//   | { type: "tasker_update"; tasker: TaskTemplateData["tasker"] }
//   | { type: "tags_update"; tags: TaskTemplateData["tags"] }
//   | {
//       type: "relative_due_date_update";
//       relativeDueDate: TaskTemplateData["relativeDueDate"];
//     }
//   | {
//       type: "dependent_on_update";
//       dependentOn: TaskTemplateData["dependentOn"];
//     }
//   | {
//       type: "krts_update";
//       krts: KnowledgeResourceTemplateData[];
//     }
//   | {
//       type: "options_update";
//       options: Options;
//     }
//   | {
//       type: "parent_task_template_update";
//       parentTaskTemplate: TaskTemplateData["parentTaskTemplate"];
//     };

// type Options = {
//   hasLinkedKnowledgeResourceTemplate: boolean;
//   isSubTask: boolean;
// };

// type Dispatch = (action: Action) => void;

// type State = {
//   taskTemplate?: TaskTemplateData;
//   title: string;
//   description: string;
//   relativeDueDate: TaskTemplateData["relativeDueDate"];
//   isVisible: boolean;
//   // the following can be undefined to begin with
//   journeyStageTemplate: TaskTemplateData["journeyStageTemplate"];
//   tasker: TaskTemplateData["tasker"];
//   dependentOn: TaskTemplateData["dependentOn"];
//   tags: TagData[];
//   krts: KnowledgeResourceTemplateData[];
//   options: Options;
//   parentTaskTemplate?: TaskTemplateData["parentTaskTemplate"];
//   parentTaskTemplateId?: ObjectID;
// };

// const TaskTemplateContext = createContext<
//   | {
//       state: State;
//       dispatch: Dispatch;
//       createTaskTemplate: () => void;
//       updateTaskTemplate: () => void;
//       deleteTaskTemplate: () => void;
//     }
//   | undefined
// >(undefined);

// function taskTemplateReducer(state: State, action: Action): State {
//   switch (action.type) {
//     case "title_update":
//       return { ...state, title: action.title };
//     case "description_update":
//       return { ...state, description: action.description };
//     case "is_visible_update":
//       return { ...state, isVisible: action.isVisible };
//     case "stage_template_update":
//       return { ...state, journeyStageTemplate: action.journeyStageTemplate };
//     case "tasker_update":
//       return { ...state, tasker: action.tasker };
//     case "tags_update":
//       return { ...state, tags: action.tags };
//     case "relative_due_date_update":
//       return { ...state, relativeDueDate: action.relativeDueDate };
//     case "dependent_on_update":
//       return { ...state, dependentOn: action.dependentOn };
//     case "options_update":
//       return { ...state, options: action.options };
//     case "krts_update":
//       return { ...state, krts: action.krts };
//     case "parent_task_template_update":
//       return { ...state, parentTaskTemplate: action.parentTaskTemplate };
//     default:
//       return state;
//   }
// }

// type Props = {
//   children: React.ReactNode;
//   taskTemplate?: TaskTemplateData;
// };

// export const TaskTemplateContextProvider = ({
//   children,
//   taskTemplate,
// }: Props) => {
//   const app = useRealmApp();
//   const { journeyTemplateId } = useParams();
//   const [state, dispatch] = useReducer(taskTemplateReducer, {
//     // stages: stages,
//     taskTemplate: taskTemplate,
//     title: taskTemplate?.title ?? "",
//     description: taskTemplate?.description ?? "",
//     isVisible: taskTemplate?.isVisible ?? false,
//     tags: taskTemplate?.tags ?? [],
//     relativeDueDate: taskTemplate?.relativeDueDate ?? { unit: "day", value: 1 },
//     dependentOn:
//       taskTemplate?.dependentOn && taskTemplate?.dependentOn?.type !== "default"
//         ? taskTemplate?.dependentOn
//         : {
//             name: "Journey Start",
//             type: "default",
//           },
//     // following can be undefined to begin with
//     journeyStageTemplate: taskTemplate?.journeyStageTemplate,
//     tasker: taskTemplate?.tasker,
//     krts: taskTemplate?.knowledgeResourceTemplates ?? [],
//     parentTaskTemplate: taskTemplate?.parentTaskTemplate,
//     options: {
//       hasLinkedKnowledgeResourceTemplate:
//         taskTemplate?.knowledgeResourceTemplates
//           ? taskTemplate?.knowledgeResourceTemplates?.length > 0
//           : false,
//       isSubTask: false,
//     },
//   });

//   const addTaskTemplateMutation = useAddTaskTemplate();
//   const updateTaskTemplateMutation = useUpdateTaskTemplate();
//   const deleteTaskTemplateMutation = useDeleteTaskTemplate();

//   const createTaskTemplate = () => {
//     if (state.parentTaskTemplate) {
//       if (
//         state.parentTaskTemplate?._id &&
//         state.parentTaskTemplate.journeyStageTemplate?._id
//       ) {
//         let taskTemplate: TaskTemplateData = {
//           title: state.title,
//           description: state.description,
//           relativeDueDate: state.parentTaskTemplate.relativeDueDate,
//           instanceId: app.currentUser.customData.instanceId,
//           journeyStageTemplate: state.parentTaskTemplate.journeyStageTemplate,
//           tasker: state.tasker,
//           dependentOn: state.parentTaskTemplate.dependentOn,
//           createdAt: new Date(),
//           journeyTemplateId: new ObjectID(journeyTemplateId),
//           tags: [],
//           isVisible: state.parentTaskTemplate.isVisible,
//           knowledgeResourceTemplateIds: [],
//           parentTaskTemplateId: state.parentTaskTemplate._id,
//         };

//         addTaskTemplateMutation.mutate({
//           taskTemplate: taskTemplate,
//           stageTemplateId: state.parentTaskTemplate.journeyStageTemplate._id,
//         });
//       }
//     } else {
//       if (!state.journeyStageTemplate?._id) {
//         return;
//       }
//       let taskTemplate: TaskTemplateData = {
//         title: state.title,
//         description: state.description,
//         relativeDueDate: state.relativeDueDate,
//         instanceId: app.currentUser.customData.instanceId,
//         journeyStageTemplate: state.journeyStageTemplate,
//         tasker: state.tasker,
//         dependentOn: state.dependentOn,
//         createdAt: new Date(),
//         journeyTemplateId: new ObjectID(journeyTemplateId),
//         tags: state.tags,
//         isVisible: state.isVisible,
//         knowledgeResourceTemplateIds: state.krts.map((krt) => krt._id),
//       };

//       addTaskTemplateMutation.mutate({
//         taskTemplate: taskTemplate,
//         stageTemplateId: state.journeyStageTemplate._id,
//       });
//     }
//   };

//   const updateTaskTemplate = () => {
//     if (
//       !state.taskTemplate?._id ||
//       !state.taskTemplate.journeyStageTemplate?._id
//     ) {
//       return;
//     }
//     let isTaskTemplateUpdated = false;
//     let update: Partial<TaskTemplateData> = { _id: state.taskTemplate._id };
//     // check if any of the fields have changed
//     if (state.title !== state.taskTemplate.title) {
//       update.title = state.title;
//       isTaskTemplateUpdated = true;
//     }
//     if (state.description !== state.taskTemplate.description) {
//       update.description = state.description;
//       isTaskTemplateUpdated = true;
//     }
//     if (state.isVisible !== state.taskTemplate.isVisible) {
//       update.isVisible = state.isVisible;
//       isTaskTemplateUpdated = true;
//     }
//     if (
//       state.journeyStageTemplate?._id &&
//       state.journeyStageTemplate?._id.toString() !==
//         state.taskTemplate.journeyStageTemplate._id.toString()
//     ) {
//       update.journeyStageTemplate = state.journeyStageTemplate;
//       isTaskTemplateUpdated = true;
//     }
//     if (
//       state.tasker?.persona !== state.taskTemplate.tasker?.persona ||
//       state.tasker?.type !== state.taskTemplate.tasker?.type
//     ) {
//       update.tasker = state.tasker;
//       isTaskTemplateUpdated = true;
//     }
//     if (state.tags !== state.taskTemplate.tags) {
//       update.tags = state.tags;
//       isTaskTemplateUpdated = true;
//     }
//     if (
//       state.relativeDueDate.unit !== state.taskTemplate.relativeDueDate.unit ||
//       state.relativeDueDate.value !== state.taskTemplate.relativeDueDate.value
//     ) {
//       update.relativeDueDate = state.relativeDueDate;
//       isTaskTemplateUpdated = true;
//     }
//     if (
//       state.dependentOn?.type !== state.taskTemplate.dependentOn?.type ||
//       (state.dependentOn?._id &&
//         state.dependentOn._id.toString() !==
//           state.taskTemplate.dependentOn?._id?.toString())
//     ) {
//       update.dependentOn = state.dependentOn;
//       isTaskTemplateUpdated = true;
//     }

//     if (state.options.hasLinkedKnowledgeResourceTemplate && state.krts.length) {
//       // if there were no krt's linked to the task template before
//       if (!state.taskTemplate.knowledgeResourceTemplates?.length) {
//         update.knowledgeResourceTemplateIds = state.krts.map((krt) => krt._id);
//         isTaskTemplateUpdated = true;
//       } else {
//         // if there were krt's linked to the task template before
//         // need to check if the krts have changed
//         if (
//           state.taskTemplate.knowledgeResourceTemplates?.length &&
//           state.krts[0]._id.toString() !==
//             state.taskTemplate.knowledgeResourceTemplates[0]._id.toString()
//         ) {
//           update.knowledgeResourceTemplateIds = [state.krts[0]._id];
//           isTaskTemplateUpdated = true;
//         }
//       }
//     } else if (
//       !state.options.hasLinkedKnowledgeResourceTemplate &&
//       state.taskTemplate.knowledgeResourceTemplates?.length
//     ) {
//       update.knowledgeResourceTemplateIds = [];
//       isTaskTemplateUpdated = true;
//     }

//     console.log(isTaskTemplateUpdated);
//     if (isTaskTemplateUpdated) {
//       console.log("updating task template");
//       console.log(update);
//       updateTaskTemplateMutation.mutate({
//         params: update,
//         journeyTemplateId: state.taskTemplate.journeyTemplateId,
//         previousJourneyStageId: state.taskTemplate.journeyStageTemplate._id,
//       });
//     }
//   };

//   const deleteTaskTemplate = () => {
//     if (
//       !state.taskTemplate?._id ||
//       !state.taskTemplate.journeyStageTemplate?._id
//     ) {
//       return;
//     }
//     deleteTaskTemplateMutation.mutate({
//       params: { _id: state.taskTemplate._id },
//       journeyStageTemplateId: state.taskTemplate.journeyStageTemplate._id,
//       journeyTemplateId: state.taskTemplate.journeyTemplateId,
//     });
//   };

//   const value = {
//     state,
//     dispatch,
//     createTaskTemplate,
//     updateTaskTemplate,
//     deleteTaskTemplate,
//   };

//   return (
//     <TaskTemplateContext.Provider value={value}>
//       {children}
//     </TaskTemplateContext.Provider>
//   );
// };

// export const useTaskTemplate = () => {
//   const context = useContext(TaskTemplateContext);
//   if (context === undefined) {
//     throw new Error(
//       "useTaskTemplate must be used within a TaskTemplateProvider"
//     );
//   }
//   return context;
// };
