import { HashtagIcon } from "@heroicons/react/24/outline";
import { CaretSortIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useGetSlackChannels,
  useGetSlackIntegrationStatus,
} from "src/api/Integrations/slack";
import {
  useGetPhase,
  useGetPhaseNotificationSettings,
  useUpdatePhaseNotificationSettings,
} from "src/api/Services/SharedSpace/phases";
import { useGetSpaces } from "src/api/Spaces/spaces";
import { Button } from "src/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "src/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import { Switch } from "src/components/ui/switch";
import { SpaceNameAndSymbol } from "src/pages/spaces/spaces";

type Props = {};

export default function PhaseNotifications({}: Props) {
  const { phaseId } = useParams();
  const { data: phase } = useGetPhase(String(phaseId));
  const { data: spaces } = useGetSpaces();
  const space = spaces?.find((s) => s._id === phase?.spaceId);

  return (
    <div className="space-y-8">
      <div className="h-12 items-center justify-between space-y-2 border-b border-gray-200/[.65] px-8 sm:flex sm:space-y-0">
        {/* {!!phase ? (
            <PhaseNavigation phase={phase} />
          ) : (
            <div className="h-8 w-40 animate-pulse rounded bg-gray-200" />
          )} */}
        <div className="flex items-center space-x-2 text-sm">
          {space && (
            <SpaceNameAndSymbol color={space.color} name={space.name} />
          )}
          <ChevronRightIcon className="h-3 w-3 text-gray-500" />
          <Link
            className="text-gray-800"
            to={`/spaces/${phase?.spaceId}/projects`}
          >
            Projects
          </Link>
          <ChevronRightIcon className="h-3 w-3 text-gray-500" />
          <Link
            className="text-gray-800"
            to={`/projects/${phase?._id.toString()}`}
          >
            {phase?.name ?? ""}
          </Link>
          <ChevronRightIcon className="h-3 w-3 text-gray-500" />
          <div className="cursor-default text-gray-800">{"Alerts"}</div>
        </div>
      </div>

      <div className="mx-auto w-full max-w-2xl space-y-8 divide-gray-300 px-8 pt-14">
        <div className="space-y-3">
          <div>
            <label className="w-40 shrink-0 text-lg font-medium text-gray-800">
              Email
            </label>
            <div className="text-sm font-light text-gray-600">
              Keep external users up to date with the project via email
              notifications
            </div>
          </div>
          <EmailNotifications />
        </div>
        <div className="pt-4">
          <div className="space-y-3">
            <div>
              <div className="flex items-center space-x-1.5">
                <svg
                  width="18px"
                  height="18px"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                >
                  <g fill-rule="evenodd" clip-rule="evenodd">
                    <path
                      fill="#E01E5A"
                      d="M2.471 11.318a1.474 1.474 0 001.47-1.471v-1.47h-1.47A1.474 1.474 0 001 9.846c.001.811.659 1.469 1.47 1.47zm3.682-2.942a1.474 1.474 0 00-1.47 1.471v3.683c.002.811.66 1.468 1.47 1.47a1.474 1.474 0 001.47-1.47V9.846a1.474 1.474 0 00-1.47-1.47z"
                    />
                    <path
                      fill="#36C5F0"
                      d="M4.683 2.471c.001.811.659 1.469 1.47 1.47h1.47v-1.47A1.474 1.474 0 006.154 1a1.474 1.474 0 00-1.47 1.47zm2.94 3.682a1.474 1.474 0 00-1.47-1.47H2.47A1.474 1.474 0 001 6.153c.002.812.66 1.469 1.47 1.47h3.684a1.474 1.474 0 001.47-1.47z"
                    />
                    <path
                      fill="#2EB67D"
                      d="M9.847 7.624a1.474 1.474 0 001.47-1.47V2.47A1.474 1.474 0 009.848 1a1.474 1.474 0 00-1.47 1.47v3.684c.002.81.659 1.468 1.47 1.47zm3.682-2.941a1.474 1.474 0 00-1.47 1.47v1.47h1.47A1.474 1.474 0 0015 6.154a1.474 1.474 0 00-1.47-1.47z"
                    />
                    <path
                      fill="#ECB22E"
                      d="M8.377 9.847c.002.811.659 1.469 1.47 1.47h3.683A1.474 1.474 0 0015 9.848a1.474 1.474 0 00-1.47-1.47H9.847a1.474 1.474 0 00-1.47 1.47zm2.94 3.682a1.474 1.474 0 00-1.47-1.47h-1.47v1.47c.002.812.659 1.469 1.47 1.47a1.474 1.474 0 001.47-1.47z"
                    />
                  </g>
                </svg>
                <span className="text-lg font-medium text-gray-800">Slack</span>
              </div>
              <div className="text-sm font-light text-gray-600">
                Keep internal users up to date with the project via Slack
                notifications
              </div>
            </div>
            <SlackNotifications />
          </div>
        </div>
      </div>
    </div>
  );
}

function SlackNotifications() {
  const { phaseId } = useParams();
  const { data: slackIntegrationStatus } = useGetSlackIntegrationStatus();
  const { data: channels } = useGetSlackChannels();
  const [channelSearch, setChannelSearch] = useState("");
  const [open, setOpen] = useState(false);
  const updateNotificationSettingsMutation =
    useUpdatePhaseNotificationSettings();
  const { data: notificationSettings } = useGetPhaseNotificationSettings(
    String(phaseId)
  );
  const navigate = useNavigate();

  return (
    <div className="rounded-md border p-6 shadow-sm">
      {!!slackIntegrationStatus && (
        <>
          {slackIntegrationStatus?.workspace ? (
            <div className="space-y-6">
              <div className="space-y-4">
                <div className="space-y-0.5">
                  <div className="font-medium text-gray-800">Internal Channel</div>
                  <div className="max-w-prose text-sm text-gray-600 font-light">
                    Send key project updates such as new chats being created,
                    external users completing tasks and more to a dedicated
                    Slack channel.
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <Popover open={open} onOpenChange={setOpen}>
                    <PopoverTrigger asChild>
                      <Button
                        variant="secondary"
                        size="lg"
                        role="combobox"
                        aria-expanded={open}
                        className="w-72 justify-between space-x-2 truncate"
                      >
                        <div className="flex items-center space-x-2">
                          <HashtagIcon className="h-3.5 w-3.5 shrink-0" />
                          {!notificationSettings?.slackNotification.channel ? (
                            <div className="font-normal text-gray-300">
                              Select channel
                            </div>
                          ) : (
                            <div className="truncate">
                              {
                                channels?.find(
                                  (c) =>
                                    c.id ===
                                    notificationSettings?.slackNotification
                                      .channel
                                )?.name
                              }
                            </div>
                          )}
                        </div>
                        <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent
                      className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
                      align="start"
                    >
                      <Command>
                        <CommandInput
                          value={channelSearch}
                          onValueChange={(s) => {
                            setChannelSearch(s);
                          }}
                          placeholder="Search channel..."
                          className="h-9"
                        />
                        <CommandEmpty>No channel found.</CommandEmpty>
                        <CommandGroup>
                          {channels?.map((channel) => (
                            <CommandItem
                              key={channel.id}
                              value={channel.name}
                              onSelect={() => {
                                if (!notificationSettings?._id) return;
                                updateNotificationSettingsMutation.mutate({
                                  metadata: {
                                    phaseId: String(phaseId),
                                  },
                                  update: {
                                    _id: notificationSettings?._id,
                                    slackNotificationChannel: channel.id,
                                    sendSlackNotifications: true,
                                  },
                                });
                                setOpen(false);
                              }}
                              className="gap-2"
                            >
                              <div className="flex items-center space-x-1">
                                <HashtagIcon className="h-3.5 w-3.5 shrink-0" />
                                <span className="truncate">{channel.name}</span>
                              </div>
                            </CommandItem>
                          ))}
                          {/* {isLoading && <div>Loading..</div>} */}
                        </CommandGroup>
                      </Command>
                    </PopoverContent>
                  </Popover>
                  {!!notificationSettings?.slackNotification.channel && (
                    <Button
                      variant={"secondaryLink"}
                      className="px-0"
                      onClick={() => {
                        if (!notificationSettings?._id) return;
                        updateNotificationSettingsMutation.mutate({
                          metadata: {
                            phaseId: String(phaseId),
                          },
                          update: {
                            _id: notificationSettings?._id,
                            slackNotificationChannel: null,
                          },
                        });
                      }}
                    >
                      Remove channel
                    </Button>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="text-sm text-gray-500">
              Your Slack has not been integrated. Click{" "}
              <span
                onClick={() => {
                  navigate("/settings/integrations");
                }}
                className="underline hover:text-gray-700"
              >
                here
              </span>{" "}
              to go to the integrations page.
            </div>
          )}
        </>
      )}
    </div>
  );
}

function EmailNotifications() {
  const { phaseId } = useParams();
  const { data: notificationSettings } = useGetPhaseNotificationSettings(
    String(phaseId)
  );
  const updateNotificationSettingsMutation =
    useUpdatePhaseNotificationSettings();

  return (
    <>
      {!!notificationSettings && (
        <div className="space-y-6 rounded-md border p-6 shadow-sm">
          <div className="flex items-center justify-between gap-10">
            <div className="space-y-0.5">
              <div className="font-medium text-gray-800">Task Overdue</div>
              <div className="max-w-prose text-sm font-light text-gray-600">
                Send an email to the tasker when a task goes overdue
              </div>
            </div>
            <div>
              <Switch
                checked={notificationSettings.overdueEmail}
                onCheckedChange={(checked) => {
                  updateNotificationSettingsMutation.mutate({
                    update: {
                      overdueEmail: checked,
                      _id: notificationSettings._id,
                    },
                    metadata: {
                      phaseId: String(phaseId),
                    },
                  });
                }}
              />
            </div>
          </div>
          <div className="flex items-center justify-between gap-10">
            <div className="space-y-0.5">
              <div className="font-medium text-gray-800">Task Activation</div>
              <div className="max-w-prose text-sm font-light text-gray-600">
                Send an email to the tasker when a new task is activated for
                them.
              </div>
            </div>
            <div>
              <Switch
                checked={notificationSettings.dependentTaskActivationEmail}
                onCheckedChange={(checked) => {
                  updateNotificationSettingsMutation.mutate({
                    update: {
                      dependentTaskActivationEmail: checked,
                      _id: notificationSettings._id,
                    },
                    metadata: {
                      phaseId: String(phaseId),
                    },
                  });
                }}
              />
              {/* <VariantToggle
                focus={false}
                setEnabled={(value) => {
                  updateNotificationSettingsMutation.mutate({
                    update: {
                      dependentTaskActivationEmail: value,
                      _id: notificationSettings._id,
                    },
                    metadata: {
                      phaseId: String(phaseId),
                    },
                  });
                }}
                enabled={notificationSettings.dependentTaskActivationEmail}
                size="sm"
              /> */}
            </div>
          </div>
          <div className="flex items-center justify-between gap-10">
            <div className="space-y-0.5">
              <div className="font-medium text-gray-800">Weekly Update</div>
              <div className="max-w-prose text-sm font-light text-gray-600">
                Send an email to all external members every Monday that includes
                the past week's achievements and the upcoming week's goals.
              </div>
            </div>
            <div>
              <Switch
                checked={notificationSettings.weeklyUpdateEmail}
                onCheckedChange={(checked) => {
                  updateNotificationSettingsMutation.mutate({
                    update: {
                      weeklyUpdateEmail: checked,
                      _id: notificationSettings._id,
                    },
                    metadata: {
                      phaseId: String(phaseId),
                    },
                  });
                }}
              />
              {/* <VariantToggle
                focus={false}
                setEnabled={(value) => {
                  updateNotificationSettingsMutation.mutate({
                    update: {
                      weeklyUpdateEmail: value,
                      _id: notificationSettings._id,
                    },
                    metadata: {
                      phaseId: String(phaseId),
                    },
                  });
                }}
                enabled={notificationSettings.weeklyUpdateEmail}
                size="sm"
              /> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
