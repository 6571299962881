import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { EditTaskTemplate } from "./EditTaskTemplate";
import { useTaskTemplatePanelContext } from "./TaskTemplatePanelContextProvider";

export default function TaskTemplatePanel() {
  const taskTemplatePanelCtx = useTaskTemplatePanelContext();

  return (
    <Transition.Root
      show={taskTemplatePanelCtx.state.taskTemplatePanel.isOpen}
      as={Fragment}
    >
      <Dialog
        // as="div"
        className="relative z-30 h-screen w-[800px]"
        onClose={() => {
          taskTemplatePanelCtx.dispatch({
            type: "TASK_TEMPLATE_PANEL_UPDATE",
            taskTemplatePanel: {
              id: taskTemplatePanelCtx.state.taskTemplatePanel.id,
              isOpen: false,
            },
          });
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 h-full overflow-hidden">
          <div className="absolute inset-0 h-full overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex h-full max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto h-full w-screen max-w-3xl">
                  <div className="flex h-full flex-col overflow-y-auto bg-white shadow-xl">
                    {!!taskTemplatePanelCtx.state.taskTemplatePanel.id ? (
                      <TaskTemplateEdit />
                    ) : (
                      <NoTaskTemplate />
                    )}
                    {/* <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      <div></div>
                    </div> */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function NoTaskTemplate() {
  const taskTemplatePanelCtx = useTaskTemplatePanelContext();
  return (
    <>
      <div className="flex items-start justify-end">
        {/* <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
        Panel title
      </Dialog.Title> */}
        <div className="ml-3 flex h-7 items-center">
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() =>
              taskTemplatePanelCtx.dispatch({
                type: "TASK_TEMPLATE_PANEL_UPDATE",
                taskTemplatePanel: {
                  id: taskTemplatePanelCtx.state.taskTemplatePanel.id,
                  isOpen: false,
                },
              })
            }
          >
            <span className="sr-only">Close panel</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="text-gray-500">Error loading task template</div>
      </div>
    </>
  );
}

function TaskTemplateEdit() {
  const taskTemplatePanelCtx = useTaskTemplatePanelContext();
  return (
    <>
      {/* Task Edit */}
      {taskTemplatePanelCtx.state.taskTemplatePanel.id && (
        <EditTaskTemplate
          taskTemplateId={taskTemplatePanelCtx.state.taskTemplatePanel.id}
        />
      )}
      <div></div>
    </>
  );
}
