import { Transition } from "@headlessui/react";
import { ArrowLongLeftIcon } from "@heroicons/react/24/outline";
import { ObjectId, ObjectID } from "bson";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useDeleteStageTemplate,
  useGetJourneyTemplate,
  useUpdateJourneyTemplate,
} from "src/api/Services/Tasks/journeys";
import { Badge } from "src/components/ui/Badges/Badge";
import { Button } from "src/components/ui/Buttons/Button";
import TextInput from "src/components/ui/Inputs/Text/TextInput";
import Select from "src/components/ui/Selects/Select";

type PersonaType = "Internal" | "External";
const personaOptions: PersonaType[] = ["Internal", "External"];

export const TemplatePersonasEdit = () => {
  const { journeyTemplateId } = useParams();
  const { journeyTemplate } = useGetJourneyTemplate(String(journeyTemplateId));
  const updateJourneyTemplateMutation = useUpdateJourneyTemplate();
  const deleteStageTemplateMutation = useDeleteStageTemplate();
  const [inAddMode, setInAddMode] = useState(false);
  const [persona, setPersona] = useState("");
  const [personaType, setPersonaType] = useState<PersonaType>("Internal");

  const addPersona = () => {
    const transformedPersona = persona.trim();

    if (transformedPersona !== "" && journeyTemplate?._id) {
      let update: {
        params: {
          _id: ObjectID;
          internalPersonas?: string[];
          externalPersonas?: string[];
        };
        journeyTemplateId: ObjectId;
      } = {
        params: {
          _id: journeyTemplate._id,
        },
        journeyTemplateId: journeyTemplate?._id,
      };

      if (personaType === "Internal") {
        // make sure not duplicate
        if (journeyTemplate.internalPersonas.includes(transformedPersona)) {
          // set error text
        } else {
          // add persona
          update.params.internalPersonas = [
            ...journeyTemplate?.internalPersonas,
            transformedPersona,
          ];
          updateJourneyTemplateMutation.mutate(update);
          setPersona("");
        }
      } else {
        // make sure not duplicate
        if (journeyTemplate.externalPersonas.includes(transformedPersona)) {
          // set error text
        } else {
          // add persona
          update.params.externalPersonas = [
            ...journeyTemplate?.externalPersonas,
            transformedPersona,
          ];
          updateJourneyTemplateMutation.mutate(update);
          setPersona("");
        }
      }
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex min-h-[40px] items-start justify-between">
        <div className="space-y-2">
          <p className="text-sm font-medium text-gray-600">Internal</p>
          {journeyTemplate?.internalPersonas.length === 0 ? (
            <p className="text-sm text-gray-400">No roles added</p>
          ) : (
            <div className="flex flex-wrap gap-2">
              {journeyTemplate?.internalPersonas.map((p) => (
                <Badge text={p} key={p} color="gray" size={"lg"} />
              ))}
            </div>
          )}

          {journeyTemplate?.type === "external" && (
            <div className="space-y-2 pt-4">
              <p className="text-sm font-medium text-gray-600">External</p>
              {journeyTemplate?.externalPersonas.length === 0 ? (
                <p className="text-sm text-gray-400">No roles added</p>
              ) : (
                <div className="flex flex-wrap gap-2">
                  {journeyTemplate.externalPersonas.map((p) => (
                    <Badge text={p} key={p} color="gray" size={"lg"} />
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="ml-10 flex w-[60px] justify-end">
          <Transition
            show={inAddMode === false}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Button
              size="xs"
              fill="outline"
              text="Add"
              onClick={() => {
                setInAddMode(true);
              }}
              // disabled={inAddMode}
            />
          </Transition>
        </div>
      </div>
      {/* Stages */}
      <div className="h-[70px] space-y-2 pt-4">
        <Transition
          show={inAddMode === true}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {!!journeyTemplate && (
            <div className="flex space-x-2">
              <div>
                <TextInput
                  name="role"
                  placeholder={
                    personaType === "Internal"
                      ? "CSM, Engineer, Support"
                      : "Champion, Decision Maker"
                  }
                  onChange={(e) => {
                    setPersona(e.target.value);
                  }}
                  value={persona}
                  rightIconDisabled={persona.trim() === ""}
                  RightIcon={ArrowLongLeftIcon}
                  rightIconOnClick={addPersona}
                  rightIconTooltip="Click or press Enter to add"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      addPersona();
                    }
                  }}
                />
              </div>
              {journeyTemplate.type === "external" && (
                <div className="w-[120px]">
                  <Select
                    options={personaOptions}
                    onChange={(value) => {
                      setPersonaType(value);
                    }}
                    displayFn={(o) => o}
                    keyFn={(o) => o}
                    selected={personaType}
                  />
                </div>
              )}
            </div>
          )}
        </Transition>
      </div>
    </div>
  );
};
