import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import Logo from "../../components/Authentication/Logo";
import { useRealmApp } from "../../store/RealmApp";

type Props = {};

interface CustomizedState {
  email: string;
}

const Verification = (props: Props) => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const location = useLocation();
  const state = location.state as CustomizedState; // Type Casting, then you can get the params passed via router
  const { email } = state;

  const resendEmail = async () => {
    const emailDetails = {
      email: email,
    };
    try {
      await app.emailPasswordAuth.retryCustomConfirmation(emailDetails);
      snackbarCtx.showSnackbar("Verification email sent", "success");
    } catch (err) {
      // console.log(err);
    }
  };

  return (
    <Container maxWidth="sm">
      <Grid container alignItems="center" justifyContent={"center"}>
        <Grid item xs={12} marginTop={20}>
          <Stack spacing={3} alignItems="center">
            <Logo height={70} />
            <Typography>Check your email for a verification link.</Typography>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={resendEmail}
            >
              Resend email
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Verification;
