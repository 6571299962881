import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useGetJourneyTemplates } from "src/api/Services/Tasks/journeys";
import { useGetSpace } from "src/api/Spaces/spaces";
import { Loading } from "src/components/ui/loading";
import { TextInput } from "src/components/ui/text-input";
import { SpaceNameAndSymbol } from "src/pages/spaces/spaces";
import { NewTemplateButton } from "../../Services/Journeys/Templates/NewTemplate/NewTemplateButton";
import { JourneyTemplatesTable } from "../../Services/Journeys/Templates/TemplatesTable/JourneyTemplatesTable";

type Props = {};

const JourneyTemplates = (props: Props) => {
  const { journeyTemplates } = useGetJourneyTemplates();
  const { spaceId } = useParams();
  const { data: space } = useGetSpace(String(spaceId));
  const filteredJourneyTemplates =
    journeyTemplates?.filter((jt) => jt.spaceId?.toString() === spaceId) ?? [];
  const [search, setSearch] = useState("");

  return (
    <div className="flex h-full flex-col overflow-y-auto">
      <div className="flex h-12 shrink-0 items-center border-b border-gray-200/70 px-8">
        <div className="flex items-center space-x-2 text-sm">
          {space && (
            <SpaceNameAndSymbol name={space.name} color={space.color} />
          )}
          <ChevronRightIcon className="h-3 w-3 text-gray-500" />
          <Link to={`/spaces/${spaceId}/templates`}>
            <div className="text-gray-800">{"Templates"}</div>
          </Link>
          <ChevronRightIcon className="h-3 w-3 text-gray-500" />
          <div className="cursor-default text-gray-800">{"Projects"}</div>
        </div>
      </div>
      <div className="flex h-16 shrink-0 items-center justify-between border-b border-gray-200/70 px-8">
        <div className="relative">
          <TextInput
            className="h-8 w-72 pl-8"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search.."
            autoFocus
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-2.5">
            <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" />
          </div>
        </div>
        {!!journeyTemplates && filteredJourneyTemplates.length > 0 && (
          <NewTemplateButton />
        )}
      </div>
      <div className="flex-1">
        {!!journeyTemplates ? (
          <>
            {filteredJourneyTemplates.length > 0 ? (
              <JourneyTemplatesTable
                journeyTemplates={filteredJourneyTemplates}
                search={search}
                setSearch={setSearch}
              />
            ) : (
              <div className="mx-auto mt-[10vh] w-fit">
                <div className="flex">
                  <div className="h-20 w-20"></div>
                  <div className="h-20 w-[420px] bg-gradient-to-t from-gray-200 to-transparent px-[1px]">
                    <div className="h-full w-full bg-white" />
                  </div>
                  <div className="h-20 w-20"></div>
                </div>
                <div className="flex">
                  <div className="w-20 bg-gradient-to-l from-gray-200 to-white py-[1px]">
                    <div className="h-full w-full bg-white" />
                  </div>
                  <div className="w-[420px] border bg-gray-50/70 p-12">
                    <div className="text-xl font-medium">Project Templates</div>
                    <div className="pt-2.5 font-light text-gray-700">
                      Templatize projects that run often so that you and your
                      team don't have to start from scratch every time.
                    </div>
                    <div className="pt-6">
                      <NewTemplateButton />
                    </div>
                  </div>
                  <div className="w-20 bg-gradient-to-r from-gray-200 to-white py-[1px]">
                    <div className="h-full w-full bg-white" />
                  </div>
                </div>
                <div className="flex">
                  <div className="h-20 w-20"></div>
                  <div className="h-20 w-[420px] bg-gradient-to-b from-gray-200 to-white px-[1px]">
                    <div className="h-full w-full bg-white" />
                  </div>
                  <div className="h-20 w-20"></div>
                </div>
              </div>
            )}
          </>
        ) : (
          <Loading className="mt-[20vh]" />
        )}
      </div>
    </div>
  );
};

export default JourneyTemplates;
