import {
  AdjustmentsHorizontalIcon,
  FunnelIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ParentStatus,
  StatusData,
  StatusGroupData,
  useGetStatusGroups,
} from "src/api/General/status-groups";
import {
  GroupDetails,
  NewTaskFilter,
  NewTasksFilter,
  ReducedTaskData,
  TaskGroup,
  TaskGroupData,
  useGetTasks,
  useGetTasksForGroup,
} from "src/api/Services/Tasks/tasks";
import { Button } from "src/components/ui/button";
import { Checkbox } from "src/components/ui/checkbox";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "src/components/ui/command";
import { Count } from "src/components/ui/count";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import { Loading } from "src/components/ui/loading";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { useNewTaskForInternalUser } from "src/pages/Services/Tasks/NewTaskForInternalUser";
import { getStatusSymbol } from "src/pages/Services/Tasks/Task";
import { Sort, TasksTable } from "src/pages/Services/Tasks/Tasks";
import TasksContextProvider from "src/pages/Services/Tasks/tasks-context-provider";
import { useRealmApp } from "src/store/RealmApp";
import {
  IdComparator,
  Logic,
  TaskStatusFilter,
  TaskStatusFilterModel,
  TaskerFilter,
  TaskerFilterModel,
  getMyTasksFilterKey,
} from "src/utils/filters";

type Props = {};

export default function MyTasks({}: Props) {
  const [group, setGroup] = useState<TaskGroup>(() => {
    const savedGroup = localStorage.getItem(`group_my_tasks`);
    if (savedGroup && (savedGroup === "project" || savedGroup === "status")) {
      return savedGroup;
    } else {
      return "status";
    }
    return "status";
  });

  useEffect(() => {
    localStorage.setItem(`group_my_tasks`, group);
  }, [group]);

  const [sort, setSort] = useState<Sort>({
    property: "dueDate",
    order: "asc",
  });

  // const [filter, setFilter] = useState<TasksFilter>({
  //   taskerType: "internal",
  //   spaceIds: [String(spaceId)],
  // });
  const { data: statusGroups } = useGetStatusGroups();
  const statuses = getTranformedCombinedStatuses(statusGroups);
  // const statusGroup = statusGroups?.find((s) => s.spaceId === spaceId);
  const app = useRealmApp();
  const [baselineFilter, setBaselineFilter] = useState<NewTasksFilter>({
    logic: "AND",
    children: [
      {
        field: "tasker",
        comparator: "in",
        value: [String(app.currentUser?.customData?._id)],
      },
      {
        field: "inactive-todo-tasks",
        comparator: "eq",
        value: false,
      },
    ],
  });

  const [filterGroup, setFilterGroup] = useState<NewTasksFilter>(() => {
    const savedFilterGroup = localStorage.getItem(getMyTasksFilterKey());
    if (savedFilterGroup) {
      return JSON.parse(savedFilterGroup);
    }
    const filter: NewTasksFilter = {
      logic: "AND",
      children: [],
    };
    return filter;
  });

  useEffect(() => {
    localStorage.setItem(getMyTasksFilterKey(), JSON.stringify(filterGroup));
  }, [filterGroup]);

  const { data: taskGroups } = useGetTasks({
    group: group,
    sort: sort,
    filter: clean(filterGroup),
    baselineFilter,
  });

  const taskExistsAfterFilter = taskGroups?.groups.find(
    (tg) => tg.tasks.length > 0
  );

  return (
    <TasksContextProvider>
      <div className="flex h-full flex-col overflow-y-auto">
        <div className="flex h-12 items-center border-b border-gray-200/70 px-8">
          <div className="flex select-none items-center space-x-2 text-sm">
            My Tasks
          </div>
        </div>
        <div className="flex h-16 items-center justify-between border-b border-gray-200/70 px-8">
          <div className="flex items-center space-x-2">
            <Filter
              filterGroup={filterGroup}
              setFilterGroup={setFilterGroup}
              isLoading={false}
              // localStorageKey="project-filter"
              filterModels={[
                // {
                //   field: "tasker",
                //   taskers:
                //     spaceMemberships?.map((sm) => ({
                //       name: sm.user.name,
                //       _id: sm.user._id,
                //     })) ?? [],
                // },
                {
                  field: "task-status",
                  statuses: statuses ?? [],
                },
              ]}
            />
            <DisplayOptions
              group={group}
              setGroup={setGroup}
              sort={sort}
              setSort={setSort}
            />
          </div>

          <NewTaskButton />
        </div>
        <div className="flex flex-1 flex-col overflow-y-auto">
          {!!taskGroups ? (
            <>
              {!!taskGroups.totalNoOfTasks ? (
                <>
                  {taskExistsAfterFilter ? (
                    <GeneralTasksList
                      taskGroups={taskGroups.groups}
                      filter={filterGroup}
                      baselineFilter={baselineFilter}
                      sort={{
                        property: "dueDate",
                        order: "asc",
                      }}
                      display={{
                        showingSpaceSlug: true,
                      }}
                      grouping={group}
                      // onClick={(taskId) => {
                      //   navigate(`/tasks/${taskId}`);
                      // }}
                    />
                  ) : (
                    <div className="mx-auto mt-[10vh]">
                      No tasks meet the filter criteria
                    </div>
                  )}
                </>
              ) : (
                <div className="mx-auto mt-[10vh]">
                  <div className="flex">
                    <div className="h-20 w-20"></div>
                    <div className="h-20 w-[420px] bg-gradient-to-t from-gray-200 to-transparent px-[1px]">
                      <div className="h-full w-full bg-white" />
                    </div>
                    <div className="h-20 w-20"></div>
                  </div>
                  <div className="flex">
                    <div className="w-20 bg-gradient-to-l from-gray-200 to-white py-[1px]">
                      <div className="h-full w-full bg-white" />
                    </div>
                    <div className="w-[420px] border bg-gray-50/70 p-12">
                      <div className="text-xl font-medium">My Tasks</div>
                      <div className="pt-2.5 font-light text-gray-700">
                        All yours tasks across spaces will show up here.
                      </div>
                      <div className="pt-6">
                        <NewTaskButton />
                      </div>
                    </div>
                    <div className="w-20 bg-gradient-to-r from-gray-200 to-white py-[1px]">
                      <div className="h-full w-full bg-white" />
                    </div>
                  </div>
                  <div className="flex">
                    <div className="h-20 w-20"></div>
                    <div className="h-20 w-[420px] bg-gradient-to-b from-gray-200 to-white px-[1px]">
                      <div className="h-full w-full bg-white" />
                    </div>
                    <div className="h-20 w-20"></div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <Loading className="mt-[20vh]" />
          )}
        </div>
      </div>
    </TasksContextProvider>
  );
}

function getTranformedCombinedStatuses(
  statusGroups: StatusGroupData[] | undefined
): StatusData[] {
  let tempData: StatusData[] = [];
  if (!statusGroups) {
    return tempData;
  }
  for (let i = 0; i < statusGroups.length; i++) {
    const statusGroup = statusGroups[i];
    // check if the status is already added in the array
    statusGroup.statuses.forEach((status) => {
      // check if the status is already added in the array
      if (
        tempData.find(
          (s) =>
            s.name === status.name && s.parentStatus === status.parentStatus
        )
      ) {
        return;
      }
      tempData.push(status);
    });
  }
  return tempData;
}

function NewTaskButton() {
  const { spaceId } = useParams();
  const newTaskForInternalUserCtx = useNewTaskForInternalUser();
  return (
    <Button
      variant={"secondary"}
      onClick={() => {
        newTaskForInternalUserCtx.dispatch({
          type: "open_update",
          spaceId: spaceId,
        });
      }}
    >
      New Task
    </Button>
  );
}

function clean(filterGroup: NewTasksFilter) {
  return {
    logic: filterGroup.logic,
    children: filterGroup.children.filter((child) => {
      if (
        child.field === "tasker" ||
        child.field === "task-status" ||
        child.field === "company" ||
        child.field === "project" ||
        child.field === "space"
      ) {
        return !!child.value.length;
      }
      return true;
    }),
  };
}

type FilterModel = TaskStatusFilterModel;

function Filter({
  filterModels,
  filterGroup,
  setFilterGroup,
  isLoading,
}: {
  filterModels: FilterModel[];
  filterGroup: NewTasksFilter;
  setFilterGroup: (filterGroup: NewTasksFilter) => void;
  isLoading: boolean;
}) {
  const [open, setOpen] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const { spaceId } = useParams();

  useEffect(() => {
    // count the number of valid filters
    let count = 0;
    for (let i = 0; i < filterGroup.children.length; i++) {
      const child = filterGroup.children[i] as NewTaskFilter;
      if (child.field === "task-status") {
        if (child.value.length > 0) {
          count++;
        }
      } else if (child.field === "company") {
        if (child.value.length > 0) {
          count++;
        }
      }
    }
    setFilterCount(count);
  }, [filterGroup]);

  useEffect(() => {
    if (!open) {
      setFilterGroup(clean(filterGroup));
    }
  }, [open]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="secondary" disabled={isLoading}>
          <FunnelIcon className="mr-2 h-3.5 w-3.5 border-gray-300" />
          Filter
          {filterCount > 0 && (
            <div className="ml-2">
              <Count variant={"primaryLight"}>{filterCount}</Count>
            </div>
          )}
          {filterCount > 0 && (
            <button
              tabIndex={-1}
              className="ml-1.5 shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-600"
              onClick={(e) => {
                e.stopPropagation();
                setFilterGroup({
                  logic: "AND",
                  children: [],
                });
              }}
            >
              <XMarkIcon className="h-2.5 w-2.5 " />
            </button>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="z-40 w-full min-w-[480px] space-y-3 p-5"
      >
        <FilterGroup
          filterGroup={filterGroup}
          setFilterGroup={setFilterGroup}
          level={0}
          filterModels={filterModels}
        />
      </PopoverContent>
    </Popover>
  );
}

function FilterGroup({
  filterGroup,
  setFilterGroup,
  level,
  filterModels,
}: // phase,
{
  filterGroup: NewTasksFilter;
  setFilterGroup: (filterGroup: NewTasksFilter) => void;
  level: number;
  filterModels: FilterModel[];
}) {
  const handleLogicChange = (logic: Logic) => {
    setFilterGroup({ ...filterGroup, logic });
  };

  const addFilter = (field: NewTaskFilter["field"]) => {
    if (field === "task-status") {
      setFilterGroup({
        ...filterGroup,
        children: [
          ...filterGroup.children,
          {
            field: field,
            comparator: "in",
            value: [],
          },
        ],
      });
    } else if (field === "tasker") {
      setFilterGroup({
        ...filterGroup,
        children: [
          ...filterGroup.children,
          {
            field: field,
            comparator: "in",
            value: [],
          },
        ],
      });
    }
    // else if (field === "company") {
    //   setFilterGroup({
    //     ...filterGroup,
    //     children: [
    //       ...filterGroup.children,
    //       {
    //         field: field,
    //         comparator: "in",
    //         value: [],
    //       },
    //     ],
    //   });
    // }
  };

  const [open, setOpen] = useState(false);

  return (
    <div className={clsx(level > 0 && "rounded-md border p-4")}>
      <div>
        <div className="flex items-start space-x-3">
          <div className="space-y-0.5">
            <div className="inline-flex h-8 w-[52px] items-center justify-center text-sm text-gray-500">
              Where
            </div>
            {filterGroup.children.length > 1 && (
              <Select
                value={filterGroup.logic}
                onValueChange={(value) => {
                  handleLogicChange(value as Logic);
                }}
              >
                <SelectTrigger
                  className="w-[52px] justify-center bg-white"
                  showIcon={false}
                >
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="AND">AND</SelectItem>
                    <SelectItem value="OR">OR</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            )}
            {Array.from({ length: filterGroup.children.length - 3 }).map(
              (_, index) => (
                <div
                  key={index}
                  className="flex h-8 w-[52px] items-center justify-center text-sm text-gray-400"
                >
                  {filterGroup.logic}
                </div>
              )
            )}
          </div>
          <div className="space-y-0.5">
            {filterGroup.children.map((child, index) =>
              "field" in child ? (
                <>
                  {child.field === "task-status" && (
                    <TaskStatusField
                      filter={child}
                      index={index}
                      filterGroup={filterGroup}
                      setFilterGroup={setFilterGroup}
                      taskStatusFilterModel={
                        filterModels.find(
                          (f) => f.field === "task-status"
                        ) as TaskStatusFilterModel
                      }
                    />
                  )}
                  {/* {child.field === "tasker" && (
                    <TaskerField
                      filter={child}
                      index={index}
                      filterGroup={filterGroup}
                      setFilterGroup={setFilterGroup}
                      taskerFilterModel={
                        filterModels.find(
                          (f) => f.field === "tasker"
                        ) as TaskerFilterModel
                      }
                    />
                  )} */}
                  {/* {child.field === "company" && (
                    <CompanyField
                      filter={child}
                      index={index}
                      filterGroup={filterGroup}
                      setFilterGroup={setFilterGroup}
                    />
                  )} */}
                </>
              ) : (
                <></>
              )
            )}

            {filterGroup.children.length === 0 && (
              <div
                className={clsx(filterGroup.children.length > 0 && "pt-2.5")}
              >
                <DropdownMenu
                  open={open}
                  onOpenChange={(isOpen) => {
                    setOpen(isOpen);
                  }}
                >
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant={"secondary"}
                      className={clsx(
                        filterGroup.children.length === 0 &&
                          "font-normal text-gray-400/70"
                      )}
                    >
                      {filterGroup.children.length > 0
                        ? "Add Filter"
                        : "Select Filter"}
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="start">
                    <DropdownMenuGroup>
                      {filterModels.map((filterModel, index) => {
                        switch (filterModel.field) {
                          case "task-status":
                            if (
                              filterGroup.children.find(
                                (f) => f.field === "task-status"
                              )
                            ) {
                              return null;
                            }
                            return (
                              <DropdownMenuItem
                                onSelect={() => {
                                  addFilter("task-status");
                                  setOpen(false);
                                }}
                                disabled={
                                  !!filterGroup.children.find(
                                    (f) => f.field === "task-status"
                                  )
                                }
                              >
                                <div className="mr-2 h-3.5 w-3.5 rounded-full border-2 border-gray-400" />
                                <span>Status</span>
                              </DropdownMenuItem>
                            );
                          // case "tasker":
                          //   return (
                          //     <DropdownMenuItem
                          //       onSelect={() => {
                          //         addFilter("tasker");
                          //         setOpen(false);
                          //       }}
                          //     >
                          //       <UserIcon className="mr-2 h-3.5 w-3.5" />
                          //       <span>Tasker</span>
                          //     </DropdownMenuItem>
                          //   );
                          // case "company":
                          //   return (
                          //     <DropdownMenuItem
                          //       onSelect={() => {
                          //         addFilter("company");
                          //         setOpen(false);
                          //       }}
                          //     >
                          //       <BuildingOffice2Icon className="mr-2 h-3.5 w-3.5" />
                          //       Company
                          //     </DropdownMenuItem>
                          //   );

                          default:
                            return null;
                        }
                      })}
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function TaskerField({
  filter,
  index,
  filterGroup,
  setFilterGroup,
  taskerFilterModel,
}: // filterModels,
// filterModels,
{
  filter: TaskerFilter;
  index: number;
  filterGroup: NewTasksFilter;
  setFilterGroup: (filterGroup: NewTasksFilter) => void;
  // filterModels: FilterModel[];
  taskerFilterModel: TaskerFilterModel;
}) {
  // const [open, setOpen] = useState(!filter.field);
  const [valueOpen, setValueOpen] = useState(!filter.value?.length);
  const type = filter.field;

  const removeChild = (index: number) => {
    const newChildren = filterGroup.children.filter((_, i) => i !== index);
    setFilterGroup({ ...filterGroup, children: newChildren });
  };

  const handleComparatorChange = (comparator: IdComparator) => {
    let newChildren = [...filterGroup.children];
    let currentChild = newChildren[index] as TaskStatusFilter;
    currentChild.comparator = comparator;
    newChildren[index] = currentChild;
    setFilterGroup({ ...filterGroup, children: newChildren });
  };

  const handleValueChange = (value: string) => {
    setFilterGroup({
      ...filterGroup,
      children: filterGroup.children.map((child, i) => {
        if (i === index) {
          const taskerFilter = child as TaskerFilter;
          return {
            ...taskerFilter,
            value: taskerFilter.value.find((t) => t === value)
              ? [...taskerFilter.value.filter((t) => t !== value)]
              : [...taskerFilter.value, value],
          };
        }
        return child;
      }),
    });
  };

  return (
    <div className="flex items-center space-x-0 text-sm">
      <div className="flex h-8 w-32 items-center truncate rounded-l-md border px-3 text-sm">
        <UserIcon className="mr-1.5 h-3.5 w-3.5" />
        Tasker
      </div>
      <Select
        value={filter.comparator}
        onValueChange={(value) => {
          handleComparatorChange(value as IdComparator);
        }}
      >
        <SelectTrigger
          className="flex h-8 w-20 items-center truncate rounded-none border-y border-x-0 border-input bg-white px-3"
          showIcon={false}
        >
          {filter.comparator === "in"
            ? "is"
            : filter.comparator === "nin"
            ? "is not"
            : ""}
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="in">is</SelectItem>
            <SelectItem value="nin">is not</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>

      <Popover modal={true} open={valueOpen} onOpenChange={setValueOpen}>
        <PopoverTrigger className="flex h-8 w-40 items-center justify-start border-y border-l bg-white px-3 text-sm hover:bg-gray-50 focus:outline-none">
          {!!filter.value && filter.value.length > 0 ? (
            <div className="truncate">
              {filter.value.length > 1 ? (
                <div className="flex items-center space-x-0.5">
                  Multiple Taskers
                </div>
              ) : (
                taskerFilterModel.taskers.find((t) => t._id === filter.value[0])
                  ?.name
              )}
            </div>
          ) : (
            <div className="text-sm font-normal text-gray-300">
              Select Tasker
            </div>
          )}
        </PopoverTrigger>
        <PopoverContent
          className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
          align="start"
        >
          <Command>
            <CommandInput placeholder="Search tasker..." className="h-9" />
            <CommandEmpty>No tasker found.</CommandEmpty>
            <CommandGroup>
              {taskerFilterModel.taskers.map((tasker) => (
                <CommandItem
                  key={tasker._id}
                  value={tasker._id}
                  onSelect={() => {
                    handleValueChange(tasker._id);
                  }}
                  className="gap-2"
                >
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      checked={filter.value?.includes(tasker._id)}
                      // onCheckedChange={(checked) => {
                      //   if (checked) {
                      //     handleValueChange(tasker._id);
                      //   } else {
                      //     handleValueChange(null);
                      //   }
                      // }}
                    />
                    <span>{tasker.name}</span>
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>

      <div
        className="flex h-8 w-fit cursor-pointer items-center rounded-r-md border bg-white px-2 transition-colors hover:bg-gray-100"
        onClick={() => {
          removeChild(index);
        }}
      >
        <XMarkIcon className="h-2.5 w-2.5 shrink-0" />
      </div>
    </div>
  );
}

function TaskStatusField({
  filter,
  index,
  filterGroup,
  setFilterGroup,
  taskStatusFilterModel,
}: // filterModels,
{
  filter: TaskStatusFilter;
  index: number;
  filterGroup: NewTasksFilter;
  setFilterGroup: (filterGroup: NewTasksFilter) => void;
  // filterModels: FilterModel[];
  taskStatusFilterModel: TaskStatusFilterModel;
}) {
  // const [open, setOpen] = useState(!filter.field);
  const [valueOpen, setValueOpen] = useState(!filter.value?.length);
  const type = filter.field;

  const removeChild = (index: number) => {
    const newChildren = filterGroup.children.filter((_, i) => i !== index);
    setFilterGroup({ ...filterGroup, children: newChildren });
  };

  const handleComparatorChange = (comparator: IdComparator) => {
    let newChildren = [...filterGroup.children];
    let currentChild = newChildren[index] as TaskStatusFilter;
    currentChild.comparator = comparator;
    newChildren[index] = currentChild;
    setFilterGroup({ ...filterGroup, children: newChildren });
  };

  const handleValueChange = (customStatus: string) => {
    const status = taskStatusFilterModel.statuses.find(
      (status) => status.name === customStatus
    );
    if (status) {
      setFilterGroup({
        ...filterGroup,
        children: filterGroup.children.map((child, i) => {
          if (i === index) {
            const statusFilter = child as TaskStatusFilter;
            return {
              ...statusFilter,
              value: statusFilter.value.find(
                (s) => s.customStatus === customStatus
              )
                ? [
                    ...statusFilter.value.filter(
                      (s) => s.customStatus !== customStatus
                    ),
                  ]
                : [
                    ...statusFilter.value,
                    {
                      status: status.parentStatus,
                      customStatus: customStatus,
                    },
                  ],
            };
          }
          return child;
        }),
      });
    }
  };

  return (
    <div className="flex items-center space-x-0 text-sm">
      <div className="flex h-8 w-32 items-center truncate rounded-l-md border px-3 text-sm">
        <div className="mr-1.5 h-3.5 w-3.5 rounded-full border-2 border-gray-400" />
        Status
      </div>
      <Select
        value={filter.comparator}
        onValueChange={(value) => {
          handleComparatorChange(value as IdComparator);
        }}
      >
        <SelectTrigger
          className="flex h-8 w-20 items-center truncate rounded-none border-y border-x-0 border-input bg-white px-3"
          showIcon={false}
        >
          {filter.comparator === "in"
            ? "is"
            : filter.comparator === "nin"
            ? "is not"
            : ""}
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="in">is</SelectItem>
            <SelectItem value="nin">is not</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>

      <Popover modal={true} open={valueOpen} onOpenChange={setValueOpen}>
        <PopoverTrigger className="flex h-8 w-40 items-center justify-start border-y border-l bg-white px-3 text-sm hover:bg-gray-50 focus:outline-none">
          {!!filter.value && filter.value.length > 0 ? (
            <div className="truncate">
              {filter.value.length > 1 ? (
                <div className="flex items-center space-x-0.5">
                  Multiple Statuses
                </div>
              ) : (
                filter.value[0].customStatus
              )}
            </div>
          ) : (
            <div className="text-sm font-normal text-gray-300">
              Select Status
            </div>
          )}
        </PopoverTrigger>
        <PopoverContent
          className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
          align="start"
        >
          <Command>
            <CommandInput placeholder="Search status..." className="h-9" />
            <CommandEmpty>No status found.</CommandEmpty>
            <CommandGroup>
              {taskStatusFilterModel.statuses
                .filter((s) => s.parentStatus === "Backlog")
                .map((status) => (
                  <StatusCommandItem
                    key={status.name}
                    parentStatus={"Backlog"}
                    customStatus={status.name}
                    isSelected={filter.value?.some(
                      (s) => s.customStatus === status.name
                    )}
                    onSelect={(customStatus) => {
                      handleValueChange(customStatus);
                    }}
                  />
                ))}
              {taskStatusFilterModel.statuses
                .filter((s) => s.parentStatus === "To-do")
                .map((status) => (
                  <StatusCommandItem
                    key={status.name}
                    parentStatus={"To-do"}
                    customStatus={status.name}
                    isSelected={filter.value?.some(
                      (s) => s.customStatus === status.name
                    )}
                    onSelect={(customStatus) => {
                      handleValueChange(customStatus);
                    }}
                  />
                ))}
              {taskStatusFilterModel.statuses
                .filter((s) => s.parentStatus === "In Progress")
                .map((status) => (
                  <StatusCommandItem
                    key={status.name}
                    parentStatus={"In Progress"}
                    customStatus={status.name}
                    isSelected={filter.value?.some(
                      (s) => s.customStatus === status.name
                    )}
                    onSelect={(customStatus) => {
                      handleValueChange(customStatus);
                    }}
                  />
                ))}
              {taskStatusFilterModel.statuses
                .filter((s) => s.parentStatus === "Stuck")
                .map((status) => (
                  <StatusCommandItem
                    key={status.name}
                    parentStatus={"Stuck"}
                    customStatus={status.name}
                    isSelected={filter.value?.some(
                      (s) => s.customStatus === status.name
                    )}
                    onSelect={(customStatus) => {
                      handleValueChange(customStatus);
                    }}
                  />
                ))}
              {taskStatusFilterModel.statuses
                .filter((s) => s.parentStatus === "Completed")
                .map((status) => (
                  <StatusCommandItem
                    key={status.name}
                    parentStatus={"Completed"}
                    customStatus={status.name}
                    isSelected={filter.value?.some(
                      (s) => s.customStatus === status.name
                    )}
                    onSelect={(customStatus) => {
                      handleValueChange(customStatus);
                    }}
                  />
                ))}
              {taskStatusFilterModel.statuses
                .filter((s) => s.parentStatus === "Canceled")
                .map((status) => (
                  <StatusCommandItem
                    key={status.name}
                    parentStatus={"Canceled"}
                    customStatus={status.name}
                    isSelected={filter.value?.some(
                      (s) => s.customStatus === status.name
                    )}
                    onSelect={(customStatus) => {
                      handleValueChange(customStatus);
                    }}
                  />
                ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>

      <div
        className="flex h-8 w-fit cursor-pointer items-center rounded-r-md border bg-white px-2 transition-colors hover:bg-gray-100"
        onClick={() => {
          removeChild(index);
        }}
      >
        <XMarkIcon className="h-2.5 w-2.5 shrink-0" />
      </div>
    </div>
  );
}

function StatusCommandItem({
  parentStatus,
  customStatus,
  isSelected,
  onSelect,
}: {
  parentStatus: ParentStatus;
  customStatus: string;
  isSelected: boolean;
  onSelect: (customStatus: string) => void;
}) {
  return (
    <CommandItem
      key={customStatus}
      onSelect={() => {
        onSelect(customStatus);
      }}
      className="gap-2"
    >
      <Checkbox checked={isSelected} />

      <div className="flex items-center space-x-2">
        {getStatusSymbol(parentStatus, false)}
        <span className="truncate">{customStatus}</span>
      </div>
    </CommandItem>
  );
}

function DisplayOptions({
  group,
  setGroup,
  setSort,
  sort,
}: {
  setSort: React.Dispatch<React.SetStateAction<Sort>>;
  sort: Sort;
  setGroup: React.Dispatch<React.SetStateAction<TaskGroup>>;
  group: TaskGroup;
}) {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="secondary"
          // size="sm"
          className={clsx(true && " border-gray-300/70")}
        >
          <AdjustmentsHorizontalIcon className="mr-1.5 h-3.5 w-4" />
          Display
        </Button>
      </PopoverTrigger>
      <PopoverContent align="start" className="p-0">
        <div className="divide-y">
          <div className="space-y-3 p-4">
            <div className="flex items-center justify-between">
              <div className="text-sm">Group by</div>
              <Select
                value={group}
                onValueChange={(value) => {
                  setGroup(value as TaskGroup);
                }}
              >
                <SelectTrigger className="h-7 w-32 px-2.5">
                  {group.charAt(0).toUpperCase() + group.slice(1)}
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="status">Status</SelectItem>
                    <SelectItem value="project">Project</SelectItem>
                    {/* <SelectItem value="tasker">Tasker</SelectItem> */}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="flex items-center justify-between">
              <div className="text-sm">Sort by</div>
              <Select
                value={sort.property}
                onValueChange={(value) => {
                  if (value === "lastCreatedAt") {
                    setSort({
                      property: "createdAt",
                      order: "desc",
                    });
                  } else if (value === "dueDate") {
                    setSort({
                      property: "dueDate",
                      order: "asc",
                    });
                  }
                }}
              >
                <SelectTrigger className="h-7 w-32 px-2.5">
                  {sort.property === "createdAt" ? "Last Created" : "Due Date"}
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="dueDate">Due Date</SelectItem>
                    <SelectItem value="lastCreatedAt">Last Created</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
          {/* <div className="space-y-3 p-4">
            <div className="text-sm">Properties</div>
            <div className="flex flex-wrap gap-2">
              <div
                className={clsx(
                  "flex h-6 w-fit cursor-pointer items-center rounded border px-2.5 text-xs transition-all",
                  tasksCtx.state.properties.timeLeft
                    ? "border-gray-200 text-gray-600 hover:bg-gray-100"
                    : "border-gray-100 text-gray-400 hover:border-gray-200 hover:bg-gray-50"
                )}
                onClick={() =>
                  tasksCtx.dispatch({
                    type: "properties_update",
                    properties: {
                      ...tasksCtx.state.properties,
                      timeLeft: !tasksCtx.state.properties.timeLeft,
                    },
                  })
                }
              >
                Duration to Due Date
              </div>
              <div
                className={clsx(
                  "flex h-6 w-fit cursor-pointer items-center rounded border px-2.5 text-xs transition-all",
                  tasksCtx.state.properties.isStatusExpanded
                    ? "border-gray-200 text-gray-600 hover:bg-gray-100"
                    : "border-gray-100 text-gray-400 hover:border-gray-200 hover:bg-gray-50"
                )}
                onClick={() =>
                  tasksCtx.dispatch({
                    type: "properties_update",
                    properties: {
                      ...tasksCtx.state.properties,
                      isStatusExpanded:
                        !tasksCtx.state.properties.isStatusExpanded,
                    },
                  })
                }
              >
                Expanded Status
              </div>
            </div>
          </div> */}
        </div>
      </PopoverContent>
    </Popover>
  );
}

type FilterGroup =
  | {
      logic: Logic;
      children: Filter[];
    }
  | undefined;

type Filter = TaskStatusFilter;

type Display = {
  showingSpaceSlug: boolean;
};

type Grouping = "project" | "status";

function GeneralTasksList({
  taskGroups,
  filter,
  sort,
  display,
  grouping,
  baselineFilter,
}: // onClick
{
  taskGroups: TaskGroupData[];
  filter: NewTasksFilter;
  sort: Sort;
  display: Display;
  grouping: TaskGroup;
  baselineFilter: NewTasksFilter;
  // onClick: (taskId: string) => void;
}) {
  const hasTasks = taskGroups.some((tg) => tg.tasks.length > 0);
  const newTaskForInternalUserCtx = useNewTaskForInternalUser();
  return (
    <>
      {hasTasks ? (
        <>
          {taskGroups?.map((taskGroup) => (
            <TasksGroup
              key={
                taskGroup.groupDetails.group === "project"
                  ? taskGroup.groupDetails.project._id
                  : taskGroup.groupDetails.group === "status"
                  ? taskGroup.groupDetails.customStatus
                  : taskGroup.groupDetails.group === "tasker"
                  ? taskGroup.groupDetails.tasker._id ??
                    taskGroup.groupDetails.tasker.email
                  : Math.random()
              }
              group={taskGroup.groupDetails}
              initialData={taskGroup.tasks}
              sort={sort}
              filter={filter}
              baselineFilter={baselineFilter}
            />
          ))}
        </>
      ) : (
        <div className="">
          <div className="mt-[15vh] flex items-center justify-center">
            <div className="flex w-[520px] max-w-md flex-col rounded-md border p-10 shadow-md">
              <>
                <div className="text-xl font-medium leading-none">Tasks</div>
                <div className="py-7">
                  <div className="text-left font-light leading-relaxed text-gray-500"></div>
                  <div className="text-left font-light leading-relaxed text-gray-500">
                    Tasks is where you can see all of your space's tasksin one
                    view.
                    <div className="mt-3 max-w-[340px]">
                      Once a task is created, it will show up here.
                    </div>
                  </div>
                </div>
              </>

              <div className="flex space-x-2.5">
                <Button
                  onClick={() => {
                    newTaskForInternalUserCtx.dispatch({
                      type: "open_update",
                    });
                  }}
                >
                  New Task
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function TasksGroup({
  group,
  sort,
  filter,
  initialData,
  baselineFilter,
}: {
  group: GroupDetails;
  sort: Sort;
  filter: NewTasksFilter;
  initialData: ReducedTaskData[];
  baselineFilter: NewTasksFilter;
}) {
  const { data: tasks } = useGetTasksForGroup({
    sort: sort,
    filter: clean({
      logic: filter.logic,
      children: [
        ...filter.children,
        ...(group.group === "project"
          ? [
              {
                field: "project" as "project",
                comparator: "in" as IdComparator,
                value: [String(group.project._id)],
              },
            ]
          : []),
        ...(group.group === "status"
          ? [
              {
                field: "task-status" as "task-status",
                comparator: "in" as IdComparator,
                value: [
                  {
                    status: group.status,
                    customStatus: group.customStatus,
                  },
                ],
              },
            ]
          : []),
        ...(group.group === "tasker"
          ? [
              {
                field: "tasker" as "tasker",
                comparator: "in" as IdComparator,
                value: [String(group.tasker._id)],
              },
            ]
          : []),
        // ...(group.group === "project"
        //   ? { phaseIds: [String(group.project._id)] }
        //   : group.group === "status"
        //   ? {
        //       statuses: [
        //         { status: group.status, customStatus: group.customStatus },
        //       ],
        //     }
        //   : group.group === "tasker"
        //   ? { taskerIds: [String(group.tasker._id)] }
        //   : {}),
      ],
      // ...(group.group === "project"
      //   ? { phaseIds: [String(group.project._id)] }
      //   : group.group === "status"
      //   ? {
      //       statuses: [
      //         { status: group.status, customStatus: group.customStatus },
      //       ],
      //     }
      //   : group.group === "tasker"
      //   ? { taskerIds: [String(group.tasker._id)] }
      //   : {}),
    }),
    initialData: initialData,
    baselineFilter: baselineFilter,
  });
  const navigate = useNavigate();
  return (
    <>
      {!!tasks && (
        <TasksTable
          tasks={tasks.tasks}
          title={
            group.group === "project"
              ? group.project.name ?? "Unassigned"
              : group.group === "status"
              ? group.customStatus
              : group.tasker.name ?? group.tasker.email ?? "Unassigned"
          }
          onClick={
            group.group === "project" && group.project._id
              ? () => {
                  if (group.group === "project" && group.project._id) {
                    navigate(`/projects/${group.project._id}/tasks`);
                  }
                }
              : undefined
          }
        />
      )}
    </>
  );
}
