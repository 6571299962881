import { DonutChart, Legend } from "@tremor/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getSubdomain } from "src/App";
import { useGetThemeSettings } from "src/api/CustomerPortal/login";
import {
  SharedSpacePhaseData,
  useGetPhasesForExternalUser,
} from "src/api/SharedSpace";
import { useRealmApp } from "src/store/RealmApp";
import { getTimeDifference } from "src/utils/functions/dateTime";

export default function PhasesForExternalUser() {
  const slug = getSubdomain();
  const { data: phases } = useGetPhasesForExternalUser(String(slug));
  const app = useRealmApp();

  useEffect(() => {
    if (!!phases && phases.length === 0) {
      app.logOut();
    }
  }, [phases]);

  return (
    <div className="overflow-auto">
      {!!phases && (
        <div className="mx-auto h-full max-w-7xl py-20 px-8">
          <div className="pb-16">
            <Logo />
          </div>
          <div className="md:grird-cols-2 grid grid-cols-1 gap-12 lg:grid-cols-2 xl:grid-cols-3">
            {phases
              ?.sort(
                (p1, p2) =>
                  new Date(p2.createdAt).getTime() -
                  new Date(p1.createdAt).getTime()
              )
              .map((phase) => (
                <Phase key={phase._id} phase={phase} />
              ))}
            {/* <div className="h-80 w-80 rounded-md border p-6"></div>
        <div className="h-80 w-80 rounded-md border p-6"></div>
        <div className="h-80 w-80 rounded-md border p-6"></div> */}
          </div>
        </div>
      )}
    </div>
  );
}

function Phase({ phase }: { phase: SharedSpacePhaseData }) {
  switch (phase.type) {
    case "Customer Onboarding":
      return <CustomerOnboardingPhase phase={phase} />;
    default:
      return <SimplePhase phase={phase} />;
  }
}

function CustomerOnboardingPhase({ phase }: { phase: SharedSpacePhaseData }) {
  const { absValue, unit } = getTimeDifference(
    new Date(),
    new Date(phase.createdAt)
  );
  const navigate = useNavigate();
  const app = useRealmApp();
  return (
    <div
      className="relative col-span-1 flex cursor-pointer flex-col gap-6 rounded-md border bg-white p-6 shadow-md transition-all hover:bg-gray-50 hover:shadow-lg"
      onClick={() => {
        navigate(`/projects/${phase._id}`);
      }}
    >
      <div className="flex-1 space-y-4">
        <div className="flex items-center justify-between gap-4">
          <div className="text-2xl font-bold">
            {phase.externalName ?? phase.name ?? "Onboarding"}
          </div>
          <div className="text-xl text-gray-500">
            {`${phase.completionPercent.toFixed(0)}%`}
          </div>
        </div>
        <div className="pb-4 pt-3">
          <OverallTasksBreakdown
            taskCount={{
              toDo: phase.tasksCount.overall.toDo,
              inProgress: phase.tasksCount.overall.inProgress,
              completed: phase.tasksCount.overall.completed,
              upcoming: phase.tasksCount.overall.upcoming,
            }}
          />
        </div>
        <div>
          <div className="flex items-center text-sm">
            <div className="min-w-0 flex-1 space-y-2 border-r">
              <div className="border-b pb-1.5 text-gray-500">{"Tasker"}</div>
              <div className="truncate pr-2 text-gray-800">
                {app.currentUser?.customData?.name}
              </div>
              <div className="truncate pr-2 text-gray-800">
                {phase.tasksCount.internal.name}
              </div>
              <div className="truncate pr-2 text-gray-800">
                {phase.tasksCount.external.name}
              </div>
            </div>
            <div className="flex w-16 flex-col items-center space-y-2 border-r">
              <div className="w-full border-b pb-1.5 text-center text-gray-500">
                {"Active"}
              </div>
              <div className="text-gray-800">
                {phase.tasksCount.user.active === 0 ? (
                  <span className="text-gray-300">-</span>
                ) : (
                  <span>{`${phase.tasksCount.user.active}`}</span>
                )}
              </div>
              <div className="text-gray-800">
                {phase.tasksCount.internal.active === 0 ? (
                  <span className="text-gray-300">-</span>
                ) : (
                  <span>{`${phase.tasksCount.internal.active}`}</span>
                )}
              </div>
              <div className="text-gray-800">
                {phase.tasksCount.external.active === 0 ? (
                  <span className="text-gray-300">-</span>
                ) : (
                  <span>{`${phase.tasksCount.external.active}`}</span>
                )}
              </div>
            </div>
            <div className="flex w-16 flex-col items-center space-y-2">
              <div className="w-full border-b pb-1.5 text-center text-gray-500">
                {"Overdue"}
              </div>
              <div className="text-gray-800">
                {phase.tasksCount.user.overdue === 0 ? (
                  <span className="text-gray-300">-</span>
                ) : (
                  <span className="text-red-500">{`${phase.tasksCount.user.overdue}`}</span>
                )}
              </div>
              <div className="text-gray-800">
                <span className="text-gray-300">-</span>
              </div>
              <div className="text-gray-800">
                {phase.tasksCount.external.overdue === 0 ? (
                  <span className="text-gray-300">-</span>
                ) : (
                  <span className="text-red-500">{`${phase.tasksCount.external.overdue}`}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SimplePhase({ phase }: { phase: SharedSpacePhaseData }) {
  const navigate = useNavigate();
  const app = useRealmApp();
  return (
    <div
      className="relative col-span-1 flex cursor-pointer flex-col gap-6 rounded-md border bg-white p-6 shadow-md transition-all hover:bg-gray-50 hover:shadow-lg"
      onClick={() => {
        navigate(`/projects/${phase._id}`);
      }}
    >
      <div className="flex-1 space-y-4">
        <div className="flex items-center justify-between gap-4">
          <div className="text-2xl font-bold">
            {phase.externalName ?? phase.name ?? "Onboarding"}
          </div>
          <div className="text-xl text-gray-500">
            {`${phase.completionPercent.toFixed(0)}%`}
          </div>
        </div>
        <div className="pb-4 pt-3">
          <OverallTasksBreakdown
            taskCount={{
              toDo: phase.tasksCount.overall.toDo,
              inProgress: phase.tasksCount.overall.inProgress,
              completed: phase.tasksCount.overall.completed,
              upcoming: phase.tasksCount.overall.upcoming,
            }}
          />
        </div>
        <div>
          <div className="flex items-center text-sm">
            <div className="min-w-0 flex-1 space-y-2 border-r">
              <div className="border-b pb-1.5 text-gray-500">{"Tasker"}</div>
              <div className="truncate pr-2 text-gray-800">
                {app.currentUser?.customData?.name}
              </div>
              <div className="truncate pr-2 text-gray-800">
                {phase.tasksCount.internal.name}
              </div>
              <div className="truncate pr-2 text-gray-800">
                {phase.tasksCount.external.name}
              </div>
            </div>
            <div className="flex w-16 flex-col items-center space-y-2 border-r">
              <div className="w-full border-b pb-1.5 text-center text-gray-500">
                {"Active"}
              </div>
              <div className="text-gray-800">
                {phase.tasksCount.user.active === 0 ? (
                  <span className="text-gray-300">-</span>
                ) : (
                  <span>{`${phase.tasksCount.user.active}`}</span>
                )}
              </div>
              <div className="text-gray-800">
                {phase.tasksCount.internal.active === 0 ? (
                  <span className="text-gray-300">-</span>
                ) : (
                  <span>{`${phase.tasksCount.internal.active}`}</span>
                )}
              </div>
              <div className="text-gray-800">
                {phase.tasksCount.external.active === 0 ? (
                  <span className="text-gray-300">-</span>
                ) : (
                  <span>{`${phase.tasksCount.external.active}`}</span>
                )}
              </div>
            </div>
            <div className="flex w-16 flex-col items-center space-y-2">
              <div className="w-full border-b pb-1.5 text-center text-gray-500">
                {"Overdue"}
              </div>
              <div className="text-gray-800">
                {phase.tasksCount.user.overdue === 0 ? (
                  <span className="text-gray-300">-</span>
                ) : (
                  <span className="text-red-500">{`${phase.tasksCount.user.overdue}`}</span>
                )}
              </div>
              <div className="text-gray-800">
                <span className="text-gray-300">-</span>
              </div>
              <div className="text-gray-800">
                {phase.tasksCount.external.overdue === 0 ? (
                  <span className="text-gray-300">-</span>
                ) : (
                  <span className="text-red-500">{`${phase.tasksCount.external.overdue}`}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function OverallTasksBreakdown({
  taskCount,
}: {
  taskCount: {
    toDo: number;
    inProgress: number;
    completed: number;
    upcoming: number;
  };
}) {
  const data: {
    type: string;
    value: number;
  }[] = [
    {
      type: "To Do",
      value: taskCount.toDo,
    },
    {
      type: "In Progress",
      value: taskCount.inProgress,
    },
    {
      type: "Completed",
      value: taskCount.completed,
    },
    {
      type: "Planned",
      value: taskCount.upcoming,
    },
  ];

  const valueFormatter = (number: number) =>
    `${new Intl.NumberFormat("us").format(number).toString()} task${
      number > 1 ? "s" : ""
    }`;

  return (
    <div className="flex items-center gap-4">
      <DonutChart
        className="w-40 shrink-0 px-0"
        data={data}
        category="value"
        index="type"
        valueFormatter={valueFormatter}
        colors={["amber", "blue", "green", "gray"]}
      />
      <Legend
        className="flex flex-col"
        categories={["To Do", "In Progress", "Completed", "Planned"]}
        colors={["amber", "blue", "green", "gray"]}
      />
    </div>
  );
}

function Logo() {
  const slug = getSubdomain();
  const { data: theme } = useGetThemeSettings(String(slug));
  return (
    <>
      {!!theme ? (
        <div className="flex items-center gap-4">
          <img src={theme.logo.url} className="h-[46px]" />
          <div className="text-3xl font-medium text-gray-500">
            {theme.instanceName}
          </div>
        </div>
      ) : (
        <div className="h-[46px] w-[120px] animate-pulse rounded-md bg-gray-100" />
      )}
    </>
  );
}
