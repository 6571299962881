import { cva, VariantProps } from "class-variance-authority";

const buttonStyles = cva(
  "inline-flex items-center rounded-md border font-medium shadow-sm focus:outline-none disabled:bg-gray-300",
  {
    variants: {
      fill: {
        solid:
          "border-transparent bg-embedPrimary/90 text-white focus:ring-embedPrimary hover:bg-embedPrimary",
        outline:
          "border border-embedPrimary/90 hover:text-embedPrimary text-embedPrimary/90 focus:ring-embedPrimary/90 bg-white hover:bg-embedPrimary/10",
        none: "hover:text-embedPrimary text-embedPrimary/90 shadow-none px-0",
      },
      size: {
        xs: "px-2.5 py-1.5 text-xs",
        sm: "px-3 py-2 text-sm",
        md: "px-4 py-2 text-sm",
        lg: "px-4 py-2 text-base",
        xl: "px-6 py-3 text-base",
      },
      ring: {
        true: "focus:ring-2 focus:ring-offset-2",
        false: "",
      },
      width: {
        full: "w-full justify-center",
        fit: "w-fit",
      },
    },
    defaultVariants: {
      size: "sm",
      fill: "solid",
      width: "fit",
      ring: false,
    },
  }
);

type ButtonProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  text: string;
  disabled?: boolean;
  leftIcon?: React.ReactNode;
  type?: "button" | "submit" | "reset";
};

export interface Props extends ButtonProps, VariantProps<typeof buttonStyles> {}

export const EmbedButton = ({
  text,
  onClick,
  disabled = false,
  size,
  fill,
  leftIcon,
  ring,
  type = "button",
  width,
  ...props
}: Props) => {
  return (
    <button
      type={type}
      className={buttonStyles({ size, fill, ring, width })}
      {...props}
      disabled={disabled}
      onClick={onClick}
    >
      {!!leftIcon && leftIcon}
      {text}
    </button>
  );
};
