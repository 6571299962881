// import React from "react";
// import {
//   FormTemplateElementBlockData,
//   FormTemplateSectionBlockData,
// } from "src/api/Forms/forms";

// type Action =
//   | {
//       type: "section_add";
//       elements: FormTemplateSectionBlockData[];
//     }
//   | {
//       type: "element_add";
//       elementType: FormTemplateElementBlockData["type"];
//       sectionIndex: number;
//       elementIndex?: number;
//     }
//   | {
//       type: "element_delete";
//       index: number;
//     }
//   | {
//       type: "element_move";
//       index1: number;
//       index2: number;
//     };

// type Dispatch = (action: Action) => void;

// type State = {
//   sections: FormTemplateSectionBlockData[];
// };

// const FormTemplateContext = React.createContext<
//   { state: State; dispatch: Dispatch } | undefined
// >(undefined);

// function formTemplateReducer(state: State, action: Action) {
//   switch (action.type) {
//     case "element_add":
//       console.log("element add", action);
//       // let sections = [...state.sections];
//       // let newSectionBlocks = [...sections[action.sectionIndex].blocks];
//       // if (action.elementIndex === undefined) {
//       //   newSectionBlocks.push(getNewElement(action.elementType));
//       // } else {
//       //   newSectionBlocks.splice(
//       //     action.elementIndex,
//       //     0,
//       //     getNewElement(action.elementType)
//       //   );
//       // }
//       // sections[action.sectionIndex].blocks = newSectionBlocks;
//       return state;
//     case "element_delete":
//       console.log("element delete", action);
//       return state;
//     case "element_move":
//       console.log("element move", action);
//       return state;
//     case "section_add":
//       console.log("section add", action);
//       return state;
//     default: {
//       return state;
//     }
//   }
// }

// export function FormTemplateContextProvider({
//   children,
//   sections,
// }: {
//   children: React.ReactNode;
//   sections: FormTemplateSectionBlockData[];
// }) {
//   const [state, dispatch] = React.useReducer(formTemplateReducer, {
//     sections: sections,
//   });
//   const value = { state, dispatch };
//   return (
//     <FormTemplateContext.Provider value={value}>
//       {children}
//     </FormTemplateContext.Provider>
//   );
// }

// export function useFormTemplateContext() {
//   const context = React.useContext(FormTemplateContext);
//   if (context === undefined) {
//     throw new Error(
//       "useFormTemplateContext must be used within a FormTemplateContextProvider"
//     );
//   }
//   return context;
// }

// function getNewElement(
//   type: FormTemplateElementBlockData["type"]
// ): FormTemplateElementBlockData {
//   switch (type) {
//     case "short text":
//       return {
//         type: "short text",
//         properties: {
//           label: "",
//           description: "",
//           required: false,
//           placeholder: "Title..",
//         },
//       };
//     case "long text":
//       return {
//         type: "long text",
//         properties: {
//           label: "",
//           description: "",
//           required: false,
//           placeholder: "Description..",
//         },
//       };
//     default:
//       return {
//         type: "short text",
//         properties: {
//           label: "",
//           description: "",
//           required: false,
//           placeholder: "Title..",
//         },
//       };
//   }
// }

import React from "react";
import {
  FormTemplateElementBlockData,
  FormTemplateSectionBlockData,
} from "src/api/Forms/forms";


const FormTemplateContext = React.createContext<
  | {
      // sections: FormTemplateSectionBlockData[];
      // addElement: (
      //   elementType: FormTemplateElementBlockData["type"],
      //   sectionIndex: number,
      //   elementIndex?: number
      // ) => void;
      selectedElement:
        | {
            sectionIndex: number;
            id: string;
          }
        | undefined;
      setSelectedElement: React.Dispatch<
        React.SetStateAction<
          | {
              sectionIndex: number;
              id: string;
            }
          | undefined
        >
      >;
    }
  | undefined
>(undefined);

// function formTemplateReducer(state: State, action: Action) {
//   switch (action.type) {
//     case "element_add":
//       console.log("element add", action);
//       // let sections = [...state.sections];
//       // let newSectionBlocks = [...sections[action.sectionIndex].blocks];
//       // if (action.elementIndex === undefined) {
//       //   newSectionBlocks.push(getNewElement(action.elementType));
//       // } else {
//       //   newSectionBlocks.splice(
//       //     action.elementIndex,
//       //     0,
//       //     getNewElement(action.elementType)
//       //   );
//       // }
//       // sections[action.sectionIndex].blocks = newSectionBlocks;
//       return state;
//     case "element_delete":
//       console.log("element delete", action);
//       return state;
//     case "element_move":
//       console.log("element move", action);
//       return state;
//     case "section_add":
//       console.log("section add", action);
//       return state;
//     default: {
//       return state;
//     }
//   }
// }

export function FormTemplateContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // const [sections, setSections] =
  //   React.useState<FormTemplateSectionBlockData[]>(formTemplateSections);

  const [selectedElement, setSelectedElement] = React.useState<
    | {
        sectionIndex: number;
        id: string;
      }
    | undefined
  >(undefined);

  // function addElement(
  //   elementType: FormTemplateElementBlockData["type"],
  //   sectionIndex: number,
  //   elementIndex?: number
  // ) {
  //   let newSections = [...sections];
  //   let newSectionBlocks = [...newSections[sectionIndex].blocks];
  //   const newElement = getNewElement(elementType);
  //   if (elementIndex === undefined) {
  //     newSectionBlocks.push(newElement);
  //   } else {
  //     newSectionBlocks.splice(elementIndex, 0, newElement);
  //   }
  //   newSections[sectionIndex].blocks = newSectionBlocks;
  //   setSelectedElement({
  //     sectionIndex: sectionIndex,
  //     id: newElement.id,
  //   });
  //   setSections(newSections);
  // }

  const value = { selectedElement, setSelectedElement };
  return (
    <FormTemplateContext.Provider value={value}>
      {children}
    </FormTemplateContext.Provider>
  );
}

export function useFormTemplateContext() {
  const context = React.useContext(FormTemplateContext);
  if (context === undefined) {
    throw new Error(
      "useFormTemplateContext must be used within a FormTemplateContextProvider"
    );
  }
  return context;
}


