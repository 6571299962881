import React from "react";

type Action = {};

type State = {
  isExternal: boolean;
  isEmbed: boolean;
};

type Dispatch = (action: Action) => void;

const PhaseNotesContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function phaseNotesReducer(state: State, action: Action) {
  return state;
}

export function PhaseNotesContextProvider({
  children,
  isExternal,
  isEmbed,
}: {
  children: React.ReactNode;
  isExternal: boolean;
  isEmbed: boolean;
}) {
  const [state, dispatch] = React.useReducer(phaseNotesReducer, {
    isExternal: isExternal,
    isEmbed: isEmbed,
  });
  const value = { state, dispatch };
  return (
    <PhaseNotesContext.Provider value={value}>
      {children}
    </PhaseNotesContext.Provider>
  );
}

export function usePhaseNotesContext() {
  const context = React.useContext(PhaseNotesContext);
  if (context === undefined) {
    throw new Error(
      "usePhaseNotesContext must be used within a PhaseNotesContextProvider"
    );
  }
  return context;
}
