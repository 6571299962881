import {
  BriefcaseIcon,
  BuildingOfficeIcon,
  PlusIcon,
  RectangleStackIcon,
  UserGroupIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ArrowLeftIcon,
  CalendarIcon,
  CaretSortIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  Cross2Icon,
  MagnifyingGlassIcon,
} from "@radix-ui/react-icons";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { Command as CommandPrimitive } from "cmdk";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CustomFieldBooleanData,
  CustomFieldCurrencyData,
  CustomFieldData,
  CustomFieldMultiSelectData,
  CustomFieldSelectData,
  useGetCustomFields,
} from "src/api/CustomFields/custom-fields";
import { useGetDealsForCustomer } from "src/api/Deals/deals";
import { useGetExternalCustomerMembers } from "src/api/External/users";
import {
  CustomerData,
  useCreateCustomer,
  useGetCustomersWithDebouncing,
} from "src/api/General/customers";
import { useGetInstance } from "src/api/General/instance";
import { useGetUsers } from "src/api/General/users";
import { useGetLogo } from "src/api/Misc/Settings/Embedding/embed";
import {
  CustomFieldRecord,
  useLaunchExternalBlankProject,
  useLaunchExternalTemplateProject,
  useLaunchInternalBlankProject,
} from "src/api/Services/Projects/projects";
import { useGetJourneyTemplates } from "src/api/Services/Tasks/journeys";
// import { Avatar } from "src/components/ui/Avatar/Avatar";
import { SpaceData, useGetSpaces } from "src/api/Spaces/spaces";
import { SpaceSymbol } from "src/components/Layout/Sidebar";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { Badge } from "src/components/ui/badge";
import { Button } from "src/components/ui/button";
import { Calendar } from "src/components/ui/calendar";
import { Checkbox } from "src/components/ui/checkbox";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "src/components/ui/command";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "src/components/ui/dialog";
import { Loading } from "src/components/ui/loading";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { TextInput } from "src/components/ui/text-input";
import { useRealmApp } from "src/store/RealmApp";
import useDebounce from "src/utils/customHooks/useDebounce";
import { cn } from "src/utils/ui/ui";
import { z } from "zod";
import {
  CustomFieldLocalRecord,
  ExternalProjectTaskers,
  InternalProjectTaskers,
  NewProjectContextProvider,
  UserData,
  useNewProjectContext,
} from "./NewProjectContextProvider";

type Props = {
  spaces: SpaceData[];
  step?: number;
  customer?: {
    _id: string;
    name: string;
    source?: "hubspot" | "salesforce";
  };
};

export default function NewProjectButton({ spaces, step, customer }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const app = useRealmApp();
  const { spaceId } = useParams();

  // console.log({
  //   _id: app.currentUser?.id ?? "",
  //   name: app.currentUser?.customData?.name ?? "",
  // });
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant={"secondary"}>
          <span>New Project</span>
        </Button>
      </DialogTrigger>
      <DialogContent
        showCloseButton={false}
        className="h-[600px] max-h-[96vh] max-w-5xl p-0"
        onPointerDownOutside={(e) => {
          e.preventDefault();
        }}
        onEscapeKeyDown={(e) => {
          e.preventDefault();
        }}
      >
        <NewProjectContextProvider
          launcher={{
            _id: app.currentUser?.id ?? "",
            name: app.currentUser?.customData?.name ?? "",
            isProjectOwner: true,
          }}
          spaceId={spaceId ? spaceId : spaces?.[0]._id}
          step={step}
          customer={customer}
        >
          <div className="flex flex-col divide-y divide-gray-200/80 overflow-auto">
            <div className="flex h-12 items-center px-6">
              <div className="shrink-0 text-sm text-gray-800">
                <ProjectName />
              </div>
            </div>
            <div className="flex h-full flex-1 divide-x divide-gray-200/80 overflow-auto">
              <ProjectSummaryPanel />
              <NewProjectSteps />
            </div>
          </div>
        </NewProjectContextProvider>
        <DialogClose className="absolute right-6 top-4 rounded-sm opacity-40 ring-offset-white transition-opacity hover:opacity-100 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
          <Cross2Icon className="h-4 w-4" />
        </DialogClose>
      </DialogContent>
    </Dialog>
  );

  // return (
  //   <>
  //     <Button
  //       onClick={() => {
  //         setIsModalOpen(true);
  //       }}
  //       size="xs"
  //       text={"New Project"}
  //       leftIcon={<PlusIcon className="-ml-0.5 mr-2 h-3 w-3" />}
  //     />
  //     <Modal
  //       isOpen={isModalOpen}
  //       closeModal={() => {
  //         setIsModalOpen(false);
  //       }}
  //       // height={"md"}
  //     >
  //       <div className="w-[700px] p-6">
  //       </div>
  //     </Modal>
  //   </>
  // );
}

function ProjectName() {
  const newProjectCtx = useNewProjectContext();
  const { data: spaces } = useGetSpaces();
  const space = spaces?.find((s) => s._id === newProjectCtx.state.spaceId);
  return (
    <div className="flex items-center space-x-1.5">
      {!!space ? (
        <div className="5 flex items-center space-x-1.5">
          <SpaceSymbol color={space.color} />
          <span className="font text-gray-700">{space.name}</span>
        </div>
      ) : (
        <div>-</div>
      )}
      <ChevronRightIcon className="h-3 w-3 text-gray-500" />
      <span className="font text-gray-700">New Project</span>
    </div>
  );
  // if (newProjectCtx.state.projectLaunchState === "details") {
  // } else {
  //   if (newProjectCtx.state.projectName.trim() !== "") {
  //     return (
  //       <span className="font-medium text-gray-700">
  //         {newProjectCtx.state.projectName}
  //       </span>
  //     );
  //   } else {
  //     return <span className="font-medium text-gray-700">New Project</span>;
  //   }
  // }
}

function NewProjectSteps() {
  const newProjectCtx = useNewProjectContext();

  switch (newProjectCtx.state.step) {
    case -1:
      return <InternalOrExternal />;
    case 0:
      return <CompanyDetails />;
    case 1:
      return <BlankOrTemplate />;
    case 2:
      return <ProjectDetails />;
    case 3:
      return <CustomFields />;
    case 4:
      return <AddInternalMembers />;
    case 5:
      return <AddExternalMembers />;
    case 6:
      return <SelectTaskers />;
    case 7:
      return <InviteEmail />;
    case 8:
      return <Review />;
    default:
      return null;
  }

  // switch (newProjectCtx.state.step) {
  //   case 0:
  //     return <BlankOrTemplate />;
  //   default:
  //     if (newProjectCtx.state.projectStart === "blank") {
  //       return <BlankSteps />;
  //     } else {
  //       return <TemplateSteps />;
  //     }
  // }
}

function ProjectDetails() {
  const newProjectCtx = useNewProjectContext();
  const [templateErrorText, setTemplateErrorText] = useState("");
  const [customerErrorText, setCustomerErrorText] = useState("");
  const [projectNameErrorText, setProjectNameErrorText] = useState("");
  const projectNameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (newProjectCtx.state.journeyTemplate) {
      projectNameRef.current?.focus();
    }
  }, [newProjectCtx.state.journeyTemplate]);
  return (
    <div className="relative flex w-full flex-col">
      <div className="flex-1 space-y-7 p-8 px-10">
        <div className="text-xl font-medium leading-none">Project Details</div>
        <div className="space-y-6">
          {/* {newProjectCtx.state.projectType === "external" && (
            <div className="space-y-1">
              <InputHeading heading="Company" required />
              <CustomerCombobox />
            </div>
          )} */}
          {newProjectCtx.state.projectStart === "template" && (
            <div className="space-y-1">
              <InputHeading heading={"Template"} required />
              <TemplateSelect
                onChange={() => {
                  setTemplateErrorText("");
                }}
              />
            </div>
          )}
          <div className="space-y-1">
            <InputHeading heading={"Name"} required />
            <div className="space-y-1">
              <div className="relative w-fit">
                <TextInput
                  autoFocus={
                    newProjectCtx.state.projectStart === "template"
                      ? !!newProjectCtx.state.journeyTemplate
                      : true
                  }
                  ref={projectNameRef}
                  spellCheck={false}
                  value={newProjectCtx.state.projectName}
                  onChange={(e) => {
                    newProjectCtx.dispatch({
                      type: "PROJECT_NAME_UPDATE",
                      projectName: e.target.value,
                    });
                    if (e.target.value.trim() !== "") {
                      setProjectNameErrorText("");
                    }
                  }}
                  // onKeyUp={(e) => {
                  //   if (e.key === "Enter") {
                  //     if (newProjectCtx.state.projectName.trim() === "") {
                  //       if (!!newProjectCtx.state.customer) {
                  //         newProjectCtx.dispatch({
                  //           type: "PROJECT_NAME_UPDATE",
                  //           projectName: `${newProjectCtx.state.customer.name}'s Project`,
                  //         });
                  //       } else {
                  //         setProjectNameErrorText("Project name is required");
                  //       }
                  //     } else {
                  //       newProjectCtx.dispatch({
                  //         type: "STEP_UPDATE",
                  //         step: newProjectCtx.state.step + 1,
                  //       });
                  //     }
                  //   }
                  // }}
                  placeholder={
                    !!newProjectCtx.state.customer
                      ? `${newProjectCtx.state.customer.name}'s Project`
                      : "Tesla's Project"
                  }
                  className="peer w-72"
                />
              </div>
            </div>
          </div>

          {/* <StatusSelect /> */}
        </div>
      </div>

      <div className="flex items-end justify-between border-t border-gray-200/80 px-10 py-6">
        <Button
          variant={"secondary"}
          onClick={() => {
            newProjectCtx.dispatch({
              type: "PREVIOUS_STEP",
            });
          }}
        >
          Back
        </Button>
        <Button
          disabled={
            !newProjectCtx.state.projectName.trim() ||
            (newProjectCtx.state.projectStart === "template" &&
              !newProjectCtx.state.journeyTemplate) ||
            (newProjectCtx.state.projectType === "external" &&
              !newProjectCtx.state.customer)
          }
          onClick={() => {
            newProjectCtx.dispatch({
              type: "NEXT_STEP",
            });
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

// function TemplateSteps() {
//   const newProjectCtx = useNewProjectContext();
//   switch (newProjectCtx.state.step) {
//     case 1:
//       //   // return <SelectCompany />;
//       //   return <SelectCompany />;
//       // case 2:
//       //   return <AddProjectName />;
//       // case 3:
//       return <AddInternalMembers />;
//     case 2:
//       return <AddExternalMembers />;
//     case 3:
//       return <SelectTaskers />;
//     case 4:
//       return <InviteEmail />;
//     case 5:
//       return <Review />;
//     default:
//       return <>should not be here either</>;
//   }
// }

// function BlankSteps() {
//   const newProjectCtx = useNewProjectContext();

//   switch (newProjectCtx.state.step) {
//     case 1:
//       return <SelectCompanyAndName />;
//     case 2:
//       return <AddInternalMembers />;
//     case 3:
//       return <AddExternalMembers />;
//     case 4:
//       return <InviteEmail />;
//     case 5:
//       return <ExternalBlankProjectReview />;
//   }
//   return <>-</>;

//   if (newProjectCtx.state.step === 1) {
//     return <InternalOrExternal />;
//   } else {
//     if (newProjectCtx.state.projectType === "internal") {
//       switch (newProjectCtx.state.step) {
//         case 2:
//           return <AddProjectName />;
//         case 3:
//           return <AddInternalMembers />;
//         case 4:
//           return <InternalProjectReview />;
//         default:
//           return <>blank - internal - should not be here</>;
//       }
//     } else {
//       switch (newProjectCtx.state.step) {
//         case 2:
//           return <SelectCompanyAndName />;
//         case 3:
//           return <AddInternalMembers />;
//         case 4:
//           return <AddExternalMembers />;
//         case 5:
//           return <InviteEmail />;
//         case 6:
//           return <ExternalBlankProjectReview />;
//           return <>blank - external - should not be here</>;
//         default:
//       }
//       // internal members
//       // external members
//       // project name
//     }
//   }
//   return <></>;

//   // switch (newProjectCtx.state.step) {
//   //   case 1:
//   //     return <InternalOrExternal />;
//   //   case 2:
//   //     return <AddProjectName />;
//   //   case 3:
//   //     return <AddInternalMembers />;
//   //   case 4:
//   //     if (newProjectCtx.state.projectType === "internal") {
//   //       newProjectCtx.dispatch({
//   //         type: "STEP_UPDATE",
//   //         step: newProjectCtx.state.step + 1,
//   //       });
//   //       return <></>;
//   //     }
//   //     return <AddExternalMembers />;
//   //   case 5:
//   //     return <Review />;
//   //   default:
//   //     return <>should not be here</>;
//   // }
// }

// function ExternalBlankProjectReview() {
//   const newProjectCtx = useNewProjectContext();
//   const launchBlankExternalProjectMutation = useLaunchBlankExternalProject();

//   useEffect(() => {
//     newProjectCtx.dispatch({
//       type: "PROJECT_LAUNCH_STATE_UPDATE",
//       projectLaunchState: "launch",
//     });
//   }, []);

//   return (
//     <div className="relative h-40 w-full p-6 px-8">
//       <div className="h-full text-sm">
//         <div className="text-gray-500">
//           Are you ready to launch the project{" "}
//           <span className="font-medium text-gray-800">
//             {newProjectCtx.state.projectName}
//           </span>{" "}
//           with{" "}
//           <span className="font-medium text-gray-800">
//             {newProjectCtx.state.customer?.name}
//           </span>
//           ?
//         </div>
//       </div>
//       <BackButton
//         onClick={() => {
//           newProjectCtx.dispatch({
//             type: "STEP_UPDATE",
//             step: newProjectCtx.state.step - 1,
//           });
//         }}
//       />
//       <div className="absolute bottom-6 right-6">
//         <Button
//           onClick={() => {
//             if (
//               !!newProjectCtx.state.customer &&
//               !!newProjectCtx.state.statusGroupId
//             ) {
//               // to do
//               launchBlankExternalProjectMutation.mutate({
//                 collaborationType: "external",
//                 customer: {
//                   _id: newProjectCtx.state.customer._id.toString(),
//                 },
//                 inviteEmail: newProjectCtx.state.inviteEmail,
//                 name: newProjectCtx.state.projectName,
//                 sharedSpaceMembers: {
//                   internal: newProjectCtx.state.internalMembers.map(
//                     (i) => i._id
//                   ),
//                   external: newProjectCtx.state.externalMembers,
//                 },
//                 statusGroupId: newProjectCtx.state.statusGroupId,
//                 revenue: newProjectCtx.state.revenue,
//               });
//             }
//           }}
//           size="sm"
//           disabled={launchBlankExternalProjectMutation.isLoading}
//         >
//           {launchBlankExternalProjectMutation.isLoading
//             ? "Launching..."
//             : "Launch Project"}
//         </Button>
//       </div>
//     </div>
//   );
// }

function BlankStepsSummary() {
  const newProjectCtx = useNewProjectContext();
  return (
    <div className="w-64 shrink-0 p-6">
      <div className="flex space-x-2">
        <div>1</div>
        <div>Internal Team</div>
      </div>
      {newProjectCtx.state.projectType === "external" && (
        <div className="flex space-x-2">
          <div>2</div>
          <div>External Team</div>
        </div>
      )}
      <div className="flex space-x-2">
        <div>{newProjectCtx.state.projectType === "internal" ? "2" : "3"}</div>
        <div>Launch</div>
      </div>
    </div>
  );
}

function InternalOrExternal() {
  const newProjectCtx = useNewProjectContext();
  const { data: customFields } = useGetCustomFields("project");

  return (
    <div className="relative flex w-full flex-col space-y-7 p-8 px-10">
      <>
        <div className="text-xl font-medium leading-none">Project Type</div>
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            What type of project is this?
          </label>

          <div className="flex gap-3">
            <div
              className="flex w-40 cursor-pointer flex-col items-center rounded-md border p-6 shadow-sm hover:bg-gray-50"
              onClick={() => {
                newProjectCtx.dispatch({
                  type: "PROJECT_TYPE_UPDATE",
                  projectType: "internal",
                });
                newProjectCtx.dispatch({
                  type: "NEXT_STEP",
                });
              }}
            >
              <UserGroupIcon className="h-6 w-6 text-gray-500" />
              <div className="flex flex-col items-center space-y-1.5 pt-2">
                <div className="font-medium leading-none text-gray-700">
                  Internal
                </div>
                <div className="max-w-[100px] text-center text-xs text-gray-500">
                  With my team
                </div>
              </div>
            </div>
            <div
              className="flex w-40 cursor-pointer flex-col items-center rounded-md border p-6 shadow-sm hover:bg-gray-50"
              onClick={() => {
                newProjectCtx.dispatch({
                  type: "PROJECT_TYPE_UPDATE",
                  projectType: "external",
                });
                newProjectCtx.dispatch({
                  type: "NEXT_STEP",
                });
              }}
            >
              <BuildingOfficeIcon className="h-6 w-6 text-gray-500" />
              <div className="flex flex-col items-center space-y-1.5 pt-2">
                <div className="font-medium leading-none text-gray-700">
                  External
                </div>
                <div className="text-center text-xs text-gray-500">
                  With a company
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

function CompanyDetails() {
  const newProjectCtx = useNewProjectContext();
  const [templateErrorText, setTemplateErrorText] = useState("");
  const [customerErrorText, setCustomerErrorText] = useState("");
  const [projectNameErrorText, setProjectNameErrorText] = useState("");
  const [isLoadingDeals, setIsLoadingDeals] = useState(false);

  return (
    <div className="relative flex w-full flex-col">
      <div className="flex-1 space-y-7 overflow-auto px-10 pt-8">
        <div className="text-xl font-medium leading-none">Company Details</div>
        <div className="space-y-6">
          <div className="space-y-1">
            <InputHeading heading="Company" required />
            <div className="flex items-center space-x-2">
              <CustomerCombobox />
              {isLoadingDeals && <Loading className="h-4 w-4 border-2" />}
            </div>
          </div>
          {newProjectCtx.state.customer?._id && (
            <DealSelector
              setIsLoadingDeals={setIsLoadingDeals}
              customerId={newProjectCtx.state.customer._id.toString()}
            />
          )}
        </div>
      </div>

      <div className="flex items-end justify-between border-t border-gray-200/80 px-10 py-6">
        <Button
          variant={"secondary"}
          onClick={() => {
            newProjectCtx.dispatch({
              type: "PREVIOUS_STEP",
            });
          }}
        >
          Back
        </Button>
        <Button
          disabled={!newProjectCtx.state.customer || isLoadingDeals}
          onClick={() => {
            newProjectCtx.dispatch({
              type: "NEXT_STEP",
            });
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

// function SelectCompanyAndName() {
//   const newProjectCtx = useNewProjectContext();
//   const [projectNameErrorText, setProjectNameErrorText] = useState("");
//   const [customerErrorText, setCustomerErrorText] = useState("");
//   return (
//     <div className="relative flex w-full flex-col space-y-4 p-6 px-8 pb-32">
//       <div className="space-y-1">
//         <InputHeading heading="Company" required />
//         <CustomerCombobox
//           onSelect={() => {
//             setCustomerErrorText("");
//             setProjectNameErrorText("");
//           }}
//         />
//         <div className={"h-4 text-xs text-red-500"}>{customerErrorText}</div>
//       </div>
//       <div className="space-y-1">
//         <InputHeading heading="Revenue" />
//         <div className="relative">
//           <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
//             <div
//               className={clsx(
//                 newProjectCtx.state.revenue ? "text-gray-600" : "text-gray-400"
//               )}
//             >
//               $
//             </div>
//           </div>
//           <TextInput
//             value={newProjectCtx.state.revenue ?? ""}
//             className="w-72 pl-7"
//             placeholder="10,000"
//             onChange={(e) => {
//               const { value } = e.target;
//               if (value === "") {
//                 newProjectCtx.dispatch({
//                   type: "REVENUE_UPDATE",
//                   revenue: undefined,
//                 });
//               } else {
//                 if (!isNaN(Number(value)) || /^\d*$/.test(value)) {
//                   // Convert the valid number string to a number type
//                   newProjectCtx.dispatch({
//                     type: "REVENUE_UPDATE",
//                     revenue: Number(value),
//                   });
//                 }
//               }
//             }}
//           />
//         </div>
//         <div className="h-4" />
//       </div>
//       <div className="space-y-1">
//         <InputHeading heading="Project Name" required />
//         <div className="space-y-1">
//           <div className="relative w-fit">
//             <TextInput
//               spellCheck={false}
//               value={newProjectCtx.state.projectName}
//               onChange={(e) => {
//                 newProjectCtx.dispatch({
//                   type: "PROJECT_NAME_UPDATE",
//                   projectName: e.target.value,
//                 });
//                 if (e.target.value.trim() !== "") {
//                   setProjectNameErrorText("");
//                 }
//               }}
//               placeholder={
//                 !!newProjectCtx.state.customer
//                   ? `${newProjectCtx.state.customer.name}'s Project`
//                   : "Tesla's Project"
//               }
//               className="peer w-72"
//             />
//           </div>

//           <div className={"h-4 text-xs text-red-500"}>
//             {projectNameErrorText}
//           </div>
//         </div>
//       </div>

//       <StatusSelect />

//       <BackButton
//         onClick={() => {
//           newProjectCtx.dispatch({
//             type: "STEP_UPDATE",
//             step: newProjectCtx.state.step - 1,
//           });
//         }}
//       />
//       <NextButton
//         disabled={false}
//         onClick={() => {
//           if (newProjectCtx.state.projectName.trim() === "") {
//             setProjectNameErrorText("Project name is required");
//           }
//           if (!newProjectCtx.state.customer) {
//             setCustomerErrorText("Company is required");
//           }
//           if (
//             newProjectCtx.state.projectName.trim() !== "" &&
//             newProjectCtx.state.customer
//           ) {
//             newProjectCtx.dispatch({
//               type: "STEP_UPDATE",
//               step: newProjectCtx.state.step + 1,
//             });
//           }
//         }}
//       />
//     </div>
//   );
// }

function DealSelector({
  customerId,
  setIsLoadingDeals,
}: {
  customerId: string;
  setIsLoadingDeals: (isLoadingDeals: boolean) => void;
}) {
  const { data: deals, isFetching } = useGetDealsForCustomer(customerId);
  const newProjectCtx = useNewProjectContext();

  useEffect(() => {
    setIsLoadingDeals(isFetching);
  }, [isFetching]);

  return (
    <>
      {!!deals?.length && (
        <div className="space-y-1">
          <InputHeading heading={`Deal`} />
          <Select
            value={newProjectCtx.state.deal?._id}
            onValueChange={(dealId) => {
              // find the deal
              const deal = deals?.find((d) => d._id.toString() === dealId);
              if (deal) {
                newProjectCtx.dispatch({
                  type: "DEAL_UPDATE",
                  deal: deal,
                });
              }
            }}
          >
            <SelectTrigger className="h-9 w-72 gap-2 truncate">
              <div className="flex items-center space-x-2 truncate">
                <BriefcaseIcon className="h-4 w-4 shrink-0 text-gray-500/70" />
                {!!newProjectCtx.state.deal ? (
                  <div className="flex min-w-0 items-center space-x-2">
                    {newProjectCtx.state.customer?.source === "hubspot" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="3 3.5 12 12"
                        className="w-4 shrink-0"
                      >
                        <path
                          fill="#FF5C35"
                          d="M11.54 7.422V6.096a1.02 1.02 0 0 0 .588-.92v-.03c0-.56-.46-1.02-1.02-1.02h-.03c-.561 0-1.02.46-1.02 1.02v.03c0 .407.243.757.589.92v1.326c-.516.08-.986.292-1.375.605l-3.64-2.832a1.14 1.14 0 0 0 .04-.286 1.15 1.15 0 1 0-1.15 1.148c.206 0 .398-.059.566-.154l3.58 2.786a2.892 2.892 0 0 0-.482 1.603c0 .62.196 1.194.527 1.666l-1.089 1.09a.935.935 0 0 0-.27-.045.945.945 0 1 0 .945.945.934.934 0 0 0-.044-.27l1.077-1.077a2.89 2.89 0 0 0 1.761.598 2.905 2.905 0 0 0 .446-5.776Zm-.447 4.36a1.49 1.49 0 1 1 0-2.98 1.49 1.49 0 0 1 0 2.98Z"
                        />
                      </svg>
                    )}
                    <div className="truncate">
                      {newProjectCtx.state.deal.name}
                    </div>
                  </div>
                ) : (
                  <div className="text-gray-300">Deal</div>
                )}
              </div>
            </SelectTrigger>
            <SelectContent className="max-h-[40vh] min-w-[120px]">
              <SelectGroup>
                {deals?.map((deal) => (
                  <SelectItem
                    key={deal._id?.toString()}
                    value={deal._id?.toString() ?? ""}
                    // disabled={t.numberOfRuns === 0}
                  >
                    <div className="flex items-center space-x-2">
                      {newProjectCtx.state.customer?.source === "hubspot" && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="3 3.5 12 12"
                          className="w-4"
                        >
                          <path
                            fill="#FF5C35"
                            d="M11.54 7.422V6.096a1.02 1.02 0 0 0 .588-.92v-.03c0-.56-.46-1.02-1.02-1.02h-.03c-.561 0-1.02.46-1.02 1.02v.03c0 .407.243.757.589.92v1.326c-.516.08-.986.292-1.375.605l-3.64-2.832a1.14 1.14 0 0 0 .04-.286 1.15 1.15 0 1 0-1.15 1.148c.206 0 .398-.059.566-.154l3.58 2.786a2.892 2.892 0 0 0-.482 1.603c0 .62.196 1.194.527 1.666l-1.089 1.09a.935.935 0 0 0-.27-.045.945.945 0 1 0 .945.945.934.934 0 0 0-.044-.27l1.077-1.077a2.89 2.89 0 0 0 1.761.598 2.905 2.905 0 0 0 .446-5.776Zm-.447 4.36a1.49 1.49 0 1 1 0-2.98 1.49 1.49 0 0 1 0 2.98Z"
                          />
                        </svg>
                      )}
                      <span>{deal.name}</span>
                    </div>
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      )}
    </>
  );
}

// function StatusSelect() {
//   const newProjectCtx = useNewProjectContext();
//   const statusGroupsCtx = useStatusGroups();
//   useEffect(() => {
//     if (statusGroupsCtx.state.statusGroups.length > 0) {
//       newProjectCtx.dispatch({
//         type: "STATUS_GROUP_ID_UPDATE",
//         statusGroupId: statusGroupsCtx.state.statusGroups[0]._id,
//       });
//     }
//   }, []);

//   const selectedStatusGroup = statusGroupsCtx.state.statusGroups.find(
//     (sg) => sg._id?.toString() === newProjectCtx.state.statusGroupId
//   );

//   if (statusGroupsCtx.state.statusGroups.length === 1) {
//     return <></>;
//   }
//   return (
//     <div className="space-y-0.5">
//       <InputHeading heading="Status Group" />
//       <Select
//         value={newProjectCtx.state.statusGroupId}
//         onValueChange={(statusGroupId) => {
//           newProjectCtx.dispatch({
//             type: "STATUS_GROUP_ID_UPDATE",
//             statusGroupId: statusGroupId,
//           });
//         }}
//       >
//         <SelectTrigger className="h-9 w-72 gap-2 truncate">
//           {selectedStatusGroup ? (
//             <div>{selectedStatusGroup.name}</div>
//           ) : (
//             <div>Select a status group</div>
//           )}
//         </SelectTrigger>
//         <SelectContent className="max-h-[30vh] min-w-[120px]">
//           <SelectGroup>
//             {statusGroupsCtx.state.statusGroups.map((sg) => (
//               <SelectItem key={sg._id} value={sg._id}>
//                 {sg.name}
//               </SelectItem>
//             ))}
//           </SelectGroup>
//         </SelectContent>
//       </Select>
//     </div>
//   );
// }

function BackButton({ onClick }: { onClick: () => void }) {
  return (
    <div className="absolute bottom-6 left-8">
      <Button
        variant={"secondaryLink"}
        size={"sm"}
        className="px-0"
        onClick={onClick}
      >
        <ChevronLeftIcon className="mr-1 h-3 w-3" />
        Back
      </Button>
    </div>
    // <div
    //   className="absolute bottom-6 left-8 flex cursor-pointer items-center gap-2 text-gray-400/80 transition-all hover:text-gray-500"
    //   onClick={() => {
    //     newProjectCtx.dispatch({
    //       type: "STEP_UPDATE",
    //       step: newProjectCtx.state.step - 1,
    //     });
    //   }}
    // >
    //   <ChevronLeftIcon className="h-2.5 w-2.5" />
    //   <div className="text-xs">Back</div>
    // </div>
  );
}

function AddProjectName() {
  const newProjectCtx = useNewProjectContext();
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    newProjectCtx.dispatch({
      type: "PROJECT_LAUNCH_STATE_UPDATE",
      projectLaunchState: "details",
    });
  }, []);

  return (
    <div className="relative flex h-72 w-full flex-col space-y-2 p-6 px-8">
      <label className="block text-sm font-medium leading-none text-gray-700">
        Project Name
      </label>
      <div className="space-y-1.5">
        <TextInput
          spellCheck={false}
          autoFocus
          value={newProjectCtx.state.projectName}
          onChange={(e) => {
            newProjectCtx.dispatch({
              type: "PROJECT_NAME_UPDATE",
              projectName: e.target.value,
            });
            if (e.target.value.trim() !== "") {
              setErrorText("");
            }
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              if (newProjectCtx.state.projectName.trim() === "") {
                setErrorText("Project name is required");
              } else {
                newProjectCtx.dispatch({
                  type: "STEP_UPDATE",
                  step: newProjectCtx.state.step + 1,
                });
              }
            }
          }}
          placeholder={
            !!newProjectCtx.state.customer
              ? `${newProjectCtx.state.customer.name}'s Project`
              : ""
          }
          className="peer w-72"
        />
        <div
          className={clsx(
            "text-xs text-transparent transition-all ease-out",
            errorText === ""
              ? "peer-focus:text-gray-400"
              : "peer-focus:text-red-500"
          )}
        >
          {errorText === "" ? (
            <>
              Press <span className="font-medium">Enter</span> to continue
            </>
          ) : (
            <>{errorText}</>
          )}
        </div>
      </div>
      {/* <Button
        onClick={() => {
          newProjectCtx.dispatch({
            type: "STEP_UPDATE",
            step: 2,
          });
        }}
        disabled={newProjectCtx.state.projectName.trim() === ""}
      >
        Next
      </Button> */}
      <BackButton
        onClick={() => {
          newProjectCtx.dispatch({
            type: "STEP_UPDATE",
            step: newProjectCtx.state.step - 1,
          });
        }}
      />
      <NextButton
        disabled={newProjectCtx.state.projectName.trim() === ""}
        onClick={() => {
          newProjectCtx.dispatch({
            type: "STEP_UPDATE",
            step: newProjectCtx.state.step + 1,
          });
        }}
      />
    </div>
  );
}

function NextButton({
  disabled,
  onClick,
}: {
  disabled: boolean;
  onClick: () => void;
}) {
  const newProjectCtx = useNewProjectContext();
  return (
    <div className="absolute bottom-6 right-8 ">
      <Button
        onClick={onClick}
        disabled={disabled}
        variant={"link"}
        size="sm"
        className="px-0"
      >
        Next
        <ChevronRightIcon className="ml-1.5 h-3 w-3" />
      </Button>
    </div>
  );
}

function SelectTemplate() {
  const newProjectCtx = useNewProjectContext();
  const { journeyTemplates } = useGetJourneyTemplates();
  console.log(journeyTemplates);
  return (
    <Select
      value={newProjectCtx.state.journeyTemplate?.name}
      onValueChange={(templateId) => {
        const t = journeyTemplates?.find(
          (template) => template._id?.toString() === templateId
        );
        if (!t) return;
        //set the journey template
        newProjectCtx.dispatch({
          type: "JOURNEY_TEMPLATE_UPDATE",
          journeyTemplate: t,
        });
        // set the journey template's internal taskers
        let internalTempTaskers: InternalProjectTaskers = {};
        if (t.internalPersonasWithTasks) {
          for (const ip of t.internalPersonasWithTasks) {
            internalTempTaskers[ip] = undefined;
          }
        }
        newProjectCtx.dispatch({
          type: "INTERNAL_TASKERS_UPDATE",
          internalTaskers: internalTempTaskers,
        });
        // set the journey template's external taskers
        let externalTempTaskers: ExternalProjectTaskers = {};
        if (t.externalPersonasWithTasks) {
          for (const ip of t.externalPersonasWithTasks) {
            externalTempTaskers[ip] = undefined;
          }
        }
        newProjectCtx.dispatch({
          type: "EXTERNAL_TASKERS_UPDATE",
          externalTaskers: externalTempTaskers,
        });
      }}
    >
      <SelectTrigger className="h-9 w-72 gap-2 truncate">
        <div className="flex items-center space-x-2 truncate">
          <RectangleStackIcon className="h-4 w-4 shrink-0 text-gray-700" />
          <SelectValue>
            {!!newProjectCtx.state.journeyTemplate ? (
              <div className="truncate">
                {newProjectCtx.state.journeyTemplate.name}
              </div>
            ) : (
              <div className="flex space-x-1 text-gray-400/80">Template</div>
            )}
          </SelectValue>
        </div>
      </SelectTrigger>
      <SelectContent className="max-h-[30vh] min-w-[120px]">
        <SelectGroup>
          {journeyTemplates
            ?.sort((t1, t2) => t2.numberOfRuns - t1.numberOfRuns)
            .map((t) => (
              <SelectItem
                key={t._id?.toString()}
                value={t._id?.toString() ?? ""}
                // disabled={t.numberOfRuns === 0}
              >
                <div className="inline-flex items-center space-x-2">
                  <span
                    className={
                      "mr-3 rounded-full bg-gray-100 py-0.5 px-2.5 text-xs font-medium text-gray-900 data-[state=active]:bg-primary-100 data-[state=active]:text-primary-600 md:inline-block"
                    }
                  >
                    {t.numberOfRuns}
                  </span>
                  <span>{t.name}</span>
                </div>
              </SelectItem>
            ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

function BlankOrTemplate() {
  const newProjectCtx = useNewProjectContext();

  return (
    <div className="relative flex w-full flex-col">
      <div className="flex-1 space-y-7 p-8 px-10">
        <div className="text-xl font-medium leading-none">Getting Started</div>
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            How would you like to start?
          </label>
          {/* <div className="mt-6 text-lg font-medium leading-none">
                How would you like to start?
              </div> */}
          <div className="grid w-fit grid-cols-2 gap-3">
            <div
              className="flex w-40 cursor-pointer flex-col items-center rounded-md border py-6 shadow-sm hover:bg-gray-50"
              onClick={() => {
                newProjectCtx.dispatch({
                  type: "PROJECT_START_UPDATE",
                  projectStart: "blank",
                });
                newProjectCtx.dispatch({
                  type: "NEXT_STEP",
                });
              }}
            >
              <PlusIcon className="h-6 w-6 text-gray-500" />
              <div className="flex flex-col items-center space-y-1.5 pt-2">
                <div className="font-medium leading-none text-gray-700">
                  Blank
                </div>
                <div className="text-xs leading-none text-gray-500">
                  Start from scratch
                </div>
              </div>
            </div>
            {newProjectCtx.state.projectType === "external" && (
              <div
                className={clsx(
                  "flex w-40 cursor-pointer flex-col items-center rounded-md border py-6 shadow-sm hover:bg-gray-50"
                )}
                onClick={() => {
                  newProjectCtx.dispatch({
                    type: "PROJECT_START_UPDATE",
                    projectStart: "template",
                  });
                  newProjectCtx.dispatch({
                    type: "NEXT_STEP",
                  });
                  // newProjectCtx.dispatch({
                  //   type: "STEP_UPDATE",
                  //   step: newProjectCtx.state.step + 1,
                  // });
                }}
              >
                <RectangleStackIcon className="h-6 w-6 text-gray-500" />
                <div className="flex flex-col items-center space-y-1.5 pt-2">
                  <div className="font-medium leading-none text-gray-700">
                    Template
                  </div>
                  <div className="text-center text-xs leading-none text-gray-500">
                    Choose from library
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-end justify-between border-t border-gray-200/80 px-10 py-6">
        <Button
          variant={"secondary"}
          onClick={() => {
            newProjectCtx.dispatch({
              type: "PREVIOUS_STEP",
            });
          }}
        >
          Back
        </Button>
        {/* <Button
          disabled={!newProjectCtx.state.customer}
          onClick={() => {
            newProjectCtx.dispatch({
              type: "NEXT_STEP",
            });
          }}
        >
          Next
        </Button> */}
      </div>

      {/* <>
              

              <BackButton
                onClick={() => {
                  newProjectCtx.dispatch({
                    type: "PROJECT_START_UPDATE",
                    projectStart: "blank",
                  });
                }}
              />
              <NextButton
                // disabled={
                //   !newProjectCtx.state.journeyTemplate ||
                //   newProjectCtx.state.projectName.trim() === "" ||
                //   !newProjectCtx.state.customer
                // }
                disabled={false}
                onClick={() => {
                  if (
                    !!newProjectCtx.state.journeyTemplate &&
                    !!newProjectCtx.state.customer &&
                    newProjectCtx.state.projectName.trim() !== ""
                  ) {
                    setStep(1);
                  } else {
                    // set error text
                    if (!newProjectCtx.state.journeyTemplate) {
                      setTemplateErrorText("Template is required");
                    }
                    if (!newProjectCtx.state.customer) {
                      setCustomerErrorText("Company is required");
                    }
                    if (newProjectCtx.state.projectName.trim() === "") {
                      setProjectNameErrorText("Project name is required");
                    }
                  }
                }}
              />
            </> */}
    </div>
  );
}

function arrayOfStringsToString(array: string[]) {
  // add commas between each string with an and at the end
  if (array.length === 0) {
    return "";
  } else if (array.length === 1) {
    return array[0];
  }
  const lastString = array[array.length - 1];
  array.pop();
  const joinedString = array.join(", ");
  return joinedString + " and " + lastString;
}

function CustomFields() {
  const newProjectCtx = useNewProjectContext();
  const [open, setOpen] = useState(false);
  const { data: customFields } = useGetCustomFields("project");

  function FieldsBeingPulledFromTheDeal() {
    if (!newProjectCtx.state.deal) {
      return <></>;
    }
    const customFieldsToBePulledFromDeal =
      newProjectCtx.state.customFieldRecords.filter((cfr) => !!cfr.id);
    if (customFieldsToBePulledFromDeal.length === 0) {
      return <></>;
    }
    return (
      <div className="w-fit space-y-2 rounded-md bg-gray-50 p-4 py-3.5 text-sm">
        <div className="text-xs font-medium">NOTE</div>
        <div className="text-gray-500">
          {`Field${customFieldsToBePulledFromDeal.length > 1 ? "s" : ""}`}{" "}
          {arrayOfStringsToString(
            customFieldsToBePulledFromDeal.map((cfr) => cfr.name)
          )}{" "}
          will automatically be pulled from HubSpot based on the deal you
          selected.
        </div>
      </div>
    );
  }
  // function FieldsBeingPulledFromTheDeal() {
  //   if (!newProjectCtx.state.deal) {
  //     return <></>;
  //   }
  //   const customFieldsToBePulledFromDeal =
  //     newProjectCtx.state.customFieldRecords.filter(
  //       (cfr) =>
  //         !!cfr.id &&
  //         !!newProjectCtx.state.deal?.crmProperties?.find(
  //           (dcp) => dcp.id === cfr.id
  //         )
  //     );

  //   return (
  //     <div>
  //       {customFieldsToBePulledFromDeal
  //         .filter((cfr) => !!cfr.id)
  //         .map((cfr) => (
  //           <>
  //             {cfr.id && (
  //               <div className="space-y-1">
  //                 <InputHeading heading={cfr.name} />
  //                 <ValueFromDeal id={cfr.id} />
  //               </div>
  //             )}
  //           </>
  //         ))}
  //     </div>
  //   );
  // }

  // function ValueFromDeal({ id }: { id: string }) {
  //   const dcp = newProjectCtx.state.deal?.crmProperties?.find(
  //     (dcp) => dcp.id === id
  //   );
  //   if (dcp) {
  //     if (dcp.type === "number" || dcp.type === "string") {
  //       return <div>{dcp.value}</div>;
  //     } else if (dcp.type === "bool") {
  //       return <div>{dcp.value ? "Yes" : "No"}</div>;
  //     } else if (dcp.type === "enumeration") {
  //       console.log("1");
  //       console.log(dcp.fieldType);
  //       if (dcp.fieldType === "select") {
  //         console.log("2");
  //         // find value from custom field options
  //         const customField = customFields?.find(
  //           (cf) => cf.crmProperty?.id === dcp.id
  //         );
  //         if (customField && customField.type === "select") {
  //           console.log("3");
  //           return (
  //             <div>
  //               {customField.options.find((o) => o.value === dcp.value)?.label}
  //             </div>
  //           );
  //         }
  //       }
  //     }
  //   }
  //   return null;
  //   // return (
  //   //   <div>Not {dcp.integrationId === "hubspot" ? "HubSpot" : "HubSpot"}</div>
  //   // );
  // }
  return (
    <div className="relative flex w-full flex-col overflow-auto">
      <div className="flex flex-1 flex-col space-y-7 overflow-auto p-8 px-10">
        <div className="text-xl font-medium leading-none">Project Fields</div>
        <div className="flex-1">
          <div className="flex flex-wrap items-start gap-6">
            {/* <div className="space-y-1">
          <InputHeading heading={"Revenue"} />
          <div className="relative">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <div
                className={clsx(
                  newProjectCtx.state.revenue
                    ? "text-gray-600"
                    : "text-gray-400"
                )}
              >
                $
              </div>
            </div>
            <TextInput
              value={newProjectCtx.state.revenue ?? ""}
              className="w-72 pl-7"
              placeholder="10,000"
              onChange={(e) => {
                const { value } = e.target;
                if (value === "") {
                  newProjectCtx.dispatch({
                    type: "REVENUE_UPDATE",
                    revenue: undefined,
                  });
                } else {
                  if (!isNaN(Number(value)) || /^\d*$/.test(value)) {
                    // Convert the valid number string to a number type
                    newProjectCtx.dispatch({
                      type: "REVENUE_UPDATE",
                      revenue: Number(value),
                    });
                  }
                }
              }}
            />
          </div>
        </div> */}
            {newProjectCtx.state.customFieldRecords
              .filter((cfr) => !cfr.id)
              .map((customFieldRecord) => (
                <CustomField
                  key={customFieldRecord._id}
                  customFieldId={customFieldRecord._id}
                />
              ))}
          </div>
        </div>

        <FieldsBeingPulledFromTheDeal />
      </div>
      <div className="flex items-end justify-between border-t border-gray-200/80 px-10 py-6">
        <Button
          variant={"secondary"}
          onClick={() => {
            newProjectCtx.dispatch({
              type: "PREVIOUS_STEP",
            });
          }}
        >
          Back
        </Button>
        <Button
          onClick={() => {
            newProjectCtx.dispatch({
              type: "NEXT_STEP",
            });
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

function CustomField({ customFieldId }: { customFieldId: string }) {
  const { data: customFields } = useGetCustomFields("project");
  const newProjectCtx = useNewProjectContext();
  const customField = customFields?.find((cf) => cf._id === customFieldId);
  if (!customField) return <></>;

  switch (customField.type) {
    case "text":
      return <CustomFieldText customField={customField} />;
    case "number":
      return <CustomFieldNumber customField={customField} />;
    case "date":
      return <CustomFieldDate customField={customField} />;
    case "select":
      return <CustomFieldSelect customField={customField} />;
    case "multi-select":
      return <CustomFieldMultiSelect customField={customField} />;
    case "currency":
      return <CustomFieldCurrency customField={customField} />;
    case "boolean":
      return <CustomFieldBoolean customField={customField} />;
    default:
      return <></>;
  }
}

function CustomFieldBoolean({
  customField,
}: {
  customField: CustomFieldBooleanData;
}) {
  const newProjectCtx = useNewProjectContext();
  const customFieldRecord = newProjectCtx.state.customFieldRecords.find(
    (cf) => cf._id === customField._id
  );
  if (!customFieldRecord) return <></>;
  if (customFieldRecord.type !== "boolean") return <></>;

  return (
    <div className="space-y-1">
      <InputHeading heading={customField.name} />
      <Select
        value={
          customFieldRecord.value === null
            ? undefined
            : customFieldRecord.value
            ? "true"
            : "false"
        }
        onValueChange={(value) => {
          // update the custom field value while keeping the other custom field values the same
          newProjectCtx.dispatch({
            type: "CUSTOM_FIELDS_UPDATE",
            customFieldRecords: newProjectCtx.state.customFieldRecords.map(
              (cf) => {
                if (cf._id === customField._id && cf.type === "boolean") {
                  return {
                    ...cf,
                    value: value === "true",
                  };
                }
                return cf;
              }
            ),
          });
        }}
      >
        <SelectTrigger className="h-9 w-72 gap-2 truncate">
          <div className="flex items-center space-x-2 truncate">
            {/* <RectangleStackIcon className="h-4 w-4 shrink-0 text-gray-700" /> */}
            <SelectValue>
              {customFieldRecord.value !== null ? (
                <div className="truncate">
                  {customFieldRecord.value ? "Yes" : "No"}
                </div>
              ) : (
                <div className="flex space-x-1 text-gray-400/80">Select</div>
              )}
            </SelectValue>
          </div>
        </SelectTrigger>
        <SelectContent className="max-h-[30vh] min-w-[120px]">
          <SelectGroup>
            <SelectItem value={"true"}>Yes</SelectItem>
            <SelectItem value={"false"}>No</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}

function CustomFieldCurrency({
  customField,
}: {
  customField: CustomFieldCurrencyData;
}) {
  const newProjectCtx = useNewProjectContext();
  const customFieldRecord = newProjectCtx.state.customFieldRecords.find(
    (cf) => cf._id === customField._id
  );

  if (!customFieldRecord || customFieldRecord.type !== "currency") return <></>;

  const formatValue = (value: number | null) => {
    if (!value) return "";
    // Allow only numbers and a single decimal point
    const cleaned = String(value).replace(/[^\d.]/g, "");
    const parts = cleaned.split(".");
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.length > 1 ? `${integerPart}.${parts[1]}` : integerPart;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue === "") {
      newProjectCtx.dispatch({
        type: "CUSTOM_FIELDS_UPDATE",
        customFieldRecords: newProjectCtx.state.customFieldRecords.map((cf) => {
          if (cf._id === customField._id && cf.type === "currency") {
            return { ...cf, value: null };
          }
          return cf;
        }),
      });
    } else {
      const numericValue = newValue.includes(".")
        ? parseFloat(newValue.replace(/,/g, ""))
        : parseInt(newValue.replace(/,/g, ""), 10);
      newProjectCtx.dispatch({
        type: "CUSTOM_FIELDS_UPDATE",
        customFieldRecords: newProjectCtx.state.customFieldRecords.map((cf) => {
          if (cf._id === customField._id && cf.type === "currency") {
            return { ...cf, value: numericValue };
          }
          return cf;
        }),
      });
    }
  };

  return (
    <div className="space-y-1">
      <InputHeading heading={customField.name} />
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2.5">
          <div className="text-xs text-gray-600">
            {customField.currencyCode}
          </div>
        </div>
        <TextInput
          value={formatValue(customFieldRecord.value)}
          className="w-72 pl-11"
          placeholder="100,000"
          type="text" // Changed to text to allow custom formatting
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

function CustomFieldMultiSelect({
  customField,
}: {
  customField: CustomFieldMultiSelectData;
}) {
  const newProjectCtx = useNewProjectContext();
  const [open, setOpen] = useState(false);
  const customFieldRecord = newProjectCtx.state.customFieldRecords.find(
    (cf) => cf._id === customField._id
  );
  if (!customFieldRecord) return <></>;
  if (customFieldRecord.type !== "multi-select") return <></>;
  return (
    <div className="space-y-1">
      <InputHeading heading={customField.name} />
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant={"secondary"}
            className="h-fit min-h-[36px] w-72 justify-between gap-1 pl-1.5"
          >
            {!!customFieldRecord?.value?.length ? (
              <div className="flex flex-wrap gap-1">
                {customFieldRecord.value.map((value) => (
                  <Badge key={value}>
                    {customField.options.find((o) => o.value === value)?.label}
                  </Badge>
                ))}
              </div>
            ) : (
              <div className="px-1.5 font-normal text-gray-300">
                Select values..
              </div>
            )}
            <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
          align="start"
        >
          <Command>
            <CommandInput placeholder="Search.." className="h-9" />
            <CommandEmpty>No options found.</CommandEmpty>
            <CommandGroup>
              {customField?.options
                // ?.filter(
                //   (option) => !customFieldRecord.value.includes(option)
                // )
                ?.map((option) => (
                  <CommandItem
                    key={option.value}
                    value={option.label}
                    onSelect={() => {
                      // select or deselect the option
                      if (
                        customFieldRecord.value &&
                        customFieldRecord.value.includes(option.value)
                      ) {
                        newProjectCtx.dispatch({
                          type: "CUSTOM_FIELDS_UPDATE",
                          customFieldRecords:
                            newProjectCtx.state.customFieldRecords.map((cf) => {
                              if (
                                cf._id === customField._id &&
                                cf.type === "multi-select"
                              ) {
                                return {
                                  ...cf,
                                  value:
                                    cf.value?.filter(
                                      (v) => v !== option.value
                                    ) ?? null,
                                };
                              }
                              return cf;
                            }),
                        });
                      } else {
                        newProjectCtx.dispatch({
                          type: "CUSTOM_FIELDS_UPDATE",
                          customFieldRecords:
                            newProjectCtx.state.customFieldRecords.map((cf) => {
                              if (
                                cf._id === customField._id &&
                                cf.type === "multi-select"
                              ) {
                                return {
                                  ...cf,
                                  value: [...(cf.value ?? []), option.value],
                                };
                              }
                              return cf;
                            }),
                        });
                      }
                    }}
                    className="gap-2"
                  >
                    <Checkbox
                      // onClick={(e) => {
                      //   e.stopPropagation();
                      // }}
                      checked={
                        !!customFieldRecord.value &&
                        customFieldRecord.value.includes(option.value)
                      }
                      // onCheckedChange={(checked) => {
                      //   if (checked) {
                      //     newProjectCtx.dispatch({
                      //       type: "CUSTOM_FIELDS_UPDATE",
                      //       customFieldRecords:
                      //         newProjectCtx.state.customFieldRecords.map(
                      //           (cf) => {
                      //             if (
                      //               cf._id === customField._id &&
                      //               cf.type === "multi-select"
                      //             ) {
                      //               return {
                      //                 ...cf,
                      //                 value: [...(cf.value ?? []), option],
                      //               };
                      //             }
                      //             return cf;
                      //           }
                      //         ),
                      //     });
                      //   } else {
                      //     newProjectCtx.dispatch({
                      //       type: "CUSTOM_FIELDS_UPDATE",
                      //       customFieldRecords:
                      //         newProjectCtx.state.customFieldRecords.map(
                      //           (cf) => {
                      //             if (
                      //               cf._id === customField._id &&
                      //               cf.type === "multi-select"
                      //             ) {
                      //               return {
                      //                 ...cf,
                      //                 value:
                      //                   cf.value?.filter((v) => v !== option) ??
                      //                   null,
                      //               };
                      //             }
                      //             return cf;
                      //           }
                      //         ),
                      //     });
                      //   }
                      // }}
                    />

                    <div className="flex items-center space-x-2">
                      <span className="truncate">{option.label}</span>
                    </div>
                  </CommandItem>
                  // <CommandItem
                  //   key={option}
                  //   onSelect={() => {
                  //     // update the custom field value while keeping the other custom field values the same
                  //     newProjectCtx.dispatch({
                  //       type: "CUSTOM_FIELDS_UPDATE",
                  //       customFieldRecords:
                  //         newProjectCtx.state.customFieldRecords.map(
                  //           (cf) => {
                  //             if (
                  //               cf._id === customField._id &&
                  //               cf.type === "multi-select"
                  //             ) {
                  //               return {
                  //                 ...cf,
                  //                 value: [...cf.value, option],
                  //               };
                  //             }
                  //             return cf;
                  //           }
                  //         ),
                  //     });
                  //     setOpen(false);
                  //   }}
                  //   className="gap-2"
                  // >
                  //   <span className="truncate">{option}</span>
                  // </CommandItem>
                ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}

function CustomFieldSelect({
  customField,
}: {
  customField: CustomFieldSelectData;
}) {
  const newProjectCtx = useNewProjectContext();
  const customFieldRecord = newProjectCtx.state.customFieldRecords.find(
    (cf) => cf._id === customField._id
  );
  if (!customFieldRecord) return <></>;
  if (customFieldRecord.type !== "select") return <></>;

  return (
    <div className="space-y-1">
      <InputHeading heading={customField.name} />
      <Select
        value={customFieldRecord.value ?? undefined}
        onValueChange={(value) => {
          // update the custom field value while keeping the other custom field values the same
          newProjectCtx.dispatch({
            type: "CUSTOM_FIELDS_UPDATE",
            customFieldRecords: newProjectCtx.state.customFieldRecords.map(
              (cf) => {
                if (cf._id === customField._id && cf.type === "select") {
                  return {
                    ...cf,
                    value: value,
                  };
                }
                return cf;
              }
            ),
          });
        }}
      >
        <SelectTrigger className="h-9 w-72 gap-2 truncate">
          <div className="flex items-center space-x-2 truncate">
            {/* <RectangleStackIcon className="h-4 w-4 shrink-0 text-gray-700" /> */}
            <SelectValue>
              {customFieldRecord.value ? (
                <div className="truncate">
                  {
                    customField.options.find(
                      (o) => o.value === customFieldRecord.value
                    )?.label
                  }
                </div>
              ) : (
                <div className="flex space-x-1 text-gray-400/80">Select</div>
              )}
            </SelectValue>
          </div>
        </SelectTrigger>
        <SelectContent className="max-h-[30vh] min-w-[120px]">
          <SelectGroup>
            {customField.options?.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}

function CustomFieldText({ customField }: { customField: CustomFieldData }) {
  const newProjectCtx = useNewProjectContext();
  const customFieldRecord = newProjectCtx.state.customFieldRecords.find(
    (cf) => cf._id === customField._id
  );
  if (!customFieldRecord) return <></>;
  if (customFieldRecord.type !== "text") return <></>;
  return (
    <div className="space-y-1">
      <InputHeading heading={customField.name} />
      <TextInput
        value={customFieldRecord.value ?? ""}
        className="w-72"
        onChange={(e) => {
          // update the custom field value while keeping the other custom field values the same
          newProjectCtx.dispatch({
            type: "CUSTOM_FIELDS_UPDATE",
            customFieldRecords: newProjectCtx.state.customFieldRecords.map(
              (cf) => {
                if (cf._id === customField._id && cf.type === "text") {
                  return {
                    ...cf,
                    value: e.target.value,
                  };
                }
                return cf;
              }
            ),
          });
        }}
      />
    </div>
  );
}

function CustomFieldNumber({ customField }: { customField: CustomFieldData }) {
  const newProjectCtx = useNewProjectContext();
  const customFieldRecord = newProjectCtx.state.customFieldRecords.find(
    (cf) => cf._id === customField._id
  );
  if (!customFieldRecord) return <></>;
  if (customFieldRecord.type !== "number") return <></>;
  return (
    <div className="space-y-1">
      <InputHeading heading={customField.name} />
      <TextInput
        value={customFieldRecord.value ?? ""}
        className="w-72"
        type="number"
        onChange={(e) => {
          // update the custom field value while keeping the other custom field values the same
          newProjectCtx.dispatch({
            type: "CUSTOM_FIELDS_UPDATE",
            customFieldRecords: newProjectCtx.state.customFieldRecords.map(
              (cf) => {
                if (cf._id === customField._id && cf.type === "number") {
                  return {
                    ...cf,
                    value: Number.parseInt(e.target.value),
                  };
                }
                return cf;
              }
            ),
          });
        }}
      />
    </div>
  );
}

function CustomFieldDate({ customField }: { customField: CustomFieldData }) {
  const newProjectCtx = useNewProjectContext();
  const customFieldRecord = newProjectCtx.state.customFieldRecords.find(
    (cf) => cf._id === customField._id
  );
  if (!customFieldRecord) return <></>;
  if (customFieldRecord.type !== "date") return <></>;
  return (
    <div className="space-y-1">
      <InputHeading heading={customField.name} />
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={"secondary"}
            className={cn(
              "h-9 w-72 justify-between text-left font-normal",
              !customFieldRecord.value ? "text-muted-foreground" : ""
            )}
          >
            <div className="inline-flex items-center">
              <CalendarIcon className="mr-2 h-4 w-4" />
              {customFieldRecord.value ? (
                dayjs(customFieldRecord.value).format("ddd DD MMM, YYYY")
              ) : (
                <span className="text-gray-300">Select date..</span>
              )}
            </div>
            <CaretSortIcon className="ml-2 h-4 w-4 text-black opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            mode="single"
            selected={
              customFieldRecord.value
                ? new Date(customFieldRecord.value)
                : undefined
            }
            onSelect={(date) => {
              if (date) {
                newProjectCtx.dispatch({
                  type: "CUSTOM_FIELDS_UPDATE",
                  customFieldRecords:
                    newProjectCtx.state.customFieldRecords.map((cf) => {
                      if (cf._id === customField._id && cf.type === "date") {
                        return {
                          ...cf,
                          value: date.toISOString(),
                        };
                      }
                      return cf;
                    }),
                });
              }
            }}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}

function PanelStep({
  step,
  title,
  isActive,
  isCompleted,
}: {
  step: number;
  title: string;
  isActive: boolean;
  isCompleted: boolean;
}) {
  return (
    <div
      className={clsx(
        "flex items-center space-x-2.5",
        isActive ? "font-medium text-gray-800" : "text-gray-400/80",
        isCompleted && "text-gray-800"
      )}
    >
      <div
        className={clsx(
          "flex h-4 w-4 items-center justify-center rounded-full",
          isActive
            ? "border-[0.5px] border-gray-800 font-medium text-black"
            : "border-[0.5px] border-gray-400/80",
          isCompleted && "border-gray-600 text-gray-800"
        )}
      >
        {isCompleted ? (
          <CheckIcon className="h-3 w-3" />
        ) : (
          <div className="text-[10px]">{step}</div>
        )}
      </div>
      <div className={"text-sm"}>{title}</div>
    </div>
  );
}

function ProjectSummaryPanel() {
  const newProjectCtx = useNewProjectContext();
  const { instance } = useGetInstance();
  return (
    <div className="w-56 shrink-0 space-y-4 p-6">
      {/* <div className="space-y-6">
        
        <div className="space-y-0.5">
          {newProjectCtx.state.step > -1 ? (
            <div className="flex h-4 items-center">
              <div className="text-xs capitalize text-gray-500">
                {newProjectCtx.state.projectType === "external"
                  ? "External Project"
                  : "Internal Project"}
              </div>
            </div>
          ) : (
            <div className="flex h-4 items-center">
              <div className="h-3 w-20 rounded bg-gray-100" />
            </div>
          )}
          {newProjectCtx.state.projectName.trim() === "" ? (
            <div className="flex h-7 items-center">
              <div className="h-6 w-40 rounded bg-gray-100" />
            </div>
          ) : (
            <div className="flex min-h-[28px] items-center">
              <div className="text-lg font-medium leading-snug text-gray-700">
                {newProjectCtx.state.projectName}
              </div>
            </div>
          )}
        </div>
        <div className="space-y-5">
          <div className="space-y-1">
            <div className="text-xs font-medium capitalize text-gray-700">
              {instance?.name}
            </div>
            <div className="flex items-center space-x-1">
              {newProjectCtx.state.internalMembers.slice(0, 3).map((im) => (
                <Avatar text={getMax2Initials(im.name)} />
              ))}
            </div>
          </div>
          {newProjectCtx.state.projectType === "external" &&
            newProjectCtx.state.step > -1 && (
              <div className="space-y-1">
                {!!newProjectCtx.state.customer?._id &&
                newProjectCtx.state.step > -1 ? (
                  <div className="flex h-4 items-center">
                    <div className="text-xs font-medium capitalize text-gray-700">
                      {newProjectCtx.state.customer?.name}
                    </div>
                  </div>
                ) : (
                  <div className="flex h-4 items-center">
                    <div className="h-3 w-20 rounded bg-gray-100" />
                  </div>
                )}
                {!!newProjectCtx.state.customer?._id &&
                newProjectCtx.state.step > 5 ? (
                  <div className="flex items-center space-x-1">
                    {newProjectCtx.state.externalMembers
                      .slice(0, 3)
                      .map((im) => (
                        <Avatar text={getMax2Initials(im.email)} />
                      ))}
                  </div>
                ) : (
                  <div className="flex items-center space-x-1">
                    {Array(3)
                      .fill(1)
                      .map((_, i) => (
                        <div key={i} className="h-5 w-5 rounded bg-gray-100" />
                      ))}
                  </div>
                )}
              </div>
            )}
        </div>
      </div> */}
      <div className="space-y-1">
        <PanelStep
          step={1}
          isActive={newProjectCtx.state.step < 1}
          title={"Type"}
          isCompleted={newProjectCtx.state.step > 0}
        />
        {/* {newProjectCtx.state.step > -1 && (
          <div className="space-y-1 pl-6">
            {newProjectCtx.state.step > -1 && (
              <Badge>
                {newProjectCtx.state.projectType === "external"
                  ? "External"
                  : "Internal"}
              </Badge>
            )}
            {newProjectCtx.state.projectType === "external" &&
              newProjectCtx.state.step > 0 && (
                <Badge variant={"gray"}>
                  {newProjectCtx.state.customer?.name}
                </Badge>
              )}
          </div>
        )} */}
      </div>
      <div className="space-y-1">
        <PanelStep
          step={2}
          isActive={
            newProjectCtx.state.step > 0 && newProjectCtx.state.step < 4
          }
          title={"Details"}
          isCompleted={newProjectCtx.state.step > 3}
        />
        {/* {newProjectCtx.state.step > 1 && (
          <div className="space-y-1 pl-6">
            {newProjectCtx.state.step > 1 && (
              <Badge>
                {newProjectCtx.state.projectStart === "template"
                  ? "Template"
                  : "Blank"}
              </Badge>
            )}
            <div>
              {newProjectCtx.state.step > 2 && (
                <Badge className="max-w-full">
                  <div className="max-w-full truncate">
                    {newProjectCtx.state.projectName}
                  </div>
                </Badge>
              )}
            </div>
          </div>
        )} */}
      </div>
      <div className="space-y-1">
        <PanelStep
          step={3}
          isActive={
            newProjectCtx.state.step > 3 && newProjectCtx.state.step < 8
          }
          title={"Members"}
          isCompleted={newProjectCtx.state.step > 7}
        />

        {/* {newProjectCtx.state.step > 4 && (
          <div className="space-y-1 pl-6">
            <div className="flex items-center space-x-1">
              {newProjectCtx.state.internalMembers.slice(0, 3).map((im) => (
                <Avatar text={getMax2Initials(im.name)} />
              ))}
              {newProjectCtx.state.internalMembers.length > 3 && (
                <div className="flex items-center space-x-2">
                  <Avatar
                    text={`+${newProjectCtx.state.internalMembers.length - 3}`}
                  />
                </div>
              )}
            </div>
          </div>
        )} */}
      </div>
      <PanelStep
        step={4}
        isActive={newProjectCtx.state.step === 8}
        title={"Review"}
        isCompleted={false}
      />
      {/* <div className={clsx("flex items-center space-x-3 text-gray-400/80")}>
        <div
          className={clsx(
            "flex h-[17px] w-[17px] items-center justify-center rounded-full border border-dashed border-gray-400/70"
          )}
        />
        <div className="h-5 w-32 rounded border border-dashed border-gray-400/70 text-sm font-light" />
      </div>
      <div className={clsx("flex items-center space-x-3 text-gray-400/80")}>
        <div
          className={clsx(
            "flex h-[17px] w-[17px] items-center justify-center rounded-full border border-dashed border-gray-400/70"
          )}
        />
        <div className="h-5 w-32 rounded border border-dashed border-gray-400/70 text-sm font-light" />
      </div>
      <div className={clsx("flex items-center space-x-3 text-gray-400/80")}>
        <div
          className={clsx(
            "flex h-[17px] w-[17px] items-center justify-center rounded-full border border-dashed border-gray-400/70"
          )}
        />
        <div className="h-5 w-32 rounded border border-dashed border-gray-400/70 text-sm font-light" />
      </div> */}
    </div>
  );

  if (newProjectCtx.state.step === 0) {
  }
  if (newProjectCtx.state.projectStart === "blank") {
    return <div className="w-56 p-6">blank steps</div>;
  } else {
    return <div className="w-56 p-6">template steps</div>;
  }

  return (
    <div className="w-56 shrink-0 p-6 pt-5">
      <div className="space-y-3">
        <div
          className={clsx(
            "flex items-center space-x-3",
            newProjectCtx.state.step === 0
              ? "text-gray-800"
              : "text-gray-400/80"
          )}
        >
          <div
            className={clsx(
              "flex h-4 w-4 items-center justify-center rounded-full border",
              newProjectCtx.state.step === 0
                ? "border-gray-800"
                : "border-gray-400/80"
            )}
          >
            <div className="text-[10px]">1</div>
          </div>
          <div className="text-sm font-light">Project Start</div>
          {/* <CheckIcon className="h-5 w-5" /> */}
        </div>
        <div
          className={clsx(
            "flex items-center space-x-3",
            newProjectCtx.state.step === 1
              ? "text-gray-800"
              : "text-gray-400/80"
          )}
        >
          <div
            className={clsx(
              "flex h-4 w-4 items-center justify-center rounded-full border",
              newProjectCtx.state.step === 1
                ? "border-gray-800"
                : "border-gray-400/80"
            )}
          >
            <div className="text-[10px]">2</div>
          </div>
          <div className="text-sm font-light">Internal Team</div>
        </div>

        <div
          className={clsx(
            "flex items-center space-x-3",
            newProjectCtx.state.step === 2
              ? "text-gray-800"
              : "text-gray-400/80"
          )}
        >
          <div
            className={clsx(
              "flex h-4 w-4 items-center justify-center rounded-full border",
              newProjectCtx.state.step === 2
                ? "border-gray-800"
                : "border-gray-400/80"
            )}
          >
            <div className="text-[10px]">3</div>
          </div>
          <div className="text-sm font-light">Customer Team</div>
        </div>
        <div
          className={clsx(
            "flex items-center space-x-3",
            newProjectCtx.state.step === 3
              ? "text-gray-800"
              : "text-gray-400/80"
          )}
        >
          <div
            className={clsx(
              "flex h-4 w-4 items-center justify-center rounded-full border",
              newProjectCtx.state.step === 3
                ? "border-gray-800"
                : "border-gray-400/80"
            )}
          >
            <div className="text-[10px]">4</div>
          </div>
          <div className="text-sm font-light">Taskers</div>
        </div>
        <div
          className={clsx(
            "flex items-center space-x-3",
            newProjectCtx.state.step === 4
              ? "text-gray-800"
              : "text-gray-400/80"
          )}
        >
          <div
            className={clsx(
              "flex h-4 w-4 items-center justify-center rounded-full border",
              newProjectCtx.state.step === 4
                ? "border-gray-800"
                : "border-gray-400/80"
            )}
          >
            <div className="text-[10px]">5</div>
          </div>
          <div className="text-sm font-light">Invite Email</div>
        </div>
        <div
          className={clsx(
            "flex items-center space-x-3",
            newProjectCtx.state.step === 5
              ? "text-gray-800"
              : "text-gray-400/80"
          )}
        >
          <div
            className={clsx(
              "flex h-4 w-4 items-center justify-center rounded-full border",
              newProjectCtx.state.step === 5
                ? "border-gray-800"
                : "border-gray-400/80"
            )}
          >
            <div className="text-[10px]">6</div>
          </div>
          <div className="text-sm font-light">Launch Confirmation</div>
        </div>
      </div>
      {/* <div className="space-y-4">
        <div className="flex items-center space-x-2">
          <div className="w-20">
            {newProjectCtx.state.step === 0 ? (
              <div className="h-4 w-4 rounded-full border-2" />
            ) : (
              <div className="flex h-4 w-4 items-center justify-center rounded-full border-2">
                <CheckIcon className="h-3 w-3" />
              </div>
            )}
          </div>
        </div>
        <div className="h-10 w-0.5 ml-1.5 bg-gray-200" />
        <div className="flex items-center space-x-2">
          <BuildingOfficeIcon className="h-4 w-4" />
          <div className="text-lg font-medium">Customer</div>
        </div>
        <div className="flex items-center space-x-2">
          <RectangleStackIcon className="h-4 w-4" />
          <div className="text-lg font-medium">Project</div>
        </div>
        <div className="flex items-center space-x-2">
          <RectangleStackIcon className="h-4 w-4" />
          <div className="text-lg font-medium">Internal Team</div>
        </div>
        <div className="flex items-center space-x-2">
          <RectangleStackIcon className="h-4 w-4" />
          <div className="text-lg font-medium">Customer Team</div>
        </div>
      </div> */}
      {/* <div>Step progress tracking</div> */}
    </div>
  );
}

function getMax2Initials(name: string) {
  const initials = name
    .split(" ")
    .map((n) => n[0])
    .join("");
  // return first and last if more than 2
  if (initials.length > 2) {
    return initials[0] + initials[initials.length - 1];
  }
  return initials;
}

function Avatar({ text }: { text: string }) {
  return (
    <div className="inline-flex h-[22px] w-6 cursor-default items-center justify-center rounded border border-gray-500/10 bg-gray-50 text-[11px] text-gray-600">
      {text}
    </div>
  );
}

const options: ["Customer Onboarding"] = ["Customer Onboarding"];

function CustomerCombobox({ onSelect }: { onSelect?: () => void }) {
  const newProjectCtx = useNewProjectContext();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<CustomerData[]>([]);
  const { instance } = useGetInstance();
  const app = useRealmApp();
  const [customerSearch, setCustomerSearch] = useState("");

  const { debouncedValue: debouncedSearch, isTyping } = useDebounce(
    customerSearch,
    500
  );
  const { paginatedCustomers, isError, isFetching, isLoading, isSuccess } =
    useGetCustomersWithDebouncing({
      search: debouncedSearch,
    });

  useEffect(() => {
    if (paginatedCustomers) {
      setOptions(paginatedCustomers.customers);
    }
  }, [paginatedCustomers]);
  const createCustomerMutation = useCreateCustomer();

  useEffect(() => {
    if (createCustomerMutation.isSuccess) {
      setOpen(false);
      setCustomerSearch("");
    }
  }, [createCustomerMutation.isSuccess]);
  const queryClient = useQueryClient();

  return (
    <Popover modal={true} open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="secondary"
          size="lg"
          role="combobox"
          aria-expanded={open}
          className="w-72 justify-between space-x-2 truncate px-2.5"
        >
          <div className="flex min-w-0 items-center space-x-2">
            <BuildingOfficeIcon className="h-4 w-4 shrink-0 text-gray-400" />
            {newProjectCtx.state.customer?.source === "hubspot" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="3 3.5 12 12"
                className="w-4 shrink-0"
              >
                <path
                  fill="#FF5C35"
                  d="M11.54 7.422V6.096a1.02 1.02 0 0 0 .588-.92v-.03c0-.56-.46-1.02-1.02-1.02h-.03c-.561 0-1.02.46-1.02 1.02v.03c0 .407.243.757.589.92v1.326c-.516.08-.986.292-1.375.605l-3.64-2.832a1.14 1.14 0 0 0 .04-.286 1.15 1.15 0 1 0-1.15 1.148c.206 0 .398-.059.566-.154l3.58 2.786a2.892 2.892 0 0 0-.482 1.603c0 .62.196 1.194.527 1.666l-1.089 1.09a.935.935 0 0 0-.27-.045.945.945 0 1 0 .945.945.934.934 0 0 0-.044-.27l1.077-1.077a2.89 2.89 0 0 0 1.761.598 2.905 2.905 0 0 0 .446-5.776Zm-.447 4.36a1.49 1.49 0 1 1 0-2.98 1.49 1.49 0 0 1 0 2.98Z"
                />
              </svg>
            )}
            {newProjectCtx.state.customer ? (
              <div className="truncate">
                {newProjectCtx.state.customer.name}
              </div>
            ) : (
              <div className="font-normal text-gray-300">Tesla</div>
            )}
          </div>
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
        align="start"
      >
        <Command
          filter={(value, search) => {
            if (value === "new") {
              return 1;
            }
            const customerName =
              options.find((c) => c._id.toString() === value)?.name ?? "";
            return customerName.toLowerCase().includes(search.toLowerCase())
              ? 1
              : 0;
          }}
        >
          <CommandInput
            value={customerSearch}
            onValueChange={(s) => {
              setCustomerSearch(s);
            }}
            placeholder="Search company..."
            className="h-9"
          />
          <CommandEmpty>No company found.</CommandEmpty>
          <CommandGroup>
            <CommandItem
              value="new"
              key="new"
              disabled={customerSearch.trim() === ""}
              onSelect={() => {
                createCustomerMutation.mutate({
                  params: {
                    name: customerSearch.trim(),
                    onChange: (customer) => {
                      newProjectCtx.dispatch({
                        type: "CUSTOMER_UPDATE",
                        customer: {
                          _id: customer._id.toString(),
                          name: customer.name,
                        },
                      });
                      newProjectCtx.dispatch({
                        type: "EXTERNAL_MEMBERS_UPDATE",
                        externalMembers: [],
                      });
                      newProjectCtx.dispatch({
                        type: "INVITE_EMAIL_UPDATE",
                        inviteEmail: {
                          subject: `${customer.name} <> ${instance?.name}`,
                          message: `Hi!\n\nI'm excited to invite you to collaborate with me on this project! This platform will help us keep track of tasks, project progress, and ensure efficient communication throughout the project's duration.`,
                          signature: `Best,\n${app.currentUser.customData.name}`,
                        },
                      });
                      newProjectCtx.dispatch({
                        type: "DEAL_UPDATE",
                        deal: undefined,
                      });
                      newProjectCtx.dispatch({
                        type: "PROJECT_NAME_UPDATE",
                        projectName: `${customer.name}'s Project`,
                      });

                      if (onSelect) {
                        onSelect();
                      }
                    },
                  },
                });
              }}
            >
              <div className="flex items-center space-x-2">
                {createCustomerMutation.isLoading ? (
                  <span>Creating..</span>
                ) : (
                  <>
                    <PlusIcon className="h-4 w-4" />
                    <span>Create Company</span>
                  </>
                )}
              </div>
            </CommandItem>
            {options?.map((customer) => (
              <CommandItem
                key={customer._id?.toString()}
                value={customer._id.toString()}
                onSelect={() => {
                  newProjectCtx.dispatch({
                    type: "CUSTOMER_UPDATE",
                    customer: {
                      _id: customer._id.toString(),
                      name: customer.name,
                    },
                  });
                  newProjectCtx.dispatch({
                    type: "EXTERNAL_MEMBERS_UPDATE",
                    externalMembers: [],
                  });
                  newProjectCtx.dispatch({
                    type: "INVITE_EMAIL_UPDATE",
                    inviteEmail: {
                      subject: `${customer.name} <> ${instance?.name}`,
                      message: `Hi!\n\nI'm excited to invite you to collaborate with me on this project! This platform will help us keep track of tasks, track project progress, and ensure efficient communication throughout the project's duration.`,
                      signature: `Best,\n${app.currentUser.customData.name}`,
                    },
                  });
                  newProjectCtx.dispatch({
                    type: "EXTERNAL_MEMBERS_UPDATE",
                    externalMembers: [],
                  });
                  newProjectCtx.dispatch({
                    type: "DEAL_UPDATE",
                    deal: undefined,
                  });
                  newProjectCtx.dispatch({
                    type: "PROJECT_NAME_UPDATE",
                    projectName: `${customer.name}'s Project`,
                  });

                  if (onSelect) {
                    onSelect();
                  }
                  setOpen(false);
                }}
                className="gap-2"
              >
                {customer.source === "hubspot" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="3 3.5 12 12"
                    className="w-4"
                  >
                    <path
                      fill="#FF5C35"
                      d="M11.54 7.422V6.096a1.02 1.02 0 0 0 .588-.92v-.03c0-.56-.46-1.02-1.02-1.02h-.03c-.561 0-1.02.46-1.02 1.02v.03c0 .407.243.757.589.92v1.326c-.516.08-.986.292-1.375.605l-3.64-2.832a1.14 1.14 0 0 0 .04-.286 1.15 1.15 0 1 0-1.15 1.148c.206 0 .398-.059.566-.154l3.58 2.786a2.892 2.892 0 0 0-.482 1.603c0 .62.196 1.194.527 1.666l-1.089 1.09a.935.935 0 0 0-.27-.045.945.945 0 1 0 .945.945.934.934 0 0 0-.044-.27l1.077-1.077a2.89 2.89 0 0 0 1.761.598 2.905 2.905 0 0 0 .446-5.776Zm-.447 4.36a1.49 1.49 0 1 1 0-2.98 1.49 1.49 0 0 1 0 2.98Z"
                    />
                  </svg>
                ) : (
                  <div className="flex w-4 items-center justify-center">
                    <div className="h-1.5 w-1.5 rounded-full bg-gray-300" />
                  </div>
                )}
                <span className="truncate">{customer.name}</span>
                <CheckIcon
                  className={cn(
                    "ml-auto h-4 w-4 shrink-0",
                    newProjectCtx.state.customer?._id?.toString() ===
                      customer?._id?.toString()
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
              </CommandItem>
            ))}
            {/* {isLoading && <div>Loading..</div>} */}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function TemplateSelect({ onChange }: { onChange?: () => void }) {
  const newProjectCtx = useNewProjectContext();
  const { journeyTemplates } = useGetJourneyTemplates();
  const filteredTemplates =
    journeyTemplates?.filter(
      (t) => t.spaceId?.toString() === newProjectCtx.state.spaceId
    ) ?? [];
  const navigate = useNavigate();
  return (
    <Select
      value={newProjectCtx.state.journeyTemplate?.name}
      onValueChange={(templateId) => {
        const t = journeyTemplates?.find(
          (template) => template._id?.toString() === templateId
        );
        if (!t) return;
        //set the journey template
        newProjectCtx.dispatch({
          type: "JOURNEY_TEMPLATE_UPDATE",
          journeyTemplate: t,
        });
        // set the journey template's internal taskers
        let internalTempTaskers: InternalProjectTaskers = {};
        if (t.internalPersonasWithTasks) {
          for (const ip of t.internalPersonasWithTasks) {
            internalTempTaskers[ip] = undefined;
          }
        }
        newProjectCtx.dispatch({
          type: "INTERNAL_TASKERS_UPDATE",
          internalTaskers: internalTempTaskers,
        });
        // set the journey template's external taskers
        let externalTempTaskers: ExternalProjectTaskers = {};
        if (t.externalPersonasWithTasks) {
          for (const ip of t.externalPersonasWithTasks) {
            externalTempTaskers[ip] = undefined;
          }
        }
        newProjectCtx.dispatch({
          type: "EXTERNAL_TASKERS_UPDATE",
          externalTaskers: externalTempTaskers,
        });
        // set the journey template's custom fields
        let customFields: CustomFieldLocalRecord[] = [];
        if (t.customFields) {
          for (const cf of t.customFields) {
            customFields.push({
              _id: cf._id.toString(),
              id: cf.crmProperty?.id,
              type: cf.type,
              value: null,
              name: cf.name,
            });
          }
        }
        newProjectCtx.dispatch({
          type: "CUSTOM_FIELDS_UPDATE",
          customFieldRecords: customFields,
        });

        if (onChange) {
          onChange();
        }
      }}
    >
      <SelectTrigger className="h-9 w-72 gap-2 truncate">
        <div className="flex items-center space-x-2 truncate">
          <RectangleStackIcon className="h-4 w-4 shrink-0 text-gray-700" />
          {!!newProjectCtx.state.journeyTemplate ? (
            <div className="truncate">
              {newProjectCtx.state.journeyTemplate.name}
            </div>
          ) : (
            <div className="text-gray-300">Select Template</div>
          )}
        </div>
      </SelectTrigger>
      <SelectContent className="max-h-[40vh] min-w-[120px]">
        {!filteredTemplates.length ? (
          <div className="text-gray-700 max-w-[288px] p-3 py-2.5 text-sm">
            You do not have any project templates. Click{" "}
            <span
              className="cursor-pointer text-gray-800 underline"
              onClick={() => {
                navigate(
                  `/spaces/${newProjectCtx.state.spaceId}/templates/projects`
                );
              }}
            >
              here
            </span>{" "}
            to create one.
          </div>
        ) : (
          <SelectGroup>
            {journeyTemplates
              ?.filter(
                (t) => t.spaceId?.toString() === newProjectCtx.state.spaceId
              )
              .sort((t1, t2) => t2.numberOfRuns - t1.numberOfRuns)
              .map((t) => (
                <SelectItem
                  key={t._id?.toString()}
                  value={t._id?.toString() ?? ""}
                  // disabled={t.numberOfRuns === 0}
                >
                  <div className="inline-flex items-center space-x-1">
                    <div className="w-10">
                      <div className="mr-3 flex h-[17px] w-fit items-center justify-center rounded bg-gray-100 px-[5px] text-[10px] text-gray-500">
                        {t.numberOfRuns}
                      </div>
                      {/* <span
                    className={
                      "mr-3 rounded-full bg-gray-100 py-0.5 px-2.5 text-xs font-medium text-gray-900 data-[state=active]:bg-primary-100 data-[state=active]:text-primary-600 md:inline-block"
                    }
                    
                  >
                  </span> */}
                    </div>
                    <span>{t.name}</span>
                  </div>
                </SelectItem>
              ))}
          </SelectGroup>
        )}
      </SelectContent>
    </Select>
  );
}

// function SelectCustomerAndProject() {
//   const newProjectCtx = useNewProjectContext();
//   const { journeyTemplates } = useGetJourneyTemplates();
//   return (
//     <div className="w-full p-6 pl-7">
//       <div className="space-y-8">
//         <div>
//           <div className="text-2xl">Project Details</div>
//           <div className="text-sm text-gray-500">
//             Select the customer you would like to collaborate with and the
//             template that matches your objectives
//           </div>
//         </div>{" "}
//         <div className="space-y-6">
//           <div className="space-y-2">
//             {/* <div className="text-lg font-medium">Customer</div> */}
//             <div className="flex space-x-4">
//               <div className="space-y-1">
//                 <InputHeading heading="Customer" required />
//                 <CustomerCombobox />
//               </div>
//               <div className="space-y-1">
//                 <InputHeading heading="Deal Amount" />
//                 <div className="relative">
//                   <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
//                     <div
//                       className={clsx(
//                         newProjectCtx.state.revenue
//                           ? "text-gray-600"
//                           : "text-gray-400"
//                       )}
//                     >
//                       $
//                     </div>
//                   </div>
//                   <TextInput
//                     value={newProjectCtx.state.revenue ?? ""}
//                     className="w-72 pl-7"
//                     onChange={(e) => {
//                       const { value } = e.target;
//                       if (value === "") {
//                         newProjectCtx.dispatch({
//                           type: "REVENUE_UPDATE",
//                           revenue: undefined,
//                         });
//                       } else {
//                         if (!isNaN(Number(value)) || /^\d*$/.test(value)) {
//                           // Convert the valid number string to a number type
//                           newProjectCtx.dispatch({
//                             type: "REVENUE_UPDATE",
//                             revenue: Number(value),
//                           });
//                         }
//                       }
//                     }}
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="space-y-2">
//             {/* <div className="text-lg font-medium">Project</div> */}
//             <div className="flex space-x-4">
//               <div className="space-y-1">
//                 <InputHeading heading="Template" required />
//                 <TemplateSelect />
//               </div>
//               <div className="space-y-1">
//                 <InputHeading heading="Project Name" required />
//                 <TextInput
//                   value={newProjectCtx.state.projectName}
//                   onChange={(e) => {
//                     newProjectCtx.dispatch({
//                       type: "PROJECT_NAME_UPDATE",
//                       projectName: e.target.value,
//                     });
//                   }}
//                   placeholder={
//                     (!!newProjectCtx.state.customer
//                       ? newProjectCtx.state.customer.name
//                       : "Pied Piper") + "'s Onboarding"
//                   }
//                   className="w-72"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="flex justify-end">
//           <Button
//             onClick={() => {
//               newProjectCtx.dispatch({
//                 type: "STEP_UPDATE",
//                 step: newProjectCtx.state.step + 1,
//               });
//             }}
//             disabled={
//               !newProjectCtx.state.customer ||
//               !newProjectCtx.state.journeyTemplate ||
//               newProjectCtx.state.projectName.trim() === ""
//             }
//           >
//             Next
//           </Button>
//         </div>
//       </div>
//       {/* <DialogHeader>
//         <DialogTitle>New Project</DialogTitle>
//         <DialogDescription>Deatails for your new stage</DialogDescription>
//       </DialogHeader>
//       <div className="grid gap-4 py-4">
//         <div className="space-y-1">
//           <InputHeading heading="Name" />
//           <TextInput
//             className="max-w-[300px]"
//             placeholder={
//               (!!newProjectCtx.state.customer
//                 ? newProjectCtx.state.customer.name
//                 : "Pied Piper") + "'s Onboarding"
//             }
//             value={newProjectCtx.state.projectName}
//             onChange={(e) => {
//               newProjectCtx.dispatch({
//                 type: "PROJECT_NAME_UPDATE",
//                 projectName: e.target.value,
//               });
//             }}
//           />
//         </div>
//       </div>
//       <DialogFooter>
//         <DialogClose asChild>
//           <Button>Next</Button>
//         </DialogClose>
//       </DialogFooter> */}
//     </div>
//     // <div className="divide-y">
//     //   <ModalHeading
//     //     title="Project Details"
//     //     subtitle="Select the customer you would like to collaborate with"
//     //   />
//     //   <div className="mt-4 w-full space-y-12 pt-6">
//     //     <div className="flex flex-wrap gap-4">
//     //       <div className="w-[320px] space-y-1">
//     //         <InputHeading heading="Customer" />
//     //         <CustomerCombobox
//     //           value={newProjectCtx.state.customer}
//     //           onChange={(value) => {
//     //             if (value) {
//     //               newProjectCtx.dispatch({
//     //                 type: "CUSTOMER_UPDATE",
//     //                 customer: value,
//     //               });
//     //               if (value.revenue) {
//     //                 newProjectCtx.dispatch({
//     //                   type: "REVENUE_UPDATE",
//     //                   revenue: value.revenue,
//     //                 });
//     //               } else {
//     //                 newProjectCtx.dispatch({
//     //                   type: "REVENUE_UPDATE",
//     //                   revenue: undefined,
//     //                 });
//     //               }
//     //             }
//     //           }}
//     //         />
//     //       </div>
//     //       <div className="max-w-[320px] space-y-1">
//     //         <InputHeading heading="Revenue" />
//     //         <div className="relative mt-2 rounded-md shadow-sm">
//     //           <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500 peer-disabled:text-gray-200">
//     //             <span className="sm:text-sm">$</span>
//     //           </div>
//     //           <input
//     //             disabled={!newProjectCtx.state.customer}
//     //             type="text"
//     //             name="price"
//     //             id="price"
//     //             // className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//     //             className="peer block w-full rounded-md border-gray-300 pl-7 pr-12 shadow-sm placeholder:text-gray-300 focus:border-primary-main focus:ring-primary-main  disabled:bg-gray-100 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm"
//     //             placeholder="0"
//     //             aria-describedby="price-currency"
//     //             value={newProjectCtx.state.revenue ?? ""}
//     //             autoComplete="off"
//     //             onChange={(e) => {
//     //               const { value } = e.target;
//     //               if (value === "") {
//     //                 console.log("value is empty string");
//     //                 newProjectCtx.dispatch({
//     //                   type: "REVENUE_UPDATE",
//     //                   revenue: undefined,
//     //                 });
//     //               } else {
//     //                 if (!isNaN(Number(value)) || /^\d*$/.test(value)) {
//     //                   // Convert the valid number string to a number type
//     //                   newProjectCtx.dispatch({
//     //                     type: "REVENUE_UPDATE",
//     //                     revenue: Number(value),
//     //                   });
//     //                 }
//     //               }

//     //               // const rx = new RegExp(/^\d+$/);
//     //               // if (rx.test(e.target.value) || e.target.value === "") {
//     //               //   newProjectCtx.dispatch({
//     //               //     type: "REVENUE_UPDATE",
//     //               //     revenue: Number(e.target.value),
//     //               //   });
//     //               // }
//     //             }}
//     //           />
//     //           <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
//     //             <span className="text-gray-500 sm:text-sm" id="price-currency">
//     //               USD
//     //             </span>
//     //           </div>
//     //         </div>
//     //       </div>
//     //     </div>
//     //     <div className="flex flex-wrap items-end gap-4">
//     //       <div className="w-[320px] space-y-1">
//     //         <Combobox
//     //           label="Project Template"
//     //           options={
//     //             !!journeyTemplates
//     //               ? journeyTemplates.filter((j) => j.type === "external")
//     //               : []
//     //           }
//     //           displayValue={(value) => (!!value ? value.name : "")}
//     //           onChange={(value) => {
//     //             if (value) {
//     //               // set the journey template
//     //               newProjectCtx.dispatch({
//     //                 type: "JOURNEY_TEMPLATE_UPDATE",
//     //                 journeyTemplate: value,
//     //               });
//     //               // set the journey template's internal taskers
//     //               let internalTempTaskers: InternalProjectTaskers = {};
//     //               if (value.internalPersonas) {
//     //                 for (const ip of value.internalPersonas) {
//     //                   internalTempTaskers[ip] = undefined;
//     //                 }
//     //               }
//     //               newProjectCtx.dispatch({
//     //                 type: "INTERNAL_TASKERS_UPDATE",
//     //                 internalTaskers: internalTempTaskers,
//     //               });
//     //               // set the journey template's external taskers
//     //               let externalTempTaskers: ExternalProjectTaskers = {};
//     //               if (value.externalPersonas) {
//     //                 for (const ip of value.externalPersonas) {
//     //                   externalTempTaskers[ip] = undefined;
//     //                 }
//     //               }
//     //               newProjectCtx.dispatch({
//     //                 type: "EXTERNAL_TASKERS_UPDATE",
//     //                 externalTaskers: externalTempTaskers,
//     //               });
//     //             }
//     //           }}
//     //           optionKeyFn={(value) =>
//     //             value?._id?.toString() ?? Math.random().toString()
//     //           }
//     //           value={newProjectCtx.state.journeyTemplate}
//     //           renderOption={(option) => (
//     //             <div className="flex items-center space-x-2">
//     //               <div className="rounded-md bg-primary-200 px-1.5 py-0.5 text-xs tracking-wide">
//     //                 {`${option?.numberOfRuns} runs`}
//     //               </div>
//     //               <div className="truncate">{option?.name}</div>
//     //             </div>
//     //           )}
//     //           by={(j1, j2) => {
//     //             return j1?._id?.toString() === j2?._id?.toString();
//     //           }}
//     //         />

//     //         {!!journeyTemplates && journeyTemplates.length === 0 && (
//     //           <p className="text-xs text-red-400">
//     //             No journey templates. Create one first!
//     //           </p>
//     //         )}
//     //       </div>

//     //       <div className="w-[300px]">
//     //         <TextInput
//     //           label="Project Name"
//     //           name="projectName"
//     //           placeholder={
//     //             (!!newProjectCtx.state.customer
//     //               ? newProjectCtx.state.customer.name
//     //               : "Pied Piper") + "'s Onboarding"
//     //           }
//     //           value={newProjectCtx.state.projectName}
//     //           onChange={(e) => {
//     //             newProjectCtx.dispatch({
//     //               type: "PROJECT_NAME_UPDATE",
//     //               projectName: e.target.value,
//     //             });
//     //           }}
//     //         />
//     //       </div>
//     //     </div>
//     //     <div className="flex justify-end">
//     //       <Button
//     //         text="Next"
//     //         size="xs"
//     //         onClick={() => {
//     //           newProjectCtx.dispatch({
//     //             type: "STEP_UPDATE",
//     //             step: newProjectCtx.state.step + 1,
//     //           });
//     //         }}
//     //         disabled={
//     //           !newProjectCtx.state.customer ||
//     //           !newProjectCtx.state.journeyTemplate ||
//     //           newProjectCtx.state.projectName.trim() === ""
//     //         }
//     //         // disabled={name.trim() === "" ? true : false}
//     //       />
//     //     </div>
//     //   </div>
//     // </div>
//   );
// }

function InternalTeamSearch() {
  const [open, setOpen] = useState(false);
  const { users } = useGetUsers();
  const [userSearch, setUserSearch] = useState("");
  const newProjectCtx = useNewProjectContext();
  const usersLeft =
    users?.filter(
      (u) =>
        newProjectCtx.state.internalMembers.filter(
          (i) => i._id.toString() === u._id.toString()
        ).length === 0
    ) ?? [];

  useEffect(() => {
    if (usersLeft.length === 0) {
      setOpen(false);
    }
  }, [usersLeft]);
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="secondary"
          size="lg"
          role="combobox"
          aria-expanded={open}
          disabled={usersLeft.length === 0}
          className="w-60 justify-center space-x-2 truncate px-2.5"
        >
          <div className="flex items-center space-x-2">
            {/* <UserIcon className="h-4 w-4 shrink-0 text-gray-400" /> */}
            <div className="font-normal text-gray-600">Add Internal User</div>
          </div>
          {/* <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" /> */}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="pointer-events-auto w-60 p-0" align="start">
        <Command
          filter={(value, search) => {
            const userName =
              users?.find((u) => u._id.toString() === value)?.name ?? "";
            return userName.toLowerCase().includes(search.toLowerCase())
              ? 1
              : 0;
          }}
        >
          <CommandInput
            value={userSearch}
            onValueChange={(s) => {
              setUserSearch(s);
            }}
            placeholder="Search.."
            className="h-9"
          />
          <CommandEmpty>No user found</CommandEmpty>
          <CommandGroup>
            {usersLeft.map((user) => (
              <CommandItem
                key={user._id?.toString()}
                value={user._id.toString()}
                onSelect={() => {
                  newProjectCtx.dispatch({
                    type: "INTERNAL_MEMBERS_UPDATE",
                    internalMembers: [
                      ...newProjectCtx.state.internalMembers,
                      {
                        _id: user._id.toString(),
                        name: user.name,
                        isProjectOwner: false,
                      },
                    ],
                  });
                }}
                className="gap-2"
              >
                <span className="truncate">{user.name}</span>
              </CommandItem>
            ))}
            {/* {isLoading && <div>Loading..</div>} */}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function AddInternalMembers() {
  const newProjectCtx = useNewProjectContext();
  const { users } = useGetUsers();
  const app = useRealmApp();
  const usersLeft =
    users?.filter(
      (u) =>
        newProjectCtx.state.internalMembers.filter(
          (i) => i._id.toString() === u._id.toString()
        ).length === 0
    ) ?? [];

  useEffect(() => {
    newProjectCtx.dispatch({
      type: "PROJECT_LAUNCH_STATE_UPDATE",
      projectLaunchState: "members",
    });
  }, []);

  return (
    <div className="relative flex w-full flex-col">
      <div className="flex-1 space-y-7 p-8 px-10">
        <div className="text-xl font-medium leading-none">Internal Team</div>
        <InternalTeamSearch />

        <div className="space-y-2 pt-2">
          <div className="flex">
            <div className="flex-1 text-sm text-gray-400">User</div>
            <div className="flex w-40 justify-center text-sm text-gray-400">
              Project Owner
            </div>
          </div>
          <div>
            {newProjectCtx.state.internalMembers.map((im) => (
              <div
                key={im._id}
                className="group relative flex h-10 items-center justify-between border-t text-sm"
              >
                <div className="flex flex-1 items-center space-x-2">
                  <div className="truncate font-medium text-gray-700">
                    {im.name}
                  </div>
                </div>
                <div className="flex w-40 justify-center text-sm text-gray-400">
                  <Checkbox
                    disabled={newProjectCtx.state.internalMembers.length === 1}
                    checked={im.isProjectOwner}
                    onCheckedChange={(checked) => {
                      if (newProjectCtx.state.internalMembers.length > 1) {
                        if (checked) {
                          newProjectCtx.dispatch({
                            type: "INTERNAL_MEMBERS_UPDATE",
                            internalMembers:
                              newProjectCtx.state.internalMembers.map((i) => {
                                if (i._id === im._id) {
                                  return {
                                    ...i,
                                    isProjectOwner: !!checked,
                                  };
                                }
                                return {
                                  ...i,
                                  isProjectOwner: false,
                                };
                              }),
                          });
                        }
                      }
                    }}
                  />
                </div>
                {newProjectCtx.state.internalMembers.length > 1 && (
                  <div
                    className="group absolute right-full cursor-pointer pr-2 opacity-0 group-hover:opacity-100"
                    onClick={() => {
                      let newInternalMembers =
                        newProjectCtx.state.internalMembers.filter(
                          (i) => i._id.toString() !== im._id.toString()
                        );
                      const projectOwner = newInternalMembers.find(
                        (i) => i.isProjectOwner
                      );

                      if (projectOwner) {
                        newProjectCtx.dispatch({
                          type: "INTERNAL_MEMBERS_UPDATE",
                          internalMembers: newInternalMembers,
                        });
                      } else {
                        if (newInternalMembers.length > 0) {
                          newInternalMembers[0].isProjectOwner = true;
                          newProjectCtx.dispatch({
                            type: "INTERNAL_MEMBERS_UPDATE",
                            internalMembers: newInternalMembers,
                          });
                        }
                      }
                    }}
                  >
                    <div className="rounded-full p-1 text-gray-400 hover:bg-gray-50 hover:text-gray-600">
                      <XMarkIcon className="h-3 w-3 " />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        {/* <div>
          <div className="flex flex-wrap gap-2">
            {newProjectCtx.state.internalMembers.map((m) => (
              <div
                className={clsx(
                  "inline-flex h-7 w-fit items-center justify-between space-x-2 truncate rounded-full border bg-gray-100 px-3 text-sm",
                  m._id.toString() !== app.currentUser.id ? "pr-1.5" : "pr-3"
                )}
              >
                <div>{m.name}</div>
                {m._id.toString() !== app.currentUser.id && (
                  <div
                    className="shrink-0 cursor-pointer rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-600"
                    onClick={() => {
                      newProjectCtx.dispatch({
                        type: "INTERNAL_MEMBERS_UPDATE",
                        internalMembers:
                          newProjectCtx.state.internalMembers.filter(
                            (i) => i._id.toString() !== m._id.toString()
                          ),
                      });
                    }}
                  >
                    <XMarkIcon className="h-2.5 w-2.5 " />
                  </div>
                )}
              </div>
            ))}
            {newProjectCtx.state.internalMembers.length === 0 && (
              <div
                className={clsx(
                  "h-8 w-20 rounded-full border border-dashed bg-gray-100"
                )}
              />
            )}
          </div>
        </div> */}
      </div>
      <div className="flex items-end justify-between border-t border-gray-200/80 px-10 py-6">
        <Button
          variant={"secondary"}
          onClick={() => {
            newProjectCtx.dispatch({
              type: "PREVIOUS_STEP",
            });
          }}
        >
          Back
        </Button>

        <Button
          onClick={() => {
            newProjectCtx.dispatch({
              type: "NEXT_STEP",
            });
          }}
        >
          Next
        </Button>
      </div>
    </div>
    // <div className="divide-y">
    //   <ModalHeading
    //     title="Internal Team"
    //     subtitle="Add internal members who will be part of the project"
    //   />
    //   <div className="mt-4 w-full space-y-8 pt-6">
    //     <div className="max-w-[250px] space-y-1">
    //       {/* <InputHeading heading="Add Team Member" /> */}
    //       <Combobox
    //         label="Add Team Member"
    //         options={
    //           !!users
    //             ? users.filter(
    //                 (u) =>
    //                   newProjectCtx.state.internalMembers.filter(
    //                     (i) => i._id.toString() === u._id.toString()
    //                   ).length === 0
    //               )
    //             : []
    //         }
    //         onChange={(value) => {
    //           // call mutation
    //           if (value) {
    //             newProjectCtx.dispatch({
    //               type: "INTERNAL_MEMBERS_UPDATE",
    //               internalMembers: [
    //                 ...newProjectCtx.state.internalMembers,
    //                 {
    //                   _id: value._id.toString(),
    //                   name: value.name,
    //                 },
    //               ],
    //             });
    //           }
    //         }}
    //         displayValue={(o) => o?.name ?? ""}
    //         optionKeyFn={(value) =>
    //           value?._id?.toString() ?? Math.random().toString()
    //         }
    //         value={undefined}
    //       />
    //     </div>
    //     <div>
    //       <label className="block text-sm font-medium text-gray-500">
    //         Internal Team
    //       </label>
    //       <div className="mt-2 flex flex-wrap gap-2">
    //         {newProjectCtx.state.internalMembers.map((m) => (
    //           <div className="inline-flex h-8 w-fit items-center justify-between space-x-2 truncate rounded-md border px-2 text-sm">
    //             <div>{m.name}</div>
    //             {m._id.toString() !== app.currentUser.id && (
    //               <div
    //                 className="shrink-0 cursor-pointer rounded-full p-1 text-gray-400 hover:bg-gray-50 hover:text-gray-600"
    //                 onClick={() => {
    //                   newProjectCtx.dispatch({
    //                     type: "INTERNAL_MEMBERS_UPDATE",
    //                     internalMembers:
    //                       newProjectCtx.state.internalMembers.filter(
    //                         (i) => i._id.toString() !== m._id.toString()
    //                       ),
    //                   });
    //                 }}
    //               >
    //                 <XMarkIcon className="h-3 w-3 " />
    //               </div>
    //             )}
    //           </div>
    //         ))}
    //       </div>
    //     </div>
    //     <div className="flex justify-between">
    //       <BackButton />
    //       <Button
    //         onClick={() => {
    //           newProjectCtx.dispatch({
    //             type: "STEP_UPDATE",
    //             step: newProjectCtx.state.step + 1,
    //           });
    //         }}
    //         disabled={!newProjectCtx.state.customer}
    //         // disabled={name.trim() === "" ? true : false}
    //       >
    //         Next
    //       </Button>
    //     </div>
    //   </div>
    // </div>
  );
}

function AddExternalMembers() {
  // const {} = useGetSharedSpaceMembersForJourneyLaunch()
  const newProjectCtx = useNewProjectContext();
  const { data: externalMembers } = useGetExternalCustomerMembers(
    newProjectCtx.state.customer?._id.toString() ?? ""
  );
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState<{ isError: boolean; message: string }>(
    {
      isError: false,
      message: "Press Enter to add",
    }
  );
  const [open, setOpen] = useState(false);
  const emailSchema = z.string().email();

  useEffect(() => {
    newProjectCtx.dispatch({
      type: "PROJECT_LAUNCH_STATE_UPDATE",
      projectLaunchState: "members",
    });
  }, []);

  // useEffect(() => {
  //   if (!!externalMembers && externalMembers?.length > 0) {
  //     const existingMembers = newProjectCtx.state.externalMembers;
  //     const newMembers = externalMembers.filter(
  //       (m) => existingMembers.filter((i) => i.email === m.email).length === 0
  //     );
  //     if (newMembers.length > 0) {
  //       newProjectCtx.dispatch({
  //         type: "EXTERNAL_MEMBERS_UPDATE",
  //         externalMembers: [
  //           ...existingMembers,
  //           ...newMembers.map((m) => ({
  //             email: m.email,
  //             sendInviteEmail: false,
  //             isNew: false,
  //             isMember: true,
  //             membershipType: "tasker" as "tasker",
  //           })),
  //         ],
  //       });
  //     }
  //   }
  // }, [externalMembers]);

  function onAdd() {
    if (
      newProjectCtx.state.externalMembers.filter((m) => m.email === email)
        .length > 0
    ) {
      setMessage({
        isError: true,
        message: "Email already added",
      });
    } else {
      const isValid = emailSchema.safeParse(emailSearch);
      if (isValid.success) {
        setMessage({
          isError: false,
          message: "Press Enter to add",
        });
        newProjectCtx.dispatch({
          type: "EXTERNAL_MEMBERS_UPDATE",
          externalMembers: [
            ...newProjectCtx.state.externalMembers,
            {
              email: emailSearch,
              sendInviteEmail: true,
              // isNew: true,
              // isMember: true,
              membershipType: "member",
            },
          ],
        });
        setEmailSearch("");
      } else {
        setMessage({
          isError: true,
          message: "Please enter a valid email",
        });
      }
    }
  }

  const [emailSearch, setEmailSearch] = useState("");
  const snackbarCtx = useSnackBar();
  const [isError, setIsError] = useState(false);
  return (
    <div className="relative flex w-full flex-col">
      <div className="flex-1 space-y-7 p-8 px-10">
        <div className="text-xl font-medium leading-none">External Team</div>

        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button
              variant="secondary"
              size="lg"
              role="combobox"
              aria-expanded={open}
              className="w-60 justify-center space-x-2 truncate px-2.5"
            >
              <div className="flex items-center space-x-2">
                <div className="font-normal text-gray-600">
                  Add External User
                </div>
              </div>
            </Button>
          </PopoverTrigger>
          <PopoverContent
            className="pointer-events-auto w-60 p-0"
            align="start"
          >
            <Command
              filter={(value, search) => {
                if (value === "-1") {
                  return 1;
                } else if (value === "+") {
                  if (emailSearch.trim() !== "") {
                    return 1;
                  }
                  return 0;
                } else {
                  if (value.startsWith(search)) {
                    return 1;
                  }
                  return 0;
                }
              }}
            >
              <div
                className={clsx(
                  "flex items-center px-3"
                  // isError ? "text-red-500" : ""
                )}
                cmdk-input-wrapper=""
              >
                <MagnifyingGlassIcon className="mr-2 h-4 w-4 shrink-0 opacity-50" />
                <CommandPrimitive.Input
                  className={cn(
                    "flex h-9 w-full rounded-md border-none bg-transparent px-0 py-3 text-sm outline-none ring-0 placeholder:text-gray-400/80 focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50"
                    // isError ? "text-red-500" : ""
                  )}
                  value={emailSearch}
                  onValueChange={(s) => {
                    setEmailSearch(s);
                    setIsError(false);
                  }}
                  placeholder="Enter email.."
                />
              </div>
              {/* <CommandInput
                value={emailSearch}
                onValueChange={(s) => {
                  setEmailSearch(s);
                }}
                placeholder="Email.."
                className="h-9"
              /> */}
              <CommandList>
                {/* <CommandEmpty>No email found</CommandEmpty> */}
                <CommandGroup
                  className={clsx(
                    emailSearch.trim() === "" &&
                      !externalMembers?.filter(
                        (em) =>
                          !newProjectCtx.state.externalMembers.find(
                            (e) => e.email === em.email
                          )
                      ).length &&
                      "py-0"
                  )}
                >
                  {/* <CommandItem value="-1" disabled={true}>
                    Enter a valid email..
                  </CommandItem> */}

                  {emailSearch.trim() !== "" ? (
                    <>
                      <CommandItem
                        value={"+"}
                        onSelect={() => {
                          if (emailSchema.safeParse(emailSearch).success) {
                            onAdd();
                          } else {
                            setIsError(true);
                          }
                        }}
                        className={clsx(
                          "flex justify-between gap-2",
                          emailSchema.safeParse(emailSearch).success
                            ? "aria-selected:text-gray-600"
                            : "aria-selected:text-gray-400",
                          isError && "aria-selected:bg-red-50"
                        )}
                      >
                        {!isError ? (
                          <>
                            <div className="flex min-w-0 items-center space-x-2 truncate">
                              <PlusIcon className="h-4 w-4 shrink-0" />
                              <div className="truncate">
                                Add&nbsp;
                                <span>{emailSearch}</span>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="text-red-500">
                            Enter a valid email
                          </div>
                        )}
                        {/* {isError && <Badge className="h-5" variant={"red"}>Invalid</Badge>} */}
                      </CommandItem>
                      {/* { ? (
                      ) : (
                        <CommandItem
                          className="text-gray-400"
                          value="-1"
                          onClick={() => {
                            snackbarCtx.showSnackbar("Please enter a valid email", "error");
                          }}
                        >
                          Add&nbsp;
                          <span className="text-gray-400"> {emailSearch}</span>
                        </CommandItem>
                      )} */}
                    </>
                  ) : (
                    <></>
                    // <CommandItem
                    //   className="text-gray-400"
                    //   disabled={true}
                    //   value="-1"
                    // >
                    //   Enter email
                    // </CommandItem>
                  )}

                  {externalMembers
                    ?.filter(
                      (em) =>
                        !newProjectCtx.state.externalMembers.find(
                          (e) => e.email === em.email
                        )
                    )
                    ?.map((em) => (
                      <CommandItem
                        key={em.email}
                        value={em.email}
                        onSelect={() => {
                          newProjectCtx.dispatch({
                            type: "EXTERNAL_MEMBERS_UPDATE",
                            externalMembers: [
                              ...newProjectCtx.state.externalMembers,
                              {
                                email: em.email,
                                sendInviteEmail: true,
                                membershipType: "member",
                              },
                            ],
                          });

                          // setOpen(false);
                        }}
                        className="gap-2"
                      >
                        <span className="truncate">{em.email}</span>
                      </CommandItem>
                    ))}
                  {emailSearch.trim() !== "" && (
                    <CommandItem>Press Enter to add email</CommandItem>
                  )}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
        {/* <div className="space-y-1">
          <InputHeading heading="Email" />
          <div className="flex space-x-2.5">
            <div className="space-y-1">
              <TextInput
                disabled={!externalMembers}
                autoComplete="off"
                spellCheck={false}
                autoFocus
                name="stage"
                type="text"
                className="peer w-72"
                placeholder="richardhendrix@piedpiper.com"
                onChange={(e) => {
                  if (e.target.value.includes(" ")) {
                    return;
                  }
                  setEmail(e.target.value.toLowerCase());
                  if (message.isError) {
                    setMessage({
                      isError: false,
                      message: "Press Enter to add",
                    });
                  }
                }}
                value={email}
                onBlur={() => {
                  if (email.trim() !== "") {
                    setMessage({
                      isError: true,
                      message: "Email has not been added. Press Enter to add",
                    });
                  }
                }}
                // RightIcon={ArrowLongLeftIcon}
                // rightIconOnClick={addStage}
                // rightIconDisabled={stage.trim() === ""}
                // rightIconTooltip="Click or press Enter to add"
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onAdd();
                  }
                }}
              />
              {message.isError ? (
                <div className="text-xs text-red-400">{message.message}</div>
              ) : (
                <></>
                // <div className="text-xs text-transparent transition-all peer-focus:text-gray-500">
                //   Press{" "}
                //   <span
                //   // className={clsx(
                //   //   "font-medium",
                //   //   email.trim() !== "" && "text-primary"
                //   // )}
                //   >
                //     Enter
                //   </span>{" "}
                //   to add external member
                // </div>
              )}
            </div>
            <Button
              variant={"secondary"}
              size={"lg"}
              onClick={() => {
                onAdd();
              }}
              disabled={!email.trim()}
            >
              Add
            </Button>
          </div>
        </div> */}
        <div className="pt-2">
          {!!externalMembers &&
            !!newProjectCtx.state.externalMembers.length && (
              <div className="space-y-2">
                <div className="flex">
                  <div className="flex-1 text-sm text-gray-400">Email</div>
                  {/* <div className="w-48 text-center text-sm text-gray-400">
                    Member
                  </div> */}
                  <div className="w-36 text-sm text-gray-400">Membership</div>
                  {/* <div className="w-32 text-center text-sm text-gray-400">
                    Send Invite
                  </div> */}
                  {/* <div className="flex-1">
                    <InputHeading heading="Email" />
                  </div> */}
                  {/* <div className="flex w-40 items-center justify-center space-x-1">
                    <InputHeading heading="Project Invite" />
                    <TooltipProvider delayDuration={1200}>
                      <Tooltip delayDuration={0}>
                        <TooltipTrigger asChild>
                          <InformationCircleIcon className="h-4 w-4 text-gray-400" />
                        </TooltipTrigger>
                        <TooltipContent
                          sideOffset={12}
                          align="end"
                          side="top"
                          className="max-w-[230px]"
                        >
                          <p>
                            {
                              "The invite email will contain a magic link. It will enable the invited user to access the project in one click."
                            }
                          </p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div> */}
                </div>
                <div>
                  {newProjectCtx.state.externalMembers.map((m) => (
                    <div
                      key={m.email}
                      className="group relative flex h-10 items-center justify-between border-t text-sm"
                    >
                      <div className="flex flex-1 items-center space-x-2">
                        <div className="truncate font-medium text-gray-700">
                          {m.email}
                        </div>
                      </div>
                      {/* <div className="flex w-48 justify-center">
                        <Checkbox
                          checked={m.isMember}
                          onCheckedChange={(checked) => {
                            newProjectCtx.dispatch({
                              type: "EXTERNAL_MEMBERS_UPDATE",
                              externalMembers:
                                newProjectCtx.state.externalMembers.map((i) => {
                                  if (i.email === m.email) {
                                    return {
                                      ...i,
                                      isMember: !!checked,
                                    };
                                  }
                                  return i;
                                }),
                            });
                          }}
                        />
                      </div> */}
                      <div className="flex w-36">
                        <Select
                          value={m.membershipType}
                          onValueChange={(value) => {
                            newProjectCtx.dispatch({
                              type: "EXTERNAL_MEMBERS_UPDATE",
                              externalMembers:
                                newProjectCtx.state.externalMembers.map((i) => {
                                  if (i.email === m.email) {
                                    return {
                                      ...i,
                                      membershipType: value as
                                        | "member"
                                        | "subscriber",
                                    };
                                  }
                                  return i;
                                }),
                            });
                          }}
                        >
                          <SelectTrigger className="h-8 w-32 gap-2 truncate border-gray-200/80">
                            <div className="flex items-center space-x-2 truncate">
                              <SelectValue>
                                {m.membershipType === "member"
                                  ? "Tasker"
                                  : "Subscriber"}
                              </SelectValue>
                            </div>
                          </SelectTrigger>
                          <SelectContent
                            className="max-h-[30vh] max-w-[240px]"
                            align="end"
                            sideOffset={-1}
                          >
                            <SelectGroup>
                              <SelectItem value={"member"}>
                                <div className="space-y-0.5">
                                  <div className="">Tasker</div>
                                  <div className="text-xs font-light text-gray-500">
                                    Taskers are responsible for the execution of
                                    the project
                                  </div>
                                </div>
                              </SelectItem>
                              <SelectItem value={"subscriber"}>
                                <div className="space-y-0.5">
                                  <div className="">Subscriber</div>
                                  <div className="text-xs font-light text-gray-500">
                                    Subscribers only track progress of the
                                    project
                                  </div>
                                </div>
                              </SelectItem>
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      {/* <div className="flex w-32 justify-center">
                        {m.isMember ? (
                          <Checkbox
                            checked={m.sendInviteEmail}
                            onCheckedChange={(value) => {
                              newProjectCtx.dispatch({
                                type: "EXTERNAL_MEMBERS_UPDATE",
                                externalMembers:
                                  newProjectCtx.state.externalMembers.map(
                                    (i) => {
                                      if (i.email === m.email) {
                                        return {
                                          ...i,
                                          sendInviteEmail: !!value,
                                        };
                                      }
                                      return i;
                                    }
                                  ),
                              });
                            }}
                          />
                        ) : (
                          "-"
                        )}
                      </div> */}

                      <div
                        className="group absolute right-full cursor-pointer pr-2 opacity-0 group-hover:opacity-100"
                        onClick={() => {
                          newProjectCtx.dispatch({
                            type: "EXTERNAL_MEMBERS_UPDATE",
                            externalMembers:
                              newProjectCtx.state.externalMembers.filter(
                                (i) => i.email !== m.email
                              ),
                          });
                        }}
                      >
                        <div className="rounded-full p-1 text-gray-400 hover:bg-gray-50 hover:text-gray-600">
                          <XMarkIcon className="h-3 w-3 " />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

          {/* <div className="space-y-1 pt-2">
            <div className="text-sm font-medium text-gray-700">
              Send a magic link of the project to the emails entered above?
            </div>
            <div className="flex items-center space-x-2">
              <div className="text-sm text-gray-600">No</div>
              <Switch
                checked={newProjectCtx.state.sendInviteEmails}
                onCheckedChange={(value) => {
                  newProjectCtx.dispatch({
                    type: "SEND_INVITE_EMAILS_UPDATE",
                    sendInviteEmails: value,
                  });
                }}
              />
              <div className="text-sm text-gray-600">Yes</div>
            </div>
          </div> */}
        </div>
        {/* <div className="flex justify-end space-x-2.5">
          <Button
            variant={"secondary"}
            onClick={() => {
              newProjectCtx.dispatch({
                type: "STEP_UPDATE",
                step: newProjectCtx.state.step - 1,
              });
            }}
          >
            Back
          </Button>
          <Button
            onClick={() => {
              if (email.trim() === "") {
                newProjectCtx.dispatch({
                  type: "STEP_UPDATE",
                  step: newProjectCtx.state.step + 1,
                });
              }
            }}
            disabled={
              !newProjectCtx.state.customer ||
              !newProjectCtx.state.journeyTemplate ||
              newProjectCtx.state.projectName.trim() === ""
            }
          >
            Next
          </Button>
        </div> */}
      </div>
      <div className="flex items-end justify-between border-t border-gray-200/80 px-10 py-6">
        <Button
          variant={"secondary"}
          onClick={() => {
            newProjectCtx.dispatch({
              type: "PREVIOUS_STEP",
            });
          }}
        >
          Back
        </Button>
        <Button
          onClick={() => {
            newProjectCtx.dispatch({
              type: "NEXT_STEP",
            });
          }}
          disabled={!newProjectCtx.state.customer}
        >
          Next
        </Button>
      </div>
    </div>
    // <div className="divide-y">
    //   <ModalHeading
    //     title="Customer Team"
    //     subtitle="Add customer members who will be part of the project"
    //   />
    //   <div className="mt-4 w-full space-y-8 pt-6">
    //     <div className="flex justify-between">
    //       <div className="space-y-1">
    //         <InputHeading
    //           heading={`Invite ${newProjectCtx.state.customer?.name}'s Team`}
    //         />
    //         <div className="flex w-[300px] items-center space-x-2">
    //           <div className="grow">
    //             <TextInput
    //               name="customerMember"
    //               value={email}
    //               onChange={(e) => {
    //                 setEmail(e.target.value);
    //                 if (
    //                   newProjectCtx.state.externalMembers.includes(
    //                     e.target.value
    //                   )
    //                 ) {
    //                   setIsValidEmail(false);
    //                 } else {
    //                   try {
    //                     emailSchema.parse(e.target.value);
    //                     setIsValidEmail(true);
    //                   } catch (error) {
    //                     setIsValidEmail(false);
    //                   }
    //                 }
    //               }}
    //               placeholder={`john@${newProjectCtx.state.customer?.name
    //                 .replace(" ", "")
    //                 .toLowerCase()}.com`}
    //             />
    //           </div>
    //           <Button
    //             onClick={() => {
    //               if (!newProjectCtx.state.externalMembers.includes(email)) {
    //                 newProjectCtx.dispatch({
    //                   type: "EXTERNAL_MEMBERS_UPDATE",
    //                   externalMembers: [
    //                     ...newProjectCtx.state.externalMembers,
    //                     email,
    //                   ],
    //                 });
    //               }
    //               setEmail("");
    //             }}
    //             disabled={!isValidEmail}
    //           >
    //             Add
    //           </Button>
    //         </div>
    //       </div>
    //     </div>
    //     <div>
    //       <label className="block text-sm font-medium text-gray-700">
    //         {newProjectCtx.state.customer?.name + "'s Team"}
    //       </label>
    //       <div className="flex flex-wrap gap-2 pt-2">
    //         {newProjectCtx.state.externalMembers.map((m) => (
    //           <div className="flex h-8 w-fit items-center justify-between space-x-2 truncate rounded-md border px-2 text-sm">
    //             <div className="truncate">{m}</div>
    //             <div
    //               className="shrink-0 cursor-pointer rounded-full p-1 text-gray-400 hover:bg-gray-50 hover:text-gray-600"
    //               onClick={() => {
    //                 newProjectCtx.dispatch({
    //                   type: "EXTERNAL_MEMBERS_UPDATE",
    //                   externalMembers:
    //                     newProjectCtx.state.externalMembers.filter(
    //                       (i) => i !== m
    //                     ),
    //                 });
    //               }}
    //             >
    //               <XMarkIcon className="h-3 w-3 " />
    //             </div>
    //           </div>
    //         ))}
    //         {newProjectCtx.state.externalMembers.length === 0 && (
    //           <div className="-mt-1 text-sm text-gray-300">
    //             No members added
    //           </div>
    //         )}
    //       </div>
    //     </div>
    //     <div className="space-y-2">
    //       <div className="text-sm font-medium text-gray-700">
    //         Send email with magic link to the customer team?
    //       </div>
    //       <VariantToggle
    //         // disabled={true}
    //         focus={false}
    //         size={"md"}
    //         enabled={newProjectCtx.state.sendInviteEmails}
    //         setEnabled={(value) => {
    //           newProjectCtx.dispatch({
    //             type: "SEND_INVITE_EMAILS_UPDATE",
    //             sendInviteEmails: value,
    //           });
    //         }}
    //       />
    //     </div>
    //     <div className="flex justify-between">
    //       <BackButton />
    //       <Button
    //         onClick={() => {
    //           newProjectCtx.dispatch({
    //             type: "STEP_UPDATE",
    //             step: newProjectCtx.state.step + 1,
    //           });
    //         }}
    //         disabled={!newProjectCtx.state.customer}
    //         // disabled={name.trim() === "" ? true : false}
    //       >
    //         Next
    //       </Button>
    //     </div>
    //   </div>
    // </div>
  );
}

// selecting the journey template
// function SelectJourneyTemplate() {
//   const newProjectCtx = useNewProjectContext();
//   // console.log(journeyTemplates);
//   return (
//     <div className="divide-y">
//       <ModalHeading
//         title="Onboarding Workflow"
//         subtitle="Select the workflow you would like to use for this onboarding"
//       />
//       <div className="mt-4 w-full space-y-8 pt-6">
//         <div className="space-y-4">

//           {/* <Button
//             text="Preview Journey"
//             size="xs"
//             onClick={() => {}}
//             fill="outline"
//             disabled={!newProjectCtx.state.journeyTemplate}
//           /> */}
//         </div>
//         <div className="flex items-end justify-end space-x-3 pt-6">
//           {/* <BackButton /> */}
//           {/* <ArrowLeftIcon className="w-4 h-4" /> */}

//           {/* <Button
//             text="Back"
//             size="xs"
//             onClick={() => {
//               newProjectCtx.dispatch({
//                 type: "STEP_UPDATE",
//                 step: newProjectCtx.state.step - 1,
//               });
//             }}
//             fill="outline"
//             // disabled={!newProjectCtx.state.phaseType}
//             // disabled={name.trim() === "" ? true : false}
//           /> */}
//           <Button
//             text="Next"
//             size="xs"
//             onClick={() => {
//               newProjectCtx.dispatch({
//                 type: "STEP_UPDATE",
//                 step: newProjectCtx.state.step + 1,
//               });
//             }}
//             disabled={!newProjectCtx.state.journeyTemplate}
//             // disabled={name.trim() === "" ? true : false}
//           />
//         </div>
//       </div>
//     </div>
//   );
// }

function SelectTaskers() {
  const newProjectCtx = useNewProjectContext();
  useEffect(() => {
    newProjectCtx.dispatch({
      type: "PROJECT_LAUNCH_STATE_UPDATE",
      projectLaunchState: "members",
    });
  }, []);

  return (
    <div className="relative flex w-full flex-col">
      <div className="flex flex-1 divide-x divide-gray-200/80 overflow-auto">
        <div className="relative flex-1 space-y-7 overflow-auto p-8 px-10">
          {/* <div className="absolute bottom-0 w-full h-10 bg-gradient-to-b from-white/70 to-white left-0 z-10" /> */}
          <div className="text-xl font-medium leading-none">Internal Roles</div>
          <div className="flex flex-wrap gap-6">
            {newProjectCtx.state.journeyTemplate &&
              newProjectCtx.state.journeyTemplate.internalPersonasWithTasks.map(
                (ip) => {
                  return <InternaRoleSelection ip={ip} key={ip} />;
                }
              )}
          </div>
        </div>
        <div className="flex-1 space-y-7 overflow-auto p-8 px-10">
          <div className="text-xl font-medium leading-none">External Roles</div>
          <div className="flex flex-wrap gap-6">
            {newProjectCtx.state.journeyTemplate &&
              newProjectCtx.state.journeyTemplate.externalPersonasWithTasks.map(
                (ep) => {
                  return <ExternalRoleSelection ep={ep} key={ep} />;
                }
              )}
          </div>
        </div>
      </div>
      {/* <div className="flex-1 space-y-7 overflow-auto p-8 px-10">
        
        <div className="text-xl font-medium leading-none">
          Tasker Assignment
        </div>

        <div className="flex flex-1 space-y-7 divide-x overflow-auto">
          <div className="w-full flex-1 space-y-4 overflow-auto">
            <div className="text-lg">Internal</div>
            
          </div>
          <div className="flex-1 space-y-1">
            <div className="space-y-1">
              <InputHeading heading="External Taskers" />
              {newProjectCtx.state.externalMembers.length === 0 && (
                <div className="text-xs text-gray-400">
                  No customer members added as yet
                </div>
              )}
            </div>
            <div className="flex flex-wrap gap-3">
              {newProjectCtx.state.journeyTemplate &&
                newProjectCtx.state.journeyTemplate.externalPersonas.map(
                  (ip, idx) => {
                    return (
                      <div className="space-y-1">
                        <label className="block text-sm font-medium text-gray-400">
                          {ip}
                        </label>
                        <div className="max-w-[200px]">
                          <Combobox
                            options={newProjectCtx.state.externalMembers.map(
                              (m) => m.email
                            )}
                            onChange={(value) => {
                              updateExternalTasker(ip, value);
                            }}
                            displayValue={(value) => {
                              return value ?? "";
                            }}
                            value={
                              !!newProjectCtx.state.externalTaskers
                                ? newProjectCtx.state.externalTaskers[ip]
                                : undefined
                            }
                            optionKeyFn={(value) =>
                              value?.toString() ?? Math.random().toString()
                            }
                          />
                        </div>
                      </div>
                    );
                  }
                )}
            </div>
          </div>
        </div>
        <div className="flex justify-end space-x-2.5">
          <Button
            variant={"secondary"}
            onClick={() => {
              newProjectCtx.dispatch({
                type: "STEP_UPDATE",
                step: newProjectCtx.state.step - 1,
              });
            }}
          >
            Back
          </Button>
          <Button
            onClick={() => {
              newProjectCtx.dispatch({
                type: "STEP_UPDATE",
                step: newProjectCtx.state.step + 1,
              });
            }}
            // disabled={disabled}
          >
            Next
          </Button>
        </div>
      </div> */}
      <div className="flex items-end justify-between border-t border-gray-200/80 px-10 py-6">
        <Button
          variant={"secondary"}
          onClick={() => {
            newProjectCtx.dispatch({
              type: "PREVIOUS_STEP",
            });
          }}
        >
          Back
        </Button>
        <Button
          onClick={() => {
            newProjectCtx.dispatch({
              type: "NEXT_STEP",
            });
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

function ExternalRoleSelection({ ep }: { ep: string }) {
  const newProjectCtx = useNewProjectContext();
  const [open, setOpen] = useState(false);

  function updateExternalTasker(key: string, tasker: string | undefined) {
    let tempTaskers = { ...newProjectCtx.state.externalTaskers };
    tempTaskers[key] = tasker;
    newProjectCtx.dispatch({
      type: "EXTERNAL_TASKERS_UPDATE",
      externalTaskers: tempTaskers,
    });
  }

  return (
    <div className="space-y-1">
      <div>
        <InputHeading heading={ep} />
      </div>
      <div>
        <Popover modal={true} open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button
              variant="secondary"
              size="lg"
              role="combobox"
              className="w-60 justify-between space-x-2 truncate px-2.5"
              disabled={!newProjectCtx.state.externalMembers.length}
            >
              <div>
                {!!newProjectCtx.state.externalTaskers
                  ? newProjectCtx.state.externalTaskers[ep]
                  : undefined}
              </div>
              <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent
            className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
            align="start"
          >
            <Command
            // filter={(value, search) => {
            //   const name =
            //     newProjectCtx.state.externalMembers.find(
            //       (im) => im._id === value
            //     )?.name ?? "";
            //   return name
            //     .toLowerCase()
            //     .includes(search.toLowerCase())
            //     ? 1
            //     : 0;
            // }}
            >
              <CommandInput
                placeholder="Search external team.."
                className="h-9"
              />
              <CommandEmpty>No team member found</CommandEmpty>
              <CommandGroup>
                {newProjectCtx.state.externalMembers.map((em) => (
                  <CommandItem
                    key={em.email}
                    value={em.email}
                    onSelect={() => {
                      updateExternalTasker(ep, em.email);
                      setOpen(false);
                    }}
                    className="gap-2"
                  >
                    <span className="truncate">{em.email}</span>
                    <CheckIcon
                      className={cn(
                        "ml-auto h-4 w-4 shrink-0",
                        newProjectCtx.state.externalTaskers?.[ep] === em.email
                          ? "opacity-100"
                          : "opacity-0"
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </Command>
          </PopoverContent>
        </Popover>
        {/* <div className="w-full max-w-[200px]">
        <Combobox
          options={newProjectCtx.state.internalMembers}
          onChange={(value) => {
            updateInternalTasker(ip, value);
          }}
          displayValue={(value) => {
            return value?.name ?? "";
          }}
          value={
            !!newProjectCtx.state.internalTaskers
              ? newProjectCtx.state.internalTaskers[ip]
              : undefined
          }
          optionKeyFn={(value) =>
            value?._id?.toString() ??
            value?.name ??
            Math.random().toString()
          }
        />
      </div> */}
      </div>
    </div>
  );
}

function InternaRoleSelection({ ip }: { ip: string }) {
  const newProjectCtx = useNewProjectContext();
  const [open, setOpen] = useState(false);
  function updateInternalTasker(key: string, tasker: UserData | undefined) {
    let tempTaskers = { ...newProjectCtx.state.internalTaskers };
    tempTaskers[key] = tasker;
    newProjectCtx.dispatch({
      type: "INTERNAL_TASKERS_UPDATE",
      internalTaskers: tempTaskers,
    });
  }

  return (
    <div className="space-y-1">
      <div>
        <InputHeading heading={ip} />
      </div>
      <div>
        <Popover modal={true} open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button
              variant="secondary"
              size="lg"
              role="combobox"
              className="w-60 justify-between space-x-2 truncate px-2.5"
            >
              <div>
                {!!newProjectCtx.state.internalTaskers
                  ? newProjectCtx.state.internalTaskers[ip]?.name
                  : undefined}
              </div>
              <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent
            className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
            align="start"
          >
            <Command
              filter={(value, search) => {
                const name =
                  newProjectCtx.state.internalMembers.find(
                    (im) => im._id === value
                  )?.name ?? "";
                return name.toLowerCase().includes(search.toLowerCase())
                  ? 1
                  : 0;
              }}
            >
              <CommandInput
                placeholder="Search internal team.."
                className="h-9"
              />
              <CommandEmpty>No team member found</CommandEmpty>
              <CommandGroup>
                {newProjectCtx.state.internalMembers.map((im) => (
                  <CommandItem
                    key={im._id}
                    value={im._id}
                    onSelect={() => {
                      updateInternalTasker(ip, im);
                      setOpen(false);
                    }}
                    className="gap-2"
                  >
                    <span className="truncate">{im.name}</span>
                    <CheckIcon
                      className={cn(
                        "ml-auto h-4 w-4 shrink-0",
                        newProjectCtx.state.internalTaskers?.[
                          ip
                        ]?._id?.toString() === im?._id
                          ? "opacity-100"
                          : "opacity-0"
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </Command>
          </PopoverContent>
        </Popover>
        {/* <div className="w-full max-w-[200px]">
        <Combobox
          options={newProjectCtx.state.internalMembers}
          onChange={(value) => {
            updateInternalTasker(ip, value);
          }}
          displayValue={(value) => {
            return value?.name ?? "";
          }}
          value={
            !!newProjectCtx.state.internalTaskers
              ? newProjectCtx.state.internalTaskers[ip]
              : undefined
          }
          optionKeyFn={(value) =>
            value?._id?.toString() ??
            value?.name ??
            Math.random().toString()
          }
        />
      </div> */}
      </div>
    </div>
  );
}

function InviteEmail() {
  const newProjectCtx = useNewProjectContext();
  if (
    newProjectCtx.state.externalMembers.filter((m) => m.sendInviteEmail)
      .length === 0
  ) {
    if (newProjectCtx.state.projectLaunchState === "launch") {
      newProjectCtx.dispatch({
        type: "STEP_UPDATE",
        step: newProjectCtx.state.step - 1,
      });
    } else {
      newProjectCtx.dispatch({
        type: "STEP_UPDATE",
        step: newProjectCtx.state.step + 1,
      });
    }
  }

  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);

  return (
    <div className="relative flex w-full flex-col overflow-auto">
      <div className="flex-1 space-y-7 overflow-auto p-8 px-10">
        <div className="text-xl font-medium leading-none">
          Send Email Invite
        </div>
        <div className="flex items-center space-x-3">
          <div className="text-sm text-gray-600">
            Would you like to send an email invite to the external team?
          </div>
          <Select
            value={newProjectCtx.state.sendInviteEmails ? "true" : "false"}
            onValueChange={(value) => {
              newProjectCtx.dispatch({
                type: "SEND_INVITE_EMAILS_UPDATE",
                sendInviteEmails: value === "true",
              });
            }}
          >
            <SelectTrigger className="w-28">
              <SelectValue>
                {newProjectCtx.state.sendInviteEmails ? "Yes" : "No"}
              </SelectValue>
            </SelectTrigger>
            <SelectContent className="min-w-[112px]">
              <SelectGroup>
                <SelectItem value="true">Yes</SelectItem>
                <SelectItem value="false">No</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        {newProjectCtx.state.sendInviteEmails && (
          <div
            className="relative h-72 max-h-full cursor-pointer overflow-clip rounded-md border"
            onClick={() => {
              setIsEmailDialogOpen(true);
            }}
          >
            <div className="pointer-events-none absolute inset-0 p-6">
              <Email
                hideBackButton={true}
                setIsEmailDialogOpen={setIsEmailDialogOpen}
              />
            </div>

            <div className="absolute inset-0 bg-gradient-to-b from-white/60 to-white" />
            <div className="absolute inset-x-0 top-[55%] z-10 flex -translate-y-1/2 items-center justify-center text-sm text-black">
              <Button
                variant={"secondary"}
                onClick={() => {
                  setIsEmailDialogOpen(false);
                }}
              >
                Edit Email
              </Button>
            </div>
          </div>
        )}
        <Dialog
          open={isEmailDialogOpen}
          onOpenChange={(open) => {
            setIsEmailDialogOpen(open);
          }}
        >
          <DialogContent
            className="max-w-2xl p-8 pb-10"
            showCloseButton={false}
          >
            <Email setIsEmailDialogOpen={setIsEmailDialogOpen} />
          </DialogContent>
        </Dialog>
        {/* <div className="mb-6 space-y-10">
          <div className="space-y-1">
            <div className="text-sm font-medium leading-none text-gray-700">
              Personalize the invite email {newProjectCtx.state.customer?.name}
              's team will receive.
            </div>
          </div>
          <div className="space-y-3">
            <div className="space-y-1">
              <InputHeading heading="Subject" />
              <TextInput
                name="subject"
                className="border-gray-200"
                value={newProjectCtx.state.inviteEmail.subject}
                onChange={(e) => {
                  newProjectCtx.dispatch({
                    type: "INVITE_EMAIL_UPDATE",
                    inviteEmail: {
                      ...newProjectCtx.state.inviteEmail,
                      subject: e.target.value,
                    },
                  });
                }}
              />
            </div>
            <div className="space-y-1">
              <InputHeading heading="Email" />
              <div className="relative rounded-md border border-gray-200 p-4 shadow-sm">
                <div className="absolute right-4 top-3">
                  <Button
                    variant={"secondary"}
                    onClick={() => {
                      setIsEditing(!isEditing);
                    }}
                    size="sm"
                  >
                    {isEditing ? "Save" : "Edit"}
                  </Button>
                </div>
                {!!logo?.url ? (
                  <img className="max-h-[40px]" src={logo?.url} alt="logo" />
                ) : (
                  <div className="h-[40px] w-20 animate-pulse rounded-md bg-gray-100" />
                )}
                <div className="mt-4">
                  {isEditing ? (
                    <textarea
                      ref={messageTextAreaRef}
                      className="w-full resize-none rounded-md border border-gray-200 outline-none ring-0 focus:border-primary-main focus:ring-0"
                      value={newProjectCtx.state.inviteEmail.message}
                      onChange={(e) => {
                        newProjectCtx.dispatch({
                          type: "INVITE_EMAIL_UPDATE",
                          inviteEmail: {
                            ...newProjectCtx.state.inviteEmail,
                            message: e.target.value,
                          },
                        });
                      }}
                    />
                  ) : (
                    <div>
                      {newProjectCtx.state.inviteEmail.message
                        .split("\n")
                        .flatMap((line, index, arr) => [
                          index < arr.length - 1 ? (
                            <div key={index}>
                              {line}
                              <br />
                            </div>
                          ) : (
                            line
                          ),
                        ])}
                    </div>
                  )}
                  <div className="my-5 w-fit rounded-md bg-gray-100 p-4 py-3 text-gray-500">
                    Click <span className="text-gray-700 underline">here</span>{" "}
                    to view the project.
                  </div>
                  {isEditing ? (
                    <textarea
                      ref={signatureTextAreaRef}
                      className="w-full resize-none rounded-md border border-gray-200 outline-none ring-0 focus:border-primary-main focus:ring-0"
                      value={newProjectCtx.state.inviteEmail.signature}
                      onChange={(e) => {
                        newProjectCtx.dispatch({
                          type: "INVITE_EMAIL_UPDATE",
                          inviteEmail: {
                            ...newProjectCtx.state.inviteEmail,
                            signature: e.target.value,
                          },
                        });
                      }}
                    />
                  ) : (
                    <div>
                      {newProjectCtx.state.inviteEmail.signature
                        .split("\n")
                        .flatMap((line, index, arr) => [
                          index < arr.length - 1 ? (
                            <div key={index}>
                              {line}
                              <br />
                            </div>
                          ) : (
                            line
                          ),
                        ])}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="flex justify-end space-x-2.5">
          <Button
            variant={"secondary"}
            onClick={() => {
              newProjectCtx.dispatch({
                type: "STEP_UPDATE",
                step: newProjectCtx.state.step - 1,
              });
            }}
          >
            Back
          </Button>
          <Button
            onClick={() => {
              newProjectCtx.dispatch({
                type: "STEP_UPDATE",
                step: newProjectCtx.state.step + 1,
              });
            }}
            // disabled={disabled}
          >
            Next
          </Button>
        </div> */}
      </div>
      <div className="flex items-end justify-between border-t border-gray-200/80 px-10 py-6">
        <Button
          variant={"secondary"}
          onClick={() => {
            newProjectCtx.dispatch({
              type: "PREVIOUS_STEP",
            });
          }}
        >
          Back
        </Button>
        <Button
          onClick={() => {
            newProjectCtx.dispatch({
              type: "NEXT_STEP",
            });
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

function Email({
  setIsEmailDialogOpen,
  hideBackButton = false,
}: {
  setIsEmailDialogOpen: (_: boolean) => void;
  hideBackButton?: boolean;
}) {
  const { logo } = useGetLogo();
  const [isToMultiSelectOpen, setIsToMultiSelectOpen] = useState(false);
  const newProjectCtx = useNewProjectContext();
  const [isEditing, setIsEditing] = useState(false);
  const messageTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const signatureTextAreaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (isEditing) {
      if (messageTextAreaRef.current) {
        messageTextAreaRef.current.style.height = "0px"; // Reset height to shrink if needed
        const scrollHeight = messageTextAreaRef.current.scrollHeight;
        messageTextAreaRef.current.style.height = scrollHeight + "px";
      }
    }
  }, [newProjectCtx.state.inviteEmail.message, isEditing]);

  useEffect(() => {
    if (isEditing) {
      if (signatureTextAreaRef.current) {
        signatureTextAreaRef.current.style.height = "0px"; // Reset height to shrink if needed
        const scrollHeight = signatureTextAreaRef.current.scrollHeight;
        signatureTextAreaRef.current.style.height = scrollHeight + "px";
      }
    }
  }, [newProjectCtx.state.inviteEmail.signature, isEditing]);

  return (
    <div className="space-y-6">
      {!hideBackButton && (
        <Button
          variant={"secondary"}
          onClick={() => {
            setIsEmailDialogOpen(false);
          }}
        >
          <ArrowLeftIcon className="mr-2 h-4 w-4" />
          Back
        </Button>
      )}
      <div className="space-y-1">
        <InputHeading heading="To" />
        <Popover
          open={isToMultiSelectOpen}
          onOpenChange={setIsToMultiSelectOpen}
        >
          <PopoverTrigger asChild>
            <Button
              variant={"secondary"}
              className="h-fit min-h-[36px] w-full justify-between gap-1 pl-1.5"
            >
              {!!newProjectCtx.state.externalMembers.filter(
                (em) => em.sendInviteEmail
              ).length ? (
                <div className="flex flex-wrap gap-1">
                  {newProjectCtx.state.externalMembers
                    .filter((em) => em.sendInviteEmail)
                    .map((value) => (
                      <Badge key={value.email}>{value.email}</Badge>
                    ))}
                </div>
              ) : (
                <div className="px-1.5 font-normal text-gray-300">
                  Select recipients..
                </div>
              )}
              <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent
            className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
            align="start"
          >
            <Command>
              <CommandInput placeholder="Search.." className="h-9" />
              <CommandEmpty>No options found.</CommandEmpty>
              <CommandGroup>
                {newProjectCtx.state.externalMembers.map((option) => (
                  <CommandItem
                    key={option.email}
                    value={option.email}
                    onSelect={() => {
                      // select or deselect the option
                      if (option.sendInviteEmail) {
                        newProjectCtx.dispatch({
                          type: "EXTERNAL_MEMBERS_UPDATE",
                          externalMembers:
                            newProjectCtx.state.externalMembers.map((em) => {
                              if (em.email === option.email) {
                                return {
                                  ...em,
                                  sendInviteEmail: false,
                                };
                              }
                              return em;
                            }),
                        });
                      } else {
                        newProjectCtx.dispatch({
                          type: "EXTERNAL_MEMBERS_UPDATE",
                          externalMembers:
                            newProjectCtx.state.externalMembers.map((em) => {
                              if (em.email === option.email) {
                                return {
                                  ...em,
                                  sendInviteEmail: true,
                                };
                              }
                              return em;
                            }),
                        });
                      }
                    }}
                    className="gap-2"
                  >
                    <Checkbox
                      // onClick={(e) => {
                      //   e.stopPropagation();
                      // }}
                      checked={option.sendInviteEmail}
                    />

                    <div className="flex items-center space-x-2">
                      <span className="truncate">{option.email}</span>
                    </div>
                  </CommandItem>
                ))}
              </CommandGroup>
            </Command>
          </PopoverContent>
        </Popover>
      </div>
      <div className="space-y-1">
        <InputHeading heading="Subject" />
        <TextInput
          name="subject"
          className="border-gray-300/70"
          value={newProjectCtx.state.inviteEmail.subject}
          onChange={(e) => {
            newProjectCtx.dispatch({
              type: "INVITE_EMAIL_UPDATE",
              inviteEmail: {
                ...newProjectCtx.state.inviteEmail,
                subject: e.target.value,
              },
            });
          }}
        />
      </div>
      <div className="space-y-1">
        <InputHeading heading="Email" />
        <div className="relative rounded-md border border-gray-200 p-4 shadow-sm">
          <div className="absolute right-4 top-3">
            <Button
              variant={"secondary"}
              onClick={() => {
                setIsEditing(!isEditing);
              }}
              size="sm"
            >
              {isEditing ? "Save" : "Edit"}
            </Button>
          </div>
          {!!logo?.url ? (
            <img className="max-h-[40px]" src={logo?.url} alt="logo" />
          ) : (
            <div className="h-[40px] w-20 animate-pulse rounded-md bg-gray-100" />
          )}
          <div className="mt-4">
            {isEditing ? (
              <textarea
                ref={messageTextAreaRef}
                className="w-full resize-none rounded-md border border-gray-200 outline-none ring-0 focus:border-primary-main focus:ring-0"
                value={newProjectCtx.state.inviteEmail.message}
                onChange={(e) => {
                  newProjectCtx.dispatch({
                    type: "INVITE_EMAIL_UPDATE",
                    inviteEmail: {
                      ...newProjectCtx.state.inviteEmail,
                      message: e.target.value,
                    },
                  });
                }}
              />
            ) : (
              <div>
                {newProjectCtx.state.inviteEmail.message
                  .split("\n")
                  .flatMap((line, index, arr) => [
                    index < arr.length - 1 ? (
                      <div key={index}>
                        {line}
                        <br />
                      </div>
                    ) : (
                      line
                    ),
                  ])}
              </div>
            )}
            <div className="my-5 w-fit rounded-md bg-gray-100 p-4 py-3 text-gray-500">
              Click <span className="text-gray-700 underline">here</span> to
              view the project.
            </div>
            {isEditing ? (
              <textarea
                ref={signatureTextAreaRef}
                className="w-full resize-none rounded-md border border-gray-200 outline-none ring-0 focus:border-primary-main focus:ring-0"
                value={newProjectCtx.state.inviteEmail.signature}
                onChange={(e) => {
                  newProjectCtx.dispatch({
                    type: "INVITE_EMAIL_UPDATE",
                    inviteEmail: {
                      ...newProjectCtx.state.inviteEmail,
                      signature: e.target.value,
                    },
                  });
                }}
              />
            ) : (
              <div>
                {newProjectCtx.state.inviteEmail.signature
                  .split("\n")
                  .flatMap((line, index, arr) => [
                    index < arr.length - 1 ? (
                      <div key={index}>
                        {line}
                        <br />
                      </div>
                    ) : (
                      line
                    ),
                  ])}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export type LaunchTaskers = {
  [key: string]:
    | // {
    //     _id?: string;
    //     email?: string;
    //     type: "user" | "team";
    //   }
    string
    | undefined;
};

function InternalProjectReview() {
  const newProjectCtx = useNewProjectContext();

  useEffect(() => {
    newProjectCtx.dispatch({
      type: "PROJECT_LAUNCH_STATE_UPDATE",
      projectLaunchState: "launch",
    });
  }, []);

  return (
    <div className="relative h-72 w-full p-6 px-8">
      <div className="h-full text-sm">
        <div className="text-gray-500">
          Are you ready to launch the project{" "}
          <span className="font-medium text-gray-800">
            {newProjectCtx.state.projectName}
          </span>
          ?
        </div>
      </div>
      <BackButton
        onClick={() => {
          newProjectCtx.dispatch({
            type: "STEP_UPDATE",
            step: newProjectCtx.state.step - 1,
          });
        }}
      />
      <div className="absolute bottom-6 right-6">
        <Button
          onClick={() => {
            // to do
          }}
          size="sm"
        >
          Launch Project
        </Button>
      </div>
    </div>
  );
}

function Review() {
  // const { sharedSpaceId } = useParams();
  const newProjectCtx = useNewProjectContext();
  const launchExternalTemplateProjectMutation =
    useLaunchExternalTemplateProject();
  const launchExternalBlankProjectMutation = useLaunchExternalBlankProject();
  const launchInternalBlankProjectMutation = useLaunchInternalBlankProject();
  // const createPhaseMutation = useCreatePhase();
  // const startJourneyMutation = useStartJourney();
  useEffect(() => {
    newProjectCtx.dispatch({
      type: "PROJECT_LAUNCH_STATE_UPDATE",
      projectLaunchState: "launch",
    });
  }, []);

  function launchProject() {
    if (newProjectCtx.state.projectType === "internal") {
      if (newProjectCtx.state.projectStart === "blank") {
        ////////////////////////////
        //// internal and blank ////
        ////////////////////////////

        launchInternalBlankProjectMutation.mutate({
          name: newProjectCtx.state.projectName,
          projectMembers: {
            external: [],
            internal: newProjectCtx.state.internalMembers.map((m) => {
              return {
                _id: m._id,
                role: m.isProjectOwner ? "owner" : "member",
              };
            }),
          },
          // statusGroupId: newProjectCtx.state.statusGroupId,
          spaceId: newProjectCtx.state.spaceId,
        });
      } else {
        // internal and template
        console.log("----");
      }
    } else {
      if (newProjectCtx.state.projectStart === "blank") {
        ////////////////////////////
        //// external and blank ////
        ////////////////////////////
        if (!!newProjectCtx.state.customer) {
          // to do
          launchExternalBlankProjectMutation.mutate({
            customer: {
              _id: newProjectCtx.state.customer._id.toString(),
            },
            inviteEmail: newProjectCtx.state.inviteEmail,
            name: newProjectCtx.state.projectName,
            projectMembers: {
              external: newProjectCtx.state.externalMembers.map((em) => {
                return {
                  email: em.email,
                  sendInviteEmail: newProjectCtx.state.sendInviteEmails
                    ? em.sendInviteEmail
                    : false,
                  role: em.membershipType,
                };
              }),
              internal: newProjectCtx.state.internalMembers.map((m) => {
                return {
                  _id: m._id,
                  role: m.isProjectOwner ? "owner" : "member",
                };
              }),
            },
            // statusGroupId: newProjectCtx.state.statusGroupId,
            spaceId: newProjectCtx.state.spaceId,
            // revenue: newProjectCtx.state.revenue,
          });
        }
      } else {
        ///////////////////////////
        // external and template //
        ///////////////////////////
        let iTaskers: LaunchTaskers = {};
        let eTaskers: LaunchTaskers = {};

        if (
          newProjectCtx.state.internalTaskers &&
          newProjectCtx.state.externalTaskers
        ) {
          for (const [k, v] of Object.entries(
            newProjectCtx.state.internalTaskers
          )) {
            iTaskers[k] = v?._id;
          }
          for (const [k, v] of Object.entries(
            newProjectCtx.state.externalTaskers
          )) {
            if (v) {
              eTaskers[k] = v;
            } else {
              eTaskers[k] = undefined;
            }
          }
        }

        let customFieldRecords: CustomFieldRecord[] = [];
        if (newProjectCtx.state.customFieldRecords.length > 0) {
          customFieldRecords = newProjectCtx.state.customFieldRecords.map(
            (cf) => {
              return {
                _id: cf._id,
                value: cf.value,
              };
            }
          );
        }

        const ownerUserId = newProjectCtx.state.internalMembers.find(
          (im) => im.isProjectOwner
        )?._id;

        if (
          newProjectCtx.state.customer &&
          newProjectCtx.state.journeyTemplate?._id &&
          ownerUserId
        ) {
          launchExternalTemplateProjectMutation.mutate({
            name: newProjectCtx.state.projectName,
            // statusGroupId: newProjectCtx.state.statusGroupId,
            spaceId: newProjectCtx.state.spaceId,
            customer: {
              _id: newProjectCtx.state.customer?._id.toString(),
            },
            ...(newProjectCtx.state.deal
              ? { dealId: newProjectCtx.state.deal._id }
              : {}),
            template: {
              _id: newProjectCtx.state.journeyTemplate?._id.toString(),
              taskers: { internalTaskers: iTaskers, externalTaskers: eTaskers },
            },
            projectMembers: {
              external: newProjectCtx.state.externalMembers.map((em) => {
                return {
                  email: em.email,
                  sendInviteEmail: newProjectCtx.state.sendInviteEmails
                    ? em.sendInviteEmail
                    : false,
                  role: em.membershipType,
                };
              }),
              internal: newProjectCtx.state.internalMembers.map((m) => {
                return {
                  _id: m._id,
                  role: m.isProjectOwner ? "owner" : "member",
                };
              }),
            },
            inviteEmail: newProjectCtx.state.inviteEmail,
            ownerUserId: ownerUserId,
            customFields: customFieldRecords,
          });
        }
      }
    }
  }

  return (
    <div className="flex w-full flex-col">
      <div className="flex-1 space-y-7 overflow-auto p-8 px-10">
        <div className="text-xl font-medium leading-none">Review</div>
        <div className="grid grid-cols-2 gap-8">
          <div className="space-y-1">
            <div className="text-xs text-gray-400">Name</div>
            <div className="w-60">{newProjectCtx.state.projectName}</div>
          </div>
          {newProjectCtx.state.projectType === "external" && (
            <div className="space-y-1">
              <div className="text-xs text-gray-400">Company</div>
              <div className="w-60">{newProjectCtx.state.customer?.name}</div>
            </div>
          )}
          {newProjectCtx.state.projectStart === "template" && (
            <div className="space-y-1">
              <div className="text-xs text-gray-400">Template</div>
              <div>
                <div className="flex items-center space-x-2">
                  <div>{newProjectCtx.state.journeyTemplate?.name}</div>
                </div>
              </div>
            </div>
          )}
          <div className="space-y-1">
            <div className="text-xs text-gray-400">Project Owner</div>
            <div>
              {
                newProjectCtx.state.internalMembers.find(
                  (im) => im.isProjectOwner
                )?.name
              }
            </div>
          </div>
          <div className="space-y-1">
            <div className="text-xs text-gray-400">Internal Team</div>
            <div className="flex w-72 flex-wrap gap-2">
              {newProjectCtx.state.internalMembers.map((im, idx) => (
                <div key={im._id} className="">
                  {im.name +
                    `${
                      idx === newProjectCtx.state.internalMembers.length - 1
                        ? ""
                        : ","
                    }`}
                </div>
              ))}
            </div>
          </div>
          {newProjectCtx.state.projectType === "external" && (
            <div className="space-y-1">
              <div className="text-xs text-gray-400">External Team</div>
              <div className="flex flex-wrap gap-2">
                {newProjectCtx.state.externalMembers.map((em, idx) => (
                  <div key={em.email} className="">
                    {em.email +
                      `${
                        idx === newProjectCtx.state.externalMembers.length - 1
                          ? ""
                          : ","
                      }`}
                  </div>
                ))}
                {newProjectCtx.state.externalMembers.length === 0 && (
                  <div>-</div>
                )}
              </div>
            </div>
          )}

          {/* <div className="text-sm">
              <div className="text-gray-500">
                Are you ready to launch the project{" "}
                <span className="font-medium text-gray-800">
                  {newProjectCtx.state.projectName}
                </span>{" "}
                with{" "}
                <span className="font-medium text-gray-800">
                  {newProjectCtx.state.customer?.name}
                </span>
                ?
              </div>
            </div> */}
        </div>
      </div>
      <div className="flex items-end justify-between border-t border-gray-200/80 px-10 py-6">
        <Button
          onClick={() => {
            newProjectCtx.dispatch({
              type: "PREVIOUS_STEP",
            });
          }}
          variant={"secondary"}
          disabled={
            launchExternalTemplateProjectMutation.isLoading ||
            launchExternalBlankProjectMutation.isLoading ||
            launchInternalBlankProjectMutation.isLoading
          }
        >
          Back
        </Button>
        <Button
          onClick={() => {
            launchProject();
          }}
          disabled={
            launchExternalTemplateProjectMutation.isLoading ||
            launchExternalBlankProjectMutation.isLoading ||
            launchInternalBlankProjectMutation.isLoading
          }
        >
          {launchExternalBlankProjectMutation.isLoading ||
          launchExternalTemplateProjectMutation.isLoading ||
          launchInternalBlankProjectMutation.isLoading
            ? "Launching.."
            : "Launch Project"}
        </Button>
      </div>
    </div>
    // <div className="w-full p-6 pl-7">
    //   <div className="flex h-full flex-col justify-between">
    //     <div className="space-y-6">
    //       <div>
    //         <div className="text-2xl">Launch Confirmation</div>
    //         <div className="text-sm text-gray-500">
    //           Confirm the template and customer before launching
    //         </div>
    //       </div>
    //       <div className="space-y-6">
    //         <div className="text text-gray-500">
    //           <span>{`Launch the project template`}</span>
    //           <span className="font-medium text-gray-700">{` ${newProjectCtx.state.journeyTemplate?.name}`}</span>
    //           <span>{` with`}</span>
    //           <span className="font-medium text-gray-700">{` ${newProjectCtx.state.customer?.name}`}</span>
    //           <span>{`?`}</span>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="flex justify-end space-x-2.5">
    //       <Button
    //         variant={"secondary"}
    //         onClick={() => {
    //           newProjectCtx.dispatch({
    //             type: "STEP_UPDATE",
    //             step: newProjectCtx.state.step - 1,
    //           });
    //         }}
    //       >
    //         Back
    //       </Button>
    //       <Button
    //         onClick={() => {
    //           launchProject();
    //         }}
    //         disabled={launchProjectMutation.isLoading}
    //       >
    //         {launchProjectMutation.isLoading ? "Launching.." : "Launch Project"}
    //       </Button>
    //     </div>
    //   </div>
    // </div>
    // <div className="divide-y">

    //   <div className="mt-4 w-full space-y-8 pt-6">
    //     {/* <div className="space-y-4">
    //       <div className="space-y-1">
    //         <InputHeading heading="Onboarding Status" />
    //         <div className="text-xs text-gray-400">
    //           Customers can view active onboardings
    //         </div>
    //       </div>
    //       <div className="inline-flex items-center space-x-2">
    //         <VariantToggle
    //           size="md"
    //           color="primary"
    //           enabled={newProjectCtx.state.isActive}
    //           setEnabled={(isActive) => {
    //             newProjectCtx.dispatch({
    //               type: "PHASE_IS_ACTIVE_UPDATE",
    //               isActive: isActive,
    //             });
    //           }}
    //           focus={false}
    //         />
    //         <span
    //           className={clsx(
    //             "text-xs",
    //             newProjectCtx.state.isActive
    //               ? "text-primary-main"
    //               : "text-gray-500"
    //           )}
    //         >
    //           Active
    //         </span>
    //       </div>
    //     </div> */}

    //     <div className="flex items-end justify-between space-x-3">
    //       {/* <Button
    //         text="Back"
    //         size="xs"
    //         onClick={() => {
    //           newProjectCtx.dispatch({
    //             type: "STEP_UPDATE",
    //             step: newProjectCtx.state.step - 1,
    //           });
    //         }}
    //         fill="outline"
    //       /> */}
    //       <BackButton />
    //       <Button
    //         // disabled={name.trim() === "" ? true : false}
    //       >
    //         {launchProjectMutation.isLoading ? "Loading.." : "Launch Project"}
    //       </Button>
    //     </div>
    //   </div>
    // </div>
  );
}

// function BackButton() {
//   const newProjectCtx = useNewProjectContext();
//   return (
//     <button
//       className="flex items-center space-x-1 text-gray-400"
//       onClick={() => {
//         newProjectCtx.dispatch({
//           type: "STEP_UPDATE",
//           step: newProjectCtx.state.step - 1,
//         });
//       }}
//     >
//       <ChevronLeftIcon className="h-3 w-3 " />
//       <span className="text-sm">Back</span>
//     </button>
//   );
// }
