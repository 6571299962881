import { useQuery } from "@tanstack/react-query";
import { ObjectID } from "bson";
import { buildFetcher } from "src/api";
import { useRealmApp } from "src/store/RealmApp";
import {
  JourneyCompletion,
  JourneyCompletionForTemplate,
  JourneyCount,
  JourneyCountForTemplate,
  JourneyOverdueTask,
  JourneyOverviewForTemplate,
  JourneyStageCompletion,
  OverudeLateTaskCountForTemplate,
  TaskBreakdownForInstance,
  TaskBreakdownForJourney,
} from "./types";

export const useGetTaskBreakdownForInstance = (): {
  taskBreakdown: TaskBreakdownForInstance | undefined;
} => {
  const app = useRealmApp();
  const meta = {
    functionName: "getTaskBreakdownForInstance",
    parameters: {
      instanceId: app.currentUser.customData?.instanceId,
    },
  };

  const { data } = useQuery<TaskBreakdownForInstance>(
    ["analytics", "tasks", "all"],
    buildFetcher(app, meta)
  );

  return {
    taskBreakdown: data,
  };
};

export const useGetTotalJourneyCountForInstance = (): {
  journeyCount: JourneyCount | undefined;
} => {
  const app = useRealmApp();
  const meta = {
    functionName: "getJourneyCountForInstance",
    parameters: {
      instanceId: app.currentUser.customData?.instanceId,
    },
  };

  const { data } = useQuery<JourneyCount>(
    ["analytics", "journeys", "all"],
    buildFetcher(app, meta)
  );

  return {
    journeyCount: data,
  };
};

export const useGetTotalJourneyCountForTemplate = (
  templateId: ObjectID
): {
  journeyCount: JourneyCountForTemplate | undefined;
} => {
  const app = useRealmApp();
  const meta = {
    functionName: "getJourneyCountForTemplate",
    parameters: {
      templateId: templateId,
    },
  };

  const { data } = useQuery<JourneyCountForTemplate>(
    ["analytics", "journeyTemplates", templateId, "count"],
    buildFetcher(app, meta)
  );

  return {
    journeyCount: data,
  };
};

export const useGetActiveJourneyCountForTemplate = (
  templateId: ObjectID
): {
  journeyCount: JourneyCountForTemplate | undefined;
} => {
  const app = useRealmApp();
  const meta = {
    functionName: "getActiveJourneyCountForTemplate",
    parameters: {
      templateId: templateId,
    },
  };

  const { data } = useQuery<JourneyCountForTemplate>(
    ["analytics", "journeyTemplates", templateId, "activeCount"],
    buildFetcher(app, meta)
  );

  return {
    journeyCount: data,
  };
};

export const useGetTotalJourneyCompletionForTemplate = (
  templateId: ObjectID
): {
  journeyTemplateCompletion: JourneyCompletionForTemplate[] | undefined;
} => {
  const app = useRealmApp();
  const meta = {
    functionName: "getJourneysCompletionForTemplate",
    parameters: {
      templateId: templateId,
    },
  };

  const { data } = useQuery<JourneyCompletionForTemplate[]>(
    ["analytics", "journeyTemplates", templateId, "completionPercentages"],
    buildFetcher(app, meta)
  );

  return {
    journeyTemplateCompletion: data,
  };
};

export const useGetJourneysOverviewForTemplate = (
  templateId: ObjectID
): {
  journeyTemplateOverview: JourneyOverviewForTemplate[] | undefined;
} => {
  const app = useRealmApp();
  const meta = {
    functionName: "getJourneysOverviewForTemplate",
    parameters: {
      templateId: templateId,
    },
  };

  const { data } = useQuery<JourneyOverviewForTemplate[]>(
    ["analytics", "journeyTemplates", templateId, "overview"],
    buildFetcher(app, meta)
  );

  return {
    journeyTemplateOverview: data,
  };
};

export const useGetLateAndOverdueForTemplate = (
  templateId: ObjectID
): {
  lateAndOverdueForTemplate: OverudeLateTaskCountForTemplate[] | undefined;
} => {
  const app = useRealmApp();
  const meta = {
    functionName: "getOverdueAndLateTasksForTemplate",
    parameters: {
      templateId: templateId,
    },
  };

  const { data } = useQuery<OverudeLateTaskCountForTemplate[]>(
    [
      "analytics",
      "journeyTemplates",
      templateId,
      "tasks",
      "lateOverdue",
      "count",
    ],
    buildFetcher(app, meta)
  );

  return {
    lateAndOverdueForTemplate: data,
  };
};

export const useGetTaskBreakdownForJourney = (
  journeyId: ObjectID
): {
  journeyTaskBreakdown: TaskBreakdownForJourney | undefined;
} => {
  const app = useRealmApp();
  const meta = {
    functionName: "getTaskBreakdownForJourney",
    parameters: {
      journeyId: journeyId,
    },
  };

  const { data } = useQuery<TaskBreakdownForJourney>(
    ["analytics", "journeys", journeyId, "tasks", "all", "count"],
    buildFetcher(app, meta)
  );

  return {
    journeyTaskBreakdown: data,
  };
};

export const useGetOverdueTasksForJourney = (
  journeyId: ObjectID
): {
  journeyOverdueTasks: JourneyOverdueTask[] | undefined;
} => {
  const app = useRealmApp();
  const meta = {
    functionName: "getOverdueTasksForJourney",
    parameters: {
      journeyId: journeyId,
    },
  };

  const { data } = useQuery<JourneyOverdueTask[]>(
    ["analytics", "journeys", journeyId, "tasks", "overdue", "count"],
    buildFetcher(app, meta)
  );

  return {
    journeyOverdueTasks: data,
  };
};

export const useGetJourneyStageCompletions = (
  journeyId: ObjectID
): {
  journeyStageCompletions: JourneyStageCompletion[] | undefined;
} => {
  const app = useRealmApp();
  const meta = {
    functionName: "getJourneyStagesCompletion",
    parameters: {
      journeyId: journeyId,
    },
  };

  const { data } = useQuery<JourneyStageCompletion[]>(
    [
      "analytics",
      "journeys",
      journeyId,
      "stages",
      "all",
      "completionPercentage",
    ],
    buildFetcher(app, meta)
  );

  return {
    journeyStageCompletions: data,
  };
};

export const useGetJourneyCompletion = (
  journeyId: ObjectID
): {
  journeyCompletion: JourneyCompletion | undefined;
} => {
  const app = useRealmApp();
  const meta = {
    functionName: "getJourneyCompletion",
    parameters: {
      journeyId: journeyId,
    },
  };

  const { data } = useQuery<JourneyCompletion>(
    ["analytics", "journeys", journeyId, "completionPercentage"],
    buildFetcher(app, meta)
  );

  return {
    journeyCompletion: data,
  };
};
