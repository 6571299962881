import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { ObjectID } from "bson";
import { ObjectId } from "mongodb";
import { useNavigate } from "react-router-dom";
import { buildFetcher, buildSetter } from "src/api";
import { TagData } from "src/api/General/tags";
import { useAccessToken } from "src/api/useAccessToken";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { useRealmApp } from "src/store/RealmApp";
import { KnowledgeResourceTemplateData } from "../KnowledgeBase/knowledgeBase";

export type TaskerType = "internal" | "external";

export const relativeDueDateUnits = [
  "minute",
  "hour",
  "day",
  "week",
  "month",
  "quarter",
] as const;

export type TaskTemplateAction =
  | "default"
  | "form"
  | "checklist"
  | "fileUpload"
  | "meeting";

export type TaskTemplateFileData = {
  name: string;
  type: string;
  label: string;
  description: string;
  createdAt: Date;
  url: string;
};

export interface TaskTemplateData {
  _id?: ObjectId;
  title: string;
  description: string;
  relativeDueDate: {
    unit: (typeof relativeDueDateUnits)[number];
    value: number;
  };
  journeyStageTemplate: { _id: ObjectId; name: string };
  journeyTemplate: {
    _id: ObjectId;
    name: string;
    type: "internal" | "external";
  };
  tasker?: {
    type: TaskerType;
    persona: string;
  };
  dependentOn: {
    dependencyTemplateId?: ObjectId;
    _id?: ObjectId;
    type: "task" | "stage" | "default";
    name?: string;
  };
  estimate: {
    unit: (typeof relativeDueDateUnits)[number];
    value: number;
  };
  instanceId: ObjectId;
  createdAt: Date;
  updatedAt?: Date;
  isVisible: boolean;
  tags: TagData[];
  knowledgeResourceTemplateIds?: ObjectId[];
  knowledgeResourceTemplates?: KnowledgeResourceTemplateData[];
  parentTaskTemplate?: TaskTemplateData;
  subTaskTemplateCount?: number;
  subTaskTemplates?: TaskTemplateData[];
  subTaskTemplateOrder?: ObjectId[];
  action: TaskTemplateAction;
  formTemplate?: {
    _id: ObjectId;
  };
  files?: TaskTemplateFileData[];
  meeting?: {
    vendor: "calendly" | "hubspot" | "other";
    link: string;
  };
  durations?: {
    planned: number
  }
}

export type TaskTemplateCreateRequest = {
  title: string;
  description: string;
  relativeDueDate: {
    unit: (typeof relativeDueDateUnits)[number];
    value: number;
  };
  estimate: {
    unit: (typeof relativeDueDateUnits)[number];
    value: number;
  };
  journeyTemplateId: string;
  journeyStageTemplateId: string;
  taskerType: TaskerType;
  taskerPersona: string;
  isVisible: boolean;
  tags?: TagData[];
  knowledgeResourceTemplateIds?: string[];
  instanceId: string;
  dependentOn: {
    dependencyTemplateId?: string;
    _id?: string;
    type: "task" | "stage" | "default";
    name?: string;
  };
  action?: TaskTemplateAction;
  formTemplateId?: string;
  meeting?: {
    vendor: MeetingVendor;
    link: string;
  };
};

export type MeetingVendor = "calendly" | "hubspot" | "other";

export type OldTaskTemplateCreateRequest = {
  title: string;
  description: string;
  relativeDueDate: {
    unit: (typeof relativeDueDateUnits)[number];
    value: number;
  };
  estimate: {
    unit: (typeof relativeDueDateUnits)[number];
    value: number;
  };
  journeyTemplateId: ObjectId;
  journeyStageTemplateId: ObjectId;
  taskerType: TaskerType;
  taskerPersona: string;
  isVisible: boolean;
  tags?: TagData[];
  knowledgeResourceTemplateIds?: ObjectId[];
  instanceId: ObjectId;
  dependentOn: {
    dependencyTemplateId?: ObjectId;
    _id?: ObjectId;
    type: "task" | "stage" | "default";
    name?: string;
  };
};

export type TaskTemplateUpdateRequest = OldTaskTemplateCreateRequest & {
  _id: ObjectId;
  removeParentTaskTemplateId?: boolean;
  subTaskTemplateOrder?: ObjectId[];
  TaskTemplateAction?: TaskTemplateAction;
  action: TaskTemplateAction;
  formTemplateId: ObjectId;
  meeting?: {
    vendor: MeetingVendor;
    link: string;
  };
};

export const journeyTemplateKeys = {
  journeyTemplates: () => ["journeyTemplates", "all"] as const,
  journeyTemplate: (journeyTemplateId: ObjectID) =>
    ["journeyTemplates", journeyTemplateId.toString()] as const,
  stageTemplates: (journeyTemplateId: ObjectID) =>
    [
      ...journeyTemplateKeys.journeyTemplate(journeyTemplateId),
      "stageTemplates",
      "all",
    ] as const,
  taskTemplate: (taskTemplateId: ObjectID) =>
    ["taskTemplates", taskTemplateId.toString()] as const,
  subTaskTemplates: (parentTaskTemplateId: ObjectID) =>
    ["subTaskTemplates", parentTaskTemplateId.toString()] as const,
  taskTemplatesForStage: (
    journeyTemplateId: ObjectID,
    stageTemplateId: ObjectID | undefined
  ) =>
    [
      ...journeyTemplateKeys.journeyTemplate(journeyTemplateId),
      "stageTemplates",
      stageTemplateId?.toString(),
      "taskTemplates",
      "all",
    ] as const,
  taskTemplatesForJourneyTemplate: (journeyTemplateId: ObjectID) =>
    [
      ...journeyTemplateKeys.journeyTemplate(journeyTemplateId),
      "taskTemplates",
      "all",
    ] as const,
  stageAndtaskTemplatesList: (journeyTemplateId: ObjectID) =>
    ["stageAndTaskTemplates", journeyTemplateId] as const,
  progress: (journeyTemplateId: ObjectID) =>
    [
      ...journeyTemplateKeys.journeyTemplate(journeyTemplateId),
      "progress",
    ] as const,
};

export type JourneyTemplateStageProgressData = {
  _id: string;
  name: string;
  durationInStage: number;
  targetDurationInStage: number;
};

export type JourneyTemplateProgressData = {
  customersProgress: {
    customer: {
      _id: string;
      name: string;
      revenue: number;
    };
    project: {
      totalProgress: number;
      startDate: string;
    };
    stagesProgress: JourneyTemplateStageProgressData[];
  }[];
  stages: {
    _id: string;
    name: string;
    templateExists: boolean;
  }[];
};

export const useDeleteTaskTemplateFile = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (taskTemplate: {
      name: string;
      taskTemplateId: string;
    }): Promise<{
      message: string;
    }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.delete(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/fileForTaskTemplate`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            ...taskTemplate,
          },
        }
      );
      return res.data;
    },
    onMutate: async (variables) => {
      const previousTaskTemplate = queryClient.getQueryData<TaskTemplateData>(
        journeyTemplateKeys.taskTemplate(new ObjectID(variables.taskTemplateId))
      );
      queryClient.setQueryData(
        journeyTemplateKeys.taskTemplate(
          new ObjectID(variables.taskTemplateId)
        ),
        {
          ...previousTaskTemplate,
          files: previousTaskTemplate?.files?.filter(
            (f) => f.name !== variables.name
          ),
        }
      );
      return { previousTaskTemplate };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(
        journeyTemplateKeys.taskTemplate(
          new ObjectID(variables.taskTemplateId)
        ),
        context?.previousTaskTemplate
      );
      snackbarCtx.showSnackbar("Error deleting attachment", "error");
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(
        journeyTemplateKeys.taskTemplate(new ObjectID(variables.taskTemplateId))
      );
    },
  });
};

export const useUploadTaskTemplateFile = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (fileDetails: {
      file: File;
      label: string;
      description: string;
      taskTemplateId: string;
    }): Promise<{ message: string }> => {
      const accessToken = await getValidAccessToken();
      const formData = new FormData();

      formData.append("file", fileDetails.file);
      formData.append("label", fileDetails.label);
      formData.append("description", fileDetails.description);
      formData.append("taskTemplateId", fileDetails.taskTemplateId);
      formData.append("instanceId", app.currentUser.customData.instanceId.$oid);

      // console.log(formData.getAll("file"));

      const res = await axios.post(
        `${process.env.REACT_APP_LAMBDA_URL}/uploadFileForTaskTemplate
        `,
        formData,
        {
          headers: {
            Accept: "application/json",
            // "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onMutate: async (variables) => {
      const previousTaskTemplate = queryClient.getQueryData<TaskTemplateData>(
        journeyTemplateKeys.taskTemplate(new ObjectID())
      );
      queryClient.setQueryData(
        journeyTemplateKeys.taskTemplate(new ObjectID()),
        {
          ...previousTaskTemplate,
          files: [
            ...(previousTaskTemplate?.files ?? []),
            {
              name: variables.file.name,
              type: "",
              label: variables.label,
              description: "",
              createdAt: new Date(),
              url: "",
            },
          ],
        }
      );
    },
    onError: (err) => {
      snackbarCtx.showSnackbar("Error uploading file", "error");
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(
        journeyTemplateKeys.taskTemplate(new ObjectID(variables.taskTemplateId))
      );
      // snackbarCtx.showSnackbar("Successfully duplicated journey template");
    },
  });
};

export const useGetJourneyTemplateProgress = (journeyTemplateId: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();

  return useQuery(
    journeyTemplateKeys.progress(new ObjectID(journeyTemplateId)),
    async (): Promise<JourneyTemplateProgressData> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/templateProgress`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            templateId: journeyTemplateId,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};

export const useGetStageTaskTemplates = (
  journeyTemplateId: ObjectID,
  stageTemplateId: ObjectID
) => {
  const app = useRealmApp();
  // const queryClient = useQueryClient();
  const meta = {
    functionName: "getTaskTemplates",
    parameters: {
      ...(stageTemplateId
        ? { journeyStageTemplateId: stageTemplateId }
        : { journeyTemplateId: journeyTemplateId }),
    },
  };

  return useQuery<TaskTemplateData[]>(
    journeyTemplateKeys.taskTemplatesForStage(
      journeyTemplateId,
      stageTemplateId
    ),
    buildFetcher(app, meta)
  );
};

export const useGetTaskTemplates = (journeyTemplateId: ObjectID) => {
  const app = useRealmApp();
  // const queryClient = useQueryClient();
  const meta = {
    functionName: "getTaskTemplates",
    parameters: {
      journeyTemplateId: journeyTemplateId,
    },
  };

  return useQuery<TaskTemplateData[]>(
    journeyTemplateKeys.taskTemplatesForJourneyTemplate(journeyTemplateId),
    buildFetcher(app, meta)
  );
};

export const useGetSubTaskTemplates = (
  parentTaskTemplateId: ObjectID
): {
  subTaskTemplates: TaskTemplateData[] | undefined;
  isLoading: boolean;
  isError: boolean;
  error: any;
} => {
  const app = useRealmApp();
  // const queryClient = useQueryClient();
  const meta = {
    functionName: "getSubTaskTemplates",
    parameters: {
      parentTaskTemplateId,
    },
  };

  const { isSuccess, isLoading, isError, error, data } = useQuery<
    TaskTemplateData[]
  >(
    journeyTemplateKeys.subTaskTemplates(parentTaskTemplateId),
    buildFetcher(app, meta)
  );

  return {
    subTaskTemplates: data,
    isLoading,
    isError,
    error,
  };
};

export const useGetTaskTemplate = (
  taskTemplateId: ObjectID
): {
  taskTemplate: TaskTemplateData | undefined;
} => {
  const app = useRealmApp();
  // const queryClient = useQueryClient();
  const meta = {
    functionName: "getTaskTemplate",
    parameters: {
      _id: taskTemplateId,
    },
  };

  const { data } = useQuery<TaskTemplateData>(
    journeyTemplateKeys.taskTemplate(taskTemplateId),
    buildFetcher(app, meta)
  );

  return {
    taskTemplate: data,
  };
};

export const useCreateTaskTemplate = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (params: {
      taskTemplate: TaskTemplateCreateRequest;
      subTaskTemplates: {
        title: string;
        estimate: TaskTemplateCreateRequest["estimate"];
        taskerPersona: string;
        taskerType: TaskerType;
        isVisible: boolean;
      }[];
      journeyTemplateId: string;
      journeyStageTemplateId: string;
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/createTaskTemplateWithSubTaskTemplates`,
        {
          taskTemplate: params.taskTemplate,
          subTaskTemplates: params.subTaskTemplates,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: (error: AxiosError<{ message: string }>) => {
      if (
        error.response?.data.message ===
        "Form template already used in another task template"
      ) {
        snackbarCtx.showSnackbar(
          "Form template cannot be used twice in the same project template",
          "error"
        );
      } else {
        snackbarCtx.showSnackbar("Error creating task template", "error");
      }
    },
    onSuccess: (data, variables) => {
      console.log(variables);
      if (variables.journeyTemplateId) {
        queryClient.invalidateQueries(
          journeyTemplateKeys.taskTemplatesForStage(
            new ObjectID(variables.journeyTemplateId),
            new ObjectID(variables.journeyStageTemplateId)
          )
        );
        queryClient.invalidateQueries(
          journeyTemplateKeys.stageAndtaskTemplatesList(
            new ObjectID(variables.taskTemplate.journeyTemplateId)
          )
        );
        queryClient.invalidateQueries(
          journeyTemplateKeys.stageTemplates(
            new ObjectID(variables.journeyTemplateId)
          )
        );
      }
      // snackbarCtx.showSnackbar("Successfully duplicated journey template");
    },
  });
};

export const useCreateSubTaskTemplate = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (params: {
      subTaskTemplates: {
        title: string;
        estimate: TaskTemplateCreateRequest["estimate"];
        taskerPersona: string;
        taskerType: TaskerType;
        isVisible: boolean;
      }[];
      parentTaskTemplateId: string;
      journeyTemplateId: string;
      journeyStageTemplateId: string;
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/createTaskTemplateWithSubTaskTemplates`,
        {
          subTaskTemplates: params.subTaskTemplates,
          parentTaskTemplateId: params.parentTaskTemplateId,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error creating task template", "error");
    },
    onSuccess: (data, variables) => {
      if (variables.journeyTemplateId) {
        queryClient.invalidateQueries(
          journeyTemplateKeys.taskTemplatesForStage(
            new ObjectID(variables.journeyTemplateId),
            new ObjectID(variables.journeyStageTemplateId)
          )
        );
        queryClient.invalidateQueries(
          journeyTemplateKeys.subTaskTemplates(
            new ObjectID(variables.parentTaskTemplateId)
          )
        );
        queryClient.invalidateQueries(
          journeyTemplateKeys.taskTemplate(
            new ObjectID(variables.parentTaskTemplateId)
          )
        );
      }
      // snackbarCtx.showSnackbar("Successfully duplicated journey template");
    },
  });
};

// export const useAddTaskTemplate = () => {
//   const app = useRealmApp();
//   const functionName = "createTaskTemplate";
//   const fieldName = "taskTemplate";
//   const queryClient = useQueryClient();

//   const addTaskTemplateMutation = useMutation(
//     buildSetter(app, functionName, fieldName),
//     {
//       onMutate: async ({
//         taskTemplate,
//         stageTemplateId,
//         journeyTemplateId,
//       }: {
//         taskTemplate: TaskTemplateCreateRequest;
//         stageTemplateId: ObjectID;
//         journeyTemplateId: ObjectID;
//       }) => {
//         // await queryClient.cancelQueries(
//         //   journeyTemplateKeys.taskTemplatesForStage(
//         //     journeyTemplateId,
//         //     stageTemplateId
//         //   )
//         // );
//         // const previousTaskTemplates = queryClient.getQueryData(
//         //   journeyTemplateKeys.taskTemplatesForStage(
//         //     journeyTemplateId,
//         //     stageTemplateId
//         //   )
//         // );
//         // const tempTaskTemplate = { _id: undefined, ...taskTemplate };
//         // queryClient.setQueryData(
//         //   journeyTemplateKeys.taskTemplatesForStage(
//         //     journeyTemplateId,
//         //     stageTemplateId
//         //   ),
//         //   (taskTemplates: TaskTemplateData[] = []) => [...taskTemplates, tempTaskTemplate]
//         // );
//         // return { previousTaskTemplates };
//       },
//       onError: (data, variables, context) => {
//         // console.log("ERROR");
//         // queryClient.setQueryData(
//         //   journeyTemplateKeys.taskTemplatesForStage(
//         //     variables.taskTemplate.journeyTemplate._id,
//         //     variables.stageTemplateId
//         //   ),
//         //   context?.previousTaskTemplates
//         // );
//       },
//       onSettled: (data, error, variables) => {
//         if (variables.taskTemplate.parentTaskTemplateId) {
//           queryClient.invalidateQueries(
//             journeyTemplateKeys.taskTemplate(
//               variables.taskTemplate.parentTaskTemplateId
//             )
//           );
//           queryClient.invalidateQueries(
//             journeyTemplateKeys.subTaskTemplates(
//               variables.taskTemplate.parentTaskTemplateId
//             )
//           );
//         }
//         queryClient.invalidateQueries(
//           journeyTemplateKeys.taskTemplatesForStage(
//             variables.journeyTemplateId,
//             variables.stageTemplateId
//           )
//         );
//         queryClient.invalidateQueries(
//           journeyTemplateKeys.stageAndtaskTemplatesList(
//             variables.taskTemplate.journeyTemplateId
//           )
//         );
//       },
//     }
//   );

//   return addTaskTemplateMutation;
// };

export const useUpdateTaskTemplate = () => {
  const app = useRealmApp();
  const functionName = "updateTaskTemplate";
  const fieldName = "params";
  const queryClient = useQueryClient();
  const snackbarCtx = useSnackBar();
  const updateTaskTemplateMutation = useMutation(
    buildSetter(app, functionName, fieldName),
    {
      onMutate: async ({
        params,
        journeyTemplateId,
        previousJourneyStageId,
        journeyStageTemplateId,
      }: {
        params: Partial<TaskTemplateUpdateRequest>;
        journeyTemplateId: ObjectID;
        journeyStageTemplateId: ObjectID;
        previousJourneyStageId?: ObjectID;
        parentTaskTemplateId?: ObjectID;
      }) => {
        const queryKey = journeyTemplateKeys.taskTemplatesForStage(
          journeyTemplateId,
          journeyStageTemplateId
        );
        await queryClient.cancelQueries(queryKey);
        let previousTaskTemplate = undefined;
        if (params._id) {
          previousTaskTemplate = queryClient.getQueryData<TaskTemplateData>(
            journeyTemplateKeys.taskTemplate(params._id)
          );

          const newTaskTemplate = { ...previousTaskTemplate, ...params };

          queryClient.setQueryData(
            journeyTemplateKeys.taskTemplate(params._id),
            newTaskTemplate
          );
        }

        const previousTaskTemplates =
          queryClient.getQueryData<TaskTemplateData[]>(queryKey);

        const newTaskTemplates = previousTaskTemplates
          ? previousTaskTemplates.map((t) =>
              t._id === params._id ? { ...t, ...params } : t
            )
          : [];
        queryClient.setQueryData(queryKey, newTaskTemplates);

        return { previousTaskTemplates, previousTaskTemplate };
      },
      onError: (error: any, variables, context) => {
        if (
          JSON.parse(error?.error)?.message ===
          "Form template already used in another task template"
        ) {
          snackbarCtx.showSnackbar(
            "Form template cannot be used twice in the same project template",
            "error"
          );
        } else {
          snackbarCtx.showSnackbar("Error updating task template", "error");
        }
        if (variables.params._id) {
          queryClient.setQueryData(
            journeyTemplateKeys.taskTemplate(variables.params._id),
            context?.previousTaskTemplate
          );
        }

        queryClient.setQueryData(
          journeyTemplateKeys.taskTemplatesForStage(
            variables.journeyTemplateId,
            variables.journeyStageTemplateId
          ),
          context?.previousTaskTemplates
        );
      },
      onSuccess: (data, variables) => {
        if (variables.params._id) {
          queryClient.invalidateQueries(
            journeyTemplateKeys.taskTemplate(variables.params._id)
          );
          queryClient.invalidateQueries(
            journeyTemplateKeys.subTaskTemplates(variables.params._id)
          );
        }
        queryClient.invalidateQueries(
          journeyTemplateKeys.taskTemplatesForStage(
            variables.journeyTemplateId,
            variables.journeyStageTemplateId
          )
        );
        if (variables.previousJourneyStageId) {
          queryClient.invalidateQueries(
            journeyTemplateKeys.taskTemplatesForStage(
              variables.journeyTemplateId,
              variables.previousJourneyStageId
            )
          );
          queryClient.invalidateQueries(
            journeyTemplateKeys.stageTemplates(variables.journeyTemplateId)
          );
        }

        if (variables.parentTaskTemplateId)
          queryClient.invalidateQueries(
            journeyTemplateKeys.subTaskTemplates(variables.parentTaskTemplateId)            
          );

        
        // if (variables.journeyStageTemplateId && variables.journeyTemplateId) {
        //   queryClient.invalidateQueries(
        //     journeyTemplateKeys.taskTemplatesForStage(
        //       variables.journeyTemplateId,
        //       variables.journeyStageTemplateId
        //     )
        //   );
        // }
      },
    }
  );

  return updateTaskTemplateMutation;
};

export const useDeleteTaskTemplateConfirmation = () => {
  const app = useRealmApp();
  const functionName = "deleteTaskTemplate";
  const fieldName = "params";
  const queryClient = useQueryClient();

  const deleteTaskTemplateMutation = useMutation(
    buildSetter(app, functionName, fieldName),
    {
      onMutate: async ({
        params,
        journeyStageTemplateId,
        journeyTemplateId,
      }: {
        params: { _id: ObjectID; force: false };
        journeyStageTemplateId: ObjectID;
        journeyTemplateId: ObjectID;
      }) => {
        const queryKey = journeyTemplateKeys.taskTemplatesForStage(
          journeyTemplateId,
          journeyStageTemplateId
        );
        await queryClient.cancelQueries(queryKey);

        const previousTaskTemplates = queryClient.getQueryData(queryKey);

        queryClient.setQueryData(queryKey, (tasks: TaskTemplateData[] = []) =>
          tasks.filter((t) => t._id !== params._id)
        );

        return { previousTaskTemplates };
      },
      onError: (data, variables, context) => {
        // console.log("ERROR");
        // queryClient.setQueryData(
        //   journeyTemplateKeys.taskTemplatesForStage(
        //     variables.journeyTemplateId,
        //     variables.journeyStageTemplateId
        //   ),
        //   context?.previousTaskTemplates
        // );
      },
      onSuccess: (
        data: {
          deleted: { _id: ObjectID; title: string }[];
          dependencies: {
            _id: ObjectID;
            title: string;
          }[];
        },
        variables
      ) => {
        // queryClient.invalidateQueries(
        //   journeyTemplateKeys.taskTemplatesForStage(
        //     variables.journeyTemplateId,
        //     variables.journeyStageTemplateId
        //   )
        // );
      },
    }
  );

  return deleteTaskTemplateMutation;
};

export const useDeleteTaskTemplate = () => {
  const app = useRealmApp();
  const functionName = "deleteTaskTemplate";
  const fieldName = "params";
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const deleteTaskTemplateMutation = useMutation(
    buildSetter(app, functionName, fieldName),
    {
      onMutate: async ({
        params,
        journeyStageTemplateId,
        journeyTemplateId,
      }: {
        params: { _id: ObjectID; force: true };
        journeyStageTemplateId: ObjectID;
        journeyTemplateId: ObjectID;
        parentTaskTemplateId?: ObjectID;
      }) => {
        const queryKey = journeyTemplateKeys.taskTemplatesForStage(
          journeyTemplateId,
          journeyStageTemplateId
        );
        await queryClient.cancelQueries(queryKey);

        const previousTaskTemplates = queryClient.getQueryData(queryKey);

        queryClient.setQueryData(queryKey, (tasks: TaskTemplateData[] = []) =>
          tasks.filter((t) => t._id !== params._id)
        );
        // navigate(-1);
        return { previousTaskTemplates };
      },
      onError: (data, variables, context) => {
        // console.log("ERROR");
        queryClient.setQueryData(
          journeyTemplateKeys.taskTemplatesForStage(
            variables.journeyTemplateId,
            variables.journeyStageTemplateId
          ),
          context?.previousTaskTemplates
        );
      },
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(
          journeyTemplateKeys.taskTemplatesForStage(
            variables.journeyTemplateId,
            variables.journeyStageTemplateId
          )
        );

        if (variables.parentTaskTemplateId) {
          queryClient.invalidateQueries(
            journeyTemplateKeys.taskTemplate(variables.parentTaskTemplateId)
          );
          queryClient.invalidateQueries(
            journeyTemplateKeys.subTaskTemplates(variables.parentTaskTemplateId)
          );
        } else {
          queryClient.invalidateQueries(
            journeyTemplateKeys.stageTemplates(variables.journeyTemplateId)
          );
        }
      },
    }
  );

  return deleteTaskTemplateMutation;
};

export const useDeleteJourneyTemplate = () => {
  const app = useRealmApp();
  const functionName = "deleteJourneyTemplate";
  const fieldName = "params";
  const queryClient = useQueryClient();
  const snackbarCtx = useSnackBar();
  const navigate = useNavigate();

  const deleteJourneyTemplateMutation = useMutation(
    buildSetter(app, functionName, fieldName),
    {
      onMutate: async ({
        params,
      }: {
        params: {
          _id: ObjectID;
        };
      }) => {
        // const queryKey = journeyTemplateKeys.journeyTemplates();
        // await queryClient.cancelQueries(queryKey);
        // const previousJourneyTemplates = queryClient.getQueryData(queryKey);
        // queryClient.setQueryData(queryKey, (stages: JourneyStageObj[] = []) =>
        //   stages.filter((s) => s._id !== params._id)
        // );
        // return { previousJourneys };
      },
      onError: (data, variables, context) => {
        // queryClient.setQueryData(
        //   journeyKeys.journeys(),
        //   context?.previousJourneys
        // );
      },
      onSettled: (data, error, variables) => {
        snackbarCtx.showSnackbar(
          "Project template successfully deleted",
          "success"
        );
        navigate("/templates/projects");
        queryClient.invalidateQueries(journeyTemplateKeys.journeyTemplates());
      },
    }
  );

  return deleteJourneyTemplateMutation;
};

export const useDuplicateJourneyTemplate = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (journeyTemplate: {
      _id: string;
      name: string;
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/duplicateJourneyTemplate`,
        journeyTemplate,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error duplicating journey template", "error");
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(journeyTemplateKeys.journeyTemplates());
      if (data.id) {
        navigate(`/templates/projects`);
      }
    },
  });
};
