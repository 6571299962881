import {
  ChatBubbleLeftEllipsisIcon,
  ChevronLeftIcon,
  GlobeAltIcon,
  Squares2X2Icon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { useQueryClient } from "@tanstack/react-query";
import Link from "@tiptap/extension-link";
import Typography from "@tiptap/extension-typography";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { DonutChart, Legend } from "@tremor/react";
import axios from "axios";
import { ObjectID } from "bson";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getSubdomain, isRestrictedSubdomain } from "src/App";
import { useGetThemeSettings } from "src/api/CustomerPortal/login";
import {
  homebaseKeys,
  useGetPhaseSummary,
  useGetSharedSpaceTimeline,
} from "src/api/Homebase/homebase";
import { useGetLogo } from "src/api/Misc/Settings/Embedding/embed";
import {
  JourneyData,
  useGetJourney,
  useGetJourneyStages,
} from "src/api/Services/Journeys/journeys";
import {
  ProjectMembers,
  useGetProjectMembers,
} from "src/api/Services/Projects/projects";
import {
  MinimalPhaseData,
  PhaseData,
  useGetPhase,
  useGetPhases,
} from "src/api/Services/SharedSpace/phases";
import {
  TaskData,
  useGetPhaseTasks,
  useUpdateTask,
} from "src/api/Services/Tasks/tasks";
import {
  ThreadData,
  useCreateThreadInPhase,
  useGetPhaseThreads,
} from "src/api/Services/Threads/threads";
import { JourneyStageObj } from "src/components/Services/Journeys/Journey/types";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { Button, Button as NewButton } from "src/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "src/components/ui/dialog";
import { Loading } from "src/components/ui/loading";
import {
  Select as NewSelect,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/components/ui/tabs";
import { TextInput } from "src/components/ui/text-input";
import { useRealmApp } from "src/store/RealmApp";
import { getTimeDifference } from "src/utils/functions/dateTime";
import { JourneyContextProvider } from "../Services/Journeys/Journey/JourneyContextProvider";
import JourneyRun from "../Services/Journeys/Journey/JourneyRun/JourneyRun";
import PhaseThreads from "../Services/SharedSpace/Phases/Tabs/Threads";
import PhaseResources from "../Services/SharedSpace/Phases/Tabs/resources/resources";
import { ResourcesContextProvider } from "../Services/SharedSpace/Phases/Tabs/resources/resources-context-provider";
import { StatusSelect } from "../Services/Tasks/Task";

type TaskBreakdownGroup = "all" | "internal" | "external";

function TasksDonut({ phase }: { phase: PhaseData }) {
  const { data: tasks } = useGetPhaseTasks(phase._id);
  const [value, setValue] = useState<TaskBreakdownGroup>("all");
  const tasksBreakdown: [
    { type: "To Do"; value: number },
    { type: "In Progress"; value: number },
    { type: "Stuck"; value: number },
    { type: "Completed"; value: number },
    { type: "Inactive"; value: number }
  ] = [
    {
      type: "To Do",
      value:
        tasks
          ?.filter((t) => t.isVisible && !!t.dueDate)
          .filter(
            (t) =>
              t.status === "To-do" &&
              (value === "all"
                ? true
                : value === "internal"
                ? t.taskerType === "internal"
                : t.taskerType === "external")
          ).length ?? 0,
    },
    {
      type: "In Progress",
      value:
        tasks
          ?.filter((t) => t.isVisible)
          .filter(
            (t) =>
              t.status === "In Progress" &&
              (value === "all"
                ? true
                : value === "internal"
                ? t.taskerType === "internal"
                : t.taskerType === "external")
          ).length ?? 0,
    },
    {
      type: "Stuck",
      value:
        tasks
          ?.filter((t) => t.isVisible)
          .filter(
            (t) =>
              t.status === "Stuck" &&
              (value === "all"
                ? true
                : value === "internal"
                ? t.taskerType === "internal"
                : t.taskerType === "external")
          ).length ?? 0,
    },
    {
      type: "Completed",
      value:
        tasks
          ?.filter((t) => t.isVisible)
          .filter(
            (t) =>
              t.status === "Completed" &&
              (value === "all"
                ? true
                : value === "internal"
                ? t.taskerType === "internal"
                : t.taskerType === "external")
          ).length ?? 0,
    },
    {
      type: "Inactive",
      value:
        tasks
          ?.filter((t) => t.isVisible && !t.dueDate)
          .filter((t) =>
            value === "all"
              ? true
              : value === "internal"
              ? t.taskerType === "internal"
              : t.taskerType === "external"
          ).length ?? 0,
    },
  ];

  return (
    <div className="space-y-4">
      <NewSelect
        value={value}
        onValueChange={(value) => {
          setValue(value as TaskBreakdownGroup);
        }}
      >
        <SelectTrigger className={`h-7 w-fit max-w-full gap-2 bg-white px-2`}>
          <UsersIcon className="h-4 w-4 shrink-0 text-gray-700" />
          <SelectValue className="truncate text-xs">
            {value === "all"
              ? "All"
              : value === "internal"
              ? phase.instance.name
              : phase.customer?.name}
          </SelectValue>
        </SelectTrigger>
        <SelectContent className="min-w-[120px]">
          <SelectGroup>
            <SelectItem value={"all"}>All</SelectItem>
            <SelectItem value={"external"}>{phase.customer?.name}</SelectItem>
            <SelectItem value={"internal"}>{phase.instance.name}</SelectItem>
          </SelectGroup>
        </SelectContent>
      </NewSelect>
      <DonutChart
        data={tasksBreakdown}
        category="value"
        index="type"
        // valueFormatter={valueFormatter}
        colors={["amber", "blue", "red", "green", "gray"]}
      />
      <div className="flex justify-center">
        <Legend
          className="w-60 flex-wrap justify-center"
          categories={[
            "To Do",
            "In Progress",
            "Stuck",
            "Completed",
            "Inactive",
          ]}
          colors={["amber", "blue", "red", "green", "gray"]}
        />
      </div>
    </div>
  );
}

function TasksOverdue({ tasks }: { tasks: TaskData[] }) {
  const tasksOverdue = tasks.filter(
    (t) =>
      (t.status === "To-do" || t.status === "In Progress") &&
      t.dueDate &&
      dayjs(t.dueDate).isBefore(dayjs())
  );

  const totalDaysOverdue = tasksOverdue.reduce(
    (acc, t) => acc + dayjs().diff(dayjs(t.dueDate), "day"),
    0
  );
  return (
    <div
      className={clsx(
        "row-span-1 space-y-4 rounded-md border p-4 shadow",
        tasksOverdue.length > 0
          ? "border-red-300 bg-red-50"
          : "border-gray-300 bg-gray-50"
      )}
    >
      <div className="flex items-center justify-between">
        <div
          className={clsx(
            "text-lg font-medium",
            tasksOverdue.length > 0 ? "text-red-600" : "text-gray-600"
          )}
        >
          Tasks Overdue
        </div>
        {/* <button className="rounded-md p-1 hover:bg-red-100">
                  <ArrowsPointingOutIcon className="h-5 w-5 text-red-500" />
                </button> */}
      </div>
      <div className="space-y-1">
        <div
          className={clsx(
            "text-5xl font-bold",
            tasksOverdue.length > 0 && "text-red-500"
          )}
        >
          {tasksOverdue.length}
        </div>
        <div
          className={clsx(
            tasksOverdue.length > 0 ? "text-red-500" : "text-gray-600"
          )}
        >
          {tasksOverdue.length > 0
            ? `${totalDaysOverdue} days overdue in total`
            : "No tasks overdue"}
        </div>
      </div>
    </div>
  );
}

function TasksBlocked({ tasks }: { tasks: TaskData[] }) {
  const tasksBlocked = tasks.filter((t) => t.blocker && !t.blocker?.resolved);

  return (
    <div
      className={clsx(
        "row-span-1 space-y-4 rounded-md border p-4 shadow",
        tasksBlocked.length > 0
          ? "border-red-300 bg-red-50"
          : "border-gray-300 bg-gray-50"
      )}
    >
      <div className="flex items-center justify-between">
        <div
          className={clsx(
            "text-lg font-medium",
            tasksBlocked.length > 0 ? "text-red-600" : "text-gray-600"
          )}
        >
          Tasks Blocked
        </div>
      </div>
      <div className="space-y-1">
        <div
          className={clsx(
            "text-5xl font-bold",
            tasksBlocked.length > 0 && "text-red-500"
          )}
        >
          {tasksBlocked.length}
        </div>
        <div
          className={clsx(
            tasksBlocked.length > 0 ? "text-red-500" : "text-gray-600"
          )}
        >
          {tasksBlocked.length > 0
            ? `${tasksBlocked.length} tasks blocked`
            : "No tasks blocked"}
        </div>
      </div>
    </div>
  );
}

export default function Homebase() {
  const { phaseId } = useParams();
  const { data: phase, error, isError } = useGetPhase(String(phaseId));
  const [is404, setIs404] = useState(false);
  // const slug = getSubdomain();
  // const { data: phases } = useGetPhasesForExternalUser(String(slug));
  const app = useRealmApp();
  const navigate = useNavigate();
  useEffect(() => {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        setIs404(true);
      }
    }
  }, [error]);
  // useEffect(() => {
  //   if (!!phases && phases.length === 0) {
  //     app.logOut();
  //   }
  // }, [phases]);
  if (is404) {
    return (
      <div className="mt-[30vh] flex items-center justify-center">
        <div className="flex w-[520px] max-w-md flex-col items-center rounded-md border p-10 shadow-md">
          <div className="text-center text-xl font-medium leading-none">
            Project not found
          </div>

          <div className="max-w-[300px] py-6 pb-7 text-center font-light leading-relaxed text-gray-500">
            This project might have been deleted or you no longer have access to
            it. Click below to view all your projects.
          </div>
          <div className="flex space-x-2.5">
            <Button
              variant={"embed"}
              onClick={() => {
                navigate(`/projects`);
              }}
            >
              View all projects
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {!!phase ? (
        <>
          <PhaseHomebase phase={phase} />
        </>
      ) : (
        <div className="pt-[30vh]">
          <Loading />
        </div>
      )}
    </>
  );
}

function PhaseHomebase({ phase }: { phase: PhaseData }) {
  const slug = getSubdomain();
  // const slug= "sero-demo"
  const isInternal = isRestrictedSubdomain(slug);
  const app = useRealmApp();
  const { phaseId, tab } = useParams();
  const { journey } = useGetJourney(new ObjectID(phase.journey._id));
  const { data: threads } = useGetPhaseThreads(String(phase._id));
  const navigate = useNavigate();
  const threadCount =
    threads?.reduce((acc, t) => acc + t.otherDetails.unreadMessagesCount, 0) ??
    0;

  if (!tab) {
    if (isInternal || !slug) {
      navigate(
        `/external-view/shared-spaces/${phase.sharedSpaceId}/phases/${phaseId}/home`,
        { replace: true }
      );
    } else {
      navigate(`/projects/${phaseId}/home`, {
        replace: true,
      });
    }
  } else {
    if (
      tab !== "home" &&
      tab !== "project" &&
      tab !== "chat" &&
      tab !== "resources" &&
      tab !== "notes"
    ) {
      navigate(`/projects/${phaseId}/home`, {
        replace: true,
      });
    }
  }

  return (
    <div className="flex h-full flex-1 flex-col overflow-auto pt-6 tracking-wide">
      <div className="flex items-start justify-between px-8">
        <div className="flex items-center space-x-3">
          <Logo />
          <div className="h-1.5 w-1.5 rounded-full bg-gray-300" />
          {/* <div className="h-1.5 w-1.5 rounded-full bg-gray-300" /> */}
          <h2 className="cursor-default text-xl font-medium leading-7 tracking-tight text-gray-900">
            {phase.name}
          </h2>
        </div>

        {isInternal && (
          <div className="flex space-x-3">
            <NewButton
              variant="secondary"
              onClick={() => {
                navigate(`/external-view/shared-spaces/${phase.sharedSpaceId}`);
              }}
            >
              <Squares2X2Icon className="mr-2 h-5 w-5" />
              <span>View All Projects</span>
            </NewButton>
          </div>
        )}
        {!isInternal && (
          <div className="flex space-x-3">
            <NewButton
              variant="secondary"
              onClick={() => {
                navigate(`/projects`);
              }}
            >
              <Squares2X2Icon className="mr-2 h-5 w-5" />
              <span>View All Projects</span>
            </NewButton>
            <NewButton
              variant="secondary"
              onClick={() => {
                app.logOut();
              }}
            >
              Logout
            </NewButton>
          </div>
        )}
      </div>

      {/* {journey && <Tabs journey={journey} phase={phase} />} */}
      {journey && (
        <Tabs
          defaultValue="home"
          className="flex flex-1 flex-col overflow-auto pt-4"
          value={tab}
          onValueChange={(value) => {
            if (isInternal || !slug) {
              navigate(
                `/external-view/shared-spaces/${phase.sharedSpaceId}/phases/${phaseId}/${value}`
              );
            } else {
              navigate(`/projects/${phaseId}/${value}`);
            }
          }}
        >
          <TabsList className="shrink-0 px-8">
            <TabsTrigger value="home">Home</TabsTrigger>
            <TabsTrigger value="project">Project</TabsTrigger>
            <TabsTrigger value="chat">
              <div className="inline-flex space-x-2">
                <span>Chat</span>
                {threadCount > 0 && (
                  <span
                    className={
                      "ml-3 hidden h-5 min-w-[18px] rounded bg-embedPrimary py-0.5 px-1 text-xs font-medium text-white data-[state=active]:bg-primary-100 data-[state=active]:text-primary-600 md:inline-block"
                    }
                  >
                    {threadCount}
                  </span>
                )}
              </div>
            </TabsTrigger>
            <TabsTrigger value="resources">Resources</TabsTrigger>
          </TabsList>
          <div className="flex-1 overflow-auto">
            <TabsContent value="home" className="h-full px-8 pt-6">
              <Overview phase={phase} />
            </TabsContent>
            <TabsContent value="project" className="h-full overflow-auto">
              <div className="col-span-8 flex h-full flex-1 flex-col overflow-auto">
                <JourneyContextProvider isExternal={true}>
                  <JourneyRun journeyId={journey._id.toString()} />
                </JourneyContextProvider>
              </div>
            </TabsContent>
            <TabsContent value="chat" className="h-full">
              <PhaseThreads isInternal={false} threads={threads ?? []} />
            </TabsContent>
            <TabsContent value="resources">
              <ResourcesContextProvider isExternal={true}>
                <PhaseResources phaseId={phase._id} />
              </ResourcesContextProvider>
              {/* <FilesContextProvider isExternal={true}>
                <Files phase={phase} />
              </FilesContextProvider> */}
            </TabsContent>
          </div>
        </Tabs>
      )}
    </div>
  );
}

export function Overview({ phase }: { phase: PhaseData }) {
  const { journey } = useGetJourney(new ObjectID(phase.journey._id));
  const { data: tasks } = useGetPhaseTasks(phase._id);
  // const tasksOverdue = tasks?.filter(
  //   (t) =>
  //     (t.status === "To-do" ||
  //       t.status === "In Progress" ||
  //       t.status === "Stuck") &&
  //     t.dueDate &&
  //     dayjs(t.dueDate).isBefore(dayjs())
  // );

  // const { data: keyDates } = useGetKeyDatesForPhase(phase._id);
  return (
    <div className="mx-auto h-full max-w-5xl">
      <div className="flex justify-center pt-4">
        {journey && <Timeline journey={journey} />}
      </div>
      <div className="grid max-w-5xl grid-cols-3 gap-10 pb-12 pt-6">
        {/* <div className="col-span-1 h-full space-y-4">
          <div className="font- text-2xl  text-gray-600">Overview</div>
          <div className="space-y-2 rounded-md border p-4 text-sm shadow">
            <div className="text-lg font-medium text-gray-600">Key Dates</div>
            <div className="flex items-center justify-between">
              <div className="text-gray-600">Start Date</div>
              {!!keyDates && (
                <div>{dayjs(keyDates?.startedDate).format("DD MMM, YYYY")}</div>
              )}
            </div>
            <div className="flex items-center justify-between">
              <div className="text-gray-600">Forecasted End Date</div>
              {!!keyDates && (
                <div>
                  {dayjs(keyDates?.forecastedCompletionDate).format(
                    "DD MMM, YYYY"
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="space-y-2 rounded-md border p-4 text-sm shadow">
            <div className="text-lg font-medium text-gray-600">
              Tasks Breakdown
            </div>
            <TasksDonut phase={phase} />
          </div>
          {!!tasksOverdue && (
            <div
              className={clsx(
                "flex items-center justify-between rounded-md border px-4 py-3 shadow",
                tasksOverdue?.length > 0 ? "" : ""
              )}
            >
              <div
                className={clsx(
                  "",
                  tasksOverdue?.length > 0 ? "text-red-500" : ""
                )}
              >
                Overdue Tasks
              </div>
              <div
                className={clsx(
                  "text-2xl font-medium",
                  tasksOverdue.length > 0 ? "text-red-500" : ""
                )}
              >
                {tasksOverdue.length}
              </div>
            </div>
          )}
        </div> */}
        <div className="col-span-2 h-full">
          <NextUp phase={phase} />
        </div>
        <div className="col-span-1 h-full space-y-8">
          <ChatWidget phase={phase} />
          {/* <ProgressWidget journeyId={phase.journey._id} /> */}
        </div>
      </div>
      {/* <div className="col-span-1 h-full">
        <Risks phase={phase} />
      </div> */}
    </div>
  );
}

function ProgressWidget({ journeyId }: { journeyId: string }) {
  const { stages } = useGetJourneyStages(new ObjectID(journeyId));
  return (
    <div className="space-y-3">
      <div className="text-lg font-semibold leading-none text-gray-600">
        Progress
      </div>
      <div className="space-y-2 rounded-md border p-4 shadow-md">
        {stages?.map((stage) => {
          return <Stage stage={stage} />;
        })}
      </div>
    </div>
  );

  function Stage({ stage }: { stage: JourneyStageObj }) {
    const totalTasks = stage.tasks?.filter((t) => t.isVisible).length ?? 0;
    const completedTasks =
      stage.tasks?.filter(
        (t) =>
          t.isVisible && (t.status === "Completed" || t.status === "Canceled")
      ).length ?? 0;
    const isActiveStage = stage.tasks?.filter((t) => !!t.dueDate).length ?? 0;
    return (
      <div className="flex justify-between gap-4">
        <div className="flex items-center justify-start gap-2.5">
          <div
            className={clsx(
              "h-3 w-3 rounded-full",
              !isActiveStage && "bg-gray-200",
              isActiveStage && completedTasks !== totalTasks && "bg-blue-400",
              completedTasks === totalTasks && totalTasks > 0 && "bg-green-500"
            )}
          />
          <div
            className={clsx(
              "truncate",
              !isActiveStage && "text-gray-400",
              isActiveStage && completedTasks !== totalTasks && "text-blue-500",
              completedTasks === totalTasks && "text-green-600"
            )}
          >
            {stage.name}
          </div>
        </div>
        <div
          className={clsx(
            !isActiveStage && "text-gray-300",
            isActiveStage && completedTasks !== totalTasks && "text-blue-500",
            completedTasks === totalTasks && totalTasks > 0 && "text-green-600"
          )}
        >{`${((completedTasks / totalTasks) * 100).toFixed(0)}%`}</div>
      </div>
    );
  }
}

function ChatWidget({ phase }: { phase: PhaseData }) {
  const { data: chats } = useGetPhaseThreads(phase._id);
  const navigate = useNavigate();
  const location = useLocation();
  const [newChatDialogOpen, setNewChatDialogOpen] = useState(false);
  const createThreadMutation = useCreateThreadInPhase();
  const [subject, setSubject] = useState("");
  const { data: projectMembers } = useGetProjectMembers(phase._id);

  useEffect(() => {
    if (createThreadMutation.isSuccess) {
      setNewChatDialogOpen(false);
      const res = createThreadMutation.data as { id: ObjectID };
      if (res.id) {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("chatPanelId", res.id.toString());
        navigate({
          pathname: location.pathname,
          search: searchParams.toString(),
        });
      }
    }
  }, [createThreadMutation.isSuccess]);
  const app = useRealmApp();

  const myChats =
    chats?.filter((c) =>
      c.userIds
        .map((i) => i.toString())
        .includes(app.currentUser?.customData?._id)
    ) ?? [];

  return (
    <div className="space-y-3">
      <div className="text-xl font-semibold leading-none text-gray-600">
        My Chats
      </div>

      <div className="rounded-md border border-gray-200 shadow-md">
        <div className="flex items-center gap-2 px-4">
          {/* <ChatBubbleLeftEllipsisIcon className="h-5 w-5 text-gray-600" /> */}

          {/* <NewButton variant={"secondary"}>
          <ChatBubbleLeftEllipsisIcon className="h-5 w-5 mr-2" />
          <span>Start a Chat</span>
        </NewButton> */}
        </div>
        <div className="px-4 py-4">
          <NewButton
            variant={"embed"}
            onClick={() => {
              setNewChatDialogOpen(true);
            }}
            className="w-full"
            size="lg"
          >
            <span>Start a Chat</span>
          </NewButton>
        </div>

        {/* <div
          className="flex cursor-pointer items-center gap-1 py-3 px-4 hover:bg-gray-100"
          onClick={() => {
            setNewChatDialogOpen(true);
          }}
        >
          <Pencil2Icon className="mr-2 h-5 w-5" />
          <span className="">Start a Chat</span>
        </div> */}
        {!!myChats?.length && (
          <div className="divide-y pb-4">
            {myChats
              .sort((c1, c2) => {
                return c2.updatedAt.getTime() - c1.updatedAt.getTime();
              })
              .map((chat) => {
                return (
                  <ChatListItem
                    key={chat._id.toString()}
                    chat={chat}
                    projectMembers={projectMembers}
                  />
                );
              })}
          </div>
        )}
        <Dialog
          open={newChatDialogOpen}
          onOpenChange={(open) => {
            setNewChatDialogOpen(open);
          }}
        >
          <DialogContent>
            <DialogHeader>
              <DialogTitle>New Chat</DialogTitle>
              <DialogDescription>{`Create a new chat with the ${phase.instance.name} team`}</DialogDescription>
            </DialogHeader>

            <div className="space-y-1 pt-2 pb-4">
              <InputHeading heading="Subject" />
              <TextInput
                value={subject}
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
              />
            </div>

            <div className="flex justify-end space-x-2">
              <NewButton
                variant={"secondary"}
                onClick={() => {
                  setNewChatDialogOpen(false);
                }}
              >
                Cancel
              </NewButton>
              <NewButton
                onClick={() => {
                  if (phase._id) {
                    createThreadMutation.mutate({
                      params: {
                        name: subject,
                        phaseId: new ObjectID(phase._id),
                        tags: [],
                        userIds: [],
                      },
                    });
                  }
                }}
                disabled={
                  createThreadMutation.isLoading || subject.trim().length === 0
                }
              >
                {createThreadMutation.isLoading ? "Creating..." : "Create"}
              </NewButton>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

function ChatListItem({
  chat,
  projectMembers,
}: {
  chat: ThreadData;
  projectMembers: ProjectMembers | undefined;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const lastActiveToday = dayjs(chat.updatedAt).isSame(dayjs(), "day");
  const lastActiveYesterday = dayjs(chat.updatedAt).isSame(
    dayjs().subtract(1, "day"),
    "day"
  );
  return (
    <div
      key={chat._id.toString()}
      className="cursor-pointer px-4 py-2 hover:bg-gray-100"
      onClick={() => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("chatPanelId", chat._id.toString());
        navigate({
          pathname: location.pathname,
          search: searchParams.toString(),
        });
      }}
    >
      <div className="space-y-0.5">
        <div className="flex items-center justify-between gap-3">
          <div className="truncate text-gray-700">{chat.name}</div>
          <div className="whitespace-nowrap text-xs text-gray-400">
            {lastActiveToday
              ? dayjs(chat.updatedAt).format("h:mm A")
              : lastActiveYesterday
              ? "Yesterday"
              : dayjs(chat.updatedAt).format("MMM DD")}
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div>
            {!projectMembers ? (
              <div className="flex items-center space-x-1">
                <div className="h-6 w-6 animate-pulse rounded bg-gray-200" />
                <div className="h-6 w-6 animate-pulse rounded bg-gray-200" />
              </div>
            ) : (
              <div className="flex items-center space-x-1">
                {chat.userIds.map((userId) => {
                  const internalMember = projectMembers.internal.find(
                    (m) => m._id?.toString() === userId.toString()
                  );
                  const externalMember = projectMembers.external.find(
                    (m) =>
                      m.userType === "fullUser" &&
                      m._id?.toString() === userId.toString()
                  );
                  return (
                    <div
                      key={userId.toString()}
                      className={clsx(
                        "flex h-6 w-6 items-center justify-center rounded border text-xs shadow-sm",
                        !!internalMember
                          ? "border-gray-300 text-gray-400"
                          : "border-gray-300 bg-gray-200 text-gray-600"
                      )}
                    >
                      {!!internalMember
                        ? internalMember?.name
                            .toUpperCase()
                            .split(" ")
                            .map((w) => w[0])
                            .join("")
                        : externalMember?.email
                            .toUpperCase()
                            .split(" ")
                            .map((w) => w[0])
                            .join("")}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {!!chat.otherDetails.unreadMessagesCount && (
            <div className="h-5 min-w-[18px] shrink-0 rounded bg-primary-main px-1 py-0.5 text-center text-xs text-white">
              <span className="leading-3">
                {chat.otherDetails?.unreadMessagesCount}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function ForecastAheadOrBehind({
  keyDates,
}: {
  keyDates: {
    startedDate: string;
    plannedCompletionDate: string;
    forecastedCompletionDate: string;
    completionDate?: string;
  };
}) {
  const delta = getTimeDifference(
    new Date(keyDates.forecastedCompletionDate),
    new Date(keyDates.plannedCompletionDate)
  );
  return (
    <div>
      <div
        className={clsx(
          "text-sm",
          delta.isDate2After ? "text-green-500" : "text-red-500"
        )}
      >{`Project is ${delta.absValue} ${delta.unit} ${
        delta.isDate2After ? "ahead of" : "behind"
      } schedule`}</div>
    </div>
  );
}

type NextUpBreakdownGroup = "my tasks" | "internal" | "external";

function NextUp({ phase }: { phase: PhaseData }) {
  const { data: tasks } = useGetPhaseTasks(phase._id);
  const slug = getSubdomain();
  const isInternal = isRestrictedSubdomain(slug);
  const app = useRealmApp();
  const [tasker, setTasker] = useState<NextUpBreakdownGroup>(
    !isInternal ? "my tasks" : "external"
  );

  const nextUpTasks = tasks?.filter(
    (t) =>
      (t.status === "To-do" ||
        t.status === "In Progress" ||
        t.status === "Stuck") &&
      !!t.dueDate &&
      t.isVisible
  );

  const myTasks = nextUpTasks?.filter(
    (t) =>
      t.tasker.userType === "fullUser" &&
      t.tasker._id === app.currentUser?.customData?._id
  );

  const internalTasks = nextUpTasks?.filter((t) => t.taskerType === "internal");

  const externalTasks = nextUpTasks?.filter((t) => t.taskerType === "external");

  const filteredTasks = tasks
    ?.filter(
      (t) =>
        (t.status === "To-do" ||
          t.status === "In Progress" ||
          t.status === "Stuck") &&
        !!t.dueDate &&
        t.isVisible
    )
    .filter((t) => {
      if (tasker === "my tasks") {
        return (
          t.tasker.userType === "fullUser" &&
          t.tasker._id.toString() === app.currentUser?.customData?._id
        );
      } else if (tasker === "internal") {
        return t.taskerType === "internal";
      } else if (tasker === "external") {
        return t.taskerType === "external";
      }
    })
    .sort((a, b) => dayjs(a.dueDate).diff(dayjs(b.dueDate)));

  const taskerText =
    tasker === "my tasks"
      ? app.currentUser?.customData?.name
      : tasker === "internal"
      ? phase.instance.name
      : phase.customer?.name;
  return (
    <div className="flex flex-1 flex-col pb-12">
      <div className="flex items-center justify-between gap-4">
        <div className="text-xl font-semibold leading-none text-gray-700">
          Next Up
        </div>
        {/* <div>
          <NewSelect
            value={tasker}
            onValueChange={(value) => {
              setTasker(value as NextUpBreakdownGroup);
            }}
          >
            <SelectTrigger
              className={`h-7 w-fit max-w-full gap-2 bg-white px-2`}
            >
              <UsersIcon className="h-4 w-4 shrink-0 text-gray-700" />
              <SelectValue className="truncate text-xs">
                {tasker === "my tasks"
                  ? app.currentUser?.customData?.name
                  : tasker === "internal"
                  ? phase.instance.name
                  : phase.customer?.name}
              </SelectValue>
            </SelectTrigger>
            <SelectContent align="end" className="min-w-[120px]">
              <SelectGroup>
                <SelectItem value={"my tasks"}>
                  {app.currentUser?.customData?.name}
                </SelectItem>
                <SelectItem value={"external"}>
                  {phase.customer?.name}
                </SelectItem>
                <SelectItem value={"internal"}>
                  {phase.instance.name}
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </NewSelect>
        </div> */}
      </div>
      <div className="mt-3 grid grid-cols-3 rounded-md shadow">
        <div
          className={clsx(
            "flex cursor-pointer flex-col items-center rounded-l-md border border-r-0 p-3 hover:bg-gray-100",
            tasker === "my tasks" && "border-b-4 border-b-embedPrimary"
          )}
          onClick={() => {
            setTasker("my tasks");
          }}
        >
          <div className="max-w-full truncate text-sm text-gray-600">
            {app.currentUser.customData.name}
          </div>
          <div className="text-gray-700">
            <span className="text-2xl font-bold">{myTasks?.length}</span>{" "}
            <span className="text-sm font-semibold">
              {myTasks?.length === 1 ? "task" : "tasks"}
            </span>
          </div>
        </div>

        <div
          className={clsx(
            "flex cursor-pointer flex-col items-center border border-x p-3 hover:bg-gray-100",
            tasker === "internal" && "border-b-4 border-b-embedPrimary"
          )}
          onClick={() => {
            setTasker("internal");
          }}
        >
          <div className="max-w-full truncate text-sm text-gray-600">
            {phase.instance.name}
          </div>
          <div className="text-gray-700">
            <span className="text-2xl font-bold">{internalTasks?.length}</span>{" "}
            <span className="text-sm font-semibold">
              {internalTasks?.length === 1 ? "task" : "tasks"}
            </span>
          </div>
        </div>

        <div
          className={clsx(
            "flex cursor-pointer flex-col items-center rounded-r-md border border-l-0 p-3 hover:bg-gray-100",
            tasker === "external" && "border-b-4 border-b-embedPrimary"
          )}
          onClick={() => {
            setTasker("external");
          }}
        >
          <div className="max-w-full truncate text-sm text-gray-600">
            {phase.customer?.name}
          </div>
          {!!externalTasks && (
            <div className="text-gray-700">
              <span className="text-2xl font-bold">
                {externalTasks?.length}
              </span>{" "}
              <span className="text-sm font-semibold">
                {externalTasks?.length === 1 ? "task" : "tasks"}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="mt-4 space-y-4">
        {!!filteredTasks && (
          <>
            {filteredTasks.length === 0 ? (
              <div className="flex items-center justify-center space-x-3 rounded-md border-2 border-dashed px-3 py-10 text-gray-500">
                {tasker === "my tasks" ? (
                  <span>{`No active tasks for you!`}</span>
                ) : (
                  <span>{`No active tasks for ${taskerText}!`}</span>
                )}
              </div>
            ) : (
              <>
                {filteredTasks.map((task) => {
                  return (
                    <NextUpTaskCard key={task._id?.toString()} task={task} />
                  );
                })}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function NextUpTaskCard({ task }: { task: TaskData }) {
  const updateTaskMutation = useUpdateTask();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="rounded-md border px-6 py-5 shadow">
      <div className="flex items-start justify-between gap-2 pb-3">
        <div className="text-3xl font-medium">{task.title}</div>
        {/* {task.action === "form" && (
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger asChild>
                <DocumentTextIcon
                  className={clsx("h-5 w-5 shrink-0 text-gray-400/80")}
                />
              </TooltipTrigger>
              <TooltipContent side="left" className="p-2 py-1">
                <p>Form task</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
        {task.action === "fileUpload" && (
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger asChild>
                <CloudArrowUpIcon
                  className={clsx("h-5 w-5 shrink-0 text-gray-400/80")}
                />
              </TooltipTrigger>
              <TooltipContent side="left" className="p-2 py-1">
                <p>File upload task</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
        {task.action === "meeting" && (
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger asChild>
                <PhoneIcon
                  className={clsx("h-4 w-4 shrink-0 text-gray-400/80")}
                />
              </TooltipTrigger>
              <TooltipContent side="left" className="p-2 py-1">
                <p>Call task</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
        {!!task.subTaskCount && (
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger asChild>
                <div className="inline-flex items-center space-x-0.5 text-sm text-gray-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M17 16a2.99 2.99 0 0 0-2.816 2H11c-1.654 0-3-1.346-3-3v-3.025A4.954 4.954 0 0 0 11 13h3.184A2.99 2.99 0 0 0 17 15a3 3 0 1 0 0-6a2.99 2.99 0 0 0-2.816 2H11c-1.654 0-3-1.346-3-3v-.184A2.99 2.99 0 0 0 10 5a3 3 0 1 0-6 0a2.99 2.99 0 0 0 2 2.816V15c0 2.757 2.243 5 5 5h3.184A2.99 2.99 0 0 0 17 22a3 3 0 1 0 0-6zm0-5a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zM7 4a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zm10 16a1.001 1.001 0 0 1 0-2a1.001 1.001 0 0 1 0 2z"
                    />
                  </svg>
                  <span>{task.subTaskCount}</span>
                </div>
              </TooltipTrigger>
              <TooltipContent side="left" className="p-2 py-1">
                <p>{`${task.subTaskCount} subtasks`}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )} */}
        <TaskTasker task={task} />
      </div>
      <div>
        <TaskDescription task={task} />
      </div>
      {task.action === "meeting" && (
        <div className="pt-3 text-gray-500">
          <span>Click </span>
          <span
            className="cursor-pointer text-embedPrimary underline"
            onClick={() => {
              if (task.meeting?.link) {
                window.open(task.meeting?.link, "_blank");
              }
            }}
          >
            here
          </span>
          <span> to book the meeting.</span>
        </div>
      )}
      <div className="mt-4 flex max-w-full items-end justify-between space-x-2">
        {/* {<StatusSelect task={task} disabled={false} />} */}
        <div className="flex items-center space-x-2">
          {/* <StatusSelect
            task={task}
            disabled={task.taskerType === "internal"}
            disabledStatuses={[
              ...(task.action === "form" || task.action === "fileUpload"
                ? ["Completed" as TaskData["status"]]
                : []),
              "Canceled",
              "Backlog",
            ]}
          /> */}

          {(task.action === "default" || task.action === "meeting") && (
            // <NewButton
            //   onClick={() => {
            //     if (!task._id) return;
            //     updateTaskMutation.mutate({
            //       update: { _id: task._id, status: "Completed" },
            //       metadata: {
            //         phaseId: task?.phase?._id ? task?.phase?._id : undefined,
            //         journeyId: task?.journey?._id
            //           ? task?.journey?._id
            //           : undefined,
            //         journeyStageId: task?.journeyStage?._id
            //           ? task?.journeyStage?._id
            //           : undefined,
            //       },
            //     });
            //   }}
            //   className="border-green-600 bg-green-400 hover:bg-green-500"
            // >
            //   Mark as Completed
            // </NewButton>
            <StatusSelect task={task} disabled={false} isProjectView={true} />
          )}
          {/* <NewButton
            variant={"secondary"}
            className="border-red-600 text-red-600 hover:bg-red-50"
            onClick={() => {
              setStuckDialogOpen(true);
            }}
          >
            Mark as Stuck
          </NewButton> */}
          {task.taskerType !== "internal" && (
            <>
              {task.action === "fileUpload" && (
                <NewButton
                  onClick={() => {
                    if (task._id) {
                      const searchParams = new URLSearchParams(location.search);
                      searchParams.set("taskId", task._id);
                      navigate({
                        pathname: location.pathname,
                        search: searchParams.toString(),
                      });
                    }
                  }}
                  variant={"secondary"}
                >
                  Upload File
                </NewButton>
              )}
              {task.action === "form" && (
                <NewButton
                  onClick={() => {
                    if (task._id) {
                      const searchParams = new URLSearchParams(location.search);
                      searchParams.set("taskId", task._id);
                      navigate({
                        pathname: location.pathname,
                        search: searchParams.toString(),
                      });
                    }
                  }}
                  variant={"secondary"}
                >
                  Open Form
                </NewButton>
              )}

              <NewButton
                onClick={() => {
                  if (task._id) {
                    const searchParams = new URLSearchParams(location.search);
                    searchParams.set("taskId", task._id);
                    navigate({
                      pathname: location.pathname,
                      search: searchParams.toString(),
                    });
                  }
                }}
                variant={"secondary"}
              >
                <ChatBubbleLeftEllipsisIcon className="mr-2 h-5 w-5" />
                <span>{task.threadId ? "Open Chat" : "Start a Chat"}</span>
              </NewButton>
            </>
          )}
        </div>
        {/* <StuckDialog
          task={task}
          open={stuckDialogOpen}
          setIsOpen={setStuckDialogOpen}
        /> */}
        <TaskDueDate task={task} />
      </div>
    </div>
  );
}

function TaskTasker({ task }: { task: TaskData }) {
  const taskerName =
    task.tasker.userType === "fullUser"
      ? task.tasker.name
      : task.tasker.userType === "invitedUser"
      ? task.tasker.email
      : "Unassigned";
  return (
    <div
      className={clsx(
        "flex h-7 items-center space-x-1.5 whitespace-nowrap rounded-full border px-2.5 text-xs shadow-sm",
        task.tasker.userType === "persona"
          ? "border-red-600 text-red-500"
          : task.taskerType === "internal"
          ? "border-gray-300 text-gray-600"
          : "border-gray-400 bg-gray-100 text-black"
      )}
    >
      <UserIcon className="h-3.5 w-3.5" />
      <span> {`${taskerName}`}</span>
    </div>
  );
}

function TaskDueDate({ task }: { task: TaskData }) {
  if (!task.dueDate) {
    return <div>This task is not active as yet</div>;
  }
  const { absValue, isDate2After, unit } = getTimeDifference(
    new Date(),
    new Date(task.dueDate)
  );
  return (
    <>
      {isDate2After ? (
        <span className="text-sm text-gray-500">
          {`Due in ${absValue} ${unit}`}
        </span>
      ) : (
        <span className="text-sm text-red-500">
          {`${absValue} ${unit} overdue`}
        </span>
      )}
    </>
  );
}

function TaskDescription({ task }: { task: TaskData }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const descriptionRef = useRef<HTMLDivElement>(null);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Typography,
      Link.configure({
        openOnClick: false,
      }),
    ],
    editorProps: {
      attributes: {
        class:
          "prose outline-none ring-0 focus:outline-none focus:ring-0 border-none focus:border-none",
      },
    },
    content: task.description,
    editable: false,
  });

  useLayoutEffect(() => {
    if (descriptionRef.current && descriptionRef.current.scrollHeight > 400) {
      setIsOverflowing(true);
    }
  }, [task.description, editor]);

  return (
    <>
      {!!editor && !editor?.isEmpty && (
        <div
          className={clsx(
            "relative mb-4 origin-top-left rounded-md bg-gray-100 p-3",
            isOverflowing
              ? isExpanded
                ? ""
                : "max-h-[400px] overflow-hidden"
              : ""
          )}
          ref={descriptionRef}
        >
          <EditorContent editor={editor} />
          {isOverflowing && !isExpanded && (
            <div className="absolute inset-x-0 bottom-0 flex w-full items-center justify-center bg-gradient-to-b from-transparent to-white px-3 py-6 text-sm text-gray-500">
              {/* <div className="flex items-center space-x-1 bg-gray-300 border rounded-md px-3 py-1">
                <span>Show more</span>
              </div> */}
              <Button
                variant="secondary"
                onClick={() => {
                  setIsExpanded(!isExpanded);
                }}
              >
                Show more
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
}

function OldOverview({ phase }: { phase: PhaseData }) {
  const { data: tasks } = useGetPhaseTasks(phase._id);
  const { journey } = useGetJourney(new ObjectID(phase.journey._id));

  return (
    <div className="">
      {journey && <Timeline journey={journey} />}
      <div className="grid w-full max-w-7xl grid-rows-2 gap-8 md:grid-cols-3 lg:grid-cols-4">
        <TasksDonut phase={phase} />
        <TasksOverdue tasks={tasks ?? []} />
        <TasksBlocked tasks={tasks ?? []} />
      </div>
    </div>
  );
}

// function Tabs({ journey, phase }: { journey: JourneyData; phase: PhaseData }) {
//   const { data: tasks } = useGetPhaseTasks(phase._id);
//   const { data: threads } = useGetPhaseThreads(String(phase._id));

//   const tabs: TabProps["tabs"] = [
//     // {
//     //   name: "Tasks",
//     //   panel: <Tasks journey={journey} phase={phase} />,
//     // },
//     {
//       name: "Overview",
//       panel: (
//         <div className="flex flex-1 flex-col items-center gap-10 pt-10">
//           <div className="text-center text-4xl font-semibold">
//             {journey.name}
//           </div>
//           <Timeline
//             journey={journey}
//             // setTabToProject={setTabToProject}
//           />
//           {/* <div className="flex space-x-3">
//             <EmbedButton text="Send Message" size="md" />
//             <EmbedButton text="Setup Call" size="md" />
//           </div> */}

//           <div className="grid w-full max-w-7xl grid-rows-2 gap-8 md:grid-cols-3 lg:grid-cols-4">
//             <TasksDonut tasks={tasks ?? []} />
//             <TasksOverdue tasks={tasks ?? []} />
//             <TasksBlocked tasks={tasks ?? []} />
//             {/* <DueDateChanges /> */}

//             {/* <div className="space-y-6 rounded-md border border-gray-300 bg-gray-50 p-4">
//               <div className="flex items-center justify-between">
//                 <div className="text-lg text-gray-600">Due Date Changes</div>
//                 <button className="rounded-md p-1 hover:bg-gray-100">
//                   <ArrowsPointingOutIcon className="h-5 w-5 text-gray-500" />
//                 </button>
//               </div>
//               <div className="space-y-1">
//                 <div className="text-3xl font-bold">0</div>
//                 <div className="text-gray-600 ">No due dates changed</div>
//               </div>
//             </div> */}
//           </div>
//         </div>
//       ),
//       id: "overview",
//     },
//     {
//       name: "Project",
//       panel: (
//         <div className="col-span-8 flex flex-1 flex-col">
//           <JourneyContextProvider isExternal={true}>
//             <JourneyRun journeyId={journey._id.toString()} />
//           </JourneyContextProvider>
//         </div>
//       ),
//       id: "project",
//     },
//     {
//       name: "Chat",
//       panel: <PhaseThreads threads={threads ?? []} />,
//       id: "threads",
//       count: threads?.reduce(
//         (acc, t) => acc + t.otherDetails.unreadMessagesCount,
//         0
//       ),
//     },
//     {
//       name: "Files",
//       panel: (
//         <FilesContextProvider isExternal={true}>
//           <Files phase={phase} />
//         </FilesContextProvider>
//       ),
//       id: "resources",
//     },
//   ];
//   return <BasicTabs inEmbed={true} tabs={tabs} />;
// }

// function Tasks({ journey, phase }: { journey: JourneyData; phase: PhaseData }) {
//   const [activeSection, setActiveSection] = useState<"internal" | "external">(
//     "external"
//   );
//   // const { sharedSpaceId } = useParams();
//   const { sharedSpace } = useGetSharedSpace(new ObjectID(phase.sharedSpaceId));
//   const [externalTasksFilter, setExternalTasksFilter] = useState<"All" | "My">(
//     "All"
//   );
//   const { stages } = useGetJourneyStages(journey._id);
//   const { data: tasks } = useGetPhaseTasks(phase._id);

//   return (
//     <>
//       <div
//         className={clsx(
//           "space-y-4 rounded-md border p-4 shadow-lg",
//           // activeSection === "external"
//           // ?
//           "col-span-4"
//           // : "col-span-1 hover:bg-gray-50 cursor-pointer"
//         )}
//         // onClick={() => {
//         //   if (activeSection !== "external") {
//         //     setActiveSection("external");
//         //   }
//         // }}
//       >
//         <div className="-mt-1 text-lg font-medium">
//           {sharedSpace?.customer.name}
//         </div>
//         <div className="flex items-center space-x-3">
//           <Pill
//             active={externalTasksFilter === "My"}
//             onClick={() => setExternalTasksFilter("My")}
//             text="My Tasks"
//             size="md"
//           />
//           <Pill
//             active={externalTasksFilter === "All"}
//             onClick={() => setExternalTasksFilter("All")}
//             text="All Tasks"
//             size="md"
//           />
//         </div>
//         {externalTasksFilter === "My" && <MyTasks tasks={tasks ?? []} />}
//         {externalTasksFilter === "All" && (
//           <AllTasks taskerType="external" tasks={tasks ?? []} />
//         )}
//       </div>
//       <div
//         className={clsx(
//           "space-y-4 rounded-md border p-4 shadow-lg",
//           // activeSection === "external"
//           //   ? "col-span-1 hover:bg-gray-50 cursor-pointer"
//           //   :
//           "col-span-4"
//         )}
//         // onClick={() => {
//         //   if (activeSection !== "internal") {
//         //     setActiveSection("internal");
//         //   }
//         // }}
//       >
//         <div className="-mt-1 text-lg font-medium">
//           {sharedSpace?.instance.name}
//         </div>
//         <div className="flex">
//           <Pill active={true} onClick={() => {}} text="All Tasks" size="md" />
//         </div>
//         <div className="space-y-2 ">
//           <AllTasks taskerType="internal" tasks={tasks ?? []} />
//         </div>
//       </div>
//     </>
//   );
// }

// function MyTasks({ tasks }: { tasks: TaskData[] }) {
//   const app = useRealmApp();
//   const myTasks = tasks.filter(
//     (task) =>
//       task.taskerType === "external" &&
//       task?.tasker?.userType === "fullUser" &&
//       task.tasker._id === app.currentUser?.id &&
//       !!task.dueDate
//   );
//   return (
//     <div className="space-y-3">
//       {myTasks.map((task) => (task ? <Task task={task} /> : <></>))}
//       {myTasks.length === 0 && (
//         <div className="rounded-md border-2 border-dashed px-3 py-6">
//           <div className="flex items-center justify-center text-gray-400">
//             {"There are no active tasks for you"}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// function AllTasks({
//   tasks,
//   taskerType,
// }: {
//   tasks: TaskData[];
//   taskerType: "internal" | "external";
// }) {
//   const app = useRealmApp();
//   const allTasks = tasks.filter(
//     (task) =>
//       task?.taskerType === taskerType && !!task.dueDate && !task.completionDate
//   );

//   const allTasksVisibleToExternal = allTasks.filter((task) => task?.isVisible);

//   return (
//     <div className="space-y-3">
//       {allTasksVisibleToExternal.map((task) =>
//         task ? <Task task={task} /> : <></>
//       )}
//       {allTasks.length === 0 ? (
//         <>
//           <div className="rounded-md border-2 border-dashed px-3 py-6">
//             <div className="flex items-center justify-center text-gray-400">
//               {"There are no active tasks for the team"}
//             </div>
//           </div>
//         </>
//       ) : (
//         <>
//           {allTasksVisibleToExternal.length === 0 && (
//             <>
//               <div className="text-md inline-flex items-center space-x-2 rounded-md border border-blue-400 bg-blue-50 px-2 py-1 text-blue-600">
//                 <ExclamationCircleIcon className="h-6 w-6" />
//                 <div>
//                   They are working on tasks behind the scenes. You will be
//                   notified with updates soon!
//                 </div>
//               </div>
//             </>
//           )}
//         </>
//       )}
//       {}
//     </div>
//   );
// }

// function Task({ task }: { task: TaskData }) {
//   const taskPanelCtx = useTaskPanelContext();
//   return (
//     <div
//       className="space-y-3 divide-y rounded-md border px-3 py-3 hover:cursor-pointer hover:shadow-md"
//       onClick={() => {
//         // if (task.dueDate) {
//         if (task._id) {
//           taskPanelCtx.dispatch({
//             type: "TASK_PANEL_UPDATE",
//             taskPanel: {
//               id: task._id.toString(),
//               isOpen: true,
//             },
//           });
//         }
//         // if (slug) {
//         //     navigate(`/${slug}/tasks/${task._id.toString()}`);
//         //   } else {
//         //     // console.log("a")
//         //     console.log(taskPanelCtx.state);
//         //     // navigate(`/task/${task._id.toString()}`);
//         //   }
//         // }
//       }}
//     >
//       <div className="space-y-3">
//         <div className="flex justify-between">
//           <div>
//             <div>{task.title}</div>
//             {task.description && (
//               <div className="text-sm text-gray-400">{task.description}</div>
//             )}
//           </div>
//           <div>
//             {task.blocker && !task.blocker?.resolved && (
//               <div className="rounded-full bg-red-500 px-2 py-0.5 text-xs text-white">
//                 Blocked
//               </div>
//             )}
//           </div>
//         </div>
//         <div>
//           <div className="flex items-end space-x-2">
//             {/* <div className="inline-flex h-max items-center">
//             <Indicator task={task} />
//           </div> */}
//             {!!task.dueDate ? (
//               <div
//                 className={clsx(
//                   !task.completionDate && "mt-2",
//                   "flex items-center space-x-2"
//                 )}
//               >
//                 <Status task={task} />
//                 {!task.completionDate && <DueDate task={task} />}
//                 {!task.completionDate && <CompletionStatus task={task} />}
//               </div>
//             ) : (
//               <></>
//             )}
//             {/* {task.issue && (
//               <span className="inline-flex items-center rounded-full border border-red-500 px-2 py-0.5 text-xs text-red-500">
//                 Issue
//               </span>
//             )} */}

//             {/* <div>
//           <Avatar task={task} />
//         </div> */}
//           </div>

//           {/* {!task.completionDate && <DueDate task={task} />}
//         {!task.completionDate && <CompletionStatus task={task} />} */}
//         </div>
//       </div>
//       <div className="flex justify-between pt-3">
//         <div>
//           {!!task?.knowledgeResourceIds &&
//             task.knowledgeResourceIds.length > 0 && (
//               <ToolTip title="Knoweldge resource">
//                 <BookOpenIcon
//                   className={clsx(
//                     "h-5 w-5 shrink-0",
//                     // task.taskerType === "external"
//                     // ? "text-gray-400"
//                     // :
//                     "text-gray-400"
//                   )}
//                 />
//               </ToolTip>
//             )}
//         </div>
//         <div className="flex flex-shrink-0 justify-end">
//           <div
//             className={clsx(
//               "max-w-[150px] truncate rounded-full border px-2 py-0.5 text-xs",
//               // task.taskerType === "external"
//               //   ? "border-black bg-slate-700 text-white"
//               //   :
//               "border-gray-500 bg-white text-gray-500"
//             )}
//           >
//             {task.tasker.userType === "persona"
//               ? "Unassigned"
//               : getIdentifier(task.tasker).identifier}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// function Pill({
//   active,
//   onClick,
//   text,
//   size,
//   count,
//   shadow,
// }: {
//   active: boolean;
//   onClick: () => void;
//   text: string;
//   size: "sm" | "md" | "lg";
//   count?: number;
//   shadow?: boolean;
// }) {
//   return (
//     <div
//       className={clsx(
//         size === "sm"
//           ? "px-2 py-1 text-xs"
//           : size === "md"
//           ? "px-3 py-2 text-sm"
//           : "text-md px-4 py-2",
//         "flex cursor-pointer space-x-2 rounded-md border",
//         shadow ? "shadow-sm" : "",
//         active ? "bg-gray-200" : "hover:bg-gray-50"
//       )}
//       onClick={onClick}
//     >
//       <span>{text}</span>
//       {(count === 0 || count) && (
//         <span
//           className={clsx(
//             "rounded-md px-2  py-0.5 text-xs",
//             active ? "bg-gray-50" : "bg-gray-100"
//           )}
//         >
//           {count}
//         </span>
//       )}
//     </div>
//   );
// }

// function AIUpdateButton({ phaseId }: { phaseId: string }) {
//   const [open, setOpen] = useState(false);
//   return (
//     <>
//       <EmbedButton
//         leftIcon={<SparklesIcon className="mr-2 h-5 w-5" />}
//         text="Quick Update"
//         size="sm"
//         fill="outline"
//         // color="gray"
//         onClick={() => {
//           setOpen(true);
//         }}
//       />
//       <Modal
//         isOpen={open}
//         closeModal={() => {
//           setOpen(false);
//         }}
//         height={"sm"}
//       >
//         <SummaryModalContent phaseId={phaseId} />
//       </Modal>
//     </>
//   );
// }

function SummaryModalContent({ phaseId }: { phaseId: string }) {
  const queryClient = useQueryClient();
  const [summaryType, setSummaryType] = useState<
    "high-level" | "roadblocks" | undefined
  >(undefined);

  function getSummary() {
    switch (summaryType) {
      case undefined:
        return <></>;
      case "high-level":
        return <HighLevelUpdate phaseId={phaseId} />;
      case "roadblocks":
        return <RoadblocksUpdate phaseId={phaseId} />;
      default:
        return <></>;
    }
  }

  const summaryTypes: {
    name: string;
    description: string;
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    onClick: () => void;
  }[] = [
    {
      name: "High Level",
      description: "Get an overview of the onboarding",
      icon: GlobeAltIcon,
      onClick: () => {
        queryClient.invalidateQueries(
          homebaseKeys.summary(phaseId, "high-level")
        );
        setSummaryType("high-level");
      },
    },
    // {
    //   name: "Roadblocks",
    //   description:
    //     "Learn about roadblocks that are slowing down the onboarding",
    //   icon: ExclamationTriangleIcon,
    //   onClick: () => {
    //     queryClient.invalidateQueries(
    //       homebaseKeys.summary(phaseId, "roadblocks")
    //     );
    //     setSummaryType("roadblocks");
    //   },
    // },
  ];

  return (
    <>
      {!summaryType && (
        <div className="space-y-6">
          <div className="text-lg font-medium text-gray-700">
            What update do you want?
          </div>
          <div className="space-y-4">
            {summaryTypes.map((st) => (
              <SummaryCard
                name={st.name}
                description={st.description}
                Icon={st.icon}
                onClick={st.onClick}
              />
            ))}
          </div>
        </div>
      )}
      {!!summaryType && (
        <button
          className="inline-flex items-center space-x-1 pb-4 text-gray-400 hover:text-gray-500"
          onClick={() => {
            setSummaryType(undefined);
          }}
        >
          <ChevronLeftIcon className="h-3 w-3" />
          <span className="text-sm">Back</span>
        </button>
      )}
      {getSummary()}
    </>
  );
}

function SummaryCard({
  name,
  description,
  Icon,
  onClick,
}: {
  name: string;
  description: string;
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  onClick: () => void;
}) {
  return (
    <div
      className="cursor-pointer rounded-md border px-3 py-2 hover:shadow-md"
      onClick={onClick}
    >
      <div className="flex items-center space-x-2">
        <Icon className="h-5 w-5 text-gray-400" />
        <div className="text-base font-semibold">{name}</div>
      </div>
      <div className="text-sm text-gray-400">{description}</div>
    </div>
  );
}

function HighLevelUpdate({ phaseId }: { phaseId: string }) {
  const prompt = `Give a high level update for the given customer onboarding phase. A phase contains a journey, and a journey contains multiple stages and each stage contains multiple tasks. You are being given details about the phase, key dates for the journey, overview of the stages, as well as all the tasks in the journey. You can loosely follow this template:-
    
    This project was started on [phase creation date] and is [journeyCompletionPercent]% complete. (if completed) It was completed on [completionDate in normal text], [keyDates.difference] (ahead or behind) schedule. (if not completed) It is forecasted to be completed on [forecastedCompletionDate in normal text], [keyDates.difference] (ahead or behind) schedule.
    
    The crucial tasks to ensure timely completion are -
    [list of only the main tasks (don't include completed tasks), who is in charge of them and why they impact timely completion of the whole phase, if any]
    
    (If any tasks are overdue that could cause untimely completion) These tasks are potential blockers to ensure timely completion:-
    [list of tasks, who is in charge of them and why they impact timely completion of the whole phase, if any]
    
    [Use this section to summarise the timeline of the phase in not more than 2 sentences]
    
    *end of template*
    
    The phase data is as follows:-`;
  const { data: res, isLoading } = useGetPhaseSummary(
    String(phaseId),
    prompt,
    "high-level"
  );
  const [answer, setAnswer] = useState("");
  const [answerDone, setAnswerDone] = useState(false);
  // console.log(phaseId);

  const getAnswer = async (res: Response) => {
    const reader = res.body!.getReader();
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        setAnswerDone(true);
        break;
      }
      setAnswer((prev) => prev + new TextDecoder().decode(value));
    }
  };

  useEffect(() => {
    // console.log(res);
    if (res) {
      if (res.status === 500) {
        setAnswer("There was an error. Please try again later.");
        return;
      } else {
        getAnswer(res);
      }
    }
  }, [res]);
  return (
    <div className="space-y-2">
      <div className="text-md font-medium text-gray-700">High Level Update</div>
      {answer.length === 0 && (
        <span className="text-sm text-gray-400">Loading...</span>
      )}
      <div className="h-full overflow-auto">
        <ReactMarkdown className="prose text-sm" linkTarget="_blank">
          {`${answer}${answerDone ? "" : ""}`}
        </ReactMarkdown>
      </div>
    </div>
  );
}

function RoadblocksUpdate({ phaseId }: { phaseId: string }) {
  const prompt = `Give an update on roadblocks for the given customer onboarding phase. If there are no roadblocks for timely completion, you can say so. No need to provide additional information. For your information - A phase contains a journey, and a journey contains multiple stages and each stage contains multiple tasks. You are being given details about the phase, key dates for the journey, overview of the stages, as well as all the tasks in the journey. Strictly follow this template:-
    
    <If any tasks are overdue or have been marked as an issue, that could cause untimely completion> These tasks are potential blockers to ensure timely completion -
    [list of tasks, who is in charge of them and why they impact timely completion of the whole phase, if any]
    
    <If there aren't any tasks that are overdue or have been marked as an issue> There are no tasks that are potential blockers to ensure timely completion.
    
    *end of template*
    
    The phase data is as follows:-`;
  const { data: res, isLoading } = useGetPhaseSummary(
    String(phaseId),
    prompt,
    "roadblocks"
  );
  const [answer, setAnswer] = useState("");
  const [answerDone, setAnswerDone] = useState(false);
  // console.log(phaseId);

  const getAnswer = async (res: Response) => {
    const reader = res.body!.getReader();
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        setAnswerDone(true);
        break;
      }
      setAnswer((prev) => prev + new TextDecoder().decode(value));
    }
  };

  useEffect(() => {
    // console.log(res);
    if (res) {
      if (res.status === 500) {
        setAnswer("There was an error. Please try again later.");
        return;
      } else {
        getAnswer(res);
      }
    }
  }, [res]);
  return (
    <div className="space-y-2">
      <div className="text-md font-medium text-gray-700">
        Current Roadblocks
      </div>
      {answer.length === 0 && (
        <span className="text-sm text-gray-400">Loading...</span>
      )}
      <div className="h-full overflow-auto">
        <ReactMarkdown className="prose text-sm" linkTarget="_blank">
          {`${answer}${answerDone ? "" : ""}`}
        </ReactMarkdown>
      </div>
    </div>
  );
}

const Thread = ({
  thread,
  onClick,
}: {
  thread: ThreadData;
  onClick: (threadId: string) => void;
}) => {
  const days = dayjs(new Date()).diff(thread.createdAt, "day");
  let activeTime = "";
  if (days === 0) {
    activeTime = "Started today";
  } else {
    activeTime = `Active since ${days} day${days === 1 ? "" : "s"}`;
  }
  return (
    <div
      className="cursor-pointer space-y-6 rounded-md border px-4 py-3 hover:bg-gray-50"
      onClick={() => {
        onClick(thread._id.toString());
      }}
    >
      <div className="flex justify-between">
        <div className={clsx("truncate text-gray-700")}>{thread.name}</div>
        {/* <div
          className={clsx(
            "text-sm font-medium",
            slug ? "text-embedPrimary" : "text-primary-600"
          )}
        >
          {journey.completionPercent.toFixed(0) + "%"}
        </div> */}
      </div>
      <div className="flex justify-between">
        <div className="flex flex-wrap gap-2">
          {thread.otherDetails?.users?.map((u) => (
            <span className="shrink-0 rounded-full border border-gray-300 bg-gray-100 py-0.5 px-2 text-sm">
              {u.name}
            </span>
          ))}
        </div>
        <div className="text-xs text-gray-600">{activeTime}</div>
      </div>
    </div>
  );
};

// function Contact({
//   phase,
//   setTabToThreads,
// }: {
//   phase: PhaseData;
//   setTabToThreads: () => void;
// }) {
//   // console.log(phase)
//   const slug
//   const [isOpen, setIsOpen] = useState(false);
//   // console.log(timeline);
//   const [isNewThreadModalOpen, setIsNewThreadModalOpen] = useState(false);
//   // console.log(phase);
//   function Selection({
//     text,
//     Icon,
//     onClick,
//     disabled = false,
//   }: {
//     text: string;
//     Icon: React.ComponentType<React.ComponentProps<"svg">>;
//     onClick: () => void;
//     disabled?: boolean;
//   }) {
//     return (
//       <div
//         className={clsx(
//           "group h-32 w-32 cursor-pointer rounded-md border",
//           !disabled && "hover:border-gray-400",
//           !disabled && "hover:bg-gray-50",
//           disabled && "bg-gray-100"
//         )}
//         onClick={onClick}
//       >
//         <div
//           className={clsx(
//             "flex h-full w-full flex-col items-center justify-center space-y-5 text-gray-500",
//             !disabled && "group-hover:text-gray-700"
//           )}
//         >
//           <Icon
//             className={clsx("h-7 w-7 text-gray-400 group-hover:text-gray-600")}
//           />
//           <div className="flex flex-col items-center">
//             <span
//               className={clsx(
//                 "text-xs text-gray-400 ",
//                 !disabled && "group-hover:text-gray-600"
//               )}
//             >
//               {text}
//             </span>
//             {disabled && <Badge text="Unavailable" />}
//           </div>
//         </div>
//       </div>
//     );
//   }
//   return (
//     <>
//       {!!phase ? (
//         <>
//           <EmbedButton
//             text={`Message ${getFirstName(phase.contactUser.name ?? "")}`}
//             size="sm"
//             onClick={() => setIsOpen(true)}
//             fill={"outline"}
//             // color="embed"
//           />
//           {/* {slug ? (

//           ) : (
//             <Button
//               text={`Contact ${getFirstName(
//                 timeline?.contactUser?.name ?? ""
//               )}`}
//               size="xs"
//               onClick={() => setIsOpen(true)}
//               // color="embed"
//             />
//           )} */}
//           <Modal isOpen={isOpen} closeModal={() => setIsOpen(false)}>
//             <div className="space-y-4">
//               <ModalHeading
//                 title={`Message ${getFirstName(
//                   phase?.contactUser?.name ?? ""
//                 )}`}
//                 subtitle={`Get in touch with your point of contact`}
//               />
//               <div className="flex justify-center space-x-4 py-4">
//                 <Selection
//                   text="Start a thread"
//                   Icon={ChatBubbleBottomCenterTextIcon}
//                   onClick={() => {
//                     setIsOpen(false);
//                     setIsNewThreadModalOpen(true);
//                   }}
//                 />
//                 {/* <Selection
//                   text="Schedule a call"
//                   Icon={PhoneArrowUpRightIcon}
//                   onClick={() => {
//                     if (timeline?.contactUser?.calendlyUrl)
//                       window.open(timeline.contactUser.calendlyUrl);
//                   }}
//                   disabled={!timeline?.contactUser?.calendlyUrl}
//                 /> */}
//               </div>
//             </div>
//           </Modal>
//           <NewThreadContextProvider
//             isInternal={!slug}
//             isSharedSpace={true}
//             isEmbed={!!slug}
//           >
//             <NewThreadModal
//               isOpen={isNewThreadModalOpen}
//               closeModal={() => {
//                 setIsNewThreadModalOpen(false);
//                 setTabToThreads();
//               }}
//             />
//           </NewThreadContextProvider>
//         </>
//       ) : (
//         <>
//           <Button
//             text={`Contact`}
//             size="xs"
//             onClick={() => setIsOpen(true)}
//             disabled={true}
//             // color="embed"
//           />
//         </>
//       )}
//     </>
//   );
// }

function getFirstName(name: string) {
  return name.split(" ")[0];
}

const TimelineProgress = ({ journey }: { journey: JourneyData }) => {
  // const { sharedSpaceId } = useParams();
  const { data: phases } = useGetPhases(
    String(journey.sharedSpaceId?.toString())
  );
  // console.log(timeline);
  const activePhase = phases?.find((phase) => phase.isActive);
  return (
    <>
      {!!activePhase && <TimelinePercentage activePhase={activePhase} />}
      {/* <div className="space-y-4">

        <div>
          <div className="text-sm text-gray-400">Progress</div>
          <div className={"text-6xl text-embedPrimary"}>
            {!!timeline ? (
              <>{timeline.finalCompletion}%</>
            ) : (
              <div>
                
                <div className="text-gray-400">0%</div>
              </div>
            )}
          </div>
        </div>
      </div> */}
    </>
  );
};

function TimelinePercentage({
  activePhase,
}: {
  activePhase: MinimalPhaseData;
}) {
  const { timeline } = useGetSharedSpaceTimeline(
    new ObjectID(activePhase.timelineId)
  );
  return (
    <div>
      <div className="text-sm text-gray-400">Progress</div>
      <div className={"text-6xl text-embedPrimary"}>
        {!!timeline ? (
          <>{timeline.finalCompletion.toFixed(0)}%</>
        ) : (
          <div>
            {/* <div className="text-sm text-gray-400">Progress</div>
              <div
                className={"text-5xl text-embedPrimary"}
              >
                {"75%"}
              </div> */}
            <div className="text-gray-400">0%</div>
          </div>
        )}
      </div>
    </div>
  );
}

function Logo() {
  const slug = getSubdomain();
  const isInternal = isRestrictedSubdomain(slug);
  return (
    <>
      {!isInternal ? (
        <> {!!slug && <LogoFromSlug slug={slug} />}</>
      ) : (
        <LogoFromInstance />
      )}
    </>
  );
}

function LogoFromSlug({ slug }: { slug: string }) {
  const { data: theme } = useGetThemeSettings(slug);
  return (
    <>
      {/* <div> */}
      <img src={theme?.logo.url} className="h-[18px]" />
      {/* </div> */}
    </>
  );
}

function LogoFromInstance() {
  const { logo } = useGetLogo();
  return (
    <>
      {logo && (
        // <div>
        <img src={logo?.url} className="h-[18px]" />
        // </div>
      )}
    </>
  );
}

const Timeline = ({
  journey,
  setTabToProject,
}: {
  journey: JourneyData;
  setTabToProject?: () => void;
}) => {
  const { stages } = useGetJourneyStages(new ObjectID(journey?._id));
  return (
    <>
      {!!stages ? (
        <>
          {/* <div className="flex gap-4 max-w-full ">
            {[1,2,3,4,5,6,7].map(i => <div key={i} className="h-40 w-40 bg-gray-500 shrink-0"></div>)}
          </div> */}
          <div className="flex max-w-full shrink-0 items-start space-x-3 overflow-x-auto py-3">
            <div className="flex h-24 w-max items-start space-x-3 overflow-x-auto pr-10">
              {stages
                .filter((stage) => !!stage.tasks && stage.tasks?.length > 0)
                .filter(
                  (stage) =>
                    !!stage.tasks &&
                    stage.tasks.filter(
                      (t) => t.taskerType === "external" || t.isVisible
                    ).length > 0
                )
                .map((s, i) => (
                  <Stage
                    key={i}
                    stage={s}
                    isFirst={i === 0}
                    isLast={
                      i ===
                      stages.filter(
                        (stage) => !!stage.tasks && stage.tasks?.length > 0
                      ).length
                    }
                  />
                ))}
            </div>
            <div className="-mt-2 shrink-0 text-3xl font-medium text-embedPrimary">
              {journey.completionPercent.toFixed(0) + "%"}
            </div>
            {/* <div className="text-2xl font-medium">{"Onboarding"}</div> */}
            {/* <div className="text-2xl font-medium">{journey.name}</div> */}
            {/* <button
            className="group flex cursor-pointer space-x-3 rounded-md border border-gray-400 bg-gray-100 px-3 py-2 text-sm hover:border-gray-700 hover:bg-gray-200"
            onClick={setTabToProject}
          >
            <RocketLaunchIcon className="h-5 w-5 text-gray-500 group-hover:text-gray-700" />
            <span className="text-gray-500 group-hover:text-gray-700">
              View Project in Detail
            </span>
          </button> */}
          </div>
        </>
      ) : (
        <div className="my-12 inline-flex">
          <div className="text-gray-400">Loading Timeline...</div>
        </div>
      )}
    </>
  );
};

export const Stage = ({
  stage,
  isFirst,
  isLast,
}: {
  stage: JourneyStageObj;
  isFirst: boolean;
  isLast: boolean;
}) => {
  const progressPercentage = !!stage.tasks
    ? stage.tasks?.filter((t) => t.completionDate).length / stage.tasks?.length
    : 0;

  const hasActiveTasks = !!stage.tasks
    ? stage.tasks?.filter((t) => !!t.dueDate).length > 0
    : false;

  return (
    <div className="flex items-center space-x-3">
      {isFirst && (
        <div
          className={clsx(
            "relative ml-6 inline-flex h-4 w-4 items-center justify-center"
          )}
        >
          <div className="flex flex-col items-center">
            <div className="text-xl">{"🚀"}</div>
            <div className="absolute mt-7 text-sm text-gray-600">{"Start"}</div>
            <div className="absolute mt-12 whitespace-nowrap text-xs text-gray-400">
              {dayjs(stage.createdAt).format("MMM DD")}
            </div>
          </div>
        </div>
      )}
      <div
        className={clsx(
          "h-1.5 w-32 rounded-sm",
          hasActiveTasks ? "bg-green-200/70" : "bg-gray-100"
          //  ? "bg-embedPrimary" : "bg-gray-200"
        )}
      >
        <div
          className={clsx(
            "h-1.5 rounded-sm",
            progressPercentage === 1 ? "bg-green-500" : "bg-green-500"
          )}
          style={{
            width: `${progressPercentage * 100}%`,
          }}
        />
      </div>
      <div
        className={clsx(
          "h-4 w-4 rounded-full",
          hasActiveTasks
            ? progressPercentage === 1
              ? "bg-green-500"
              : "bg-green-200/70"
            : "bg-gray-200"
        )}
      >
        <div className="pl-3">
          <div className="-ml-16 mt-6 w-32 text-center">
            <div className="font- truncate whitespace-nowrap text-sm text-gray-600">
              {stage.name}
            </div>
            <div
              className={clsx(
                "space-y-1 text-xs font-light",
                hasActiveTasks
                  ? progressPercentage === 1
                    ? "text-green-500"
                    : "text-green-500"
                  : "text-gray-400"
              )}
            >
              {hasActiveTasks ? (
                <div>{(progressPercentage * 100).toFixed(0) + "%"}</div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="absolute text-center">{stage.name}</div> */}
    </div>
  );
};
