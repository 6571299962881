import {
  ArrowLeftOnRectangleIcon,
  BoltIcon,
  BuildingOffice2Icon,
  Cog6ToothIcon,
  EllipsisHorizontalIcon,
  RectangleStackIcon,
  RocketLaunchIcon,
} from "@heroicons/react/24/outline";
import { RadiobuttonIcon, TriangleRightIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetInstance } from "src/api/General/instance";
import { useGetPhase } from "src/api/Services/SharedSpace/phases";
import { useGetSharedSpacesForUser } from "src/api/Services/SharedSpace/spaces";
import { SpaceData, useGetSpaces } from "src/api/Spaces/spaces";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import { useRealmApp } from "src/store/RealmApp";
import { getFirstAndLastInitials } from "src/utils/functions/avatar";
import { classNames } from "src/utils/functions/classes";
import { Button } from "../ui/button";
import { Notifications, WorkspaceSelector } from "./TailwindSidebar";

type Props = {};
type Workspace = { type: "instance" | "shared-space"; id: string };

export default function Sidebar({}: Props) {
  const location = useLocation();
  const { data: spaces } = useGetSpaces();
  const { slug } = useParams();
  const { sharedSpaces } = useGetSharedSpacesForUser();
  const app = useRealmApp();
  const [spacesOpen, setSpacesOpen] = useState(
    !!localStorage.getItem("spacesOpen")
      ? localStorage.getItem("spacesOpen") === "true"
      : true
  );
  useEffect(() => {
    localStorage.setItem("spacesOpen", String(spacesOpen));
  }, [spacesOpen]);

  const [workspaceSectionOpen, setWorkspaceSectionOpen] = useState(
    !!localStorage.getItem("workspaceSectionOpen")
      ? localStorage.getItem("workspaceSectionOpen") === "true"
      : true
  );
  useEffect(() => {
    localStorage.setItem("workspaceSectionOpen", String(workspaceSectionOpen));
  }, [workspaceSectionOpen]);

  const { instance } = useGetInstance();
  const [workspace, setWorkspace] = useState<Workspace>({
    type: "instance",
    id: instance?._id.toString() ?? "",
  });
  const [profileOpen, setProfileOpen] = useState(false);

  useEffect(() => {
    if (instance) {
      setWorkspace({
        type: "instance",
        id: instance._id.toString(),
      });
    }
  }, [instance]);

  useEffect(() => {
    if (!slug) {
      setWorkspace({ type: "instance", id: instance?._id.toString() ?? "" });
    } else {
      const space = sharedSpaces
        ?.filter((s) => s.type === "external")
        .find((s) => s.instance.slug === slug);
      if (space) {
        setWorkspace({ type: "shared-space", id: space._id.toString() });
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (slug && sharedSpaces) {
      const space = sharedSpaces
        .filter((s) => s.type === "external")
        .find((s) => s.instance.slug === slug);
      if (space) {
        setWorkspace({ type: "shared-space", id: space._id.toString() });
      }
    }
  }, [slug, sharedSpaces?.length]);

  return (
    <div className="flex h-screen w-56 shrink-0 flex-col border-r border-gray-200/70">
      {((!!slug && !!sharedSpaces) || !slug) && (
        <>
          {!!instance ? (
            <WorkspaceSelector
              instance={{
                name: instance.name,
                _id: instance._id.toString(),
              }}
              workspace={workspace}
              setWorkspace={setWorkspace}
            />
          ) : (
            <div className="flex h-12 items-center border-b border-b-gray-200/70 px-3">
              <div className="h-7 w-full animate-pulse rounded-md bg-gray-200" />
            </div>
          )}
          {workspace.type === "instance" ? (
            <div className="relative flex-1 overflow-auto">
              <div className="pointer-events-none absolute inset-x-0 bottom-0  z-20 h-14 bg-gradient-to-b from-transparent via-white/80 to-white" />
              <div className="h-full overflow-auto px-3 pt-3.5 pb-14">
                <Notifications workspace={workspace} />
                {/* <Tab
                Icon={
                  <InboxIcon
                    className={classNames(
                      location.pathname.startsWith("/inbox")
                        ? "text-gray-800"
                        : "text-gray-700 group-hover:text-gray-800",
                      "mr-2.5 h-4 w-4"
                    )}
                    aria-hidden="true"
                  />
                }
                tab={{
                  name: "Inbox",
                  route: "/inbox",
                }}
              /> */}
                <Tab
                  Icon={
                    <RadiobuttonIcon
                      className={classNames(
                        location.pathname.startsWith("/my-tasks")
                          ? "text-gray-800"
                          : "text-gray-700 group-hover:text-gray-800",
                        "mr-2.5 h-4 w-4"
                      )}
                      aria-hidden="true"
                    />
                  }
                  tab={{
                    name: "My Tasks",
                    route: "/my-tasks",
                  }}
                />
                {/* <Tab
                Icon={
                  <ChatBubbleLeftEllipsisIcon
                    className={classNames(
                      location.pathname.startsWith("/chats")
                        ? "text-gray-800"
                        : "text-gray-700 group-hover:text-gray-800",
                      "mr-2.5 h-4 w-4"
                    )}
                    aria-hidden="true"
                  />
                }
                tab={{
                  name: "Chats",
                  route: "/chats",
                }}
              /> */}
                <div className="pt-5">
                  <div
                    className="group flex h-7 cursor-pointer items-center space-x-1.5 rounded-md px-2 font-light text-gray-500 transition-colors hover:bg-gray-100 hover:text-gray-700"
                    onClick={() => {
                      setWorkspaceSectionOpen((prev) => !prev);
                    }}
                  >
                    <div className="select-none text-xs">Workspace</div>
                    <TriangleRightIcon
                      className={clsx(
                        "h-3.5 w-3.5 text-gray-300 transition-all group-hover:text-gray-400/80",
                        workspaceSectionOpen && "rotate-90 transition-transform"
                      )}
                    />
                  </div>
                  {workspaceSectionOpen && (
                    <div className="pt-0.5">
                      {/* <Tab
                  Icon={
                    <TargetIcon
                      className={classNames(
                        location.pathname.startsWith("/goals")
                          ? "text-gray-800"
                          : "text-gray-700 group-hover:text-gray-800",
                        "mr-2.5 h-4 w-4"
                      )}
                      aria-hidden="true"
                    />
                  }
                  tab={{
                    name: "Goals",
                    route: "/goals",
                  }}
                /> */}
                      {/* <Tab
                  Icon={
                    <LayersIcon
                      className={classNames(
                        location.pathname.startsWith("/views")
                          ? "text-gray-800"
                          : "text-gray-700 group-hover:text-gray-800",
                        "mr-2.5 h-4 w-4"
                      )}
                      aria-hidden="true"
                    />
                  }
                  tab={{
                    name: "Views",
                    route: "/views",
                  }}
                /> */}
                      <Tab
                        Icon={
                          <BuildingOffice2Icon
                            className={classNames(
                              location.pathname.startsWith("/companies")
                                ? "text-gray-800"
                                : "text-gray-700 group-hover:text-gray-800",
                              "mr-2.5 h-4 w-4"
                            )}
                            aria-hidden="true"
                          />
                        }
                        tab={{
                          name: "Companies",
                          route: "/companies",
                        }}
                      />
                      <Tab
                        Icon={
                          <div className="mr-2.5 flex w-4 justify-center">
                            <SpaceSymbol color="gray" />
                          </div>
                        }
                        tab={{
                          name: "Spaces",
                          route: "/spaces",
                          strictEquals: true,
                        }}
                      />
                    </div>
                  )}
                </div>
                <div
                  className={clsx("", workspaceSectionOpen ? "pt-5" : "pt-3")}
                >
                  <div
                    className="group flex h-7 cursor-pointer items-center space-x-1.5 rounded-md px-2 font-light text-gray-500 transition-colors hover:bg-gray-100 hover:text-gray-700"
                    onClick={() => {
                      setSpacesOpen((prev) => !prev);
                    }}
                  >
                    <div className="select-none text-xs">My Spaces</div>
                    <TriangleRightIcon
                      className={clsx(
                        "h-3.5 w-3.5 text-gray-300 transition-all group-hover:text-gray-400/80",
                        spacesOpen && "rotate-90 transition-transform"
                      )}
                    />
                  </div>
                  {spacesOpen && (
                    <div className="pt-0.5">
                      {spaces?.map((space) => (
                        <Space space={space} key={space.slug} />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="relative flex-1 overflow-auto">
              {/* <div className="pointer-events-none absolute inset-x-0 bottom-0  z-20 h-14 bg-gradient-to-b from-transparent via-white/80 to-white" /> */}
              <div className="h-full overflow-auto px-3 pt-3.5 pb-14">
                <Notifications workspace={workspace} />
                <Tab
                  Icon={
                    <RocketLaunchIcon
                      className={classNames(
                        location.pathname.includes("projects")
                          ? "text-gray-800"
                          : "text-gray-700 group-hover:text-gray-800",
                        "mr-2.5 h-4 w-4"
                      )}
                      aria-hidden="true"
                    />
                  }
                  tab={{
                    name: "Projects",
                    route: `/${slug}/projects`,
                    strictEquals: false,
                  }}
                />
              </div>
            </div>
          )}
          <div className="flex h-12 items-center justify-between border-t border-gray-200/70 px-3">
            {!slug ? (
              <Link to={"/settings"}>
                <Button
                  variant={"ghost"}
                  size={"icon"}
                  className="h-7 w-7 text-gray-500"
                >
                  <Cog6ToothIcon className="h-4 w-4" />
                </Button>
              </Link>
            ) : (
              <div></div>
            )}

            <DropdownMenu
              open={profileOpen}
              onOpenChange={(isOpen) => {
                setProfileOpen(isOpen);
              }}
            >
              <DropdownMenuTrigger className="outline-none ring-0 focus:outline-none">
                {/* <Button
              variant={"ghost"}
              size={"icon"}
              className="h-7 w-7 text-gray-400"
              onClick={() => {
                setProfileOpen((prev) => !prev);
              }}
            >
              <Cog6ToothIcon className="h-4 w-4" />
            </Button> */}
                <div className="flex h-7 w-7 items-center justify-center rounded-full border text-[11px] font-light select-none text-gray-500 shadow-sm outline-none hover:bg-gray-50">
                  {getFirstAndLastInitials(app.currentUser?.customData?.name)}
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuGroup>
                  <DropdownMenuItem
                    className="py-1"
                    onClick={() => {
                      app.logOut();
                    }}
                  >
                    <ArrowLeftOnRectangleIcon className="mr-2 h-3.5 w-3.5" />
                    <span className="text-sm">Logout</span>
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </>
      )}
    </div>
  );
}

function Space({ space }: { space: SpaceData }) {
  const [spaceOpen, setSpaceOpen] = useState(
    localStorage.getItem(`space_${space.slug}_open`) === "true"
  );
  const [optionsOpen, setOptionsOpen] = useState(false);
  useEffect(() => {
    localStorage.setItem(`space_${space.slug}_open`, String(spaceOpen));
  }, [spaceOpen]);

  const { phaseId } = useParams();

  const location = useLocation();

  const [projectIsInSpace, setProjectIsInSpace] = useState(false);

  useEffect(() => {
    if (!phaseId) {
      setProjectIsInSpace(false);
    }
  }, [phaseId]);
  const navigate = useNavigate();

  return (
    <div>
      {phaseId && (
        <ProjectIsInSpace
          projectId={phaseId}
          spaceId={space._id}
          setProjectIsInSpace={setProjectIsInSpace}
        />
      )}
      <div className="relative">
        <div className="group cursor-pointer select-none pb-0.5">
          <div
            className={clsx(
              "group flex h-7 items-center justify-between space-x-2.5 rounded-md px-2 pr-1 text-gray-600 group-hover:bg-gray-100 group-hover:text-gray-700",
              optionsOpen && "bg-gray-100 text-gray-700"
            )}
            onClick={() => {
              setSpaceOpen((prev) => !prev);
            }}
          >
            <div className="flex w-4 justify-center">
              <SpaceSymbol color={space.color} />
            </div>
            <div className="flex min-w-0 flex-1 items-center space-x-1.5">
              <div className="truncate whitespace-nowrap text-sm text-gray-700">
                {space.name}
              </div>
              <TriangleRightIcon
                className={clsx(
                  "h-3.5 w-3.5 shrink-0 text-gray-300 transition-all group-hover:text-gray-400/80",
                  spaceOpen && "rotate-90 transition-transform"
                )}
              />
            </div>
            <div className="flex items-center justify-between space-x-1">
              <DropdownMenu
                open={optionsOpen}
                onOpenChange={(isOpen) => {
                  setOptionsOpen(isOpen);
                }}
              >
                <DropdownMenuTrigger asChild>
                  <Button
                    variant={"ghost"}
                    size={"icon"}
                    className={clsx(
                      "h-5 w-5 rounded opacity-0 hover:bg-gray-200 group-hover:opacity-100",
                      optionsOpen && "bg-gray-200 opacity-100"
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <EllipsisHorizontalIcon className="h-3.5 w-3.5" />
                  </Button>
                  {/* <Button
                    variant={"ghost"}
                    className={clsx(
                      "h-7 w-7 rounded p-0 hover:bg-gray-200/60 group-hover:visible",
                      !open ? "invisible" : "bg-gray-200/60"
                    )}
                  >
                    <EllipsisVerticalIcon className="h-3.5 w-3.5" />
                  </Button> */}
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-44" align="start">
                  <DropdownMenuGroup>
                    <DropdownMenuItem
                      // className="py-1 focus:bg-gray-50 focus:text-red-600"
                      onClick={(e) => {
                        navigate(`/settings/spaces/${space._id}`);
                      }}
                      className="py-1"
                    >
                      <Cog6ToothIcon className="h-3.5 w-3.5 shrink-0" />
                      <span className="ml-2 text-sm">Settings</span>
                    </DropdownMenuItem>

                    {/* <DropdownMenuItem>Delete</DropdownMenuItem> */}
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </div>
        {spaceOpen && (
          <div className="flex h-full space-x-2 pl-[26px] pb-2">
            {/* <div className="w-[1.5px] shrink-0 rounded-md bg-gray-300" /> */}
            <div className="flex-1 space-y-0.5">
              <Link
                className={clsx(
                  "flex h-7 cursor-pointer items-center space-x-2.5 rounded-md px-2 hover:bg-gray-100 hover:text-gray-700",
                  (location.pathname.startsWith(
                    `/spaces/${space._id}/projects`
                  ) ||
                    projectIsInSpace) &&
                    "bg-gray-100 text-gray-700"
                )}
                to={`/spaces/${space._id}/projects`}
              >
                {/* <div className="h-1.5 w-1.5 rounded-full bg-gray-400"
                  style={{ backgroundColor: space.color}}
                /> */}
                <RocketLaunchIcon className="h-4 w-4" />
                <div className="text-sm text-gray-700">Projects</div>
              </Link>
              <Link
                to={`/spaces/${space._id}/tasks`}
                className={clsx(
                  "flex h-7 cursor-pointer items-center space-x-2.5 rounded-md px-2  hover:bg-gray-100",
                  location.pathname.startsWith(`/spaces/${space._id}/tasks`)
                    ? "bg-gray-100 text-gray-800"
                    : "hover:text-gray-700"
                  // isProjects ? "text-gray-400" : "text-gray-500"
                )}
              >
                <BoltIcon className="h-4 w-4" />
                {/* <div className="h-1.5 w-1.5 rounded-full bg-gray-400"
                  style={{ backgroundColor: space.color}}
                /> */}
                <div className="text-sm text-gray-700">Tasks</div>
              </Link>
              {/* <div
                className={clsx(
                  "flex h-7 cursor-pointer items-center space-x-2.5 rounded-md px-2 hover:bg-gray-100 hover:text-gray-700"
                  // isProjects ? "text-gray-400" : "text-gray-500"
                )}
                onClick={() => {
                  // setIsProjects(!isProjects);
                }}
              >
                <ChartBarIcon className="h-4 w-4" />
                <div className="text-sm text-gray-700">Analytics</div>
              </div> */}
              <Link
                className={clsx(
                  "flex h-7 cursor-pointer items-center space-x-2.5 rounded-md px-2 hover:bg-gray-100",
                  location.pathname.startsWith(`/spaces/${space._id}/templates`)
                    ? "bg-gray-100 text-gray-800"
                    : "hover:text-gray-700"
                  // isProjects ? "text-gray-400" : "text-gray-500"
                )}
                to={`/spaces/${space._id}/templates`}
              >
                <RectangleStackIcon className="h-4 w-4" />
                {/* <div className="h-1.5 w-1.5 rounded-full bg-gray-400"
                  style={{ backgroundColor: space.color}}
                /> */}
                <div className="text-sm text-gray-700">Templates</div>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function ProjectIsInSpace({
  projectId,
  spaceId,
  setProjectIsInSpace,
}: {
  projectId: string;
  spaceId: string;
  setProjectIsInSpace: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { data: phase } = useGetPhase(projectId);
  useEffect(() => {
    if (phase && phase.spaceId === spaceId) {
      setProjectIsInSpace(true);
    } else {
      setProjectIsInSpace(false);
    }
  }, [phase]);
  return <></>;
}

function Tab({
  Icon,
  tab,
}: {
  Icon: React.ReactNode;
  tab: {
    name: string;
    route: string;
    strictEquals?: boolean;
  };
}) {
  const location = useLocation();
  return (
    <Link to={tab.route}>
      <div className="group select-none pb-0.5">
        <div
          className={classNames(
            (
              tab.strictEquals
                ? location.pathname === tab.route
                : location.pathname.startsWith(tab.route)
            )
              ? "bg-gray-100 text-gray-800"
              : "text-gray-700 group-hover:bg-gray-100 group-hover:text-gray-800",
            "group flex h-[28px] items-center rounded-md px-2 text-sm"
          )}
        >
          {Icon}
          <div className="">{tab.name}</div>
        </div>
      </div>
    </Link>
  );
}

function Subtab({
  Icon,
  tab,
}: {
  Icon: React.ReactNode;
  tab: {
    name: string;
    route: string;
  };
}) {
  const location = useLocation();
  return (
    <Link to={tab.route}>
      <div className="group select-none pb-0.5">
        <div
          className={classNames(
            location.pathname.startsWith(tab.route)
              ? "bg-gray-100 text-gray-800"
              : "text-gray-700 group-hover:bg-gray-100 group-hover:text-gray-800",
            "group flex h-[28px] items-center rounded-md px-2 text-sm"
          )}
        >
          {Icon}
          <div className="">{tab.name}</div>
        </div>
      </div>
    </Link>
  );
}

export function SpaceSymbol({ color }: { color: string }) {
  return (
    <div
      className={`flex h-[13px] w-[13px] items-center justify-center rounded border-[1.5px]`}
      style={{ borderColor: color, opacity: 0.9 }}
    >
      <div
        className={`flex h-[7px] w-[7px] items-center justify-center rounded-sm`}
        style={{ backgroundColor: color, opacity: 0.7 }}
      />
    </div>
  );
}
