import { TaskData } from "src/api/Services/Tasks/tasks";

export function getIdentifier(tasker: TaskData["tasker"]): {
  type: TaskData["tasker"]["userType"];
  identifier: string;
} {
  return {
    type: tasker.userType,
    identifier:
      tasker.userType === "fullUser"
        ? tasker.name
        : tasker.userType === "invitedUser"
        ? tasker.email
        : tasker.persona,
  };
}

export const sortTasks = (tasks: TaskData[]): TaskData[] => {
  let tempTasks = [...tasks].sort(function (a, b) {
    if (a.completionDate && b.completionDate) {
      return (
        +b.completionDate - +a.completionDate || a.title.localeCompare(b.title)
      );
    } else {
      if (a.completionDate) {
        return 1;
      } else if (b.completionDate) {
        return -1;
      } else {
        if (a.dueDate && b.dueDate) {
          return +a.dueDate - +b.dueDate || a.title.localeCompare(b.title);
        } else if (a.dueDate) {
          return -1;
        } else if (b.dueDate) {
          return 1;
        } else if (a.customer?.name && b.customer?.name) {
          const comparison = a.title.localeCompare(b.title);
          if (comparison === 0) {
            return a.customer?.name.localeCompare(b.customer?.name);
          } else {
            return a.title.localeCompare(b.title);
          }
        } else if (a.title) {
          return a.title.localeCompare(b.title);
        } else if (b.title) {
          return 1;
        } else {
          return 0;
        }
      }
    }
  });
  return tempTasks;
};
