import { UserPlusIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import {
  InternalSharedSpaceInviteData,
  useAcceptSharedSpaceInvite,
  useGetSharedspaceInvitesForInternalUser,
} from "src/api/Services/SharedSpace/externalUser";
import { useGetSharedSpacesForUser } from "src/api/Services/SharedSpace/spaces";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "src/components/ui/dialog";
import { PageHeading } from "src/components/ui/heading";
import { Loading } from "src/components/ui/loading";
import { getDurationDifference } from "src/utils/functions/dateTime";

export default function ExternalSpacesForInternalUser() {
  const { sharedSpaces } = useGetSharedSpacesForUser();
  const { data: invites } = useGetSharedspaceInvitesForInternalUser();
  const [invitesModalOpen, setInvitesModalOpen] = useState(false);
  return (
    <div className="h-full">
      <div className="flex h-16 items-center justify-between gap-6 border-b px-8">
        <PageHeading heading={"External Spaces"} />

        {!!invites?.length && (
          <Button
            variant={"secondary"}
            onClick={() => {
              setInvitesModalOpen(true);
            }}
          >
            <UserPlusIcon className="mr-2 h-4 w-4" />
            <span>Invites</span>
            <div className="ml-2 min-w-[18px] rounded bg-primary-main px-1 py-[1px] text-center text-xs text-white">
              {invites?.length}
            </div>
          </Button>
        )}
      </div>
      <Dialog
        open={invitesModalOpen}
        onOpenChange={(open) => {
          setInvitesModalOpen(open);
        }}
      >
        <DialogContent className="top-[12%] max-w-3xl translate-y-0 p-0">
          <DialogHeader className="p-6 pb-4">
            <DialogTitle>Invites</DialogTitle>
            <DialogDescription>
              Invites to collaborate with other workspaces
            </DialogDescription>
          </DialogHeader>
          <div className="px-6 pb-6">
            {invites?.map((invite) => {
              return <Invite invite={invite} key={invite._id.toString()} />;
            })}
          </div>
        </DialogContent>
      </Dialog>
      {!!sharedSpaces ? (
        <div className="grid gap-4 px-8 pt-6 lg:grid-cols-4">
          {sharedSpaces
            ?.filter((s) => s.type === "external")
            .map((space) => {
              return (
                <div
                  key={space._id.toString()}
                  className="relative flex cursor-pointer flex-col space-y-2 rounded-md border p-4 shadow hover:bg-gray-50"
                >
                  <div className="flex items-center justify-between gap-4">
                    <div className="truncate text-lg leading-5">
                      {space.instance.name}
                    </div>
                    {!!space.notificationsCount && (
                      <div className="min-w-[18px] rounded bg-primary-main px-1 py-0.5 text-center text-xs text-white">
                        {space.notificationsCount}
                      </div>
                    )}
                  </div>

                  {/* <div className="text-sm text-gray-500">{space.description}</div> */}
                </div>
              );
            })}
        </div>
      ) : (
        <div className="mt-[20vh]">
          <Loading />
        </div>
      )}
    </div>
  );
}

function Invite({ invite }: { invite: InternalSharedSpaceInviteData }) {
  const [time] = getDurationDifference(invite.createdAt);
  const acceptSharedSpaceInviteMutation = useAcceptSharedSpaceInvite();
  return (
    <div className="flex items-center justify-between gap-4 rounded-md border border-b p-4">
      <div>
        <div className="text-lg font-medium">{invite.instance.name}</div>
        {!!invite.invitedBy ? (
          <div className="text-xs text-gray-500">
            {`${invite.invitedBy.name} from ${invite.instance.name} invited you ${time} ago`}
          </div>
        ) : (
          <div className="text-xs text-gray-500">
            {`You were invited ${time} ago`}
          </div>
        )}
      </div>
      <div className="flex gap-2">
        <Button size={"sm"} variant="secondary">
          Reject
        </Button>
        <Button
          size={"sm"}
          onClick={() => {
            acceptSharedSpaceInviteMutation.mutate({
              params: {
                sharedSpaceInviteId: invite._id,
              },
            });
          }}
          disabled={acceptSharedSpaceInviteMutation.isLoading}
        >
          {acceptSharedSpaceInviteMutation.isLoading ? "Accepting.." : "Accept"}
        </Button>
      </div>
    </div>
  );
}
