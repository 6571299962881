import clsx from "clsx";

type Props = {
  heading: string;
  required?: boolean;
  isEmbed?: boolean;
};

const InputHeading = ({
  heading,
  required = false,
  isEmbed = false,
}: Props) => {
  return (
    <div className="relative w-fit">
      <label className="block text-sm font-medium text-gray-700">
        {heading}
      </label>
      {required && (
        <span
          className={clsx(
            "text-md absolute top-0 right-0 -mt-0.5 -mr-2.5",
            isEmbed ? "text-red-500" : "text-primary-main"
          )}
        >
          {"*"}
        </span>
      )}
    </div>
  );
};

export default InputHeading;
