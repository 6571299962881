import { Editor, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useEffect } from "react";
import { classNames } from "src/utils/functions/classes";
import "./editorStyles.scss";

type Props = {
  content: string;
  setContent?: (_: string) => void;
  isEditable: boolean;
  // onBlur?: () => void
};

export const RichTextEditor = ({
  content,
  setContent = () => {},
  isEditable,
}: Props) => {
  const editor = useEditor({
    // element: document.querySelector(".element"),
    extensions: [StarterKit],
    editorProps: {
      attributes: {
        class:
          "prose focus:outline-primary-main rounded-md p-3 border-gray-200 border leading-6 w-[650px]",
      },
    },
    content: content,
    onBlur: ({ editor }) => {
      setContent(editor.getHTML());
    },
    editable: isEditable,
  });

  useEffect(() => {
    editor?.setEditable(isEditable);
  }, [isEditable]);

  return (
    <div>
      {/* <div className="p-3  rounded-md border-gray-300 border"> */}
      {!!editor && isEditable && <MenuBar editor={editor} />}
      <EditorContent editor={editor} />
    </div>
  );
};

const MenuBar = ({ editor }: { editor: Editor }) => {
  const isActiveClassName = "border-gray-600 text-gray-600";
  const buttons: {
    onClick: () => void;
    disabled: boolean | undefined;
    className: string;
    name: string;
  }[] = [
    {
      onClick: () => editor.chain().focus().toggleBold().run(),
      disabled: !editor.can().chain().focus().toggleBold().run(),
      className: editor.isActive("bold") ? isActiveClassName : "",
      name: "bold",
    },
    {
      onClick: () => editor.chain().focus().toggleItalic().run(),
      disabled: !editor.can().chain().focus().toggleItalic().run(),
      className: editor.isActive("italic") ? isActiveClassName : "",
      name: "italic",
    },
    {
      onClick: () => editor.chain().focus().toggleStrike().run(),
      disabled: !editor.can().chain().focus().toggleStrike().run(),
      className: editor.isActive("strike") ? isActiveClassName : "",
      name: "strike",
    },
    {
      onClick: () => editor.chain().focus().toggleCode().run(),
      disabled: !editor.can().chain().focus().toggleCode().run(),
      className: editor.isActive("code") ? isActiveClassName : "",
      name: "code",
    },
    {
      onClick: () => editor.chain().focus().setParagraph().run(),
      disabled: false,
      className: editor.isActive("paragraph") ? isActiveClassName : "",
      name: "paragraph",
    },
    {
      onClick: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
      disabled: false,
      className: editor.isActive("heading", { level: 2 })
        ? isActiveClassName
        : "",
      name: "h1",
    },
    {
      onClick: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
      disabled: false,
      className: editor.isActive("heading", { level: 3 })
        ? isActiveClassName
        : "",
      name: "h2",
    },
    {
      onClick: () => editor.chain().focus().toggleBulletList().run(),
      disabled: false,
      className: editor.isActive("bulletList") ? isActiveClassName : "",
      name: "bullet list",
    },
    {
      onClick: () => editor.chain().focus().toggleOrderedList().run(),
      disabled: false,
      className: editor.isActive("orderedList") ? isActiveClassName : "",
      name: "ordered list",
    },
    {
      onClick: () => editor.chain().focus().toggleCodeBlock().run(),
      disabled: false,
      className: editor.isActive("codeBlock") ? isActiveClassName : "",
      name: "code block",
    },
    {
      onClick: () => editor.chain().focus().toggleBlockquote().run(),
      disabled: false,
      className: editor.isActive("blockquote") ? isActiveClassName : "",
      name: "block quote",
    },
    {
      onClick: () => editor.chain().focus().setHorizontalRule().run(),
      disabled: false,
      className: "",
      name: "line",
    },
  ];

  if (!editor) {
    return null;
  }

  return (
    <>
      <div className="mb-3 flex flex-wrap gap-1">
        {buttons.map((button) => (
          <button
            key={button.name}
            onClick={button.onClick}
            disabled={button.disabled}
            className={classNames(
              "rounded border border-gray-200 bg-white px-1 text-xs text-gray-400",
              button.className
            )}
          >
            {button.name}
          </button>
        ))}
      </div>
      {/* <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={editor.isActive("bold") ? "is-active" : ""}
      >
        bold
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={editor.isActive("italic") ? "is-active" : ""}
      >
        italic
      </button>
      <button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        className={editor.isActive("strike") ? "is-active" : ""}
      >
        strike
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCode().run()}
        disabled={!editor.can().chain().focus().toggleCode().run()}
        className={editor.isActive("code") ? "is-active" : ""}
      >
        code
      </button>
      <button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
        clear marks
      </button>
      <button onClick={() => editor.chain().focus().clearNodes().run()}>
        clear nodes
      </button>
      <button>paragraph</button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={editor.isActive("heading", { level: 1 }) ? "is-active" : ""}
      >
        h1
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={editor.isActive("heading", { level: 2 }) ? "is-active" : ""}
      >
        h2
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
      >
        h3
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
      >
        h4
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
        className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
      >
        h5
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
        className={editor.isActive("heading", { level: 6 }) ? "is-active" : ""}
      >
        h6
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive("bulletList") ? "is-active" : ""}
      >
        bullet list
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive("orderedList") ? "is-active" : ""}
      >
        ordered list
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={editor.isActive("codeBlock") ? "is-active" : ""}
      >
        code block
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={editor.isActive("blockquote") ? "is-active" : ""}
      >
        blockquote
      </button>
      <button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
        horizontal rule
      </button>
      <button onClick={() => editor.chain().focus().setHardBreak().run()}>
        hard break
      </button>
      <button
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().chain().focus().undo().run()}
      >
        undo
      </button>
      <button
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().chain().focus().redo().run()}
      >
        redo
      </button> */}
    </>
  );
};
