import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ObjectID } from "bson";
import { useNavigate } from "react-router-dom";
import { buildFetcher, buildSetter } from "src/api";
import { TagData } from "src/api/General/tags";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { useRealmApp } from "src/store/RealmApp";
import { taskKeys } from "../Tasks/tasks";

export const threadKeys = {
  threads: () => ["threads", "all"] as const,
  sharedSpaceThreads: (sharedSpaceId: ObjectID) =>
    ["threads", "sharedSpace", sharedSpaceId.toString(), "all"] as const,
  phaseThreads: (phaseId: ObjectID) =>
    ["threads", "phase", phaseId.toString(), "all"] as const,
  thread: (threadId: ObjectID) => ["threads", threadId.toString()] as const,
  threadMessages: (threadId: ObjectID) =>
    ["threads", threadId.toString(), "messages", "all"] as const,
  taskThread: (taskId: string) => ["threads", "task", taskId] as const,
};

// TYPES

export type ThreadData = {
  _id: ObjectID;
  sharedSpaceId: ObjectID;
  phaseId?: ObjectID;
  name: string;
  userIds: ObjectID[];
  tags: TagData[];
  createdAt: Date;
  updatedAt: Date;
  isActive: boolean;
  otherDetails: {
    createdBy?: {
      _id: ObjectID;
      email: string;
      name: string;
    };
    sharedSpace?: {
      _id: ObjectID;
      name: string;
    };
    users?: {
      _id: ObjectID;
      name: string;
      email: string;
      type: "internal" | "external";
    }[];
    unreadMessagesCount: number;
  };
  associations?: {
    taskId?: ObjectID;
  };
};

export type ThreadMessageData = {
  _id: ObjectID;
  threadId?: ObjectID;
  text: string;
  user: {
    _id: ObjectID;
    name: string;
    email: string;
    type: "internal" | "external";
  };
  attachments?: string[];
  createdAt: Date;
  updatedAt?: Date;
  readReceipts?: {
    userId: ObjectID;
    read: boolean;
  }[];
};

// THREADS

export const useGetTaskThreadMessages = (taskId: string) => {
  const app = useRealmApp();
  const queryClient = useQueryClient();
  const meta = {
    functionName: "getThreadMessages",
    parameters: {
      taskId: new ObjectID(taskId),
    },
  };

  return useQuery<ThreadMessageData[]>(
    threadKeys.taskThread(taskId),
    buildFetcher(app, meta),
    {
      staleTime: Infinity,
    }
  );
};

export const useGetThread = (
  threadId: ObjectID
): {
  thread: ThreadData | undefined;
} => {
  const app = useRealmApp();
  const queryClient = useQueryClient();
  const meta = {
    functionName: "getThread",
    parameters: {
      _id: threadId,
    },
  };

  const { data } = useQuery<ThreadData>(
    threadKeys.thread(threadId),
    buildFetcher(app, meta),
    {
      staleTime: Infinity,
    }
  );

  return {
    thread: data,
  };
};

export const useGetInstanceThreads = (): {
  threads: ThreadData[] | undefined;
} => {
  const app = useRealmApp();
  const queryClient = useQueryClient();
  const meta = {
    functionName: "getThreads",
    parameters: {
      instanceId: app.currentUser.customData.instanceId,
    },
  };

  const { data } = useQuery<ThreadData[]>(
    threadKeys.threads(),
    buildFetcher(app, meta),
    {
      staleTime: Infinity,
    }
  );

  return {
    threads: data,
  };
};

export const useGetPhaseThreads = (phaseId: string) => {
  const app = useRealmApp();
  const queryClient = useQueryClient();
  const meta = {
    functionName: "getThreads",
    parameters: {
      phaseId: new ObjectID(phaseId),
    },
  };

  return useQuery<ThreadData[]>(
    threadKeys.phaseThreads(new ObjectID(phaseId)),
    buildFetcher(app, meta),
    {
      staleTime: Infinity,
    }
  );
};

export const useGetSharedSpaceThreads = (
  sharedSpaceId: string
): {
  threads: ThreadData[] | undefined;
} => {
  const app = useRealmApp();
  const queryClient = useQueryClient();
  const meta = {
    functionName: "getThreads",
    parameters: {
      sharedSpaceId: new ObjectID(sharedSpaceId),
    },
  };

  const { data } = useQuery<ThreadData[]>(
    threadKeys.sharedSpaceThreads(new ObjectID(sharedSpaceId)),
    buildFetcher(app, meta),
    {
      staleTime: Infinity,
    }
  );

  return {
    threads: data,
  };
};

export const useGetThreadMessages = (
  threadId: string | undefined
): {
  threadMessages: ThreadMessageData[] | undefined;
} => {
  const app = useRealmApp();
  const meta = {
    functionName: "getThreadMessages",
    parameters: {
      threadId: new ObjectID(threadId),
    },
  };

  const { data } = useQuery<ThreadMessageData[]>(
    threadKeys.threadMessages(new ObjectID(threadId)),
    buildFetcher(app, meta)
  );

  return {
    threadMessages: data,
  };
};

export const useCreateThreadInPhase = () => {
  const app = useRealmApp();
  const functionName = "createThread";
  const fieldName = "params";
  const queryClient = useQueryClient();
  const snackbarCtx = useSnackBar();

  const createThreadMutation = useMutation(
    buildSetter(app, functionName, fieldName),
    {
      onMutate: async ({
        params,
      }: {
        params: {
          name: string;
          phaseId: ObjectID;
          userIds: ObjectID[];
          tags: TagData[];
        };
      }) => {},
      onError: (data, variables, context) => {
        snackbarCtx.showSnackbar("Error creating thread", "error");
      },
      onSuccess: (data, variables) => {
        if (variables.params.phaseId) {
          queryClient.invalidateQueries(
            threadKeys.phaseThreads(variables.params.phaseId)
          );
        }
      },
    }
  );

  return createThreadMutation;
};

export const useCreateThread = () => {
  const app = useRealmApp();
  const functionName = "createThread";
  const fieldName = "params";
  const queryClient = useQueryClient();
  const snackbarCtx = useSnackBar();
  const navigate = useNavigate();

  const createThreadMutation = useMutation(
    buildSetter(app, functionName, fieldName),
    {
      onMutate: async ({
        params,
      }: {
        params: {
          name: string;
          sharedSpaceId: ObjectID;
          userIds?: ObjectID[];
          tags?: TagData[];
          associations?: {
            id: string;
            type: "file" | "task" | "table";
          }[];
        };
      }) => {
        // optimistically add the message to the thread or create the message with the thread
      },
      onError: (data, variables, context) => {
        snackbarCtx.showSnackbar("Error creating chat", "error");
      },
      onSettled: (data, error, variables) => {
        // const tempData = data as {
        //   status: number;
        //   id: ObjectID;
        //   message: string;
        // };
        // snackbarCtx.showSnackbar("New knowledge resource created", "success");
        queryClient.invalidateQueries(["threads"]);
        // navigate(`/threads/${tempData.id.toString()}`, {
        //   state: { isEditable: true },
        // });
      },
    }
  );

  return createThreadMutation;
};

export const useCreateThreadInTask = () => {
  const app = useRealmApp();
  const functionName = "createThread";
  const fieldName = "params";
  const queryClient = useQueryClient();
  const snackbarCtx = useSnackBar();
  const navigate = useNavigate();

  const createThreadMutation = useMutation(
    buildSetter(app, functionName, fieldName),
    {
      onMutate: async ({
        params,
      }: {
        params: {
          name: string;
          userIds?: ObjectID[];
          // tags?: TagData[];
          associations?: {
            _id: ObjectID;
            type: "task";
          }[];
          firstMessage?: {
            text: string;
          };
          instanceId: ObjectID;
        };
      }) => {
        // optimistically add the message to the thread or create the message with the thread
      },
      onError: (data, variables, context) => {
        snackbarCtx.showSnackbar("Error creating chat", "error");
      },
      onSettled: (data, error, variables) => {
        // const tempData = data as {
        //   status: number;
        //   id: ObjectID;
        //   message: string;
        // };
        // snackbarCtx.showSnackbar("New knowledge resource created", "success");
        queryClient.invalidateQueries(["threads"]);
        if (
          !!variables?.params?.associations &&
          variables?.params?.associations.length > 0
        ) {
          queryClient.invalidateQueries(
            taskKeys.task(variables?.params?.associations[0]._id?.toString())
          );
        }
        // navigate(`/threads/${tempData.id.toString()}`, {
        //   state: { isEditable: true },
        // });
      },
    }
  );

  return createThreadMutation;
};

export const useCreateThreadInPhaseTask = () => {
  const app = useRealmApp();
  const functionName = "createThread";
  const fieldName = "params";
  const queryClient = useQueryClient();
  const snackbarCtx = useSnackBar();
  const navigate = useNavigate();

  const createThreadMutation = useMutation(
    buildSetter(app, functionName, fieldName),
    {
      onMutate: async ({
        params,
      }: {
        params: {
          name: string;
          userIds?: ObjectID[];
          // tags?: TagData[];
          phaseId: ObjectID;
          associations: {
            _id: ObjectID;
            type: "task";
          }[];
          firstMessage?: {
            text: string;
          };
        };
      }) => {
        // optimistically add the message to the thread or create the message with the thread
      },
      onError: (data, variables, context) => {
        snackbarCtx.showSnackbar("Error creating chat", "error");
      },
      onSettled: (data, error, variables) => {
        // const tempData = data as {
        //   status: number;
        //   id: ObjectID;
        //   message: string;
        // };
        // snackbarCtx.showSnackbar("New knowledge resource created", "success");
        queryClient.invalidateQueries(["threads"]);
        queryClient.invalidateQueries(
          taskKeys.task(variables.params?.associations[0]?._id?.toString())
        );
        // navigate(`/threads/${tempData.id.toString()}`, {
        //   state: { isEditable: true },
        // });
      },
    }
  );

  return createThreadMutation;
};

export const useDeleteThread = () => {
  const app = useRealmApp();
  const functionName = "deleteThread";
  const fieldName = "params";
  const queryClient = useQueryClient();
  const snackbarCtx = useSnackBar();
  const navigate = useNavigate();

  const createThreadMutation = useMutation(
    buildSetter(app, functionName, fieldName),
    {
      onMutate: async ({
        params,
      }: {
        params: {
          _id: ObjectID;
        };
      }) => {
        const queryKey = threadKeys.threads();
        await queryClient.cancelQueries(queryKey);
        const previousThreads = queryClient.getQueryData(queryKey);
        queryClient.setQueryData(queryKey, (threads: ThreadData[] = []) =>
          threads.filter((t) => t._id.toString() !== params._id.toString())
        );
        return { previousThreads };
      },
      onError: (data, variables, context) => {
        snackbarCtx.showSnackbar("Error deleting thread", "error");
        queryClient.setQueryData(
          threadKeys.threads(),
          context?.previousThreads
        );
      },
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries(threadKeys.threads());
      },
    }
  );

  return createThreadMutation;
};

export const useUpdateThread = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const functionName = "updateThread";
  type UpdateType = Partial<ThreadData> & { isRead?: boolean };

  const queryClient = useQueryClient();
  const updateKRTMutation = useMutation(
    buildSetter(app, functionName, "update"),
    {
      onMutate: async (params: { update: UpdateType; _id: ObjectID }) => {
        // const queryKey = threadKeys.thread(params._id);
        // await queryClient.cancelQueries(queryKey);
        // const previousThread = queryClient.getQueryData<ThreadData>(queryKey);
        // let newThread = { ...previousThread, ...params.update };
        // if (params.update.isRead) {
        //   if (newThread.otherDetails)
        //     newThread.otherDetails.unreadMessagesCount = 0;
        // }
        // queryClient.setQueryData(queryKey, newThread);
        // return { previousThread };
      },
      onError: (err, update, context) => {
        // queryClient.setQueryData(
        //   threadKeys.thread(update._id),
        //   context?.previousThread
        // );
        snackbarCtx.showSnackbar("Error updating the thread", "error");
      },
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries(["threads"]);
      },
    }
  );
  return updateKRTMutation;
};

// THREAD MESSAGE

export const useCreateThreadMessage = () => {
  const app = useRealmApp();
  const functionName = "createThreadMessage";
  const fieldName = "params";
  const queryClient = useQueryClient();
  const snackbarCtx = useSnackBar();
  const navigate = useNavigate();

  const createThreadMessageMutation = useMutation(
    buildSetter(app, functionName, fieldName),
    {
      onMutate: async ({
        params,
        metadata,
      }: {
        params: {
          text?: string;
          threadId: ObjectID;
          attachments?: string[];
        };
        metadata?: {
          type: "internal" | "external";
        };
      }) => {
        const queryKey = threadKeys.threadMessages(params.threadId);
        await queryClient.cancelQueries(queryKey);
        const previousMessages = queryClient.getQueryData(queryKey);
        queryClient.setQueryData(
          queryKey,
          (messages: ThreadMessageData[] = []) => [
            ...messages,
            {
              _id: new ObjectID(),
              threadId: params.threadId,
              text: params.text ?? "",
              user: {
                _id: app.currentUser.id,
                name: app.currentUser.customData.name,
                email: app.currentUser.customData.email,
                type: metadata?.type || "internal",
              },
              attachments: params.attachments ?? [],
              createdAt: new Date(),
              updatedAt: new Date(),
            },
          ]
        );
        return { previousMessages };
      },
      onError: (data, variables, context) => {
        queryClient.setQueryData(
          threadKeys.threadMessages(variables.params.threadId),
          context?.previousMessages
        );
        snackbarCtx.showSnackbar("Error sending message", "error");
      },
      onSettled: (data, error, variables) => {
        // snackbarCtx.showSnackbar("New knowledge resource created", "success");
        queryClient.invalidateQueries(
          threadKeys.threadMessages(variables.params.threadId)
        );

        // navigate(`/threads/${tempData.id.toString()}`, {
        //   state: { isEditable: true },
        // });
      },
    }
  );

  return createThreadMessageMutation;
};
