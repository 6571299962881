import { useGetInstance } from "src/api/General/instance";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { Loading } from "src/components/ui/loading";
import { TextInput } from "src/components/ui/text-input";

type Props = {};

export default function WorkspaceGeneralSettings({}: Props) {
  const { instance } = useGetInstance();
  return (
    <div>
      <div className="flex h-12 items-center border-b border-gray-200/70 px-8 text-sm">
        {instance?.name}
      </div>
      <div className="mx-auto max-w-2xl pt-20">
        <div>
          <div className="border-b border-gray-200/70 pb-6 text-xl font-medium">
            General
          </div>
          {!!instance ? (
            <div className="space-y-10 pt-8">
              <div className="space-y-2.5">
                <div className="space-y-0.5">
                  <div className="font-medium text-gray-800">Details</div>
                  <div className="text-sm font-light text-gray-600">
                    Name and logo of the workspace
                  </div>
                </div>
                <div className="space-y-4 rounded-md bg-gray-50 p-6 pt-5 shadow-sm">
                  <div className="space-y-0.5">
                    <InputHeading heading="Name" />
                    <WorkspaceName />
                  </div>
                </div>
              </div>
              {/* <div className="space-y-2.5">
                <div className="space-y-0.5">
                  <div className="font-medium text-gray-800">Delete</div>
                </div>
                <div className="rounded-md bg-gray-50 p-6 shadow-sm">
                  <div className="flex items-center justify-between gap-6">
                    <div className="max-w-md text-sm font-light text-gray-800">
                      Deleting a space is a permanent action. All projects and
                      tasks in the space will be permanently deleted.
                    </div>
                    <div>
                      <Button
                        size={"sm"}
                        variant={"destructive"}
                        onClick={() => {}}
                        className="text-white"
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          ) : (
            <Loading className="mt-20" />
          )}
        </div>
      </div>
    </div>
  );
}

function WorkspaceName() {
  const { instance } = useGetInstance();
  return <TextInput disabled className="bg-white w-72 disabled:opacity-100 cursor-not-allowed" value={instance?.name} />;
}
