import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { classNames } from "src/utils/functions/classes";

type Breadcrumb = {
  title: string;
  route?: string;
};

type Props = {
  breadcrumbs: Breadcrumb[];
};

export const Breadcrumbs = ({ breadcrumbs }: Props) => {
  return (
    <>
      {breadcrumbs.length > 0 ? (
        <nav className="flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            {breadcrumbs.map((b, i) => (
              <Breadcrumb key={b.route ?? b.title} breadcrumb={b} index={i} />
            ))}
          </ol>
        </nav>
      ) : (
        <></>
      )}
    </>
  );
};

const Breadcrumb = ({
  breadcrumb,
  index,
}: {
  breadcrumb: Breadcrumb;
  index: number;
}) => {
  const navigate = useNavigate();
  return (
    <li>
      <div className="flex items-center">
        {index !== 0 && (
          <ChevronRightIcon
            className="h-4 w-4 flex-shrink-0 text-gray-400"
            aria-hidden="true"
          />
        )}
        {breadcrumb.title !== "" ? (
          <span
            onClick={() => {
              if (breadcrumb.route) navigate(breadcrumb.route);
            }}
            className={classNames(
              index !== 0 ? "ml-4" : "",
              !!breadcrumb.route
                ? "cursor-pointer hover:text-gray-600"
                : "cursor-default",
              "text-sm font-medium text-gray-400 transition-colors"
            )}
          >
            {breadcrumb.title}
          </span>
        ) : (
          <div
            className={classNames(
              index !== 0 ? "ml-4" : "",
              "h-4 w-40 rounded bg-gray-200"
            )}
          />
        )}
      </div>
    </li>
  );
};
