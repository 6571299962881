import clsx from "clsx";
import React from "react";

type Props = {
  cta?: React.ReactNode;
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  title: string;
  subtitle: React.ReactNode;
  requireMargin?: boolean;
};

export const EmptySection = ({
  cta,
  Icon,
  title,
  subtitle,
  requireMargin = true,
}: Props) => {
  return (
    <div
      className={clsx(
        "flex justify-center flex-col items-center",
        requireMargin && "mt-20"
      )}
    >
      <Icon className="mx-auto h-6 w-6 text-gray-400" />
      <h3 className="mt-2 text-sm font-medium text-gray-900">{title}</h3>
      <div className="mt-1 text-sm text-gray-500">{subtitle}</div>
      {!!cta && <div className="mt-6">{cta}</div>}
    </div>
  );
};
