import React, { createContext, useContext, useReducer } from "react";

type Action =
  | { type: "modal_open" }
  | { type: "modal_close" }
  | { type: "name_update"; name: string }
  | { type: "internal_description_update"; description: string }
  | { type: "type_update"; templateType: TemplateType }
  | { type: "internal_personas_add"; persona: string }
  | { type: "internal_personas_delete"; persona: string }
  | { type: "external_personas_add"; persona: string }
  | { type: "external_personas_delete"; persona: string }
  | { type: "stages_add"; stage: string }
  | { type: "stages_delete"; stage: string };

type Dispatch = (action: Action) => void;

export type TemplateType =
  | { name: "Internal"; value: "internal" }
  | { name: "External"; value: "external" };

type State = {
  isModalOpen: boolean;
  name: string;
  internalDescription: string;
  type: TemplateType;
  internalPersonas: string[];
  externalPersonas: string[];
  stages: string[];
};

const NewTemplateContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function newTemplateReducer(state: State, action: Action): State {
  switch (action.type) {
    case "modal_open": {
      return { ...state, isModalOpen: true };
    }
    case "modal_close": {
      return { ...state, isModalOpen: false };
    }
    case "name_update": {
      return { ...state, name: action.name };
    }
    case "internal_description_update": {
      return { ...state, internalDescription: action.description };
    }
    case "type_update": {
      return { ...state, type: action.templateType };
    }
    case "internal_personas_add": {
      return {
        ...state,
        internalPersonas: [...state.internalPersonas, action.persona],
      };
    }
    case "internal_personas_delete": {
      return {
        ...state,
        internalPersonas: [...state.internalPersonas].filter(
          (p) => p !== action.persona
        ),
      };
    }
    case "external_personas_add": {
      return {
        ...state,
        externalPersonas: [...state.externalPersonas, action.persona],
      };
    }
    case "external_personas_delete": {
      return {
        ...state,
        externalPersonas: [...state.externalPersonas].filter(
          (p) => p !== action.persona
        ),
      };
    }
    case "stages_add": {
      return { ...state, stages: [...state.stages, action.stage] };
    }
    case "stages_delete": {
      return {
        ...state,
        stages: [...state.stages].filter((s) => s !== action.stage),
      };
    }
    default:
      return state;
  }
}

type Props = {
  children: React.ReactNode;
};

export const NewTemplateContextProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(newTemplateReducer, {
    isModalOpen: false,
    name: "",
    internalDescription: "",
    type: { name: "External", value: "external" },
    internalPersonas: [],
    externalPersonas: [],
    stages: [],
  });
  const value = { state, dispatch };
  return (
    <NewTemplateContext.Provider value={value}>
      {children}
    </NewTemplateContext.Provider>
  );
};

export const useNewTemplate = () => {
  const context = useContext(NewTemplateContext);
  if (context === undefined) {
    throw new Error("useNewTemplate must be used within a NewTemplateProvider");
  }
  return context;
};
