import { useState } from "react";
import { useParams } from "react-router-dom";
import { PhaseData } from "src/api/Services/SharedSpace/phases";
import { Button } from "src/components/ui/button";
import { NewProjectTaskContextProvider } from "src/pages/Services/Tasks/Modals/NewProjectTaskModal/NewProjectTaskContextProvider";
import NewProjectTaskModal from "src/pages/Services/Tasks/Modals/NewProjectTaskModal/NewProjectTaskModal";
import { useNewTaskForExternalUser } from "src/pages/Services/Tasks/NewTaskForExternalUser";
import { useNewTaskForInternalUser } from "src/pages/Services/Tasks/NewTaskForInternalUser";
import { useJourney } from "../../JourneyContextProvider";

export default function NewTaskButton({
  phase,
  journeyId,
}: {
  phase: PhaseData;
  journeyId: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const journeyCtx = useJourney();
  const newTaskForExternalUserCtx = useNewTaskForExternalUser();
  const newTaskForInternalUserCtx = useNewTaskForInternalUser();
  const { slug } = useParams();
  return (
    <>
      {journeyCtx.state.isExternal ? (
        <Button
          className="space-x-2"
          variant={"secondary"}
          onClick={() => {
            if (journeyCtx.state.isExternal) {
              newTaskForExternalUserCtx.dispatch({
                type: "open_update",
                projectId: phase._id,
              });
            }
          }}
          // disabled={!newTaskForExternalUserCtx.state.projects}
        >
          <span>New Task</span>
        </Button>
      ) : (
        <Button
          onClick={() => {
            // newTaskDialogCtx.dispatch({
            //   type: "open_update",
            //   isOpen: true,
            // });
            // const statusGroup = newTaskCtx.state.statusGroups?.find(
            //   (sg) => sg._id === phase.statusGroup._id
            // );
            // const status = statusGroup?.statuses.find(
            //   (s) => s.parentStatus === "To-do"
            // );
            // const project = newTaskCtx.state.projects?.find(
            //   (p) => p._id === phase._id
            // );
            newTaskForInternalUserCtx.dispatch({
              type: "open_update",
              projectId: phase._id,
            });

            // newTaskCtx.dispatch({
            //   type: "state_update",
            //   state: {
            //     project: project,
            //     stage: undefined,
            //     tasker: undefined,
            //     dueDate: undefined,
            //     dependentOn: {
            //       _id: undefined,
            //       type: "task",
            //     },
            //     addingDependency: false,
            //     relativeDueDate: {
            //       unit: "day",
            //       value: 1,
            //     },
            //     statusGroup: statusGroup,
            //     ...(!!status
            //       ? {
            //           status: {
            //             status: status.parentStatus,
            //             customStatus: status.name,
            //           },
            //         }
            //       : {}),
            //   },
            // });
          }}
          className="space-x-2"
          variant={"secondary"}
        >
          <span>New Task</span>
        </Button>
      )}
      <NewProjectTaskContextProvider
        sharedSpaceId={phase.sharedSpaceId}
        journeyId={journeyId}
        phaseId={phase._id}
      >
        <NewProjectTaskModal
          isOpen={isOpen}
          closeModal={() => {
            setIsOpen(false);
          }}
        />
      </NewProjectTaskContextProvider>
    </>
  );
}
