import React from "react";
import { TaskGroup } from "src/api/Services/Tasks/tasks";

type Properties = {
  timeLeft: boolean;
  isStatusExpanded: boolean;
};

type Action =
  | { type: "properties_update"; properties: Properties }
  | { type: "group_update"; group: TaskGroup };

type Dispatch = (action: Action) => void;

type State = {
  properties: Properties;
  group: TaskGroup;
};

const TasksContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function taskReducer(state: State, action: Action) {
  switch (action.type) {
    case "properties_update": {
      return { ...state, properties: action.properties };
    }
    case "group_update": {
      return { ...state, group: action.group };
    }
  }
}

type Props = {
  children: React.ReactNode;
};

export default function TasksContextProvider({ children }: Props) {
  const [state, dispatch] = React.useReducer(taskReducer, {
    properties: {
      timeLeft: false,
      isStatusExpanded: false,
    },
    group: "status",
  });

  const value = { state, dispatch };

  return (
    <TasksContext.Provider value={value}>{children}</TasksContext.Provider>
  );
}

export function useTasksContext() {
  const context = React.useContext(TasksContext);
  if (context === undefined) {
    throw new Error(
      "useTasksContext must be used within a TasksContextProvider"
    );
  }
  return context;
}
