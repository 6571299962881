import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  closestCorners,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  Cog6ToothIcon,
  EllipsisHorizontalIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Cross2Icon, Pencil2Icon, PlusIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useNavigate, useParams } from "react-router-dom";
import {
  RoadmapData,
  RoadmapProjectData,
  useCreateRoadmapStage,
  useDeleteRoadmapStage,
  useGetRoadmap,
  useUpdateRoadmap,
  useUpdateRoadmapStage,
} from "src/api/Roadmaps/roadmaps";
import { useGetOnboardingProjects } from "src/api/Services/Projects/projects";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { Breadcrumbs } from "src/components/ui/Navigation/Breadcrumbs";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import { Loading } from "src/components/ui/loading";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
} from "src/components/ui/select";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/components/ui/tabs";
import { TextInput } from "src/components/ui/text-input";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "src/components/ui/tooltip";

type Props = {};

export default function Roadmap({}: Props) {
  const { projectGroupId } = useParams();
  const { data: roadmapFetch } = useGetRoadmap(String(projectGroupId));
  const [roadmap, setRoadmap] = useState<RoadmapData | undefined>(roadmapFetch);
  const navigate = useNavigate();
  const [newStageName, setNewStageName] = useState("");
  const [newStageDialogOpen, setNewStageDialogOpen] = useState(false);
  const createRoadmapStageMutation = useCreateRoadmapStage();
  const [addingProjectsDialogOpen, setAddingProjectsDialogOpen] =
    useState(false);
  const updateRoadmapMutation = useUpdateRoadmap(String(projectGroupId));

  useEffect(() => {
    if (newStageDialogOpen) {
      setNewStageName("");
    }
  }, [newStageDialogOpen]);

  useEffect(() => {
    if (roadmapFetch) setRoadmap(roadmapFetch);
  }, [roadmapFetch]);

  const { tab } = useParams();
  if (!tab) {
    navigate(`/project-groups/${projectGroupId}/projects`, {
      replace: true,
    });
  } else {
    if (tab !== "projects" && tab !== "testing") {
      navigate(`/project-groups/${projectGroupId}/projects`, {
        replace: true,
      });
    }
  }

  const numberOfProjects = roadmap?.projects?.length ?? 0;

  const subGroupIds = useMemo(() => {
    return roadmap?.stages.map((s) => s._id) ?? [];
  }, [roadmap]);

  const [activeGroup, setActiveGroup] = useState<string | null>(null);
  const [activeProject, setActiveProject] = useState<string | null>(null);

  function getSubGroup(id: string) {
    const subGroup = roadmap?.stages.find((s) => s._id === id);
    if (!subGroup) {
      const project = roadmap?.projects?.find((p) => p._id === id);
      if (project) {
        return project.roadmapStageId;
      } else {
        return null;
      }
    } else {
      return subGroup._id;
    }
  }

  function onDragStart(event: DragStartEvent) {
    if (event.active.data.current?.type === "SUB_GROUP") {
      setActiveGroup(event.active.data.current?._id);
    }
    if (event.active.data.current?.type === "PROJECT") {
      setActiveProject(event.active.data.current?.project._id);
    }
  }

  function onDragEnd(event: DragEndEvent) {
    setActiveGroup(null);
    setActiveProject(null);
    const { active, over } = event;
    if (!over) return;
    const activeId = active.data.current?._id;
    const overId = over.data.current?._id;
    if (active.data.current?.type !== "SUB_GROUP") {
      return;
    }

    if (activeId === overId) return;

    // const isActiveProject = active.data.current?.type === "PROJECT";
    // const isActiveSubGroup = active.data.current?.type === "SUB_GROUP";
    // const isOverProject = over.data.current?.type === "PROJECT";
    // const isOverSubGroup = over.data.current?.type === "SUB_GROUP";

    const activeSubGroupIndex = roadmap?.stages.findIndex(
      (s) => s._id === activeId
    );

    const overSubGroupId = getSubGroup(overId);
    console.log(overSubGroupId);
    if (!overSubGroupId) return;

    const overSubGroupIndex = roadmap?.stages.findIndex(
      (s) => s._id === overSubGroupId
    );
    if (
      activeSubGroupIndex === undefined ||
      overSubGroupIndex === undefined
      // ||
      // !overSubGroup ||
      // overSubGroup.isUnassigned
    ) {
      return;
    }
    const newGroupOrder = arrayMove(
      roadmap?.stages ?? [],
      activeSubGroupIndex,
      overSubGroupIndex
    );
    updateRoadmapMutation.mutate({
      _id: String(projectGroupId),
      stageOrder: newGroupOrder.map((g) => g._id),
    });
    setRoadmap((prev) => {
      if (!prev) return prev;
      const newStages = arrayMove(
        prev.stages,
        activeSubGroupIndex,
        overSubGroupIndex
      );
      return {
        ...prev,
        stages: newStages,
      };
    });
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3,
      },
    })
  );

  return (
    <div className="flex h-full flex-col">
      <div className="flex items-start justify-between px-8 pt-6">
        <div className="space-y-2">
          <Breadcrumbs
            breadcrumbs={[
              { title: "Project Groups", route: "/project-groups" },
              // {
              //   title: roadmap?.name ?? "",
              // },
            ]}
          />
          <div className="flex items-center space-x-2.5">
            {/* <Link
              to={"/project-groups"}
              className="flex items-center space-x-1.5 text-gray-400 transition-colors hover:text-gray-600"
            >
              <Squares2X2Icon className="h-5 w-5" />
            </Link> */}
            {/* <ChevronRightIcon className="h-3 w-3 text-gray-500" /> */}
            {/* <Button
              variant={"ghost"}
              size={"icon"}
              className="h-fit w-fit hover:bg-white"
              onClick={() => {
                navigate("/project-groups");
              }}
            >
              <ChevronLeftIcon viewBox="6 0 24 24" className="h-4 w-4" />
            </Button> */}
            <div className="cursor-default text-xl font-medium leading-none text-gray-900">
              {roadmap?.name}
            </div>
          </div>
        </div>

        <div className="flex gap-1.5">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant={"secondaryLink"}
                  onClick={() => {
                    navigate(`/project-groups/${projectGroupId}/settings`);
                  }}
                  className="h-fit w-fit"
                  size="icon"
                >
                  <Cog6ToothIcon className="h-[18px] w-[18px]" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Settings</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          {/* <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger asChild>
                <Button
                  variant={"ghost"}
                  onClick={() => {
                    // window.open(
                    //   `/external-view/shared-spaces/${phase.sharedSpaceId}`,
                    //   "_blank"
                    // );
                  }}
                  size="icon"
                >
                  <EyeIcon className="h-[17px] w-[17px]" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>External View</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          
          <div className="ml-1.5">
            <Button variant={"secondary"}>Members</Button>
          </div> */}
        </div>
      </div>

      {!!roadmap ? (
        <>
          {roadmap?.stages.length > 1 || numberOfProjects > 0 ? (
            <Tabs
              defaultValue="projects"
              value={tab}
              className="flex flex-1 flex-col overflow-auto pt-6"
              onValueChange={(value) => {
                navigate(`/project-groups/${projectGroupId}/${value}`);
              }}
            >
              <TabsList className="shrink-0 px-8">
                {/* <TabsTrigger value="testing">Testing</TabsTrigger> */}
                <TabsTrigger value="projects">Projects</TabsTrigger>
              </TabsList>
              <div className="flex-1 overflow-auto">
                {/* <TabsContent
                  value="testing"
                  className="h-full overflow-auto pt-6"
                >
                  <Testing />
                </TabsContent> */}
                <TabsContent
                  value="projects"
                  className="h-full overflow-auto pt-6"
                >
                  {/* <div className="px-2">
                    <Button variant={"secondary"} className="flex space-x-1.5">
                      <FunnelIcon className="h-3.5 w-3.5" />
                      <span>Filter</span>
                    </Button>
                  </div> */}
                  <div className="flex h-full flex-1 overflow-auto pl-5">
                    <DndContext
                      collisionDetection={closestCorners}
                      onDragStart={onDragStart}
                      onDragEnd={onDragEnd}
                      sensors={sensors}
                      onDragOver={(event: DragOverEvent) => {
                        const { active, over } = event;
                        if (!over) return;

                        const activeId = active.id;
                        const overId = over.id;
                        // if the project is dragged over itself, return
                        if (activeId === overId) return;
                        const isActiveProject =
                          active.data.current?.type === "PROJECT";
                        if (!isActiveProject) return;
                        const isOverProject =
                          over.data.current?.type === "PROJECT";
                        const isOverSubGroup =
                          over.data.current?.type === "SUB_GROUP";
                        if (isActiveProject && isOverProject) {
                          // find the sub-group of the active project
                          const activeProject = roadmap?.projects?.find(
                            (p) => p._id === activeId
                          );
                          const overProject = roadmap.projects?.find(
                            (p) => p._id === overId
                          );
                          if (!activeProject) return;
                          if (!overProject) return;

                          if (!roadmap.projects) return;

                          // const isBelowOverItem =
                          //   over &&
                          //   active.rect.current.translated &&
                          //   active.rect.current.translated.top >
                          //     over.rect.top + over.rect.height;

                          // if (active.rect.current.translated) {
                          //   console.log(active.rect.current.translated.top);
                          //   console.log(over.rect.top + over.rect.height);
                          //   console.log(isBelowOverItem);
                          // }

                          let newProjectOrder = arrayMove(
                            roadmap.projectOrder,
                            roadmap.projectOrder.findIndex(
                              (p) => p._id === activeProject._id
                            ),
                            roadmap.projects.findIndex(
                              (p) => p._id === overProject._id
                            )
                          );
                          newProjectOrder = newProjectOrder.map((p) =>
                            p._id === activeProject._id
                              ? { ...p, stageId: overProject.roadmapStageId }
                              : p
                          );
                          let newProjects = arrayMove(
                            roadmap.projects,
                            roadmap.projects.findIndex(
                              (p) => p._id === activeProject._id
                            ),
                            roadmap.projects.findIndex(
                              (p) => p._id === overProject._id
                            )
                          );
                          newProjects = newProjects.map((p) =>
                            p._id === activeProject._id
                              ? {
                                  ...p,
                                  roadmapStageId: overProject.roadmapStageId,
                                }
                              : p
                          );
                          setRoadmap((prev) => {
                            if (!prev) return prev;
                            return {
                              ...prev,
                              projectOrder: newProjectOrder,
                              projects: newProjects,
                            };
                          });
                          updateRoadmapMutation.mutate({
                            _id: String(projectGroupId),
                            projectOrder: newProjectOrder,
                          });
                        } else if (isActiveProject && isOverSubGroup) {
                          if (!roadmap.projects) return;
                          // if the active project is dragged over a different sub-group
                          const activeProject = roadmap?.projects?.find(
                            (p) => p._id === activeId
                          );
                          const activeProjectIndex =
                            roadmap.projectOrder.findIndex(
                              (p) => p._id === activeId
                            );
                          if (!activeProject || activeProjectIndex === -1)
                            return;
                          const overSubGroup = roadmap.stages.find(
                            (s) => s._id === overId
                          );
                          if (!overSubGroup) return;

                          // find index of first project in the over sub-group
                          const firstProjectIndex =
                            roadmap.projectOrder.findIndex(
                              (p) => p.stageId === overId
                            );
                          if (firstProjectIndex === -1) {
                            const newProjectOrder = roadmap.projectOrder.map(
                              (p) =>
                                p._id === activeId
                                  ? { ...p, stageId: overSubGroup._id }
                                  : p
                            );
                            const newProjects = roadmap.projects.map((p) =>
                              p._id === activeId
                                ? { ...p, roadmapStageId: overSubGroup._id }
                                : p
                            );
                            setRoadmap((prev) => {
                              if (!prev) return prev;
                              return {
                                ...prev,
                                projectOrder: newProjectOrder,
                                projects: newProjects,
                              };
                            });
                            updateRoadmapMutation.mutate({
                              _id: String(projectGroupId),
                              projectOrder: newProjectOrder,
                            });
                          } else {
                            let newProjectOrder = arrayMove(
                              roadmap.projectOrder,
                              activeProjectIndex,
                              firstProjectIndex
                            );
                            newProjectOrder = newProjectOrder.map((p) =>
                              p._id === activeId
                                ? { ...p, stageId: overSubGroup._id }
                                : p
                            );
                            let newProjects = arrayMove(
                              roadmap.projects,
                              activeProjectIndex,
                              firstProjectIndex
                            );
                            newProjects = newProjects.map((p) =>
                              p._id === activeId
                                ? { ...p, roadmapStageId: overSubGroup._id }
                                : p
                            );
                            setRoadmap((prev) => {
                              if (!prev) return prev;
                              return {
                                ...prev,
                                projectOrder: newProjectOrder,
                                projects: newProjects,
                              };
                            });
                            updateRoadmapMutation.mutate({
                              _id: String(projectGroupId),
                              projectOrder: newProjectOrder,
                            });
                          }
                          // find index of the active project

                          // move the active project to the over sub-group
                        }
                      }}
                    >
                      <SortableContext
                        items={subGroupIds}
                        strategy={horizontalListSortingStrategy}
                      >
                        {roadmap.stages.map((subGroup) => (
                          <SubGroup
                            key={subGroup._id}
                            subGroupId={subGroup._id}
                            group={roadmap}
                            roadmap={roadmap}
                          />
                        ))}
                      </SortableContext>

                      {createPortal(
                        <DragOverlay>
                          {activeGroup && (
                            <SubGroup
                              subGroupId={activeGroup}
                              group={roadmap}
                              roadmap={roadmap}
                            />
                          )}
                          {activeProject && (
                            <ProjectCardOverlay
                              project={roadmap.projects?.find(
                                (p) => p._id === activeProject
                              )}
                              roadmap={roadmap}
                            />
                          )}
                        </DragOverlay>,
                        document.body
                      )}
                    </DndContext>
                    {/* {roadmap.unassignedProjects.length > 0 && (
                    <ProjectsGroup
                      projects={roadmap.unassignedProjects}
                      title="Unassigned"
                      roadmapStageId=""
                      customerId={roadmap.customerId}
                      allProjects={[
                        ...roadmap.stages.map((s) => s.projects).flat(),
                        ...roadmap.unassignedProjects,
                      ]}
                    />
                  )} */}

                    <div className="pr-8 pl-3">
                      <Button
                        variant={"secondary"}
                        className="h-10 w-full min-w-[312px] border-dashed"
                        onClick={() => {
                          setNewStageDialogOpen(true);
                        }}
                      >
                        Add Sub-Group
                      </Button>
                    </div>
                  </div>
                </TabsContent>
              </div>
            </Tabs>
          ) : (
            <div className="mt-[20vh] flex items-center justify-center">
              <div className="flex max-w-md flex-col space-y-1.5 rounded-md border p-8 shadow-md">
                <div className="text-xl font-medium leading-none">
                  Get started
                </div>
                <div className="space-y-3 pb-3 pt-3.5">
                  {/* <div className="text-left font-light text-gray-500">
              Begin by adding the projects you want to group together.
                Project groups are made up of{" "}
                <span className="font-normal text-gray-800">sub-groups</span>.
                Sub-groups are made up of{" "}
                <span className="font-normal text-gray-800">projects</span>.
                Begin by creating your first sub-group.
              </div> */}
                  <div className="text-left font-light text-gray-500">
                    Begin by adding the projects you want to group together.
                  </div>

                  <div className="text-left font-light text-gray-500">
                    If you want to create structure between the projects in the
                    group, you can create sub-groups too.
                  </div>
                </div>
                <div className="flex space-x-3 pt-2.5">
                  <Button
                    onClick={() => {
                      // setNewStageDialogOpen(true);
                      setAddingProjectsDialogOpen(true);
                    }}
                  >
                    Add Projects
                  </Button>
                  <Button
                    variant={"secondary"}
                    onClick={() => {
                      setNewStageDialogOpen(true);
                    }}
                  >
                    Create Sub-Group
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="mt-[30vh]">
          <Loading />
        </div>
      )}
      <Dialog open={newStageDialogOpen} onOpenChange={setNewStageDialogOpen}>
        {/* <ModalHeading title="New Task" /> */}
        <DialogContent
          className={clsx(
            "top-[20%] w-full max-w-lg translate-y-0 data-[state=open]:slide-in-from-top-8"
          )}
        >
          <DialogHeader>
            <DialogTitle>New Sub-Group</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="space-y-1">
              <InputHeading heading="Name" />
              <TextInput
                className="max-w-[300px]"
                value={newStageName}
                onChange={(e) => {
                  setNewStageName(e.target.value);
                }}
              />
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button
                disabled={newStageName.trim() === ""}
                onClick={() => {
                  // to do
                  if (projectGroupId)
                    createRoadmapStageMutation.mutate({
                      roadmapId: String(projectGroupId),
                      name: newStageName,
                    });
                }}
              >
                Create
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      {!!roadmap && (
        <AddProjectsDialog
          open={addingProjectsDialogOpen}
          setOpen={setAddingProjectsDialogOpen}
          roadmap={roadmap}
        />
      )}
    </div>
  );
}

type SubGroup = {
  _id: string;
  name: string;
};

type ProjectGroup = {
  projectOrder: string[];
  projects: Project[];
  _id: string;
  name: string;
  subgroupOrder: string[];
  subgroups: SubGroup[];
};

type Project = {
  _id: string;
  name: string;
  subGroupId: string;
};

function Testing() {
  const [state, setState] = useState<ProjectGroup>({
    projectOrder: ["1", "2", "3"],
    projects: [
      {
        _id: "1",
        name: "Project 1",
        subGroupId: "A",
      },
      {
        _id: "2",
        name: "Project 2",
        subGroupId: "B",
      },
      {
        _id: "3",
        name: "Project 3",
        subGroupId: "B",
      },
    ],
    _id: "123",
    name: "Testing",
    subgroupOrder: ["A", "B"],
    subgroups: [
      {
        _id: "A",
        name: "Subgroup A",
      },
      {
        _id: "B",
        name: "Subgroup B",
      },
    ],
  });

  const [activeSubgroup, setActiveSubgroup] = useState<string | null>(null);
  const [activeProject, setActiveProject] = useState<string | null>(null);
  const overlaySubgroup = state.subgroups.find((s) => s._id === activeSubgroup);
  const overlayProject = state.projects.find((p) => p._id === activeProject);
  return (
    <div className="flex gap-4 px-8">
      <DndContext
        onDragStart={(event) => {
          const { active } = event;
          if (active.data.current?.type === "SUB_GROUP") {
            setActiveSubgroup(String(active.id));
          } else if (active.data.current?.type === "PROJECT") {
            setActiveProject(String(active.id));
          }
        }}
        onDragEnd={(event) => {
          setActiveSubgroup(null);
          setActiveProject(null);
        }}
        onDragOver={(event) => {
          const { active, over } = event;
          if (!over) return;
          const activeId = active.id;
          const overId = over.id;
          if (activeId === overId) return;
          const isActiveProject = active.data.current?.type === "PROJECT";
          const isOverProject = over.data.current?.type === "PROJECT";
          const isOverSubGroup = over.data.current?.type === "SUB_GROUP";

          const activeSubgroup = state.subgroups.find(
            (s) => s._id === activeId
          );
          const overSubgroup = state.subgroups.find((s) => s._id === overId);
          // console.log(activeSubgroup, overSubgroup);
          if (!activeProject) return;

          if (isActiveProject && isOverProject) {
            const activeProjectIndex = state.projectOrder.indexOf(
              String(activeId)
            );
            const overProjectIndex = state.projectOrder.indexOf(String(overId));
            if (activeProjectIndex === -1 || overProjectIndex === -1) return;
            const activeProject = state.projects.find(
              (p) => p._id === activeId
            );
            if (!activeProject) return;
            const overProject = state.projects.find((p) => p._id === overId);
            if (!overProject) return;
            if (activeProject.subGroupId === overProject.subGroupId) {
              const newProjectOrder = arrayMove(
                state.projectOrder,
                activeProjectIndex,
                overProjectIndex
              );
              const newProjects = arrayMove(
                state.projects,
                activeProjectIndex,
                overProjectIndex
              );
              setState((prev) => {
                return {
                  ...prev,
                  projectOrder: newProjectOrder,
                  projects: newProjects,
                };
              });
            } else {
              const isBelowOverItem =
                over &&
                active.rect.current.translated &&
                active.rect.current.translated.top >
                  over.rect.top + over.rect.height;
              const modifier = isBelowOverItem ? 1 : 0;

              const newOverIndex = overProjectIndex + modifier;

              console.log(activeProjectIndex, newOverIndex);
              const newProjectOrder = arrayMove(
                state.projectOrder,
                activeProjectIndex,
                newOverIndex
              );
              let newProjects = arrayMove(
                state.projects,
                activeProjectIndex,
                newOverIndex
              );
              newProjects = newProjects.map((p) => {
                if (p._id === activeId) {
                  return {
                    ...p,
                    subGroupId: overProject.subGroupId,
                  };
                }
                return p;
              });
              setState((prev) => {
                return {
                  ...prev,
                  projectOrder: newProjectOrder,
                  projects: newProjects,
                };
              });
            }
          } else if (isActiveProject && isOverSubGroup) {
            const activeProject = state.projects.find(
              (p) => p._id === activeId
            );
            if (!activeProject) return;
            const overSubGroup = state.subgroups.find((s) => s._id === overId);
            if (!overSubGroup) return;

            setState((prev) => {
              return {
                ...prev,
                projects: prev.projects.map((p) => {
                  if (p._id === activeId) {
                    return {
                      ...p,
                      subGroupId: String(overId),
                    };
                  }
                  return p;
                }),
              };
            });
          }
        }}
      >
        {/* <SortableContext items={state.projectOrder}>
            <div className="flex">
              {state.projects.map((project) => (
                <TestingProject key={project._id} project={project} />
              ))}
            </div>
          </SortableContext> */}
        <SortableContext
          items={state.subgroupOrder}
          // strategy={horizontalListSortingStrategy}
        >
          {state.subgroups.map((subgroup) => (
            <TestingSubGroup
              key={subgroup._id}
              subGroup={subgroup}
              projects={state.projects.filter(
                (p) => p.subGroupId === subgroup._id
              )}
            />
          ))}
        </SortableContext>

        {createPortal(
          <DragOverlay>
            {activeSubgroup && overlaySubgroup && (
              <TestingSubGroup
                subGroup={overlaySubgroup}
                projects={state.projects.filter(
                  (p) => p.subGroupId === activeSubgroup
                )}
              />
            )}
            {activeProject && overlayProject && (
              <TestingProject project={overlayProject} />
            )}
          </DragOverlay>,
          document.body
        )}
      </DndContext>
    </div>
  );
}

function TestingSubGroup({
  subGroup,
  projects,
}: {
  subGroup: SubGroup;
  projects: Project[];
}) {
  const { setNodeRef, listeners, attributes, transform, transition } =
    useSortable({
      id: subGroup._id,
      data: {
        type: "SUB_GROUP",
        subGroup,
      },
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} {...listeners} {...attributes} style={style}>
      <div>{subGroup.name}</div>
      <div>
        <SortableContext
          items={projects
            .filter((p) => p.subGroupId === subGroup._id)
            .map((p) => p._id)}
          strategy={verticalListSortingStrategy}
        >
          {projects
            .filter((p) => p.subGroupId === subGroup._id)
            .map((project) => (
              <TestingProject key={project._id} project={project} />
            ))}
        </SortableContext>
      </div>
    </div>
  );
}

function TestingProject({ project }: { project: Project }) {
  const { setNodeRef, listeners, attributes, transform, transition } =
    useSortable({
      id: project._id,
      data: {
        type: "PROJECT",
        project,
      },
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} {...listeners} {...attributes} style={style}>
      {project.name}
    </div>
  );
}

function ProjectCardOverlay({
  project,
  roadmap,
}: {
  project: RoadmapProjectData | undefined;
  roadmap: RoadmapData;
}) {
  if (!project) return null;
  return <ProjectCard project={project} roadmap={roadmap} />;
}

function AddProjectsDialog({
  open,
  setOpen,
  roadmap,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  roadmap: RoadmapData;
}) {
  const { projectGroupId } = useParams();
  const { data: onboardingProjects } = useGetOnboardingProjects({
    status: ["Canceled", "In Progress", "Completed", "Paused"],
    customerIds: [roadmap.customerId],
  });
  const [newProjects, setNewProjects] = useState<Array<string>>([]);
  const selectedProjects = onboardingProjects?.filter((project) =>
    newProjects.includes(project._id)
  );
  // const updateRoadmapStageMutation = useUpdateRoadmapStage(
  //   String(projectGroupId)
  // );
  const updateRoadmapMutation = useUpdateRoadmap(String(projectGroupId));

  useEffect(() => {
    if (updateRoadmapMutation.isSuccess) {
      setOpen(false);
    }
  }, [updateRoadmapMutation.isSuccess]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {/* <ModalHeading title="New Task" /> */}
      <DialogContent
        className={clsx(
          "top-[20%] w-full max-w-lg translate-y-0 data-[state=open]:slide-in-from-top-8"
        )}
      >
        <DialogHeader>
          <DialogTitle>Add Projects</DialogTitle>
        </DialogHeader>
        <div className="py-4">
          <Select
            // value={newProject}
            value={undefined}
            onValueChange={(value) => {
              if (newProjects.includes(value)) {
                setNewProjects(newProjects.filter((p) => p !== value));
              } else {
                setNewProjects([...newProjects, value]);
              }
            }}
          >
            <SelectTrigger className="w-72 text-gray-400/80">
              Select project to add
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {onboardingProjects
                  ?.filter(
                    (p) => !selectedProjects?.find((sp) => sp._id === p._id)
                  )
                  .map((p) => (
                    <SelectItem value={p._id}>{p.name}</SelectItem>
                  ))}
              </SelectGroup>
            </SelectContent>
          </Select>
          {!!selectedProjects?.length && (
            <div className="mt-6 max-w-full divide-y overflow-x-clip">
              <div className="flex items-center pb-1.5">
                <div className="w-2/3">
                  <InputHeading heading="Project" />
                </div>
                <div className="">
                  <InputHeading heading="Created On" />
                </div>
              </div>
              {selectedProjects?.map((project) => (
                <div className="group relative flex max-w-full items-center py-1.5 text-sm text-gray-500">
                  <div className="w-2/3 truncate">{project.name}</div>
                  <div className="flex flex-1 items-center justify-between">
                    <span>
                      {dayjs(project.createdAt).format("DD MMM YYYY")}
                    </span>
                    <button
                      className="right-full rounded-full p-1 text-transparent hover:bg-gray-100 hover:text-gray-600 group-hover:text-gray-400"
                      onClick={() => {
                        setNewProjects(
                          newProjects.filter((p) => p !== project._id)
                        );
                      }}
                    >
                      <Cross2Icon className="h-3 w-3" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <DialogFooter>
          <Button
            disabled={updateRoadmapMutation.isLoading}
            onClick={() => {
              if (!!selectedProjects?.length && !!roadmap) {
                const unassignedStage = roadmap.stages.find(
                  (stage) => stage.isUnassigned
                );
                if (unassignedStage) {
                  updateRoadmapMutation.mutate({
                    _id: String(projectGroupId),
                    projectOrder: selectedProjects.map((p) => {
                      return { _id: p._id, stageId: unassignedStage._id };
                    }),

                    // params: {
                    //   roadmapStageId: unassignedStage._id,
                    //   phaseIds: selectedProjects.map((p) => p._id),
                    // },
                  });
                }
              }
            }}
          >
            {updateRoadmapMutation.isLoading ? "Adding.." : "Add"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function SubGroup({
  subGroupId,
  group,
  roadmap,
}: {
  subGroupId: string;
  group: RoadmapData;
  roadmap: RoadmapData;
}) {
  const { projectGroupId } = useParams();
  const subGroup = group.stages.find((s) => s._id === subGroupId);
  const {
    setNodeRef,
    listeners,
    attributes,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: subGroupId,
    data: {
      type: "SUB_GROUP",
      _id: subGroupId,
    },
    disabled: subGroup?.isUnassigned,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const projectIds =
    roadmap?.projects
      ?.filter((p) => p.roadmapStageId === subGroupId)
      .map((p) => p._id) ?? [];
  const allProjects = roadmap.projects;
  const [newProjectDialogOpen, setNewProjectDialogOpen] = useState(false);
  const [newProject, setNewProject] = useState("");
  const { data: onboardingProjects } = useGetOnboardingProjects({
    status: ["Canceled", "In Progress", "Completed", "Paused"],
    customerIds: [group.customerId],
  });
  const selectedProject = onboardingProjects?.find(
    (project) => project._id === newProject
  );
  const updateRoadmapMutation = useUpdateRoadmap(String(projectGroupId));
  const updateRoadmapStageMutation = useUpdateRoadmapStage(
    String(projectGroupId)
  );

  const deleteRoadmapStageMutation = useDeleteRoadmapStage(
    String(projectGroupId)
  );

  useEffect(() => {
    if (newProjectDialogOpen) {
      setNewProject("");
    }
  }, [newProjectDialogOpen]);

  useEffect(() => {
    if (updateRoadmapMutation.isSuccess) {
      setNewProjectDialogOpen(false);
    }
  }, [updateRoadmapMutation.isSuccess]);

  const options =
    onboardingProjects?.filter(
      (p) => !allProjects?.find((ap) => ap._id === p._id)
    ) ?? [];

  const [open, setOpen] = useState(false);
  const [editingStage, setEditingStage] = useState(false);
  const [deletingStage, setDeletingStage] = useState(false);

  const [name, setName] = useState(subGroup?.name ?? "");

  useEffect(() => {
    if (subGroup?.name) {
      setName(subGroup.name);
    }
  }, [subGroup?.name]);

  return (
    <div
      className="flex shrink-0 cursor-default flex-col overflow-auto pr-3 pl-3"
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <div className={clsx(isDragging ? "opacity-40" : "")}>
        <div className="peer" {...listeners}>
          <div
            className={clsx(
              "group flex h-10 w-[312px] items-center justify-between gap-3 rounded-md px-3",
              !!subGroup?.isUnassigned
                ? "bg-gray-50 text-gray-500"
                : "bg-gray-100"
            )}
          >
            <div className="min-w-0 truncate text-sm font-medium">
              {subGroup?.isUnassigned ? "Unassigned" : subGroup?.name}
            </div>
            <div className="flex items-center space-x-1.5">
              {!subGroup?.isUnassigned && (
                <DropdownMenu
                  open={open}
                  onOpenChange={(isOpen) => {
                    setOpen(isOpen);
                  }}
                >
                  <DropdownMenuTrigger className="focus:outline-none">
                    <div
                      className={clsx(
                        "rounded border px-1.5 py-0.5 text-gray-400 opacity-0 transition-all hover:text-gray-600 group-hover:opacity-100",
                        open
                          ? "border-gray-300 text-gray-600 opacity-100"
                          : "border-transparent"
                      )}
                    >
                      <EllipsisHorizontalIcon className="h-4 w-4" />
                    </div>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent
                    align="end"
                    side="bottom"
                    className="w-40"
                  >
                    <DropdownMenuGroup>
                      <DropdownMenuItem
                        className="py-1"
                        onClick={(e) => {
                          setEditingStage(true);
                          e.stopPropagation();
                        }}
                      >
                        <Pencil2Icon className="mr-2.5 h-3.5 w-3.5" />
                        <span className="text-sm">Edit</span>
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        className="py-1 focus:bg-red-50 focus:text-red-600"
                        onClick={(e) => {
                          setDeletingStage(true);
                          e.stopPropagation();
                        }}
                      >
                        <TrashIcon className="mr-2.5 h-3.5 w-3.5" />
                        <span className="text-sm">Delete</span>
                      </DropdownMenuItem>

                      {/* <DropdownMenuItem>Delete</DropdownMenuItem> */}
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              )}

              <Dialog
                open={newProjectDialogOpen}
                onOpenChange={setNewProjectDialogOpen}
              >
                <DialogTrigger asChild>
                  <Button variant={"secondaryLink"} className="p-0">
                    <PlusIcon className="h-4 w-4" />
                  </Button>
                </DialogTrigger>
                {/* <ModalHeading title="New Task" /> */}
                <DialogContent
                  className={clsx(
                    "top-[20%] w-full max-w-lg translate-y-0 data-[state=open]:slide-in-from-top-8"
                  )}
                >
                  <DialogHeader>
                    <DialogTitle>Add Project to Stage</DialogTitle>
                  </DialogHeader>
                  <div className="grid gap-4 py-4">
                    <div className="space-y-1">
                      <InputHeading heading="Project" />
                      <Select
                        value={newProject}
                        onValueChange={(value) => {
                          setNewProject(value);
                        }}
                        disabled={!options.length}
                      >
                        <SelectTrigger
                          className={clsx(
                            "w-72",
                            selectedProject
                              ? "text-gray-900"
                              : "text-gray-400/80"
                          )}
                        >
                          <span>
                            {selectedProject
                              ? selectedProject.name
                              : "Select Project"}
                          </span>
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            {options.map((p) => (
                              <SelectItem value={p._id}>{p.name}</SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                      {!!onboardingProjects && options.length === 0 && (
                        <div className="text-xs text-red-600">
                          No projects available to add
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <Button
                      disabled={
                        newProject.trim() === "" ||
                        updateRoadmapMutation.isLoading
                      }
                      onClick={() => {
                        // find index of the first project in the sub-group
                        const firstProjectIndex = roadmap?.projects?.findIndex(
                          (p) => p.roadmapStageId === subGroupId
                        );
                        if (firstProjectIndex === undefined) {
                          updateRoadmapMutation.mutate({
                            _id: String(projectGroupId),
                            projectOrder: [
                              { _id: newProject, stageId: subGroupId },
                            ],
                          });
                        } else {
                          // add right before the first project
                          const newProjectOrder = [
                            ...roadmap.projectOrder.slice(0, firstProjectIndex),
                            { _id: newProject, stageId: subGroupId },
                            ...roadmap.projectOrder.slice(firstProjectIndex),
                          ];
                          updateRoadmapMutation.mutate({
                            _id: String(projectGroupId),
                            projectOrder: newProjectOrder,
                          });
                        }
                      }}
                    >
                      {updateRoadmapMutation.isLoading ? "Adding.." : "Add"}
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </div>
        <div className="mt-3 flex flex-1 flex-col gap-3 overflow-auto pb-6">
          <SortableContext
            items={projectIds}
            strategy={verticalListSortingStrategy}
            id={subGroupId}
          >
            {roadmap?.projects
              ?.filter((p) => p.roadmapStageId === subGroupId)
              .map((project) => (
                <ProjectCard
                  key={project._id}
                  project={project}
                  roadmap={roadmap}
                  // setActiveProject={setActiveProject}
                />
              ))}
          </SortableContext>
        </div>
        {/* <div className="absolute right-full pt-2.5 pr-0.5 opacity-0 transition-opacity hover:cursor-grab hover:opacity-100 peer-hover:opacity-100">
        <DragHandleDots2Icon className="h-5 w-5 text-gray-400" />
      </div> */}
      </div>
      <Dialog open={editingStage} onOpenChange={setEditingStage}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Sub-Group</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <div className="space-y-1">
              <InputHeading heading="Name" />
              <TextInput
                className="max-w-[300px]"
                value={name}
                onChange={(e) => {
                  // setNewStageName(e.target.value);
                  setName(e.target.value);
                }}
                onBlur={() => {
                  if (name.trim() === "") {
                    setName(subGroup?.name ?? "");
                  } else {
                    if (name !== subGroup?.name) {
                      updateRoadmapStageMutation.mutate({
                        _id: subGroupId,
                        name,
                      });
                    }
                  }
                }}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={deletingStage} onOpenChange={setDeletingStage}>
        <DialogContent>
          <div className="space-y-6">
            <div className="space-y-1">
              <div>Are you sure you want to delete this stage?</div>
            </div>
            <div className="flex justify-end space-x-3">
              <Button
                variant={"secondary"}
                onClick={() => setDeletingStage(false)}
              >
                Cancel
              </Button>
              <Button
                variant={"destructive"}
                onClick={() => {
                  deleteRoadmapStageMutation.mutate({
                    _id: subGroupId,
                  });
                }}
              >
                {deleteRoadmapStageMutation.isLoading ? "Deleting.." : "Delete"}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function ProjectCard({
  project,
  roadmap,
}: {
  project: RoadmapProjectData;
  roadmap: RoadmapData;
}) {
  const navigate = useNavigate();
  const {
    setNodeRef,
    listeners,
    attributes,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: project._id,
    data: {
      type: "PROJECT",
      project,
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={clsx(
        "group relative w-[312px] cursor-pointer rounded-md border p-4 shadow-sm hover:bg-gray-50",
        isDragging ? "opacity-40" : "",
        open ? "border-gray-300" : "bg-white"

        // project.status === "In Progress" && "bg-blue-50 border-blue-500"
        // !task.dueDate && task.taskerType === "internal" && "border-gray-400"
      )}
      onClick={() => {
        navigate(`/projects/${project._id}/tasks`);
      }}
    >
      <div className="space-y-4">
        <div className="flex items-start justify-between gap-3">
          <div className="text-sm">{project.name}</div>
          <div
            className={clsx(
              "w-fit whitespace-nowrap rounded-full px-2.5 py-1 text-xs",
              project.status === "In Progress" && "bg-blue-100 text-blue-600",
              project.status === "Completed" && "bg-green-100 text-green-600",
              project.status === "Paused" && "bg-gray-100 text-gray-600",
              project.status === "Canceled" && "bg-red-100 text-red-600"
            )}
          >
            {project.status}
          </div>
        </div>
        {/* <div className="h-2.5 w-full rounded-sm bg-gray-100"></div> */}
        <div className="space-y-1.5">
          <div className="flex justify-between text-xs text-gray-400">
            {project.totalTasks > 0 ? (
              <div className="">
                {project.completedTasks}/{project.totalTasks} tasks
              </div>
            ) : (
              <div>No tasks</div>
            )}
            <div className="">{`${
              project.totalTasks > 0
                ? ((project.completedTasks / project.totalTasks) * 100).toFixed(
                    0
                  )
                : 0
            }%`}</div>
          </div>
          <div
            className={clsx(
              "h-2 w-full shrink-0 overflow-hidden rounded-sm",
              project.completedTasks === 0
                ? "border-gray-300 bg-gray-100"
                : "border-green-200 bg-green-200/70"
            )}
          >
            <div
              style={{
                width:
                  (project.totalTasks > 0
                    ? (project.completedTasks / project.totalTasks) * 100
                    : 0) + "%",
              }}
              className="h-full rounded-sm bg-green-500"
            ></div>
          </div>
        </div>
      </div>
      {roadmap && (
        <ProjectCardOptions
          roadmap={roadmap}
          project={project}
          open={open}
          setOpen={setOpen}
        />
      )}
    </div>
  );
}

function ProjectCardOptions({
  roadmap,
  project,
  open,
  setOpen,
}: {
  roadmap: RoadmapData;
  project: RoadmapProjectData;
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const updateRoadmapMutation = useUpdateRoadmap(roadmap._id);
  return (
    <DropdownMenu
      open={open}
      onOpenChange={(isOpen) => {
        setOpen(isOpen);
      }}
    >
      <DropdownMenuTrigger className="absolute top-2 right-2 focus-visible:outline-none">
        <div
          className={clsx(
            "rounded border bg-white px-1.5 py-0.5 text-gray-400 opacity-0 transition-all hover:text-gray-600 hover:!opacity-100 group-hover:opacity-80",
            open && "border-gray-300 text-gray-600 opacity-100"
          )}
        >
          <EllipsisHorizontalIcon className="h-4 w-4" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" side="bottom" className="w-40">
        <DropdownMenuGroup>
          <DropdownMenuItem
            className="py-1 focus:bg-red-50 focus:text-red-600"
            onClick={(e) => {
              updateRoadmapMutation.mutate({
                _id: roadmap._id,
                projectOrder: roadmap.projectOrder.filter(
                  (p) => p._id !== project._id
                ),
              });
              e.stopPropagation();
            }}
          >
            <TrashIcon className="mr-2.5 h-3.5 w-3.5" />
            <span className="text-sm">Delete</span>
          </DropdownMenuItem>

          {/* <DropdownMenuItem>Delete</DropdownMenuItem> */}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
