import { useState } from "react";
import { SettingsData, useUpdateSettings } from "src/api/General/settings";
import Select from "src/components/ui/Selects/Select";

type Props = {
  settings: SettingsData;
};

type FontType = { type: SettingsData["embed"]["fontType"]; name: string };
// type FontType = "sans" | "serif" | "mono";

const fonts: FontType[] = [
  { type: "sans", name: "Sans" },
  { type: "serif", name: "Serif" },
  { type: "mono", name: "Mono" },
];

export const FontType = ({ settings }: Props) => {
  // const [selected, setSelected] = useState<FontType>(settings.embed.fontType);
  const updateSettingsMutation = useUpdateSettings();
  const [selected, setSelected] = useState<FontType>(
    !!settings.embed.fontType
      ? {
          type: settings.embed.fontType,
          name:
            settings.embed.fontType[0].toUpperCase() +
            settings.embed.fontType.substring(1),
        }
      : { type: "sans", name: "Sans" }
  );

  return (
    <div className="space-y-3">
      <div className="min-w-[150px]">
        <Select
          selected={selected}
          onChange={(value) => {
            setSelected(value);
            updateSettingsMutation.mutate({
              update: {
                embed: {
                  fontType: value.type,
                },
              },
            });
          }}
          keyFn={(option) => {
            return option.name ?? "";
          }}
          options={fonts}
          displayFn={(option) => {
            return (
              <div className={`font-${option.type}`}>{option.name}</div> ?? ""
            );
          }}
        />
      </div>
    </div>
  );
};
