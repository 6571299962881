import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Realm from "realm-web";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { Button } from "src/components/ui/button";
import { TextInput } from "src/components/ui/text-input";
import { useRealmApp } from "src/store/RealmApp";
import { z } from "zod";
type Props = {};

const PasswordSchema = z.object({
  password: z
    .string()
    .min(1, "Password is required")
    .min(12, "Password must be at least 12 characters long")
    .max(128, "Password must be at most 128 characters long")
    .regex(
      /(?=.*[A-Z])/,
      "Password must contain at least one uppercase character"
    )
    .regex(
      /(?=.*[!@#$%^&*])/,
      "Password must contain at least one special character: !, @, #, $, %, ^, &, or *"
    ),
  // confirmPassword: z.string().min(8),
});

export default function ExternalUserEmailPasswordSetup({}: Props) {
  const app = useRealmApp();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const snackbarCtx = useSnackBar();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<z.infer<typeof PasswordSchema>>({
    resolver: zodResolver(PasswordSchema),
  });

  async function handleRegistration(email: string, password: string) {
    const emailPasswordUserCredentials = Realm.Credentials.emailPassword(
      email,
      password
    );
    try {
      await app.currentUser.linkCredentials(emailPasswordUserCredentials);
    } catch (err: any) {
      snackbarCtx.showSnackbar("Error with your registration", "error");
    }
    navigate("/");
  }

  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <div className="mx-auto w-fit rounded-md border p-20 shadow-md">
        <img src="/static/symbolLogo.svg" alt="logo" className="mx-auto w-16" />
        <div className="mt-4 text-center text-2xl font-medium">
          Create an account
        </div>
        <div className="mt-12 w-80">
          <form
            className="mx-auto mt-6 max-w-xs space-y-8"
            onSubmit={handleSubmit((data) => {
              console.log(data);
              handleRegistration(
                app.currentUser.customData.email,
                data.password
              );
            })}
          >
            <div className="space-y-1">
              <InputHeading heading="Email" />
              <TextInput
                className="cursor-not-allowed"
                value={app.currentUser.customData.email}
                disabled
              />
            </div>
            <div className="space-y-1">
              <InputHeading heading="Password" />
              <TextInput {...register("password")} type="password" />
              {errors.password && (
                <div className="text-xs text-red-500">
                  {errors.password.message}
                </div>
              )}
            </div>
            <Button className="w-full" type="submit" size={"lg"}>
              Create account
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}
