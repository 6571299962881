import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { Button } from "src/components/ui/Buttons/Button";
import InputHeading from "src/components/ui/Headings/InputHeading";
import TextInput from "src/components/ui/Inputs/Text/TextInput";
import * as yup from "yup";
import { useRealmApp } from "../../../store/RealmApp";
import { ForgotPasswordObj } from "../../../types/Authentication";
import Logo from "../Logo";

const ForgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email must be a valid email address")
    .required("Email is required"),
});

const initialValues: ForgotPasswordObj = {
  email: "",
};

type Props = {};

export const ResetPasswordForm = (props: Props) => {
  const app = useRealmApp();
  let navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const snackbarCtx = useSnackBar();
  const handlePasswordReset = async (email: string) => {
    try {
      const resetDetails = { email: email, password: "######" };
      await app.emailPasswordAuth.callResetPasswordFunction(resetDetails);
      snackbarCtx.showSnackbar("If the email address is registered, a password reset email has been sent", "success");
      navigate("/login");
    } catch (error: any) {
      snackbarCtx.showSnackbar("If the email address is registered, a password reset email has been sent", "success");
      navigate("/login");
      // console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values) => {
      setDisabled(true);
      handlePasswordReset(values.email);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
      <div className="flex flex-col items-center space-y-10 rounded-lg border bg-white py-16 px-24 pb-12 shadow-md">
        <Logo height={80} />
        <div className="space-y-2">
          <h2 className="text-2xl font-medium">Reset password</h2>
        </div>
        <div className="w-full">
          <div className="space-y-2">
            <InputHeading heading="Email" />
            <TextInput
              id="email"
              name="email"
              // label="Email"
              placeholder="richard@piedpiper.com"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </div>
        </div>
        {/* <TextField
          fullWidth
          id="email"
          name="email"
          label="Email"
          placeholder="richard@piedpiper.com"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        /> */}
        <Button
          disabled={disabled}
          size="md"
          type="submit"
          text="Send Verification Email"
          width={"full"}
        />
        <p className="text-center text-sm leading-6 text-gray-500">
          Don't need to reset?{" "}
          <a
            onClick={() => {
              navigate("/login");
            }}
            // href="/login"
            className="cursor-pointer font-semibold text-primary-600 hover:text-primary-main"
          >
            Back to login
          </a>
        </p>
      </div>
    </form>
  );
};
