import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { useRealmApp } from "src/store/RealmApp";

export const useAddHubSpotOAuth = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (req: { code: string }): Promise<{ message: string }> => {
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/addHubspotOauth`,
        {
          code: req.code,
          instanceId: app.currentUser.customData.instanceId.$oid,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${app.currentUser?.accessToken}`,
          },
        }
      );
      // console.log(res);
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error integrating with HubSpot", "error");
    },
    onSuccess: () => {
      snackbarCtx.showSnackbar(
        "Successfully integrated with HubSpot!",
        "success"
      );
      navigate("/settings/hubspot/success");
      // queryClient.invalidateQueries(tableKeys.tables());
      // snackbarCtx.showSnackbar("Successfully duplicated journey template");
    },
  });

  // return useMutation(
  //   buildSetterWithInstanceIdProvided(app, functionName, fieldName),
  //   {
  //     onMutate: async ({
  //       params,
  //     }: {
  //       params: {
  //         code: string;
  //       };
  //     }) => {},
  //     onError: () => {
  //       snackbarCtx.showSnackbar("Error integrating with HubSpot", "error");
  //     },
  //     onSuccess: () => {
  //       snackbarCtx.showSnackbar(
  //         "Successfully integrated with HubSpot!",
  //         "success"
  //       );
  //       navigate("/settings/hubspot/success");
  //     },
  //   }
  // );
};
