import React, { createContext, useContext, useReducer } from "react";
import { TaskerType } from "src/api/Services/Journeys/journeyTemplates";

type Action =
  | { type: "VIEW_SHOW_SUBTASKS_UPDATE"; showSubTasks: boolean }
  | { type: "VIEW_TASKER_TYPE_UPDATE"; taskerType: TaskerType | "all" };

type Dispatch = (action: Action) => void;

type State = {
  view: {
    showSubTasks: boolean;
    responsibility: TaskerType | "all";
  };
};

const JourneyTemplateContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function journeyTemplateReducer(state: State, action: Action): State {
  switch (action.type) {
    case "VIEW_SHOW_SUBTASKS_UPDATE": {
      return {
        ...state,
        view: { ...state.view, showSubTasks: action.showSubTasks },
      };
    }
    case "VIEW_TASKER_TYPE_UPDATE": {
      return {
        ...state,
        view: { ...state.view, responsibility: action.taskerType },
      };
    }
    default:
      return state;
  }
}

type Props = {
  children: React.ReactNode;
  showSubTasks?: boolean;
};

export const JourneyTemplateContextProvider = ({
  showSubTasks,
  children,
}: Props) => {
  const [state, dispatch] = useReducer(journeyTemplateReducer, {
    view: {
      showSubTasks: showSubTasks || false,
      responsibility: "all",
    },
  });
  const value = { state, dispatch };

  return (
    <JourneyTemplateContext.Provider value={value}>
      {children}
    </JourneyTemplateContext.Provider>
  );
};

export const useJourneyTemplate = () => {
  const context = useContext(JourneyTemplateContext);
  if (context === undefined) {
    throw new Error(
      "useJourneyTemplate must be used within a JourneyTemplateProvider"
    );
  }
  return context;
};
