import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { Button } from "src/components/ui/Buttons/Button";
import InputHeading from "src/components/ui/Headings/InputHeading";
import TextInput from "src/components/ui/Inputs/Text/TextInput";
import * as yup from "yup";
import { useRealmApp } from "../../../store/RealmApp";
import { PasswordResetObj } from "../../../types/Authentication";
import Logo from "../Logo";

const PasswordResetSchema = yup.object().shape({
  password: yup
    .string()
    .min(12, "Too Short!")
    .max(128, "Too Long!")
    .matches(/(?=.*[0-9])/, "Password must contain at least one number")
    .matches(
      /(?=.*[A-Z])/,
      "Password must contain at least one uppercase character"
    )
    .matches(
      /(?=.*[!@#$%^&*])/,
      "Password must contain at least one special character: !, @, #, $, %, ^, &, or *"
    )
    .required("Password is required"),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const initialValues: PasswordResetObj = {
  password: "",
  passwordConfirmation: "",
};

type Props = {
  token: string;
  tokenId: string;
};

export const NewPasswordForm = ({ token, tokenId }: Props) => {
  // const registrationCtx = useContext(RegistrationContext);
  const app = useRealmApp();
  let navigate = useNavigate();
  const snackbarCtx = useSnackBar();
  const resetPassword = async (password: string) => {
    const resetDetails = {
      token: token,
      tokenId: tokenId,
      password: password,
    };
    try {
      app.emailPasswordAuth.resetPassword(resetDetails).then((result: any) => {
        snackbarCtx.showSnackbar("Password successfully reset!", "success");
        navigate(`/login`);
      });
    } catch (err) {
      // console.log(err);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: PasswordResetSchema,
    onSubmit: (values) => {
      resetPassword(values.password);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
      <div className="flex flex-col items-center space-y-10 rounded-lg border bg-white py-16 px-24 shadow-md">
        <Logo height={80} />
        <div className="space-y-2">
          <h2 className="text-2xl font-medium">New password</h2>
        </div>
        <div className="w-full">
          <div className="space-y-2">
            <InputHeading heading="New Password" />
            <TextInput
              autoComplete="new-password"
              id="password"
              name="password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </div>
        </div>
        <div className="w-full">
          <div className="space-y-2">
            <InputHeading heading="Password Confirmation" />
            <TextInput
              autoComplete="new-password"
              id="passwordConfirmation"
              name="passwordConfirmation"
              type="password"
              value={formik.values.passwordConfirmation}
              onChange={formik.handleChange}
              error={
                formik.touched.passwordConfirmation &&
                Boolean(formik.errors.passwordConfirmation)
              }
              helperText={
                formik.touched.passwordConfirmation &&
                formik.errors.passwordConfirmation
              }
            />
          </div>
        </div>
        {/* <TextField
          fullWidth
          autoComplete="new-password"
          id="password"
          name="password"
          label="New Password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        /> */}
        {/* <TextField
          fullWidth
          autoComplete="new-password"
          id="passwordConfirmation"
          name="passwordConfirmation"
          label="Password Confirmation"
          type="password"
          value={formik.values.passwordConfirmation}
          onChange={formik.handleChange}
          error={
            formik.touched.passwordConfirmation &&
            Boolean(formik.errors.passwordConfirmation)
          }
          helperText={
            formik.touched.passwordConfirmation &&
            formik.errors.passwordConfirmation
          }
        /> */}
        {/* <Button
          variant="contained"
          size="large"
          type="submit"
          color="secondary"
          sx={{ width: "40%" }}
        >
          Reset Password
        </Button> */}
        <Button
          // disabled={disabled}
          size="md"
          type="submit"
          text="Reset Password"
          width={"full"}  
        /> 
      </div>
    </form>
  );
};
