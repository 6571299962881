import React, { createContext, useContext, useReducer } from "react";

type Action = {};

type Dispatch = (action: Action) => void;

type State = {
  isInternal: boolean;
  isSharedSpace: boolean;
  isEmbed: boolean;
};

type Props = {
  children: React.ReactNode;
  isInternal: boolean;
  isSharedSpace: boolean;
  isEmbed: boolean;
};

const NewThreadContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function newThreadReducer(state: State, action: Action): State {
  switch (action) {
    default:
      return state;
  }
}

export const NewThreadContextProvider = ({
  children,
  isInternal,
  isSharedSpace,
  isEmbed,
}: Props) => {
  const [state, dispatch] = useReducer(newThreadReducer, {
    isInternal: isInternal,
    isSharedSpace: isSharedSpace,
    isEmbed: isEmbed,
  });

  const value = { state, dispatch };
  return (
    <NewThreadContext.Provider value={value}>
      {children}
    </NewThreadContext.Provider>
  );
};

export const useNewThread = () => {
  const context = useContext(NewThreadContext);
  if (context === undefined) {
    throw new Error("useNewThread must be used within a NewThreadProvider");
  }
  return context;
};
