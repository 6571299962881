import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useAccessToken } from "src/api/useAccessToken";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { useRealmApp } from "src/store/RealmApp";

export const customFieldsKeys = {
  customFields: () => ["custom-fields"] as const,
};

type Option = {
  label: string;
  value: string;
};

export type CustomFieldData =
  | CustomFieldTextData
  | CustomFieldNumberData
  | CustomFieldBooleanData
  | CustomFieldSelectData
  | CustomFieldMultiSelectData
  | CustomFieldDateData
  | CustomFieldCurrencyData;

type CustomFieldTextData = BaseCustomFieldData & {
  type: "text";
  // value: string | null;
};

type CustomFieldNumberData = BaseCustomFieldData & {
  type: "number";
  // value: number | null;
};

export type CustomFieldBooleanData = BaseCustomFieldData & {
  type: "boolean";
  // value: boolean | null;
};

export type CustomFieldSelectData = BaseCustomFieldData & {
  type: "select";
  // value: string | null;
  options: Option[];
};

export type CustomFieldMultiSelectData = BaseCustomFieldData & {
  type: "multi-select";
  // value: string[] | null;
  options: Option[];
};

type CustomFieldDateData = BaseCustomFieldData & {
  type: "date";
  // value: string | null;
};

export type CustomFieldCurrencyData = BaseCustomFieldData & {
  type: "currency";
  // value: number | null;
  currencyCode: CurrencyCode;
};

type BaseCustomFieldData = {
  _id: string;
  name: string;
  description: string;
  objectType: CustomFieldObjectType;
  instanceId: string;
  createdAt: string;
  updatedAt: string;
  crmProperty?: {
    id: string;
    integrationId: "hubspot" | "salesforce";
  };
  mutableDefinition?: boolean;
  seroDefined?: true;
  readableId?: string;
  createdBy?: {
    _id: string;
    name: string;
    email: string;
  };
};

export type CustomFieldValueData =
  | TextCustomFieldValueData
  | NumberCustomFieldValueData
  | DateCustomFieldValueData
  | BooleanCustomFieldValueData
  | SelectCustomFieldValueData
  | MultiSelectCustomFieldValueData
  | CurrencyCustomFieldValueData;

type BaseCustomFieldValueData = {
  _id: string;
  name: string;
  description: string;
  crmProperty?: {
    integrationId: "hubspot" | "salesforce";
    readyOnly: boolean;
  };
};

export type TextCustomFieldValueData = BaseCustomFieldValueData & {
  type: "text";
  value: string | null;
};

export type NumberCustomFieldValueData = BaseCustomFieldValueData & {
  type: "number";
  value: number | null;
};

export type DateCustomFieldValueData = BaseCustomFieldValueData & {
  type: "date";
  value: string | null;
};

export type BooleanCustomFieldValueData = BaseCustomFieldValueData & {
  type: "boolean";
  value: boolean | null;
};

export type SelectCustomFieldValueData = BaseCustomFieldValueData & {
  type: "select";
  value: string | null;
  options: Option[];
};

export type MultiSelectCustomFieldValueData = BaseCustomFieldValueData & {
  type: "multi-select";
  value: string[] | null;
  options: Option[];
};

export type CurrencyCustomFieldValueData = BaseCustomFieldValueData & {
  type: "currency";
  value: number | null;
  currencyCode: CurrencyCode;
};

export type CustomFieldType =
  | "text"
  | "number"
  | "date"
  | "boolean"
  | "select"
  | "multi-select"
  | "currency";

export type CustomFieldObjectType = "project" | "customer";

export enum CurrencyCode {
  USD = "USD",
  EUR = "EUR",
  GBP = "GBP",
  JPY = "JPY",
  CAD = "CAD",
  INR = "INR",
  MXN = "MXN",
  AUD = "AUD",
}

export type Currency = {
  name: string;
  code: CurrencyCode;
  symbol: string;
};

export const currencies: Currency[] = [
  { name: "US Dollar", code: CurrencyCode.USD, symbol: "$" },
  { name: "Euro", code: CurrencyCode.EUR, symbol: "€" },
  { name: "British Pound", code: CurrencyCode.GBP, symbol: "£" },
  { name: "Japanese Yen", code: CurrencyCode.JPY, symbol: "¥" },
  { name: "Australian Dollar", code: CurrencyCode.AUD, symbol: "A$" },
  { name: "Canadian Dollar", code: CurrencyCode.CAD, symbol: "C$" },
  { name: "Indian Rupee", code: CurrencyCode.INR, symbol: "₹" },
  { name: "Mexican Peso", code: CurrencyCode.MXN, symbol: "$" }, // Note: symbol is the same as USD but context usually differentiates them
];

export const useGetCustomFields = (objectType: CustomFieldObjectType) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    customFieldsKeys.customFields(),
    async (): Promise<CustomFieldData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/customFields`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            instanceId: app.currentUser.customData.instanceId.$oid,
            objectType,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};

export const useCreateCustomField = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (customField: {
      name: string;
      description: string;
      type: CustomFieldType;
      options?: Option[];
      objectType: CustomFieldObjectType;
      currencyCode?: CurrencyCode;
    }): Promise<{ message: string; phaseId: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/customField`,
        {
          instanceId: app.currentUser.customData.instanceId.$oid,
          ...customField,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error creating custom field", "error");
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(customFieldsKeys.customFields());
    },
  });
};
