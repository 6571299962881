import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChevronUpDownIcon,
  RectangleStackIcon,
} from "@heroicons/react/24/outline";
import { CheckIcon, Half2Icon } from "@radix-ui/react-icons";
import {
  Column,
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { BarChart, Card, EventProps, Text, Title } from "@tremor/react";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  PipelineProjectStageData,
  ProjectDurationInStageData,
  useGetPipelineStagesForProjectTemplate,
  useGetProjectDurationsInStage,
} from "src/api/Analytics/pipeline-review";
import { useGetJourneyTemplates } from "src/api/Services/Tasks/journeys";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { Breadcrumbs } from "src/components/ui/Navigation/Breadcrumbs";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "src/components/ui/dialog";
import { PageHeading } from "src/components/ui/heading";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "src/components/ui/hover-card";
import { Loading } from "src/components/ui/loading";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { getTimeDifference } from "src/utils/functions/dateTime";
import {
  PipelineReviewContextProvider,
  usePipelineReviewContext,
} from "./pipeline-review-context-provider";

export default function PipelineReview() {
  return (
    <>
      <div className="space-y-2 overflow-auto px-8 py-6">
        <Breadcrumbs
          breadcrumbs={[
            { title: "Analytics", route: "/analytics" },

            {
              title: "Guides",
              route: "/analytics/guides",
            },
            {
              title: "Pipeline Review",
            },
          ]}
        />
        <PageHeading heading={"Pipeline Review"} />
      </div>
      <PipelineReviewContextProvider>
        <div className="overflow-auto">
          <div className="flex items-center space-x-2 px-8">
            <ProjectTemplateSelect />
            {/* <div>Project Start Date</div> */}
          </div>
          <Charts />
        </div>
      </PipelineReviewContextProvider>
    </>
  );
}

function Charts() {
  const pipelineReviewCtx = usePipelineReviewContext();
  const { journeyTemplates } = useGetJourneyTemplates();
  return (
    <>
      {!!journeyTemplates ? (
        <>
          {!!pipelineReviewCtx.state.template ? (
            <>
              <div className="px-8 pt-6">
                <StageDistribution
                  templateId={pipelineReviewCtx.state.template}
                />
              </div>
              <div className="overflow-auto px-8 py-6">
                <ProjectsDurationsInStages
                  templateId={pipelineReviewCtx.state.template}
                />
              </div>
            </>
          ) : (
            <div></div>
          )}
        </>
      ) : (
        <Loading className="mt-20" />
      )}
    </>
  );
}

function durationToTime(duration: number) {
  const seconds = Number((duration / 1000).toFixed(0));
  const minutes = Number((seconds / 60).toFixed(0));
  const hours = Number((minutes / 60).toFixed(0));
  const days = Number((hours / 24).toFixed(0));
  const weeks = Number((days / 7).toFixed(0));
  const months = Number((days / 30).toFixed(0));
  const years = Number((days / 365).toFixed(0));
  // if (years > 0) {
  //   return `${years} year${years > 1 ? "s" : ""}`;
  // }
  // if (months > 0) {
  //   return `${months} month${months > 1 ? "s" : ""}`;
  // }
  // if (weeks > 0) {
  //   return `${weeks} week${weeks > 1 ? "s" : ""}`;
  // }
  if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""}`;
  }
  if (hours > 0) {
    return `${hours} hr${hours > 1 ? "s" : ""}`;
  }
  if (minutes > 0) {
    return `${minutes} min${minutes > 1 ? "s" : ""}`;
  }
  if (seconds > 0) {
    return `${seconds} sec${seconds > 1 ? "s" : ""}`;
  }
}

function ProjectsDurationsInStages({ templateId }: { templateId: string }) {
  const { data: projects } = useGetProjectDurationsInStage(templateId);
  const navigate = useNavigate();
  const [isViewingDelta, setIsViewingDelta] = useState(false);
  return (
    <Card className="max-w-fit overflow-auto px-0 pb-0">
      <div className="px-6 pb-6">
        <div className="flex justify-between gap-4">
          <div>
            <Title className="pb-1.5 leading-4">Stage Breakdown</Title>
            <Text>
              Understand the duration of projects in each stage of the pipeline.
            </Text>
          </div>
          {/* <div>
            <Button
              variant={"secondary"}
              onClick={() => {
                setIsViewingDelta(!isViewingDelta);
              }}
            >
              <Half2Icon className="mr-2 h-5 w-5" />
              {isViewingDelta ? (
                <span>See present</span>
              ) : (
                <span>Compare to the past</span>
              )}
            </Button>
          </div> */}
        </div>
      </div>
      {!!projects && <ProjectsTable projects={projects} />}
    </Card>
  );
}

const columns: ColumnDef<ProjectDurationInStageData>[] = [
  {
    id: "name",
    header: "Project",
    cell: ({ row }) => row.original.name,
  },
  {
    id: "daysActive",
    header: ({ column }) => (
      <div className="flex items-center space-x-1">
        <span>Days Active</span>
        <SortToggle column={column} />
      </div>
    ),
    accessorFn: ({ createdAt }) => {
      const now = new Date();
      const diff = now.getTime() - new Date(createdAt).getTime();
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      return days;
    },
    cell: ({ row }) => {
      const createdAt = new Date(row.original.createdAt);
      const now = new Date();
      const diff = now.getTime() - createdAt.getTime();
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      return days;
    },
  },
  {
    id: "progress",
    header: ({ column }) => (
      <div className="flex items-center space-x-1">
        <span>Progress</span>
        <SortToggle column={column} />
      </div>
    ),
    cell: ({ row }) => {
      const totalTasks = row.original.stages.reduce(
        (acc, s) => acc + s.totalTasks,
        0
      );
      const completedTasks = row.original.stages.reduce(
        (acc, s) => acc + s.completedTasks,
        0
      );
      return (
        <>
          {totalTasks > 0 ? (
            <div className="space-y-">
              <div className="flex items-center space-x-3">
                <div
                  className={clsx(
                    "h-1.5 w-20 shrink-0 overflow-hidden rounded-sm",
                    completedTasks === 0
                      ? "border-gray-300 bg-gray-200/70"
                      : "border-green-200 bg-green-200/70"
                  )}
                >
                  <div
                    style={{ width: (completedTasks / totalTasks) * 100 + "%" }}
                    className="h-full rounded-sm bg-green-500"
                  ></div>
                </div>
                <div className="text-sm font-semibold text-gray-600">
                  {((completedTasks / totalTasks) * 100).toFixed(0) + "%"}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-xs text-gray-400">{`${completedTasks}/${totalTasks} tasks`}</div>
              </div>
            </div>
          ) : (
            <div className="text-sm text-gray-400">No tasks</div>
          )}
        </>
      );
    },
    accessorFn: ({ stages }) => {
      const totalTasks = stages.reduce((acc, s) => acc + s.totalTasks, 0);
      const completedTasks = stages.reduce(
        (acc, s) => acc + s.completedTasks,
        0
      );
      const progress = Math.round((completedTasks / totalTasks) * 100).toFixed(
        0
      );
      return progress;
    },
  },
  {
    id: "progessDelta",
    header: ({ column }) => (
      <div className="flex items-center space-x-1">
        <div>
          <div>Progress</div>
          <div className="lowercase">(vs 1 week ago)</div>
          {/* <div className="text-xs">since 1 week ago</div> */}
        </div>
        <SortToggle column={column} />
      </div>
    ),
    cell: ({ row }) => {
      const currentTotalTasks = row.original.totalTasks
      const currentCompletedTasks = row.original.completedTasks
      const currentProgress = Math.round(
        (currentCompletedTasks / currentTotalTasks) * 100
      );
      const previousTotalTasks = row.original.previousTotalTasks
      const previousCompletedTasks = row.original.previousCompletedTasks

      // if previous data is not present (could be 0)
      if (!previousTotalTasks) {
        return (
          <div className="text-sm text-gray-400">N/A</div>
        )
      }


      const previousProgress = Math.round(
        (previousCompletedTasks / previousTotalTasks) * 100
      );
      const delta = currentProgress - previousProgress;
      return (
        <div className="">
          <div
            className={clsx(
              "flex items-center space-x-1",
              delta > 0 ? "text-green-500" : "text-red-500"
            )}
          >
            {delta > 0 ? (
              <ArrowUpIcon className="h-4 w-4" />
            ) : delta < 0 ?(
              <ArrowDownIcon className="h-4 w-4" />
            ) : 
              <ChevronUpDownIcon viewBox="0 0 24 16" className="h-5 w-5 rotate-90 -mr-1.5" />
            }
            <span>{Math.abs(delta)}%</span>
          </div>
          {/* <div className="text-xs text-gray-500">
            {currentProgress}% vs {previousProgress}%
          </div> */}
        </div>
      );


      // return (
      //   <>
      //     {totalTasks > 0 ? (
      //       <div className="space-y-">
      //         <div className="flex items-center space-x-3">
      //           <div
      //             className={clsx(
      //               "h-1.5 w-20 shrink-0 overflow-hidden rounded-sm",
      //               completedTasks === 0
      //                 ? "border-gray-300 bg-gray-200/70"
      //                 : "border-green-200 bg-green-200/70"
      //             )}
      //           >
      //             <div
      //               style={{ width: (completedTasks / totalTasks) * 100 + "%" }}
      //               className="h-full rounded-sm bg-green-500"
      //             ></div>
      //           </div>
      //           <div className="text-sm font-semibold text-gray-600">
      //             {((completedTasks / totalTasks) * 100).toFixed(0) + "%"}
      //           </div>
      //         </div>
      //         <div className="flex justify-between">
      //           <div className="text-xs text-gray-400">{`${completedTasks}/${totalTasks} tasks`}</div>
      //         </div>
      //       </div>
      //     ) : (
      //       <div className="text-sm text-gray-400">No tasks</div>
      //     )}
      //   </>
      // );
    },
    accessorFn: ({ previousTotalTasks, previousCompletedTasks, totalTasks, completedTasks }) => {
      const currentProgress = Math.round(
        (completedTasks / totalTasks) * 100
      );

      // if previous data is not present it should always rank lowest
      if (!previousTotalTasks) {
        return -100;
      }

      const previousProgress = Math.round(
        (previousCompletedTasks / previousTotalTasks) * 100
      );
      const delta = currentProgress - previousProgress;
      
      return delta;
    },
  },
  {
    id: "forecast",
    header: ({ column }) => (
      <div className="flex items-center space-x-1">
        <span>Forecast</span>
        <SortToggle column={column} />
      </div>
    ),
    accessorFn: ({ forecastedCompletionDate, plannedCompletionDate }) => {
      const forecastedDate = new Date(forecastedCompletionDate);
      const plannedDate = new Date(plannedCompletionDate);
      const delta = getTimeDifference(forecastedDate, plannedDate);
      // console.log(`${}`)
      return delta.isDate2After
        ? delta.absDifferenceInMs
        : -delta.absDifferenceInMs;
    },
    cell: ({ row }) => {
      const forecastedCompletionDate = new Date(
        row.original.forecastedCompletionDate
      );
      const plannedCompletionDate = new Date(
        row.original.plannedCompletionDate
      );
      const delta = getTimeDifference(
        forecastedCompletionDate,
        plannedCompletionDate
      );
      return (
        <div className="space-y-1">
          <div
            className={clsx(
              "text-sm",
              delta.isDate2After ? "text-green-500" : "text-red-500"
            )}
          >{`${delta.absValue} ${delta.unit} ${
            delta.isDate2After ? "ahead" : "behind"
          }`}</div>
          <div
            className={clsx(
              "text-xs text-gray-500"
              // delta.isDate2After ? "text-green-500" : "text-red-500"
            )}
          >
            <span>{dayjs(plannedCompletionDate).format("DD MMM")}</span>
            <span className="text-gray-400"> vs </span>
            <span>{dayjs(forecastedCompletionDate).format("DD MMM")}</span>
          </div>
        </div>
      );
    },
  },
  {
    id: "stages",
    header: "Stages",
    cell: ({ row }) => (
      <div className="flex items-center gap-2">
        {row.original.stages.map((s) => (
          <HoverCard openDelay={400} closeDelay={0}>
            <HoverCardTrigger asChild>
              <div
                key={s._id}
                className={clsx(
                  "h-14 w-40 space-y-1 rounded p-3 py-2",
                  !s.actualDuration
                    ? "bg-gray-100 group-hover:bg-gray-200"
                    : s.actualDuration > s.plannedDuration
                    ? "border-red-500 bg-red-100 group-hover:bg-red-200"
                    : "border-green-500 bg-green-100 group-hover:bg-green-200"
                )}
              >
                <div className="flex items-center">
                  {s.completedTasks === s.totalTasks && s.totalTasks > 0 && (
                    <CheckIcon
                      className={clsx(
                        "h-5 w-5 shrink-0",
                        s.actualDuration > s.plannedDuration
                          ? "text-red-500"
                          : "text-green-500"
                      )}
                      viewBox="3 0 16 16"
                    />
                  )}
                  <div className="truncate text-sm font-medium">{s.name}</div>
                </div>
                <div className="flex justify-between">
                  <div className="text-xs">
                    {durationToTime(s.actualDuration)}
                  </div>
                  <div className="text-xs">
                    {durationToTime(s.plannedDuration)}
                  </div>
                </div>
              </div>
            </HoverCardTrigger>
            <HoverCardContent className="py-3">
              <div className="space-y-3">
                <div className="font-medium">{s.name}</div>
                <div className="flex w-52 justify-between gap-3">
                  <div className="flex-1 text-sm">
                    <div>Actual</div>
                    <div className="text-xs text-gray-500">
                      {s.actualDuration
                        ? `${durationToTime(s.actualDuration)}`
                        : "-"}
                    </div>
                  </div>
                  <div className="flex-1 text-sm">
                    <div>Target</div>
                    <div className="text-xs text-gray-500">
                      {s.plannedDuration
                        ? `${durationToTime(s.plannedDuration)}`
                        : "-"}
                    </div>
                  </div>
                </div>
              </div>
            </HoverCardContent>
          </HoverCard>
        ))}
      </div>
    ),
  },
];

function SortToggle({
  column,
}: {
  column: Column<ProjectDurationInStageData>;
}) {
  return (
    <button
      className="inline-flex rounded-md px-1 py-1 hover:bg-gray-300/20"
      onClick={() => {
        if (column.getIsSorted() === "asc") {
          column.toggleSorting(true);
        } else {
          column.toggleSorting(false);
        }
      }}
    >
      <ArrowUpIcon
        className={clsx(
          "h-3 w-3 stroke-2 text-gray-300",
          column.getIsSorted() === "asc" && "text-primary-main"
        )}
      />
      <ArrowDownIcon
        className={clsx(
          "h-3 w-3 stroke-2 text-gray-300",
          column.getIsSorted() === "desc" && "text-primary-main"
        )}
        onClick={() => {
          column.toggleSorting(true);
        }}
      />
    </button>
  );
}

function ProjectsTable({
  projects,
}: {
  projects: ProjectDurationInStageData[];
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    data: projects,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });
  const navigate = useNavigate();
  return (
    <div className="flex-1 overflow-auto">
      <div className="max-h-full max-w-full overflow-auto border-t">
        <table
          style={{ borderCollapse: "separate", borderSpacing: 0 }}
          className="min-w-[600px]"
        >
          <thead className="overflow-auto">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    scope="col"
                    className={
                      "sticky top-0 z-10 whitespace-nowrap border-b border-r bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 first:left-0 first:z-20 first:pl-6 last:w-full last:border-r-0"
                    }
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {!projects ? (
              <tr>
                {/* <td className="inset-0 flex items-center justify-center p-4 pl-8"> */}
                <div className="sticky left-0 z-10 p-4 pl-8 text-gray-400">
                  Loading...
                </div>
                {/* </td> */}
              </tr>
            ) : (
              <>
                {projects.length === 0 ? (
                  <tr>
                    <div className="sticky left-0 z-10 whitespace-nowrap p-4 pl-8 text-gray-400">
                      No projects
                    </div>
                    {/* <td className="inset-0 flex items-center justify-center p-4">
                    </td> */}
                  </tr>
                ) : (
                  <>
                    {table.getRowModel().rows.map((row) => (
                      <tr
                        key={row.id}
                        className="group relative rounded-lg hover:cursor-pointer hover:bg-gray-50"
                        onClick={() => {
                          navigate(`/projects/${row.original._id}`);
                        }}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            className={
                              "whitespace-nowrap border-r px-6 py-3 text-sm text-gray-500 first:sticky first:left-0 first:z-10 first:bg-white first:pr-6 first:font-medium first:text-gray-900 last:border-r-0 first:group-hover:bg-gray-50 first:sm:pl-6"
                            }
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function ForecastedVsPlannedDates({
  plannedCompletionDate,
  forecastedCompletionDate,
}: {
  plannedCompletionDate: Date;
  forecastedCompletionDate: Date;
}) {
  const { absValue, unit, isDate2After } = getTimeDifference(
    plannedCompletionDate,
    forecastedCompletionDate
  );

  return (
    <div
      className={clsx(
        "flex h-14 w-32 shrink-0 items-center rounded px-3 text-sm",
        !isDate2After
          ? "bg-green-100 group-hover:bg-green-200"
          : "bg-red-100 group-hover:bg-red-200"
      )}
    >
      <div>
        {absValue} {unit} {!isDate2After ? "ahead" : "behind"}
      </div>
    </div>
  );
}

type StageDistributionChartType = "Count" | "Revenue";

type ChartData = {
  name: string;
  "Projects On Time": number;
  "Projects Late": number;
  projectsOnTime: PipelineProjectStageData["projects"];
  projectsLate: PipelineProjectStageData["projects"];
  "Revenue On Time": number;
  "Revenue Late": number;
};

function StageDistribution({ templateId }: { templateId: string }) {
  const { data: pipelineStages } =
    useGetPipelineStagesForProjectTemplate(templateId);
  const [selectedStage, setSelectedStage] = useState<null | ChartData>(null);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<StageDistributionChartType>("Count");
  const valueFormatter = (number: number) =>
    `${type === "Count" ? "" : "$"}${new Intl.NumberFormat("us")
      .format(number)
      .toString()}`;
  const chartdata: ChartData[] =
    pipelineStages?.map((s) => {
      const onTimeProjects = s.projects.filter((p) => {
        const forecastedCompletionDate = new Date(p.forecastedCompletionDate);
        const plannedCompletionDate = new Date(p.plannedCompletionDate);
        return forecastedCompletionDate <= plannedCompletionDate;
      });
      const lateProjects = s.projects.filter((p) => {
        const forecastedCompletionDate = new Date(p.forecastedCompletionDate);
        const plannedCompletionDate = new Date(p.plannedCompletionDate);
        return forecastedCompletionDate > plannedCompletionDate;
      });
      return {
        name: s.name,
        "Projects On Time": onTimeProjects.length,
        "Projects Late": lateProjects.length,
        projectsOnTime: onTimeProjects,
        projectsLate: lateProjects,
        "Revenue On Time": onTimeProjects.reduce(
          (acc, p) => acc + (p.revenue ?? 0),
          0
        ),
        "Revenue Late": lateProjects.reduce(
          (acc, p) => acc + (p.revenue ?? 0),
          0
        ),
      };
    }) ?? [];
  const navigate = useNavigate();
  return (
    <Card className={clsx("h-fit w-[800px]")}>
      <div className="flex justify-between gap-4">
        <div>
          <Title className="pb-1.5 leading-4">Distribution</Title>
          <Text>
            Understand the distribution of your pipeline across different
            stages.
          </Text>
        </div>
        <StageDistributionChartTypeSelect type={type} setType={setType} />
      </div>
      <BarChart
        className={clsx("mt-6")}
        style={{ height: `${92 + chartdata.length * 28}px` }}
        data={chartdata}
        index="name"
        categories={
          type === "Count"
            ? ["Projects On Time", "Projects Late"]
            : ["Revenue On Time", "Revenue Late"]
        }
        // animationDuration={300}
        // showAnimation={true}
        colors={["green", "red"]}
        valueFormatter={valueFormatter}
        allowDecimals={false}
        yAxisWidth={140}
        layout="vertical"
        stack={true}
        onValueChange={(v) => {
          const stage = v as ChartData & EventProps;
          if (stage.name) {
            setSelectedStage(stage);
            setOpen(true);
          }
        }}
      />
      <Dialog
        open={open}
        onOpenChange={(open) => {
          setOpen(open);
        }}
      >
        <DialogContent
          className={clsx(
            "top-[20%] w-full max-w-5xl translate-y-0 space-y-0 p-0 data-[state=open]:slide-in-from-top-8"
          )}
        >
          <DialogHeader className="p-6 pb-0">
            <DialogTitle>{selectedStage?.name}</DialogTitle>
          </DialogHeader>
          <div className="overflow-auto pb-5">
            <div className="flex items-end justify-between gap-3 border-b px-6 pb-2">
              <InputHeading heading="Project" />
              <div className="flex items-end gap-3">
                <div className="w-[200px]">
                  <InputHeading heading="Customer" />
                </div>
                <div className="w-[100px]">
                  <InputHeading heading="Forecasted End Date" />
                </div>
                <div className="w-[150px]">
                  <InputHeading heading="Delta" />
                </div>
                <div className="w-[100px]">
                  <InputHeading heading="Revenue" />
                </div>
                <div className="w-[100px]">
                  <InputHeading heading="Progress" />
                </div>
              </div>
            </div>

            {!!selectedStage && (
              <div className="max-h-[50vh] overflow-auto">
                {[
                  ...selectedStage.projectsLate,
                  ...selectedStage?.projectsOnTime,
                ].map((p) => (
                  <div
                    className="flex cursor-pointer items-center justify-between gap-3 py-2 px-6 hover:bg-gray-50"
                    key={p._id}
                    onClick={() => {
                      navigate(`/projects/${p._id}`);
                    }}
                  >
                    <div className="w-[500px] truncate text-sm text-gray-700">
                      {p.name}
                    </div>
                    <div className="flex items-center gap-3">
                      <div className="w-[200px] truncate text-sm text-gray-500">
                        {p.customer.name}
                      </div>
                      <div className="w-[100px] truncate text-sm text-gray-500">
                        {dayjs(p.forecastedCompletionDate).format("DD MMM")}
                      </div>
                      <div className="w-[150px] truncate text-sm text-gray-500">
                        <Delta
                          forecastedCompletionDate={
                            new Date(p.forecastedCompletionDate)
                          }
                          plannedCompletionDate={
                            new Date(p.plannedCompletionDate)
                          }
                        />
                      </div>
                      <div className="w-[100px] text-sm text-gray-500">
                        ${p.revenue?.toLocaleString() ?? 0}
                      </div>
                      <div className="flex w-[100px] items-center gap-1 text-sm text-gray-500">
                        {/* <span>{`${p.completedTasks}/${p.totalTasks}`}</span> */}
                        <span>{`${(
                          (p.completedTasks / p.totalTasks) *
                          100
                        ).toFixed(0)}%`}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </Card>
  );
}

function Delta({
  forecastedCompletionDate,
  plannedCompletionDate,
}: {
  forecastedCompletionDate: Date;
  plannedCompletionDate: Date;
}) {
  const delta = getTimeDifference(
    forecastedCompletionDate,
    plannedCompletionDate
  );
  return (
    <div className="space-y-1">
      <div
        className={clsx(
          "text-sm",
          delta.isDate2After ? "text-green-500" : "text-red-500"
        )}
      >{`${delta.absValue} ${delta.unit} ${
        delta.isDate2After ? "ahead" : "behind"
      }`}</div>
    </div>
  );
}

function StageDistributionChartTypeSelect({
  type,
  setType,
}: {
  type: StageDistributionChartType;
  setType: (type: StageDistributionChartType) => void;
}) {
  return (
    <Select
      value={type}
      onValueChange={(value) => {
        setType(value as StageDistributionChartType);
      }}
    >
      <SelectTrigger
        className={`mt-0 h-7 w-fit max-w-full gap-2 bg-white px-2`}
      >
        {/* <PieChartIcon className="h-4 w-4 shrink-0 text-gray-700" /> */}
        <SelectValue className="truncate text-xs">
          {type === "Count" ? (
            <div className="flex items-center space-x-1">
              <div className="px-1">#</div>
              <div>Count</div>
            </div>
          ) : (
            <div className="flex items-center space-x-1">
              <div className="px-1">$</div>
              <div>Revenue</div>
            </div>
          )}
        </SelectValue>
      </SelectTrigger>
      <SelectContent align="end" className="min-w-[120px]">
        <SelectGroup>
          <SelectItem value={"Count"}>
            <div className="flex items-center space-x-1">
              <div className="px-1">#</div>
              <div>Count</div>
            </div>
          </SelectItem>
          <SelectItem value={"Revenue"}>
            <div className="flex items-center space-x-1">
              <div className="px-1">$</div>
              <div>Revenue</div>
            </div>
          </SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

function ProjectTemplateSelect() {
  const { journeyTemplates } = useGetJourneyTemplates();
  const pipelineReviewCtx = usePipelineReviewContext();

  useEffect(() => {
    if (journeyTemplates && !pipelineReviewCtx.state.template) {
      // template id with max runs
      let maxRuns = 0;
      let maxRunsTemplateId = "";
      journeyTemplates.forEach((t) => {
        if (t.numberOfRuns > maxRuns) {
          maxRuns = t.numberOfRuns;
          maxRunsTemplateId = t._id?.toString() ?? "";
        }
      });
      if (maxRunsTemplateId) {
        pipelineReviewCtx.dispatch({
          type: "project_template_update",
          template: maxRunsTemplateId,
        });
      }
    }
  }, [journeyTemplates]);

  return (
    <Select
      value={pipelineReviewCtx.state.template}
      onValueChange={(v) => {
        pipelineReviewCtx.dispatch({
          type: "project_template_update",
          template: v,
        });
      }}
    >
      <SelectTrigger
        className={`h-8 w-fit max-w-full gap-2 bg-white py-0 px-2 focus-visible:border-gray-300 focus-visible:ring-0`}
      >
        <div className="flex h-full items-center gap-2 divide-x">
          <div className="inline-flex items-center space-x-1">
            <RectangleStackIcon className="h-4 w-4" />
            <div>Template</div>
          </div>
          <div className="inline-flex h-full items-center pl-2">
            {!pipelineReviewCtx.state.template ? (
              <span className="text-gray-500">Select a template</span>
            ) : (
              journeyTemplates?.find(
                (t) => t._id?.toString() === pipelineReviewCtx.state.template
              )?.name
            )}
          </div>
        </div>
      </SelectTrigger>
      <SelectContent className="min-w-[120px]">
        <SelectGroup>
          {journeyTemplates
            ?.sort((t1, t2) => t2.numberOfRuns - t1.numberOfRuns)
            .map((t) => (
              <SelectItem
                key={t._id?.toString()}
                value={t._id?.toString() ?? ""}
                disabled={t.numberOfRuns === 0}
              >
                <div className="inline-flex items-center space-x-2">
                  <span
                    className={
                      "mr-3 rounded-full bg-gray-100 py-0.5 px-2.5 text-xs font-medium text-gray-900 data-[state=active]:bg-primary-100 data-[state=active]:text-primary-600 md:inline-block"
                    }
                  >
                    {t.numberOfRuns}
                  </span>
                  <span>{t.name}</span>
                </div>
              </SelectItem>
            ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
