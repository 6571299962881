import { useGetInstance } from "src/api/General/instance";
import { useGetUsers } from "src/api/General/users";
import { UserResponseObj } from "src/types/Services/general";

type Props = {};

const TeamSettings = (props: Props) => {
  const { instance } = useGetInstance();
  const { users } = useGetUsers();
  return (
    <div>
      <div className="flex h-12 items-center border-b border-gray-200/70 px-8 text-sm">
        {instance?.name}
      </div>
      <div className="mx-auto max-w-2xl pt-20">
        <div className="space-y-1 border-b border-gray-200/70 pb-6">
          <div className="text-xl font-medium">Members</div>
          {/* <div className="text-sm font-light text-gray-600">
            Settings for the external view of your projects
          </div> */}
        </div>
        <div className="space-y-12 pt-8">
          <div className="space-y-2.5">
            <div className="space-y-0.5">
              <div className="font-medium text-gray-800">All Members</div>
              <div className="text-sm font-light text-gray-600">
                View all the members of your workspace
              </div>
            </div>
            <div className="rounded-md border border-gray-200 p-6 pb-3 shadow-sm">
              <div className="flex items-center border-b border-gray-200 pb-3 font-normal text-gray-500">
                <div className="flex-1 text-sm leading-none">Name</div>
                <div className="w-72 text-sm leading-none">Email</div>
              </div>
              {users?.map((user) => (
                <UserRow key={user._id.toString()} user={user} />
              ))}
            </div>

            {/* <div className="p-6 rounded shadow-sm bg-gray-50">
              <div className="border-b flex items-center h-7">
                <div className="flex-1 text-gray-500">Name</div>
                <div className="w-72 text-gray-500">Email</div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
    // <Grid
    //   container
    //   alignItems="center"
    //   // justifyContent={"center"}
    //   spacing={3}
    //   // marginTop={1}
    // >
    //   <Grid item xs={12}>
    //   </Grid>
    //   <Grid item xs={12}>
    //     <InvitesSent />
    //   </Grid>
    //   <Grid item xs={12}>
    //   </Grid>
    // </Grid>
  );
};

export default TeamSettings;

function UserRow({ user }: { user: UserResponseObj }) {
  return (
    <div className="flex h-10 items-center border-b border-gray-100 last:border-0">
      <div className="flex-1 truncate text-sm font-medium leading-none">
        {user.name}
      </div>
      <div className="w-72 text-sm leading-none">{user.email}</div>
    </div>
  );
}
