import {
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  CustomFieldValueData,
  useGetCustomFields,
} from "src/api/CustomFields/custom-fields";
import {
  PhaseData,
  useDeletePhase,
  useGetPhase,
  useUpdatePhase,
} from "src/api/Services/SharedSpace/phases";
import { useGetSpaces } from "src/api/Spaces/spaces";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { Modal } from "src/components/ui/Modals/Modal";
import ModalHeading from "src/components/ui/Modals/ModalHeading";
import { Button } from "src/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "src/components/ui/command";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import { TextInput } from "src/components/ui/text-input";
import { SpaceNameAndSymbol } from "src/pages/spaces/spaces";

export default function PhaseSettings() {
  const { phaseId } = useParams();
  const { data: phase } = useGetPhase(String(phaseId));
  const { data: spaces } = useGetSpaces();
  const space = spaces?.find((s) => s._id === phase?.spaceId);
  const navigate = useNavigate();
  const updatePhaseMutation = useUpdatePhase();
  const [isDeleteStageConfirmationOpen, setIsDeleteStageConfirmationOpen] =
    useState(false);
  const [open, setOpen] = useState(false);
  const { data: customFields } = useGetCustomFields("project");
  return (
    <div className="space-y-8">
      <div className="h-12 items-center justify-between space-y-2 border-b border-gray-200/[.65] px-8 sm:flex sm:space-y-0">
        {/* {!!phase ? (
          <PhaseNavigation phase={phase} />
        ) : (
          <div className="h-8 w-40 animate-pulse rounded bg-gray-200" />
        )} */}
        <div className="flex items-center space-x-2 text-sm">
          {space && (
            <SpaceNameAndSymbol color={space.color} name={space.name} />
          )}
          <ChevronRightIcon className="h-3 w-3 text-gray-500" />
          <Link
            className="text-gray-800"
            to={`/spaces/${phase?.spaceId}/projects`}
          >
            Projects
          </Link>
          <ChevronRightIcon className="h-3 w-3 text-gray-500" />
          <Link
            className="text-gray-800"
            to={`/projects/${phase?._id.toString()}`}
          >
            {phase?.name ?? ""}
          </Link>
          <ChevronRightIcon className="h-3 w-3 text-gray-500" />
          <div className="cursor-default text-gray-800">{"Settings"}</div>
        </div>
      </div>
      {/* <div>
        <Breadcrumbs
          breadcrumbs={[
            { title: "Projects", route: "/projects" },
            // {
            //   title: !!phase ? phase.customer?.name : "",
            //   // route: `/shared-spaces/${sharedSpace?._id.toString()}`,
            // },
            {
              title: !!phase ? phase.name : "",
              route: `/projects/${phase?._id.toString()}`,
            },
            {
              title: "Settings",
            },
          ]}
        />
        <div>
          <PageHeading heading={"Settings"} />
        </div>
      </div> */}

      {/* <div className="mx-auto w-full max-w-2xl divide-y pt-12">
        <div className="space-y-6 py-6">
          <label className="text-md w-40 shrink-0 font-medium text-gray-600">
            Actions
          </label>
          <div className="flex items-center">
            <label className="w-40 shrink-0 text-sm text-gray-400">
              Delete
            </label>
            <DeletePhaseButton />
          </div>
        </div>
      </div> */}
      <div className="w-full space-y-14 px-8 pt-14">
        {/* <div className="mx-auto max-w-2xl space-y-2">
          <div className="space-y-6 pb-6">
            <label className="text-lg font-medium text-gray-600">Details</label>
            <div className="space-y-5">
              <div className="flex items-center">
                <label className="w-40 shrink-0 text-sm text-gray-400">
                  Name
                </label>
                {phase && <EditableProjectName phase={phase} />}
              </div>
              <div className="flex">
                <label className="w-40 shrink-0 text-sm text-gray-400">
                  Launch Date
                </label>
                <div className="text-sm text-gray-600">
                  {dayjs(phase?.createdAt).format("MMM DD, YYYY")}
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="mx-auto max-w-2xl space-y-2">
          <div className="">
            <label className="w-40 shrink-0 text-lg font-medium leading-none text-gray-800">
              Details
            </label>
          </div>
          <div className="space-y-6 rounded-md border p-6 shadow-sm">
            <div className="space-y-0.5">
              <InputHeading heading="Name" />
              {phase && <EditableProjectName phase={phase} />}
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-2xl space-y-2">
          <div className="space-y-0.5">
            <label className="w-40 shrink-0 text-lg font-medium leading-none text-gray-800">
              Custom Fields
            </label>
            <div className="pb-1.5 text-sm font-light text-gray-500">
              Custom fields enable you to add additional information to your
              projects.
            </div>
          </div>
          <div className="space-y-6 rounded-md border p-6 shadow-sm">
            <div>
              <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                  <Button variant={"secondary"}>Add Custom Field</Button>
                </PopoverTrigger>
                <PopoverContent
                  className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
                  align="start"
                >
                  <Command>
                    <CommandInput
                      placeholder="Search custom field..."
                      className="h-9"
                    />
                    <CommandEmpty>No custom field found.</CommandEmpty>
                    <CommandGroup>
                      {customFields
                        ?.filter(
                          (cf1) =>
                            !phase?.customFields?.find(
                              (cf2) => cf2._id.toString() === cf1._id
                            ) && cf1.objectType === "project"
                        )
                        .map((customField) => (
                          <CommandItem
                            key={customField._id}
                            value={customField.name}
                            onSelect={() => {
                              if (!phase?._id || !phase) {
                                return;
                              }
                              const newField: CustomFieldValueData | undefined =
                                customField.type === "text"
                                  ? {
                                      type: "text",
                                      _id: customField._id,
                                      value: null,
                                      description: customField.description,
                                      name: customField.name,
                                    }
                                  : customField.type === "number"
                                  ? {
                                      type: "number",
                                      _id: customField._id,
                                      value: null,
                                      description: customField.description,
                                      name: customField.name,
                                    }
                                  : customField.type === "boolean"
                                  ? {
                                      type: "boolean",
                                      _id: customField._id,
                                      value: null,
                                      description: customField.description,
                                      name: customField.name,
                                    }
                                  : customField.type === "select"
                                  ? {
                                      type: "select",
                                      _id: customField._id,
                                      value: null,
                                      description: customField.description,
                                      name: customField.name,
                                      options: customField.options,
                                    }
                                  : customField.type === "currency"
                                  ? {
                                      type: "currency",
                                      _id: customField._id,
                                      currencyCode: customField.currencyCode,
                                      value: null,
                                      description: customField.description,
                                      name: customField.name,
                                    }
                                  : customField.type === "multi-select"
                                  ? {
                                      type: "multi-select",
                                      _id: customField._id,
                                      value: null,
                                      description: customField.description,
                                      name: customField.name,
                                      options: customField.options,
                                    }
                                  : customField.type === "date"
                                  ? {
                                      type: "date",
                                      _id: customField._id,
                                      value: null,
                                      description: customField.description,
                                      name: customField.name,
                                    }
                                  : undefined;
                              if (newField) {
                                updatePhaseMutation.mutate({
                                  phase: {
                                    _id: phase._id,
                                    customFields: [
                                      ...(phase?.customFields?.map((cf) => {
                                        return { _id: cf._id, value: cf.value };
                                      }) ?? []),
                                      {
                                        _id: customField._id,
                                        value: null,
                                      },
                                    ],
                                  },
                                  update: {
                                    _id: phase._id,
                                    customFields: [
                                      ...(phase.customFields ?? []),
                                      newField,
                                    ],
                                  },
                                });
                              }
                              setOpen(false);
                            }}
                            className="gap-2"
                          >
                            <span className="truncate">{customField.name}</span>
                          </CommandItem>
                        ))}
                    </CommandGroup>
                  </Command>
                </PopoverContent>
              </Popover>
            </div>
            {!!phase?.customFields?.length && (
              <div>
                <div className="flex items-center border-b border-gray-100 pb-3 text-gray-500">
                  <div className="flex-1 text-sm leading-none">Name</div>
                  <div className="w-40 text-sm leading-none">Type</div>
                  <div className="w-[30px]"></div>
                </div>
                {phase.customFields.map((customField) => (
                  <ProjectCustomFieldRow
                    customField={customField}
                    phase={phase}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="mx-auto max-w-2xl space-y-2">
          <label className="w-40 shrink-0 text-lg font-medium leading-none text-gray-800">
            Actions
          </label>
          <div className="space-y-6 divide-y rounded-md border p-6 shadow-sm">
            <div className="flex items-center justify-between">
              <div className="space-y-0.5">
                <label className="leading-none text-gray-600">Delete</label>
                <p className="text-sm font-light text-gray-400">
                  Deleting a project is a permanent action
                </p>
              </div>
              <div>{!!phase && <DeletePhaseButton phase={phase} />}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="space-y-4">
    //   <div className="space-y-4">
    //     <h3 className="text-md font-semibold">Journey Actions</h3>
    //     <div className="space-y-2">
    //     </div>
    //   </div>
    // </div>
  );
}

function ProjectCustomFieldRow({
  customField,
  phase,
}: {
  customField: any;
  phase: PhaseData;
}) {
  const [optionsOpen, setOptionsOpen] = useState(false);
  const updatePhaseMutation = useUpdatePhase();
  return (
    <div
      key={customField._id.toString()}
      className="flex h-10 items-center border-b border-gray-100 last:border-0 last:pb-0"
    >
      <div className="flex-1 truncate text-sm font-medium leading-none">
        {customField.name}
      </div>
      <div className="w-40 text-sm leading-none">
        {customField.type.charAt(0).toUpperCase() + customField.type.slice(1)}
      </div>
      <div className="w-[30px]">
        <DropdownMenu
          open={optionsOpen}
          onOpenChange={(isOpen) => {
            setOptionsOpen(isOpen);
          }}
        >
          <DropdownMenuTrigger className="focus:outline-none">
            <div
              className={clsx(
                "rounded px-1.5 py-0.5 text-gray-400 transition-all hover:text-gray-600 group-hover:opacity-100",
                optionsOpen
                  ? "bg-gray-100 text-gray-600 opacity-100"
                  : "border-transparent"
              )}
            >
              <EllipsisHorizontalIcon className="h-4 w-4" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" side="bottom" className="w-40">
            <DropdownMenuGroup>
              <DropdownMenuItem
                className="py-1 focus:bg-red-50 focus:text-red-600"
                onClick={(e) => {
                  e.stopPropagation();
                  if (phase.customFields) {
                    updatePhaseMutation.mutate({
                      phase: {
                        _id: phase._id,
                        customFields: phase.customFields.filter(
                          (cf) =>
                            cf._id.toString() !== customField._id.toString()
                        ),
                      },
                      update: {
                        _id: phase._id,
                        customFields: phase.customFields.filter(
                          (cf) =>
                            cf._id.toString() !== customField._id.toString()
                        ),
                      },
                    });
                  }
                }}
              >
                <TrashIcon className="mr-2.5 h-3.5 w-3.5" />
                <span className="text-sm">Delete</span>
              </DropdownMenuItem>

              {/* <DropdownMenuItem>Delete</DropdownMenuItem> */}
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
}

function LinkProjectButton() {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant={"secondary"} size={"sm"}>
          Link
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Link Project</DialogTitle>
          <DialogDescription>
            Create a link from this project to another project to give easy
            access to projects that are related
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4 py-6">
          <div className="space-y-1">
            <InputHeading heading="Stage" />
            <TextInput />
          </div>
          <div className="space-y-1">
            <InputHeading heading="Project to link to" />
            <TextInput />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

function EditableProjectName({ phase }: { phase: PhaseData }) {
  const [heading, setHeading] = useState(phase.name);
  const updatePhaseMutation = useUpdatePhase();

  useEffect(() => {
    setHeading(phase.name);
  }, [phase.name]);

  return (
    <div className="relative flex-1">
      <input
        className="w-full min-w-0 rounded-md border border-transparent py-1.5 text-sm text-gray-600 outline-none transition-all delay-150
           hover:border-gray-200 hover:px-2.5 hover:shadow-sm focus:border focus:border-primary-main focus:px-2.5 focus:shadow-sm focus:outline-none focus:ring-0"
        value={heading}
        onChange={(e) => {
          setHeading(e.target.value);
        }}
        onBlur={() => {
          if (heading.trim() !== phase.name) {
            updatePhaseMutation.mutate({
              phase: {
                _id: phase._id,
                name: heading,
              },
              update: {
                _id: phase._id,
                name: heading,
              },
            });
          }
        }}
      />
    </div>
  );
}

function DeletePhaseButton({ phase }: { phase: PhaseData }) {
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const deletePhaseMutation = useDeletePhase(phase.spaceId);

  useEffect(() => {
    if (deletePhaseMutation.isSuccess) {
      setIsConfirmingDelete(false);
    }
  }, [deletePhaseMutation.isSuccess]);

  return (
    <>
      <Button
        variant={"destructive"}
        onClick={() => {
          setIsConfirmingDelete(true);
        }}
        size={"sm"}
      >
        Delete
      </Button>
      <Modal
        isOpen={isConfirmingDelete}
        closeModal={() => {
          setIsConfirmingDelete(false);
        }}
      >
        <div className="p-6">
          <ModalHeading
            title="Confirm Project Deletion"
            // subtitle="Are you sure you want to delete this task?"
          />
          <div className="mt-6 space-y-4">
            {phase?.collaborationType === "external" ? (
              <div className="text-sm">
                <span className="font-light text-gray-600">{`Are you sure you want to delete project `}</span>
                <span className="font-medium">{`${phase?.name}`}</span>
                <span className="font-light text-gray-600">{` that is ongoing with `}</span>
                <span className="font-medium">{`${phase?.customer?.name}`}</span>
                <span className="font-light text-gray-600">{`?`}</span>
              </div>
            ) : (
              <div className="text-sm">
                <span className="font-light text-gray-600">{`Are you sure you want to delete project `}</span>
                <span className="font-medium">{`${phase?.name}`}</span>
                <span className="font-light text-gray-600">{`?`}</span>
              </div>
            )}
            {/* <div className="space-y-3">
            {otherTasksToDelete.length > 1 && (
              <ul className="list-disc pl-4">
                {otherTasksToDelete
                  .filter((t) => t._id.toString() !== task?._id?.toString())
                  .map((task, index) => {
                    return (
                      <li key={task._id.toString()}>
                        <div className="text-sm">{task.title}</div>
                      </li>
                    );
                  })}
              </ul>
            )}
          </div> */}
            <div className="flex justify-end space-x-2 pt-2">
              <Button
                onClick={() => {
                  setIsConfirmingDelete(false);
                }}
                variant="secondary"
              >
                Cancel
              </Button>
              <Button
                disabled={deletePhaseMutation.isLoading}
                onClick={() => {
                  deletePhaseMutation.mutate({
                    _id: String(phase._id),
                  });
                }}
                variant={"destructive"}
              >
                {deletePhaseMutation.isLoading ? "Deleting.." : "Delete"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
