import { cva, VariantProps } from "class-variance-authority";
import { ToolTip } from "../ToolTip/ToolTip";

const buttonStyles = cva(
  "inline-flex items-center rounded-md font-medium shadow-sm focus:outline-none disabled:bg-gray-300 disabled:text-white disabled:border-gray-300 disabled:hover:disabled:border-gray-300 truncate",
  {
    variants: {
      color: {
        primary:
          "[--borderColor:theme(colors.primary.main)] [--color:theme(colors.primary.main)] [--hoverColor:theme(colors.primary.600)] [--outlineHoverColor:theme(colors.primary.100)]",
        secondary:
          "[--borderColor:theme(colors.secondary.main)] [--color:theme(colors.secondary.main)] [--hoverColor:theme(colors.primary.600)] [--outlineHoverColor:theme(colors.primary.100)]",
        danger:
          "[--borderColor:theme(colors.red.600)]  [--color:theme(colors.red.600)] [--hoverColor:theme(colors.red.700)] [--outlineHoverColor:theme(colors.red.50)]",
        gray: "[--borderColor:theme(colors.gray.300)] [--color:theme(colors.gray.400)] [--hoverColor:theme(colors.gray.400)] [--outlineHoverColor:theme(colors.gray.50)]",
        embed:
          "[--borderColor:theme(colors.embedPrimary/80)] [--color:theme(colors.embedPrimary/90)] [--hoverColor:theme(colors.embedPrimary)] [--outlineHoverColor:theme(colors.embedPrimary)]",
      },
      fill: {
        solid:
          "bg-[color:var(--color)] text-white hover:bg-[color:var(--hoverColor)]",
        outline:
          "border border-[color:var(--borderColor)] disabled:hover:border-[color:var(--borderColor)] hover:border-[color:var(--hoverColor)] hover:text-[color:var(--hoverColor)] disabled:text-[color:var(--hoverColor)] text-[color:var(--color)] bg-white hover:bg-[color:var(--outlineHoverColor)]",
        none: "hover:text-[color:var(--hoverColor)] text-[color:var(--color)] shadow-none px-0",
      },
      size: {
        xs: "px-2.5 py-1.5 text-xs",
        sm: "px-3 py-2 text-sm",
        md: "px-4 py-2 text-sm",
        lg: "px-4 py-2 text-base",
        xl: "px-6 py-3 text-base",
      },
      ring: {
        true: "focus:ring-2 focus:ring-offset-2 focus:ring-[color:var(--color)]",
        false: "focus:ring-none",
      },
      width: {
        full: "w-full justify-center",
        fit: "w-fit",
      },
    },
    defaultVariants: {
      color: "primary",
      size: "sm",
      fill: "solid",
      ring: false,
      width: "fit",
    },
  }
);

type ButtonProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  text: string;
  disabled?: boolean;
  disabledText?: string;
  leftIcon?: React.ReactNode;
  type?: "button" | "submit" | "reset";
};

export interface Props extends ButtonProps, VariantProps<typeof buttonStyles> {}

export const Button = ({
  text,
  onClick,
  disabled = false,
  color,
  size,
  width,
  fill,
  leftIcon,
  ring,
  type = "button",
  disabledText,
  ...props
}: Props) => {
  return (
    <>
      <ToolTip title={disabled ? disabledText : undefined}>
        <button
          type={type}
          className={buttonStyles({ color, size, fill, ring, width })}
          {...props}
          disabled={disabled}
          onClick={onClick}
        >
          {!!leftIcon && leftIcon}
          {text}
        </button>
      </ToolTip>
    </>
  );
};
