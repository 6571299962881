import React, { createContext, useState } from "react";
import { AppView, DrawerSelection, Services } from "./types";

type Props = {
  children: React.ReactNode;
};


interface LayoutContextObj {
  services: Services;
  updateServices: (services: Services) => void;
  view: AppView;
  setView: (_: AppView) => void
  selected: DrawerSelection;
  setSelected: (_: DrawerSelection) => void;
}

export const LayoutContext = createContext<LayoutContextObj>({
  services: { dashboard: true, feedback: true, sharedspaces: true, tasks: true, journeys: true, customers: true },
  updateServices: (services: Services) => {},
  view: "instance",
  setView: (_) => {},
  selected: "/tasks",
  setSelected: (_: DrawerSelection) => {},
});

const LayoutContextProvider = (props: Props) => {
  const [services, setServices] = useState<Services>({
    dashboard: true,
    feedback: true,
    sharedspaces: true,
    tasks: true,
    journeys: true, 
    customers: false,
  });
  const [view, setView] = useState<AppView>("instance");
  const [selected, setSelected] = useState<DrawerSelection>("/tasks");

  const updateServices = (services: Services) => {
    setServices(services);
  };

  const value = {
    services: services,
    updateServices: updateServices,
    view,
    setView,
    selected,
    setSelected,
  };

  return (
    <LayoutContext.Provider value={value}>
      {props.children}
    </LayoutContext.Provider>
  );
};

export default LayoutContextProvider;
