import clsx from "clsx";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useGetInstance } from "src/api/General/instance";
import { useGetSettings } from "src/api/General/settings";
import { getRGBColor } from "src/utils/functions/embed";
import ChatPanel from "../Chats/chat-panel";
import NewTaskForExternalUser from "../Services/Tasks/NewTaskForExternalUser";
import TaskPanel from "../Services/Tasks/TaskPanel";

export default function ExternalViewSetup() {
  const { data: settings } = useGetSettings();
  const { instance } = useGetInstance();
  const [color, setColor] = useState(`${getRGBColor("#000000", "primary")}`);

  useEffect(() => {
    if (!!settings) {
      setColor(
        `{${getRGBColor(
          settings.embed.primaryColor ? settings.embed.primaryColor : "#000000",
          "primary"
        )}}`
      );
    }
  }, [settings]);

  return (
    <>
      {!!settings && (
        <div
          className={clsx(
            "h-screen overflow-auto",
            !!settings.embed.fontType
              ? settings.embed.fontType === "sans"
                ? "font-sans"
                : settings.embed.fontType === "serif"
                ? "font-serif"
                : "font-mono"
              : "font-sans"
          )}
        >
          <style>
            :root
            {color}
          </style>
          <Outlet />
          <TaskPanel />
          <ChatPanel />
          {!!instance && <NewTaskForExternalUser slug={instance?.slug} />}
        </div>
      )}
    </>
  );
}
