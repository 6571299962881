import { PlusIcon, UsersIcon } from "@heroicons/react/24/outline";
import { ObjectID } from "bson";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { PhaseData, useGetPhase } from "src/api/Services/SharedSpace/phases";
import {
  ThreadData,
  ThreadMessageData,
  useCreateThreadInPhase,
  useCreateThreadMessage,
  useGetPhaseThreads,
  useGetThread,
  useGetThreadMessages,
  useUpdateThread,
} from "src/api/Services/Threads/threads";
import { Avatar } from "src/components/ui/Avatar/Avatar";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "src/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";

import { useGetProjectMembers } from "src/api/Services/Projects/projects";
import { Loading } from "src/components/ui/ApplicationStates/Loading";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { Modal } from "src/components/ui/Modals/Modal";
import ModalHeading from "src/components/ui/Modals/ModalHeading";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import { TextInput } from "src/components/ui/text-input";
import { useRealmApp } from "src/store/RealmApp";
import { UserResponseObj } from "src/types/Services/general";
import { getTimeDifference } from "src/utils/functions/dateTime";
import { NewThreadContextProvider } from "./NewThread/NewThreadContextProvider";
import { ChatMessages } from "./thread-elements/messages";

type Props = {
  threads: ThreadData[];
  isInternal?: boolean;
  isSharedSpace?: boolean;
  isEmbed?: boolean;
};

export const Threads = ({
  // threads,
  isInternal = true,
  isSharedSpace = false,
  isEmbed = false,
}: Props) => {
  const { phaseId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const chatId = searchParams.get("chatId");

  const { data: threads } = useGetPhaseThreads(String(phaseId));
  const { data: phase } = useGetPhase(String(phaseId));

  const [isNewThreadModalOpen, setIsNewThreadModalOpen] = useState(false);

  const [activeThreadId, setActiveThreadId] = useState<string | undefined>(
    !!threads && threads?.length > 0 ? threads[0]._id.toString() : undefined
  );
  const [activeThreadName, setActiveThreadName] = useState("");

  function setActiveThread(threadId: string) {
    setActiveThreadId(threadId);
  }

  useEffect(() => {
    if (!!threads) {
      if (!!chatId) {
        const thread = threads.find((t) => t._id.toString() === chatId);
        if (!!thread) {
          setActiveThreadId(thread._id.toString());
          setActiveThreadName(thread.name);
        }
      } else if (threads.length > 0 && !activeThreadId) {
        setActiveThreadId(threads[0]._id.toString());
        setActiveThreadName(threads[0].name);
      }
    }
  }, [threads, chatId]);

  return (
    <NewThreadContextProvider
      isInternal={isInternal}
      isSharedSpace={isSharedSpace}
      isEmbed={isEmbed}
    >
      {/* {isEmbed || isSharedSpace ? (
          <ButtonTabs tabs={tabs} inEmbed={isEmbed} />
        ) : (
          <BasicTabs tabs={tabs} inEmbed={isEmbed} />
        )} */}
      <div className="flex h-full flex-1 divide-x overflow-y-auto">
        <div className="flex w-80 flex-col overflow-auto">
          <div className="w-full p-6 px-8">
            <Button
              size="lg"
              onClick={() => {
                setIsNewThreadModalOpen(true);
              }}
              className="w-full space-x-2"
              variant={"secondary"}
            >
              <PlusIcon className="h-3 w-3" />
              <span>New Chat</span>
            </Button>
          </div>
          {!!phase && (
            <NewThreadModal
              isNewThreadModalOpen={isNewThreadModalOpen}
              setIsNewThreadModalOpen={setIsNewThreadModalOpen}
              sharedSpaceId={phase.sharedSpaceId}
            />
          )}
          <div className={clsx("flex-1 overflow-auto pb-6")}>
            {/* <InputHeading heading="Active Chats" /> */}
            {phase && (
              <div className="divide-y">
                {threads?.map((thread) => (
                  <ThreadCard
                    phase={phase}
                    thread={thread}
                    setActiveThread={setActiveThread}
                    key={thread._id.toString()}
                    activeThreadId={activeThreadId}
                    setActiveThreadName={setActiveThreadName}
                  />
                ))}
              </div>
            )}
            {/* <div className="space-y-2">
              <InputHeading heading="Task Chats" />
              <div className="space-y-1">
                {threads?.map((thread) => (
                  <ThreadCard
                    thread={thread}
                    setActiveThread={setActiveThread}
                    key={thread._id.toString()}
                    activeThreadId={activeThreadId}
                    setActiveThreadName={setActiveThreadName}
                  />
                ))}
              </div>
            </div> */}
          </div>
        </div>
        <div className="flex flex-1 flex-col">
          {!!threads ? (
            <>
              {!!activeThreadId ? (
                <ActiveThread
                  threadId={activeThreadId}
                  threadName={activeThreadName}
                  isInternal={isInternal}
                />
              ) : (
                <div className="flex h-full flex-col items-center justify-center bg-gray-50">
                  <div className="text-center">
                    <div className="text-lg font-medium text-gray-700">
                      No chat to show
                    </div>
                    <div className="text-gray-500">Create a chat first</div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="flex h-full flex-col items-center justify-center bg-gray-50">
              <Loading />
            </div>
          )}
        </div>
      </div>
    </NewThreadContextProvider>
  );
};

function ThreadCard({
  thread,
  // setActiveThread,
  activeThreadId,
  setActiveThreadName,
  phase,
}: {
  phase: PhaseData;
  thread: ThreadData;
  setActiveThread: (threadId: string) => void;
  activeThreadId?: string;
  setActiveThreadName: (threadName: string) => void;
}) {
  const { data: projectMembers } = useGetProjectMembers(phase._id);
  const updateThreadMutation = useUpdateThread();
  const { absValue, unit } = getTimeDifference(thread.createdAt, new Date());
  const [searchParams, setSearchParams] = useSearchParams();
  // const activeThreadId = searchParams.get("chatId");
  const navigate = useNavigate();
  const location = useLocation();
  const lastActiveToday = dayjs(thread.updatedAt).isSame(dayjs(), "day");
  const lastActiveYesterday = dayjs(thread.updatedAt).isSame(
    dayjs().subtract(1, "day"),
    "day"
  );
  return (
    <div
      className={clsx(
        "cursor-pointer p-2 px-8 text-sm transition-colors hover:bg-gray-100",
        activeThreadId === thread._id.toString() && "bg-gray-100"
      )}
      onClick={() => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("chatId", thread._id.toString());
        navigate({
          pathname: location.pathname,
          search: searchParams.toString(),
        });
        setActiveThreadName(thread.name);
        if (thread.otherDetails?.unreadMessagesCount > 0) {
          updateThreadMutation.mutate({
            update: {
              isRead: true,
              _id: thread._id,
            },
            _id: thread._id,
          });
        }
      }}
    >
      <div className="space-y-0.5">
        <div className="flex items-center justify-between gap-3">
          <div className="truncate text-gray-700">{thread.name}</div>
          <div className="whitespace-nowrap text-xs text-gray-400">
            {lastActiveToday
              ? dayjs(thread.updatedAt).format("h:mm A")
              : lastActiveYesterday
              ? "Yesterday"
              : dayjs(thread.updatedAt).format("MMM DD")}
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div>
            {!projectMembers ? (
              <div className="flex items-center space-x-1">
                <div className="h-6 w-6 animate-pulse rounded bg-gray-200" />
                <div className="h-6 w-6 animate-pulse rounded bg-gray-200" />
              </div>
            ) : (
              <div className="flex items-center space-x-1">
                {thread.userIds.map((userId) => {
                  const internalMember = projectMembers.internal.find(
                    (m) => m._id?.toString() === userId.toString()
                  );
                  const externalMember = projectMembers.external.find(
                    (m) =>
                      m.userType === "fullUser" &&
                      m._id?.toString() === userId.toString()
                  );
                  return (
                    <div
                      key={userId.toString()}
                      className={clsx(
                        "flex h-6 w-6 items-center justify-center rounded border text-xs shadow-sm",
                        internalMember
                          ? "border-gray-300 text-gray-400"
                          : "border-gray-300 bg-gray-200 text-gray-600"
                      )}
                    >
                      {internalMember
                        ? internalMember?.name
                            .toUpperCase()
                            .split(" ")
                            .map((w) => w[0])
                            .join("")
                        : externalMember?.email
                            .toUpperCase()
                            .split(" ")
                            .map((w) => w[0])
                            .join("")}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {!!thread.otherDetails.unreadMessagesCount && (
            <div className="h-5 min-w-[18px] shrink-0 rounded bg-primary-main px-1 py-0.5 text-center text-xs text-white">
              <span className="leading-3">
                {thread.otherDetails?.unreadMessagesCount}
              </span>
            </div>
          )}
        </div>
        {/* <div className="flex items-center justify-between gap-4">
        <div className="min-w-0 truncate">
          <span
            className={clsx(
              "min-w-0 truncate text-sm",
              activeThreadId === thread._id.toString()
                ? "text-black"
                : "text-gray-600"
            )}
          >
            {thread.name}
          </span>
          
        </div>
        {thread.otherDetails?.unreadMessagesCount > 0 && (
          <div className="h-5 min-w-[18px] shrink-0 rounded bg-primary-main px-1 py-0.5 text-center text-xs text-white">
            <span className="leading-3">
              {thread.otherDetails?.unreadMessagesCount}
            </span>
          </div>
        )}
      </div> */}
      </div>
    </div>
  );
}

function NewThreadModal({
  isNewThreadModalOpen,
  setIsNewThreadModalOpen,
  sharedSpaceId,
}: {
  isNewThreadModalOpen: boolean;
  setIsNewThreadModalOpen: (value: boolean) => void;
  sharedSpaceId: string;
}) {
  const app = useRealmApp();
  const { phaseId } = useParams();
  const [subject, setSubject] = useState("");
  const [members, setMembers] = useState<UserResponseObj[]>([]);
  const createThreadInPhaseMutation = useCreateThreadInPhase();

  useEffect(() => {
    if (createThreadInPhaseMutation.isSuccess) {
      setIsNewThreadModalOpen(false);
    }
  }, [createThreadInPhaseMutation.isSuccess]);

  useEffect(() => {
    if (isNewThreadModalOpen) {
      setSubject("");
      setMembers([]);
    }
  }, [isNewThreadModalOpen]);

  return (
    <Modal
      isOpen={isNewThreadModalOpen}
      closeModal={() => {
        setIsNewThreadModalOpen(false);
      }}
    >
      <div className="w-[600px] space-y-6 p-6">
        <ModalHeading title="New Chat" subtitle="Details for your new chat" />
        <div className="space-y-6">
          <div className="w-[300px]">
            <TextInput
              // name="subject"
              // name="text"
              value={subject}
              onChange={(e) => {
                setSubject(e.target.value);
              }}
            />
          </div>
          {/* <div className="space-y-2">
            <InputHeading heading="Members" />
            <ThreadMembers
              members={members}
              sharedSpaceId={sharedSpaceId}
              setMembers={setMembers}
            />
          </div> */}
          <div className="flex justify-end">
            <Button
              disabled={
                subject.trim() === "" || createThreadInPhaseMutation.isLoading
              }
              onClick={() => {
                createThreadInPhaseMutation.mutate({
                  params: {
                    name: subject,
                    phaseId: new ObjectID(phaseId),
                    // userIds: members
                    //   .filter(
                    //     (m) =>
                    //       m._id.toString() !==
                    //       app.currentUser.customData._id.toString()
                    //   )
                    //   .map((m) => m._id),
                    userIds: [],
                    tags: [],
                  },
                });
              }}
            >
              {createThreadInPhaseMutation.isLoading ? "Creating.." : "Create"}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

// function ThreadMembers({
//   members,
//   sharedSpaceId,
//   setMembers,
// }: {
//   members: UserResponseObj[];
//   sharedSpaceId: string;
//   setMembers: (value: UserResponseObj[]) => void;
// }) {
//   const app = useRealmApp();
//   const { data: projectMembers } = useGetProjectMembers(phase._id);
//   const [query, setQuery] = useState("");
//   const options = {
//     internal: projectMembers?.internal.filter(
//       (m) => m._id.toString() !== app.currentUser.customData._id
//     ),
//     external: projectMembers?.external.filter(
//       (m) => m.userType === "fullUser" && m._id.toString() !== app.currentUser.customData._id
//     ),
//   };
//   const filteredOptions =
//     query === ""
//       ? options
//       :
//       {
//         internal: options.internal.filter(
//           (option) => option.name.toLowerCase().includes(query.toLowerCase())
//         ),
//         external: options.external.filter(
//           (option) =>
//             option.email.toLowerCase().includes(query.toLowerCase())
//         ),
//       }
//       options
//           .filter((o) => o._id.toString() !== app.currentUser.customData._id)
//           .filter((option) =>
//             option.name
//               .toLowerCase()
//               .replace(/\s+/g, "")
//               .includes(query.toLowerCase().replace(/\s+/g, ""))
//           );

//   useEffect(() => {
//     if (sharedSpaceMembers) {
//       setMembers(
//         sharedSpaceMembers.filter(
//           (m) => m._id.toString() === app.currentUser.customData._id
//         )
//       );
//     }
//   }, [sharedSpaceMembers]);
//   return (
//     <>
//       <div className="flex flex-wrap gap-2">
//         {members.map((m) => (
//           <Badge
//             size={"lg"}
//             text={m.name}
//             color={m.type === "internal" ? "gray" : "slate"}
//             Icon={XMarkIcon}
//             clickable={m._id.toString() !== app.currentUser.customData._id}
//             onIconClick={() => {
//               setMembers(
//                 members.filter(
//                   (member) => member._id.toString() !== m._id.toString()
//                 )
//               );
//             }}
//           />
//         ))}
//       </div>
//       <HeadlessCombobox
//         as="div"
//         value={members}
//         onChange={setMembers}
//         disabled={options.length === 0}
//         multiple
//         className={"w-[300px]"}
//         // by={by}
//         // by={(o1, o2) => {

//         // }}
//         // by={by}
//         // by={by}
//       >
//         {/* <HeadlessCombobox.Label className="block text-sm font-medium text-gray-700">Assigned to</HeadlessCombobox.Label> */}
//         {/* <div
//             className={clsx(
//               "relative rounded-md border border-gray-300 shadow-sm bg-white focus:ring-1  disabled:bg-gray-100",
//               isEmbed
//                 ? " focus-within:border-embedPrimary  focus-within:ring-embedPrimary"
//                 : " focus-within:border-primary-main  focus-within:ring-primary-main"
//             )}
//           > */}
//         <div className={"relative"}>
//           {/* <HeadlessCombobox.Input
//                 // value={query}
//                 placeholder={placeholder}
//                 className={clsx(
//                   "border border-red-200 bg-none  py-2 pl-3 pr-10  placeholder:text-gray-300 focus:outline-none  sm:text-sm"
//                 )}
//                 onBlur={() => {
//                   setQuery("");
//                 }}
//                 onChange={(event) => setQuery(event.target.value)}
//                 // displayValue={displayValue}
//               /> */}

//           <HeadlessCombobox.Button className="w-full">
//             <HeadlessCombobox.Input
//               onBlur={() => {
//                 setQuery("");
//               }}
//               autoComplete="off"
//               // value={query}
//               // placeholder={placeholder}
//               className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm placeholder:text-gray-300 focus:border-primary-main focus:outline-none focus:ring-1 focus:ring-primary-main disabled:bg-gray-100 sm:text-sm"
//               onChange={(event) => setQuery(event.target.value)}
//             />
//             <div className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
//               <ChevronUpDownIcon
//                 className="h-5 w-5 text-gray-400"
//                 aria-hidden="true"
//               />
//             </div>
//           </HeadlessCombobox.Button>

//           <HeadlessCombobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
//             {filteredOptions.length === 0 && query !== "" ? (
//               <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
//                 No results
//               </div>
//             ) : (
//               filteredOptions.map((option) => (
//                 <HeadlessCombobox.Option
//                   key={option?._id.toString() ?? ""}
//                   value={option}
//                   className={({ active }) =>
//                     clsx(
//                       "relative mx-1 cursor-pointer select-none rounded-md py-2 pl-3 pr-9 text-gray-900",
//                       // active ? " text-white" : "text-gray-900",
//                       active && "bg-gray-100"
//                       // active && isEmbed && "bg-embedPrimary",
//                       // active && !isEmbed && "bg-primary-600"
//                     )
//                   }
//                 >
//                   {({ active, selected }) => (
//                     <>
//                       <div className="flex items-center space-x-3">
//                         <div
//                           className={clsx(
//                             option.type === "internal"
//                               ? "bg-gray-200"
//                               : "bg-slate-700",
//                             "h-2 w-2 shrink-0 rounded-full"
//                           )}
//                         ></div>
//                         <span
//                           className={classNames(
//                             "block truncate",
//                             selected ? "font-semibold" : ""
//                           )}
//                         >
//                           {option.name}
//                         </span>
//                       </div>

//                       {selected && (
//                         <span
//                           className={classNames(
//                             "absolute inset-y-0 right-0 flex items-center pr-4",
//                             active ? "text-gray-900" : ""
//                             // active
//                             // ? "text-gray-900"
//                             //   : isEmbed
//                             //   ? "text-embedPrimary"
//                             //   : "text-primary-600"
//                           )}
//                         >
//                           <CheckIcon className="h-4 w-4" aria-hidden="true" />
//                         </span>
//                       )}
//                     </>
//                   )}
//                 </HeadlessCombobox.Option>
//               ))
//             )}
//           </HeadlessCombobox.Options>
//         </div>
//       </HeadlessCombobox>
//     </>
//   );
// }

function ActiveThread({
  threadId,
  threadName,
  isInternal,
}: {
  threadId: string;
  threadName: string;
  isInternal: boolean;
}) {
  const { thread } = useGetThread(new ObjectID(threadId));
  const { threadMessages } = useGetThreadMessages(threadId);

  const [message, setMessage] = useState("");
  const createThreadMessageMutation = useCreateThreadMessage();
  const messagesDiv = useRef<HTMLDivElement>(null);
  const app = useRealmApp();

  const sendMessage = () => {
    const user = thread?.otherDetails.users?.find(
      (user) => user._id.toString() === app.currentUser?.id
    );

    if (message.trim() !== "") {
      createThreadMessageMutation.mutate({
        params: {
          threadId: new ObjectID(threadId),
          text: message,
        },
        metadata: {
          type: user?.type || "internal",
        },
      });
    }
    setMessage("");
  };

  useEffect(() => {
    if (messagesDiv.current) {
      messagesDiv.current.scrollTop = messagesDiv.current.scrollHeight;
    }
  }, [threadMessages]);

  const updateThreadMutation = useUpdateThread();

  return (
    <>
      <div className="flex h-14 items-center justify-between bg-gray-100 px-6 pr-8">
        <div className="text-lg font-medium">{thread?.name ?? threadName}</div>
        <div className="flex items-center space-x-2">
          {/* <Button
            variant={"secondary"}
            size="sm"
            onClick={() => {
              updateThreadMutation.mutate({
                update: {
                  _id: new ObjectID(threadId),
                  isActive: !thread?.isActive,
                },
                _id: new ObjectID(threadId),
              });
            }}
          >
            {thread?.isActive ? "Mark as Complete" : "Mark as Active"}
          </Button> */}

          {!!thread && (
            <ThreadMembersButton isInternal={isInternal} thread={thread} />
          )}
        </div>
      </div>

      {threadMessages ? (
        <ChatMessages
          messages={threadMessages}
          className="flex-1 overflow-y-auto bg-white p-6"
        />
      ) : (
        <div className="flex-1" />
      )}

      {/* <div className="" ref={messagesDiv}>
        {!!threadMessages ? (
          <>
            {threadMessages.length > 0 ? (
              threadMessages.map((m) => <ThreadMessage message={m} />)
            ) : (
              <div className="text-sm text-gray-400">No messages</div>
            )}
          </>
        ) : (
          <div className="text-gray-400">Loading...</div>
        )}
      </div> */}
      <div className="rounded-b-md p-6">
        <div className="w-full">
          <textarea
            id="message"
            name="message"
            rows={3}
            autoFocus
            className={
              "block w-full rounded-md border-[1.5px] border-gray-200 p-4 text-gray-700 shadow-sm outline-none ring-0 placeholder:text-gray-400 focus:border-primary focus:outline-none focus:ring-0"
            }
            onKeyDown={(event) => {
              if (event.key === "Enter" && message.trim() !== "") {
                event.preventDefault();
                sendMessage();
              }
            }}
            placeholder="Message.."
            value={message}
            onChange={(e) => {
              // if (e.target.value)
              setMessage(e.target.value);
            }}
          />
          {/* <div className="w-full rounded-b-md border border-t-0 px-4 py-2">
            Toolbox
          </div> */}
        </div>
      </div>
    </>
  );
}

export function ThreadMembersButton({
  thread,
  isInternal,
}: {
  thread: ThreadData;
  isInternal: boolean;
}) {
  const numberOfMembers = thread.otherDetails.users?.length ?? 0;
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <div className="flex items-center space-x-1">
        <Dialog>
          <DialogTrigger asChild>
            <Button variant={"secondary"} size="sm">
              <UsersIcon className="mr-1.5 h-4 w-4" />
              <span>Members</span>
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Members</DialogTitle>
              <DialogDescription>
                Only members part of this chat will be able to send messages and
                receive notifications
              </DialogDescription>
            </DialogHeader>
            <div className="space-y-6 pt-2">
              {isInternal && thread.phaseId && (
                <AddMembersCombobox
                  thread={thread}
                  phaseId={thread.phaseId.toString()}
                />
              )}
              <Members isInternal={isInternal} thread={thread} />
            </div>
          </DialogContent>
        </Dialog>
        {/* {thread.otherDetails?.users?.slice(0, 3).map((u) => {
          return (
            <div
              key={u.toString()}
              className={clsx(
                "flex h-6 w-6 items-center justify-center rounded border text-xs shadow-sm",
                u?.type === "internal"
                  ? "border-gray-300 text-gray-400"
                  : "border-gray-300 bg-gray-200 text-gray-600"
              )}
            >
              {u?.name
                .toUpperCase()
                .split(" ")
                .map((w) => w[0])
                .join("")}
            </div>
          );
        })}
        {numberOfMembers > 3 && (
          <div className="flex h-6 w-6 items-center justify-center rounded border border-gray-300 bg-white p-1 text-gray-400">
            +{numberOfMembers - 3}
          </div>
        )} */}
        {/* {thread.userIds.map((userId) => {
          const member = sharedSpaceMembers.find(
            (m) => m._id?.toString() === userId.toString()
          );
          return (
            <div
              key={userId.toString()}
              className={clsx(
                "flex h-6 w-6 items-center justify-center rounded border text-xs shadow-sm",
                member?.type === "internal"
                  ? "border-gray-300 text-gray-400"
                  : "border-gray-300 bg-gray-200 text-gray-600"
              )}
            >
              {member?.name
                .toUpperCase()
                .split(" ")
                .map((w) => w[0])
                .join("")}
            </div>
          );
        })} */}
      </div>
      {/* <div
        className="flex -space-x-2"
        // onClick={() => {
        //   setIsModalOpen(true);
        // }}
      >
        {thread.otherDetails?.users?.slice(0, 3).map((u) => (
          <Avatar name={u.name} color="gray" size="sm" />
        ))}
        {numberOfMembers > 3 && (
          <div className="flex h-8 w-8 items-center justify-center rounded-full border border-gray-400 bg-white p-1 text-gray-400">
            +{numberOfMembers - 3}
          </div>
        )}
      </div> */}
      {/* <Modal
        isOpen={isModalOpen}
        closeModal={() => {
          setIsModalOpen(false);
        }}
      >
        <div className="min-h-[400px] min-w-[500px] space-y-6 p-6">
          <ModalHeading title="Members" />
          <div className="space-y-2">
            {thread.otherDetails?.users?.map((u) => (
              <div className="flex items-center space-x-3">
                <div
                  className={clsx(
                    u.type === "internal" ? "text-gray-600" : "text-slate-700"
                  )}
                >
                  {u.name}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal> */}
    </>
  );
}

function AddMembersCombobox({
  thread,
  phaseId,
}: {
  thread: ThreadData;
  phaseId: string;
}) {
  const { data: projectMembers } = useGetProjectMembers(phaseId);
  const [open, setOpen] = useState(false);
  const filteredMembers = {
    internal: projectMembers?.internal.filter(
      (im) =>
        !thread.userIds.map((u) => u.toString()).includes(im._id.toString())
    ),
    external: projectMembers?.external.filter(
      (em) =>
        em.userType === "fullUser" &&
        !thread.userIds.map((u) => u.toString()).includes(em._id.toString())
    ),
  };
  // const filteredMembers =
  //   sharedSpaceMembers?.filter((m) => {
  //     return !thread.userIds
  //       .map((u) => u.toString())
  //       .includes(m._id.toString());
  //   }) ?? [];
  const updateThreadMutation = useUpdateThread();
  return (
    <div className="flex items-center space-x-2">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger
          asChild
          disabled={
            !filteredMembers.internal?.length &&
            !filteredMembers.external?.length
          }
        >
          <Button variant="secondary" role="combobox" aria-expanded={open}>
            <PlusIcon className="mr-2 h-4 w-4 shrink-0" />
            <span>Add Member</span>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="w-fit min-w-[200px] max-w-[700px] p-0"
          align="start"
        >
          <Command>
            <CommandInput placeholder="Search members..." className="h-9" />
            <CommandEmpty>No member found.</CommandEmpty>
            <CommandGroup>
              {filteredMembers.internal?.map((m) => (
                <CommandItem
                  key={m._id.toString()}
                  value={m.name}
                  onSelect={() => {
                    // to do : add user to thread
                    updateThreadMutation.mutate({
                      update: {
                        _id: thread._id,
                        userIds: [...thread.userIds, new ObjectID(m._id)],
                      },
                      _id: thread._id,
                    });
                    setOpen(false);
                  }}
                  className="gap-2"
                >
                  <div className="flex items-center space-x-2">
                    <span className="truncate">{m.name}</span>
                  </div>
                </CommandItem>
              ))}
              {filteredMembers.external?.map((m) => {
                if (m.userType === "fullUser") {
                  return (
                    <CommandItem
                      key={m._id.toString()}
                      value={m.name}
                      onSelect={() => {
                        if (thread.userIds) {
                          updateThreadMutation.mutate({
                            update: {
                              _id: thread._id,
                              userIds: [...thread.userIds, new ObjectID(m._id)],
                            },
                            _id: thread._id,
                          });
                        }
                        setOpen(false);
                      }}
                      className="gap-2"
                    >
                      <div className="flex items-center space-x-2">
                        <span className="truncate">{m.name}</span>
                      </div>
                    </CommandItem>
                  );
                }
                return null;
              })}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
      {/* {!!sharedSpaceMembers && filteredMembers.length === 0 && (
        <div>
          <span className="text-xs text-gray-400">
            All members have been added.
          </span>
        </div>
      )} */}
    </div>
  );
}

function Members({
  thread,
  isInternal,
}: {
  thread: ThreadData;
  isInternal: boolean;
}) {
  const updateThreadMutation = useUpdateThread();
  return (
    <div>
      <div className="border-b px-2 pb-2">
        <InputHeading heading="Member" />
      </div>
      {thread.otherDetails.users?.map((m) => {
        return (
          <div
            key={m._id.toString()}
            className="relative flex h-9 items-center justify-between border-b px-2 text-sm text-gray-500 last:border-b-0"
          >
            <div>{m.name}</div>
            {isInternal && (
              <ButtonToRemoveMemberFromChat thread={thread} member={m} />
            )}
          </div>
        );
      })}
    </div>
  );
}

function ButtonToRemoveMemberFromChat({
  thread,
  member,
}: {
  thread: ThreadData;
  member: UserResponseObj;
}) {
  const updateThreadMutation = useUpdateThread();
  return (
    <Button
      className="h-fit"
      variant={"secondaryLink"}
      disabled={
        updateThreadMutation.isLoading || updateThreadMutation.isSuccess
      }
      onClick={() => {
        updateThreadMutation.mutate({
          update: {
            _id: thread._id,
            userIds: thread.userIds.filter(
              (u) => u.toString() !== member._id.toString()
            ),
          },
          _id: thread._id,
        });
      }}
    >
      {"Remove"}
    </Button>
  );
}

const ThreadMessage = ({ message }: { message: ThreadMessageData }) => {
  return (
    <div className="flex space-x-2">
      <div className="shrink-0">
        <Avatar name={message.user.name} color="gray" rounded="md" />
      </div>
      <div>
        <div className="flex items-center space-x-2 text-xs font-medium">
          <div className="text-gray-600">{message.user.name}</div>
          <span className="text-xs font-medium text-gray-400">
            {dayjs(message.createdAt).format("h:mm A, MMM DD")}
          </span>
        </div>
        <div className="mt-1 text-sm text-gray-700">{message.text}</div>
      </div>
    </div>
  );
};
