import React from "react";
import { StatusGroupData } from "src/api/General/status-groups";

type Action = { type: "status_groups_update"; statusGroups: StatusGroupData[] };

type Dispatch = (action: Action) => void;

type State = {
  statusGroups: StatusGroupData[];
};

const StatusGroupsContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function statusGroupsReducer(state: State, action: Action) {
  switch (action.type) {
    case "status_groups_update": {
      return { ...state, statusGroups: action.statusGroups };
    }
  }
}

type Props = {
  children: React.ReactNode;
};

export default function StatusGroupsContextProvider({ children }: Props) {
  const [state, dispatch] = React.useReducer(statusGroupsReducer, {
    statusGroups: [],
  });

  const value = { state, dispatch };

  return (
    <StatusGroupsContext.Provider value={value}>
      {children}
    </StatusGroupsContext.Provider>
  );
}

export function useStatusGroups() {
  const context = React.useContext(StatusGroupsContext);
  if (context === undefined) {
    throw new Error(
      "useStatusGroupsContext must be used within a StatusGroupsContextProvider"
    );
  }
  return context;
}