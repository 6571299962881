import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { ObjectID } from "bson";
import { ObjectId } from "mongodb";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { buildFetcher, buildSetter } from "..";
import { useRealmApp } from "../../store/RealmApp";
import { UserResponseObj } from "../../types/Services/general";
import { useAccessToken } from "../useAccessToken";

export const userKeys = {
  instance: (instanceId: string) => ["users", "instance", instanceId],
  phases: (userId: string) => ["users", userId, "phases"],
  details: (userId: string) => ["users", userId, "details"],
};

export const useGetPhasesForUser = () => {
  const app = useRealmApp();
  return useQuery(
    userKeys.phases(app.currentUser.customData.id),
    async (): Promise<{
      _id: string;
      name: string;
      type: "Sales Room" | "Customer Onboarding" | "Quick Start";
    }> => {
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/phasesForUser`,
        {
          headers: {
            Authorization: `Bearer ${app.currentUser?.accessToken}`,
            Accept: "application/json",
          },
          params: {},
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};

export const useGetUsers = (): { users: UserResponseObj[] | undefined } => {
  const app = useRealmApp();
  const meta = {
    functionName: "getUsersForInstance",
    parameters: {
      instanceId: app.currentUser.customData.instanceId,
    },
  };

  const { isLoading, isError, error, data } = useQuery<UserResponseObj[]>(
    ["users", "all"],
    buildFetcher(app, meta),
    {
      staleTime: Infinity,
    }
  );

  return {
    users: data,
  };
};

type SharedSpaceCommon = {
  _id: ObjectId;
  name: string;
  type: "internal" | "external";
  isActive: boolean;
  externalMembersCanInvite: boolean;
  internalMembers: {
    _id: ObjectId;
    type: "team" | "user";
  }[];
  createdAt: Date;
  updatedAt: Date;
};

type ExternalSharedSpace = SharedSpaceCommon & {
  type: "external";
  instance: {
    _id: ObjectId;
    name: string;
    slug: string;
  };
};

type InternalSharedSpace = SharedSpaceCommon & {
  type: "internal";
  customer?: {
    _id: ObjectId;
    name: string;
  };
};

type SharedSpace = ExternalSharedSpace | InternalSharedSpace;

// export const useGetSharedSpacesForUser = () => {
//   const app = useRealmApp();
//   const meta = {
//     functionName: "getSharedSpacesForUser",
//     parameters: {},
//   };

//   return useQuery<SharedSpace[]>(
//     userKeys.sharedSpaces(app.currentUser.customData.id),
//     buildFetcher(app, meta)
//   );
// };

type UserDetails = {
  _id: ObjectId;
  email: string;
  name: string;
  sharedSpaceds: ObjectId[];
  calendlyUrl: string;
};

export const useGetUserDetails = () => {
  const app = useRealmApp();
  console.log(app.currentUser.customData._id);
  const meta = {
    functionName: "getUserDetails",
    parameters: {
      _id: new ObjectID(app.currentUser.customData._id),
    },
  };

  return useQuery<UserDetails>(
    userKeys.details(app.currentUser.customData._id),
    buildFetcher(app, meta)
  );
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  const app = useRealmApp();
  const functionName = "updateUser";
  const fieldName = "params";
  const snackbarCtx = useSnackBar();
  // const navigate = useNavigate();

  const updateUserMutation = useMutation(
    buildSetter(app, functionName, fieldName),
    {
      onMutate: async ({
        params,
      }: {
        params: {
          _id: ObjectId;
          calendlyUrl?: string;
          name?: string;
        };
      }) => {},
      onError: () => {
        snackbarCtx.showSnackbar("Error updating settings", "error");
      },
      onSuccess: () => {
        snackbarCtx.showSnackbar("Successfully updated settings", "success");
        queryClient.invalidateQueries(
          userKeys.details(app.currentUser.customData._id)
        );
      },
    }
  );

  return updateUserMutation;
};
