import { Command as CommandPrimitive } from "cmdk";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "src/components/ui/command";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/components/ui/tabs";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "src/components/ui/tooltip";
import { z } from "zod";

import {
  BellAlertIcon,
  ChevronLeftIcon,
  Cog6ToothIcon,
  EyeIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronRightIcon,
  Link1Icon,
  MagnifyingGlassIcon,
  PlusIcon,
  TriangleRightIcon,
} from "@radix-ui/react-icons";
import { ObjectID } from "bson";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  PhaseData,
  useGetPhase,
  useUpdatePhase,
} from "src/api/Services/SharedSpace/phases";
import {
  useAddExternalMembersToPhase,
  useGetPendingSharedSpaceInvites,
  useResendSharedSpaceInviteEmail,
} from "src/api/Services/SharedSpace/spaces";
import {
  TaskerType,
  useGetTaskersForInstance,
} from "src/api/Services/Tasks/tasks";

import { useGetInstance } from "src/api/General/instance";
import {
  ExternalProjectMember,
  InternalProjectMember,
  InviteEmail,
  ProjectMembers,
  ProjectMembership,
  useAddInternalMemberToProject,
  useGetProjectMembers,
  useGetSharedSpaceMembers,
  useUpdateProjectInvite,
  useUpdateProjectMembership,
} from "src/api/Services/Projects/projects";
import { useGetSpaces } from "src/api/Spaces/spaces";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { Badge } from "src/components/ui/badge";
import { Button } from "src/components/ui/button";
import { Loading } from "src/components/ui/loading";
import { SpaceNameAndSymbol } from "src/pages/spaces/spaces";
import { useRealmApp } from "src/store/RealmApp";
import { cn } from "src/utils/ui/ui";
import CustomerOnboardingPhase from "./CustomerOnboardingPhase";

type Props = {};

export default function Phase({}: Props) {
  const { phaseId } = useParams();
  const navigate = useNavigate();
  const { data: spaces } = useGetSpaces();
  const { data: phase } = useGetPhase(String(phaseId));
  const space = spaces?.find((s) => s._id === phase?.spaceId);
  // const [addMemberModalOpen, setAddMemberModalOpen] = useState(false);

  return (
    <div className="flex h-full flex-col space-y-2 overflow-y-auto">
      <div className="h-12 items-center justify-between space-y-2 border-b border-gray-200/[.65] px-8 sm:flex sm:space-y-0">
        <div className="flex items-center space-x-2 text-sm">
          {space && (
            <SpaceNameAndSymbol color={space.color} name={space.name} />
          )}
          <ChevronRightIcon className="h-3 w-3 text-gray-500" />
          <Link to={`/spaces/${phase?.spaceId}/projects`}>
            <div className="text-gray-800">{"Projects"}</div>
          </Link>
          <ChevronRightIcon className="h-3 w-3 text-gray-500" />
          <div className="cursor-default text-gray-800">{phase?.name}</div>
        </div>
        <div>
          {!!phase && (
            <div className="flex items-center gap-1.5">
              <div className="items-center-space-x-1 flex">
                {phase.collaborationType === "external" && (
                  <TooltipProvider>
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger asChild>
                        <Button
                          variant={"ghost"}
                          onClick={() => {
                            window.open(
                              `/external-view/shared-spaces/${phase.sharedSpaceId}`,
                              "_blank"
                            );
                          }}
                          size="icon"
                        >
                          <EyeIcon className="h-4 w-4" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>External View</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                )}
                {/* <button
                    className="rounded-md p-2 px-2.5 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                    onClick={() => {
                      window.open(
                        `/external-view/shared-spaces/${phase.sharedSpaceId}`,
                        "_blank"
                      );
                    }}
                  >
                
                  </button> */}
                {/* <ToolTip title={"Members"}>
                  <button
                    className="rounded-md p-2 px-2.5 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                    onClick={() => {
                      setAddMemberModalOpen(true);
                    }}
                  >
                    <UsersIcon className="h-5 w-5" />
                  </button>
                </ToolTip> */}
                {phase.collaborationType === "external" && (
                  <TooltipProvider>
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger asChild>
                        <Button
                          variant={"ghost"}
                          onClick={() => {
                            navigate(`/projects/${phaseId}/notifications`);
                          }}
                          size="icon"
                        >
                          <BellAlertIcon className="h-4 w-4" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Alert Settings</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                )}
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                      <Button
                        variant={"ghost"}
                        onClick={() => {
                          navigate(`/projects/${phaseId}/settings`);
                        }}
                        size="icon"
                      >
                        <Cog6ToothIcon className="h-4 w-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Settings</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <MembersButton view={"internal"} />
              </div>

              {/* <button
                className="rounded-md p-2 px-2.5 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                onClick={() => {
                  navigate(`/projects/${phaseId}/settings`);
                }}
              ></button> */}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-1 flex-col overflow-y-auto">
        {phase && <PhaseSelector phase={phase} />}
      </div>
    </div>
  );
}

function PhaseNavigation({ phase }: { phase: PhaseData }) {
  const navigate = useNavigate();
  // const { phaseId } = useParams();
  // const { journey } = useGetJourney(new ObjectID(phase.journey._id));
  return (
    <div className="flex-1">
      {/* <Breadcrumbs
        breadcrumbs={[
          { title: "Projects", route: "/projects" },
          {
            title: phase.name,
          },
        ]}
      /> */}
      <div className="flex items-center space-x-2">
        <Button
          variant={"ghost"}
          size={"icon"}
          className="h-fit w-fit hover:bg-white"
          onClick={() => {
            // check if previous URL path was project groups or

            navigate("/projects");
          }}
        >
          <ChevronLeftIcon viewBox="6 0 24 24" className="h-4 w-4" />
        </Button>
        <div className="text-xl font-medium leading-none text-gray-900">
          {phase.name}
        </div>
        {/* <EditablePageHeading phase={phase} /> */}
      </div>
    </div>
  );
}

function PhaseSelector({ phase }: { phase: PhaseData }) {
  switch (phase.type) {
    case "Customer Onboarding":
      return <CustomerOnboardingPhase phase={phase} />;
    case "Simple":
      return <CustomerOnboardingPhase phase={phase} />;
    default:
      return <div>Phase not found</div>;
  }
}

function EditablePageHeading({ phase }: { phase: PhaseData }) {
  const [heading, setHeading] = useState(phase.name);
  const updatePhaseMutation = useUpdatePhase();

  useEffect(() => {
    setHeading(phase.name);
  }, [phase.name]);

  return (
    <>
      <div className="relative flex-1">
        <input
          className="w-full min-w-0 max-w-2xl rounded-md border border-transparent py-1.5 text-xl font-medium leading-7 tracking-tight text-gray-900 outline-none transition-all delay-200
           hover:border-gray-200 hover:px-3.5 hover:shadow-md focus:border focus:border-primary-main focus:px-3.5 focus:outline-none focus:ring-0"
          value={heading}
          onChange={(e) => {
            setHeading(e.target.value);
          }}
          onBlur={() => {
            if (heading.trim() !== phase.name) {
              updatePhaseMutation.mutate({
                phase: {
                  _id: phase._id,
                  name: heading,
                },
                update: {
                  _id: phase._id,
                  name: heading,
                },
              });
            }
          }}
        />
      </div>
    </>
  );
}

function MembersButton({ view }: { view: TaskerType }) {
  const { phaseId } = useParams();
  const { data: phase } = useGetPhase(String(phaseId));
  const [open, setOpen] = useState(false);
  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
      }}
    >
      <DialogTrigger asChild>
        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger asChild>
              <Button
                variant={"ghost"}
                size="icon"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <UserIcon className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Members</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        {/* <Button className="ml-1 h-[30px]" variant={"secondary"}>
          <UsersIcon className="mr-2 h-5 w-5" />
          <span>Members</span>
        </Button> */}
        {/* <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger asChild>
              <Button
                variant={"ghost"}
                size="icon"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <UsersIcon className="h-5 w-5" />
              </Button>
            </TooltipTrigger>
            <TooltipContent side="bottom">
              <p>Members</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider> */}
      </DialogTrigger>
      <DialogContent className="top-[12%] max-w-3xl translate-y-0 p-0">
        <DialogHeader
          className={clsx(
            "p-6",
            phase?.collaborationType === "external" ? "pb-4" : "pb-2"
          )}
        >
          <DialogTitle>Members</DialogTitle>
          {/* <DialogDescription>Manage members of your project</DialogDescription> */}
        </DialogHeader>
        {phase?.collaborationType === "external" ? (
          <Tabs
            defaultValue={"external"}
            className="flex flex-1 flex-col overflow-auto"
          >
            <TabsList className="relative shrink-0 px-6">
              <TabsTrigger value="external">
                {!!phase ? phase.customer?.name : ""}
              </TabsTrigger>
              <TabsTrigger value="internal">
                {!!phase ? phase.instance.name : ""}
              </TabsTrigger>
            </TabsList>
            <div className="flex-1 overflow-auto pb-6">
              <TabsContent
                value="external"
                className="space-y-6 overflow-y-auto px-6 pt-6"
              >
                {phase && <ExternalMembers phase={phase} view={view} />}
              </TabsContent>
              <TabsContent
                value="internal"
                className="space-y-6 overflow-y-auto px-6 pt-6"
              >
                {!!phase ? (
                  <InternalMembers view={view} phase={phase} />
                ) : (
                  <></>
                )}
              </TabsContent>
            </div>
          </Tabs>
        ) : (
          <div className="div space-y-4 p-6 pt-0">
            {phase && <InternalMembers phase={phase} view={view} />}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

function InternalMembers({
  view,
  phase,
}: {
  view: TaskerType;
  phase: PhaseData;
}) {
  const { data: members } = useGetProjectMembers(phase._id);
  const { data: instanceMembers } = useGetTaskersForInstance();
  const addInternalMemberToProjectMutation = useAddInternalMemberToProject();
  const filteredInstanceMembers =
    instanceMembers?.filter(
      (instanceMember) =>
        !members?.internal.find((m) => m._id === instanceMember._id)
    ) ?? [];
  const [open, setOpen] = useState(false);
  return (
    <>
      <div>
        <Popover modal={true} open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button
              variant={"secondary"}
              onClick={() => setOpen(true)}
              disabled={!filteredInstanceMembers.length}
            >
              <PlusIcon className="mr-2 h-4 w-4" />
              Add Internal Member
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-fit max-w-sm p-0" align="start">
            <Command>
              <CommandInput placeholder="Search.." className="h-9" />
              <CommandEmpty>No members found.</CommandEmpty>
              <CommandGroup>
                {filteredInstanceMembers?.map((instanceMember) => (
                  <CommandItem
                    key={instanceMember._id}
                    value={instanceMember.name}
                    onSelect={() => {
                      addInternalMemberToProjectMutation.mutate({
                        userId: instanceMember._id,
                        phaseId: phase._id,
                        role: "member",
                      });
                      setOpen(false);
                    }}
                    className="gap-2"
                  >
                    <span className="truncate">{instanceMember.name}</span>
                  </CommandItem>
                ))}
              </CommandGroup>
            </Command>
          </PopoverContent>
        </Popover>
      </div>
      {!!members ? (
        <>
          {!!members?.internal.length ? (
            <div className="space-y-2">
              <div className="flex">
                <div className="flex-1 text-sm text-gray-400">Name</div>
              </div>
              <div>
                {members.internal.map((m) => (
                  <InternalMemberListItem m={m} key={m.email} phase={phase} />
                ))}
              </div>
            </div>
          ) : (
            <div>No internal members added</div>
          )}
        </>
      ) : (
        <div>
          <Loading />
        </div>
      )}
    </>
  );
}

function InternalMemberListItem({
  m,
  phase,
}: {
  m: InternalProjectMember;
  phase: PhaseData;
}) {
  return (
    <div
      key={m.email}
      className="group relative flex h-10 items-center justify-between border-t text-sm"
    >
      <div className="flex flex-1 items-center space-x-2">
        <div className="truncate font-medium text-gray-700">{m.name}</div>
      </div>
    </div>
  );
}

type NewMember = {
  email: string;
  sendInviteEmail: boolean;
  role: ProjectMembership;
};

function ExternalMembers({
  phase,
  view,
}: {
  phase: PhaseData;
  view: TaskerType;
}) {
  const app = useRealmApp();
  const { sharedSpaceInvites } = useGetPendingSharedSpaceInvites(
    new ObjectID(phase.sharedSpaceId)
  );
  const addMembersToSharedSpaceMutation = useAddExternalMembersToPhase();
  const { instance } = useGetInstance();

  // const [isInviting, setIsInviting] = useState(false);
  const [newExternalMembers, setNewExternalMembers] = useState<NewMember[]>([]);

  const [inviteEmail, setInviteEmail] = useState({
    subject: `${phase.customer?.name} <> ${phase.instance?.name}`,
    message:
      "Hi!\n\nI'm excited to invite you to collaborate with me on this project! This platform will help us keep track of tasks, project progress, and ensure efficient communication throughout the project's duration.",
    signature: `Best,\n${app.currentUser.customData.name}`,
  });
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState<{ isError: boolean; message: string }>(
    {
      isError: false,
      message: "Press Enter to add",
    }
  );
  const [open, setOpen] = useState(false);
  const emailSchema = z.string().email();
  const snackbarCtx = useSnackBar();
  const { data: phaseMembers } = useGetProjectMembers(phase._id);
  // const [isAddingMembers, setIsAddingMembers] = useState(false);
  const [isExistingMembersOpen, setIsExistingMembersOpen] = useState(false);
  useEffect(() => {
    if (addMembersToSharedSpaceMutation.isSuccess) {
      // setIsInviting(false);
      setNewExternalMembers([]);
      setEmail("");
      setMessage({
        isError: false,
        message: "Press Enter to add",
      });
    }
  }, [addMembersToSharedSpaceMutation.isSuccess]);

  useEffect(() => {
    setIsExistingMembersOpen(false);
  }, [newExternalMembers]);

  return (
    <>
      <div className="space-y-4">
        {view === "internal" && (
          <div className="flex space-x-3">
            <ExternalMemberAddButton
              open={open}
              setOpen={setOpen}
              phase={phase}
              setNewExternalMembers={setNewExternalMembers}
              newMembers={newExternalMembers}
              // setIsAddingMembers={setIsAddingMembers}
            />
            <Button
              variant={"secondary"}
              onClick={async () => {
                if (!instance) {
                  return;
                }
                const slug = instance.slug;
                const phaseId = phase._id;
                try {
                  await navigator.clipboard.writeText(
                    `https://${slug}.${process.env.REACT_APP_DOMAIN}/projects/${phaseId}`
                  );
                  snackbarCtx.showSnackbar(
                    "Link copied to clipboard!",
                    "success"
                  );
                } catch (err) {
                  console.error("Failed to copy", err);
                }
              }}
              disabled={!instance}
            >
              <Link1Icon className="mr-2 h-4 w-4" />
              <span>Copy Sharing Link</span>
            </Button>
          </div>
        )}

        <NewMembersList
          newMembers={newExternalMembers}
          setNewMembers={setNewExternalMembers}
          inviteEmail={inviteEmail}
          phase={phase}
        />

        {!!phaseMembers ? (
          <>
            {newExternalMembers.length > 0 ? (
              <div className="space-y-2 pt-2">
                {/* collapsible existing members */}
                <div
                  className="group flex cursor-pointer items-center space-x-1.5"
                  onClick={() => {
                    setIsExistingMembersOpen(!isExistingMembersOpen);
                  }}
                >
                  <div className="text-sm text-gray-500">Existing Members</div>
                  <TriangleRightIcon
                    className={clsx(
                      "h-3 w-3 text-gray-400 transition-all group-hover:text-gray-500",
                      isExistingMembersOpen ? "rotate-90" : ""
                    )}
                  />
                </div>
                {isExistingMembersOpen && (
                  <ExternalMembersList
                    phaseMembers={phaseMembers}
                    phase={phase}
                  />
                )}
              </div>
            ) : (
              <ExternalMembersList
                phaseMembers={phaseMembers}
                // view={view}
                phase={phase}
              />
            )}
          </>
        ) : (
          <div className="flex h-20 items-center justify-center">
            <Loading />
          </div>
        )}
      </div>
      {!true ? (
        <></>
      ) : (
        <></>
        // <div className="space-y-8">
        //   {/* <div className="space-y-2">
        //     <ExternalMemberAddButton
        //       open={open}
        //       setOpen={setOpen}
        //       phase={phase}
        //     />
        //     <div className="space-y-1">
        //       <InputHeading heading="Email" />
        //       <div>
        //         <TextInput
        //           autoComplete="off"
        //           spellCheck={false}
        //           // autoFocus
        //           name="stage"
        //           className="w-72"
        //           placeholder="richardhendrix@piedpiper.com"
        //           onChange={(e) => {
        //             if (e.target.value.includes(" ")) {
        //               return;
        //             }
        //             setEmail(e.target.value.toLowerCase());
        //             if (message.isError) {
        //               setMessage({
        //                 isError: false,
        //                 message: "Press Enter to add",
        //               });
        //             }
        //           }}
        //           value={email}
        //           onBlur={() => {
        //             if (email.trim() !== "" && !message.isError) {
        //               setMessage({
        //                 isError: true,
        //                 message: "Email has not been added. Press Enter to add",
        //               });
        //             }
        //           }}
        //           // RightIcon={ArrowLongLeftIcon}
        //           // rightIconOnClick={addStage}
        //           // rightIconDisabled={stage.trim() === ""}
        //           // rightIconTooltip="Click or press Enter to add"
        //           onKeyDown={(event) => {
        //             if (!sharedSpaceInvites || !sharedSpaceMembers) {
        //               return;
        //             }
        //             if (event.key === "Enter") {
        //               if (
        //                 newExternalMembers.filter((m) => m.email === email)
        //                   .length > 0
        //               ) {
        //                 setMessage({
        //                   isError: true,
        //                   message: "Email already added below",
        //                 });
        //               } else if (
        //                 sharedSpaceMembers.filter((m) => m.email === email)
        //                   .length > 0
        //               ) {
        //                 setMessage({
        //                   isError: true,
        //                   message: "Member already added to project",
        //                 });
        //               } else if (
        //                 sharedSpaceInvites.filter((m) => m.email === email)
        //                   .length > 0
        //               ) {
        //                 setMessage({
        //                   isError: true,
        //                   message: "Member already added to project",
        //                 });
        //               } else {
        //                 const isValid = emailSchema.safeParse(email);
        //                 if (isValid.success) {
        //                   setMessage({
        //                     isError: false,
        //                     message: "Press Enter to add",
        //                   });
        //                   // setNewExternalMembers([
        //                   //   ...newExternalMembers,
        //                   //   { email: email, sendInviteEmail: true },
        //                   // ]);
        //                   setEmail("");
        //                 } else {
        //                   setMessage({
        //                     isError: true,
        //                     message: "Please enter a valid email",
        //                   });
        //                 }
        //               }
        //             }
        //           }}
        //         />
        //       </div>
        //       <div className="text-xs">
        //         {message.isError ? (
        //           <div className="text-red-400">{message.message}</div>
        //         ) : (
        //           <div className="text-gray-500">
        //             Press{" "}
        //             <span
        //               className={clsx(
        //                 "font-medium",
        //                 email.trim() !== "" && "text-primary"
        //               )}
        //             >
        //               Enter
        //             </span>{" "}
        //             to add
        //           </div>
        //         )}
        //       </div>
        //     </div>
        //   </div> */}
        //   {newExternalMembers.length > 0 && (
        //     <div className="space-y-8">
        //       <div className="space-y-2">
        //         <div className="flex justify-between">
        //           <InputHeading heading="Customer Member" />
        //           <div className="flex items-center space-x-1">
        //             <TooltipProvider delayDuration={1200}>
        //               <Tooltip delayDuration={0}>
        //                 <TooltipTrigger asChild>
        //                   <InformationCircleIcon className="h-4 w-4 text-gray-400" />
        //                 </TooltipTrigger>
        //                 <TooltipContent
        //                   sideOffset={12}
        //                   className="max-w-[230px]"
        //                 >
        //                   <p>
        //                     {
        //                       "The invite email will contain a magic link. It will enable the invited user to access the project in one click."
        //                     }
        //                   </p>
        //                 </TooltipContent>
        //               </Tooltip>
        //             </TooltipProvider>
        //             <InputHeading heading="Send Invite Email" />
        //           </div>
        //         </div>
        //         <div className="space-y-1">
        //           {newExternalMembers.map((m) => (
        //             <div className="group relative flex items-center justify-between border-b py-1.5 text-sm first:border-t">
        //               <div className="truncate text-gray-500">{m.email}</div>
        //               <Switch
        //                 checked={m.sendInviteEmail}
        //                 onCheckedChange={(value) => {
        //                   setNewExternalMembers(
        //                     newExternalMembers.map((i) => {
        //                       if (i.email === m.email) {
        //                         return { ...i, sendInviteEmail: value };
        //                       } else {
        //                         return i;
        //                       }
        //                     })
        //                   );
        //                 }}
        //               />
        //               <div
        //                 className="absolute right-full mr-0.5 cursor-pointer rounded-full p-1 text-gray-400 opacity-0 hover:bg-gray-50 hover:text-gray-600 group-hover:opacity-100"
        //                 onClick={() => {
        //                   setNewExternalMembers(
        //                     newExternalMembers.filter(
        //                       (i) => i.email !== m.email
        //                     )
        //                   );
        //                 }}
        //               >
        //                 <XMarkIcon className="h-3 w-3 " />
        //               </div>
        //             </div>
        //           ))}
        //         </div>
        //       </div>
        //     </div>
        //   )}
        //   <div className="flex justify-between space-x-2">
        //     <Button
        //       variant={"secondary"}
        //       onClick={() => {
        //         // setIsInviting(false);
        //       }}
        //     >
        //       <ArrowLeftIcon className="mr-2 h-4 w-4" />
        //       <span>Back to Members</span>
        //     </Button>
        //     {newExternalMembers.length > 0 && (
        //       <Button
        //         disabled={addMembersToSharedSpaceMutation.isLoading}
        //         onClick={() => {
        //           addMembersToSharedSpaceMutation.mutate({
        //             members: newExternalMembers.map((m) => {
        //               return {
        //                 email: m.email.toLowerCase(),
        //                 sendInviteEmail: m.sendInviteEmail,
        //                 phaseId: phase._id,
        //                 sharedSpaceId: phase.sharedSpaceId,
        //                 inviteEmail: {
        //                   subject: `${phase.instance.name} is inviting you to join a project - ${phase.name}`,
        //                   message: `You have been invited to join a project with ${phase.instance.name}.`,
        //                   signature: `Best,\n${phase.instance.name}`,
        //                 },
        //               };
        //             }),
        //             metadata: {
        //               sharedSpaceId: phase.sharedSpaceId,
        //               phaseId: phase._id,
        //             },
        //           });
        //         }}
        //       >
        //         <span>
        //           {addMembersToSharedSpaceMutation.isLoading
        //             ? "Adding Members.."
        //             : "Add Members"}
        //         </span>
        //       </Button>
        //     )}
        //   </div>
        // </div>
      )}
    </>
  );
}

function ExternalMemberAddButton({
  open,
  setOpen,
  phase,
  setNewExternalMembers,
  newMembers,
}: // setIsAddingMembers,
{
  open: boolean;
  setOpen: (open: boolean) => void;
  phase: PhaseData;
  setNewExternalMembers: React.Dispatch<React.SetStateAction<NewMember[]>>;
  newMembers: NewMember[];
  // setIsAddingMembers: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { data: phaseMembers } = useGetProjectMembers(phase._id);
  const { data: sharedSpaceMembers } = useGetSharedSpaceMembers(
    phase.sharedSpaceId
  );

  const [emailSearch, setEmailSearch] = useState("");
  const [isError, setIsError] = useState(false);
  const emailSchema = z.string().email();
  function addExternalMember() {
    setNewExternalMembers((prev) => {
      // if (prev.length === 0) {
      //   setIsAddingMembers(true);
      // }
      return [
        ...prev,
        { email: emailSearch, sendInviteEmail: true, role: "member" },
      ];
    });

    setEmailSearch("");
    setOpen(false);
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant={"secondary"}>
          <PlusIcon className="mr-2 h-4 w-4" />
          <span>Add External Member</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="pointer-events-auto w-60 p-0" align="start">
        <Command
          filter={(value, search) => {
            if (value === "-1") {
              return 1;
            } else if (value === "+") {
              if (emailSearch.trim() !== "") {
                return 1;
              }
              return 0;
            } else {
              if (value.startsWith(search)) {
                return 1;
              }
              return 0;
            }
          }}
        >
          <div className={clsx("flex items-center px-3")} cmdk-input-wrapper="">
            <MagnifyingGlassIcon className="mr-2 h-4 w-4 shrink-0 opacity-50" />
            <CommandPrimitive.Input
              className={cn(
                "flex h-9 w-full rounded-md border-none bg-transparent px-0 py-3 text-sm outline-none ring-0 placeholder:text-gray-400/80 focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50"
              )}
              value={emailSearch}
              onValueChange={(s) => {
                setEmailSearch(s);
                setIsError(false);
              }}
              placeholder="Enter email to add.."
            />
          </div>
          <CommandList>
            <CommandGroup
              className={clsx(
                emailSearch.trim() === "" &&
                  !sharedSpaceMembers?.external.filter(
                    (ssm) =>
                      !phaseMembers?.external.find(
                        (e) => e.email === ssm.email
                      ) && !newMembers.find((nm) => nm.email === ssm.email)
                  ).length &&
                  "py-0"
              )}
            >
              {emailSearch.trim() !== "" && (
                <CommandItem
                  value={"+"}
                  onSelect={() => {
                    if (emailSchema.safeParse(emailSearch).success) {
                      addExternalMember();
                    } else {
                      setIsError(true);
                    }
                  }}
                  className={clsx(
                    "flex justify-between gap-2",
                    emailSchema.safeParse(emailSearch).success
                      ? "aria-selected:text-gray-600"
                      : "aria-selected:text-gray-400",
                    isError && "aria-selected:bg-red-50"
                  )}
                >
                  {!isError ? (
                    <>
                      <div className="flex min-w-0 items-center space-x-2 truncate">
                        <PlusIcon className="h-4 w-4 shrink-0" />
                        <div className="truncate">
                          Add&nbsp;
                          <span>{emailSearch}</span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="text-red-500">Enter a valid email</div>
                  )}
                </CommandItem>
              )}

              {sharedSpaceMembers?.external
                .filter(
                  (ssm) =>
                    !phaseMembers?.external.find(
                      (e) => e.email === ssm.email
                    ) && !newMembers.find((nm) => nm.email === ssm.email)
                )
                ?.map((ssm) => (
                  <CommandItem
                    key={ssm.email}
                    value={ssm.email}
                    onSelect={() => {
                      setNewExternalMembers((prev) => [
                        ...prev,
                        {
                          email: ssm.email,
                          sendInviteEmail: true,
                          role: "member",
                        },
                      ]);
                      setOpen(false);
                    }}
                    className="gap-2"
                  >
                    <span className="truncate">{ssm.email}</span>
                  </CommandItem>
                ))}
              {emailSearch.trim() !== "" && (
                <CommandItem>Press Enter to add email</CommandItem>
              )}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function ExternalMembersList({
  phaseMembers,
  // view,
  phase,
}: {
  phaseMembers: ProjectMembers;
  // view: TaskerType;
  phase: PhaseData;
}) {
  return (
    <>
      {!!phaseMembers.external.length ? (
        <div className="space-y-2 pt-2">
          <div className="flex">
            <div className="flex-1 text-sm text-gray-400">Email</div>
            <div className="w-36 text-sm text-gray-400">Status</div>
            <div className="w-36 text-sm text-gray-400">Membership</div>
            <div className="w-32 text-sm text-gray-400"></div>
          </div>
          <div>
            {phaseMembers.external.map((m) => (
              <ExternalMemberListItem m={m} key={m.email} phase={phase} />
            ))}
          </div>
        </div>
      ) : (
        <div className="text-sm text-gray-600">No external members added.</div>
      )}
    </>
  );
}

function NewMembersList({
  newMembers,
  setNewMembers,
  inviteEmail,
  phase,
}: {
  newMembers: NewMember[];
  setNewMembers: React.Dispatch<React.SetStateAction<NewMember[]>>;
  inviteEmail: InviteEmail;
  phase: PhaseData;
}) {
  const addMembersToSharedSpaceMutation = useAddExternalMembersToPhase();

  useEffect(() => {
    if (addMembersToSharedSpaceMutation.isSuccess) {
      setNewMembers([]);
    }
  }, [addMembersToSharedSpaceMutation.isSuccess]);
  return (
    <>
      {!!newMembers.length && (
        <div className="space-y-3 rounded-md bg-gray-50 p-4">
          {/* <div className="text-sm text-gray-400">New Members</div> */}
          <div className="space-y-2 pb-4">
            <div className="flex">
              <div className="flex-1 text-sm text-gray-400">
                New Member Email
              </div>
              <div className="w-36 text-sm text-gray-400">Membership</div>
            </div>
            <div>
              {newMembers.map((nm) => (
                <NewMemberListItem
                  key={nm.email}
                  newMember={nm}
                  setNewMembers={setNewMembers}
                />
              ))}
            </div>
          </div>
          <div className="flex items-center justify-end space-x-2.5">
            <Button
              variant={"secondary"}
              onClick={() => {
                setNewMembers([]);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (newMembers.length > 0) {
                  addMembersToSharedSpaceMutation.mutate({
                    members: newMembers.map((m) => {
                      return {
                        email: m.email,
                        sendInviteEmail: m.sendInviteEmail,
                        phaseId: phase._id,
                        sharedSpaceId: phase.sharedSpaceId,
                        inviteEmail,
                        role: m.role,
                      };
                    }),
                    metadata: {
                      sharedSpaceId: phase.sharedSpaceId,
                      phaseId: phase._id,
                    },
                  });
                }
              }}
              disabled={addMembersToSharedSpaceMutation.isLoading}
            >
              {addMembersToSharedSpaceMutation.isLoading ? "Adding.." : "Add"}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

function NewMemberListItem({
  newMember,
  setNewMembers,
}: {
  newMember: NewMember;
  setNewMembers: React.Dispatch<React.SetStateAction<NewMember[]>>;
}) {
  return (
    <div
      key={newMember.email}
      className="group relative flex h-10 items-center justify-between border-t text-sm"
    >
      <div className="flex flex-1 items-center space-x-2">
        <div className="truncate font-medium text-gray-700">
          {newMember.email}
        </div>
      </div>

      <div className="w-36">
        <Select
          value={newMember.role}
          onValueChange={(value) => {
            setNewMembers((prev) =>
              [...prev].map((nm) => {
                if (nm.email === newMember.email) {
                  return {
                    ...nm,
                    role: value === "member" ? "member" : "subscriber",
                  };
                }
                return nm;
              })
            );
          }}
        >
          <SelectTrigger className="h-8 w-32 gap-2 truncate border-gray-200/80 bg-white">
            <div className="flex items-center space-x-2 truncate">
              <SelectValue>
                {newMember.role === "member" ? "Tasker" : "Subscriber"}
              </SelectValue>
            </div>
          </SelectTrigger>
          <SelectContent
            align="end"
            className="max-h-[30vh] max-w-[240px]"
            sideOffset={-1}
          >
            <SelectGroup>
              <SelectItem value={"member"}>
                <div className="space-y-0.5">
                  <div className="">Tasker</div>
                  <div className="text-xs font-light text-gray-500">
                    Taskers are responsible for the execution of the project
                  </div>
                </div>
              </SelectItem>
              <SelectItem value={"subscriber"}>
                <div className="space-y-0.5">
                  <div className="">Subscriber</div>
                  <div className="text-xs font-light text-gray-500">
                    Subscribers only track progress of the project
                  </div>
                </div>
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}

function ExternalMemberListItem({
  m,
  phase,
}: {
  m: ExternalProjectMember;
  phase: PhaseData;
}) {
  const app = useRealmApp();
  const resendSharedSpaceInviteEmailMutation =
    useResendSharedSpaceInviteEmail();
  const updateProjectInviteMutation = useUpdateProjectInvite();
  const updateProjectMembershipMutation = useUpdateProjectMembership();
  return (
    <div
      key={m.email}
      className="group relative flex h-10 items-center justify-between border-t text-sm"
    >
      <div className="flex flex-1 items-center space-x-2">
        <div className="truncate font-medium text-gray-700">{m.email}</div>
      </div>
      <div className="flex w-36">
        {m.userType === "fullUser" ? (
          <Badge variant={"green"}>Active</Badge>
        ) : (
          <Badge variant={"gray"}>Invited</Badge>
        )}
      </div>
      <div className="w-36">
        <Select
          value={m.role}
          onValueChange={(value) => {
            if (m.userType === "invitedUser") {
              if (value === "member") {
                updateProjectInviteMutation.mutate({
                  invite: {
                    _id: m.inviteId,
                    role: "member",
                  },
                  phase: {
                    _id: phase._id,
                  },
                });
              } else if (value === "subscriber") {
                updateProjectInviteMutation.mutate({
                  invite: {
                    _id: m.inviteId,
                    role: "subscriber",
                  },
                  phase: {
                    _id: phase._id,
                  },
                });
              }
            } else if (m.userType === "fullUser") {
              if (value === "member") {
                updateProjectMembershipMutation.mutate({
                  userId: m._id,
                  phaseId: phase._id,
                  role: "member",
                });
              } else if (value === "subscriber") {
                updateProjectMembershipMutation.mutate({
                  userId: m._id,
                  phaseId: phase._id,
                  role: "subscriber",
                });
              }
            }
          }}
        >
          <SelectTrigger className="h-8 w-32 gap-2 truncate border-gray-200/80">
            <div className="flex items-center space-x-2 truncate">
              <SelectValue>
                {m.role === "member" ? "Tasker" : "Subscriber"}
              </SelectValue>
            </div>
          </SelectTrigger>
          <SelectContent className="max-h-[30vh] max-w-[240px]" sideOffset={-1}>
            <SelectGroup>
              <SelectItem value={"member"}>
                <div className="space-y-0.5">
                  <div className="">Tasker</div>
                  <div className="text-xs font-light text-gray-500">
                    Taskers are responsible for the execution of the project
                  </div>
                </div>
              </SelectItem>
              <SelectItem value={"subscriber"}>
                <div className="space-y-0.5">
                  <div className="">Subscriber</div>
                  <div className="text-xs font-light text-gray-500">
                    Subscribers only track progress of the project
                  </div>
                </div>
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      <div className="w-32">
        <Button
          variant={"secondaryLink"}
          disabled={resendSharedSpaceInviteEmailMutation.isLoading}
          onClick={() => {
            resendSharedSpaceInviteEmailMutation.mutate({
              params: {
                sharedSpaceId: new ObjectID(phase.sharedSpaceId),
                email: m.email.toLowerCase(),
                phaseId: new ObjectID(phase._id),
                inviteEmail: {
                  subject: `${phase.customer?.name} <> ${phase.instance?.name}`,
                  message: `Hi!\n\nI'm excited to invite you to collaborate with me on this project! This platform will help us keep track of tasks, project progress, and ensure efficient communication throughout the project's duration.`,
                  signature: `Best,\n${app.currentUser.customData.name}`,
                },
              },
            });
          }}
        >
          {resendSharedSpaceInviteEmailMutation.isLoading
            ? "Resending.."
            : "Resend Invite"}
        </Button>
      </div>
    </div>
  );
}
