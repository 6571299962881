import { useMutation, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import posthog from "posthog-js";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { useAccessToken } from "../useAccessToken";
export const useLoginWithEmailPassword = () => {
  const snackbarCtx = useSnackBar();
  return useMutation({
    mutationFn: async ({
      email,
      password,
      token,
    }: {
      email: string;
      password: string;
      token: string;
    }): Promise<{
      identity: {
        userId: string;
        email: string;
        instanceId: string;
      };
      localStorage: { [key: string]: string };
    }> => {
      const res = await axios.post(
        // `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/sendLoginCodeForFullUser`,
        `https://8sn1x4q835.execute-api.us-east-1.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/login`,
        {
          email,
          password,
          token,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Api-Key": "Xp8jqnancG76ULs50pe4gYwMiU0Z0Oo7gdVylAfd",
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Incorrect email or password", "error");
    },
    onSuccess: (data) => {
      // iterate through keys and set to local storage
      for (const [key, value] of Object.entries(data.localStorage)) {
        localStorage.setItem(key, value);
      }
      // set posthog
      posthog.identify(data.identity.userId);
      posthog.people.set({ email: data.identity.email });
      posthog.people.set({
        instanceId: data.identity.instanceId,
      });
      // reload page
      window.location.reload();
    },
  });
};

export const useGenerateLoginCode = () => {
  const snackbarCtx = useSnackBar();

  return useMutation({
    mutationFn: async (email: string): Promise<{ message: string }> => {
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/sendLoginCodeForFullUser`,
        {
          email,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${app.currentUser?.accessToken}`,
          },
          params: {
            secret: process.env.REACT_APP_SECRET,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error generating OTP", "error");
    },
    onSuccess: (data) => {
      snackbarCtx.showSnackbar("Check your email for an OTP", "success");
    },
  });
};

export const useVerifyLoginCode = () => {
  const snackbarCtx = useSnackBar();

  return useMutation({
    mutationFn: async ({
      email,
      code,
    }: {
      email: string;
      code: string;
    }): Promise<{ message: string }> => {
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/verifyLoginCodeForFullUser`,
        {
          email,
          code,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${app.currentUser?.accessToken}`,
          },
          params: {
            secret: process.env.REACT_APP_SECRET,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Incorrect OTP", "error");
    },
    onSuccess: (data) => {
      // snackbarCtx.showSnackbar("Check your email for an OTP", "success");
    },
  });
};

// export const useSendCodeForRegistration = () => {
//   const snackbarCtx = useSnackBar();
//   return useMutation({
//     mutationFn: async ({
//       email,
//     }: {
//       email: string;
//     }): Promise<{ message: string }> => {
//       const res = await axios.post(
//         `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/sendCodeForRegistration`,
//         {
//           email,
//         },
//         {
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//           },
//           params: {
//             secret: process.env.REACT_APP_SECRET,
//           },
//         }
//       );
//       return res.data;
//     },
//     onError: (error: AxiosError) => {
//       if (error.response?.status === 409) {
//         snackbarCtx.showSnackbar("Email is already registered", "error");
//       } else {
//         snackbarCtx.showSnackbar("Error generating code for email", "error");
//       }
//     },
//     onSuccess: (data) => {
//       snackbarCtx.showSnackbar(
//         "Check your email for a verification code!",
//         "success"
//       );
//     },
//   });
// };

export const useCheckUserTypeForEmail = () => {
  const snackbarCtx = useSnackBar();
  return useMutation({
    mutationFn: async ({
      email,
    }: {
      email: string;
    }): Promise<{ type: "external" | "none" }> => {
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/checkUserTypeForEmail`,
        {
          email,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            secret: process.env.REACT_APP_SECRET,
          },
        }
      );
      return res.data;
    },
    onError: (error: AxiosError) => {
      if (error.response?.status === 409) {
        snackbarCtx.showSnackbar("Email is already registered", "error");
      } else {
        snackbarCtx.showSnackbar("Error validating email", "error");
      }
    },
    onSuccess: () => {
      // snackbarCtx.showSnackbar(
      //   "Check your email for a verification code!",
      //   "success"
      // );
    },
  });
};

export const useSendEmailToExternalUserForRegistration = () => {
  const snackbarCtx = useSnackBar();
  return useMutation({
    mutationFn: async ({
      email,
    }: {
      email: string;
    }): Promise<{ message: string }> => {
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/sendEmailToExternalUserForRegistration`,
        {
          email,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            secret: process.env.REACT_APP_SECRET,
          },
        }
      );
      return res.data;
    },
    onError: (error: AxiosError) => {
      snackbarCtx.showSnackbar("Issue sending registration email", "error");
    },
    onSuccess: () => {
      // snackbarCtx.showSnackbar(
      //   "Check your email for a verification code!",
      //   "success"
      // );
    },
  });
};

// export const useVerifyCodeForRegistration = () => {
//   const snackbarCtx = useSnackBar();
//   return useMutation({
//     mutationFn: async ({
//       email,
//       code
//     }: {
//       email: string;
//       code: string;
//     }): Promise<{ message: string }> => {
//       const res = await axios.post(
//         `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/verifyCodeForRegistration`,
//         {
//           email,
//           code
//         },
//         {
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//           },
//           params: {
//             secret: process.env.REACT_APP_SECRET,
//           },
//         }
//       );
//       return res.data;
//     },
//     onError: (error: AxiosError) => {
//       snackbarCtx.showSnackbar("Incorrect code", "error");
//     },
//     onSuccess: (data) => {
//       snackbarCtx.showSnackbar(
//         "Check your email for a verification code!",
//         "success"
//       );
//     },
//   });
// };

export const useGetJWTForInternalUser = () => {
  const getValidAccessToken = useAccessToken();
  return useQuery(
    ["jwt"],
    async (): Promise<{
      token: string;
    }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/jwtForInternalUser`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};
