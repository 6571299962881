import clsx from "clsx";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetSettings } from "src/api/General/settings";
import { getRGBColor } from "src/utils/functions/embed";
import Homebase from "./Homebase";

type Props = {};

export default function HomebaseSetup({}: Props) {
  const { slug } = useParams();

  return (
    <>
      {!!slug ? (
        <>
          <Homebase />
        </>
      ) : (
        <Setup />
      )}
    </>
  );
}

function Setup() {
  const { data: settings } = useGetSettings();
  const [color, setColor] = useState(`${getRGBColor("#000000", "primary")}`);

  useEffect(() => {
    if (!!settings) {
      setColor(
        `{${getRGBColor(
          settings.embed.primaryColor ? settings.embed.primaryColor : "#000000",
          "primary"
        )}}`
      );
    }
  }, [settings]);

  return (
    <>
      {!!settings && (
        <div
          className={clsx(
            "flex-1 flex flex-col px-8 py-6 overflow-y-auto",
            !!settings.embed.fontType
              ? settings.embed.fontType === "sans"
                ? "font-sans"
                : settings.embed.fontType === "serif"
                ? "font-serif"
                : "font-mono"
              : "font-sans"
          )}
        >
          {/* <Helmet> */}
          <style>
            :root
            {color}
          </style>
          {/* </Helmet> */}
          <Homebase />
        </div>
      )}
    </>
  );
}
