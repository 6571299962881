import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { ObjectID } from "bson";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useCreateThreadMessage,
  useGetThread,
  useGetThreadMessages,
  useUpdateThread,
} from "src/api/Services/Threads/threads";
import { useRealmApp } from "src/store/RealmApp";
import { ThreadMembersButton } from "../Services/Threads/Threads";
import { ChatMessages } from "../Services/Threads/thread-elements/messages";

type Props = {};

export default function ChatPanel({}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const chatId = searchParams.get("chatPanelId");
  const [open, setOpen] = useState(!!chatId);
  const navigate = useNavigate();

  useEffect(() => {
    if (!!chatId) {
      setOpen(true);
    }
  }, [chatId]);

  if (!chatId) {
    return null;
  }

  function handleClose() {
    setOpen(false);
    setTimeout(() => {
      searchParams.delete("chatPanelId");
      navigate(
        {
          pathname: window.location.pathname,
          search: `?${searchParams}`,
        },
        { replace: true }
      );
    }, 300);
  }

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          handleClose();
        } else {
          setOpen(true);
        }
      }}
    >
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-50 bg-black bg-opacity-25 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
        <Dialog.Content className="fixed inset-y-0 right-0 z-50 w-[600px] max-w-[90vw] overflow-auto bg-white duration-200 focus-visible:outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=open]:slide-in-from-right-1/2 data-[state=closed]:slide-out-to-right-1/2">
          <Chat chatId={chatId} handleClose={handleClose} />
          <Dialog.Close className="absolute left-4 top-5 rounded-sm opacity-70 ring-offset-white transition-opacity hover:opacity-100 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
            <Cross2Icon className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

function Chat({
  chatId,
  handleClose,
}: {
  chatId: string;
  handleClose: () => void;
}) {
  const { thread } = useGetThread(new ObjectID(chatId));
  const { threadMessages } = useGetThreadMessages(chatId);
  const updateThreadMutation = useUpdateThread();
  const createThreadMessageMutation = useCreateThreadMessage();
  const [message, setMessage] = useState("");
  const app = useRealmApp();

  const sendMessage = () => {
    const user = thread?.otherDetails.users?.find(
      (user) => user._id.toString() === app.currentUser?.id
    );

    if (message.trim() !== "") {
      createThreadMessageMutation.mutate({
        params: {
          threadId: new ObjectID(chatId),
          text: message,
        },
        metadata: {
          type: user?.type || "internal",
        },
      });
    }
    setMessage("");
  };

  useEffect(() => {
    // console.log(thread);
    // console.log(thread?.otherDetails.unreadMessagesCount);
    if (!!thread && thread.otherDetails?.unreadMessagesCount > 0) {
      updateThreadMutation.mutate({
        update: {
          isRead: true,
          _id: thread._id,
        },
        _id: thread._id,
      });
    }
  }, [thread]);

  return (
    <div className="flex h-full flex-col">
      <div className="flex h-14 items-center justify-between bg-gray-100 pl-11 pr-5">
        <div className="text-lg font-semibold">{thread?.name}</div>
        {!!thread && <ThreadMembersButton thread={thread} isInternal={false} />}
      </div>

      {!!threadMessages ? (
        <ChatMessages
          messages={threadMessages}
          className="flex-1 overflow-auto border-t border-gray-300 p-4"
        />
      ) : (
        <div className="flex-1 bg-white" />
      )}

      <div className="p-4">
        <textarea
          id="message"
          name="message"
          rows={3}
          className={
            "block w-full rounded-md border-gray-300 shadow-sm placeholder:text-gray-400  focus:border-primary-main focus:ring-primary-main sm:text-sm"
          }
          onKeyDown={(event) => {
            if (event.key === "Enter" && message.trim() !== "") {
              event.preventDefault();
              sendMessage();
            }
          }}
          placeholder="Message.."
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          autoFocus
        />
      </div>
    </div>
  );
}
