import { Alert, AlertColor, Snackbar } from "@mui/material";
import React, { createContext, useState, useContext } from "react";

type SnackbarContextActions = {
  showSnackbar: (text: string, color: AlertColor) => void;
};

const SnackbarContext = createContext({} as SnackbarContextActions);

type Props = { children: React.ReactNode };

const CustomSnackbarProvider = ({ children }: Props) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [color, setColor] = useState<AlertColor>("info");

  const showSnackbar = (text: string, color: AlertColor) => {
    setMessage(text);
    setColor(color);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={color}>
          {message}
        </Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
};

export default CustomSnackbarProvider;

export const useSnackBar = (): SnackbarContextActions => {
  const snackbarCtx = useContext(SnackbarContext);

  if (!snackbarCtx) {
    throw new Error('useSnackBar must be used within an SnackBarProvider');
  }

  return snackbarCtx;
};