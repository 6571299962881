import clsx from "clsx";
import React from "react";
import { classNames } from "src/utils/functions/classes";

type Props = {
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  onPaste?: React.ClipboardEventHandler<HTMLTextAreaElement>;
  name: string;
  id?: string;
  label?: string;
  placeholder?: string;
  htmlFor?: string;
  error?: boolean;
  helperText?: string | false | undefined;
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement> | undefined;
  isEmbed?: boolean;
  disabled?: boolean;
  required?: boolean;
};

const TextArea = ({
  name,
  id,
  label,
  placeholder,
  htmlFor,
  value,
  onChange,
  onPaste,
  error = false,
  helperText = "",
  onKeyDown,
  onBlur,
  isEmbed = false,
  disabled = false,
  required = false,
}: Props) => {
  return (
    <div className="space-y-1">
      {!!label && label.trim() !== "" && (
        <div className="inline-flex items-start space-x-0.5">
          <label
            htmlFor={htmlFor}
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
          {required && (
            <span
              className={clsx(
                "text-md -mt-0.5 ",
                isEmbed ? "text-red-500" : "text-primary-main"
              )}
            >
              {"*"}
            </span>
          )}
        </div>
      )}

      <textarea
        rows={4}
        name={name}
        id={id}
        // className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        className={classNames(
          isEmbed
            ? "focus:border-embedPrimary focus:ring-embedPrimary"
            : "focus:border-primary-main focus:ring-primary-main",
          "focus:ring-[0.5px] block w-full rounded-md border-gray-300 shadow-sm placeholder:text-gray-300  disabled:bg-gray-100 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm"
        )}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onPaste={onPaste}
        disabled={disabled}
        value={value}
        required={required}
      />
      {!!helperText && (
        <p
          className={classNames(
            "text-xs",
            error ? "text-red-400" : "text-gray-500"
          )}
        >
          {helperText}
        </p>
      )}
    </div>
  );
};

export default TextArea;
