// import { useRealmApp } from "../store/RealmApp";

// export const buildFetcher = ({ queryKey } : any) => {
//   const app = useRealmApp();
//   const [functionName, parameters] = queryKey;
//   return app.currentUser.callFunction(functionName, parameters);
// };

export const buildFetcher = (
  app: any,
  meta: { functionName: string; parameters: object }
) => {
  return () => {
    return app.currentUser?.callFunction(meta.functionName, meta.parameters);
  };
};

export const buildFetcherWithoutParams = (app: any, functionName: string) => {
  return ({ queryKey }: any) => {
    // console.log(queryKey[1]);
    return app.currentUser?.callFunction(functionName, { _id: queryKey[1] });
  };
};

export const buildSetterWithoutExtraParams = (
  app: any,
  functionName: string
) => {
  return (params: object) => {
    return app.currentUser.callFunction(functionName, params);
  };
};

export const buildSetter = (
  app: any,
  functionName: string,
  fieldName: string
) => {
  return (options: any) => {
    return app.currentUser.callFunction(functionName, options[fieldName]);
  };
};

export const buildSetterWithoutParams = (app: any, functionName: string) => {
  return () => {
    return app.currentUser.callFunction(functionName);
  };
};

export const buildLauncher = (app: any, functionName: string) => {
  return (options: any) => {
    // console.log(options);
    return app.currentUser.callFunction(functionName, options);
  };
};


// Jan 2023

export const buildSetterWithInstanceIdProvided = (
  app: any,
  functionName: string,
  fieldName: string
) => {
  return (options: any) => {
    return app.currentUser.callFunction(functionName, {...options[fieldName], instanceId: app.currentUser.customData?.instanceId});
  };
};
