import { CodeBracketIcon, ListBulletIcon } from "@heroicons/react/24/outline";
import {
  DividerHorizontalIcon,
  FontBoldIcon,
  FontItalicIcon,
  Link1Icon,
  StrikethroughIcon,
} from "@radix-ui/react-icons";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import Typography from "@tiptap/extension-typography";
import { Editor, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React, { useCallback, useEffect } from "react";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "src/components/ui/dialog";
import { TextInput } from "src/components/ui/text-input";
import { classNames } from "src/utils/functions/classes";
import "./styles.scss";

type Props = {
  description: string;
  placeholder?: string;
  onBlur: (description: string) => void;
  isEditing?: boolean;
};

function isValidHttpsUrl(url: string): boolean {
  const regex = /^https:\/\/.+/;
  return regex.test(url);
}

export default function Description({
  description,
  placeholder = "Description...",
  onBlur,
  isEditing = true,
}: Props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [url, setUrl] = React.useState("");
  const [newUrl, setNewUrl] = React.useState("");
  const editor = useEditor({
    extensions: [
      StarterKit,
      Typography,
      Placeholder.configure({
        placeholder: placeholder,
        showOnlyWhenEditable: false,
      }),
      Link.configure({
        openOnClick: false,
        validate: (href) => /^https?:\/\//.test(href),
      }),
    ],
    editorProps: {
      attributes: {
        class:
          "prose outline-none ring-0 focus:outline-none focus:ring-0 border-none focus:border-none",
      },
    },
    onBlur: ({ editor }) => {
      onBlur(editor.getHTML());
    },
    content: description,
    editable: isEditing,
  });

  useEffect(() => {
    if (editor) {
      editor.commands.setContent(description);
    }
  }, [description]);

  useEffect(() => {
    if (!isOpen) {
      setUrl("");
      setNewUrl("");
    } else {
    }
  }, [isOpen]);

  useEffect(() => {
    editor?.setEditable(isEditing);
    if (editor && isEditing) {
      editor?.commands.focus();
    }
  }, [isEditing]);

  const [error, setError] = React.useState("");

  return (
    // <div className="p-3 rounded-md border">
    <>
      {editor && isEditing && (
        <MenuBar
          editor={editor}
          setModalOpen={setIsOpen}
          setUrl={setUrl}
          setNewUrl={setNewUrl}
        />
      )}
      <Dialog open={isOpen} onOpenChange={(value) => setIsOpen(value)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>URL</DialogTitle>
            <DialogDescription>
              Edit the URL for the selected text
            </DialogDescription>
          </DialogHeader>
          <div>
            <div className="space-y-1">
              <InputHeading heading="URL" />
              <TextInput
                value={newUrl}
                onChange={(e) => {
                  setNewUrl(e.target.value);
                  setError("");
                }}
              />
              <div className="text-xs text-red-500">{error}</div>
            </div>
          </div>
          <DialogFooter>
            <div className="flex space-x-2">
              {!!url && (
                <Button
                  variant="secondary"
                  onClick={() => {
                    editor?.commands.unsetLink();
                    setIsOpen(false);
                  }}
                >
                  Remove Link
                </Button>
              )}
              <Button
                disabled={!newUrl}
                onClick={() => {
                  if (!isValidHttpsUrl(newUrl)) {
                    setError(
                      "Please enter a valid URL that starts with 'https://'"
                    );
                    return;
                  }
                  if (editor) {
                    // console.log(newUrl);
                    editor?.commands.setLink({
                      href: newUrl,
                      target: "_blank",
                    });
                    setIsOpen(false);
                  }
                }}

                // disabled={url}
              >
                Save
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <div className="px-1">
        <EditorContent editor={editor} />
      </div>
    </>
  );
}
export const MenuBar = ({
  editor,
  setModalOpen,
  setUrl,
  setNewUrl,
}: {
  editor: Editor;
  setModalOpen: (value: boolean) => void;
  setUrl: (value: string) => void;
  setNewUrl: (value: string) => void;
}) => {
  const isActiveClassName = "text-gray-400";
  const buttons: {
    onClick: () => void;
    disabled: boolean | undefined;
    className: string;
    children: React.ReactNode;
  }[] = [
    {
      onClick: () => editor.chain().focus().toggleBold().run(),
      disabled: !editor.can().chain().focus().toggleBold().run(),
      className: editor.isActive("bold") ? isActiveClassName : "",
      children: <FontBoldIcon className="h-4 w-4" />,
    },
    {
      onClick: () => editor.chain().focus().toggleItalic().run(),
      disabled: !editor.can().chain().focus().toggleItalic().run(),
      className: editor.isActive("italic") ? isActiveClassName : "",
      children: <FontItalicIcon className="h-4 w-4" />,
    },
    {
      onClick: () => editor.chain().focus().toggleStrike().run(),
      disabled: !editor.can().chain().focus().toggleStrike().run(),
      className: editor.isActive("strike") ? isActiveClassName : "",
      children: <StrikethroughIcon className="h-4 w-4" />,
    },
    // {
    //   onClick: () => editor.chain().focus().toggleCode().run(),
    //   disabled: !editor.can().chain().focus().toggleCode().run(),
    //   className: editor.isActive("code") ? isActiveClassName : "",
    //   children: <Link1Icon className="h-5 w-5" />,
    // },
    // {
    //   onClick: () => editor.chain().focus().setParagraph().run(),
    //   disabled: false,
    //   className: editor.isActive("paragraph") ? isActiveClassName : "",
    //   children: < className="h-5 w-5" />,
    // },
    {
      onClick: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
      disabled: false,
      className: editor.isActive("heading", { level: 1 })
        ? isActiveClassName
        : "",
      children: <span className="font-mono">H</span>,
    },
    // {
    //   onClick: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
    //   disabled: false,
    //   className: editor.isActive("heading", { level: 2 })
    //     ? isActiveClassName
    //     : "",
    //   children: <span className="font-mono">H2</span>,
    // },
    // {
    //   onClick: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
    //   disabled: false,
    //   className: editor.isActive("heading", { level: 3 })
    //     ? isActiveClassName
    //     : "",
    //   children: (
    //     <div className="flex items-center">
    //       {/* <HeadingIcon className="h-3 w-3" /> */}
    //       <span className="font-mono">H3</span>
    //     </div>
    //   ),
    // },
    {
      onClick: () => editor.chain().focus().toggleBulletList().run(),
      disabled: false,
      className: editor.isActive("bulletList") ? isActiveClassName : "",
      children: <ListBulletIcon className="h-4 w-4" />,
    },
    {
      onClick: () => editor.chain().focus().toggleOrderedList().run(),
      disabled: false,
      className: editor.isActive("orderedList") ? isActiveClassName : "",
      children: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          // className="bi bi-list-ol"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z"
          />
          <path d="M1.713 11.865v-.474H2c.217 0 .363-.137.363-.317 0-.185-.158-.31-.361-.31-.223 0-.367.152-.373.31h-.59c.016-.467.373-.787.986-.787.588-.002.954.291.957.703a.595.595 0 0 1-.492.594v.033a.615.615 0 0 1 .569.631c.003.533-.502.8-1.051.8-.656 0-1-.37-1.008-.794h.582c.008.178.186.306.422.309.254 0 .424-.145.422-.35-.002-.195-.155-.348-.414-.348h-.3zm-.004-4.699h-.604v-.035c0-.408.295-.844.958-.844.583 0 .96.326.96.756 0 .389-.257.617-.476.848l-.537.572v.03h1.054V9H1.143v-.395l.957-.99c.138-.142.293-.304.293-.508 0-.18-.147-.32-.342-.32a.33.33 0 0 0-.342.338v.041zM2.564 5h-.635V2.924h-.031l-.598.42v-.567l.629-.443h.635V5z" />
        </svg>
      ),
    },
    {
      onClick: () => editor.chain().focus().toggleCodeBlock().run(),
      disabled: false,
      className: editor.isActive("codeBlock") ? isActiveClassName : "",
      children: <CodeBracketIcon className="h-4 w-4" />,
    },
    // {
    //   onClick: () => editor.chain().focus().toggleBlockquote().run(),
    //   disabled: false,
    //   className: editor.isActive("blockquote") ? isActiveClassName : "",
    //   children: <QuoteIcon className="h-4 w-4" />,
    // },
    {
      onClick: () => editor.chain().focus().setHorizontalRule().run(),
      disabled: false,
      className: "",
      children: <DividerHorizontalIcon className="h-4 w-4" />,
    },
    {
      onClick: useCallback(() => {
        if (editor) {
          const url = editor.getAttributes("link").href;
          console.log(url);
          setUrl(url);
          setNewUrl(url);
          setModalOpen(true);
          // const url = window.prompt("URL", url);

          // cancelled
          // if (url === null) {
          //   return;
          // }

          // empty
          // if (url === "") {
          //   editor.chain().focus().extendMarkRange("link").unsetLink().run();
          //   return;
          // }

          // update link
        }
      }, [editor]),
      className: editor?.isActive("link") ? isActiveClassName : "",
      children: <Link1Icon className="h-4 w-4" />,
      disabled: false,
    },
  ];

  if (!editor) {
    return null;
  }

  return (
    <>
      <div className="mb-3 flex flex-wrap gap-1">
        {buttons.map((button, index) => (
          <button
            tabIndex={-1}
            key={index}
            onClick={button.onClick}
            disabled={button.disabled}
            className={classNames(
              "inline-flex items-center justify-center rounded p-0.5 text-xs text-gray-300 hover:text-gray-400 focus:outline-none",
              button.className
            )}
          >
            {button.children}
          </button>
        ))}
      </div>
    </>
  );
};
