import { XMarkIcon } from "@heroicons/react/24/outline";

type Props = {
  onClick: () => void;
};

export const ModalCloseIcon = ({ onClick }: Props) => {
  return (
    <div
      className="group cursor-pointer rounded-full p-1.5 hover:bg-gray-50 shrink-0"
      onClick={onClick}
    >
      <XMarkIcon className="h-4 w-4 text-gray-300 group-hover:text-gray-500" />
    </div>
  );
};
