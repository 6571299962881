import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "src/utils/ui/ui";

const buttonVariants = cva(
  "select-none inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 focus:outline-none focus-visible:ring-1 ring-offset-1",
  // focus-visible:ring-[0.5px] focus-visible:ring-primary-main
  {
    variants: {
      variant: {
        default:
          "border border-primary-main bg-primary-main text-white shadow hover:bg-primary-600 focus-visible:ring-primary",
        destructive:
          "border bg-red-600 border-red-600 text-red-100 shadow-sm hover:bg-red-700 hover:border-red-700 focus-visible:ring-red-400",
        // outline:
        //   "border border-input bg-transparent shadow-sm hover:bg-accent hover:text-accent-foreground",
        secondary:
          "border bg-white text-gray-700 shadow-sm hover:bg-gray-50 border-input focus-visible:ring-gray-400",
        // "bg-gray-200 border-gray-200 text-gray-600 shadow-sm hover:bg-gray-300 hover:border-gray-300 hover:text-gray-700",
        ghost:
          "text-gray-600 hover:bg-accent hover:text-accent-foreground focus:ring-gray-400",
        embed:
          "bg-embedPrimary border-embedPrimary text-white shadow hover:bg-embedPrimary/90 border focus-visible:ring-embedPrimary",
        link: "text-primary hover:text-primary-600 px-0",
        secondaryLink:
          "text-gray-400 hover:text-gray-600 px-0 focus-visible:ring-gray-400",
      },
      size: {
        default: "h-8 px-2.5 py-1",
        sm: "h-7 rounded-md px-2 text-xs",
        lg: "h-9 rounded-md px-[14px]",
        icon: "h-8 w-8",
        fit: "px-0 py-0 h-fit",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
