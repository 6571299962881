import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { ObjectID } from "bson";
import { useNavigate } from "react-router-dom";
import { CustomFieldType } from "src/api/CustomFields/custom-fields";
import { useAccessToken } from "src/api/useAccessToken";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { useRealmApp } from "src/store/RealmApp";
import { sortByCreatedAt } from "src/utils/functions/sorts";
import { buildFetcher, buildSetter } from "../..";
import { JourneyStageObj } from "../../../components/Services/Journeys/Journey/types";
import {
  StageInList,
  TaskInList,
  TaskerType,
} from "../../../types/Services/Tasks";
import { projectKeys } from "../Projects/projects";
import { phaseKeys } from "../SharedSpace/phases";
import { TaskRequest, taskKeys } from "../Tasks/tasks";

export const journeyKeys = {
  journeys: () => ["journeys", "all"] as const,
  journey: (journeyId: ObjectID) => ["journeys", journeyId.toString()] as const,
  stages: (journeyId: ObjectID) =>
    [...journeyKeys.journey(journeyId), "stages", "all"] as const,

  allTasks: (journeyId: ObjectID) =>
    ["tasks", ...journeyKeys.journey(journeyId), "all"] as const,
  members: (journeyId: ObjectID) =>
    [...journeyKeys.journey(journeyId), "members", "all"] as const,
  stagesAndTasks: (journeyId: ObjectID) =>
    ["stagesAndTasks", journeyId] as const,
  keyDates: (journeyId: ObjectID) =>
    [journeyKeys.journey(journeyId), "keyDates"] as const,
  tasksProgress: (journeyId: ObjectID) =>
    [journeyKeys.journey(journeyId), "tasksProgress"] as const,
};

export type Status = "To-do" | "In Progress" | "Completed" | "Canceled";

export interface JourneyData {
  _id: ObjectID;
  name: string;
  templateId: ObjectID;
  customerId: ObjectID;
  sharedSpaceId?: ObjectID;
  status: Status;
  type: "internal" | "external";
  completionDate: Date;
  createdAt: Date;
  updatedAt: Date;
  completionPercent: number;
  customer?: {
    _id: ObjectID;
    name: string;
  };
}

export type JourneyWithUserTypeData = {
  journey: JourneyData;
  type: "internal" | "external";
};

export type JourneyTemplateTriggerEvents = "UPDATE";
export type JourneyTemplateTriggerObjects = "Deal";
export type JourneyTemplateTriggerChangeValues = "Closed";

export interface JourneyTemplateData {
  _id?: ObjectID;
  spaceId: ObjectID;
  name: string;
  internalDescription: string;
  type: TaskerType;
  internalPersonas: string[];
  externalPersonas: string[];
  internalPersonasWithTasks: string[];
  externalPersonasWithTasks: string[];
  createdBy?: { _id: ObjectID; name: string; email: string };
  createdAt?: Date;
  updatedAt?: Date;
  lastModifiedAt?: Date;
  trigger?: {
    event: JourneyTemplateTriggerEvents;
    object: JourneyTemplateTriggerObjects;
    changeValue: JourneyTemplateTriggerChangeValues;
  };
  hasTrigger: boolean;
  numberOfRuns: number;
  numberOfCompletedRuns: number;
  stageTemplateOrder?: ObjectID[];
  customFields?: {
    _id: ObjectID;
    name: string;
    description: string;
    type: CustomFieldType;
    crmProperty?: {
      id: string;
      integrationId: "hubspot" | "salesforce";
      readOnly: boolean;
    };
  }[];
}

// ANALYTICS

export type JourneyTaskProgress = {
  date: string;
  completedTasks?: number;
  plannedTasks?: number;
  forecastedTasks?: number;
};

export const useGetTasksProgressForJourney = (journeyId: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();

  return useQuery(
    journeyKeys.tasksProgress(new ObjectID(journeyId)),
    async (): Promise<JourneyTaskProgress[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/tasksProgressForJourney`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            journeyId,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};

// MEMBERS

// export const useGetJourneyMembers = (
//   journeyId: ObjectID
// ): {
//   journeyMembers: JourneyMember[] | undefined;
// } => {
//   const app = useRealmApp();
//   const meta = {
//     functionName: "getJourneyMembers",
//     parameters: {
//       journeyId: journeyId,
//     },
//   };

//   const { isError, error, data } = useQuery<JourneyMember[]>(
//     journeyKeys.members(journeyId),
//     buildFetcher(app, meta)
//   );

//   if (isError) {
//     console.log(error);
//   }

//   return {
//     journeyMembers: data,
//   };
// };

// JOURNEY
export const useGetJourneys = (): {
  journeys: JourneyData[] | undefined;
  isJourneysLoading: boolean;
  isJourneysError: boolean;
  journeysError: any;
} => {
  const app = useRealmApp();
  const meta = {
    functionName: "getJourneys",
    parameters: {
      instanceId: app.currentUser.customData.instanceId,
    },
  };

  const { isLoading, isError, error, data } = useQuery<JourneyData[]>(
    journeyKeys.journeys(),
    buildFetcher(app, meta)
  );

  let journeys;
  if (data) {
    journeys = sortByCreatedAt([...data]);
  }

  return {
    journeys: journeys,
    isJourneysLoading: isLoading,
    isJourneysError: isError,
    journeysError: error,
  };
};

export const useGetJourneyWithUserType = (
  _id: ObjectID
): {
  journeyWithUserType: JourneyWithUserTypeData | undefined;
} => {
  const app = useRealmApp();
  const meta = {
    functionName: "getJourneyWithUserType",
    parameters: {
      _id: _id,
    },
  };

  const { data } = useQuery<JourneyWithUserTypeData>(
    journeyKeys.journey(_id),
    buildFetcher(app, meta)
  );

  return {
    journeyWithUserType: data,
  };
};

export const useGetJourney = (
  _id: ObjectID
): {
  journey: JourneyData | undefined;
  isJourneyLoading: boolean;
  isJourneyError: boolean;
  journeyError: any;
} => {
  const app = useRealmApp();
  const queryClient = useQueryClient();
  const meta = {
    functionName: "getJourney",
    parameters: {
      _id: _id,
    },
  };

  const { isLoading, isError, error, data } = useQuery<JourneyData>(
    journeyKeys.journey(_id),
    buildFetcher(app, meta),
    {
      staleTime: Infinity,
    }
  );

  return {
    journey: data,
    isJourneyLoading: isLoading,
    isJourneyError: isError,
    journeyError: error,
  };
};

export const useDeleteJourney = () => {
  const app = useRealmApp();
  const functionName = "deleteJourney";
  const fieldName = "params";
  const queryClient = useQueryClient();
  const snackbarCtx = useSnackBar();
  const navigate = useNavigate();

  const deleteJourneyMutation = useMutation(
    buildSetter(app, functionName, fieldName),
    {
      onMutate: async ({
        params,
      }: {
        params: {
          _id: ObjectID;
        };
      }) => {
        const queryKey = journeyKeys.journeys();
        await queryClient.cancelQueries(queryKey);
        const previousJourneys = queryClient.getQueryData(queryKey);

        queryClient.setQueryData(queryKey, (stages: JourneyStageObj[] = []) =>
          stages.filter((s) => s._id !== params._id)
        );

        return { previousJourneys };
      },
      onError: (data, variables, context) => {
        queryClient.setQueryData(
          journeyKeys.journeys(),
          context?.previousJourneys
        );
      },
      onSettled: (data, error, variables) => {
        snackbarCtx.showSnackbar("Journey successfully deleted", "success");
        navigate(-1);
        queryClient.invalidateQueries(journeyKeys.journeys());
      },
    }
  );

  return deleteJourneyMutation;
};

// STAGE

export const useGetJourneyStages = (
  _id: ObjectID
): {
  stages: JourneyStageObj[] | undefined;
  isStagesLoading: boolean;
  isStagesError: boolean;
  stagesError: any;
} => {
  const app = useRealmApp();
  const meta = {
    functionName: "getStageAndTaskMap",
    parameters: {
      _id: _id,
    },
  };

  const { isLoading, isError, error, data } = useQuery<JourneyStageObj[]>(
    journeyKeys.stages(_id),
    buildFetcher(app, meta)
  );

  // let tempData;
  // if (data) {
  //   tempData = data.sort(function (a, b) {
  //     if (a.name && b.name) {
  //       return a.name.localeCompare(b.name);
  //     } else if (a.name) {
  //       return 1;
  //     } else {
  //       return -1;
  //     }
  //   });
  // }

  return {
    stages: data,
    isStagesLoading: isLoading,
    isStagesError: isError,
    stagesError: error,
  };
};

export const useAddStage = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const getValidAccessToken = useAccessToken();
  return useMutation({
    mutationFn: async ({
      params,
    }: {
      params: {
        name: string;
        journeyId: string;
      };
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/addStageToJourney`,
        {
          ...params,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onMutate: async (variables) => {
      const queryKey = journeyKeys.stages(
        new ObjectID(variables.params.journeyId)
      );
      await queryClient.cancelQueries(queryKey);
      const previousStages = queryClient.getQueryData(queryKey);
      const newStage: JourneyStageObj = {
        journeyId: new ObjectID(variables.params.journeyId),
        name: variables.params.name,
        instanceId: new ObjectID(app.currentUser.customData.instanceId.$oid),
        createdAt: new Date(),
        _id: undefined,
      };

      queryClient.setQueryData(queryKey, (stages: JourneyStageObj[] = []) => [
        ...stages,
        newStage,
      ]);

      return { previousStages };
    },
    onError: (data, variables, context) => {
      snackbarCtx.showSnackbar("Error creating stage", "error");
      queryClient.setQueryData(
        journeyKeys.stages(new ObjectID(variables.params.journeyId)),
        context?.previousStages
      );
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(
        journeyKeys.stages(new ObjectID(variables.params.journeyId))
      );
      queryClient.invalidateQueries(projectKeys.projects());
      queryClient.invalidateQueries(["projects", "slug"]);
    },
  });
};

// export const useAddStage = () => {
//   const app = useRealmApp();
//   const functionName = "addStageToJourney";
//   const fieldName = "params";
//   const queryClient = useQueryClient();

//   const addStageMutation = useMutation(
//     buildSetter(app, functionName, fieldName),
//     {
//       onMutate: async ({
//         params,
//       }: {
//         params: {
//           name: string;
//           journeyId: ObjectID;
//           instanceId: ObjectID;
//         };
//       }) => {
//         const queryKey = journeyKeys.stages(params.journeyId);
//         await queryClient.cancelQueries(queryKey);
//         const previousStages = queryClient.getQueryData(queryKey);
//         const newStage: JourneyStageObj = {
//           ...params,
//           createdAt: new Date(),
//           _id: undefined,
//         };

//         queryClient.setQueryData(queryKey, (stages: JourneyStageObj[] = []) => [
//           ...stages,
//           newStage,
//         ]);

//         return { previousStages };
//       },
//       onError: (data, variables, context) => {
//         queryClient.setQueryData(
//           journeyKeys.stages(variables.params.journeyId),
//           context?.previousStages
//         );
//       },
//       onSuccess: (data, variables) => {
//         queryClient.invalidateQueries(
//           journeyKeys.stages(variables.params.journeyId)
//         );
//       },
//     }
//   );

//   return addStageMutation;
// };

export const useGetStageAndTasksList = (
  journeyId: ObjectID
): {
  stagesAndTasks:
    | {
        stages: StageInList[];
        tasks: TaskInList[];
      }
    | undefined;
  isListLoading: boolean;
  isListError: boolean;
  listError: any;
} => {
  const app = useRealmApp();
  const meta = {
    functionName: "getStagesAndTasks",
    parameters: {
      journeyId: journeyId,
    },
  };

  const { isLoading, isError, error, data } = useQuery<{
    stages: StageInList[];
    tasks: TaskInList[];
  }>(journeyKeys.stagesAndTasks(journeyId), buildFetcher(app, meta));

  return {
    stagesAndTasks: data,
    isListLoading: isLoading,
    isListError: isError,
    listError: error,
  };
};

// TASK

// export const useGetStageTasks = (
//   journeyId: ObjectID,
//   _id: ObjectID | undefined
// ): {
//   tasks: TaskData[] | undefined;
//   isTasksLoading: boolean;
//   isTasksError: boolean;
//   tasksError: any;
// } => {
//   const app = useRealmApp();
//   const meta = {
//     functionName: "getTasksForJourneyStage",
//     parameters: {
//       _id: _id,
//     },
//   };

//   const { isLoading, isError, error, data } = useQuery<TaskData[]>(
//     journeyKeys.tasks(journeyId, _id),
//     buildFetcher(app, meta)
//   );

//   // let tempData;
//   // if (data) {
//   //   tempData = sortTasks(data);
//   // }

//   return {
//     tasks: data,
//     isTasksLoading: isLoading,
//     isTasksError: isError,
//     tasksError: error,
//   };
// };

// export const useGetJourneyTasks = (_id: ObjectID) => {
//   const app = useRealmApp();
//   const meta = {
//     functionName: "getTasksForJourney",
//     parameters: {
//       _id: _id,
//     },
//   };

//   return useQuery<TaskData[]>(
//     journeyKeys.allTasks(_id),
//     buildFetcher(app, meta)
//   );
// };

export const useAddJourneyTask = () => {
  const app = useRealmApp();
  const functionName = "addTaskToJourney";
  const fieldName = "task";
  const queryClient = useQueryClient();

  const addJourneyTaskMutation = useMutation(
    buildSetter(app, functionName, fieldName),
    {
      onMutate: async ({
        task,
        journeyId,
        stageId,
      }: {
        task: TaskRequest;
        journeyId: ObjectID;
        stageId: ObjectID;
      }) => {
        // const queryKey = journeyKeys.tasks(journeyId, stageId);
        // await queryClient.cancelQueries(queryKey);
        // const previousTasks = queryClient.getQueryData(queryKey);
        // const newTask = { _id: undefined, ...task };
        // queryClient.setQueryData(queryKey, (tasks: TaskData[] = []) => [
        //   ...tasks,
        //   newTask,
        // ]);
        // return { previousTasks };
      },
      onError: (data, variables, context) => {
        // queryClient.setQueryData(
        //   journeyKeys.tasks(variables.journeyId, variables.stageId),
        //   context?.previousTasks
        // );
      },
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries(
          taskKeys.journeyStageTasks(
            variables.journeyId.toString(),
            variables.stageId.toString()
          )
        );
      },
    }
  );
  return addJourneyTaskMutation;
};

export const useUpdateJourney = () => {
  const app = useRealmApp();
  const functionName = "updateJourney";
  const fieldName = "params";
  const queryClient = useQueryClient();
  const snackbarCtx = useSnackBar();

  const addJourneyTaskMutation = useMutation(
    buildSetter(app, functionName, fieldName),
    {
      onMutate: async ({
        params,
        metadata,
      }: {
        params: {
          _id: ObjectID;
          name?: string;
          stageOrder?: ObjectID[];
          customerId?: ObjectID;
          type?: "internal" | "external";
        };
        metadata?: {
          phaseId?: string;
        };
      }) => {},
      onError: (data, variables, context) => {
        snackbarCtx.showSnackbar("Error updating project", "error");
      },
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(
          journeyKeys.journey(variables.params._id)
        );
        if (variables.metadata?.phaseId) {
          queryClient.invalidateQueries(
            phaseKeys.journeys(variables.metadata.phaseId)
          );
        }
      },
    }
  );
  return addJourneyTaskMutation;
};

export const useUpdateJourneyStage = () => {
  const app = useRealmApp();
  const functionName = "updateJourneyStage";
  const fieldName = "params";
  const queryClient = useQueryClient();
  const snackbarCtx = useSnackBar();

  return useMutation(buildSetter(app, functionName, fieldName), {
    onMutate: async ({
      params,
      metadata,
    }: {
      params: {
        _id: ObjectID;
        name: string;
      };
      metadata: {
        journeyId: string;
      };
    }) => {},
    onError: (data, variables, context) => {
      snackbarCtx.showSnackbar("Error updating project", "error");
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(
        journeyKeys.journey(new ObjectID(variables.metadata.journeyId))
      );
      queryClient.invalidateQueries(projectKeys.projects());
      // if (variables.metadata?.phaseId) {
      //   queryClient.invalidateQueries(
      //     phaseKeys.journeys(variables.metadata.phaseId)
      //   );
      // }
    },
  });
};

// export const useUpdateJourneyTask = () => {
//   const app = useRealmApp();
//   type UpdateType = Partial<TaskData>;

//   const functionName = "updateTask";
//   const fieldName = "update";
//   const queryClient = useQueryClient();
//   const updateJourneyTaskMutation = useMutation(
//     buildSetter(app, functionName, fieldName),
//     {
//       onMutate: async ({
//         update,
//         stageId,
//         journeyId,
//       }: {
//         update: UpdateType;
//         stageId: ObjectID;
//         journeyId: ObjectID;
//         phaseId?: string;
//       }) => {
//         await queryClient.cancelQueries(journeyKeys.tasks(journeyId, stageId));

//         const tasksKey = taskKeys.journeyStageTasks(journeyId.toString(), stageId.toString());

//         const previousTask = queryClient.getQueryData<TaskData>([
//           "tasks",
//           update._id,
//         ]);
//         const newTask = { ...previousTask, ...update };
//         queryClient.setQueryData(["tasks", update._id], newTask);

//         const previousTasks = queryClient.getQueryData<TaskData[]>(tasksKey);
//         const newTasks = previousTasks
//           ? previousTasks.map((t) =>
//               t._id == update._id ? { ...t, ...update } : t
//             )
//           : [];
//         queryClient.setQueryData(tasksKey, newTasks);
//         return { previousTasks, previousTask };
//       },
//       // If the mutation fails, use the context returned from onMutate to roll back
//       onError: (err, update, context) => {
//         queryClient.setQueryData(
//           journeyKeys.tasks(update.journeyId, update.stageId),
//           context?.previousTasks
//         );
//         queryClient.setQueryData(
//           ["tasks", update.update._id],
//           context?.previousTask
//         );
//       },
//       // Always refetch after error or success:
//       onSettled: (data, error, update, variables) => {
//         // console.log("updated task");
//         if (update.phaseId) {
//           queryClient.invalidateQueries(taskKeys.phaseTasks(update.phaseId));
//         }
//         queryClient.invalidateQueries(
//           journeyKeys.tasks(update.journeyId, update.stageId)
//         );
//         queryClient.invalidateQueries(journeyKeys.journey(update.journeyId));
//         queryClient.invalidateQueries(["tasks", "all"]);
//         queryClient.invalidateQueries(["tasks", update.update._id]);
//       },
//     }
//   );
//   return updateJourneyTaskMutation;
// };
