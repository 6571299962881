import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { ObjectId } from "mongodb";
import { buildSetterWithInstanceIdProvided } from "src/api";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { useRealmApp } from "../../store/RealmApp";
import { CustomerResponseObj } from "../../types/Services/general";

export const customerKeys = {
  customers: (search: string = "", page: number = 0) =>
    ["customers", search, page] as const,
  
};

export type CustomerData = CustomerResponseObj;

type PaginatedCustomersData = {
  customers: CustomerData[];
  totalNumberOfPages: number;
  currentPageNo: number;
};

export const useGetCustomersWithDebouncing = ({
  search = "",
  pageNo = 0,
}: {
  search?: string;
  pageNo?: number;
}): {
  paginatedCustomers: PaginatedCustomersData | undefined;
  isError: boolean;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  // console.log(name)

  const app = useRealmApp();
  const meta = {
    functionName: "getCustomers",
    parameters: {
      instanceId: app.currentUser.customData.instanceId,
      search: search,
      pageNo: pageNo,
    },
  };

  const { data, isError, isFetching, isLoading, isSuccess } =
    useQuery<PaginatedCustomersData>(
      ["customers", search, pageNo],
      async () => {
        const response = await app.currentUser.callFunction(
          meta.functionName,
          meta.parameters
        );
        if (response.status === 403) {
          throw new Error("Need more characters");
        }
        return response;
      }
      // buildFetcher(app, meta),
      // {
      //   enabled: !!name,
      // }
    );

  // if (isError) {
  //   console.log(error)
  // } else {
  //   console.log(data)
  // }

  return {
    paginatedCustomers: data,
    isError,
    isFetching,
    isLoading,
    isSuccess,
  };
};

export const useCreateCustomer = () => {
  const app = useRealmApp();
  const functionName = "createCustomer";
  const fieldName = "params";
  const queryClient = useQueryClient();
  const snackbarCtx = useSnackBar();
  // const navigate = useNavigate();
  const createCustomerMutation = useMutation(
    buildSetterWithInstanceIdProvided(app, functionName, fieldName),
    {
      onMutate: async ({
        params,
      }: {
        params: {
          name: string;
          onChange?: (value: CustomerData) => void;
        };
      }) => {},
      onError: (data, variables, context) => {
        snackbarCtx.showSnackbar("Error creating customer", "error");
      },
      onSuccess: (data: { id: ObjectId }, variables) => {
        // console.log(data);
        // queryClient.invalidateQueries(["customers"]);
        // const customerName = variables.params.name;
        setTimeout(() => {
          queryClient.invalidateQueries(["customers"]);
        }, 1000);
        
        if (variables.params.onChange)
          variables.params.onChange({
            _id: data.id,
            name: variables.params.name,
          } as CustomerData);

        // add customer to local state
        // if (data.id)
        //   queryClient.setQueryData(
        //     ["customers", variables.params.name, 0],
        //     (oldData: CustomerData[] | undefined) => {
        //       if (oldData) {
        //         return [
        //           {
        //             _id: data.id,
        //             name: variables.params.name,
        //           } as CustomerData,
        //           ...oldData,
        //         ];
        //       }
        //       return [
        //         {
        //           _id: data.id,
        //           name: variables.params.name,
        //         } as CustomerData,
        //       ];
        //     }
        //   );

        // for (let i = 0; i < customerName.length; i++) {
        //   // revalidate all the pages that have the same search string
        //   // console.log(customerName.substring(0, i));
        //   queryClient.invalidateQueries([
        //     "customers",
        //     // customerName.substring(0, i),
        //   ]);
        // }

        // const tempData = data as {
        //   status: number;
        //   id: ObjectID;
        //   message: string;
        // };
        // snackbarCtx.showSnackbar("New knowledge resource created", "success");
        // queryClient.invalidateQueries(threadKeys.threads());
        // navigate(`/threads/${tempData.id.toString()}`, {
        //   state: { isEditable: true },
        // });
      },
    }
  );

  return createCustomerMutation;
};
