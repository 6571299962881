import { XMarkIcon } from "@heroicons/react/24/outline";
import Nango from "@nangohq/frontend";
import { CaretSortIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useGetInstanceCRMStatus } from "src/api/General/instance";
import {
  HubSpotProperty,
  useGetCompanyPropertiesForHubSpot,
  useGetDealPropertiesForHubSpot,
  useSetCompanyAndDealCustomPropertiesForHubSpot,
  useStartHubSpotImport,
} from "src/api/Integrations/hubspot";
import { Badge } from "src/components/ui/Badges/Badge";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { Button } from "src/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "src/components/ui/command";
import { Dialog, DialogContent, DialogTrigger } from "src/components/ui/dialog";
import { Loading } from "src/components/ui/loading";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import { useRealmApp } from "src/store/RealmApp";
import { BackToIntegrationsButton } from "../Integrations";

type Props = {};

export default function HubSpotIntegration({}: Props) {
  const { instance } = useGetInstanceCRMStatus();
  return (
    <div className="mx-auto mt-20 max-w-3xl px-8">
      <BackToIntegrationsButton />
      <div className="mt-4 flex w-full items-center gap-3">
        <div className="flex h-12 w-12 items-center justify-center rounded-md border shadow-sm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32px"
            height="32px"
            viewBox="0 0 24 24"
            fill="#f57722"
          >
            <path
              fill="#f57722"
              d="M17.1 8.6V6.2c.6-.3 1.1-.9 1.1-1.6v-.1c0-1-.8-1.8-1.8-1.8h-.1c-1 0-1.8.8-1.8 1.8v.1c0 .7.4 1.3 1.1 1.6v2.4c-.9.1-1.8.5-2.5 1.1L6.5 4.6c.3-1.1-.4-2.3-1.5-2.5s-2.2.3-2.5 1.4s.4 2.3 1.5 2.6c.5.1 1.1.1 1.6-.2l6.4 5c-1.2 1.8-1.2 4.1.1 5.9l-2 2c-.2 0-.3-.1-.5-.1c-.9 0-1.7.8-1.7 1.7S8.7 22 9.6 22s1.7-.8 1.7-1.7c0-.2 0-.3-.1-.5l1.9-1.9c2.3 1.7 5.6 1.3 7.3-1s1.3-5.6-1-7.3c-.6-.5-1.4-.9-2.3-1m-.8 7.8c-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7s2.7 1.2 2.7 2.7s-1.2 2.7-2.7 2.7"
            />
          </svg>
        </div>
        <div className="flex w-full items-center justify-between">
          <div>
            <h2 className="cursor-default text-2xl tracking-wide text-gray-900">
              HubSpot
            </h2>
            <p className="text-sm text-gray-500">
              Trigger projects in Sero when a deal closes in HubSpot
            </p>
          </div>
          <div>
            {!!instance && (
              <>
                {!!instance.crmIntegration &&
                instance.crmIntegration.integrationId === "hubspot" ? (
                  <>
                    {instance.crmIntegration.initialImportStatus ===
                    "Not Started" ? (
                      <Badge
                        color={"yellow"}
                        text="Waiting to Import"
                        size={"md"}
                      />
                    ) : (
                      <>
                        {instance.crmIntegration.initialImportStatus ===
                        "In Progress" ? (
                          <Badge color={"blue"} text="Importing" size={"md"} />
                        ) : (
                          <Badge color={"green"} text="Connected" size={"md"} />
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Badge color={"gray"} text="Not Connected" size={"md"} />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="mt-8">
        <HubSpotOAuth />
      </div>
    </div>
  );
}

function HubSpotOAuth() {
  let nango = new Nango({
    publicKey: String(process.env.REACT_APP_NANGO_ID),
  });
  const app = useRealmApp();
  const { instance } = useGetInstanceCRMStatus();

  return (
    <div className="rounded-md border p-6 shadow-sm">
      {!instance ? (
        <Loading className="my-14" />
      ) : (
        <>
          {/* <HubSpotImport /> */}

          {!instance.crmIntegration ? (
            <>
              <div>
                <div className="text-lg leading-none">Overview</div>
                <div className="pt-3">
                  <p className="text-sm text-gray-500">
                    The HubSpot integration allows you to import Companies,
                    Deals and Pipelines into Sero.
                  </p>

                  <p className="pt-2.5 text-sm text-gray-500">
                    Once you have made the integration, we will continuously
                    monitor for updates so that your data is always in sync. You
                    can set up automations such that when a Deal moves to a
                    certain stage in a HubSpot Pipeline, a project is created in
                    Sero with information from the Deal pre-populated in the
                    project.
                  </p>
                </div>
              </div>
              <div className="mt-4 flex justify-end">
                <Button
                  onClick={async () => {
                    const result = await nango
                      .auth(
                        "hubspot",
                        `${app.currentUser.customData.instanceId.$oid}`
                      )
                      .catch((error) => {
                        console.error(error);
                      });
                  }}
                >
                  Connect
                </Button>
              </div>
            </>
          ) : (
            <>
              {instance.crmIntegration.initialImportStatus === "Not Started" ? (
                <HubSpotImport />
              ) : (
                <div>
                  {instance.crmIntegration.initialImportStatus ===
                  "In Progress" ? (
                    <>
                      <div className="text-lg leading-none">
                        Import in Progress
                      </div>
                      <div className="pt-3">
                        <p className="text-sm text-gray-500">
                          Your HubSpot account is connected and the import is in
                          progress. This may take a few minutes depending on the
                          size of your data.
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex items-center justify-between">
                        <div className="text-lg leading-none">
                          Integration is Active
                        </div>
                      </div>
                      <div className="pt-3">
                        <p className="text-sm text-gray-500">
                          Your HubSpot account is connected, the data has been
                          imported and the integration is active. If you wish to
                          re-import data, please reach out to us.
                        </p>
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

const defaultCompanyProperties = ["name", "id"];

const defaultDealProperties = [
  "dealname",
  "id",
  "dealstage",
  "pipeline",
  "amount",
];

function HubSpotImport() {
  const [isImporting, setIsImporting] = useState(false);
  const [step, setStep] = useState(0);
  return (
    <>
      <div>
        <div className="flex items-center justify-between">
          <div className="text-lg leading-none">Import Your Data</div>
        </div>
        <div className="pt-3">
          <p className="text-sm text-gray-500">
            Now that your HubSpot account is connected, you can import your
            Companies, Deals and Pipelines into Sero. As part of the import
            process, you can select the properties you want to import for
            Companies and Deals.
          </p>
        </div>

        <div className="mt-4 flex justify-end">
          <Dialog open={isImporting} onOpenChange={setIsImporting}>
            <DialogTrigger asChild>
              <Button
                onClick={() => {
                  setIsImporting(true);
                }}
              >
                Start Import
              </Button>
            </DialogTrigger>
            <DialogContent className="top-[12%] max-w-5xl translate-y-0 overflow-auto p-0">
              <div className="flex flex-col divide-y overflow-auto">
                <div className="shrink-0 px-6 py-3 text-lg font-semibold">
                  HubSpot Data Import
                </div>
                <div className="flex flex-1 divide-x overflow-auto">
                  <div className="w-64 shrink-0 p-6">
                    <div className="space-y-3">
                      <div
                        className={clsx(
                          "flex items-center space-x-3",
                          step === 0 ? "text-gray-800" : "text-gray-400"
                        )}
                      >
                        <div
                          className={clsx(
                            "flex h-5 w-5 items-center justify-center rounded-full border",
                            step === 0 ? "border-gray-800" : "border-gray-400"
                          )}
                        >
                          <div className="text-xs">1</div>
                        </div>
                        <div>Company Properties</div>
                        {/* <CheckIcon className="h-5 w-5" /> */}
                      </div>
                      <div
                        className={clsx(
                          "flex items-center space-x-3",
                          step === 1 ? "text-gray-800" : "text-gray-400"
                        )}
                      >
                        <div
                          className={clsx(
                            "flex h-5 w-5 items-center justify-center rounded-full border",
                            step === 1 ? "border-gray-800" : "border-gray-400"
                          )}
                        >
                          <div className="text-xs">2</div>
                        </div>
                        <div>Deal Properties</div>
                      </div>

                      <div
                        className={clsx(
                          "flex items-center space-x-3",
                          step === 2 ? "text-gray-800" : "text-gray-400"
                        )}
                      >
                        <div
                          className={clsx(
                            "flex h-5 w-5 items-center justify-center rounded-full border",
                            step === 2 ? "border-gray-800" : "border-gray-400"
                          )}
                        >
                          <div className="text-xs">3</div>
                        </div>
                        <div>Start Import</div>
                      </div>
                    </div>
                  </div>
                  <HubSpotImportSteps
                    step={step}
                    setStep={setStep}
                    setIsImporting={setIsImporting}
                  />
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </>
  );
}

function HubSpotImportSteps({
  step,
  setStep,
  setIsImporting,
}: {
  step: number;
  setStep: (step: number) => void;
  setIsImporting: (isImporting: boolean) => void;
}) {
  const { data: companyProperties } = useGetCompanyPropertiesForHubSpot();
  const { data: dealProperties } = useGetDealPropertiesForHubSpot();
  const [selectedCompanyProperties, setSelectedCompanyProperties] = useState<
    HubSpotProperty[]
  >([]);
  const [selectedDealProperties, setSelectedDealProperties] = useState<
    HubSpotProperty[]
  >([]);
  const [companyPropertiesOpen, setCompanyPropertiesOpen] = useState(false);
  const [dealPropertiesOpen, setDealPropertiesOpen] = useState(false);
  const setPropertiesMutation =
    useSetCompanyAndDealCustomPropertiesForHubSpot();
  const startHubSpotImportMutation = useStartHubSpotImport();
  useEffect(() => {
    if (setPropertiesMutation.isSuccess) {
      setStep(2);
    }
  }, [setPropertiesMutation.isSuccess]);

  useEffect(() => {
    if (startHubSpotImportMutation.isSuccess) {
      setIsImporting(false);
    }
  }, [startHubSpotImportMutation.isSuccess]);

  if (step === 0) {
    return (
      <div className="w-full p-6 pl-7">
        <div className="space-y-8">
          <div>
            <div className="text-2xl">Company Properties</div>
            <div className="text-sm text-gray-500">
              Select the properties you want to import for Companies. Company
              Name is imported by default.
            </div>
          </div>
          <div className="space-y-2">
            <div className="space-y-1">
              <InputHeading heading="Company Properties" />
              <Popover
                modal={true}
                open={companyPropertiesOpen}
                onOpenChange={setCompanyPropertiesOpen}
              >
                <PopoverTrigger asChild>
                  <Button
                    disabled={
                      companyProperties?.filter((p) => {
                        return (
                          !selectedCompanyProperties.some(
                            (selected) => selected.name === p.name
                          ) && !defaultCompanyProperties.includes(p.name)
                        );
                      }).length === 0 || !companyProperties
                    }
                    variant="secondary"
                    size="lg"
                    role="combobox"
                    aria-expanded={companyPropertiesOpen}
                    className="w-72 justify-between space-x-2 truncate disabled:cursor-not-allowed"
                  >
                    <div className="flex items-center space-x-2">
                      <div className="font-normal text-gray-300">
                        Company Property
                      </div>
                    </div>
                    <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent
                  className="pointer-events-auto min-w-[200px] max-w-[700px] p-0"
                  align="start"
                >
                  <Command>
                    <CommandInput
                      placeholder="Search company property..."
                      className="h-9"
                    />
                    <CommandEmpty>No company property found.</CommandEmpty>
                    <CommandGroup>
                      {companyProperties
                        ?.filter((p) => {
                          return (
                            !selectedCompanyProperties.some(
                              (selected) => selected.name === p.name
                            ) && !defaultCompanyProperties.includes(p.name)
                          );
                        })
                        .map((property) => (
                          <CommandItem
                            key={property.name}
                            value={property.label}
                            onSelect={() => {
                              setSelectedCompanyProperties([
                                ...selectedCompanyProperties,
                                property,
                              ]);
                              setCompanyPropertiesOpen(false);
                            }}
                            className="gap-2"
                          >
                            <span className="truncate">{property.label}</span>
                          </CommandItem>
                        ))}
                      {/* {isLoading && <div>Loading..</div>} */}
                    </CommandGroup>
                  </Command>
                </PopoverContent>
              </Popover>
            </div>
            <div className="flex flex-wrap gap-2 pt-3">
              <Badge
                clickable={false}
                text={"Company Name"}
                color="gray"
                size={"lg"}
              />
              {selectedCompanyProperties.map((property) => (
                <div key={property.name} className="max-w-full">
                  <Badge
                    clickable={true}
                    text={property.label}
                    key={property.name}
                    onIconClick={() => {
                      setSelectedCompanyProperties(
                        selectedCompanyProperties.filter(
                          (selected) => selected.name !== property.name
                        )
                      );
                    }}
                    Icon={XMarkIcon}
                    color="gray"
                    size={"lg"}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-end">
            <Button
              onClick={() => {
                setStep(1);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    );
  } else if (step === 1) {
    return (
      <div className="w-full p-6 pl-7">
        <div className="space-y-8">
          <div>
            <div className="text-2xl">Deal Properties</div>
            <div className="text-sm text-gray-500">
              Select the properties you want to import for Deals. Deal Name,
              Deal Stage, Pipeline and Amount are imported by default.
            </div>
          </div>
          <div className="space-y-2">
            <div className="space-y-1">
              <InputHeading heading="Deal Properties" />
              <div>
                <Popover
                  modal={true}
                  open={dealPropertiesOpen}
                  onOpenChange={setDealPropertiesOpen}
                >
                  <PopoverTrigger asChild>
                    <Button
                      disabled={
                        dealProperties?.filter((p) => {
                          return (
                            !selectedDealProperties.some(
                              (selected) => selected.name === p.name
                            ) && !defaultDealProperties.includes(p.name)
                          );
                        }).length === 0 || !dealProperties
                      }
                      variant="secondary"
                      size="lg"
                      role="combobox"
                      aria-expanded={dealPropertiesOpen}
                      className="w-72 justify-between space-x-2 truncate disabled:cursor-not-allowed"
                    >
                      <div className="flex items-center space-x-2">
                        <div className="font-normal text-gray-300">
                          Deal Property
                        </div>
                      </div>
                      <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent
                    className="pointer-events-auto min-w-[200px] max-w-[700px] p-0"
                    align="start"
                  >
                    <Command>
                      <CommandInput
                        placeholder="Search deal property..."
                        className="h-9"
                      />
                      <CommandEmpty>No deal property found.</CommandEmpty>
                      <CommandGroup>
                        {dealProperties
                          ?.filter((p) => {
                            return (
                              !selectedDealProperties.some(
                                (selected) => selected.name === p.name
                              ) && !defaultDealProperties.includes(p.name)
                            );
                          })
                          .map((property) => (
                            <CommandItem
                              key={property.name}
                              value={property.label}
                              onSelect={() => {
                                setSelectedDealProperties([
                                  ...selectedDealProperties,
                                  property,
                                ]);
                                setDealPropertiesOpen(false);
                              }}
                              className="gap-2"
                            >
                              <span className="truncate">{property.label}</span>
                            </CommandItem>
                          ))}
                      </CommandGroup>
                    </Command>
                  </PopoverContent>
                </Popover>
              </div>
            </div>
            <div className="flex flex-wrap gap-2 pt-3">
              <Badge
                clickable={false}
                text={"Deal Name"}
                color="gray"
                size={"lg"}
              />
              <Badge
                clickable={false}
                text={"Deal Stage"}
                color="gray"
                size={"lg"}
              />
              <Badge
                clickable={false}
                text={"Pipeline"}
                color="gray"
                size={"lg"}
              />
              <Badge
                clickable={false}
                text={"Amount"}
                color="gray"
                size={"lg"}
              />
              {selectedDealProperties.map((property) => (
                <div key={property.name} className="max-w-full">
                  <Badge
                    clickable={true}
                    text={property.label}
                    key={property.name}
                    onIconClick={() => {
                      setSelectedDealProperties(
                        selectedDealProperties.filter(
                          (selected) => selected.name !== property.name
                        )
                      );
                    }}
                    Icon={XMarkIcon}
                    color="gray"
                    size={"lg"}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-between">
            <Button
              key={"back"}
              onClick={() => {
                setStep(0);
              }}
              variant="secondary"
            >
              Back
            </Button>
            <Button
              disabled={setPropertiesMutation.isLoading}
              onClick={() => {
                setPropertiesMutation.mutate({
                  companyProperties: selectedCompanyProperties,
                  dealProperties: selectedDealProperties,
                });
              }}
            >
              {setPropertiesMutation.isLoading
                ? "Confirming Properties.."
                : "Confirm Properties"}
            </Button>
          </div>
        </div>
      </div>
    );
  } else if (step === 2) {
    return (
      <div className="w-full p-6 pl-7">
        <div className="space-y-6">
          <div>
            <div className="text-2xl">Confirm Import Start</div>
          </div>
          <div>
            <div className="text-base text-gray-500">
              You have successfully set up the properties to import for
              Companies and Deals. Click the button below to start the import.
            </div>
          </div>
          <div className="flex justify-end">
            <Button
              onClick={() => {
                startHubSpotImportMutation.mutate();
              }}
            >
              {startHubSpotImportMutation.isLoading
                ? "Starting Import.."
                : "Start Import"}
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
