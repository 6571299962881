import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { JourneyTemplateData } from "src/api/Services/Journeys/journeys";
import {
  useGetJourneyTemplate,
  useUpdateJourneyTemplate,
} from "src/api/Services/Tasks/journeys";
import { useGetSpace } from "src/api/Spaces/spaces";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/components/ui/tabs";
import { SpaceNameAndSymbol } from "src/pages/spaces/spaces";
import JourneyTemplateEdit from "./JourneyTemplateEdit";
import { JourneyTemplateContextProvider } from "./Template/TemplateEdit/Stages/JourneyTemplateContextProvider";
import TemplateSettings from "./Template/TemplateSettings/TemplateSettings";

const JourneyTemplate = () => {
  const navigate = useNavigate();
  let { journeyTemplateId } = useParams();

  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const [value, setValue] = useState(tab === "analytics" ? 1 : 0);
  const { journeyTemplate } = useGetJourneyTemplate(String(journeyTemplateId));

  const [selectedIndex, setSelectedIndex] = useState(0);

  // const tabs: TabsProps["tabs"] = [
  //   {
  //     name: "Builder",
  //     panel: (

  //     ),
  //     id: "template",
  //   },
  //   // {
  //   //   name: "Analytics",
  //   //   panel: <JourneyTemplateAnalytics />,
  //   //   id: "analytics",
  //   // },
  //   {
  //     name: "Settings",
  //     panel: <TemplateSettings />,
  //     id: "settings",
  //   },
  // ];

  return (
    // <Container maxWidth="xl">
    <div className="flex h-full flex-col space-y-2 overflow-y-auto">
      <div className="h-12 items-center justify-between space-y-2 border-b border-gray-200/[.65] px-8 sm:flex sm:space-y-0">
        <div className="flex items-center space-x-2 text-sm">
          {journeyTemplate?.spaceId && (
            <SpaceSymbol spaceId={journeyTemplate.spaceId?.toString()} />
          )}
          <ChevronRightIcon className="h-3 w-3 text-gray-500" />
          <Link to={`/spaces/${journeyTemplate?.spaceId}/templates`}>
            <div className="text-gray-800">{"Templates"}</div>
          </Link>
          <ChevronRightIcon className="h-3 w-3 text-gray-500" />
          <Link to={`/spaces/${journeyTemplate?.spaceId}/templates/projects`}>
            <div className="text-gray-800">{"Projects"}</div>
          </Link>
          <ChevronRightIcon className="h-3 w-3 text-gray-500" />
          <div className="cursor-default text-gray-800">
            {journeyTemplate?.name}
          </div>
        </div>
      </div>

      {/* <div className="px-8">
        <Breadcrumbs
          breadcrumbs={[
            { title: "Project Templates", route: "/templates/projects" },
            {
              title: !!journeyTemplate ? journeyTemplate.name : "",
            },
          ]}
        />
        <div className="items-start justify-between space-y-2 sm:flex sm:space-y-0">
          <EditablePageHeading journeyTemplate={journeyTemplate} />
        </div>
      </div> */}
      <Tabs
        defaultValue="builder"
        className="flex flex-1 flex-col overflow-y-auto pt-1"
      >
        <TabsList className="border-gray-200/70 px-8">
          <TabsTrigger className="font-normal text-gray-500/80" value="builder">
            <span>Builder</span>
          </TabsTrigger>
          <TabsTrigger
            className="font-normal text-gray-500/80"
            value="settings"
          >
            <span>Settings</span>
          </TabsTrigger>
        </TabsList>
        <div className="flex-1 overflow-auto">
          <TabsContent value="builder" className="h-full overflow-auto">
            <JourneyTemplateContextProvider showSubTasks={true}>
              {!!journeyTemplate && (
                <JourneyTemplateEdit journeyTemplate={journeyTemplate} />
              )}
            </JourneyTemplateContextProvider>
          </TabsContent>
          <TabsContent value="settings" className="h-full overflow-auto">
            <TemplateSettings />
          </TabsContent>
        </div>
      </Tabs>
      {/* <Tab.Group
          selectedIndex={selectedIndex}
          onChange={(index) => {
            setSelectedIndex(index);
          }}
          manual
        >
          <div className="px-8">
            <div className="border-b border-gray-200">
              <Tab.List className="-mb-px flex space-x-10">
                {tabs.map((t) => (
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        "flex whitespace-nowrap border-b-2 py-4 text-sm font-medium focus-visible:outline-none",
                        selected
                          ? "border-primary-main text-primary-main"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                      )
                    }
                    key={t.id}
                  >
                    {({ selected }) => (
                      <>
                        {t.name}
                        {t.count ? (
                          <span
                            className={classNames(
                              selected
                                ? "bg-primary-100 text-primary-600"
                                : "bg-gray-100 text-gray-900",
                              "ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block"
                            )}
                          >
                            {t.count}
                          </span>
                        ) : null}
                      </>
                    )}
                  </Tab>
                ))}
              </Tab.List>
            </div>
          </div>
          <Tab.Panels className={"flex flex-1 flex-col overflow-auto px-3"}>
            {tabs.map((t) => (
              <Tab.Panel
                className={
                  "flex flex-1 flex-col overflow-y-auto focus:outline-none"
                }
                key={t.id}
              >
                {t.panel}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group> */}
    </div>
    // <Grid
    //   container
    //   alignItems="center"
    //   justifyContent={"center"}
    //   spacing={3}
    //   paddingX={3}
    // >
    //   <Grid item xs={12}>
    //     <Back
    //       onClick={() => {
    //         navigate({
    //           pathname: `/journeys`,
    //           search: createSearchParams({
    //             tab: "templates",
    //           }).toString(),
    //         });
    //       }}
    //       backText={"Back to Templates"}
    //     />
    //   </Grid>
    //   <Grid item xs={12}>
    //     <Box sx={{ width: "100%" }}>
    //       <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
    //         <Tabs
    //           value={value}
    //           onChange={handleChange}
    //           aria-label="basic tabs example"
    //         >
    //           <Tab sx={{ minWidth: tabWidth }} label="Template" />
    //           <Tab sx={{ minWidth: tabWidth }} label="Analytics" />
    //           <Tab sx={{ minWidth: tabWidth }} label="Settings" />
    //         </Tabs>
    //       </Box>
    //       <TabPanel value={value} index={0}>
    //         <JourneyTemplateEdit />
    //       </TabPanel>
    //       <TabPanel value={value} index={1}>
    //         <JourneyTemplateAnalytics />
    //       </TabPanel>
    //       <TabPanel value={value} index={2}>
    //         <TemplateTriggers />
    //       </TabPanel>
    //     </Box>
    //   </Grid>
    // </Grid>
    // </Container>
  );
};

export default JourneyTemplate;

function SpaceSymbol({ spaceId }: { spaceId: string }) {
  const { data: space } = useGetSpace(spaceId);
  return (
    <>{space && <SpaceNameAndSymbol color={space.color} name={space.name} />}</>
  );
}

function EditablePageHeading({
  journeyTemplate,
}: {
  journeyTemplate: JourneyTemplateData | undefined;
}) {
  const [heading, setHeading] = useState(journeyTemplate?.name ?? "");
  const updateJourneyTemplateMutation = useUpdateJourneyTemplate();

  useEffect(() => {
    setHeading(journeyTemplate?.name ?? "");
  }, [journeyTemplate]);

  return (
    <>
      {!!journeyTemplate ? (
        <div className="mt-2 flex-1">
          <input
            className="w-full max-w-xl border-b-2 border-transparent text-2xl font-medium leading-7
            tracking-tight text-gray-900 outline-none hover:border-primary-main focus:border-primary-main focus:outline-none focus:ring-0"
            value={heading}
            onChange={(e) => {
              setHeading(e.target.value);
            }}
            onBlur={() => {
              if (
                !!journeyTemplate &&
                heading.trim() !== journeyTemplate.name &&
                journeyTemplate._id
              ) {
                updateJourneyTemplateMutation.mutate({
                  params: { _id: journeyTemplate._id, name: heading },
                  journeyTemplateId: journeyTemplate?._id,
                });
              }
            }}
          />
        </div>
      ) : (
        <div className="mt-2 h-8 w-40 rounded bg-gray-200" />
      )}
    </>
  );
}
