import { ChevronLeftIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useGetInstance } from "src/api/General/instance";
import { useGetSettings } from "src/api/General/settings";
import { Badge } from "src/components/ui/badge";
import { Button } from "src/components/ui/button";
import { Loading } from "src/components/ui/loading";
import { useRealmApp } from "src/store/RealmApp";

export default function Integrations() {
  const { data: settings } = useGetSettings();
  const { instance } = useGetInstance();
  return (
    <div>
      <div className="flex h-12 items-center border-b border-gray-200/70 px-8 text-sm">
        {instance?.name}
      </div>
      <div className="mx-auto max-w-2xl pt-20">
        <div className="space-y-1 border-gray-200/70 pb-2">
          <div className="text-xl font-medium">Integrations</div>
          <div className="text-sm font-light text-gray-600">
            Connect Sero with your essential tools to create powerful workflows
          </div>
        </div>
      <div className="mt-8">
        {!!settings ? (
          <div className="grid grid-cols-2 gap-4">
            <IntegrationCard
              Icon={
                <svg
                  width="22px"
                  height="22px"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                >
                  <g fill-rule="evenodd" clip-rule="evenodd">
                    <path
                      fill="#E01E5A"
                      d="M2.471 11.318a1.474 1.474 0 001.47-1.471v-1.47h-1.47A1.474 1.474 0 001 9.846c.001.811.659 1.469 1.47 1.47zm3.682-2.942a1.474 1.474 0 00-1.47 1.471v3.683c.002.811.66 1.468 1.47 1.47a1.474 1.474 0 001.47-1.47V9.846a1.474 1.474 0 00-1.47-1.47z"
                    />
                    <path
                      fill="#36C5F0"
                      d="M4.683 2.471c.001.811.659 1.469 1.47 1.47h1.47v-1.47A1.474 1.474 0 006.154 1a1.474 1.474 0 00-1.47 1.47zm2.94 3.682a1.474 1.474 0 00-1.47-1.47H2.47A1.474 1.474 0 001 6.153c.002.812.66 1.469 1.47 1.47h3.684a1.474 1.474 0 001.47-1.47z"
                    />
                    <path
                      fill="#2EB67D"
                      d="M9.847 7.624a1.474 1.474 0 001.47-1.47V2.47A1.474 1.474 0 009.848 1a1.474 1.474 0 00-1.47 1.47v3.684c.002.81.659 1.468 1.47 1.47zm3.682-2.941a1.474 1.474 0 00-1.47 1.47v1.47h1.47A1.474 1.474 0 0015 6.154a1.474 1.474 0 00-1.47-1.47z"
                    />
                    <path
                      fill="#ECB22E"
                      d="M8.377 9.847c.002.811.659 1.469 1.47 1.47h3.683A1.474 1.474 0 0015 9.848a1.474 1.474 0 00-1.47-1.47H9.847a1.474 1.474 0 00-1.47 1.47zm2.94 3.682a1.474 1.474 0 00-1.47-1.47h-1.47v1.47c.002.812.659 1.469 1.47 1.47a1.474 1.474 0 001.47-1.47z"
                    />
                  </g>
                </svg>
              }
              name={"Slack"}
              description={
                "Receive updates from Sero projects in your Slack channels or via the Sero Slack app."
              }
              route={"/settings/integrations/slack"}
            />
            <IntegrationCard
              Icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22px"
                  height="22px"
                  viewBox="0 0 24 24"
                  fill="#f57722"
                >
                  <path
                    fill="#f57722"
                    d="M17.1 8.6V6.2c.6-.3 1.1-.9 1.1-1.6v-.1c0-1-.8-1.8-1.8-1.8h-.1c-1 0-1.8.8-1.8 1.8v.1c0 .7.4 1.3 1.1 1.6v2.4c-.9.1-1.8.5-2.5 1.1L6.5 4.6c.3-1.1-.4-2.3-1.5-2.5s-2.2.3-2.5 1.4s.4 2.3 1.5 2.6c.5.1 1.1.1 1.6-.2l6.4 5c-1.2 1.8-1.2 4.1.1 5.9l-2 2c-.2 0-.3-.1-.5-.1c-.9 0-1.7.8-1.7 1.7S8.7 22 9.6 22s1.7-.8 1.7-1.7c0-.2 0-.3-.1-.5l1.9-1.9c2.3 1.7 5.6 1.3 7.3-1s1.3-5.6-1-7.3c-.6-.5-1.4-.9-2.3-1m-.8 7.8c-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7s2.7 1.2 2.7 2.7s-1.2 2.7-2.7 2.7"
                  />
                </svg>
              }
              name={"HubSpot"}
              description={
                "Trigger projects in Sero when a deal closes in HubSpot."
              }
              route={"/settings/integrations/hubspot"}
              disabled={!settings?.view.integrations.crm}
            />
          </div>
        ) : (
          <Loading className="mt-20" />
        )}
      </div>
    </div>
    </div>
  );
}

export function BackToIntegrationsButton() {
  const navigate = useNavigate();
  return (
    <Button
      className="space-x-2 px-0"
      variant={"secondaryLink"}
      onClick={() => {
        navigate("/settings/integrations");
      }}
    >
      <ChevronLeftIcon className="h-4 w-4" />
      <span>Integrations</span>
    </Button>
  );
}

function IntegrationCard({
  name,
  Icon,
  description,
  route,
  disabled,
}: {
  name: string;
  Icon: React.ReactNode;
  description: string;
  route: string;
  disabled?: boolean;
}) {
  const navigate = useNavigate();
  return (
    <div
      className={clsx(
        "space-y-2 rounded-md border p-4 shadow-sm",
        disabled ? "cursor-not-allowed" : "cursor-pointer hover:bg-gray-50"
      )}
      onClick={() => {
        navigate(route);
      }}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          {Icon}
          <div className="text-xl leading-none">{name}</div>
        </div>
        {disabled && <Badge variant={"gray"}>Disabled</Badge>}
      </div>
      <div className="text-sm font-light text-gray-600">
        {description}
      </div>
    </div>
  );
}

function HubSpotTicketsButton() {
  const app = useRealmApp();
  // const redirect_uri = `${process.env.REACT_APP_URL}/settings/slack/authentication`;
  const url = `https://app.hubspot.com/oauth/authorize?client_id=b67334cc-ff9c-450c-94f4-fd2b34d2ff2d&redirect_uri=${process.env.REACT_APP_URL}/settings/hubspot/authentication&scope=tickets`;

  return (
    <button className="rounded-md border border-gray-300 px-2 py-2">
      <a href={url} className="flex items-center justify-center space-x-2">
        <img
          alt="Add to HubSpot"
          className="h-5"
          // width="139"
          src="/static/hubspot.svg"
          // srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
        />
        <div>
          <span className="text-sm font-medium">Connect to </span>
          <span className="text-sm font-bold">HubSpot</span>
        </div>
      </a>
    </button>
  );
}
