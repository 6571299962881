import { EllipsisHorizontalIcon, TrashIcon } from "@heroicons/react/24/outline";
import { ObjectID } from "bson";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useGetCustomFields } from "src/api/CustomFields/custom-fields";
import {
  useDeleteJourneyTemplate,
  useDuplicateJourneyTemplate,
} from "src/api/Services/Journeys/journeyTemplates";
import { JourneyTemplateData } from "src/api/Services/Journeys/journeys";
import {
  useGetJourneyTemplate,
  useUpdateJourneyTemplate,
} from "src/api/Services/Tasks/journeys";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { Button } from "src/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "src/components/ui/command";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import { TextInput } from "src/components/ui/text-input";
type Props = {};

export default function TemplateSettings({}: Props) {
  const { journeyTemplateId } = useParams();
  const { journeyTemplate } = useGetJourneyTemplate(String(journeyTemplateId));
  const updateJourneyTemplateMutation = useUpdateJourneyTemplate();
  const deleteJourneyTemplateMutation = useDeleteJourneyTemplate();
  const [isDeleteStageConfirmationOpen, setIsDeleteStageConfirmationOpen] =
    useState(false);
  const [open, setOpen] = useState(false);
  const { data: customFields } = useGetCustomFields("project");
  useEffect(() => {
    if (deleteJourneyTemplateMutation.isSuccess) {
      setIsDeleteStageConfirmationOpen(false);
    }
  }, [deleteJourneyTemplateMutation.isSuccess]);

  return (
    <div className="w-full space-y-14 px-8 pt-16">
      <div className="mx-auto max-w-2xl space-y-1">
        <label className="w-40 shrink-0 text-lg font-medium leading-none text-gray-800">
          Custom Fields
        </label>
        <div className="pb-2.5 text-sm text-gray-500 font-light">
          Link a custom field if you want users to fill in additional
          information during project launch. You can only link existing custom
          fields to a project template. To create a new custom field, go to
          <Link to="/settings/custom-fields">
            <span className="text-gray-600"> Custom Fields </span>
          </Link>
          in the Settings page.
        </div>
        <div className="space-y-6 rounded-md border p-6 shadow-sm">
          <div>
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild>
                <Button variant={"secondary"}>Add Custom Field</Button>
              </PopoverTrigger>
              <PopoverContent
                className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
                align="start"
              >
                <Command>
                  <CommandInput
                    placeholder="Search custom field..."
                    className="h-9"
                  />
                  <CommandEmpty>No custom field found.</CommandEmpty>
                  <CommandGroup>
                    {customFields
                      ?.filter(
                        (cf1) =>
                          !journeyTemplate?.customFields?.find(
                            (cf2) => cf2._id.toString() === cf1._id
                          ) && cf1.objectType === "project"
                      )
                      .map((customField) => (
                        <CommandItem
                          key={customField._id}
                          value={customField.name}
                          onSelect={() => {
                            if (journeyTemplate)
                              updateJourneyTemplateMutation.mutate({
                                params: {
                                  _id: new ObjectID(journeyTemplateId),
                                  customFieldIds: [
                                    ...(journeyTemplate.customFields?.map(
                                      (cf) => cf._id
                                    ) ?? []),
                                    new ObjectID(customField._id),
                                  ],
                                },
                                journeyTemplateId: new ObjectID(
                                  journeyTemplateId
                                ),
                              });
                            setOpen(false);
                          }}
                          className="gap-2"
                        >
                          <span className="truncate">{customField.name}</span>
                          {/* <CheckIcon
                  className={cn(
                    "ml-auto h-4 w-4 shrink-0",
                    taskTemplate.formTemplate?._id.toString() ===
                      formTemplate._id.toString()
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                /> */}
                        </CommandItem>
                      ))}
                  </CommandGroup>
                </Command>
              </PopoverContent>
            </Popover>
          </div>
          {!!journeyTemplate?.customFields?.length && (
            <div>
              <div className="flex items-center border-b border-gray-100 pb-3 text-gray-500">
                <div className="flex-1 text-sm leading-none">Name</div>
                <div className="w-40 text-sm leading-none">Type</div>
                <div className="w-[30px]"></div>
              </div>
              {journeyTemplate.customFields.map((customField) => (
                <TemplateCustomFieldRow
                  customField={customField}
                  journeyTemplate={journeyTemplate}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="mx-auto max-w-2xl space-y-2.5">
        <label className="w-40 shrink-0 text-lg font-medium leading-none text-gray-800">
          Actions
        </label>
        <div className="space-y-6 divide-y rounded-md border p-6 shadow-sm">
          <div className="flex items-center justify-between">
            <div className="space-y-0.5">
              <label className="leading-none text-gray-700">Delete</label>
              <p className="text-sm font-light text-gray-500">
                Deleting a project template is a permanent action
              </p>
            </div>
            <div>
              <Dialog
                open={isDeleteStageConfirmationOpen}
                onOpenChange={(open) => {
                  setIsDeleteStageConfirmationOpen(open);
                }}
              >
                <DialogTrigger asChild>
                  <Button
                    variant={"secondary"}
                    onClick={() => {
                      setIsDeleteStageConfirmationOpen(true);
                    }}
                  >
                    Delete
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Delete Confirmation</DialogTitle>
                    {/* <DialogDescription>
                Confirm deletion of stage template
              </DialogDescription> */}
                  </DialogHeader>
                  <div className="space-y-5 pt-3 text-sm text-gray-600">
                    <div>
                      Are you sure you want to delete the project template{" "}
                      <span className="font-medium text-gray-900">
                        {journeyTemplate?.name}
                      </span>
                      ?
                    </div>
                  </div>
                  <DialogFooter>
                    <DialogClose asChild className="">
                      <div className="flex justify-end space-x-3">
                        <Button
                          variant={"secondary"}
                          onClick={() => {
                            setIsDeleteStageConfirmationOpen(false);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant={"destructive"}
                          onClick={() => {
                            deleteJourneyTemplateMutation.mutate({
                              params: {
                                _id: new ObjectID(journeyTemplateId),
                              },
                            });
                          }}
                        >
                          {deleteJourneyTemplateMutation.isLoading
                            ? "Deleting..."
                            : "Delete"}
                        </Button>
                      </div>
                    </DialogClose>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            </div>
            {/* <Button
            text={isConfirmingDelete ? "Confirm Delete" : "Delete"}
            ring={false}
            fill="outline"
            size="xs"
            color={isConfirmingDelete ? "danger" : "primary"}
            onClick={() => {
              if (isConfirmingDelete) {
                deleteJourneyTemplateMutation.mutate({
                  params: {
                    _id: new ObjectID(journeyTemplateId),
                  },
                });
              } else {
                setIsConfirmingDelete(true);
              }
            }}
          /> */}
          </div>
          {journeyTemplate && (
            <DuplicateJourneyTemplate previousName={journeyTemplate.name} />
          )}
          {/* <div className="flex">
          <label className="w-40 shrink-0 text-sm text-gray-400">
            Font Type
          </label>
          {!!settings && <FontType settings={settings} />}
        </div> */}
        </div>
      </div>

      <div className="max">
        <div className="space-y-6 pb-4">
          {/* <div className="flex">
            <label className="w-40 shrink-0 text-sm text-gray-400">
              Deal Closed Won
            </label>
            <div className="text-sm text-gray-600">
              <VariantToggle
                size={"sm"}
                enabled={!!journeyTemplate?.hasTrigger}
                setEnabled={(value) => {
                  if (value) {
                    updateJourneyTemplateMutation.mutate({
                      params: {
                        _id: new ObjectID(journeyTemplateId),
                        hasTrigger: true,
                        trigger: {
                          event: "UPDATE",
                          object: "Deal",
                          changeValue: "Closed",
                        },
                      },
                      journeyTemplateId: new ObjectID(journeyTemplateId),
                    });
                  } else {
                    updateJourneyTemplateMutation.mutate({
                      params: {
                        _id: new ObjectID(journeyTemplateId),
                        hasTrigger: false,
                      },
                      journeyTemplateId: new ObjectID(journeyTemplateId),
                    });
                  }
                }}
              />
            </div>
          </div> */}
        </div>
      </div>
      {/* <div className="py-4">
        <div className="space-y-6 pb-4">
          <label className="text-md w-40 shrink-0 font-medium text-gray-600">
            Triggers
          </label>

          <div className="flex">
            <label className="w-40 shrink-0 text-sm text-gray-400">
              Deal Closed Won
            </label>
            <div className="text-sm text-gray-600">
              <VariantToggle
                size={"sm"}
                enabled={!!journeyTemplate?.hasTrigger}
                setEnabled={(value) => {
                  if (value) {
                    updateJourneyTemplateMutation.mutate({
                      params: {
                        _id: new ObjectID(journeyTemplateId),
                        hasTrigger: true,
                        trigger: {
                          event: "UPDATE",
                          object: "Deal",
                          changeValue: "Closed",
                        },
                      },
                      journeyTemplateId: new ObjectID(journeyTemplateId),
                    });
                  } else {
                    updateJourneyTemplateMutation.mutate({
                      params: {
                        _id: new ObjectID(journeyTemplateId),
                        hasTrigger: false,
                      },
                      journeyTemplateId: new ObjectID(journeyTemplateId),
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div> */}
    </div>
    // <div className="space-y-4">
    //   <div className="space-y-4">
    //     <h3 className="text-md font-semibold">Journey Actions</h3>
    //     <div className="space-y-2">
    //     </div>
    //   </div>
    // </div>
  );
}

function TemplateCustomFieldRow({
  customField,
  journeyTemplate,
}: {
  customField: any;
  journeyTemplate: JourneyTemplateData;
}) {
  const [optionsOpen, setOptionsOpen] = useState(false);
  const updateJourneyTemplateMutation = useUpdateJourneyTemplate();
  return (
    <div
      key={customField._id.toString()}
      className="flex h-10 items-center border-b border-gray-100 last:border-0 last:pb-0"
    >
      <div className="flex-1 truncate text-sm font-medium leading-none">
        {customField.name}
      </div>
      <div className="w-40 text-sm leading-none">
        {customField.type === "boolean"
          ? "Yes/No"
          : customField.type.charAt(0).toUpperCase() +
            customField.type.slice(1)}
      </div>
      <div className="w-[30px]">
        <DropdownMenu
          open={optionsOpen}
          onOpenChange={(isOpen) => {
            setOptionsOpen(isOpen);
          }}
        >
          <DropdownMenuTrigger className="focus:outline-none">
            <div
              className={clsx(
                "rounded px-1.5 py-0.5 text-gray-400 transition-all hover:text-gray-600 group-hover:opacity-100",
                optionsOpen
                  ? "bg-gray-100 text-gray-600 opacity-100"
                  : "border-transparent"
              )}
            >
              <EllipsisHorizontalIcon className="h-4 w-4" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" side="bottom" className="w-40">
            <DropdownMenuGroup>
              <DropdownMenuItem
                className="py-1 focus:bg-red-50 focus:text-red-600"
                onClick={(e) => {
                  // setDeletingStage(true);
                  e.stopPropagation();
                  if (journeyTemplate._id) {
                    updateJourneyTemplateMutation.mutate({
                      params: {
                        _id: journeyTemplate._id,
                        customFieldIds:
                          journeyTemplate.customFields
                            ?.filter(
                              (cf) =>
                                cf._id.toString() !== customField._id.toString()
                            )
                            .map((cf) => cf._id) ?? [],
                      },
                      journeyTemplateId: journeyTemplate._id,
                    });
                  }
                }}
              >
                <TrashIcon className="mr-2.5 h-3.5 w-3.5" />
                <span className="text-sm">Delete</span>
              </DropdownMenuItem>

              {/* <DropdownMenuItem>Delete</DropdownMenuItem> */}
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
}

const DuplicateJourneyTemplate = ({
  previousName,
}: {
  previousName: string;
}) => {
  const { journeyTemplateId } = useParams();
  const duplicateJourneyTemplateMutation = useDuplicateJourneyTemplate();
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState(
    previousName + " Copy"
  );

  useEffect(() => {
    if (duplicateJourneyTemplateMutation.isSuccess) {
      setIsDuplicating(false);
    }
  }, [duplicateJourneyTemplateMutation.isSuccess]);

  return (
    <div className="flex items-center justify-between pt-6">
      <div className="space-y-0.5">
        <label className="leading-none text-gray-700">Duplicate</label>
        <p className="text-sm font-light text-gray-500">
          Duplicate this project template and all of its tasks.
        </p>
      </div>
      <Dialog
        open={isDuplicating}
        onOpenChange={(open) => {
          setIsDuplicating(open);
        }}
      >
        <DialogTrigger asChild>
          <Button
            onClick={() => {
              setIsDuplicating(true);
            }}
            variant={"secondary"}
          >
            Duplicate
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Duplicate Project Template</DialogTitle>
          </DialogHeader>
          <div className="space-y-6 py-3">
            <div className="space-y-1">
              <InputHeading heading="New Template Name" />
              <TextInput
                value={newTemplateName}
                onChange={(e) => {
                  setNewTemplateName(e.target.value);
                }}
                className="max-w-xs"
              />
            </div>
          </div>
          <div className="flex justify-end space-x-2">
            {/* <Button
              variant={"secondary"}
              onClick={() => {
                setIsDuplicating(false);
              }}
            >
              Cancel
            </Button> */}
            <Button
              disabled={
                duplicateJourneyTemplateMutation.isLoading ||
                newTemplateName.trim() === ""
              }
              onClick={() => {
                if (journeyTemplateId)
                  duplicateJourneyTemplateMutation.mutate({
                    _id: journeyTemplateId,
                    name: newTemplateName,
                  });
              }}
            >
              Duplicate
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* <Modal
        isOpen={isDuplicating}
        closeModal={() => {
          setIsDuplicating(false);
        }}
      >
        <div className="p-6">
          <ModalHeading
            title="Duplicate Journey Template"
            subtitle="Duplicate this journey template and all of its tasks."
          />
          <div className="mt-6 space-y-6">
            <TextInput
              label="New Template Name"
              value={newTemplateName}
              name="newTemplateName"
              onChange={(e) => {
                setNewTemplateName(e.target.value);
              }}
            />
            <div className="flex justify-end">
              <Button
                disabled={
                  duplicateJourneyTemplateMutation.isLoading ||
                  newTemplateName.trim() === ""
                }
                onClick={() => {
                  if (journeyTemplateId)
                    duplicateJourneyTemplateMutation.mutate({
                      _id: journeyTemplateId,
                      name: newTemplateName,
                    });
                }}
              >
                Duplicate
              </Button>
            </div>
          </div>
        </div>
      </Modal> */}
    </div>
  );
};
