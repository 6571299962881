import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InstanceDetailsForm from "../../components/Authentication/PostVerificationSetup/InstanceDetailsForm";
import Steps from "../../components/Authentication/PostVerificationSetup/Steps";
import PostVerificationContextProvider from "../../store/Authentication/PostVerificationContextProvider";
import { useRealmApp } from "../../store/RealmApp";

type Props = {};

const PostVerificationSetup = (props: Props) => {

  return (
    <Container maxWidth="sm">
      <Grid container alignItems="center" justifyContent={"center"}>
        <Grid item xs={12} marginTop={20}>
          {/* <PostVerificationContextProvider> */}
            <Steps />
          {/* </PostVerificationContextProvider> */}
        </Grid>
      </Grid>
    </Container>
  );
};

export default PostVerificationSetup;
