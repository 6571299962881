import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { useAccessToken } from "../useAccessToken";

const hubSpotKeys = {
  companyProperties: () => ["hubspot", "companyProperties"] as const,
  dealProperties: () => ["hubspot", "dealProperties"] as const,
  pipelines: () => ["hubspot", "pipelines"] as const,
};

export type HubSpotProperty = {
  name: string;
  label: string;
  type: string
};

export type HubSpotPipeline = {
  id: string;
  label: string;
  stages: { id: string; label: string }[];
};

export const useGetCompanyPropertiesForHubSpot = () => {
  const getValidAccessToken = useAccessToken();
  return useQuery(
    hubSpotKeys.companyProperties(),
    async (): Promise<HubSpotProperty[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/customCompanyPropertiesForHubspot`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
        }
      );
      return res.data;
    },
    {
      staleTime: 1000 * 60 * 60, // 1 hour
      cacheTime: Infinity,
    }
  );
};

export const useGetDealPropertiesForHubSpot = () => {
  const getValidAccessToken = useAccessToken();
  return useQuery(
    hubSpotKeys.dealProperties(),
    async (): Promise<HubSpotProperty[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/customDealPropertiesForHubspot`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
        }
      );
      return res.data;
    },
    {
      staleTime: 1000 * 60 * 60, // 1 hour
      cacheTime: Infinity,
    }
  );
};

export const useGetPipelineAndStagesForHubSpot = () => {
  const getValidAccessToken = useAccessToken();
  return useQuery(
    hubSpotKeys.pipelines(),
    async (): Promise<HubSpotPipeline[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/pipelinesAndStagesForHubspot`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
        }
      );
      return res.data;
    },
    {
      staleTime: 1000 * 60 * 60, // 1 hour
      cacheTime: Infinity,
    }
  );
};

export const useSetCompanyAndDealCustomPropertiesForHubSpot = () => {
  const getValidAccessToken = useAccessToken();
  const snackbarCtx = useSnackBar();
  return useMutation({
    mutationFn: async (params: {
      companyProperties: HubSpotProperty[];
      dealProperties: HubSpotProperty[];
    }): Promise<{ message: string; status: number }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/setCompanyAndDealCustomProperties`,
        params,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error saving properties", "error");
    },
  });
};

export const useStartHubSpotImport = () => {
  const getValidAccessToken = useAccessToken();
  const snackbarCtx = useSnackBar();
  return useMutation({
    mutationFn: async (): Promise<{ message: string; status: number }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/startHubspotImport`,
        {},
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Issue starting import", "error");
    },
    onSuccess: () => {
      snackbarCtx.showSnackbar("Import successfully started", "success");
    },
  });
};
