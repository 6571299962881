import { DndContext, DragEndEvent, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { ObjectID } from "bson";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TaskTemplateData } from "src/api/Services/Journeys/journeyTemplates";
import { JourneyTemplateData } from "src/api/Services/Journeys/journeys";
import {
  useGetStageTemplates,
  useUpdateJourneyTemplate,
} from "src/api/Services/Tasks/journeys";
import TaskTemplatePanel from "./Template/TemplateEdit/EditTaskTemplate/TaskTemplatePanel";
import { TaskTemplatePanelContextProvider } from "./Template/TemplateEdit/EditTaskTemplate/TaskTemplatePanelContextProvider";
import { useJourneyTemplate } from "./Template/TemplateEdit/Stages/JourneyTemplateContextProvider";
import { TemplateStages } from "./Template/TemplateEdit/Stages/TemplateStages";
import { TemplateSettingsButton } from "./Template/TemplateEdit/TemplateSettings/TemplateSettingsButton";

const JourneyTemplateEdit = ({
  journeyTemplate,
}: {
  journeyTemplate: JourneyTemplateData;
}) => {
  const [editTaskTemplate, setEditTaskTemplate] = useState<
    TaskTemplateData | undefined
  >(undefined);
  let { journeyTemplateId } = useParams();
  const navigate = useNavigate();
  const { stages } = useGetStageTemplates(new ObjectID(journeyTemplateId));
  // console.log(stages)
  const handleDragEnd = (event: DragEndEvent) => {
    const { over, active } = event;
    if (
      over &&
      active &&
      stages &&
      journeyTemplate._id &&
      !!journeyTemplate.stageTemplateOrder
    ) {
      // console.log("drag end 2")
      const { id: overId } = over;
      const { id: activeId } = active;
      if (overId !== activeId) {
        const oldIndex = stages.findIndex((s) => s._id.toString() === activeId);
        const newIndex = stages.findIndex((s) => s._id.toString() === overId);
        const newStageTemplateOrder = arrayMove(
          journeyTemplate.stageTemplateOrder,
          oldIndex,
          newIndex
        );
        updateJourneyTemplateMutation.mutate({
          journeyTemplateId: journeyTemplate._id,
          params: {
            _id: journeyTemplate._id,
            stageTemplateOrder: newStageTemplateOrder,
          },
        });
      }
    }
  };

  const journeyTemplateCtx = useJourneyTemplate();
  const updateJourneyTemplateMutation = useUpdateJourneyTemplate();

  return (
    <div className="space-y-2 pt-6">
      <div className="flex items-start justify-end">
        {/* <h2 className="text-md text-gray-500">Stages</h2> */}
        {/* <div className="flex space-x-2">
          <div className="space-y-1">
            <InputHeading heading="Show Subtasks" />
            <VariantToggle
              size="sm"
              focus={false}
              setEnabled={() => {
                journeyTemplateCtx.dispatch({
                  type: "VIEW_SHOW_SUBTASKS_UPDATE",
                  showSubTasks: !journeyTemplateCtx.state.view.showSubTasks,
                });
              }}
              enabled={journeyTemplateCtx.state.view.showSubTasks}
            />
          </div>
        </div> */}
        <div className="inline-flex space-x-3 px-5">
          {/* <TaskTemplateContextProvider>
            <NewTaskTemplateButton />
          </TaskTemplateContextProvider> */}
          <TemplateSettingsButton />
        </div>
      </div>
      {/* <div className="flex gap-4 flex-wrap">
        <div className="rounded-md px-3 py-2">
          <div className="flex flex-col items-center">
            <span>Tasks</span>
            <span></span>
          </div>
        </div>
      </div> */}
      <div className="px-8">
        {!!stages && (
          <TaskTemplatePanelContextProvider>
            <DndContext
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={
                  !!journeyTemplate.stageTemplateOrder
                    ? journeyTemplate.stageTemplateOrder.map((s) =>
                        s.toString()
                      )
                    : []
                }
                strategy={verticalListSortingStrategy}
              >
                <TemplateStages
                  stages={stages}
                  journeyTemplate={journeyTemplate}
                />
              </SortableContext>
            </DndContext>
            <TaskTemplatePanel />
          </TaskTemplatePanelContextProvider>
        )}
      </div>
      {/* {!!editTaskTemplate && (
        <TaskTemplateContextProvider taskTemplate={editTaskTemplate}>
          <EditTaskTemplateModal
            isOpen={!!editTaskTemplate}
            closeModal={() => {
              setEditTaskTemplate(undefined);
            }}
          />
        </TaskTemplateContextProvider>
      )} */}
    </div>
  );
};

export default JourneyTemplateEdit;
