import { HashtagIcon } from "@heroicons/react/24/outline";
import { CaretSortIcon, CrossCircledIcon } from "@radix-ui/react-icons";
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import {
  useGetSlackChannels,
  useGetSlackIntegrationStatus,
} from "src/api/Integrations/slack";
import {
  AllPhaseNotificationSettingsData,
  PhaseNotificationSettingsData,
  useGetAllPhaseNotificationSettings,
  useUpdatePhaseNotificationSettings,
} from "src/api/Services/SharedSpace/phases";
import { Button } from "src/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "src/components/ui/command";
import { PageHeading } from "src/components/ui/heading";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import { Switch } from "src/components/ui/switch";

type Props = {};

export default function ProjectNotificationSettings({}: Props) {
  const { data: notificationSettings } = useGetAllPhaseNotificationSettings();

  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    data: notificationSettings ?? [],
    columns: projectNotificationColumns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className="flex-1 overflow-auto ">
      <div className="flex h-16 items-center border-b px-8">
        <PageHeading heading="Project Notifications" />
      </div>
      <div className="max-h-full max-w-full overflow-auto border-b">
        <table
          style={{ borderCollapse: "separate", borderSpacing: 0 }}
          className="min-w-[600px]"
        >
          <thead className="overflow-auto">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={
                      "sticky top-0 z-10 h-11 whitespace-nowrap border-b border-r bg-gray-50 px-6 text-xs font-medium uppercase tracking-wide text-gray-500 first:left-0 first:z-20 first:pl-8 last:w-full"
                    }
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                  // <th key={header.id} colSpan={header.colSpan}>
                  //   {header.isPlaceholder ? null : (
                  //     <div>
                  //       {flexRender(
                  //         header.column.columnDef.header,
                  //         header.getContext()
                  //       )}
                  //     </div>
                  //   )}
                  // </th>
                ))}
                <th
                  key={"#extension"}
                  scope="col"
                  className={"w-full border-b bg-gray-50"}
                ></th>
              </tr>
            ))}
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {!notificationSettings ? (
              <tr>
                {/* <td className="inset-0 flex items-center justify-center p-4 pl-8"> */}
                <div className="sticky left-0 z-10 p-4 pl-8 text-gray-400">
                  Loading...
                </div>
                {/* </td> */}
              </tr>
            ) : (
              <>
                {notificationSettings.length === 0 ? (
                  <tr>
                    <div className="sticky left-0 z-10 whitespace-nowrap p-4 pl-8 text-gray-400">
                      No projects
                    </div>
                    {/* <td className="inset-0 flex items-center justify-center p-4">
                    </td> */}
                  </tr>
                ) : (
                  <>
                    {table.getRowModel().rows.map((row) => (
                      <tr
                        key={row.id}
                        className="group relative rounded-lg"
                        // onClick={() => {
                        //   navigate(`${row.original._id}`);
                        // }}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            className={
                              "whitespace-nowrap border-r border-b border-gray-100 px-6 py-3 text-sm text-gray-500 first:sticky first:left-0 first:z-10 first:bg-white first:pr-6 first:font-medium first:text-gray-900 first:sm:pl-8"
                            }
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const projectNotificationColumns: ColumnDef<AllPhaseNotificationSettingsData>[] =
  [
    {
      id: "project",
      header: () => {
        return <span>Project</span>;
      },
      cell: ({ row }) => {
        return (
          <div className="h-full">
            <span>{row.original.name}</span>
          </div>
        );
      },
    },
    {
      id: "customer",
      header: ({ table }) => {
        return <span>Customer</span>;
      },
      cell: ({ row }) => {
        return (
          <div className="h-full">
            <span>{row.original.customer.name}</span>
          </div>
        );
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: "slackChannel",
      header: () => {
        return <span>Slack Channel</span>;
      },
      cell: ({ row }) => {
        return (
          <div className="flex h-full justify-center">
            <SlackChannel
              phaseNotificationSettings={row.original.notificationSettings}
            />
          </div>
        );
      },
    },
    {
      id: "emails",
      header: () => {
        return <span>Emails</span>;
      },
      columns: [
        {
          accessorKey: "fwef",
          id: "overdueEmails",
          header: () => {
            return <div className="w-40">Overdue Emails</div>;
          },
          cell: ({ row }) => {
            return (
              <div className="flex h-full justify-center">
                <OverdueEmailNotification
                  phaseNotificationSettings={row.original.notificationSettings}
                />
              </div>
            );
          },
        },
        {
          id: "taskActivationEmails",
          header: () => {
            return <div className="w-40">Task Activation Emails</div>;
          },
          cell: ({ row }) => {
            return (
              <div className="flex h-full justify-center">
                <TaskActivationEmailNotification
                  phaseNotificationSettings={row.original.notificationSettings}
                />
              </div>
            );
          },
        },
        {
          id: "weeklyUpdateEmails",
          header: () => {
            return <div className="w-40">Weekly Update Emails</div>;
          },
          cell: ({ row }) => {
            return (
              <div className="flex h-full justify-center">
                <WeeklyUpdateEmailNotification
                  phaseNotificationSettings={row.original.notificationSettings}
                />
              </div>
            );
          },
        },
      ],
    },
  ];

function SlackChannel({
  phaseNotificationSettings,
}: {
  phaseNotificationSettings: PhaseNotificationSettingsData;
}) {
  const { data: slackIntegrationStatus } = useGetSlackIntegrationStatus();
  const { data: channels } = useGetSlackChannels();
  const [channelSearch, setChannelSearch] = useState("");
  const [open, setOpen] = useState(false);
  const updateNotificationSettingsMutation =
    useUpdatePhaseNotificationSettings();
  return (
    <>
      {!!slackIntegrationStatus && (
        <>
          {slackIntegrationStatus?.workspace ? (
            <div className="h-full">
              <div className="flex items-center space-x-3">
                <Popover open={open} onOpenChange={setOpen}>
                  <PopoverTrigger asChild>
                    <Button
                      variant="secondary"
                      size="lg"
                      role="combobox"
                      aria-expanded={open}
                      className="w-72 justify-between truncate space-x-1"
                    >
                      <div className="mr-2 flex flex-1 items-center space-x-2">
                        <HashtagIcon className="h-3.5 w-3.5 shrink-0" />
                        {!phaseNotificationSettings.slackNotification
                          .channel ? (
                          <div className="font-normal text-gray-300">
                            Select channel
                          </div>
                        ) : (
                          <div className="w-full truncate text-left">
                            {
                              channels?.find(
                                (c) =>
                                  c.id ===
                                  phaseNotificationSettings?.slackNotification
                                    .channel
                              )?.name
                            }
                          </div>
                        )}
                      </div>
                      {!!phaseNotificationSettings.slackNotification
                        .channel && (
                        <button
                          className="rounded-full p-1 hover:bg-gray-200/70 group/button"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <CrossCircledIcon
                            onClick={() => {
                              if (!phaseNotificationSettings?._id) return;
                              updateNotificationSettingsMutation.mutate({
                                metadata: {
                                  phaseId: String(
                                    phaseNotificationSettings.phaseId
                                  ),
                                },
                                update: {
                                  _id: phaseNotificationSettings?._id,
                                  slackNotificationChannel: null,
                                },
                              });
                            }}
                            className="group-hover/button:opacity-80 h-4 w-4 shrink-0 opacity-50"
                          />
                        </button>
                      )}
                      <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent
                    className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
                    align="start"
                  >
                    <Command>
                      <CommandInput
                        value={channelSearch}
                        onValueChange={(s) => {
                          setChannelSearch(s);
                        }}
                        placeholder="Search channel..."
                        className="h-9"
                      />
                      <CommandEmpty>No channel found.</CommandEmpty>
                      <CommandGroup>
                        {channels?.map((channel) => (
                          <CommandItem
                            key={channel.id}
                            value={channel.name}
                            onSelect={() => {
                              if (!phaseNotificationSettings?._id) return;
                              updateNotificationSettingsMutation.mutate({
                                metadata: {
                                  phaseId: String(
                                    phaseNotificationSettings.phaseId
                                  ),
                                },
                                update: {
                                  _id: phaseNotificationSettings?._id,
                                  slackNotificationChannel: channel.id,
                                  sendSlackNotifications: true,
                                },
                              });
                              setOpen(false);
                            }}
                            className="gap-2"
                          >
                            <div className="flex items-center space-x-1">
                              <HashtagIcon className="h-3.5 w-3.5 shrink-0" />
                              <span className="truncate">{channel.name}</span>
                            </div>
                          </CommandItem>
                        ))}
                        {/* {isLoading && <div>Loading..</div>} */}
                      </CommandGroup>
                    </Command>
                  </PopoverContent>
                </Popover>
                {/* {!!phaseNotificationSettings?.slackNotification.channel && (
                  <Button
                    variant={"secondaryLink"}
                    className="px-0"
                    
                  >
                    Remove channel
                  </Button>
                )} */}
              </div>
              {/* <span>{phaseNotificationSettings.slackNotification.channel}</span> */}
            </div>
          ) : (
            <div></div>
          )}
        </>
      )}
    </>
  );
}

function OverdueEmailNotification({
  phaseNotificationSettings,
}: {
  phaseNotificationSettings: PhaseNotificationSettingsData;
}) {
  const updatePhaseNotificationMutation = useUpdatePhaseNotificationSettings();
  return (
    <Switch
      checked={phaseNotificationSettings.overdueEmail}
      onCheckedChange={(checked) => {
        updatePhaseNotificationMutation.mutate({
          update: {
            _id: phaseNotificationSettings._id,
            overdueEmail: checked,
          },
          metadata: {
            phaseId: phaseNotificationSettings.phaseId,
          },
        });
      }}
    />
  );
}

function TaskActivationEmailNotification({
  phaseNotificationSettings,
}: {
  phaseNotificationSettings: PhaseNotificationSettingsData;
}) {
  const updatePhaseNotificationMutation = useUpdatePhaseNotificationSettings();
  return (
    <Switch
      checked={phaseNotificationSettings.dependentTaskActivationEmail}
      onCheckedChange={(checked) => {
        updatePhaseNotificationMutation.mutate({
          update: {
            _id: phaseNotificationSettings._id,
            dependentTaskActivationEmail: checked,
          },
          metadata: {
            phaseId: phaseNotificationSettings.phaseId,
          },
        });
      }}
    />
  );
}

function WeeklyUpdateEmailNotification({
  phaseNotificationSettings,
}: {
  phaseNotificationSettings: PhaseNotificationSettingsData;
}) {
  const updatePhaseNotificationMutation = useUpdatePhaseNotificationSettings();
  return (
    <Switch
      checked={phaseNotificationSettings.weeklyUpdateEmail}
      onCheckedChange={(checked) => {
        updatePhaseNotificationMutation.mutate({
          update: {
            _id: phaseNotificationSettings._id,
            weeklyUpdateEmail: checked,
          },
          metadata: {
            phaseId: phaseNotificationSettings.phaseId,
          },
        });
      }}
    />
  );
}
