import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useRealmApp } from "../../../store/RealmApp";
import { InvitesResponse } from "../../../types/Authentication";
import InstanceDetailsForm from "./InstanceDetailsForm";
import Invites from "./Invites";
import PersonalDetailsForm from "./PersonalDetailsForm";

type Props = {};

const Steps = (props: Props) => {
  const app = useRealmApp();
  const [step, setStep] = useState(0);
  const [invites, setInvites] = useState<InvitesResponse[]>([]);
  const [skipInvites, setSkipInvites] = useState(false);

  useEffect(() => {
    // check if the user has invites
    try {
      app.currentUser.functions
        .getIncomingInstanceInvitesForUser()
        .then((res: InvitesResponse[]) => {
          // console.log(res);
          setInvites(res);
          // check if user is registered
          app.currentUser.functions
            .isRegistered()
            .then((result: { isRegistered: boolean }) => {
              // console.log(result);
              if (result.isRegistered) {
                if (res.length > 0) {
                  setStep(2);
                } else {
                  setStep(3);
                }
              } else {
                setStep(1);
              }
            });
        });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const nextStep = () => {
    // if (step == 1) {
    //   try {
    //     app.currentUser.functions
    //       .getIncomingInstanceInvitesForUser()
    //       .then((result: InvitesResponse[]) => {
    //         console.log(result);
    //         setInvites(result);
    //       });
    //   } catch (error) {
    //     console.error(error);
    //   }
    // }
    setStep((prev) => {
      if (prev === 1 && invites.length === 0) {
        return prev + 2;
      }
      return prev + 1;
    });
  };

  const prevStep = () => {
    setStep((prev) => prev - 1);
  };

  const returnStep = () => {
    switch (step) {
      case 0:
        return <CircularProgress color="secondary" size={"large"} />;
      case 1:
        return <PersonalDetailsForm nextStep={nextStep} />;
      case 2:
        return <Invites invites={invites} nextStep={nextStep} />;
      case 3:
        return (
          <InstanceDetailsForm
            invitesLength={invites.length}
            prevStep={prevStep}
          />
        );
      default:
        return <>Invalid</>;
    }
  };

  return <>{returnStep()}</>;
};

export default Steps;
