import {
  MapIcon,
  RectangleStackIcon,
  RocketLaunchIcon,
} from "@heroicons/react/24/outline";
import { HTMLAttributes, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { PageHeading } from "src/components/ui/heading";
import { cn } from "src/utils/ui/ui";

export default function Analytics() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex h-16 items-center px-8">
        <PageHeading heading={"Analytics"} />
      </div>
      <div className="px-8">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
          <AnalyticsCard
            onClick={() => {
              navigate("/analytics/guides");
            }}
          >
            <div className="flex items-center space-x-1.5">
              <MapIcon className="text-primary-500 h-[18px] w-[18px]" />
              <div className="text-lg font-medium">Guides</div>
            </div>
            <div className="pt-1 text-sm text-gray-500">
              Explore our curated guides that focus on key datapoints.
            </div>
          </AnalyticsCard>
          <AnalyticsCard
            onClick={() => {
              navigate("/analytics/project-performance");
            }}
          >
            <div className="flex items-center space-x-2">
              <RocketLaunchIcon className="text-primary-500 h-[18px] w-[18px]" />
              <div className="text-lg font-medium">Projects Explorer</div>
            </div>
            <div className="pt-1 text-sm text-gray-500">
              Dive deep into the performance of your projects.
            </div>
          </AnalyticsCard>

          <AnalyticsCard
            onClick={() => {
              navigate("/analytics/project-template-performance");
            }}
          >
            <div>
              <div className="flex items-center space-x-2">
                <RectangleStackIcon className="text-primary-500 h-[18px] w-[18px]" />
                <div className="text-lg font-medium">Template Explorer</div>
              </div>
              <div className="pt-1 text-sm text-gray-500">
                Undertstand past performance of your templates & how you can
                iterate.
              </div>
            </div>
          </AnalyticsCard>
        </div>
      </div>
    </div>
  );
}

type CardProps = {} & HTMLAttributes<HTMLDivElement>;

const AnalyticsCard = forwardRef<HTMLDivElement, CardProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={cn(
          "h-30 cursor-pointer rounded-md border p-5 py-4 shadow hover:bg-gray-50",
          className
        )}
      >
        {children}
      </div>
    );
  }
);
