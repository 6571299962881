import { CopyIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import {
  useGenerateApiKey,
  useHasApiKey,
  useViewApiKey,
} from "src/api/Misc/Settings/Embedding/embed";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { Button } from "src/components/ui/button";
import { useRealmApp } from "src/store/RealmApp";

type Props = {};

const APIKey = (props: Props) => {
  const app = useRealmApp();
  const { hasApiKey, isSuccess } = useHasApiKey();
  const generateApiKeyMutation = useGenerateApiKey();

  const [view, setView] = useState(false);

  return (
    <div className="flex min-w-0 flex-1 space-x-4">
      {isSuccess && !hasApiKey && (
        <Button
          variant={"link"}
          size={"fit"}
          onClick={() => {
            generateApiKeyMutation.mutate({
              params: {
                instanceId: app.currentUser.customData.instanceId,
              },
            });
          }}
        >
          Generate API Key
        </Button>
      )}
      {isSuccess && hasApiKey && !view && (
        <Button
          onClick={() => {
            setView(true);
          }}
          variant="secondaryLink"
          size={"fit"}
          disabled={view}
          className="text-gray-600"
        >
          View API Key
        </Button>
      )}
      {view && <ViewApiKey />}
    </div>
  );
};

export default APIKey;

const ViewApiKey = () => {
  const { apiKey } = useViewApiKey();
  const snackbarCtx = useSnackBar();
  return (
    <>
      {apiKey && (
        // <Card variant="outlined" style={{ padding: 4 }}>
        <div className="flex min-w-0 items-center space-x-1 rounded-md border bg-white border-gray-300 py-1 pl-3 pr-1">
          <p className="min-w-0 truncate text-xs">{apiKey.apiKey}</p>
          <Button
            className="shrink-0"
            variant={"ghost"}
            size="icon"
            onClick={() => {
              navigator.clipboard.writeText(apiKey.apiKey);
              snackbarCtx.showSnackbar(
                "API Key copied to clipboard",
                "success"
              );
            }}
          >
            <CopyIcon className="h-4 w-4" />
          </Button>
        </div>
        // </Card>
      )}
    </>
  );
};
