import { Box } from "@mui/material";
import React from "react";

type Props = {
  height: number;
};

const Logo = (props: Props) => {
  return (
    <Box component="img" src="/static/symbolLogo.svg" sx={{ height: props.height }} />
  );
};

export default Logo;
