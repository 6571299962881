import { Button, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useRealmApp } from "../../../store/RealmApp";
// import { RegistrationContext } from "../Register/RegistrationContext";
import { useNavigate } from "react-router-dom";
import { PersonalDetailsObj } from "../../../types/Authentication";
import Header from "../Header";
import { useState } from "react";

const PersonalDetailsSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Name is required"),
});

const initialValues: PersonalDetailsObj = {
  name: "",
};

type Props = {
  nextStep: () => void;
};

const PersonalDetailsForm = (props: Props) => {
  const app = useRealmApp();
  let navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  
  // const postVerificationSetupCtx = useContext(PostVerificationContext);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: PersonalDetailsSchema,
    onSubmit: (values) => {
      // Register user
      try {
        setDisabled(true)
        app.currentUser.functions
          .registerUser({
            name: values.name,
          })
          .then((result: { status: string; message: string }) => {
            // console.log(result);
            app.currentUser.refreshCustomData().then(() => {
              props.nextStep();
            });
          });
      } catch (err) {
        setDisabled(false)
        console.error(err);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
      <Stack spacing={3} alignItems="center">
        <Header
          heading="Personal Details"
          subHeading="Please fill in your details"
        />
        <TextField
          fullWidth
          id="name"
          name="name"
          label="Name"
          placeholder="Richard Hendricks"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <Button
          disabled={disabled}
          variant="contained"
          size="large"
          type="submit"
          sx={{ width: "20%" }}
        >
          Next
        </Button>
      </Stack>
    </form>
  );
};

export default PersonalDetailsForm;
