import { useFormik } from "formik";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from "react-router-dom";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { Button } from "src/components/ui/Buttons/Button";
import InputHeading from "src/components/ui/Headings/InputHeading";
import TextInput from "src/components/ui/Inputs/Text/TextInput";
import * as yup from "yup";
import { useRealmApp } from "../../../store/RealmApp";
import { LogInObj } from "../../../types/Authentication";
import Logo from "../Logo";

const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email must be a valid email address")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

const initialValues: LogInObj = {
  email: "",
  password: "",
};

type Props = {};

const LoginForm = (props: Props) => {
  // const registrationCtx = useContext(RegistrationContext);
  const [submitting, setSubmitting] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const app = useRealmApp();
  let navigate = useNavigate();
  const snackbarCtx = useSnackBar();

  const handleLogin = async (
    email: string,
    password: string,
    token: string
  ) => {
    try {
      setSubmitting(true);
      await app.loginWithEmailPassword(email, password, token);
      // await app.logIn(Realm.Credentials.emailPassword(email, password));
      setSubmitting(false);
      // console.log(app.currentUser.customData)

      // navigate("/tasks");
    } catch (err: any) {
      setSubmitting(false);
      // console.log(JSON.stringify(err));
      // console.log(err.error);
      switch (err.error) {
        case "invalid username/password":
          snackbarCtx.showSnackbar("Incorrect email or password", "error");
          // console.error("Wrong email / password combo");
          break;
        case "confirmation required":
          snackbarCtx.showSnackbar("Email not verified", "error");
          // console.error("Need to verifiy email");
          navigate("/verification", { state: { email: email } });
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      if (formik.isValid) {
        setSubmitting(true);
        recaptchaRef.current?.execute();
      } else {
        setSubmitting(false);
      }
    },
  });

  const onReCAPTCHAChange = async (token: string | null) => {
    // Send this token to your backend for verification
    try {
      if (!token) {
        snackbarCtx.showSnackbar(
          "reCAPTCHA verification failed. Please reach out to hello@sero.so",
          "error"
        );
        return;
      }
      handleLogin(
        formik.values.email.toLowerCase(),
        formik.values.password,
        token
      );
    } catch (error) {
      // Handle any errors from the API call
      snackbarCtx.showSnackbar(
        "reCAPTCHA verification failed. Please reach out to hello@sero.so",
        "error"
      );
      setSubmitting(false);
    }
    // try {
    //   const response = await axios.post<{ isValid: boolean }>(
    //     "https://api.sero.so/captcha/verify",
    //     { token }
    //   );
    //   setSubmitting(false);

    //   if (response.data.isValid) {
    //     // If the captcha is verified, proceed with login using the SDK
    //     handleLogin(formik.values.email.toLowerCase(), formik.values.password, token);
    //   } else {
    //     // Handle the case where captcha verification fails
    //     setSubmitting(false);
    //     snackbarCtx.showSnackbar(
    //       "reCAPTCHA verification failed. Please reach out to hello@sero.so",
    //       "error"
    //     );
    //   }
    // } catch (error) {
    //   // Handle any errors from the API call
    //   snackbarCtx.showSnackbar(
    //     "reCAPTCHA verification failed. Please reach out to hello@sero.so",
    //     "error"
    //   );
    //   setSubmitting(false);
    // }
  };

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
  //   document.body.appendChild(script);
  // }, []);

  return (
    <div>
      <div className="flex flex-col items-center space-y-10 rounded-lg border bg-white py-16 px-24 pb-12 shadow-md">
        <Logo height={80} />
        {/* <Header heading="Log In" subHeading="Enter your details below" /> */}
        <div className="space-y-2">
          <h2 className="text-2xl font-medium">Sign in to your account</h2>
        </div>
        <form onSubmit={formik.handleSubmit} className="w-full space-y-10">
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
            onChange={onReCAPTCHAChange}
          />
          <div className="w-full">
            <div className="space-y-2">
              <InputHeading heading="Email" />
              <TextInput
                id="email"
                name="email"
                // label="Email"
                placeholder="richard@piedpiper.com"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>
          </div>
          {/* <TextField
          fullWidth
          id="email"
          name="email"
          label="Email"
          placeholder="richard@piedpiper.com"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        /> */}
          <div className="w-full">
            <div className="space-y-2">
              <div className="flex justify-between">
                <InputHeading heading="Password" />
                <div className="text-sm">
                  <Link
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   navigate("/reset-password");
                    // }}
                    // href="/forgot-password"
                    tabIndex={-1}
                    to="/reset-password"
                    className="cursor-pointer font-semibold text-primary-600 hover:text-primary-main"
                  >
                    Forgot password?
                  </Link>
                </div>
              </div>
              <TextInput
                id="password"
                name="password"
                // label="Password"
                // placeholder="********"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </div>
          </div>
          {/* <TextField
          fullWidth
          autoComplete="new-password"
          id="password"
          name="password"
          label="Password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        /> */}

          <Button
            disabled={submitting}
            size="md"
            type="submit"
            text={"Sign in"}
            width={"full"}
          />
        </form>
        <p className="text-center text-sm leading-6 text-gray-500">
          Not a user?{" "}
          <button
            onClick={(e) => {
              e.preventDefault();
              window.open(
                "https://calendly.com/sero-founders/intro-call-with-founders",
                "_blank"
              );
            }}
            className="cursor-pointer font-semibold text-primary-600 hover:text-primary-main"
          >
            Reach out to us
          </button>
        </p>
        {/* <Box paddingTop={4}>
          <Link
            color="primary"
            component={"button"}
            variant="subtitle2"
            onClick={() => {
              navigate("/forgotPassword");
            }}
          >
            Forgot password?
          </Link>
        </Box>

        <Footer isLogin={true} /> */}
      </div>
    </div>
  );
};

export default LoginForm;

// import { useFormik } from "formik";
// import { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import * as Realm from "realm-web";
// import {
//   useGenerateLoginCode,
//   useVerifyLoginCode,
// } from "src/api/Authentication/authentication";
// import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
// import { Button } from "src/components/ui/Buttons/Button";
// import InputHeading from "src/components/ui/Headings/InputHeading";
// import TextInput from "src/components/ui/Inputs/Text/TextInput";
// import * as yup from "yup";
// import { useRealmApp } from "../../../store/RealmApp";
// import { LogInObj } from "../../../types/Authentication";
// import Logo from "../Logo";

// const LoginSchema = yup.object().shape({
//   email: yup
//     .string()
//     .email("Email must be a valid email address")
//     .required("Email is required"),
//   password: yup
//     .string()
//     .min(6, "Too Short!")
//     .max(128, "Too Long!")
//     .required("Password is required"),
//   otc: yup
//     .string()
//     .min(6, "6 digit code required")
//     .max(6, "6 digit code required"),
//   // .required("One Time Code is required"),
// });

// const initialValues: LogInObj = {
//   email: "",
//   password: "",
//   otc: "",
// };

// type Props = {};

// const LoginForm = (props: Props) => {
//   // const registrationCtx = useContext(RegistrationContext);
//   const [isFirstAuthDone, setIsFirstAuthDone] = useState(false);
//   const [submitting, setSubmitting] = useState(false);

//   const app = useRealmApp();
//   let navigate = useNavigate();
//   const snackbarCtx = useSnackBar();
//   const generateLoginCodeMutation = useGenerateLoginCode();
//   const verifyLoginCodeMutation = useVerifyLoginCode();

//   useEffect(() => {
//     if (generateLoginCodeMutation.isSuccess) {
//       setSubmitting(false);
//       setIsFirstAuthDone(true);
//     } else {
//       // setSubmitting(true)
//       // setIsFirstAuthDone(false)
//     }
//     // if login code generated successfully
//     // else give some error
//   }, [generateLoginCodeMutation.isSuccess]);

//   useEffect(() => {
//     if (verifyLoginCodeMutation.isSuccess) {
//       login();
//     }
//   }, [verifyLoginCodeMutation.isSuccess]);

//   const handleLogin = async (email: string, password: string) => {
//     if (!isFirstAuthDone) {
//       setSubmitting(true);
//       generateLoginCodeMutation.mutate(email);

//       // generate login code
//     } else {
//       verifyLoginCodeMutation.mutate({
//         email,
//         code: formik.values.otc,
//       });
//     }
//   };

//   const formik = useFormik({
//     initialValues: initialValues,
//     validationSchema: LoginSchema,
//     onSubmit: (values) => {
//       handleLogin(values.email.toLowerCase(), values.password);
//     },
//   });

//   async function login() {
//     try {
//       setSubmitting(true);
//       await app.logIn(
//         Realm.Credentials.emailPassword(
//           formik.values.email,
//           formik.values.password
//         )
//       );
//       setSubmitting(false);
//       // console.log(app.currentUser.customData)
//       // console.log(app.currentUser.customData);

//       navigate("/tasks");
//     } catch (err: any) {
//       setSubmitting(false);
//       // console.log(JSON.stringify(err));
//       // console.log(err.error);
//       switch (err.error) {
//         case "invalid username/password":
//           snackbarCtx.showSnackbar("Incorrect email or password", "error");
//           // console.error("Wrong email / password combo");
//           break;
//         case "confirmation required":
//           snackbarCtx.showSnackbar("Email not verified", "error");
//           // console.error("Need to verifiy email");
//           navigate("/verification", { state: { email: formik.values.email } });
//       }
//     }
//   }

//   return (
//     <div>
//       <div className="flex flex-col items-center space-y-10 rounded-lg border bg-white py-16 px-24 pb-12 shadow-md">
//         <Logo height={80} />
//         {/* <Header heading="Log In" subHeading="Enter your details below" /> */}
//         <div className="space-y-2">
//           <h2 className="text-2xl font-medium">Sign in to your account</h2>
//         </div>
//         <form onSubmit={formik.handleSubmit} className="w-full space-y-10">
//           <div className="w-full">
//             <div className="space-y-2">
//               <InputHeading heading="Email" />
//               <TextInput
//                 id="email"
//                 name="email"
//                 // label="Email"
//                 placeholder="richard@piedpiper.com"
//                 value={formik.values.email}
//                 onChange={formik.handleChange}
//                 error={formik.touched.email && Boolean(formik.errors.email)}
//                 helperText={formik.touched.email && formik.errors.email}
//               />
//             </div>
//           </div>
//           {/* <TextField
//           fullWidth
//           id="email"
//           name="email"
//           label="Email"
//           placeholder="richard@piedpiper.com"
//           value={formik.values.email}
//           onChange={formik.handleChange}
//           error={formik.touched.email && Boolean(formik.errors.email)}
//           helperText={formik.touched.email && formik.errors.email}
//         /> */}
//           <div className="w-full">
//             <div className="space-y-2">
//               <div className="flex justify-between">
//                 <InputHeading heading="Password" />
//                 <div className="text-sm">
//                   <Link
//                     // onClick={(e) => {
//                     //   e.preventDefault();
//                     //   navigate("/reset-password");
//                     // }}
//                     // href="/forgot-password"
//                     tabIndex={-1}
//                     to="/reset-password"
//                     className="cursor-pointer text-gray-400 hover:text-gray-500"
//                   >
//                     Forgot password?
//                   </Link>
//                 </div>
//               </div>
//               <TextInput
//                 id="password"
//                 name="password"
//                 // label="Password"
//                 // placeholder="********"

//                 type="password"
//                 value={formik.values.password}
//                 onChange={formik.handleChange}
//                 error={
//                   formik.touched.password && Boolean(formik.errors.password)
//                 }
//                 helperText={formik.touched.password && formik.errors.password}
//               />
//             </div>
//           </div>

//           <div className="w-full">
//             <div className="space-y-2">
//               <div className="flex justify-between">
//                 <InputHeading heading="OTP" />
//                 {/* <div className="text-sm">
//                   <Link
//                     // onClick={(e) => {
//                     //   e.preventDefault();
//                     //   navigate("/reset-password");
//                     // }}
//                     // href="/forgot-password"
//                     tabIndex={-1}
//                     to="/reset-password"
//                     className="cursor-pointer font-semibold text-primary-600 hover:text-primary-main"
//                   >
//                     Forgot password?
//                   </Link>
//                 </div> */}
//               </div>
//               <TextInput
//                 id="otc"
//                 name="otc"
//                 // label="Password"
//                 // placeholder="********"
//                 // type="otc"
//                 submitting={!isFirstAuthDone}
//                 value={formik.values.otc}
//                 onChange={formik.handleChange}
//                 error={formik.touched.otc && Boolean(formik.errors.otc)}
//                 helperText={formik.touched.otc && formik.errors.otc}
//               />
//             </div>
//           </div>
//           {/* <TextField
//           fullWidth
//           autoComplete="new-password"
//           id="password"
//           name="password"
//           label="Password"
//           type="password"
//           value={formik.values.password}
//           onChange={formik.handleChange}
//           error={formik.touched.password && Boolean(formik.errors.password)}
//           helperText={formik.touched.password && formik.errors.password}
//         /> */}

//           <Button
//             submitting={submitting}
//             size="md"
//             type="submit"
//             text={isFirstAuthDone ? "Sign in" : "Generate OTP"}
//             width={"full"}
//           />
//         </form>
//         <p className="text-center text-sm leading-6 text-gray-500">
//           Not a user?{" "}
//           <button
//             onClick={(e) => {
//               e.preventDefault();
//               window.open(
//                 "https://calendly.com/sero-founders/request-for-account",
//                 "_blank"
//               );
//             }}
//             className="cursor-pointer font-semibold text-primary-600 hover:text-primary-main"
//           >
//             Reach out to us
//           </button>
//         </p>
//         {/* <Box paddingTop={4}>
//           <Link
//             color="primary"
//             component={"button"}
//             variant="subtitle2"
//             onClick={() => {
//               navigate("/forgotPassword");
//             }}
//           >
//             Forgot password?
//           </Link>
//         </Box>

//         <Footer isLogin={true} /> */}
//       </div>
//     </div>
//   );
// };

// export default LoginForm;
