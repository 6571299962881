import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useRealmApp } from "../../../store/RealmApp";
import { InvitesResponse } from "../../../types/Authentication";
import Header from "../Header";

type Props = {
  invites: InvitesResponse[];
  nextStep: () => void;
};

const Invites = (props: Props) => {
  // console.log(props.invites)
  return (
    <Stack spacing={2} justifyContent="center" alignItems="center">
      <Header
        heading="Invitations"
        subHeading="Accept an invite or make a new workspace"
      />
      {props.invites.map((i) => {
        return <Invite invite={i} />;
      })}
      <Box paddingTop={4}>
        <Button
          style={{ width: 200 }}
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => {
            props.nextStep();
          }}
        >
          New Workspace
        </Button>
      </Box>
    </Stack>
  );
};

export default Invites;

type InviteProps = {
  invite: InvitesResponse;
};

const Invite = ({ invite }: InviteProps) => {
  const app = useRealmApp();
  const theme = useTheme();
  let navigate = useNavigate();

  const acceptInvite = () => {
    app.currentUser.functions
      .acceptInstanceInvite({
        inviteId: invite._id,
      })
      .then((result: any) => {
        // console.log(result);
        app.currentUser.refreshCustomData().then(() => {
          navigate("/");
        });
      });
  };

  return (
    <Card variant="outlined" style={{ minWidth: 600 }}>
      <CardContent>
        <Stack spacing={2}>
          <Stack direction="row">
            <Typography variant="h6">Invitation to the&nbsp;&nbsp;</Typography>
            <Typography
              color="white"
              variant="h6"
              style={{ backgroundColor: theme.palette.secondary.main }}
            >
              &nbsp;{invite.instanceName}&nbsp;
            </Typography>
            <Typography variant="h6">&nbsp;&nbsp;workspace</Typography>
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Stack direction="row" alignItems="center">
              <Typography style={{ fontSize: 18 }}>
                Invited by&nbsp;&nbsp;
              </Typography>
              <Typography
                color="white"
                style={{
                  fontSize: 18,
                  backgroundColor: theme.palette.secondary.main,
                }}
              >
                &nbsp;{invite.fromEmail}&nbsp;
              </Typography>
            </Stack>
            <Button
              variant="contained"
              size="medium"
              color="secondary"
              onClick={acceptInvite}
            >
              Accept
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
