import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "src/utils/ui/ui";

const badgeVariants = cva(
  "inline-flex items-center gap-x-0.5 rounded-md px-2 h-6 text-xs font-medium ring-1 ring-inset",
  {
    variants: {
      variant: {
        gray: "bg-gray-50 text-gray-600 ring-gray-500/10",
        red: "bg-red-50 text-red-600 ring-red-500/10",
        darkGreen: "bg-green-400 ring-green-600 text-white",
        green: "bg-green-50 text-green-600 ring-green-500/10",
        yellow: "bg-yellow-50 text-yellow-600 ring-yellow-500/10",
        blue: "bg-blue-50 text-blue-600 ring-blue-500/10",
      },
    },
    defaultVariants: {
      variant: "gray",
    },
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
