import React from "react";
import { relativeDueDateUnits } from "src/api/Services/Journeys/journeyTemplates";
import { TaskData, TaskerData } from "src/api/Services/Tasks/tasks";
import { JourneyStageObj } from "src/components/Services/Journeys/Journey/types";

type Options = {
  hasDependency: boolean;
};

type Action =
  | { type: "title_update"; title: string }
  | { type: "description_update"; description: string }
  | { type: "due_date_update"; dueDate: Date | undefined }
  | { type: "tasker_update"; tasker: TaskerData }
  | { type: "stage_update"; stage: JourneyStageObj }
  | { type: "estimate_update"; estimate: TaskData["estimate"] }
  | { type: "visibility_update"; isVisible: boolean }
  | { type: "dependent_on_update"; dependentOn: DependentOn }
  | { type: "relative_due_date_update"; relativeDueDate: RelativeDueDate }
  | {type: "adding_dependency_update"; addingDependency: boolean}

// | { type: "options_update"; options: Options }
// | { type: "estimate_update"; estimate: TaskData["relativeDueDate"] }
// | { type: "parent_task_update"; parentTask: ReducedTaskData };

type DependentOn = {
  _id?: string;
  type: "task" | "stage";
};

type Dispatch = (action: Action) => void;

type RelativeDueDate = {
  unit: (typeof relativeDueDateUnits)[number];
  value: number;
};

type State = {
  title: string;
  description: string;
  dueDate: Date | undefined;
  tasker: TaskerData | undefined;
  stage: JourneyStageObj | undefined;
  sharedSpaceId: string;
  journeyId: string;
  phaseId: string;
  estimate: TaskData["estimate"];
  isVisible: boolean;
  dependentOn: DependentOn;
  relativeDueDate: RelativeDueDate;
  addingDependency: boolean;
  // estimate: (typeof ESTIMATES)[number];
  // parentTask: ReducedTaskData | undefined;
  // options: Options;
};

const NewProjectTaskContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function newTaskReducer(state: State, action: Action) {
  switch (action.type) {
    case "title_update": {
      return { ...state, title: action.title };
    }
    case "description_update": {
      return { ...state, description: action.description };
    }
    case "due_date_update": {
      return { ...state, dueDate: action.dueDate };
    }
    case "tasker_update": {
      return { ...state, tasker: action.tasker };
    }
    case "estimate_update": {
      return { ...state, estimate: action.estimate };
    }
    case "visibility_update": {
      return { ...state, isVisible: action.isVisible };
    }
    case "stage_update": {
      return { ...state, stage: action.stage };
    }
    case "dependent_on_update": {
      return { ...state, dependentOn: action.dependentOn };
    }
    case "relative_due_date_update": {
      return { ...state, relativeDueDate: action.relativeDueDate };
    }
    case "adding_dependency_update": {
      return { ...state, addingDependency: action.addingDependency };
    }
    default: {
      return state;
    }
  }
}

export function NewProjectTaskContextProvider({
  children,
  sharedSpaceId,
  journeyId,
  phaseId,
}: {
  children: React.ReactNode;
  sharedSpaceId: string;
  journeyId: string;
  phaseId: string;
}) {
  const [state, dispatch] = React.useReducer(newTaskReducer, {
    title: "",
    description: "",
    dueDate: undefined,
    stage: undefined,
    tasker: undefined,
    sharedSpaceId,
    journeyId,
    phaseId,
    estimate: {
      unit: "minute",
      value: 10,
    },
    isVisible: true,
    dependentOn: {
      type: "task",
      _id: undefined,
    },
    relativeDueDate: {
      unit: "day",
      value: 1,
    },
    addingDependency: false,
    // estimate: 1,
    // parentTask: undefined,
    // options: {

    // },
  });

  const value = { state, dispatch };
  return (
    <NewProjectTaskContext.Provider value={value}>
      {children}
    </NewProjectTaskContext.Provider>
  );
}

export function useNewProjectTask() {
  const context = React.useContext(NewProjectTaskContext);
  if (context === undefined) {
    throw new Error(
      "useNewProjectTask must be used within a NewProjectTaskProvider"
    );
  }
  return context;
}
