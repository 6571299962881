import { createContext, useContext, useReducer } from "react";
import { PhaseStatus } from "src/api/Services/SharedSpace/phases";

type Action =
  | { type: "template_update"; template: string }
  | { type: "owner_update"; owner: string }
  | {
      type: "revenue_range_update";
      revenueRange: {
        min: number;
        max: number;
        currentMin: number;
        currentMax: number;
      };
    }
  | {
      type: "status_update";
      status: PhaseStatus;
    }
  | {
      type: "start_date_range_update";
      startDateRange: { min: string; max: string };
    };

type State = {
  template: string;
  owner: string;
  revenueRange: {
    min: number;
    max: number;
    currentMin: number;
    currentMax: number;
  };
  status: PhaseStatus;
  startDateRange: {
    min: string;
    max: string;
  };
};

type Dispatch = (action: Action) => void;

function reducer(state: State, action: Action) {
  switch (action.type) {
    case "template_update":
      return { ...state, template: action.template };
    case "owner_update":
      return { ...state, owner: action.owner };
    case "revenue_range_update":
      return { ...state, revenueRange: action.revenueRange };
    case "status_update":
      return { ...state, status: action.status };
    default:
      return state;
  }
}

const ProjectTemplatePerformanceContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

export const ProjectTemplatePerformanceContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(reducer, {
    template: "",
    owner: "all",
    revenueRange: { min: 0, max: 100, currentMin: 0, currentMax: 100 },
    status: "Completed",
    startDateRange: { min: "", max: "" },
  });
  return (
    <ProjectTemplatePerformanceContext.Provider value={{ state, dispatch }}>
      {children}
    </ProjectTemplatePerformanceContext.Provider>
  );
};

export const useProjectTemplatePerformanceContext = () => {
  const context = useContext(ProjectTemplatePerformanceContext);
  if (context === undefined) {
    throw new Error(
      "useProjectTemplatePerformanceContext must be used within a ProjectTemplatePerformanceContextProvider"
    );
  }
  return context;
};
