import { RocketLaunchIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import {
  SharedSpacePhaseData,
  useGetPhasesForExternalUser,
} from "src/api/SharedSpace";
import { PageHeading } from "src/components/ui/heading";
import { Loading } from "src/components/ui/loading";

type Props = {};

export default function ExternalProjectsForInternalUser({}: Props) {
  const { slug } = useParams();
  const { data: phases } = useGetPhasesForExternalUser(String(slug));
  const projects =
    phases?.filter(
      (phase) => phase.type === "Simple" || phase.type === "Customer Onboarding"
    ) ?? [];

  return (
    <>
      <div className="flex h-12 items-center border-b px-8">
        <div className="text-gray-800 text-sm">Projects</div>
      </div>
      <>
        {!!phases ? (
          projects.length > 0 ? (
            <div>
              <div className="flex h-11 items-center justify-between gap-8 border-b bg-gray-50 px-8 text-xs font-medium uppercase text-gray-500">
                <div className="min-w-[160px] flex-1">Project</div>
                <div className="flex shrink-0 items-center">
                  <div className="w-40 shrink-0">Progress</div>
                  <div className="w-40 shrink-0">Start Date</div>
                </div>
              </div>
              {projects.map((phase) => (
                <ProjectRow key={phase._id} phase={phase} />
              ))}
            </div>
          ) : (
            <div className="mt-[20vh] flex justify-center">
              <div className="flex flex-col">
                <div className="flex items-center">
                  <RocketLaunchIcon className="mr-2.5 h-7 w-7 text-gray-400" />
                  <div className="text-xl font-medium text-gray-700">
                    Projects
                  </div>
                </div>
                <div className="mt-1 max-w-[600px] font-light text-gray-500">
                  Projects enable you to set goal and track progress. Currently,
                  you have no projects.
                </div>
              </div>
            </div>
          )
        ) : (
          <div>
            <Loading className="mt-[20vh]" />
          </div>
        )}
      </>
    </>
  );
}

function ProjectRow({ phase }: { phase: SharedSpacePhaseData }) {
  const progress =
    (phase.tasksCount.overall.completed /
      (phase.tasksCount.overall.completed +
        phase.tasksCount.overall.inProgress +
        phase.tasksCount.overall.upcoming +
        phase.tasksCount.overall.toDo)) *
    100;
  const navigate = useNavigate();
  return (
    <div
      className="flex h-11 cursor-pointer items-center justify-between border-b border-gray-100 px-8 text-sm text-gray-500 hover:bg-gray-50"
      onClick={() => {
        navigate(`${phase._id}`);
      }}
    >
      <div className="min-w-[160px] flex-1">{phase.name}</div>
      <div className="item]s-center flex shrink-0">
        <div className="w-40 shrink-0">{`${progress.toFixed(0)}%`}</div>
        <div className="w-40 shrink-0">
          {dayjs(phase.createdAt).format("DD MMM")}
        </div>
      </div>
    </div>
  );
}
