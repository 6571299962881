import { ObjectID } from "bson";
import { useNavigate, useParams } from "react-router-dom";
import { useGetJourney } from "src/api/Services/Journeys/journeys";
import { PhaseData } from "src/api/Services/SharedSpace/phases";
import { useGetPhaseThreads } from "src/api/Services/Threads/threads";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/components/ui/tabs";
import Journey from "./Tabs/Journey";
import Notes from "./Tabs/Notes";
import Overview from "./Tabs/Overview";
import PhaseThreads from "./Tabs/Threads";
import PhaseResources from "./Tabs/resources/resources";
import { ResourcesContextProvider } from "./Tabs/resources/resources-context-provider";

type Props = {
  phase: PhaseData;
};

export default function CustomerOnboardingPhase({ phase }: Props) {
  const navigate = useNavigate();
  const { phaseId, tab } = useParams();
  if (!tab) {
    navigate(`/projects/${phaseId}/home`, {
      replace: true,
    });
  } else {
    if (
      tab !== "home" &&
      tab !== "tasks" &&
      tab !== "chat" &&
      tab !== "resources" &&
      tab !== "notes"
    ) {
      navigate(`/projects/${phaseId}/home`, {
        replace: true,
      });
    }
  }
  const { data: threads } = useGetPhaseThreads(String(phaseId));
  const { journey } = useGetJourney(new ObjectID(phase.journey._id));
  const threadCount =
    threads?.reduce((acc, t) => acc + t.otherDetails.unreadMessagesCount, 0) ??
    0;
  return (
    <Tabs
      defaultValue="home"
      value={tab}
      className="flex flex-1 flex-col overflow-auto pt-1"
      onValueChange={(value) => {
        navigate(`/projects/${phaseId}/${value}`);
      }}
    >
      <TabsList className="shrink-0 px-8 border-gray-200/70">
        <TabsTrigger className="font-normal text-gray-500/80" value="home">Home</TabsTrigger>
        <TabsTrigger className="font-normal text-gray-500/80" value="tasks">Tasks</TabsTrigger>
        <TabsTrigger className="font-normal text-gray-500/80" value="chat">
          <div className="inline-flex space-x-2">
            <span>Chat</span>
            {threadCount > 0 && (
              <span
                className={
                  "ml-3 hidden min-w-[18px] rounded bg-primary py-0.5 px-1 text-xs font-medium text-white data-[state=active]:bg-primary-100 data-[state=active]:text-primary-600 md:inline-block"
                }
              >
                {threadCount}
              </span>
            )}
          </div>
        </TabsTrigger>
        <TabsTrigger className="font-normal text-gray-500/80" value="resources">Resources</TabsTrigger>
        <TabsTrigger className="font-normal text-gray-500/80" value="notes">Notes</TabsTrigger>
      </TabsList>
      <div className="flex-1 overflow-auto">
        <TabsContent value="home" className="h-full">
          <>{!!journey && <Overview journey={journey} phase={phase} />}</>
        </TabsContent>
        <TabsContent value="tasks" className="h-full overflow-auto">
          <Journey phase={phase} />
        </TabsContent>
        <TabsContent value="chat" className="h-full">
          <PhaseThreads threads={threads ?? []} />
        </TabsContent>
        <TabsContent value="resources" className="">
          <ResourcesContextProvider>
            {phaseId && <PhaseResources phaseId={phaseId} />}
          </ResourcesContextProvider>
          {/* <FilesContextProvider isExternal={false}>
            <Files phase={phase} />
          </FilesContextProvider> */}
        </TabsContent>
        <TabsContent value="notes">
          <Notes />
        </TabsContent>
      </div>
    </Tabs>
  );
}
