import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  RoadmapData,
  useDeleteRoadmap,
  useGetRoadmap,
  useUpdateRoadmap,
} from "src/api/Roadmaps/roadmaps";
import { Breadcrumbs } from "src/components/ui/Navigation/Breadcrumbs";
import { Button } from "src/components/ui/button";
import { Dialog, DialogContent } from "src/components/ui/dialog";

type Props = {};

export default function RoadmapSettings({}: Props) {
  const { projectGroupId } = useParams();
  const { data: projectGroup } = useGetRoadmap(String(projectGroupId));
  const [name, setName] = useState("");
  const updateRoadmapMutation = useUpdateRoadmap(String(projectGroupId));

  useEffect(() => {
    if (projectGroup?.name) {
      setName(projectGroup?.name ?? "");
    }
  }, [projectGroup?.name]);

  return (
    <div>
      <div className="px-8 pt-6">
        <div className="space-y-2">
          <Breadcrumbs
            breadcrumbs={[
              {
                title: "Project Groups",
                route: "/project-groups",
              },
              {
                title: projectGroup?.name ?? "",
                route: `/project-groups/${projectGroupId}`,
              },
              {
                title: "Settings",
                // route: `/project-groups/${projectGroupId}/settings`,
              },
            ]}
          />
          <div className="cursor-default text-xl font-medium leading-none text-gray-900">
            {"Settings"}
          </div>
        </div>
        <div className="mx-auto w-full max-w-2xl divide-y pt-12">
          <div>
            <div className="space-y-6 pb-6">
              <label className="text-lg font-medium text-gray-600">
                Details
              </label>
              <div className="space-y-5">
                <div className="flex items-center">
                  <label className="w-40 shrink-0 text-sm text-gray-400">
                    Name
                  </label>
                  {projectGroup && (
                    <EditableProjectGroupName projectGroup={projectGroup} />
                  )}
                </div>
                <div className="flex">
                  <label className="w-40 shrink-0 text-sm text-gray-400">
                    Launch Date
                  </label>
                  <div className="text-sm text-gray-600">
                    {dayjs(projectGroup?.createdAt).format("MMM DD, YYYY")}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-6 py-6">
            <label className="text-md w-40 shrink-0 font-medium text-gray-600">
              Actions
            </label>
            {/* 
          <div className="flex items-center">
            <label className="w-40 shrink-0 text-sm text-gray-400">
              Link Project
            </label>
            <LinkProjectButton />
          </div> */}
            <div className="flex items-center">
              <label className="w-40 shrink-0 text-sm text-gray-400">
                Delete
              </label>
              {!!projectGroup && (
                <DeleteProjectGroupButton projectGroup={projectGroup} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function EditableProjectGroupName({
  projectGroup,
}: {
  projectGroup: RoadmapData;
}) {
  const [heading, setHeading] = useState(projectGroup.name);
  const updateRoadmapMutation = useUpdateRoadmap(projectGroup._id);

  useEffect(() => {
    setHeading(projectGroup.name);
  }, [projectGroup.name]);

  return (
    <div className="relative flex-1">
      <input
        className="w-full min-w-0 rounded-md border border-transparent py-1.5 text-sm text-gray-600 outline-none transition-all delay-150
           hover:border-gray-200 hover:px-2.5 hover:shadow-sm focus:border focus:border-primary-main focus:px-3.5 focus:shadow-sm focus:outline-none focus:ring-0"
        value={heading}
        onChange={(e) => {
          setHeading(e.target.value);
        }}
        onBlur={() => {
          if (heading.trim() !== projectGroup.name) {
            updateRoadmapMutation.mutate({
              _id: projectGroup._id,
              name: heading,
            });
          }
        }}
      />
    </div>
  );
}

function DeleteProjectGroupButton({
  projectGroup,
}: {
  projectGroup: RoadmapData;
}) {
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const deleteRoadmapMutation = useDeleteRoadmap();
  return (
    <>
      <Button
        variant={"secondary"}
        onClick={() => {
          setIsConfirmingDelete(true);
        }}
        size={"sm"}
      >
        Delete
      </Button>
      <Dialog open={isConfirmingDelete} onOpenChange={setIsConfirmingDelete}>
        <DialogContent>
          <div className="space-y-6">
            <div className="space-y-1">
              <div>Are you sure you want to delete this project group?</div>
            </div>
            <div className="flex justify-end space-x-3">
              <Button
                variant={"secondary"}
                onClick={() => setIsConfirmingDelete(false)}
              >
                Cancel
              </Button>
              <Button
                variant={"destructive"}
                onClick={() => {
                  deleteRoadmapMutation.mutate({
                    _id: projectGroup._id,
                  });
                }}
              >
                {deleteRoadmapMutation.isLoading ? "Deleting.." : "Delete"}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
