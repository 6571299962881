import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "src/components/ui/tooltip";
// import { EyeIcon } from "@heroicons/react/24/outline";
import {
  CheckCircleIcon,
  CloudArrowUpIcon,
  PhoneIcon,
} from "@heroicons/react/24/solid";
import clsx from "clsx";
import dayjs from "dayjs";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TaskData } from "src/api/Services/Tasks/tasks";
// import { StatusSelect } from "src/components/ui/Components/StatusSelect";
import {
  DocumentTextIcon,
  EyeSlashIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { CalendarIcon, CircleIcon, UploadIcon } from "@radix-ui/react-icons";
import { StatusSelect } from "src/pages/Services/Tasks/Task";
import { getTimeDifference } from "src/utils/functions/dateTime";
import { getIdentifier } from "src/utils/functions/tasks";
import { useJourney } from "../../JourneyContextProvider";

type Props = {
  task: TaskData;
};

export const TaskCard = ({ task }: Props) => {
  // const journeyCtx = useJourney();
  const [taskSelection, setTaskSelection] = useState<
    | {
        taskId: string;
        isOpen: boolean;
      }
    | undefined
  >(undefined);

  const { slug } = useParams();
  const navigate = useNavigate();
  const journeyCtx = useJourney();
  const location = useLocation();

  return (
    <div
      className={clsx(
        "relative cursor-pointer space-y-1.5 rounded-lg border-[0.5px] border-gray-300 bg-white pt-3.5 pb-2.5 shadow transition-colors hover:bg-white/50",
        // task.taskerType === "external" &&
        //   "border-l-[3px] border-l-gray-400/80"
        !task.dueDate && "opacity-50"
      )}
      onClick={() => {
        if (task._id) {
          const searchParams = new URLSearchParams(location.search);
          searchParams.set("taskId", task._id);
          navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
          });
        }
      }}
    >
      <div className="flex items-center space-x-2">
        {/* {task.status === "Completed" ? (
          <div className="inline-flex h-3.5 w-3.5 shrink-0 items-center justify-center rounded border border-green-600 bg-green-400">
            <CheckIcon className="h-3 w-3 shrink-0 text-green-800" />
          </div>
        ) : (
          <div className="inline-flex h-3.5 w-3.5 shrink-0 items-center justify-center rounded border border-gray-600 bg-gray-300">
            <Cross2Icon className="h-2.5 w-2.5 shrink-0 text-gray-800" />
          </div>
        )} */}
        <div
          className={clsx(
            "px-4 text-sm font-medium text-gray-900",
            (task.status === "Completed" || task.status === "Canceled") &&
              "truncate"
          )}
        >
          {task.title}
        </div>
      </div>
      <div className="px-2.5">
        {task.dueDate ? (
          <div className="flex h-6 items-center space-x-2 px-1.5">
            <div>
              <StatusSelect
                isProjectView={true}
                task={task}
                disabled={
                  task.taskerType === "internal" && journeyCtx.state.isExternal
                }
                disabledStatuses={[
                  ...(task.action === "form" || task.action === "fileUpload"
                    ? ["Completed" as TaskData["status"]]
                    : []),
                  ...(journeyCtx.state.isExternal
                    ? ["Canceled" as TaskData["status"]]
                    : []),
                  "Backlog",
                ]}
                isSymbol={true}
              />
            </div>
            <div className="text-xs text-gray-400">{task.customStatus}</div>
          </div>
        ) : (
          <div className="flex h-6 items-center space-x-2 px-1.5">
            <CircleIcon className="h-3.5 w-3.5 text-gray-400/70" />
            <div className="text-xs text-gray-400">Inactive</div>
          </div>
        )}
        {task.status !== "Completed" && task.status !== "Canceled" && (
          <>
            <div className="flex h-6 items-center space-x-2 px-1.5">
              <CalendarIcon
                className={clsx(
                  "h-3.5 w-3.5",
                  task.dueDate && dayjs(task.dueDate).isBefore(new Date())
                    ? "text-red-500"
                    : "text-gray-400/70"
                )}
              />
              {task.dueDate ? (
                <div
                  className={clsx(
                    "text-xs",
                    task.dueDate && dayjs(task.dueDate).isBefore(new Date())
                      ? "text-red-500"
                      : "text-gray-400"
                  )}
                >
                  {dayjs(task.dueDate).format("MMM DD")}
                </div>
              ) : (
                <>
                  {task.durations?.forecasted && (
                    <div className={clsx("text-xs text-gray-500")}>
                      {dayjs(
                        new Date().getTime() + task.durations.forecasted
                      ).format("MMM DD")}
                    </div>
                  )}
                </>
              )}
            </div>
            {task.action === "meeting" && (
              <div className="flex h-6 items-center justify-between gap-3 px-1.5">
                <div className="flex items-center space-x-2">
                  <PhoneIcon className="h-3.5 w-3.5 shrink-0 text-gray-400/70" />
                  <div className={clsx("text-xs text-gray-400")}>
                    {"Call Scheduling"}
                  </div>
                </div>
              </div>
            )}
            {task.action === "fileUpload" && (
              <div className="flex h-6 items-center justify-between gap-3 px-1.5">
                <div className="flex items-center space-x-2">
                  <UploadIcon className="h-3.5 w-3.5 shrink-0 text-gray-400/70" />
                  <div className={clsx("text-xs text-gray-400")}>
                    {"File Upload"}
                  </div>
                </div>
              </div>
            )}
            {task.action === "form" && (
              <div className="flex h-6 items-center justify-between gap-3 px-1.5">
                <div className="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={14}
                    height={14}
                    className="text-gray-400/70"
                    fill={"none"}
                  >
                    <path
                      d="M6.51331 16H10.5133M6.51331 11H14.5133"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                    <path
                      d="M10.0133 22H11.0133"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                    <path
                      d="M7.50993 22C6.36069 21.975 5.58621 22 4.58688 21.775C3.53758 21.5 2.88801 20.85 2.66317 19.55C2.43831 18.7 2.5106 14.9238 2.51327 11.325C2.51533 8.53219 2.53385 5.99934 2.7631 5.475C3.08789 4.35 3.83739 3.55 6.16084 3.525M16.0292 3.525C16.8287 3.6 18.9184 3.525 19.327 5.825C19.5491 7.075 19.5019 8.85 19.5019 10.975M8.18449 5.5C9.23379 5.525 12.6065 5.5 13.7558 5.5C14.905 5.5 15.5123 4.55409 15.5046 3.675C15.4967 2.77925 14.7051 2.08017 13.9307 2C12.9813 2 8.95897 2 8.1595 2C7.23512 2.05 6.61054 2.8 6.5106 3.55C6.41067 4.575 7.16017 5.45 8.18449 5.5Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                    <path
                      d="M18.2776 14.375C16.9035 15.775 14.2553 18.275 14.2553 18.45C14.0417 18.7468 13.8555 19.35 13.7306 20.2C13.5737 20.9879 13.3858 21.675 13.6057 21.875C13.8256 22.075 14.6535 21.9064 15.5294 21.725C16.229 21.65 16.8785 21.4 17.2033 21.15C17.678 20.7298 20.9009 17.475 21.2756 17.05C21.5504 16.675 21.5754 15.975 21.3356 15.55C21.2007 15.25 20.3512 14.45 20.0764 14.225C19.5767 13.925 18.8772 13.875 18.2776 14.375Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>
                  <div className={clsx("text-xs text-gray-400")}>{"Form"}</div>
                </div>
              </div>
            )}
            <div className="flex h-6 items-center justify-between gap-3 px-1.5">
              <div className="flex items-center space-x-2">
                <UserIcon
                  className={clsx(
                    "h-3.5 w-3.5 shrink-0",
                    task.tasker.userType === "persona"
                      ? "text-red-500"
                      : "text-gray-400/70"
                  )}
                />
                <div
                  className={clsx(
                    "max-w-[140px] truncate text-xs",
                    task.tasker.userType === "persona"
                      ? "text-red-500"
                      : "text-gray-400"
                  )}
                >
                  {task.tasker.userType === "persona"
                    ? "Unassigned"
                    : getIdentifier(task.tasker).identifier}
                </div>
              </div>
            </div>

            {!!task.subTaskCount && (
              <div className="flex h-6 items-center justify-between gap-3 px-1.5">
                <div className="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="4 0 24 24"
                    className="text-gray-400"
                  >
                    <path
                      fill="currentColor"
                      d="M17 16a2.99 2.99 0 0 0-2.816 2H11c-1.654 0-3-1.346-3-3v-3.025A4.954 4.954 0 0 0 11 13h3.184A2.99 2.99 0 0 0 17 15a3 3 0 1 0 0-6a2.99 2.99 0 0 0-2.816 2H11c-1.654 0-3-1.346-3-3v-.184A2.99 2.99 0 0 0 10 5a3 3 0 1 0-6 0a2.99 2.99 0 0 0 2 2.816V15c0 2.757 2.243 5 5 5h3.184A2.99 2.99 0 0 0 17 22a3 3 0 1 0 0-6zm0-5a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zM7 4a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zm10 16a1.001 1.001 0 0 1 0-2a1.001 1.001 0 0 1 0 2z"
                    />
                  </svg>
                  <div className={clsx("text-xs text-gray-400")}>
                    {`${task.subTaskCount} subtasks`}
                  </div>
                </div>
              </div>
            )}

            {/* {task.taskerType === "external" && (
              <div className="flex h-6 items-center space-x-2 px-1.5">
                <BuildingOfficeIcon className="h-3.5 w-3.5 text-gray-400/70" />
                <div className="rounded border bg-gray-200 px-1 text-xs text-gray-800">
                  {task.customer?.name ?? ""}
                </div>
              </div>
            )} */}
          </>
        )}
        {/* <div className="flex items-center">
          <div
            className={clsx(
              "h-5 w-4 rounded-l border border-amber-500 bg-amber-300 transition-all",
              "hover:bg-amber-500"
            )}
          />
          <div className="border-r border-y border-amber-500 rounded-r-md h-5 text-xs inline-flex items-center pr-1.5 pl-[5px] font-light">Not Started</div>
        </div> */}
        {/* <StatusSelect
          isProjectView={true}
          task={task}
          disabled={
            task.taskerType === "internal" && journeyCtx.state.isExternal
          }
          disabledStatuses={[
            ...(task.action === "form" || task.action === "fileUpload"
              ? ["Completed" as TaskData["status"]]
              : []),
            ...(journeyCtx.state.isExternal
              ? ["Canceled" as TaskData["status"]]
              : []),
            "Backlog",
          ]}
          isTag={true}
        /> */}
      </div>
      {task.taskerType === "external" && (
        <div className="absolute right-3.5 bottom-3.5 max-w-[100px] truncate rounded border bg-gray-200/70 px-1 text-xs text-gray-800">
          {task.customer?.name}
        </div>
      )}
    </div>
  );

  if (!task.dueDate) {
    return (
      <div
        className={clsx(
          "relative cursor-pointer rounded-lg border-[0.5px] border-gray-300 bg-white pt-3.5 pb-4 shadow-sm"
          // task.taskerType === "external" &&
          //   "border-l-[3px] border-l-gray-400/80"
        )}
        onClick={() => {
          if (task._id) {
            const searchParams = new URLSearchParams(location.search);
            searchParams.set("taskId", task._id);
            navigate({
              pathname: location.pathname,
              search: searchParams.toString(),
            });
          }
        }}
      >
        <div
          className={clsx(
            "flex space-x-2 px-4 pb-3",
            task.taskerType === "external" && "border-gray-400"
          )}
        >
          <div className="truncate text-sm text-gray-500">{task.title}</div>
        </div>
        <div className="flex items-center space-x-1.5 px-4 text-xs text-gray-500">
          <div className="shrink-0 rounded border border-gray-200/70 bg-gray-50 px-1.5 py-0.5 text-xs">
            Inactive
          </div>
          {/* <span>Forecasted Completion Date - </span> */}
          {/* {task.durations?.forecasted && (
            <ForecastedDate duration={task.durations?.forecasted} />
            // <TooltipProvider>
            //   <Tooltip delayDuration={0}>
            //     <TooltipTrigger>
            //     </TooltipTrigger>
            //     <TooltipContent side="right" sideOffset={8}>
            //       <p>Forecasted completion date</p>
            //     </TooltipContent>
            //   </Tooltip>
            // </TooltipProvider>s
          )} */}
          {/* {task.taskerType === "external" && (
            <div className="flex flex-1 justify-end">
              <div className="ml-2 h-2 w-2 rounded-full bg-gray-400"></div>
            </div>
          )} */}
        </div>
      </div>
    );
  }

  if (task.status === "Completed" || task.status === "Canceled") {
  }

  return (
    <div
      className={clsx(
        "relative cursor-pointer rounded-lg border-[0.5px] border-gray-300 bg-white pt-3.5 pb-4 transition-all hover:bg-white/70"
        // task.taskerType === "external" &&
        //   "rounded-r-lg border-l-[3px] border-l-gray-400/80"
        // !task.dueDate && task.taskerType === "internal" && "border-gray-400"
      )}
      onClick={() => {
        if (task._id) {
          const searchParams = new URLSearchParams(location.search);
          searchParams.set("taskId", task._id);
          navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
          });
        }
      }}
    >
      {/* {task.taskerType === "external" && (
        <div
          style={{ height: "calc(100% - 20px)" }}
          className="absolute -ml-[3px] top-1/2 w-[3px] -translate-y-1/2 bg-gray-400"
        />
      )} */}
      <div className="px-4">
        {!task.dueDate && (
          <div className="mb-1.5 flex flex-shrink items-center truncate rounded-full border border-gray-300 bg-white px-2 py-0.5 text-xs text-gray-500">
            <span
              className={clsx(
                "font-medium",
                !journeyCtx.state.isExternal
                  ? "text-primary-main"
                  : "font-bold text-embedPrimary"
              )}
            >
              {"Dependent"}
            </span>
            <span className="whitespace-pre font-light">{" on "}</span>
            {journeyCtx.state.isExternal ? (
              <span className="flex-shrink truncate font-light">
                another task
              </span>
            ) : (
              <span className="flex-shrink truncate font-medium">
                {task.dependentOn?.name}
              </span>
            )}
          </div>
        )}
        <div className="flex items-start justify-between space-x-2 text-sm font-medium">
          <p className={clsx("text-gray-700")}>{task.title}</p>
          {/* {task.blocker && !task.blocker?.resolved && (
            <div className="rounded-full bg-red-500 px-2 py-0.5 text-xs text-white">
              Blocked
            </div>
          )} */}
        </div>
        {/* {task.dueDate &&
          !task.completionDate &&
          (task.action === "form" ||
            task.action === "fileUpload" ||
            task.action === "meeting") && (
            <>
              {task.action === "form" && (
                <div>
                  <p className="text-xs text-gray-500">
                    {"Click to open the attached form"}
                  </p>
                </div>
              )}
              {task.action === "meeting" && (
                <div>
                  <div className="text-xs text-gray-500">
                    Click{" "}
                    <span
                      className="font-bold underline hover:text-gray-700"
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(task.meeting?.link, "_blank");
                      }}
                    >
                      here
                    </span>{" "}
                    to book a call
                  </div>
                </div>
              )}
              {task.action === "fileUpload" && (
                <div>
                  <p className="text-xs text-gray-500">
                    {"Click to upload a file"}
                  </p>
                </div>
              )}
            </>
          )} */}
        {/* {!!task.dueDate && (
              <div>
                <p className="text-xs text-gray-500">{"Due in 5 days"}</p>
              </div>
            )} */}
        <div className="flex items-end space-x-2 pt-3">
          {/* <div className="inline-flex h-max items-center">
            <Indicator task={task} /> 
          </div> */}
          {!!task.dueDate ? (
            <div className="space-y-2">
              <div
                className={clsx(
                  "flex items-center space-x-1.5",
                  !task.completionDate && "mt-0.5",
                  !!task.completionDate && "w-full justify-between"
                )}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <StatusSelect
                  isProjectView={true}
                  task={task}
                  disabled={
                    task.taskerType === "internal" &&
                    journeyCtx.state.isExternal
                  }
                  disabledStatuses={[
                    ...(task.action === "form" || task.action === "fileUpload"
                      ? ["Completed" as TaskData["status"]]
                      : []),
                    ...(journeyCtx.state.isExternal
                      ? ["Canceled" as TaskData["status"]]
                      : []),
                    "Backlog",
                  ]}
                  isTag={true}
                />

                {!!task.completionDate && (
                  <>
                    {task.tasker.userType === "fullUser" ||
                      (task.tasker.userType === "invitedUser" && (
                        <div
                          className={clsx(
                            "truncate rounded-full border px-2 py-0.5 text-xs",
                            task.taskerType === "external"
                              ? "border-gray-200/70 bg-gray-50 text-black"
                              : "border-gray-500 bg-white text-gray-500"
                          )}
                        >
                          {getIdentifier(task.tasker).identifier}
                        </div>
                      ))}
                  </>
                )}
                {!task.completionDate && <DueDate task={task} />}
                <div
                  className={clsx(
                    "w-fit truncate rounded border px-1.5 py-0.5 text-xs",
                    task.taskerType === "external"
                      ? "border-gray-200/70 bg-gray-50 text-gray-600"
                      : "border-gray-200/70 bg-gray-50 text-gray-600",
                    task.tasker.userType === "persona" &&
                      "border-red-200/70 bg-red-50/70 text-red-500"
                  )}
                >
                  {task.tasker.userType === "persona"
                    ? "Unassigned"
                    : getIdentifier(task.tasker).identifier}
                </div>
                {/* {!task.completionDate && <CompletionStatus task={task} />} */}
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* {task.issue && (
            <span className="inline-flex items-center rounded-full border border-red-500 px-2 py-0.5 text-xs text-red-500">
              Issue
            </span>
          )} */}

          {/* <div>
          <Avatar task={task} />
        </div> */}
        </div>
      </div>

      {((!task.isVisible && !journeyCtx.state.isExternal) ||
        task.action === "form" ||
        task.action === "meeting" ||
        task.action === "fileUpload" ||
        !!task.subTaskCount) && (
        <div className="flex items-center justify-between px-4 pt-4">
          <div className="flex items-center space-x-2">
            {!task.isVisible && !journeyCtx.state.isExternal && (
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger asChild>
                    <EyeSlashIcon
                      className={clsx(
                        "h-[18px] w-[18px] shrink-0 text-gray-400/80"
                      )}
                    />
                  </TooltipTrigger>
                  <TooltipContent
                    side="right"
                    sideOffset={1}
                    className="p-2 py-1"
                  >
                    <p>Externally hidden</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
            {task.action === "form" && (
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger asChild>
                    <DocumentTextIcon
                      className={clsx("h-5 w-5 shrink-0 text-gray-400/80")}
                    />
                  </TooltipTrigger>
                  <TooltipContent
                    side="right"
                    sideOffset={1}
                    className="p-2 py-1"
                  >
                    <p>Form task</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
            {task.action === "fileUpload" && (
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger asChild>
                    <CloudArrowUpIcon
                      className={clsx("h-5 w-5 shrink-0 text-gray-400/80")}
                    />
                  </TooltipTrigger>
                  <TooltipContent
                    side="right"
                    sideOffset={1}
                    className="p-2 py-1"
                  >
                    <p>File upload task</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
            {task.action === "meeting" && (
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger asChild>
                    <PhoneIcon
                      className={clsx("h-4 w-4 shrink-0 text-gray-400/80")}
                      // viewBox=""
                    />
                  </TooltipTrigger>
                  <TooltipContent
                    side="right"
                    sideOffset={1}
                    className="p-2 py-1"
                  >
                    <p>Call task</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
            {!!task.subTaskCount && (
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger asChild>
                    <div className="inline-flex items-center space-x-0.5 text-sm text-gray-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="4 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M17 16a2.99 2.99 0 0 0-2.816 2H11c-1.654 0-3-1.346-3-3v-3.025A4.954 4.954 0 0 0 11 13h3.184A2.99 2.99 0 0 0 17 15a3 3 0 1 0 0-6a2.99 2.99 0 0 0-2.816 2H11c-1.654 0-3-1.346-3-3v-.184A2.99 2.99 0 0 0 10 5a3 3 0 1 0-6 0a2.99 2.99 0 0 0 2 2.816V15c0 2.757 2.243 5 5 5h3.184A2.99 2.99 0 0 0 17 22a3 3 0 1 0 0-6zm0-5a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zM7 4a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zm10 16a1.001 1.001 0 0 1 0-2a1.001 1.001 0 0 1 0 2z"
                        />
                      </svg>
                      <span>{task.subTaskCount}</span>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent
                    side="right"
                    sideOffset={1}
                    className="p-2 py-1"
                  >
                    <p>{`${task.subTaskCount} subtasks`}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

// export const StatusSelect = ({ task }: { task: TaskData }) => {
//   // const updateJourneyTaskMutation = useUpdateJourneyTask();
//   const journeyCtx = useJourney();
//   const updateTaskMutation = useUpdateTask();
//   const { slug } = useParams();
//   return (
//     <div className="shrink-0">

//       <Status
//         disabled={
//           (task.taskerType === "internal" && journeyCtx.state.isExternal) ||
//           task.action === "form" ||
//           task.action === "fileUpload"
//         }
//         isButtonIcon={true}
//         onChange={(status) => {
//           if (task.journeyStage?._id && task.journey?._id) {
//             if (status === "Completed" || status === "Canceled") {
//               updateTaskMutation.mutate({
//                 update: {
//                   _id: new ObjectID(task._id),
//                   status: status,
//                   completionDate: new Date(),
//                 },
//                 metadata: {
//                   phaseId: task?.phase?._id,
//                   journeyId: task?.journey?._id,
//                   journeyStageId: task?.journeyStage?._id,
//                 },
//               });
//             } else {
//               updateTaskMutation.mutate({
//                 update: {
//                   _id: new ObjectID(task._id),
//                   status: status,
//                   completionDate: undefined,
//                 },
//                 metadata: {
//                   phaseId: task?.phase?._id,
//                   journeyId: task?.journey?._id,
//                   journeyStageId: task?.journeyStage?._id,
//                 },
//               });
//             }
//           }
//         }}
//         status={task.status}
//         isBacklogAvailable={false}

//         // selected={task.status}
//         // button={
//         //   <span
//         //     className={clsx(
//         //       task.status === "To-do" &&
//         //         "border-amber-600 bg-amber-400 hover:border-amber-500 hover:bg-amber-500",
//         //       task.status === "In Progress" &&
//         //         "border-blue-600 bg-blue-400 hover:border-blue-500 hover:bg-blue-500",
//         //       task.status === "Completed" &&
//         //         "border-green-600 bg-green-400 hover:border-green-500 hover:bg-green-500",
//         //       task.status === "Canceled" &&
//         //         "border-gray-400 bg-gray-300 hover:border-gray-400 hover:bg-gray-400",
//         //       "inline-flex items-center space-x-1 rounded-full border py-0.5 pl-2 pr-1.5 text-xs text-white"
//         //     )}
//         //   >
//         //     <span className="whitespace-nowrap">{task.status}</span>
//         //     <ChevronDownIcon className="h-4 w-4 text-white" />
//         //   </span>
//         // }
//         // onChange={(v) => {
//         //   if (v) {
//         //     if (task.journeyStage?._id && task.journey?._id) {
//         //       updateTaskMutation.mutate({
//         //         update: { _id: new ObjectID(task._id), status: v },
//         //         metadata: {
//         //           phaseId: task?.phase?._id,
//         //           journeyId: task?.journey?._id,
//         //         },
//         //       });
//         //       // updateJourneyTaskMutation.mutate({
//         //       //   update: {
//         //       //     _id: task._id,
//         //       //     status: v,
//         //       //   },
//         //       //   stageId: task.journeyStage?._id,
//         //       //   journeyId: task.journey?._id,
//         //       // });
//         //     }
//         //   }
//         // }}
//       />
//     </div>
//   );
// };

function ForecastedDate({ duration }: { duration: number }) {
  const forecastedDate = new Date(new Date().getTime() + duration);
  return (
    // <span
    //   className={clsx(
    //     "text-xs text-gray-400"
    //   )}
    // >
    //   {dayjs(forecastedDate).format("MMM DD")}
    // </span>
    <span
      className={clsx(
        "inline-flex shrink-0 items-center whitespace-nowrap rounded border border-gray-200/70 bg-gray-50 px-1.5 py-0.5 text-xs text-gray-500"
      )}
    >
      {/* <span className="inline-flex items-center px-1 text-xs text-gray-400"> */}
      {dayjs(forecastedDate).format("MMM DD")}
    </span>
  );
}

export const CompletionStatus = ({ task }: { task: TaskData }) => {
  const getCompletionStatus = () => {
    if (task.dueDate) {
      if (task.completionDate) {
        const { absValue, unit, isDate2After } = getTimeDifference(
          new Date(task.completionDate),
          new Date(task.dueDate)
        );
        if (isDate2After) {
          return (
            <span className="inline-flex items-center truncate rounded-full border border-green-400 bg-white px-2 py-0.5 text-xs text-green-500">
              On Time Completion
            </span>
          );
        } else {
          return (
            <span className="inline-flex items-center truncate rounded-full border border-red-400 bg-white px-2 py-0.5 text-xs text-red-500">
              Late Completion
            </span>
          );
        }
      } else {
        const { absValue, unit, isDate2After } = getTimeDifference(
          new Date(),
          new Date(task.dueDate)
        );
        if (isDate2After) {
          return (
            <span
              className={clsx(
                "inline-flex items-center truncate rounded-full border bg-white px-2 py-0.5 text-xs",
                unit !== "day" && unit !== "days"
                  ? "border-amber-500 text-amber-500"
                  : "border-gray-400 text-gray-500"
              )}
            >
              {absValue + " " + unit + " left"}
            </span>
          );
        } else {
          return (
            <span className="inline-flex items-center truncate rounded-full border border-red-400 bg-white  px-2 py-0.5 text-xs text-red-500">
              {absValue + " " + unit + " overdue"}
            </span>
          );
        }
      }
    } else {
      return <></>;
    }
  };
  return <>{getCompletionStatus()}</>;
};

export const DueDate = ({ task }: { task: TaskData }) => {
  function getDueDate() {
    if (task.dueDate) {
      // is task due date after current date
      const isDate2After =
        new Date(task.dueDate).getTime() < new Date().getTime();
      // const { absValue, unit, isDate2After } = getTimeDifference(
      //   new Date(),
      //   new Date(task.dueDate)
      // );

      return (
        <span
          className={clsx(
            // !isDate2After && "border-red-500 text-red-500",
            // isDate2After &&
            //   unit !== "day" &&
            //   unit !== "days" &&
            //   "border-amber-600 text-amber-500",
            "inline-flex shrink-0 items-center rounded border px-1.5 py-0.5 text-xs",
            isDate2After
              ? "border-red-200/70 bg-red-50/70 text-red-500"
              : "border-gray-200/70 bg-gray-50 text-gray-600"
          )}
        >
          {/* <span className="inline-flex items-center px-1 text-xs text-gray-400"> */}
          {dayjs(task.dueDate).format("MMM DD")}
        </span>
      );
    }
  }
  return <>{getDueDate()}</>;
};

const Indicator = ({ task }: { task: TaskData }) => {
  // console.log(task.dueDate?.getTime())
  // console.log(new Date().getTime())

  const hw = "h-4 w-4";
  const hwIcon = "h-5 w-5";
  if (!task.dueDate) {
    return <div className={clsx(hw, "rounded-full bg-gray-400", hw)}></div>;
  }

  if (task.status === "Completed" && !!task.completionDate) {
    return (
      <CheckCircleIcon
        className={clsx(
          hwIcon,
          new Date(task.dueDate).getTime() >
            new Date(task.completionDate).getTime()
            ? "text-green-400"
            : "text-red-400"
        )}
      />
    );
  } else {
    return (
      <div
        className={clsx(
          "rounded-full",
          hw,
          new Date(task.dueDate).getTime() > new Date().getTime()
            ? "bg-green-400"
            : "bg-red-400"
        )}
      />
    );
  }
  return <></>;
};

function initials(name: string) {
  return name
    .split(" ")
    .map((w) => w[0])
    .join("");
}

const Avatar = ({ task }: { task: TaskData }) => {
  // const journeyCtx = useJourney();
  const { slug } = useParams();
  return (
    <>
      {task.tasker && (
        <span
          className={clsx(
            "inline-flex h-6 w-7 items-center justify-center rounded-full",
            task.taskerType === "external" &&
              !slug &&
              "bg-primary-main text-white",
            task.taskerType === "external" &&
              !slug &&
              "bg-embedPrimary text-white",
            task.taskerType === "internal" &&
              !slug &&
              "border border-primary-main bg-white text-primary-main",
            task.taskerType === "internal" &&
              !slug &&
              "border border-embedPrimary bg-white text-embedPrimary"
          )}
        >
          <span className="text-xs font-medium uppercase leading-none">
            {initials(getIdentifier(task.tasker).identifier)}
          </span>
        </span>
      )}
    </>
  );
};
