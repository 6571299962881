import { ObjectID } from "bson";
import clsx from "clsx";
import { ObjectId } from "mongodb";
import { useEffect, useState } from "react";
import {
  Theme,
  useGetInstanceIdFromSlug,
  useGetThemeSettings,
  useLoginWithCode,
  useSendLoginCode,
} from "src/api/CustomerPortal/login";
import { EmbedButton } from "src/components/ui/Embed/EmbedButton";
import TextInput from "src/components/ui/Inputs/Text/TextInput";
import { Loading } from "src/components/ui/loading";
import { getRGBColor } from "src/utils/functions/embed";

export default function ExternalLogin() {
  const slug = window.location.hostname.split(".")[0];
  // const slug ="sero-demo"
  const { data: theme, isError: isThemeError } = useGetThemeSettings(
    String(slug)
  );
  const { data: instanceDetails, isError } = useGetInstanceIdFromSlug(
    String(slug)
  );

  if (isError || isThemeError) {
    window.location.href = String(process.env.REACT_APP_URL);
  } else if (!theme || !instanceDetails) {
    return <Loading className="mt-[30vh]" />;
  }

  return (
    <div className="h-screen bg-gray-50">
      {theme && (
        <div
          className={clsx(
            theme.fontType === "sans"
              ? "font-sans"
              : theme.fontType === "serif"
              ? "font-serif"
              : theme.fontType === "mono"
              ? "font-mono"
              : "font-sans"
          )}
        >
          <style>
            :root
            {`{${getRGBColor(theme.primaryColor, "primary")}}`}
          </style>
          {theme && instanceDetails && (
            <ExternalLoginForm
              theme={theme}
              instanceId={new ObjectID(instanceDetails.instanceId)}
            />
          )}
        </div>
      )}
    </div>
  );
}

function ExternalLoginForm({
  instanceId,
  theme,
}: {
  instanceId: ObjectId;
  theme: Theme;
}) {
  const slug = window.location.hostname.split(".")[0];
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [submittedForCode, setSubmittedForCode] = useState(false);
  const sendLoginCodeMutation = useSendLoginCode();
  const loginWithCodeMutation = useLoginWithCode();

  useEffect(() => {
    if (sendLoginCodeMutation.isSuccess) {
      setSubmittedForCode(true);
    }
  }, [sendLoginCodeMutation.isSuccess]);

  return (
    <div className="space-y-10 pt-32 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="flex justify-center">
        <img
          src={theme.logo.url}
          className="h-full max-h-[80px] max-w-[200px]"
        />
      </div>
      <div className="space-y-6">
        <div className="px-4 sm:px-10">
          <div className="mx-auto space-y-2 text-center">
            <div className="text-2xl font-medium">Log In</div>
            <div className="text-gray-500">Enter your details below</div>
          </div>
        </div>
      </div>
      <div className="bg-white px-4 py-10 shadow sm:rounded-lg sm:px-10">
        <div className="space-y-8">
          <div className="w-full space-y-6">
            <TextInput
              name="email"
              disabled={submittedForCode}
              label="Email"
              placeholder="richard@piedpiper.com"
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
              value={email}
              isEmbed={true}
            />
          </div>
          {submittedForCode && (
            <div className="w-full space-y-6">
              <TextInput
                id="code"
                name="code"
                label="Code"
                placeholder="000000"
                isEmbed={true}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
                value={code}
              />
            </div>
          )}
          <div>
            {!submittedForCode ? (
              <EmbedButton
                disabled={
                  email.trim().length === 0 || sendLoginCodeMutation.isLoading
                }
                type="submit"
                size="sm"
                text="Generate Login Code"
                width={"full"}
                ring={false}
                onClick={(e) => {
                  sendLoginCodeMutation.mutate({ email, instanceId });
                  e.preventDefault();
                }}
              />
            ) : (
              <EmbedButton
                disabled={code.trim().length !== 6}
                size="sm"
                type="submit"
                text="Login"
                width={"full"}
                onClick={(e) => {
                  e.preventDefault();
                  loginWithCodeMutation.mutate({
                    email: email.toLowerCase(),
                    code,
                    instanceId,
                    slug: String(slug),
                  });
                }}
                ring={false}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
