import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useRealmApp } from "src/store/RealmApp";
import { useAccessToken } from "../useAccessToken";

const sharedSpaceKeys = {
  sharedSpace: (sharedSpaceId: string) =>
    ["sharedSpaces", sharedSpaceId] as const,
  phases: (sharedSpaceId: string) =>
    [...sharedSpaceKeys.sharedSpace(sharedSpaceId), "phases"] as const,
  phasesForExternalUser: (slug: string) => ["phases", slug] as const,
};
export type PhaseType = "Sales Room" | "Customer Onboarding" | "Simple";

export type SharedSpacePhaseData = {
  _id: string;
  name: string;
  externalName?: string;
  type: PhaseType;
  createdAt: string;
  completionDate: string;
  journeyId: string;
  tasksCount: {
    overall: {
      toDo: number;
      inProgress: number;
      completed: number;
      upcoming: number;
    };
    user: {
      active: number;
      overdue: number;
    };
    internal: {
      active: number;
      name: string;
    };
    external: {
      active: number;
      overdue: number;
      name: string;
    };
  };
  completionPercent: number;
  notificationsCount: number;
  isActive: boolean;
};

export const useGetPhasesForSharedSpace = (sharedSpaceId: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    sharedSpaceKeys.phases(sharedSpaceId),
    async (): Promise<SharedSpacePhaseData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/phasesForSharedSpace`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            sharedSpaceId,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};

export const useGetPhasesForExternalUser = (slug: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    sharedSpaceKeys.phasesForExternalUser(slug),
    async (): Promise<SharedSpacePhaseData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/phasesForUser`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            slug,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};
