type Props = {
  heading: string;
};

export const PageHeading = ({ heading }: Props) => {
  return (
    <>
      {heading !== "" ? (
        <div className="mt-2 min-w-0 flex-1">
          <h2 className="text-2xl font-medium leading-7 tracking-tight text-gray-900 cursor-default">
            {heading}
          </h2>
        </div>
      ) : (
        <div className="mt-2 h-8 w-40 rounded bg-gray-100 animate-pulse" />
      )}
    </>
  );
};
