import {
  CaretSortIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
} from "@radix-ui/react-icons";
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import dayjs from "dayjs";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CompanyData, useGetCompany } from "src/api/Companies/companies";
import {
  OnboardingProjectData,
  useGetOnboardingProjects,
} from "src/api/Services/Projects/projects";
import { PhaseStatus } from "src/api/Services/SharedSpace/phases";
import { useGetTaskersForInstance } from "src/api/Services/Tasks/tasks";
import { useGetSpaces } from "src/api/Spaces/spaces";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "src/components/ui/command";
import { Loading } from "src/components/ui/loading";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/components/ui/tabs";
import { TextInput } from "src/components/ui/text-input";
import { getTimeDifference } from "src/utils/functions/dateTime";
import { fuzzyFilter } from "../Services/Projects/ProjectsTable";
import { SortToggle } from "../Services/Projects/columns";
import { getPhaseStatus } from "../Services/SharedSpace/Phases/Tabs/Overview";
// import ProjectsTable from "../Services/Projects/ProjectsTable";

type Props = {};

export default function Company({}: Props) {
  const { companyId, tab } = useParams();

  const navigate = useNavigate();
  const { data: company } = useGetCompany(String(companyId));
  return (
    <div className="flex h-full flex-col overflow-y-auto">
      <div className="flex h-12 shrink-0 items-center border-b border-gray-200/70 px-8">
        <div className="flex items-center space-x-2 text-sm">
          <Link
            className="text-gray-800 transition-all hover:text-gray-800"
            to="/companies"
          >
            Companies
          </Link>
          <ChevronRightIcon className="h-3 w-3 text-gray-500" />
          <div className="cursor-default text-gray-800">{company?.name}</div>
        </div>
      </div>
      <div className="flex flex-1">
        <div className="flex h-full flex-1 flex-col overflow-auto">
          <Tabs
            defaultValue="projects"
            value={tab}
            className="flex flex-1 flex-col overflow-auto pt-3"
            onValueChange={(value) => {
              navigate(`/companies/${companyId}/${value}`);
            }}
          >
            <TabsList className="shrink-0 border-gray-200/[.65] px-8 ">
              {/* <TabsTrigger
                className="font-normal text-gray-500/80"
                value="overview"
              >
                Overview
              </TabsTrigger> */}
              <TabsTrigger
                className="font-normal text-gray-500/80"
                value="projects"
              >
                Projects
              </TabsTrigger>
              {/* <TabsTrigger
                className="font-normal text-gray-500/80"
                value="tasks"
              >
                Tasks
              </TabsTrigger>
              <TabsTrigger
                className="font-normal text-gray-500/80"
                value="emails"
              >
                Emails
              </TabsTrigger>
              <TabsTrigger
                className="font-normal text-gray-500/80"
                value="notes"
              >
                Notes
              </TabsTrigger>
              <TabsTrigger
                className="font-normal text-gray-500/80"
                value="activity"
              >
                Activity
              </TabsTrigger> */}
            </TabsList>
            <div className="flex-1 overflow-auto">
              {/* <TabsContent value="overview" className="h-full">
                {!!company && <Overview company={company} />}
              </TabsContent> */}
              <TabsContent value="projects" className="h-full">
                <Projects />
              </TabsContent>
              {/* <TabsContent value="tasks" className="h-full">
                <div>Tasks</div>
              </TabsContent>
              <TabsContent value="emails" className="h-full">
                <div>Emails</div>
              </TabsContent>
              <TabsContent value="activity" className="h-full">
                <div>Activity</div>
              </TabsContent> */}
            </div>
          </Tabs>
          <div></div>
        </div>
      </div>

      {/* <div className="flex flex-1 overflow-auto">
        
      </div> */}
    </div>
  );
}

function Overview({ company }: { company: CompanyData }) {
  return (
    <div className="flex h-full">
      <div className="flex-1 px-8 pt-6">Overview</div>
      <div className="h-full w-[480px] space-y-6 overflow-auto border-l border-l-gray-200/70 px-8 pt-6">
        <div className="space-y-2.5">
          <div className="cursor-default text-base font-medium leading-none text-gray-600">
            Overview
          </div>
          <div className="space-y-1">
            <div className="flex min-h-[32px] items-center gap-6">
              <div className="w-32 cursor-default text-[13px] text-gray-400">
                Owner
              </div>
              {!!company && <CompanyOwnerCombobox company={company} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CompanyOwnerCombobox({ company }: { company: CompanyData }) {
  const [open, setOpen] = useState(false);
  const { data: instanceMembers } = useGetTaskersForInstance();
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <button
          className={clsx(
            "group -ml-1.5 flex min-h-[32px] w-full items-center justify-between space-x-2 rounded-md px-1.5 py-1 hover:bg-gray-100",
            open && "bg-gray-100"
          )}
        >
          {/* {instanceMembers?.find((m) => m._id === phase.ownerUser._id)?.name}  */}
          TODO: Company Owner
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-0 group-hover:opacity-40" />
        </button>
      </PopoverTrigger>
      <PopoverContent
        className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
        align="start"
      >
        <Command>
          <CommandInput placeholder="Search.." className="h-9" />
          <CommandEmpty>No options found.</CommandEmpty>
          <CommandGroup>
            {instanceMembers?.map((option) => (
              <CommandItem
                key={option._id}
                value={option.name}
                onSelect={() => {
                  // TODO - update company owner
                  setOpen(false);
                }}
                className="justify-between gap-2"
              >
                <span className="truncate">{option.name}</span>
                {/* {option._id === company.ownerUser._id && (
                  <CheckIcon className="h-4 w-4" />
                )} */}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function Projects() {
  const [search, setSearch] = useState("");
  const { companyId } = useParams();
  const { data: company } = useGetCompany(String(companyId));
  const filter = {
    status: ["Canceled", "In Progress", "Completed", "Paused"] as PhaseStatus[],
    customerIds: [String(companyId)],
  };
  const { data: projects } = useGetOnboardingProjects(filter);
  const { data: spaces } = useGetSpaces();
  return (
    <>
      <div className="flex h-16 items-center justify-between space-x-2 border-b border-gray-200/70 px-8">
        <div className="relative">
          <TextInput
            className="h-8 w-72 pl-8"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            placeholder="Search.."
            autoFocus
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-2.5">
            <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" />
          </div>
        </div>
        {/* <Dialog>
            <DialogTrigger asChild>
              <Button variant={"secondary"}>New Project</Button>
            </DialogTrigger>
            <DialogContent className="max-h-[60vh] max-w-3xl p-0">

            </DialogContent>
        </Dialog> */}
        {/* {!!spaces && !!company && (
          <NewProjectButton spaceId={spaces ?? []} step={0} customer={company} />
        )} */}
      </div>
      {!!projects && !!company ? (
        <>
          {projects.length > 0 ? (
            <ProjectsTable
              projects={projects}
              search={search}
              setSearch={setSearch}
            />
          ) : (
            <>
              <div className="mx-auto mt-[10vh] w-fit">
                <div className="flex">
                  <div className="h-20 w-20"></div>
                  <div className="h-20 w-[420px] bg-gradient-to-t from-gray-200 to-transparent px-[1px]">
                    <div className="h-full w-full bg-white" />
                  </div>
                  <div className="h-20 w-20"></div>
                </div>
                <div className="flex">
                  <div className="w-20 bg-gradient-to-l from-gray-200 to-white py-[1px]">
                    <div className="h-full w-full bg-white" />
                  </div>
                  <div className="w-[420px] border bg-gray-50/70 p-12">
                    <div className="text-xl font-medium">Projects</div>
                    <div className="pt-2.5 font-light text-gray-700">
                      {/* <div>
                        Projects with customers help you give them visibility
                        into projects where they are actively involved.
                      </div> */}
                      <div>
                        You currently have no projects with {company?.name}.
                        Create a project with them in one of yours spaces to get
                        started.
                      </div>
                    </div>
                    {/* <div className="pt-6">
                      <NewProjectButton spaces={spaces} />
                    </div> */}
                  </div>
                  <div className="w-20 bg-gradient-to-r from-gray-200 to-white py-[1px]">
                    <div className="h-full w-full bg-white" />
                  </div>
                </div>
                <div className="flex">
                  <div className="h-20 w-20"></div>
                  <div className="h-20 w-[420px] bg-gradient-to-b from-gray-200 to-white px-[1px]">
                    <div className="h-full w-full bg-white" />
                  </div>
                  <div className="h-20 w-20"></div>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <Loading className="mt-[20vh]" />
      )}
    </>
  );
}

function ProjectsTable({
  projects,
  search,
  setSearch,
}: {
  projects: OnboardingProjectData[];
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}) {
  const navigate = useNavigate();
  const [sorting, setSorting] = useState<SortingState>([]);
  console.log(projects);
  const table = useReactTable({
    data: projects ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      globalFilter: search,
    },
    globalFilterFn: fuzzyFilter,
    onSortingChange: setSorting,
    onGlobalFilterChange: setSearch,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <table
      style={{ borderCollapse: "separate", borderSpacing: 0 }}
      className="w-full"
    >
      <thead className="overflow-auto">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className="border-b">
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                scope="col"
                className={
                  "sticky top-0 z-10 h-11 whitespace-nowrap border-b border-r border-gray-200/70 bg-gray-50 px-6 text-left text-xs font-normal uppercase tracking-wide text-gray-400 first:left-0 first:z-20 first:pl-8 last:w-full last:border-r-0"
                }
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {!projects ? (
          <tr>
            {/* <td className="inset-0 flex items-center justify-center p-4 pl-8"> */}
            <div className="sticky left-0 z-10 p-4 pl-8 text-gray-400">
              Loading...
            </div>
            {/* </td> */}
          </tr>
        ) : (
          <>
            {projects.length === 0 ? (
              <tr>
                <div className="sticky left-0 z-10 whitespace-nowrap p-4 pl-8 text-gray-400">
                  No projects
                </div>
                {/* <td className="inset-0 flex items-center justify-center p-4">
                    </td> */}
              </tr>
            ) : (
              <>
                {table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    className="group relative rounded-lg hover:cursor-pointer hover:bg-gray-50"
                    onClick={() => {
                      navigate(`/projects/${row.original._id}/tasks`);
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className={
                          "whitespace-nowrap border-r border-b border-gray-100 px-6 py-3 text-sm text-gray-500 first:sticky first:left-0 first:z-10 first:bg-white first:pr-6 first:font-medium first:text-gray-900 last:border-r-0 first:group-hover:bg-gray-50 first:sm:pl-8"
                        }
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            )}
          </>
        )}
      </tbody>
    </table>
  );
}

const columns: ColumnDef<OnboardingProjectData>[] = [
  {
    id: "name",
    header: "Name",
    cell: ({ row }) => row.original.name,
    accessorFn: ({ name }) => name,
  },
  // {
  //   id: "space",
  //   header: "Space",
  //   cell: ({ row }) => row.original.space.name,
  //   accessorFn: ({ space }) => space.name,
  // },
  {
    id: "status",
    header: ({ column }) => {
      return (
        <div className="flex items-center space-x-2">
          <span>Status</span>
        </div>
      );
    },
    cell: ({ row }) => {
      const { status } = row.original;
      return (
        <div className="h-full">
          <span>{getPhaseStatus(status)}</span>
        </div>
      );
    },
  },
  {
    id: "onboardingProgress",
    header: ({ column }) => (
      <div className="flex items-center space-x-1">
        <span>Progress</span>
        <SortToggle column={column} />
      </div>
    ),
    accessorFn: ({ journey }) => {
      // const totalTasks =
      //   allTasksBreakdown?.external?.toDo.total +
      //   allTasksBreakdown?.external?.inProgress.total +
      //   allTasksBreakdown?.external?.canceled +
      //   allTasksBreakdown?.external?.completed.late +
      //   allTasksBreakdown?.external?.completed.onTime +
      //   allTasksBreakdown?.internal?.toDo.total +
      //   allTasksBreakdown?.internal?.inProgress.total +
      //   allTasksBreakdown?.internal?.canceled +
      //   allTasksBreakdown?.internal?.completed.late +
      //   allTasksBreakdown?.internal?.completed.onTime;
      // const completedTasks =
      //   allTasksBreakdown?.external?.completed.late +
      //   allTasksBreakdown?.external?.completed.onTime +
      //   allTasksBreakdown?.internal?.completed.late +
      //   allTasksBreakdown?.internal?.completed.onTime;
      const progress = Math.round(
        (journey.completedTasks / journey.totalTasks) * 100
      ).toFixed(0);
      return progress;
    },
    sortingFn: "alphanumeric",
    cell: ({ row }) => {
      const progress = Math.round(
        (row.original.journey.completedTasks /
          row.original.journey.totalTasks) *
          100
      );
      const hasTasks = row.original.journey.totalTasks > 0;
      return (
        <>
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-3">
              <div
                className={clsx(
                  "h-1.5 w-20 shrink-0 overflow-hidden rounded-sm",
                  row.original.journey.completedTasks === 0
                    ? "border-gray-300 bg-gray-200/70"
                    : "border-green-200 bg-green-200/70"
                )}
              >
                <div
                  style={{ width: hasTasks ? progress + "%" : "0%" }}
                  className="h-full rounded-sm bg-green-500"
                ></div>
              </div>
              <div className="w-6 text-sm font-medium text-gray-600">
                {(hasTasks ? progress.toFixed(0) : "0") + "%"}
              </div>
            </div>
            <div className="flex h-5 w-fit items-center justify-center rounded-md bg-gray-50 px-1.5">
              <div className="text-[11px] text-gray-400">{`${row.original.journey.completedTasks}/${row.original.journey.totalTasks}`}</div>
            </div>
          </div>
        </>
      );
    },
  },
  {
    id: "forecastedCompletionDate",
    header: ({ column }) => (
      <div className="flex items-center space-x-1">
        <div>
          <span>Status</span>{" "}
          {/* <span className="text-xs lowercase text-gray-400"> vs </span>
          <span>Actual</span> */}
        </div>
        <SortToggle column={column} />
      </div>
    ),
    cell: ({ row }) => {
      if (row.original.type === "Simple") {
        return <div className="text-gray-500">-</div>;
      }
      const forecastedCompletionDate = new Date(
        row.original.keyDates.forecastedCompletionDate
      );
      const plannedCompletionDate = new Date(
        row.original.keyDates.plannedCompletionDate
      );
      let completionDate;
      let completionDelta;
      if (row.original.keyDates.completionDate) {
        completionDate = new Date(row.original.keyDates.completionDate);
        completionDelta = getTimeDifference(
          completionDate,
          plannedCompletionDate
        );
      }

      const delta = getTimeDifference(
        forecastedCompletionDate,
        plannedCompletionDate
      );
      return (
        <>
          {!!completionDate && !!completionDelta ? (
            <div>
              <div
                className={clsx(
                  "text-sm",
                  delta.isDate2After ? "text-green-500" : "text-red-500"
                )}
              >{`Completed ${delta.absValue} ${delta.unit} ${
                delta.isDate2After ? "ahead of time" : "behind schedule"
              }`}</div>
            </div>
          ) : (
            <div className="space-y-1">
              <div
                className={clsx(
                  "text-sm",
                  delta.isDate2After ? "text-green-500" : "text-red-500"
                )}
              >{`${delta.absValue} ${delta.unit} ${
                delta.isDate2After ? "ahead" : "behind"
              }`}</div>
              {/* <div
                className={clsx(
                  "text-xs text-gray-500"
                  // delta.isDate2After ? "text-green-500" : "text-red-500"
                )}
              >
                <span>{dayjs(plannedCompletionDate).format("DD MMM")}</span>
                <span className="text-gray-400"> vs </span>
                <span>{dayjs(forecastedCompletionDate).format("DD MMM")}</span>
              </div> */}
            </div>
          )}
        </>
      );
    },

    accessorFn: (row) => {
      const forecastedCompletionDate1 = new Date(
        row.keyDates.forecastedCompletionDate
      );
      const plannedCompletionDate1 = new Date(
        row.keyDates.plannedCompletionDate
      );
      const delta = getTimeDifference(
        forecastedCompletionDate1,
        plannedCompletionDate1
      );
      // console.log(`${}`)
      return delta.isDate2After
        ? delta.absDifferenceInMs
        : -delta.absDifferenceInMs;
    },
    enableSorting: true,
    // sortingFn: "alphanumeric",
    sortingFn: (row1, row2, columnId) => {
      return Number(row1.getValue(columnId)) - Number(row2.getValue(columnId));
    },
  },
  {
    id: "startedDate",
    accessorFn: ({ keyDates }) => keyDates.startedDate,
    header: ({ column }) => (
      <div className="flex items-center space-x-1">
        <span>Start Date</span>
        <SortToggle column={column} />
      </div>
    ),
    cell: ({ row }) => {
      const startedDate = new Date(row.original.keyDates.startedDate);
      const timeAgo = getTimeDifference(startedDate, new Date());
      return (
        <div className="space-y-1">
          <div className="text-sm">
            {dayjs(startedDate).format("DD MMM, YYYY")}
          </div>
          {/* <div className="text-xs text-gray-400">{`${timeAgo.absValue} ${timeAgo.unit} ago`}</div> */}
        </div>
      );
    },
    // sortingFn: (rowA, rowB) => {
    //   const startedDateA = new Date(rowA.original.keyDates.startedDate);
    //   const startedDateB = new Date(rowB.original.keyDates.startedDate);
    //   return startedDateA.getTime() - startedDateB.getTime();
    // },
    sortingFn: "datetime",
    // enableSorting: true,
  },
  {
    id: "owner",
    header: () => {
      return <span>Owner</span>;
    },
    cell: ({ row }) => {
      return (
        <div className="h-full">
          <span>{row.original.ownerUser.name}</span>
        </div>
      );
    },
  },
];
