import {
  ChevronRightIcon,
  DocumentTextIcon,
  RocketLaunchIcon,
} from "@heroicons/react/24/outline";
import { HTMLAttributes, forwardRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetSpace } from "src/api/Spaces/spaces";
import { cn } from "src/utils/ui/ui";
import { SpaceNameAndSymbol } from "../spaces/spaces";

export default function Templates() {
  const navigate = useNavigate();
  const { spaceId } = useParams();
  const { data: space } = useGetSpace(String(spaceId));

  return (
    <div className="flex h-full flex-col overflow-y-auto">
      <div className="flex h-12 items-center border-b border-gray-200/70 px-8">
        <div className="flex items-center space-x-2 text-sm">
          {space && (
            <SpaceNameAndSymbol name={space.name} color={space.color} />
          )}
          <ChevronRightIcon className="h-3 w-3 text-gray-500" />
          <div className="cursor-default text-gray-800">{"Templates"}</div>
        </div>
      </div>
      <div className="px-8 pt-6">
        <div className="flex flex-wrap gap-4">
          <TemplatesCard
            onClick={() => {
              navigate("projects");
            }}
          >
            <div className="flex items-center space-x-1.5">
              <RocketLaunchIcon className="text-primary-500 h-[16px] w-[16px]" />
              <div className="text-lg font-medium leading-none">Projects</div>
            </div>
            <div className="pt-2 text-sm font-light text-gray-600">
              Create and view project templates.
            </div>
          </TemplatesCard>
          <TemplatesCard
            onClick={() => {
              navigate("forms");
            }}
          >
            <div className="flex items-center space-x-2">
              <DocumentTextIcon className="text-primary-500 h-[16px] w-[16px]" />
              <div className="text-lg font-medium leading-none">Forms</div>
            </div>
            <div className="pt-2 text-sm font-light text-gray-600">
              Create and view form templates.
            </div>
          </TemplatesCard>
        </div>
      </div>
    </div>

    //   <div className="flex h-16 items-center px-8">
    //     <PageHeading heading={"Templates"} />
    //   </div>
    //   <div className="px-8">
    //     <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
    //       <TemplatesCard
    //         onClick={() => {
    //           navigate("/templates/projects");
    //         }}
    //       >
    //         <div className="flex items-center space-x-1.5">
    //           <RocketLaunchIcon className="text-primary-500 h-[18px] w-[18px]" />
    //           <div className="text-lg font-medium">Projects</div>
    //         </div>
    //         <div className="pt-1 text-sm text-gray-500">
    //           Create and view project templates.
    //         </div>
    //       </TemplatesCard>
    //       <TemplatesCard
    //         onClick={() => {
    //           navigate("/templates/forms");
    //         }}
    //       >
    //         <div className="flex items-center space-x-2">
    //           <DocumentTextIcon className="text-primary-500 h-[18px] w-[18px]" />
    //           <div className="text-lg font-medium">Forms</div>
    //         </div>
    //         <div className="pt-1 text-sm text-gray-500">
    //           Create and view form templates.
    //         </div>
    //       </TemplatesCard>
    //     </div>
    //   </div>
    // </div>
  );
}

type CardProps = {} & HTMLAttributes<HTMLDivElement>;

const TemplatesCard = forwardRef<HTMLDivElement, CardProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={cn(
          "w-80 cursor-pointer rounded-md border p-5 py-4 shadow-sm hover:bg-gray-50",
          className
        )}
      >
        {children}
      </div>
    );
  }
);
