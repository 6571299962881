import { BookOpenIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { KnowledgeResourceData } from "src/api/Services/KnowledgeBase/knowledgeBase";
import { TaskData } from "src/api/Services/Tasks/tasks";
import { KnowledgeResourceModal } from "src/components/Services/KnowledgeBases/KnowledgeResource/KnowledgeResourceModal";
import InputHeading from "src/components/ui/Headings/InputHeading";

type Props = {
  task: TaskData;
};

export default function LinkedKRs({ task }: Props) {
  const { slug } = useParams();
  const [selectedKnowledgeResources, setSelectedKnowledgeResources] = useState<
    KnowledgeResourceData | undefined
  >(undefined);
  return (
    <>
      {task.knowledgeResources?.length && (
        <div className="space-y-1">
          <InputHeading heading="Knowledge Resources" />

          <div className="flex space-x-6">
            {task.knowledgeResources.map((krt) => (
              <div
                className={clsx(
                  "bg-white space-x-2 inline-flex cursor-pointer items-center rounded-md border border-gray-400 py-1.5 px-3 text-sm text-gray-500 hover:bg-gray-50 "
                  // slug
                  //   ? "border-embedPrimary text-embedPrimary"
                  //   : "border-primary-main text-primary-main"
                )}
                onClick={() => {
                  setSelectedKnowledgeResources(krt);
                }}
              >
                <BookOpenIcon className="h-4 w-4" />
                <span>{krt.name}</span>
              </div>
            ))}
          </div>

          {!!selectedKnowledgeResources && (
            <KnowledgeResourceModal
              isOpen={!!selectedKnowledgeResources}
              closeModal={() => setSelectedKnowledgeResources(undefined)}
              knowledgeResource={selectedKnowledgeResources}
              isEmbed={!!slug}
            />
          )}
        </div>
      )}
    </>
  );
}
