import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { ObjectID } from "bson";
import { buildFetcher, buildSetter } from "src/api";
import { useAccessToken } from "src/api/useAccessToken";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { useRealmApp } from "src/store/RealmApp";

export const apiKeyKeys = {
  view: (instanceId: string) =>
    ["instance", instanceId, "apiKey", "viewApiKey"] as const,
  has: (instanceId: string) =>
    ["instance", instanceId, "apiKey", "hasApiKey"] as const,
};

export const themeSettingsKeys = {
  logo: (instanceId: string) => ["instance", instanceId, "logo"] as const,
};

export const useGetLogo = (): {
  logo: { name: string; type: string; url: string } | undefined;
  isLoading: boolean;
} => {
  const app = useRealmApp();

  const meta = {
    functionName: "getLogoFile",
    parameters: {
      instanceId: app.currentUser.customData.instanceId,
    },
  };

  const { data, isLoading } = useQuery<any>(
    themeSettingsKeys.logo(app.currentUser.customData.instanceId),
    buildFetcher(app, meta)
  );

  return {
    logo: data,
    isLoading: isLoading,
  };
};

export const useUploadLogoForInstance = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (fileDetails: {
      file: File;
    }): Promise<{ message: string; id: string }> => {
      const formData = new FormData();
      formData.append("file", fileDetails.file);
      formData.append(
        "instanceId",
        app.currentUser?.customData?.instanceId?.$oid
      );
      const accessToken = await getValidAccessToken();
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/uploadFile/logoFile
        `,
        formData,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: (err) => {
      snackbarCtx.showSnackbar("Error uploading logo", "error");
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        themeSettingsKeys.logo(app.currentUser.customData.instanceId)
      );
    },
  });
};

export const useHasApiKey = (): {
  hasApiKey: any;
  isSuccess: boolean;
} => {
  const snackbarCtx = useSnackBar();
  const app = useRealmApp();

  const meta = {
    functionName: "hasApiKey",
    parameters: {
      instanceId: app.currentUser.customData.instanceId,
    },
  };

  const { isSuccess, isError, error, data } = useQuery<any>(
    apiKeyKeys.has(app.currentUser.customData.instanceId),
    buildFetcher(app, meta)
  );

  // console.log(data)

  // if (data) {
  //   console.log(data)
  // }

  if (isError) {
    if (error instanceof Error) {
      snackbarCtx.showSnackbar(error.message, "error");
    }
  }

  return {
    hasApiKey: data,
    isSuccess: isSuccess,
  };
};

type GeneratedApiKey = { status: number; message: string; apiKey: string };

export const useGenerateApiKey = () => {
  const app = useRealmApp();
  const functionName = "generateApiKey";
  const fieldName = "params";
  const queryClient = useQueryClient();
  const snackbarCtx = useSnackBar();
  const generateApiKeyMutation = useMutation(
    buildSetter(app, functionName, fieldName),
    {
      onMutate: async ({
        params,
      }: {
        params: {
          instanceId: ObjectID;
        };
      }) => {},
      onError: (data, variables, context) => {
        snackbarCtx.showSnackbar("Error generating API Key", "error");
      },
      onSettled: (data, error, variables) => {
        snackbarCtx.showSnackbar("Successfully created API Key", "success");
        queryClient.invalidateQueries(
          apiKeyKeys.has(app.currentUser.customData.instanceId)
        );
        queryClient.invalidateQueries(
          apiKeyKeys.view(app.currentUser.customData.instanceId)
        );
      },
    }
  );

  return generateApiKeyMutation;
};

export const useViewApiKey = (): {
  apiKey: { apiKey: string } | undefined;
} => {
  const snackbarCtx = useSnackBar();
  const app = useRealmApp();

  const meta = {
    functionName: "viewApiKey",
    parameters: {
      instanceId: app.currentUser.customData.instanceId,
    },
  };

  const { isError, error, data } = useQuery<{ apiKey: string }>(
    apiKeyKeys.view(app.currentUser.customData.instanceId),
    buildFetcher(app, meta)
  );

  if (isError) {
    if (error instanceof Error) {
      snackbarCtx.showSnackbar(error.message, "error");
    }
  }

  return {
    apiKey: data,
  };
};
