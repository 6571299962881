import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "src/components/ui/command";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "src/components/ui/tooltip";

import {
  AdjustmentsHorizontalIcon,
  DocumentTextIcon,
  FunnelIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

import { CloudArrowUpIcon, PhoneIcon } from "@heroicons/react/24/solid";
import { ChevronRightIcon, Cross2Icon } from "@radix-ui/react-icons";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ParentStatus,
  StatusData,
  StatusGroupData,
} from "src/api/General/status-groups";
import {
  GroupDetails,
  ReducedTaskData,
  TaskData,
  TaskGroup,
  // TaskerData,
  TasksFilter,
  useGetTaskersForInstance,
  useUpdateTask,
} from "src/api/Services/Tasks/tasks";
import { Button } from "src/components/ui/button";
import { Checkbox } from "src/components/ui/checkbox";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "src/components/ui/hover-card";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import { useStatusGroups } from "src/pages/status-groups/status-groups-context-provider";
import { useRealmApp } from "src/store/RealmApp";
import { getTimeDifference } from "src/utils/functions/dateTime";
// import { useNewTaskDialog } from "./NewTaskForInternalUser";
import { useNewTaskForInternalUser } from "./NewTaskForInternalUser";
import { StatusPill, StatusSelect, getStatusSymbol } from "./Task";
import TasksContextProvider, {
  useTasksContext,
} from "./tasks-context-provider";

const defaultOptions: Option[] = [
  { name: "My Tasks", id: "my-tasks" },
  { name: "All Tasks", id: "all-tasks" },
];

export type SortProperty = "createdAt" | "dueDate";

export type Sort = {
  property: SortProperty;
  order: "asc" | "desc";
};

const Tasks = () => {
  const app = useRealmApp();
  // const newTaskDialogCtx = useNewTaskDialog();
  const [view, setView] = useState(defaultOptions[0]);
  const [viewOptions, setViewOptions] = useState([...defaultOptions]);
  const [filter, setFilter] = useState<TasksFilter>({
    taskerType: "internal",
    taskerIds: [String(app.currentUser.customData._id)],
  });
  const [sort, setSort] = useState<Sort>({
    property: "dueDate",
    order: "asc",
  });
  const newTaskForInternalUserCtx = useNewTaskForInternalUser();
  useEffect(() => {
    if (view.id === "my-tasks") {
      setFilter({
        taskerType: "internal",
        taskerIds: [String(app.currentUser.customData._id)],
      });
    } else if (view.id === "all-tasks") {
      setFilter({
        taskerType: "internal",
      });
    }
  }, [view.id]);

  // useEffect(() => {
  //   if (!isFilterActive) {
  //     if (view.id === "my-tasks") {
  //       setFilter({
  //         taskerType: "internal",
  //         taskerIds: [String(app.currentUser.customData._id)],
  //       });
  //     } else if (view.id === "all-tasks") {
  //       setFilter({
  //         taskerType: "internal",
  //       });
  //     }
  //   }
  // }, [isFilterActive]);

  return (
    <TasksContextProvider>
      <div className="flex h-full flex-col overflow-y-auto">
        <div className="flex h-12 items-center border-b border-gray-200/70 px-8">
          <div className="cursor-default text-sm text-gray-800">Tasks</div>
        </div>
        <div className="flex h-16 items-center justify-between border-b border-gray-200/70 px-8">
          <div className="flex items-center space-x-2">
            <Filters view={view} setFilter={setFilter} filter={filter} />
            <Display setSort={setSort} sort={sort} />
            <ViewSelector view={view} setView={setView} options={viewOptions} />
            {/* <Button variant="secondary" onClick={() => {}} size="sm">
              <ArrowsUpDownIcon className="mr-1.5 h-3.5 w-4" />
              Sort
            </Button> */}
          </div>
          <div>
            <Button
              variant="secondary"
              onClick={() => {
                newTaskForInternalUserCtx.dispatch({
                  type: "open_update",
                  
                });
              }}
            >
              New Task
            </Button>
          </div>
        </div>
        <div className="flex flex-1 flex-col overflow-y-auto">
          <TasksView filter={filter} sort={sort} view={view} />
        </div>
      </div>
    </TasksContextProvider>
  );
};

function Display({
  setSort,
  sort,
}: {
  setSort: React.Dispatch<React.SetStateAction<Sort>>;
  sort: Sort;
}) {
  const [open, setOpen] = useState(false);
  const tasksCtx = useTasksContext();
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="secondary"
          // size="sm"
          className={clsx(true && " border-gray-300/70")}
        >
          <AdjustmentsHorizontalIcon className="mr-1.5 h-3.5 w-4" />
          Display
        </Button>
      </PopoverTrigger>
      <PopoverContent align="start" className="p-0">
        <div className="divide-y">
          <div className="space-y-3 p-4">
            <div className="flex items-center justify-between">
              <div className="text-sm">Group by</div>
              <Select
                value={tasksCtx.state.group}
                onValueChange={(value) => {
                  tasksCtx.dispatch({
                    type: "group_update",
                    group: value as TaskGroup,
                  });
                }}
              >
                <SelectTrigger className="h-7 w-32 px-2.5">
                  {tasksCtx.state.group.charAt(0).toUpperCase() +
                    tasksCtx.state.group.slice(1)}
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="status">Status</SelectItem>
                    <SelectItem value="project">Project</SelectItem>
                    <SelectItem value="tasker">Tasker</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="flex items-center justify-between">
              <div className="text-sm">Sort by</div>
              <Select
                value={sort.property}
                onValueChange={(value) => {
                  if (value === "lastCreatedAt") {
                    setSort({
                      property: "createdAt",
                      order: "desc",
                    });
                  } else if (value === "dueDate") {
                    setSort({
                      property: "dueDate",
                      order: "asc",
                    });
                  }
                }}
              >
                <SelectTrigger className="h-7 w-32 px-2.5">
                  {sort.property === "createdAt" ? "Last Created" : "Due Date"}
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="dueDate">Due Date</SelectItem>
                    <SelectItem value="lastCreatedAt">Last Created</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="space-y-3 p-4">
            <div className="text-sm">Properties</div>
            <div className="flex flex-wrap gap-2">
              <div
                className={clsx(
                  "flex h-6 w-fit cursor-pointer items-center rounded border px-2.5 text-xs transition-all",
                  tasksCtx.state.properties.timeLeft
                    ? "border-gray-200 text-gray-600 hover:bg-gray-100"
                    : "border-gray-100 text-gray-400 hover:border-gray-200 hover:bg-gray-50"
                )}
                onClick={() =>
                  tasksCtx.dispatch({
                    type: "properties_update",
                    properties: {
                      ...tasksCtx.state.properties,
                      timeLeft: !tasksCtx.state.properties.timeLeft,
                    },
                  })
                }
              >
                Duration to Due Date
              </div>
              <div
                className={clsx(
                  "flex h-6 w-fit cursor-pointer items-center rounded border px-2.5 text-xs transition-all",
                  tasksCtx.state.properties.isStatusExpanded
                    ? "border-gray-200 text-gray-600 hover:bg-gray-100"
                    : "border-gray-100 text-gray-400 hover:border-gray-200 hover:bg-gray-50"
                )}
                onClick={() =>
                  tasksCtx.dispatch({
                    type: "properties_update",
                    properties: {
                      ...tasksCtx.state.properties,
                      isStatusExpanded:
                        !tasksCtx.state.properties.isStatusExpanded,
                    },
                  })
                }
              >
                Expanded Status
              </div>
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}

type Logic = "AND" | "OR";

type FilterGroup = {
  logic: Logic;
  children: (FilterGroup | Filter)[];
};

type Comparator = {
  boolean: {
    eq: boolean;
  };
  string: {
    in?: string[];
    notIn?: string[];
    eq?: string;
  };
  number: {
    eq?: number;
    gt?: number;
    gte?: number;
    lt?: number;
    lte?: number;
    in?: number[];
  };
  date: {
    eq?: string;
    gt?: string;
    gte?: string;
    lt?: string;
    lte?: string;
  };
};

type Filter = {
  field: string;
  comparator: Partial<Comparator>;
};

const filterGroup: FilterGroup = {
  logic: "AND",
  children: [
    {
      field: "status",
      comparator: {
        string: {
          in: ["Stuck"],
        },
      },
    },
    {
      field: "taskerIds",
      comparator: {
        string: {
          in: ["abc", "def"],
        },
      },
    },
    {
      logic: "OR",
      children: [
        {
          field: "status",
          comparator: {
            string: {
              in: ["Stuck"],
            },
          },
        },
        {
          field: "taskerIds",
          comparator: {
            string: {
              in: ["abc", "def"],
            },
          },
        },
      ],
    },
  ],
};

function Filters({
  filter,
  setFilter,
  view,
}: {
  filter: TasksFilter;
  setFilter: React.Dispatch<React.SetStateAction<TasksFilter>>;
  view: Option;
}) {
  const [open, setOpen] = useState(false);
  const [taskerOpen, setTaskerOpen] = useState(false);
  const [isTaskerFilterActive, setIsTaskerFilterActive] = useState(
    !!filter.taskerIds?.length && view.id !== "my-tasks"
  );
  const [isStatusFilterActive, setIsStatusFilterActive] = useState(
    !!filter.statuses?.length
  );
  const [statusOpen, setStatusOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setIsTaskerFilterActive(!!filter.taskerIds?.length);
      setIsStatusFilterActive(!!filter.statuses?.length);
    }
  }, [open]);

  useEffect(() => {
    if (!taskerOpen && !filter.taskerIds?.length) {
      setIsTaskerFilterActive(false);
    }
  }, [taskerOpen]);

  useEffect(() => {
    if (!statusOpen && !filter.statuses?.length) {
      setIsStatusFilterActive(false);
    }
  }, [statusOpen]);

  const app = useRealmApp();
  const { data: taskers } = useGetTaskersForInstance();

  const statusGroupsCtx = useStatusGroups();

  const statuses = getTransformedCombinedStatuses(
    statusGroupsCtx.state.statusGroups
  );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="secondary"
          className={clsx(
            (!!filter.taskerIds?.length && view.id !== "my-tasks") ||
              !!filter.statuses?.length
              ? ""
              : "border-gray-300/70"
          )}
        >
          <FunnelIcon className="mr-1.5 h-3.5 w-3.5" />
          Filter
          {((!!filter.taskerIds?.length && view.id !== "my-tasks") ||
            !!filter.statuses?.length) && (
            <div className="ml-1.5 flex h-3.5 min-w-[12px] items-center justify-center rounded bg-primary px-0.5 text-[8px] text-white">
              {(!!filter.taskerIds?.length && view.id !== "my-tasks" ? 1 : 0) +
                (!!filter.statuses?.length ? 1 : 0)}
            </div>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent align="start" className="p-0">
        {/* <div className="text-sm font-medium leading-none">Filter</div> */}
        <div>
          {((isTaskerFilterActive && view.id !== "my-tasks") ||
            isStatusFilterActive) && (
            <div className="space-y-2 border-b p-4">
              {isTaskerFilterActive && view.id !== "my-tasks" && (
                <div className="flex h-7 w-fit items-center overflow-hidden rounded-md border border-gray-300 py-0 pl-1.5">
                  <div className="flex h-full cursor-default items-center space-x-1 border-r border-r-gray-300 pr-2">
                    <UserIcon className="h-3.5" />
                    <div className="text-sm">Tasker</div>
                  </div>
                  {/* <div className="text-sm text-gray-600">is</div> */}
                  <div className="h-full">
                    <Popover
                      modal={true}
                      open={taskerOpen}
                      onOpenChange={setTaskerOpen}
                    >
                      <PopoverTrigger className="h-full px-2 text-sm hover:bg-gray-50 focus:outline-none">
                        {!!filter.taskerIds && filter.taskerIds?.length > 0 ? (
                          <div className="truncate">
                            {filter.taskerIds.length > 1
                              ? "Multiple"
                              : taskers?.find(
                                  (t) =>
                                    !!filter.taskerIds &&
                                    t._id.toString() === filter.taskerIds[0]
                                )?.email}
                          </div>
                        ) : (
                          <div className="text-sm font-normal text-gray-300">
                            Select Tasker
                          </div>
                        )}
                      </PopoverTrigger>
                      <PopoverContent
                        className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
                        align="start"
                      >
                        <Command>
                          <CommandInput
                            placeholder="Search tasker..."
                            className="h-9"
                          />
                          <CommandEmpty>No tasker found.</CommandEmpty>
                          <CommandGroup>
                            {/* {filter.ownerUserIds?.length && (

                  )} */}
                            {taskers?.map((tasker) => (
                              <CommandItem
                                key={tasker.email}
                                value={tasker.name}
                                onSelect={() => {
                                  setFilter({
                                    ...filter,
                                    taskerIds: [
                                      ...(filter.taskerIds ?? []),
                                      String(tasker._id),
                                    ],
                                  });
                                  // localStorage.setItem(
                                  //   "project-filter",
                                  //   JSON.stringify({
                                  //     ...filter,
                                  //     ownerUserIds: [String(tasker._id)],
                                  //   })
                                  // );

                                  setTaskerOpen(false);
                                }}
                                className="gap-2"
                              >
                                <Checkbox
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  checked={filter.taskerIds?.includes(
                                    tasker._id.toString()
                                  )}
                                  onCheckedChange={(checked) => {
                                    if (checked) {
                                      setFilter({
                                        ...filter,
                                        taskerIds: [
                                          ...(filter.taskerIds ?? []),

                                          tasker._id.toString(),
                                        ],
                                      });
                                    } else {
                                      console.log(
                                        filter.taskerIds?.filter(
                                          (id) => id !== tasker._id.toString()
                                        )
                                      );
                                      setFilter({
                                        ...filter,
                                        taskerIds: filter.taskerIds?.filter(
                                          (id) => id !== tasker._id.toString()
                                        ),
                                      });
                                    }
                                  }}
                                />

                                <div className="flex items-center space-x-2">
                                  <span className="truncate">
                                    {tasker.name}
                                  </span>
                                </div>
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </div>
                  <button
                    onClick={() => {
                      setIsTaskerFilterActive(false);
                      setFilter({
                        ...filter,
                        taskerIds: [],
                      });
                    }}
                    className="flex h-full w-6 items-center justify-center border-l hover:bg-gray-50"
                  >
                    <Cross2Icon className="h-2.5" />
                  </button>
                </div>
              )}
              {isStatusFilterActive && (
                <div className="flex h-7 w-fit items-center overflow-hidden rounded-md border border-gray-300 py-0 pl-1.5">
                  <div className="flex h-full cursor-default items-center space-x-1.5 border-r border-r-gray-300 pr-2">
                    <div className="h-3 w-3 rounded border-[1.4px] border-gray-500" />
                    <div className="text-sm">Status</div>
                  </div>
                  {/* <div className="text-sm text-gray-600">is</div> */}
                  <div className="h-full">
                    <Popover
                      modal={true}
                      open={statusOpen}
                      onOpenChange={setStatusOpen}
                    >
                      <PopoverTrigger className="h-full px-2 text-sm hover:bg-gray-50 focus:outline-none">
                        {!!filter.statuses && filter.statuses?.length > 0 ? (
                          <div className="truncate">
                            {filter.statuses.length > 1
                              ? "Multiple"
                              : filter.statuses[0].customStatus}
                          </div>
                        ) : (
                          <div className="text-sm font-normal text-gray-300">
                            Select Status
                          </div>
                        )}
                      </PopoverTrigger>
                      <PopoverContent
                        className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
                        align="start"
                      >
                        <Command>
                          <CommandInput
                            placeholder="Search status..."
                            className="h-9"
                          />
                          <CommandEmpty>No status found.</CommandEmpty>
                          <CommandGroup>
                            {/* {filter.ownerUserIds?.length && (

                  )} */}
                            {statuses["Backlog"]?.map((customStatus) => (
                              <StatusCommandItem
                                key={customStatus.name}
                                parentStatus={"Backlog"}
                                customStatus={customStatus.name}
                                filter={filter}
                                setFilter={setFilter}
                                setStatusOpen={setStatusOpen}
                              />
                            ))}
                            {statuses["To-do"]?.map((customStatus) => (
                              <StatusCommandItem
                                key={customStatus.name}
                                parentStatus={"To-do"}
                                customStatus={customStatus.name}
                                filter={filter}
                                setFilter={setFilter}
                                setStatusOpen={setStatusOpen}
                              />
                            ))}
                            {statuses["In Progress"]?.map((customStatus) => (
                              <StatusCommandItem
                                key={customStatus.name}
                                parentStatus={"In Progress"}
                                customStatus={customStatus.name}
                                filter={filter}
                                setFilter={setFilter}
                                setStatusOpen={setStatusOpen}
                              />
                            ))}
                            {statuses["Stuck"]?.map((customStatus) => (
                              <StatusCommandItem
                                key={customStatus.name}
                                parentStatus={"Stuck"}
                                customStatus={customStatus.name}
                                filter={filter}
                                setFilter={setFilter}
                                setStatusOpen={setStatusOpen}
                              />
                            ))}
                            {statuses["Completed"]?.map((customStatus) => (
                              <StatusCommandItem
                                key={customStatus.name}
                                parentStatus={"Completed"}
                                customStatus={customStatus.name}
                                filter={filter}
                                setFilter={setFilter}
                                setStatusOpen={setStatusOpen}
                              />
                            ))}
                            {statuses["Canceled"]?.map((customStatus) => (
                              <StatusCommandItem
                                key={customStatus.name}
                                parentStatus={"Canceled"}
                                customStatus={customStatus.name}
                                filter={filter}
                                setFilter={setFilter}
                                setStatusOpen={setStatusOpen}
                              />
                            ))}
                          </CommandGroup>
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </div>
                  <button
                    onClick={() => {
                      setIsStatusFilterActive(false);
                      setFilter({
                        ...filter,
                        statuses: [],
                      });
                    }}
                    className="flex h-full w-6 items-center justify-center border-l hover:bg-gray-50"
                  >
                    <Cross2Icon className="h-2.5" />
                  </button>
                </div>
              )}
            </div>
          )}
          {(!isTaskerFilterActive || !isStatusFilterActive) && (
            <div className="space-y-3 p-4">
              <div className="text-sm leading-none">Add a filter</div>
              <div className="flex flex-wrap gap-2">
                {!isTaskerFilterActive && view.id !== "my-tasks" && (
                  <div
                    className="flex h-6 cursor-pointer items-center rounded border border-gray-300 px-2 text-xs text-gray-400 transition-all hover:border-gray-400 hover:bg-gray-50 hover:text-gray-600"
                    onClick={() => {
                      setIsTaskerFilterActive(true);
                      setTaskerOpen(true);
                    }}
                  >
                    Tasker
                  </div>
                )}
                {!isStatusFilterActive && (
                  <div
                    className="flex h-6 cursor-pointer items-center rounded border border-gray-300 px-2 text-xs text-gray-400 transition-all hover:border-gray-400 hover:bg-gray-50 hover:text-gray-600"
                    onClick={() => {
                      setIsStatusFilterActive(true);
                      setStatusOpen(true);
                    }}
                  >
                    Status
                  </div>
                )}
              </div>
            </div>
          )}
          {/* <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                size="sm"
                variant="secondary"
                className="border-dashed"
                disabled={isTaskerFilterActive && isStatusFilterActive}
              >
                Add a filter
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-40" align="start">
              <DropdownMenuGroup>
                {!isTaskerFilterActive && (
                  <DropdownMenuItem
                    className="py-1 px-1.5 text-sm"
                    onClick={() => {
                      setIsTaskerFilterActive(true);
                      setTaskerOpen(true);
                    }}
                  >
                    <UserIcon className="mr-2.5 h-3.5 w-3.5" />
                    Tasker
                  </DropdownMenuItem>
                )}
                {!isStatusFilterActive && (
                  <DropdownMenuItem
                    className="py-1 px-1.5 text-sm"
                    onClick={() => {
                      setIsStatusFilterActive(true);
                      setStatusOpen(true);
                    }}
                  >
                    <div className="mr-2.5 flex w-3.5 justify-center">
                      <div className="h-3 w-3 rounded border-[1.4px] border-gray-500" />
                    </div>
                    Status
                  </DropdownMenuItem>
                )}
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu> */}
        </div>
      </PopoverContent>
    </Popover>
  );
}

function getTransformedCombinedStatuses(
  statusGroups: StatusGroupData[] | undefined
): Record<ParentStatus, StatusData[]> {
  let statusGroupsMap: Record<ParentStatus, StatusData[]> = {
    Backlog: [],
    "To-do": [],
    "In Progress": [],
    Stuck: [],
    Completed: [],
    Canceled: [],
  };
  if (!statusGroups) {
    return statusGroupsMap;
  }
  for (let statusGroup of statusGroups) {
    const statuses = statusGroup.statuses;
    for (const status of statuses) {
      // check if the status is already added in the array
      if (
        !statusGroupsMap[status.parentStatus].some(
          (s) =>
            s.name === status.name && s.parentStatus === status.parentStatus
        )
      ) {
        statusGroupsMap[status.parentStatus].push(status);
      }
    }
  }
  return statusGroupsMap;
}

function StatusCommandItem({
  parentStatus,
  customStatus,
  filter,
  setFilter,
  setStatusOpen,
}: {
  parentStatus: ParentStatus;
  customStatus: string;
  filter: TasksFilter;
  setFilter: React.Dispatch<React.SetStateAction<TasksFilter>>;
  setStatusOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <CommandItem
      key={customStatus}
      onSelect={() => {
        setFilter({
          ...filter,
          statuses: [
            ...(filter.statuses ?? []),
            {
              status: parentStatus,
              customStatus: customStatus,
            },
          ],
        });
        setStatusOpen(false);
      }}
      className="gap-2"
    >
      <Checkbox
        onClick={(e) => {
          e.stopPropagation();
        }}
        checked={filter.statuses?.some(
          (status) =>
            status.customStatus === customStatus &&
            status.status === parentStatus
        )}
        onCheckedChange={(checked) => {
          if (checked) {
            setFilter({
              ...filter,
              statuses: [
                ...(filter.statuses ?? []),
                {
                  status: parentStatus,
                  customStatus: customStatus,
                },
              ],
            });
          } else {
            setFilter({
              ...filter,
              statuses: filter.statuses?.filter(
                (status) =>
                  !(
                    status.customStatus === customStatus &&
                    status.status === parentStatus
                  )
              ),
            });
          }
        }}
      />

      <div className="flex items-center space-x-2">
        {getStatusSymbol(parentStatus, false)}
        <span className="truncate">{customStatus}</span>
      </div>
    </CommandItem>
  );
}

export function TasksView({
  filter,
  sort,
  view,
}: {
  filter: TasksFilter;
  sort: Sort;
  view: Option;
}) {
  return null;
  // const tasksCtx = useTasksContext();
  // const { data: taskGroups } = useGetTasks(tasksCtx.state.group, sort, filter);
  // const numberOfTasks =
  //   taskGroups?.reduce((acc, tg) => acc + tg.tasks.length, 0) ?? 0;
  // const newTaskForInternalUserCtx = useNewTaskForInternalUser();
  // return (
  //   <>
  //     {!!taskGroups ? (
  //       <>
  //         {numberOfTasks > 0 ? (
  //           <>
  //             {taskGroups?.map((taskGroup) => (
  //               <TasksGroup
  //                 key={
  //                   taskGroup.groupDetails.group === "project"
  //                     ? taskGroup.groupDetails.project._id
  //                     : taskGroup.groupDetails.group === "status"
  //                     ? taskGroup.groupDetails.customStatus
  //                     : taskGroup.groupDetails.group === "tasker"
  //                     ? taskGroup.groupDetails.tasker._id ??
  //                       taskGroup.groupDetails.tasker.email
  //                     : Math.random()
  //                 }
  //                 group={taskGroup.groupDetails}
  //                 initialData={taskGroup.tasks}
  //                 sort={sort}
  //                 filter={filter}
  //               />
  //             ))}
  //           </>
  //         ) : (
  //           <div className="">
  //             <div className="mt-[15vh] flex items-center justify-center">
  //               <div className="flex w-[520px] max-w-md flex-col rounded-md border p-10 shadow-md">
  //                 {}
  //                 {view.id === "my-tasks" ? (
  //                   <>
  //                     {" "}
  //                     <div className="text-xl font-medium leading-none">
  //                       My Tasks
  //                     </div>
  //                     <div className="py-7">
  //                       <div className="text-left font-light leading-relaxed text-gray-500"></div>
  //                       <div className="text-left font-light leading-relaxed text-gray-500">
  //                         My Tasks is where you can see all of your tasks in one
  //                         view.
  //                         <div className="mt-3 max-w-[440px]">
  //                           Once a task is assigned to you, it will show up
  //                           here.
  //                         </div>

  //                       </div>
  //                     </div>
  //                   </>
  //                 ) : (
  //                   <>
  //                     <div className="text-xl font-medium leading-none">
  //                       All Tasks
  //                     </div>
  //                     <div className="py-7">
  //                       <div className="text-left font-light leading-relaxed text-gray-500"></div>
  //                       <div className="text-left font-light leading-relaxed text-gray-500">
  //                         All Tasks is where you can see all of your team's in
  //                         one view.
  //                         <div className="mt-3 max-w-[340px]">
  //                           Once a task is created, it will show up here.
  //                         </div>

  //                       </div>
  //                     </div>
  //                   </>
  //                 )}

  //                 <div className="flex space-x-2.5">
  //                   <Button
  //                     onClick={() => {
  //                       newTaskForInternalUserCtx.dispatch({
  //                         type: "open_update",
  //                       });
  //                     }}
  //                   >
  //                     New Task
  //                   </Button>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         )}
  //       </>
  //     ) : (
  //       <Loading className="mt-[20vh]" />
  //     )}
  //   </>
  // );
}

function TasksGroup({
  group,
  sort,
  filter,
  initialData,
}: {
  group: GroupDetails;
  sort: Sort;
  filter: TasksFilter;
  initialData: ReducedTaskData[];
}) {
  return null;
  // const { data: tasks } = useGetTasksForGroup(
  //   sort,
  //   {
  //     ...filter,
  //     ...(group.group === "project"
  //       ? { phaseIds: [group.project._id ?? "none"] }
  //       : group.group === "status"
  //       ? {
  //           statuses: [
  //             { status: group.status, customStatus: group.customStatus },
  //           ],
  //         }
  //       : group.group === "tasker"
  //       ? { taskerIds: [group.tasker._id ?? "none"] }
  //       : {}),
  //   },
  //   initialData
  // );
  // const navigate = useNavigate();
  // return (
  //   <>
  //     {!!tasks && (
  //       <TasksTable
  //         tasks={tasks}
  //         title={
  //           group.group === "project"
  //             ? group.project.name ?? "Unassigned"
  //             : group.group === "status"
  //             ? group.customStatus
  //             : group.tasker.name ?? group.tasker.email ?? "Unassigned"
  //         }
  //         onClick={
  //           group.group === "project" && group.project._id
  //             ? () => {
  //                 if (group.group === "project" && group.project._id) {
  //                   navigate(`/projects/${group.project._id}`);
  //                 }
  //               }
  //             : undefined
  //         }
  //       />
  //     )}
  //   </>
  // );
}

type Option = {
  name: string;
  id: "my-tasks" | "all-tasks" | "todays-tasks";
};

function ViewSelector({
  view,
  setView,
  options,
}: {
  view: Option;
  setView: (view: Option) => void;
  options: Option[];
}) {
  const [open, setOpen] = useState(false);
  return (
    <Select
      value={view.name}
      onValueChange={(value) => {
        setView(options.find((option) => option.name === value) ?? options[0]);
      }}
    >
      <SelectTrigger className="h-8 border-gray-300/70">
        <SelectValue />
      </SelectTrigger>
      <SelectContent className="w-fit min-w-0">
        <SelectGroup>
          {defaultOptions.map((option) => (
            <SelectItem className="pr-2" key={option.id} value={option.name}>
              {option.name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
    // <Popover open={open} onOpenChange={setOpen}>
    //   <PopoverTrigger asChild>
    //     <Button
    //       variant="secondary"
    //       role="combobox"
    //       aria-expanded={open}
    //       className="max-w-[200px] space-x-2 truncate px-2"
    //     >
    //       <Square3Stack3DIcon className="h-4 w-4 shrink-0" />
    //       <span>{view.name}</span>
    //       <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
    //     </Button>
    //   </PopoverTrigger>
    //   <PopoverContent
    //     className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
    //     align="start"
    //   >
    //     <Command>
    //       <CommandInput placeholder="Search view..." className="h-9" />
    //       <CommandEmpty>No view found.</CommandEmpty>
    //       <CommandGroup>
    //         {options.map((option) => (
    //           <CommandItem
    //             key={option.id}
    //             value={option.name}
    //             onSelect={() => {
    //               setView(option);
    //               setOpen(false);
    //             }}
    //             className="gap-2"
    //           >
    //             <div className="flex items-center space-x-2">
    //               {/* <div
    //                 className={"h-2 w-2 shrink-0 rounded-full bg-slate-700"}
    //               /> */}
    //               <span className="truncate">{option.name}</span>
    //             </div>
    //             <CheckIcon
    //               className={cn(
    //                 "ml-auto h-4 w-4 shrink-0",
    //                 view.id === option.id ? "opacity-100" : "opacity-0"
    //               )}
    //             />
    //           </CommandItem>
    //         ))}
    //       </CommandGroup>
    //     </Command>
    //   </PopoverContent>
    // </Popover>
  );
}

export default Tasks;

// function Filters({
//   filter,
//   setFilter,
// }: {
//   filter: TasksFilter;
//   setFilter: React.Dispatch<React.SetStateAction<TasksFilter>>;
// }) {
//   const app = useRealmApp();
//   const [open, setOpen] = useState(false);
//   const { data: taskers } = useGetTaskersForInstance(
//     app.currentUser?.customData?.instanceId
//   );
//   const [taskerId, setTaskerId] = useState<string | undefined>(undefined);

//   return (
//     <Popover open={open} onOpenChange={setOpen}>
//       <PopoverTrigger asChild>
//         <Button
//           variant="secondary"
//           role="combobox"
//           aria-expanded={open}
//           className="max-w-[200px] space-x-2 truncate px-2"
//         >
//           <UserIcon className="h-4 w-4 shrink-0" />
//           {taskerId ? (
//             <div className="truncate">
//               <TaskerName taskerId={taskerId} taskers={taskers} />
//             </div>
//           ) : (
//             <div className="text-gray-400">Select a tasker</div>
//           )}
//           <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
//         </Button>
//       </PopoverTrigger>
//       <PopoverContent
//         className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
//         align="start"
//       >
//         <Command>
//           <CommandInput placeholder="Search tasker..." className="h-9" />
//           <CommandEmpty>No tasker found.</CommandEmpty>
//           <CommandGroup>
//             {taskers
//               ?.filter((t) => t.userType === "fullUser")
//               .map((tasker) => (
//                 <CommandItem
//                   key={tasker.email}
//                   value={
//                     tasker.userType === "fullUser" ? tasker.name : tasker.email
//                   }
//                   onSelect={() => {
//                     if (tasker.userType === "fullUser") {
//                       setTaskerId(tasker._id.toString());
//                       setFilter({
//                         ...filter,
//                         taskerIds: [String(tasker._id)],
//                       });
//                     }
//                     // if (tasker.userType === "fullUser") {
//                     //   updateTaskMutation.mutate({
//                     //     update: {
//                     //       _id: task._id,
//                     //       taskerType: tasker.type,
//                     //       taskerId: tasker._id,
//                     //     },
//                     //     metadata: {
//                     //       journeyId: task?.journey?._id,
//                     //       phaseId: task?.phase?._id,
//                     //     },
//                     //   });
//                     // } else if (tasker.userType === "invitedUser") {
//                     //   updateTaskMutation.mutate({
//                     //     update: {
//                     //       _id: task._id,
//                     //       taskerType: tasker.type,
//                     //       taskerEmail: tasker.email,
//                     //     },
//                     //     metadata: {
//                     //       journeyId: task?.journey?._id,
//                     //       phaseId: task?.phase?._id,
//                     //     },
//                     //   });
//                     // }
//                     setOpen(false);
//                   }}
//                   className="gap-2"
//                 >
//                   <div className="flex items-center space-x-2">
//                     {/* <div
//                     className={"h-2 w-2 shrink-0 rounded-full bg-slate-700"}
//                   /> */}
//                     <span className="truncate">
//                       {tasker.userType === "fullUser"
//                         ? tasker.name
//                         : tasker.email}
//                     </span>
//                   </div>
//                   <CheckIcon
//                     className={cn(
//                       "ml-auto h-4 w-4 shrink-0",
//                       tasker.userType === "fullUser" &&
//                         tasker._id.toString() === taskerId
//                         ? "opacity-100"
//                         : "opacity-0"
//                     )}
//                   />
//                 </CommandItem>
//               ))}
//           </CommandGroup>
//         </Command>
//       </PopoverContent>
//     </Popover>
//   );
// }

// function TaskerName({
//   taskers,
//   taskerId,
// }: {
//   taskers: TaskerData[] | undefined;
//   taskerId: string | undefined;
// }) {
//   const tasker = taskers?.find(
//     (t) => t.userType === "fullUser" && t._id.toString() === taskerId
//   );
//   return <div>{tasker?.userType === "fullUser" ? tasker.name : ""}</div>;
// }

// function TaskTables({ tasks }: { tasks: ReducedTaskData[] }) {
//   const [selectedTasks, setSelectedTasks] = useState<ReducedTaskData[]>([]);
//   const app = useRealmApp();
//   // create arrays for each status by one filter
//   let toResolveTasks: ReducedTaskData[] = [];
//   let stuckTasks: ReducedTaskData[] = [];
//   let inProgressTasks: ReducedTaskData[] = [];
//   let toDoTasks: ReducedTaskData[] = [];
//   let completedTasks: ReducedTaskData[] = [];
//   let backlogTasks: ReducedTaskData[] = [];
//   let canceledTasks: ReducedTaskData[] = [];
//   // loop through tasks and push to respective arrays
//   tasks.forEach((task) => {
//     // if (
//     //   task.status === "Stuck" &&
//     //   task.blocker?.resolverUser._id === app.currentUser?.customData?._id
//     // ) {
//     //   toResolveTasks.push(task);
//     // } else
//     if (
//       task.status === "Stuck"
//       // && task.blocker?.resolverUser._id !== app.currentUser?.customData?._id
//     ) {
//       stuckTasks.push(task);
//     } else if (task.status === "In Progress") {
//       inProgressTasks.push(task);
//     } else if (task.status === "To-do") {
//       toDoTasks.push(task);
//     } else if (task.status === "Completed") {
//       completedTasks.push(task);
//     } else if (task.status === "Backlog") {
//       backlogTasks.push(task);
//     } else if (task.status === "Canceled") {
//       canceledTasks.push(task);
//     }
//   });

//   return (
//     <div className="">
//       {/* <TasksTable
//         tasks={toResolveTasks}
//         title={"To Resolve"}
//         selectedTasks={selectedTasks}
//         setSelectedTasks={setSelectedTasks}
//       /> */}
//       <TasksTable
//         tasks={stuckTasks}
//         title={"Stuck"}
//         // selectedTasks={selectedTasks}
//         // setSelectedTasks={setSelectedTasks}
//       />
//       <TasksTable
//         tasks={inProgressTasks}
//         title={"In Progress"}
//         // selectedTasks={selectedTasks}
//         // setSelectedTasks={setSelectedTasks}
//       />
//       <TasksTable
//         tasks={toDoTasks}
//         title={"To-do"}
//         // selectedTasks={selectedTasks}
//         // setSelectedTasks={setSelectedTasks}
//       />
//       <TasksTable
//         tasks={completedTasks}
//         title={"Completed"}
//         // selectedTasks={selectedTasks}
//         // setSelectedTasks={setSelectedTasks}
//       />
//       <TasksTable
//         tasks={backlogTasks}
//         title={"Backlog"}
//         // selectedTasks={selectedTasks}
//         // setSelectedTasks={setSelectedTasks}
//       />
//       <TasksTable
//         tasks={canceledTasks}
//         title={"Canceled"}
//         // selectedTasks={selectedTasks}
//         // setSelectedTasks={setSelectedTasks}
//       />
//     </div>
//   );
// }

// function AllTasksTables({
//   paginatedTasks,
//   filter,
// }: {
//   paginatedTasks: PaginatedAllTasksData;
//   filter: TasksFilter;
// }) {
//   const [selectedTasks, setSelectedTasks] = useState<ReducedTaskData[]>([]);
//   const [toDoPageNo, setToDoPageNo] = useState(0);
//   const { data: toDoTasks, isPreviousData: isPreviousToDoData } =
//     useGetTasksForStatus("To-do", toDoPageNo, filter, {
//       currentPageNo: 0,
//       tasks: paginatedTasks.tasks.filter((t) => t.status === "To-do"),
//       totalNumberOfPages: paginatedTasks.toDoNoOfPages,
//     });
//   const [inProgressPageNo, setInProgressPageNo] = useState(0);
//   const { data: inProgressTasks, isPreviousData: isPreviousInProgressData } =
//     useGetTasksForStatus("In Progress", inProgressPageNo, filter, {
//       currentPageNo: 0,
//       tasks: paginatedTasks.tasks.filter((t) => t.status === "In Progress"),
//       totalNumberOfPages: paginatedTasks.inProgressNoOfPages,
//     });
//   const [stuckPageNo, setStuckPageNo] = useState(0);
//   const { data: stuckTasks, isPreviousData: isPreviousStuckData } =
//     useGetTasksForStatus("Stuck", stuckPageNo, filter, {
//       currentPageNo: 0,
//       tasks: paginatedTasks.tasks.filter((t) => t.status === "Stuck"),
//       totalNumberOfPages: paginatedTasks.stuckNoOfPages,
//     });
//   const [backlogPageNo, setBacklogPageNo] = useState(0);
//   const { data: backlogTasks, isPreviousData: isPreviousBacklogData } =
//     useGetTasksForStatus("Backlog", backlogPageNo, filter, {
//       currentPageNo: 0,
//       tasks: paginatedTasks.tasks.filter((t) => t.status === "Backlog"),
//       totalNumberOfPages: paginatedTasks.backlogNoOfPages,
//     });
//   const [completedPageNo, setCompletedPageNo] = useState(0);
//   const { data: completedTasks, isPreviousData: isPreviousCompletedData } =
//     useGetTasksForStatus(
//       "Completed",
//       completedPageNo,
//       filter,
//       completedPageNo === 0
//         ? {
//             currentPageNo: 0,
//             tasks: paginatedTasks.tasks.filter((t) => t.status === "Completed"),
//             totalNumberOfPages: paginatedTasks.completedNoOfPages,
//           }
//         : undefined
//     );
//   const [canceledPageNo, setCanceledPageNo] = useState(0);
//   const { data: canceledTasks, isPreviousData: isPreviousCanceledData } =
//     useGetTasksForStatus("Canceled", canceledPageNo, filter, {
//       currentPageNo: 0,
//       tasks: paginatedTasks.tasks.filter((t) => t.status === "Canceled"),
//       totalNumberOfPages: paginatedTasks.canceledNoOfPages,
//     });

//   return (
//     <div className="space-y-4">
//       <TasksTable
//         currentPageNo={stuckPageNo}
//         totalPageNos={paginatedTasks.stuckNoOfPages}
//         setPageNo={setStuckPageNo}
//         tasks={stuckTasks?.tasks ?? []}
//         title={"Stuck"}
//         selectedTasks={selectedTasks}
//         setSelectedTasks={setSelectedTasks}
//         isPreviousData={isPreviousStuckData}
//       />
//       <TasksTable
//         currentPageNo={inProgressPageNo}
//         totalPageNos={paginatedTasks.inProgressNoOfPages}
//         setPageNo={setInProgressPageNo}
//         tasks={inProgressTasks?.tasks ?? []}
//         title={"In Progress"}
//         selectedTasks={selectedTasks}
//         setSelectedTasks={setSelectedTasks}
//         isPreviousData={isPreviousInProgressData}
//       />
//       <TasksTable
//         currentPageNo={toDoPageNo}
//         totalPageNos={paginatedTasks.toDoNoOfPages}
//         setPageNo={setToDoPageNo}
//         tasks={toDoTasks?.tasks ?? []}
//         title={"To-do"}
//         selectedTasks={selectedTasks}
//         setSelectedTasks={setSelectedTasks}
//         isPreviousData={isPreviousToDoData}
//       />
//       <TasksTable
//         currentPageNo={completedPageNo}
//         totalPageNos={paginatedTasks.completedNoOfPages}
//         setPageNo={setCompletedPageNo}
//         tasks={completedTasks?.tasks ?? []}
//         title={"Completed"}
//         selectedTasks={selectedTasks}
//         setSelectedTasks={setSelectedTasks}
//         isPreviousData={isPreviousCompletedData}
//       />
//       <TasksTable
//         currentPageNo={backlogPageNo}
//         totalPageNos={paginatedTasks.backlogNoOfPages}
//         setPageNo={setBacklogPageNo}
//         tasks={backlogTasks?.tasks ?? []}
//         title={"Backlog"}
//         selectedTasks={selectedTasks}
//         setSelectedTasks={setSelectedTasks}
//         isPreviousData={isPreviousBacklogData}
//       />
//       <TasksTable
//         currentPageNo={canceledPageNo}
//         totalPageNos={paginatedTasks.canceledNoOfPages}
//         setPageNo={setCanceledPageNo}
//         tasks={canceledTasks?.tasks ?? []}
//         title={"Canceled"}
//         selectedTasks={selectedTasks}
//         setSelectedTasks={setSelectedTasks}
//         isPreviousData={isPreviousCanceledData}
//       />
//     </div>
//   );
// }

export function TasksTable({
  tasks,
  title,
  onClick,
}: // selectedTasks,
// setSelectedTasks,
{
  tasks: ReducedTaskData[];
  title: string;
  onClick?: () => void;
  // selectedTasks: ReducedTaskData[];
  // setSelectedTasks: React.Dispatch<React.SetStateAction<ReducedTaskData[]>>;
}) {
  if (tasks.length === 0) return null;
  return (
    <div className="">
      <div
        className={clsx(
          "sticky top-0 z-10 flex h-[38px] items-center justify-between border-b border-gray-100 bg-gray-50 px-8",
          onClick ? "cursor-pointer hover:bg-gray-100" : ""
        )}
        onClick={onClick}
      >
        <div className="flex items-center space-x-2">
          {/* {getStatusSymbol(title)} */}
          <div className="text-sm text-gray-600 select-none">{title}</div>

          {/* {totalPageNos !== 1 && (
            <div className="flex items-center">
              <ChevronLeftIcon
                className={clsx(
                  "h-4 w-4 text-gray-400",
                  currentPageNo === 0
                    ? "cursor-not-allowed opacity-50"
                    : "cursor-pointer hover:text-primary"
                )}
                onClick={() => {
                  if (currentPageNo > 0) setPageNo((prev) => prev - 1);
                }}
              />
              <div className="cursor-default text-sm text-gray-400">{`${
                currentPageNo + 1
              } / ${totalPageNos}`}</div>

              <ChevronRightIcon
                className={clsx(
                  "h-4 w-4 text-gray-400",
                  currentPageNo + 1 === totalPageNos
                    ? "cursor-not-allowed opacity-50"
                    : "cursor-pointer hover:text-primary"
                )}
                onClick={() => {
                  if (currentPageNo + 1 < totalPageNos)
                    setPageNo((prev) => prev + 1);
                }}
              />
            </div>
          )} */}
        </div>
        {/* Add some status specific stuff like duration sums*/}
      </div>
      <div className="divide-y divide-gray-100 border-b border-gray-100">
        {tasks.map((task) => (
          <TaskWithSubtasks
            key={task._id?.toString() ?? Math.random()}
            task={task}
            // selectedTasks={selectedTasks}
            // setSelectedTasks={setSelectedTasks}
          />
        ))}
      </div>
    </div>
  );
}

function TaskWithSubtasks({
  task,
}: // selectedTasks,
// setSelectedTasks,
{
  task: ReducedTaskData;
  // selectedTasks: ReducedTaskData[];
  // setSelectedTasks: React.Dispatch<React.SetStateAction<ReducedTaskData[]>>;
}) {
  return (
    <Task
      task={task}
      // selectedTasks={selectedTasks}
      // setSelectedTasks={setSelectedTasks}
    />
  );
}

function Task({
  task,
}: // selectedTasks,
// setSelectedTasks,
{
  task: ReducedTaskData;
  // selectedTasks: ReducedTaskData[];
  // setSelectedTasks: React.Dispatch<React.SetStateAction<ReducedTaskData[]>>;
}) {
  const navigate = useNavigate();
  const updateTaskMutation = useUpdateTask();
  const location = useLocation();
  const tasksCtx = useTasksContext();
  return (
    <div
      className={clsx(
        "flex h-10 max-w-full cursor-pointer items-center gap-4 px-8 hover:bg-gray-50/70"
      )}
      onClick={() => {
        if (task.dueDate && task._id) {
          const searchParams = new URLSearchParams(location.search);
          searchParams.set("taskId", task._id);
          navigate(
            {
              pathname: location.pathname,
              search: searchParams.toString(),
            },
            {
              replace: true,
            }
          );
        }
      }}
    >
      <div className="flex min-w-0 flex-1 items-center">
        <div className="pr-2.5">
          <StatusSelect
            isSymbol={!tasksCtx.state.properties.isStatusExpanded}
            isTag={tasksCtx.state.properties.isStatusExpanded}
            task={task}
            disabledStatuses={
              task.action === "form" || task.action === "fileUpload"
                ? ["Completed"]
                : []
            }
          />
        </div>

        <TaskIcon task={task} />

        {!!task.subtasks && task.subtasks.length > 0 && (
          <HoverCard openDelay={100} closeDelay={0}>
            <HoverCardTrigger>
              <div className="inline-flex h-[26px] items-center space-x-1.5 rounded-full pr-2">
                <ProgressCircle
                  totalTasks={task.subtasks.length}
                  completedTasks={
                    task.subtasks.filter(
                      (t) => t.status === "Completed" || t.status === "Canceled"
                    ).length
                  }
                />
                <div className="font- flex items-center">
                  <span className="text-xs leading-3 text-gray-400">
                    {
                      task.subtasks.filter(
                        (t) =>
                          t.status === "Completed" || t.status === "Canceled"
                      ).length
                    }
                  </span>
                  <span className="px-0.5 text-xs leading-3 text-gray-400">
                    /
                  </span>
                  <span className="text-xs leading-3 text-gray-400">
                    {task.subtasks.length}
                  </span>
                </div>
              </div>
            </HoverCardTrigger>
            <HoverCardContent
              className="min-w-[240px] max-w-[500px] divide-x p-0"
              side="bottom"
              align="start"
              sideOffset={0}
            >
              <div className="pb-2 pt-2.5">
                <div className="space-y-1">
                  <div className="px-3 text-xs text-gray-400">Subtasks</div>
                  <div>
                    {task.subtasks.map((t) => (
                      <div
                        key={t._id}
                        className="flex items-start space-x-1.5 py-1 px-3 text-sm hover:bg-gray-100"
                        onClick={(e) => {
                          if (t.dueDate && t._id) {
                            const searchParams = new URLSearchParams(
                              location.search
                            );
                            searchParams.set("taskId", t._id);
                            navigate(
                              {
                                pathname: location.pathname,
                                search: searchParams.toString(),
                              },
                              {
                                replace: true,
                              }
                            );
                          }
                          e.stopPropagation();
                        }}
                      >
                        <div className="shrink-0 pt-1">
                          <StatusPill status={t.status} />
                        </div>
                        <div>{t.title}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </HoverCardContent>
          </HoverCard>
        )}
        <div className="truncate text-sm font-[450] text-gray-800">
          {task.title}
        </div>
        {task.parentTask && (
          <div className="flex min-w-0 items-center space-x-1.5 pl-1.5">
            <ChevronRightIcon className="h-3 w-3 text-gray-400" />
            <HoverCard openDelay={100} closeDelay={0}>
              <HoverCardTrigger asChild>
                <div className="min-w-0 truncate text-sm text-gray-400">
                  {task.parentTask.title}
                </div>
              </HoverCardTrigger>
              <HoverCardContent
                className="w-60 divide-x p-0"
                side="right"
                align="start"
                sideOffset={0}
              >
                <div className="p-3">
                  <div className="space-y-1">
                    <div className="text-xs text-gray-400">Parent Task</div>
                    <div className="text-sm">{task.parentTask.title}</div>
                  </div>
                </div>
                {/* <div className="p-3">
                    {task.parentTask.status}
                  </div> */}
              </HoverCardContent>
            </HoverCard>
          </div>
        )}
      </div>
      <div className="flex items-center space-x-3">
        {task.phase && tasksCtx.state.group !== "project" && (
          <div
            className="truncate rounded-md border border-gray-200 px-2 py-1 text-xs text-gray-400 transition-colors hover:border-gray-300 hover:bg-white hover:text-gray-500"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/projects/${task.phase?._id.toString()}/tasks`);
            }}
          >
            {task.journey?.name}
          </div>
        )}
        {task.dueDate && tasksCtx.state.properties.timeLeft && (
          <TimeLeft
            dueDate={task.dueDate}
            completionDate={task.completionDate}
          />
        )}

        <span
          className={clsx(
            "w-14 text-right text-sm",
            dayjs(task.dueDate).isBefore(dayjs(), "day") &&
              task.status !== "Completed" &&
              task.status !== "Canceled"
              ? "text-red-500"
              : "text-gray-500"
          )}
        >
          {task.dueDate ? dayjs(task.dueDate).format("MMM DD") : ""}
        </span>

        {task.tasker.userType === "fullUser" ||
        task.tasker.userType === "invitedUser" ? (
          <div className="w-24 text-xs">
            <div
              className={clsx(
                "inline-flex h-[26px] max-w-full items-center rounded-full border border-gray-200 px-2 text-gray-400",
                task.taskerType === "external"
                  ? "bg-gray-100 text-gray-600"
                  : ""
              )}
            >
              <span className="min-w-0 truncate">
                {task.tasker.userType === "fullUser"
                  ? task.tasker.name
                  : task.tasker.email}
              </span>
            </div>
          </div>
        ) : (
          <div className="w-24">
            <div className="inline-flex h-[26px] w-fit px-2 items-center justify-center truncate rounded-full border border-red-300 text-xs text-red-400">
              Unassigned
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function TaskIcon({ task }: { task: ReducedTaskData }) {
  switch (task.action) {
    case "form":
      return (
        <div className="inline-flex items-center pr-2">
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger asChild>
                <DocumentTextIcon
                  tabIndex={-1}
                  className="h-5 w-5 text-gray-400"
                />
              </TooltipTrigger>
              <TooltipContent>
                <p>Form task</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      );
    case "fileUpload":
      return (
        <div className="inline-flex items-center pr-2">
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger asChild>
                <CloudArrowUpIcon className="h-5 w-5 text-gray-400" />
              </TooltipTrigger>
              <TooltipContent>
                <p>File upload task</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      );
    case "meeting":
      return (
        <div className="inline-flex items-center pr-2">
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger asChild>
                <PhoneIcon className="h-4 w-4 text-gray-400" />
              </TooltipTrigger>
              <TooltipContent>
                <p>Meeting task</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      );
    default:
      return null;
  }
}

function getMax2Initials(name: string) {
  const initials = name
    .split(" ")
    .map((n) => n[0])
    .join("");
  // return first and last if more than 2
  if (initials.length > 2) {
    return initials[0] + initials[initials.length - 1];
  }
  return initials;
}

export const TimeLeft = ({
  dueDate,
  completionDate,
}: {
  dueDate: string;
  completionDate: string | undefined;
}) => {
  if (completionDate) {
    const { absValue, unit } = getTimeDifference(
      new Date(completionDate),
      new Date(dueDate)
    );
    if (completionDate < dueDate) {
      return (
        <div className="truncate rounded-full border border-gray-300 bg-white px-2 py-1 text-xs text-gray-400">
          {`Completed ${absValue} ${unit} early`}
        </div>
      );
    } else {
      return (
        <div className="truncate rounded-full border border-gray-300 bg-white px-2 py-1 text-xs text-gray-400">
          {`Completed ${absValue} ${unit} late`}
        </div>
      );
    }
  }
  const { absValue, isDate2After, unit } = getTimeDifference(
    new Date(),
    new Date(dueDate)
  );
  if (isDate2After) {
    return (
      <div
        key={"timeLeft"}
        className={clsx(
          "truncate rounded-full border border-gray-300  bg-white px-2 py-1 text-xs text-gray-400"
        )}
      >
        {`Due in ${absValue} ${unit}`}
      </div>
    );
  } else {
    return (
      <div
        key={"overdue"}
        className="truncate rounded-full border border-red-400 bg-white px-2 py-1 text-xs text-red-500"
      >
        {`${absValue} ${unit} overdue`}
      </div>
    );
  }
};

function TaskEstimate({ estimate }: { estimate: TaskData["estimate"] }) {
  return (
    <div className="text-sm">
      <span>
        {estimate.unit === "hour" ? estimate.value * 60 : estimate.value}
      </span>
      <span className="text-xs text-gray-500"> min</span>
    </div>
  );
}

const ProgressCircle = ({
  totalTasks,
  completedTasks,
}: {
  totalTasks: number;
  completedTasks: number;
}) => {
  const radius = 7;
  const circumference = 2 * Math.PI * radius;
  const progress = (completedTasks / totalTasks) * circumference;

  return (
    <div className="flex items-center justify-center">
      <svg
        width="16.5"
        height="16.5"
        viewBox="0 0 16.5 16.5"
        className="-rotate-90 transform"
      >
        <circle
          cx="8.25"
          cy="8.25"
          r={radius}
          fill="none"
          stroke="#e5e7eb"
          strokeWidth="2.5"
        />
        <circle
          cx="8.25"
          cy="8.25"
          r={radius}
          fill="none"
          stroke="#966fff"
          strokeWidth="2.5"
          strokeDasharray={circumference}
          strokeDashoffset={circumference - progress}
        />
      </svg>
    </div>
  );
};

// function DisplayOptions() {
//   // const displayOptions = getLocalStorageDisplaySettings();
//   return (
//     <Popover className="relative text-sm">
//       {({ open }) => (
//         <>
//           <Popover.Button
//             className={
//               "flex items-center space-x-2 rounded-md border px-3 py-1 text-gray-600"
//             }
//           >
//             <span>Display</span>
//             <ChevronDownIcon className={"h-3 w-3"} aria-hidden="true" />
//           </Popover.Button>
//           <Transition.Child
//             as={Fragment}
//             enter="ease-out duration-300"
//             enterFrom="opacity-0"
//             enterTo="opacity-100"
//             leave="ease-in duration-200"
//             leaveFrom="opacity-100"
//             leaveTo="opacity-0"
//           >
//             <div className="fixed inset-0 z-10 bg-black bg-opacity-25" />
//           </Transition.Child>
//           <Transition
//             as={Fragment}
//             enter="transition ease-out duration-200"
//             enterFrom="opacity-0 translate-y-1"
//             enterTo="opacity-100 translate-y-0"
//             leave="transition ease-in duration-150"
//             leaveFrom="opacity-100 translate-y-0"
//             leaveTo="opacity-0 translate-y-1"
//           >
//             <Popover.Panel className="absolute right-0 z-10 mt-3 w-72">
//               <div className="divide-y rounded-lg bg-white px-4 py-4 shadow-md">
//                 <div className="space-y-3">
//                   <div className="text-md font-semibold">Subtasks</div>
//                   <div className="flex items-center justify-between">
//                     <div>Visible</div>
//                     <VariantToggle
//                       size="sm"
//                       enabled={true}
//                       setEnabled={() => {}}
//                       focus={false}
//                     />
//                   </div>
//                   <div className="flex items-center justify-between">
//                     <div>View</div>
//                     <div className="flex items-center space-x-2">
//                       <Pill text="Indented" onClick={() => {}} active={true} />
//                       <Pill
//                         text="Collapsible"
//                         onClick={() => {}}
//                         active={false}
//                       />
//                       {/* <div className="rounded border px-2 py-1 text-xs">
//                     Collapsible
//                   </div> */}
//                     </div>
//                   </div>
//                 </div>
//                 <div className="mt-3 pt-3">
//                   <div className="space-y-3">
//                     <div className="text-md font-semibold">All Tasks</div>
//                     <div className="flex gap-2">
//                       {elements.map((element) => (
//                         <Pill
//                           text={element.text}
//                           onClick={element.onClick}
//                           active={false}
//                         />
//                       ))}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </Popover.Panel>
//           </Transition>
//         </>
//       )}
//     </Popover>)
// }

const elements: { text: string; onClick: () => void }[] = [
  { text: "Due Date", onClick: () => {} },
  { text: "Estimates", onClick: () => {} },
  { text: "Time Left", onClick: () => {} },
];

function Pill({
  text,
  onClick,
  disabled = false,
  active,
}: {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  active: boolean;
}) {
  return (
    <div
      className={clsx(
        "cursor-default rounded border px-2 py-1 text-xs text-gray-600",
        disabled
          ? "bg-gray-200"
          : active
          ? "border-primary-400 text-primary-main"
          : "hover:border-primary-400  cursor-pointer hover:text-primary-main"
      )}
    >
      {text}
    </div>
  );
}
