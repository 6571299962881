import { Stack, Typography } from "@mui/material";
import Logo from "./Logo";

type Props = {
  heading: string;
  subHeading?: string;
  instanceName?: string | null;
};

const Header = ({ heading, subHeading, instanceName }: Props) => {
  return (
    <Stack sx={{ mb: 5 }} spacing={2}>
      <Logo height={90} />
      <Stack sx={{ pt: 3 }}>
        {instanceName ? (
          <Stack direction="row">
            <Typography variant="h4" align="center" gutterBottom>
              {"To join\xa0"}
            </Typography>
            <Typography
              variant="h4"
              align="center"
              gutterBottom
              color="primary"
            >
              {instanceName}
            </Typography>
            <Typography
              variant="h4"
              align="center"
              gutterBottom
            >
              {", please sign up first!"}
            </Typography>
          </Stack>
        ) : (
          <Typography variant="h4" align="center" gutterBottom>
            {heading}
          </Typography>
        )}
        {subHeading && (
          <Typography align="center" sx={{ color: "text.secondary" }}>
            {subHeading}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default Header;
