import { ChevronRightIcon, MagnifyingGlassIcon } from "@radix-ui/react-icons";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  FormTemplateData,
  useCreateFormTemplate,
  useGetFormTemplates,
} from "src/api/Forms/forms";
import { useGetSpace } from "src/api/Spaces/spaces";
import InputHeading from "src/components/ui/Headings/InputHeading";
import TextArea from "src/components/ui/Inputs/Text/TextArea";
import { Modal } from "src/components/ui/Modals/Modal";
import ModalHeading from "src/components/ui/Modals/ModalHeading";
import { Button } from "src/components/ui/button";
import { Loading } from "src/components/ui/loading";
import { TextInput } from "src/components/ui/text-input";
import { getTimeDifference } from "src/utils/functions/dateTime";
import { fuzzyFilter } from "../Services/Projects/ProjectsTable";
import { SpaceNameAndSymbol } from "../spaces/spaces";
var ObjectID = require("bson-objectid");

type Props = {};

export default function FormTemplates({}: Props) {
  const { spaceId } = useParams();
  const { data: space } = useGetSpace(String(spaceId));
  const [search, setSearch] = useState("");
  const { data: formTemplates } = useGetFormTemplates();
  const filteredFormTemplates =
    formTemplates?.filter((f) => f.spaceId === spaceId) ?? [];
  return (
    <div className="flex h-full flex-col overflow-y-auto">
      <div className="flex h-12 shrink-0 items-center border-b border-gray-200/70 px-8">
        <div className="flex items-center space-x-2 text-sm">
          {space && (
            <SpaceNameAndSymbol name={space.name} color={space.color} />
          )}
          <ChevronRightIcon className="h-3 w-3 text-gray-500" />
          <Link to={`/spaces/${spaceId}/templates`}>
            <div className="text-gray-800">{"Templates"}</div>
          </Link>
          <ChevronRightIcon className="h-3 w-3 text-gray-500" />
          <div className="cursor-default text-gray-800">{"Forms"}</div>
        </div>
      </div>
      <div className="flex h-16 shrink-0 items-center justify-between border-b border-gray-200/70 px-8">
        <div className="relative">
          <TextInput
            className="h-8 w-72 pl-8"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search.."
            autoFocus
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-2.5">
            <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" />
          </div>
        </div>
        {/* <div></div> */}
        <NewFormTemplateButton />
      </div>
      <div className="flex-1">
        {!!formTemplates ? (
          <>
            {filteredFormTemplates.length > 0 ? (
              <FormTemplatesTable
                formTemplates={filteredFormTemplates}
                search={search}
                setSearch={setSearch}
              />
            ) : (
              // <></>
              <div className="mx-auto mt-[10vh] w-fit">
                <div className="flex">
                  <div className="h-20 w-20"></div>
                  <div className="h-20 w-[420px] bg-gradient-to-t from-gray-200 to-transparent px-[1px]">
                    <div className="h-full w-full bg-white" />
                  </div>
                  <div className="h-20 w-20"></div>
                </div>
                <div className="flex">
                  <div className="w-20 bg-gradient-to-l from-gray-200 to-white py-[1px]">
                    <div className="h-full w-full bg-white" />
                  </div>
                  <div className="w-[420px] border bg-gray-50/70 p-12">
                    <div className="text-xl font-medium">Form Templates</div>
                    <div className="pt-2.5 font-light text-gray-700">
                      Templatize forms that you often use in your projects.
                    </div>
                    <div className="pt-6">
                      <NewFormTemplateButton />
                    </div>
                  </div>
                  <div className="w-20 bg-gradient-to-r from-gray-200 to-white py-[1px]">
                    <div className="h-full w-full bg-white" />
                  </div>
                </div>
                <div className="flex">
                  <div className="h-20 w-20"></div>
                  <div className="h-20 w-[420px] bg-gradient-to-b from-gray-200 to-white px-[1px]">
                    <div className="h-full w-full bg-white" />
                  </div>
                  <div className="h-20 w-20"></div>
                </div>
              </div>
            )}
          </>
        ) : (
          <Loading className="mt-[20vh]" />
        )}
      </div>
    </div>
    // <div className="px-8 py-6">
    //   <div className="flex items-start justify-between">
    //     <div>
    //       <Breadcrumbs
    //         breadcrumbs={[
    //           {
    //             title: "Form Templates",
    //             route: "/templates/forms",
    //           },
    //         ]}
    //       />
    //       <PageHeading heading="Form Templates" />
    //     </div>
    //     <NewFormTemplateButton />
    //   </div>
    //   <div className="pt-10">

    //   </div>
    // </div>
  );
}

function NewFormTemplateButton() {
  const { spaceId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const createFormTemplateMutation = useCreateFormTemplate();
  return (
    <>
      <Button
        variant={"secondary"}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        New Form Template
      </Button>
      <Modal
        isOpen={isOpen}
        closeModal={() => {
          setIsOpen(false);
        }}
      >
        <div className="w-[600px] max-w-4xl space-y-6 p-6">
          <ModalHeading
            title="New Form Template"
            subtitle="Streamline data collection with customizable form templates. Enhance efficiency and ensure uniformity in information gathering for all your projects."
          />
          <div className="space-y-4">
            <div className="w-[300px]">
              <div className="space-y-1">
                <InputHeading heading="Name" />
                <TextInput
                  name="form-template-title"
                  // label="Title"
                  placeholder="Customer Requirements"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </div>
            </div>
            <TextArea
              name="form-template-description"
              label="Description"
              placeholder="A form for collecting customer requirements"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>
          <div className="flex justify-end">
            <Button
              onClick={() => {
                if (spaceId) {
                  createFormTemplateMutation.mutate({
                    spaceId: spaceId,
                    title,
                    description,
                    blocks: [
                      {
                        type: "section",
                        blocks: [
                          {
                            type: "heading",
                            properties: {
                              text: title,
                              size: "large",
                              description: "",
                            },
                            id: new ObjectID().toString(),
                          },
                        ],
                        id: new ObjectID().toString(),
                      },
                    ],
                  });
                }
              }}
              disabled={title.trim() === ""}
            >
              Create
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

function FormTemplatesTable({
  search,
  setSearch,
  formTemplates,
}: {
  search: string;
  setSearch: (search: string) => void;
  formTemplates: FormTemplateData[];
}) {
  // const { spaceId } = useParams();
  // const { data: formTemplates } = useGetFormTemplates();
  const navigate = useNavigate();
  const columns: ColumnDef<FormTemplateData>[] = [
    {
      id: "name",
      header: "Name",
      cell: ({ row }) => {
        return <div className="truncate">{row.original.title}</div>;
      },
      accessorFn: (row) => row.title,
    },
    {
      id: "createdAt",
      header: "Created On",
      accessorFn: (row) => dayjs(row.createdAt).format("DD MMM, YYYY"),
    },
    {
      id: "updatedAt",
      header: "Last Updated",
      cell: (cell) => {
        const date1 = cell.row.original.updatedAt;
        if (!date1) {
          return <></>;
        } else {
          const timeDifference = getTimeDifference(new Date(date1), new Date());
          return (
            <div>{`${timeDifference.absValue} ${timeDifference.unit} ago`}</div>
          );
        }
      },
    },
  ];
  const table = useReactTable({
    data: formTemplates,
    columns,
    state: {
      globalFilter: search,
    },
    onGlobalFilterChange: setSearch,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: fuzzyFilter,
    getFilteredRowModel: getFilteredRowModel(),
  });

  if (table.getRowModel().rows.length === 0) {
    return (
      <div className="flex">
        <NoFormsMeetFilter />
      </div>
    );
  }

  return (
    <div className="flex-1 overflow-auto">
      <div className="max-h-full max-w-full overflow-auto">
        <table
          style={{ borderCollapse: "separate", borderSpacing: 0 }}
          className="min-w-[600px]"
        >
          <thead className="overflow-auto">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    scope="col"
                    className={
                      "sticky top-0 z-10 h-11 whitespace-nowrap border-b border-r border-gray-200/70 bg-gray-50 px-6 text-left text-xs font-normal uppercase tracking-wide text-gray-400 first:left-0 first:z-20 first:w-full first:pl-8"
                    }
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className="group relative rounded-lg hover:cursor-pointer hover:bg-gray-50"
                  onClick={() => {
                    navigate(`/templates/forms/${row.original._id}`);
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={
                        "whitespace-nowrap border-r border-b border-gray-100 px-6 py-3 text-sm text-gray-500 first:sticky first:left-0 first:z-10 first:bg-white first:pr-6 first:font-medium first:text-gray-900 first:group-hover:bg-gray-50 first:sm:pl-8"
                      }
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </>
          </tbody>
        </table>
      </div>
    </div>
    // <div className="mx-auto w-full max-w-5xl">
    //   <table className="w-full divide-y divide-gray-300 overflow-hidden rounded-lg shadow ring-1 ring-black ring-opacity-5">
    //     <thead className="bg-gray-50">
    //       {table.getHeaderGroups().map((headerGroup) => (
    //         <tr key={headerGroup.id}>
    //           {headerGroup.headers.map((header) => (
    //             <th
    //               key={header.id}
    //               scope="col"
    //               className={clsx(
    //                 "px-4 text-left text-xs font-medium uppercase tracking-wide text-gray-500 first:pl-4 first:pr-3 first:sm:pl-6",
    //                 "py-3"
    //               )}
    //             >
    //               {header.isPlaceholder
    //                 ? null
    //                 : flexRender(
    //                     header.column.columnDef.header,
    //                     header.getContext()
    //                   )}
    //             </th>
    //           ))}
    //         </tr>
    //       ))}
    //     </thead>
    //     <tbody className="divide-y divide-gray-200 bg-white">
    //       {!formTemplates ? (
    //         <tr>
    //           <td className="justify-left inset-0 flex items-center p-4">
    //             <div className="text-gray-400">Loading...</div>
    //           </td>
    //         </tr>
    //       ) : (
    //         <>
    //           {formTemplates.length === 0 ? (
    //             <tr>
    //               <td className="justify-left inset-0 flex items-center p-4">
    //                 <div className="text-left text-gray-400">
    //                   No form templates
    //                 </div>
    //               </td>
    //             </tr>
    //           ) : (
    //             <>
    //               {table.getRowModel().rows.map((row) => (
    //                 <tr
    //                   key={row.id}
    //                   className="hover:cursor-pointer hover:bg-gray-100"
    //                   onClick={() => {
    //                     navigate(`/templates/forms/${row.original._id}`);
    //                   }}
    //                 >
    //                   {row.getVisibleCells().map((cell) => (
    //                     <td
    //                       key={cell.id}
    //                       className={clsx(
    //                         "whitespace-nowrap px-4 text-sm text-gray-500 first:pl-4 first:pr-3 first:font-medium first:text-gray-900 first:sm:pl-6 ",
    //                         "py-3"
    //                       )}
    //                     >
    //                       {flexRender(
    //                         cell.column.columnDef.cell,
    //                         cell.getContext()
    //                       )}
    //                     </td>
    //                   ))}
    //                 </tr>
    //               ))}
    //             </>
    //           )}
    //         </>
    //       )}
    //     </tbody>
    //   </table>
    // </div>
  );
}

function NoFormsMeetFilter() {
  return (
    <div className="mx-auto mt-[10vh]">
      No form templates meet the filter criteria
    </div>
  );
}