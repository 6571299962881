import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAddHubSpotOAuth } from "src/api/Misc/Settings/Integrations/hubspot";
import { Loading } from "src/components/ui/ApplicationStates/Loading";

type Props = {};

export default function HubSpotAuthentication({}: Props) {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const addHubSpotOAuthMutation = useAddHubSpotOAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (code) {
      addHubSpotOAuthMutation.mutate({
        code,
      });
    }
  }, []);

  useEffect(() => {
    if (addHubSpotOAuthMutation.isError) {
      navigate("/settings/integrations");
    }
  }, [addHubSpotOAuthMutation.isError]);

  return (
    <div>
      <Loading />
    </div>
  );
}
