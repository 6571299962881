import {
  BuildingOfficeIcon,
  Squares2X2Icon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { CaretSortIcon, CheckIcon, Cross2Icon } from "@radix-ui/react-icons";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  RoadmapData,
  useCreateRoadmap,
  useGetRoadmapsForInstance,
} from "src/api/Roadmaps/roadmaps";
import {
  SharedSpaceData,
  useGetSharedSpacesForInstance,
} from "src/api/Services/SharedSpace/spaces";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { Button } from "src/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "src/components/ui/command";
import { Dialog, DialogClose, DialogContent } from "src/components/ui/dialog";
import { PageHeading } from "src/components/ui/heading";
import { Loading } from "src/components/ui/loading";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import { TextInput } from "src/components/ui/text-input";
import { cn } from "src/utils/ui/ui";

type Props = {};

export default function Roadmaps({}: Props) {
  // get roadmaps
  const { data: roadmaps } = useGetRoadmapsForInstance();
  const [open, setOpen] = useState(false);
  return (
    <div className="flex flex-col">
      <div className="flex h-16 items-center justify-between border-b px-8">
        <PageHeading heading="Project Groups" />
        {!!roadmaps?.length && (
          <>
            <Button
              onClick={() => {
                setOpen(true);
              }}
            >
              Create Project Group
            </Button>
            <NewRoadmapDialog open={open} setOpen={setOpen} />
          </>
        )}
      </div>
      <RoadmapsList />
    </div>
  );
}

function RoadmapsList() {
  const { data: roadmaps } = useGetRoadmapsForInstance();
  const [open, setOpen] = useState(false);
  return (
    <>
      {!!roadmaps ? (
        roadmaps.length > 0 ? (
          <div>
            <div className="flex h-11 items-center justify-between gap-8 border-b bg-gray-50 px-8 text-xs font-medium uppercase text-gray-500">
              <div className="min-w-[160px] flex-1">Name</div>
              <div className="flex shrink-0 items-center">
                <div className="w-40 shrink-0">Type</div>
              </div>
            </div>

            {roadmaps.map((roadmap) => (
              <RoadmapRow key={roadmap._id} roadmap={roadmap} />
            ))}
          </div>
        ) : (
          <div className="flex-1 pt-[20vh]">
            <div className="mx-auto flex max-w-md flex-col space-y-1.5 rounded-md border bg-white p-8 shadow-md">
              <div className="flex items-center space-x-2">
                <Squares2X2Icon className="h-6 w-6 text-gray-600" />
                <div className="text-xl font-medium">Project Groups</div>
              </div>
              <div className="space-y-2 py-1.5">
                <div className="text-left font-light text-gray-500">
                  Create more stucture between projects with project groups.
                </div>
                {/* <div className="text-left font-light text-gray-500">
                Begin by adding the projects you want to group together.
              </div>

              <div className="text-left font-light text-gray-500">
                If you want to create structure between the projects in the
                group, you can create sub-groups too.
              </div> */}
              </div>
              <div className="flex space-x-3 pt-2.5">
                <Button
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Create Project Group
                </Button>
              </div>
            </div>
            <NewRoadmapDialog open={open} setOpen={setOpen} />
          </div>
          // <div className="mt-[20vh] flex justify-center">
          //   <div className="flex flex-col items-center">
          //     <div className="flex flex-col items-center gap-3">
          //       <Squares2X2Icon className="mr-2.5 h-7 w-7 text-gray-400" />
          //       <div className="text-xl font-medium text-gray-700">

          //       </div>
          //     </div>

          //     <div className="mt-1 max-w-[260px] pb-5 text-center text-[15px] font-light leading-relaxed text-gray-500">
          //       Group projects that are together
          //     </div>

          //   </div>
          // </div>
        )
      ) : (
        <div>
          <Loading className="mt-[20vh]" />
        </div>
      )}
    </>
  );
}

function NewRoadmapDialog({ open, setOpen }: { open: boolean; setOpen: any }) {
  const createRoadmapMutation = useCreateRoadmap();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isInternal, setIsInternal] = useState(false);
  const [step, setStep] = useState(1);
  const [sharedSpace, setSharedSpace] = useState<SharedSpaceData | undefined>(
    undefined
  );
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (createRoadmapMutation.isSuccess) {
      setOpen(false);
    }
  }, [createRoadmapMutation.isSuccess]);

  useEffect(() => {
    if (sharedSpace) {
      setName(sharedSpace.name);
    }
  }, [sharedSpace]);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [description]);

  useEffect(() => {
    if (open) {
      setStep(1);
      setIsInternal(false);
      setName("");
      setDescription("");
      setSharedSpace(undefined);
    }
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        showCloseButton={false}
        className="top-[12%] max-w-2xl translate-y-0 space-y-0 overflow-auto p-0 transition-all"
      >
        <div>
          <div className="flex h-12 items-center border-b px-6 text-sm font-medium text-gray-700">
            New Project Group
          </div>
          {step === 0 && (
            <div className="space-y-3 p-6 pb-20">
              <label className="block text-sm font-medium leading-none text-gray-700">
                What type of projects are you creating a group for?
              </label>
              <div className="flex gap-3">
                <div
                  className="flex w-40 cursor-pointer flex-col items-center rounded-md border p-6 shadow-sm hover:bg-gray-50"
                  onClick={() => {
                    setIsInternal(true);
                    setStep(1);
                  }}
                >
                  <UserGroupIcon className="h-6 w-6 text-gray-500" />
                  <div className="flex flex-col items-center space-y-1.5 pt-2">
                    <div className="font-medium leading-none text-gray-700">
                      Internal
                    </div>
                    <div className="max-w-[100px] text-center text-xs text-gray-500">
                      Projects with my team
                    </div>
                  </div>
                </div>
                <div
                  className="flex w-40 cursor-pointer flex-col items-center rounded-md border p-6 px-4 shadow-sm hover:bg-gray-50"
                  onClick={() => {
                    setIsInternal(false);
                    setStep(1);
                  }}
                >
                  <BuildingOfficeIcon className="h-6 w-6 text-gray-500" />
                  <div className="flex flex-col items-center space-y-1.5 pt-2">
                    <div className="font-medium leading-none text-gray-700">
                      External
                    </div>
                    <div className="text-center text-xs text-gray-500">
                      Projects with other companies
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {step === 1 && (
            <>
              {isInternal ? (
                <>
                  <div className="space-y-4 p-6">
                    <div className="space-y-1">
                      <InputHeading heading="Name" required />
                      <TextInput
                        className="w-72"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        placeholder="Q2 Objectives"
                      />
                    </div>
                    <div className="space-y-1">
                      <InputHeading heading="Description" />
                      <textarea
                        ref={textAreaRef}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder={
                          "Q2 objectives for customer success and support teams.."
                        }
                        className="block min-h-[80px] w-full resize-none rounded-md border-gray-300 p-2.5 py-2 text-sm shadow-sm outline-none transition-all placeholder:text-gray-300 focus:border-primary focus:outline-none focus:ring-[0.5px] focus:ring-primary"
                        // Spread additional props
                      />
                    </div>
                  </div>
                  <div className="flex justify-end p-6 pt-2">
                    <Button
                      onClick={() => {
                        createRoadmapMutation.mutate({
                          name,
                          description,
                        });
                        setOpen(false);
                      }}
                      disabled={!name.trim()}
                    >
                      {createRoadmapMutation.isLoading
                        ? "Creating.."
                        : "Create"}
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className="space-y-6 p-6">
                    <div>
                      <InputHeading heading="Customer" required />
                      <div className="pt-1">
                        <SharedSpaceCombobox
                          sharedSpace={sharedSpace}
                          onSelect={(ss) => {
                            setSharedSpace(ss);
                          }}
                        />
                      </div>
                      {/* <div className="text-xs pt-1 text-gray-600">
                        0 active projects
                      </div> */}
                    </div>
                    <div className="space-y-1">
                      <InputHeading heading="Name" required />
                      <TextInput
                        ref={nameRef}
                        className="w-72"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        placeholder="Tesla"
                      />
                    </div>
                    <div className="space-y-1">
                      <InputHeading heading="Description" />
                      <textarea
                        ref={textAreaRef}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder={"Add a description..."}
                        className="block min-h-[100px] w-full resize-none rounded-md border-gray-300 p-2.5 py-2 text-sm shadow-sm outline-none transition-all placeholder:text-gray-300 focus:border-primary focus:outline-none focus:ring-[0.5px] focus:ring-primary"
                        // Spread additional props
                      />
                    </div>
                  </div>
                  <div className="flex justify-end p-6 pt-2">
                    <Button
                      onClick={() => {
                        if (sharedSpace) {
                          createRoadmapMutation.mutate({
                            name,
                            description,
                            sharedSpaceId: sharedSpace?._id.toString(),
                          });
                          setOpen(false);
                        }
                      }}
                      disabled={
                        !name.trim() || !description.trim() || !sharedSpace
                      }
                    >
                      {createRoadmapMutation.isLoading
                        ? "Creating.."
                        : "Create"}
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
          <DialogClose className="absolute right-6 top-4 rounded-sm opacity-40 ring-offset-white transition-opacity hover:opacity-100 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
            <Cross2Icon className="h-4 w-4" />
          </DialogClose>
        </div>
      </DialogContent>
    </Dialog>
  );
}

function SharedSpaceCombobox({
  sharedSpace,
  onSelect,
}: {
  sharedSpace: SharedSpaceData | undefined;
  onSelect: (sharedSpace: SharedSpaceData | undefined) => void;
}) {
  const [open, setOpen] = useState(false);
  const { data: sharedSpaces } = useGetSharedSpacesForInstance();
  return (
    <Popover modal={true} open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="secondary"
          size="lg"
          role="combobox"
          aria-expanded={open}
          className="w-72 justify-between space-x-2 truncate"
        >
          <div className="flex items-center space-x-2">
            <BuildingOfficeIcon className="h-4 w-4 shrink-0 text-gray-400" />
            {sharedSpace ? (
              <div className="truncate">{sharedSpace.name}</div>
            ) : (
              <div className="font-normal text-gray-300">Tesla</div>
            )}
          </div>
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
        align="start"
      >
        <Command>
          <CommandInput placeholder="Search customer..." className="h-9" />
          <CommandEmpty>No customer found.</CommandEmpty>
          <CommandGroup>
            {sharedSpaces?.map((ss) => (
              <CommandItem
                key={ss._id.toString()}
                value={ss.name}
                onSelect={() => {
                  onSelect(ss);
                  setOpen(false);
                }}
                className="gap-2"
              >
                <span className="truncate">{ss.name}</span>
                <CheckIcon
                  className={cn(
                    "ml-auto h-4 w-4 shrink-0",
                    sharedSpace?._id?.toString() === ss?._id?.toString()
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
              </CommandItem>
            ))}
            {/* {isLoading && <div>Loading..</div>} */}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function RoadmapRow({ roadmap }: { roadmap: RoadmapData }) {
  const navigate = useNavigate();
  return (
    <div
      className={
        "flex h-11 cursor-pointer items-center justify-between gap-8 border-b border-gray-100 px-8 text-sm text-gray-500 hover:bg-gray-50"
      }
      onClick={() => {
        navigate(`/project-groups/${roadmap._id}`);
      }}
    >
      <div className="min-w-[160px] flex-1 truncate">{roadmap.name}</div>
      <div className="flex shrink-0 items-center">
        <div className="w-40 truncate">
          {!!roadmap.sharedSpaceId ? "External" : "Internal"}
        </div>
      </div>
    </div>
  );
}
