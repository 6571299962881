import { useNavigate } from "react-router-dom";
import { Button } from "src/components/ui/Buttons/Button";

type Props = {
  integration: string;
};

export default function IntegrationSuccess({ integration }: Props) {
  const navigate = useNavigate();
  return (
    <div className="mx-auto flex items-center justify-center pt-32">
      <div className="mx-auto flex flex-col items-center space-y-3">
        <div className="text-3xl">
          {/* <CheckIcon className="h-8 w-8 text-green-500" /> */}
          🎉
        </div>
        <div>Successfully integrated with {integration}!</div>
        <div>
          <Button
            text="Go to Integrations"
            onClick={() => {
              navigate("/settings/integrations");
            }}
            size="xs"
          />
        </div>
      </div>
    </div>
  );
}
