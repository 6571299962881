import {
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../components/Authentication/Logo";
import { useRealmApp } from "../../store/RealmApp";

type Props = {};

const Verified = (props: Props) => {
  const navigate = useNavigate();
  const app = useRealmApp();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  // console.log(searchParams.get("token"));
  // console.log(searchParams.get("tokenId"));

  const confirmUser = async () => {
    try {
      await app.emailPasswordAuth.confirmUser({
        token: searchParams.get("token"),
        tokenId: searchParams.get("tokenId"),
      });
      navigate("/login");
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    confirmUser();
  }, []);

  return (
    <Container maxWidth="sm">
      <Grid container alignItems="center" justifyContent={"center"}>
        <Grid item xs={12} marginTop={20}>
          <Stack spacing={3} alignItems="center">
            <Logo height={70} />
            <Typography>Redirecting you now!</Typography>
            <CircularProgress color="secondary" />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Verified;
