import { useEffect, useRef } from "react";

export const Title = ({
  title,
  onChange,
  onBlur,
  border,
}: {
  title: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  border?: boolean;
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "0px"; // Reset height to shrink if needed
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + "px";
    }
  }, [title]);

  return (
    <textarea
      ref={textAreaRef}
      className="w-full resize-none border-none p-0 pr-5 text-xl font-medium outline-none placeholder:text-gray-300 focus:border-none focus:outline-none focus:ring-0"
      value={title}
      onChange={onChange}
      onBlur={onBlur}
      placeholder="Title..."
    />
  );
};
