import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ObjectId } from "mongodb";
import { useNavigate } from "react-router-dom";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { useRealmApp } from "src/store/RealmApp";

const slugKeys = {
  theme: (slug: string) => ["slug", slug, "theme"] as const,
  instance: (slug: string) => ["slug", slug, "instance"] as const,
  access: (slug: string) => ["slug", slug, "access"] as const,
};

export type Theme = {
  instanceName: string;
  primaryColor: string;
  secondaryColor: string;
  font: string;
  fontType: string;
  logo: {
    name: string;
    type: string;
    url: string;
  };
};

export const useGetThemeSettings = (slug: string) => {
  // console.log(process.env.REACT_APP_SECRET)
  async function fetchTheme(): Promise<Theme> {
    const res = await axios.get(
      `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/getThemeSettings`,
      {
        params: {
          secret: process.env.REACT_APP_SECRET,
          slug: slug,
        },
      }
    );
    return res.data;
  }

  return useQuery(slugKeys.theme(slug), fetchTheme, {
    staleTime: Infinity,
  });
};

export const useGetInstanceIdFromSlug = (slug: string) => {
  // console.log(process.env.REACT_APP_SECRET)
  async function fetchInstanceId(): Promise<{ instanceId: string }> {
    const res = await axios.get(
      `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/getInstanceIdForSlug`,
      {
        params: {
          secret: process.env.REACT_APP_SECRET,
          slug: slug,
        },
      }
    );
    return res.data;
  }

  return useQuery(slugKeys.instance(slug), fetchInstanceId);
};

export const useSendLoginCode = () => {
  const snackbarCtx = useSnackBar();
  return useMutation({
    mutationFn: (params: { email: string; instanceId: ObjectId }) =>
      axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/sendLoginCode?secret=${process.env.REACT_APP_SECRET}`,
        params
      ),
    onError: () => {
      snackbarCtx.showSnackbar("No account found", "error");
    },
    onSuccess: (data) => {
      console.log(data);
      if (data)
        snackbarCtx.showSnackbar(
          "Check your email for a login code",
          "success"
        );
    },
  });
};

export const useLoginWithCode = () => {
  const snackbarCtx = useSnackBar();
  const navigate = useNavigate();
  // async function loginWithCode(params: {
  //   email: string;
  //   code: string;
  //   instanceId: ObjectId;
  // }): Promise<{ instanceId: string }> {
  //   const res = await axios.get(
  //     `https://us-east-1.aws.data.mongodb-api.com/app/csplatform-dev-ptwls/endpoint/getInstanceIdForSlug`,
  //     {
  //       params: {
  //         secret: process.env.REACT_APP_SECRET,
  //         slug: slug,
  //       },
  //     }
  //   );
  //   return res.data;
  // }

  return useMutation({
    mutationFn: (params: {
      email: string;
      code: string;
      instanceId: ObjectId;
      slug: string;
    }): Promise<{
      data: { token: string; sharedSpaceId: string; phaseId: string };
    }> =>
      axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/loginWithCode?secret=${process.env.REACT_APP_SECRET}`,
        {
          email: params.email,
          code: params.code,
          instanceId: params.instanceId,
        }
      ),
    onError: () => {
      snackbarCtx.showSnackbar("Incorrect authentication code", "error");
    },
    onSuccess: (data, variables) => {
      const {
        data: { token, sharedSpaceId, phaseId },
      } = data;
      navigate(
        `/authentication?token=${token}&sharedSpaceId=${sharedSpaceId}&phaseId=${phaseId}&email=${variables.email}`
      );
    },
  });
};
