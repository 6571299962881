import { createContext, useContext, useReducer } from "react";
import { TaskTemplateData } from "src/api/Services/Journeys/journeyTemplates";

type Options = {
  hasLinkedKnowledgeResourceTemplate: boolean;
  hasParentTask: boolean;
};

type Action = {
  type: "options_update";
  options: Options;
};

type Dispatch = (action: Action) => void;

type State = {
  options: Options;
};

const EditTaskTemplateContext = createContext<
  | {
      state: State;
      dispatch: Dispatch;
    }
  | undefined
>(undefined);

function editTaskTemplateReducer(state: State, action: Action): State {
  switch (action.type) {
    case "options_update":
      return { ...state, options: action.options };
    default:
      return state;
  }
}

type Props = {
  children: React.ReactNode;
  taskTemplate: TaskTemplateData;
};

export const EditTaskTemplateContextProvider = ({
  children,
  taskTemplate,
}: Props) => {
  const [state, dispatch] = useReducer(editTaskTemplateReducer, {
    options: {
      hasLinkedKnowledgeResourceTemplate: taskTemplate
        ?.knowledgeResourceTemplates?.length
        ? true
        : false,
      hasParentTask: !!taskTemplate?.parentTaskTemplate ? true : false,
    },
  });

  const value = {
    state,
    dispatch,
  };

  return (
    <EditTaskTemplateContext.Provider value={value}>
      {children}
    </EditTaskTemplateContext.Provider>
  );
};

export const useEditTaskTemplate = () => {
  const context = useContext(EditTaskTemplateContext);
  if (context === undefined) {
    throw new Error(
      "useEditTaskTemplate must be used within an EditTaskTemplateProvider"
    );
  }
  return context;
};
