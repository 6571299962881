import {
  BoltIcon,
  EyeIcon,
  EyeSlashIcon,
  FlagIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { CalendarIcon, CheckIcon, StopwatchIcon } from "@radix-ui/react-icons";
import { ObjectID } from "bson";
import clsx from "clsx";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetJourneyStageCompletions } from "src/api/Services/Dashboard/analytics";
import { relativeDueDateUnits } from "src/api/Services/Journeys/journeyTemplates";
import { useGetJourneyStages } from "src/api/Services/Journeys/journeys";
import { useGetProjectMembers } from "src/api/Services/Projects/projects";
import {
  TaskRequest,
  useAddTaskToProject,
  useGetPhaseTasks,
} from "src/api/Services/Tasks/tasks";
import Description from "src/components/Services/Reusable/Tasks/Elements/Description";
import { Title } from "src/components/Services/Reusable/Tasks/Elements/Title";
import { VariantToggle } from "src/components/ui/Toggle/VariantToggle";
import { Button } from "src/components/ui/button";
import { Calendar } from "src/components/ui/calendar";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "src/components/ui/command";
import { Dialog, DialogContent } from "src/components/ui/dialog";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { Switch } from "src/components/ui/switch";
import { TextInput } from "src/components/ui/text-input";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "src/components/ui/tooltip";
import { useJourney } from "src/pages/Services/Journeys/Journey/JourneyContextProvider";
import { cn } from "src/utils/ui/ui";
import { useNewProjectTask } from "./NewProjectTaskContextProvider";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

export default function NewProjectTaskModal({ isOpen, closeModal }: Props) {
  const [createMore, setCreateMore] = useState(false);
  const newProjectTaskCtx = useNewProjectTask();
  const addTaskMutation = useAddTaskToProject();

  const journeyCtx = useJourney();

  useEffect(() => {
    if (addTaskMutation.isSuccess) {
      newProjectTaskCtx.dispatch({
        type: "title_update",
        title: "",
      });
      newProjectTaskCtx.dispatch({
        type: "description_update",
        description: "",
      });
      if (!createMore) {
        closeModal();
      }
    }
  }, [addTaskMutation.isSuccess]);

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(o) => {
        if (!o) {
          closeModal();
        }
      }}
    >
      <DialogContent
        className={clsx(
          "top-[8%] max-w-fit translate-y-0 p-0 data-[state=open]:slide-in-from-top-8"
        )}
        onPointerDownOutside={(e) => {
          e.preventDefault();
        }}
        onEscapeKeyDown={(e) => {
          e.preventDefault();
          // setCloseState("escape");
        }}
        // onFocus={() => {
        //   // setCloseState("open");
        // }}
      >
        {/* <ModalHeading title="New Task" /> */}
        <div className="w-[800px] divide-gray-200">
          <div className="p-6 pb-0">
            <Title
              title={newProjectTaskCtx.state.title}
              onChange={(e) => {
                newProjectTaskCtx.dispatch({
                  type: "title_update",
                  title: e.target.value,
                });
              }}
            />
            <div className="flex flex-wrap items-start gap-2 pt-3 pb-2">
              <StageCombobox />
              <SharedSpaceTaskerCombobox />

              <DueDatePicker />
              {/* <EstimateSelect /> */}
              {!journeyCtx.state.isExternal && <VisibilitySelect />}
            </div>

            {/* <div className="pb-2">
              <Dependency setIsAddingDependency={setIsAddingDependency} />
            </div> */}

            <div className="pb-4 pt-1">
              <div className="rounded-md border-[1.5px] border-gray-200 bg-gray-50 p-2 px-2">
                <Description
                  description={newProjectTaskCtx.state.description}
                  onBlur={(description) => {
                    newProjectTaskCtx.dispatch({
                      type: "description_update",
                      description: description,
                    });
                  }}
                />
              </div>
            </div>

            {/* <div>
                <div className="pb-1.5 font-medium text-gray-500">
                  Extensions
                </div>
                <div className="flex items-center space-x-3 text-gray-400">
                  <Action
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        strokeWidth="1"
                      >
                        <path
                          fill="currentColor"
                          d="M17 16a2.99 2.99 0 0 0-2.816 2H11c-1.654 0-3-1.346-3-3v-3.025A4.954 4.954 0 0 0 11 13h3.184A2.99 2.99 0 0 0 17 15a3 3 0 1 0 0-6a2.99 2.99 0 0 0-2.816 2H11c-1.654 0-3-1.346-3-3v-.184A2.99 2.99 0 0 0 10 5a3 3 0 1 0-6 0a2.99 2.99 0 0 0 2 2.816V15c0 2.757 2.243 5 5 5h3.184A2.99 2.99 0 0 0 17 22a3 3 0 1 0 0-6zm0-5a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zM7 4a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zm10 16a1.001 1.001 0 0 1 0-2a1.001 1.001 0 0 1 0 2z"
                          // stroke="currentColor"
                          strokeWidth={0}
                        />
                      </svg>
                    }
                    onClick={() => {}}
                    text="Subtasks"
                    // disabled={
                    //   taskTemplateCtx.state.action === "form" ||
                    //   taskTemplateCtx.state.action === "checklist"
                    // }
                    // active={taskTemplateCtx.state.options.hasSubTasks}
                  />
                  <Action
                    icon={<DocumentTextIcon className="h-5 w-5" />}
                    onClick={() => {}}
                    text="Form"
                  />
                  <Action
                    icon={<PhoneIcon className="h-5 w-5" />}
                    onClick={() => {}}
                    text="Call"
                  />
                  <Action
                    icon={<CloudArrowUpIcon className="h-5 w-5" />}
                    onClick={() => {}}
                    text="File Upload"
                  />
                </div>
              </div> */}
          </div>
          <div className="p-6 py-4">
            <div className="flex justify-between">
              <div>{/* <Options /> */}</div>
              <div className="inline-flex items-center space-x-4">
                <CreateMore
                  createMore={createMore}
                  setCreateMore={setCreateMore}
                />
                <div className="inline-flex items-center space-x-2">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={
                      newProjectTaskCtx.state.title.trim() === "" ||
                      !newProjectTaskCtx.state.tasker ||
                      !newProjectTaskCtx.state.stage ||
                      (newProjectTaskCtx.state.addingDependency &&
                        !newProjectTaskCtx.state.dependentOn._id) ||
                      (!newProjectTaskCtx.state.addingDependency &&
                        !newProjectTaskCtx.state.dueDate) ||
                      addTaskMutation.isLoading
                    }
                    onClick={() => {
                      // add task
                      // if (
                      //   newProjectTaskCtx.state.stage?._id &&
                      //   newProjectTaskCtx.state.tasker
                      // ) {
                      //   addTaskMutation.mutate({
                      //     params: {
                      //       task: {
                      //         title: newProjectTaskCtx.state.title,
                      //         description: newProjectTaskCtx.state.description,
                      //         ...(newProjectTaskCtx.state.addingDependency
                      //           ? newProjectTaskCtx.state.dependentOn._id
                      //             ? {
                      //                 dependentOn: {
                      //                   type: newProjectTaskCtx.state
                      //                     .dependentOn.type,
                      //                   _id: newProjectTaskCtx.state.dependentOn
                      //                     ._id,
                      //                 },
                      //                 relativeDueDate: {
                      //                   value:
                      //                     newProjectTaskCtx.state
                      //                       .relativeDueDate.value,
                      //                   unit: newProjectTaskCtx.state
                      //                     .relativeDueDate.unit,
                      //                 },
                      //               }
                      //             : {}
                      //           : !!newProjectTaskCtx.state.dueDate
                      //           ? {
                      //               dueDate: newProjectTaskCtx.state.dueDate,
                      //             }
                      //           : {}),
                      //         // dueDate: newProjectTaskCtx.state.dueDate,
                      //         ...(newProjectTaskCtx.state.tasker.userType ===
                      //         "fullUser"
                      //           ? {
                      //               taskerId:
                      //                 newProjectTaskCtx.state.tasker._id.toString(),
                      //             }
                      //           : newProjectTaskCtx.state.tasker.userType ===
                      //             "invitedUser"
                      //           ? {
                      //               taskerEmail:
                      //                 newProjectTaskCtx.state.tasker.email,
                      //             }
                      //           : {}),
                      //         taskerType: newProjectTaskCtx.state.tasker.type,
                      //         journeyId: newProjectTaskCtx.state.journeyId,
                      //         journeyStageId:
                      //           newProjectTaskCtx.state.stage?._id?.toString(),
                      //         estimate: {
                      //           value: newProjectTaskCtx.state.estimate.value,
                      //           unit: newProjectTaskCtx.state.estimate.unit,
                      //         },
                      //         isVisible: newProjectTaskCtx.state.isVisible,
                      //       },
                      //     },
                      //     metadata: {
                      //       phaseId: newProjectTaskCtx.state.phaseId,
                      //       journeyId: newProjectTaskCtx.state.journeyId,
                      //       journeyStageId:
                      //         newProjectTaskCtx.state.stage?._id?.toString(),
                      //     },
                      //   });
                      // }
                      // else if (
                      //   newProjectTaskCtx.state.tasker?.userType ===
                      //     "invitedUser" &&
                      //   newProjectTaskCtx.state.stage?._id
                      // ) {
                      //   addTaskMutation.mutate({
                      //     params: {
                      //       task: {
                      //         title: newProjectTaskCtx.state.title,
                      //         description: newProjectTaskCtx.state.description,
                      //         dueDate: newProjectTaskCtx.state.dueDate,
                      //         ...(newProjectTaskCtx.state.tasker && {
                      //           taskerEmail:
                      //             newProjectTaskCtx.state.tasker.email,
                      //         }),
                      //         taskerType: newProjectTaskCtx.state.tasker.type,
                      //         journeyId: newProjectTaskCtx.state.journeyId,
                      //         journeyStageId:
                      //           newProjectTaskCtx.state.stage?._id?.toString(),
                      //         estimate: {
                      //           value: newProjectTaskCtx.state.estimate.value,
                      //           unit: newProjectTaskCtx.state.estimate.unit,
                      //         },
                      //         isVisible: newProjectTaskCtx.state.isVisible,
                      //       },
                      //     },
                      //     metadata: {
                      //       phaseId: newProjectTaskCtx.state.phaseId,
                      //       journeyId: newProjectTaskCtx.state.journeyId,
                      //       journeyStageId:
                      //         newProjectTaskCtx.state.stage?._id?.toString(),
                      //     },
                      //   });
                      // }
                      // addTaskMutation.mutate({
                      //   task: {
                      //     title: newProjectTaskCtx.state.title,
                      //     description: newProjectTaskCtx.state.description,
                      //     dueDate: newProjectTaskCtx.state.dueDate,
                      //     ...(newProjectTaskCtx.state.tasker && {taskerId: newProjectTaskCtx.state.tasker._id}),
                      //   }
                      // })

                      // clear fields
                      // newProjectTaskCtx.dispatch({
                      //   type: "title_update",
                      //   title: "",
                      // });
                      // newProjectTaskCtx.dispatch({
                      //   type: "description_update",
                      //   description: "",
                      // });
                    }}
                  >
                    {addTaskMutation.isLoading ? "Creating.." : "Create"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

function TaskDependency() {
  const { phaseId } = useParams();
  const { data: tasks } = useGetPhaseTasks(String(phaseId));
  const newProjectTaskCtx = useNewProjectTask();
  const [open, setOpen] = useState(false);

  return (
    <Popover modal={true} open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="secondary"
          role="combobox"
          aria-expanded={open}
          className="max-w-[200px] space-x-2 truncate"
        >
          <BoltIcon className="h-4 w-4 shrink-0" />
          {newProjectTaskCtx.state.dependentOn._id &&
          newProjectTaskCtx.state.dependentOn.type === "task" ? (
            <div className="truncate">
              {
                tasks?.find(
                  (t) =>
                    t._id?.toString() ===
                    newProjectTaskCtx.state.dependentOn._id?.toString()
                )?.title
              }
            </div>
          ) : (
            <div className="flex space-x-1 text-gray-400/80">
              Task
              <span className="top-0 ml-0.5 text-primary-main">*</span>
            </div>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="pointer-events-auto max-h-[300px] w-fit min-w-[200px] max-w-[700px] p-0"
        align="start"
      >
        <Command>
          <CommandInput placeholder="Search task..." className="h-9" />
          <CommandEmpty>No task found.</CommandEmpty>
          <CommandGroup>
            {tasks
              ?.filter(
                (t) =>
                  t.status === "To-do" ||
                  t.status === "In Progress" ||
                  t.status === "Stuck"
              )
              .map((t) => (
                <TooltipProvider delayDuration={1200}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <CommandItem
                        key={t?._id?.toString()}
                        value={t.title}
                        onSelect={() => {
                          newProjectTaskCtx.dispatch({
                            type: "dependent_on_update",
                            dependentOn: {
                              type: "task",
                              _id: t._id?.toString(),
                            },
                          });
                          setOpen(false);
                        }}
                        className="gap-2"
                      >
                        {/* {t.journeyStage?._id} */}
                        <span className="truncate">{t.title}</span>
                        <CheckIcon
                          className={cn(
                            "ml-auto h-4 w-4 shrink-0",
                            newProjectTaskCtx.state.dependentOn._id?.toString() ===
                              t?._id?.toString()
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                      </CommandItem>
                    </TooltipTrigger>
                    <TooltipContent align="start" side="left" sideOffset={12}>
                      <p>{t.title}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function StageDependency() {
  const { phaseId } = useParams();
  const newProjectTaskCtx = useNewProjectTask();
  // const { stages } = useGetJourneyStages(
  //   new ObjectID(newProjectTaskCtx.state.journeyId)
  // );
  const { journeyStageCompletions } = useGetJourneyStageCompletions(
    new ObjectID(newProjectTaskCtx.state.journeyId)
  );
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="secondary"
          role="combobox"
          aria-expanded={open}
          className="max-w-[200px] space-x-2 truncate"
        >
          <FlagIcon className="h-4 w-4 shrink-0" />
          {!!newProjectTaskCtx.state.dependentOn._id &&
          newProjectTaskCtx.state.dependentOn.type === "stage" ? (
            <div className="truncate">
              {
                journeyStageCompletions?.find(
                  (s) =>
                    s._id?.toString() ===
                    newProjectTaskCtx.state.dependentOn._id?.toString()
                )?.name
              }
            </div>
          ) : (
            <div className="flex space-x-1 text-gray-400/80">
              Stage
              <span className="top-0 ml-0.5 text-primary-main">*</span>
            </div>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="pointer-events-auto max-h-[300px] w-fit min-w-[200px] max-w-[700px] p-0"
        align="start"
      >
        <Command>
          <CommandInput placeholder="Search task..." className="h-9" />
          <CommandEmpty>No task found.</CommandEmpty>
          <CommandGroup>
            {journeyStageCompletions
              ?.filter((s) => s.completionPercent !== 100)
              .map((s) => (
                <TooltipProvider delayDuration={1200}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <CommandItem
                        key={s?._id?.toString()}
                        value={s.name}
                        onSelect={() => {
                          newProjectTaskCtx.dispatch({
                            type: "dependent_on_update",
                            dependentOn: {
                              type: "stage",
                              _id: s._id?.toString(),
                            },
                          });
                          setOpen(false);
                        }}
                        className="gap-2"
                      >
                        <span className="truncate">{s.name}</span>
                        <CheckIcon
                          className={cn(
                            "ml-auto h-4 w-4 shrink-0",
                            newProjectTaskCtx.state.dependentOn._id?.toString() ===
                              s?._id?.toString()
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                      </CommandItem>
                    </TooltipTrigger>
                    <TooltipContent align="start" side="left" sideOffset={12}>
                      <p>{s.name}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function ConditionSelect() {
  const newProjectTaskCtx = useNewProjectTask();
  return (
    <Select
      value={newProjectTaskCtx.state.dependentOn.type}
      onValueChange={(value) => {
        newProjectTaskCtx.dispatch({
          type: "dependent_on_update",
          dependentOn: {
            ...newProjectTaskCtx.state.dependentOn,
            type: value as "task" | "stage",
            _id: undefined,
          },
        });
      }}
    >
      <SelectTrigger className="w-fit gap-2" showIcon={false}>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem value="task">completion of task</SelectItem>
          <SelectItem value="stage">completion of stage</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

function ValueInput() {
  const newProjectTaskCtx = useNewProjectTask();
  const [value, setValue] = useState(
    String(newProjectTaskCtx.state.relativeDueDate.value)
  );

  useEffect(() => {
    setValue(String(newProjectTaskCtx.state.relativeDueDate.value));
  }, [newProjectTaskCtx.state.relativeDueDate.value]);

  return (
    <TextInput
      value={value}
      className="h-8 w-[70px]"
      onChange={(e) => {
        const rx = new RegExp(/^\d+$/);
        if (rx.test(e.target.value) || e.target.value === "") {
          setValue(e.target.value);
        }
      }}
      onBlur={(e) => {
        if (e.target.value === "") {
          newProjectTaskCtx.dispatch({
            type: "relative_due_date_update",
            relativeDueDate: {
              value: 1,
              unit: newProjectTaskCtx.state.relativeDueDate.unit,
            },
          });
        } else {
          newProjectTaskCtx.dispatch({
            type: "relative_due_date_update",
            relativeDueDate: {
              value: parseInt(e.target.value),
              unit: newProjectTaskCtx.state.relativeDueDate.unit,
            },
          });
        }
      }}
    />
  );
}

function UnitSelect() {
  const newProjectTaskCtx = useNewProjectTask();
  return (
    <Select
      value={newProjectTaskCtx.state.relativeDueDate.unit}
      onValueChange={(value) => {
        newProjectTaskCtx.dispatch({
          type: "relative_due_date_update",
          relativeDueDate: {
            ...newProjectTaskCtx.state.relativeDueDate,
            unit: value as (typeof relativeDueDateUnits)[number],
          },
        });
      }}
    >
      <SelectTrigger className="w-fit gap-2" showIcon={false}>
        <SelectValue>
          {newProjectTaskCtx.state.relativeDueDate.unit +
            (newProjectTaskCtx.state.relativeDueDate.value > 1 ? "s" : "")}
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {relativeDueDateUnits
            .filter(
              (unit) =>
                unit !== "minute" && unit !== "month" && unit !== "quarter"
            )
            .map((unit) => (
              <SelectItem value={unit}>{unit + "s"}</SelectItem>
            ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

function VisibilitySelect() {
  const newProjectTaskCtx = useNewProjectTask();
  return (
    <div className="inline-flex h-8 items-center gap-1 rounded-md border border-gray-300 px-1 shadow-sm">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <button
              className={clsx(
                "rounded p-1 outline-none focus-visible:ring-[1.25px] focus-visible:ring-primary",
                newProjectTaskCtx.state.isVisible
                  ? "bg-primary-100 text-primary-600"
                  : "text-gray-500 hover:bg-gray-100"
              )}
              onClick={() => {
                newProjectTaskCtx.dispatch({
                  type: "visibility_update",
                  isVisible: true,
                });
              }}
            >
              <EyeIcon className="h-4 w-4" />
            </button>
          </TooltipTrigger>
          <TooltipContent align="start" side="bottom" sideOffset={8}>
            <p>Externally visible</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <button
              className={clsx(
                "rounded p-1 outline-none focus-visible:ring-[1.25px] focus-visible:ring-primary",
                !newProjectTaskCtx.state.isVisible
                  ? "bg-primary-100 text-primary-600"
                  : "text-gray-500 hover:bg-gray-100",
                newProjectTaskCtx.state.tasker?.type == "external" &&
                  "bg-gray-100 text-gray-300 hover:bg-gray-100"
              )}
              onClick={() => {
                newProjectTaskCtx.dispatch({
                  type: "visibility_update",
                  isVisible: false,
                });
              }}
              disabled={newProjectTaskCtx.state.tasker?.type == "external"}
            >
              <EyeSlashIcon
                className={`h-4 w-4 ${
                  newProjectTaskCtx.state.tasker?.type === "external"
                    ? "text-gray-300"
                    : ""
                }`}
              />
            </button>
          </TooltipTrigger>
          <TooltipContent align="start" side="bottom" sideOffset={8}>
            <p>Externally hidden</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
}

export const estimateOptions: NonNullable<TaskRequest["estimate"]>[] = [
  { value: 5, unit: "minute" },
  { value: 10, unit: "minute" },
  { value: 30, unit: "minute" },
  { value: 45, unit: "minute" },
  { value: 1, unit: "hour" },
  { value: 2, unit: "hour" },
  { value: 4, unit: "hour" },
];

function EstimateSelect() {
  const newProjectTaskCtx = useNewProjectTask();
  return (
    <Select
      value={
        newProjectTaskCtx.state.estimate.value +
        " " +
        newProjectTaskCtx.state.estimate.unit
      }
      onValueChange={(value) => {
        const e = estimateOptions.find(
          (estimate) => estimate.value + " " + estimate.unit === value
        );
        if (e) {
          newProjectTaskCtx.dispatch({
            type: "estimate_update",
            estimate: e,
          });
        }
        if (value === "0 hour") {
          newProjectTaskCtx.dispatch({
            type: "estimate_update",
            estimate: {
              value: 0,
              unit: "hour",
            },
          });
        }
      }}
    >
      <SelectTrigger className="w-fit gap-2" showIcon={false}>
        <StopwatchIcon className="h-4 w-4 text-gray-700" />
        <SelectValue>
          {newProjectTaskCtx.state.estimate.value === 0
            ? "Not sure"
            : newProjectTaskCtx.state.estimate.value +
              " " +
              (newProjectTaskCtx.state.estimate.unit === "minute"
                ? "min"
                : "hr")}
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {/* <SelectLabel>Fruits</SelectLabel> */}
          <SelectItem value={"0 hour"}>Not sure</SelectItem>
          {estimateOptions.map((estimate) => (
            <SelectItem
              key={estimate.value + estimate.unit}
              value={estimate.value + " " + estimate.unit}
            >
              {estimate.value +
                " " +
                (estimate.unit === "minute" ? "min" : "hr")}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

const hourOptions = Array.from({ length: 12 }, (_, i) => i);
const minuteOptions = [0, 15, 30, 45];
const ampmOptions = ["am", "pm"];

const timeOptions = [
  { hour: 0, minute: 0, label: "12:00am" },
  { hour: 0, minute: 30, label: "12:30am" },
  { hour: 1, minute: 0, label: "01:00am" },
  { hour: 1, minute: 30, label: "01:30am" },
  { hour: 2, minute: 0, label: "02:00am" },
  { hour: 2, minute: 30, label: "02:30am" },
  { hour: 3, minute: 0, label: "03:00am" },
  { hour: 3, minute: 30, label: "03:30am" },
  { hour: 4, minute: 0, label: "04:00am" },
  { hour: 4, minute: 30, label: "04:30am" },
  { hour: 5, minute: 0, label: "05:00am" },
  { hour: 5, minute: 30, label: "05:30am" },
  { hour: 6, minute: 0, label: "06:00am" },
  { hour: 6, minute: 30, label: "06:30am" },
  { hour: 7, minute: 0, label: "07:00am" },
  { hour: 7, minute: 30, label: "07:30am" },
  { hour: 8, minute: 0, label: "08:00am" },
  { hour: 8, minute: 30, label: "08:30am" },
  { hour: 9, minute: 0, label: "09:00am" },
  { hour: 9, minute: 30, label: "09:30am" },
  { hour: 10, minute: 0, label: "10:00am" },
  { hour: 10, minute: 30, label: "10:30am" },
  { hour: 11, minute: 0, label: "11:00am" },
  { hour: 11, minute: 30, label: "11:30am" },
  { hour: 12, minute: 0, label: "12:00pm" },
  { hour: 12, minute: 30, label: "12:30pm" },
  { hour: 13, minute: 0, label: "01:00pm" },
  { hour: 13, minute: 30, label: "01:30pm" },
  { hour: 14, minute: 0, label: "02:00pm" },
  { hour: 14, minute: 30, label: "02:30pm" },
  { hour: 15, minute: 0, label: "03:00pm" },
  { hour: 15, minute: 30, label: "03:30pm" },
  { hour: 16, minute: 0, label: "04:00pm" },
  { hour: 16, minute: 30, label: "04:30pm" },
  { hour: 17, minute: 0, label: "05:00pm" },
  { hour: 17, minute: 30, label: "05:30pm" },
  { hour: 18, minute: 0, label: "06:00pm" },
  { hour: 18, minute: 30, label: "06:30pm" },
  { hour: 19, minute: 0, label: "07:00pm" },
  { hour: 19, minute: 30, label: "07:30pm" },
  { hour: 20, minute: 0, label: "08:00pm" },
  { hour: 20, minute: 30, label: "08:30pm" },
  { hour: 21, minute: 0, label: "09:00pm" },
  { hour: 21, minute: 30, label: "09:30pm" },
  { hour: 22, minute: 0, label: "10:00pm" },
  { hour: 22, minute: 30, label: "10:30pm" },
  { hour: 23, minute: 0, label: "11:00pm" },
  { hour: 23, minute: 30, label: "11:30pm" },
];

function DueDatePicker() {
  const newProjectTaskCtx = useNewProjectTask();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"secondary"}
          className={cn(
            "h-8 justify-between text-left font-normal",
            !newProjectTaskCtx.state.dueDate && "text-muted-foreground"
          )}
        >
          <div className="inline-flex items-center">
            <CalendarIcon className="mr-2 h-4 w-4" />
            {newProjectTaskCtx.state.addingDependency ? (
              <>
                {!!newProjectTaskCtx.state.dependentOn._id ? (
                  <div>
                    {newProjectTaskCtx.state.dependentOn.type === "task"
                      ? "Dependent on task"
                      : "Dependent on stage"}
                  </div>
                ) : (
                  <div className="flex space-x-1 text-gray-400/80">
                    Dynamic Due Date
                    <span className="top-0 ml-0.5 text-primary-main">*</span>
                  </div>
                )}
              </>
            ) : newProjectTaskCtx.state.dueDate ? (
              dayjs(newProjectTaskCtx.state.dueDate).format("DD MMM")
            ) : (
              <div className="flex space-x-1 text-gray-400/80">
                Date
                <span className="top-0 ml-0.5 text-primary-main">*</span>
              </div>
            )}
          </div>
          {/* <CaretSortIcon className="ml-2 h-4 w-4 opacity-50" /> */}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[400px] p-0" align="start">
        <div className="border-b p-3 px-4">
          <div className="flex items-center justify-between">
            <div className="text-sm text-gray-500">{"Dynamic Due Date"}</div>
            <Switch
              checked={newProjectTaskCtx.state.addingDependency}
              onCheckedChange={(checked) => {
                newProjectTaskCtx.dispatch({
                  type: "adding_dependency_update",
                  addingDependency: checked,
                });
              }}
            />
          </div>
        </div>
        {newProjectTaskCtx.state.addingDependency ? (
          <div className="space-y-2">
            <div className="flex items-center gap-2 p-3 pb-0 text-sm">
              <div className="text-gray-500">Due</div>
              <ValueInput />
              <UnitSelect />
              <div className="text-gray-500">after</div>
            </div>
            <div className="flex items-center gap-2 p-3 pt-0 text-sm">
              <ConditionSelect />
              {newProjectTaskCtx.state.dependentOn.type === "task" && (
                <TaskDependency />
              )}
              {newProjectTaskCtx.state.dependentOn.type === "stage" && (
                <StageDependency />
              )}
              {/* <div className="px-2.5">Kickoff Call</div> */}
            </div>
          </div>
        ) : (
          <div className="flex gap-3">
            <Calendar
              mode="single"
              selected={
                newProjectTaskCtx.state.dueDate
                  ? new Date(newProjectTaskCtx.state.dueDate)
                  : undefined
              }
              onSelect={(date) => {
                if (date) {
                  newProjectTaskCtx.dispatch({
                    type: "due_date_update",
                    dueDate: date,
                  });
                }
              }}
              initialFocus
            />
            <div className="flex items-center gap-2 p-3">
              {/* <Hour /> */}
              {/* <Minute />
              <AMPM /> */}
            </div>
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
}

function Hour() {
  const newProjectTaskCtx = useNewProjectTask();
  return (
    <Select
      value={
        newProjectTaskCtx.state.dueDate
          ? dayjs(newProjectTaskCtx.state.dueDate).format("h")
          : "hh"
      }
      disabled={!newProjectTaskCtx.state.dueDate}
      onValueChange={(value) => {
        if (!newProjectTaskCtx.state.dueDate) return;
        const newHour = parseInt(value, 10);
        const amPm =
          newProjectTaskCtx.state.dueDate.getHours() >= 12 ? "PM" : "AM";
        // Convert 12-hour time to 24-hour time
        let hour24 = newHour;
        if (amPm === "PM" && newHour !== 12) {
          hour24 = newHour + 12;
        } else if (amPm === "AM" && newHour === 12) {
          hour24 = 0; // Midnight
        }
        newProjectTaskCtx.dispatch({
          type: "due_date_update",
          dueDate: new Date(newProjectTaskCtx.state.dueDate.setHours(hour24)),
        });
      }}
    >
      <SelectTrigger
        className={`h-7 w-fit gap-2 bg-white px-2`}
        showIcon={false}
      >
        <div className="inline-flex w-6 items-center justify-center gap-2">
          <SelectValue>
            <span className="text-sm">
              {newProjectTaskCtx.state.dueDate
                ? dayjs(newProjectTaskCtx.state.dueDate).format("hh")
                : "hh"}
            </span>
          </SelectValue>
        </div>
      </SelectTrigger>
      <SelectContent className="w-fit min-w-0">
        <SelectGroup>
          {/* <SelectItem value={"0 hour"}>Not sure</SelectItem> */}
          {hourOptions.map((hour) => (
            <SelectItem className="pr-2" key={hour} value={String(hour)}>
              <div className="inline-flex items-center">
                <span className="w-4 text-right">{hour.toFixed()}</span>
              </div>
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

function StageCombobox() {
  const newProjectTaskCtx = useNewProjectTask();
  const { stages } = useGetJourneyStages(
    new ObjectID(newProjectTaskCtx.state.journeyId)
  );
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="secondary"
          role="combobox"
          aria-expanded={open}
          className="max-w-[200px] space-x-2 truncate"
        >
          <FlagIcon className="h-4 w-4 shrink-0" />
          {newProjectTaskCtx.state.stage ? (
            <div className="truncate">{newProjectTaskCtx.state.stage.name}</div>
          ) : (
            <div className="flex space-x-1 text-gray-400/80">
              Stage
              <span className="top-0 ml-0.5 text-primary-main">*</span>
            </div>
          )}
          {/* <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" /> */}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
        align="start"
      >
        <Command>
          <CommandInput placeholder="Search stage..." className="h-9" />
          <CommandEmpty>No stage found.</CommandEmpty>
          <CommandGroup>
            {stages?.map((stage) => (
              <CommandItem
                key={stage._id?.toString()}
                value={stage.name}
                onSelect={() => {
                  newProjectTaskCtx.dispatch({
                    type: "stage_update",
                    stage,
                  });
                  setOpen(false);
                }}
                className="gap-2"
              >
                <span className="truncate">{stage.name}</span>
                <CheckIcon
                  className={cn(
                    "ml-auto h-4 w-4 shrink-0",
                    newProjectTaskCtx.state.stage?._id?.toString() ===
                      stage?._id?.toString()
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function SharedSpaceTaskerCombobox() {
  const [open, setOpen] = useState(false);
  const newProjectTaskCtx = useNewProjectTask();
  // const { data: taskers } = useGetTaskersForSharedSpace(
  //   new ObjectID(newProjectTaskCtx.state.sharedSpaceId)
  // );
  const { data: projectTaskers } = useGetProjectMembers(
    newProjectTaskCtx.state.phaseId
  );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="secondary"
          role="combobox"
          aria-expanded={open}
          className="max-w-[200px] space-x-1.5 truncate"
        >
          <UserIcon viewBox="4 0 20 24" className="h-4 shrink-0" />
          {newProjectTaskCtx.state.tasker && (
            <div
              className={clsx(
                "h-1.5 w-1.5 shrink-0 rounded-full bg-gray-300",
                newProjectTaskCtx.state.tasker.type === "internal"
                  ? "bg-gray-300"
                  : "bg-slate-700"
              )}
            />
          )}
          {newProjectTaskCtx.state.tasker ? (
            <div className="truncate">
              {newProjectTaskCtx.state.tasker.userType === "fullUser"
                ? newProjectTaskCtx.state.tasker.name
                : newProjectTaskCtx.state.tasker.email}
            </div>
          ) : (
            <div className="flex space-x-1 text-gray-400/80">
              Tasker
              <span className="top-0 ml-0.5 text-primary-main">*</span>
            </div>
          )}
          {/* <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" /> */}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
        align="start"
      >
        <Command>
          <CommandInput placeholder="Search role..." className="h-9" />
          <CommandEmpty>No role found.</CommandEmpty>
          <CommandGroup>
            {/* {taskers?.map((tasker) => (
              <CommandItem
                key={tasker.email}
                value={
                  tasker.userType === "fullUser" ? tasker.name : tasker.email
                }
                onSelect={() => {
                  newProjectTaskCtx.dispatch({
                    type: "tasker_update",
                    tasker: tasker,
                  });
                  if (tasker.type === "external") {
                    newProjectTaskCtx.dispatch({
                      type: "visibility_update",
                      isVisible: true,
                    });
                  }
                  setOpen(false);
                }}
                className="gap-2"
              >
                <div className="flex items-center space-x-2">
                  <div
                    className={clsx(
                      "h-2 w-2 shrink-0 rounded-full",
                      tasker.type === "internal"
                        ? "bg-gray-300"
                        : "bg-slate-700"
                    )}
                  />
                  <span className="truncate">
                    {tasker.userType === "fullUser"
                      ? tasker.name
                      : tasker.email}
                  </span>
                </div>
                <CheckIcon
                  className={cn(
                    "ml-auto h-4 w-4 shrink-0",
                    newProjectTaskCtx.state.tasker?.email === tasker.email
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
              </CommandItem>
            ))} */}
            <CommandItem>To do</CommandItem>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

const CreateMore = ({
  createMore,
  setCreateMore,
}: {
  createMore: boolean;
  setCreateMore: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div className="inline-flex items-center space-x-2">
      <VariantToggle
        enabled={createMore}
        setEnabled={() => setCreateMore(!createMore)}
        size="sm"
        focus={false}
      />
      <span className="text-xs text-gray-400">Create more</span>
    </div>
  );
};
