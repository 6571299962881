import { createTheme } from "@mui/material/styles";

const PRIMARY = {
  main: "#8454F4",
  contrastText: "#FFFFFF",
};
const SECONDARY = {
  main: "#45D3A1",
  contrastText: "#fff",
};
const INFO = {
  main: "#1890FF",
  contrastText: "#fff",
};
const SUCCESS = {
  main: "#54D62C",
  contrastText: "#fff",
};
const WARNING = {
  main: "#FFC107",
  contrastText: "#fff",
};
const ERROR = {
  main: "#FF4842",
  contrastText: "#fff",
};

export const theme = createTheme({
  palette: {
    primary: { ...PRIMARY },
    secondary: { ...SECONDARY },
    info: { ...INFO },
    success: { ...SUCCESS },
    warning: { ...WARNING },
    error: { ...ERROR },
  },
});
