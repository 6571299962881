import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useParams } from "react-router-dom";
import { KnowledgeResourceLinkData } from "src/api/Services/KnowledgeBase/knowledgeBase";
import { Button } from "src/components/ui/Buttons/Button";
import { EmbedButton } from "src/components/ui/Embed/EmbedButton";

type Props = {
  links: KnowledgeResourceLinkData[];
  setLinks?: React.Dispatch<React.SetStateAction<KnowledgeResourceLinkData[]>>;
  isEditable: boolean;
  isEmbed?: boolean;
};

export const Links = ({
  links,
  setLinks = () => {},
  isEditable,
  isEmbed = false,
}: Props) => {
  return (
    <div className="space-y-3">
      {isEditable && (
        <div className="inline-flex w-full justify-end">
          <Button
            text="Add Link"
            fill="outline"
            size="xs"
            color={"gray"}
            ring={false}
            onClick={() => {
              setLinks((prev) => [
                ...prev,
                { title: "", description: "", url: "" },
              ]);
            }}
          />
        </div>
      )}
      {links.map((l, i) => (
        <KnowledgeResourceLink
          key={i}
          link={l}
          index={i}
          setLinks={setLinks}
          isEditable={isEditable}
        />
      ))}
      {links.length === 0 && !isEditable && (
        <div className="text-sm text-gray-400">No links</div>
      )}
    </div>
  );
};

const KnowledgeResourceLink = ({
  link,
  index,
  setLinks,
  isEditable,
}: // isEmbed,
{
  link: KnowledgeResourceLinkData;
  index: number;
  setLinks: React.Dispatch<React.SetStateAction<KnowledgeResourceLinkData[]>>;
  isEditable: boolean;
  // isEmbed: boolean;
}) => {
  const { slug } = useParams();
  const isEmbed = !!slug;
  return (
    <Disclosure>
      {({ open }) => (
        <div>
          <Disclosure.Button className="flex w-[650px] justify-between rounded-lg bg-gray-100 px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-primary-main focus-visible:ring-opacity-75">
            <div>
              <span className="text-gray-400">{`Link ${index + 1}`}</span>
              <span className="pl-2 text-gray-700">{link.title}</span>
            </div>
            <ChevronUpIcon
              className={`${
                open ? "rotate-180 transform" : ""
              } h-5 w-5 text-gray-500`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="w-[650px] px-1 py-3 text-sm text-gray-500 ">
            {isEditable ? (
              <div className="grid grid-cols-3 gap-2">
                <div className="spacing-y-2 col-span-2">
                  <label
                    htmlFor="KRtitle"
                    className="mb-2 block text-sm font-medium text-gray-700"
                  >
                    Title
                  </label>
                  <input
                    type="text"
                    name="KRtitle"
                    id="title"
                    value={link.title}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setLinks((prev) => {
                        let temp = [...prev];
                        temp[index].title = event.target.value;
                        return temp;
                      });
                    }}
                    className="block w-full rounded-md border-gray-300 shadow-sm placeholder:text-gray-300 focus:border-primary-main focus:ring-primary-main sm:text-sm"
                    placeholder="Feature Details"
                  />
                </div>
                <div className="col-span-1">
                  <label
                    htmlFor="name"
                    className="mb-2 block text-sm font-medium text-gray-700"
                  >
                    URL
                  </label>
                  <input
                    type="url"
                    name="url"
                    id="url"
                    value={link.url}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setLinks((prev) => {
                        let temp = [...prev];
                        temp[index].url = event.target.value;
                        return temp;
                      });
                    }}
                    className="block w-full rounded-md border-gray-300 shadow-sm placeholder:text-gray-300 focus:border-primary-main focus:ring-primary-main sm:text-sm"
                    placeholder="https://app.notion.so/kwehkfjhwf"
                  />
                </div>
                {/* <div className="spacing-y-2 col-span-3">
                  <label
                    htmlFor="name"
                    className="mb-2 block text-sm font-medium text-gray-700"
                  >
                    Description
                  </label>

                  <input
                    type="text"
                    name="description"
                    id="description"
                    value={link.description}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setLinks((prev) => {
                        let temp = [...prev];
                        temp[index].description = event.target.value;
                        return temp;
                      });
                    }}
                    className="block w-full rounded-md border-gray-300 shadow-sm placeholder:text-gray-300 focus:border-primary-main focus:ring-primary-main sm:text-sm"
                    placeholder="Check out our guide on how to make the most of our new feature!"
                  />
                </div> */}
                <div className="mt-3">
                  <Button
                    text="Delete Link"
                    onClick={() => {
                      setLinks((prev) => {
                        const newLinks = [...prev];
                        newLinks.splice(index, 1);
                        return newLinks;
                      });
                    }}
                    color="danger"
                    size="xs"
                    fill="outline"
                    ring={false}
                  />
                </div>
              </div>
            ) : (
              <div className="space-y-4">
                {/* {link.title.trim() === "" ? (
                  <p className="gray-400 text-sm italic">{"No title added"}</p>
                ) : (
                  <p className="text-md">{link.title}</p>
                )} */}
                {/* {link.description.trim() === "" ? (
                  <p className="text-sm text-gray-400">
                    {"No description added"}
                  </p>
                ) : (
                  <p className="text-sm">{link.description}</p>
                )} */}
                {link.url.trim() === "" ? (
                  <p className="text-sm text-gray-400">{"No URL added"}</p>
                ) : isEmbed ? (
                  <EmbedButton
                    onClick={() => {
                      window.open(link.url, "_blank");
                    }}
                    size="xs"
                    text="Open Link"
                    fill="outline"
                    leftIcon={
                      <ArrowTopRightOnSquareIcon className="-ml-0.5 mr-2 h-3 w-3" />
                    }
                  />
                ) : (
                  <Button
                    onClick={() => {
                      window.open(link.url, "_blank");
                    }}
                    size="xs"
                    text="Open Link"
                    fill="outline"
                    leftIcon={
                      <ArrowTopRightOnSquareIcon className="-ml-0.5 mr-2 h-3 w-3" />
                    }
                  />
                )}
              </div>
            )}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
};
