import { KnowledgeResourceData } from "src/api/Services/KnowledgeBase/knowledgeBase";
import { Modal } from "src/components/ui/Modals/Modal";
import { ModalCloseIcon } from "src/components/ui/Modals/ModalCloseIcon";
import ModalHeading from "src/components/ui/Modals/ModalHeading";
import { Links } from "../Common/Links";
import { RichTextEditor } from "../Common/RichTextEditor";

type Props = {
  knowledgeResource: KnowledgeResourceData | undefined;
  isOpen: boolean;
  closeModal: () => void;
  isEmbed?: boolean;
};

export const KnowledgeResourceModal = ({
  knowledgeResource,
  isOpen,
  closeModal,
  isEmbed = false,
}: Props) => {
  return (
    <Modal isOpen={isOpen} closeModal={closeModal} height="md">
      <div className="space-y-6 p-6">
        <div className="flex justify-between">
          <ModalHeading title={knowledgeResource?.name ?? ""} />
          <ModalCloseIcon onClick={closeModal} />
        </div>
        <RichTextEditor
          content={knowledgeResource?.body ?? ""}
          isEditable={false}
        />
        <Links
          links={knowledgeResource?.links ?? []}
          isEditable={false}
          isEmbed={isEmbed}
        />
      </div>
    </Modal>
  );
};
