import { createContext, useState } from "react";
import { TasksFilter, UserPreferencesContextObj } from "./types";

type Props = {
  children: JSX.Element;
};

export const UserPreferencesContext = createContext<UserPreferencesContextObj>({
  tasksFilter: "All",
  setTasksFilter: (_) => {},
});

export const UserPreferencesContextProvider = (props: Props) => {
  const [tasksFilter, setTasksFilter] = useState<TasksFilter>("All");

  const value = {
    tasksFilter,
    setTasksFilter,
  };

  return (
    <UserPreferencesContext.Provider value={value}>
      {props.children}
    </UserPreferencesContext.Provider>
  );
};
