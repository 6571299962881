import { DndContext, DragEndEvent, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { PhoneIcon } from "@heroicons/react/20/solid";
import {
  ArrowPathIcon,
  BoltIcon,
  BookOpenIcon,
  ChevronRightIcon,
  DocumentTextIcon,
  EyeSlashIcon,
  FlagIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { CloudArrowUpIcon, EyeIcon, PlusIcon } from "@heroicons/react/24/solid";
import { DragHandleDots2Icon } from "@radix-ui/react-icons";
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ObjectID } from "bson";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  TaskTemplateData,
  TaskerType,
  useGetStageTaskTemplates,
  useGetSubTaskTemplates,
  useUpdateTaskTemplate,
} from "src/api/Services/Journeys/journeyTemplates";
import { JourneyTemplateData } from "src/api/Services/Journeys/journeys";
import {
  useAddStageTemplate,
  useDeleteStageTemplate,
  useDeleteStageTemplateConfirmation,
  useUpdateJourneyStageTemplate,
} from "src/api/Services/Tasks/journeys";
import { Badge } from "src/components/ui/Badges/Badge";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { EmptySection } from "src/components/ui/Skeleton/EmptySection";
import { ToolTip } from "src/components/ui/ToolTip/ToolTip";
import { Button, Button as NewButton } from "src/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "src/components/ui/hover-card";
import { Loading } from "src/components/ui/loading";
import { TextInput } from "src/components/ui/text-input";
import { estimateToMinutes } from "src/pages/Services/Tasks/Tables/TasksTable";
import { StageTemplateObj } from "src/types/Services/Tasks";
import { useTaskTemplatePanelContext } from "../EditTaskTemplate/TaskTemplatePanelContextProvider";
import NewTaskTemplateModal from "../NewTaskTemplate/NewTaskTemplateModal";
import { TaskTemplateContextProvider } from "../NewTaskTemplate/TaskTemplateContextProvider";
import { useJourneyTemplate } from "./JourneyTemplateContextProvider";

export const TemplateStages = ({
  stages,
  journeyTemplate,
}: {
  stages: StageTemplateObj[];
  journeyTemplate: JourneyTemplateData;
}) => {
  const [selectedStageForAddTask, setSelectedStageForAddTask] = useState<
    StageTemplateObj | undefined
  >(undefined);

  return (
    <div className="mx-auto mb-10 max-w-6xl space-y-6">
      <div className="space-y-10">
        {journeyTemplate.stageTemplateOrder?.map((s) => {
          const stage = stages.find((st) => st._id.toString() === s.toString());
          if (stage) {
            return (
              <StageTemplate
                stage={stage}
                key={stage._id.toString()}
                setSelectedStage={setSelectedStageForAddTask}
              />
            );
          }
          return null;
        })}
        {stages.length > 0 ? (
          <NewStageButton />
        ) : (
          <EmptySection
            Icon={FlagIcon}
            title="No existing stages"
            subtitle="Add a stage to get started"
            cta={<NewStageButton />}
          />
        )}
        {/* {stages?.map((s) => (
          <StageTemplate
            stage={s}
            key={s._id.toString()}
            setSelectedStage={setSelectedStageForAddTask}
          />
        ))} */}
      </div>

      {selectedStageForAddTask && (
        <TaskTemplateContextProvider stage={selectedStageForAddTask}>
          <NewTaskTemplateModal
            isOpen={!!selectedStageForAddTask}
            closeModal={() => {
              setSelectedStageForAddTask(undefined);
            }}
          />
        </TaskTemplateContextProvider>
      )}
    </div>
  );
};

function NewStageButton() {
  const { journeyTemplateId } = useParams();
  const addStageTemplateMutation = useAddStageTemplate();
  const [newStageName, setNewStageName] = useState("");

  return (
    <Dialog>
      <DialogTrigger asChild>
        <NewButton
          className="h-10 space-x-1 px-5 text-gray-400"
          variant={"secondary"}
        >
          <PlusIcon className="h-4 w-4" />
          <div>Add Stage</div>
        </NewButton>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>New Stage</DialogTitle>
          <DialogDescription>Deatails for your new stage</DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="space-y-1">
            <InputHeading heading="Name" />
            <TextInput
              className="max-w-[300px]"
              value={newStageName}
              onChange={(e) => {
                setNewStageName(e.target.value);
              }}
            />
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <NewButton
              disabled={!newStageName}
              onClick={() => {
                addStageTemplateMutation.mutate({
                  params: {
                    name: newStageName,
                    journeyTemplateId: new ObjectID(journeyTemplateId),
                  },
                });
              }}
            >
              Create
            </NewButton>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function StageTemplate({
  stage,
  setSelectedStage,
}: {
  stage: StageTemplateObj;
  setSelectedStage: (stage: StageTemplateObj) => void;
}) {
  const { data: taskTemplates } = useGetStageTaskTemplates(
    stage.journeyTemplateId,
    stage._id
  );
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: stage._id.toString(),
    });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };
  const updateStageTemplateMutation = useUpdateJourneyStageTemplate();

  const handleDragEnd = (event: DragEndEvent) => {
    const { over, active } = event;
    if (over && active) {
      const { id: overId } = over;
      const { id: activeId } = active;
      if (overId !== activeId && taskTemplates && !!stage.taskTemplateOrder) {
        const oldIndex = stage.taskTemplateOrder.findIndex(
          (tid) => tid?.toString() === activeId
        );
        const newIndex = stage.taskTemplateOrder.findIndex(
          (tid) => tid?.toString() === overId
        );
        const newTaskTemplateOrder = arrayMove(
          stage.taskTemplateOrder,
          oldIndex,
          newIndex
        );
        updateStageTemplateMutation.mutate({
          journeyTemplateId: stage.journeyTemplateId,
          params: {
            _id: stage._id,
            taskTemplateOrder: newTaskTemplateOrder,
          },
        });
      }
    }
  };

  return (
    <div className="space-y-2" ref={setNodeRef} style={style} {...attributes}>
      <div className="flex items-center justify-between">
        <div className="group relative flex w-full cursor-default items-center">
          {/* <button
          className="rounded-md border border-gray-300 px-2 hover:bg-gray-50"
          onClick={() => {
            setSelectedStage(stage);
          }}
        >
          <PlusIcon className="h-3 w-3 font-bold text-gray-500" />
        </button> */}
          {/* text=""
          leftIcon={
            
          }
          size="xs"
          color={"gray"}
          fill="outline"
          
        /> */}
          <div className="absolute right-full cursor-move pr-2">
            <div
              className="rounded-md p-1 transition-colors hover:bg-gray-100"
              {...listeners}
            >
              <DragHandleDots2Icon className="h-5 w-5 text-transparent outline-none ring-0 transition-colors focus:outline-none active:text-primary-main group-hover:text-gray-400 group-hover:delay-200" />
            </div>
          </div>
          <StageTemplateName stage={stage} />
        </div>
        <StageOptions stage={stage} />
      </div>
      {/* <div className="flex px-4 space-x-6 text-sm text-gray-400 pl-12">
        <div className="flex-1">Task</div>
        <div className="w-52">Due Date</div>
        <div className="w-24">Duration</div>
        <div className="w-36">Role</div>
      </div> */}

      <div
        className={clsx(
          "min-w-full cursor-default rounded-md border border-gray-300 transition-transform"
        )}
      >
        <div className="flex h-11 items-center space-x-6 rounded-t-md border-b border-gray-300 bg-gray-50 px-4 pl-8 text-xs font-medium tracking-wide text-gray-500">
          <div className="min-w-[212px] flex-1 uppercase">Task</div>
          <div className="w-52 shrink-0 uppercase">Relative Due Date</div>
          <div className="w-28 shrink-0 uppercase">Days</div>
          <div className="w-28 shrink-0 uppercase">
            <span>Duration (Mins)</span>
          </div>
          <div className="w-36 shrink-0 uppercase">Role</div>
          <div className="w-20 shrink-0 uppercase">Health</div>
        </div>
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          {!!stage.taskTemplateOrder && stage.taskTemplateOrder?.length > 0 && (
            <SortableContext
              items={stage.taskTemplateOrder?.map((t) => t.toString())}
              strategy={verticalListSortingStrategy}
            >
              {taskTemplates &&
                stage.taskTemplateOrder?.map((t) => {
                  const taskTemplate = taskTemplates.find(
                    (tt) => tt._id?.toString() === t.toString()
                  );
                  if (taskTemplate && !taskTemplate.parentTaskTemplate) {
                    return (
                      <TaskTemplate
                        key={taskTemplate._id?.toString() ?? Math.random()}
                        taskTemplate={taskTemplate}
                        isSubTask={false}
                        taskTemplateId={
                          taskTemplate._id?.toString() ??
                          Math.random().toString()
                        }
                      />
                    );
                  }
                  return null;
                })}
              {/* {taskTemplates
              ?.filter((t) => !t.parentTaskTemplate)
              .map((t) => (
                <TaskTemplate
                  key={t._id?.toString() ?? Math.random()}
                  taskTemplate={t}
                  isSubTask={false}
                />
              ))} */}
            </SortableContext>
          )}
        </DndContext>
        <div
          className="flex cursor-pointer rounded-b-md py-2.5 hover:bg-gray-50"
          onClick={() => {
            setSelectedStage(stage);
          }}
        >
          <div className="flex items-center space-x-2 pl-7 text-gray-400">
            <PlusIcon className="h-4 w-4 text-gray-400" />
            <span className="text-sm">Add task</span>
          </div>
        </div>

        {/* {taskTemplates?.length === 0 && (
          <div className="flex border-t py-3">
            <div className="text-gray-400">No tasks</div>
          </div>
        )} */}
      </div>
    </div>
  );
}

function StageOptions({ stage }: { stage: StageTemplateObj }) {
  const deleteStageTemplateConfirmationMutation =
    useDeleteStageTemplateConfirmation();

  const [deletions, setDeletions] = useState<
    { _id: ObjectID; title: string }[]
  >([]);
  const [dependencies, setDependencies] = useState<
    { _id: ObjectID; title: string }[]
  >([]);

  useEffect(() => {
    if (deleteStageTemplateConfirmationMutation.data) {
      setDeletions(deleteStageTemplateConfirmationMutation.data.deleted);
      setDependencies(
        deleteStageTemplateConfirmationMutation.data.dependencies
      );
    }
  }, [deleteStageTemplateConfirmationMutation.data]);

  const [isDeleteStageConfirmationOpen, setIsDeleteStageConfirmationOpen] =
    useState(false);

  const deleteStageTemplateMutation = useDeleteStageTemplate();

  return (
    <>
      <Button
        variant={"ghost"}
        className="p-1.5 px-2"
        onClick={() => {
          if (stage._id)
            deleteStageTemplateConfirmationMutation.mutate({
              params: { _id: stage._id, force: false },
              journeyTemplateId: stage.journeyTemplateId,
            });
          setIsDeleteStageConfirmationOpen(true);
        }}
      >
        <TrashIcon className="h-4 w-4 text-gray-400" />
      </Button>

      {/* <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant={"ghost"} className="p-1.5 px-2">
            <DotsVerticalIcon className="h-4 w-4 text-gray-400" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-40">
          <DropdownMenuGroup>
            <DropdownMenuItem
              onClick={() => {
                if (stage._id) {
                  deleteStageTemplateConfirmationMutation.mutate({
                    params: { _id: stage._id, force: false },
                    journeyTemplateId: stage.journeyTemplateId,
                  });
                  setIsDeleteStageConfirmationOpen(true);
                }
              }}
            >
              Delete
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu> */}
      <Dialog
        open={isDeleteStageConfirmationOpen}
        onOpenChange={(open) => {
          setIsDeleteStageConfirmationOpen(open);
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Confirmation</DialogTitle>
            {/* <DialogDescription>
              Confirm deletion of stage template
            </DialogDescription> */}
          </DialogHeader>
          <div className="space-y-5 pt-3 text-sm text-gray-600">
            <div>
              Are you sure you want to delete stage template{" "}
              <span className="font-medium text-gray-900">{stage.name}</span>?
            </div>
            {deletions.length > 0 && (
              <div className="space-y-1">
                <div>
                  <span>The following tasks will also be deleted -</span>
                </div>
                <ul className="list-disc space-y-1 pl-6">
                  {deletions.map((d) => (
                    <li key={d._id.toString()}>{d.title}</li>
                  ))}
                </ul>
              </div>
            )}
            {dependencies.length > 0 && (
              <div className="space-y-1">
                <div>
                  <span>The following tasks that used to be dependent on </span>
                  <span className="font-medium text-gray-900">
                    {stage.name}
                  </span>{" "}
                  <span>will now be dependent on </span>
                  <span className="font-medium text-gray-900">
                    {"Project Start -"}
                  </span>
                </div>
                <ul className="list-disc space-y-1 pl-6">
                  {dependencies.map((d) => (
                    <li key={d._id.toString()}>{d.title}</li>
                  ))}
                </ul>
              </div>
            )}
            <div></div>
          </div>
          <DialogFooter>
            <DialogClose asChild className="">
              <div className="flex justify-end space-x-3">
                <NewButton
                  variant={"secondary"}
                  onClick={() => {
                    setIsDeleteStageConfirmationOpen(false);
                  }}
                >
                  Cancel
                </NewButton>
                <NewButton
                  variant={"destructive"}
                  onClick={() => {
                    if (stage?._id)
                      deleteStageTemplateMutation.mutate({
                        params: {
                          _id: stage?._id,
                          force: true,
                        },
                        journeyTemplateId: stage.journeyTemplateId,
                      });
                  }}
                >
                  Delete
                </NewButton>
              </div>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}

function StageTemplateName({ stage }: { stage: StageTemplateObj }) {
  const [stageName, setStageName] = useState(stage.name);
  const updateStageTemplateMutation = useUpdateJourneyStageTemplate();
  return (
    <div className="flex w-full items-center space-x-2">
      <input
        value={stageName}
        onChange={(e) => {
          setStageName(e.target.value);
        }}
        className="mr-2 w-full border-b border-transparent text-lg font-medium outline-none transition-colors focus:border-primary group-hover:border-primary group-hover:delay-200"
        onBlur={() => {
          if (stageName === "") {
            setStageName(stage.name);
          } else if (stage.name.trim() !== stageName.trim()) {
            updateStageTemplateMutation.mutate({
              params: {
                _id: stage._id,
                name: stageName,
              },
              journeyTemplateId: stage.journeyTemplateId,
            });
          }
        }}
      />
    </div>
  );
}

function TaskTemplate({
  taskTemplate,
  isSubTask,
  taskTemplateId,
}: {
  taskTemplate: TaskTemplateData;
  isSubTask: boolean;
  taskTemplateId: string;
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const journeyTemplateCtx = useJourneyTemplate();
  const taskTemplatePanelCtx = useTaskTemplatePanelContext();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: taskTemplateId,
    });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };
  const [subTaskTemplateDurations, setSubTaskTemplateDurations] = useState(0);
  return (
    <div
      className={clsx(
        "relative w-full bg-white first:border-t last:border-b-0",
        taskTemplate.tasker?.type === "external" && "",
        !isSubTask && "border-b"

        // isSubTask && ""
        // taskTemplate.tasker?.type === "external"
        //   ? "bg-slate-200 hover:bg-slate-300"
        //   : "bg-white hover:bg-gray-50 "
      )}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <div
        className={clsx(
          "group flex h-full w-full max-w-full cursor-pointer items-center space-x-1 py-2 pr-4 pl-1 hover:bg-gray-50",
          isSubTask && "pl-14",
          taskTemplate.tasker?.type === "external"
            ? "bg-white hover:bg-gray-50"
            : "bg-white hover:bg-gray-50"
        )}
        onClick={() => {
          if (taskTemplate._id)
            taskTemplatePanelCtx.dispatch({
              type: "TASK_TEMPLATE_PANEL_UPDATE",
              taskTemplatePanel: {
                id: taskTemplate._id.toString(),
                isOpen: true,
              },
            });
        }}
      >
        <div className="flex h-full items-center space-x-1">
          {isSubTask && <div className="w-1" />}
          <div className="absolute right-full flex h-full items-center pr-1.5 text-transparent hover:text-gray-400 group-hover:text-gray-400">
            <div
              {...listeners}
              className="cursor-move rounded-md p-1.5 transition-colors hover:bg-gray-100"
            >
              <DragHandleDots2Icon
                className={clsx(
                  "h-4 w-4 outline-none ring-0 focus:outline-none active:text-primary-main"
                )}
              />
            </div>
          </div>
          {taskTemplate.subTaskTemplateCount ? (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(!isExpanded);
              }}
              className={clsx(
                "group h-full rounded-md px-1 py-1 hover:bg-gray-200"
                // taskTemplate.tasker?.type === "external"
                //   ? "hover:bg-slate-300 group-hover:text-slate-700"
                //   : "hover:bg-gray-200 group-hover:text-gray-700"
              )}
            >
              <ChevronRightIcon
                className={clsx(
                  "h-3 w-3 text-gray-500 transition duration-150 ease-in-out",
                  isExpanded && "rotate-90"
                )}
              />
            </button>
          ) : (
            <>{!isSubTask && <div className="w-5" />}</>
          )}
          {/* <>
            {taskTemplate.tasker?.type === "external" ? (
              <ToolTip title="External task">
                <div className={clsx("h-2 w-2 rounded-full bg-slate-700")} />
              </ToolTip>
            ) : (
              <ToolTip title="Internal task">
                <div className={clsx("h-2 w-2 rounded-full bg-gray-300")} />
              </ToolTip>
            )}
          </> */}
        </div>
        {/* {taskTemplate.action === "default" && (
          <div className="w-5">
            <div
              className={clsx(
                "h-[14px] w-[14px] rounded-full border-[1.5px] border-gray-400",
                // taskTemplate.tasker?.type === "external"
                //   ? "border-slate-500"
                //   : "border-gray-400"
              )}
            />
          </div>
        )} */}
        {taskTemplate.action === "form" && (
          <div className="w-5">
            <DocumentTextIcon
              className={clsx(
                "h-[18px] w-[18px] text-gray-400"
                // taskTemplate.tasker?.type === "external"
                //   ? "text-slate-500"
                //   : "text-gray-400"
              )}
              viewBox="3 0 24 24"
            />
          </div>
        )}
        {taskTemplate.action === "meeting" && (
          <div className="w-5">
            <PhoneIcon
              className={clsx(
                "h-[20px] w-[20px] text-gray-400"
                // taskTemplate.tasker?.type === "external"
                //   ? "text-slate-500"
                //   : "text-gray-400"
              )}
              viewBox="1 -2 24 24"
            />
          </div>
        )}
        {taskTemplate.action === "fileUpload" && (
          <div className="w-5">
            <CloudArrowUpIcon
              className={clsx(
                "h-[20px] w-[20px] text-gray-400"
                // taskTemplate.tasker?.type === "external"
                //   ? "text-slate-500"
                //   : "text-gray-400"
              )}
              viewBox="1 -2 24 24"
            />
          </div>
        )}
        <div
          className={clsx(
            "flex-1 truncate text-sm text-gray-700",
            isSubTask ? "min-w-[100px]" : "min-w-[172px]"
          )}
        >
          {taskTemplate.title}
        </div>
        <div className="flex w-14 shrink-0 justify-end space-x-2 pr-4">
          {/* {taskTemplate.knowledgeResourceTemplates?.length && !isSubTask && (
            <ToolTip title="Knowledge resource">
              <BookOpenIcon
                className={clsx(
                  taskTemplate.tasker?.type === "external"
                    ? "text-slate-400/80"
                    : "text-gray-300",
                  "h-4 w-4 text-gray-300"
                )}
              />
            </ToolTip>
          )} */}
          {!taskTemplate.isVisible && (
            <ToolTip title="Externally hidden">
              <EyeSlashIcon
                className={clsx(
                  // taskTemplate.tasker?.type === "external"
                  //   ? "text-slate-400/80"
                  //   : "text-gray-300",
                  "h-4 w-4 text-gray-400"
                )}
              />
            </ToolTip>
          )}
        </div>
        <div className="flex items-center space-x-6">
          <div className="w-52 truncate text-sm text-gray-500">
            {!isSubTask && (
              <>
                <span>{"Due "}</span>
                <span className="font-medium text-gray-700">
                  {taskTemplate.relativeDueDate.value}{" "}
                </span>
                <span className="font-medium text-gray-700">
                  {taskTemplate.relativeDueDate.unit}
                  {taskTemplate.relativeDueDate.value > 1 ? "s" : ""}
                </span>
                <span>{" after "}</span>
                {!taskTemplate.dependentOn.name && <span>{"start"}</span>}
                {taskTemplate.dependentOn.name && (
                  <span className="font-medium text-gray-700">
                    {taskTemplate.dependentOn.name}
                  </span>
                )}
              </>
            )}
          </div>
          <div className="w-28">
            <TimeSinceStart ms={taskTemplate.durations?.planned} />
          </div>
          <div className="w-28">
            <span
              className={clsx(
                "text-sm",
                isSubTask ? "font-light text-gray-500" : "text-gray-700"
              )}
            >
              {taskTemplate.subTaskTemplateCount ? (
                <SubTaskTemplatesDuration
                  totalMinutes={subTaskTemplateDurations}
                />
              ) : (
                <>
                  <span>
                    {taskTemplate.estimate.unit === "hour"
                      ? taskTemplate.estimate.value * 60
                      : taskTemplate.estimate.value}{" "}
                  </span>
                  {/* <span className="text-xs text-gray-500">
                    {taskTemplate.estimate.unit === "hour" ? "min" : "min"}
                  </span> */}
                </>
              )}
            </span>
          </div>
          <div className="w-36">
            <div
              className={clsx(
                "w-fit max-w-full truncate rounded-full border px-2 py-1 text-xs",
                taskTemplate.tasker && taskTemplate.tasker.type === "external"
                  ? "border-slate-700 bg-slate-600 text-white"
                  : "border-gray-500 bg-white text-gray-500"
              )}
            >
              {taskTemplate.tasker?.persona}
            </div>
          </div>

          <div className="w-20">
            <TaskTemplateHealth taskTemplate={taskTemplate} />
          </div>
        </div>
      </div>
      {taskTemplate.subTaskTemplateCount && !!taskTemplate._id && (
        <SubTaskTemplates
          taskTemplate={taskTemplate}
          taskTemplateId={taskTemplate._id}
          isExpanded={isExpanded}
          setSubTaskTemplateDurations={setSubTaskTemplateDurations}
        />
      )}
    </div>
  );
}

function TaskTemplateHealth({
  taskTemplate,
}: {
  taskTemplate: TaskTemplateData;
}) {
  const descriptionLength = htmlCharLength(taskTemplate.description);
  // return <>{descriptionLength}</>;
  return (
    <HoverCard openDelay={200} closeDelay={200}>
      <HoverCardTrigger asChild>
        <div
          className={clsx(
            "ml-[18px] h-3 w-3 rounded",
            descriptionLength < 150 && "bg-red-500",
            descriptionLength >= 150 &&
              descriptionLength < 250 &&
              "bg-amber-500",
            descriptionLength >= 250 && "bg-green-500"
          )}
        />
      </HoverCardTrigger>
      <HoverCardContent className="max-w-xs" align="end">
        <div className="text-sm">
          {descriptionLength < 150 &&
            `The description for this task is ${descriptionLength} characters long. We recommend having longer descriptions that help taskers understand the task better.`}
          {descriptionLength >= 150 &&
            descriptionLength < 250 &&
            `The description for this task is ${descriptionLength} characters long. We recommend having longer descriptions that help taskers understand the task better.`}

          {descriptionLength >= 250 &&
            `The description for this task is ${descriptionLength} characters long. Descriptions of this length help taskers understand the task better! Great job!`}
        </div>
      </HoverCardContent>
    </HoverCard>
  );
}

function TimeSinceStart({ ms }: { ms: number | undefined }) {
  if (!ms) {
    return (
      <div>
        <Loading className="ml-0 h-4 w-4 border-2" />
      </div>
    );
  }
  const minutes = Math.floor(ms / 60000);
  const hours = Math.floor(minutes / 60);
  const days = hours / 24;
  if (days < 1) {
    return (
      <div className="text-sm">{roundUpToNearestTenth(days).toFixed(1)}</div>
    );
  }
  return <div className="text-sm">{days.toFixed(0)}</div>;
}

function roundUpToNearestTenth(num: number): number {
  return Math.ceil(num * 10) / 10;
}

function htmlCharLength(html: string): number {
  return html.replace(/<[^>]*>/g, "").length;
}

function SubTaskTemplatesDuration({ totalMinutes }: { totalMinutes: number }) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return (
    <div className="text-sm">
      <span>{totalMinutes}</span>
      {/* <span className="text-xs text-gray-500"> min</span> */}
    </div>
  );

  if (totalMinutes > 0) {
    if (minutes === 0) {
      return <div className="text-sm">{`${hours} h`}</div>;
    } else {
      if (hours === 0) {
        return <div className="text-sm">{`${minutes} m`}</div>;
      }
      return <div className="text-sm">{`${hours} h ${minutes} m`}</div>;
    }
  } else {
    return <div className="text-sm">{`0 h`}</div>;
  }
}

function SubTaskTemplates({
  taskTemplate,
  taskTemplateId,
  isExpanded,
  setSubTaskTemplateDurations,
}: {
  taskTemplate: TaskTemplateData;
  taskTemplateId: ObjectID;
  isExpanded: boolean;
  setSubTaskTemplateDurations: React.Dispatch<React.SetStateAction<number>>;
}) {
  // console.log(taskTemplate);
  const { subTaskTemplates } = useGetSubTaskTemplates(taskTemplateId);
  const updateTaskTemplateMutation = useUpdateTaskTemplate();
  const handleDragEnd = (event: DragEndEvent) => {
    const { over, active } = event;
    if (over && active) {
      const { id: overId } = over;
      const { id: activeId } = active;
      if (overId !== activeId && taskTemplate.subTaskTemplateOrder) {
        const oldIndex = taskTemplate.subTaskTemplateOrder.findIndex(
          (tid) => tid?.toString() === activeId
        );
        const newIndex = taskTemplate.subTaskTemplateOrder.findIndex(
          (tid) => tid?.toString() === overId
        );
        const newSubTaskTemplateOrder = arrayMove(
          taskTemplate.subTaskTemplateOrder,
          oldIndex,
          newIndex
        );
        updateTaskTemplateMutation.mutate({
          journeyTemplateId: taskTemplate.journeyTemplate._id,
          journeyStageTemplateId: taskTemplate.journeyStageTemplate._id,
          params: {
            _id: taskTemplate._id,
            subTaskTemplateOrder: newSubTaskTemplateOrder,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (subTaskTemplates) {
      let durations = 0;
      subTaskTemplates.forEach((st) => {
        durations += estimateToMinutes(st.estimate);
      });
      setSubTaskTemplateDurations(durations);
    }
  }, [subTaskTemplates]);

  return (
    <>
      {taskTemplate.subTaskTemplateOrder && isExpanded && (
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={taskTemplate.subTaskTemplateOrder?.map((t) => t.toString())}
            strategy={verticalListSortingStrategy}
          >
            {taskTemplate.subTaskTemplateOrder.map((t) => {
              const subTaskTemplate = subTaskTemplates?.find(
                (tt) => tt._id?.toString() === t.toString()
              );
              if (subTaskTemplate) {
                return (
                  <TaskTemplate
                    key={subTaskTemplate._id?.toString() ?? Math.random()}
                    taskTemplate={subTaskTemplate}
                    isSubTask={true}
                    taskTemplateId={
                      subTaskTemplate._id?.toString() ??
                      Math.random().toString()
                    }
                  />
                );
              }
              return null;
            })}
            {/* {isExpanded && (
          <div className="">
            {subTaskTemplates?.map((st) => (
              <TaskTemplate
                key={st._id?.toString() ?? Math.random()}
                taskTemplate={st}
                isSubTask={true}
                taskTemplateId={st._id?.toString() ?? Math.random().toString()}
              />
            ))}
          </div>
        )} */}
          </SortableContext>
        </DndContext>
      )}
    </>
  );
}

// const TemplateStage = ({
//   stage,
//   stageId,
//   onRowClick,
//   setSelectedStage,
// }: {
//   stage: StageTemplateObj;
//   stageId: ObjectID;
//   onRowClick: (taskTemplate: TaskTemplateData) => void;
//   setSelectedStage: (stage: StageTemplateObj) => void;
// }) => {
//   const { taskTemplates } = useGetTaskTemplates(
//     stage.journeyTemplateId,
//     stageId
//   );

//   const { attributes, listeners, setNodeRef, transform } = useDraggable({
//     id: stageId.toString(),
//   });

//   const style = transform
//     ? {
//         transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
//       }
//     : undefined;

//   const Drag = () => {
//     return (
//       <>
//         <Bars3Icon
//           className="invisible absolute h-4 w-4 text-gray-500 delay-200 group-hover:visible group-hover:relative"
//           {...attributes}
//           {...listeners}
//         />
//       </>
//     );
//   };

//   return (
//     <div className="w-fit space-y-4" ref={setNodeRef} style={style}>
//       {taskTemplates?.length === 0 ? (
//         <div className="inline-flex items-center space-x-2">
//           <div className="group inline-flex items-center space-x-2">
//             {/* <Drag /> */}
//             <div
//               className="text-md font-semibold text-gray-600"
//               {...attributes}
//               {...listeners}
//             >
//               {stage.name}
//             </div>
//           </div>

//           <Badge text="No tasks yet" color={"gray"} />
//           <Button
//             text=""
//             leftIcon={
//               <PlusIcon className="-mx-0.5 h-3 w-3 font-bold text-primary-main" />
//             }
//             size="xs"
//             fill="outline"
//             onClick={() => {
//               setSelectedStage(stage);
//             }}
//           />
//         </div>
//       ) : (
//         <div className="flex justify-between">
//           <div className="group inline-flex items-center">
//             {/* <Drag /> */}

//             <div
//               className="text-md font-semibold text-gray-600 hover:cursor-move"
//               {...attributes}
//               {...listeners}
//             >
//               {stage.name}
//             </div>
//           </div>

//           <Button
//             text=""
//             leftIcon={
//               <PlusIcon className="-mx-0.5 h-3 w-3 font-bold text-primary-main" />
//             }
//             size="xs"
//             fill="outline"
//             onClick={() => {
//               setSelectedStage(stage);
//             }}
//           />
//         </div>
//       )}
//       {!!taskTemplates && taskTemplates.length > 0 && (
//         <TaskTemplatesTable
//           taskTemplates={taskTemplates}
//           onRowClick={onRowClick}
//         />
//       )}
//     </div>
//   );
// };

export const TaskTemplatesTable = ({
  taskTemplates,
  onRowClick,
  isSubTasks = false,
}: {
  taskTemplates: TaskTemplateData[];
  onRowClick: (taskTemplate: TaskTemplateData) => void;
  isSubTasks?: boolean;
}) => {
  // console.log("taskTemplates", taskTemplates);
  const columnHelper = createColumnHelper<TaskTemplateData>();
  const columns: ColumnDef<TaskTemplateData>[] = [
    {
      id: "taskType",
      accessorFn: (row: TaskTemplateData) => {
        return row.tasker?.type === "internal" ? "int" : "ext";
      },
      cell: ({ row }) => (
        <>
          {row.original.tasker?.type === "external" ? (
            <ToolTip title="External task">
              <div className={clsx("h-2 w-2 rounded-full bg-primary-main")} />
            </ToolTip>
          ) : (
            <ToolTip title="Internal task">
              <div className={clsx("h-2 w-2 rounded-full bg-gray-300")} />
            </ToolTip>
          )}
        </>
      ),
      header: () => <span></span>,
      size: 30,
    },
    // columnHelper.accessor("title", {
    //   cell: (info) => (
    //     <div className="flex items-center justify-between space-x-2">
    //       <div className="truncate">{info.getValue()}</div>
    //       <div className="inline-flex items-center space-x-2 px-2">
    //         {info.row.original.tags.map((tag) => (
    //           <Badge
    //             text={tag.text}
    //             color={tag.color}
    //             clickable={false}
    //             size="md"
    //           />
    //         ))}
    //         {info.row.original.dependentOn &&
    //           info.row.original.dependentOn.type !== "default" && (
    //             <ToolTip title="Dependency">
    //               <ArrowPathIcon className="h-4 w-4 text-gray-300" />
    //             </ToolTip>
    //           )}
    //         {info.row.original.isVisible && (
    //           <ToolTip title="Visible to customer">
    //             <EyeIcon className="h-4 w-4 text-gray-300" />
    //           </ToolTip>
    //         )}
    //         {info.row.original.knowledgeResourceTemplates?.length && (
    //           <ToolTip title="Knowledge resource">
    //             <BookOpenIcon className="h-4 w-4 text-gray-300" />
    //           </ToolTip>
    //         )}

    //         <div></div>
    //       </div>
    //     </div>
    //   ),
    //   header: () => <span>Task</span>,
    //   size: 600,
    // }),
    {
      id: "title",
      accessorFn: (row: TaskTemplateData) => {
        return row.title;
      },
      cell: ({ row, cell }) => (
        <div className="flex items-center justify-between space-x-2">
          <div className="flex space-x-2 truncate">
            <div className="flex-initial truncate">
              {String(cell.getValue())}
            </div>
            {row.original.parentTaskTemplate && (
              <>
                <div className="text-gray-400">{">"}</div>
                <div className="flex-initial truncate text-gray-400">
                  {row.original.parentTaskTemplate.title}
                </div>
              </>
            )}
          </div>
          <div className="inline-flex items-center space-x-2 px-2">
            {row.original.subTaskTemplateCount && (
              <div>
                <ToolTip
                  title={`${row.original.subTaskTemplateCount} Subtasks`}
                  // title="Subtasks"
                >
                  <div className="inline-flex items-center space-x-1 rounded-md border border-primary-main py-0.5 px-1 text-primary-main">
                    <div className="text-xs">
                      {row.original.subTaskTemplateCount}
                    </div>
                    <BoltIcon className="h-3 w-3" />
                  </div>
                </ToolTip>
              </div>
            )}
            {row.original.tags.map((tag) => (
              <Badge
                key={tag.text}
                text={tag.text}
                color={tag.color}
                clickable={false}
                size="md"
              />
            ))}
            {row.original.dependentOn &&
              row.original.dependentOn.type !== "default" && (
                <ToolTip title="Dependency">
                  <ArrowPathIcon className="h-4 w-4 text-gray-300" />
                </ToolTip>
              )}
            {row.original.isVisible && (
              <ToolTip title="Visible to customer">
                <EyeIcon className="h-4 w-4 text-gray-300" />
              </ToolTip>
            )}
            {row.original.knowledgeResourceTemplates?.length && (
              <ToolTip title="Knowledge resource">
                <BookOpenIcon className="h-4 w-4 text-gray-300" />
              </ToolTip>
            )}

            <div></div>
          </div>
        </div>
      ),
      header: () => <span>{isSubTasks ? "Subtask" : "Task"}</span>,
      size: 600,
    },
    {
      id: "dueDate",
      accessorFn: (row: TaskTemplateData) => {
        return (
          row.relativeDueDate.value +
          " " +
          row.relativeDueDate.unit +
          (row.relativeDueDate.value == 1 ? "" : "s")
        );
      },
      header: () => <span>Due Date</span>,
      size: 120,
    },
    {
      id: "dependentOn",
      // accessorFn: (row: TaskTemplateData) => {
      //   return row.dependentOn?.name;
      // },
      cell: ({ row }) => (
        <div className="truncate">{row.original.dependentOn?.name ?? ""}</div>
      ),
      header: () => <span>Dependent On</span>,
      size: 200,
    },
    {
      id: "estimate",
      header: () => <span>Duration</span>,
      // accessorFn: (row: TaskTemplateData) => {
      //   return row.estimate;
      // },
      cell: ({ row }) => (
        <div className="truncate">
          {row.original.estimate.value +
            " " +
            `${
              row.original.estimate.unit +
              (row.original.estimate.value === 1 ? "" : "s")
            }`}
        </div>
      ),
    },
    {
      id: "role",
      accessorFn: (row: TaskTemplateData) => {
        return row.tasker?.persona;
      },
      cell: ({ cell, row }) => (
        <>
          {row.original.tasker?.type && (
            <RoleBadge
              persona={String(cell.getValue())}
              taskerType={row.original.tasker?.type}
            />
          )}
        </>
        // <div
        //   className={clsx(
        //     "max-w-fit truncate rounded-full border py-1 px-2 text-xs",
        //     info.cell.row.original.tasker?.type === "internal"
        //       ? "border-gray-400 text-gray-400"
        //       : "border-primary-main text-primary-main"
        //   )}
        // >
        //   {info.getValue()}
        // </div>
      ),
      header: () => <span>Role</span>,
      size: 150,
    },
  ];

  const table = useReactTable({
    data: taskTemplates,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div></div>
      <div className="w-fit border shadow md:rounded-sm">
        <table className="divide-y divide-gray-300">
          <thead className="bg-gray-50">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    scope="col"
                    className="text-ellipsis px-2 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 last:sm:pr-4"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {table.getRowModel().rows.map((row) => (
              <>
                {!row.original.parentTaskTemplate && (
                  <tr
                    key={row.id}
                    className="hover:cursor-pointer hover:bg-gray-50"
                    onClick={() => {
                      onRowClick(row.original);
                    }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      // console.log(cell.row.original._id?.toString());
                      return (
                        <td
                          key={cell.id}
                          // style={{
                          //   ...(cell.column.id === "title"
                          //     ? { minWidth: 600 }
                          //     : {
                          //         width: cell.column.getSize(),
                          //         maxWidth: cell.column.getSize(),
                          //         minWidth: cell.column.getSize(),
                          //       }),
                          // }}
                          style={{
                            width: cell.column.getSize(),
                            maxWidth: cell.column.getSize(),
                          }}
                          className="min-w-0 py-2 pl-2 pr-6 text-sm text-gray-500 first:font-medium first:text-gray-900 first:sm:pl-4 first:sm:pr-2 last:sm:pr-4"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export const RoleBadge = ({
  taskerType,
  persona,
}: {
  taskerType: TaskerType;
  persona: string;
}) => {
  return (
    <div
      className={clsx(
        "max-w-fit cursor-pointer truncate rounded-full border py-1 px-2 text-xs",
        taskerType === "internal"
          ? "border-gray-400 text-gray-400"
          : "border-primary-main text-primary-main"
      )}
    >
      {persona}
    </div>
  );
};
