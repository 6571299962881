import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import React, { Fragment } from "react";
import { classNames } from "src/utils/functions/classes";

type Props<T> = {
  selected: T | undefined;
  options: T[];
  onChange: (value: T) => void;
  keyFn: (option: T) => string;
  displayFn: (option: T, active?: boolean) => React.ReactNode;
  disabled?: boolean;
  isEmbed?: boolean;
  by?: (o1: T, o2: T) => boolean;
};

const Select = <T,>({
  selected,
  options,
  onChange,
  keyFn,
  displayFn,
  disabled = false,
  isEmbed = false,
  by,
}: Props<T>) => {

  return (
    <Listbox value={selected} onChange={onChange} disabled={disabled} by={by}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button
              className={clsx(
                "relative w-full cursor-pointer rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm  focus:outline-none focus:ring-1  disabled:bg-gray-100 sm:text-sm",
                isEmbed
                  ? "focus:border-embedPrimary focus:ring-embedPrimary"
                  : "focus:border-primary-main focus:ring-primary-main"
              )}
            >
              <span className="block truncate whitespace-pre">
                {!!selected ? displayFn(selected) : " "}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.length === 0 && (
                  <Listbox.Option
                    key={"No options"}
                    className={({ active }) =>
                      classNames(
                        "relative mx-1 cursor-default select-none rounded-md py-2 pl-3 pr-9 text-gray-400"
                      )
                    }
                    value={"No options"}
                  >
                    <>
                      <span
                        className={classNames("font-normal", "block truncate")}
                      >
                        {"No options"}
                      </span>
                    </>
                  </Listbox.Option>
                )}
                {options.map((option) => (
                  <Listbox.Option
                    key={keyFn(option)}
                    className={({ active }) =>
                      classNames(
                        // active
                        //   ? isEmbed
                        //     ? "bg-embedPrimary text-white"
                        //     : "bg-primary-main text-white"
                        //   : "text-gray-900",
                        active ? "bg-gray-100" : "text-gray-900",
                        "relative mx-1 cursor-pointer select-none rounded-md py-2 pl-3 pr-9"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {displayFn(option, active)}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              isEmbed
                                ? "text-embedPrimary"
                                : "text-primary-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-4 w-4" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default Select;
