import {
  BoltIcon,
  DocumentTextIcon,
  EyeIcon,
  EyeSlashIcon,
  MagnifyingGlassIcon,
  PaperClipIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Link2Icon } from "@radix-ui/react-icons";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FileResourceData,
  FormResourceData,
  LinkResourceData,
  ResourceData,
  useCreatePhaseLinkResource,
  useDeleteResource,
  useGetPhaseResources,
  useUpdatePhaseResource,
} from "src/api/Services/SharedSpace/files";
import { useUploadPhaseFile } from "src/api/Services/SharedSpace/phases";
import { useGetPhaseTasks } from "src/api/Services/Tasks/tasks";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import { Loading } from "src/components/ui/loading";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
} from "src/components/ui/select";
import { TextInput } from "src/components/ui/text-input";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "src/components/ui/tooltip";
import { useResourcesContext } from "./resources-context-provider";

type Props = {
  phaseId: string;
};

export default function PhaseResources({ phaseId }: Props) {
  const resourcesCtx = useResourcesContext();
  const { data: resources } = useGetPhaseResources(phaseId);
  const [search, setSearch] = useState("");
  const viewableResources =
    (resourcesCtx.state.isExternal
      ? resources?.filter((r) =>
          r.type === "form" ? r.task.isVisible : r.isVisible
        )
      : resources) ?? [];

  const filteredResources = viewableResources.filter((r) =>
    r.name.toLowerCase().includes(search.toLowerCase())
  );
  const [selectedResource, setSelectedResource] = useState<ResourceData | null>(
    null
  );
  const { data: tasks } = useGetPhaseTasks(phaseId);

  return (
    <>
      {!!resources ? (
        <>
          {viewableResources.length > 0 ? (
            <>
              <div className="flex h-20 items-center justify-between border-b px-8">
                <div className="relative">
                  <TextInput
                    className="w-72 pl-10"
                    placeholder="Search.."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
                  </div>
                </div>
                <div>
                  <AddResourceButton phaseId={phaseId} />
                  {/* {resourcesCtx.state.isExternal ?  */}
                </div>
              </div>
              <div className="border-b text-sm">
                <div className="flex h-11 justify-between gap-6 bg-gray-50 px-8 text-gray-500">
                  <div className="flex flex-1 items-center space-x-8">
                    <div className="w-10">Type</div>
                    <div className="min-w-[160px] flex-1">Resource</div>
                  </div>
                  <div className="flex items-center space-x-4">
                    {!resourcesCtx.state.isExternal && (
                      <div className="flex w-32 flex-col items-center">
                        Visibility
                      </div>
                    )}
                    <div className="w-40">Created By</div>
                    <div className="w-32">Created At</div>
                    {/* <div className="w-20">Updated At</div> */}
                  </div>
                </div>
              </div>
              <div className="overflow-auto">
                {filteredResources?.map((r) => {
                  return r.type === "form" ? (
                    <FormResourceRow
                      key={r._id}
                      resource={r}
                      isExternal={resourcesCtx.state.isExternal}
                    />
                  ) : (
                    <ResourceRow
                      key={r._id}
                      resource={r}
                      isExternal={resourcesCtx.state.isExternal}
                    />
                  );
                })}
                {filteredResources.length === 0 && (
                  <div className="flex h-12 items-center border-b border-gray-100 px-8 text-sm text-gray-400">
                    <div>No resource found..</div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="relative mx-auto mt-[15vh] flex w-96 flex-col items-center p-8">
              <DocumentTextIcon className="h-7 w-7 text-gray-400" />
              <div className="mt-1 text-xl font-medium text-gray-700">
                Resources
              </div>
              <div className="mb-2 mt-1 max-w-[200px] text-center font-light text-gray-500">
                Resources enable easy access to files and links.
              </div>
              <div className="mt-3">
                <AddResourceButton phaseId={phaseId} />
              </div>
            </div>
          )}
        </>
      ) : (
        <Loading className="mt-[20vh]" />
      )}
    </>
  );
}

function FormResourceRow({
  resource,
  isExternal,
}: {
  resource: FormResourceData;
  isExternal: boolean;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div
      className="flex h-12 cursor-pointer justify-between gap-6 border-b border-gray-100 px-8 hover:bg-gray-50"
      onClick={() => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("taskId", resource.task._id);
        navigate({
          pathname: location.pathname,
          search: searchParams.toString(),
        });
      }}
      key={resource.task._id}
    >
      <div className="flex min-w-0 flex-1 items-center space-x-8">
        <div className="inline-flex w-10 items-center">
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger>
                <DocumentTextIcon className="h-[18px] w-[18px] text-gray-400" />
              </TooltipTrigger>
              <TooltipContent side="right">
                <div>Form</div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="min-w-[160px] flex-1 truncate text-sm font-medium text-gray-600">
          <div>{resource.name}</div>
        </div>
      </div>
      <div className="flex shrink-0 items-center space-x-4">
        {!isExternal && (
          <div className="flex w-32 flex-col items-center">
            {resource.task.isVisible ? (
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger>
                    <EyeIcon className="h-[18px] w-[18px] text-gray-400" />
                  </TooltipTrigger>
                  <TooltipContent side="left">
                    <div>Externally visible</div>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ) : (
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger>
                    <EyeSlashIcon className="h-[18px] w-[18px] text-gray-400" />
                  </TooltipTrigger>
                  <TooltipContent side="left">
                    <div>Externally hidden</div>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </div>
        )}

        <div className="w-40 truncate text-sm text-gray-400">
          {""}
        </div>
        <div className="w-32 text-sm text-gray-400">
          {/* {dayjs(resource.createdAt).format("MMM DD, h:mm A")} */}
        </div>
        {/* <div className="w-20">{}</div> */}
      </div>
    </div>
  );
}

function ResourceRow({
  resource,
  isExternal,
}: {
  resource: LinkResourceData | FileResourceData;
  isExternal: boolean;
}) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div
        className="flex h-12 cursor-pointer justify-between gap-6 border-b border-gray-100 px-8 hover:bg-gray-50"
        onClick={() => {
          setOpen(true);
        }}
        key={resource._id}
      >
        <div className="flex min-w-0 flex-1 items-center space-x-8">
          <div className="inline-flex w-10 items-center">
            {resource.type === "file" ? (
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger>
                    <PaperClipIcon className="h-[18px] w-[18px] text-gray-400" />
                  </TooltipTrigger>
                  <TooltipContent side="right">
                    <div>File</div>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ) : (
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger>
                    <Link2Icon className="h-[18px] w-[18px] text-gray-400" />
                  </TooltipTrigger>
                  <TooltipContent side="right">
                    <div>Link</div>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </div>
          <div className="min-w-[160px] flex-1 truncate text-sm font-medium text-gray-600">
            <div>{resource.name}</div>
          </div>
        </div>
        <div className="flex shrink-0 items-center space-x-4">
          {!isExternal && (
            <div className="flex w-32 flex-col items-center">
              {resource.isVisible ? (
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger>
                      <EyeIcon className="h-[18px] w-[18px] text-gray-400" />
                    </TooltipTrigger>
                    <TooltipContent side="left">
                      <div>Externally visible</div>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : (
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger>
                      <EyeSlashIcon className="h-[18px] w-[18px] text-gray-400" />
                    </TooltipTrigger>
                    <TooltipContent side="left">
                      <div>Externally hidden</div>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
          )}

          <div className="w-40 truncate text-sm text-gray-400">
            {resource.createdBy.name}
          </div>
          <div className="w-32 text-sm text-gray-400">
            {dayjs(resource.createdAt).format("MMM DD, h:mm A")}
          </div>
          {/* <div className="w-20">{}</div> */}
        </div>
      </div>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          {resource.type === "file" ? (
            <FileResourceDetails resource={resource} />
          ) : //
          resource.type === "link" ? (
            <LinkResourceDetails resource={resource} />
          ) : (
            <></>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

function LinkResourceDetails({ resource }: { resource: LinkResourceData }) {
  const [isEditing, setIsEditing] = useState(false);
  const [url, setUrl] = useState(
    resource.type === "link" ? resource.link.url : ""
  );
  const [name, setName] = useState(resource.name);
  const [description, setDescription] = useState(resource.description);
  const [error, setError] = useState("");
  const updateResourceMutation = useUpdatePhaseResource(resource.phaseId);
  const deleteResourceMutation = useDeleteResource(resource.phaseId);
  const [isDeleting, setIsDeleting] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const resourceCtx = useResourcesContext();
  const [visibility, setVisibility] = useState<"internal" | "all">(
    resource.isVisible ? "all" : "internal"
  );

  useEffect(() => {
    if (updateResourceMutation.isSuccess) {
      setIsEditing(false);
    }
  }, [updateResourceMutation.isSuccess]);

  return (
    <>
      {!isEditing ? (
        <div>
          <div className="space-y-2">
            <div className="text-xl font-medium">{resource.name}</div>
            <div className="text-gray-500">{resource.description}</div>
            <div>
              <Button
                onClick={() => {
                  if (resource.type === "link") {
                    window.open(resource.link.url, "_blank");
                  }
                }}
                variant={"link"}
                className="px-0"
              >
                Open Link
              </Button>
            </div>
          </div>
          <div className="mt-6 flex items-center space-x-2">
            <Button
              variant={"secondary"}
              onClick={() => {
                setIsEditing(true);
              }}
            >
              Edit
            </Button>
          </div>
        </div>
      ) : (
        <div className="space-y-6">
          <div className="text-xl font-medium">Editing Link</div>
          <div className="space-y-4">
            <div className="space-y-1">
              <InputHeading heading="Name" />
              <TextInput
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                placeholder="Resource name..."
              />
            </div>
            <div className="space-y-1">
              <InputHeading heading="Description" />
              <textarea
                className="block w-full resize-none rounded-md border-gray-300 p-2.5 py-2 text-sm shadow-sm outline-none transition-all placeholder:text-gray-300 focus:border-primary focus:outline-none focus:ring-[0.5px] focus:ring-primary"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                ref={textAreaRef}
                placeholder="Description..."
              />
            </div>
            <div className="space-y-1">
              <InputHeading heading="URL" />
              <TextInput
                value={url}
                onChange={(e) => {
                  setUrl(e.target.value);
                  setError("");
                }}
              />
              {error && <div className="text-xs text-red-500">{error}</div>}
            </div>
            {!resourceCtx.state.isExternal && (
              <div className="space-y-1">
                <InputHeading heading="Visibility" />
                <Select
                  value={visibility}
                  onValueChange={(value) => {
                    setVisibility(value as typeof visibility);
                  }}
                >
                  <SelectTrigger className="h-9 w-fit">
                    {visibility === "all" ? (
                      <div className="flex items-center space-x-2 pr-1">
                        <EyeIcon className="h-4 w-4 text-gray-400" />
                        <div>Externally visible</div>
                      </div>
                    ) : (
                      <div className="flex items-center space-x-2 pr-1">
                        <EyeSlashIcon className="h-4 w-4 text-gray-400" />
                        <div>Externally hidden</div>
                      </div>
                    )}
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="all">
                        <div className="flex items-center space-x-2">
                          <div>
                            <EyeIcon className="h-4 w-4 text-gray-400" />
                          </div>
                          <div>Externally visible</div>
                        </div>
                      </SelectItem>
                      <SelectItem value={"internal"}>
                        <div className="flex items-center space-x-2">
                          <EyeSlashIcon className="h-4 w-4 text-gray-400" />
                          <div>Externall hidden</div>
                        </div>
                      </SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            )}
          </div>
          <div className="flex justify-between">
            <Dialog open={isDeleting} onOpenChange={setIsDeleting}>
              <DialogTrigger asChild>
                <Button variant={"secondary"} size="icon">
                  <TrashIcon className="h-4 w-4" />
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Delete Resource</DialogTitle>
                </DialogHeader>
                <div className="py-2">
                  <div className="text-sm text-gray-600">
                    Are you sure you want to delete the resource{" "}
                    <span className="font-medium text-gray-900">
                      {resource.name}
                    </span>
                    ?
                  </div>
                </div>
                <div className="flex justify-end space-x-2.5">
                  <Button
                    variant={"secondary"}
                    onClick={() => {
                      setIsDeleting(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant={"destructive"}
                    onClick={() => {
                      setIsDeleting(false);
                      setIsEditing(false);
                      deleteResourceMutation.mutate({
                        _id: resource._id,
                      });
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
            <div className="flex items-center space-x-2.5">
              <Button
                variant="secondary"
                onClick={() => {
                  setIsEditing(false);
                }}
              >
                Cancel
              </Button>

              <Button
                disabled={
                  url.trim() === "" ||
                  name.trim() === "" ||
                  (url.trim() ===
                    (resource.type === "link"
                      ? resource.link.url.trim()
                      : "") &&
                    name.trim() === resource.name.trim() &&
                    description.trim() === resource.description.trim() &&
                    visibility === (resource.isVisible ? "all" : "internal")) ||
                  updateResourceMutation.isLoading
                }
                onClick={() => {
                  if (resource.type === "link") {
                    if (
                      url.trim() !== resource.link.url.trim() ||
                      name.trim() !== resource.name.trim() ||
                      description.trim() !== resource.description.trim() ||
                      visibility !== (resource.isVisible ? "all" : "internal")
                    ) {
                      const urlRegex = /^(http|https):\/\/[^ "]+$/;
                      if (!urlRegex.test(url)) {
                        setError("URL must start with http:// or https://");
                      } else {
                        updateResourceMutation.mutate({
                          _id: resource._id,
                          url: url,
                          name: name,
                          description: description,
                          isVisible: visibility === "all",
                        });
                      }
                    }
                  }
                }}
              >
                {updateResourceMutation.isLoading ? "Saving.." : "Save"}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function FileResourceDetails({ resource }: { resource: FileResourceData }) {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(resource.name);
  const [description, setDescription] = useState(resource.description);
  const updateResourceMutation = useUpdatePhaseResource(resource.phaseId);
  const deleteResourceMutation = useDeleteResource(resource.phaseId);
  const [isDeleting, setIsDeleting] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const resourceCtx = useResourcesContext();
  const [visibility, setVisibility] = useState<"internal" | "all">(
    resource.isVisible ? "all" : "internal"
  );

  useEffect(() => {
    if (updateResourceMutation.isSuccess) {
      setIsEditing(false);
    }
  }, [updateResourceMutation.isSuccess]);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto"; // Reset the height
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`; // Set the height based on scroll height
    }
  }, [description, isEditing]);

  return (
    <>
      {!isEditing ? (
        <div>
          <div className="space-y-2">
            <div className="text-xl font-medium">{resource.name}</div>
            <div className="text-gray-500">{resource.description}</div>
            <div>
              <Button
                onClick={() => {
                  if (resource.type === "file") {
                    window.open(resource.file.url, "_blank");
                  }
                }}
                variant={"link"}
                className="px-0"
              >
                Open File
              </Button>
            </div>
          </div>
          <div className="mt-6 flex items-center space-x-2">
            <Button
              variant={"secondary"}
              onClick={() => {
                setIsEditing(true);
              }}
            >
              Edit
            </Button>
          </div>
        </div>
      ) : (
        <div className="space-y-6">
          <div className="text-xl font-medium">Editing File</div>
          <div className="space-y-4">
            <div className="space-y-1">
              <InputHeading heading="Name" />
              <TextInput
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                placeholder="Resource name..."
              />
            </div>
            <div className="space-y-1">
              <InputHeading heading="Description" />
              <textarea
                className="block w-full resize-none rounded-md border-gray-300 p-2.5 py-2 text-sm shadow-sm outline-none transition-all placeholder:text-gray-300 focus:border-primary focus:outline-none focus:ring-[0.5px] focus:ring-primary"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                ref={textAreaRef}
                placeholder="Description..."
              />
            </div>
            {!resourceCtx.state.isExternal && (
              <div className="space-y-1">
                <InputHeading heading="Visibility" />
                <Select
                  value={visibility}
                  onValueChange={(value) => {
                    setVisibility(value as typeof visibility);
                  }}
                >
                  <SelectTrigger className="h-9 w-fit">
                    {visibility === "all" ? (
                      <div className="flex items-center space-x-2 pr-1">
                        <EyeIcon className="h-4 w-4 text-gray-400" />
                        <div>Externally visible</div>
                      </div>
                    ) : (
                      <div className="flex items-center space-x-2 pr-1">
                        <EyeSlashIcon className="h-4 w-4 text-gray-400" />
                        <div>Externally hidden</div>
                      </div>
                    )}
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="all">
                        <div className="flex items-center space-x-2">
                          <div>
                            <EyeIcon className="h-4 w-4 text-gray-400" />
                          </div>
                          <div>Externally visible</div>
                        </div>
                      </SelectItem>
                      <SelectItem value={"internal"}>
                        <div className="flex items-center space-x-2">
                          <EyeSlashIcon className="h-4 w-4 text-gray-400" />
                          <div>Externall hidden</div>
                        </div>
                      </SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            )}
          </div>
          <div className="flex justify-between">
            <Dialog open={isDeleting} onOpenChange={setIsDeleting}>
              <DialogTrigger asChild>
                <Button variant={"secondary"} size="icon">
                  <TrashIcon className="h-4 w-4" />
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Delete Resource</DialogTitle>
                </DialogHeader>
                <div className="py-2">
                  <div className="text-sm text-gray-600">
                    Are you sure you want to delete the resource{" "}
                    <span className="font-medium text-gray-900">
                      {resource.name}
                    </span>
                    ?
                  </div>
                </div>
                <div className="flex justify-end space-x-2.5">
                  <Button
                    variant={"secondary"}
                    onClick={() => {
                      setIsDeleting(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant={"destructive"}
                    onClick={() => {
                      setIsDeleting(false);
                      setIsEditing(false);
                      deleteResourceMutation.mutate({
                        _id: resource._id,
                      });
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
            <div className="flex items-center space-x-2.5">
              <Button
                variant="secondary"
                onClick={() => {
                  setIsEditing(false);
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={
                  name.trim() === "" ||
                  (name.trim() === resource.name.trim() &&
                    description.trim() === resource.description.trim() &&
                    visibility === (resource.isVisible ? "all" : "internal")) ||
                  updateResourceMutation.isLoading
                }
                onClick={() => {
                  updateResourceMutation.mutate({
                    _id: resource._id,
                    name: name,
                    description: description,
                    isVisible: visibility === "all",
                  });
                }}
              >
                {updateResourceMutation.isLoading ? "Saving.." : "Save"}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function AddResourceButton({ phaseId }: { phaseId: string }) {
  const [open, setOpen] = useState(false);
  const [resourceType, setResourceType] = useState<"file" | "link" | "none">(
    "none"
  );
  useEffect(() => {
    if (open) {
      setResourceType("none");
      setLabel("");
      setFile(undefined);
      setVisibility("all");
      setUrl("");
      setUrlError("");
      setDescription("");
    }
  }, [open]);

  const resourceCtx = useResourcesContext();

  const [urlError, setUrlError] = useState("");
  const [label, setLabel] = useState("");
  const [file, setFile] = useState<File | undefined>(undefined);
  const [url, setUrl] = useState("");
  const [visibility, setVisibility] = useState<"internal" | "all">("all");
  const uploadPhaseFileMutation = useUploadPhaseFile();
  const createPhaseLinkMutation = useCreatePhaseLinkResource(phaseId);
  useEffect(() => {
    if (uploadPhaseFileMutation.isSuccess) {
      setOpen(false);
    }
  }, [uploadPhaseFileMutation.isSuccess]);

  useEffect(() => {
    if (createPhaseLinkMutation.isSuccess) {
      setOpen(false);
    }
  }, [createPhaseLinkMutation.isSuccess]);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto"; // Reset the height
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`; // Set the height based on scroll height
    }
  }, [description]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>Add Resource</Button>
      </DialogTrigger>
      <DialogContent className="top-[12%] translate-y-0">
        <DialogHeader>
          <DialogTitle>
            {resourceType === "none"
              ? "New Resource"
              : resourceType === "file"
              ? "New File"
              : "New Link"}
          </DialogTitle>
          {resourceType === "none" && (
            <DialogDescription>
              {resourceType === "none"
                ? "Select a type of resource to share"
                : resourceType === "file"
                ? "Details about the file you want to share"
                : "Details about the link you want to share"}
            </DialogDescription>
          )}
        </DialogHeader>
        <div className="pt-2">
          {resourceType === "none" && (
            <div>
              {/* <div>What type of resource would you like to add?</div> */}
              <div className="grid gap-3">
                <div
                  className={
                    "cursor-pointer rounded-md border py-3 px-4 shadow-sm hover:bg-gray-50"
                  }
                  onClick={() => setResourceType("file")}
                >
                  <div className="flex items-center space-x-1.5">
                    <DocumentTextIcon className="h-[18px] w-[18px] text-gray-400" />
                    <div className="text-lg font-medium">File</div>
                  </div>
                  <div>
                    <div className="text-sm text-gray-500">
                      Upload PDFs, images and other types of files
                    </div>
                  </div>
                </div>
                <div
                  className={
                    "cursor-pointer rounded-md border py-3 px-4 shadow-sm hover:bg-gray-50"
                  }
                  onClick={() => setResourceType("link")}
                >
                  <div className="flex items-center space-x-2">
                    <Link2Icon className="h-[18px] w-[18px] text-gray-400" />
                    <div className="text-lg font-medium">Link</div>
                  </div>
                  <div>
                    <div className="text-sm text-gray-500">
                      Share URLs of other websites
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {resourceType === "file" && (
            <div className="space-y-4">
              <div className="max-w-xs">
                <div className="space-y-1">
                  <InputHeading heading="Name" />
                  <TextInput
                    autoComplete="off"
                    placeholder="Resource Name.."
                    value={label}
                    onChange={(e) => setLabel(e.target.value)}
                  />
                </div>
              </div>
              <div className="space-y-1">
                <InputHeading heading="Description" />
                <textarea
                  ref={textAreaRef}
                  className="block w-full resize-none rounded-md border-gray-300 p-2.5 py-2 text-sm shadow-sm outline-none transition-all placeholder:text-gray-300 focus:border-primary focus:outline-none focus:ring-[0.5px] focus:ring-primary"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  placeholder="Description..."
                />
              </div>
              <div className="space-y-1">
                <InputHeading heading="File" />
                <div className="flex flex-col items-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                  <div className="min-w-0 space-y-1 text-center">
                    {!!file ? (
                      <div className="max-w-xs truncate whitespace-nowrap text-lg font-medium text-primary">
                        {file.name}
                      </div>
                    ) : (
                      <DocumentTextIcon className="mx-auto mb-2 h-6 w-6 text-gray-400" />
                    )}
                    <div className="flex text-sm text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className={clsx(
                          "relative flex w-full cursor-pointer flex-col items-center rounded-md bg-white text-center font-medium transition-colors focus-within:outline-none",
                          !!file
                            ? "text-gray-400 hover:text-gray-500"
                            : "text-primary-600 hover:text-primary"
                        )}
                      >
                        <div>{!!file ? "Replace file" : "Select a file"}</div>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                          accept="application/pdf, image/jpg, image/jpeg, image/png, image/svg, image/gif, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .csv, .txt"
                          onChange={(e) => {
                            // console.log(e.target.files);
                            const selectedFile = e.target.files?.[0];
                            if (selectedFile) {
                              if (
                                !/^[a-zA-Z0-9_ -]+\.[a-zA-Z0-9]+$/.test(
                                  selectedFile.name
                                )
                              ) {
                                alert(
                                  "The file name must consist solely of letters, numbers, underscores, hyphens, and a single dot. Please ensure it adheres to this format."
                                );
                              } else if (selectedFile.size > 5 * 1024 * 1024) {
                                alert("File size should not exceed 5MB.");
                              } else {
                                setFile(selectedFile);
                              }
                            }
                          }}
                        />
                      </label>
                    </div>
                    <p className="text-xs text-gray-500">
                      PDF, JPG, etc. up to 5MB
                    </p>
                  </div>
                </div>
              </div>
              {!resourceCtx.state.isExternal && (
                <div className="space-y-1">
                  <InputHeading heading="Visibility" />
                  <Select
                    value={visibility}
                    onValueChange={(value) => {
                      setVisibility(value as typeof visibility);
                    }}
                  >
                    <SelectTrigger className="h-9 w-fit">
                      {visibility === "all" ? (
                        <div className="flex items-center space-x-2 pr-1">
                          <EyeIcon className="h-4 w-4 text-gray-400" />
                          <div>Externally visible</div>
                        </div>
                      ) : (
                        <div className="flex items-center space-x-2 pr-1">
                          <EyeSlashIcon className="h-4 w-4 text-gray-400" />
                          <div>Externally hidden</div>
                        </div>
                      )}
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value="all">
                          <div className="flex items-center space-x-2">
                            <div>
                              <EyeIcon className="h-4 w-4 text-gray-400" />
                            </div>
                            <div>Externally visible</div>
                          </div>
                        </SelectItem>
                        <SelectItem value={"internal"}>
                          <div className="flex items-center space-x-2">
                            <EyeSlashIcon className="h-4 w-4 text-gray-400" />
                            <div>Externall hidden</div>
                          </div>
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              )}
              {/* {!filesCtx.state.isExternal && (
                  <div className="space-y-2">
                    <InputHeading heading="External Visibility" />
                    <div className="inline-flex items-center space-x-2">
                      <VariantToggle
                        focus={false}
                        enabled={isVisible}
                        setEnabled={(value) => {
                          setIsVisible(value);
                        }}
                        color={filesCtx.state.isExternal ? "embed" : "primary"}
                        size="md"
                      />
                      <div className="whitespace-nowrap text-sm font-light text-gray-400">
                        Visible
                      </div>
                    </div>
                  </div>
                )} */}
              <div className="flex justify-end">
                <Button
                  disabled={
                    !file ||
                    label.trim() === "" ||
                    uploadPhaseFileMutation.isLoading
                  }
                  onClick={async () => {
                    if (!file) return;
                    uploadPhaseFileMutation.mutate({
                      label: label,
                      description: description,
                      phaseId: phaseId,
                      isVisible: visibility === "all",
                      file: file,
                      // type: file?.type,
                      // encodedFile: base64String,
                    });

                    // const reader = new FileReader();
                    // reader.readAsDataURL(file);
                    // reader.onload = () => {
                    //   const dataUrl = reader.result as string;
                    //   const match = dataUrl.match(/^data:.+\/(.+);base64,(.*)$/);

                    //   if (match) {
                    //     const contentType = match[1];
                    //     const base64String = match[2];

                    //   }
                    // };
                  }}
                >
                  {uploadPhaseFileMutation.isLoading
                    ? "Uploading..."
                    : "Upload"}
                </Button>
              </div>
            </div>
          )}
          {resourceType === "link" && (
            <div className="space-y-4">
              <div className="max-w-xs space-y-1">
                <InputHeading heading="Name" />
                <TextInput
                  autoComplete="off"
                  placeholder="Resource Name.."
                  value={label}
                  onChange={(e) => setLabel(e.target.value)}
                />
              </div>

              <div className="space-y-1">
                <InputHeading heading="Description" />
                <textarea
                  ref={textAreaRef}
                  className="block w-full resize-none rounded-md border-gray-300 p-2.5 py-2 text-sm shadow-sm outline-none transition-all placeholder:text-gray-300 focus:border-primary focus:outline-none focus:ring-[0.5px] focus:ring-primary"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  placeholder="Description..."
                />
              </div>
              <div className="max-w-xs space-y-1">
                <InputHeading heading="URL" />
                <TextInput
                  autoComplete="off"
                  placeholder="https://www.youtube.com/123"
                  value={url}
                  onChange={(e) => {
                    setUrl(e.target.value);
                    setUrlError("");
                  }}
                />
                {urlError && (
                  <div className="text-xs text-red-500">{urlError}</div>
                )}
              </div>

              {!resourceCtx.state.isExternal && (
                <div className="space-y-1">
                  <InputHeading heading="Visibility" />
                  <Select
                    value={visibility}
                    onValueChange={(value) => {
                      setVisibility(value as typeof visibility);
                    }}
                  >
                    <SelectTrigger className="h-9 w-fit">
                      {visibility === "all" ? (
                        <div className="flex items-center space-x-2 pr-1">
                          <EyeIcon className="h-4 w-4 text-gray-400" />
                          <div>Externally visible</div>
                        </div>
                      ) : (
                        <div className="flex items-center space-x-2 pr-1">
                          <EyeSlashIcon className="h-4 w-4 text-gray-400" />
                          <div>Externally hidden</div>
                        </div>
                      )}
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value="all">
                          <div className="flex items-center space-x-2">
                            <div>
                              <EyeIcon className="h-4 w-4 text-gray-400" />
                            </div>
                            <div>Externally visible</div>
                          </div>
                        </SelectItem>
                        <SelectItem value={"internal"}>
                          <div className="flex items-center space-x-2">
                            <EyeSlashIcon className="h-4 w-4 text-gray-400" />
                            <div>Externally hidden</div>
                          </div>
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              )}
              {/* {!filesCtx.state.isExternal && (
                <div className="space-y-2">
                  <InputHeading heading="External Visibility" />
                  <div className="inline-flex items-center space-x-2">
                    <VariantToggle
                      focus={false}
                      enabled={isVisible}
                      setEnabled={(value) => {
                        setIsVisible(value);
                      }}
                      color={filesCtx.state.isExternal ? "embed" : "primary"}
                      size="md"
                    />
                    <div className="whitespace-nowrap text-sm font-light text-gray-400">
                      Visible
                    </div>
                  </div>
                </div>
              )} */}
              <div className="flex justify-end">
                <Button
                  disabled={
                    label.trim() === "" ||
                    url.trim() === "" ||
                    createPhaseLinkMutation.isLoading
                  }
                  onClick={() => {
                    // check if url starts with http or https
                    const urlRegex = /^(http|https):\/\/[^ "]+$/;
                    if (!urlRegex.test(url)) {
                      setUrlError("URL must start with http:// or https://");
                      return;
                    }
                    if (label.trim() === "" || url.trim() === "") return;
                    createPhaseLinkMutation.mutate({
                      name: label,
                      isVisible: visibility === "all",
                      url: url,
                      description: description,
                    });
                  }}
                >
                  {createPhaseLinkMutation.isLoading ? "Adding.." : "Add"}
                </Button>
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
