import { useGetInstance } from "src/api/General/instance";
import { useGetSlackIntegrationStatus } from "src/api/Integrations/slack";
import { Button } from "src/components/ui/button";
import { useRealmApp } from "src/store/RealmApp";
import { BackToIntegrationsButton } from "../Integrations";

type Props = {};

export default function SlackIntegration({}: Props) {
  return (
    <div className="mx-auto mt-20 max-w-3xl px-8">
      <BackToIntegrationsButton />
      <div className="mt-4 flex items-center gap-3">
        <div className="flex h-12 w-12 items-center justify-center rounded-md border shadow-sm">
          <svg
            width="32px"
            height="32px"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
          >
            <g fill-rule="evenodd" clip-rule="evenodd">
              <path
                fill="#E01E5A"
                d="M2.471 11.318a1.474 1.474 0 001.47-1.471v-1.47h-1.47A1.474 1.474 0 001 9.846c.001.811.659 1.469 1.47 1.47zm3.682-2.942a1.474 1.474 0 00-1.47 1.471v3.683c.002.811.66 1.468 1.47 1.47a1.474 1.474 0 001.47-1.47V9.846a1.474 1.474 0 00-1.47-1.47z"
              />
              <path
                fill="#36C5F0"
                d="M4.683 2.471c.001.811.659 1.469 1.47 1.47h1.47v-1.47A1.474 1.474 0 006.154 1a1.474 1.474 0 00-1.47 1.47zm2.94 3.682a1.474 1.474 0 00-1.47-1.47H2.47A1.474 1.474 0 001 6.153c.002.812.66 1.469 1.47 1.47h3.684a1.474 1.474 0 001.47-1.47z"
              />
              <path
                fill="#2EB67D"
                d="M9.847 7.624a1.474 1.474 0 001.47-1.47V2.47A1.474 1.474 0 009.848 1a1.474 1.474 0 00-1.47 1.47v3.684c.002.81.659 1.468 1.47 1.47zm3.682-2.941a1.474 1.474 0 00-1.47 1.47v1.47h1.47A1.474 1.474 0 0015 6.154a1.474 1.474 0 00-1.47-1.47z"
              />
              <path
                fill="#ECB22E"
                d="M8.377 9.847c.002.811.659 1.469 1.47 1.47h3.683A1.474 1.474 0 0015 9.848a1.474 1.474 0 00-1.47-1.47H9.847a1.474 1.474 0 00-1.47 1.47zm2.94 3.682a1.474 1.474 0 00-1.47-1.47h-1.47v1.47c.002.812.659 1.469 1.47 1.47a1.474 1.474 0 001.47-1.47z"
              />
            </g>
          </svg>
        </div>
        <div>
          <h2 className="cursor-default text-2xl tracking-wide text-gray-900">
            Slack
          </h2>
          <p className="text-sm text-gray-500">
            Receive updates from Sero projects in your Slack channels or via the
            Sero Slack App
          </p>
        </div>
      </div>
      <div className="mt-8">
        <Slack />
      </div>
    </div>
  );
}

function Slack() {
  const { data: slackIntegrationStatus } = useGetSlackIntegrationStatus();
  const { instance } = useGetInstance();
  const app = useRealmApp();
  return (
    <div className="space-y-4">
      <div className="divide-y rounded-md border py-6 shadow-sm">
        <div className="px-6">
          <div className="text-lg leading-none text-gray-800">Workspace</div>
          <div className="mt-1 text-sm font-light text-gray-500">
            Receive updates from Sero projects in your Slack channels or via the
            Sero Slack App
          </div>
          <div className="mt-5 flex items-center justify-between gap-4">
            {!!instance ? (
              <div className="truncate text-gray-600">{instance.name}</div>
            ) : (
              <div className="h-5 w-40 animate-pulse rounded-md bg-gray-100" />
            )}
            {!!slackIntegrationStatus ? (
              <div className="flex w-fit items-center space-x-2">
                {slackIntegrationStatus?.workspace ? (
                  <div className="inline-flex h-7 items-center justify-center gap-x-0.5 whitespace-nowrap rounded-full bg-green-50 px-3 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-500/10">
                    Connected
                  </div>
                ) : (
                  <div className="inline-flex h-7 items-center justify-center gap-x-0.5 whitespace-nowrap rounded-full bg-gray-50 px-3 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                    Not Connected
                  </div>
                )}
                <SlackButton
                  connected={slackIntegrationStatus?.workspace}
                  userId={app.currentUser?.id}
                />
              </div>
            ) : (
              <div className="h-5 w-40 animate-pulse rounded-md bg-gray-100" />
            )}
          </div>
        </div>
      </div>
      <div className="divide-y rounded-md border py-6 shadow-sm">
        <div className="px-6">
          <div>
            <div className="text-lg leading-none text-gray-800">Users</div>
            <div className="mt-1 text-sm font-light text-gray-500">
              Receive personalized notifications from the Sero Slack App
            </div>
          </div>

          <div className="mt-4">
            {!!slackIntegrationStatus ? (
              <>
                {slackIntegrationStatus?.users.map((user) => (
                  <div key={user.email} className="py-1 last:pb-0">
                    <div className="flex justify-between gap-4">
                      <div className="truncate text-gray-600">{user.name}</div>

                      <div className="flex items-center space-x-2">
                        {user.status ? (
                          <div className="inline-flex h-7 items-center justify-center gap-x-0.5 whitespace-nowrap rounded-full bg-green-50 px-3 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-500/10">
                            Connected
                          </div>
                        ) : (
                          <div className="inline-flex h-7 items-center justify-center gap-x-0.5 whitespace-nowrap rounded-full bg-gray-50 px-3 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                            Not Connected
                          </div>
                        )}
                        <SlackButton
                          connected={user.status}
                          userId={user._id}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div>
                <div className="py-1.5">
                  <div className="flex justify-between gap-4">
                    <div className="h-5 w-20 animate-pulse rounded-md bg-gray-100" />
                    <div className="h-5 w-40 animate-pulse rounded-md bg-gray-100" />
                  </div>
                </div>
                <div className="pt-1.5">
                  <div className="flex justify-between gap-4">
                    <div className="h-5 w-20 animate-pulse rounded-md bg-gray-100" />
                    <div className="h-5 w-40 animate-pulse rounded-md bg-gray-100" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function SlackButton({
  connected,
  userId,
}: {
  connected: boolean;
  userId: string;
}) {
  const app = useRealmApp();
  const redirect_uri = `${process.env.REACT_APP_URL}/settings/slack/authentication`;

  return (
    <Button
      variant={"secondary"}
      size="sm"
      onClick={() => {
        window.open(
          `https://slack.com/oauth/v2/authorize?redirect_uri=${redirect_uri}&client_id=647746016512.2990898648288&scope=app_mentions:read,channels:history,channels:join,channels:manage,channels:read,chat:write,chat:write.customize,commands,groups:history,im:history,im:write,groups:read,im:read,mpim:read&user_scope=`,
          // `https://slack.com/oauth/v2/authorize?redirect_uri=${redirect_uri}&client_id=647746016512.2990898648288&scope=app_mentions:read,channels:history,channels:join,channels:manage,channels:read,chat:write,commands,groups:history,im:history,im:write,chat:write.customize&user_scope=`
          "_blank"
        );
      }}
      disabled={userId !== app.currentUser?.id}
    >
      Connect
    </Button>
    // <>
    //   <a
    //     // href={`https://slack.com/oauth/v2/authorize?redirect_uri=${redirect_uri}&client_id=647746016512.2990898648288&scope=app_mentions:read,channels:history,commands,groups:history,im:history,im:write,channels:manage&user_scope=`}
    //     href={`https://slack.com/oauth/v2/authorize?redirect_uri=${redirect_uri}&client_id=647746016512.2990898648288&scope=app_mentions:read,channels:history,channels:manage,chat:write,commands,groups:history,im:history,im:write,channels:join,channels:read&user_scope=`}
    //   >
    //     <img
    //       alt="Add to Slack"
    //       height="40"
    //       width="139"
    //       src="https://platform.slack-edge.com/img/add_to_slack.png"
    //       srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
    //     />
    //   </a>
    // </>
  );
}
