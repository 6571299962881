import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import { ChevronLeftIcon, TriangleRightIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { useState } from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useGetInstance } from "src/api/General/instance";
import { SpaceData, useGetSpaces } from "src/api/Spaces/spaces";
import { SpaceSymbol } from "src/components/Layout/Sidebar";
import { classNames } from "src/utils/functions/classes";

type Props = {};

export default function SettingsLayout({}: Props) {
  return (
    <div className="flex h-screen">
      <SettingsSidebar />
      <main className="flex-1 overflow-auto">
        <Outlet />
      </main>
    </div>
  );
}

function SettingsSidebar() {
  const { data: spaces } = useGetSpaces();
  const navigate = useNavigate();
  const { instance } = useGetInstance();
  return (
    <div className="w-56 shrink-0 border-r border-gray-200/70 bg-white px-3 pt-3.5 text-sm">
      <div className="flex items-center space-x-1">
        <div
          className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-700"
          onClick={() => {
            navigate("/");
          }}
        >
          <ChevronLeftIcon className="h-3.5 w-3.5" />
        </div>
        <div className="select-none font-medium">Settings</div>
      </div>
      <div className="pb-4 pt-4">
        <div className="flex h-7 items-center space-x-2 px-2">
          <BuildingOfficeIcon className="h-4 w-4 shrink-0" />
          <span>{"Workspace"}</span>
        </div>
        <div className="pl-6 pt-0.5">
          <Tab name={"General"} route={`/settings`} />
          <Tab name={"Members"} route={`/settings/members`} />
          <Tab name={"Fields"} route={`/settings/custom-fields`} />
          {/* <Tab name={"Notifications"} route={`/settings/notifications`} /> */}
          <Tab name={"Integrations"} route={`/settings/integrations`} />
          <Tab name={"Embed"} route={`/settings/embed`} />
        </div>
      </div>
      <div>
        <div className="flex h-7 items-center space-x-2 px-2">
          <div className="w-4">
            <SpaceSymbol color={"gray"} />
          </div>
          <div>Spaces</div>
        </div>
        <div className="min-w-0 pl-6 pt-0.5">
          {spaces?.map((space) => (
            <SpaceSettings space={space} key={space._id} />
          ))}
        </div>
      </div>
    </div>
  );
}

function SpaceSettings({ space }: { space: SpaceData }) {
  const { spaceId } = useParams();
  const [open, setOpen] = useState(spaceId === space._id);
  return (
    <div>
      <div
        className="flex h-7 cursor-pointer items-center space-x-1.5 rounded-md px-2 hover:bg-gray-100 hover:text-gray-700"
        onClick={() => {
          setOpen((prev) => !prev);
        }}
      >
        <div className="flex min-w-0 items-center">
          <SpaceSymbol color={space.color} />
          <div className="ml-2.5 min-w-0 flex-1 truncate text-sm text-gray-600">
            {space.name}
          </div>
        </div>
        <TriangleRightIcon
          className={clsx(
            "h-3.5 w-3.5 shrink-0 text-gray-300 transition-all group-hover:text-gray-400/80",
            open && "rotate-90 transition-transform"
          )}
        />
      </div>
      {open && (
        <div className="pl-[22px] pt-0.5">
          <Tab name={"General"} route={`/settings/spaces/${space._id}`} />
          {/* <Tab name={"Tasks"} route={`/settings/spaces/${space._id}/tasks`} /> */}
        </div>
      )}
    </div>
  );
}

function Tab({ name, route }: { name: string; route: string }) {
  const location = useLocation();
  return (
    <Link to={route}>
      <div className="group select-none pb-0.5">
        <div
          className={classNames(
            location.pathname === route
              ? "bg-gray-100 text-gray-800"
              : "text-gray-700 group-hover:bg-gray-100 group-hover:text-gray-800",
            "group flex h-[28px] items-center rounded-md px-2 text-sm"
          )}
        >
          <div className="">{name}</div>
        </div>
      </div>
    </Link>
  );
}
