import {
  BuildingOfficeIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
  Square3Stack3DIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Button } from "src/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "src/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";

import {
  CheckIcon,
  LockClosedIcon,
  LockOpen1Icon,
} from "@radix-ui/react-icons";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CustomerData,
  useGetCustomersWithDebouncing,
} from "src/api/General/customers";
import { ProjectsFilter } from "src/api/Services/Projects/projects";
import { PhaseStatus } from "src/api/Services/SharedSpace/phases";
import { useGetTaskersForInstance } from "src/api/Services/Tasks/tasks";
import { useGetSpaces } from "src/api/Spaces/spaces";
import { useCreateProjectView } from "src/api/Views/views";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { Checkbox } from "src/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import { TextInput } from "src/components/ui/text-input";
import { useRealmApp } from "src/store/RealmApp";
import useDebounce from "src/utils/customHooks/useDebounce";
import {
  CompanyFilter,
  Logic,
  ProjectOwnerFilter,
  ProjectStatusFilter,
} from "src/utils/filters";
import { cn } from "src/utils/ui/ui";
import { getPhaseStatus } from "../SharedSpace/Phases/Tabs/Overview";
import NewProjectButton from "./NewProject/NewProject";
import ProjectsTable from "./ProjectsTable";

type FilterGroup = {
  logic: Logic;
  children: Filter[];
};

type Filter = CompanyFilter | ProjectStatusFilter | ProjectOwnerFilter;

export default function Projects() {
  // const [view, setView] = useState(defaultOptions[0]);
  // const [viewOptions, setViewOptions] = useState([...defaultOptions]);
  const { data: spaces } = useGetSpaces();
  const { spaceId } = useParams();
  const [filterGroup, setFilterGroup] = useState<FilterGroup>(() => {
    const savedFilterGroup = localStorage.getItem(
      `space_projects_filter_${spaceId}`
    );
    return savedFilterGroup
      ? JSON.parse(savedFilterGroup)
      : {
          logic: "AND",
          children: [
            {
              field: "status",
              comparator: "in",
              value: ["In Progress"],
            },
          ],
        };
  });
  const [displayOpen, setDisplayOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  // const [isFilterActive, setIsFilterActive] = useState(false);
  // const { data: projectViews } = useGetProjectViews();
  // const searchParams = new URLSearchParams(window.location.search);
  // const viewId = searchParams.get("viewId");
  const [search, setSearch] = useState("");

  useEffect(() => {
    // check if the filter group has a status filter
    if (
      !filterGroup.children.find((child) => child.field === "project-status")
    ) {
      // if not, add one
      setFilterGroup({
        ...filterGroup,
        children: [
          ...filterGroup.children,
          {
            field: "project-status",
            comparator: "in",
            value: ["In Progress"],
          },
        ],
      });
    }
  }, [filterGroup]);

  useEffect(() => {
    localStorage.setItem(
      `space_projects_filter_${spaceId}`,
      JSON.stringify(filterGroup)
    );
  }, [filterGroup]);

  // useEffect(() => {
  //   setFilter(view.filter);
  // }, [view]);

  // useEffect(() => {
  //   if (projectViews) {
  //     setViewOptions([
  //       ...defaultOptions,
  //       ...projectViews.map((view) => ({
  //         name: view.name,
  //         _id: view._id.toString(),
  //         filter: view.filter,
  //       })),
  //     ]);
  //     if (viewId) {
  //       const selectedView = projectViews.find((view) => view._id === viewId);
  //       console.log(selectedView);
  //       if (selectedView) {
  //         setView({
  //           name: selectedView.name,
  //           _id: selectedView._id.toString(),
  //           filter: selectedView.filter,
  //         });
  //       }
  //     }
  //   }
  // }, [projectViews]);

  // const [projectView, setProjectView] = useState<"projects" | "project groups">(
  //   "projects"
  // );
  const navigate = useNavigate();
  return (
    <div className="flex h-full flex-col">
      <div className="flex h-12 items-center justify-between gap-6 border-b border-gray-200/70 px-8">
        <div className={clsx("flex items-center space-x-3")}>
          {/* <PageHeading heading={"Projects"} /> */}
          <div className="cursor-default text-sm text-gray-800">Projects</div>

          {/* <div className="flex h-8 items-center space-x-1 rounded-md border px-1">
            <div
              className={clsx(
                "h-6 rounded px-1.5",
                projectView === "projects"
                  ? "bg-gray-100"
                  : "bg-white text-gray-400"
              )}
              onClick={() => setProjectView("projects")}
            >
              Projects
            </div>
          </div> */}
        </div>
      </div>

      <div className="flex h-16 items-center justify-between border-b border-gray-200/70 px-8">
        <div className="flex space-x-2">
          <Popover open={filterOpen} onOpenChange={setFilterOpen}>
            <PopoverTrigger asChild>
              <Button variant="secondary">
                <FunnelIcon className="mr-1.5 h-3.5 w-3.5" />
                Filter
              </Button>
            </PopoverTrigger>
            <PopoverContent
              align="start"
              className="w-full min-w-[288px] space-y-3 p-4"
            >
              {/* <FilterGroup
                filterGroup={filterGroup}
                setFilterGroup={setFilterGroup}
              /> */}
              {/* <div className="w-fit space-y-2">
                <div>
                  <CustomerFilter filter={filter} setFilter={setFilter} />
                </div>
                <div>
                  <OwnerFilter filter={filter} setFilter={setFilter} />
                </div>
              </div> */}
            </PopoverContent>
          </Popover>
          <StatusFilter
            filterGroup={filterGroup}
            setFilterGroup={setFilterGroup}
          />
          {/* <Popover open={displayOpen} onOpenChange={setDisplayOpen}>
            <PopoverTrigger asChild>
              <Button variant="secondary" className="border-gray-300/70">
                <AdjustmentsHorizontalIcon className="mr-1.5 h-3.5 w-4" />
                Display
              </Button>
            </PopoverTrigger>
            <PopoverContent align="start">
              This is where the display options will be
            </PopoverContent>
          </Popover> */}
          {/* {!isFilterActive && view._id === "all-projects" && (
            <Button
            variant="secondary"
            className={"h-[30px] border-gray-300/70"}
          >
            <FunnelIcon className="mr-1.5 h-3.5 w-3.5" />
            Filter
          </Button>
            // <Button
            //   variant="secondary"
            //   onClick={() => setIsFilterActive((prev) => !prev)}
            //   className="space-x-2 border-gray-300/70"
            // >
            //   <FunnelIcon className="h-4 w-4 text-gray-400" />
            //   <span className="font-normal text-gray-400">{`${
            //     isFilterActive ? "Remove" : ""
            //   } Filter`}</span>
            // </Button>
          )} */}
          <div className="relative">
            <TextInput
              className="h-8 w-72 pl-8"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search.."
              autoFocus
            />
            <div className="absolute inset-y-0 left-0 flex items-center pl-2.5">
              <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" />
            </div>
          </div>
        </div>
        {spaces && <NewProjectButton spaces={spaces} />}
      </div>
      {/* {isFilterActive && (
        <div className="flex items-center justify-between gap-6 border-b py-4 px-8">
          <Filters
            filter={filter}
            setFilter={setFilter}
            setIsFilterActive={setIsFilterActive}
          />

          <ViewButton filter={filter} />
        </div>
      )} */}
      <ProjectsTable
        filter={{
          status: filterGroup.children.find(
            (child) => child.field === "project-status"
          )?.value as PhaseStatus[],
        }}
        search={search}
        setSearch={setSearch}
        onClick={(projectId) => {
          navigate(`/projects/${projectId}`);
        }}
      />
    </div>
  );
}

type Visibility = "Private" | "Public";

function ViewButton({ filter }: { filter: ProjectsFilter }) {
  const createProjectViewMutation = useCreateProjectView();
  const [name, setName] = useState("");
  const [visibility, setVisibility] = useState<Visibility>("Private");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (createProjectViewMutation.isSuccess) {
      setOpen(false);
    }
  }, [createProjectViewMutation.isSuccess]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          disabled={!filter.customerIds?.length && !filter.ownerUserIds?.length}
          variant="secondary"
        >
          <Square3Stack3DIcon className="mr-2 h-4 w-4 text-gray-400" />
          <span className="font-normal text-gray-400">Save View</span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>New View</DialogTitle>
          <DialogDescription>Details of your new view</DialogDescription>
        </DialogHeader>
        <div className="space-y-4 py-2">
          <div className="space-y-1">
            <InputHeading heading="Name" />
            <TextInput
              className="w-3/4"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="space-y-1">
            <InputHeading heading="Visibility" />
            <Select
              value={visibility}
              onValueChange={(value) => {
                setVisibility(value as Visibility);
              }}
            >
              <SelectTrigger className="h-9 w-fit gap-2">
                <SelectValue />
              </SelectTrigger>
              <SelectContent align="start" className="min-w-[120px]">
                <SelectGroup>
                  <SelectItem value={"Private"}>
                    <div className="flex items-center">
                      <LockClosedIcon className="mr-2 h-4 w-4 shrink-0" />
                      <div>{"Private"}</div>
                    </div>
                  </SelectItem>
                  <SelectItem value={"Public"}>
                    <div className="flex items-center">
                      <LockOpen1Icon className="mr-2 h-4 w-4 shrink-0" />
                      <div>{"Public"}</div>
                    </div>
                  </SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>
        <DialogFooter>
          <Button
            onClick={() => {
              createProjectViewMutation.mutate({
                filter: {
                  // only assign customerIds and ownerUserIds if they are not empty
                  ...(filter.customerIds?.length && {
                    customerIds: filter.customerIds,
                  }),
                  ...(filter.ownerUserIds?.length && {
                    ownerUserIds: filter.ownerUserIds,
                  }),
                  status: filter.status,
                },
                name: name,
                isShared: visibility === "Public",
              });
            }}
            disabled={!name.trim()}
          >
            {createProjectViewMutation.isLoading ? "Saving.." : "Save"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

// function Filters({
//   filter,
//   setFilter,
//   setIsFilterActive,
// }: {
//   filter: ProjectsFilter;
//   setFilter: React.Dispatch<React.SetStateAction<ProjectsFilter>>;
//   setIsFilterActive: React.Dispatch<React.SetStateAction<boolean>>;
// }) {
//   return (
//     <div className="flex items-center justify-between space-x-2">
//       <div className="flex items-center space-x-2">
//         <Button
//           variant="secondary"
//           onClick={() => {
//             setIsFilterActive((prev) => !prev);
//             setFilter({
//               status: filter.status,
//             });
//             localStorage.setItem(
//               "project-filter",
//               JSON.stringify({
//                 status: filter.status,
//               })
//             );
//           }}
//           className="space-x-2 border-dashed text-gray-500"
//         >
//           <FunnelIcon className="h-4 w-4 text-gray-400" />
//           <span className="font-normal text-gray-400">{`Remove`}</span>
//         </Button>

//       </div>
//       {/* <div>
//         <Button
//           variant={"secondary"}
//           disabled={
//             !filter.customerIds?.length || filter.customerIds.length === 0
//           }
//         >
//           Save as View
//         </Button>
//       </div> */}
//     </div>
//   );
// }

function OwnerFilter({
  filter,
  setFilter,
}: {
  filter: ProjectsFilter;
  setFilter: React.Dispatch<React.SetStateAction<ProjectsFilter>>;
}) {
  const app = useRealmApp();
  const { data: taskers } = useGetTaskersForInstance();
  const [open, setOpen] = useState(false);
  return (
    <div className="inline-flex h-8 items-center overflow-hidden rounded-md border border-gray-300 pl-2">
      <div className="flex h-full items-center divide-x divide-gray-300 text-sm">
        <div className="flex h-full items-center space-x-1.5 pr-2">
          <UserIcon className="h-4 w-4 shrink-0" />
          <span>Owner</span>
        </div>
        <div className="h-full">
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger className="h-full px-2 hover:bg-gray-50 focus:outline-none">
              {!!filter.ownerUserIds && filter.ownerUserIds?.length > 0 ? (
                <div className="truncate">
                  {filter.ownerUserIds.length > 1
                    ? "Multiple"
                    : taskers?.find(
                        (t) =>
                          !!filter.ownerUserIds &&
                          t._id.toString() === filter.ownerUserIds[0]
                      )?.email}
                </div>
              ) : (
                <div className="font-normal text-gray-300">Owner</div>
              )}
            </PopoverTrigger>
            <PopoverContent
              className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
              align="start"
            >
              <Command>
                <CommandInput placeholder="Search tasker..." className="h-9" />
                <CommandEmpty>No tasker found.</CommandEmpty>
                <CommandGroup>
                  {/* {filter.ownerUserIds?.length && (

                  )} */}
                  {taskers
                    // ?.filter((t) => t.userType === "fullUser")
                    ?.map((tasker) => (
                      <CommandItem
                        key={tasker.email}
                        value={
                          // tasker.userType === "fullUser"
                          tasker.name
                        }
                        onSelect={() => {
                          console.log("selected");
                          setFilter({
                            ...filter,
                            ownerUserIds: [
                              ...(filter.ownerUserIds ?? []),
                              String(tasker._id),
                            ],
                          });
                          // if (tasker.userType === "fullUser") {
                          //   // localStorage.setItem(
                          //   //   "project-filter",
                          //   //   JSON.stringify({
                          //   //     ...filter,
                          //   //     ownerUserIds: [String(tasker._id)],
                          //   //   })
                          //   // );
                          // }
                          setOpen(false);
                        }}
                        className="gap-2"
                      >
                        <Checkbox
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          checked={filter.ownerUserIds?.includes(
                            tasker._id.toString()
                          )}
                          onCheckedChange={(checked) => {
                            if (checked) {
                              setFilter({
                                ...filter,
                                ownerUserIds: [
                                  ...(filter.ownerUserIds ?? []),

                                  tasker._id.toString(),
                                ],
                              });
                            } else {
                              // console.log(
                              //   filter.ownerUserIds?.filter(
                              //     (id) => id !== tasker._id.toString()
                              //   )
                              // );
                              setFilter({
                                ...filter,
                                ownerUserIds: filter.ownerUserIds?.filter(
                                  (id) => id !== tasker._id.toString()
                                ),
                              });
                            }
                          }}
                        />

                        <div className="flex items-center space-x-2">
                          <span className="truncate">{tasker.name}</span>
                        </div>
                      </CommandItem>
                    ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
        </div>

        <button
          className="flex h-full items-center justify-center px-1.5 hover:bg-gray-50 focus:outline-none"
          onClick={() => {
            setFilter({
              ...filter,
              ownerUserIds: [],
            });
            // localStorage.setItem(
            //   "project-filter",
            //   JSON.stringify({
            //     ...filter,
            //     ownerUserIds: [],
            //   })
            // );
            // setSelectedTasker(undefined);
          }}
        >
          <XMarkIcon className="h-3 w-3 shrink-0 text-gray-400" />
        </button>
      </div>
    </div>
  );
}

function CustomerFilter({
  filter,
  setFilter,
}: {
  filter: ProjectsFilter;
  setFilter: React.Dispatch<React.SetStateAction<ProjectsFilter>>;
}) {
  const [options, setOptions] = useState<CustomerData[]>([]);

  const [customerSearch, setCustomerSearch] = useState("");

  const { debouncedValue: debouncedSearch, isTyping } = useDebounce(
    customerSearch,
    500
  );
  const { paginatedCustomers, isError, isFetching, isLoading, isSuccess } =
    useGetCustomersWithDebouncing({
      search: debouncedSearch,
    });

  useEffect(() => {
    if (paginatedCustomers) {
      setOptions(paginatedCustomers.customers);
    }
  }, [paginatedCustomers]);
  const [open, setOpen] = useState(false);

  const [selectedCustomer, setSelectedCustomer] = useState<
    CustomerData | undefined
  >(undefined);

  return (
    <div className="inline-flex h-8 items-center overflow-hidden rounded-md border border-gray-300 pl-2">
      <div className="flex h-full items-center divide-x divide-gray-300 text-sm">
        <div className="flex h-full items-center space-x-1.5 pr-2">
          <BuildingOfficeIcon className="h-4 w-4 shrink-0" />
          <span>Customer</span>
        </div>
        <div className="h-full">
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger className="h-full px-2 hover:bg-gray-50 focus:outline-none">
              {!!selectedCustomer ? (
                <div className="truncate">{selectedCustomer.name}</div>
              ) : (
                <div className="font-normal text-gray-300">Customer</div>
              )}
            </PopoverTrigger>
            <PopoverContent
              className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
              align="start"
              sideOffset={6}
            >
              <Command
                filter={(value, search) => {
                  if (value === "new") {
                    return 1;
                  }
                  const customerName =
                    options.find((c) => c._id.toString() === value)?.name ?? "";
                  return customerName
                    .toLowerCase()
                    .includes(search.toLowerCase())
                    ? 1
                    : 0;
                }}
              >
                <CommandInput
                  value={customerSearch}
                  onValueChange={(s) => {
                    setCustomerSearch(s);
                  }}
                  placeholder="Search customer..."
                  className="h-9"
                />
                <CommandEmpty>No customer found.</CommandEmpty>
                <CommandGroup>
                  {options?.map((customer) => (
                    <CommandItem
                      key={customer._id?.toString()}
                      value={customer._id.toString()}
                      onSelect={() => {
                        setSelectedCustomer(customer);
                        setFilter((prev) => ({
                          ...prev,
                          customerIds: [customer._id.toString()],
                        }));
                        setOpen(false);
                        localStorage.setItem(
                          "project-filter",
                          JSON.stringify({
                            ...filter,
                            customerIds: [customer._id.toString()],
                          })
                        );
                      }}
                      className="gap-2"
                    >
                      <span className="truncate">{customer.name}</span>
                      <CheckIcon
                        className={cn(
                          "ml-auto h-4 w-4 shrink-0",
                          selectedCustomer?._id?.toString() ===
                            customer?._id?.toString()
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                    </CommandItem>
                  ))}
                  {/* {isLoading && <div>Loading..</div>} */}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
        </div>

        <button
          className="flex h-full items-center justify-center px-1.5 hover:bg-gray-50 focus:outline-none"
          onClick={() => {
            setFilter({
              ...filter,
              customerIds: [],
            });
            localStorage.setItem(
              "project-filter",
              JSON.stringify({
                ...filter,
                customerIds: [],
              })
            );
            setSelectedCustomer(undefined);
          }}
        >
          <XMarkIcon className="h-3 w-3 shrink-0 text-gray-400" />
        </button>
      </div>
    </div>
  );
}

function StatusFilter({
  filterGroup,
  setFilterGroup,
}: {
  filterGroup: FilterGroup;
  setFilterGroup: React.Dispatch<React.SetStateAction<FilterGroup>>;
}) {
  const selectedValue = filterGroup.children.find(
    (child) => child.field === "project-status"
  )?.value as string[] | undefined;
  return (
    <Select
      value={selectedValue?.length ? selectedValue[0] : undefined}
      onValueChange={(value) => {
        setFilterGroup({
          ...filterGroup,
          children: filterGroup.children.map((child) => {
            if (child.field === "project-status") {
              return {
                ...child,
                value: [value],
              };
            }
            return child;
          }),
        });
      }}
    >
      <SelectTrigger
        className={clsx(
          `mt-0 flex h-8 w-fit max-w-full gap-2 bg-white px-2 py-0 text-sm`
        )}
      >
        <div className="flex h-full items-center space-x-2 divide-x">
          {/* <div>Status</div> */}
          {/* <PieChartIcon className="h-4 w-4 shrink-0 text-gray-700" /> */}
          <SelectValue
            asChild
            className="flex h-full items-center truncate text-xs"
          >
            <div className="inline-flex h-full items-center pl-2">
              {selectedValue?.length
                ? getPhaseStatus(selectedValue[0] as PhaseStatus)
                : "All"}
            </div>
          </SelectValue>
        </div>
      </SelectTrigger>
      <SelectContent align="start" className="min-w-[120px]">
        <SelectGroup>
          {/* <SelectItem value={"all"}>All</SelectItem> */}
          <SelectItem value={"In Progress"}>
            {getPhaseStatus("In Progress")}
          </SelectItem>
          <SelectItem value={"Completed"}>
            {getPhaseStatus("Completed")}
          </SelectItem>
          <SelectItem value={"Paused"}>{getPhaseStatus("Paused")}</SelectItem>
          <SelectItem value={"Canceled"}>
            {getPhaseStatus("Canceled")}
          </SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

// function ViewSelector({
//   view,
//   setView,
//   options,
// }: {
//   view: Option;
//   setView: (view: Option) => void;
//   options: Option[];
// }) {
//   const [open, setOpen] = useState(false);
//   const { data: projectViews } = useGetProjectViews();
//   return (
//     <Popover open={open} onOpenChange={setOpen}>
//       <PopoverTrigger asChild>
//         <Button
//           variant="secondary"
//           role="combobox"
//           aria-expanded={open}
//           className="min-w-fit space-x-2 truncate px-2"
//         >
//           <Square3Stack3DIcon className="h-4 w-4 shrink-0" />
//           <span>{view.name}</span>
//           <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
//         </Button>
//       </PopoverTrigger>
//       <PopoverContent
//         className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
//         align="start"
//       >
//         <Command>
//           <CommandInput placeholder="Search view..." className="h-9" />
//           <CommandEmpty>No view found.</CommandEmpty>
//           <CommandGroup>
//             {options.map((option) => (
//               <CommandItem
//                 key={option._id}
//                 value={option.name}
//                 onSelect={() => {
//                   setView(option);
//                   setOpen(false);
//                 }}
//                 className="gap-2"
//               >
//                 <div className="flex items-center space-x-2">
//                   {/* <div
//                     className={"h-2 w-2 shrink-0 rounded-full bg-slate-700"}
//                   /> */}
//                   <span className="truncate">{option.name}</span>
//                 </div>
//                 <CheckIcon
//                   className={cn(
//                     "ml-auto h-4 w-4 shrink-0",
//                     view._id === option._id ? "opacity-100" : "opacity-0"
//                   )}
//                 />
//               </CommandItem>
//             ))}
//           </CommandGroup>
//         </Command>
//       </PopoverContent>
//     </Popover>
//   );
// }
