import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CustomFieldValueData } from "src/api/CustomFields/custom-fields";
import { useAccessToken } from "src/api/useAccessToken";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { LaunchTaskers } from "src/pages/Services/Projects/NewProject/NewProject";
import { useRealmApp } from "src/store/RealmApp";
import {
  CompanyFilter,
  Logic,
  ProjectOwnerFilter,
  ProjectStatusFilter,
  ProjectTemplateFilter,
  SpaceFilter,
} from "src/utils/filters";
import { InternalPhaseUpdateData, PhaseStatus } from "../SharedSpace/phases";
import { sharedSpaceKeys } from "../SharedSpace/spaces";

export const projectKeys = {
  projects: () => ["projects"] as const,
  projectsv2: (
    baselineFilterGroup: ProjectsV2FilterGroup,
    filterGroup: ProjectsV2FilterGroup
  ) => ["projectsv2", baselineFilterGroup, filterGroup] as const,
  projectDetails: (
    baselineFilterGroup: ProjectsV2FilterGroup,
    filterGroup: ProjectsV2FilterGroup
  ) => ["projectDetails", baselineFilterGroup, filterGroup] as const,
  projectsForExternalUser: (slug: string) =>
    ["projects", "slug", slug] as const,
  // projects: (userId: string) => ["projects", "user", userId] as const,
  members: (phaseId: string) => ["projects", phaseId, "members"] as const,
  filterProjects: (filter: ProjectsFilter) => ["projects", filter],
};

export type ProjectsFilter = {
  customerIds?: string[];
  status: PhaseStatus[];
  ownerUserIds?: string[];
  internalMemberIds?: string[];
};

type TasksBreakdown = {
  toDo: {
    total: number;
    overdue: number;
  };
  inProgress: {
    total: number;
    overdue: number;
  };
  completed: {
    onTime: number;
    late: number;
  };
  canceled: number;
};

export type OnboardingProjectData = {
  _id: string;
  type: "Customer Onboarding" | "Simple";
  status: PhaseStatus;
  name: string;
  revenue?: number;
  ownerUser: {
    _id: string;
    name: string;
    email: string;
  };
  contactUser: {
    _id: string;
    name: string;
    email: string;
  };
  isActive: boolean;
  createdAt: string;
  // phase: {
  //   _id: string;
  // }
  customer?: {
    _id: string;
    name: string;
    revenue?: number;
  };
  sharedSpace?: {
    _id: string;
    name: string;
  };
  journey: {
    _id: string;
    name: string;
    completedTasks: number;
    totalTasks: number;
  };
  keyDates: {
    startedDate: string;
    plannedCompletionDate: string;
    forecastedCompletionDate: string;
    completionDate: string;
  };
  // allTasksBreakdown: {
  //   internal: TasksBreakdown;
  //   external: TasksBreakdown;
  // };
  // userTasksBreakdown: TasksBreakdown;
  stages: {
    _id: string;
    name: string;
    status: string;
    completionPercent: number;
    completionDate: string;
  }[];
};

export type ProjectMembers = {
  internal: InternalProjectMember[];
  external: ExternalProjectMember[];
};

export type InternalProjectMember = InternalSharedSpaceMember & {
  role: ProjectMembership;
};

export type ExternalProjectMember = ExternalSharedSpaceMember & {
  role: ProjectMembership;
};

export type InternalSharedSpaceMember = {
  _id: string;
  name: string;
  email: string;
};

export type ExternalSharedSpaceMember =
  | {
      _id: string;
      name: string;
      email: string;
      userType: "fullUser";
    }
  | {
      email: string;
      userType: "invitedUser";
      inviteId: string;
    };

type SharedSpaceMembers = {
  internal: InternalSharedSpaceMember[];
  external: ExternalSharedSpaceMember[];
};

export const useGetSharedSpaceMembers = (sharedSpaceId: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    sharedSpaceKeys.members(sharedSpaceId),
    async (): Promise<SharedSpaceMembers> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/sharedSpaceMembers`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            sharedSpaceId,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: 1000 * 60 * 3 * 1,
      cacheTime: Infinity,
    }
  );
};

export const useGetProjectMembers = (phaseId: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    projectKeys.members(phaseId),
    async (): Promise<ProjectMembers> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/projectMembers`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            phaseId,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: 1000 * 60 * 3 * 1,
      cacheTime: Infinity,
    }
  );
};

export type ProjectData = {
  _id: string;
  name: string;
  stages: {
    _id: string;
    name: string;
  }[];
  statusGroupId: string;
  isExternal: boolean;
  journeyId: string;
  spaceId: string;
};

export const useGetProjects = () => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    projectKeys.projects(),
    async (): Promise<ProjectData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/projects`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            instanceId: app.currentUser.customData.instanceId.$oid,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: 1000 * 60 * 10 * 1,
      cacheTime: Infinity,
    }
  );
};

export type ProjectDataV2 = {
  _id: string;
  name: string;
  status: PhaseStatus;
  customer: {
    _id: string;
    name: string;
  };
  isActive: boolean;
  revenue?: number;
  createdAt: string;
  updatedAt: string;
  // keyDates: {
  //   completionDate?: string;
  // };
  owner: {
    _id: string;
    name: string;
    email: string;
  };
  customFields?: CustomFieldValueData[];
};

export type ProjectsV2FilterGroup = {
  logic: Logic;
  children: ProjectsV2Filter[];
};

export type ProjectsV2Filter =
  | ProjectOwnerFilter
  | SpaceFilter
  | ProjectStatusFilter
  | CompanyFilter
  | ProjectTemplateFilter;

export const useGetProjectsV2 = (
  baselineFilter: ProjectsV2FilterGroup,
  filter: ProjectsV2FilterGroup
) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    projectKeys.projectsv2(baselineFilter, filter),
    async (): Promise<{
      noOfProjects: number;
      projects: ProjectDataV2[];
    }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/projectsv2`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            instanceId: app.currentUser.customData.instanceId.$oid,
            baselineFilter: JSON.stringify(baselineFilter),
            filter: JSON.stringify(filter),
          },
        }
      );
      return res.data;
    },
    {
      staleTime: 1000 * 60 * 10 * 1,
      cacheTime: Infinity,
    }
  );
};

export type ProjectDetailsData = {
  _id: string;
  ownerUser?: {
    _id: string;
    name: string;
    email: string;
  };
  journey?: {
    _id: string;
    name: string;
    completedTasks: number;
    totalTasks: number;
  };
  keyDates?: {
    startedDate: string;
    plannedCompletionDate: string;
    forecastedCompletionDate: string;
    completionDate?: string;
  };
  template?: {
    _id: string;
    name: string;
  };
  stages?: {
    _id: string;
    name: string;
    status: string;
    completionDate?: string;
    isUnassigned?: boolean;
    completionPercent: number;
    totalTasks: number;
  };
  internalUpdates?: InternalPhaseUpdateData[];
};

export const useGetProjectsDetails = (
  baselineFilter: ProjectsV2FilterGroup,
  filter: ProjectsV2FilterGroup
) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    projectKeys.projectDetails(baselineFilter, filter),
    async (): Promise<{
      noOfProjects: number;
      projects: ProjectDetailsData[];
    }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/projectsDetails`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            instanceId: app.currentUser.customData.instanceId.$oid,
            baselineFilter: JSON.stringify(baselineFilter),
            filter: JSON.stringify(filter),
          },
        }
      );
      return res.data;
    },
    {
      staleTime: 1000 * 60 * 10 * 1,
      cacheTime: Infinity,
    }
  );
};

export const useGetProjectsForExternalUser = (slug: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    projectKeys.projectsForExternalUser(slug),
    async (): Promise<ProjectData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/projects`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            slug: slug,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: 1000 * 60 * 10 * 1,
      cacheTime: Infinity,
    }
  );
};

export const useAddInternalMemberToProject = () => {
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();
  const snackbarCtx = useSnackBar();
  return useMutation({
    mutationFn: async (params: {
      userId: string;
      phaseId: string;
      role: ProjectMembership;
    }): Promise<{ message: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/addInternalMemberToProject`,
        params,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error updating membership", "error");
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(projectKeys.members(variables.phaseId));
    },
  });
};

export const useUpdateProjectInvite = () => {
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();
  const snackbarCtx = useSnackBar();
  return useMutation({
    mutationFn: async ({
      invite,
      phase,
    }: {
      invite: {
        _id: string;
        role: ProjectMembership;
      };
      phase: {
        _id: string;
      };
    }): Promise<{ message: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/updateProjectInvite`,
        invite,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error updating membership", "error");
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(projectKeys.members(variables.phase._id));
    },
  });
};

export const useUpdateProjectMembership = () => {
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();
  const snackbarCtx = useSnackBar();
  return useMutation({
    mutationFn: async (membership: {
      phaseId: string;
      userId: string;
      role: ProjectMembership;
    }): Promise<{ message: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/updateProjectMembership`,
        membership,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error updating membership", "error");
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(projectKeys.members(variables.phaseId));
    },
  });
};

export const useGetOnboardingProjects = (filter: ProjectsFilter) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    projectKeys.filterProjects(filter),
    async (): Promise<OnboardingProjectData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/customerOnboardingProjects`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            filter: JSON.stringify(filter),
          },
        }
      );
      return res.data;
    },
    {
      staleTime: 1000 * 60 * 3 * 1,
      cacheTime: Infinity,
    }
  );
};

// export const useGetOboardingOverviewForUser = (userId?: string) => {
//   const app = useRealmApp();
//   return useQuery(
//     projectKeys.projects(userId ?? "all"),
//     async (): Promise<OnboardingOverviewData[]> => {
//       const res = await axios.get(
//         // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
//         `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/onboardingsOverviewForUser`,
//         {
//           headers: {
//             Authorization: `Bearer ${app.currentUser?.accessToken}`,
//             Accept: "application/json",
//           },
//           params: {
//             userId,
//           },
//         }
//       );
//       return res.data;
//     },
//     {
//       staleTime: 1000 * 60 * 3 * 1,
//       cacheTime: Infinity,
//     }
//   );
// };

// export type MinimalPhaseData = {
//   _id: string;
//   templateId: string;
//   templateName: string;
//   timelineId: string;
//   sharedSpaceId: string;
//   journeyTemplateIds: string[];
//   name: string;
//   type: PhaseTemplateType;
//   createdBy: {
//     _id: string;
//     name: string;
//     email: string;
//   };
//   contactUser: {
//     _id: string;
//     name: string;
//     email: string;
//   };
//   isActive: boolean;
//   completionDate?: string;
//   createdAt: string;
//   updatedAt: string;
// };

// export type PhaseData = {
//   _id: string;
//   templateId: string;
//   templateName: string;
//   sharedSpaceId: string;
//   timelineId: string;
//   journeyTemplates: {
//     _id: string;
//     name: string;
//   }[];
//   instance: {
//     name: string
//   }
//   customer: {
//     name: string
//   }
//   name: string;
//   type: PhaseTemplateType;
//   createdBy: {
//     _id: string;
//     name: string;
//     email: string;
//   };
//   contactUserId: {
//     _id: string;
//     name: string;
//     email: string;
//   };
//   knowledgeResources: {
//     _id: string;
//     name: string;
//   }[];
//   isActive: boolean;
//   completionDate?: string;
//   createdAt: string;
//   updatedAt: string;
// };

export type InviteEmail = {
  subject: string;
  message: string;
  signature: string;
};

export type CustomFieldRecord =
  | {
      _id: string;
      value: string | null;
    }
  | {
      _id: string;
      value: number | null;
    }
  | {
      _id: string;
      value: boolean | null;
    }
  | {
      _id: string;
      value: string[] | null;
    };

export type ProjectMembership = "member" | "subscriber" | "owner";

type LaunchProjectMembers = {
  internal: { _id: string; role: ProjectMembership }[];
  external: {
    email: string;
    sendInviteEmail: boolean;
    role: ProjectMembership;
  }[];
};

export const useLaunchExternalTemplateProject = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (project: {
      name: string;
      customer: {
        _id: string;
      };
      dealId?: string;
      template: {
        _id: string;
        taskers: {
          internalTaskers: LaunchTaskers;
          externalTaskers: LaunchTaskers;
        };
      };
      projectMembers: LaunchProjectMembers;
      // statusGroupId: string;
      spaceId: string;
      // revenue?: number;
      ownerUserId: string;
      inviteEmail?: InviteEmail;
      customFields?: CustomFieldRecord[];
    }): Promise<{ message: string; phaseId: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/project`,
        {
          instanceId: app.currentUser.customData.instanceId.$oid,
          ...project,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error launching project", "error");
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["projects"]);
      queryClient.invalidateQueries(["projectsv2"]);
      queryClient.invalidateQueries(["projectDetails"]);
      if (data.phaseId) {
        navigate(`/projects/${data.phaseId}/tasks`);
      }
    },
  });
};

export const useLaunchInternalBlankProject = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (project: {
      name: string;
      projectMembers: LaunchProjectMembers;
      // statusGroupId: string;
      spaceId: string;
      assignedStartDate?: string;
      ownerUserId?: string;
    }): Promise<{ message: string; phaseId: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/project`,
        {
          instanceId: app.currentUser.customData.instanceId.$oid,
          ...project,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error launching project", "error");
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["projects"]);
      queryClient.invalidateQueries(["projectsv2"]);
      queryClient.invalidateQueries(["projectDetails"]);
      if (data.phaseId) {
        navigate(`/projects/${data.phaseId}/tasks`);
      }
    },
  });
};

export const useLaunchExternalBlankProject = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (project: {
      name: string;
      customer: {
        _id: string;
      };
      projectMembers: LaunchProjectMembers;
      // statusGroupId: string;
      spaceId: string;
      assignedStartDate?: string;
      ownerUserId?: string;
      // revenue?: number;
      inviteEmail?: InviteEmail;
    }): Promise<{ message: string; phaseId: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/project`,
        {
          instanceId: app.currentUser.customData.instanceId.$oid,
          ...project,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error launching project", "error");
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["projects"]);
      queryClient.invalidateQueries(["projectsv2"]);
      queryClient.invalidateQueries(["projectDetails"]);
      if (data.phaseId) {
        navigate(`/projects/${data.phaseId}/tasks`);
      }
    },
  });
};

// export const useLaunchBlankExternalProject = () => {
//   const app = useRealmApp();
//   const snackbarCtx = useSnackBar();
//   const queryClient = useQueryClient();
//   const navigate = useNavigate();
//   const getValidAccessToken = useAccessToken();

//   return useMutation({
//     mutationFn: async (project: {
//       customer: {
//         _id: string;
//       };
//       sharedSpaceMembers: {
//         internal: string[];
//         external: NewProjectExternalMember[];
//       };
//       // contactUserId: string;
//       assignedStartDate?: string;
//       statusGroupId: string;
//       revenue?: number;
//       inviteEmail: InviteEmail;
//       collaborationType: "external";
//       name: string;
//     }): Promise<{ message: string; phaseId: string }> => {
//       const accessToken = await getValidAccessToken();
//       const res = await axios.post(
//         `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/project`,
//         {
//           instanceId: app.currentUser.customData.instanceId.$oid,
//           ...project,
//         },
//         {
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );
//       return res.data;
//     },
//     onError: () => {
//       snackbarCtx.showSnackbar("Error launching project", "error");
//     },
//     onSuccess: (data) => {
//       if (data.phaseId) {
//         // queryClient.invalidateQueries(
//         //   phaseKeys.phases(variables.sharedSpaceId)
//         // );
//         queryClient.invalidateQueries(["projects"]);
//         navigate(`/projects/${data.phaseId}/tasks`);
//       }

//       // snackbarCtx.showSnackbar("Successfully duplicated journey template");
//     },
//   });
// };

export const useLinkProjects = () => {
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (link: {
      parentProjectId: string;
      childProjectId: string;
      parentStageId: string;
    }): Promise<{ message: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/linkProjects`,
        link,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error linking projects", "error");
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["journeys"]);
    },
  });
};

// export const useCreateOnboardingPhaseWithJourney = () => {
//   const app = useRealmApp();
//   const snackbarCtx = useSnackBar();
//   const queryClient = useQueryClient();
//   const navigate = useNavigate();

//   return useMutation({
//     mutationFn: async (phase: {
//       sharedSpaceId: string;
//       type: "Customer Onboarding";
//       contactUserId?: string;
//       isActive: boolean;
//     }): Promise<{ message: string; id: string }> => {
//       const res = await axios.post(
//         `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/phase`,
//         phase,
//         {
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${app.currentUser?.accessToken}`,
//           },
//         }
//       );
//       return res.data;
//     },
//     onError: () => {
//       snackbarCtx.showSnackbar("Error launching phase", "error");
//     },
//     onSuccess: (data, variables) => {
//       if (data.id && variables.sharedSpaceId) {
//         queryClient.invalidateQueries(
//           phaseKeys.phases(variables.sharedSpaceId)
//         );
//         navigate(`phases/${data.id}`);
//       }
//       // snackbarCtx.showSnackbar("Successfully duplicated journey template");
//     },
//   });
// };

// export const useUpdatePhase = () => {
//   const app = useRealmApp();
//   const snackbarCtx = useSnackBar();
//   const queryClient = useQueryClient();
//   const navigate = useNavigate();

//   return useMutation({
//     mutationFn: async (phase: {
//       _id: string;
//       name?: string;
//       isActive?: boolean;
//       sharedSpaceId: string;
//       journeyTemplateIds?: string[];
//     }): Promise<{ message: string }> => {
//       const res = await axios.post(
//         `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/updatePhase`,
//         {
//           _id: phase._id,
//           name: phase.name,
//           isActive: phase.isActive,
//           journeyTemplateIds: phase.journeyTemplateIds,
//           // journeyTemplateIds:
//           //   phaseTemplate.jourenyTemplates?.journeyTemplateIds,
//           // instanceId: app.currentUser.customData.instanceId.$oid,
//         },
//         {
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${app.currentUser?.accessToken}`,
//           },
//         }
//       );
//       // console.log(res);
//       return res.data;
//     },
//     // onMutate: (variables) => {
//     //   const oldData = queryClient.getQueryData(
//     //     phaseTemplateKeys.phaseTemplate(variables._id)
//     //   );
//     //   queryClient.setQueryData(
//     //     phaseTemplateKeys.phaseTemplate(variables._id),
//     //     (oldData: PhaseTemplateData | undefined) => {
//     //       if (!oldData) return;
//     //       return {
//     //         ...oldData,
//     //         name: variables.name || oldData.name,
//     //         journeyTemplates:
//     //           variables?.jourenyTemplates?.newJourneyTemplates ??
//     //           oldData.journeyTemplates,
//     //       };
//     //     }
//     //   );
//     //   return { oldData }
//     // },
//     onError: (err, variables, context) => {
//       // queryClient.setQueryData(
//       //   phaseTemplateKeys.phaseTemplate(variables._id),
//       //   context?.oldData)
//       snackbarCtx.showSnackbar("Error updating phase", "error");
//     },
//     onSuccess: (data, variables) => {
//       if (variables._id) {
//         queryClient.invalidateQueries(
//           phaseKeys.phases(variables.sharedSpaceId)
//         );
//         queryClient.invalidateQueries(phaseKeys.phase(variables._id));
//       }
//       // if (data.id) {
//       //   navigate(`/templates/phases/${data.id}`);
//       // }
//       // snackbarCtx.showSnackbar("Successfully duplicated journey template");
//     },
//   });
// };
