import { UserIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Button } from "src/components/ui/Buttons/Button";
import { Modal } from "src/components/ui/Modals/Modal";
import ModalHeading from "src/components/ui/Modals/ModalHeading";
import { TemplatePersonasEdit } from "./TemplatePersonasEdit";

type Props = {};

export const TemplateSettingsButton = (props: Props) => {
  const [isTemplateSettingsModalOpen, setIsTemplateSettingsModalOpen] =
    useState(false);

  return (
    <>
      <Button
        onClick={() => {
          setIsTemplateSettingsModalOpen(true);
        }}
        size="xs"
        fill={"outline"}
        leftIcon={<UserIcon className="mr-2 h-4 w-4" />}
        text="Roles"
        color="gray"
      />
      <Modal
        isOpen={isTemplateSettingsModalOpen}
        closeModal={() => {
          setIsTemplateSettingsModalOpen(false);
        }}
      >
        <div className="w-[500px] p-6">
          <ModalHeading
            title="Roles"
            subtitle="Add roles to your project template"
          />
          <div className="mt-6">
            <TemplatePersonasEdit />
          </div>
        </div>
      </Modal>
    </>
  );
};
