import { Button, Link, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useRealmApp } from "../../../store/RealmApp";
// import { RegistrationContext } from "../Register/RegistrationContext";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import posthog from "posthog-js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InstanceDetailsObj } from "../../../types/Authentication";
import Header from "../Header";

const InstanceDetailsSchema = yup.object().shape({
  instanceName: yup
    .string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Company name is required"),
});

const initialValues: InstanceDetailsObj = {
  instanceName: "",
};

type Props = {
  invitesLength: number;
  prevStep: () => void;
};

const InstanceDetailsForm = (props: Props) => {
  const app = useRealmApp();
  let navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);

  const [slug, setSlug] = useState("");
  const [slugHasBeenEdited, setSlugHasBeenEdited] = useState(false);

  // const registrationCtx = useContext(RegistrationContext);

  // const handleLogin = async (email: string, password: string) => {
  //   try {
  //     await app.logIn(Realm.Credentials.emailPassword(email, password));

  //     // TODO: After logging in the user,
  //   } catch (err) {
  //     // TODO: handle error with snackbar https://github.com/mongodb-university/realm-tutorial-web/blob/final/src/components/LoginScreen.js
  //   }
  // };

  // const handleRegistrationAndLogin = async (
  //   email: string,
  //   password: string
  // ) => {

  //   // TODO: Check if the instance slug is valid

  //   // All the registrration details are valid - registering user

  //   try {
  //     // Register the user and, if successful, log them in
  //     await app.emailPasswordAuth.registerUser({ email, password });
  //     // After successful registration, log in the user
  //     return await handleLogin(email, password);
  //   } catch (err) {
  //     // TODO: handle error with snackbar https://github.com/mongodb-university/realm-tutorial-web/blob/final/src/components/LoginScreen.js
  //   }
  // };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: InstanceDetailsSchema,
    onSubmit: (values) => {
      // Create instance
      // console.log(values);
      setDisabled(true);
      // Create instance
      try {
        app.currentUser.functions
          .createInstance({
            name: values.instanceName,
            slug: slug
          })
          .then((result: any) => {
            // console.log(result);
            app.currentUser.refreshCustomData().then(() => {
              if (app.currentUser?.customData?.instanceId) {
                posthog.people.set({
                  instanceId: app.currentUser.customData.instanceId.$oid,
                });
              }
              navigate("/");
            });
          });
      } catch (error: any) {
        setDisabled(false);
      }
      // const { email, password } = registrationCtx.personalDetails;
      // console.log(email, password)
      // handleRegistrationAndLogin(email, password);
      // alert(JSON.stringify(values, null, 2));
    },
  });

  useEffect(() => {
    const instanceName = formik.values.instanceName;

    if (instanceName.trim() === "" && !slugHasBeenEdited) {
      setSlug("")
    }

    if (instanceName.trim() !== "" && disabled) {
      setDisabled(false);
    }

    if (!slugHasBeenEdited && instanceName.trim() !== "") {
      let editedSlug = instanceName.toLowerCase().replaceAll(" ", "-");
      setSlug(editedSlug);
    }
  }, [formik.values.instanceName]);

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
      <Stack spacing={3} alignItems="center">
        <Header
          heading={"Company Details"}
          subHeading={"Setup your workspace!"}
        />
        {props.invitesLength > 0 && (
          <Stack
            sx={{ width: "100%" }}
            direction="row"
            justifyContent={"flex-start"}
            alignItems="center"
          >
            <Link
              color="primary"
              component={"button"}
              variant="subtitle2"
              onClick={() => {
                props.prevStep();
              }}
            >
              <Stack
                sx={{ width: "100%" }}
                direction="row"
                justifyContent={"flex-start"}
                alignItems="center"
              >
                <ArrowLeftIcon /> <Typography>Back</Typography>
              </Stack>
            </Link>
          </Stack>
        )}

        <TextField
          fullWidth
          id="instanceName"
          name="instanceName"
          label="Company Name"
          value={formik.values.instanceName}
          onChange={formik.handleChange}
          error={
            formik.touched.instanceName && Boolean(formik.errors.instanceName)
          }
          helperText={formik.touched.instanceName && formik.errors.instanceName}
        />
        <TextField
          fullWidth
          disabled={
            formik.values.instanceName.trim() === ""
          }
          id="instanceSlug"
          name="instanceSlug"
          label="Company ID"
          value={slug}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (!slugHasBeenEdited) {
              setSlugHasBeenEdited(true);
            }
            const input = event.target.value;
            const regex = /[^A-Za-z0-9_]/g
            setSlug(input.toLowerCase().replaceAll(regex, "-"));
          }}
          // error={
          //   formik.touched.instanceSlug && Boolean(formik.errors.instanceSlug)
          // }
          // helperText={formik.touched.instanceSlug && formik.errors.instanceSlug}
        />
        <Stack direction="row" spacing={2}>
          <Button
            disabled={
              disabled ||
              formik.values.instanceName.trim() === "" ||
              slug.trim() === ""
            }
            variant="contained"
            size="large"
            type="submit"
            color="secondary"
            // sx={{ width: "40%" }}
          >
            Create Workspace
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default InstanceDetailsForm;
