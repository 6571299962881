import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { getSubdomain } from "src/App";
import { useGetInstanceSettingsFromSlug } from "src/api/General/settings";
import { useGetStatusGroupsFromSlug } from "src/api/General/status-groups";
import { Loading } from "src/components/ui/loading";
import TaskPanel from "src/pages/Services/Tasks/TaskPanel";
import { classNames } from "src/utils/functions/classes";
import { getRGBColor } from "src/utils/functions/embed";
import ChatPanel from "../Chats/chat-panel";
import NewTaskForExternalUser from "../Services/Tasks/NewTaskForExternalUser";
import { useStatusGroups } from "../status-groups/status-groups-context-provider";

type Props = {};

export default function ExternalLayout(props: Props) {
  const slug = getSubdomain();
  // const slug = "sero-demo";
  const { data: settings } = useGetInstanceSettingsFromSlug(String(slug));
  const [color, setColor] = useState(`${getRGBColor("#000000", "primary")}`);
  const { data: statusGroups } = useGetStatusGroupsFromSlug(String(slug));
  const statusGroupsCtx = useStatusGroups();
  useEffect(() => {
    if (!!settings) {
      setColor(
        `{${getRGBColor(
          settings.embed.primaryColor ? settings.embed.primaryColor : "#000000",
          "primary"
        )}}`
      );
    }
  }, [settings]);

  useEffect(() => {
    if (!!statusGroups?.length) {
      statusGroupsCtx.dispatch({
        type: "status_groups_update",
        statusGroups: statusGroups,
      });
    }
  }, [statusGroups]);

  return (
    <>
      {!!statusGroups ? (
        <>
          {statusGroups.length > 0 ? (
            <div className="flex h-screen flex-1 flex-col overflow-auto">
              <div
                className={classNames(
                  "flex flex-1 flex-col overflow-auto",
                  !!settings?.embed.fontType
                    ? settings.embed.fontType === "sans"
                      ? "font-sans"
                      : settings.embed.fontType === "serif"
                      ? "font-serif"
                      : "font-mono"
                    : "font-sans"
                )}
              >
                <style>
                  :root
                  {color}
                </style>
                {/* <title>Sign In</title> */}
                <Outlet />
                <TaskPanel />
                <ChatPanel />
                <NewTaskForExternalUser slug={String(slug)} />
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center pt-[30vh]">
              There was an error. Please reach out to hello@sero.so.
            </div>
          )}
        </>
      ) : (
        <div>
          <Loading className="mt-[30vh]" />
        </div>
      )}
    </>
  );
}
