import clsx from "clsx";
import React from "react";
import { classNames } from "src/utils/functions/classes";
import { ToolTip } from "../../ToolTip/ToolTip";

type Props = {
  type?: React.HTMLInputTypeAttribute;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onPaste?: React.ClipboardEventHandler<HTMLInputElement>;
  name: string;
  id?: string;
  label?: string;
  placeholder?: string;
  htmlFor?: string;
  error?: boolean;
  helperText?: string | false | undefined;
  RightIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  rightIconOnClick?: () => void;
  rightIconTooltip?: string;
  rightIconDisabled?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement> | undefined;
  isEmbed?: boolean;
  min?: number | string;
  max?: number | string;
  disabled?: boolean;
  required?: boolean;
  onClick?: React.MouseEventHandler<HTMLInputElement> | undefined;
  // rules?: RegisterOptions;React.MouseEventHandler<HTMLInputElement> | undefined
  ref?: React.Ref<HTMLInputElement> | undefined;
  autoComplete?: React.InputHTMLAttributes<HTMLInputElement>["autoComplete"];
};

const TextInput = ({
  type = "text",
  name,
  id,
  label,
  placeholder,
  htmlFor,
  value,
  onChange,
  onPaste,
  error = false,
  helperText = "",
  RightIcon,
  rightIconOnClick,
  rightIconTooltip,
  rightIconDisabled = false,
  onKeyDown,
  onBlur,
  isEmbed = false,
  min,
  max,
  disabled = false,
  required = false,
  onClick,
  ref,
  autoComplete = "off",
}: // rules,
Props) => {
  // const { register } = useForm();
  return (
    <div className="space-y-1">
      {!!label && label.trim() !== "" && (
        <div className="inline-flex items-start space-x-0.5">
          <label
            htmlFor={htmlFor}
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
          {required && <span className={clsx("-mt-0.5 text-md ", isEmbed ? "text-red-500" : "text-primary-main")}>{"*"}</span>}
        </div>
      )}
      <div className="relative flex items-center">
        <input
          // {...register(name, rules)}
          readOnly={!onChange}
          ref={ref}
          required={required}
          disabled={disabled}
          type={type}
          min={min}
          max={max}
          name={name}
          id={id}
          value={value}
          onClick={onClick}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          autoComplete={autoComplete}
          className={classNames(
            isEmbed
              ? "focus:border-embedPrimary focus:ring-embedPrimary"
              : "focus:border-primary-main focus:ring-primary-main",
            "block w-full rounded-md border-gray-300 shadow-sm placeholder:text-gray-300  disabled:bg-gray-100 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm",
            !!RightIcon ? "pr-12" : ""
          )}
          placeholder={placeholder}
        />
        {!!RightIcon && (
          <>
            {rightIconTooltip && (
              // <ToolTip title={rightIconTooltip}>
                <div
                  className={clsx(
                    "absolute flex hover:cursor-pointer right-0 pr-2"
                  )}
                  onClick={() => {
                    if (!rightIconDisabled) {
                      rightIconOnClick && rightIconOnClick();
                    }
                  }}
                >
                  <kbd
                    className={clsx(
                      "inline-flex items-center rounded border px-1.5 py-1 font-sans text-sm font-medium my-auto",
                      !rightIconDisabled
                        ? isEmbed
                          ? "border-embedPrimary text-embedPrimary"
                          : "border-primary-main text-primary-main"
                        : "border-gray-300 text-gray-300",
                      !rightIconDisabled
                        ? isEmbed
                          ? "hover:bg-embedPrimary/10"
                          : "hover:bg-primary-100"
                        : ""
                    )}
                  >
                    <RightIcon className="h-4 w-4" />
                  </kbd>
                </div>
              // </ToolTip>
            )}
            {!rightIconTooltip && (
                <div
                  className={clsx(
                    "absolute inset-y-0 right-0 flex py-1.5 pr-2.5 hover:cursor-pointer"
                  )}
                  onClick={() => {
                    if (!rightIconDisabled) {
                      rightIconOnClick && rightIconOnClick();
                    }
                  }}
                >
                  <kbd
                    className={clsx(
                      "inline-flex items-center",
                      !rightIconDisabled
                        ? isEmbed
                          ? "border-embedPrimary text-embedPrimary"
                          : "border-gray-500 text-gray-500"
                        : "border-gray-300 text-gray-300",
                      !rightIconDisabled
                        ? isEmbed
                          ? ""
                          : "hover:text-gray-700"
                        : ""
                    )}
                  >
                    <RightIcon className="h-5 w-5" />
                  </kbd>
                </div>
            )}
          </>
        )}
      </div>
      {!!helperText && (
        <p
          className={classNames(
            "text-xs",
            error ? "text-red-400" : "text-gray-500"
          )}
        >
          {helperText}
        </p>
      )}
    </div>
  );
};

export default TextInput;
