import { Menu as HeadlessUIMenu, Transition } from "@headlessui/react";
import clsx from "clsx";
import React, { Fragment } from "react";

type Props = {
  children: React.ReactNode;
  items: {
    icon?: React.ComponentType<React.ComponentProps<"svg">>;
    text: string;
    onClick: () => void;
    disabled?: boolean;
  }[];
  side?: "left" | "right";
};

export const Menu = ({ children, items, side = "right" }: Props) => {
  return (
    <HeadlessUIMenu as="div" className="relative inline-block text-left">
      <div>
        <HeadlessUIMenu.Button>{children}</HeadlessUIMenu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <HeadlessUIMenu.Items
          className={clsx(
            "min-w-56 absolute z-10 mt-1 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
            side === "right" ? "right-0" : "left-0"
          )}
        >
          <div className="px-1 py-1">
            {items.map((item) => {
              return (
                <HeadlessUIMenu.Item key={item.text} disabled={item.disabled}>
                  {({ active }) => (
                    <button
                      className={clsx(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-600",
                        "flex w-full items-center whitespace-nowrap rounded-md px-2 py-2 text-sm disabled:cursor-default disabled:text-gray-400"
                      )}
                      onClick={item.onClick}
                    >
                      {item.icon && (
                        <item.icon
                          className="mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                      )}
                      {item.text}
                    </button>
                  )}
                </HeadlessUIMenu.Item>
              );
            })}
            {/* <HeadlessUIMenu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-violet-500 text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    {active ? (
                      <EditActiveIcon
                        className="mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    ) : (
                      <EditInactiveIcon
                        className="mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    )}
                    Edit
                  </button>
                )}
              </HeadlessUIMenu.Item> */}
          </div>
        </HeadlessUIMenu.Items>
      </Transition>
    </HeadlessUIMenu>
  );
};
