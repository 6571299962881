import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CustomFieldValueData } from "src/api/CustomFields/custom-fields";
import { PhaseType } from "src/api/Templates/Phases/phases";
import { useAccessToken } from "src/api/useAccessToken";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { useRealmApp } from "src/store/RealmApp";
import { TaskerType, taskKeys } from "../Tasks/tasks";
import { resourceKeys } from "./files";

export const phaseKeys = {
  phases: (sharedSpaceId: string) =>
    ["phases", "sharedSpace", sharedSpaceId, "all"] as const,
  phase: (_id: string) => ["phase", _id] as const,
  journeys: (phaseId: string) => ["phase", phaseId, "journeys", "all"] as const,
  notificationSettings: (phaseId: string) =>
    ["phase", phaseId, "notificationSettings", "all"] as const,
  allPhaseNotificationSettings: () =>
    ["phase", "notificationSettings", "all"] as const,
  files: (phaseId: string) => ["phase", phaseId, "files", "all"] as const,
  file: (phaseId: string, fileId: string) =>
    ["phase", phaseId, "file", fileId] as const,
  keyDates: (phaseId: string) => ["phase", phaseId, "keyDates"] as const,
  internalUpdates: (phaseId: string) =>
    ["phase", phaseId, "internalUpdates"] as const,
};

export type MinimalPhaseData = {
  _id: string;
  templateId: string;
  templateName: string;
  timelineId: string;
  sharedSpaceId: string;
  journeyTemplateIds: string[];
  name: string;
  type: PhaseType;
  createdBy: {
    _id: string;
    name: string;
    email: string;
  };
  contactUser: {
    _id: string;
    name: string;
    email: string;
  };
  isActive: boolean;
  completionDate?: string;
  createdAt: string;
  updatedAt: string;
};

export type PhaseStatus = "In Progress" | "Completed" | "Paused" | "Canceled";

export type PhaseData = {
  spaceId: string;
  status: PhaseStatus;
  _id: string;
  templateId: string;
  templateName: string;
  sharedSpaceId: string;
  timelineId: string;
  journey: {
    _id: string;
  };
  instance: {
    name: string;
  };
  customer?: {
    name: string;
    customFields?: CustomFieldValueData[];
  };
  name: string;
  type: PhaseType;
  createdBy: {
    _id: string;
    name: string;
    email: string;
  };
  contactUser: {
    _id: string;
    name: string;
    email: string;
  };
  knowledgeResources: {
    _id: string;
    name: string;
  }[];
  ownerUser: {
    _id: string;
    name: string;
    email: string;
  };
  isActive: boolean;
  completionDate?: string;
  createdAt: string;
  updatedAt: string;
  statusGroup: {
    _id: string;
  };
  collaborationType: TaskerType;
  customFields?: CustomFieldValueData[];
};

export type InternalPhaseUpdateData = BaseInternalPhaseUpdateData & {
  phaseId: string;
};

type BaseInternalPhaseUpdateData = {
  _id: string;
  status?: string;
  description?: string;
  createdBy?: {
    _id: string;
    name: string;
    email: string;
  };
  event?: "stage-completed" | "project-started";
  isManual: boolean;
  isSkipped?: boolean;
  createdAt: string;
  updatedAt: string;
  metadata?: {
    stageName?: string;
  };
};

export const useGetPhaseInternalUpdates = (phaseId: string) => {
  const getValidAccessToken = useAccessToken();
  return useQuery(
    phaseKeys.internalUpdates(phaseId),
    async (): Promise<InternalPhaseUpdateData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/internalUpdates`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            phaseId,
          },
        }
      );
      return res.data;
    }
  );
};

export const useGetPhase = (_id: string) => {
  const getValidAccessToken = useAccessToken();
  return useQuery(
    phaseKeys.phase(_id),
    async (): Promise<PhaseData> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/phase`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            _id,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
      retry: (failureCount, error) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 404) {
            return false; // Do not retry on 404 errors
          }
        }
        return failureCount < 3; // Retry up to 3 times for other errors
      },
    }
  );
};

export const useGetKeyDatesForPhase = (phaseId: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    phaseKeys.keyDates(phaseId),
    async (): Promise<{
      startedDate: string;
      plannedCompletionDate: string;
      forecastedCompletionDate: string;
      completionDate?: string;
    }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/keyDatesForJourney`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            phaseId,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};

export type PhaseNotificationSettingsData = {
  _id: string;
  phaseId: string;
  overdueEmail: boolean;
  overdueEmailsTotal: number;
  overdueEmailFrequencyDays: number;
  dependentTaskActivationEmail: boolean;
  weeklyUpdateEmail: boolean;
  threadMessageEmail: boolean;
  overdueEmailAddress: {
    name: string;
    email: string;
  };
  dependentTaskActivationEmailAddress: {
    name: string;
    email: string;
  };
  weeklyUpdateEmailAddress: {
    name: string;
    email: string;
  };
  threadMessageEmailAddress: {
    name: string;
    email: string;
  };
  slackNotification: {
    isEnabled: boolean;
    channel: string;
    slackWeeklyUpdate: boolean;
    slackWeeklyUpdateDay: number; // 1-7 with 1 being Sunday
    highLevelSlackNotificationUsers: {
      _id: string;
      name: string;
      email: string;
    }[];
  };
  createdAt: string;
  updatedAt: string;
};

export const useGetPhaseNotificationSettings = (phaseId: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();

  return useQuery(
    phaseKeys.notificationSettings(phaseId),
    async (): Promise<{
      _id: string;
      phaseId: string;
      overdueEmail: boolean;
      overdueEmailFrequencyDays: number;
      dependentTaskActivationEmail: boolean;
      weeklyUpdateEmail: boolean;
      slackNotification: {
        isEnabled: boolean;
        channel: string;
      };
      createdAt: string;
      updatedAt: string;
    }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/phaseNotificationSettings`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            phaseId,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};

export type AllPhaseNotificationSettingsData = {
  _id: string;
  name: string;
  customer: {
    _id: string;
    name: string;
  };
  notificationSettings: PhaseNotificationSettingsData;
  createdAt: string;
};

export const useGetAllPhaseNotificationSettings = () => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();

  return useQuery(
    phaseKeys.allPhaseNotificationSettings(),
    async (): Promise<AllPhaseNotificationSettingsData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/allPhaseNotificationSettings`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};

export const useUpdatePhaseNotificationSettings = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async ({
      update,
      metadata,
    }: {
      update: {
        _id: string;
        overdueEmail?: boolean;
        overdueEmailsTotal?: number;
        overdueEmailFrequencyDays?: number;
        dependentTaskActivationEmail?: boolean;
        weeklyUpdateEmail?: boolean;
        sendSlackNotifications?: boolean;
        slackNotificationChannel?: string | null;
      };
      metadata: {
        phaseId: string;
      };
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/phaseNotificationSettings`,
        update,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onMutate: (variables) => {
      const oldData = queryClient.getQueryData(
        phaseKeys.notificationSettings(variables.metadata.phaseId)
      );
      queryClient.cancelQueries(
        phaseKeys.notificationSettings(variables.metadata.phaseId)
      );
      queryClient.setQueryData(
        phaseKeys.notificationSettings(variables.metadata.phaseId),
        (
          oldData:
            | {
                _id: string;
                phaseId: string;
                overdueEmail: boolean;
                dependentTaskActivationEmail: boolean;
                weeklyUpdateEmail: boolean;
                createdAt: string;
                updatedAt: string;
                slackNotification: {
                  isEnabled: boolean;
                  channel: string;
                };
              }
            | undefined
        ) => {
          if (!oldData) return;
          return {
            ...oldData,
            overdueEmail: variables.update.overdueEmail ?? oldData.overdueEmail,
            dependentTaskActivationEmail:
              variables.update.dependentTaskActivationEmail ??
              oldData.dependentTaskActivationEmail,
            weeklyUpdateEmail:
              variables.update.weeklyUpdateEmail ?? oldData.weeklyUpdateEmail,
            slackNotification: {
              isEnabled:
                variables.update.sendSlackNotifications ??
                oldData.slackNotification.isEnabled,
              channel:
                variables.update.slackNotificationChannel ??
                oldData.slackNotification.channel,
            },
          };
        }
      );
      return { oldData };
    },
    onError: (error, variables, context) => {
      snackbarCtx.showSnackbar("Error updating notification settings", "error");
      queryClient.setQueryData(
        phaseKeys.notificationSettings(variables.metadata.phaseId),
        context?.oldData
      );
    },
    onSuccess: (data, variables) => {
      if (variables.metadata.phaseId) {
        queryClient.invalidateQueries(
          phaseKeys.notificationSettings(variables.metadata.phaseId)
        );
      }
      queryClient.invalidateQueries(phaseKeys.allPhaseNotificationSettings());
      // snackbarCtx.showSnackbar("Successfully duplicated journey template");
    },
  });
};

export const useGetPhases = (sharedSpaceId: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    phaseKeys.phases(sharedSpaceId),
    async (): Promise<MinimalPhaseData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/phases`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            sharedSpaceId,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};

export const useUploadPhaseFile = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (fileDetails: {
      file: File;
      label: string;
      isVisible: boolean;
      phaseId: string;
      description?: string;
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const formData = new FormData();

      formData.append("file", fileDetails.file);
      formData.append("label", fileDetails.label);
      formData.append("isVisible", JSON.stringify(fileDetails.isVisible));
      formData.append("phaseId", fileDetails.phaseId);
      formData.append("createdBy", app.currentUser?.id);
      formData.append("description", fileDetails.description ?? "");

      // console.log(formData.getAll("file"));

      const res = await axios.post(
        `${process.env.REACT_APP_LAMBDA_URL}/uploadSharedSpaceFile
        `,
        formData,
        {
          headers: {
            Accept: "application/json",
            // "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: (err) => {
      console.log(err);
      snackbarCtx.showSnackbar("Error uploading file", "error");
    },
    onSuccess: (data, variables) => {
      if (variables.phaseId) {
        queryClient.invalidateQueries(phaseKeys.files(variables.phaseId));
        queryClient.invalidateQueries(
          resourceKeys.resources(variables.phaseId)
        );
      }
      // snackbarCtx.showSnackbar("Successfully duplicated journey template");
    },
  });
};

export const useCreateInternalUpdateForPhase = (phaseId: string) => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  // const navigate = useNavigate();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (update: {
      status: string;
      description: string;
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/internalUpdate`,
        {
          phaseId,
          ...update,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error creating phase update", "error");
    },
    onSuccess: () => {
      queryClient.invalidateQueries(phaseKeys.internalUpdates(phaseId));
    },
  });
};

export const useCreatePhase = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (phase: {
      sharedSpaceId: string;
      type: "Customer Onboarding";
      contactUserId?: string;
      isActive: boolean;
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/phase`,
        phase,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error launching phase", "error");
    },
    // onSuccess: (data, variables) => {
    //   if (data.id && variables.sharedSpaceId) {
    //     queryClient.invalidateQueries(
    //       phaseKeys.phases(variables.sharedSpaceId)
    //     );
    //     navigate(`phases/${data.id}`);
    //   }
    //   // snackbarCtx.showSnackbar("Successfully duplicated journey template");
    // },
  });
};

export const useDeletePhase = (spaceId: string) => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (phase: {
      _id: string;
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.delete(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/phase`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          params: phase,
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error deleting phase", "error");
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["projectsv2"]);
      navigate(`/spaces/${spaceId}/projects`);
    },
  });
};

export const useCreateOnboardingPhaseWithJourney = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (phase: {
      sharedSpaceId: string;
      type: "Customer Onboarding";
      contactUserId?: string;
      isActive: boolean;
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/phase`,
        phase,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error launching phase", "error");
    },
    onSuccess: (data, variables) => {
      if (data.id && variables.sharedSpaceId) {
        queryClient.invalidateQueries(
          phaseKeys.phases(variables.sharedSpaceId)
        );
        navigate(`phases/${data.id}`);
      }
      // snackbarCtx.showSnackbar("Successfully duplicated journey template");
    },
  });
};

export const useUpdatePhase = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async ({
      phase,
      update,
    }: {
      phase: {
        _id: string;
        name?: string;
        isActive?: boolean;
        sharedSpaceId?: string;
        journeyId?: string;
        status?: PhaseStatus;
        ownerUserId?: string;
        customFields?: {
          _id: string;
          value: null | string | number | boolean | string[];
          crmProperty?: {
            integrationId: "hubspot" | "salesforce";
          };
        }[];
      };
      update: {
        _id: string;
        name?: string;
        isActive?: boolean;
        sharedSpaceId?: string;
        journeyId?: string;
        status?: PhaseStatus;
        customFields?: CustomFieldValueData[];
        ownerUser?: {
          _id: string;
          name: string;
          email: string;
        };
      };
    }): Promise<{ message: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/updatePhase`,
        {
          ...phase,
          // journeyTemplateIds:
          //   phaseTemplate.jourenyTemplates?.journeyTemplateIds,
          // instanceId: app.currentUser.customData.instanceId.$oid,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      // console.log(res);
      return res.data;
    },
    onMutate: async (variables) => {
      await queryClient.cancelQueries(phaseKeys.phase(variables.phase._id));
      const oldPhase = queryClient.getQueryData<PhaseData>(
        phaseKeys.phase(variables.phase._id)
      );
      const newPhase = { ...oldPhase, ...variables.update };
      queryClient.setQueryData(phaseKeys.phase(variables.phase._id), newPhase);
      return { oldPhase };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(
        phaseKeys.phase(variables.phase._id),
        context?.oldPhase
      );
      snackbarCtx.showSnackbar("Error updating phase", "error");
    },
    onSuccess: (data, variables) => {
      if (variables.phase._id) {
        // queryClient.invalidateQueries(
        //   phaseKeys.phases(variables.sharedSpaceId)
        // );
        queryClient.invalidateQueries(phaseKeys.phase(variables.phase._id));
      }
      if (variables.update.status) {
        queryClient.invalidateQueries(taskKeys.phaseTasks(variables.phase._id));
        if (variables.update.journeyId) {
          queryClient.invalidateQueries(
            taskKeys.journeyTasks(variables.update.journeyId)
          );
        }
      }
      // if (data.id) {
      //   navigate(`/templates/phases/${data.id}`);
      // }
      // snackbarCtx.showSnackbar("Successfully duplicated journey template");
    },
  });
};
