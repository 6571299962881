import { useParams } from "react-router-dom";
import { Stages } from "./Stages/Stages";

type Props = {
  isInternal?: boolean;
  isEmbed?: boolean;
  isSharedSpace?: boolean;
  journeyId: string
};

const JourneyRun = ({
  isInternal = true,
  isEmbed = false,
  isSharedSpace = false,
  journeyId
}: Props) => {
  // const { journeyId } = useParams();
  return (
    <div className="h-full space-y-4">
      {/* <div className=""> */}
        <Stages journeyId={journeyId} />
      {/* </div> */}
    </div>
  );
};

export default JourneyRun;
