import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { buildSetterWithInstanceIdProvided } from "src/api";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { useRealmApp } from "src/store/RealmApp";

export const useAddSlackOAuth = () => {
  const app = useRealmApp();
  const functionName = "addSlackOauth";
  const fieldName = "params";
  const snackbarCtx = useSnackBar();
  const navigate = useNavigate();

  return useMutation(
    buildSetterWithInstanceIdProvided(app, functionName, fieldName),
    {
      onMutate: async ({
        params,
      }: {
        params: {
          code: string;
        };
      }) => {},
      onError: () => {
        snackbarCtx.showSnackbar("Error integrating with Slack", "error");
      },
      onSuccess: () => {
        snackbarCtx.showSnackbar(
          "Successfully integrated with Slack!",
          "success"
        );
        navigate("/settings/slack/success");
      },
    }
  );
};
