import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { ObjectID } from "bson";
import { useNavigate } from "react-router-dom";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { useRealmApp } from "src/store/RealmApp";
import { journeyKeys } from "../Services/Journeys/journeys";
import { sharedSpaceKeys } from "../Services/SharedSpace/spaces";
import { taskKeys } from "../Services/Tasks/tasks";
import { useAccessToken } from "../useAccessToken";

export const formKeys = {
  formTemplates: () => ["formTemplates"] as const,
  formTemplate: (_id: string) => ["formTemplates", _id] as const,
  form: (_id: string) => ["forms", _id] as const,
};

export type FormData = {
  _id: string;
  templateId: string;
  taskId: string;
  title: string;
  description: string;
  instanceId: string;
  sharedSpaceId: string;
  phaseId: string;
  journeyId: string;
  journeyStageId: string;
  isSubmitted: boolean;
  submissionDate: string;
  createdAt: string;
  updatedAt: string;
  blocks: FormTemplateSectionBlockData[];
};

type FormRequest = {
  _id: string;
  title?: string;
  description?: string;
  blocks?: FormTemplateSectionBlockData[];
  isSubmitted?: boolean;
};

export type FormTemplateData = {
  spaceId?: string;
  _id: string;
  title: string;
  description: string;
  blocks: FormTemplateSectionBlockData[];
  instanceId: string;
  createdAt: string;
  updatedAt: string;
};

type BaseFormTemplateBlockData = {
  properties: {
    label: string;
    description: string;
    required: boolean;
    placeholder: string;
    value?: string;
  };
};

export type FormTemplateSectionBlockData = {
  type: "section";
  blocks: FormTemplateElementBlockData[];
  id: string;
};

export type FormTemplateElementBlockData =
  | FormTemplateShortTextBlockData
  | FormTemplateLongTextBlockData
  | FormTemplateDateBlockData
  | FormTemplateBooleanBlockData
  | FormTemplateEmailBlockData
  | FormTemplateHeadingBlockData
  | FormTemplateRichTextBlockData
  | FormTemplateTableBlockData;

export type FormTemplateShortTextBlockData = {
  type: "short text";
  id: string;
} & BaseFormTemplateBlockData;

export type FormTemplateLongTextBlockData = {
  type: "long text";
  id: string;
} & BaseFormTemplateBlockData;

export type FormTemplateDateBlockData = {
  type: "date";
  id: string;
} & {
  properties: {
    label: string;
    description: string;
    required: boolean;
    value?: string;
  };
};

export type FormTemplateBooleanBlockData = {
  type: "boolean";
  id: string;
} & {
  properties: {
    label: string;
    description: string;
    trueLabel: string;
    falseLabel: string;
    value?: boolean;
  };
};

export type FormTemplateEmailBlockData = {
  type: "email";
  id: string;
} & BaseFormTemplateBlockData;

export type FormTemplateHeadingBlockData = {
  type: "heading";
  id: string;
  properties: {
    text: string;
    description: string;
    size: "small" | "medium" | "large";
  };
};

export type FormTemplateRichTextBlockData = {
  type: "richText";
  id: string;
  properties: {
    text: string;
  };
};

export type FormTemplateTableBlockData = {
  type: "table";
  id: string;
  properties: {
    label: string;
    description: string;
    columns: { name: string }[];
    rows?: string[][];
    initialNumberOfRows: number;
    canAddRows: boolean;
  };
};

export type ElementType = FormTemplateElementBlockData["type"];

type FormTemplateRequest = {
  title: string;
  description: string;
  blocks: FormTemplateSectionBlockData[];
};

type FormTemplateCreateReuqest = Partial<FormTemplateRequest> & {
  spaceId: string;
};

type FormTemplateUpdateRequest = {
  _id: string;
  // blocks: FormTemplateSectionBlockData[];
} & Partial<FormTemplateRequest>;

export const useUpdateForm = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();
  return useMutation({
    mutationFn: async ({
      update,
      metadata,
    }: {
      update: FormRequest;
      metadata?: {
        taskId: string;
        journeyId?: string;
        journeyStageId?: string;
        phaseId?: string;
        sharedSpaceId?: string;
      };
    }): Promise<{ message: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/updateForm`,
        {
          ...update,
          // instanceId: app.currentUser?.customData.instanceId.$oid,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onMutate: (variables) => {
      // const oldData = queryClient.getQueryData(
      //   formKeys.formTemplate(variables._id)
      // );
      // queryClient.setQueryData(
      //   formKeys.formTemplate(variables._id),
      //   (oldData: FormTemplateData | undefined) => {
      //     if (!oldData) return;
      //     return {
      //       ...oldData,
      //       title: variables.title || oldData.title,
      //       description: variables.description || oldData.description,
      //       blocks: variables.blocks || oldData.blocks,
      //     };
      //   }
      // );
      // return { oldData };
    },
    onError: (error, variables) => {
      // console.error(error)
      // queryClient.setQueryData(
      //   formKeys.formTemplate(variables._id),
      //   (context: any) => context.oldData
      // );
      snackbarCtx.showSnackbar("Error updating form template", "error");
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["tasks"]);
      queryClient.invalidateQueries(formKeys.form(variables.update._id));
      if (variables.metadata?.journeyId && variables.metadata.journeyStageId) {
        queryClient.invalidateQueries(taskKeys.task(variables.metadata.taskId));
        queryClient.invalidateQueries(
          taskKeys.journeyStageTasks(
            variables.metadata.journeyId,
            variables.metadata.journeyStageId
          )
        );
      }
      if (variables.metadata?.sharedSpaceId) {
        queryClient.invalidateQueries(
          sharedSpaceKeys.tasks(new ObjectID(variables.metadata.sharedSpaceId))
        );
        queryClient.invalidateQueries(
          sharedSpaceKeys.taskBreakdown(
            new ObjectID(variables.metadata.sharedSpaceId)
          )
        );
      }
      if (variables.metadata?.journeyId) {
        queryClient.invalidateQueries(
          journeyKeys.journey(new ObjectID(variables.metadata.journeyId))
        );
        queryClient.invalidateQueries(
          journeyKeys.stages(new ObjectID(variables.metadata.journeyId))
        );
        queryClient.invalidateQueries(
          journeyKeys.keyDates(new ObjectID(variables.metadata.journeyId))
        );
        queryClient.invalidateQueries(
          journeyKeys.tasksProgress(new ObjectID(variables.metadata.journeyId))
        );
      }
      if (variables.metadata?.phaseId) {
        queryClient.invalidateQueries(
          taskKeys.phaseTasks(variables.metadata.phaseId)
        );
      }
    },
  });
};

export const useGetForm = (_id: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    formKeys.form(_id),
    async (): Promise<FormData> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/form`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            _id,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};

export const useGetFormTemplates = () => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    formKeys.formTemplates(),
    async (): Promise<FormTemplateData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/formTemplates`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            instanceId: app.currentUser?.customData.instanceId.$oid,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};

export const useGetFormTemplate = (_id: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    formKeys.formTemplate(_id),
    async (): Promise<FormTemplateData> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/formTemplate`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            _id,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};

export const useCreateFormTemplate = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();
  return useMutation({
    mutationFn: async (
      formTemplate: FormTemplateCreateReuqest
    ): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/formTemplate`,
        {
          ...formTemplate,
          instanceId: app.currentUser?.customData.instanceId.$oid,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error creating form template", "error");
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(formKeys.formTemplates());
      navigate(`/templates/forms/${data.id}`);
    },
  });
};

export const useUpdateFormTemplate = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();
  return useMutation({
    mutationFn: async (
      formTemplate: FormTemplateUpdateRequest
    ): Promise<{ message: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/updateFormTemplate`,
        {
          ...formTemplate,
          // instanceId: app.currentUser?.customData.instanceId.$oid,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onMutate: (variables) => {
      const oldData = queryClient.getQueryData(
        formKeys.formTemplate(variables._id)
      );
      queryClient.setQueryData(
        formKeys.formTemplate(variables._id),
        (oldData: FormTemplateData | undefined) => {
          if (!oldData) return;
          return {
            ...oldData,
            title: variables.title || oldData.title,
            description: variables.description || oldData.description,
            blocks: variables.blocks || oldData.blocks,
          };
        }
      );
      return { oldData };
    },
    onError: (error, variables) => {
      // console.error(error)
      queryClient.setQueryData(
        formKeys.formTemplate(variables._id),
        (context: any) => context.oldData
      );
      snackbarCtx.showSnackbar("Error updating form template", "error");
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(formKeys.formTemplate(variables._id));
      if (variables.title) {
        queryClient.invalidateQueries(formKeys.formTemplates());
      }
    },
  });
};

export const useDeleteFormTemplateConfirmation = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (formTemplate: {
      _id: string;
      force: false;
    }): Promise<{
      message: string;
      dependencies: {
        _id: string;
        title: string;
      }[];
    }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.delete(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/formTemplate`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            ...formTemplate,
            // instanceId: app.currentUser.customData.instanceId.$oid,
          },
        }
      );
      // console.log(res);
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error deleting form template", "error");
    },
    onSuccess: (data, variables) => {
      console.log(data);
      // queryClient.invalidateQueries(formKeys.formTemplates());
    },
  });
};

export const useDeleteFormTemplate = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();
  return useMutation({
    mutationFn: async (formTemplate: {
      _id: string;
      force: true;
    }): Promise<{
      message: string;
    }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.delete(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/formTemplate`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            ...formTemplate,
            // instanceId: app.currentUser.customData.instanceId.$oid,
          },
        }
      );
      // console.log(res);
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error deleting form template", "error");
    },
    onSuccess: (data, variables) => {
      navigate("/templates/forms");
      queryClient.invalidateQueries(formKeys.formTemplates());
    },
  });
};

// export const useDeletePhase = () => {
//   const app = useRealmApp();
//   const snackbarCtx = useSnackBar();
//   const queryClient = useQueryClient();
//   const navigate = useNavigate();

//   return useMutation({
//     mutationFn: async (phase: {
//       _id: string;
//     }): Promise<{ message: string; id: string }> => {
//       const res = await axios.delete(
//         `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/phase`,
//         {
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${app.currentUser?.accessToken}`,
//           },
//           params: phase,
//         }
//       );
//       return res.data;
//     },
//     onError: () => {
//       snackbarCtx.showSnackbar("Error deleting phase", "error");
//     },
//     onSuccess: () => {
//       queryClient.invalidateQueries(["projects"]);
//       navigate(`/projects`);
//       // if (data.id && variables.sharedSpaceId) {
//       //   queryClient.invalidateQueries(
//       //     phaseKeys.phases(variables.sharedSpaceId)
//       //   );
//       // }
//       // snackbarCtx.showSnackbar("Successfully duplicated journey template");
//     },
//   });
// };

// export const useCreateOnboardingPhaseWithJourney = () => {
//   const app = useRealmApp();
//   const snackbarCtx = useSnackBar();
//   const queryClient = useQueryClient();
//   const navigate = useNavigate();

//   return useMutation({
//     mutationFn: async (phase: {
//       sharedSpaceId: string;
//       type: "Customer Onboarding";
//       contactUserId?: string;
//       isActive: boolean;
//     }): Promise<{ message: string; id: string }> => {
//       const res = await axios.post(
//         `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/phase`,
//         phase,
//         {
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${app.currentUser?.accessToken}`,
//           },
//         }
//       );
//       return res.data;
//     },
//     onError: () => {
//       snackbarCtx.showSnackbar("Error launching phase", "error");
//     },
//     onSuccess: (data, variables) => {
//       if (data.id && variables.sharedSpaceId) {
//         queryClient.invalidateQueries(
//           phaseKeys.phases(variables.sharedSpaceId)
//         );
//         navigate(`phases/${data.id}`);
//       }
//       // snackbarCtx.showSnackbar("Successfully duplicated journey template");
//     },
//   });
// };

// export const useUpdatePhase = () => {
//   const app = useRealmApp();
//   const snackbarCtx = useSnackBar();
//   const queryClient = useQueryClient();
//   const navigate = useNavigate();

//   return useMutation({
//     mutationFn: async (phase: {
//       _id: string;
//       name?: string;
//       isActive?: boolean;
//       sharedSpaceId: string;
//       journeyTemplateIds?: string[];
//     }): Promise<{ message: string }> => {
//       const res = await axios.post(
//         `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/updatePhase`,
//         {
//           _id: phase._id,
//           name: phase.name,
//           isActive: phase.isActive,
//           journeyTemplateIds: phase.journeyTemplateIds,
//           // journeyTemplateIds:
//           //   phaseTemplate.jourenyTemplates?.journeyTemplateIds,
//           // instanceId: app.currentUser.customData.instanceId.$oid,
//         },
//         {
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${app.currentUser?.accessToken}`,
//           },
//         }
//       );
//       // console.log(res);
//       return res.data;
//     },
//     // onMutate: (variables) => {
//     //   const oldData = queryClient.getQueryData(
//     //     phaseTemplateKeys.phaseTemplate(variables._id)
//     //   );
//     //   queryClient.setQueryData(
//     //     phaseTemplateKeys.phaseTemplate(variables._id),
//     //     (oldData: PhaseTemplateData | undefined) => {
//     //       if (!oldData) return;
//     //       return {
//     //         ...oldData,
//     //         name: variables.name || oldData.name,
//     //         journeyTemplates:
//     //           variables?.jourenyTemplates?.newJourneyTemplates ??
//     //           oldData.journeyTemplates,
//     //       };
//     //     }
//     //   );
//     //   return { oldData }
//     // },
//     onError: (err, variables, context) => {
//       // queryClient.setQueryData(
//       //   phaseTemplateKeys.phaseTemplate(variables._id),
//       //   context?.oldData)
//       snackbarCtx.showSnackbar("Error updating phase", "error");
//     },
//     onSuccess: (data, variables) => {
//       if (variables._id) {
//         queryClient.invalidateQueries(
//           phaseKeys.phases(variables.sharedSpaceId)
//         );
//         queryClient.invalidateQueries(phaseKeys.phase(variables._id));
//       }
//       // if (data.id) {
//       //   navigate(`/templates/phases/${data.id}`);
//       // }
//       // snackbarCtx.showSnackbar("Successfully duplicated journey template");
//     },
//   });
// };
