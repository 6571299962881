import { useQueryClient } from "@tanstack/react-query";
import posthog from "posthog-js";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as Realm from "realm-web";
import { useLoginWithEmailPassword } from "src/api/Authentication/authentication";
// import userbased from "userbased-js";
const RealmAppContext = React.createContext();

export const useRealmApp = () => {
  const app = React.useContext(RealmAppContext);
  if (!app) {
    throw new Error(
      `You must call useRealmApp() inside of a <RealmAppProvider />`
    );
  }
  return app;
};

export const RealmAppProvider = ({ appId, children }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [app, setApp] = React.useState(new Realm.App(appId));
  const [isExternal, setIsExternal] = React.useState(false);
  const [externalDetails, setExternalDetails] = React.useState({
    slug: "",
    sharedSpaceId: "",
  });

  React.useEffect(() => {
    setApp(new Realm.App(appId));
  }, [appId]);

  // Wrap the Realm.App object's user state with React state
  const [currentUser, setCurrentUser] = React.useState(app.currentUser);
  const loginWithEmailPasswordMutation = useLoginWithEmailPassword();

  async function logOutAndLogin(credentials) {
    // console.log("logging out")
    posthog.reset();
    localStorage.clear();
    await app.currentUser?.logOut();
    queryClient.removeQueries();
    await app.logIn(credentials);
    await app.currentUser?.refreshCustomData();
    if (app.currentUser.customData._id) {
      posthog.identify(app.currentUser.customData._id, {
        email: app.currentUser.customData.email,
      });
      if (app.currentUser?.customData?.instanceId?.$oid) {
        posthog.people.set({
          instanceId: app.currentUser.customData.instanceId.$oid,
        });
      }
    }
    // If successful, app.currentUser is the user that just logged in
    setCurrentUser(app.currentUser);
    return app.currentUser;
    // navigate("/login")
    // Log out the currently active user
    // console.log(app.currentUser)
    // If another user was logged in too, they're now the current user.
    // Otherwise, app.currentUser is null.
  }

  async function loginWithEmailPassword(email, password, token) {
    localStorage.clear();
    loginWithEmailPasswordMutation.mutate({
      email,
      password,
      token,
    });
  }

  async function logIn(credentials) {
    await app.logIn(credentials);
    await app.currentUser?.refreshCustomData();
    // console.log(app.currentUser);
    console.log("here");
    if (app.currentUser.customData._id) {
      console.log("before");
      // userbased.identify(app.currentUser.customData.email);
      console.log("after");
      posthog.identify(app.currentUser.customData._id, {
        email: app.currentUser.customData.email,
      });
      if (app.currentUser?.customData?.instanceId?.$oid) {
        posthog.people.set({
          instanceId: app.currentUser.customData.instanceId.$oid,
        });
      }
    }
    // If successful, app.currentUser is the user that just logged in
    setCurrentUser(app.currentUser);
    return app.currentUser;
  }

  async function logInAndRegister(credentials, userData) {
    await app.logIn(credentials);
    await app.currentUser.functions.registerUser(userData);
    setCurrentUser(app.currentUser);
  }

  async function logOut() {
    // console.log("logging out")
    posthog.reset();
    localStorage.clear();
    await app.currentUser?.logOut();
    setCurrentUser(app.currentUser);
    queryClient.removeQueries();
    // navigate("/login")
    // Log out the currently active user
    // console.log(app.currentUser)
    // If another user was logged in too, they're now the current user.
    // Otherwise, app.currentUser is null.
  }

  async function logOutOfCustomerPortal(slug) {
    posthog.reset();
    await app.currentUser?.logOut();
    navigate(`/${slug}/login`);
    setCurrentUser(app.currentUser);
    queryClient.removeQueries();
  }

  const wrapped = {
    ...app,
    currentUser,
    logIn,
    logOut,
    logInAndRegister,
    logOutAndLogin,
    isExternal,
    setIsExternal,
    externalDetails,
    setExternalDetails,
    logOutOfCustomerPortal,
    loginWithEmailPassword,
  };

  return (
    <RealmAppContext.Provider value={wrapped}>
      {children}
    </RealmAppContext.Provider>
  );
};
