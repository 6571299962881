import { ThemeProvider } from "@mui/material/styles";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import posthog from "posthog-js";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "sonner";
import CustomSnackbarProvider from "src/components/Reusable/CustomSnackbarProvider";
import { UserPreferencesContextProvider } from "src/store/Preferences/UserPreferencesContextProvider";
// import userbased from "userbased-js";
import App from "./App";
import "./index.css";
import { NewTaskForExternalUserProvider } from "./pages/Services/Tasks/NewTaskForExternalUser";
import { NewTaskForInternalUserContextProvider } from "./pages/Services/Tasks/NewTaskForInternalUser";
import StatusGroupsContextProvider from "./pages/status-groups/status-groups-context-provider";
import { RealmAppProvider } from "./store/RealmApp";
import { theme } from "./theme";

export const APP_ID = process.env.REACT_APP_APP_ID;

posthog.init(process.env.REACT_APP_POSTHOG_API_KEY || "", {
  api_host: "https://app.posthog.com",
});

// userbased.init("ub-4dc5a06a-03a7-4dee-99f7-6462b54c710f", {});

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  Sentry.init({
    dsn: "https://dfe884fcc1c34a93bb6d85887973bc07@o1388801.ingest.sentry.io/4503938840330240",
    integrations: [new BrowserTracing()],
    environment: "production",
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

// react-query
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CustomSnackbarProvider>
          <NewTaskForExternalUserProvider>
            <NewTaskForInternalUserContextProvider>
              <BrowserRouter>
                <RealmAppProvider appId={APP_ID}>
                  <UserPreferencesContextProvider>
                    <StatusGroupsContextProvider>
                      {/* <UserbasedProvider client={userbased}> */}
                      <Toaster
                        expand={true}
                        // toastOptions={{
                        //   classNames: {
                        //     toast:
                        //       "group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg",
                        //     description:
                        //       "group-[.toast]:text-muted-foreground",
                        //     actionButton:
                        //       "group-[.toast]:bg-white group-[.toast]:border group-[.toast]:border-input group-[.toast]:text-gray-700",
                        //     cancelButton:
                        //       "group-[.toast]:bg-muted group-[.toast]:text-muted-foreground",
                        //   },
                        // }}
                      />
                      <App />
                      {/* </UserbasedProvider> */}
                    </StatusGroupsContextProvider>
                  </UserPreferencesContextProvider>
                </RealmAppProvider>
              </BrowserRouter>
            </NewTaskForInternalUserContextProvider>
          </NewTaskForExternalUserProvider>
        </CustomSnackbarProvider>
      </ThemeProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} position={"bottom-right"} /> */}
    </QueryClientProvider>
  </React.StrictMode>
);
