import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useRealmApp } from "src/store/RealmApp";
import { useAccessToken } from "../useAccessToken";

export const projectTemplateAnalyticsKeys = {
  templates: () => ["analytics", "projectTemplates", "list"] as const,
  completedPhaseBreakdown: (templateId: string) =>
    ["templates", templateId, "completedPhaseBreakdown"] as const,
  completedStageBreakdown: (templateId: string) =>
    ["templates", templateId, "completedStageBreakdown"] as const,
  completedTaskBreakdown: (templateId: string) =>
    ["templates", templateId, "completedTaskBreakdown"] as const,
  revenuePerStage: (templateId: string, userId: string) =>
    ["templates", templateId, "revenueByStage", "user", userId] as const,
  owners: (templateId: string) => ["templates", templateId, "owners"] as const,
};

export type ProjectTemplateListData = {
  _id: string;
  name: string;
  completed: number;
};

export type TemplateStageRevenueData = {
  _id: string;
  name: string;
  revenue: number;
  templateExists: boolean;
};

export type CompletedPhaseForTemplateData = {
  _id: string;
  revenue: number;
  startDate: string;
  timeTaken: number;
  expectedTime: number;
  ownerUser: {
    _id: string;
    name: string;
    email: string;
  };
};

export type CompletedStageBreakdownForTemplateData = {
  _id: string;
  name: string;
  stages: {
    _id: string;
    phaseId: string;
    timeTaken: number;
  }[];
};

export type CompletedTaskBreakdownForTemplateData = {
  _id: string;
  title: string;
  tasks: {
    _id: string;
    phaseId: string;
    timeTaken: number;
  }[];
};

export const useGetCompletedTaskBreakdownForTemplate = (
  templateId: string
) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    projectTemplateAnalyticsKeys.completedTaskBreakdown(templateId),
    async (): Promise<CompletedTaskBreakdownForTemplateData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/completedTaskBreakdownForTemplate`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            templateId,
          },
        }
      );
      return res.data;
    },
    {
      // staleTime: 1000 * 60 * 60 * 5,
      cacheTime: Infinity,
    }
  );
};

export const useGetCompletedStageBreakdownForTemplate = (
  templateId: string
) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    projectTemplateAnalyticsKeys.completedStageBreakdown(templateId),
    async (): Promise<CompletedStageBreakdownForTemplateData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/completedStageBreakdownForTemplate`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            templateId,
          },
        }
      );
      return res.data;
    },
    {
      // staleTime: 1000 * 60 * 60 * 5,
      cacheTime: Infinity,
    }
  );
};

export const useGetCompletedPhaseBreakdownForTemplate = (
  templateId: string
) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    projectTemplateAnalyticsKeys.completedPhaseBreakdown(templateId),
    async (): Promise<CompletedPhaseForTemplateData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/completedPhaseBreakdownForTemplate`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            templateId,
          },
        }
      );
      return res.data;
    },
    {
      // staleTime: 1000 * 60 * 60 * 5,
      cacheTime: Infinity,
    }
  );
};

export const useGetRevenuePerStageForProjectTemplate = (
  templateId: string,
  userId?: string
) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    projectTemplateAnalyticsKeys.revenuePerStage(templateId, userId ?? "all"),
    async (): Promise<TemplateStageRevenueData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/revenuePerStageForJourneyTemplate`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            templateId,
            userId,
          },
        }
      );
      return res.data;
    },
    {
      // staleTime: 1000 * 60 * 60 * 5,
      cacheTime: Infinity,
    }
  );
};

export type OwnerData = {
  _id: string;
  name: string;
  email: string;
};

export const useGetOwnersForProjectTemplate = (templateId: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    projectTemplateAnalyticsKeys.owners(templateId),
    async (): Promise<OwnerData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/projectTemplateOwners`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            templateId,
          },
        }
      );
      return res.data;
    },
    {
      // staleTime: 1000 * 60 * 60 * 5,
      cacheTime: Infinity,
    }
  );
};
