import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { isValidRoute, Route } from "./Sidebartypes";

type Props = {
  children: React.ReactNode;
};

interface AppLayoutContextObj {
  activeRoute: string;
  setActiveRoute: React.Dispatch<React.SetStateAction<string>>;
}

export const AppLayoutContext = createContext<AppLayoutContextObj>({
  activeRoute: "dashboard",
  setActiveRoute: (_) => {},
});

const AppLayoutContextProvider = (props: Props) => {
  const [activeRoute, setActiveRoute] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    // console.log(location.pathname)
    setActiveRoute(location.pathname);
    // const paths = location.pathname.split("/");
    // if (paths.length > 0) {
    //   // if (isValidRoute(paths[1])) {
    //     //   if (paths[1] !== activeRoute) {
    //     // setActiveRoute(paths);
    //   //   }
    //   // }
    // }
  }, [location.pathname]);

  const value = {
    activeRoute,
    setActiveRoute,
  };

  return (
    <AppLayoutContext.Provider value={value}>
      {props.children}
    </AppLayoutContext.Provider>
  );
};

export default AppLayoutContextProvider;
