import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useRealmApp } from "src/store/RealmApp";
import { useAccessToken } from "../useAccessToken";

const slackKeys = {
  status: () => ["slack", "status"] as const,
  channels: () => ["slack", "channels"] as const,
};

export type SlackIntegrationStatusData = {
  workspace: boolean;
  users: {
    _id: string;
    name: string;
    email: string;
    status: boolean;
  }[];
  waitingRoomChannelSet: boolean;
};

export const useGetSlackIntegrationStatus = () => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    slackKeys.status(),
    async (): Promise<SlackIntegrationStatusData> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/slackIntegrationStatus`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            instanceId: app.currentUser.customData.instanceId.$oid,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: 1000 * 60, // 1 minute
      cacheTime: Infinity,
    }
  );
};

export const useGetSlackChannels = () => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    slackKeys.channels(),
    async (): Promise<{ id: string; name: string }[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/slackChannels`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            instanceId: app.currentUser.customData.instanceId.$oid,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: 1000 * 60, // 1 minute
      cacheTime: Infinity,
    }
  );
};
