import { RocketLaunchIcon } from "@heroicons/react/24/outline";
import { HTMLAttributes, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { PageHeading } from "src/components/ui/heading";
import { cn } from "src/utils/ui/ui";

type Props = {};

export default function NotificationSettings({}: Props) {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex h-16 items-center px-8">
        <PageHeading heading="Notifications" />
      </div>
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 px-8">
        <NOtificationsCard
          onClick={() => {
            navigate("/settings/notifications/projects");
          }}
        >
          <div className="flex items-center space-x-1.5">
            <RocketLaunchIcon className="text-primary-500 h-[18px] w-[18px]" />
            <div className="text-lg font-medium">Project Notifications</div>
          </div>
          <div className="pt-1 text-sm text-gray-500">
            View and manage notifications for all active projects.
          </div>
        </NOtificationsCard>
      </div>
    </>
  );
}

type CardProps = {} & HTMLAttributes<HTMLDivElement>;

const NOtificationsCard = forwardRef<HTMLDivElement, CardProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={cn(
          "h-30 cursor-pointer rounded-md border p-5 py-4 shadow hover:bg-gray-50",
          className
        )}
      >
        {children}
      </div>
    );
  }
);
