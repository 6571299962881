import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useGetStatusGroups } from "src/api/General/status-groups";
import { useGetProjects } from "src/api/Services/Projects/projects";
import { useGetSpaces } from "src/api/Spaces/spaces";
import { useStatusGroups } from "./status-groups-context-provider";

type Props = {};

export default function StatusGroupsForInternal({}: Props) {
  const { data: statusGroups } = useGetStatusGroups();
  const { data: _ } = useGetProjects();
  const { data: spaces } = useGetSpaces();
  const statusGroupsCtx = useStatusGroups();

  useEffect(() => {
    if (!!statusGroups?.length) {
      statusGroupsCtx.dispatch({
        type: "status_groups_update",
        statusGroups: [...statusGroupsCtx.state.statusGroups, ...statusGroups],
      });
    }
  }, [statusGroups]);

  return (
    <>
      {!!statusGroups && !!spaces ? (
        <>{statusGroups.length > 0 ? <Outlet /> : <>There was an error</>}</>
      ) : (
        <></>
      )}
    </>
  );
}
