import LoginForm from "../../components/Authentication/Login/LoginForm";

export default function Login() {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center">
      <div className="container mx-auto max-w-lg">
        <LoginForm />
      </div>
    </div>
  );
}
