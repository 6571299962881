export function sortByCreatedAt<T extends { createdAt: Date }>(
  elements: T[],
  order: "asc" | "dsc" = "dsc"
): T[] {
  let sortedArray = [...elements].sort(function (a, b) {
    if (order === "asc") {
      return a.createdAt.getTime() - b.createdAt.getTime();
    } else {
      return b.createdAt.getTime() - a.createdAt.getTime();
    }
  });
  return sortedArray;
}
