import {
  PencilIcon,
  Square3Stack3DIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  DotsVerticalIcon,
  LockClosedIcon,
  LockOpen1Icon,
} from "@radix-ui/react-icons";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  ViewData,
  useDeleteProjectView,
  useGetProjectViews,
  useGetViews,
  useUpdateProjectView,
} from "src/api/Views/views";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "src/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import { PageHeading } from "src/components/ui/heading";
import { Loading } from "src/components/ui/loading";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { TextInput } from "src/components/ui/text-input";

type Props = {};

export default function Views({}: Props) {
  return (
    <div className="h-full">
      <div className="flex h-16 items-center border-b px-8">
        <PageHeading heading={"Views"} />
      </div>
      <ViewsList />
    </div>
  );
}

const rows: { name: string; type: string; visibility: string }[] = [
  { name: "All Tasks", type: "Task", visibility: "Public" },
];

function ViewsList() {
  const { data: views } = useGetViews();
  return (
    <>
      {!!views ? (
        views.length > 0 ? (
          <div>
            <div className="flex h-11 items-center justify-between gap-8 border-b bg-gray-50 px-8 text-xs font-medium uppercase text-gray-500">
              <div className="min-w-[160px] flex-1">Name</div>
              <div className="flex shrink-0 items-center">
                <div className="w-40 shrink-0">Type</div>
                <div className="w-40 shrink-0">Visibility</div>
                <div className="w-10 shrink-0" />
              </div>
            </div>

            {views.map((view) => (
              <ViewRow key={view._id} view={view} />
            ))}
          </div>
        ) : (
          <div className="mt-[20vh] flex justify-center">
            <div className="flex flex-col">
              <div className="flex items-center">
                <Square3Stack3DIcon className="mr-2.5 h-7 w-7 text-gray-400" />
                <div className="text-xl font-medium text-gray-700">Views</div>
              </div>
              <div className="mt-1 max-w-[600px] font-light text-gray-500">
                Views enable easy access to projects that matter to you. To
                create a view, apply a filter in the{" "}
                <Link
                  to={"/projects"}
                  className="underline transition-colors hover:text-gray-700"
                >
                  Projects
                </Link>{" "}
                page and save it.
              </div>
            </div>
          </div>
        )
      ) : (
        <div>
          <Loading className="mt-[20vh]" />
        </div>
      )}
    </>
  );
}

type Visibility = "Public" | "Private";

function ViewRow({ view }: { view: ViewData }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const deleteProjectViewMutation = useDeleteProjectView();
  const updateProjectViewMutation = useUpdateProjectView();
  const [name, setName] = useState(view.name);
  const [visibility, setVisibility] = useState<Visibility>(
    view.isShared ? "Public" : "Private"
  );
  return (
    <div
      className={clsx(
        "flex h-11 cursor-pointer items-center justify-between gap-8 border-b border-gray-100 px-8 text-sm text-gray-500",
        open ? "bg-gray-50" : "hover:bg-gray-50"
      )}
      onClick={() => {
        navigate(`/projects?viewId=${view._id}`);
      }}
    >
      <div className="min-w-[160px] flex-1 truncate">{view.name}</div>
      <div className="flex shrink-0 items-center">
        <div className="w-40 shrink-0 capitalize">{view.type}</div>
        <div className="w-40 shrink-0">
          {view.isShared ? "Public" : "Private"}
        </div>
        <div className="flex w-10 shrink-0 justify-center">
          <DropdownMenu
            open={open}
            onOpenChange={(isOpen) => {
              setOpen(isOpen);
            }}
          >
            <DropdownMenuTrigger asChild>
              <Button
                variant={"ghost"}
                size="icon"
                onClick={() => {
                  setOpen(!open);
                }}
                className={clsx(open && "bg-gray-200")}
              >
                <DotsVerticalIcon className="h-3.5 w-3.5 text-gray-400" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-40">
              <DropdownMenuGroup>
                <DropdownMenuItem
                  className="text-sm"
                  onClick={(e) => {
                    setOpenEditModal(true);
                    e.stopPropagation();
                  }}
                >
                  <PencilIcon className="mr-2.5 h-4 w-4" />
                  Edit
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  className="focus:bg-red-50 focus:text-red-600"
                  onClick={(e) => {
                    setOpenDeleteModal(true);
                    e.stopPropagation();
                  }}
                >
                  <TrashIcon className="mr-2.5 h-4 w-4" />
                  Delete
                </DropdownMenuItem>

                {/* <DropdownMenuItem>Delete</DropdownMenuItem> */}
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
          <div onClick={(e) => e.stopPropagation()}>
            <Dialog
              open={openDeleteModal}
              onOpenChange={(open) => {
                setOpenDeleteModal(open);
              }}
            >
              <DialogContent
                showCloseButton={false}
                className="top-[20%] translate-y-0 focus-visible:outline-none data-[state=open]:slide-in-from-top-8"
              >
                <div className="pb-2">
                  <span>Are you sure you want to delete the view</span>{" "}
                  <span className="font-medium">{view.name}</span>?
                </div>
                <DialogFooter>
                  <Button
                    variant={"secondary"}
                    onClick={() => {
                      setOpenDeleteModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant={"destructive"}
                    onClick={() => {
                      deleteProjectViewMutation.mutate({ _id: view._id });
                      setOpenDeleteModal(false);
                    }}
                  >
                    Delete
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
            <Dialog
              open={openEditModal}
              onOpenChange={(open) => {
                setOpenEditModal(open);
              }}
            >
              <DialogContent className="top-[20%] translate-y-0 focus-visible:outline-none data-[state=open]:slide-in-from-top-8">
                <DialogHeader>
                  <DialogTitle>Edit View</DialogTitle>
                </DialogHeader>
                <div className="space-y-4 py-2">
                  <div className="space-y-1">
                    <InputHeading heading="Name" />
                    <TextInput
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        // if (e.target.value === "") {
                        //   setName(view.name);
                        // }
                      }}
                      onBlur={() => {
                        if (name.trim() === "") {
                          setName(view.name);
                        } else if (name !== view.name) {
                          updateProjectViewMutation.mutate({
                            _id: view._id,
                            name,
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="space-y-1">
                    <InputHeading heading="Visibility" />
                    <Select
                      value={visibility}
                      onValueChange={(value) => {
                        setVisibility(value as Visibility);
                        updateProjectViewMutation.mutate({
                          _id: view._id,
                          isShared: value === "Public",
                        });
                      }}
                    >
                      <SelectTrigger className="h-9 w-fit gap-2">
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent align="start" className="min-w-[120px]">
                        <SelectGroup>
                          <SelectItem value={"Private"}>
                            <div className="flex items-center">
                              <LockClosedIcon className="mr-2 h-4 w-4 shrink-0" />
                              <div>{"Private"}</div>
                            </div>
                          </SelectItem>
                          <SelectItem value={"Public"}>
                            <div className="flex items-center">
                              <LockOpen1Icon className="mr-2 h-4 w-4 shrink-0" />
                              <div>{"Public"}</div>
                            </div>
                          </SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
}

function ViewsTable() {
  const { data: views } = useGetProjectViews();
  const table = useReactTable({
    data: views ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <div className="max-h-full max-w-full overflow-auto border-y">
      <table
        style={{ borderCollapse: "separate", borderSpacing: 0 }}
        className="min-w-[600px]"
      >
        <thead className="overflow-auto">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="border-b">
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  scope="col"
                  className={
                    "sticky top-0 z-10 whitespace-nowrap border-b border-r bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 first:left-0 first:z-20 first:pl-8 last:w-full"
                  }
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {!views ? (
            <tr>
              {/* <td className="inset-0 flex items-center justify-center p-4 pl-8"> */}
              <div className="sticky left-0 z-10 p-4 pl-8 text-gray-400">
                Loading...
              </div>
              {/* </td> */}
            </tr>
          ) : (
            <>
              {views.length === 0 ? (
                <tr>
                  <div className="sticky left-0 z-10 whitespace-nowrap p-4 pl-8 text-gray-400">
                    No views
                  </div>
                  {/* <td className="inset-0 flex items-center justify-center p-4">
                    </td> */}
                </tr>
              ) : (
                <>
                  {table.getRowModel().rows.map((row) => (
                    <tr
                      key={row.id}
                      className="group relative rounded-lg hover:cursor-pointer hover:bg-gray-50"
                      onClick={() => {
                        if (row.original.type === "task") {
                          // navigate(`/tasks?viewId=${row.original._id}`);
                        } else if (row.original.type === "project") {
                          // navigate(`/projects?viewId=${row.original._id}`);
                        } else if (row.original.type === "analytics") {
                          if (row.original.guide === "pipeline-review") {
                            // navigate(`/analytics/guides/pipeline-review?viewId=${row.original._id}`);
                          }
                        }
                      }}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          className={
                            "whitespace-nowrap border-r border-b px-6 py-3 text-sm text-gray-500 first:sticky first:left-0 first:z-10 first:bg-white first:pr-6 first:font-medium first:text-gray-900 first:group-hover:bg-gray-50 first:sm:pl-8"
                          }
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </>
              )}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
}

const columns: ColumnDef<ViewData>[] = [
  {
    id: "name",
    header: "Name",
    cell: ({ row }) => <div>{row.original.name}</div>,
  },
  {
    id: "type",
    header: "Type",
    cell: ({ row }) => <div className="uppercase">{row.original.type}</div>,
  },
  {
    id: "visibility",
    header: "Visibility",
    cell: ({ row }) => (
      <div>{row.original.isShared ? "Public" : "Private"}</div>
    ),
  },
];
