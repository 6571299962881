import { useEffect } from "react";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import * as Realm from "realm-web";
import Login from "src/pages/Authentication/Login";
import { NewPassword } from "src/pages/Authentication/NewPassword";
import PostVerificationSetup from "src/pages/Authentication/PostVerificationSetup";
import Verification from "src/pages/Authentication/Verification";
import Verified from "src/pages/Authentication/Verified";
import Layout from "src/pages/Layouts/Layout";
import JourneyTemplate from "src/pages/Services/Journeys/JourneyTemplate";
import { useNotificationsSubscription } from "src/utils//notifications/notifications";
import { useGetJWTForInternalUser } from "./api/Authentication/authentication";
import { useGetStatusGroupsFromSlug } from "./api/General/status-groups";
import Sidebar from "./components/Layout/Sidebar";
import { Loading } from "./components/ui/loading";
import Analytics from "./pages/Analytics/Analytics";
import Guides from "./pages/Analytics/Guides/Guides";
import PipelineReview from "./pages/Analytics/Guides/pipeline-review/pipeline-review";
import ProjectTemplatePerformance from "./pages/Analytics/ProjectTemplates/ProjectTemplatePerformance";
import ProjectsPerformance from "./pages/Analytics/Projects/ProjectsPerformance";
import ExternalAuthentication from "./pages/Authentication/External/ExternalAuthentication";
import ExternalLogin from "./pages/Authentication/External/ExternalLogin";
import ExternalUserEmailPasswordSetup from "./pages/Authentication/Internal/ExternalUserEmailPasswordSetup";
import LoginExternalUserUsingJWT from "./pages/Authentication/Internal/LoginExternalUserUsingJWT";
import Register from "./pages/Authentication/Internal/Register";
import { ResetPassword } from "./pages/Authentication/ResetPassword";
import TaskCompletion from "./pages/Automations/TaskCompletion";
import PhasesForExternalUser from "./pages/Embed/PhasesForExternalUser";
import ExternalProject from "./pages/ExternalSpaces/external-project";
import ExternalProjectsForInternalUser from "./pages/ExternalSpaces/external-projects";
import ExternalSharedSpaceForInternalUser from "./pages/ExternalSpaces/external-space";
import ExternalSpacesForInternalUser from "./pages/ExternalSpaces/external-spaces";
import ExternalViewSetup from "./pages/ExternalView/ExternalViewSetup";
import FormTemplate from "./pages/Forms/form-template";
import FormTemplates from "./pages/Forms/form-templates";
import Homebase from "./pages/Homebase/Homebase";
import HomebaseSetup from "./pages/Homebase/HomebaseSetup";
import ExternalLayout from "./pages/Layouts/ExternalLayout";
import Projects from "./pages/Services/Projects/Projects";
import PhaseNotifications from "./pages/Services/SharedSpace/Phases/Notifications";
import Phase from "./pages/Services/SharedSpace/Phases/Phase";
import PhaseSettings from "./pages/Services/SharedSpace/Phases/Tabs/Settings";
import NewTaskForExternalUser from "./pages/Services/Tasks/NewTaskForExternalUser";
import HubSpotAuthentication from "./pages/Settings/Integrations/HubSpotAuthentication";
import SlackAuthentication from "./pages/Settings/Integrations/SlackAuthentication";
import IntegrationSuccess from "./pages/Settings/Integrations/Success";
import EmbeddingSettings from "./pages/Settings/SettingsTabs/EmbeddingSettings";
import Integrations from "./pages/Settings/SettingsTabs/Integrations";
import HubSpotIntegration from "./pages/Settings/SettingsTabs/Integrations/hubspot-integration";
import SlackIntegration from "./pages/Settings/SettingsTabs/Integrations/slack-integration";
import ProjectNotificationSettings from "./pages/Settings/SettingsTabs/Notifications/project-notification-settings";
import TeamSettings from "./pages/Settings/SettingsTabs/TeamSettings";
import CustomFields from "./pages/Settings/SettingsTabs/custom-fields";
import NotificationSettings from "./pages/Settings/SettingsTabs/notification-settings";
import SharedSpace from "./pages/SharedSpace/SharedSpace";
import JourneyTemplates from "./pages/Templates/Journeys/JourneyTemplates";
import Templates from "./pages/Templates/templates";
import Views from "./pages/Views/views";
import WorkspaceCleaner from "./pages/Workspace/workspace-cleaner";
import Roadmap from "./pages/roadmaps/roadmap";
import RoadmapSettings from "./pages/roadmaps/roadmap-settings";
import Roadmaps from "./pages/roadmaps/roadmaps";
import { useStatusGroups } from "./pages/status-groups/status-groups-context-provider";
import StatusGroupsForInternal from "./pages/status-groups/status-groups-for-internal";
import { useRealmApp } from "./store/RealmApp";
// import { useUserbased } from "./store/UserbasedContext";
// import userbased from "userbased-js";
import { useGetSpaces } from "./api/Spaces/spaces";
import Companies from "./pages/Companies/companies";
import Company from "./pages/Companies/company";
import ExternalLayoutWithSidebar from "./pages/Layouts/external-layout-with-sidebar";
import SettingsLayout from "./pages/Settings/settings-layout";
import SpaceSettings from "./pages/Settings/space-settings/space-settings";
import SpaceTaskSettings from "./pages/Settings/space-settings/space-task-settings";
import WorkspaceGeneralSettings from "./pages/Settings/workspace-settings/general";
import SpaceProjects from "./pages/spaces/space/projects";
import SpaceTasks from "./pages/spaces/space/tasks";
import Spaces from "./pages/spaces/spaces";
import MyTasks from "./pages/tasks/my-tasks";

function App() {
  const subdomain = getSubdomain();
  useNotificationsSubscription();
  return (
    <Routes>
      {/* {true ? ( */}
      {!isRestrictedSubdomain(subdomain) ? (
        // External user
        <>
          <Route path="/authentication" element={<ExternalAuthentication />} />
          <Route element={<RequireExternalUserLoggedIn />}>
            <Route element={<ExternalLayout />}>
              <Route path="/" element={<Navigate to="/projects" />} />
              <Route path="/projects" element={<PhasesForExternalUser />} />
              <Route path="/projects/:phaseId" element={<Homebase />} />
              <Route path="/projects/:phaseId/:tab" element={<Homebase />} />
            </Route>
          </Route>
          <Route element={<ExternalRequireNotLoggedIn />}>
            <Route path="/login" element={<ExternalLogin />} />
          </Route>
          <Route path="*" element={<Navigate to="/login" />} />
        </>
      ) : (
        // Internal user
        <>
          <Route element={<RequireLoggedIn />}>
            <Route path="/" element={<Navigate to="/my-tasks" />} />
            <Route element={<RequireSetupPending />}>
              <Route path="/setup" element={<PostVerificationSetup />} />
            </Route>
            <Route element={<RequireExternalUserSetup />}>
              <Route
                path="/setup-external-user"
                element={<ExternalUserEmailPasswordSetup />}
              />
            </Route>
            <Route
              path="/settings/slack/authentication"
              element={<SlackAuthentication />}
            />
            <Route
              path="/settings/hubspot/authentication"
              element={<HubSpotAuthentication />}
            />
            <Route element={<RequireSetupIfInternal />}>
              <Route element={<StatusGroupsForInternal />}>
                <Route path="/external-view" element={<ExternalViewSetup />}>
                  <Route
                    path="shared-spaces/:sharedSpaceId"
                    element={<SharedSpace />}
                  />
                  <Route
                    path="shared-spaces/:sharedSpaceId/phases/:phaseId"
                    element={<Homebase />}
                  />
                  <Route
                    path="shared-spaces/:sharedSpaceId/phases/:phaseId/:tab"
                    element={<Homebase />}
                  />
                </Route>

                <Route element={<Layout />}>
                  <Route path=":slug" element={<ExternalViewInternallySetup />}>
                    <Route
                      path=""
                      element={<ExternalSharedSpaceForInternalUser />}
                    />
                    <Route
                      path="projects"
                      element={<ExternalProjectsForInternalUser />}
                    />
                    <Route
                      path="projects/:phaseId"
                      element={<ExternalProject />}
                    />
                    <Route
                      path="projects/:phaseId/:tab"
                      element={<ExternalProject />}
                    />
                  </Route>
                </Route>
                <Route element={<Layout />}>
                  <Route path="*" element={<Navigate to="/my-tasks" />} />
                  <Route path="/project-groups" element={<Roadmaps />} />
                  <Route
                    path="/project-groups/:projectGroupId"
                    element={<Roadmap />}
                  />
                  <Route
                    path="/project-groups/:projectGroupId/:tab"
                    element={<Roadmap />}
                  />
                  <Route
                    path="/project-groups/:projectGroupId/settings"
                    element={<RoadmapSettings />}
                  />
                  <Route
                    path="/workspace-cleaner"
                    element={<WorkspaceCleaner />}
                  />
                  <Route path="/companies" element={<Companies />} />
                  <Route path="/companies/:companyId" element={<Company />} />
                  <Route
                    path="/companies/:companyId/:tab"
                    element={<Company />}
                  />
                  <Route path="/views" element={<Views />} />
                  <Route path="/analytics" element={<Analytics />} />
                  <Route path="/analytics/guides" element={<Guides />} />
                  <Route
                    path="/analytics/guides/pipeline-review"
                    element={<PipelineReview />}
                  />
                  <Route
                    path="/analytics/project-performance"
                    element={<ProjectsPerformance />}
                  />
                  <Route
                    path="/analytics/project-template-performance"
                    element={<ProjectTemplatePerformance />}
                  />

                  <Route path="/projects" element={<Projects />} />
                  <Route path="/projects/:phaseId" element={<Phase />} />
                  <Route path="/projects/:phaseId/:tab" element={<Phase />} />
                  <Route
                    path="/projects/:phaseId/settings"
                    element={<PhaseSettings />}
                  />
                  <Route
                    path="/projects/:phaseId/notifications"
                    element={<PhaseNotifications />}
                  />
                  <Route
                    path="/projects/:phaseId/customer-view"
                    element={<HomebaseSetup />}
                  />
                  <Route path="/projects/:phaseId/:tab" element={<Phase />} />
                  <Route path="/templates">
                    <Route path="" element={<Templates />} />
                    <Route path="projects" element={<JourneyTemplates />} />
                    <Route
                      path="projects/:journeyTemplateId"
                      element={<JourneyTemplate />}
                    />
                    <Route path="forms" element={<FormTemplates />} />
                    <Route
                      path="forms/:formTemplateId"
                      element={<FormTemplate />}
                    />
                    {/* No match */}
                    <Route
                      path="*"
                      element={<Navigate to="/templates/journeys" />}
                    />
                  </Route>

                  {/* <Route path="/tasks" element={<Tasks />} /> */}
                  <Route path="/my-tasks" element={<MyTasks />} />

                  <Route path="/spaces" element={<Spaces />} />
                  <Route path="/spaces/:spaceId">
                    <Route path="tasks" element={<SpaceTasks />} />
                    <Route path="projects" element={<SpaceProjects />} />
                    <Route path="templates">
                      <Route path="" element={<Templates />} />
                      <Route path="projects" element={<JourneyTemplates />} />
                      <Route
                        path="projects/:journeyTemplateId"
                        element={<JourneyTemplate />}
                      />
                      <Route path="forms" element={<FormTemplates />} />
                      <Route
                        path="forms/:formTemplateId"
                        element={<FormTemplate />}
                      />
                      {/* No match */}
                      <Route
                        path="*"
                        element={<Navigate to="/templates/journeys" />}
                      />
                    </Route>
                  </Route>
                  <Route
                    path="/external-spaces"
                    element={<ExternalSpacesForInternalUser />}
                  />
                  {/* <Route path="/tasks/:tab" element={} /> */}

                  {/* <Route
                    path="/settings"
                    element={
                      <Navigate to={"/settings/members"} replace={true} />
                    }
                  />
                  <Route
                    path="/settings/integrations"
                    element={<Integrations />}
                  /> */}
                  {/* <Route
                    path="/settings/custom-fields"
                    element={<CustomFields />}
                  /> */}
                  {/* <Route
                    path="/settings/integrations/slack"
                    element={<SlackIntegration />}
                  />
                  <Route
                    path="/settings/integrations/hubspot"
                    element={<HubSpotIntegration />}
                  /> */}
                  {/* <Route
                    path="/settings/notifications"
                    element={<NotificationSettings />}
                  /> */}
                  {/* <Route
                    path="/settings/notifications/projects"
                    element={<ProjectNotificationSettings />}
                  /> */}
                  {/* <Route path="/settings/members" element={<TeamSettings />} /> */}
                  {/* <Route
                    path="/settings/status-groups"
                    element={<StatusGroupsSettings />}
                  /> */}
                  {/* <Route
                    path="/settings/embed"
                    element={<EmbeddingSettings />}
                  /> */}

                  {/* <Route
                    path="/settings/slack/success"
                    element={<IntegrationSuccess integration="Slack" />}
                  /> */}
                  {/* <Route
                    path="/settings/hubspot/success"
                    element={<IntegrationSuccess integration="HubSpot" />}
                  /> */}
                  {/* <Route path="/integrations" element={<Integrations />} /> */}
                </Route>
                <Route path="settings" element={<SettingsLayout />}>
                  <Route path="" element={<WorkspaceGeneralSettings />} />
                  <Route path="integrations">
                    <Route path="" element={<Integrations />} />
                    <Route path="slack">
                      <Route path="" element={<SlackIntegration />} />
                      <Route
                        path="success"
                        element={<IntegrationSuccess integration="Slack" />}
                      />
                    </Route>
                    <Route path="hubspot">
                      <Route path="" element={<HubSpotIntegration />} />
                      <Route
                        path="success"
                        element={<IntegrationSuccess integration="HubSpot" />}
                      />
                    </Route>
                  </Route>
                  <Route path="custom-fields" element={<CustomFields />} />
                  <Route path="members" element={<TeamSettings />} />
                  <Route path="notifications">
                    <Route path="" element={<NotificationSettings />} />
                    <Route
                      path="projects"
                      element={<ProjectNotificationSettings />}
                    />
                  </Route>
                  <Route path="embed" element={<EmbeddingSettings />} />
                  <Route path="spaces/:spaceId">
                    <Route path="" element={<SpaceSettings />} />
                    <Route path="tasks" element={<SpaceTaskSettings />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
          <Route element={<InternalRequireNotLoggedIn />}>
            <Route element={<NotLoggedInLayout />}>
              <Route path="/login" element={<Login />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/new-password" element={<NewPassword />} />
              <Route path="/register" element={<Register />} />
              <Route
                path="/register-external-user"
                element={<LoginExternalUserUsingJWT />}
              />
              <Route path="/verification" element={<Verification />} />
              <Route path="/verified" element={<Verified />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Route>
          </Route>
        </>
      )}
      {/* Logged in and logged out users, internal and external = literally anyone */}
      <Route path="/automations/task-completion" element={<TaskCompletion />} />
      {/* <Route
            path="/:slug/authentication"
            element={<ExternalAuthentication />}
          /> */}
      {/* Only logged out users */}
      {/* <Route element={<RequireNotLoggedIn />}>
        <Route path="/:slug/login" element={<CustomerLogin />} />
        <Route path="/:slug" element={<RedirectToSlugLogin />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/verified" element={<Verified />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Route> */}

      {/* Only logged in users */}
      <Route element={<RequireLoggedIn />}>
        {/* Only external users */}
        {/* <Route element={<RequireLoggedInAsExternalUser />}>
          <Route path="/:slug" element={<EmbedSetup />}>
            <Route path=":phaseId" element={<Homebase />} />
            <Route
              path="shared-spaces/:sharedSpaceId"
              element={<ExternalSharedSpace />}
            />
          </Route>
        </Route> */}
        {/* Only internal users */}
        {/* <Route element={<RequireNotExternal />}></Route> */}
        {/* both */}
      </Route>

      <Route element={<RequireLoggedIn />}>
        {/* <Route path="/sharedspace" element={<RequireSharedSpace />}>
          <Route path="setup" element={<SharedspaceSetup />} />
          <Route element={<RequireSharedSpaceSetup />}>
            <Route element={<ExternalSharedspaceLayout />}>
              <Route path="home" element={<ExternalSharedspaceHome />} />
              <Route path="invites" element={<ExternalSharedspaceInvites />} />
              <Route path=":sharedSpaceId">
                <Route path="" element={<ExternalSharedspaceDashboard />} />
                <Route
                  path="journeys/active/:journeyRunId"
                  element={<ActiveJourney view={"external"} />}
                />
                <Route
                  path="knowledge-base/:knowledgeResourceId"
                  // element={<KnowledgeResourceView view={"external"} />}
                />
              </Route>

              <Route path="*" element={<Home />} />
            </Route>
          </Route>
        </Route> */}
      </Route>
    </Routes>
  );
}

export default App;

// function NewTaskForExternalProjectWrapper() {
//   return (
//     <NewTaskForExternalProjectProvider>
//       <>
//         <NewTaskForExternalProject />
//         <Outlet />
//       </>
//     </NewTaskForExternalProjectProvider>
//   );
// }

function GoToSpace() {
  const { data: spaces } = useGetSpaces();
  if (spaces) {
    return <Navigate to={`/spaces/${spaces[0]._id}/projects`} />;
  }
  return (
    <div>
      <Loading className="mt-[20vh]" />
    </div>
  );
}

function ExternalViewInternallySetup() {
  const { slug } = useParams();
  const { data: statusGroups } = useGetStatusGroupsFromSlug(String(slug));
  const statusGroupsCtx = useStatusGroups();

  useEffect(() => {
    if (!!statusGroups?.length) {
      statusGroupsCtx.dispatch({
        type: "status_groups_update",
        statusGroups: [...statusGroupsCtx.state.statusGroups, ...statusGroups],
      });
    }
  }, [statusGroups]);

  return (
    <>
      <Outlet />
      <NewTaskForExternalUser slug={String(slug)} />
    </>
  );
}

export function getSubdomain(): string | null {
  const hostname = window.location.hostname;
  const parts = hostname.split(".");

  // Assuming a standard 3-part domain format like 'subdomain.domain.com'
  if (parts.length === 3) {
    return parts[0];
  }

  // No subdomain found
  return null;
}

export function isRestrictedSubdomain(subdomain: string | null): boolean {
  if (!subdomain) return true;
  return subdomain === "app" || subdomain === "dev" || subdomain === "staging";
}

function RequireExternalUserLoggedIn() {
  const app = useRealmApp();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramsString = searchParams.toString();
  // console.log(paramsString);
  const token = searchParams.get("token");
  const sharedSpaceId = searchParams.get("sharedSpaceId");
  // console.log(app.currentUser);
  if (app.currentUser) {
    return <Outlet />;
  } else if (!!token && !!sharedSpaceId) {
    return <Navigate to={`/authentication?${paramsString}`} />;
  } else {
    return <Navigate to="/login" />;
  }
}

export const RequireLoggedIn = () => {
  // const userbased = useUserbased();
  const app = useRealmApp();

  // useEffect(() => {
  //   if (app.currentUser) {
  //     userbased?.identify(app.currentUser.customData.email);
  //   }
  // }, [userbased, app.currentUser]);

  if (!app.currentUser) {
    return <Navigate to="/login" />;
  }
  const identities: {
    id: string;
    providerType: "custom-token" | "local-userpass";
  }[] = app.currentUser?.identities;
  const hasCustomToken = identities.some(
    (i) => i.providerType === "custom-token"
  );
  if (!hasCustomToken) {
    return <CredentialLinking />;
  } else {
    if (app.currentUser.customData._id) {
      // userbased.identify(app.currentUser.customData.email);
    }
    return <Outlet />;
  }
  // if (app.currentUser.identities)
  //   return app.currentUser ? <Outlet /> : <Navigate to="/login" />;
};

function CredentialLinking() {
  const { data } = useGetJWTForInternalUser();
  const app = useRealmApp();

  async function linkAccounts(token: string) {
    // console.log(token);
    const credentials = Realm.Credentials.jwt(token);
    await app.currentUser.linkCredentials(credentials);
    await app.currentUser.refreshCustomData();
  }

  useEffect(() => {
    if (data) {
      const token = data.token;
      linkAccounts(token);
    }
  }, [data]);

  // useEffect(() => {
  //   const identities: {
  //     id: string;
  //     providerType: "custom-token" | "local-userpass";
  //   }[] = app.currentUser?.identities;
  //   const hasCustomToken = identities.some(
  //     (i) => i.providerType === "custom-token"
  //   );
  //   if (hasCustomToken) {
  //     return;
  //   }
  // }, [app.currentUser.identities]);

  return <Loading className="mt-[20vh]" />;
}

// export const RequireLoggedInAsExternalUser = () => {
//   const { slug, phaseId } = useParams();
//   const localStorageSlug = localStorage.getItem("slug");
//   const localStoragePhaseId = localStorage.getItem("phaseId");
//   // const { data: phases } = useGetPhasesForUser();
//   const app = useRealmApp();

//   if (!localStorageSlug) {
//     // console.log("1");
//     // localStorage.clear();
//     // app.logOut();
//     return <Navigate to="/login" />;
//   }

//   if (!localStoragePhaseId) {
//     // console.log("2");
//     // localStorage.clear();
//     // app.logOutOfCustomerPortal(localStorageSlug);
//     return <Navigate to="/login" />;
//   }

//   if (slug !== localStorageSlug) {
//     return <Navigate to={`/${localStorageSlug}/${localStoragePhaseId}`} />;
//   }

//   return <Outlet />;

//   // if (sharedSpaceId) {
//   //   if (slug !== localStorageSlug) {
//   //     return (
//   //       <Navigate
//   //         to={`/${localStorageSlug}/shared-spaces-${localStorageSharedSpaceId}/homebase`}
//   //       />
//   //     );
//   //   }
//   // } else {
//   //   return (
//   //     <Navigate
//   //       to={`/${localStorageSlug}/shared-spaces-${localStorageSharedSpaceId}/homebase`}
//   //     />
//   //   );
//   // }

//   // if (sharedSpaces) {
//   //   const sharedSpace = sharedSpaces.find((s) => {
//   //     if (s.type === "external") {
//   //       return s.instance.slug === slug;
//   //     } else {
//   //       return false;
//   //     }
//   //   });
//   //   if (sharedSpace) {
//   //     return <Outlet />;
//   //   } else {
//   //     return <Navigate to="/dashboard" />;
//   //   }
//   // }
//   // return <></>;
// };

export const RequireSetupIfInternal = () => {
  const app = useRealmApp();
  if (app.currentUser?.customData?.instanceId) {
    return <Outlet />;
  } else {
    return <Navigate to="/setup" />;
  }
};

// export const RequireNotExternal = () => {
//   const app = useRealmApp();
//   const slug = localStorage.getItem("slug");
//   const phaseId = localStorage.getItem("phaseId");

//   if (slug && phaseId) {
//     return <Navigate to={`/${slug}/${phaseId}`} />;
//   } else {
//     return <Outlet />;
//   }
// };

export const RequireSetupPending = () => {
  const app = useRealmApp();
  if (app.currentUser?.customData?.instanceId) {
    return <Navigate to="/my-tasks" />;
  } else {
    const identities: {
      id: string;
      providerType: "custom-token" | "local-userpass";
    }[] = app.currentUser?.identities;
    const hasUserPass = identities.some(
      (i) => i.providerType === "local-userpass"
    );
    if (!hasUserPass) {
      return <Navigate to="/setup-external-user" />;
    }
    return <Outlet />;
  }
};

const InternalRequireNotLoggedIn = () => {
  const app = useRealmApp();
  return app.currentUser ? <Navigate to="/my-tasks" /> : <Outlet />;
};

const ExternalRequireNotLoggedIn = () => {
  const app = useRealmApp();
  return app.currentUser ? <Navigate to="/" /> : <Outlet />;
};

function NotLoggedInLayout() {
  return (
    <main className="h-screen overflow-auto">
      <Outlet />
    </main>
  );
}

function RequireExternalUserSetup() {
  const app = useRealmApp();
  if (!app.currentUser) {
    return <Navigate to="/login" />;
  }
  const identities: {
    id: string;
    providerType: "custom-token" | "local-userpass";
  }[] = app.currentUser?.identities;
  const hasUserPass = identities.some(
    (i) => i.providerType === "local-userpass"
  );
  if (!hasUserPass) {
    return <Outlet />;
  } else {
    return <Navigate to={"/"} />;
  }
}
