import { Outlet } from "react-router-dom";
import AppLayoutContextProvider from "src/components/Layout/AppLayoutContextProvider";
import Sidebar from "src/components/Layout/Sidebar";
import { getRGBColor } from "src/utils/functions/embed";
import LayoutContextProvider from "../../components/Layout/LayoutContextProvider";
import ChatPanel from "../Chats/chat-panel";
import NewTaskForInternalUser from "../Services/Tasks/NewTaskForInternalUser";
import TaskPanel from "../Services/Tasks/TaskPanel";
import { SidebarContextProvider } from "./Sidebar/SiderbarContextProvider";

const Layout = () => {
  const color = `{${getRGBColor("#966fff", "primary")}}`;
  return (
    <LayoutContextProvider>
      <AppLayoutContextProvider>
        <SidebarContextProvider>
          <div className="flex h-screen">
            <Sidebar />
            <main className="h-full flex-grow overflow-auto">
              <Outlet />
            </main>
            <TaskPanel />
            {/* <WorkspaceCleanerListener /> */}
            <ChatPanel />
            <style>
              :root
              {color}
            </style>
            <NewTaskForInternalUser />
          </div>
        </SidebarContextProvider>
      </AppLayoutContextProvider>
    </LayoutContextProvider>
  );
};

export default Layout;
