import { useQueryClient } from "@tanstack/react-query";
import { ObjectID } from "bson";
import { useEffect } from "react";
import {
  NotificationAction,
  NotificationType,
  notificationsKeys,
} from "src/api/General/notifications";
import { waitingRoomKeys } from "src/api/Misc/WaitingRoom/waitingroom";
import { journeyTemplateKeys } from "src/api/Services/Journeys/journeyTemplates";
import { phaseKeys } from "src/api/Services/SharedSpace/phases";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { useRealmApp } from "src/store/RealmApp";

export const useNotificationsSubscription = () => {
  const queryClient = useQueryClient();
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();

  // console.log(app.currentUser.customData);
  useEffect(() => {
    if (
      app.currentUser &&
      app.currentUser?.customData &&
      process.env.REACT_APP_WEBSOCKET_URL
    ) {
      const websocket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);
      websocket.onopen = () => {
        websocket.send(
          JSON.stringify({
            action: "setUser",
            userId: app.currentUser.customData._id,
          })
        );
        if (app.currentUser.customData.instanceId) {
          websocket.send(
            JSON.stringify({
              action: "setInstance",
              instanceId: app.currentUser.customData.instanceId.$oid,
            })
          );
        }
      };

      websocket.onmessage = (event) => {
        queryClient.invalidateQueries(notificationsKeys.unreadNotifications());
        queryClient.invalidateQueries(notificationsKeys.allNotifications());
        const data: {
          action: NotificationAction;
          type: NotificationType;
          message: string;
          _id?: ObjectID;
          templateId?: string;
        } = JSON.parse(event.data);

        if (data.type === "task") {
          // if (data.action === "UPDATE") {
          //   snackbarCtx.showSnackbar(data.message, "info");
          // } else
          if (data.action === "CREATE") {
            queryClient.invalidateQueries(["tasks"]);
          }
        }
        // else if (data.type === "journey") {
        //   if (data.action === "CREATE") {
        //     snackbarCtx.showSnackbar(data.message, "info");
        //   }
        // }
        else if (data.type === "vessel") {
          if (data.action === "SYNC") {
            snackbarCtx.showSnackbar(data.message, "info");
          }
        } else if (data.type === "instance invite") {
          if (data.action === "UPDATE") {
            snackbarCtx.showSnackbar(data.message, "info");
          }
        } else if (data.type === "shared space invite") {
          if (data.action === "UPDATE") {
            snackbarCtx.showSnackbar(data.message, "info");
          }
        } else if (data.type === "waiting room object") {
          if (data.action === "CREATE") {
            snackbarCtx.showSnackbar(data.message, "info");
            queryClient.invalidateQueries(waitingRoomKeys.objects());
          }
        } else if (data.type === "phaseForecast") {
          const phaseForecastData: {
            phaseId: string;
            action: "started" | "completed";
            phaseName?: string;
          } = JSON.parse(event.data);
          // console.log(phaseForecastData);
          if (phaseForecastData.action === "started") {
            // snackbarCtx.showSnackbar("Forecast is being re-calculated", "info");
          } else if (phaseForecastData.action === "completed") {
            // snackbarCtx.showSnackbar("Forecast has been recalculated", "info");
            queryClient.invalidateQueries(["projects"]);
            queryClient.invalidateQueries(
              phaseKeys.keyDates(phaseForecastData.phaseId)
            );
            // queryClient.invalidateQueries();
          }
        } else if (data.type === "thread" || data.type === "thread message") {
          queryClient.invalidateQueries(["threads"]);
        } else if (data.type === "templateDurations") {
          if (data.templateId) {
            console.log(data);
            queryClient.invalidateQueries([
              ...journeyTemplateKeys.journeyTemplate(
                new ObjectID(data.templateId)
              ),
              "stageTemplates",
            ]);
          }
        }

        // const queryKey = [...data.entity, data.id].filter(Boolean)
        // queryClient.invalidateQueries(queryKey)

        // console.log(data);
      };

      return () => {
        websocket.close();
      };
    }
  }, [app.currentUser]);
};
