import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";
import { Column, ColumnDef } from "@tanstack/react-table";
import clsx from "clsx";
import dayjs from "dayjs";
import { OnboardingProjectData } from "src/api/Services/Projects/projects";
import { Badge } from "src/components/ui/badge";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "src/components/ui/hover-card";
import { getTimeDifference } from "src/utils/functions/dateTime";
import { getPhaseStatus } from "../SharedSpace/Phases/Tabs/Overview";

export const columns: ColumnDef<OnboardingProjectData>[] = [
  {
    id: "project",
    header: () => {
      return <div className="w-72">Project</div>;
    },
    cell: ({ row }) => {
      return (
        <div className="h-full w-72 truncate">{row.original.journey.name}</div>
      );
    },
    accessorFn: ({ journey }) => journey.name,
  },
  {
    id: "customer",
    header: ({ table }) => {
      return <div className="w-52">Customer</div>;
    },
    cell: ({ row }) => {
      return (
        <div className="h-full w-52 truncate">
          {row.original.customer?.name}
        </div>
      );
    },
    enableSorting: false,
    enableHiding: false,
    accessorFn: ({ customer }) => customer?.name,
  },

  {
    id: "status",
    header: ({ column }) => {
      return (
        <div className="flex items-center space-x-2">
          <span>Status</span>
        </div>
      );
    },
    cell: ({ row }) => {
      const { status } = row.original;
      return (
        <div className="h-full">
          <span>{getPhaseStatus(status)}</span>
        </div>
      );
    },
  },
  {
    id: "onboardingProgress",
    header: ({ column }) => (
      <div className="flex items-center space-x-1">
        <span>Progress</span>
        <SortToggle column={column} />
      </div>
    ),
    accessorFn: ({ journey }) => {
      // const totalTasks =
      //   allTasksBreakdown?.external?.toDo.total +
      //   allTasksBreakdown?.external?.inProgress.total +
      //   allTasksBreakdown?.external?.canceled +
      //   allTasksBreakdown?.external?.completed.late +
      //   allTasksBreakdown?.external?.completed.onTime +
      //   allTasksBreakdown?.internal?.toDo.total +
      //   allTasksBreakdown?.internal?.inProgress.total +
      //   allTasksBreakdown?.internal?.canceled +
      //   allTasksBreakdown?.internal?.completed.late +
      //   allTasksBreakdown?.internal?.completed.onTime;
      // const completedTasks =
      //   allTasksBreakdown?.external?.completed.late +
      //   allTasksBreakdown?.external?.completed.onTime +
      //   allTasksBreakdown?.internal?.completed.late +
      //   allTasksBreakdown?.internal?.completed.onTime;
      const progress = Math.round(
        (journey.completedTasks / journey.totalTasks) * 100
      ).toFixed(0);
      return progress;
    },
    sortingFn: "alphanumeric",
    cell: ({ row }) => {
      const progress = Math.round(
        (row.original.journey.completedTasks /
          row.original.journey.totalTasks) *
          100
      );
      const hasTasks = row.original.journey.totalTasks > 0;
      return (
        <>
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-3">
              <div
                className={clsx(
                  "h-1.5 w-20 shrink-0 overflow-hidden rounded-sm",
                  row.original.journey.completedTasks === 0
                    ? "border-gray-300 bg-gray-200/70"
                    : "border-green-200 bg-green-200/70"
                )}
              >
                <div
                  style={{ width: hasTasks ? progress + "%" : "0%" }}
                  className="h-full rounded-sm bg-green-500"
                ></div>
              </div>
              <div className="w-6 text-sm font-medium text-gray-600">
                {(hasTasks ? progress.toFixed(0) : "0") + "%"}
              </div>
            </div>
            <div className="flex h-5 w-fit items-center justify-center rounded-md bg-gray-50 px-1.5">
              <div className="text-[11px] text-gray-400">{`${row.original.journey.completedTasks}/${row.original.journey.totalTasks}`}</div>
            </div>
          </div>
        </>
      );
    },
  },
  {
    id: "forecastedCompletionDate",
    header: ({ column }) => (
      <div className="flex items-center space-x-1">
        <div>
          <span>Status</span>{" "}
          {/* <span className="text-xs lowercase text-gray-400"> vs </span>
          <span>Actual</span> */}
        </div>
        <SortToggle column={column} />
      </div>
    ),
    cell: ({ row }) => {
      if (row.original.type === "Simple") {
        return <div className="text-gray-500">-</div>;
      }
      const forecastedCompletionDate = new Date(
        row.original.keyDates.forecastedCompletionDate
      );
      const plannedCompletionDate = new Date(
        row.original.keyDates.plannedCompletionDate
      );
      let completionDate;
      let completionDelta;
      if (row.original.keyDates.completionDate) {
        completionDate = new Date(row.original.keyDates.completionDate);
        completionDelta = getTimeDifference(
          completionDate,
          plannedCompletionDate
        );
      }

      const delta = getTimeDifference(
        forecastedCompletionDate,
        plannedCompletionDate
      );
      return (
        <>
          {!!completionDate && !!completionDelta ? (
            <div>
              <div
                className={clsx(
                  "text-sm",
                  delta.isDate2After ? "text-green-500" : "text-red-500"
                )}
              >{`Completed ${delta.absValue} ${delta.unit} ${
                delta.isDate2After ? "ahead of time" : "behind schedule"
              }`}</div>
            </div>
          ) : (
            <div className="space-y-1">
              <div
                className={clsx(
                  "text-sm",
                  delta.isDate2After ? "text-green-500" : "text-red-500"
                )}
              >{`${delta.absValue} ${delta.unit} ${
                delta.isDate2After ? "ahead" : "behind"
              }`}</div>
              {/* <div
                className={clsx(
                  "text-xs text-gray-500"
                  // delta.isDate2After ? "text-green-500" : "text-red-500"
                )}
              >
                <span>{dayjs(plannedCompletionDate).format("DD MMM")}</span>
                <span className="text-gray-400"> vs </span>
                <span>{dayjs(forecastedCompletionDate).format("DD MMM")}</span>
              </div> */}
            </div>
          )}
        </>
      );
    },

    accessorFn: (row) => {
      const forecastedCompletionDate1 = new Date(
        row.keyDates.forecastedCompletionDate
      );
      const plannedCompletionDate1 = new Date(
        row.keyDates.plannedCompletionDate
      );
      const delta = getTimeDifference(
        forecastedCompletionDate1,
        plannedCompletionDate1
      );
      // console.log(`${}`)
      return delta.isDate2After
        ? delta.absDifferenceInMs
        : -delta.absDifferenceInMs;
    },
    enableSorting: true,
    // sortingFn: "alphanumeric",
    sortingFn: (row1, row2, columnId) => {
      return Number(row1.getValue(columnId)) - Number(row2.getValue(columnId));
    },
  },
  {
    id: "revenue",
    header: ({ column }) => {
      return (
        <div className="flex items-center space-x-1">
          <span>Revenue</span>
          <SortToggle column={column} />
        </div>
      );
    },
    cell: ({ row }) => {
      // console.log(row.original);
      return (
        <div className="h-full">
          <span>
            {row.original?.revenue
              ? "$" + row.original.revenue.toLocaleString()
              : "-"}
          </span>
        </div>
      );
    },
    accessorFn: ({ revenue }) => revenue ?? 0,
    enableSorting: true,
  },
  {
    id: "stages",
    header: () => <span>Stages</span>,
    cell: ({ row }) => {
      const { stages } = row.original;

      return (
        <HoverCard openDelay={200} closeDelay={200}>
          <HoverCardTrigger asChild>
            <div className="flex items-center space-x-1.5">
              {stages.map((stage) => (
                <div
                  key={stage._id.toString()}
                  className={clsx(
                    "h-3 w-2.5 rounded-sm border",
                    stage.completionPercent === 100
                      ? "border-green-600 bg-green-400"
                      : stage.completionPercent > 0
                      ? "border-green-500 bg-green-100"
                      : "border-gray-400"
                  )}
                ></div>
              ))}
            </div>
          </HoverCardTrigger>
          <HoverCardContent>
            <div className="space-y-6">
              {/* <div>{"Stage Completions"}</div> */}
              <div className="space-y-2">
                {stages.map((stage) => (
                  <div
                    className="flex items-center justify-between space-x-4"
                    key={stage._id.toString()}
                  >
                    <div>{stage.name}</div>
                    <Badge
                      variant={
                        stage.completionPercent === 100
                          ? "darkGreen"
                          : stage.completionPercent === 0
                          ? "gray"
                          : "green"
                      }
                    >
                      {stage.completionPercent === 100
                        ? "100%"
                        : stage.completionPercent.toFixed(0) + "%"}
                    </Badge>
                  </div>
                ))}
              </div>
            </div>
          </HoverCardContent>
        </HoverCard>
      );
    },
  },
  // {
  //   id: "plannedCompletionDate",
  //   header: () => <span>Planned Completion Date</span>,
  //   cell: ({ row }) => {
  //     const plannedCompletionDate = new Date(
  //       row.original.keyDates.plannedCompletionDate
  //     );
  //     return (
  //       <div className="text-base text-gray-600">
  //         {dayjs(plannedCompletionDate).format("DD MMM, YYYY")}
  //       </div>
  //     );
  //   },
  // },

  {
    id: "startedDate",
    accessorFn: ({ keyDates }) => keyDates.startedDate,
    header: ({ column }) => (
      <div className="flex items-center space-x-1">
        <span>Start Date</span>
        <SortToggle column={column} />
      </div>
    ),
    cell: ({ row }) => {
      const startedDate = new Date(row.original.keyDates.startedDate);
      const timeAgo = getTimeDifference(startedDate, new Date());
      return (
        <div className="space-y-1">
          <div className="text-sm">
            {dayjs(startedDate).format("DD MMM, YYYY")}
          </div>
          {/* <div className="text-xs text-gray-400">{`${timeAgo.absValue} ${timeAgo.unit} ago`}</div> */}
        </div>
      );
    },
    // sortingFn: (rowA, rowB) => {
    //   const startedDateA = new Date(rowA.original.keyDates.startedDate);
    //   const startedDateB = new Date(rowB.original.keyDates.startedDate);
    //   return startedDateA.getTime() - startedDateB.getTime();
    // },
    sortingFn: "datetime",
    // enableSorting: true,
  },
  {
    id: "owner",
    header: () => {
      return <span>Owner</span>;
    },
    cell: ({ row }) => {
      return (
        <div className="h-full">
          <span>{row.original.ownerUser.name}</span>
        </div>
      );
    },
  },

  // {
  //   id: "userProgress",
  //   header: () => <span>User Progress</span>,
  //   cell: ({ row }) => {
  //     const { userTasksBreakdown } = row.original;
  //     const totalTasks =
  //       userTasksBreakdown?.toDo.total +
  //       userTasksBreakdown?.inProgress.total +
  //       userTasksBreakdown?.canceled +
  //       userTasksBreakdown?.completed.late +
  //       userTasksBreakdown?.completed.onTime;

  //     const completedTasks =
  //       userTasksBreakdown?.completed.late +
  //       userTasksBreakdown?.completed.onTime;
  //     const progress = Math.round((completedTasks / totalTasks) * 100).toFixed(
  //       0
  //     );
  //     return (
  //       <div className="space-y-1">
  //         <div className="text-base text-gray-600">{progress + "%"}</div>
  //         <div className="text-xs text-gray-400">{`${completedTasks}/${totalTasks} completed`}</div>
  //       </div>
  //     );
  //   },
  // },
];

export function SortToggle({ column }: { column: Column<OnboardingProjectData> }) {
  return (
    <button
      className="inline-flex rounded-md px-1 py-1 hover:bg-gray-300/20"
      onClick={() => {
        if (column.getIsSorted() === "asc") {
          column.toggleSorting(true);
        } else {
          column.toggleSorting(false);
        }
      }}
    >
      <ArrowUpIcon
        className={clsx(
          "h-3 w-3 stroke-2 text-gray-300",
          column.getIsSorted() === "asc" && "text-primary-main"
        )}
        // onClick={() => {
        //   column.toggleSorting(false);
        // }}
      />
      <ArrowDownIcon
        className={clsx(
          "h-3 w-3 stroke-2 text-gray-300",
          column.getIsSorted() === "desc" && "text-primary-main"
        )}
        onClick={() => {
          column.toggleSorting(true);
        }}
      />
      {/* <ChevronUpDownIcon className="h-4 w-4 text-gray-400" /> */}
    </button>
  );
}
