import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ObjectID } from "bson";
import { buildFetcher, buildLauncher, buildSetter } from "src/api";
import { WaitingRoomObject } from "src/components/Misc/WaitingRoom/types";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { useRealmApp } from "src/store/RealmApp";

export const waitingRoomKeys = {
  objects: () => ["waitingRoom", "all"] as const,
};

// Get Waiting Room objects
export const useGetWaitingRoomObjects = (): {
  waitingRoomObjects: WaitingRoomObject[] | undefined;
} => {
  const app = useRealmApp();
  const meta = {
    functionName: "getWaitingRoomObjects",
    parameters: {
      instanceId: app.currentUser.customData.instanceId,
    },
  };

  const { data } = useQuery<WaitingRoomObject[]>(
    waitingRoomKeys.objects(),
    buildFetcher(app, meta)
  );

  return {
    waitingRoomObjects: data,
  };
};

export const useDiscardWaitingRoomOject = () => {
  const app = useRealmApp();
  const functionName = "discardWaitingRoomObject";
  const fieldName = "update";
  const queryClient = useQueryClient();
  const snackbarCtx = useSnackBar();

  const discardWaitingRoomObjectMutation = useMutation(
    buildSetter(app, functionName, fieldName),
    {
      onMutate: async ({ update }: { update: { _id: ObjectID } }) => {
        const queryKey = waitingRoomKeys.objects();
        await queryClient.cancelQueries(queryKey);
        const previousObjects = queryClient.getQueryData(queryKey);
        queryClient.setQueryData(
          queryKey,
          (waitingRoomObjects: WaitingRoomObject[] = []) =>
            waitingRoomObjects.filter((w) => w._id !== update._id)
        );
        return { previousObjects };
      },
      onError: (data, variables, context) => {
        queryClient.setQueryData(
          waitingRoomKeys.objects(),
          context?.previousObjects
        );
        snackbarCtx.showSnackbar(
          "Error discarding waiting room object",
          "error"
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(waitingRoomKeys.objects());
        snackbarCtx.showSnackbar("Waiting room object discarded", "success");
      },
    }
  );
  return discardWaitingRoomObjectMutation;
};

export const useStartWaitingRoomObject = () => {
  const app = useRealmApp();
  const functionName = "startWaitingRoomObject";
  const queryClient = useQueryClient();
  const snackbarCtx = useSnackBar();

  const startWaitingRoomObjectMutation = useMutation(
    buildLauncher(app, functionName),
    {
      onError: (data, variables, context) => {
        snackbarCtx.showSnackbar("Error starting journey", "error");
      },
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries(waitingRoomKeys.objects());
        snackbarCtx.showSnackbar("Journey started!", "success");
      },
    }
  );

  return startWaitingRoomObjectMutation;
};
