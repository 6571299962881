import {
  ArrowLeftIcon,
  EllipsisVerticalIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  EyeIcon,
  EyeSlashIcon,
  PlusIcon,
  TrashIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import {
  CalendarIcon,
  CaretSortIcon,
  CheckIcon,
  ChevronDownIcon,
  ClockIcon,
  ExternalLinkIcon,
  OpenInNewWindowIcon,
} from "@radix-ui/react-icons";
import { useQueryClient } from "@tanstack/react-query";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import Typography from "@tiptap/extension-typography";
import { EditorContent, Extension, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { ObjectID } from "bson";
import clsx from "clsx";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FormData,
  FormTemplateBooleanBlockData,
  FormTemplateDateBlockData,
  FormTemplateElementBlockData,
  FormTemplateEmailBlockData,
  FormTemplateHeadingBlockData,
  FormTemplateLongTextBlockData,
  FormTemplateRichTextBlockData,
  FormTemplateSectionBlockData,
  FormTemplateShortTextBlockData,
  FormTemplateTableBlockData,
  useGetForm,
  useUpdateForm,
} from "src/api/Forms/forms";
import { ParentStatus } from "src/api/General/status-groups";
import {
  NoteData,
  useCreateTaskNote,
  useDeleteNote,
  useGetTaskNotes,
  useUpdateNote,
} from "src/api/Notes/notes";
import { useGetProjectMembers } from "src/api/Services/Projects/projects";
import { useUploadFileForTaskInSharedSpace } from "src/api/Services/SharedSpace/files";
import {
  ReducedTaskData,
  TaskData,
  TaskLogData,
  TaskWithUserType,
  // TaskerData,
  useDeleteTask,
  useGetSubtasksForTask,
  useGetTaskLogs,
  useGetTaskersForInstance,
  // useGetTaskersForInstance,
  // useGetTaskersForSharedSpace,
  useUpdateTask,
  useUpdateTaskFromProjectView,
} from "src/api/Services/Tasks/tasks";
import {
  useCreateThread,
  useCreateThreadInPhaseTask,
  useCreateThreadInTask,
  useCreateThreadMessage,
  useGetThread,
  useGetThreadMessages,
} from "src/api/Services/Threads/threads";
import LinkedKRs from "src/components/Services/Reusable/Tasks/Elements/LinkedKRs";
import { Title } from "src/components/Services/Reusable/Tasks/Elements/Title";
import InputHeading from "src/components/ui/Headings/InputHeading";
import TextArea from "src/components/ui/Inputs/Text/TextArea";
import { Menu } from "src/components/ui/Menu/Menu";
import { Button } from "src/components/ui/button";
import { Calendar } from "src/components/ui/calendar";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "src/components/ui/command";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "src/components/ui/dialog";
import TiptapEditor from "src/components/ui/editor/tiptap-editor";
import { Loading } from "src/components/ui/loading";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { Switch } from "src/components/ui/switch";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/components/ui/tabs";
import { TextInput } from "src/components/ui/text-input";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "src/components/ui/tooltip";
import { useStatusGroups } from "src/pages/status-groups/status-groups-context-provider";
import { useRealmApp } from "src/store/RealmApp";
import { getIdentifier } from "src/utils/functions/tasks";
import { cn } from "src/utils/ui/ui";
import { ChatMessages } from "../Threads/thread-elements/messages";

export function TaskEdit({
  task,
  type,
  handleClose,
}: {
  task: TaskWithUserType["task"];
  type: TaskWithUserType["type"];
  handleClose: () => void;
}) {
  console.log(task);
  // const { slug } = useParams();
  const updateTaskMutation = useUpdateTask();
  // const containerRef = useRef<HTMLDivElement>(null);

  const [title, setTitle] = useState(task?.title ?? "");

  useEffect(() => {
    if (task?.title) setTitle(task?.title);
  }, [task?.title]);

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={clsx("flex h-full overflow-auto")}>
      <div
        className={clsx(
          "flex flex-col overflow-auto",
          task?.action === "form" ? "w-[1000px]" : "w-[800px]"
        )}
      >
        <div className="shrink-0 space-y-3 px-8 pt-6">
          {!task?.dueDate && (
            <div className="pb-">
              <div
                className={clsx(
                  "inline-flex rounded-md border border-red-500 bg-red-50 px-2 py-1.5 text-sm text-red-500 shadow"
                )}
              >
                <div className="flex items-start space-x-2 text-sm">
                  <ExclamationCircleIcon className="h-5 w-5 shrink-0" />
                  <div>
                    {type === "internal" ? (
                      <>
                        <span>{`This task is inactive. It will be activated on the completion of the `}</span>
                        <span className="font-semibold">{`${task?.dependentOn?.name} `}</span>
                        <span>
                          {task?.dependentOn?.type === "stage"
                            ? "stage."
                            : "task."}
                        </span>
                        <span className="">{` Click `}</span>
                        <span
                          className="cursor-pointer font-semibold underline transition-colors hover:text-red-600"
                          onClick={() => {
                            if (!task?._id) return;
                            updateTaskMutation.mutate({
                              update: {
                                _id: task?._id,
                                overrideDependency: true,
                              },
                              metadata: {
                                phaseId: task?.phase?._id,
                                journeyId: task?.journey?._id,
                              },
                            });
                          }}
                        >{`here`}</span>
                        <span className="">
                          {` to override the dependency and activate this task.`}
                        </span>
                      </>
                    ) : (
                      <span>{`This task is inactive. It will be activated on the completion of another stage or task.`}</span>
                    )}

                    {/* <span>{`This task is inactive. It will be activated on the completion of another `}</span>
                    <span className="italic">
                      {task?.dependentOn?.type === "stage" ? "stage" : "task"}
                    </span>
                    {type === "external" && <span>.</span>}
                    {type === "internal" && (
                      <span className="">{` (${task?.dependentOn?.name})`}</span>
                    )}
                    {type === "internal" && (
                      <>
                        <span className="">{`. Click `}</span>
                        <span
                          className="cursor-pointer font-semibold underline transition-colors hover:text-red-600"
                          onClick={() => {
                            if (!task?._id) return;
                            updateTaskMutation.mutate({
                              update: {
                                _id: task?._id,
                                overrideDependency: true,
                              },
                              metadata: {
                                phaseId: task?.phase?._id,
                                journeyId: task?.journey?._id,
                              },
                            });
                          }}
                        >{`here`}</span>
                        <span className="">
                          {` to override the dependency and activate this task.`}
                        </span>
                      </>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          )}
          {!!task?.blocker && (
            <div className="flex w-fit gap-2 rounded-md border border-red-500 bg-red-50 px-2 py-1.5 text-sm text-red-500 shadow">
              <ExclamationTriangleIcon className="h-5 w-5" />
              <span>
                This task is currently stuck. {task.blocker?.resolverUser?.name}{" "}
                is resposible for resolving it.
              </span>
            </div>
          )}
          {/* {!!task?.dueDate && (
            <>
              {task?.action === "fileUpload" && !task.completionDate && (
                <div className="inline-flex items-center space-x-2 rounded-md border border-blue-500 bg-blue-50 px-2 py-1.5 text-sm text-blue-500 shadow">
                  <InformationCircleIcon className="h-5 w-5" />
                  <span>{`Upload a file to complete this task!`}</span>
                </div>
              )}
              {task?.action === "meeting" && !task.completionDate && (
                <div className="inline-flex items-center space-x-2 rounded-md border border-blue-500 bg-blue-50 px-2 py-1.5 text-sm text-blue-500 shadow">
                  <InformationCircleIcon className="h-5 w-5" />
                  <span>{`Click on the link below to book a meeting. Once booked, mark the task as complete!`}</span>
                </div>
              )}
              {task?.action === "form" && !task.completionDate && (
                <div className="inline-flex items-center space-x-2 rounded-md border border-blue-500 bg-blue-50 px-2 py-1.5 text-sm text-blue-500 shadow">
                  <InformationCircleIcon className="h-5 w-5" />
                  <span>{`Fill the form to complete this task!`}</span>
                </div>
              )}
            </>
          )} */}
          <div>
            {task?.parentTask && (
              <div className="pb-4">
                <div
                  className="flex w-fit cursor-pointer items-center gap-2 rounded-full border border-gray-300 bg-gray-50 px-3 text-sm shadow hover:bg-gray-100"
                  onClick={() => {
                    if (task?.parentTask?._id) {
                      const searchParams = new URLSearchParams(location.search);
                      searchParams.set("taskId", task.parentTask._id);
                      navigate(
                        {
                          pathname: location.pathname,
                          search: searchParams.toString(),
                        },
                        {
                          replace: true,
                        }
                      );
                    }
                  }}
                >
                  <div className="flex shrink-0 items-center gap-2 border-r border-gray-300 py-1 pr-2">
                    <ArrowLeftIcon className="h-3 w-3" />
                    <span>Parent task</span>
                  </div>
                  <span className="text-sm font-medium text-gray-500">
                    {task?.parentTask?.title}
                  </span>
                </div>
              </div>
            )}
            <div className="flex w-full justify-between gap-4 pb-4">
              <Title
                title={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                onBlur={() => {
                  if (task?.title !== title) {
                    if (!task?._id) return;
                    updateTaskMutation.mutate({
                      update: { _id: task?._id, title: title },
                      metadata: {
                        journeyId: task?.journey?._id,
                        phaseId: task?.phase?._id,
                      },
                    });
                  }
                }}
              />
              <Options task={task} handleClose={handleClose} />
            </div>
          </div>
        </div>
        <div className="space-y-4 px-8 pb-6">
          <div className="">
            <div className="flex justify-between gap-6">
              <div className="flex flex-wrap gap-2">
                {task?.dueDate && (
                  <StatusSelect
                    task={task}
                    disabled={
                      type === "external" && task.taskerType === "internal"
                    }
                    disabledStatuses={
                      task.action === "form"
                        ? ["Completed"]
                        : task.action === "fileUpload" && !task.files?.length
                        ? ["Completed"]
                        : []
                    }
                  />
                )}
                {(task?.completionDate || task?.assignedCompletionDate) && (
                  <CompletionDateSelect task={task} />
                )}
                {/* {!!task && task.status === "Stuck" && (
                  <ResolverCombobox
                    task={task}
                    disabled={type === "external"}
                  />
                )} */}
                {task?.dueDate && <DateSelect task={task} />}
                {!task?.dueDate && task?.phase?._id && (
                  <TooltipProvider>
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger asChild>
                        <div className="inline-flex h-8 cursor-default items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm text-gray-500">
                          <CalendarIcon className="mr-2 h-4 w-4" />
                          {!!task?.durations?.forecasted && (
                            <span>
                              {dayjs(
                                new Date().getTime() + task.durations.forecasted
                              ).format("DD MMM, YYYY")}
                            </span>
                          )}
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Forecasted completion date</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                )}
                {!!task && (
                  <TaskerCombobox
                    task={task}
                    disabled={task.status === "Completed"}
                  />
                )}
                {!!task && task.sharedSpaceId && type === "internal" && (
                  <VisibilityButtons task={task} />
                )}
                {!!task && <LinkedKRs task={task} />}
              </div>
              <div></div>
            </div>
            {!!task && (
              <div className="mt-4 rounded-md bg-gray-50 p-3" key={task._id}>
                <Description task={task} />
              </div>
            )}
            {/* <div
              className={clsx(
                isUpdatingDescription ? "rounded-md border px-3 py-2" : "",
                "group relative mt-4 rounded-md bg-gray-50 p-3 pr-32"
              )}
              // onClick={() => {
              //   // setIsUpdatingDescription(true);
              // }}
              ref={containerRef}
              onBlur={(event: React.FocusEvent) => {
                if (
                  !containerRef.current?.contains(event.relatedTarget as Node)
                ) {
                  setIsUpdatingDescription(false);
                  if (task?.description.trim() !== description.trim()) {
                    if (!task?._id) return;
                    updateTaskMutation.mutate({
                      update: {
                        _id: task?._id,
                        description: description,
                      },
                      metadata: {
                        phaseId: task?.phase?._id,
                        journeyId: task?.journey?._id,
                      },
                    });
                  }
                }
              }}
            >
              <Description
                isEditing={isUpdatingDescription}
                description={description}
                onBlur={(newDescription) => {
                  // console.log(newDescription, description);
                  if (newDescription.trim() !== description.trim())
                    setDescription(newDescription);
                }}
              />

              {!isUpdatingDescription && (
                <div className="absolute right-2 top-2 opacity-0 transition-opacity group-hover:opacity-100">
                  <Button
                    variant={"ghost"}
                    size="sm"
                    className="px-1.5"
                    onClick={() => {
                      setIsUpdatingDescription(true);
                    }}
                  >
                    <PencilIcon className="h-4 w-4 text-gray-500" />
                  </Button>
                </div>
              )}
              {description.trim() !== "" &&
                description.trim() !== "<p></p>" && (
                  <div
                    className={clsx(
                      "text-xs text-transparent transition-colors",
                      !isUpdatingDescription && "group-hover:text-gray-400"
                    )}
                  >
                    Click to edit description
                  </div>
                )} 
            </div> */}
            {!!task?.readOnlyFiles && task.readOnlyFiles.length > 0 && (
              <TaskFiles task={task} />
            )}
            {/* {task?.blocker && !task?.blocker.resolved && (
              <TaskBlocker task={task} />
            )} */}
          </div>
        </div>
        <div className="flex flex-1 flex-col divide-y divide-gray-300">
          <TaskDetails task={task} type={type} />
        </div>
      </div>

      <div className="min-w-[398px] max-w-md border-l border-gray-200">
        <Chat task={task} />
      </div>
    </div>
  );
}

function Description({ task }: { task: TaskData }) {
  const updateTaskMutation = useUpdateTask();
  const editor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
        autolink: true,
      }),
      Typography,
      Placeholder.configure({
        placeholder: "Add description...",
        showOnlyWhenEditable: false,
      }),
    ],
    content: task.description,
    onBlur: ({ editor }) => {
      if (task.description.trim() !== editor?.getHTML().trim() && task?._id) {
        updateTaskMutation.mutate({
          update: {
            _id: task?._id,
            description: editor?.getHTML(),
          },
          metadata: {
            phaseId: task?.phase?._id,
            journeyId: task?.journey?._id,
          },
        });
      }
    },
  });
  return <TiptapEditor editor={editor} />;
}

function TaskFiles({ task }: { task: TaskWithUserType["task"] }) {
  return (
    <div className="space-y-2 pt-4">
      <div className="flex items-center space-x-2">
        <div className="text-sm font-medium text-gray-600">Attachments</div>
        {/* <PaperClipIcon className="h-4 w-4 text-gray-400" /> */}
      </div>
      <div className="flex flex-wrap items-center gap-2 text-gray-400">
        {task?.readOnlyFiles?.map((f) => (
          <div className="flex h-8 cursor-default items-center rounded-full border pl-3 pr-1.5">
            <div className="pr-1 text-sm font-medium text-gray-500">
              {f.label}
            </div>
            {/* <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant={"ghost"}
                    className="h-5 w-5 rounded-full p-0.5"
                    onClick={() => {
                      setSelectedFile(f);
                    }}
                  >
                    <TrashIcon className="h-3 w-3 text-gray-400" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent sideOffset={8}>
                  <p>Delete attachment</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider> */}
            <Button
              variant={"ghost"}
              className="h-5 w-5 rounded-full p-0.5"
              onClick={() => {
                window.open(f.url, "_blank");
              }}
            >
              <OpenInNewWindowIcon className="h-3 w-3 text-gray-400" />
            </Button>
          </div>
        ))}
      </div>
      {/* <Dialog
        open={!!selectedFile}
        onOpenChange={(open) => {
          if (!open) {
            setSelectedFile(undefined);
          }
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Confirmation</DialogTitle>
    
          </DialogHeader>
          <div className="space-y-5 pt-3 text-sm text-gray-600">
            <div>
              Are you sure you want to delete the file{" "}
              <span className="font-medium text-gray-900">
                {selectedFile?.name}
              </span>
              ?
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild className="">
              <div className="flex justify-end space-x-3">
                <Button
                  variant={"secondary"}
                  onClick={() => {
                    setSelectedFile(undefined);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant={"destructive"}
                  onClick={() => {
                    if (taskTemplate._id && selectedFile?.name)
                      deleteTaskTemplateFileMutation.mutate({
                        taskTemplateId: taskTemplate._id.toString(),
                        name: selectedFile?.name,
                      });
                  }}
                >
                  {deleteTaskTemplateFileMutation.isLoading
                    ? "Deleting..."
                    : "Delete"}
                </Button>
              </div>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog> */}
    </div>
  );
}

function TaskDetails({
  task,
  type,
}: {
  task: TaskWithUserType["task"];
  type: TaskWithUserType["type"];
}) {
  const [value, setValue] = useState(
    task?.action === "form"
      ? "form"
      : task?.action === "meeting"
      ? "meeting"
      : task?.action === "fileUpload"
      ? "fileUpload"
      : !!task?.subTaskCount
      ? "subtasks"
      : "logs"
  );

  useEffect(() => {
    setValue(
      task?.action === "form"
        ? "form"
        : task?.action === "meeting"
        ? "meeting"
        : task?.action === "fileUpload"
        ? "fileUpload"
        : !!task?.subTaskCount
        ? "subtasks"
        : "logs"
    );
  }, [task]);
  return (
    <Tabs
      // defaultValue={
      //   task?.action === "form"
      //     ? "form"
      //     : task?.action === "meeting"
      //     ? "meeting"
      //     : task?.action === "fileUpload"
      //     ? "fileUpload"
      //     : !!task?.subTaskCount
      //     ? "subtasks"
      //     : "logs"
      // }
      value={value}
      onValueChange={(v) => {
        setValue(v);
      }}
      className="flex h-full flex-col overflow-auto"
    >
      <TabsList className="mb-0 shrink-0 border-gray-200">
        {!!task?.subTaskCount && (
          <TabsTrigger value="subtasks" className="ml-8">
            Subtasks
          </TabsTrigger>
        )}
        {task?.action === "form" && (
          <TabsTrigger value="form" className="ml-8">
            Form
          </TabsTrigger>
        )}
        {task?.action === "fileUpload" && (
          <TabsTrigger value="fileUpload" className="ml-8">
            File Upload
          </TabsTrigger>
        )}
        {task?.action === "meeting" && (
          <TabsTrigger value="meeting" className="ml-8">
            Call Scheduling
          </TabsTrigger>
        )}
        {/* <TabsTrigger value="notes">Notes</TabsTrigger> */}
        <TabsTrigger value="logs" className="ml-8">
          Updates
        </TabsTrigger>
        {type === "internal" && (
          <TabsTrigger value="notes" className="ml-8">
            Notes
          </TabsTrigger>
        )}
      </TabsList>
      <div className={clsx("flex-1 overflow-auto")}>
        {!!task?.subTaskCount && (
          <TabsContent value="subtasks" className={clsx("h-full bg-gray-100")}>
            {!!task._id && (
              <TaskSubtasks task={task} taskId={task._id?.toString()} />
            )}
          </TabsContent>
        )}
        {task?.action === "form" && !!task?.form?._id && (
          <TabsContent
            value="form"
            className={clsx(
              "h-full bg-gray-100",
              !task.dueDate &&
                "pointer-events-none cursor-not-allowed opacity-50"
            )}
          >
            <TaskForm task={task} formId={task.form._id.toString()} />
          </TabsContent>
        )}
        {task?.action === "fileUpload" && (
          <TabsContent
            value="fileUpload"
            className={clsx(
              "h-full bg-gray-100",
              !task.dueDate &&
                "pointer-events-none cursor-not-allowed opacity-50"
            )}
          >
            <TaskFileUpload task={task} />
          </TabsContent>
        )}

        {task?.action === "meeting" && (
          <TabsContent
            value="meeting"
            className={clsx(
              "h-full bg-gray-100",
              !task.dueDate &&
                "pointer-events-none cursor-not-allowed opacity-50"
            )}
          >
            <TaskMeeting task={task} />
          </TabsContent>
        )}

        <TabsContent
          value="logs"
          className="h-full overflow-auto bg-gray-50 px-8 pt-8"
        >
          {!!task?._id && <TaskLog taskId={task._id.toString()} />}
        </TabsContent>
        <TabsContent
          value="notes"
          className="relative h-full overflow-auto bg-gray-100"
        >
          {!!task?._id && (
            <TaskNotes taskId={task?._id?.toString()} task={task} />
          )}
        </TabsContent>
      </div>
    </Tabs>
  );
}

function TaskSubtasks({
  task,
  taskId,
}: {
  task: TaskWithUserType["task"];
  taskId: string;
}) {
  const { data: subtasks } = useGetSubtasksForTask(taskId);
  const navigate = useNavigate();
  const location = useLocation();

  function AddSubtaskButton() {
    return (
      <Button variant={"secondary"}>
        <PlusIcon className="mr-2 h-4 w-4" />
        <span>Add Subtask</span>
      </Button>
    );
  }
  return (
    <>
      {!!subtasks?.length ? (
        <div className="space-y-3 p-6 px-8">
          <div className="divide-y divide-gray-100 overflow-hidden rounded-md border border-gray-300 bg-white p-0">
            {subtasks?.map((subtask) => (
              <div
                className="flex cursor-pointer items-center justify-between bg-white px-2.5 py-2 hover:bg-gray-50"
                onClick={(e) => {
                  if (!subtask._id) return;
                  const searchParams = new URLSearchParams(location.search);
                  searchParams.set("taskId", subtask._id);
                  navigate(
                    {
                      pathname: location.pathname,
                      search: searchParams.toString(),
                    },
                    {
                      replace: true,
                    }
                  );
                }}
              >
                <div className="flex items-center gap-1 truncate">
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <StatusSelect task={subtask} isTag />
                  </div>
                  <div className="min-w-0 flex-1 truncate px-2 text-sm">
                    {subtask.title}
                  </div>
                </div>
                <div
                  className={clsx(
                    "shrink-0 truncate rounded-full border px-2 py-0.5 text-xs",
                    subtask.taskerType === "external"
                      ? "border-gray-400 bg-gray-200 text-black"
                      : "border-gray-400 bg-white text-gray-500",
                    subtask.tasker?.userType === "persona" &&
                      "border-red-500 bg-white text-red-500"
                  )}
                >
                  {subtask.tasker?.userType === "persona"
                    ? "Unassigned"
                    : getIdentifier(subtask.tasker).identifier}
                </div>
              </div>
            ))}
          </div>
          {/* <AddSubtaskButton /> */}
        </div>
      ) : (
        <div className="mt-[20vh] flex justify-center">
          <div className="flex flex-col">
            <div className="flex items-center">
              {/* <Square3Stack3DIcon className="mr-2.5 h-7 w-7 text-gray-400" /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="4 0 24 24"
                className="text-gray-500"
              >
                <path
                  fill="currentColor"
                  d="M17 16a2.99 2.99 0 0 0-2.816 2H11c-1.654 0-3-1.346-3-3v-3.025A4.954 4.954 0 0 0 11 13h3.184A2.99 2.99 0 0 0 17 15a3 3 0 1 0 0-6a2.99 2.99 0 0 0-2.816 2H11c-1.654 0-3-1.346-3-3v-.184A2.99 2.99 0 0 0 10 5a3 3 0 1 0-6 0a2.99 2.99 0 0 0 2 2.816V15c0 2.757 2.243 5 5 5h3.184A2.99 2.99 0 0 0 17 22a3 3 0 1 0 0-6zm0-5a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zM7 4a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zm10 16a1.001 1.001 0 0 1 0-2a1.001 1.001 0 0 1 0 2z"
                />
              </svg>
              <div className="text-xl font-medium text-gray-700">Subtasks</div>
            </div>
            <div className="mt-1 max-w-[600px] font-light text-gray-500">
              Subtasks enable you to breakdown complex tasks into simpler ones.
            </div>
            <div className="mt-3">
              <AddSubtaskButton />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function TaskMeeting({ task }: { task: TaskWithUserType["task"] }) {
  return (
    <div className="p-6">
      <div
        className={clsx(
          "rounded-md border border-embedPrimary bg-white py-6 px-6 text-center"
        )}
      >
        <div className="text-gray-700">
          Click{" "}
          <span
            className="cursor-pointer font-semibold text-embedPrimary underline hover:text-embedPrimary/90"
            onClick={() => {
              window.open(task?.meeting?.link, "_blank");
            }}
          >
            here
          </span>{" "}
          to book a call.
        </div>
      </div>
    </div>
  );
}

function TaskFileUpload({ task }: { task: TaskWithUserType["task"] }) {
  const uploadFileForTaskInSharedSpaceMutation =
    useUploadFileForTaskInSharedSpace();
  const [file, setFile] = useState<File | undefined>(undefined);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(!task?.files?.length);

  useEffect(() => {
    if (uploadFileForTaskInSharedSpaceMutation.isSuccess) {
      setFile(undefined);
      setIsUploading(false);
    }
  }, [uploadFileForTaskInSharedSpaceMutation.isSuccess]);

  return (
    <div className="p-6">
      {!isUploading ? (
        <div className={clsx("rounded-md border border-gray-300 bg-white p-4")}>
          {!!task?.files && task.files.length > 0 && (
            <div className="font-bold">Uploaded files</div>
          )}
          <ol className="list-decimal py-4 pt-2 pl-4 text-sm">
            {!!task?.files &&
              task.files.map((file, idx) => (
                <li>
                  <div className="flex items-center space-x-2">
                    <div className="text-sm text-gray-600">{file.label}</div>
                    <Button
                      variant={"ghost"}
                      size="icon"
                      className="h-6 w-6"
                      onClick={() => {
                        window.open(file.url, "_blank");
                      }}
                    >
                      <ExternalLinkIcon className="h-4 w-4 text-gray-400" />
                    </Button>
                  </div>
                  <div>
                    <div className="text-xs text-gray-400">
                      Uploaded on {dayjs(file.createdAt).format("MMM DD, YYYY")}{" "}
                      at {dayjs(file.createdAt).format("hh:mm A")}
                    </div>
                  </div>
                </li>
              ))}
          </ol>
          <div className="text-xs text-gray-500">
            Click{" "}
            <span
              className="cursor-pointer text-gray-500 underline hover:text-gray-600"
              onClick={() => {
                fileInputRef.current?.click();
              }}
            >
              here
            </span>{" "}
            to replace the previously uploaded file.
          </div>
          {/* <div className="pt-4 text-sm text-gray-500">Click <span className="underline hover:text-gray-700 cursor-pointer transition-colors">here</span> to upload another file</div> */}
        </div>
      ) : (
        <div
          className={clsx(
            !file && "cursor-pointer hover:bg-gray-50",
            "flex h-36 items-center justify-center rounded-md border border-dashed border-gray-400 bg-white"
          )}
          onClick={() => {
            if (!file) {
              fileInputRef.current?.click();
            }
          }}
        >
          <div className="flex flex-col items-center">
            {!file && (
              <div className="text-sm text-gray-500">
                <div>{"Click to select a file (up to 5MB)"}</div>
              </div>
            )}
            {!!file && <div>{file.name}</div>}
            {!!file && (
              <div className="py-1.5">
                <Button
                  disabled={uploadFileForTaskInSharedSpaceMutation.isLoading}
                  onClick={() => {
                    if (
                      file &&
                      task?._id &&
                      task.journey &&
                      task.phase &&
                      task.instanceId
                    ) {
                      uploadFileForTaskInSharedSpaceMutation.mutate({
                        isVisible: task.isVisible ?? false,
                        journeyId: task.journey?._id.toString(),
                        label: file.name,
                        name: file.name,
                        taskId: task._id.toString(),
                        file,
                        phaseId: task.phase?._id.toString(),
                      });
                    }
                  }}
                >
                  {uploadFileForTaskInSharedSpaceMutation.isLoading
                    ? "Uploading.."
                    : "Upload File"}
                </Button>
              </div>
            )}
            {!!file && (
              <div className="text-sm text-gray-500">
                Click{" "}
                <span
                  className="cursor-pointer whitespace-pre font-semibold underline hover:text-gray-700"
                  onClick={() => {
                    fileInputRef.current?.click();
                  }}
                >
                  here
                </span>{" "}
                to select a new file
                {" (up to 5MB)"}
              </div>
            )}
          </div>
        </div>
      )}
      <input
        ref={fileInputRef}
        id="file-upload"
        name="file-upload"
        type="file"
        className="sr-only"
        accept="application/pdf, image/jpg, image/jpeg, image/png, image/svg, image/gif, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .csv, .txt"
        onChange={(e) => {
          // console.log(e.target.files);
          const selectedFile = e.target.files?.[0];
          if (selectedFile) {
            if (!/^[a-zA-Z0-9_ -]+\.[a-zA-Z0-9]+$/.test(selectedFile.name)) {
              alert(
                "The file name must consist solely of letters, numbers, underscores, hyphens, and a single dot. Please ensure it adheres to this format."
              );
            } else if (selectedFile.size > 5 * 1024 * 1024) {
              alert("File size should not exceed 5MB.");
            } else {
              setIsUploading(true);
              setFile(selectedFile);
            }
          }
        }}
      />
    </div>
  );
}

function TaskNotes({
  taskId,
  task,
}: {
  taskId: string;
  task: TaskWithUserType["task"];
}) {
  const { data: notes } = useGetTaskNotes(taskId);
  return (
    <>
      {!!notes && (
        <>
          {notes.length > 0 ? (
            <>
              <NewNote taskId={taskId} task={task} />
              <div className="px-6">
                <div className="flex h-11 items-center justify-between gap-6 rounded-t-md border border-gray-300 bg-gray-200/70 px-8 text-sm">
                  <div className="min-w-[160px] flex-1">Name</div>
                  <div className="flex items-center space-x-4">
                    <div className="w-40">Created By</div>
                    <div className="w-32">Created At</div>
                  </div>
                </div>

                <div>
                  {notes
                    ?.sort(
                      (n1, n2) =>
                        new Date(n2.createdAt).getTime() -
                        new Date(n1.createdAt).getTime()
                    )
                    .map((n) => (
                      <NoteRow key={n._id.toString()} note={n} />
                    ))}
                </div>
              </div>
            </>
          ) : (
            <div className="flex flex-col items-center space-y-3 pt-10">
              <div className="max-w-sm text-center text-sm font-medium text-gray-500">
                Take notes to keep track of important information.
                {task?.sharedSpaceId ? (
                  <> They will be hidden from external members.</>
                ) : null}
              </div>
              <NewNote taskId={taskId} task={task} />
            </div>
          )}
        </>
      )}
    </>
  );
}

function NoteRow({ note }: { note: NoteData }) {
  const updateNoteMutation = useUpdateNote();
  // const editor = useEditor({
  //   extensions: [
  //     StarterKit,
  //     Typography,
  //     Placeholder.configure({
  //       placeholder: "Enter your note..",
  //       showOnlyWhenEditable: false,
  //     }),
  //     Link.configure({
  //       openOnClick: false,
  //       validate: (href) => /^https?:\/\//.test(href),
  //     }),
  //   ],
  //   editorProps: {
  //     attributes: {
  //       class:
  //         "prose outline-none ring-0 focus:outline-none focus:ring-0 border-none focus:border-none",
  //     },
  //   },
  //   content: note.content,
  //   editable: true,
  //   onBlur: ({ editor }) => {
  //     if (note.task?._id) {
  //       updateNoteMutation.mutate({
  //         params: {
  //           _id: note._id.toString(),
  //           content: editor.getHTML(),
  //         },
  //         metadata: {
  //           taskId: note.task?._id,
  //           phaseId: note.phaseId,
  //         },
  //       });
  //     }
  //   },
  // });

  // const previewEditor = useEditor({
  //   extensions: [
  //     StarterKit,
  //     Typography,
  //     Placeholder.configure({
  //       placeholder: "Note is empty",
  //       showOnlyWhenEditable: false,
  //     }),
  //     // Link.configure({
  //     //   openOnClick: false,
  //     //   validate: (href) => /^https?:\/\//.test(href),
  //     // }),
  //   ],
  //   editorProps: {
  //     attributes: {
  //       class:
  //         "prose outline-none ring-0 focus:outline-none focus:ring-0 border-none focus:border-none",
  //     },
  //   },
  //   content: note.content,
  //   editable: false,
  // });

  // useEffect(() => {
  //   if (previewEditor) {
  //     previewEditor.commands.setContent(note.content);
  //   }
  // }, [note.content]);

  const [isEditing, setIsEditing] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [name, setName] = useState(note.name);
  const editorDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "0px"; // Reset height to shrink if needed
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + "px";
    }
  }, [name]);

  useEffect(() => {
    setTimeout(() => {
      if (textAreaRef.current) {
        textAreaRef.current.style.height = "0px"; // Reset height to shrink if needed
        const scrollHeight = textAreaRef.current.scrollHeight;
        textAreaRef.current.style.height = scrollHeight + "px";
      }
      editor?.commands.focus("end");
      if (editorDivRef.current) {
        editorDivRef.current.scrollTop = editorDivRef.current.scrollHeight;
      }
    }, 50);
  }, [isEditing]);

  const [isVisible, setIsVisible] = useState(note.isVisible);
  const deleteNoteMutation = useDeleteNote();

  const editorRef = useRef<{ focusEditor: () => void }>(null);

  const handleFocusEditor = () => {
    editorRef.current?.focusEditor();
  };

  const editor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
        autolink: true,
      }),
      Typography,
      Placeholder.configure({
        placeholder: "Enter your note..",
        showOnlyWhenEditable: false,
      }),
    ],
    content: note.content,
    onBlur: ({ editor }) => {
      if (note.content.trim() !== editor.getHTML().trim()) {
        if (note.task?._id) {
          updateNoteMutation.mutate({
            params: {
              _id: note._id.toString(),
              content: editor.getHTML(),
            },
            metadata: {
              taskId: note.task?._id,
              phaseId: note.phaseId,
            },
          });
        }
      }
    },
  });

  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <>
      <div
        className="flex h-12 cursor-pointer items-center justify-between gap-6 border-x border-b border-gray-300 bg-white px-8 last:rounded-b-md hover:bg-gray-50"
        onClick={() => {
          setIsEditing(true);
        }}
      >
        <div className="min-w-[160px] flex-1 truncate text-sm font-medium text-gray-600">
          {note.name}
        </div>

        <div className="flex shrink-0 items-center space-x-4">
          <div className="w-40 truncate text-sm text-gray-400">
            {note.createdBy?.name}
          </div>
          <div className="w-32 text-sm text-gray-400">
            {dayjs(note.createdAt).format("MMM DD, h:mm A")}
          </div>
          {/* <div className="w-20">{}</div> */}
        </div>
      </div>
      {/* <div
          className="relative h-32 cursor-pointer overflow-clip rounded-md border bg-white shadow hover:bg-gray-50"
          onClick={() => {
            setIsEditing(true);
          }}
          ref={noteCardRef}
        >
          <div className="absolute z-10 h-full w-full bg-gradient-to-b from-transparent via-transparent to-white" />
          <div className="p-4">
            <div className="flex items-start justify-between gap-4">
              <div className="truncate">
                <span className="truncate font-semibold">{note.name}</span>
                <span className="font-light text-gray-600">{" by "}</span>
                <span className="font-light text-gray-600">
                  {note.createdBy.name}
                </span>
              </div>
              <div className="flex shrink-0 items-center space-x-2 text-xs text-gray-500">
                <div>
                  {dayjs(note.createdAt).format("MMM DD, YYYY")} at{" "}
                  {dayjs(note.createdAt).format("hh:mm A")}
                </div>
              </div>
            </div>
            <div className="pointer-events-none pt-2">
              <EditorContent editor={previewEditor} />
            </div>
          </div>
        </div> */}
      <Dialog
        open={isEditing}
        onOpenChange={(open) => {
          setIsEditing(open);
        }}
      >
        <DialogContent className="w-full max-w-2xl">
          {/* <DialogHeader>
              <DialogTitle>New Note</DialogTitle>
            </DialogHeader> */}
          <div className="pb-2">
            <div className="space-y-4">
              <div className="space-y-1">
                <textarea
                  ref={textAreaRef}
                  className="w-full resize-none border-none p-0 pr-5 text-3xl font-medium outline-none placeholder:text-gray-300 focus:border-none focus:outline-none focus:ring-0"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  onBlur={() => {
                    if (name.trim() !== note.name.trim()) {
                      updateNoteMutation.mutate({
                        params: {
                          _id: note._id.toString(),
                          name,
                        },
                        metadata: {
                          taskId: note.task?._id,
                          phaseId: note.phaseId,
                        },
                      });
                    }
                  }}
                  placeholder="Title..."
                />
                {/* <InputHeading heading="Name" />
                  <TextInput
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    className="border-transparent shadow-none hover:border-gray-100 hover:shadow-md focus:border"
                    onBlur={() => {
                      if (name.trim() !== note.name.trim()) {
                        updateNoteMutation.mutate({
                          params: {
                            _id: note._id.toString(),
                            name,
                          },
                          metadata: {
                            taskId: note.task?._id,
                            phaseId: note.phaseId,
                          },
                        });
                      }
                    }}
                  /> */}
              </div>
              <div
                className="max-h-[400px] min-h-[300px] cursor-text overflow-auto rounded-md border border-gray-300 p-3 shadow-sm"
                onClick={() => {
                  editor?.commands.focus();
                }}
                ref={editorDivRef}
              >
                <TiptapEditor editor={editor} />
              </div>
              <div>
                <Button
                  variant={"secondary"}
                  size={"icon"}
                  onClick={() => {
                    setIsDeleting(true);
                  }}
                >
                  <TrashIcon className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isDeleting}
        onOpenChange={(open) => {
          setIsDeleting(open);
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Confirmation</DialogTitle>
          </DialogHeader>
          <div className="space-y-5 pt-3 text-sm text-gray-600">
            <div>
              Are you sure you want to delete the note{" "}
              <span className="font-medium text-gray-900">{note.name}</span>?
            </div>
          </div>
          <DialogFooter>
            <div className="flex justify-end space-x-3">
              <Button
                variant={"secondary"}
                onClick={() => {
                  setIsDeleting(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant={"destructive"}
                onClick={() => {
                  if (note.task?._id) {
                    deleteNoteMutation.mutate({
                      note: {
                        _id: note._id.toString(),
                      },
                      metadata: {
                        taskId: note.task?._id,
                        phaseId: note.phaseId,
                      },
                    });
                  }
                }}
              >
                {deleteNoteMutation.isLoading ? "Deleting..." : "Delete"}
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );

  // return (
  //   <>
  //     <div
  //       className="max-h-[200px] cursor-pointer overflow-clip rounded-md border bg-white p-4 hover:bg-gray-50"
  //       onClick={() => {
  //         setIsEditing(true);
  //       }}
  //     >
  //       <div className="flex items-start justify-between gap-4">
  //         <div className="flex items-center space-x-2 truncate">
  //           {/* <div className="h-1.5 w-1.5 shrink-0 rounded-full bg-gray-300" /> */}
  //           {/* {!note.isVisible && (
  //             <div className="inline-flex rounded bg-primary-100 p-1">
  //               <EyeSlashIcon className="inline-block h-4 w-4 text-primary" />
  //             </div>
  //           )} */}
  //           <div className="truncate">
  //             <span className="truncate font-medium">{note.name}</span>
  //             <span className="font-light text-gray-600">{" by "}</span>
  //             <span className="font-light text-gray-600">
  //               {note.createdBy.name}
  //             </span>
  //           </div>
  //         </div>
  //         <div className="flex shrink-0 items-center space-x-2 text-xs text-gray-500">
  //           <div>
  //             {dayjs(note.createdAt).format("MMM DD, YYYY")} at{" "}
  //             {dayjs(note.createdAt).format("hh:mm A")}
  //           </div>
  //         </div>
  //       </div>
  //       <div className="pointer-events-none pl-3.5 pt-2">
  //         <EditorContent editor={previewEditor} />
  //       </div>
  //     </div>
  //     <Dialog
  //       open={isEditing}
  //       onOpenChange={(open) => {
  //         setIsEditing(open);
  //       }}
  //     >
  //       <DialogContent>
  //         {/* <DialogHeader>
  //           <DialogTitle>New Note</DialogTitle>
  //         </DialogHeader> */}
  //         <div className="py-4">
  //           <div className="space-y-4">
  //             <div className="space-y-1">
  //               <InputHeading heading="Name" />
  //               <TextInput
  //                 value={name}
  //                 onChange={(e) => {
  //                   setName(e.target.value);
  //                 }}
  //                 onBlur={() => {
  //                   // to do
  //                   if (note.task?._id && name.trim() !== note.name.trim()) {
  //                     updateNoteMutation.mutate({
  //                       params: {
  //                         _id: note._id.toString(),
  //                         name,
  //                       },
  //                       metadata: {
  //                         taskId: note.task._id,
  //                         phaseId: note.phaseId,
  //                       },
  //                     });
  //                   }
  //                 }}
  //               />
  //             </div>
  //             <div
  //               className="max-h-[400px] min-h-[300px] cursor-text overflow-auto rounded-md border border-gray-300 p-3 shadow-sm"
  //               onClick={handleFocusEditor}
  //             >
  //               <TiptapEditor
  //                 content={note.content}
  //                 setContent={(content) => {
  //                   if (note.task?._id) {
  //                     updateNoteMutation.mutate({
  //                       params: {
  //                         _id: note._id.toString(),
  //                         content: content,
  //                       },
  //                       metadata: {
  //                         taskId: note.task?._id,
  //                         phaseId: note.phaseId,
  //                       },
  //                     });
  //                   }
  //                 }}
  //                 placeholder="Enter your note.."
  //               />
  //             </div>
  //             <div>
  //               <Button
  //                 variant={"ghost"}
  //                 size={"icon"}
  //                 onClick={() => {
  //                   deleteNoteMutation.mutate({
  //                     note: {
  //                       _id: note._id.toString(),
  //                     },
  //                     metadata: {
  //                       taskId: note.task?._id,
  //                       phaseId: note.phaseId,
  //                     },
  //                   });
  //                 }}
  //               >
  //                 <TrashIcon className="h-4 w-4" />
  //               </Button>
  //             </div>
  //           </div>
  //         </div>

  //       </DialogContent>
  //     </Dialog>
  //   </>
  // );
}
function NewNote({
  taskId,
  task,
}: {
  taskId: string;
  task: TaskWithUserType["task"];
}) {
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [name, setName] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  const createTaskNoteMutation = useCreateTaskNote();
  // const editor = useEditor({
  //   extensions: [
  //     StarterKit,
  //     Typography,
  //     Placeholder.configure({
  //       placeholder: "Enter your note..",
  //       showOnlyWhenEditable: false,
  //     }),
  //     Link.configure({
  //       openOnClick: false,
  //       validate: (href) => /^https?:\/\//.test(href),
  //     }),
  //   ],
  //   editorProps: {
  //     attributes: {
  //       class:
  //         "prose outline-none ring-0 focus:outline-none focus:ring-0 border-none focus:border-none",
  //     },
  //   },
  //   // onBlur: ({ editor }) => {
  //   //   setContent(editor.getHTML());
  //   // },
  //   content: "",
  //   editable: true,
  // });

  useEffect(() => {
    if (createTaskNoteMutation.isSuccess) {
      setContent("");
      setName("");
      setIsVisible(true);
      setIsAddingNote(false);
    }
  }, [createTaskNoteMutation.isSuccess]);

  const [content, setContent] = useState("");

  const editor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
        autolink: true,
      }),
      Typography,
      Placeholder.configure({
        placeholder: "Enter your note..",
        showOnlyWhenEditable: false,
      }),
    ],
    content: content,
    onBlur: ({ editor }) => {
      setContent(editor.getHTML());
    },
  });

  return (
    <>
      <div className="flex h-16 items-center justify-end px-6">
        <Button
          className="space-x-2"
          size="sm"
          onClick={() => {
            setIsAddingNote(true);
          }}
        >
          {/* <PlusIcon className="h-4 w-4" /> */}
          <span>Create Note</span>
        </Button>
      </div>
      <Dialog
        open={isAddingNote}
        onOpenChange={(open) => {
          setIsAddingNote(open);
        }}
      >
        <DialogContent className="w-full max-w-2xl">
          <DialogHeader>
            <DialogTitle>New Note</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <div className="space-y-4">
              <div className="space-y-1">
                <InputHeading heading="Name" />
                <TextInput
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div
                className="max-h-[60vh] min-h-[400px] cursor-text overflow-auto rounded-md border border-gray-300 p-3 shadow-sm"
                onClick={() => {
                  editor?.commands.focus();
                }}
              >
                <TiptapEditor editor={editor} />
              </div>
            </div>
          </div>
          <div className="flex w-full justify-end">
            {/* {!!task?.sharedSpaceId && (
                <div className="inline-flex h-8 items-center gap-1 rounded-md border border-gray-300 px-1 shadow-sm">
                  <TooltipProvider>
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger asChild>
                        <button
                          className={clsx(
                            "rounded p-1 outline-none focus-visible:ring-[1.25px] focus-visible:ring-primary",
                            isVisible
                              ? "bg-primary-100 text-primary-600"
                              : "text-gray-500 hover:bg-gray-100"
                          )}
                          onClick={() => {
                            setIsVisible(true);
                          }}
                        >
                          <EyeIcon className="h-4 w-4" />
                        </button>
                      </TooltipTrigger>
                      <TooltipContent sideOffset={8}>
                        <p>Externally visible</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  <TooltipProvider>
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger asChild>
                        <button
                          className={clsx(
                            "rounded p-1 outline-none focus-visible:ring-[1.25px] focus-visible:ring-primary",
                            !isVisible
                              ? "bg-primary-100 text-primary-600"
                              : "text-gray-500 hover:bg-gray-100"
                            // taskTemplateCtx.state.tasker?.type == "external" &&
                            //   "bg-gray-100 text-gray-300 hover:bg-gray-100"
                          )}
                          onClick={() => {
                            setIsVisible(false);
                          }}
                          // disabled={
                          //   taskTemplateCtx.state.tasker?.type == "external"
                          // }
                        >
                          <EyeSlashIcon
                            className={
                              `h-4 w-4`
                              // ${
                              //   taskTemplateCtx.state.tasker?.type === "external"
                              //     ? "text-gray-300"
                              //     : ""
                              // }
                              // `
                            }
                          />
                        </button>
                      </TooltipTrigger>
                      <TooltipContent sideOffset={8}>
                        <p>Externally hidden</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              )} */}
            <div className="flex justify-end space-x-2.5">
              {/* <Button
                  variant={"secondary"}
                  onClick={() => {
                    setIsAddingNote(false);
                  }}
                >
                  Cancel
                </Button> */}
              <Button
                disabled={
                  createTaskNoteMutation.isLoading || name.trim() === ""
                }
                onClick={() => {
                  createTaskNoteMutation.mutate({
                    params: {
                      taskId,
                      name: name,
                      content: content,
                      description: "",
                      isVisible: false,
                    },
                    metadata: {
                      phaseId: task?.phase?._id,
                    },
                  });
                }}
              >
                {createTaskNoteMutation.isLoading ? "Creating.." : "Create"}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

function Chat({ task }: { task?: TaskData }) {
  const createThreadMutation = useCreateThreadInTask();
  const createThreadsInPhaseTaskMutation = useCreateThreadInPhaseTask();
  const app = useRealmApp();
  // const createThreadMessageMutation = useCreateThreadMessage();
  const [message, setMessage] = useState("");

  const sendMessage = () => {
    if (message.trim() !== "") {
      if (task?._id && task.sharedSpaceId) {
        if (task.phase) {
          createThreadsInPhaseTaskMutation.mutate({
            params: {
              name: task.title,
              phaseId: new ObjectID(task.phase?._id),
              associations: [{ _id: new ObjectID(task._id), type: "task" }],
              firstMessage: { text: message },
            },
          });
        } else {
          createThreadMutation.mutate({
            params: {
              name: task.title,
              associations: [{ _id: new ObjectID(task._id), type: "task" }],
              instanceId: new ObjectID(
                app.currentUser?.customData?.instanceId?.$oid
              ),
              firstMessage: { text: message },
            },
          });
        }
      }
    }
    setMessage("");
  };
  if (!task) {
    return null;
  } else if (!task?.threadId) {
    return (
      <div className="flex h-full flex-col overflow-auto">
        <div className="flex flex-1 flex-col overflow-auto bg-white">
          <div className="border-b border-gray-200 p-4 py-3 font-medium">
            <span>Chat</span>
          </div>
          {createThreadMutation.isLoading ||
          createThreadsInPhaseTaskMutation.isLoading ? (
            <div className="flex h-full items-center justify-between">
              <Loading />
            </div>
          ) : (
            <>
              <div className="flex flex-1 items-center justify-center overflow-auto p-4">
                {!createThreadMutation.isSuccess &&
                  !createThreadsInPhaseTaskMutation.isSuccess && (
                    <span className="text-gray-600">
                      Send the first message in this chat!
                    </span>
                  )}
              </div>
            </>
          )}
        </div>
        <div className="space-y-1 p-4 pb-1">
          <textarea
            id="message"
            name="message"
            rows={3}
            className={clsx(
              "block w-full rounded-md border-gray-300 shadow-sm placeholder:text-gray-400  sm:text-sm",
              // isEmbed
              //   ? "focus:border-embedPrimary focus:ring-embedPrimary"
              //   :
              "focus:border-primary-main focus:ring-primary-main"
            )}
            onKeyDown={(event) => {
              if (
                event.key === "Enter" &&
                !event.shiftKey &&
                message.trim() !== ""
              ) {
                event.preventDefault();
                sendMessage();
              }
            }}
            placeholder="Message.."
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            autoFocus
            disabled={
              createThreadMutation.isLoading ||
              createThreadsInPhaseTaskMutation.isLoading
            }
          />
          <div
            className={clsx(
              "text-xs",
              message.trim() === "" ? "text-white" : "text-gray-500"
            )}
          >
            {createThreadMutation.isLoading ||
            createThreadsInPhaseTaskMutation.isLoading
              ? "Sending.."
              : "Press Enter to send"}
          </div>
        </div>
      </div>
    );
  } else {
    return <ActiveChat threadId={task.threadId} />;
  }
}

function ActiveChat({ threadId }: { threadId: string }) {
  const { threadMessages } = useGetThreadMessages(threadId);
  return (
    <div className="flex h-full flex-col overflow-auto">
      <div className="flex flex-1 flex-col overflow-auto bg-white">
        <div className="border-b border-gray-300 p-4 py-3 font-medium">
          <span>Chat</span>
        </div>
        <div className="flex-1 overflow-auto p-4">
          {!!threadMessages && <ChatMessages messages={threadMessages} />}
        </div>
      </div>
      <SimpleChatMessageInput threadId={threadId} />
      {/* <ChatMessageInput /> */}
    </div>
  );
}

function SimpleChatMessageInput({ threadId }: { threadId: string }) {
  const app = useRealmApp();
  const createThreadMessageMutation = useCreateThreadMessage();
  const [message, setMessage] = useState("");
  const { thread } = useGetThread(new ObjectID(threadId));
  const sendMessage = () => {
    const user = thread?.otherDetails.users?.find(
      (user) => user._id.toString() === app.currentUser?.id
    );

    if (message.trim() !== "") {
      createThreadMessageMutation.mutate({
        params: {
          threadId: new ObjectID(threadId),
          text: message,
        },
        metadata: {
          type: user?.type || "internal",
        },
      });
    }
    // if (message.trim() !== "") {
    //   createThreadMessageMutation.mutate({
    //     params: {
    //       threadId: new ObjectID(threadId),
    //       text: message,
    //     },
    //   });
    // }
    setMessage("");
  };

  return (
    <div className="space-y-1 p-4 pb-1">
      <textarea
        id="message"
        name="message"
        rows={3}
        className={clsx(
          "block w-full rounded-md border-gray-300 shadow-sm placeholder:text-gray-400  sm:text-sm",
          // isEmbed
          //   ? "focus:border-embedPrimary focus:ring-embedPrimary"
          //   :
          "focus:border-primary-main focus:ring-primary-main"
        )}
        onKeyDown={(event) => {
          if (event.key === "Enter" && message.trim() !== "") {
            event.preventDefault();
            sendMessage();
          }
        }}
        placeholder="Message.."
        value={message}
        onChange={(e) => {
          setMessage(e.target.value);
        }}
        autoFocus
      />
      <div
        className={clsx(
          "text-xs",
          message.trim() === "" ? "text-white" : "text-gray-500"
        )}
      >
        Press Enter to send
      </div>
    </div>
  );
}

// function Message({
//   message,
//   previousMessage,
// }: {
//   message: ThreadMessageData;
//   previousMessage?: ThreadMessageData;
// }) {
//   const editor = useEditor({
//     extensions: [
//       StarterKit,
//       Typography,
//       Link.configure({
//         openOnClick: true,
//       }),
//     ],
//     editorProps: {
//       attributes: {
//         class:
//           "prose outline-none ring-0 focus:outline-none focus:ring-0 border-none focus:border-none",
//       },
//     },
//     autofocus: true,
//     content: message.text,
//     editable: false,
//   });
//   const { absDifferenceInMs } = getTimeDifference(
//     message.createdAt,
//     previousMessage?.createdAt ?? new Date()
//   );

//   return (
//     <>
//       {(!!previousMessage?.createdAt && absDifferenceInMs > 1000 * 60 * 5) ||
//       !previousMessage ||
//       previousMessage.user._id.toString() !== message.user._id.toString() ? (
//         <div className={clsx("flex space-x-3", !!previousMessage && "mt-1.5")}>
//           <div className="shrink-0">
//             <div
//               className={clsx(
//                 "flex h-8 w-8 items-center justify-center rounded-md shadow-sm"
//                 // message.user.type === "external" ? "bg-gray-200" : "bg-white"
//               )}
//             >
//               <div className="text-gray-500">
//                 {message.user.name
//                   .toUpperCase()
//                   .split(" ")
//                   .map((w) => w[0])
//                   .join("")}
//               </div>
//             </div>
//           </div>
//           <div>
//             <div className="flex items-center space-x-2">
//               <div className="text-sm font-medium leading-4 text-gray-400">
//                 {message.user.name}
//               </div>
//               <div className="pt-[1px]">
//                 <div className="text-xs leading-4 text-gray-400">
//                   {dayjs(message.createdAt).format("h:mm A, MMM DD")}
//                 </div>
//               </div>
//             </div>
//             <div className="">
//               <TiptapEditor editor={editor} showMenu={false} />
//             </div>
//           </div>
//         </div>
//       ) : (
//         <div className="group flex items-center space-x-3">
//           <div className="w-8 shrink-0">
//             <span
//               className={clsx(
//                 "text-xs text-white",
//                 "group-hover:text-gray-400"
//               )}
//             >
//               {dayjs(message.createdAt).format("h:mm")}
//             </span>
//           </div>
//           <div className="">
//             <TiptapEditor editor={editor} showMenu={false} />
//           </div>
//         </div>
//       )}
//     </>
//   );
// }

const EnterKeyExtension = Extension.create({
  name: "enterKey",
  addKeyboardShortcuts() {
    return {
      Enter: () => this.options.onEnter(),
    };
  },
});

function ChatMessageInput() {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Typography,
      Placeholder.configure({
        placeholder: "Type a message...",
        showOnlyCurrent: true,
      }),
      Link.configure({
        openOnClick: false,
      }),
    ],
    editorProps: {
      attributes: {
        class:
          "prose outline-none ring-0 focus:outline-none focus:ring-0 border-none focus:border-none",
      },
    },
    autofocus: true,
    content: "",
  });
  const queryClient = useQueryClient();
  const app = useRealmApp();
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" && editor) {
        event.preventDefault();
        // queryClient.setQueryData(
        //   ["threads", "task", String(taskPanelCtx.state.taskPanel.id)],
        //   (oldData: ThreadMessageData[] | undefined) => {
        //     if (oldData) {
        //       return [
        //         ...oldData,
        //         {
        //           _id: new ObjectID(taskPanelCtx.state.taskPanel.id),
        //           text: editor.getHTML(),
        //           createdAt: new Date(),
        //           createdBy: {
        //             _id: "user",
        //             name: "User",
        //             type: "user",
        //           },
        //           user: {
        //             _id: new ObjectID(app.currentUser.customData._id),
        //             name: app.currentUser.customData.name,
        //             email: "",
        //           },
        //         },
        //       ];
        //     }
        //     return [];
        //   }
        // );
        editor.commands.clearContent(true); // Clear the editor content
      }
    };

    if (editor) {
      editor.view.dom.addEventListener("keydown", handleKeyDown);
    }

    // Cleanup the event listener
    return () => {
      if (editor) {
        editor.view.dom.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [editor]); // Dependency array ensures this effect runs when the editor instance changes

  const createThreadMutation = useCreateThread();

  function sendMessage() {
    // if thread exists
    // if thread doesn't exist
    // createThreadMutation.mutate({
    //   params: {
    //     taskId: taskPanelCtx.state.taskPanel.id,
    //     message: editor?.getHTML(),
    //   },
    // });
  }

  return (
    <div className="shrink-0 bg-white p-4">
      <div
        className="cursor-text rounded-md border-[1.5px] p-3 pt-2 focus-within:border-primary"
        onClick={() => {
          editor?.commands.focus();
        }}
      >
        <EditorContent editor={editor} className="min-h-[60px]" />
        <div className="flex justify-end">
          <Button
            disabled={editor?.isEmpty}
            onClick={() => {
              sendMessage();
            }}
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  );
}

function Notes({ task }: { task: TaskWithUserType["task"] }) {
  return <div></div>;
}

function TaskForm({ task, formId }: { task: TaskData; formId: string }) {
  const { data: form } = useGetForm(formId);
  return (
    <>
      {form && (
        <>
          {form.blocks.map((section, idx) => (
            <FormSection sectionIndex={idx} section={section} form={form} />
          ))}
        </>
      )}
    </>
  );
}

// Define the type for individual refs
type DivRef = React.RefObject<HTMLDivElement>;

// Define the type for the refs object
type DivRefs = {
  [key: string]: DivRef;
};

function FormSection({
  sectionIndex,
  section,
  form,
}: {
  sectionIndex: number;
  section: FormTemplateSectionBlockData;
  form: FormData;
}) {
  const updateFormMutation = useUpdateForm();
  const [isInvalid, setIsInvalid] = useState<{ [key: string]: boolean }>({});
  const [numberOfInvalids, setNumberOfInvalids] = useState(0);
  const divRefs = useRef<DivRefs>({});
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    // Temporary object to hold the refs
    let newRefs: DivRefs = {};

    // Populate newRefs with refs for each id
    section.blocks
      .map((b) => b.id)
      .forEach((id) => {
        newRefs[id] = React.createRef();
      });

    // Update divRefs.current
    divRefs.current = newRefs;
    setIsReady(true);
  }, []);

  function submitForm() {
    setIsResubmitting(false);
    let isInvalid: { [key: string]: boolean } = {};
    section.blocks.forEach((block) => {
      switch (block.type) {
        case "short text":
        case "long text":
        case "date":
        case "email":
          if (block.properties.required && !block.properties.value) {
            isInvalid[block.id] = true;
          }
      }
    });
    setIsInvalid(isInvalid);
    if (Object.keys(isInvalid).length === 0) {
      setNumberOfInvalids(0);
      // submit form
      updateFormMutation.mutate({
        update: {
          _id: form._id,
          isSubmitted: true,
        },
        metadata: {
          taskId: form.taskId,
          journeyId: form.journeyId,
          journeyStageId: form.journeyStageId,
          phaseId: form.phaseId,
          sharedSpaceId: form.sharedSpaceId,
        },
      });
    } else {
      setNumberOfInvalids(Object.keys(isInvalid).length);
      // console.log(Object.keys(isInvalid)[0]);
      // console.log(divRefs.current);
      const ref = divRefs.current[Object.keys(isInvalid)[0]];
      if (ref && ref.current) {
        // console.log(ref.current);
        ref.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }

  function getBlock(block: FormTemplateElementBlockData) {
    switch (block.type) {
      case "short text":
        return (
          <ShortTextElement
            sectionIndex={sectionIndex}
            form={form}
            block={block}
            isInvalid={isInvalid[block.id]}
          />
        );
      case "email":
        return (
          <EmailElement
            sectionIndex={sectionIndex}
            form={form}
            block={block}
            isInvalid={isInvalid[block.id]}
          />
        );
      case "heading":
        return <HeadingElement block={block} />;
      case "date":
        return (
          <DateElement
            sectionIndex={sectionIndex}
            form={form}
            block={block}
            isInvalid={isInvalid[block.id]}
          />
        );
      case "richText":
        return <RichTextElement block={block} />;
      case "long text":
        return (
          <LongTextElement
            sectionIndex={sectionIndex}
            form={form}
            block={block}
            isInvalid={isInvalid[block.id]}
          />
        );
      case "boolean":
        return (
          <BooleanElement
            sectionIndex={sectionIndex}
            form={form}
            block={block}
          />
        );
      case "table":
        return (
          <TableElement sectionIndex={sectionIndex} form={form} block={block} />
        );
      default:
        return <div className="text-red-500">{"Error"}</div>;
    }
  }

  const [isSubmitted, setIsSubmitted] = useState(form.isSubmitted);
  const [isResubmitting, setIsResubmitting] = useState(false);
  useEffect(() => {
    setIsSubmitted(form.isSubmitted);
  }, [form.isSubmitted]);

  return (
    <>
      <div className="flex-1 space-y-10 overflow-auto bg-gray-100 px-10 py-10">
        {section.blocks.map((block) => {
          return (
            <div
              ref={divRefs.current[block.id]}
              key={block.id}
              className={clsx(
                !isResubmitting &&
                  form.isSubmitted &&
                  "pointer-events-none opacity-50"
              )}
            >
              {getBlock(block)}
            </div>
          );
        })}
      </div>
      <div className="flex shrink-0 items-center justify-between border-t bg-gray-50 px-6 py-4">
        <div className="flex items-center space-x-3">
          {isSubmitted && (
            <Button
              disabled={isResubmitting || updateFormMutation.isLoading}
              variant={"secondary"}
              onClick={() => {
                setIsResubmitting(true);
              }}
            >
              Edit Submission
            </Button>
          )}
          <Button
            disabled={
              (form.isSubmitted || updateFormMutation.isLoading) &&
              !isResubmitting
            }
            onClick={submitForm}
          >
            {updateFormMutation.isLoading ? "Submitting..." : "Submit"}
          </Button>
          {numberOfInvalids > 0 && (
            <div className="text-sm text-red-600">{`${numberOfInvalids} issues`}</div>
          )}
        </div>
        {form.isSubmitted && (
          <div className="text-sm text-gray-500">
            {`Last submitted at ${dayjs(form.updatedAt).format(
              "hh:mm A, DD MMM, YYYY"
            )}`}
          </div>
        )}
      </div>
    </>
  );
}

function TableElement({
  form,
  block,
  sectionIndex,
}: {
  form: FormData;
  block: FormTemplateTableBlockData;
  sectionIndex: number;
}) {
  const [rows, setRows] = useState(
    block.properties.rows ?? [Array(block.properties.columns.length).fill("")]
  );
  const updateFormMutation = useUpdateForm();

  useEffect(() => {
    setRows(
      block.properties.rows ?? [Array(block.properties.columns.length).fill("")]
    );
  }, [block.properties.rows]);

  return (
    <div className="">
      <div className="space-y-1">
        <div>
          <div className="text-md">{block.properties.label}</div>
          {block.properties.description.trim() !== "" && (
            <div className="text-sm text-gray-500">
              {block.properties.description}
            </div>
          )}
        </div>
        <div className="overflow-x-auto">
          <div className="overflow-x-auto">
            <div className="flex">
              {block.properties.columns.map((column, idx) => (
                <div
                  key={idx}
                  className="w-48 shrink-0 border-y border-r border-gray-400/50 bg-gray-300/70 p-2 text-sm text-gray-700 first:border-l"
                >
                  {column.name}
                </div>
              ))}
            </div>
            {/* rows that are editable as textareas */}
            <div className="flex flex-col">
              {rows.map((row, rowIdx) => (
                <div key={rowIdx} className="flex items-stretch">
                  {row.map((cell, cellIdx) => (
                    <RowCell
                      key={cellIdx}
                      cell={cell}
                      form={form}
                      block={block}
                      sectionIndex={sectionIndex}
                      rows={rows}
                      setRows={setRows}
                      rowIdx={rowIdx}
                      cellIdx={cellIdx}
                    />
                    // <div
                    //   key={cellIdx}
                    //   className="w-48 border bg-white p-2 text-sm text-gray-700"
                    // >
                    //   <TextInput
                    //     value={cell}
                    //     onChange={(e) => {
                    //       setRows((prevRows) => {
                    //         const newRows = [...prevRows];
                    //         newRows[rowIdx][cellIdx] = e.target.value;
                    //         return newRows;
                    //       });
                    //     }}
                    //   />
                    // </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <button
            className="max-w-full border-x border-b border-gray-300 bg-white p-2 text-sm hover:bg-gray-50"
            onClick={() => {
              setRows((prevRows) => {
                const newRows = [...prevRows];
                newRows.push(Array(block.properties.columns.length).fill(""));
                return newRows;
              });
              updateFormMutation.mutate({
                update: {
                  _id: form._id,
                  blocks: form.blocks.map((section, idx) => {
                    if (idx === sectionIndex) {
                      return {
                        ...section,
                        blocks: section.blocks.map((b) => {
                          if (b.id === block.id && b.type === "table") {
                            return {
                              ...b,
                              properties: {
                                ...b.properties,
                                rows: [
                                  ...(b.properties.rows ?? []),
                                  Array(block.properties.columns.length).fill(
                                    ""
                                  ),
                                ],
                              },
                            };
                          }
                          return b;
                        }),
                      };
                    }
                    return section;
                  }),
                },
              });
            }}
          >
            + Add Row
          </button>
        </div>
      </div>
    </div>
  );
}

function RowCell({
  cell,
  block,
  form,
  sectionIndex,
  rows,
  setRows,
  rowIdx,
  cellIdx,
}: {
  cell: string;
  block: FormTemplateTableBlockData;
  form: FormData;
  sectionIndex: number;
  rows: string[][];
  setRows: React.Dispatch<React.SetStateAction<string[][]>>;
  rowIdx: number;
  cellIdx: number;
}) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [value, setValue] = useState(cell);
  const updateFormMutation = useUpdateForm();
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "0px"; // Reset height to shrink if needed
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + 4 + "px";
    }
  }, [value]);

  useEffect(() => {
    setValue(cell ?? "");
  }, [cell]);

  return (
    <textarea
      ref={textAreaRef}
      // placeholder={block.properties.placeholder}
      // rows={3}
      className="h-full w-48 shrink-0 resize-none border-t-0 border-l-0 border-r border-b border-gray-300 p-2 text-sm shadow-sm outline-none placeholder:text-gray-300 first:border-l focus:border-gray-300 focus:outline-none focus:ring-0"
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onBlur={(e) => {
        // if (block.properties.required && e.target.value.trim() === "") {
        //   setIsError(true);
        // } else {
        //   setIsError(false);
        // }
        // set value via POST request
        if (form.blocks) {
          if (value.trim() !== cell.trim()) {
            updateFormMutation.mutate({
              update: {
                _id: form._id,
                blocks: form.blocks.map((section, idx) => {
                  if (idx === sectionIndex) {
                    return {
                      ...section,
                      blocks: section.blocks.map((b) => {
                        if (b.id === block.id && b.type === "table") {
                          return {
                            ...b,
                            properties: {
                              ...b.properties,
                              rows: rows.map((row, rIdx) => {
                                if (rIdx === rowIdx) {
                                  return row.map((cell, cIdx) => {
                                    if (cIdx === cellIdx) {
                                      return value;
                                    }
                                    return cell;
                                  });
                                }
                                return row;
                              }),
                            },
                          };
                        }
                        return b;
                      }),
                    };
                  }
                  return section;
                }),
              },
            });
          } else {
            // console.log("no change");
          }
        }
      }}
    />
  );
}

function BooleanElement({
  form,
  block,
  sectionIndex,
}: {
  form: FormData;
  block: FormTemplateBooleanBlockData;
  sectionIndex: number;
}) {
  const [value, setValue] = useState(block.properties.value ?? false);
  const updateFormMutation = useUpdateForm();

  useEffect(() => {
    setValue(block.properties.value ?? false);
  }, [block.properties.value]);

  return (
    <div>
      <div className="space-y-1">
        <div>
          <div className="text-md">{block.properties.label}</div>
          {block.properties.description.trim() !== "" && (
            <div className="text-sm text-gray-500">
              {block.properties.description}
            </div>
          )}
        </div>
        <div className="flex items-center space-x-2">
          <div className="text-sm text-gray-700">
            {block.properties.falseLabel}
          </div>
          <Switch
            checked={value}
            onCheckedChange={(checked) => {
              setValue(checked);
              // set value via POST request
              if (form.blocks)
                updateFormMutation.mutate({
                  update: {
                    _id: form._id,
                    blocks: form.blocks.map((section, idx) => {
                      if (idx === sectionIndex) {
                        return {
                          ...section,
                          blocks: section.blocks.map((b) => {
                            if (b.id === block.id && b.type === "boolean") {
                              return {
                                ...b,
                                properties: {
                                  ...b.properties,
                                  value: checked,
                                },
                              };
                            }
                            return b;
                          }),
                        };
                      }
                      return section;
                    }),
                  },
                });
            }}
          />
          <div className="text-sm text-gray-700">
            {block.properties.trueLabel}
          </div>
        </div>
      </div>
    </div>
  );
}

function RichTextElement({ block }: { block: FormTemplateRichTextBlockData }) {
  const editor = useEditor({
    extensions: [StarterKit, Typography],
    editorProps: {
      attributes: {
        class:
          "prose outline-none ring-0 focus:outline-none focus:ring-0 border-none focus:border-none",
      },
    },
    content: block.properties.text,
    editable: false,
  });
  return <EditorContent editor={editor} className="" />;
}

function DateElement({
  form,
  block,
  isInvalid,
  sectionIndex,
}: {
  form: FormData;
  block: FormTemplateDateBlockData;
  isInvalid?: boolean;
  sectionIndex: number;
}) {
  const [isError, setIsError] = useState(false);
  const [value, setValue] = useState(block.properties.value ?? "");
  const updateFormMutation = useUpdateForm();

  useEffect(() => {
    setIsError(isInvalid === true);
  }, [isInvalid]);

  useEffect(() => {
    setValue(block.properties.value ?? "");
  }, [block.properties.value]);

  return (
    <div>
      <div className="space-y-1">
        <div>
          <div className="relative w-fit">
            <div className="text-md">{block.properties.label}</div>
            {block.properties.required && (
              <div className="absolute top-0 left-full -mt-0.5 ml-0.5 h-1.5 w-1.5 rounded-full text-red-500">
                *
              </div>
            )}
          </div>
          {block.properties.description.trim() !== "" && (
            <div className="text-sm text-gray-500">
              {block.properties.description}
            </div>
          )}
        </div>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant={"secondary"}
              className={cn(
                "h-[38px] w-72 justify-between text-left font-normal",
                !value && "text-muted-foreground"
              )}
            >
              <div className="inline-flex items-center">
                <CalendarIcon className="mr-2 h-4 w-4" />
                {value ? (
                  dayjs(value).format("DD MMM, YYYY")
                ) : (
                  <span>Pick a date</span>
                )}
              </div>
              <CaretSortIcon className="ml-2 h-4 w-4 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              mode="single"
              selected={value ? new Date(value) : undefined}
              onSelect={(date) => {
                if (date) {
                  setValue(date.toISOString());
                  setIsError(false);
                  // set value via POST request
                  if (form.blocks)
                    updateFormMutation.mutate({
                      update: {
                        _id: form._id,
                        blocks: form.blocks.map((section, idx) => {
                          if (idx === sectionIndex) {
                            return {
                              ...section,
                              blocks: section.blocks.map((b) => {
                                if (b.id === block.id && b.type === "date") {
                                  return {
                                    ...b,
                                    properties: {
                                      ...b.properties,
                                      value: date.toISOString(),
                                    },
                                  };
                                }
                                return b;
                              }),
                            };
                          }
                          return section;
                        }),
                      },
                    });
                }
              }}
              initialFocus
            />
          </PopoverContent>
        </Popover>
        {isError && (
          <div className="text-xs text-red-500">This field is required</div>
        )}
      </div>
    </div>
  );
}

function HeadingElement({ block }: { block: FormTemplateHeadingBlockData }) {
  return (
    <div>
      <div
        className={clsx(
          "truncate font-bold",
          block.properties.size === "small" && "text-xl",
          block.properties.size === "medium" && "text-2xl",
          block.properties.size === "large" && "text-3xl"
        )}
      >
        {block.properties.text}
      </div>
      {block.properties.description.trim() !== "" && (
        <div>{block.properties.description}</div>
      )}
    </div>
  );
}

function EmailElement({
  form,
  block,
  isInvalid,
  sectionIndex,
}: {
  form: FormData;
  block: FormTemplateEmailBlockData;
  isInvalid?: boolean;
  sectionIndex: number;
}) {
  const [isError, setIsError] = useState(false);
  const [value, setValue] = useState(block.properties.value ?? "");
  const updateFormMutation = useUpdateForm();

  useEffect(() => {
    setIsError(isInvalid === true);
  }, [isInvalid]);

  useEffect(() => {
    setValue(block.properties.value ?? "");
  }, [block.properties.value]);

  return (
    <div className="space-y-1">
      <div>
        <div className="relative w-fit">
          <div className="text-md">{block.properties.label}</div>
          {block.properties.required && (
            <div className="absolute top-0 left-full -mt-0.5 ml-0.5 h-1.5 w-1.5 rounded-full text-red-500">
              *
            </div>
          )}
        </div>
        {block.properties.description.trim() !== "" && (
          <div className="text-sm text-gray-500">
            {block.properties.description}
          </div>
        )}
      </div>
      <TextInput
        type="email"
        className="w-72"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        placeholder={block.properties.placeholder}
        onBlur={(e) => {
          if (block.properties.required && e.target.value.trim() === "") {
            setIsError(true);
          } else {
            setIsError(false);
          }
          // set value via POST request
          if (form.blocks)
            updateFormMutation.mutate({
              update: {
                _id: form._id,
                blocks: form.blocks.map((section, idx) => {
                  if (idx === sectionIndex) {
                    return {
                      ...section,
                      blocks: section.blocks.map((b) => {
                        if (b.id === block.id && b.type === "email") {
                          return {
                            ...b,
                            properties: {
                              ...b.properties,
                              value: e.target.value,
                            },
                          };
                        }
                        return b;
                      }),
                    };
                  }
                  return section;
                }),
              },
            });
        }}
      />
      {isError && (
        <div className="text-xs text-red-500">This field is required</div>
      )}
    </div>
  );
}

function ShortTextElement({
  form,
  block,
  isInvalid,
  sectionIndex,
}: {
  form: FormData;
  block: FormTemplateShortTextBlockData;
  isInvalid?: boolean;
  sectionIndex: number;
}) {
  const [isError, setIsError] = useState(false);
  const [value, setValue] = useState(block.properties.value ?? "");
  const updateFormMutation = useUpdateForm();

  useEffect(() => {
    setIsError(isInvalid === true);
  }, [isInvalid]);

  useEffect(() => {
    setValue(block.properties.value ?? "");
  }, [block.properties.value]);

  return (
    <div className="space-y-1">
      <div>
        <div className="relative w-fit">
          <div className="text-md">{block.properties.label}</div>
          {block.properties.required && (
            <div className="absolute top-0 left-full -mt-0.5 ml-0.5 h-1.5 w-1.5 rounded-full text-red-500">
              *
            </div>
          )}
        </div>
        {block.properties.description.trim() !== "" && (
          <div className="text-sm text-gray-500">
            {block.properties.description}
          </div>
        )}
      </div>
      <TextInput
        className="w-72"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        placeholder={block.properties.placeholder}
        onBlur={(e) => {
          if (block.properties.required && e.target.value.trim() === "") {
            setIsError(true);
          } else {
            setIsError(false);
          }
          // set value via POST request
          if (form.blocks)
            updateFormMutation.mutate({
              update: {
                _id: form._id,
                blocks: form.blocks.map((section, idx) => {
                  if (idx === sectionIndex) {
                    return {
                      ...section,
                      blocks: section.blocks.map((b) => {
                        if (b.id === block.id && b.type === "short text") {
                          return {
                            ...b,
                            properties: {
                              ...b.properties,
                              value: e.target.value,
                            },
                          };
                        }
                        return b;
                      }),
                    };
                  }
                  return section;
                }),
              },
            });
        }}
      />
      {isError && (
        <div className="text-xs text-red-500">This field is required</div>
      )}
    </div>
  );
}

function LongTextElement({
  form,
  block,
  isInvalid,
  sectionIndex,
}: {
  form: FormData;
  block: FormTemplateLongTextBlockData;
  isInvalid?: boolean;
  sectionIndex: number;
}) {
  const [isError, setIsError] = useState(false);
  const [value, setValue] = useState(block.properties.value ?? "");
  const updateFormMutation = useUpdateForm();

  useEffect(() => {
    setIsError(isInvalid === true);
  }, [isInvalid]);

  useEffect(() => {
    setValue(block.properties.value ?? "");
  }, [block.properties.value]);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "inherit"; // Reset height to shrink if needed
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + 4 + "px";
    }
  }, [value]);

  return (
    <div className="space-y-1 pr-6">
      <div>
        <div className="relative w-fit">
          <div className="text-md">{block.properties.label}</div>
          {block.properties.required && (
            <div className="absolute top-0 left-full -mt-0.5 ml-0.5 h-1.5 w-1.5 rounded-full text-red-500">
              *
            </div>
          )}
        </div>
        {block.properties.description.trim() !== "" && (
          <div className="text-sm text-gray-500">
            {block.properties.description}
          </div>
        )}
      </div>
      <textarea
        ref={textAreaRef}
        placeholder={block.properties.placeholder}
        rows={3}
        className="w-full resize-none rounded-md border border-gray-300 p-2 text-sm shadow-sm outline-none placeholder:text-gray-300 focus:border-gray-300 focus:outline-none focus:ring-0"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onBlur={(e) => {
          if (block.properties.required && e.target.value.trim() === "") {
            setIsError(true);
          } else {
            setIsError(false);
          }
          // set value via POST request
          if (form.blocks)
            updateFormMutation.mutate({
              update: {
                _id: form._id,
                blocks: form.blocks.map((section, idx) => {
                  if (idx === sectionIndex) {
                    return {
                      ...section,
                      blocks: section.blocks.map((b) => {
                        if (b.id === block.id && b.type === "long text") {
                          return {
                            ...b,
                            properties: {
                              ...b.properties,
                              value: e.target.value,
                            },
                          };
                        }
                        return b;
                      }),
                    };
                  }
                  return section;
                }),
              },
            });
        }}
      />
      {isError && (
        <div className="text-xs text-red-500">This field is required</div>
      )}
    </div>
  );
}

function TaskLog({ taskId }: { taskId: string }) {
  const { data: taskLogs } = useGetTaskLogs(taskId);

  return (
    <div className="space-y-3">
      {
        <ul role="list" className="pb-8">
          {taskLogs?.map((l, i) => (
            <li key={l._id.toString()}>
              <div className="relative pb-5">
                {i !== taskLogs.length - 1 ? (
                  <span
                    className="absolute left-1 top-2 -ml-[2px] h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="flex items-start">
                  <div
                    className={
                      "text-md relative mt-1 flex h-1.5 w-1.5 items-center justify-center rounded-full bg-gray-400/70"
                    }
                  ></div>

                  <div className="ml-5 w-28 whitespace-nowrap text-left text-xs text-gray-400">
                    <time dateTime={dayjs(l.createdAt).format("MMM DD")}>
                      {dayjs(l.createdAt).format("h:mm A, MMM DD")}
                    </time>
                  </div>
                  <div className="ml-2 -mt-0.5 flex min-w-0 flex-1 justify-between space-x-4 text-xs">
                    <div className="text-sm font-light text-gray-500">
                      <LogText log={l} />
                      <p className="text-sm"></p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      }
      {/* {taskLogs?.map((log, index) => (
        <div className="flex items-start space-x-3 ">
          <div className="w-30">
            <LogTime logTime={log.createdAt} />
          </div>
          <div className="mt-1 h-2 w-2 rounded-full bg-gray-300"></div>
          <div>
            <LogText log={log} />
          </div>
        </div>
      ))} */}
    </div>
  );
}

function LogText({ log }: { log: TaskLogData }) {
  switch (log.type) {
    case "task created":
      return (
        <div>
          {log.metadata.journey ? (
            <span>{`Task was created`}</span>
          ) : (
            // <span className="font-medium">{log.metadata.createdBy.name}</span>
            <>
              {log.metadata.createdBy ? (
                <>
                  <span>{`Task was created by `}</span>
                  <span className="font-medium">
                    {log.metadata.createdBy.name}
                  </span>
                </>
              ) : (
                <span>{`Task was created`}</span>
              )}
            </>
          )}
        </div>
      );
    case "task status changed":
      return (
        <div>
          <span>{`Status changed from `}</span>
          <span className="font-medium">{log.metadata.oldStatus}</span>
          <span>{` to `}</span>
          <span className="font-medium">{log.metadata.newStatus}</span>
          {!!log.metadata.tasker.name && (
            <>
              <span>{` by `}</span>
              <span className="font-medium">{log.metadata.tasker.name}</span>
            </>
          )}
        </div>
      );
    case "task title changed":
      return (
        <div>
          <span>{`Title changed from `}</span>
          <span className="font-medium">{log.metadata.oldTitle}</span>
          <span>{` to `}</span>
          <span className="font-medium">{log.metadata.newTitle}</span>
        </div>
      );
    case "task tasker changed":
      return (
        <div>
          <span>{`Tasker changed from `}</span>
          <span className="font-medium">
            {log.metadata.oldTasker.name ??
              log.metadata.oldTasker.email ??
              "Unassigned"}
          </span>
          <span>{` to `}</span>
          <span className="font-medium">
            {log.metadata.newTasker.name ??
              log.metadata.newTasker.email ??
              "Unassigned"}
          </span>
        </div>
      );
    case "task due date changed":
      // check if time changed
      if (
        dayjs(log.metadata.oldDueDate).format("hh:mm A") !==
        dayjs(log.metadata.newDueDate).format("hh:mm A")
      ) {
        return (
          <div>
            <span>{`Due date changed from `}</span>
            <span className="font-medium">
              {dayjs(log.metadata.oldDueDate).format("DD MMM, hh:mm A")}
            </span>
            <span>{` to `}</span>
            <span className="font-medium">
              {dayjs(log.metadata.newDueDate).format("DD MMM, hh:mm A")}
            </span>
          </div>
        );
      }
      return (
        <div>
          <span>{`Due date changed from `}</span>
          <span className="font-medium">
            {dayjs(log.metadata.oldDueDate).format("DD MMM")}
          </span>
          <span>{` to `}</span>
          <span className="font-medium">
            {dayjs(log.metadata.newDueDate).format("DD MMM")}
          </span>
        </div>
      );
    case "task activated":
      return (
        <div>
          <span>{`Task was activated`}</span>
        </div>
      );
    case "task blocker reported":
      return (
        <div>
          <span>{`Blocker was reported by `}</span>
          <span className="font-medium">
            {log.metadata.blocker.reporterUser.name}
          </span>
        </div>
      );
    case "task description changed":
      return (
        <div>
          <span>{`Description changed`}</span>
          {/* <span className="font-medium">{log.metadata.oldDescription}</span>
          <span>{` to `}</span>
          <span className="font-medium">{log.metadata.newDescription}</span> */}
        </div>
      );
    default:
      return <>{log.type}</>;
  }
  return <></>;
}

// function LogTime({ logTime }: { logTime: string }) {
//   const { absValue, unit } = getTimeDifference(new Date(logTime), new Date());
//   return (
//     <div className="space-y-1 text-right">
//       <div className="text-xs text-gray-400">
//         {dayjs(logTime).format("DD MMM, hh:mm a")}
//       </div>
//       <div className="text-xs text-gray-400">{`${absValue} ${unit} ago`}</div>
//     </div>
//   );
// }

// function TaskBlocker({ task }: { task: TaskData }) {
//   const [blockerDescription, setBlockerDescription] = useState(
//     task.blocker?.description ?? ""
//   );
//   const [isBlockerEditingModalOpen, setIsBlockerEditingModalOpen] =
//     useState(false);

//   return (
//     <>
//       {task.blocker && (
//         <div className="pt-2">
//           <div className="space-y-3 rounded-md border border-red-500 bg-red-50 px-3 py-3">
//             {/* <div className="flex items-end justify-between gap-2"> */}
//             <div className="space-y-3">
//               <div className="w-fit rounded-full bg-red-500 px-2 py-0.5 text-xs text-white">
//                 Blocker
//               </div>
//               <div className="text-sm text-gray-600">
//                 <span>{"The following blocker was reported by "}</span>
//                 <span className="font-medium text-gray-800">{`${task.blocker.reporterUser.name}`}</span>
//                 <span>{" at "}</span>
//                 <span className="font-medium text-gray-800">{`${dayjs(
//                   task.blocker.createdAt
//                 ).format("h:mm A, DD MMM, YYYY")} -`}</span>
//               </div>
//               <div className="flex space-x-2">
//                 <div className="inset-y-0 w-[3px] bg-red-400" />
//                 <span className="text-sm text-red-500">{`${blockerDescription}`}</span>
//               </div>
//               <div className="flex justify-end">
//                 <Button
//                   onClick={() => {
//                     setIsBlockerEditingModalOpen(true);
//                   }}
//                   variant={"destructive"}
//                 >
//                   Resolve
//                 </Button>
//               </div>
//             </div>

//             {/* <button
//                 className="rounded-md border border-red-500 bg-red-50 px-3 py-2 text-sm text-red-500 hover:bg-red-500 hover:text-white hover:shadow-sm"
//                 onClick={() => {
//                   setIsBlockerEditingModalOpen(true);
//                 }}
//               >
//                 {"Resolve"}
//                 <PencilIcon className="h-4 w-4" />
//               </button> */}
//             {/* </div> */}
//           </div>
//           {/* <BlockerEditingModal
//             task={task}
//             isBlockerEditingModalOpen={isBlockerEditingModalOpen}
//             setIsBlockerEditingModalOpen={setIsBlockerEditingModalOpen}
//           /> */}
//         </div>
//       )}
//     </>
//   );
// }

function Options({
  task,
  handleClose,
}: {
  task: TaskData | undefined;
  handleClose: () => void;
}) {
  const deleteTaskMutation = useDeleteTask();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  return (
    <div>
      <Menu
        side="right"
        items={[
          // {
          //   text: "Mark as Blocked",

          //   icon: ExclamationTriangleIcon,
          // },
          {
            text: "Delete",
            onClick: () => {
              if (task?._id) {
                setIsConfirmationModalOpen(true);
              }
            },
            icon: TrashIcon,
            disabled: !task,
          },

          // {
          //   text: task?.estimate ? "Remove Estimate" : "Add Estimate",
          //   onClick: () => {
          //     if (task?.estimate) {
          //       updateTaskMutation.mutate({
          //         update: {
          //           _id: task?._id,
          //           estimate: 0,
          //         },
          //         metadata: {
          //           journeyId: task?.journey?._id,
          //         }
          //       });
          //     } else {
          //       updateTaskMutation.mutate({
          //         update: { _id: task?._id, estimate: 1 },
          //         metadata: {
          //           journeyId: task?.journey?._id,
          //         }
          //       });
          //     }
          //   },
          //   icon: WrenchIcon,
          //   disabled: !task,
          // },
          // {
          //   text: task?.issue ? "Remove Issue" : "Add Issue",
          //   onClick: () => {
          //     if (task?.issue) {
          //       updateTaskMutation.mutate({
          //         update: {
          //           _id: task?._id,
          //           hasIssue: false,
          //         },
          //         metadata: {
          //           journeyId: task?.journey?._id,
          //         }
          //       });
          //     } else {
          //       setIsIssueModalOpen(true);
          //       // updateTaskMutation.mutate({
          //       //   update: { _id: task?._id, issue: },
          //       // });
          //     }
          //   },
          //   icon: WrenchIcon,
          //   disabled: !task,
          // },
        ]}
      >
        <div className="inline-flex h-[30px] w-[30px] items-center justify-center rounded-md bg-white text-gray-400 hover:bg-gray-100 hover:bg-gray-50 hover:text-gray-500">
          <EllipsisVerticalIcon className="h-5 w-5 " />
        </div>
      </Menu>
      <Dialog
        open={isConfirmationModalOpen}
        onOpenChange={(open) => {
          setIsConfirmationModalOpen(open);
        }}
      >
        <DialogContent>
          <DialogTitle>Confirm Task Deletion</DialogTitle>
          <div className="space-y-4">
            <div className="text-sm">
              <span className="font-light text-gray-600">{`Are you sure you want to delete task `}</span>
              <span className="font-medium">{`"${task?.title}"`}</span>
              <span className="font-light text-gray-600">{`?`}</span>
            </div>
            <div className="flex justify-end space-x-2 pt-2">
              <Button
                variant={"secondary"}
                onClick={() => {
                  setIsConfirmationModalOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant={"destructive"}
                onClick={() => {
                  if (task?._id) {
                    deleteTaskMutation.mutate({
                      update: {
                        _id: new ObjectID(task?._id),
                      },
                      otherDetails: {
                        journeyId: new ObjectID(task?.journey?._id),
                        sharedSpaceId: new ObjectID(task?.sharedSpaceId),
                        stageId: new ObjectID(task?.journeyStage?._id),
                      },
                    });
                    // taskPanelCtx.dispatch({
                    //   type: "TASK_PANEL_UPDATE",
                    //   taskPanel: {
                    //     id: undefined,
                    //     isOpen: false,
                    //   },
                    // });
                    handleClose();
                  }
                  setIsConfirmationModalOpen(false);
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

// function BlockerReportingModal({
//   isBlockerReportingModalOpen,
//   setIsBlockerReportingModalOpen,
//   task,
// }: {
//   isBlockerReportingModalOpen: boolean;
//   setIsBlockerReportingModalOpen: (value: boolean) => void;
//   task: TaskData;
// }) {
//   // const {data: taskers} = useGetTaskersForTask()
//   const updateTaskMutation = useUpdateTask();
//   const markBlockerForTaskMutation = useMarkBlockerForTask();
//   const [blockerDescription, setBlockerDescription] = useState("");
//   const [resolver, setResolver] = useState<TaskerData | undefined>(undefined);
//   return (
//     <Modal
//       isOpen={isBlockerReportingModalOpen}
//       closeModal={() => {
//         setIsBlockerReportingModalOpen(false);
//       }}
//     >
//       <div className="w-96 p-6">
//         <ModalHeading
//           title="Blocker Details"
//           subtitle="Details of what is blocking this task"
//         />
//         <div className="mt-6 space-y-4">
//           <div className="space-y-1">
//             <TextArea
//               label="What is blocking the task?"
//               name="blockerDescription"
//               value={blockerDescription}
//               onChange={(e) => {
//                 setBlockerDescription(e.target.value);
//               }}
//             />
//             {/* <span className="font-light text-gray-600">{`Are you sure you want to delete task `}</span>
//             <span className="font-medium">{`"${task?.title}"`}</span>
//             <span className="font-light text-gray-600">{`?`}</span>
//              */}
//           </div>
//           {/* <Tasker

//           label="Resolver"
//           // name="resolver"
//           onChange={(tasker) => {
//             if (tasker.taskerId) {
//               setResolver
//             }
//             // setResolver(tasker);
//           }}
//           task={task}
//         /> */}
//           <div className="space-y-3"></div>
//           <div className="flex justify-end space-x-2 pt-2">
//             <Button
//               onClick={() => {
//                 setIsBlockerReportingModalOpen(false);
//               }}
//               variant={"secondary"}
//             >
//               Cancel
//             </Button>
//             <Button
//               onClick={() => {
//                 if (task._id) {
//                   markBlockerForTaskMutation.mutate({
//                     blocker: {
//                       _id: task._id.toString(),
//                       description: blockerDescription,
//                     },
//                     metadata: {
//                       phaseId: task?.phase?._id,
//                       journeyId: task?.journey?._id,
//                     },
//                   });
//                 }
//                 // if (task?._id)
//                 //   updateTaskMutation.mutate({
//                 //     update: {
//                 //       _id: task?._id,
//                 //       issue: {
//                 //         reason: issueDescription,
//                 //         resolverId: ,
//                 //       },
//                 //     },
//                 //   });
//                 setIsBlockerReportingModalOpen(false);
//               }}
//               disabled={blockerDescription.trim() === ""}
//             >
//               Confirm
//             </Button>
//             {/* <Button
//             text={"Report Issue"}
//             onClick={() => {
//               if (task?._id)
//                 updateTaskMutation.mutate({
//                   update: {
//                     _id: task?._id,
//                     issue: {
//                       reason: issueDescription,
//                       resolverId: ,
//                     },
//                   },
//                 });
//               setIsIssueModalOpen(false);
//             }}
//             size="xs"
//             ring={false}
//           /> */}
//           </div>
//         </div>
//       </div>
//     </Modal>
//   );
// }

// function BlockerEditingModal({
//   isBlockerEditingModalOpen,
//   setIsBlockerEditingModalOpen,
//   task,
// }: {
//   isBlockerEditingModalOpen: boolean;
//   setIsBlockerEditingModalOpen: (value: boolean) => void;
//   task: TaskData;
// }) {
//   // const {data: taskers} = useGetTaskersForTask()
//   const updateTaskMutation = useUpdateTask();
//   const resolveBlockerForTaskMutation = useResolveBlockerForTask();
//   const [resolutionNote, setResolutionNote] = useState("");
//   const [resolver, setResolver] = useState<TaskerData | undefined>(undefined);
//   return (
//     <Modal
//       isOpen={isBlockerEditingModalOpen}
//       closeModal={() => {
//         setIsBlockerEditingModalOpen(false);
//       }}
//     >
//       <div className="w-96 p-6">
//         <ModalHeading
//           title="Resolve Blocker"
//           subtitle="Details on how the blocker was resolved"
//         />
//         <div className="mt-6 space-y-4">
//           <div className="space-y-1">
//             <TextArea
//               label="Resolution Details"
//               name="resolution"
//               value={resolutionNote}
//               onChange={(e) => {
//                 setResolutionNote(e.target.value);
//               }}
//             />
//           </div>
//           <div className="space-y-3"></div>
//           <div className="flex justify-end space-x-2 pt-2">
//             <Button
//               onClick={() => {
//                 setIsBlockerEditingModalOpen(false);
//               }}
//               variant={"secondary"}
//             >
//               Cancel
//             </Button>
//             <Button
//               onClick={() => {
//                 if (task._id) {
//                   resolveBlockerForTaskMutation.mutate({
//                     blocker: {
//                       _id: task._id.toString(),
//                       resolutionNote: resolutionNote,
//                     },
//                     metadata: {
//                       phaseId: task?.phase?._id,
//                       journeyId: task?.journey?._id,
//                     },
//                   });
//                 }
//                 setIsBlockerEditingModalOpen(false);
//               }}
//               disabled={resolutionNote.trim() === ""}
//             >
//               Mark as Resolved
//             </Button>
//           </div>
//         </div>
//       </div>
//     </Modal>
//   );
// }

export function getStatusItem(parentStatus: ParentStatus, status: string) {
  return (
    <div className="flex items-center space-x-2">
      {getStatusSymbol(parentStatus)}
      <div className="text-sm">{status}</div>
    </div>
  );
}

function getStatusTag(
  status: TaskData["status"],
  isClickable: boolean = false
) {
  return (
    <div
      className={clsx(
        status === "To-do" && "border-amber-600 bg-amber-400 text-white",
        status === "In Progress" && "border-blue-600 bg-blue-400 text-white",
        status === "Completed" && "border-green-600 bg-green-400 text-white",
        status === "Stuck" && "border-red-600 bg-red-400 text-white",
        status === "Canceled" && "border-gray-600 bg-gray-400 text-white",
        status === "Backlog" &&
          "border-dashed border-gray-500 bg-white text-gray-500",
        "inline-flex items-center space-x-1 whitespace-nowrap rounded-full border py-0.5 px-2 text-xs"
      )}
    >
      <span>{status}</span>
      {isClickable && <ChevronDownIcon className="h-3 w-3 text-white" />}
    </div>
  );
}

export function StatusPill({ status }: { status: TaskData["status"] }) {
  return (
    <div
      className={clsx(
        status === "To-do" &&
          "border-amber-600 bg-amber-400 text-white hover:bg-amber-400",
        status === "In Progress" &&
          "border-blue-600 bg-blue-400 text-white hover:bg-blue-400",
        status === "Completed" &&
          "border-green-600 bg-green-400 text-white hover:bg-green-400",
        status === "Stuck" &&
          "border-red-600 bg-red-400 text-white hover:bg-red-400",
        status === "Canceled" &&
          "border-gray-600 bg-gray-400 text-white hover:bg-gray-400",
        status === "Backlog" &&
          "border-dashed border-gray-500 bg-white text-gray-500 hover:bg-white ",
        "h-3 w-3 rounded-sm"
      )}
    />
  );
}

export function getStatusSymbol(
  status: ParentStatus,
  isClickable: boolean = false
) {
  switch (status) {
    case "To-do":
      return (
        <div className="flex items-center space-x-2">
          {/* <div className={clsx("group rounded-md", isClickable && "p-0")}> */}
          <div
            className={clsx(
              "h-4 w-4 rounded-full border-[2px] border-amber-400 bg-amber-200 transition-all",
              isClickable && "hover:border-amber-500"
            )}
          />
          {/* </div> */}
          {/* <div className="h-3.5 w-3.5 rounded border border-amber-600 bg-amber-400" />
          <div className="h-3.5 w-3.5 rounded-full border border-amber-600 bg-amber-400" />
          <div className="h-3.5 w-3.5 rounded-full border-[1.5px] border-amber-400 bg-amber-100" /> */}
        </div>
      );
    case "Backlog":
      return (
        <div
          className={clsx(
            "h-4 w-4 rounded-full border-[2px] border-dashed border-gray-400 transition-all",
            isClickable && "hover:border-gray-500"
          )}
        />
      );

    case "Canceled":
      return (
        <div
          className={clsx(
            "flex h-4 w-4 items-center justify-center rounded-full bg-gray-500/60 text-white transition-all",
            isClickable && "hover:bg-gray-500/80"
          )}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={3.5}
            stroke="currentColor"
            className="h-2.5 w-2.5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
      );

    case "In Progress":
      return (
        <div
          className={clsx(
            "group flex h-4 w-4 items-center justify-center rounded-full border-[1.5px] border-blue-500 transition-all",
            isClickable && "hover:border-blue-600"
          )}
        >
          <div
            className={clsx(
              "h-2 w-2 rounded-full bg-blue-400",
              isClickable && "group-hover:bg-blue-500 transition-all"
            )}
          />
        </div>
      );
    case "Stuck":
      return (
        <div
          className={clsx(
            "group flex h-4 w-4 items-center justify-center rounded-full border-[1.5px] border-red-500 transition-all",
            isClickable && "hover:border-red-600"
          )}
        >
          <div
            className={clsx(
              "h-2 w-2 rounded-full bg-red-400",
              isClickable && "group-hover:bg-red-500 transition-all"
            )}
          />
        </div>
      );
    case "Completed":
      return (
        <div className="flex items-center space-x-2">
          <div
            className={clsx(
              "flex h-4 w-4 items-center justify-center rounded-full bg-green-500 text-white transition-all",
              isClickable && "hover:bg-green-600"
            )}
          >
            {/* <svg
              width="14"
              height="14"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              strokeWidth={4}
            >
              <path
                d="M11.4669 3.72684C11.7558 3.91574 11.8369 4.30308 11.648 4.59198L7.39799 11.092C7.29783 11.2452 7.13556 11.3467 6.95402 11.3699C6.77247 11.3931 6.58989 11.3355 6.45446 11.2124L3.70446 8.71241C3.44905 8.48022 3.43023 8.08494 3.66242 7.82953C3.89461 7.57412 4.28989 7.55529 4.5453 7.78749L6.75292 9.79441L10.6018 3.90792C10.7907 3.61902 11.178 3.53795 11.4669 3.72684Z"
                fill="currentColor"
                fill-rule="evenodd"
                clip-rule="evenodd"
              ></path>
            </svg> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className="h-3 w-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m4.5 12.75 6 6 9-13.5"
              />
            </svg>
          </div>
          {/* <div className="flex h-3.5 w-3.5 cursor-pointer items-center justify-center rounded border-[1.5px] group border-green-600 hover:border-green-800 transition-all bg-green-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3.5}
              stroke="currentColor"
              className="h-2.5 w-2.5 text-green-600 group-hover:text-green-800
              "
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m4.5 12.75 6 6 9-13.5"
              />
            </svg>
          </div>
          <div className="flex h-3.5 w-3.5 cursor-pointer items-center justify-center rounded  bg-green-500 hover:bg-green-600">
            
          </div> */}
          {/* <div className="h-3.5 w-3.5 rounded border border-amber-600 bg-amber-400" />
          <div className="h-3.5 w-3.5 rounded-full border border-amber-600 bg-amber-400" />
          <div className="h-3.5 w-3.5 rounded-full border-[1.5px] border-amber-400 bg-amber-100" /> */}
        </div>
      );
    default:
      return <div>TBD</div>;
  }
}

export function StatusSelect({
  task,
  disabledStatuses = [],
  isTag,
  disabled = false,
  isSymbol = false,
  isProjectView = false,
}: {
  isSymbol?: boolean;
  task: ReducedTaskData;
  disabledStatuses?: string[];
  isTag?: boolean;
  disabled?: boolean;
  isProjectView?: boolean;
}) {
  const [isStuckReasonModalOpen, setIsStuckReasonModalOpen] = useState(false);
  const updateTaskMutation = useUpdateTask();
  const updateTaskFromProjectViewMutation = useUpdateTaskFromProjectView();
  const statusGroupsCtx = useStatusGroups();
  // console.log(taskx)
  const statusGroup = statusGroupsCtx.state.statusGroups.find(
    (sg) => sg._id === task.statusGroup._id
  );
  if (!statusGroup) {
    return <div>No match</div>;
  }

  let statuses: {
    [key: string]: string[];
  } = {};

  if (statusGroup) {
    statuses = {
      Backlog: statusGroup.statuses
        .filter((s) => s.parentStatus === "Backlog")
        .map((s) => s.name),
      "To-do": statusGroup.statuses
        .filter((s) => s.parentStatus === "To-do")
        .map((s) => s.name),
      "In Progress": statusGroup.statuses
        .filter((s) => s.parentStatus === "In Progress")
        .map((s) => s.name),
      Completed: statusGroup.statuses
        .filter((s) => s.parentStatus === "Completed")
        .map((s) => s.name),
      Stuck: statusGroup.statuses
        .filter((s) => s.parentStatus === "Stuck")
        .map((s) => s.name),
      Canceled: statusGroup.statuses
        .filter((s) => s.parentStatus === "Canceled")
        .map((s) => s.name),
    };
  }

  return (
    <>
      <Select
        disabled={disabled}
        value={task.customStatus}
        onValueChange={(customStatus: string) => {
          if (task?.customStatus !== customStatus) {
            if (customStatus === "Stuck") {
              setIsStuckReasonModalOpen(true);
            } else if (
              customStatus === "Completed" ||
              customStatus === "Canceled"
            ) {
              if (!task._id) return;
              // find the new parent status
              const newParentStatus = Object.keys(statuses).find((key) =>
                statuses[key].includes(customStatus)
              ) as ParentStatus | undefined;

              if (isProjectView && task.phase?._id) {
                updateTaskFromProjectViewMutation.mutate({
                  update: {
                    _id: task._id,
                    customStatus,
                    ...(!!newParentStatus ? { status: newParentStatus } : {}),
                    completionDate: new Date().toISOString(),
                  },
                  metadata: {
                    phaseId: task?.phase?._id,
                    journeyId: task?.journey?._id,
                  },
                });
              } else {
                updateTaskMutation.mutate({
                  update: {
                    _id: task._id,
                    // status: status,
                    customStatus,
                    completionDate: new Date().toISOString(),
                  },
                  metadata: {
                    phaseId: task?.phase?._id,
                    journeyId: task?.journey?._id,
                    journeyStageId: task?.journeyStage?._id,
                    oldStatus: task.customStatus,
                    task: task,
                  },
                });
              }
            } else {
              if (!task._id) return;
              // find the new parent status
              const newParentStatus = Object.keys(statuses).find((key) =>
                statuses[key].includes(customStatus)
              ) as ParentStatus | undefined;

              if (isProjectView && task.phase?._id) {
                updateTaskFromProjectViewMutation.mutate({
                  update: {
                    _id: task._id,
                    customStatus,
                    completionDate: undefined,
                    ...(!!newParentStatus ? { status: newParentStatus } : {}),
                  },
                  metadata: {
                    phaseId: task?.phase?._id,
                    journeyId: task?.journey?._id,
                  },
                });
              } else {
                updateTaskMutation.mutate({
                  update: {
                    _id: task._id,
                    customStatus,
                    completionDate: undefined,
                  },
                  metadata: {
                    phaseId: task?.phase?._id,
                    journeyId: task?.journey?._id,
                    journeyStageId: task?.journeyStage?._id,
                    oldStatus: task.customStatus,
                    ...(newParentStatus && { newParentStatus }),
                    task,
                  },
                });
              }
            }
            // updateTaskMutation.mutate({
            //   update: { _id: new ObjectID(task._id), status: status },
            //   metadata: {
            //     phaseId: task?.phase?._id
            //       ? new ObjectID(task?.phase?._id)
            //       : undefined,
            //     journeyId: task?.journey?._id
            //       ? new ObjectID(task?.journey?._id)
            //       : undefined,
            //     journeyStageId: task?.journeyStage?._id
            //       ? new ObjectID(task?.journeyStage?._id)
            //       : undefined,
            //   },
            // });
          }
        }}
      >
        {isSymbol ? (
          <>
            <SelectTrigger
              className={clsx(
                // task.status === "To-do" &&
                //   "border-amber-600 bg-amber-400 text-white hover:bg-amber-400",
                // task.status === "In Progress" &&
                //   "border-blue-600 bg-blue-400 text-white hover:bg-blue-400",
                // task.status === "Completed" &&
                //   "border-green-600 bg-green-400 text-white hover:bg-green-400",
                // task.status === "Stuck" &&
                //   "border-red-600 bg-red-400 text-white hover:bg-red-400",
                // task.status === "Canceled" &&
                //   "border-gray-600 bg-gray-400 text-white hover:bg-gray-400",
                // task.status === "Backlog" &&
                //   "border-dashed border-gray-500 bg-white text-gray-500 hover:bg-white",
                "h-fit border-0 p-0 shadow-none outline-none focus:border-0 focus:outline-none focus:ring-0 focus-visible:border-0 focus-visible:outline-none focus-visible:ring-0"
              )}
              showIcon={false}
            >
              {getStatusSymbol(task.status, true)}
            </SelectTrigger>
          </>
        ) : isTag ? (
          <SelectTrigger
            iconClassName="opacity-100 h-3 w-3 ml-0.5"
            className={clsx(
              task.status === "To-do" &&
                "border-amber-600 bg-amber-400 text-white hover:bg-amber-400",
              task.status === "In Progress" &&
                "border-blue-600 bg-blue-400 text-white hover:bg-blue-400",
              task.status === "Completed" &&
                "border-green-600 bg-green-400 text-white hover:bg-green-400",
              task.status === "Stuck" &&
                "border-red-600 bg-red-400 text-white hover:bg-red-400",
              task.status === "Canceled" &&
                "border-gray-600 bg-gray-400 text-white hover:bg-gray-400",
              task.status === "Backlog" &&
                "border-dashed border-gray-500 bg-white text-gray-500 hover:bg-white",
              "flex h-[22px] w-fit rounded-md px-1.5 text-xs"
            )}
            showIcon={false}
          >
            {task.customStatus}
            <ChevronDownIcon
              className={clsx(
                "ml-1 h-3 w-3",
                task.status === "Backlog" ? "text-gray-600" : "text-white"
              )}
            />
            {/* "h-8 w-fit rounded-md" */}
            {/* {getStatusTag(task.status, !disabled)} */}
          </SelectTrigger>
        ) : (
          <SelectTrigger
            showIcon={false}
            className={clsx(
              task.status === "To-do" &&
                "border-amber-600 bg-amber-400 text-white hover:bg-amber-400",
              task.status === "In Progress" &&
                "border-blue-600 bg-blue-400 text-white hover:bg-blue-400",
              task.status === "Completed" &&
                "border-green-600 bg-green-400 text-white hover:bg-green-400",
              task.status === "Stuck" &&
                "border-red-600 bg-red-400 text-white hover:bg-red-400",
              task.status === "Canceled" &&
                "border-gray-600 bg-gray-400 text-white hover:bg-gray-400",
              task.status === "Backlog" &&
                "border-dashed border-gray-500 bg-white text-gray-500 hover:bg-white ",
              "h-8 w-fit rounded-md"
            )}
          >
            <div className="">{task.customStatus}</div>
            <CaretSortIcon className={"h-4 w-4"} />
          </SelectTrigger>
        )}
        <SelectContent>
          <SelectGroup>
            {statuses["Backlog"].map((status) => (
              <SelectItem key={status} value={status} className="h-[30px]">
                {getStatusItem("Backlog", status)}
              </SelectItem>
            ))}
            {statuses["To-do"].map((status) => (
              <SelectItem key={status} value={status} className="h-[30px]">
                {getStatusItem("To-do", status)}
              </SelectItem>
            ))}
            {statuses["In Progress"].map((status) => (
              <SelectItem key={status} value={status} className="h-[30px]">
                {getStatusItem("In Progress", status)}
              </SelectItem>
            ))}
            {statuses["Stuck"].map((status) => (
              <SelectItem key={status} value={status} className="h-[30px]">
                {getStatusItem("Stuck", status)}
              </SelectItem>
            ))}
            {!disabledStatuses.includes("Completed") && (
              <>
                {statuses["Completed"].map((status) => (
                  <SelectItem key={status} value={status} className="h-[30px]">
                    {getStatusItem("Completed", status)}
                  </SelectItem>
                ))}
              </>
            )}
            {statuses["Canceled"].map((status) => (
              <SelectItem key={status} value={status} className="h-[30px]">
                {getStatusItem("Canceled", status)}
              </SelectItem>
            ))}

            {/* {statuses
              .filter((s) => {
                if (task.journey) {
                  if (s === "Backlog") {
                    return false;
                  }
                }
                if (disabledStatuses.includes(s)) {
                  return false;
                }
                return true;
              })
              .map((status) => (
                <SelectItem key={status} value={status} className="">
                  {getStatusItem(status)}
                </SelectItem>
              ))} */}
            {/* <SelectItem value={"Blocked"} key={"Blocked"}>
            {getStatusTag("Blocked")}
          </SelectItem>
          <SelectItem value={"Canceled"} key={"Canceled"}>
            {getStatusTag("Canceled")}
          </SelectItem> */}
          </SelectGroup>
        </SelectContent>
      </Select>
      <StuckDialog
        task={task}
        open={isStuckReasonModalOpen}
        setIsOpen={setIsStuckReasonModalOpen}
      />
    </>
  );
}

export function StuckDialog({
  task,
  open,
  setIsOpen,
}: {
  task: ReducedTaskData;
  open: boolean;
  setIsOpen: (value: boolean) => void;
}) {
  const [resolverId, setResolverId] = useState("");
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [reason, setReason] = useState("");
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "0px"; // Reset height to shrink if needed
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + "px";
    }
  }, [reason]);
  const createThreadMutation = useCreateThreadInTask();
  const createThreadsInPhaseTaskMutation = useCreateThreadInPhaseTask();
  const createThreadMessageMutation = useCreateThreadMessage();
  const app = useRealmApp();
  const updateTaskMutation = useUpdateTask();

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        setIsOpen(open);
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Report Issue</DialogTitle>
          {/* <DialogDescription>
          {`Please provide a reason for marking this task as stuck`}
        </DialogDescription> */}
        </DialogHeader>
        <div className="py-4">
          <div className="space-y-5">
            <div className="space-y-2">
              <div>
                <InputHeading heading="Why are you stuck?" />
                <p className="text-xs text-gray-400">
                  This will be sent as a message in the task chat
                </p>
              </div>

              <textarea
                ref={textAreaRef}
                className="min-h-[100px] w-full resize-none rounded-md border border-gray-200 p-2 px-3 text-sm outline-none placeholder:text-gray-300 focus:border-gray-300 focus:outline-none focus:ring-0"
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
                placeholder="I can't figure out how to..."
              />
            </div>
            {/* <div className="space-y-1">
            <div>
              <InputHeading heading="Resolver" required />
              <p className="text-xs text-gray-400">
                This person will be notified of the issue you are facing
              </p>
            </div>
            <ResolverCombobox
              task={task}
              resolverId={resolverId}
              setResolverId={setResolverId}
            />
          </div> */}
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild className="">
            <div className="flex justify-end space-x-3">
              <Button
                variant={"secondary"}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={reason.trim() === ""}
                onClick={() => {
                  if (task._id) {
                    updateTaskMutation.mutate({
                      update: {
                        _id: task._id,
                        customStatus: "Stuck",
                        // blocker: {
                        //   resolverUserId: resolverId,
                        // },
                        completionDate: undefined,
                      },
                      metadata: {
                        phaseId: task?.phase?._id,
                        journeyId: task?.journey?._id,
                        journeyStageId: task?.journeyStage?._id,
                      },
                    });
                    if (task.threadId) {
                      createThreadMessageMutation.mutate({
                        params: {
                          threadId: new ObjectID(task.threadId),
                          text: reason,
                        },
                      });
                    } else {
                      if (task.phase) {
                        createThreadsInPhaseTaskMutation.mutate({
                          params: {
                            name: task.title,
                            phaseId: new ObjectID(task.phase?._id),
                            associations: [
                              { _id: new ObjectID(task._id), type: "task" },
                            ],
                            firstMessage: {
                              text: reason,
                            },
                            // userIds: [new ObjectID(resolverId)],
                          },
                        });
                      } else {
                        createThreadMutation.mutate({
                          params: {
                            name: task.title,
                            associations: [
                              { _id: new ObjectID(task._id), type: "task" },
                            ],
                            firstMessage: {
                              text: reason,
                            },
                            // userIds: [new ObjectID(resolverId)],
                            instanceId: new ObjectID(
                              app.currentUser?.customData?.instanceId?.$oid
                            ),
                          },
                        });
                      }
                    }
                  }
                  setIsOpen(false);
                }}
              >
                {updateTaskMutation.isLoading ? "Reporting..." : "Report"}
              </Button>
            </div>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

// function ResolverCombobox({
//   task,
//   disabled,
// }: {
//   task: ReducedTaskData;
//   disabled: boolean;
// }) {
//   return (
//     <>
//       {task.sharedSpaceId ? (
//         <SharedSpaceResolverCombobox
//           task={task}
//           sharedSpaceId={task.sharedSpaceId}
//           disabled={disabled}
//         />
//       ) : (
//         <InternalResolver task={task} disabled={disabled} />
//       )}
//     </>
//   );
// }

// function SharedSpaceResolverCombobox({
//   task,
//   sharedSpaceId,
//   disabled,
// }: {
//   task: ReducedTaskData;
//   sharedSpaceId: string;
//   disabled: boolean;
// }) {
//   const { data: taskers } = useGetTaskersForSharedSpace(
//     new ObjectID(sharedSpaceId)
//   );
//   return (
//     <>
//       {taskers && (
//         <CommonResolverCombobox
//           task={task}
//           taskers={taskers}
//           type="sharedSpace"
//           disabled={disabled}
//         />
//       )}
//     </>
//   );
// }

// function InternalResolver({
//   task,
//   disabled,
// }: {
//   task: ReducedTaskData;
//   disabled: boolean;
// }) {
//   const app = useRealmApp();
//   const { data: taskers } = useGetTaskersForInstance(
//     app.currentUser?.customData?.instanceId
//   );
//   return (
//     <>
//       {taskers && (
//         <CommonResolverCombobox
//           task={task}
//           taskers={taskers}
//           type="instance"
//           disabled={disabled}
//         />
//       )}
//     </>
//   );
// }

// function CommonResolverCombobox({
//   task,
//   taskers,
//   type,
//   disabled,
// }: {
//   taskers: TaskerData[];
//   type: "instance" | "sharedSpace";
//   task: ReducedTaskData;
//   disabled: boolean;
// }) {
//   const updateTaskMutation = useUpdateTask();
//   const [open, setOpen] = useState(false);

//   return (
//     <Popover open={open} onOpenChange={setOpen}>
//       <PopoverTrigger asChild>
//         <Button
//           variant="secondary"
//           role="combobox"
//           aria-expanded={open}
//           className="max-w-[200px] space-x-2 truncate px-2"
//           disabled={disabled}
//         >
//           <ExclamationTriangleIcon className="h-4 w-4 shrink-0 text-red-500" />

//           {!!task.blocker?.resolverUser?._id ? (
//             <>
//               {/* {type === "sharedSpace" && (
//                 <div
//                   className={clsx(
//                     "h-1.5 w-1.5 shrink-0 rounded-full bg-gray-300",
//                     task.taskerType === "internal"
//                       ? "bg-gray-300"
//                       : "bg-slate-700"
//                   )}
//                 />
//               )} */}
//               <ResolverName
//                 resolverId={task.blocker?.resolverUser._id}
//                 taskers={taskers}
//               />
//             </>
//           ) : (
//             <div className="font-normal text-gray-400">Select a resolver</div>
//           )}
//           <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
//         </Button>
//       </PopoverTrigger>
//       <PopoverContent
//         className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
//         align="start"
//       >
//         <Command>
//           <CommandInput placeholder="Search resolver..." className="h-9" />
//           <CommandEmpty>No resolver found.</CommandEmpty>
//           <CommandGroup>
//             {taskers
//               .filter((r) => r.userType === "fullUser")
//               .map((r) => (
//                 <CommandItem
//                   key={r.email}
//                   value={r.userType === "fullUser" ? r.name : r.email}
//                   onSelect={() => {
//                     if (r.userType === "fullUser" && task._id) {
//                       updateTaskMutation.mutate({
//                         update: {
//                           _id: task._id,
//                           resolverUserId: r._id.toString(),
//                         },
//                         metadata: {
//                           phaseId: task?.phase?._id,
//                           journeyId: task?.journey?._id,
//                           journeyStageId: task?.journeyStage?._id,
//                         },
//                       });
//                     }
//                     setOpen(false);
//                   }}
//                   className="gap-2"
//                 >
//                   <div className="flex items-center space-x-2">
//                     {/* <div
//                     className={"h-2 w-2 shrink-0 rounded-full bg-slate-700"}
//                   /> */}
//                     {/* {type === "sharedSpace" && (
//                       <div
//                         className={clsx(
//                           "h-2 w-2 shrink-0 rounded-full bg-gray-300",
//                           r.userType === "invitedUser"
//                             ? "bg-slate-700"
//                             : r.type === "internal"
//                             ? "bg-gray-300"
//                             : "bg-slate-700"
//                         )}
//                       />
//                     )} */}
//                     <span className="truncate">
//                       {r.userType === "fullUser" ? r.name : r.email}
//                     </span>
//                   </div>
//                   <CheckIcon
//                     className={cn(
//                       "ml-auto h-4 w-4 shrink-0",
//                       r.userType === "fullUser" &&
//                         task.blocker?.resolverUser?._id === r._id.toString()
//                         ? "opacity-100"
//                         : "opacity-0"
//                     )}
//                   />
//                 </CommandItem>
//               ))}
//           </CommandGroup>
//         </Command>
//       </PopoverContent>
//     </Popover>
//   );
// }

// function ResolverName({
//   resolverId,
//   taskers,
// }: {
//   resolverId: string;
//   taskers: TaskerData[];
// }) {
//   const resolver = taskers.find(
//     (t) => t.userType === "fullUser" && t._id.toString() === resolverId
//   );
//   if (resolver) {
//     return (
//       <span className="truncate text-red-500">
//         {resolver.userType === "fullUser" ? resolver.name : resolver.email}
//       </span>
//     );
//   } else {
//     return <></>;
//   }
// }
// of the format { hour: 0, minute: 0, label: "12:00am" }
const timeOptions = [
  { hour: 0, minute: 0, label: "12:00am" },
  { hour: 0, minute: 30, label: "12:30am" },
  { hour: 1, minute: 0, label: "01:00am" },
  { hour: 1, minute: 30, label: "01:30am" },
  { hour: 2, minute: 0, label: "02:00am" },
  { hour: 2, minute: 30, label: "02:30am" },
  { hour: 3, minute: 0, label: "03:00am" },
  { hour: 3, minute: 30, label: "03:30am" },
  { hour: 4, minute: 0, label: "04:00am" },
  { hour: 4, minute: 30, label: "04:30am" },
  { hour: 5, minute: 0, label: "05:00am" },
  { hour: 5, minute: 30, label: "05:30am" },
  { hour: 6, minute: 0, label: "06:00am" },
  { hour: 6, minute: 30, label: "06:30am" },
  { hour: 7, minute: 0, label: "07:00am" },
  { hour: 7, minute: 30, label: "07:30am" },
  { hour: 8, minute: 0, label: "08:00am" },
  { hour: 8, minute: 30, label: "08:30am" },
  { hour: 9, minute: 0, label: "09:00am" },
  { hour: 9, minute: 30, label: "09:30am" },
  { hour: 10, minute: 0, label: "10:00am" },
  { hour: 10, minute: 30, label: "10:30am" },
  { hour: 11, minute: 0, label: "11:00am" },
  { hour: 11, minute: 30, label: "11:30am" },
  { hour: 12, minute: 0, label: "12:00pm" },
  { hour: 12, minute: 30, label: "12:30pm" },
  { hour: 13, minute: 0, label: "01:00pm" },
  { hour: 13, minute: 30, label: "01:30pm" },
  { hour: 14, minute: 0, label: "02:00pm" },
  { hour: 14, minute: 30, label: "02:30pm" },
  { hour: 15, minute: 0, label: "03:00pm" },
  { hour: 15, minute: 30, label: "03:30pm" },
  { hour: 16, minute: 0, label: "04:00pm" },
  { hour: 16, minute: 30, label: "04:30pm" },
  { hour: 17, minute: 0, label: "05:00pm" },
  { hour: 17, minute: 30, label: "05:30pm" },
  { hour: 18, minute: 0, label: "06:00pm" },
  { hour: 18, minute: 30, label: "06:30pm" },
  { hour: 19, minute: 0, label: "07:00pm" },
  { hour: 19, minute: 30, label: "07:30pm" },
  { hour: 20, minute: 0, label: "08:00pm" },
  { hour: 20, minute: 30, label: "08:30pm" },
  { hour: 21, minute: 0, label: "09:00pm" },
  { hour: 21, minute: 30, label: "09:30pm" },
  { hour: 22, minute: 0, label: "10:00pm" },
  { hour: 22, minute: 30, label: "10:30pm" },
  { hour: 23, minute: 0, label: "11:00pm" },
  { hour: 23, minute: 30, label: "11:30pm" },
];

export function TimeSelect({ task }: { task: TaskData }) {
  const updateTaskMutation = useUpdateTask();
  if (!task.dueDate) {
    return null;
  }
  return (
    <Select
      value={`${new Date(task.dueDate).getHours()} ${new Date(
        task.dueDate
      ).getMinutes()}`}
      onValueChange={(value) => {
        const values = value.split(" ");
        const hour = parseInt(values[0]);
        const minute = parseInt(values[1]);
        if (task._id && task.dueDate) {
          updateTaskMutation.mutate({
            update: {
              _id: task._id,
              dueDate: new Date(
                new Date(task.dueDate).setHours(hour, minute)
              ).toISOString(),
            },
            metadata: {
              phaseId: task?.phase?._id,
              journeyId: task?.journey?._id,
            },
          });
        }
      }}
    >
      <SelectTrigger className={`h-7 w-fit gap-2 bg-white px-2`}>
        <div className="inline-flex items-center gap-2">
          <ClockIcon className="h-4 w-4 text-gray-700" />
          <SelectValue>
            <span className="text-xs">
              {new Date(task.dueDate).toLocaleTimeString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
            </span>
          </SelectValue>
        </div>
      </SelectTrigger>
      <SelectContent className="w-fit min-w-0">
        <SelectGroup>
          {/* <SelectItem value={"0 hour"}>Not sure</SelectItem> */}
          {timeOptions.map((time) => (
            <SelectItem
              className="pr-2"
              key={time.label}
              value={time.hour + " " + time.minute}
            >
              <div className="inline-flex items-center">
                <span className="w-4 text-right">
                  {/* show 1 as 01 */}
                  {time.hour === 0
                    ? "12"
                    : time.hour > 12
                    ? time.hour - 12
                    : time.hour}
                </span>
                <span>:</span>
                <span className="w-5 text-left">
                  {time.minute === 0 ? "00" : `${time.minute}`}
                </span>
                <span className="text-xs">{time.hour < 12 ? "AM" : "PM"}</span>
              </div>
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

function DateSelect({ task }: { task: TaskData }) {
  const [isDueDateChangeReasonModalOpen, setIsDueDateChangeReasonModalOpen] =
    useState(false);
  const [dueDateChangeReason, setDueDateChangeReason] = useState("");
  const [newDueDate, setNewDueDate] = useState<Date | undefined>(undefined);
  const updateTaskMutation = useUpdateTask();
  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={"secondary"}
            className={cn(
              "justify-start text-left font-normal",
              !task.dueDate && "text-muted-foreground"
            )}
            disabled={task.status === "Completed" || task.status === "Canceled"}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {task.dueDate ? (
              dayjs(task.dueDate).format("DD MMM hh:mm A")
            ) : (
              <span>Pick a date</span>
            )}
            <CaretSortIcon className="ml-2 h-4 w-4 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <>
            <Calendar
              defaultMonth={task.dueDate ? new Date(task.dueDate) : new Date()}
              disabled={{
                before: new Date(),
              }}
              mode="single"
              selected={task.dueDate ? new Date(task.dueDate) : undefined}
              onSelect={(date) => {
                if (date && task.dueDate) {
                  // Extract date parts from the selected date
                  let year = date.getFullYear();
                  let month = date.getMonth(); // Note: months are 0-indexed in JavaScript
                  let day = date.getDate();

                  // Extract time parts from the old date
                  let hours = new Date(task.dueDate).getHours();
                  let minutes = new Date(task.dueDate).getMinutes();
                  let seconds = new Date(task.dueDate).getSeconds();
                  let milliseconds = new Date(task.dueDate).getMilliseconds();

                  // Create new date with selected date and time from old date
                  let combinedDate = new Date(
                    year,
                    month,
                    day,
                    hours,
                    minutes,
                    seconds,
                    milliseconds
                  );

                  setNewDueDate(combinedDate);
                  setIsDueDateChangeReasonModalOpen(true);
                }
              }}
              initialFocus
            />
            <div className="border-t p-4">
              <TimeSelect task={task} />
            </div>
          </>
        </PopoverContent>
      </Popover>
      <Dialog
        open={isDueDateChangeReasonModalOpen}
        onOpenChange={(open) => {
          setIsDueDateChangeReasonModalOpen(open);
        }}
      >
        <DialogContent>
          <div>
            <DialogHeader>
              <>
                <DialogTitle>Reason for Due Date change</DialogTitle>
                <DialogDescription>
                  Help others stay up to date with changed timelines
                </DialogDescription>
              </>
            </DialogHeader>
            <div className="space-y-6 py-6">
              <div className="text-sm text-gray-500">
                <span>{"Changing from "}</span>
                <span className="font-medium text-gray-700">
                  {dayjs(task.dueDate).format("ddd, MMM DD")}
                </span>
                <span>{" to "}</span>
                <span className="font-medium text-gray-700">
                  {dayjs(newDueDate).format("ddd, MMM DD")}
                </span>
              </div>
              <TextArea
                label="Reason"
                name="reason"
                value={dueDateChangeReason}
                onChange={(e) => {
                  setDueDateChangeReason(e.target.value);
                }}
                required={true}
              />
            </div>
            <DialogFooter>
              <DialogClose className="space-x-3">
                <Button
                  variant={"secondary"}
                  onClick={() => {
                    setIsDueDateChangeReasonModalOpen(false);
                  }}
                  // disabled={dueDateChangeReason.trim() === ""}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    if (!task._id) return;
                    if (newDueDate)
                      updateTaskMutation.mutate({
                        update: {
                          _id: task._id,
                          dueDate: newDueDate.toISOString(),
                          // dueDateChangeReason: dueDateChangeReason,
                        },
                        metadata: {
                          phaseId: task?.phase?._id,
                          journeyId: task?.journey?._id,
                        },
                      });
                    setIsDueDateChangeReasonModalOpen(false);
                  }}
                  disabled={dueDateChangeReason.trim() === ""}
                >
                  Confirm Change
                </Button>
              </DialogClose>
            </DialogFooter>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

function CompletionDateSelect({ task }: { task: TaskData }) {
  // const [isDueDateChangeReasonModalOpen, setIsDueDateChangeReasonModalOpen] =
  //   useState(false);
  // const [dueDateChangeReason, setDueDateChangeReason] = useState("");
  const [newDueDate, setNewDueDate] = useState<Date | undefined>(undefined);
  const updateTaskMutation = useUpdateTask();
  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={"secondary"}
            className={cn(
              "justify-start py-0 text-left font-normal",
              !task.dueDate && "text-muted-foreground"
            )}
          >
            <div className="mr-2 flex h-full items-center border-r pr-2">
              Completion Date
            </div>
            {/* <CheckCircleIcon className="mr-2 h-4 w-4" /> */}
            {task.assignedCompletionDate ? (
              dayjs(task.assignedCompletionDate).format("DD MMM hh:mm A")
            ) : task.completionDate ? (
              dayjs(task.completionDate).format("DD MMM hh:mm A")
            ) : (
              <span>No completion date</span>
            )}
            <CaretSortIcon className="ml-2 h-4 w-4 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <>
            <Calendar
              defaultMonth={
                task.assignedCompletionDate
                  ? new Date(task.assignedCompletionDate)
                  : task.completionDate
                  ? new Date(task.completionDate)
                  : new Date()
              }
              mode="single"
              selected={
                task.assignedCompletionDate
                  ? new Date(task.assignedCompletionDate)
                  : task.completionDate
                  ? new Date(task.completionDate)
                  : undefined
              }
              onSelect={(date) => {
                if (date && task.dueDate && task._id) {
                  // Extract date parts from the selected date
                  let year = date.getFullYear();
                  let month = date.getMonth(); // Note: months are 0-indexed in JavaScript
                  let day = date.getDate();

                  // Extract time parts from the old date
                  let hours = new Date(task.dueDate).getHours();
                  let minutes = new Date(task.dueDate).getMinutes();
                  let seconds = new Date(task.dueDate).getSeconds();
                  let milliseconds = new Date(task.dueDate).getMilliseconds();

                  // Create new date with selected date and time from old date
                  let combinedDate = new Date(
                    year,
                    month,
                    day,
                    hours,
                    minutes,
                    seconds,
                    milliseconds
                  );

                  updateTaskMutation.mutate({
                    update: {
                      _id: task._id,
                      assignedCompletionDate: combinedDate.toISOString(),
                      // dueDateChangeReason: dueDateChangeReason,
                    },
                    metadata: {
                      phaseId: task?.phase?._id,
                      journeyId: task?.journey?._id,
                    },
                  });

                  // setNewDueDate(combinedDate);
                  // setIsDueDateChangeReasonModalOpen(true);
                }
              }}
              initialFocus
            />
            {/* <div className="border-t p-4">
              <TimeSelect task={task} />
            </div> */}
          </>
        </PopoverContent>
      </Popover>
      {/* <Dialog
        open={isDueDateChangeReasonModalOpen}
        onOpenChange={(open) => {
          setIsDueDateChangeReasonModalOpen(open);
        }}
      >
        <DialogContent>
          <div>
            <DialogHeader>
              <>
                <DialogTitle>Reason for Due Date change</DialogTitle>
                <DialogDescription>
                  Help others stay up to date with changed timelines
                </DialogDescription>
              </>
            </DialogHeader>
            <div className="space-y-6 py-6">
              <div className="text-sm text-gray-500">
                <span>{"Changing from "}</span>
                <span className="font-medium text-gray-700">
                  {dayjs(task.dueDate).format("ddd, MMM DD")}
                </span>
                <span>{" to "}</span>
                <span className="font-medium text-gray-700">
                  {dayjs(newDueDate).format("ddd, MMM DD")}
                </span>
              </div>
              <TextArea
                label="Reason"
                name="reason"
                value={dueDateChangeReason}
                onChange={(e) => {
                  setDueDateChangeReason(e.target.value);
                }}
                required={true}
              />
            </div>
            <DialogFooter>
              <DialogClose className="space-x-3">
                <Button
                  variant={"secondary"}
                  onClick={() => {
                    setIsDueDateChangeReasonModalOpen(false);
                  }}
                  // disabled={dueDateChangeReason.trim() === ""}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    if (!task._id) return;
                    if (newDueDate)
                      updateTaskMutation.mutate({
                        update: {
                          _id: task._id,
                          dueDate: newDueDate.toISOString(),
                          // dueDateChangeReason: dueDateChangeReason,
                        },
                        metadata: {
                          phaseId: task?.phase?._id,
                          journeyId: task?.journey?._id,
                        },
                      });
                    setIsDueDateChangeReasonModalOpen(false);
                  }}
                  disabled={dueDateChangeReason.trim() === ""}
                >
                  Confirm Change
                </Button>
              </DialogClose>
            </DialogFooter>
          </div>
        </DialogContent>
      </Dialog> */}
    </>
  );
}

function TaskerCombobox({
  task,
  disabled,
}: {
  task: TaskData;
  disabled: boolean;
}) {
  return (
    <>
      {!!task.phase?._id ? (
        <ProjectTaskerCombobox
          task={task}
          phaseId={task.phase._id}
          disabled={disabled}
        />
      ) : (
        <InternalTasker task={task} disabled={disabled} />
      )}
    </>
  );
}

function ProjectTaskerCombobox({
  task,
  phaseId,
  disabled,
}: {
  task: TaskData;
  phaseId: string;
  disabled: boolean;
}) {
  const { data: projectMembers } = useGetProjectMembers(phaseId);
  const [open, setOpen] = useState(false);
  const updateTaskMutation = useUpdateTask();
  if (!task._id) {
    return null;
  }
  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild disabled={disabled}>
          <Button
            variant="secondary"
            role="combobox"
            aria-expanded={open}
            className="max-w-[200px] space-x-2 truncate px-2"
          >
            <UserIcon className="h-4 w-4 shrink-0" />

            {task.tasker?.userType === "fullUser" ||
            task.tasker?.userType === "invitedUser" ? (
              <>
                {/* {task.phase === "sharedSpace" && (
                <div
                  className={clsx(
                    "h-1.5 w-1.5 shrink-0 rounded-full bg-gray-300",
                    task.taskerType === "internal"
                      ? "bg-gray-300"
                      : "bg-slate-700"
                  )}
                />
              )} */}
                <div className="truncate">
                  {task.tasker.userType === "fullUser"
                    ? task.tasker.name
                    : task.tasker.email}
                </div>
              </>
            ) : (
              <div className="text-red-600">Unassigned</div>
            )}
            <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
          align="start"
        >
          <Command>
            <CommandInput placeholder="Search tasker..." className="h-9" />
            <CommandEmpty>No tasker found.</CommandEmpty>
            <CommandGroup>
              {projectMembers?.internal.map((internalTasker) => (
                <CommandItem
                  key={internalTasker.email}
                  value={internalTasker.name}
                  onSelect={() => {
                    if (!task._id) return;
                    updateTaskMutation.mutate({
                      update: {
                        _id: task._id,
                        taskerType: "internal",
                        taskerId: internalTasker._id,
                        tasker: {
                          _id: internalTasker._id,
                          name: internalTasker.name,
                          email: internalTasker.email,
                          userType: "fullUser",
                        },
                      },
                      metadata: {
                        journeyId: task?.journey?._id,
                        phaseId: task?.phase?._id,
                      },
                    });
                    setOpen(false);
                  }}
                  className="gap-2"
                >
                  <div className="flex items-center space-x-2">
                    {/* <div
                  className={"h-2 w-2 shrink-0 rounded-full bg-slate-700"}
                /> */}

                    <div
                      className={clsx(
                        "h-2 w-2 shrink-0 rounded-full bg-gray-300"
                      )}
                    />
                    <span className="truncate">{internalTasker.name}</span>
                  </div>
                  <CheckIcon
                    className={cn(
                      "ml-auto h-4 w-4 shrink-0",
                      task.tasker.userType === "fullUser" &&
                        internalTasker._id.toString() ===
                          task.tasker._id.toString()
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                </CommandItem>
              ))}
              {projectMembers?.external.map((externalTasker) => (
                <CommandItem
                  key={externalTasker.email}
                  value={
                    externalTasker.userType === "fullUser"
                      ? externalTasker.name
                      : externalTasker.email
                  }
                  onSelect={() => {
                    if (externalTasker.userType === "fullUser") {
                      if (!task._id) return;
                      updateTaskMutation.mutate({
                        update: {
                          _id: task._id,
                          taskerType: "external",
                          taskerId: externalTasker._id,
                          tasker: {
                            _id: externalTasker._id,
                            name: externalTasker.name,
                            email: externalTasker.email,
                            userType: externalTasker.userType,
                          },
                          isVisible: true,
                        },
                        metadata: {
                          journeyId: task?.journey?._id,
                          phaseId: task?.phase?._id,
                        },
                      });
                    } else if (externalTasker.userType === "invitedUser") {
                      if (!task._id) return;
                      updateTaskMutation.mutate({
                        update: {
                          _id: task._id,
                          taskerType: "external",
                          taskerEmail: externalTasker.email,
                          isVisible: true,
                        },
                        metadata: {
                          journeyId: task?.journey?._id,
                          phaseId: task?.phase?._id,
                        },
                      });
                    }
                    setOpen(false);
                  }}
                  className="gap-2"
                >
                  <div className="flex items-center space-x-2">
                    <div
                      className={clsx(
                        "h-2 w-2 shrink-0 rounded-full bg-slate-700"
                      )}
                    />
                    <span className="truncate">
                      {externalTasker.userType === "fullUser"
                        ? externalTasker.name
                        : externalTasker.email}
                    </span>
                  </div>
                  <CheckIcon
                    className={cn(
                      "ml-auto h-4 w-4 shrink-0",
                      (task.tasker?.userType === "fullUser" &&
                        externalTasker.userType === "fullUser" &&
                        externalTasker._id.toString() ===
                          task.tasker._id.toString()) ||
                        (task.tasker?.userType === "invitedUser" &&
                          externalTasker.userType === "invitedUser" &&
                          externalTasker.email === task.tasker.email)
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    </>
  );
}

function InternalTasker({
  task,
  disabled,
}: {
  task: TaskData;
  disabled: boolean;
}) {
  const app = useRealmApp();
  const { data: instanceMembers } = useGetTaskersForInstance();
  const updateTaskMutation = useUpdateTask();
  // const { data: taskers } = useGetTaskersForInstance(
  //   app.currentUser?.customData?.instanceId
  // );
  const [open, setOpen] = useState(false);
  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild disabled={disabled}>
          <Button
            variant="secondary"
            role="combobox"
            aria-expanded={open}
            className="max-w-[200px] space-x-2 truncate px-2"
          >
            <UserIcon className="h-4 w-4 shrink-0" />

            {task.tasker?.userType === "fullUser" ? (
              <>
                {/* {task.phase === "sharedSpace" && (
                <div
                  className={clsx(
                    "h-1.5 w-1.5 shrink-0 rounded-full bg-gray-300",
                    task.taskerType === "internal"
                      ? "bg-gray-300"
                      : "bg-slate-700"
                  )}
                />
                )} */}
                <div className="truncate">{task.tasker.name}</div>
              </>
            ) : (
              <div className="text-red-600">Unassigned</div>
            )}
            <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
          align="start"
        >
          <Command>
            <CommandInput placeholder="Search tasker..." className="h-9" />
            <CommandEmpty>No tasker found.</CommandEmpty>
            <CommandGroup>
              {instanceMembers?.map((internalTasker) => (
                <CommandItem
                  key={internalTasker.email}
                  value={internalTasker.name}
                  onSelect={() => {
                    if (!task._id) return;
                    updateTaskMutation.mutate({
                      update: {
                        _id: task._id,
                        taskerId: internalTasker._id,
                        tasker: {
                          _id: internalTasker._id,
                          name: internalTasker.name,
                          email: internalTasker.email,
                          userType: "fullUser",
                        },
                      },
                      metadata: {
                        journeyId: task?.journey?._id,
                        phaseId: task?.phase?._id,
                      },
                    });
                    setOpen(false);
                  }}
                  className="gap-2"
                >
                  <div className="flex items-center space-x-2">
                    {/* <div
                  className={"h-2 w-2 shrink-0 rounded-full bg-slate-700"}
                /> */}

                    <div
                      className={clsx(
                        "h-2 w-2 shrink-0 rounded-full bg-gray-300"
                      )}
                    />
                    <span className="truncate">{internalTasker.name}</span>
                  </div>
                  <CheckIcon
                    className={cn(
                      "ml-auto h-4 w-4 shrink-0",
                      task.tasker.userType === "fullUser" &&
                        internalTasker._id.toString() ===
                          task.tasker._id.toString()
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    </>
  );
}

// function CommonTaskerCombobox({
//   task,
//   taskers,
//   type,
//   disabled,
// }: {
//   taskers: TaskerData[];
//   type: "instance" | "sharedSpace";
//   task: TaskData;
//   disabled: boolean;
// }) {
//   const updateTaskMutation = useUpdateTask();
//   const [open, setOpen] = useState(false);

//   return (
//     <Popover open={open} onOpenChange={setOpen}>
//       <PopoverTrigger asChild disabled={disabled}>
//         <Button
//           variant="secondary"
//           role="combobox"
//           aria-expanded={open}
//           className="max-w-[200px] space-x-2 truncate px-2"
//         >
//           <UserIcon className="h-4 w-4 shrink-0" />

//           {task.tasker?.userType === "fullUser" ||
//           task.tasker?.userType === "invitedUser" ? (
//             <>
//               {type === "sharedSpace" && (
//                 <div
//                   className={clsx(
//                     "h-1.5 w-1.5 shrink-0 rounded-full bg-gray-300",
//                     task.taskerType === "internal"
//                       ? "bg-gray-300"
//                       : "bg-slate-700"
//                   )}
//                 />
//               )}
//               <div className="truncate">
//                 {task.tasker.userType === "fullUser"
//                   ? task.tasker.name
//                   : task.tasker.email}
//               </div>
//             </>
//           ) : (
//             <div className="text-red-600">Unassigned</div>
//           )}
//           <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
//         </Button>
//       </PopoverTrigger>
//       <PopoverContent
//         className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
//         align="start"
//       >
//         <Command>
//           <CommandInput placeholder="Search tasker..." className="h-9" />
//           <CommandEmpty>No tasker found.</CommandEmpty>
//           <CommandGroup>
//             {taskers.map((tasker) => (
//               <CommandItem
//                 key={tasker.email}
//                 value={
//                   tasker.userType === "fullUser" ? tasker.name : tasker.email
//                 }
//                 onSelect={() => {
//                   if (tasker.userType === "fullUser") {
//                     if (!task._id) return;
//                     updateTaskMutation.mutate({
//                       update: {
//                         _id: task._id,
//                         taskerType: tasker.type,
//                         taskerId: tasker._id,
//                         tasker: {
//                           _id: tasker._id,
//                           name: tasker.name,
//                           email: tasker.email,
//                           userType: tasker.userType,
//                         },
//                         isVisible:
//                           tasker.type === "external" ? true : task.isVisible,
//                       },
//                       metadata: {
//                         journeyId: task?.journey?._id,
//                         phaseId: task?.phase?._id,
//                       },
//                     });
//                   } else if (tasker.userType === "invitedUser") {
//                     if (!task._id) return;
//                     updateTaskMutation.mutate({
//                       update: {
//                         _id: task._id,
//                         taskerType: tasker.type,
//                         taskerEmail: tasker.email,
//                         tasker,
//                         isVisible: true,
//                       },
//                       metadata: {
//                         journeyId: task?.journey?._id,
//                         phaseId: task?.phase?._id,
//                       },
//                     });
//                   }
//                   setOpen(false);
//                 }}
//                 className="gap-2"
//               >
//                 <div className="flex items-center space-x-2">
//                   {/* <div
//                     className={"h-2 w-2 shrink-0 rounded-full bg-slate-700"}
//                   /> */}
//                   {type === "sharedSpace" && (
//                     <div
//                       className={clsx(
//                         "h-2 w-2 shrink-0 rounded-full bg-gray-300",
//                         tasker.userType === "invitedUser"
//                           ? "bg-slate-700"
//                           : tasker.type === "internal"
//                           ? "bg-gray-300"
//                           : "bg-slate-700"
//                       )}
//                     />
//                   )}
//                   <span className="truncate">
//                     {tasker.userType === "fullUser"
//                       ? tasker.name
//                       : tasker.email}
//                   </span>
//                 </div>
//                 <CheckIcon
//                   className={cn(
//                     "ml-auto h-4 w-4 shrink-0",
//                     task.tasker?.userType === "fullUser" &&
//                       tasker.userType === "fullUser" &&
//                       tasker._id.toString() === task.tasker._id.toString()
//                       ? "opacity-100"
//                       : "opacity-0"
//                   )}
//                 />
//               </CommandItem>
//             ))}
//           </CommandGroup>
//         </Command>
//       </PopoverContent>
//     </Popover>
//   );
// }

function VisibilityButtons({ task }: { task: TaskData }) {
  const updateTaskMutation = useUpdateTask();
  return (
    <div className="inline-flex h-8 items-center gap-1 rounded-md border border-gray-300 px-1 shadow-sm">
      <TooltipProvider>
        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>
            <button
              className={clsx(
                "rounded p-1 outline-none focus-visible:ring-[1.25px] focus-visible:ring-primary",
                task.isVisible
                  ? "bg-primary-100 text-primary-600"
                  : "text-gray-500 hover:bg-gray-100"
              )}
              onClick={() => {
                if (!task._id) return;
                updateTaskMutation.mutate({
                  update: {
                    _id: task._id,
                    isVisible: true,
                  },
                  metadata: {
                    phaseId: task?.phase?._id,
                    journeyId: task?.journey?._id,
                  },
                });
              }}
            >
              <EyeIcon className="h-4 w-4" />
            </button>
          </TooltipTrigger>
          <TooltipContent sideOffset={8}>
            <p>Externally visible</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <TooltipProvider>
        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>
            <button
              className={clsx(
                "rounded p-1 outline-none focus-visible:ring-[1.25px] focus-visible:ring-primary",
                !task.isVisible
                  ? "bg-primary-100 text-primary-600"
                  : "text-gray-500 hover:bg-gray-100",
                task.taskerType == "external" &&
                  "bg-gray-100 text-gray-300 hover:bg-gray-100"
              )}
              onClick={() => {
                if (!task._id) return;
                updateTaskMutation.mutate({
                  update: {
                    _id: task._id,
                    isVisible: false,
                  },
                  metadata: {
                    phaseId: task?.phase?._id,
                    journeyId: task?.journey?._id,
                  },
                });
              }}
              disabled={task.taskerType == "external"}
            >
              <EyeSlashIcon
                className={`h-4 w-4 ${
                  task.taskerType === "external" ? "text-gray-300" : ""
                }`}
              />
            </button>
          </TooltipTrigger>
          <TooltipContent sideOffset={8}>
            <p>Externally hidden</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
}
