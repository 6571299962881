import { RectangleStackIcon, UserIcon } from "@heroicons/react/24/outline";
import * as Slider from "@radix-ui/react-slider";
import {
  BarChart,
  Card,
  DonutChart,
  EventProps,
  Legend,
  ScatterChart,
  Subtitle,
  Text,
  Title,
} from "@tremor/react";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ProjectPerformanceData,
  useGetProjectPerformance,
  useGetStageDistributionForProjectTemplate,
} from "src/api/Analytics/project";
import { useGetOnboardingProjects } from "src/api/Services/Projects/projects";
import { useGetJourneyTemplates } from "src/api/Services/Tasks/journeys";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { PageHeading } from "src/components/ui/Headings/PageHeading";
import { Breadcrumbs } from "src/components/ui/Navigation/Breadcrumbs";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "src/components/ui/dialog";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import {
  Select as NewSelect,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import {
  ProjectPerformanceContextProvider,
  useProjectPerformanceContext,
} from "./ProjectPerformanceContextProvider";

export default function ProjectsPerformance() {
  return (
    <div className="h-full overflow-auto px-8 py-6">
      <div className="flex items-start justify-between">
        <div>
          <Breadcrumbs
            breadcrumbs={[
              { title: "Analytics", route: "/analytics" },
              {
                title: "Project Performance",
              },
            ]}
          />
          <div className="items-start justify-between space-y-2 sm:flex sm:space-y-0">
            <PageHeading heading={"Project Performance"} />
          </div>
        </div>
      </div>
      <ProjectPerformanceContextProvider>
        <PerformanceFiltersAndCharts />
      </ProjectPerformanceContextProvider>
    </div>
  );
}

function PerformanceFiltersAndCharts() {
  const projectPerformanceCtx = useProjectPerformanceContext();
  const { data: projectPerformance } = useGetProjectPerformance();
  const filteredProjectPerformance =
    projectPerformance
      ?.filter((p) => !!p)
      .filter((p) => {
        if (
          projectPerformanceCtx.state.revenueRange.currentMin ===
            projectPerformanceCtx.state.revenueRange.min &&
          projectPerformanceCtx.state.revenueRange.currentMax ===
            projectPerformanceCtx.state.revenueRange.max
        ) {
          return true;
        } else {
          return (
            (p.revenue ?? 0) >=
              projectPerformanceCtx.state.revenueRange.currentMin &&
            (p.revenue ?? 0) <=
              projectPerformanceCtx.state.revenueRange.currentMax
          );
        }
      })
      .filter((p) => {
        if (projectPerformanceCtx.state.template === "all") {
          return true;
        } else {
          return (
            p.template?._id?.toString() === projectPerformanceCtx.state.template
          );
        }
      })
      .filter((p) => {
        if (projectPerformanceCtx.state.owner === "all") {
          return true;
        } else {
          return (
            p.ownerUser?._id?.toString() === projectPerformanceCtx.state.owner
          );
        }
      }) ?? [];
  useEffect(() => {
    if (!!projectPerformance) {
      const maxRevenue = Math.max(
        ...projectPerformance.filter((p) => !!p).map((p) => p.revenue ?? 0)
      );
      const minRevenue = Math.min(
        ...projectPerformance.filter((p) => !!p).map((p) => p.revenue ?? 0)
      );
      projectPerformanceCtx.dispatch({
        type: "revenue_range_update",
        revenueRange: {
          min: minRevenue,
          max: maxRevenue,
          currentMax: maxRevenue,
          currentMin: minRevenue,
        },
      });
    }
  }, [projectPerformance]);

  return (
    <>
      {!!projectPerformance ? (
        <div className="space-y-6 pt-6">
          <div className="flex gap-3">
            <TemplateFilter />
            <OwnerFilter />
            <RevenueSlider />
          </div>
          <div className="flex flex-wrap items-start gap-6">
            {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"> */}
            <NumberAndRevenue projectPerformance={filteredProjectPerformance} />
            <AheadBehindSchedule
              projectPerformance={filteredProjectPerformance}
            />
            <DistributionByTasker
              projectPerformance={filteredProjectPerformance}
            />
            <RevenueVsDelay projectPerformance={filteredProjectPerformance} />
            {projectPerformanceCtx.state.template !== "all" && (
              <Pipeline
                projectTemplateId={projectPerformanceCtx.state.template}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="flex h-full items-center justify-center">
          <Loading />
        </div>
      )}
    </>
  );
}

type StageDistributionData = {
  name: string;
  projects: { _id: string; name: string; customer: string; revenue?: number }[];
};

type StageDistributionChartType = "Count" | "Revenue";

function Pipeline({ projectTemplateId }: { projectTemplateId: string }) {
  const { data: stageDistribution } =
    useGetStageDistributionForProjectTemplate(projectTemplateId);
  const [selectedStage, setSelectedStage] =
    useState<StageDistributionData | null>(null);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<StageDistributionChartType>("Count");
  const valueFormatter = (number: number) =>
    `${type === "Count" ? "" : "$"}${new Intl.NumberFormat("us")
      .format(number)
      .toString()}`;
  const chartdata =
    stageDistribution?.map((s) => {
      return {
        name: s.name,
        "Project Count": s.projects.length,
        projects: s.projects,
        Revenue: s.projects.reduce((acc, p) => acc + (p.revenue ?? 0), 0),
      };
    }) ?? [];
  const navigate = useNavigate();
  return (
    <Card className={clsx("h-fit w-[800px]")}>
      <div className="flex justify-between gap-4">
        <div>
          <Title>Stage Distribution</Title>
          <Text>
            Understand the distribution of your pipeline across different
            stages.
          </Text>
        </div>
        <StageDistributionChartTypeSelect type={type} setType={setType} />
      </div>
      <BarChart
        className={clsx("mt-6", `h-[260px]`)}
        data={chartdata}
        index="name"
        categories={type === "Count" ? ["Project Count"] : ["Revenue"]}
        colors={["blue"]}
        valueFormatter={valueFormatter}
        yAxisWidth={140}
        layout="vertical"
        onValueChange={(v) => {
          const stage = v as StageDistributionData & EventProps;
          if (stage.name) {
            setSelectedStage(stage);
            setOpen(true);
          }
        }}
      />
      <Dialog
        open={open}
        onOpenChange={(open) => {
          setOpen(open);
        }}
      >
        <DialogContent
          className={clsx(
            "top-[20%] w-full max-w-4xl translate-y-0 space-y-0 p-0 data-[state=open]:slide-in-from-top-8"
          )}
        >
          <DialogHeader className="p-6 pb-0">
            <DialogTitle>{selectedStage?.name}</DialogTitle>
          </DialogHeader>
          <div className="pb-5">
            <div className="flex justify-between gap-3 border-b px-6 pb-2">
              <InputHeading heading="Project" />
              <div className="flex gap-3">
                <div className="w-[200px]">
                  <InputHeading heading="Customer" />
                </div>
                <div className="w-[100px]">
                  <InputHeading heading="Revenue" />
                </div>
              </div>
            </div>

            {selectedStage?.projects.map((p) => (
              <div
                className="flex cursor-pointer items-center justify-between gap-3 py-2 px-6 hover:bg-gray-50"
                key={p._id}
                onClick={() => {
                  navigate(`/projects/${p._id}`);
                }}
              >
                <div className="w-[500px] truncate text-sm text-gray-700">
                  {p.name}
                </div>
                <div className="flex items-center gap-3">
                  <div className="w-[200px] truncate text-sm text-gray-500">
                    {p.customer}
                  </div>
                  <div className="w-[100px] text-sm text-gray-500">
                    ${p.revenue?.toLocaleString() ?? 0}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </Card>
  );
}

function StageDistributionChartTypeSelect({
  type,
  setType,
}: {
  type: StageDistributionChartType;
  setType: (type: StageDistributionChartType) => void;
}) {
  return (
    <NewSelect
      value={type}
      onValueChange={(value) => {
        setType(value as StageDistributionChartType);
      }}
    >
      <SelectTrigger
        className={`mt-0 h-7 w-fit max-w-full gap-2 bg-white px-2`}
      >
        {/* <PieChartIcon className="h-4 w-4 shrink-0 text-gray-700" /> */}
        <SelectValue className="truncate text-xs">
          {type === "Count" ? (
            <div className="flex items-center space-x-1">
              <div className="px-1">#</div>
              <div>Count</div>
            </div>
          ) : (
            <div className="flex items-center space-x-1">
              <div className="px-1">$</div>
              <div>Revenue</div>
            </div>
          )}
        </SelectValue>
      </SelectTrigger>
      <SelectContent align="end" className="min-w-[120px]">
        <SelectGroup>
          <SelectItem value={"Count"}>
            <div className="flex items-center space-x-1">
              <div className="px-1">#</div>
              <div>Count</div>
            </div>
          </SelectItem>
          <SelectItem value={"Revenue"}>
            <div className="flex items-center space-x-1">
              <div className="px-1">$</div>
              <div>Revenue</div>
            </div>
          </SelectItem>
        </SelectGroup>
      </SelectContent>
    </NewSelect>
  );
}

function NumberAndRevenue({
  projectPerformance,
}: {
  projectPerformance: ProjectPerformanceData[];
}) {
  const projectPerformanceCtx = useProjectPerformanceContext();
  const revenue =
    projectPerformance.reduce((acc, p) => {
      return acc + (p.revenue ?? 0);
    }, 0) ?? 0;
  return (
    <div className="flex w-[288px] flex-col gap-6">
      <Card className="h-1/2 w-full py-4">
        <div className="space-y-2">
          <div className="text-lg font-medium">Projects</div>
          <div className="text-5xl font-medium">
            {projectPerformance.length}
          </div>
        </div>
      </Card>
      <Card className="h-1/2 w-full py-4">
        <div className="space-y-2">
          <div className="text-lg font-medium">Revenue</div>
          <div className="text-5xl font-medium">
            ${revenue.toLocaleString()}
          </div>
        </div>
      </Card>
    </div>
  );
}

function RevenueSlider() {
  const projectPerformanceCtx = useProjectPerformanceContext();
  const [minValue, setMinValue] = useState(
    projectPerformanceCtx.state.revenueRange.currentMin
  );
  const [maxValue, setMaxValue] = useState(
    projectPerformanceCtx.state.revenueRange.currentMax
  );
  useEffect(() => {
    setMinValue(projectPerformanceCtx.state.revenueRange.currentMin);
  }, [projectPerformanceCtx.state.revenueRange.currentMin]);
  useEffect(() => {
    setMaxValue(projectPerformanceCtx.state.revenueRange.currentMax);
  }, [projectPerformanceCtx.state.revenueRange.currentMax]);
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="secondary" className="space-x-2 divide-x py-0">
          <div className="inline-flex h-full items-center space-x-2">
            <div className="text-lg font-light">$</div>
            <div>Revenue</div>
          </div>
          <div className="flex h-full items-center space-x-2 pl-2 text-sm font-normal">
            <div
              className={clsx(
                projectPerformanceCtx.state.revenueRange.min !==
                  projectPerformanceCtx.state.revenueRange.currentMin
                  ? "text-primary"
                  : "text-gray-500",
                "text-sm"
              )}
            >
              ${projectPerformanceCtx.state.revenueRange.currentMin / 1000}K
            </div>
            <div>-</div>
            <div
              className={clsx(
                projectPerformanceCtx.state.revenueRange.max !==
                  projectPerformanceCtx.state.revenueRange.currentMax
                  ? "text-primary"
                  : "text-gray-500",
                "text-sm"
              )}
            >
              ${projectPerformanceCtx.state.revenueRange.currentMax / 1000}K
            </div>
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex w-fit flex-col items-center space-y-2">
        <div className="text-sm text-gray-500">
          {/* <span>Current Range: </span> */}
          <span className="text-primary">
            ${minValue / 1000}K - ${maxValue / 1000}K
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-sm text-gray-500">
            ${projectPerformanceCtx.state.revenueRange.min / 1000}K
          </span>
          <Slider.Root
            min={projectPerformanceCtx.state.revenueRange.min}
            max={projectPerformanceCtx.state.revenueRange.max}
            className="relative flex h-5 w-40 touch-none select-none items-center"
            value={[minValue, maxValue]}
            onValueChange={(v) => {
              setMinValue(v[0]);
              setMaxValue(v[1]);
            }}
            onValueCommit={(v) => {
              projectPerformanceCtx.dispatch({
                type: "revenue_range_update",
                revenueRange: {
                  ...projectPerformanceCtx.state.revenueRange,
                  currentMin: v[0],
                  currentMax: v[1],
                },
              });
            }}
            step={projectPerformanceCtx.state.revenueRange.max / 10}
          >
            <Slider.Track className="relative h-[3px] grow rounded-full bg-gray-200">
              <Slider.Range className="absolute h-full rounded-full bg-primary" />
            </Slider.Track>
            <Slider.Thumb className="block h-4 w-4 rounded-full border border-primary/50 bg-background shadow transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50" />
            <Slider.Thumb className="block h-4 w-4 rounded-full border border-primary/50 bg-background shadow transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50" />
          </Slider.Root>
          <span className="text-sm text-gray-500">
            {projectPerformanceCtx.state.revenueRange.max / 1000}K
          </span>
        </div>
      </PopoverContent>
    </Popover>
  );
}

function RevenueVsDelay({
  projectPerformance,
}: {
  projectPerformance: ProjectPerformanceData[];
}) {
  // console.log(projectPerformance)
  const chartdata = projectPerformance.map((p) => {
    const totalTasks =
      p.allTasksBreakdown?.external?.toDo.total +
      p.allTasksBreakdown?.external?.inProgress.total +
      p.allTasksBreakdown?.external?.canceled +
      p.allTasksBreakdown?.external?.completed.late +
      p.allTasksBreakdown?.external?.completed.onTime +
      p.allTasksBreakdown?.internal?.toDo.total +
      p.allTasksBreakdown?.internal?.inProgress.total +
      p.allTasksBreakdown?.internal?.canceled +
      p.allTasksBreakdown?.internal?.completed.late +
      p.allTasksBreakdown?.internal?.completed.onTime;
    const completedTasks =
      p.allTasksBreakdown?.external?.completed.late +
      p.allTasksBreakdown?.external?.completed.onTime +
      p.allTasksBreakdown?.internal?.completed.late +
      p.allTasksBreakdown?.internal?.completed.onTime;
    const progress = Math.round((completedTasks / totalTasks) * 100);
    return {
      Customer: p.customer.name,
      Revenue: p.revenue ?? 0,
      Delay: dayjs(p.keyDates.forecastedCompletionDate).diff(
        p.keyDates.plannedCompletionDate,
        "days"
      ),
      Progress: progress,
      phaseId: p._id,
    };
  });
  const navigate = useNavigate();
  return (
    <Card className="w-full max-w-xl py-4">
      <Title>Delay vs Progress vs Revenue</Title>
      <Text>
        Understand the severity of delays {`(y-axis)`} with reference to
        progress {`(x-axis)`} and potential revenue impact {`(size)`}
      </Text>
      <ScatterChart
        className="mt-6 -ml-2 h-80"
        yAxisWidth={60}
        data={chartdata}
        category="Customer"
        x="Progress"
        y="Delay"
        size="Revenue"
        sizeRange={[100, 700]}
        showOpacity={true}
        minYValue={0}
        onValueChange={(v) => {
          navigate(`/projects/${v?.phaseId}`);
        }}
        valueFormatter={{
          x: (progress) => `${progress.toFixed(0)}%`,
          y: (days) => `${days} days`,
          size: (amount) => `$${(amount / 1000).toFixed(1)}K`,
        }}
        enableLegendSlider
      />
    </Card>
  );
}

function AheadBehindSchedule({
  projectPerformance,
}: {
  projectPerformance: ProjectPerformanceData[];
}) {
  const chartdata = [
    {
      name: "Projects",
      "Behind Schedule": projectPerformance
        .map(
          (p) =>
            new Date(p.keyDates.forecastedCompletionDate).getTime() -
            new Date(p.keyDates.plannedCompletionDate).getTime()
        )
        .filter((p) => p > 0).length,
      "On Time": projectPerformance
        .map(
          (p) =>
            new Date(p.keyDates.plannedCompletionDate).getTime() -
            new Date(p.keyDates.forecastedCompletionDate).getTime()
        )
        .filter((p) => p > 0).length,
    },
  ];
  // const valueFormatter = (number: number) =>
  //   `${new Intl.NumberFormat("us").format(number).toString()} projects`;

  return (
    <Card className="w-[288px] py-4">
      <Title>On Time vs Behind</Title>
      <Subtitle>Projects on time vs behind schedule</Subtitle>
      <BarChart
        className="mt-4 h-40"
        data={chartdata}
        index="name"
        allowDecimals={false}
        categories={["On Time", "Behind Schedule"]}
        colors={["green", "red"]}
        showLegend={false}
        // valueFormatter={valueFormatter}
        yAxisWidth={20}
        onValueChange={(v) => {
          if (v?.categoryClicked === "Behind Schedule") {
          } else if (v?.categoryClicked === "On Time") {
          }
        }}
        showTooltip={false}
      />
      <Legend
        className="w-60 flex-wrap justify-center"
        categories={["On Time", "Behind Schedule"]}
        colors={["green", "red"]}
      />
    </Card>
  );
}

function Loading() {
  return (
    <div className="flex h-full w-full items-center justify-center pb-12 pt-6">
      <div className="h-8 w-8 animate-spin rounded-full border-4 border-gray-200 border-t-primary" />
    </div>
  );
}

function DistributionByTasker({
  projectPerformance,
}: {
  projectPerformance: ProjectPerformanceData[];
}) {
  let distribution: {
    _id: string;
    name: string;
    count: number;
    revenue: number;
  }[] = [];
  projectPerformance?.forEach((p) => {
    if (p.ownerUser?._id) {
      const index = distribution.findIndex((d) => d._id === p.ownerUser?._id);
      if (index === -1) {
        distribution.push({
          _id: p.ownerUser?._id,
          name: p.ownerUser?.name ?? "",
          count: 1,
          revenue: p.customer.revenue ?? 0,
        });
      } else {
        distribution[index].count += 1;
        distribution[index].revenue += p.customer.revenue ?? 0;
      }
    }
  });
  return (
    <Card className="flex w-[288px] flex-col justify-between py-4">
      <div>
        <Title>Tasker Distribution</Title>
        <Subtitle>Distribution of projects across taskers</Subtitle>
      </div>

      <div className="space-y-4">
        <DonutChart
          data={distribution}
          category="count"
          index="name"
          // valueFormatter={valueFormatter}
          // colors={["amber", "blue", "green"]}
        />
        <Legend
          className=""
          categories={distribution.map((d) => d.name)}
          enableLegendSlider
          // colors={["amber", "blue", "green", "gray"]}
        />
        {/* <div className="flex justify-center">
        <Legend
          className="w-60 flex-wrap justify-center"
          categories={["To Do", "In Progress", "Completed", "Planned"]}
          colors={["amber", "blue", "green", "gray"]}
        />
      </div> */}
      </div>
    </Card>
  );
}

function TemplateFilter() {
  const { journeyTemplates } = useGetJourneyTemplates();
  const projectTemplateCtx = useProjectPerformanceContext();

  return (
    <NewSelect
      value={projectTemplateCtx.state.template}
      onValueChange={(v) => {
        projectTemplateCtx.dispatch({
          type: "template_update",
          template: v,
        });
      }}
    >
      <SelectTrigger
        className={`h-8 w-fit max-w-full gap-2 bg-white py-0 px-2`}
      >
        <div className="flex h-full items-center gap-2 divide-x">
          <div className="inline-flex items-center space-x-1">
            <RectangleStackIcon className="h-4 w-4" />
            <div>Template</div>
          </div>
          <div className="inline-flex h-full items-center pl-2">
            <SelectValue className="truncate text-xs">
              {projectTemplateCtx.state.template === "all"
                ? "All"
                : journeyTemplates?.find(
                    (t) =>
                      t._id?.toString() === projectTemplateCtx.state.template
                  )?.name}
            </SelectValue>
          </div>
        </div>
      </SelectTrigger>
      <SelectContent className="min-w-[120px]">
        <SelectGroup>
          <SelectItem
            value={"all"}
            onSelect={() => {
              projectTemplateCtx.dispatch({
                type: "template_update",
                template: "all",
              });
            }}
          >
            All
          </SelectItem>
          {journeyTemplates
            ?.sort((t1, t2) => t2.numberOfRuns - t1.numberOfRuns)
            .map((t) => (
              <SelectItem
                key={t._id?.toString()}
                value={t._id?.toString() ?? ""}
                disabled={t.numberOfRuns === 0}
              >
                <div className="inline-flex items-center space-x-2">
                  <span
                    className={
                      "mr-3 rounded-full bg-gray-100 py-0.5 px-2.5 text-xs font-medium text-gray-900 data-[state=active]:bg-primary-100 data-[state=active]:text-primary-600 md:inline-block"
                    }
                  >
                    {t.numberOfRuns}
                  </span>
                  <span>{t.name}</span>
                </div>
              </SelectItem>
            ))}
        </SelectGroup>
      </SelectContent>
    </NewSelect>
  );
}

function OwnerFilter() {
  const { data } = useGetOnboardingProjects({
    status: ["In Progress"],
  });
  const projectTemplateCtx = useProjectPerformanceContext();
  // users
  let users = data?.map((p) => p.ownerUser);
  // only keep unique users
  users = users?.filter(
    (user, index, self) => index === self.findIndex((u) => u?._id === user?._id)
  );
  return (
    <NewSelect
      value={projectTemplateCtx.state.owner}
      onValueChange={(v) => {
        projectTemplateCtx.dispatch({
          type: "owner_update",
          owner: v,
        });
      }}
    >
      <SelectTrigger
        className={clsx(
          `h-8 w-fit max-w-full gap-2  py-0 px-2`,
          projectTemplateCtx.state.owner === "all"
            ? "bg-white"
            : "divide-x-primary border-primary text-primary hover:bg-primary-100"
        )}
      >
        <div
          className={clsx(
            "flex h-full items-center gap-2 divide-x",
            projectTemplateCtx.state.owner === "all" ? "" : "divide-primary/60"
          )}
        >
          <div className="inline-flex items-center space-x-1">
            <UserIcon className="h-4 w-4" />
            <div>Owner</div>
          </div>
          <div className="inline-flex h-full items-center pl-2">
            <SelectValue className="truncate text-xs">
              {projectTemplateCtx.state.owner === "all"
                ? "All"
                : users?.find(
                    (u) => u._id.toString() === projectTemplateCtx.state.owner
                  )?.name}
            </SelectValue>
          </div>
        </div>
      </SelectTrigger>
      <SelectContent className="min-w-[120px]">
        <SelectGroup>
          <SelectItem
            value={"all"}
            onSelect={() => {
              projectTemplateCtx.dispatch({
                type: "owner_update",
                owner: "all",
              });
            }}
          >
            All
          </SelectItem>
          {users?.map((u) => (
            <SelectItem key={u._id?.toString()} value={u._id?.toString() ?? ""}>
              <span>{u.name}</span>
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </NewSelect>
  );
}
