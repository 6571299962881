import { DndContext, DragEndEvent, closestCenter } from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { TrashIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import { DragHandleDots2Icon, PlusIcon } from "@radix-ui/react-icons";
import "@tanstack/react-table";
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ParentStatus, StatusGroupData } from "src/api/General/status-groups";
import {
  InstanceMemberData,
  useGetTaskersForInstance,
} from "src/api/Services/Tasks/tasks";
import { SpaceData, useCreateSpace, useGetSpaces } from "src/api/Spaces/spaces";
import { SpaceSymbol } from "src/components/Layout/Sidebar";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { Button } from "src/components/ui/button";
import { Checkbox } from "src/components/ui/checkbox";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "src/components/ui/command";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { Switch } from "src/components/ui/switch";
import { TextInput } from "src/components/ui/text-input";
import { useRealmApp } from "src/store/RealmApp";
import { v4 as uuidv4 } from "uuid";
import { z } from "zod";
import { fuzzyFilter } from "../Services/Projects/ProjectsTable";

type Props = {};

export default function Spaces({}: Props) {
  const { data: spaces } = useGetSpaces();
  const { data: instanceMembers } = useGetTaskersForInstance();
  return (
    <div className="flex h-full flex-col overflow-y-auto">
      <div className="flex h-12 items-center border-b border-gray-200/70 px-8">
        <div className="cursor-default text-sm text-gray-800">Spaces</div>
      </div>
      <div className="flex h-16 items-center justify-between border-b border-gray-200/70 px-8">
        {/* <Button variant={"secondary"}>Filter</Button> */}
        <div></div>
        {spaces && instanceMembers && (
          <NewSpaceButton spaces={spaces} instanceMembers={instanceMembers} />
        )}
      </div>
      <div className="flex flex-1 flex-col overflow-y-auto">
        <SpacesTable />
      </div>
    </div>
  );
}

interface SpaceFormValues {
  spaceName: string;
  slug: string;
}

const existingSpaces = [
  { name: "ExistingSpace1", slug: "EXS" },
  { name: "ExistingSpace2", slug: "EX" },
];

type SubStatus = {
  name: string;
  id: string;
  parentStatus: ParentStatus;
};

type MemberData = {
  isAdmin: boolean;
  _id: string;
  name: string;
  email: string;
};

function NewSpaceButton({
  spaces,
  instanceMembers,
}: {
  spaces: SpaceData[];
  instanceMembers: InstanceMemberData[];
}) {
  const spaceSchema = z.object({
    spaceName: z
      .string()
      .min(1, "Name is required")
      .refine((val) => !spaces.map((space) => space.name).includes(val), {
        message: "Another space already has this name",
      }),

    // .regex(/^[a-zA-Z\s]*$/, "Name should only contain letters and spaces"),
    slug: z
      .string()
      .regex(/^[A-Z]*$/, "Identifier should only contain letters")
      .min(1, "Identifier is required")
      .max(4, "Identifier cannot be longer than 4 characters")
      .refine((val) => !spaces.map((space) => space.slug).includes(val), {
        message: "Another space already has this identifier",
      }),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<SpaceFormValues>({
    resolver: zodResolver(spaceSchema),
  });

  const [step, setStep] = useState(0);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [color, setColor] = useState<string>("#6b7280");
  const [isPickingCustomColor, setIsPickingCustomColor] = useState(false);

  const onSubmit = () => {
    setStep(1);
  };

  const isNameUnique = (spaceName: string) => {
    return existingSpaces.some((space) => space.name === spaceName)
      ? false
      : true;
  };

  const isSlugUnique = (slug: string) => {
    return (
      !existingSpaces.some((space) => space.slug === slug) ||
      "Another space already has this identifier"
    );
  };

  const colors = [
    { name: "Purple", color: "#a855f7" },
    { name: "Blue", color: "#3b82f6" },
    { name: "Teal", color: "#14b8a6" },
    { name: "Green", color: "#22c55e" },
    { name: "Yellow", color: "#FFC30A" },
    { name: "Orange", color: "#f97316" },
    { name: "Rose", color: "#f43f5e" },
    { name: "Pink", color: "#ec4899" },
    { name: "Gray", color: "#6b7280" },
  ];

  const [backlogStatuses, setBacklogStatuses] = useState<SubStatus[]>([
    { name: "Backlog", id: uuidv4(), parentStatus: "Backlog" },
  ]);
  const [toDoStatuses, setToDoStatuses] = useState<SubStatus[]>([
    { name: "To-do", id: uuidv4(), parentStatus: "To-do" },
  ]);
  const [inProgressStatuses, setInProgressStatuses] = useState<SubStatus[]>([
    { name: "In Progress", id: uuidv4(), parentStatus: "In Progress" },
  ]);
  const [completedStatuses, setCompletedStatuses] = useState<SubStatus[]>([
    { name: "Completed", id: uuidv4(), parentStatus: "Completed" },
  ]);
  const [stuckStatuses, setStuckStatuses] = useState<SubStatus[]>([
    { name: "Stuck", id: uuidv4(), parentStatus: "Stuck" },
  ]);
  const [canceledStatuses, setCanceledStatuses] = useState<SubStatus[]>([
    { name: "Canceled", id: uuidv4(), parentStatus: "Canceled" },
  ]);

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    let oldIndex: number;
    let newIndex: number;
    if (!over) return;
    if (active.id !== over.id) {
      switch (active.data.current?.parentStatus as ParentStatus) {
        case "Backlog":
          oldIndex = backlogStatuses.findIndex((s) => s.id === active.id);
          newIndex = backlogStatuses.findIndex((s) => s.id === over.id);
          const newWhiteboardStatuses = arrayMove(
            backlogStatuses,
            oldIndex,
            newIndex
          );
          setBacklogStatuses(newWhiteboardStatuses);
          break;
        case "To-do":
          oldIndex = toDoStatuses.findIndex((s) => s.id === active.id);
          newIndex = toDoStatuses.findIndex((s) => s.id === over.id);
          const newToDoStatuses = arrayMove(toDoStatuses, oldIndex, newIndex);
          setToDoStatuses(newToDoStatuses);
          break;
        case "In Progress":
          oldIndex = inProgressStatuses.findIndex((s) => s.id === active.id);
          newIndex = inProgressStatuses.findIndex((s) => s.id === over.id);
          const newInProgressStatuses = arrayMove(
            inProgressStatuses,
            oldIndex,
            newIndex
          );
          setInProgressStatuses(newInProgressStatuses);
          break;
        case "Completed":
          oldIndex = completedStatuses.findIndex((s) => s.id === active.id);
          newIndex = completedStatuses.findIndex((s) => s.id === over.id);
          const newCompletedStatuses = arrayMove(
            completedStatuses,
            oldIndex,
            newIndex
          );
          setCompletedStatuses(newCompletedStatuses);
          break;
        case "Stuck":
          oldIndex = stuckStatuses.findIndex((s) => s.id === active.id);
          newIndex = stuckStatuses.findIndex((s) => s.id === over.id);
          const newStuckStatuses = arrayMove(stuckStatuses, oldIndex, newIndex);
          setStuckStatuses(newStuckStatuses);
          break;
        case "Canceled":
          oldIndex = canceledStatuses.findIndex((s) => s.id === active.id);
          newIndex = canceledStatuses.findIndex((s) => s.id === over.id);
          const newCanceledStatuses = arrayMove(
            canceledStatuses,
            oldIndex,
            newIndex
          );
          setCanceledStatuses(newCanceledStatuses);
          break;
      }
    }
  }

  const allStatuses = [
    ...backlogStatuses,
    ...toDoStatuses,
    ...inProgressStatuses,
    ...completedStatuses,
    ...stuckStatuses,
    ...canceledStatuses,
  ];

  const duplicateStatuses = allStatuses.filter(
    (s, i, arr) => arr.findIndex((s2) => s2.name.trim() === s.name.trim()) !== i
  );

  const app = useRealmApp();
  const [members, setMembers] = useState<MemberData[]>([
    {
      ...(instanceMembers.find((m) => m._id === app.currentUser?.id) ??
        instanceMembers[0]),
      isAdmin: true,
    },
  ]);

  const filteredInstanceMembers =
    instanceMembers.filter(
      (instanceMember) => !members.find((m) => m._id === instanceMember._id)
    ) ?? [];
  const [addMemberOpen, setAddMemberOpen] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const createSpaceMutation = useCreateSpace();
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant={"secondary"}>New Space</Button>
      </DialogTrigger>
      <DialogContent className="max-h-[96vh] max-w-xl gap-0 p-0">
        <div className="flex flex-col overflow-auto">
          {step === 0 && (
            <>
              <DialogHeader className="p-8 pb-4">
                <DialogTitle>New Space</DialogTitle>
                <DialogDescription>
                  Create a space to give more structure to how you manage work
                  on Sero. A space could represent a team, a type of projects,
                  or any grouping that gives you the structure you need.
                </DialogDescription>
              </DialogHeader>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-1 flex-col overflow-auto pt-4"
              >
                <div className="flex-1 space-y-8 overflow-auto px-8 pb-8">
                  <div className="space-y-0.5">
                    <InputHeading heading="Name" />
                    <div className="space-y-0.5">
                      <TextInput
                        data-1p-ignore
                        aria-autocomplete="none"
                        spellCheck={false}
                        id="space-name"
                        autoComplete="off"
                        className="w-72 "
                        placeholder="Onboarding"
                        {...register("spaceName", {
                          // validate: {
                          //   unique: (value) => isNameUnique(value),
                          //   required: (value) => value !== "" || "Name is required",
                          // },
                          onChange: (e) => {
                            // allow spaces and letters only
                            const value = e.target.value;
                            const letterValue = e.target.value.replace(
                              /[^a-zA-Z\s]/g,
                              ""
                            );
                            setValue("spaceName", value, {
                              shouldValidate: true,
                            });
                            const slugValue = letterValue
                              .replace(/\s+/g, "")
                              .slice(0, 3)
                              .toUpperCase();
                            setValue("slug", slugValue, {
                              shouldValidate: true,
                            });
                          },
                        })}
                      />
                      {errors.spaceName?.message && (
                        <div className={clsx("text-xs text-red-500")}>
                          {errors.spaceName.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <div>
                      <InputHeading heading="Identifier" />
                      <p className="-mt-[1px] text-xs font-light text-gray-400">
                        Helps you easily differentiate between spaces
                      </p>
                    </div>
                    <div className="space-y-0.5">
                      <TextInput
                        data-1p-ignore
                        aria-autocomplete="none"
                        spellCheck={false}
                        className={clsx(
                          "w-72"
                          // errors.slug?.message &&
                          //   "border-red-500 ring-red-500 focus:border-red-500 focus:ring-red-500"
                        )}
                        autoComplete="off"
                        maxLength={4}
                        placeholder="ONB"
                        {...register("slug", {
                          // validate: {
                          //   unique: isSlugUnique,
                          //   required: (value) =>
                          //     value !== "" || "Identifier is required",
                          // },
                          onChange: (e) => {
                            const value = e.target.value
                              .replace(/[^A-Z]/g, "")
                              .toUpperCase();
                            if (value.length <= 4) {
                              setValue("slug", value, { shouldValidate: true });
                            }
                          },
                        })}
                      />
                      {errors.slug?.message && (
                        <div className={clsx("text-xs text-red-500")}>
                          {errors.slug.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="space-y-0.5">
                    <InputHeading heading="Color" />
                    <Select
                      value={color}
                      onValueChange={(value) => {
                        setColor(value);
                        setIsColorPickerOpen(false);
                      }}
                    >
                      <SelectTrigger
                        className={clsx("h-9 w-72 justify-between")}
                      >
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent className="overflow-auto">
                        <SelectGroup>
                          {colors.map((color) => (
                            <SelectItem value={color.color}>
                              <div className="flex items-center space-x-2">
                                <SpaceSymbol color={color.color} />
                                <span className="truncate">{color.name}</span>
                              </div>
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                  {/* <div className="shrink-0 bg-red-200">Say 2</div>
                  <div className="h-96 shrink-0 bg-blue-200" /> */}
                </div>
                <div className="flex shrink-0 justify-end space-x-2 border-t border-gray-200/70 py-6 px-8">
                  <Button type="submit">Next</Button>
                </div>
              </form>
            </>
          )}
          {/* {step === 0 && (
            <>
              <DialogHeader className="p-8 pb-4">
                <DialogTitle>New Space</DialogTitle>
                <DialogDescription>
                  Create a space to give more structure to how you manage work
                  on Sero. A space could represent a team, a type of projects,
                  or any grouping that gives you the structure you need.
                </DialogDescription>
              </DialogHeader>

              <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-1 flex-col overflow-auto pt-4"
              >
                <div className="flex-1 space-y-8 overflow-auto px-8 pb-8">
                  <div className="space-y-0.5">
                    <InputHeading heading="Name" />
                    <div className="space-y-0.5">
                      <TextInput
                        data-1p-ignore
                        aria-autocomplete="none"
                        spellCheck={false}
                        id="space-name"
                        autoComplete="off"
                        className="w-72 "
                        placeholder="Onboarding"
                        {...register("spaceName", {
                          // validate: {
                          //   unique: (value) => isNameUnique(value),
                          //   required: (value) => value !== "" || "Name is required",
                          // },
                          onChange: (e) => {
                            // allow spaces and letters only
                            const value = e.target.value;
                            const letterValue = e.target.value.replace(
                              /[^a-zA-Z\s]/g,
                              ""
                            );
                            setValue("spaceName", value, {
                              shouldValidate: true,
                            });
                            const slugValue = letterValue
                              .replace(/\s+/g, "")
                              .slice(0, 3)
                              .toUpperCase();
                            setValue("slug", slugValue, {
                              shouldValidate: true,
                            });
                          },
                        })}
                      />
                      {errors.spaceName?.message && (
                        <div className={clsx("text-xs text-red-500")}>
                          {errors.spaceName.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <div>
                      <InputHeading heading="Identifier" />
                      <p className="-mt-[1px] text-xs font-light text-gray-400">
                        Helps you easily differentiate between spaces
                      </p>
                    </div>
                    <div className="space-y-0.5">
                      <TextInput
                        data-1p-ignore
                        aria-autocomplete="none"
                        spellCheck={false}
                        className={clsx(
                          "w-72"
                          // errors.slug?.message &&
                          //   "border-red-500 ring-red-500 focus:border-red-500 focus:ring-red-500"
                        )}
                        autoComplete="off"
                        maxLength={4}
                        placeholder="ONB"
                        {...register("slug", {
                          // validate: {
                          //   unique: isSlugUnique,
                          //   required: (value) =>
                          //     value !== "" || "Identifier is required",
                          // },
                          onChange: (e) => {
                            const value = e.target.value
                              .replace(/[^A-Z]/g, "")
                              .toUpperCase();
                            if (value.length <= 4) {
                              setValue("slug", value, { shouldValidate: true });
                            }
                          },
                        })}
                      />
                      {errors.slug?.message && (
                        <div className={clsx("text-xs text-red-500")}>
                          {errors.slug.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="space-y-0.5">
                    <InputHeading heading="Color" />
                    <Select
                      value={color}
                      onValueChange={(value) => {
                        setColor(value);
                        setIsColorPickerOpen(false);
                      }}
                    >
                      <SelectTrigger
                        className={clsx("h-9 w-72 justify-between")}
                      >

                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {colors.map((color) => (
                            <SelectItem value={color.color}>
                              <div className="flex items-center space-x-2">
                                <SpaceSymbol color={color.color} />
                                <span className="truncate">{color.name}</span>
                              </div>
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <div className="flex shrink-0 justify-end space-x-2 border-t border-gray-200/70 py-4 px-8">
                  <Button type="submit">Next</Button>
                </div>
              </form>
            </>
          )} */}
          {step === 2 && (
            <>
              <DialogHeader className="border-b border-gray-200/70 p-8">
                <DialogTitle>Task Statuses</DialogTitle>
                <DialogDescription>
                  You can modify the defaul statuses for tasks in this space to
                  suit your workflow. If you aren't sure of your workflow as
                  yet, we recommend leaving the statuses as they are. You can
                  always change them later!
                </DialogDescription>
              </DialogHeader>
              <div className="flex-1 overflow-auto py-6 px-8">
                <DndContext
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                  modifiers={[restrictToParentElement]}
                >
                  <div className="space-y-3">
                    <StatusType
                      title={"Backlog"}
                      statuses={backlogStatuses}
                      allStatuses={allStatuses}
                      setStatuses={setBacklogStatuses}
                      iconClassName="border bg-gray-100"
                      parentStatus="Backlog"
                    />
                    <StatusType
                      title={"To-do"}
                      statuses={toDoStatuses}
                      allStatuses={allStatuses}
                      setStatuses={setToDoStatuses}
                      iconClassName="border border-amber-600 bg-amber-400"
                      parentStatus="To-do"
                    />
                    <StatusType
                      title={"In Progress"}
                      statuses={inProgressStatuses}
                      allStatuses={allStatuses}
                      setStatuses={setInProgressStatuses}
                      iconClassName="border border-blue-600 bg-blue-400"
                      parentStatus="In Progress"
                    />

                    <StatusType
                      title={"Stuck"}
                      statuses={stuckStatuses}
                      allStatuses={allStatuses}
                      setStatuses={setStuckStatuses}
                      iconClassName="border border-red-600 bg-red-400"
                      parentStatus="Stuck"
                    />
                    <StatusType
                      title={"Completed"}
                      statuses={completedStatuses}
                      allStatuses={allStatuses}
                      setStatuses={setCompletedStatuses}
                      iconClassName="border border-green-600 bg-green-400"
                      parentStatus="Completed"
                    />
                    <StatusType
                      title={"Canceled"}
                      statuses={canceledStatuses}
                      allStatuses={allStatuses}
                      setStatuses={setCanceledStatuses}
                      iconClassName="border border-gray-600 bg-gray-400"
                      parentStatus="Canceled"
                    />
                  </div>
                </DndContext>
              </div>
              <div className="flex items-center justify-between border-t border-gray-200/70 px-8 py-6">
                <Button variant={"secondary"} onClick={() => setStep(0)}>
                  Back
                </Button>
                <Button
                  disabled={
                    !!backlogStatuses.find((s) => s.name.trim() === "") ||
                    !!toDoStatuses.find((s) => s.name.trim() === "") ||
                    !!inProgressStatuses.find((s) => s.name.trim() === "") ||
                    !!completedStatuses.find((s) => s.name.trim() === "") ||
                    !!stuckStatuses.find((s) => s.name.trim() === "") ||
                    !!canceledStatuses.find((s) => s.name.trim() === "") ||
                    !!duplicateStatuses.length
                  }
                  onClick={() => {
                    setStep(2);
                  }}
                >
                  Next
                </Button>
              </div>
            </>
          )}
          {step === 1 && (
            <>
              <DialogHeader className="p-8 pb-4">
                <DialogTitle>Members</DialogTitle>
                <DialogDescription>
                  Add members you want to collaborate with in this space.
                </DialogDescription>
              </DialogHeader>

              <div className="flex flex-1 flex-col overflow-auto px-8 pt-4">
                <div className="flex">
                  <Popover
                    modal={true}
                    open={addMemberOpen}
                    onOpenChange={setAddMemberOpen}
                  >
                    <PopoverTrigger asChild>
                      <Button
                        variant={"secondary"}
                        onClick={() => setAddMemberOpen(true)}
                        disabled={!filteredInstanceMembers.length}
                      >
                        <PlusIcon className="mr-2 h-4 w-4" />
                        Add Member
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent
                      className="w-fit max-w-sm p-0"
                      align="start"
                    >
                      <Command>
                        <CommandInput placeholder="Search.." className="h-9" />
                        <CommandEmpty>No members found.</CommandEmpty>
                        <CommandGroup>
                          {filteredInstanceMembers.map((instanceMember) => (
                            <CommandItem
                              key={instanceMember._id}
                              value={instanceMember.name}
                              onSelect={() => {
                                setMembers([
                                  ...members,
                                  { ...instanceMember, isAdmin: false },
                                ]);
                                setAddMemberOpen(false);
                              }}
                              className="gap-2"
                            >
                              <span className="truncate">
                                {instanceMember.name}
                              </span>
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </Command>
                    </PopoverContent>
                  </Popover>
                </div>
                <div className="space-y-2 pt-8">
                  <div className="flex">
                    <div className="flex-1 text-sm text-gray-400">Name</div>
                    <div className="flex w-16 justify-center text-sm text-gray-400">
                      Admin
                    </div>
                  </div>
                  <div>
                    {members.map((m) => (
                      <MemberListItem
                        m={m}
                        members={members}
                        key={m.email}
                        setMembers={setMembers}
                      />
                    ))}
                  </div>
                </div>
                <div className="mt-8 flex items-center justify-between gap-4 rounded-md bg-gray-50 p-4">
                  <div className="space-y-1">
                    <div className="text-sm">Private Space</div>
                    <div className="text-xs font-light text-gray-500">
                      Private spaces are only visible to members of the space.
                      Only admins of a space can add new members or make a space
                      public.
                    </div>
                  </div>
                  <Switch
                    checked={isPrivate}
                    onCheckedChange={(value) => {
                      setIsPrivate(value);
                    }}
                  />
                </div>
                <div className="flex-1 space-y-8 overflow-auto px-8 pb-8"></div>
              </div>
              <div className="flex justify-between space-x-2 border-t border-gray-200/70 py-6 px-8">
                <Button
                  variant={"secondary"}
                  onClick={() => setStep(0)}
                  disabled={createSpaceMutation.isLoading}
                >
                  Back
                </Button>
                <Button
                  disabled={createSpaceMutation.isLoading}
                  onClick={() => {
                    createSpaceMutation.mutate({
                      name: getValues("spaceName"),
                      slug: getValues("slug"),
                      color,
                      isPrivate: isPrivate,
                      statusGroup: {
                        name: getValues("spaceName"),
                        description: "",
                        statuses: [
                          ...backlogStatuses.map((s) => {
                            return {
                              name: s.name,
                              parentStatus: s.parentStatus,
                            };
                          }),
                          ...toDoStatuses.map((s) => {
                            return {
                              name: s.name,
                              parentStatus: s.parentStatus,
                            };
                          }),
                          ...inProgressStatuses.map((s) => {
                            return {
                              name: s.name,
                              parentStatus: s.parentStatus,
                            };
                          }),
                          ...completedStatuses.map((s) => {
                            return {
                              name: s.name,
                              parentStatus: s.parentStatus,
                            };
                          }),
                          ...stuckStatuses.map((s) => {
                            return {
                              name: s.name,
                              parentStatus: s.parentStatus,
                            };
                          }),
                          ...canceledStatuses.map((s) => {
                            return {
                              name: s.name,
                              parentStatus: s.parentStatus,
                            };
                          }),
                        ],
                      },

                      members: members.map((m) => {
                        return {
                          _id: m._id,
                          role: m.isAdmin ? "admin" : "member",
                        };
                      }),
                    });
                  }}
                >
                  {createSpaceMutation.isLoading
                    ? "Creating Space.."
                    : "Create Space"}
                </Button>
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

function MemberListItem({
  m,
  members,
  setMembers,
}: {
  m: MemberData;
  members: MemberData[];
  setMembers: React.Dispatch<React.SetStateAction<MemberData[]>>;
}) {
  return (
    <div
      key={m.email}
      className="group relative flex h-9 items-center justify-between border-t text-sm"
    >
      <div className="flex flex-1 items-center space-x-2">
        <div className="truncate font-medium text-gray-700">{m.name}</div>
      </div>
      <div className="flex w-16 items-center justify-center space-x-2">
        <Checkbox
          disabled={members.filter((m) => m.isAdmin).length === 1 && m.isAdmin}
          checked={m.isAdmin}
          onCheckedChange={(checked) => {
            setMembers((members) =>
              members.map((member) => {
                if (member._id === m._id) {
                  return { ...member, isAdmin: !!checked };
                }
                return member;
              })
            );
          }}
        />
      </div>
    </div>
  );
}

function SpacesTable() {
  const [sorting, setSorting] = useState<SortingState>([]);
  // only projects with names that match the search string
  // const filteredProjects = projects?.filter((p) =>
  //   p.name.toLowerCase().includes(search.toLowerCase())
  // );
  const navigate = useNavigate();
  const { data: spaces } = useGetSpaces();
  console.log(spaces);
  const table = useReactTable<SpaceData>({
    data: spaces ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      // globalFilter: search,
    },
    globalFilterFn: fuzzyFilter,
    onSortingChange: setSorting,
    // onGlobalFilterChange: setSearch,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  // const table = useReactTable({
  //   data:
  //     projects ?? [],
  //   columns,
  //   getCoreRowModel: getCoreRowModel(),
  //   state: {
  //     sorting,
  //   },
  //   onSortingChange: setSorting,
  //   getSortedRowModel: getSortedRowModel(),
  // });

  return (
    <>
      <div className="flex-1 overflow-auto">
        <div className="max-h-full max-w-full overflow-auto">
          <table
            style={{ borderCollapse: "separate", borderSpacing: 0 }}
            className="w-full"
          >
            <thead className="overflow-auto">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="border-b">
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      scope="col"
                      className={
                        "sticky top-0 z-10 h-11 whitespace-nowrap border-b border-r border-gray-200/70 bg-gray-50 px-6 text-left text-xs font-normal uppercase tracking-wide text-gray-400 first:left-0 first:z-20 first:w-full first:pl-8"
                      }
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              <>
                {table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    className="group relative rounded-lg hover:cursor-pointer hover:bg-gray-50"
                    onClick={() => {
                      navigate(`/spaces/${row.original._id}/tasks`);
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className={
                          "whitespace-nowrap border-r border-b border-gray-100 px-6 py-3 text-sm text-gray-500 first:sticky first:left-0 first:z-10 first:bg-white first:pr-6 first:pl-8 first:font-medium first:text-gray-900 first:group-hover:bg-gray-50"
                        }
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export function SpaceNameAndSymbol({
  name,
  color,
}: {
  name: string;
  color: string;
}) {
  return (
    <div className="flex select-none items-center space-x-1.5 text-sm text-gray-800">
      <SpaceSymbol color={color} />
      <span className="truncate">{name}</span>
    </div>
  );
}

export const columns: ColumnDef<SpaceData>[] = [
  {
    id: "name",
    header: () => {
      return <div className="w-full min-w-[240px]">Name</div>;
    },
    cell: ({ row }) => {
      return (
        <div className="flex h-full w-full min-w-[240px] items-center space-x-3 truncate">
          <SpaceSymbol color={row.original.color} />
          <span>{row.original.name}</span>
        </div>
      );
    },
    accessorFn: ({ name }) => name,
  },
  {
    id: "created-by",
    header: () => {
      return <div className="w-60">Created By</div>;
    },
    cell: ({ row }) => {
      return (
        <div className="h-full w-60 truncate">
          {row.original.createdBy?.name ?? "Sero"}
        </div>
      );
    },
  },
];

function StatusType({
  title,
  statuses,
  setStatuses,
  iconClassName,
  parentStatus,
  allStatuses,
}: {
  title: string;
  statuses: SubStatus[];
  setStatuses: React.Dispatch<React.SetStateAction<SubStatus[]>>;
  iconClassName: string;
  parentStatus: ParentStatus;
  allStatuses: SubStatus[];
}) {
  return (
    <div className="space-y-3">
      <div className="flex justify-between rounded bg-gray-50 px-3 py-2 text-sm leading-none text-gray-500">
        <div>{title}</div>
        <button>
          <PlusIcon
            className="h-3.5 w-3.5 text-gray-500"
            onClick={() => {
              setStatuses((prev) => [
                ...prev,
                {
                  name: "",
                  id: uuidv4(),
                  parentStatus,
                },
              ]);
            }}
          />
        </button>
      </div>
      <div className="cursor-default space-y-2">
        <SortableContext
          items={statuses.map((s) => s.id)}
          strategy={verticalListSortingStrategy}
        >
          {statuses.map((status, i) => (
            <SubStatus
              allStatuses={allStatuses}
              key={i}
              status={status}
              i={i}
              setStatuses={setStatuses}
              iconClassName={iconClassName}
              statuses={statuses}
            />
          ))}
        </SortableContext>
      </div>
    </div>
  );
}

function SubStatus({
  status,
  i,
  setStatuses,
  iconClassName,
  statuses,
  allStatuses,
}: {
  status: SubStatus;
  i: number;
  statuses: SubStatus[];
  setStatuses: React.Dispatch<React.SetStateAction<SubStatus[]>>;
  iconClassName: string;
  allStatuses: SubStatus[];
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      animateLayoutChanges: () => false,
      id: status.id,
      data: {
        parentStatus: status.parentStatus,
      },
    });
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div>
      <div
        style={style}
        {...attributes}
        ref={setNodeRef}
        className="group flex cursor-default items-center pl-1.5"
        tabIndex={-1}
      >
        <div
          className="mr-1.5 cursor-grab rounded p-1 hover:bg-gray-100"
          {...listeners}
        >
          <DragHandleDots2Icon className="h-4 w-4 text-gray-500" />
        </div>
        <div className={`h-3 w-3 rounded-full ${iconClassName}`} />
        <TextInput
          autoFocus
          value={status.name}
          onChange={(e) => {
            if (
              allStatuses.find(
                (s) =>
                  s.name.trim() === e.target.value.trim() && s.id !== status.id
              )
            ) {
              setError(true);
              setErrorText("Status name already exists");
            } else {
              setError(false);
              setErrorText("");
            }
            setStatuses((prev) =>
              prev.map((s, index) =>
                index === i
                  ? {
                      name: e.target.value,
                      id: s.id,
                      parentStatus: s.parentStatus,
                    }
                  : s
              )
            );
          }}
          onBlur={(e) => {
            if (e.target.value.trim() === "") {
              setError(true);
              setErrorText("Status name cannot be empty");
            }
          }}
          className={clsx(
            "ml-2.5 h-8 w-72 text-sm",
            error &&
              "border border-red-500 ring-[0.5px] ring-red-500 focus:border-red-500 focus:ring-red-500"
          )}
          // onBlur={() => {
          //   if (status.name.trim() === "") {
          //     setError(true);
          //     setErrorText("Status name cannot be empty");
          //   } else if (
          //     allStatuses.find(
          //       (s) => s.name === status.name && s.id !== status.id
          //     )
          //   ) {
          //     setError(true);
          //     setErrorText("Status name already exists");
          //   } else {
          //     setError(false);
          //     setErrorText("");
          //   }
          // }}
          autoComplete="off"
        />

        <button
          className={clsx(
            "ml-1.5 rounded p-[5px] text-gray-400 opacity-0 transition-all hover:bg-gray-100",
            status.parentStatus === "Backlog" ||
              status.parentStatus === "Stuck" ||
              status.parentStatus === "Canceled"
              ? "opacity-100 transition-opacity"
              : "",
            statuses.length > 1 && "opacity-100 transition-opacity"
          )}
          disabled={
            status.parentStatus === "Backlog" ||
            status.parentStatus === "Stuck" ||
            status.parentStatus === "Canceled"
              ? false
              : statuses.length === 1
          }
        >
          <TrashIcon
            className="h-3.5 w-3.5"
            onClick={() => {
              setStatuses((prev) => prev.filter((s) => s.id !== status.id));
            }}
          />
        </button>
      </div>
      {errorText !== "" && (
        <p className="pl-14 pt-0.5 text-xs text-red-500 transition-all">
          {errorText}
        </p>
      )}
    </div>
  );
}

function StatusGroupDisplay({
  selectedStatusGroup,
}: {
  selectedStatusGroup: StatusGroupData | undefined;
}) {
  if (!selectedStatusGroup) {
    return null;
  }
  // let statusMap: { [k: string]: [string] } = {};
  // for (const status of selectedStatusGroup.statuses) {
  //   // if no parent status, then add to statusMap with 1 element in array
  //   if (!status.parentStatus) {
  //     statusMap[status.parentStatus] = [status.name];
  //   } else {
  //     // if parent status exists, then add to statusMap with parent status as key and status as value
  //     if (statusMap[status.parentStatus]) {
  //       statusMap[status.parentStatus].push(status.name);
  //     } else {
  //       statusMap[status.parentStatus] = [status.name];
  //     }
  //   }
  // }

  // console.log(sortedStatusMap)

  return (
    <div className="space-y-4 rounded-md border p-6 shadow-sm">
      {/* <div className="flex items-center justify-between">
        <h3 className="text-lg font-medium text-gray-900">
          {selectedStatusGroup.name}
        </h3>
        <Button variant={"ghost"}>Edit</Button>
      </div> */}
      <div className="space-y-4">
        <ParentStatusDisplay
          bg="bg-gray-100"
          border="border-gray-600 border-dotted"
          parentStatus="Backlog"
          statuses={selectedStatusGroup.statuses
            .filter((s) => s.parentStatus === "Backlog")
            .map((s) => s.name)}
        />
        <ParentStatusDisplay
          bg="bg-amber-400"
          border="border-amber-600"
          parentStatus="To-do"
          statuses={selectedStatusGroup.statuses
            .filter((s) => s.parentStatus === "To-do")
            .map((s) => s.name)}
        />
        <ParentStatusDisplay
          bg="bg-blue-400"
          border="border-blue-600"
          parentStatus="In Progress"
          statuses={selectedStatusGroup.statuses
            .filter((s) => s.parentStatus === "In Progress")
            .map((s) => s.name)}
        />
        <ParentStatusDisplay
          bg="bg-red-400"
          border="border-red-600"
          parentStatus="Stuck"
          statuses={selectedStatusGroup.statuses
            .filter((s) => s.parentStatus === "Stuck")
            .map((s) => s.name)}
        />
        <ParentStatusDisplay
          bg="bg-green-400"
          border="border-green-600"
          parentStatus="Completed"
          statuses={selectedStatusGroup.statuses
            .filter((s) => s.parentStatus === "Completed")
            .map((s) => s.name)}
        />
        <ParentStatusDisplay
          bg="bg-gray-400"
          border="border-gray-600"
          parentStatus="Canceled"
          statuses={selectedStatusGroup.statuses
            .filter((s) => s.parentStatus === "Canceled")
            .map((s) => s.name)}
        />
      </div>
    </div>
  );
}

function ParentStatusDisplay({
  parentStatus,
  statuses,
  bg,
  border,
}: {
  parentStatus: ParentStatus;
  statuses: string[];
  bg: string;
  border: string;
}) {
  return (
    <div className="space-y-3">
      <div className="flex justify-between rounded bg-gray-100 px-3 py-2 text-sm leading-none text-gray-500">
        <div>{parentStatus}</div>
        {/* <div>
          <PlusIcon className="h-3.5 w-3.5 text-gray-500" />
        </div> */}
      </div>
      <div className="space-y-2.5">
        {statuses.map((status, i) => (
          <div key={i} className="flex items-center pl-1.5">
            <div className={`h-3 w-3 rounded-full border ${bg} ${border}`} />
            <div className="ml-2.5 text-sm text-gray-900">{status}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
