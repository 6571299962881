import * as Dialog from "@radix-ui/react-dialog";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetTaskWithUserType } from "src/api/Services/Tasks/tasks";
import { Loading } from "src/components/ui/ApplicationStates/Loading";
import { TaskEdit } from "./Task";

export default function TaskPanel() {
  const [searchParams, setSearchParams] = useSearchParams();
  const taskId = searchParams.get("taskId");
  const [open, setOpen] = useState(!!taskId);
  const navigate = useNavigate();
  useEffect(() => {
    if (!!taskId) {
      setOpen(true);
    }
  }, [taskId]);

  if (!taskId) {
    return null;
  }

  function handleClose() {
    setOpen(false);
    setTimeout(() => {
      searchParams.delete("taskId");
      navigate({
        pathname: window.location.pathname,
        search: `?${searchParams}`,
      }, { replace: true });
      // setSearchParams(searchParams);
    }, 300);
  }

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          handleClose();
        } else {
          setOpen(true);
        }
      }}
    >
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-50 bg-black bg-opacity-25 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
        <Dialog.Content className="fixed inset-y-0 right-0 z-50 max-w-[90vw] w-[1198px] overflow-auto bg-white duration-200 focus-visible:outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=open]:slide-in-from-right-1/2 data-[state=closed]:slide-out-to-right-1/2">
          <TaskEditSection taskId={taskId} handleClose={handleClose} />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
    // <Transition.Root show={open} as={Fragment}>
    //   <Dialog
    //     as="div"
    //     className="relative z-30"
    //     onClose={() => {
    //       console.log("closing here");
    //       handleClose();
    //     }}
    //   >
    //     <Transition.Child
    //       as={Fragment}
    //       enter="ease-in-out duration-300"
    //       enterFrom="opacity-0"
    //       enterTo="opacity-100"
    //       leave="ease-in-out duration-300"
    //       leaveFrom="opacity-100"
    //       leaveTo="opacity-0"
    //     >
    //       <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
    //     </Transition.Child>

    //     <div className="fixed inset-0 overflow-hidden">
    //       <div className="absolute inset-0 overflow-hidden">
    //         <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
    //           <Transition.Child
    //             as={Fragment}
    //             enter="transform transition ease-in-out duration-300 sm:duration-600"
    //             enterFrom="translate-x-full"
    //             enterTo="translate-x-0"
    //             leave="transform transition ease-in-out duration-300 sm:duration-600"
    //             leaveFrom="translate-x-0"
    //             leaveTo="translate-x-full"
    //           >
    //             <Dialog.Panel className="pointer-events-auto flex justify-end overflow-auto">
    //               <div className="flex h-full min-w-[800px] flex-col overflow-auto bg-white shadow-xl">
    //                 <TaskEditSection
    //                   taskId={taskId}
    //                   handleClose={handleClose}
    //                 />
    //                 {/* <div className="relative mt-6 flex-1 px-4 sm:px-6">
    //                   <div></div>
    //                 </div> */}
    //               </div>
    //             </Dialog.Panel>
    //           </Transition.Child>
    //         </div>
    //       </div>
    //     </div>
    //   </Dialog>
    // </Transition.Root>
  );
}

// function NoTask() {
//   const taskPanelCtx = useTaskPanelContext();
//   return (
//     <>
//       <div className="flex items-start justify-end">
//         {/* <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
//         Panel title
//       </Dialog.Title> */}
//         <div className="ml-3 flex h-7 items-center">
//           <button
//             type="button"
//             className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
//             onClick={() =>
//               taskPanelCtx.dispatch({
//                 type: "TASK_PANEL_UPDATE",
//                 taskPanel: {
//                   id: taskPanelCtx.state.taskPanel.id,
//                   isOpen: false,
//                 },
//               })
//             }
//           >
//             <span className="sr-only">Close panel</span>
//             <XMarkIcon className="h-6 w-6" aria-hidden="true" />
//           </button>
//         </div>
//       </div>
//       <div className="flex flex-col items-center justify-center">
//         <div className="text-gray-500">Error loading task</div>
//       </div>
//     </>
//   );
// }

function TaskEditSection({
  taskId,
  handleClose,
}: {
  taskId: string;
  handleClose: () => void;
}) {
  const { data: taskWithUserType } = useGetTaskWithUserType(taskId);

  return (
    <>
      {!!taskWithUserType ? (
        <TaskEdit
          type={taskWithUserType.type}
          task={taskWithUserType?.task}
          handleClose={handleClose}
        />
      ) : (
        <Loading />
      )}
    </>
  );
}
