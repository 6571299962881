import { ResetPasswordForm } from "src/components/Authentication/PasswordReset/ResetPasswordForm";

type Props = {};

export const ResetPassword = (props: Props) => {
  return (
    <div className="min-h-screen p-40">
      {/* <div className="w-30 absolute top-0 left-0 h-40 rounded-full bg-primary-400"></div> */}
      <div className="container mx-auto max-w-lg">
        <ResetPasswordForm />
      </div>
    </div>
  );
};
