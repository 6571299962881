import { RectangleStackIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import * as Slider from "@radix-ui/react-slider";
import {
  BarChart,
  Card,
  DonutChart,
  Legend,
  ScatterChart,
  Subtitle,
  Text,
  Title,
} from "@tremor/react";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ProjectPerformanceData } from "src/api/Analytics/project";
import {
  useGetCompletedPhaseBreakdownForTemplate,
  useGetCompletedStageBreakdownForTemplate,
  useGetCompletedTaskBreakdownForTemplate,
} from "src/api/Analytics/projectTemplate";
import { useGetJourneyTemplates } from "src/api/Services/Tasks/journeys";
import { Button } from "src/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import {
  Select as NewSelect,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import {
  ProjectTemplatePerformanceContextProvider,
  useProjectTemplatePerformanceContext,
} from "./ProjectTemplatePerformanceContextProvider";

export default function ProjectTemplatePerformance() {
  return (
    <div className="flex h-full flex-col overflow-y-auto">
      <div className="h-12 shrink-0 items-center justify-between space-y-2 border-b border-gray-200/[.65] px-8 sm:flex sm:space-y-0">
        <div className="flex items-center space-x-2 text-sm">
          <Link
            className="text-gray-500/80 transition-all hover:text-gray-800"
            to="/analytics"
          >
            Analytics
          </Link>
          <ChevronRightIcon className="h-3 w-3 text-gray-500" />
          <div className="cursor-default text-gray-800">
            {"Project Template Performance"}
          </div>
        </div>
      </div>
      <div className="flex-1 overflow-auto">
        <ProjectTemplatePerformanceContextProvider>
          <TemplateExistsOrNot />
        </ProjectTemplatePerformanceContextProvider>
      </div>
    </div>
  );
}

function TemplateExistsOrNot() {
  const { journeyTemplates } = useGetJourneyTemplates();
  const projectTemplateCtx = useProjectTemplatePerformanceContext();
  useEffect(() => {
    if (!!journeyTemplates) {
      if (journeyTemplates.length > 0) {
        let templateWithMaxCompletedRuns = String(
          journeyTemplates[0]._id?.toString()
        );
        // find the template with the most runs
        journeyTemplates.forEach((t) => {
          if (
            t.numberOfCompletedRuns > journeyTemplates[0].numberOfCompletedRuns
          ) {
            templateWithMaxCompletedRuns = String(t._id?.toString());
          }
        });
        projectTemplateCtx.dispatch({
          type: "template_update",
          template: templateWithMaxCompletedRuns,
        });
      }
    }
  }, [journeyTemplates]);

  if (!journeyTemplates) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loading />
      </div>
    );
  }
  const totalNumberOfRuns = journeyTemplates.reduce((acc, t) => {
    return acc + t.numberOfRuns;
  }, 0);
  if (totalNumberOfRuns === 0) {
    return <NoTemplates />;
  } else {
    return <PerformanceFiltersAndCharts />;
  }

  return (
    <div className="flex h-full items-center justify-center">
      <Loading />
    </div>
  );
}

function NoTemplates() {
  return (
    <div className="flex flex-1 items-center justify-center">
      <div>You do not have any completed projects to analyze</div>
    </div>
  );
}

function PerformanceFiltersAndCharts() {
  const projectTemplatePerformanceCtx = useProjectTemplatePerformanceContext();

  // const filteredTemplatePerformance =
  // templatePerformance?.customersProgress
  //     ?.filter((p) => {
  //       if (
  //         projectTemplatePerformanceCtx.state.revenueRange.currentMin ===
  //           projectTemplatePerformanceCtx.state.revenueRange.min &&
  //         projectTemplatePerformanceCtx.state.revenueRange.currentMax ===
  //           projectTemplatePerformanceCtx.state.revenueRange.max
  //       ) {
  //         return true;
  //       } else {
  //         return (
  //           (p.revenue ?? 0) >=
  //             projectTemplatePerformanceCtx.state.revenueRange.currentMin &&
  //           (p.revenue ?? 0) <=
  //             projectTemplatePerformanceCtx.state.revenueRange.currentMax
  //         );
  //       }
  //     })
  //     .filter((p) => {
  //       if (projectTemplatePerformanceCtx.state.template === "all") {
  //         return true;
  //       } else {
  //         return (
  //           p.template?._id?.toString() === projectTemplatePerformanceCtx.state.template
  //         );
  //       }
  //     })
  //     .filter((p) => {
  //       if (projectTemplatePerformanceCtx.state.owner === "all") {
  //         return true;
  //       } else {
  //         return (
  //           p.ownerUser?._id?.toString() === projectTemplatePerformanceCtx.state.owner
  //         );
  //       }
  //     }) ?? [];
  // useEffect(() => {
  //   if (!!projectPerformance) {
  //     const maxRevenue = Math.max(
  //       ...projectPerformance.filter((p) => !!p).map((p) => p.revenue ?? 0)
  //     );
  //     const minRevenue = Math.min(
  //       ...projectPerformance.filter((p) => !!p).map((p) => p.revenue ?? 0)
  //     );
  //     projectTemplatePerformanceCtx.dispatch({
  //       type: "revenue_range_update",
  //       revenueRange: {
  //         min: minRevenue,
  //         max: maxRevenue,
  //         currentMax: maxRevenue,
  //         currentMin: minRevenue,
  //       },
  //     });
  //   }
  // }, [projectPerformance]);

  return (
    <>
      <div className="flex h-16 items-center border-b border-gray-200/70 px-8">
        <TemplateFilter />
        {/* <OwnerFilter />
          <RevenueSlider /> */}
      </div>
      {!!projectTemplatePerformanceCtx.state.template && (
        <div className="space-y-6 overflow-auto px-8 pt-6">
          <HighLevelStats />
          <StageBreakdown />
          <TaskBreakdown />
        </div>
      )}

      {/* <div className="flex flex-wrap gap-6">
          <NumberAndRevenue projectPerformance={filteredProjectPerformance} />
          <AheadBehindSchedule
            projectPerformance={filteredProjectPerformance}
          />
          <DistributionByTasker
            projectPerformance={filteredProjectPerformance}
          />
          <RevenueVsDelay projectPerformance={filteredProjectPerformance} />
        </div> */}
    </>
  );
}

function TaskBreakdown() {
  const projectTemplatePerformanceCtx = useProjectTemplatePerformanceContext();
  const { data: taskBreakdown } = useGetCompletedTaskBreakdownForTemplate(
    projectTemplatePerformanceCtx.state.template
  );
  const maxAverageTimeTakenAcrossAllTasks = Math.max(
    ...(taskBreakdown?.map((tt) => {
      const averageTimeTaken =
        tt.tasks.reduce((acc, t) => acc + t.timeTaken, 0) / tt.tasks.length;
      if (tt.tasks.length > 0) {
        return averageTimeTaken;
      }
      return 0;
      // return averageTimeTaken;
    }) ?? [])
  );
  return (
    <Card className="w-fit pt-5">
      <div className="pb-6">
        <Title>Task Analysis</Title>
        <Text>
          Find bottlenecks based on the average time taken to complete each task
        </Text>
      </div>
      <div className="space-y-2">
        <div className="flex items-center gap-4">
          <div className="w-60 truncate font-medium">Task</div>
          <div className="w-80 font-medium">Comparison</div>
          <div className="font-medium">Duration</div>
        </div>
        <div className="space-y-1">
          {taskBreakdown
            ?.sort(
              (t1, t2) =>
                t2.tasks.reduce((acc, t) => acc + t.timeTaken, 0) /
                  t2.tasks.length -
                t1.tasks.reduce((acc, t) => acc + t.timeTaken, 0) /
                  t1.tasks.length
            )
            .map((tt) => (
              <Task
                key={tt._id}
                title={tt.title}
                timeTaken={tt.tasks.map((t) => t.timeTaken)}
                maxAverageTimeTaken={maxAverageTimeTakenAcrossAllTasks}
              />
            ))}
        </div>
      </div>
    </Card>
  );
}

function Task({
  title,
  timeTaken,
  maxAverageTimeTaken,
}: {
  title: string;
  timeTaken: number[];
  maxAverageTimeTaken: number;
}) {
  const averageTimeTaken =
    timeTaken.reduce((acc, t) => acc + t, 0) / timeTaken.length;
  const averageTimeTakenInDays = Math.round(
    averageTimeTaken / (1000 * 60 * 60 * 24)
  );
  const averageTimeTakenInHours = Math.round(
    averageTimeTaken / (1000 * 60 * 60)
  );
  const averageTimeTakenInMinutes = Math.round(averageTimeTaken / (1000 * 60));
  const averageTimeTakenInSeconds = Math.round(averageTimeTaken / 1000);

  return (
    <div>
      <div className="flex items-center gap-4">
        <div className="w-60 truncate text-sm">{title}</div>
        <div
          className={clsx("h-3 w-80 overflow-clip rounded-full bg-blue-100")}
        >
          <div
            className="h-full  bg-blue-500"
            style={{
              width:
                timeTaken.length > 0
                  ? `${(averageTimeTaken / maxAverageTimeTaken) * 100}%`
                  : `0%`,
            }}
          />
        </div>
        <div className="whitespace-nowrap text-sm">
          {timeTaken.length > 0 ? (
            averageTimeTakenInDays > 0 ? (
              `${averageTimeTakenInDays} days`
            ) : averageTimeTakenInHours > 0 ? (
              `${averageTimeTakenInHours} hours`
            ) : averageTimeTakenInMinutes > 0 ? (
              `${averageTimeTakenInMinutes} minutes`
            ) : (
              `${averageTimeTakenInSeconds} seconds`
            )
          ) : (
            <span className="text-gray-300">No data</span>
          )}
        </div>
      </div>
    </div>
  );
}
function StageBreakdown() {
  const projectTemplatePerformanceCtx = useProjectTemplatePerformanceContext();
  const { data: stageBreakdown } = useGetCompletedStageBreakdownForTemplate(
    projectTemplatePerformanceCtx.state.template
  );
  const maxAverageTimeTakenAcrossAllStages = Math.max(
    ...(stageBreakdown?.map((st) => {
      const averageTimeTaken =
        st.stages.reduce((acc, s) => acc + s.timeTaken, 0) / st.stages.length;
      if (st.stages.length > 0) {
        return averageTimeTaken;
      }
      return 0;
    }) ?? [])
  );
  return (
    <Card className="w-fit pt-5">
      <div className="pb-6">
        <Title>Stage Analysis</Title>
        <Text>
          Find bottlenecks based on the average time taken to complete each
          stage
        </Text>
      </div>
      <div className="space-y-2">
        <div className="flex items-center gap-4">
          <div className="w-60 truncate font-medium">Stage</div>
          <div className="w-80 font-medium">Comparison</div>
          <div className="font-medium">Duration</div>
        </div>
        <div className="space-y-1">
          {stageBreakdown?.map((st) => (
            <Stage
              key={st._id}
              name={st.name}
              timeTaken={st.stages.map((s) => s.timeTaken)}
              maxAverageTimeTaken={maxAverageTimeTakenAcrossAllStages}
            />
          ))}
        </div>
      </div>
    </Card>
  );
}

function Stage({
  name,
  timeTaken,
  maxAverageTimeTaken,
}: {
  name: string;
  timeTaken: number[];
  maxAverageTimeTaken: number;
}) {
  const averageTimeTaken =
    timeTaken.reduce((acc, t) => acc + t, 0) / timeTaken.length;
  const averageTimeTakenInDays = Math.round(
    averageTimeTaken / (1000 * 60 * 60 * 24)
  );
  const averageTimeTakenInHours = Math.round(
    averageTimeTaken / (1000 * 60 * 60)
  );
  const averageTimeTakenInMinutes = Math.round(averageTimeTaken / (1000 * 60));
  const averageTimeTakenInSeconds = Math.round(averageTimeTaken / 1000);

  return (
    <div>
      <div className="flex items-center gap-4">
        <div className="w-60 truncate text-sm">{name}</div>
        <div
          className={clsx("h-3 w-80 overflow-clip rounded-full bg-blue-100")}
        >
          <div
            className="h-full bg-blue-500"
            style={{
              width:
                timeTaken.length > 0
                  ? `${(averageTimeTaken / maxAverageTimeTaken) * 100}%`
                  : `0%`,
            }}
          />
        </div>
        <div className="whitespace-nowrap text-sm">
          {timeTaken.length > 0 ? (
            averageTimeTakenInDays > 0 ? (
              `${averageTimeTakenInDays} days`
            ) : averageTimeTakenInHours > 0 ? (
              `${averageTimeTakenInHours} hours`
            ) : averageTimeTakenInMinutes > 0 ? (
              `${averageTimeTakenInMinutes} minutes`
            ) : (
              `${averageTimeTakenInSeconds} seconds`
            )
          ) : (
            <span className="text-gray-300">No data</span>
          )}
        </div>
      </div>
    </div>
  );
}

function HighLevelStats() {
  const projectTemplatePerformanceCtx = useProjectTemplatePerformanceContext();
  const { data: projectPerformance } = useGetCompletedPhaseBreakdownForTemplate(
    projectTemplatePerformanceCtx.state.template
  );
  const projects = projectPerformance?.length ?? 0;
  const revenue =
    projectPerformance?.reduce((acc, p) => {
      return acc + (p.revenue ?? 0);
    }, 0) ?? 0;
  const avgTimeTakenInMilliseconds =
    projectPerformance?.reduce((acc, p) => {
      return acc + p.timeTaken;
    }, 0) ?? 0;
  const avgSeconds = Math.round(avgTimeTakenInMilliseconds / 1000);
  const avgMinutes = Math.round(avgTimeTakenInMilliseconds / (1000 * 60));
  const avgHours = Math.round(avgTimeTakenInMilliseconds / (1000 * 60 * 60));
  const avgDays = Math.round(
    avgTimeTakenInMilliseconds / (1000 * 60 * 60 * 24)
  );

  const projectsCompletedLate =
    projectPerformance?.filter((p) => p.timeTaken > p.expectedTime).length ?? 0;
  return (
    <div className="flex min-h-[100px] flex-wrap gap-6">
      {!!projectPerformance && (
        <>
          <Card className="max-w-[240px] py-4">
            <div className="space-y-2">
              <div className="text-lg font-medium">Projects</div>
              <div className="text-4xl font-medium">
                {projectPerformance?.length}
              </div>
            </div>
          </Card>
          <Card className="max-w-[240px] py-4">
            <div className="space-y-2">
              <div className="text-lg font-medium">Revenue</div>
              <div className="text-4xl font-medium">
                ${revenue.toLocaleString()}
              </div>
            </div>
          </Card>
          <Card className="max-w-[240px] py-4">
            <div className="space-y-2">
              <div className="text-lg font-medium">Average Duration</div>
              <div className="text-4xl font-medium">
                {avgDays > 0
                  ? `${avgDays} days`
                  : avgHours > 0
                  ? `${avgHours} hours`
                  : avgMinutes > 0
                  ? `${avgMinutes} minutes`
                  : `${avgSeconds} seconds`}
              </div>
            </div>
          </Card>
          <Card className="max-w-[240px] py-4">
            <div className="space-y-2">
              <div className="text-lg font-medium">Completed Late</div>
              <div className="text-4xl font-medium">
                {projectsCompletedLate}%
              </div>
            </div>
          </Card>
        </>
      )}
    </div>
  );
}

// generic typed function that takes in an object with an id field and revenue field and filters out ones with a revenue below 1000
function filterTemplates<
  T extends { _id: string; revenue: number; startDate: string; owner: string }
>(
  data: T[],
  template: string,
  minRevenue: number,
  maxRevenue: number,
  minStartDate: string,
  maxStartDate: string,
  owner: string
): T[] {
  return data
    .filter((d) => d._id === template)
    .filter((d) => d.revenue >= minRevenue && d.revenue <= maxRevenue)
    .filter(
      (d) => new Date(d.startDate).getTime() >= new Date(minStartDate).getTime()
    )
    .filter(
      (d) => new Date(d.startDate).getTime() <= new Date(maxStartDate).getTime()
    )
    .filter((d) => d.owner === owner);
}

function NumberAndRevenue({
  projectPerformance,
}: {
  projectPerformance: ProjectPerformanceData[];
}) {
  const projectTemplatePerformanceCtx = useProjectTemplatePerformanceContext();
  const revenue =
    projectPerformance.reduce((acc, p) => {
      return acc + (p.revenue ?? 0);
    }, 0) ?? 0;
  return (
    <div className="flex w-[288px] flex-col gap-6">
      <Card className="h-1/2 w-full py-4">
        <div className="space-y-2">
          <div className="text-lg font-medium">Projects</div>
          <div className="text-5xl font-medium">
            {projectPerformance.length}
          </div>
        </div>
      </Card>
      <Card className="h-1/2 w-full py-4">
        <div className="space-y-2">
          <div className="text-lg font-medium">Revenue</div>
          <div className="text-5xl font-medium">
            ${revenue.toLocaleString()}
          </div>
        </div>
      </Card>
    </div>
  );
}

function RevenueSlider() {
  const projectTemplatePerformanceCtx = useProjectTemplatePerformanceContext();
  const [minValue, setMinValue] = useState(
    projectTemplatePerformanceCtx.state.revenueRange.currentMin
  );
  const [maxValue, setMaxValue] = useState(
    projectTemplatePerformanceCtx.state.revenueRange.currentMax
  );
  useEffect(() => {
    setMinValue(projectTemplatePerformanceCtx.state.revenueRange.currentMin);
  }, [projectTemplatePerformanceCtx.state.revenueRange.currentMin]);
  useEffect(() => {
    setMaxValue(projectTemplatePerformanceCtx.state.revenueRange.currentMax);
  }, [projectTemplatePerformanceCtx.state.revenueRange.currentMax]);
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="secondary" className="space-x-2 divide-x py-0">
          <div className="inline-flex h-full items-center space-x-2">
            <div className="text-lg font-light">$</div>
            <div>Revenue</div>
          </div>
          <div className="flex h-full items-center space-x-2 pl-2 text-sm font-normal">
            <div
              className={clsx(
                projectTemplatePerformanceCtx.state.revenueRange.min !==
                  projectTemplatePerformanceCtx.state.revenueRange.currentMin
                  ? "text-primary"
                  : "text-gray-500",
                "text-sm"
              )}
            >
              $
              {projectTemplatePerformanceCtx.state.revenueRange.currentMin /
                1000}
              K
            </div>
            <div>-</div>
            <div
              className={clsx(
                projectTemplatePerformanceCtx.state.revenueRange.max !==
                  projectTemplatePerformanceCtx.state.revenueRange.currentMax
                  ? "text-primary"
                  : "text-gray-500",
                "text-sm"
              )}
            >
              $
              {projectTemplatePerformanceCtx.state.revenueRange.currentMax /
                1000}
              K
            </div>
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex w-fit flex-col items-center space-y-2">
        <div className="text-sm text-gray-500">
          {/* <span>Current Range: </span> */}
          <span className="text-primary">
            ${minValue / 1000}K - ${maxValue / 1000}K
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-sm text-gray-500">
            ${projectTemplatePerformanceCtx.state.revenueRange.min / 1000}K
          </span>
          <Slider.Root
            min={projectTemplatePerformanceCtx.state.revenueRange.min}
            max={projectTemplatePerformanceCtx.state.revenueRange.max}
            className="relative flex h-5 w-40 touch-none select-none items-center"
            value={[minValue, maxValue]}
            onValueChange={(v) => {
              setMinValue(v[0]);
              setMaxValue(v[1]);
            }}
            onValueCommit={(v) => {
              projectTemplatePerformanceCtx.dispatch({
                type: "revenue_range_update",
                revenueRange: {
                  ...projectTemplatePerformanceCtx.state.revenueRange,
                  currentMin: v[0],
                  currentMax: v[1],
                },
              });
            }}
            step={projectTemplatePerformanceCtx.state.revenueRange.max / 10}
          >
            <Slider.Track className="relative h-[3px] grow rounded-full bg-gray-200">
              <Slider.Range className="absolute h-full rounded-full bg-primary" />
            </Slider.Track>
            <Slider.Thumb className="block h-4 w-4 rounded-full border border-primary/50 bg-background shadow transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50" />
            <Slider.Thumb className="block h-4 w-4 rounded-full border border-primary/50 bg-background shadow transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50" />
          </Slider.Root>
          <span className="text-sm text-gray-500">
            {projectTemplatePerformanceCtx.state.revenueRange.max / 1000}K
          </span>
        </div>
      </PopoverContent>
    </Popover>
  );
}

function RevenueVsDelay({
  projectPerformance,
}: {
  projectPerformance: ProjectPerformanceData[];
}) {
  // console.log(projectPerformance)
  const chartdata = projectPerformance.map((p) => {
    const totalTasks =
      p.allTasksBreakdown?.external?.toDo.total +
      p.allTasksBreakdown?.external?.inProgress.total +
      p.allTasksBreakdown?.external?.canceled +
      p.allTasksBreakdown?.external?.completed.late +
      p.allTasksBreakdown?.external?.completed.onTime +
      p.allTasksBreakdown?.internal?.toDo.total +
      p.allTasksBreakdown?.internal?.inProgress.total +
      p.allTasksBreakdown?.internal?.canceled +
      p.allTasksBreakdown?.internal?.completed.late +
      p.allTasksBreakdown?.internal?.completed.onTime;
    const completedTasks =
      p.allTasksBreakdown?.external?.completed.late +
      p.allTasksBreakdown?.external?.completed.onTime +
      p.allTasksBreakdown?.internal?.completed.late +
      p.allTasksBreakdown?.internal?.completed.onTime;
    const progress = Math.round((completedTasks / totalTasks) * 100);
    return {
      Customer: p.customer.name,
      Revenue: p.revenue ?? 0,
      Delay: dayjs(p.keyDates.forecastedCompletionDate).diff(
        p.keyDates.plannedCompletionDate,
        "days"
      ),
      Progress: progress,
      phaseId: p._id,
    };
  });

  const navigate = useNavigate();
  return (
    <Card className="w-full max-w-xl py-4">
      <Title>Delay vs Progress vs Revenue</Title>
      <Text>
        Understand the severity of delays {`(y-axis)`} with reference to
        progress {`(x-axis)`} and potential revenue impact {`(size)`}
      </Text>
      <ScatterChart
        className="mt-6 -ml-2 h-80"
        yAxisWidth={60}
        data={chartdata}
        category="Customer"
        x="Progress"
        y="Delay"
        size="Revenue"
        sizeRange={[100, 700]}
        showOpacity={true}
        minYValue={0}
        onValueChange={(v) => {
          navigate(`/projects/${v?.phaseId}`);
        }}
        valueFormatter={{
          x: (progress) => `${progress.toFixed(0)}%`,
          y: (days) => `${days} days`,
          size: (amount) => `$${(amount / 1000).toFixed(1)}K`,
        }}
        enableLegendSlider
      />
    </Card>
  );
}

function AheadBehindSchedule({
  projectPerformance,
}: {
  projectPerformance: ProjectPerformanceData[];
}) {
  const chartdata = [
    {
      name: "Projects",
      "Behind Schedule": projectPerformance
        .map(
          (p) =>
            new Date(p.keyDates.forecastedCompletionDate).getTime() -
            new Date(p.keyDates.plannedCompletionDate).getTime()
        )
        .filter((p) => p > 0).length,
      "On Time": projectPerformance
        .map(
          (p) =>
            new Date(p.keyDates.plannedCompletionDate).getTime() -
            new Date(p.keyDates.forecastedCompletionDate).getTime()
        )
        .filter((p) => p > 0).length,
    },
  ];
  // const valueFormatter = (number: number) =>
  //   `${new Intl.NumberFormat("us").format(number).toString()} projects`;

  return (
    <Card className="w-[288px] py-4">
      <Title>On Time vs Behind</Title>
      <Subtitle>Projects on time vs behind schedule</Subtitle>
      <BarChart
        className="mt-4 h-40"
        data={chartdata}
        index="name"
        allowDecimals={false}
        categories={["On Time", "Behind Schedule"]}
        colors={["green", "red"]}
        showLegend={false}
        // valueFormatter={valueFormatter}
        yAxisWidth={20}
        onValueChange={(v) => {
          if (v?.categoryClicked === "Behind Schedule") {
          } else if (v?.categoryClicked === "On Time") {
          }
        }}
        showTooltip={false}
      />
      <Legend
        className="w-60 flex-wrap justify-center"
        categories={["On Time", "Behind Schedule"]}
        colors={["green", "red"]}
      />
    </Card>
  );
}

function Loading() {
  return (
    <div className="flex h-full w-full items-center justify-center pb-12 pt-6">
      <div className="h-8 w-8 animate-spin rounded-full border-4 border-gray-200 border-t-primary" />
    </div>
  );
}

function DistributionByTasker({
  projectPerformance,
}: {
  projectPerformance: ProjectPerformanceData[];
}) {
  let distribution: {
    _id: string;
    name: string;
    count: number;
    revenue: number;
  }[] = [];
  projectPerformance?.forEach((p) => {
    if (p.ownerUser?._id) {
      const index = distribution.findIndex((d) => d._id === p.ownerUser?._id);
      if (index === -1) {
        distribution.push({
          _id: p.ownerUser?._id,
          name: p.ownerUser?.name ?? "",
          count: 1,
          revenue: p.customer.revenue ?? 0,
        });
      } else {
        distribution[index].count += 1;
        distribution[index].revenue += p.customer.revenue ?? 0;
      }
    }
  });
  return (
    <Card className="flex w-[288px] flex-col justify-between py-4">
      <div>
        <Title>Tasker Distribution</Title>
        <Subtitle>Distribution of projects across taskers</Subtitle>
      </div>

      <div className="space-y-4">
        <DonutChart
          data={distribution}
          category="count"
          index="name"
          // valueFormatter={valueFormatter}
          // colors={["amber", "blue", "green"]}
        />
        <Legend
          className=""
          categories={distribution.map((d) => d.name)}
          enableLegendSlider
          // colors={["amber", "blue", "green", "gray"]}
        />
        {/* <div className="flex justify-center">
        <Legend
          className="w-60 flex-wrap justify-center"
          categories={["To Do", "In Progress", "Completed", "Planned"]}
          colors={["amber", "blue", "green", "gray"]}
        />
      </div> */}
      </div>
    </Card>
  );
}

function TemplateFilter() {
  const { journeyTemplates } = useGetJourneyTemplates();
  const projectTemplateCtx = useProjectTemplatePerformanceContext();

  return (
    <NewSelect
      value={projectTemplateCtx.state.template}
      onValueChange={(v) => {
        projectTemplateCtx.dispatch({
          type: "template_update",
          template: v,
        });
      }}
    >
      <SelectTrigger
        className={`h-8 w-fit max-w-full gap-2 bg-white py-0 px-2`}
      >
        <div className="flex h-full items-center gap-2 divide-x">
          <div className="inline-flex items-center space-x-1">
            <RectangleStackIcon className="h-4 w-4" />
            <div>Template</div>
          </div>
          <div className="inline-flex h-full items-center pl-2">
            <SelectValue className="truncate text-xs">
              {projectTemplateCtx.state.template === ""
                ? "No template selected"
                : journeyTemplates?.find(
                    (t) =>
                      t._id?.toString() === projectTemplateCtx.state.template
                  )?.name}
            </SelectValue>
          </div>
        </div>
      </SelectTrigger>
      <SelectContent className="min-w-[120px]">
        <SelectGroup>
          {/* <SelectItem
            value={"all"}
            onSelect={() => {
              projectTemplateCtx.dispatch({
                type: "template_update",
                template: "all",
              });
            }}
          >
            All
          </SelectItem> */}
          {journeyTemplates
            ?.sort(
              (t1, t2) => t2.numberOfCompletedRuns - t1.numberOfCompletedRuns
            )
            .map((t) => (
              <SelectItem
                key={t._id?.toString()}
                value={t._id?.toString() ?? ""}
                disabled={t.numberOfCompletedRuns === 0}
              >
                <div className="inline-flex items-center space-x-2">
                  <span
                    className={
                      "mr-3 rounded-full bg-gray-100 py-0.5 px-2.5 text-xs font-medium text-gray-900 data-[state=active]:bg-primary-100 data-[state=active]:text-primary-600 md:inline-block"
                    }
                  >
                    {t.numberOfCompletedRuns}
                  </span>
                  <span>{t.name}</span>
                </div>
              </SelectItem>
            ))}
        </SelectGroup>
      </SelectContent>
    </NewSelect>
  );
}

// function OwnerFilter() {
//   const { data } = useGetOboardingOverviewForUser();
//   const projectTemplateCtx = useProjectTemplatePerformanceContext();
//   // users
//   let users = data?.map((p) => p.ownerUser);
//   // only keep unique users
//   users = users?.filter(
//     (user, index, self) => index === self.findIndex((u) => u?._id === user?._id)
//   );
//   return (
//     <NewSelect
//       value={projectTemplateCtx.state.owner}
//       onValueChange={(v) => {
//         projectTemplateCtx.dispatch({
//           type: "owner_update",
//           owner: v,
//         });
//       }}
//     >
//       <SelectTrigger
//         className={clsx(
//           `h-8 w-fit max-w-full gap-2  py-0 px-2`,
//           projectTemplateCtx.state.owner === "all"
//             ? "bg-white"
//             : "divide-x-primary border-primary text-primary hover:bg-primary-100"
//         )}
//       >
//         <div
//           className={clsx(
//             "flex h-full items-center gap-2 divide-x",
//             projectTemplateCtx.state.owner === "all" ? "" : "divide-primary/60"
//           )}
//         >
//           <div className="inline-flex items-center space-x-1">
//             <UserIcon className="h-4 w-4" />
//             <div>Owner</div>
//           </div>
//           <div className="inline-flex h-full items-center pl-2">
//             <SelectValue className="truncate text-xs">
//               {projectTemplateCtx.state.owner === "all"
//                 ? "All"
//                 : users?.find(
//                     (u) => u._id.toString() === projectTemplateCtx.state.owner
//                   )?.name}
//             </SelectValue>
//           </div>
//         </div>
//       </SelectTrigger>
//       <SelectContent className="min-w-[120px]">
//         <SelectGroup>
//           <SelectItem
//             value={"all"}
//             onSelect={() => {
//               projectTemplateCtx.dispatch({
//                 type: "owner_update",
//                 owner: "all",
//               });
//             }}
//           >
//             All
//           </SelectItem>
//           {users?.map((u) => (
//             <SelectItem key={u._id?.toString()} value={u._id?.toString() ?? ""}>
//               <span>{u.name}</span>
//             </SelectItem>
//           ))}
//         </SelectGroup>
//       </SelectContent>
//     </NewSelect>
//   );
// }
