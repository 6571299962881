import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ObjectID } from "bson";
import { useNavigate } from "react-router-dom";
import { buildFetcher, buildSetter } from "src/api";
import {
  ExternalSharedSpace,
  ExternalSharedSpaceInvite,
} from "src/components/ExternalSharedspace/types";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { useRealmApp } from "src/store/RealmApp";
import { sharedSpaceKeys } from "./spaces";

export const internalSharedSpaceKeys = {
  invites: () => ["internalSharedSpace", "invites", "all"] as const,
};

export const externalSharedspaceKeys = {
  sharedspaces: () => ["externalSharedspaces", "all"] as const,
  sharedspace: (sharedSpaceId: ObjectID) =>
    ["externalSharedspaces", sharedSpaceId] as const,
  invites: () =>
    [...externalSharedspaceKeys.sharedspaces(), "invites", "all"] as const,
  members: (sharedSpaceId: ObjectID) =>
    [
      ...externalSharedspaceKeys.sharedspace(sharedSpaceId),
      "members",
      "all",
    ] as const,
  journeys: (sharedSpaceId: ObjectID) =>
    [
      ...externalSharedspaceKeys.sharedspace(sharedSpaceId),
      "journeys",
      "all",
    ] as const,
};

// export const useGetSharedspacesForUser = (): {
//   sharedspaces: ExternalSharedSpace[] | undefined;
// } => {
//   const app = useRealmApp();
//   const meta = {
//     functionName: "getSharedSpacesForUser",
//     parameters: {},
//   };

//   const { data } = useQuery<ExternalSharedSpace[]>(
//     externalSharedspaceKeys.sharedspaces(),
//     buildFetcher(app, meta)
//   );

//   return {
//     sharedspaces: data,
//   };
// };

export const useGetSharedspaceInvitesForExternalUser = (): {
  sharedspaceInvites: ExternalSharedSpaceInvite[] | undefined;
} => {
  const app = useRealmApp();
  const meta = {
    functionName: "getSharedSpaceInvitesForExternalUser",
    parameters: {},
  };

  const { data } = useQuery<ExternalSharedSpaceInvite[]>(
    externalSharedspaceKeys.invites(),
    buildFetcher(app, meta)
  );

  return {
    sharedspaceInvites: data,
  };
};

export type InternalSharedSpaceInviteData = {
  _id: ObjectID;
  instance: {
    _id: ObjectID;
    name: string;
  };
  sharedSpace: {
    _id: ObjectID;
    name: string;
  };
  invitedBy?: {
    _id: ObjectID;
    name: string;
    email: string;
  };
  createdAt: Date;
};

export const useGetSharedspaceInvitesForInternalUser = () => {
  const app = useRealmApp();
  const meta = {
    functionName: "getSharedSpaceInvitesForInternalUser",
    parameters: {},
  };

  return useQuery<InternalSharedSpaceInviteData[]>(
    internalSharedSpaceKeys.invites(),
    buildFetcher(app, meta)
  );
};

export const useAcceptSharedSpaceInvite = () => {
  const app = useRealmApp();
  const functionName = "acceptSharedSpaceInvite";
  const fieldName = "params";
  const queryClient = useQueryClient();
  const snackbarCtx = useSnackBar();
  const navigate = useNavigate();

  const acceptSharedSpaceInviteMutation = useMutation(
    buildSetter(app, functionName, fieldName),
    {
      onMutate: async ({
        params,
      }: {
        params: {
          sharedSpaceInviteId: ObjectID;
          // sharedSpaceId: ObjectID;
          // redirectExternalUser: boolean;
        };
      }) => {},
      onError: (data, variables, context) => {
        snackbarCtx.showSnackbar(
          "Error accepting shared space invite",
          "error"
        );
      },
      onSuccess: async (data, error, variables) => {
        snackbarCtx.showSnackbar(
          "Successfully accepted shared space invite",
          "success"
        );
        await app.currentUser.refreshCustomData();
        // if (variables.params.redirectExternalUser) {
        //   navigate(`/sharedspace/${variables.params.sharedSpaceId.toString()}`);
        // }
        // console.log(data, variables);
        queryClient.invalidateQueries(internalSharedSpaceKeys.invites());
        queryClient.invalidateQueries(externalSharedspaceKeys.sharedspaces());
        queryClient.invalidateQueries(
          sharedSpaceKeys.sharedSpacesForUser(app.currentUser.customData._id)
        );
      },
    }
  );

  return acceptSharedSpaceInviteMutation;
};

// getSharedSpacesForUser
