import { Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import {
  ArrowLeftOnRectangleIcon,
  BellAlertIcon,
  BellIcon,
  Cog6ToothIcon,
  RocketLaunchIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import * as Popover from "@radix-ui/react-popover";
import clsx from "clsx";
import dayjs from "dayjs";
import { AnimatePresence, motion } from "framer-motion";
import { Fragment, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  NotificationData,
  useGetAllNotifications,
  useGetNotificationsForSharedSpaceForUser,
  useGetUnreadNotifications,
  useGetUnreadNotificationsForSharedSpaceForUser,
  useMarkNotificationAsRead,
  useMarkSharedSpaceNotificationAsRead,
} from "src/api/General/notifications";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/components/ui/tabs";
import { useSidebar } from "src/pages/Layouts/Sidebar/SiderbarContextProvider";
import { useRealmApp } from "src/store/RealmApp";
import { classNames } from "src/utils/functions/classes";
import { Avatar } from "../ui/Avatar/Avatar";
// import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { CaretSortIcon, ChevronLeftIcon } from "@radix-ui/react-icons";
import { ObjectID } from "bson";
import { useGetInstance } from "src/api/General/instance";
import {
  useGetSeroSharedSpace,
  useGetSharedSpacesForUser,
} from "src/api/Services/SharedSpace/spaces";
import { Button } from "../ui/button";
import { AppLayoutContext } from "./AppLayoutContextProvider";
import { Tab, tabs } from "./Tabs";

const TailwindSidebar = () => {
  // get sibebar open state
  const siderbarCtx = useSidebar();
  const { slug } = useParams();
  const { sharedSpaces } = useGetSharedSpacesForUser();
  const [previousPath, setPreviousPath] = useState("/tasks");
  useEffect(() => {
    // if url contains "homebase" then close sidebar
    if (
      window.location.href.includes("homebase") ||
      window.location.href.includes("customer-view")
    ) {
      if (siderbarCtx.state.isSidebarOpen) {
        siderbarCtx.dispatch({
          type: "siderbar_is_open_update",
          isSidebarOpen: false,
        });
      }
    } else {
      if (!siderbarCtx.state.isSidebarOpen) {
        siderbarCtx.dispatch({
          type: "siderbar_is_open_update",
          isSidebarOpen: true,
        });
      }
    }
  }, [window.location.href]);

  const navigate = useNavigate();

  const { instance } = useGetInstance();
  const [workspace, setWorkspace] = useState<Workspace>({
    type: "instance",
    id: instance?._id.toString() ?? "",
  });
  const location = useLocation();

  // console.log(sharedSpaces)

  useEffect(() => {
    if (instance) {
      setWorkspace({
        type: "instance",
        id: instance._id.toString(),
      });
    }
  }, [instance]);

  useEffect(() => {
    if (!slug) {
      setWorkspace({ type: "instance", id: instance?._id.toString() ?? "" });
    } else {
      const space = sharedSpaces
        ?.filter((s) => s.type === "external")
        .find((s) => s.instance.slug === slug);
      if (space) {
        setWorkspace({ type: "shared-space", id: space._id.toString() });
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (slug && sharedSpaces) {
      const space = sharedSpaces
        .filter((s) => s.type === "external")
        .find((s) => s.instance.slug === slug);
      if (space) {
        setWorkspace({ type: "shared-space", id: space._id.toString() });
      }
    }
  }, [slug, sharedSpaces?.length]);

  return (
    <div className="group/sidebar relative z-10 flex w-56 shrink-0 flex-col">
      <div className="flex flex-1 flex-col border-r border-gray-200/70 bg-white">
        {((!!slug && !!sharedSpaces) || !slug) && (
          <>
            {location.pathname.startsWith("/settings") ? (
              <div className="flex flex-1 flex-col space-y-2 overflow-y-auto px-3 pt-4">
                <div className="flex items-center space-x-1">
                  <Button
                    variant={"ghost"}
                    size={"icon"}
                    onClick={() => {
                      navigate(previousPath);
                    }}
                  >
                    <ChevronLeftIcon className="h-4 w-4" />
                  </Button>
                  <div className="font-medium text-gray-600">Settings</div>
                </div>
                <div>
                  {/* <Link to={"/settings/workspace"}>
                    <div className="group pb-0.5">
                      <div
                        className={classNames(
                          location.pathname.startsWith("/settings/workspace")
                            ? "bg-gray-100 text-gray-700"
                            : "text-gray-500 group-hover:bg-gray-100 group-hover:text-gray-600",
                          "group flex h-[32px] cursor-pointer items-center rounded-md px-3 text-sm font-normal"
                        )}
                      >
                        <div>{"Workspace"}</div>
                      </div>
                    </div>
                  </Link> */}
                  <Link to={"/settings/members"}>
                    <div className="group pb-0.5">
                      <div
                        className={classNames(
                          location.pathname.startsWith("/settings/members")
                            ? "bg-gray-100 text-gray-700"
                            : "text-gray-500 group-hover:bg-gray-100 group-hover:text-gray-600",
                          "group flex h-[32px] cursor-pointer items-center rounded-md px-3 text-sm font-normal"
                        )}
                      >
                        <div>{"Members"}</div>
                      </div>
                    </div>
                  </Link>
                  <Link to={"/settings/integrations"}>
                    <div className="group pb-0.5">
                      <div
                        className={classNames(
                          location.pathname.startsWith("/settings/integrations")
                            ? "bg-gray-100 text-gray-700"
                            : "text-gray-500 group-hover:bg-gray-100 group-hover:text-gray-600",
                          "group flex h-[32px] cursor-pointer items-center rounded-md px-3 text-sm font-normal"
                        )}
                      >
                        <div>{"Integrations"}</div>
                      </div>
                    </div>
                  </Link>
                  <Link to={"/settings/notifications"}>
                    <div className="group pb-0.5">
                      <div
                        className={classNames(
                          location.pathname.startsWith(
                            "/settings/notifications"
                          )
                            ? "bg-gray-100 text-gray-700"
                            : "text-gray-500 group-hover:bg-gray-100 group-hover:text-gray-600",
                          "group flex h-[32px] cursor-pointer items-center rounded-md px-3 text-sm font-normal"
                        )}
                      >
                        <div>{"Notifications"}</div>
                      </div>
                    </div>
                  </Link>
                  <Link to={"/settings/custom-fields"}>
                    <div className="group pb-0.5">
                      <div
                        className={classNames(
                          location.pathname.startsWith(
                            "/settings/custom-fields"
                          )
                            ? "bg-gray-100 text-gray-700"
                            : "text-gray-500 group-hover:bg-gray-100 group-hover:text-gray-600",
                          "group flex h-[32px] cursor-pointer items-center rounded-md px-3 text-sm font-normal"
                        )}
                      >
                        <div>{"Custom Fields"}</div>
                      </div>
                    </div>
                  </Link>
                  <Link to={"/settings/status-groups"}>
                    <div className="group pb-0.5">
                      <div
                        className={classNames(
                          location.pathname.startsWith(
                            "/settings/status-groups"
                          )
                            ? "bg-gray-100 text-gray-700"
                            : "text-gray-500 group-hover:bg-gray-100 group-hover:text-gray-600",
                          "group flex h-[32px] cursor-pointer items-center rounded-md px-3 text-sm font-normal"
                        )}
                      >
                        <div>{"Status Groups"}</div>
                      </div>
                    </div>
                  </Link>
                  <Link to={"/settings/embed"}>
                    <div className="group pb-0.5">
                      <div
                        className={classNames(
                          location.pathname.startsWith("/settings/embed")
                            ? "bg-gray-100 text-gray-700"
                            : "text-gray-500 group-hover:bg-gray-100 group-hover:text-gray-600",
                          "group flex h-[32px] cursor-pointer items-center rounded-md px-3 text-sm font-normal"
                        )}
                      >
                        <div>{"Embed"}</div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ) : (
              <div className="flex flex-1 flex-col overflow-y-auto">
                <div className="flex shrink-0 items-center justify-between">
                  <div className="w-full">
                    {!!instance ? (
                      <WorkspaceSelector
                        instance={{
                          name: instance.name,
                          _id: instance._id.toString(),
                        }}
                        workspace={workspace}
                        setWorkspace={setWorkspace}
                      />
                    ) : (
                      <div className="flex h-12 items-center border-b border-b-gray-200/70 px-3">
                        <div className="h-7 w-full animate-pulse rounded-md bg-gray-200" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="space-y-0.5 px-3 pb-0.5 pt-3">
                  {/* <div className="flex h-[32px] items-center space-x-2.5 rounded-md px-2 hover:bg-gray-100">
                <InboxIcon className="h-[17px] w-[17px]" />
                <span className="text-sm text-gray-600">Inbox</span>
              </div> */}
                  <Notifications workspace={workspace} />
                </div>
                {workspace.type === "instance" ? (
                  <>
                    <nav className="flex-1 bg-white">
                      <div className="p-2 px-3 pt-0">
                        {tabs.map((tab) => {
                          return (
                            <Link key={tab.key} to={tab.route}>
                              <div className="group pb-0.5">
                                <div
                                  className={classNames(
                                    location.pathname.startsWith(tab.route)
                                      ? // ? "bg-gray-100 text-gray-900"
                                        "bg-gray-100 text-gray-800"
                                      : "text-gray-700 group-hover:bg-gray-100 group-hover:text-gray-800",
                                    "group flex h-[30px] cursor-pointer items-center rounded-md px-2 text-sm font-normal"
                                  )}
                                >
                                  <tab.icon
                                    className={classNames(
                                      // location.pathname.startsWith(tab.route)
                                      //   ? "text-gray-600"
                                      //   : // ? "text-gray-500"
                                      //     "text-gray-400 group-hover:text-gray-500",
                                      "mr-3 h-4 w-4 flex-shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                  <div className="">{tab.name}</div>
                                </div>
                              </div>
                              {/* ) : (
                            <Disclosure as="div" className="" key={tab.key}>
                              {({ open }) => (
                                <>
                                  <Disclosure.Button
                                    className={clsx(
                                      " text-gray-600 hover:bg-gray-100 hover:text-gray-900",
                                      "group flex w-full cursor-pointer items-center justify-between rounded-md px-2 py-2 text-sm font-normal"
                                    )}
                                  >
                                    <tab.icon
                                      className={classNames(
                                        // tab.route === appLayoutCtx.activeRoute
                                        //   ? "text-primary-600"
                                        //   : // ? "text-gray-500"
                                        "text-gray-400 group-hover:text-gray-500",
                                        "mr-3 h-5 w-5 flex-shrink-0"
                                      )}
                                      aria-hidden="true"
                                    />
                                    {tab.name}
                                    <ChevronRightIcon
                                      className={clsx(
                                        open
                                          ? "rotate-90 text-gray-500"
                                          : "text-gray-400",
                                        "ml-auto h-[17px] w-[17px] shrink-0 transition duration-150 ease-in-out group-hover:text-gray-500"
                                      )}
                                      aria-hidden="true"
                                    />
                                  </Disclosure.Button>
                                  {!!tab.subTabs && (
                                    <Disclosure.Panel
                                      as="ul"
                                      className="mt-1 space-y-1 pl-8"
                                    >
                                      {({ close }) => (
                                        <>
                                          {!!tab.subTabs && (
                                            <SubTabs
                                              subTabs={tab.subTabs}
                                              close={close}
                                              tabRoute={tab.route}
                                            />
                                          )}
                                        </>
                                      )}
                                    </Disclosure.Panel>
                                  )}
                                </>
                              )}
                            </Disclosure>
                          )} */}
                            </Link>
                          );
                        })}
                      </div>
                      {/* {!!sharedSpaces?.filter((s) => s.type === "external").length && (
              <div className="border-t p-2">
                <span
                  key={"external"}
                  // href={tab.route}
                  onClick={() => {
                    navigate("/external-spaces");
                  }}
                  className={classNames(
                    appLayoutCtx.activeRoute.startsWith("/external")
                      ? // ? "bg-gray-100 text-gray-900"
                        "bg-primary-100 text-primary-600"
                      : "text-gray-600 hover:bg-gray-100 hover:text-gray-900",
                    "group flex cursor-pointer items-center justify-between rounded-md px-2 py-2 text-sm font-normal"
                  )}
                >
                  <div className="flex items-center">
                    <GlobeIcon
                      className={classNames(
                        appLayoutCtx.activeRoute.startsWith("/external")
                          ? "text-primary-600"
                          : // ? "text-gray-500"
                            "text-gray-400 group-hover:text-gray-500",
                        "mr-3 h-5 w-5 flex-shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {"External Spaces"}
                  </div>
                  {!!externalSpacesNotificationsCount && (
                    <span className="min-w-[18px] rounded bg-primary-main px-1 py-0.5 text-center text-xs text-white">
                      {externalSpacesNotificationsCount}
                    </span>
                  )}
                </span>
              </div>
            )} */}
                      {/* {tabs.map((tab) => {
              if (settings?.view?.tabs[tab.key]) {
                return (
                  <span
                    key={tab.key}
                    // href={tab.route}
                    onClick={() => {
                      navigate(tab.route);
                    }}
                    className={classNames(
                      tab.route === appLayoutCtx.activeRoute
                        ? // ? "bg-gray-100 text-gray-900"
                          "bg-primary-100 text-primary-600"
                        : "text-gray-600 hover:bg-gray-100 hover:text-gray-900",
                      "group flex cursor-pointer items-center rounded-md px-2 py-2 text-sm font-normal"
                    )}
                  >
                    <tab.icon
                      className={classNames(
                        tab.route === appLayoutCtx.activeRoute
                          ? "text-primary-600"
                          : // ? "text-gray-500"
                            "text-gray-400 group-hover:text-gray-500",
                        "mr-4 h-5 w-5 flex-shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {tab.name}
                  </span>
                );
              }
            })} */}
                    </nav>

                    <div>
                      {/* <div className="mx-4 flex items-center rounded-lg bg-primary-100">
              <Avatar name={app.currentUser?.customData?.name} />
              <div className="space-y-1">
                <div className="text-sm text-white">
                  {app.currentUser.customData.name}
                </div>
                <div className="text-sm text-white">
                  {app.currentUser.customData.email}
                </div>
              </div>
            </div> */}
                    </div>
                    <div className="flex h-11 shrink-0 items-center justify-between border-t border-gray-200/70 px-4 pl-3">
                      <Button
                        variant={"ghost"}
                        size={"icon"}
                        onClick={() => {
                          setPreviousPath(location.pathname);
                          navigate("/settings");
                        }}
                        className="text-gray-400"
                      >
                        <Cog6ToothIcon className="h-[17px] w-[17px]" />
                      </Button>
                      <UserAvatar />
                    </div>
                  </>
                ) : (
                  <div className="px-3">
                    <span
                      onClick={() => {
                        navigate(`${slug}/projects`);
                      }}
                      className={classNames(
                        location.pathname.includes("projects")
                          ? // ? "bg-gray-100 text-gray-900"
                            "bg-gray-100 text-gray-700"
                          : "text-gray-400 hover:bg-gray-100 hover:text-gray-600",
                        "group flex h-[32px] cursor-pointer items-center rounded-md px-2 text-sm font-normal"
                      )}
                    >
                      <RocketLaunchIcon
                        className={classNames(
                          location.pathname.includes("projects")
                            ? "text-gray-600"
                            : // ? "text-gray-500"
                              "text-gray-400 group-hover:text-gray-600",
                          "mr-3 h-[17px] w-[17px] flex-shrink-0"
                        )}
                        aria-hidden="true"
                      />
                      {"Projects"}
                    </span>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TailwindSidebar;

type Workspace = { type: "instance" | "shared-space"; id: string };

export function WorkspaceSelector({
  instance,
  workspace,
  setWorkspace,
}: {
  instance: { name: string; _id: string };
  workspace: Workspace;
  setWorkspace: (workspace: Workspace) => void;
}) {
  const { sharedSpaces } = useGetSharedSpacesForUser();
  const [open, setOpen] = useState(false);
  const { data: unreadNotifications } = useGetUnreadNotifications();
  let unreadNotificationsCount = 0;
  if (workspace.type === "instance") {
    unreadNotificationsCount =
      sharedSpaces
        ?.filter((s) => s.type === "external")
        .reduce((acc, s) => acc + s.notificationsCount, 0) ?? 0;
  } else {
    unreadNotificationsCount =
      sharedSpaces
        ?.filter(
          (s) => s._id.toString() !== workspace.id && s.type === "external"
        )
        .reduce((acc, s) => acc + s.notificationsCount, 0) ?? 0;
    unreadNotificationsCount += unreadNotifications?.length ?? 0;
  }

  const navigate = useNavigate();

  return (
    <div className="">
      <DropdownMenu
        open={open}
        onOpenChange={(open) => {
          setOpen(open);
        }}
      >
        <DropdownMenuTrigger asChild>
          <div
            onClick={() => {
              setOpen(true);
            }}
            className="flex h-12 w-full cursor-pointer items-center justify-between border-b border-b-gray-200/70 px-5 text-sm font-normal text-gray-700 hover:bg-gray-100 hover:text-gray-800"
          >
            <div className="flex items-center gap-2">
              {/* <img className="h-5 w-5 rounded-sm" src={logo?.url} /> */}
              <div>
                {workspace.type === "instance"
                  ? instance.name
                  : sharedSpaces?.find((s) => s._id.toString() === workspace.id)
                      ?.instance.name}
              </div>
            </div>

            <div className="flex items-center gap-2">
              <CaretSortIcon className="h-4 w-4 text-transparent" />
              {!!unreadNotificationsCount && (
                <div className="flex h-[17px] items-center justify-center rounded bg-primary-main px-[4px] text-[10px] text-white">
                  {unreadNotificationsCount}
                </div>
              )}
            </div>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start" className="-mt-2.5 ml-2 w-60 p-1.5">
          <DropdownMenuGroup>
            <div className="px-1 pb-1 text-xs text-gray-400">Workspaces</div>
            <DropdownMenuItem
              className={clsx(
                "group flex h-8 items-center justify-between gap-3 truncate px-3 transition-none",
                instance._id.toString() === workspace.id &&
                  "bg-gray-100 text-gray-600 focus:bg-gray-100 focus:text-gray-600"
              )}
              onClick={() => {
                setWorkspace({ type: "instance", id: instance._id.toString() });
                navigate("/my-tasks");
                setOpen(false);
              }}
            >
              <div className="flex-1 truncate">{instance.name}</div>
              {!!unreadNotifications?.length &&
                workspace.type !== "instance" && (
                  <div className="flex h-[17px] min-w-[15px] items-center justify-center rounded bg-primary-main px-1 text-[10px] text-white transition-all">
                    {unreadNotifications.length}
                  </div>
                )}
            </DropdownMenuItem>
            {!!sharedSpaces?.filter((s) => s.type === "external").length && (
              <>
                {/* <DropdownMenuSeparator /> */}
                <div className="space-y-0.5">
                  <div className="px-1 pb-0.5 pt-2 text-xs text-gray-400">
                    Shared Spaces
                  </div>
                  {sharedSpaces
                    ?.filter((s) => s.type === "external")
                    .map((s) => (
                      <DropdownMenuItem
                        key={s._id.toString()}
                        onClick={() => {
                          setWorkspace({
                            type: "shared-space",
                            id: s._id.toString(),
                          });
                          navigate(`/${s.instance.slug}/projects`);
                          setOpen(false);
                        }}
                        className={clsx(
                          "group flex justify-between gap-3 px-3 transition-none",
                          workspace.id === s._id.toString() &&
                            "bg-gray-100 text-gray-600 focus:bg-gray-100 focus:text-gray-600"
                        )}
                      >
                        <div className="flex-1 truncate">
                          {s?.instance?.name}
                        </div>
                        {!!s.notificationsCount &&
                          workspace.id !== s._id.toString() && (
                            <div className="[15px] flex h-[17px] items-center justify-center rounded bg-primary-main px-1 text-center text-[10px] text-white transition-all">
                              {s.notificationsCount}
                            </div>
                          )}
                      </DropdownMenuItem>
                    ))}
                </div>
              </>
            )}
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
  {
    /* <Select>
        <SelectTrigger className={`w-fit gap-2 bg-white focus:ring-0`}>
          <div className="inline-flex items-center gap-2">
            {value.type === "instance"
              ? instance.name
              : sharedSpaces?.find((s) => s._id.toString() === value.id)
                  ?.instance.name}
          </div>
        </SelectTrigger>
        <SelectContent className="[12rem]">
          <SelectGroup className="p-0">
            <div className="px-1 py-0.5 text-xs text-gray-400">Workspaces</div>
            <SelectItem
              value="instance"
              onClick={() => {
                setValue({ type: "instance", id: instance._id.toString() });
                setOpen(false);
              }}
              className="flex items-center"
            >
              <div className="flex items-center">{instance.name}</div>
            </SelectItem>
            {!!sharedSpaces?.length && (
              <>
                <SelectSeparator />
                <div>
                  <div className="px-1 py-0.5 text-xs text-gray-400">
                    Shared Spaces
                  </div>
                  {sharedSpaces
                    ?.filter((s) => s.type === "external")
                    .map((s) => (
                      <SelectItem
                        key={s._id.toString()}
                        value="shared-space"
                        onClick={() => {
                          setValue({
                            type: "shared-space",
                            id: s._id.toString(),
                          });
                          setOpen(false);
                        }}
                        className="pr-12"
                      >
                        <div className="flex w-full items-center justify-between">
                          <div className="flex-1 truncate">
                            {s?.instance?.name}
                          </div>
                          {!!s.notificationsCount && (
                            <div className="absolute right-1.5 [18px] rounded bg-primary-main px-1 py-0.5 text-center text-xs text-white">
                              {s.notificationsCount}
                            </div>
                          )}
                        </div>
                      </SelectItem>
                    ))}
                </div>
              </>
            )}
          </SelectGroup>
        </SelectContent>
      </Select> */
  }
}

function SubTabs({
  subTabs,
  close,
  tabRoute,
}: {
  subTabs: Tab[];
  close: () => void;
  tabRoute: string;
}) {
  const navigate = useNavigate();
  const appLayoutCtx = useContext(AppLayoutContext);
  const location = useLocation();

  // useEffect(() => {
  //   if (!location.pathname.includes(tabRoute)) {
  //     close();
  //   }
  // }, [location.pathname]);

  return (
    <>
      {subTabs?.map((subTab) => (
        <span
          key={subTab.key}
          // href={tab.route}
          onClick={() => {
            navigate(subTab.route);
          }}
          className={classNames(
            appLayoutCtx.activeRoute.startsWith(subTab.route)
              ? //  === appLayoutCtx.activeRoute
                // ? "bg-gray-100 text-gray-900"
                "bg-primary-100 text-primary-600"
              : "text-gray-600 hover:bg-gray-100 hover:text-gray-900",
            "group flex cursor-pointer items-center rounded-md px-2 py-2 text-sm font-normal"
          )}
        >
          <subTab.icon
            className={classNames(
              appLayoutCtx.activeRoute.startsWith(subTab.route)
                ? "text-primary-600"
                : // ? "text-gray-500"
                  "text-gray-400 group-hover:text-gray-500",
              "mr-4 h-5 w-5 flex-shrink-0"
            )}
            aria-hidden="true"
          />
          {subTab.name}
        </span>
        // <li key={subItem.name}>
        //   {/* 44px */}
        //   <Disclosure.Button
        //     as="a"
        //     href={subItem.href}
        //     className={classNames(
        //       subItem.current
        //         ? "bg-gray-50"
        //         : "hover:bg-gray-50",
        //       "block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700"
        //     )}
        //   >
        //     {subItem.name}
        //   </Disclosure.Button>
        // </li>
      ))}
    </>
  );
}

function SeroSuccessHubTab() {
  const { data } = useGetSeroSharedSpace();
  const navigate = useNavigate();
  console.log(data);

  return (
    <>
      {/* {data && data.sharedSpaceExists && (
        <div className="relative w-full">
          <button className="w-full justify-center rounded-md border border-primary-main px-2.5 py-1.5 text-xs text-primary-main hover:border-primary-600 hover:bg-primary-100 hover:text-primary-600">
            <div className="inline-flex items-center space-x-3">
              <div>Success Hub</div>
              
            </div>
          </button>
          <div className="absolute top-0 right-0 -mr-2 -mt-3">
            <div className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-primary-main text-xs text-white">
              3
            </div>
          </div>
        </div>
      )} */}
    </>
  );
}

function UserAvatar() {
  const app = useRealmApp();
  let navigate = useNavigate();
  const items: {
    name: string;
    onClick: () => void;
    icon: React.ComponentType<React.ComponentProps<"svg">>;
  }[] = [
    {
      name: "Logout",
      onClick: () => {
        try {
          // posthog.reset()
          app.logOut();
          navigate("/login");
        } catch (err) {
          // console.log(err);
        }
      },
      icon: ArrowLeftOnRectangleIcon,
    },
    {
      name: "Profile",
      onClick: () => {
        navigate("/settings/profile");
      },
      icon: UserIcon,
    },
  ];

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="">
          <div>
            {app.currentUser?.customData?.name ? (
              <Avatar
                name={app.currentUser?.customData?.name}
                size="sm"
                clickable={true}
              />
            ) : (
              // <span className="inline-flex h-6 w-6 shrink-0 cursor-pointer items-center justify-center rounded-full bg-gray-300">
              //   <span className="text-xs font-medium text-gray-700 leading-none text-white">
              //     {app.currentUser.customData.name[0]}
              //   </span>
              // </span>
              <span className="inline-block h-6 w-6 shrink-0 cursor-pointer overflow-hidden rounded-full bg-gray-100">
                <svg
                  className="h-full w-full text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
              </span>
            )}
          </div>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 bottom-0 z-20 mb-9 w-32 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1 px-1">
            {items.map((item) => (
              <Menu.Item key={item.name}>
                {({ active }) => (
                  <button
                    className={clsx(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-600",
                      "flex w-full items-center whitespace-nowrap rounded-md px-2 py-2 text-sm disabled:cursor-default disabled:text-gray-400"
                    )}
                    onClick={item.onClick}
                  >
                    {item.icon && (
                      <item.icon
                        className="mr-2 h-[17px] w-[17px]"
                        aria-hidden="true"
                      />
                    )}
                    {item.name}
                  </button>
                  // <div
                  //   className={classNames(
                  //     active ? "bg-gray-100 text-gray-900" : "text-gray-600",
                  //     "block px-2 py-1 text-sm hover:cursor-pointer"
                  //   )}
                  //   onClick={item.onClick}
                  // >
                  //   {item.name}
                  // </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

function InstanceNotifications() {
  const { data: unreadNotifications } = useGetUnreadNotifications();
  const { data: allNotifications } = useGetAllNotifications();
  function handleClose() {
    setOpen(false);
  }
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState<"unread" | "all">("unread");

  useEffect(() => {
    if (
      !!unreadNotifications &&
      unreadNotifications.length === 0 &&
      tab === "unread"
    ) {
      setTab("all");
    } else if (!!unreadNotifications && unreadNotifications.length > 0) {
      setTab("unread");
    }
  }, [unreadNotifications]);

  return (
    <Popover.Root open={open} onOpenChange={(open) => setOpen(open)}>
      <Popover.Trigger asChild>
        <div className="group select-none pb-0.5">
          <div
            className={clsx(
              "group flex h-[28px] w-full cursor-pointer items-center rounded-md px-2 text-gray-700 hover:bg-gray-100 group-hover:text-gray-800",
              open && "bg-gray-100"
            )}
            onClick={() => {
              setOpen(!open);
            }}
          >
            <AnimatePresence initial={false}>
              <motion.div
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                {!!unreadNotifications?.length ? (
                  <BellAlertIcon
                    className={clsx(
                      "mr-2.5 h-4 w-4 text-gray-700 group-hover:text-gray-800"
                    )}
                  />
                ) : (
                  <BellIcon
                    className={clsx(
                      "mr-2.5 h-4 w-4 text-gray-700 group-hover:text-gray-800"
                    )}
                  />
                )}
              </motion.div>

              <motion.div
                key="count"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                layout
                className="flex flex-1 items-center justify-between "
              >
                <span className="text-sm text-gray-700 group-hover:text-gray-800">
                  Notifications
                </span>
                {!!unreadNotifications && unreadNotifications.length > 0 && (
                  <span className="flex h-[17px] min-w-[15px] items-center justify-center rounded bg-primary-main px-1 py-0.5 text-[10px] text-white">
                    {unreadNotifications.length}
                  </span>
                )}
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </Popover.Trigger>
      <Popover.Portal>
        <>
          <Popover.Content
            side="right"
            align="start"
            sideOffset={4}
            className="relative z-50 flex max-h-[70vh] min-h-[30vh] w-[700px] flex-col rounded-md bg-white p-0 text-gray-800 shadow-md outline-none ring-0 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
          >
            <Tabs
              defaultValue={
                !!unreadNotifications && unreadNotifications.length > 0
                  ? "unread"
                  : "all"
              }
              value={tab}
              onValueChange={(value) => {
                setTab(value as "unread" | "all");
              }}
              className="flex flex-1 flex-col overflow-auto pt-3"
            >
              <TabsList className="z-10 shrink-0 space-x-5 px-4">
                <TabsTrigger
                  className="pb-1.5"
                  value="unread"
                  disabled={
                    !unreadNotifications ||
                    (!!unreadNotifications && unreadNotifications.length === 0)
                  }
                >
                  Unread
                </TabsTrigger>
                <TabsTrigger className="pb-1.5" value="all">
                  All
                </TabsTrigger>
              </TabsList>
              <div className="flex-1 overflow-auto">
                <TabsContent
                  value="unread"
                  className="h-full overflow-auto py-1 focus-visible:ring-0"
                >
                  <div className="divide-y divide-gray-100">
                    {unreadNotifications?.map((n) => (
                      <Notification
                        key={n._id}
                        notification={n}
                        handleClose={handleClose}
                      />
                    ))}
                  </div>
                </TabsContent>
                <TabsContent value="all" className="h-full overflow-auto py-1">
                  <div className="divide-y divide-gray-100">
                    {allNotifications?.notifications?.map((n) => (
                      <Notification
                        key={n._id}
                        notification={n}
                        handleClose={handleClose}
                      />
                    ))}
                  </div>
                </TabsContent>
              </div>
            </Tabs>
          </Popover.Content>
          <div className="fixed top-0 left-0 z-20 h-full w-full bg-black bg-opacity-25" />
        </>
      </Popover.Portal>
    </Popover.Root>
  );
}

function SharedSpaceNotifications({ workspace }: { workspace: Workspace }) {
  const { data: unreadNotifications } =
    useGetUnreadNotificationsForSharedSpaceForUser(workspace.id);
  const { data: allNotifications } = useGetNotificationsForSharedSpaceForUser(
    workspace.id
  );

  function handleClose() {
    setOpen(false);
  }
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState<"unread" | "all">("unread");

  useEffect(() => {
    if (
      !!unreadNotifications &&
      unreadNotifications.length === 0 &&
      tab === "unread"
    ) {
      setTab("all");
    } else if (!!unreadNotifications && unreadNotifications.length > 0) {
      setTab("unread");
    }
  }, [unreadNotifications]);

  return (
    <Popover.Root open={open} onOpenChange={(open) => setOpen(open)}>
      <Popover.Trigger asChild>
        <div className="group select-none pb-0.5">
          <div
            className={clsx(
              !!unreadNotifications && unreadNotifications.length > 0
                ? "text-gray-700"
                : "group-hover:text-gray-800",
              "group flex h-[28px] w-full cursor-pointer items-center rounded-md pl-2 pr-2 hover:bg-gray-100",
              open && "bg-gray-100"
            )}
            onClick={() => {
              setOpen(!open);
            }}
          >
            <AnimatePresence initial={false}>
              <motion.div
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <BellIcon
                  className={clsx(
                    "mr-2.5 h-4 w-4 shrink-0 text-gray-700 group-hover:text-gray-800"
                  )}
                />
              </motion.div>

              <motion.div
                key="count"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                layout
                className="flex flex-1 items-center justify-between "
              >
                <span className="text-sm text-gray-700 group-hover:text-gray-800">
                  Notifications
                </span>
                {!!unreadNotifications && unreadNotifications.length > 0 && (
                  <span className="flex h-[17px] min-w-[15px] items-center justify-center rounded bg-primary-main px-1 py-0.5 text-[10px] text-white">
                    {unreadNotifications.length}
                  </span>
                )}
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </Popover.Trigger>
      <Popover.Portal>
        <>
          <Popover.Content
            side="right"
            align="start"
            sideOffset={4}
            className="relative z-50 flex max-h-[70vh] min-h-[30vh] w-[700px] flex-col rounded-md bg-white p-0 text-gray-800 shadow-md outline-none ring-0 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
          >
            <Tabs
              defaultValue={
                !!unreadNotifications && unreadNotifications.length > 0
                  ? "unread"
                  : "all"
              }
              value={tab}
              onValueChange={(value) => {
                setTab(value as "unread" | "all");
              }}
              className="flex flex-1 flex-col overflow-auto pt-3"
            >
              <TabsList className="z-10 shrink-0 space-x-5 px-4">
                <TabsTrigger
                  className="pb-1.5"
                  value="unread"
                  disabled={
                    !unreadNotifications ||
                    (!!unreadNotifications && unreadNotifications.length === 0)
                  }
                >
                  Unread
                </TabsTrigger>
                <TabsTrigger className="pb-1.5" value="all">
                  All
                </TabsTrigger>
              </TabsList>
              <div className="flex-1 overflow-auto">
                <TabsContent
                  value="unread"
                  className="h-full overflow-auto py-1 focus-visible:ring-0"
                >
                  <div className="divide-y divide-gray-100">
                    {unreadNotifications?.map((n) => (
                      <SharedSpaceNotification
                        key={n._id}
                        notification={n}
                        handleClose={handleClose}
                        sharedSpaceId={workspace.id}
                      />
                    ))}
                  </div>
                </TabsContent>
                <TabsContent value="all" className="h-full overflow-auto py-1">
                  <div className="divide-y divide-gray-100">
                    {allNotifications?.notifications?.map((n) => (
                      <SharedSpaceNotification
                        key={n._id}
                        notification={n}
                        handleClose={handleClose}
                        sharedSpaceId={workspace.id}
                      />
                    ))}
                  </div>
                </TabsContent>
              </div>
            </Tabs>
          </Popover.Content>
          <div className="fixed top-0 left-0 z-10 h-full w-full bg-black bg-opacity-25" />
        </>
      </Popover.Portal>
    </Popover.Root>
  );
}

export function Notifications({ workspace }: { workspace: Workspace }) {
  if (workspace.type === "instance") {
    return <InstanceNotifications />;
  } else {
    return <SharedSpaceNotifications workspace={workspace} />;
  }

  // const {data: allNotifications} = useGetAllNotifications()

  // const { data: notifications } = useGetNotificationsForUser();
  // // console.log(notifications);
  // const numberOfUnreadNotifications =
  //   notifications?.unreadNotifications.length ?? 0;
  // const allNotifications = [
  //   ...(notifications?.unreadNotifications ?? []),
  //   ...(notifications?.readNotifications ?? []),
  // ].sort((n1, n2) => {
  //   return new Date(n2.createdAt).getTime() - new Date(n1.createdAt).getTime();
  // });
  // const markAllNotificationsAsReadMutation = useMarkAllNotificationsAsRead();
}

function NotificationButton({
  color,
  text,
  notificationCount,
  onClick,
  isActive,
}: {
  color: string;
  text: string;
  notificationCount: number;
  onClick: () => void;
  isActive: boolean;
}) {
  return (
    <div
      className={clsx(
        `inline-flex h-6 items-center space-x-1.5 border border-${color}-400 px-2.5 text-sm text-${color}-700 rounded-full  shadow-sm hover:bg-${color}-50 cursor-pointer`,
        isActive && `bg-${color}-50 cursor-default pr-1`
      )}
      onClick={onClick}
    >
      <span className="">{text}</span>
      <span className="text-xs">{notificationCount}</span>
      {isActive && (
        <button
          className={clsx(
            "-pr-1 rounded-full p-0.5",
            `border-${color}-400 hover:bg-${color}-100`
          )}
        >
          <XMarkIcon
            className={`h-3 w-3 text-${color}-700 hover:text-${color}-900`}
          />
        </button>
      )}
    </div>
  );
}

function SharedSpaceNotification({
  notification,
  handleClose,
  sharedSpaceId,
}: {
  notification: NotificationData;
  handleClose: () => void;
  sharedSpaceId: string;
}) {
  const { update, emoji, type } = getNotificationUpdate(notification);
  const navigate = useNavigate();
  const location = useLocation();
  const markSharedSpaceNotificationAsReadMutation =
    useMarkSharedSpaceNotificationAsRead(sharedSpaceId);
  const { slug } = useParams();

  return (
    <>
      {update && (
        <div
          className={clsx(
            "group relative z-0 flex  items-start overflow-x-clip px-4 py-2.5",
            notification.type === "task" ||
              notification.type === "journey" ||
              notification.type === "thread" ||
              notification.type === "thread message"
              ? "cursor-pointer hover:bg-gray-50"
              : "cursor-default"
          )}
          onClick={() => {
            if (slug) {
              if (notification.type === "task") {
                const searchParams = new URLSearchParams(location.search);
                searchParams.set("taskId", notification.objectId);
                navigate({
                  pathname: location.pathname,
                  search: searchParams.toString(),
                });
                handleClose();
              } else if (notification.type === "thread") {
                navigate(
                  `${slug}/projects/${notification.metadata.phaseId}/chat?chatId=${notification.objectId}`
                );
                handleClose();
              } else if (notification.type === "thread message") {
                navigate(
                  `${slug}/projects/${notification.metadata.phaseId}/chat?chatId=${notification.metadata.threadId}`
                );
                handleClose();
              } else if (notification.type === "journey") {
                navigate(`${slug}/projects/${notification.metadata.phaseId}`);
                handleClose();
              }
              markSharedSpaceNotificationAsReadMutation.mutate({
                update: {
                  _id: new ObjectID(notification._id),
                },
              });
            }
          }}
        >
          <div className="inline-flex items-start">
            <div
              className={clsx(
                "mt-[7px] h-1.5 w-1.5 rounded-full",
                !notification.isRead && "bg-primary"
              )}
            />
            <div className="ml-4 flex items-center whitespace-nowrap pt-0.5 text-left text-xs text-gray-400">
              <time
                className="w-[50px]"
                dateTime={dayjs(notification.createdAt).format("MMM DD")}
              >
                {dayjs(notification.createdAt).format("MMM DD")}
              </time>
              <time
                className="w-[60px]"
                dateTime={dayjs(notification.createdAt).format("MMM DD")}
              >
                {dayjs(notification.createdAt).format("h:mm A")}
              </time>
            </div>

            <div className="w-20 px-4 text-sm">
              <NotificationEntity notification={notification} />
            </div>
            <div className="flex min-w-0 flex-1 justify-between space-x-4 text-sm">
              {update}
            </div>
          </div>
          {!notification.isRead && (
            <div className="absolute right-2 top-1.5 hidden transition-all group-hover:block">
              <Button
                variant={"secondary"}
                size="sm"
                onClick={(e) => {
                  markSharedSpaceNotificationAsReadMutation.mutate({
                    update: {
                      _id: new ObjectID(notification._id),
                    },
                  });
                  e.stopPropagation();
                }}
              >
                Mark as read
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
}

function Notification({
  notification,
  handleClose,
  sharedSpaceId,
}: {
  notification: NotificationData;
  handleClose: () => void;
  sharedSpaceId?: string;
}) {
  const { update, emoji, type } = getNotificationUpdate(notification);
  console.log(update === null ? notification : "");
  const navigate = useNavigate();
  const location = useLocation();
  const markNotificationAsReadMutation = useMarkNotificationAsRead();

  return (
    <>
      {update && (
        <div
          className={clsx(
            "group relative z-0 flex  items-start overflow-x-clip px-4 py-2.5",
            notification.type === "task" ||
              notification.type === "journey" ||
              notification.type === "thread" ||
              notification.type === "thread message"
              ? "cursor-pointer hover:bg-gray-50"
              : "cursor-default"
          )}
          onClick={() => {
            if (notification.type === "task") {
              const searchParams = new URLSearchParams(location.search);
              searchParams.set("taskId", notification.objectId);
              navigate({
                pathname: location.pathname,
                search: searchParams.toString(),
              });
              handleClose();
            } else if (notification.type === "thread") {
              navigate(
                `/projects/${notification.metadata.phaseId}/chat?chatId=${notification.objectId}`
              );
              handleClose();
            } else if (notification.type === "thread message") {
              navigate(
                `/projects/${notification.metadata.phaseId}/chat?chatId=${notification.metadata.threadId}`
              );
              handleClose();
            } else if (notification.type === "journey") {
              navigate(`/projects/${notification.metadata.phaseId}`);
              handleClose();
            }
            markNotificationAsReadMutation.mutate({
              update: {
                _id: new ObjectID(notification._id),
              },
            });
          }}
        >
          <div className="inline-flex items-start">
            <div
              className={clsx(
                "mt-[7px] h-1.5 w-1.5 rounded-full",
                !notification.isRead && "bg-primary"
              )}
            />
            <div className="ml-4 flex items-center whitespace-nowrap pt-0.5 text-left text-xs text-gray-400">
              <time
                className="w-[50px]"
                dateTime={dayjs(notification.createdAt).format("MMM DD")}
              >
                {dayjs(notification.createdAt).format("MMM DD")}
              </time>
              <time
                className="w-[60px]"
                dateTime={dayjs(notification.createdAt).format("MMM DD")}
              >
                {dayjs(notification.createdAt).format("h:mm A")}
              </time>
            </div>

            <div className="w-28 px-4 text-sm">
              <NotificationEntity notification={notification} />
            </div>
            <div className="flex min-w-0 flex-1 justify-between space-x-4 text-sm">
              {update}
            </div>
          </div>
          {!notification.isRead && (
            <div className="absolute right-2 top-1.5 hidden transition-all group-hover:block">
              <Button
                variant={"secondary"}
                size="sm"
                onClick={(e) => {
                  markNotificationAsReadMutation.mutate({
                    update: {
                      _id: new ObjectID(notification._id),
                    },
                  });
                  e.stopPropagation();
                }}
              >
                Mark as read
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
}

function NotificationEntity({
  notification,
}: {
  notification: NotificationData;
}) {
  switch (notification.type) {
    case "task":
      return <Entity type="Task" color="blue" />;
    case "journey":
      return <Entity type="Project" color="pink" />;
    case "thread":
      return <Entity type="Chat" color="green" />;
    case "thread message":
      return <Entity type="Chat" color="green" />;
    case "shared space invite":
      return <Entity type="Invite" color="yellow" />;
    case "integration":
      return <Entity type="Integration" color="purple" />;
    case "email log":
      return <Entity type="Email" color="purple" />;
  }
  return null;
}
function getNotificationUpdate(notification: NotificationData): {
  update: React.ReactNode;
  emoji?: string;
  type?: {
    color: "red" | "green" | "blue" | "yellow" | "gray" | "indigo" | "pink";
    text: string;
  };
} {
  if (notification.type === "task") {
    if (notification.action === "CREATE") {
      return {
        update: (
          <div>
            <span className="text-gray-700">{`${notification.metadata.taskTitle} `}</span>
            <span className="text-gray-400">
              has been created & assigned to you
            </span>
          </div>
        ),
        type: {
          color: "blue",
          text: "Task",
        },
      };
    } else if (notification.action === "UPDATE") {
      if (notification.subAction === "overdue") {
        return {
          update: (
            <div>
              <span className="text-gray-700">{`${notification.metadata.taskTitle} `}</span>
              <span className="text-gray-400">is now overdue</span>
              {/* <span className="font-medium text-gray-700">{` ${notification.metadata.taskerName} `}</span> */}
            </div>
          ),
          emoji: "🛑",
        };
      } else if (notification.subAction === "due date change") {
        return {
          update: (
            <div>
              <span className="text-gray-700">{`${notification.metadata.taskTitle} `}</span>
              <span className="text-gray-400">had its due date changed</span>
              {/* <span className="font-medium text-gray-700">{` ${notification.metadata.taskerName} `}</span> */}
            </div>
          ),
          // emoji: "",
        };
      } else if (notification.subAction === "blocker marked") {
        return {
          update: (
            <div>
              <span className="text-gray-700">{`${notification.metadata.taskTitle} `}</span>
              <span className="text-gray-400">was marked as </span>
              <span className="text-gray-700">stuck</span>
              {/* <span className="text-red-600">blocked</span> */}
              <span className="text-gray-400"> by</span>
              <span className="text-gray-700">{` ${notification.metadata.reporterName} `}</span>
              {/* <span className="font-medium text-gray-700">{` ${notification.metadata.taskerName} `}</span> */}
            </div>
          ),
          // emoji: "",
        };
      } else if (notification.subAction === "tasker change") {
        return {
          update: (
            <div>
              <span className="text-gray-700">{`${notification.metadata.taskTitle} `}</span>
              <span className="text-gray-400">tasker changed from </span>
              <span className="text-gray-700">
                {notification.metadata.previousTaskerName}
              </span>
              {/* <span className="text-red-600">blocked</span> */}
              <span className="text-gray-400"> to </span>
              <span className="text-gray-700">
                {notification.metadata.newTaskerName}
              </span>
              {/* <span className="font-medium text-gray-700">{` ${notification.metadata.taskerName} `}</span> */}
            </div>
          ),
          // emoji: "",
        };
      }
      // else if (notification.subAction === "blocker resolved") {
      //   return {
      //     update: (
      //       <div>
      //       <span className="text-gray-400">Task</span>
      //         <span className="text-gray-700">{` ${notification.metadata.taskTitle}`}</span>
      //         <span className="text-gray-400">'s blocker was resolved by </span>
      //         <span className="font-medium text-gray-700">{` ${notification.metadata.resolverName} `}</span>
      //         {/* <span className="font-medium text-gray-700">{` ${notification.metadata.taskerName} `}</span> */}
      //       </div>
      //     ),
      //     // emoji: "",
      //   };
      // }
    }
  } else if (notification.type === "journey") {
    if (notification.action === "CREATE") {
      return {
        update: (
          <div>
            <span className="text-gray-700">{`${notification.metadata.journeyName} `}</span>
            <span className="text-gray-400">was launched with</span>
            <span className="text-gray-700">{` ${notification.metadata.taskCount} tasks `}</span>
            <span className="text-gray-400">assigned to you</span>
          </div>
        ),
        emoji: "🚀",
      };
    } else if (notification.action === "UPDATE") {
      if (notification.subAction === "completion") {
        return {
          update: (
            <div>
              <span className="font-medium text-gray-700">{`${notification.metadata.journeyName} `}</span>
              <span className="text-gray-400">was completed!</span>
            </div>
          ),
          emoji: "🔥",
        };
      }
    }
  } else if (notification.type === "thread") {
    if (notification.action === "CREATE") {
      return {
        update: (
          <div>
            <span className="text-gray-700">{`${notification.metadata.threadName} `}</span>
            <span className="text-gray-400">was started by</span>
            <span className="text-gray-700">{` ${notification.metadata.createdByName} `}</span>
          </div>
        ),
        emoji: "🧵",
      };
    }
  } else if (notification.type === "thread message") {
    if (notification.action === "CREATE") {
      return {
        update: (
          <div>
            <span className="text-gray-700">{`${notification.metadata.threadName} `}</span>
            <span className="text-gray-400">has a new message from </span>
            <span className="text-gray-700">{` ${notification.metadata.createdByName} `}</span>
          </div>
        ),
      };
    }
  } else if (notification.type === "shared space timeline stage") {
    if (notification.action === "UPDATE") {
      if (notification.subAction === "start") {
        return {
          update: (
            <div>
              <Entity type="Shared space stage" color="gray" />
              <span className="font-medium text-gray-700">{` ${notification.metadata.name} `}</span>
              <span className="text-gray-400">started</span>
            </div>
          ),
        };
      } else if (notification.subAction === "completion") {
        return {
          update: (
            <div>
              <Entity type="Shared space stage" color="gray" />
              <span className="font-medium text-gray-700">{` ${notification.metadata.name} `}</span>
              <span className="text-gray-400">completed</span>
            </div>
          ),
        };
      }
    }
  } else if (notification.type === "feedback") {
    if (notification.action === "CREATE") {
      return {
        update: (
          <div>
            <span className="text-gray-400">New </span>
            <Entity type="feedback" color="green" />
            <span className="font-medium text-gray-700">{` ${notification.metadata.feedbackName} `}</span>
            <span className="text-gray-400">created by</span>
            <span className="font-medium text-gray-700">{` ${notification.metadata.createdBy} `}</span>
          </div>
        ),
        type: {
          color: "indigo",
          text: "Feedback",
        },
      };
    } else if (notification.action === "UPDATE") {
      if (notification.subAction === "status change") {
        return {
          update: (
            <div>
              <Entity type="Feedback" color="green" />
              <span className="font-medium text-gray-700">{` ${notification.metadata.feedbackName} `}</span>
              <span className="text-gray-400">updated to</span>
              <span className="font-medium text-gray-700">{` ${notification.metadata.status}`}</span>
            </div>
          ),
        };
      }
    }
  } else if (notification.type === "shared space invite") {
    if (notification.action === "UPDATE") {
      if (notification.subAction === "accepted") {
        return {
          update: (
            <div>
              <span className="text-gray-700">{`${notification.metadata.userName} `}</span>
              <span className="text-gray-400">
                logged in for the first time
              </span>
            </div>
          ),
        };
      }
    }
  } else if (notification.type === "integration") {
    if (notification.action === "CRM_SYNC_COMPLETE") {
      return {
        update: (
          <div>
            <span className="text-gray-700">{`${
              notification.metadata.integrationId === "hubspot"
                ? "HubSpot"
                : "Salesforce"
            } `}</span>
            <span className="text-gray-400">data import completed</span>
          </div>
        ),
      };
    }
  } else if (notification.type === "email log") {
    if (notification.action === "UPDATE") {
      if (notification.subAction === "bounced") {
        return {
          update: (
            <div>
              <span className="text-gray-400">Email to</span>
              <span className="text-gray-700">{` ${notification.metadata.toEmail} `}</span>
              <span className="text-gray-400">in the project</span>
              <span className="text-gray-700">{` ${notification.metadata.projectName} `}</span>
              <span className="text-gray-400">has bounced.</span>
            </div>
          ),
        };
      } else if (notification.subAction === "delivery delayed") {
        return {
          update: (
            <div>
              <span className="text-gray-400">Email to</span>
              <span className="text-gray-700">{` ${notification.metadata.toEmail} `}</span>
              <span className="text-gray-400">in the project</span>
              <span className="text-gray-700">{` ${notification.metadata.projectName} `}</span>
              <span className="text-gray-400">has been delayed.</span>
            </div>
          ),
        };
      }
    }
  }
  // else if (notification.type === "shared space invite") {
  //   if (notification.action === "CREATE") {
  //     return {
  //       update: (
  //         <div>
  //           <Entity type="Project Invite" color="pink" />
  //           <span className="font-medium text-gray-400">{` sent to `}</span>
  //           <span className="font-medium text-gray-700">{`${notification.metadata.toEmail}`}</span>
  //         </div>
  //       ),
  //     };
  //   } else if (notification.action === "UPDATE") {
  //     if (notification.subAction === "accepted") {
  //       return {
  //         update: (
  //           <div>
  //             <Entity type="Project Invite" color="pink" />
  //             <span className="font-medium text-gray-400">{` accepted by `}</span>
  //             <span className="font-medium text-gray-700">{`${notification.metadata.userName}`}</span>
  //           </div>
  //         ),
  //       };
  //     }
  //   }
  // } else if (notification.type === "instance invite") {
  //   if (notification.action === "CREATE") {
  //     return {
  //       update: (
  //         <div>
  //           <Entity type="Team Invite" color="pink" />
  //           <span className="font-medium text-gray-400">{` sent to `}</span>
  //           <span className="font-medium text-gray-700">{`${notification.metadata.toEmail}`}</span>
  //         </div>
  //       ),
  //     };
  //   }
  //   else if (notification.action === "UPDATE") {
  //     if (notification.subAction === "accepted") {
  //       return {
  //         update: (
  //           <div>
  //             <Entity type="Team Invite" color="pink" />
  //             <span className="font-medium text-gray-400">{` accepted by `}</span>
  //             <span className="font-medium text-gray-700">{`${notification.metadata.userName}`}</span>
  //           </div>
  //         ),
  //       };
  //     }
  //   }
  // }
  return { update: null };
}

export function Entity({
  type,
  color,
}: {
  type: string;
  color: "green" | "blue" | "gray" | "red" | "purple" | "pink" | "yellow";
}) {
  return (
    <span
      className={clsx(
        "rounded px-1.5 py-[3px] text-xs",
        color === "green" && "bg-green-50 text-green-600",
        color === "blue" && "bg-blue-50 text-blue-600",
        color === "red" && "bg-red-50 text-red-600",
        color === "gray" && "bg-gray-50 text-gray-600",
        color === "purple" && "bg-purple-50 text-purple-600",
        color === "pink" && "bg-pink-50 text-pink-600",
        color === "yellow" && "bg-yellow-50 text-yellow-600"
      )}
    >
      {type}
    </span>
  );
}
