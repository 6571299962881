import { ChevronRightIcon } from "@radix-ui/react-icons";
import { ObjectID } from "bson";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetJourney } from "src/api/Services/Journeys/journeys";
import { PhaseData, useGetPhase } from "src/api/Services/SharedSpace/phases";
import { useGetPhaseThreads } from "src/api/Services/Threads/threads";
import { Loading } from "src/components/ui/loading";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/components/ui/tabs";
import { Overview } from "../Homebase/Homebase";
import { JourneyContextProvider } from "../Services/Journeys/Journey/JourneyContextProvider";
import JourneyRun from "../Services/Journeys/Journey/JourneyRun/JourneyRun";
import PhaseThreads from "../Services/SharedSpace/Phases/Tabs/Threads";
import PhaseResources from "../Services/SharedSpace/Phases/Tabs/resources/resources";
import { ResourcesContextProvider } from "../Services/SharedSpace/Phases/Tabs/resources/resources-context-provider";

type Props = {};

export default function ExternalProject({}: Props) {
  const { phaseId, slug } = useParams();
  const { data: phase } = useGetPhase(String(phaseId));

  return (
    <div className="flex h-full flex-col">
      <div className="h-12 items-center justify-between space-y-2 border-b border-gray-200/[.65] px-8 sm:flex sm:space-y-0">
        <div className="flex items-center space-x-2 text-sm">
          <Link to={`/${slug}/projects`}>
            <div className="text-gray-800">{"Projects"}</div>
          </Link>
          <ChevronRightIcon className="h-3 w-3 text-gray-500" />
          <div className="cursor-default text-gray-800">{phase?.name}</div>
        </div>
      </div>
      {phase ? <Project phase={phase} /> : <Loading className="mt-[30vh]" />}
    </div>
  );
}

function Project({ phase }: { phase: PhaseData }) {
  const { journey } = useGetJourney(new ObjectID(phase.journey._id));
  const { tab, slug } = useParams();
  const { data: threads } = useGetPhaseThreads(String(phase._id));
  const navigate = useNavigate();
  const threadCount =
    threads?.reduce((acc, t) => acc + t.otherDetails.unreadMessagesCount, 0) ??
    0;

  if (!tab) {
    if (slug) {
      navigate(`/${slug}/projects/${phase._id}/home`, { replace: true });
    }
  } else {
    if (
      tab !== "home" &&
      tab !== "tasks" &&
      tab !== "chat" &&
      tab !== "resources" &&
      tab !== "notes"
    ) {
      if (slug) {
        navigate(`/${slug}/projects/${phase._id}/home`, {
          replace: true,
        });
      }
    }
  }

  return (
    <>
      {journey && (
        <Tabs
          defaultValue="home"
          className="flex flex-1 flex-col overflow-auto pt-3"
          value={tab}
          onValueChange={(value) => {
            if (slug) {
              navigate(`/${slug}/projects/${phase._id}/${value}`, {
                replace: true,
              });
            }
          }}
        >
          <TabsList className="shrink-0 px-8 border-gray-200/70">
            <TabsTrigger className="font-normal" value="home">Home</TabsTrigger>
            <TabsTrigger className="font-normal" value="tasks">Tasks</TabsTrigger>
            <TabsTrigger className="font-normal" value="chat">
              <div className="inline-flex space-x-2">
                <span>Chat</span>
                {threadCount > 0 && (
                  <span
                    className={
                      "ml-3 hidden h-5 min-w-[18px] rounded bg-embedPrimary py-0.5 px-1 text-xs font-medium text-white data-[state=active]:bg-primary-100 data-[state=active]:text-primary-600 md:inline-block"
                    }
                  >
                    {threadCount}
                  </span>
                )}
              </div>
            </TabsTrigger>
            <TabsTrigger className="font-normal" value="resources">Resources</TabsTrigger>
          </TabsList>
          <div className="flex-1 overflow-auto">
            <TabsContent value="home" className="h-full px-8 pt-6">
              <Overview phase={phase} />
            </TabsContent>
            <TabsContent value="tasks" className="h-full overflow-auto">
              <div className="col-span-8 flex h-full flex-1 flex-col overflow-auto">
                <JourneyContextProvider isExternal={true}>
                  <JourneyRun journeyId={journey._id.toString()} />
                </JourneyContextProvider>
              </div>
            </TabsContent>
            <TabsContent value="chat" className="h-full">
              <PhaseThreads isInternal={false} threads={threads ?? []} />
            </TabsContent>
            <TabsContent value="resources">
              <ResourcesContextProvider isExternal={true}>
                <PhaseResources phaseId={phase._id} />
              </ResourcesContextProvider>
            </TabsContent>
          </div>
        </Tabs>
      )}
    </>
  );
}
