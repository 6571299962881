import React from 'react'
import {
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

type Props = {}

export const Loading = (props: Props) => {
  return (
    <Container maxWidth="sm">
      <Grid container alignItems="center" justifyContent={"center"}>
        <Grid item xs={12} marginTop={20}>
          <Stack spacing={3} alignItems="center">
          <div className="h-8 w-8 animate-spin rounded-full border-4 border-gray-200 border-t-primary" />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  )
}