import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "src/utils/ui/ui";

const countVariants = cva(
  "min-w-[15px] flex h-[17px] items-center justify-center rounded px-1 text-center text-[10px] text-gray-700 transition-all",
  {
    variants: {
      variant: {
        gray: "bg-gray-200 text-gray-700",
        primary: "bg-primary-main text-white",
        primaryLight: "bg-primary/20 text-primary"
      },
    },
    defaultVariants: {
      variant: "gray",
    },
  }
);

export interface CountProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof countVariants> {}

function Count({ className, variant, ...props }: CountProps) {
  return (
    <div className={cn(countVariants({ variant }), className)} {...props} />
  );
}

export { Count, countVariants };

// type Props = { count: number };

// export default function Count({ count }: Props) {
//   return (
//     <div className="">
//       {count}
//     </div>
//   );
// }
