import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { useRealmApp } from "src/store/RealmApp";
import { useAccessToken } from "../useAccessToken";

const noteKeys = {
  taskNotes: (taskId: string) => ["notes", "task", taskId] as const,
  phaseNotes: (phaseId: string) => ["notes", "phase", phaseId] as const,
  note: (noteId: string) => ["notes", noteId] as const,
};

export type NoteData = {
  _id: string;
  name: string;
  description: string;
  content: string;
  isVisible?: boolean;
  phaseId?: string;
  task?: {
    _id: string;
    title: string;
  };
  source?: "hubspot" | "salesforce";
  crmId?: string;
  sharedSpaceId?: string;
  instanceId: string;
  readOnly?: boolean;
  createdBy?: {
    _id: string;
    name: string;
    email: string;
  };
  createdAt: Date;
  updatedAt: Date;
};

export const useGetTaskNotes = (taskId: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    noteKeys.taskNotes(taskId),
    async (): Promise<NoteData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/notes`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            taskId,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};

export const useGetPhaseNotes = (phaseId: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    noteKeys.phaseNotes(phaseId),
    async (): Promise<NoteData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/notes`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            phaseId,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};

export const useUpdateNote = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async ({
      params,
      metadata,
    }: {
      params: {
        _id: string;
        name?: string;
        description?: string;
        content?: string;
        isVisible?: boolean;
      };
      metadata?: {
        taskId?: string;
        phaseId?: string;
      };
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/updateNote`,
        params,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onMutate: async (variables) => {
      const taskNoteKey = noteKeys.taskNotes(variables.metadata?.taskId ?? "");
      const phaseNoteKey = noteKeys.phaseNotes(
        variables.metadata?.phaseId ?? ""
      );
      const previousTaskNotes =
        queryClient.getQueryData<NoteData[]>(taskNoteKey);
      const previousPhaseNotes =
        queryClient.getQueryData<NoteData[]>(phaseNoteKey);
      const newTaskNotes = previousTaskNotes?.map((note) => {
        if (note._id === variables.params._id) {
          return {
            ...note,
            ...variables.params,
          };
        }
        return note;
      });
      const newPhaseNotes = previousPhaseNotes?.map((note) => {
        if (note._id === variables.params._id) {
          return {
            ...note,
            ...variables.params,
          };
        }
        return note;
      });
      queryClient.setQueryData(taskNoteKey, newTaskNotes);
      queryClient.setQueryData(phaseNoteKey, newPhaseNotes);
      return { previousTaskNotes, previousPhaseNotes };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(
        noteKeys.taskNotes(variables.metadata?.taskId ?? ""),
        context?.previousTaskNotes
      );
      queryClient.setQueryData(
        noteKeys.phaseNotes(variables.metadata?.phaseId ?? ""),
        context?.previousPhaseNotes
      );
      snackbarCtx.showSnackbar("Error updating note", "error");
    },
    onSuccess: (data, variables) => {
      if (variables.metadata?.taskId) {
        queryClient.invalidateQueries(
          noteKeys.taskNotes(variables.metadata.taskId)
        );
      }
      if (variables.metadata?.phaseId) {
        queryClient.invalidateQueries(
          noteKeys.phaseNotes(variables.metadata.phaseId)
        );
      }
    },
  });
};

export const useCreatePhaseNote = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async ({
      params,
    }: {
      params: {
        name: string;
        description: string;
        content: string;
        isVisible: boolean;
        phaseId: string;
      };
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/note`,
        params,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onMutate: async (variables) => {
      const phaseNoteKey = noteKeys.phaseNotes(variables.params.phaseId);
      const previousPhaseNotes =
        queryClient.getQueryData<NoteData[]>(phaseNoteKey);
      queryClient.setQueryData(phaseNoteKey, [
        ...previousPhaseNotes!,
        {
          ...variables.params,
          _id: "",
          createdBy: {
            _id: app.currentUser?.id,
            name: app.currentUser?.name ?? "",
          },
          createdAt: new Date(),
          updatedAt: new Date(),
          instanceId: "",
        },
      ]);
      return { previousPhaseNotes };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(
        noteKeys.phaseNotes(variables.params.phaseId),
        context?.previousPhaseNotes
      );
      snackbarCtx.showSnackbar("Error creating note", "error");
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(
        noteKeys.phaseNotes(variables.params.phaseId)
      );
    },
  });
};

export const useCreateTaskNote = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async ({
      params,
      metadata,
    }: {
      params: {
        name: string;
        description: string;
        content: string;
        isVisible: boolean;
        taskId: string;
      };
      metadata?: {
        phaseId?: string;
      };
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/note`,
        params,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onMutate: async (variables) => {
      const taskNoteKey = noteKeys.taskNotes(variables.params.taskId);
      const previousTaskNotes =
        queryClient.getQueryData<NoteData[]>(taskNoteKey);
      queryClient.setQueryData(taskNoteKey, [
        ...previousTaskNotes!,
        {
          ...variables.params,
          _id: "",
          createdBy: {
            _id: app.currentUser?.id,
            name: app.currentUser?.name ?? "",
          },
          createdAt: new Date(),
          updatedAt: new Date(),
          instanceId: "",
        },
      ]);
      return { previousTaskNotes };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(
        noteKeys.taskNotes(variables.params.taskId),
        context?.previousTaskNotes
      );
      snackbarCtx.showSnackbar("Error creating note", "error");
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(
        noteKeys.taskNotes(variables.params.taskId)
      );
      if (variables.metadata?.phaseId) {
        queryClient.invalidateQueries(
          noteKeys.phaseNotes(variables.metadata.phaseId)
        );
      }
    },
  });
};

export const useDeleteNote = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async ({
      note,
      metadata,
    }: {
      note: {
        _id: string;
      };
      metadata?: {
        taskId?: string;
        phaseId?: string;
      };
    }): Promise<{
      message: string;
    }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.delete(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/note`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            ...note,
          },
        }
      );
      return res.data;
    },
    onMutate: async (variables) => {
      const taskNoteKey = noteKeys.taskNotes(variables.metadata?.taskId ?? "");
      const phaseNoteKey = noteKeys.phaseNotes(
        variables.metadata?.phaseId ?? ""
      );
      const previousTaskNotes =
        queryClient.getQueryData<NoteData[]>(taskNoteKey);
      const previousPhaseNotes =
        queryClient.getQueryData<NoteData[]>(phaseNoteKey);
      const newTaskNotes = previousTaskNotes?.filter(
        (note) => note._id !== variables.note._id
      );
      const newPhaseNotes = previousPhaseNotes?.filter(
        (note) => note._id !== variables.note._id
      );
      queryClient.setQueryData(taskNoteKey, newTaskNotes);
      queryClient.setQueryData(phaseNoteKey, newPhaseNotes);
      return { previousTaskNotes, previousPhaseNotes };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(
        noteKeys.taskNotes(variables.metadata?.taskId ?? ""),
        context?.previousTaskNotes
      );
      queryClient.setQueryData(
        noteKeys.phaseNotes(variables.metadata?.phaseId ?? ""),
        context?.previousPhaseNotes
      );
      snackbarCtx.showSnackbar("Error deleting note", "error");
    },
    onSuccess: (data, variables) => {
      if (variables.metadata?.taskId) {
        queryClient.invalidateQueries(
          noteKeys.taskNotes(variables.metadata.taskId)
        );
      }
      if (variables.metadata?.phaseId) {
        queryClient.invalidateQueries(
          noteKeys.phaseNotes(variables.metadata.phaseId)
        );
      }
    },
  });
};
