import "@tanstack/react-table";
import {
  FilterFn,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ProjectsFilter,
  useGetOnboardingProjects,
} from "src/api/Services/Projects/projects";
import { columns } from "./columns";

import { rankItem } from "@tanstack/match-sorter-utils";

export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

type Props = {
  filter: ProjectsFilter;
  search: string;
  setSearch: (search: string) => void;
  onClick: (projectId: string) => void;
};

// type ProjectsUser =
//   | {
//       type: "all" | "user";
//       user?: UserResponseObj;
//     }
//   | undefined;

export default function ProjectsTable({ filter, search, setSearch, onClick }: Props) {
  const { data: projects } = useGetOnboardingProjects(filter);
  const [sorting, setSorting] = useState<SortingState>([]);

  // only projects with names that match the search string
  // const filteredProjects = projects?.filter((p) =>
  //   p.name.toLowerCase().includes(search.toLowerCase())
  // );

  const table = useReactTable({
    data: projects ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      globalFilter: search,
    },
    globalFilterFn: fuzzyFilter,
    onSortingChange: setSorting,
    onGlobalFilterChange: setSearch,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  // const table = useReactTable({
  //   data:
  //     projects ?? [],
  //   columns,
  //   getCoreRowModel: getCoreRowModel(),
  //   state: {
  //     sorting,
  //   },
  //   onSortingChange: setSorting,
  //   getSortedRowModel: getSortedRowModel(),
  // });

  return (
    <div className="flex-1 overflow-auto">
      <div className="max-h-full max-w-full overflow-auto">
        <table
          style={{ borderCollapse: "separate", borderSpacing: 0 }}
          className="min-w-[600px]"
        >
          <thead className="overflow-auto">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    scope="col"
                    className={
                      "sticky top-0 z-10 h-11 whitespace-nowrap border-b border-r border-gray-200/70 bg-gray-50 px-6 text-left text-xs font-medium uppercase tracking-wide text-gray-400 first:left-0 first:z-20 first:pl-8 last:w-full"
                    }
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {!projects ? (
              <tr>
                {/* <td className="inset-0 flex items-center justify-center p-4 pl-8"> */}
                <div className="sticky left-0 z-10 p-4 pl-8 text-gray-400">
                  Loading...
                </div>
                {/* </td> */}
              </tr>
            ) : (
              <>
                {projects.length === 0 ? (
                  <tr>
                    <div className="sticky left-0 z-10 whitespace-nowrap p-4 pl-8 text-gray-400">
                      No projects
                    </div>
                    {/* <td className="inset-0 flex items-center justify-center p-4">
                    </td> */}
                  </tr>
                ) : (
                  <>
                    {table.getRowModel().rows.map((row) => (
                      <tr
                        key={row.id}
                        className="group relative rounded-lg hover:cursor-pointer hover:bg-gray-50"
                        onClick={() => {
                          onClick(row.original._id);
                        }}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            className={
                              "whitespace-nowrap border-r border-b border-gray-100 px-6 py-3 text-sm text-gray-500 first:sticky first:left-0 first:z-10 first:bg-white first:pr-6 first:font-medium first:text-gray-900 first:group-hover:bg-gray-50 first:sm:pl-8"
                            }
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

// function FiltersAndTable() {
//   const { data } = useGetOboardingOverviewForUser();
//   const projectTableCtx = useProjectTableContext();
//   const filteredData =
//     data
//       ?.filter((d) => {
//         if (projectTableCtx.state.owner === "all") {
//           return true;
//         } else {
//           return d.ownerUser._id === projectTableCtx.state.owner;
//         }
//       })
//       .filter((p) => {
//         if (projectTableCtx.state.status === "all") {
//           return true;
//         } else {
//           return p.status === projectTableCtx.state.status;
//         }
//       }) ?? [];
//   return (
//     <>
//       {!!data ? (
//         <div className="flex flex-1 flex-col space-y-4 overflow-auto pt-3">
//           <div className="flex justify-between px-8">
//             <div className="flex gap-3">
//               <OwnerFilter />
//               <StatusFilter />
//             </div>
//           </div>
//           <Table projects={filteredData} />
//         </div>
//       ) : (
//         <div className="flex h-full items-center justify-center">
//           <Loading />
//         </div>
//       )}
//     </>
//   );
// }

// function OwnerFilter() {
//   const { data } = useGetOboardingOverviewForUser();
//   const projectTableCtx = useProjectTableContext();
//   // users
//   let users = data?.map((p) => p.ownerUser);
//   // only keep unique users
//   users = users?.filter(
//     (user, index, self) => index === self.findIndex((u) => u?._id === user?._id)
//   );
//   return (
//     <NewSelect
//       value={projectTableCtx.state.owner}
//       onValueChange={(v) => {
//         projectTableCtx.dispatch({
//           type: "owner_update",
//           owner: v,
//         });
//       }}
//     >
//       <SelectTrigger
//         className={clsx(
//           `h-8 w-fit max-w-full gap-2 py-0 px-2`,
//           projectTableCtx.state.owner === "all"
//             ? "bg-white"
//             : "divide-x-primary border-primary text-primary hover:bg-primary-100"
//         )}
//       >
//         <div
//           className={clsx(
//             "flex h-full items-center gap-2 divide-x",
//             projectTableCtx.state.owner === "all" ? "" : "divide-primary/60"
//           )}
//         >
//           <div className="inline-flex items-center space-x-1">
//             <UserIcon className="h-4 w-4" />
//             <div>Owner</div>
//           </div>
//           <div className="inline-flex h-full items-center pl-2">
//             <SelectValue className="truncate text-xs">
//               {projectTableCtx.state.owner === "all"
//                 ? "All"
//                 : users?.find(
//                     (u) => u._id.toString() === projectTableCtx.state.owner
//                   )?.name}
//             </SelectValue>
//           </div>
//         </div>
//       </SelectTrigger>
//       <SelectContent className="min-w-[120px]">
//         <SelectGroup>
//           <SelectItem
//             value={"all"}
//             onSelect={() => {
//               projectTableCtx.dispatch({
//                 type: "owner_update",
//                 owner: "all",
//               });
//             }}
//           >
//             All
//           </SelectItem>
//           {users?.map((u) => (
//             <SelectItem key={u._id?.toString()} value={u._id?.toString() ?? ""}>
//               <span>{u.name}</span>
//             </SelectItem>
//           ))}
//         </SelectGroup>
//       </SelectContent>
//     </NewSelect>
//   );
// }

function Loading() {
  return (
    <div className="flex h-full w-full items-center justify-center pb-12 pt-6">
      <div className="h-8 w-8 animate-spin rounded-full border-4 border-gray-200 border-t-primary" />
    </div>
  );
}

// function Table({ projects }: { projects: OnboardingOverviewData[] }) {
//   // console.log(data);
//   return <TableWithFilters columns={columns} data={projects} />;
// }

// function TablePreProcessing({ userId }: { userId: string }) {
//   const { data } = useGetOboardingOverviewForUser(userId);
//   // console.log(data);
//   return <TableWithFilters columns={columns} data={data} />;
// }

// function TableWithAllUsers() {
//   const { data } = useGetOboardingOverviewForUser();
//   // console.log(data);
//   return <TableWithFilters columns={columns} data={data} />;
// }

// function Table({
//   data,
//   columns,
// }: {
//   data: OnboardingOverviewData[] | undefined;
//   columns: ColumnDef<OnboardingOverviewData>[];
// }) {
//   const [sorting, setSorting] = useState<SortingState>([]);
//   const table = useReactTable({
//     data: data ?? [],
//     columns,
//     getCoreRowModel: getCoreRowModel(),
//     state: {
//       sorting,
//     },
//     onSortingChange: setSorting,
//     getSortedRowModel: getSortedRowModel(),
//   });

//   const navigate = useNavigate();

//   return (
//     <div className="flex-1 overflow-auto">
//       <div className="max-h-full max-w-full overflow-auto border-y">
//         <table
//           style={{ borderCollapse: "separate", borderSpacing: 0 }}
//           className="min-w-[600px]"
//         >
//           <thead className="overflow-auto">
//             {table.getHeaderGroups().map((headerGroup) => (
//               <tr key={headerGroup.id} className="border-b">
//                 {headerGroup.headers.map((header) => (
//                   <th
//                     key={header.id}
//                     scope="col"
//                     className={
//                       "sticky top-0 z-10 whitespace-nowrap border-b border-r bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 first:left-0 first:z-20 first:pl-8 last:w-full"
//                     }
//                   >
//                     {header.isPlaceholder
//                       ? null
//                       : flexRender(
//                           header.column.columnDef.header,
//                           header.getContext()
//                         )}
//                   </th>
//                 ))}
//               </tr>
//             ))}
//           </thead>
//           <tbody className="divide-y divide-gray-200 bg-white">
//             {!data ? (
//               <tr>
//                 {/* <td className="inset-0 flex items-center justify-center p-4 pl-8"> */}
//                 <div className="sticky left-0 z-10 p-4 pl-8 text-gray-400">
//                   Loading...
//                 </div>
//                 {/* </td> */}
//               </tr>
//             ) : (
//               <>
//                 {data.length === 0 ? (
//                   <tr className="border-r">
//                     <div className="sticky left-0 z-10 whitespace-nowrap px-6 py-4 pl-8 text-gray-400">
//                       No projects
//                     </div>
//                     {/* <td className="inset-0 flex items-center justify-center p-4">
//                     </td> */}
//                   </tr>
//                 ) : (
//                   <>
//                     {table.getRowModel().rows.map((row) => (
//                       <tr
//                         key={row.id}
//                         className="group relative rounded-lg hover:cursor-pointer hover:bg-gray-50"
//                         onClick={() => {
//                           navigate(`${row.original._id}`);
//                         }}
//                       >
//                         {row.getVisibleCells().map((cell) => (
//                           <td
//                             key={cell.id}
//                             className={
//                               "whitespace-nowrap border-r border-b px-6 py-3 text-sm text-gray-500 first:sticky first:left-0 first:z-10 first:pr-6 first:font-medium first:text-gray-900 first:group-hover:bg-gray-100 first:sm:pl-8"
//                             }
//                           >
//                             {flexRender(
//                               cell.column.columnDef.cell,
//                               cell.getContext()
//                             )}
//                           </td>
//                         ))}
//                       </tr>
//                     ))}
//                   </>
//                 )}
//               </>
//             )}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }

// function TestingTable() {
//   type Data = {
//     name: string;
//     age: number;
//   };
//   const [sorting, setSorting] = useState<SortingState>([]);
//   const data = [
//     { name: "a", age: 1 },
//     { name: "b", age: 2 },
//   ];
//   const columns: ColumnDef<Data>[] = [
//     {
//       header: "Name",
//       accessorFn: ({ name }) => name,
//       cell: ({ getValue }) => {
//         return <div>{String(getValue()) + "ABC"}</div>;
//       },
//       // cell: ({ row, getValue, cell }) => {
//       //   return <div>{cell.getValue('name')}</div>;
//       // },
//     },
//     // {
//     //   accessorKey: "age",
//     //   header: () => "Age",
//     // },
//     {
//       id: "age",
//       header: ({ column }) => {
//         // console.log("age");
//         // return "Age";
//         const isSorted = column.getIsSorted();
//         console.log(isSorted);
//         if (isSorted) {
//           if (isSorted === "asc") {
//             return (
//               <div
//                 className="-ml-3 flex cursor-pointer space-x-2 rounded-md px-3 py-2 hover:bg-gray-200"
//                 onClick={() => {
//                   column.toggleSorting(true);
//                 }}
//               >
//                 <span>Started Date</span>
//                 {" ASC"}
//               </div>
//             );
//           } else {
//             return (
//               <div
//                 className="-ml-3 flex cursor-pointer space-x-2 rounded-md px-3 py-2 hover:bg-gray-200"
//                 onClick={() => {
//                   column.toggleSorting(false);
//                 }}
//               >
//                 <span>Started Date</span>
//                 {" DSC"}
//               </div>
//             );
//           }
//         }
//         return (
//           <div
//             className="-ml-3 flex cursor-pointer space-x-2 rounded-md px-3 py-2 hover:bg-gray-200"
//             onClick={() => {
//               if (column.getIsSorted()) {
//                 column.toggleSorting(false);
//                 // column.toggleSorting(false);
//               } else {
//                 column.toggleSorting(true);
//               }
//             }}
//           >
//             <span>Started Date</span>
//             {column.getIsSorted() && "Sorted"}
//           </div>
//         );
//       },
//       // cell: ({ row }) => row.original.age,
//       accessorFn: ({ age }) => age + 5,
//       cell: ({ getValue }) => {
//         return <div>{String(getValue()) + "ABC"}</div>;
//       },
//       sortingFn: (rowA, rowB) => {
//         return rowA.original.age - rowB.original.age;
//       },
//       // sortingFn: "alphanumeric",
//     },
//   ];

//   return <DisplayTable data={data} columns={columns} onRowClick={() => {}} />;

//   const table = useReactTable({
//     data,
//     columns,
//     state: {
//       sorting,
//     },
//     onSortingChange: setSorting,
//     getCoreRowModel: getCoreRowModel(),
//     getSortedRowModel: getSortedRowModel(),
//     debugTable: true,
//   });

//   return (
//     <div className="w-fit overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
//       <table className="min-w-[600px]  divide-y divide-gray-300">
//         <thead className="bg-gray-50">
//           {table.getHeaderGroups().map((headerGroup) => (
//             <tr key={headerGroup.id}>
//               {headerGroup.headers.map((header) => (
//                 <th
//                   key={header.id}
//                   scope="col"
//                   className={
//                     "px-4 text-left text-xs font-medium uppercase tracking-wide text-gray-500 first:pl-4 first:pr-3 first:sm:pl-6"
//                   }
//                 >
//                   {header.isPlaceholder
//                     ? null
//                     : flexRender(
//                         header.column.columnDef.header,
//                         header.getContext()
//                       )}
//                 </th>
//               ))}
//             </tr>
//           ))}
//         </thead>
//         <tbody className="divide-y divide-gray-200 bg-white">
//           {table.getRowModel().rows.map((row) => (
//             <tr
//               key={row.id}
//               className="hover:cursor-pointer hover:bg-gray-100"
//               onClick={() => {
//                 // onRowClick(row.original);
//               }}
//             >
//               {row.getVisibleCells().map((cell) => (
//                 <td
//                   key={cell.id}
//                   className={
//                     "whitespace-nowrap px-4 text-sm text-gray-500 first:pl-4 first:pr-3 first:font-medium first:text-gray-900 first:sm:pl-6 "
//                   }
//                 >
//                   {flexRender(cell.column.columnDef.cell, cell.getContext())}
//                 </td>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// }
