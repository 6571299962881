import {
  ChatBubbleLeftEllipsisIcon,
  InformationCircleIcon,
  UserIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  CaretSortIcon,
  CheckIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from "@radix-ui/react-icons";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import Typography from "@tiptap/extension-typography";
import { EditorContent, PureEditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import {
  BarChart,
  DonutChart,
  Flex,
  Legend,
  LineChart,
  ProgressBar,
  Text,
  Title,
} from "@tremor/react";
import { ObjectID } from "bson";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BooleanCustomFieldValueData,
  CurrencyCustomFieldValueData,
  CustomFieldValueData,
  MultiSelectCustomFieldValueData,
  SelectCustomFieldValueData,
} from "src/api/CustomFields/custom-fields";
import {
  JourneyData,
  JourneyTaskProgress,
  useGetJourneyStages,
  useGetTasksProgressForJourney,
} from "src/api/Services/Journeys/journeys";
import { useGetProjectMembers } from "src/api/Services/Projects/projects";
import {
  InternalPhaseUpdateData,
  PhaseData,
  PhaseStatus,
  useCreateInternalUpdateForPhase,
  useGetKeyDatesForPhase,
  useGetPhaseInternalUpdates,
  useUpdatePhase,
} from "src/api/Services/SharedSpace/phases";
import {
  TaskData,
  useGetPhaseTasks,
  useUpdateTask,
} from "src/api/Services/Tasks/tasks";
import { Badge } from "src/components/ui/badge";
import { Button, Button as NewButton } from "src/components/ui/button";
import { Calendar } from "src/components/ui/calendar";
import { Checkbox } from "src/components/ui/checkbox";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "src/components/ui/command";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import TiptapEditor from "src/components/ui/editor/tiptap-editor";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "src/components/ui/hover-card";
import { Loading } from "src/components/ui/loading";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { Stage } from "src/pages/Homebase/Homebase";
import { StatusSelect } from "src/pages/Services/Tasks/Task";
import { useStatusGroups } from "src/pages/status-groups/status-groups-context-provider";
import { useRealmApp } from "src/store/RealmApp";
import { getTimeDifference } from "src/utils/functions/dateTime";

type Props = {
  journey: JourneyData;
  phase: PhaseData;
};

export default function Overview({ journey, phase }: Props) {
  const { data: tasks } = useGetPhaseTasks(phase._id);
  const tasksOverdue = tasks?.filter(
    (t) =>
      (t.status === "To-do" ||
        t.status === "In Progress" ||
        t.status === "Stuck") &&
      t.dueDate &&
      dayjs(t.dueDate).isBefore(dayjs())
  );
  const app = useRealmApp();
  const { data: keyDates } = useGetKeyDatesForPhase(phase._id);
  const myActiveTasks = tasks?.filter(
    (t) =>
      t.tasker.userType === "fullUser" &&
      t.tasker?._id === app.currentUser?.customData?._id &&
      (t.status === "To-do" ||
        t.status === "In Progress" ||
        t.status === "Stuck") &&
      !!t.dueDate
  );
  const internalActiveTasks = tasks?.filter(
    (t) =>
      t.taskerType === "internal" &&
      (t.status === "To-do" ||
        t.status === "In Progress" ||
        t.status === "Stuck") &&
      !!t.dueDate
  );
  const externalActiveTasks = tasks?.filter(
    (t) =>
      t.taskerType === "external" &&
      (t.status === "To-do" ||
        t.status === "In Progress" ||
        t.status === "Stuck") &&
      !!t.dueDate
  );
  const completedTasks = tasks?.filter(
    (t) => t.status === "Completed" || t.status === "Canceled"
  );
  const toDoTasksActive = tasks?.filter(
    (t) => t.status === "To-do" && t.dueDate
  );
  const inProgressTasks = tasks?.filter((t) => t.status === "In Progress");
  const stuckTasks = tasks?.filter((t) => t.status === "Stuck");
  const inactiveTasks = tasks?.filter((t) => !t.dueDate);
  const progress = Math.round(
    ((completedTasks?.length ?? 0) / (!tasks?.length ? 1 : tasks.length)) * 100
  );
  const navigate = useNavigate();
  const { data: internalUpdates } = useGetPhaseInternalUpdates(phase._id);
  const createInternalUpdateMutation = useCreateInternalUpdateForPhase(
    phase._id
  );
  console.log(internalUpdates);
  const editor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
        autolink: true,
      }),
      Typography,
      Placeholder.configure({
        placeholder: "Write a project update...",
        showOnlyWhenEditable: false,
      }),
    ],
    content: "",
  });

  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

  const [status, setStatus] = useState("On-Track");

  useEffect(() => {
    if (updateDialogOpen) {
      editor?.commands.focus();
    }
  }, [updateDialogOpen]);

  useEffect(() => {
    if (createInternalUpdateMutation.isSuccess) {
      setUpdateDialogOpen(false);
    }
  }, [createInternalUpdateMutation.isSuccess]);

  return (
    <div className="flex h-full">
      <div className="flex flex-1 flex-wrap content-start gap-4 overflow-auto px-8 py-6">
        <div className="h-fit w-[420px] shrink-0 rounded-md border border-gray-200/70 p-6 shadow-sm">
          <div className="cursor-default text-base font-medium leading-none text-gray-800">
            Tasks Breakdown
          </div>
          <div className="space-y-2 pt-6">
            {/* <div className="flex items-center">
              <div className="w-32 cursor-default text-[13px] text-gray-500">
                Progress
              </div>
              {!!tasks ? (
                <div className="flex flex-1 items-center space-x-3">
                  <div
                    className={clsx(
                      "h-2 min-w-[40px] max-w-[240px] flex-1 shrink-0 overflow-hidden rounded-sm",
                      (completedTasks ?? []).length === 0
                        ? "border-gray-300 bg-gray-200/70"
                        : "border-green-200 bg-green-200/70"
                    )}
                  >
                    <div
                      style={{
                        width:
                          ((completedTasks?.length ?? 0) * 100) / tasks.length +
                          "%",
                      }}
                      className="h-full rounded-sm bg-green-500"
                    />
                  </div>
                  <div className="text-sm font-semibold text-gray-600">
                    {progress.toFixed(0) + "%"}
                  </div>
                </div>
              ) : (
                <div className="h-4 w-20 animate-pulse rounded bg-gray-200" />
              )}
            </div> */}
            <div className="flex items-center">
              <div className="w-32 cursor-default text-[13px] text-gray-500">
                All Tasks
              </div>
              {!!tasks ? (
                <TasksBreakdown tasks={tasks} />
              ) : (
                <div className="h-4 w-20 animate-pulse rounded bg-gray-200" />
              )}
            </div>

            {phase.collaborationType === "external" && (
              <>
                <div className="flex items-center">
                  <div className="w-32 cursor-default text-[13px] text-gray-500">
                    Intenal Tasks
                  </div>
                  {!!tasks ? (
                    <TasksBreakdown
                      tasks={tasks.filter((t) => t.taskerType === "internal")}
                    />
                  ) : (
                    <div className="h-4 w-20 animate-pulse rounded bg-gray-200" />
                  )}
                </div>
                <div className="flex items-center">
                  <div className="w-32 cursor-default text-[13px] text-gray-500">
                    External Tasks
                  </div>
                  {!!tasks ? (
                    <TasksBreakdown
                      tasks={tasks.filter((t) => t.taskerType === "external")}
                    />
                  ) : (
                    <div className="h-4 w-20 animate-pulse rounded bg-gray-200" />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="h-fit w-[480px] shrink-0 rounded-md border border-gray-200/70 pb-2 pt-3.5 shadow-sm">
          <div className="flex items-center justify-between px-6">
            <div className="cursor-default text-base font-medium leading-none text-gray-800">
              Updates
            </div>
            <Dialog
              open={updateDialogOpen}
              onOpenChange={(open) => {
                setUpdateDialogOpen(open);
              }}
            >
              <DialogTrigger asChild>
                <Button variant={"secondary"} size={"sm"}>
                  Add Update
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>New Update</DialogTitle>
                  {/* <DialogDescription>Details for your new stage</DialogDescription> */}
                </DialogHeader>
                <div className="grid gap-4 py-4">
                  <div className="space-y-4">
                    <Select
                      value={status}
                      onValueChange={(value) => {
                        setStatus(value);
                      }}
                    >
                      <SelectTrigger className="w-40">
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {["On-Track", "At Risk", "Off-Track"].map(
                            (option) => (
                              <SelectItem
                                key={option}
                                value={option}
                                className=""
                              >
                                <div className="flex items-center">
                                  <div
                                    className={clsx(
                                      `mr-2 h-2 w-2 rounded-full`,
                                      option === "On-Track" && "bg-green-400",
                                      option === "At Risk" && "bg-amber-400",
                                      option === "Off-Track" && "bg-red-400"
                                    )}
                                  />
                                  <div>{option}</div>
                                </div>
                              </SelectItem>
                            )
                          )}
                        </SelectGroup>
                      </SelectContent>
                    </Select>

                    <div
                      className="min-h-[180px] cursor-text overflow-auto rounded-md border border-input p-3 px-4 shadow-sm"
                      onClick={() => {
                        editor?.commands.focus();
                      }}
                    >
                      <TiptapEditor editor={editor} />
                    </div>
                  </div>
                </div>
                <div className="flex justify-end">
                  <Button
                    onClick={() => {
                      createInternalUpdateMutation.mutate({
                        description: editor?.getHTML() ?? "",
                        status: status,
                      });
                    }}
                    disabled={
                      createInternalUpdateMutation.isLoading || editor?.isEmpty
                    }
                  >
                    {createInternalUpdateMutation.isLoading
                      ? "Posting..."
                      : "Post Update"}
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
          </div>
          {!!internalUpdates ? (
            <div className="mt-4 border-t border-gray-200/70">
              {!!internalUpdates.length ? (
                <InternalUpdates internalUpdates={internalUpdates} />
              ) : (
                <>
                  {/* <div className="border-b border-gray-200/70 p-5 px-6">
                    <div className="flex h-7 items-center justify-between">
                      <div className="text-xs font-light text-gray-500">
                        Aug 13 by <span className="text-black">Sero</span>
                      </div>
                      <div className="flex h-7 items-center rounded bg-red-50 px-2">
                        <ExclamationTriangleIcon className="h-3.5 w-3.5 text-red-500" />
                      </div>
                    </div>
                    <div className="flex-1 pt-3 text-sm font-light text-gray-600">
                      <span className="font-medium text-gray-800">
                        Jai Thirani
                      </span>{" "}
                      missed posting a project update between Aug 05 and Aug 12.
                    </div>
                  </div> */}
                  {/* <div className="p-5 px-6">
                    <div className="flex h-7 items-center justify-between">
                      <div className="text-xs font-light text-gray-500">
                        Aug 12 by <span className="text-black">Sero</span>
                      </div>
                    </div>
                    <div className="flex-1 pt-3 text-sm font-light text-gray-600">
                      Project was created by{" "}
                      <span className="font-medium text-gray-800">
                        Jai Thirani
                      </span>
                    </div>
                  </div> */}
                </>
              )}
            </div>
          ) : (
            <div>
              <Loading />
            </div>
          )}
        </div>
        {/* {!!tasks && (
          <>
            {tasks.length === 0 && (
              <div className="mt-[15vh] flex items-center justify-center">
                <div className="flex max-w-md flex-col space-y-2 rounded-md border-[0.5px] border-gray-300/40 p-8 shadow">
                  <div className="text-lg font-medium">Get started</div>
                  <div className="text-left text-base font-light leading-relaxed text-gray-600">
                    There are currently no tasks in your project. Begin by
                    creating your first one!
                  </div>
                  <div className="flex space-x-3 pt-2.5">
                    <Button>New Task</Button>
                    <Button
                      onClick={() => {
                        navigate(`/projects/${phase._id}/tasks`);
                      }}
                    >
                      Go to Tasks
                    </Button>
                  </div>
                </div>
              </div>
              // <div className="text-sm text-gray-400">
              //   There are no acive tasks in this project. Click{" "}
              //   <span
              //     onClick={() => {
              //       navigate(`/projects/${phase._id}/tasks`);
              //     }}
              //     className="cursor-pointer text-gray-600"
              //   >
              //     here
              //   </span>{" "}
              //   to go to the tasks tab to add tasks.
              // </div>
            )}
            {tasks.length > 0 && (
              <div className="space-y-3">
                <div>
                <div className="cursor-default text-base font-medium leading-none text-gray-700">
                  Go To
                </div>
                {!myActiveTasks?.length &&
                  !internalActiveTasks?.length &&
                  !externalActiveTasks?.length && (
                    <div className="text-sm text-gray-400">
                      There are no acive tasks in this project. Click{" "}
                      <span
                        onClick={() => {
                          navigate(`/projects/${phase._id}/tasks`);
                        }}
                      >
                        here
                      </span>{" "}
                      to go to the tasks tab to add tasks.
                    </div>
                  )}
              </div>
                <div>
                  <StatusBarChart tasks={tasks} phase={phase} />
                </div>
                <div className="grid gap-3 lg:grid-cols-2 xl:grid-cols-3">
                  {!!myActiveTasks?.length && (
                    <div
                      className="group relative cursor-pointer rounded-md border border-gray-200/70 p-4 shadow-sm hover:bg-gray-50"
                      onClick={() => {
                        navigate(
                          `/projects/${phase._id}/tasks?responsibility=my`
                        );
                      }}
                    >
                      <div className="space-y-4">
                        <div className="space-y-0.5">
                          <div className="text-xs text-gray-400">
                            Active Tasks
                          </div>
                          <div className="truncate text-base font-medium text-gray-700">
                            {"My Tasks"}
                          </div>
                        </div>
                        <div className="text-3xl font-semibold leading-none">
                          {myActiveTasks.length}
                        </div>
                      </div>
                      <div
                        className="absolute right-5 bottom-4 transition-all group-hover:right-4"
                        onClick={() => {
                          navigate(
                            `/projects/${phase._id}/tasks?responsibility=my`
                          );
                        }}
                      >
                        <ArrowRightIcon className="h-5 w-5 text-gray-400" />
                      </div>
                    </div>
                  )}
                  {!!internalActiveTasks?.length && (
                    <div
                      className="group relative cursor-pointer rounded-md border border-gray-200/70 p-4 shadow-sm hover:bg-gray-50"
                      onClick={() => {
                        navigate(
                          `/projects/${phase._id}/tasks?responsibility=internal`
                        );
                      }}
                    >
                      <div className="space-y-4">
                        <div className="space-y-0.5">
                          <div className="text-xs text-gray-400">
                            Active Tasks
                          </div>
                          <div className="truncate text-base font-medium text-gray-700">
                            {phase.instance.name}
                          </div>
                        </div>
                        <div className="text-3xl font-semibold leading-none">
                          {internalActiveTasks.length}
                        </div>
                      </div>
                      <div
                        className="absolute right-5 bottom-4 transition-all group-hover:right-4"
                        onClick={() => {
                          navigate(
                            `/projects/${phase._id}/tasks?responsibility=my`
                          );
                        }}
                      >
                        <ArrowRightIcon className="h-5 w-5 text-gray-400" />
                      </div>
                    </div>
                    // <div
                    //   className="flex cursor-pointer items-center justify-between gap-2 rounded-md border border-gray-200/70 p-4 shadow-sm hover:bg-gray-50 "
                    //   onClick={() => {
                    //     navigate(
                    //       `/projects/${phase._id}/tasks?responsibility=internal`
                    //     );
                    //   }}
                    // >
                    //   <div className="truncate text-xl font-medium">
                    //     {phase.instance.name}
                    //   </div>
                    //   <div className="min-w-[16px] shrink-0 rounded bg-gray-200 px-2 py-0.5 text-center text-lg text-gray-500">
                    //     {internalActiveTasks.length}
                    //   </div>
                    // </div>
                  )}
                  {!!externalActiveTasks?.length && (
                    <div
                      className="group relative cursor-pointer rounded-md border border-gray-200/70 p-4 shadow-sm hover:bg-gray-50"
                      onClick={() => {
                        navigate(
                          `/projects/${phase._id}/tasks?responsibility=external`
                        );
                      }}
                    >
                      <div className="space-y-4">
                        <div className="space-y-0.5">
                          <div className="text-xs text-gray-400">
                            Active Tasks
                          </div>
                          <div className="truncate text-base font-medium text-gray-700">
                            {phase.customer?.name}
                          </div>
                        </div>
                        <div className="text-3xl font-semibold leading-none">
                          {externalActiveTasks.length}
                        </div>
                      </div>
                      <div
                        className="absolute right-5 bottom-4 transition-all group-hover:right-4"
                        onClick={() => {
                          navigate(
                            `/projects/${phase._id}/tasks?responsibility=my`
                          );
                        }}
                      >
                        <ArrowRightIcon className="h-5 w-5 text-gray-400" />
                      </div>
                    </div>
                    // <div
                    //   className="flex cursor-pointer items-center justify-between gap-2 rounded-md border border-gray-200/70 p-4 shadow-sm hover:bg-gray-50 "
                    //   onClick={() => {
                    //     navigate(
                    //       `/projects/${phase._id}/tasks?responsibility=external`
                    //     );
                    //   }}
                    // >
                    //   <div className="truncate text-xl font-medium">
                    //     {phase.customer.name}
                    //   </div>
                    //   <div className="min-w-[16px] shrink-0 rounded bg-gray-200 px-2 py-0.5 text-center text-lg text-gray-500">
                    //     {externalActiveTasks.length}
                    //   </div>
                    // </div>
                  )}
                </div>
              </div>
            )}
          </>
        )} */}
      </div>
      <div className="w-[420px] space-y-6 overflow-auto border-l border-l-gray-200/70 px-8 pt-6">
        <div className="space-y-2.5">
          <div className="cursor-default text-base font-medium leading-none text-gray-600">
            Details
          </div>
          <div className="space-y-1">
            {/* <div className="flex min-h-[32px] items-center gap-6">
              <div className="w-32 cursor-default text-[13px] text-gray-400">
                Progress
              </div>
              {!!tasks ? (
                <div className="space-y-">
                  <div className="flex items-center space-x-3">
                    <div
                      className={clsx(
                        "h-1.5 w-20 shrink-0 overflow-hidden rounded-sm",
                        (completedTasks ?? []).length === 0
                          ? "border-gray-300 bg-gray-200/70"
                          : "border-green-200 bg-green-200/70"
                      )}
                    >
                      <div
                        style={{ width: progress + "%" }}
                        className="h-full rounded-sm bg-green-500"
                      ></div>
                    </div>
                    <div className="text-sm font-semibold text-gray-600">
                      {progress.toFixed(0) + "%"}
                    </div>
                    <div className="text-xs text-gray-400">{`(${completedTasks?.length}/${tasks?.length} tasks)`}</div>
                  </div>
                </div>
              ) : (
                <div className="h-4 w-20 animate-pulse rounded bg-gray-200" />
              )}
            </div> */}
            <div className="flex h-8 items-center gap-6">
              <div className="w-32 shrink-0 cursor-default text-[13px] font-light text-gray-500">
                Project Status
              </div>
              <PhaseStatusSelect phase={phase} />
            </div>
            <div className="flex h-8 items-center gap-6 text-sm">
              <div className="w-32 shrink-0 cursor-default text-[13px] font-light text-gray-500">
                Project Owner
              </div>
              <ProjectOwnerCombobox phase={phase} />
            </div>
            {phase.customer && (
              <div className="flex h-8 items-center gap-6 text-sm">
                <div className="w-32 cursor-default text-[13px] font-light text-gray-500">
                  Customer
                </div>
                <div>{phase.customer.name}</div>
              </div>
            )}
          </div>
        </div>
        <div className="space-y-2.5 text-sm">
          <div className="cursor-default text-base font-medium leading-none text-gray-600">
            Key Dates
          </div>
          <div className="space-y-1">
            <div className="flex h-8 items-center gap-6">
              <div className="w-32 cursor-default text-[13px] font-light text-gray-500">
                Start Date
              </div>
              {!!keyDates && (
                <div>{dayjs(keyDates?.startedDate).format("DD MMM, YYYY")}</div>
              )}
            </div>
            {phase.type !== "Simple" && (
              <div className="flex h-8 items-center gap-6">
                <div className="w-32 cursor-default text-[13px] font-light text-gray-500">
                  Planned End Date
                </div>
                {!!keyDates && (
                  <div>
                    {dayjs(keyDates?.plannedCompletionDate).format(
                      "DD MMM, YYYY"
                    )}
                  </div>
                )}
              </div>
            )}

            <div className="flex h-8 items-center gap-6">
              <div className="w-32 cursor-default text-[13px] font-light text-gray-500">
                Forecasted End Date
              </div>
              {keyDates && (
                <ForecastedCompletionDate phase={phase} keyDates={keyDates} />
              )}
            </div>
            {/* <div className="flex justify-center pt-2">
                {!!keyDates && <ForecastAheadOrBehind keyDates={keyDates} />}
              </div> */}
          </div>
        </div>
        {!!phase.customFields?.length && (
          <div className="space-y-2.5">
            <div className="cursor-default text-base font-medium leading-none text-gray-600">
              Fields
            </div>
            <div className="space-y-1 text-sm">
              {phase.customFields.map((customField) => {
                return (
                  <CustomFieldValue
                    customField={customField}
                    key={customField._id}
                    phase={phase}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
      {/* <div className="flex justify-center pt-4">
        {journey && <Timeline journey={journey} />}
      </div> */}
      {/* <div className="grid max-w-5xl grid-cols-3 gap-10">
        <div className="col-span-1 h-full space-y-4 pb-12">
          <div className="font- text-2xl text-gray-600">Details</div>
          <div className="space-y-6 rounded-md border p-4 text-sm shadow-sm">
            <div className="flex items-center justify-between">
              <div className="text-lg font-medium text-gray-600">
                Project Status
              </div>
              <PhaseStatusSelect phase={phase} />
            </div>
          </div>

          <div className="space-y-2 rounded-md border p-4 text-sm shadow">
            <div className="text-lg font-medium text-gray-600">
              Tasks Breakdown
            </div>
            <TasksDonut phase={phase} />
          </div>
          {!!tasksOverdue && (
            <div
              className={clsx(
                "flex items-center justify-between rounded-md border px-4 py-3 shadow",
                tasksOverdue?.length > 0 ? "" : ""
              )}
            >
              <div
                className={clsx(
                  "",
                  tasksOverdue?.length > 0 ? "text-red-500" : ""
                )}
              >
                Overdue Tasks
              </div>
              <div
                className={clsx(
                  "text-2xl font-medium",
                  tasksOverdue.length > 0 ? "text-red-500" : ""
                )}
              >
                {tasksOverdue.length}
              </div>
            </div>
          )}
        </div>
        <div className="col-span-2 h-full"></div>
      </div> */}
      {/* <div className="col-span-1 h-full">
        <Risks phase={phase} />
      </div> */}
    </div>
  );
}

function TasksBreakdown({ tasks }: { tasks: TaskData[] }) {
  const completedTasks = tasks.filter((t) => t.status === "Completed");
  const canceledTasks = tasks.filter((t) => t.status === "Canceled");
  const stuckTasks = tasks.filter((t) => t.status === "Stuck");
  const inProgressTasks = tasks.filter((t) => t.status === "In Progress");
  const toDoActiveTasks = tasks.filter(
    (t) => t.status === "To-do" && t.dueDate
  );
  const inactiveTasks = tasks.filter((t) => !t.dueDate);
  const backlogTasks = tasks.filter((t) => t.status === "Backlog");
  const totalTasksLength = tasks.length;
  const progress = !!totalTasksLength
    ? Math.round(
        ((completedTasks.length + canceledTasks.length) / totalTasksLength) *
          100
      ).toFixed(0)
    : "0";
  return (
    <HoverCard openDelay={100} closeDelay={0}>
      <HoverCardTrigger asChild>
        <div className="flex w-fit cursor-default items-center space-x-4 rounded-md px-3 py-1.5 hover:bg-gray-50">
          <div
            className={clsx(
              "flex h-2 w-28 shrink-0 overflow-hidden rounded-sm",
              "bg-gray-200/70"
            )}
          >
            <div
              style={{
                width: (completedTasks.length * 100) / tasks.length + "%",
              }}
              className="h-full bg-green-500"
            />
            <div
              style={{
                width: (canceledTasks.length * 100) / tasks.length + "%",
              }}
              className="h-full bg-gray-300"
            />
            <div
              style={{
                width: (stuckTasks.length * 100) / tasks.length + "%",
              }}
              className="h-full bg-red-500"
            />
            <div
              style={{
                width: (inProgressTasks.length * 100) / tasks.length + "%",
              }}
              className="h-full bg-blue-500"
            />
            <div
              style={{
                width: (toDoActiveTasks.length * 100) / tasks.length + "%",
              }}
              className="h-full bg-amber-400"
            />
            <div
              style={{
                width: (backlogTasks.length * 100) / tasks.length + "%",
              }}
              className="h-full bg-gray-200"
            />
            <div
              style={{
                width:
                  ((inactiveTasks?.length ?? 0) * 100) / tasks.length + "%",
              }}
              className="h-full bg-gray-600"
            />
          </div>

          <div className="w-10 text-sm font-semibold text-gray-800">
            {progress + "%"}
          </div>
          <div className="w-14 text-sm text-gray-500">{`${completedTasks.length} / ${tasks?.length}`}</div>
        </div>
      </HoverCardTrigger>
      <HoverCardContent
        className="w-60 p-4"
        sideOffset={0}
        side="right"
        align="start"
      >
        {/* <div className="font-medium">Tasks breakdown</div> */}
        <div className="space-y-2">
          <div className="flex items-center space-x-3 text-sm">
            <div className="h-2 w-2 rounded-full bg-green-500" />
            <div className="w-32 text-gray-700">Completed</div>
            <div className="">
              {!!completedTasks.length ? completedTasks.length : "-"}
            </div>
          </div>
          <div className="flex items-center space-x-3 text-sm">
            <div className="h-2 w-2 rounded-full bg-gray-300" />
            <div className="w-32 text-gray-700">Canceled</div>
            <div className="">
              {!!canceledTasks.length ? canceledTasks.length : "-"}
            </div>
          </div>
          <div className="flex items-center space-x-3 text-sm">
            <div className="h-2 w-2 rounded-full bg-red-500" />
            <div className="w-32 text-gray-700">Stuck</div>
            <div className="">
              {!!stuckTasks.length ? stuckTasks.length : "-"}
            </div>
          </div>
          <div className="flex items-center space-x-3 text-sm">
            <div className="h-2 w-2 rounded-full bg-blue-500" />
            <div className="w-32 text-gray-700">In Progress</div>
            <div className="">
              {!!inProgressTasks.length ? inProgressTasks.length : "-"}
            </div>
          </div>
          <div className="flex items-center space-x-3 text-sm">
            <div className="h-2 w-2 rounded-full bg-amber-400" />
            <div className="w-32 text-gray-700">To Do</div>
            <div className="">
              {!!toDoActiveTasks.length ? toDoActiveTasks.length : "-"}
            </div>
          </div>
          <div className="flex items-center space-x-3 text-sm">
            <div className="h-2 w-2 rounded-full bg-gray-200" />
            <div className="w-32 text-gray-700">Backlog</div>
            <div className="">
              {!!backlogTasks.length ? backlogTasks.length : "-"}
            </div>
          </div>
          <div className="flex items-center space-x-3 text-sm">
            <div className="h-2 w-2 rounded-full bg-gray-600" />
            <div className="w-32 text-gray-700">Inactive</div>
            <div className="">
              {!!inactiveTasks.length ? inactiveTasks.length : "-"}
            </div>
          </div>
        </div>
        <div className="my-2 border-y py-2">
          <div className="flex items-center space-x-3 text-sm">
            <div className="w-2" />
            <div className="w-32 text-gray-700">Total</div>
            <div className="">{tasks.length}</div>
          </div>
        </div>
        <div className="flex items-center space-x-3 text-sm font-medium">
          <div className="w-2" />
          <div className="w-32 ">Closed %</div>
          <div className="">
            {totalTasksLength
              ? ((completedTasks.length * 100) / tasks.length).toFixed(0)
              : "0" + "%"}
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
}

function InternalUpdates({
  internalUpdates,
}: {
  internalUpdates: InternalPhaseUpdateData[];
}) {
  return (
    <div className="">
      {internalUpdates.map((update) => (
        <InternalUpdate key={update._id} update={update} />
      ))}
    </div>
  );
}

export function InternalUpdate({
  update,
}: {
  update: InternalPhaseUpdateData;
}) {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
      }),
    ],
    content: update.description,
    editable: false,
  });

  return (
    <div className="border-t border-gray-200/70 p-4 px-6 pb-3 first:border-t-0">
      <div className="flex items-center justify-between">
        <div className="flex h-6 items-center space-x-1.5 rounded-md bg-gray-100 px-2 text-xs font-light text-gray-500">
          <span>{dayjs(update.createdAt).format("MMM DD")}</span>
          {!!update.createdBy && (
            <>
              <div className="h-1 w-1 rounded-full bg-gray-400/80" />
              <span className="text-black"> {update.createdBy?.name}</span>
            </>
          )}
        </div>
        {!!update.status && (
          <div
            className={clsx(
              "flex h-6 items-center rounded-md px-2 text-xs",
              update.status === "On-Track" && "bg-green-50 text-green-600",
              update.status === "Off-Track" && "bg-red-50 text-red-600",
              update.status === "At Risk" && "bg-yellow-50 text-yellow-600"
            )}
          >
            {update.status === "On-Track"
              ? "On-Track"
              : update.status === "Off-Track"
              ? "Off-Track"
              : "At Risk"}
          </div>
        )}
      </div>
      <div className="flex-1 pt-3 text-sm">
        {update.description ? (
          <PureEditorContent
            editor={editor}
            className="prose h-full focus:border-none focus:outline-none focus:ring-0"
          />
        ) : (
          <>
            {update.event === "stage-completed" && (
              <div className="font-light text-gray-600">
                <span>Stage </span>
                <span className="font-medium text-gray-800">
                  {update.metadata?.stageName}
                </span>
                <span> completed</span>
              </div>
            )}
            {update.event === "project-started" && (
              <div className="font-light text-gray-600">
                <span>Project was created</span>
                {/* <span className="font-medium text-gray-800">
                  {update.metadata?.stageName}
                </span> */}
                {/* <span> completed</span> */}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function StatusBarChart({
  tasks,
  phase,
}: {
  tasks: TaskData[];
  phase: PhaseData;
}) {
  const statusGroupsCtx = useStatusGroups();
  const statusGroup = statusGroupsCtx.state.statusGroups.find(
    (sg) => sg._id === phase.statusGroup._id
  );
  if (!statusGroup) {
    return <></>;
  }

  const internalStatusCounts: { [key: string]: number } = {};
  const externalStatusCounts: { [key: string]: number } = {};

  statusGroup.statuses.forEach(({ name }) => {
    internalStatusCounts[name] = 0;
  });
  statusGroup.statuses.forEach(({ name }) => {
    externalStatusCounts[name] = 0;
  });

  // Count each task's status
  tasks
    .filter((t) => t.taskerType === "internal")
    .forEach((task) => {
      if (internalStatusCounts.hasOwnProperty(task.customStatus)) {
        internalStatusCounts[task.customStatus]++;
      }
    });

  tasks
    .filter((t) => t.taskerType === "external")
    .forEach((task) => {
      if (externalStatusCounts.hasOwnProperty(task.customStatus)) {
        externalStatusCounts[task.customStatus]++;
      }
    });

  let data = [
    {
      name: phase.instance.name,
      ...internalStatusCounts,
    },
    ...(phase.collaborationType === "external"
      ? [
          {
            name: phase.customer?.name,
            ...externalStatusCounts,
          },
        ]
      : []),
  ];

  const colors = statusGroup.statuses.map(({ parentStatus }) => {
    switch (parentStatus) {
      case "To-do":
        return "amber";
      case "In Progress":
        return "blue";
      case "Completed":
        return "green";
      case "Canceled":
        return "gray";
      case "Backlog":
        return "gray";
      case "Stuck":
        return "red";
      default:
        return "gray";
    }
  });
  // if (external) {
  //   data.push({
  //     name: "external",
  //     Backlog: tasks.filter(
  //       (t) => t.status === "Backlog" && t.taskerType === "external"
  //     ).length,
  //     "To-do": tasks.filter(
  //       (t) => t.status === "To-do" && t.taskerType === "external"
  //     ).length,
  //     "In Progress": tasks.filter(
  //       (t) => t.status === "In Progress" && t.taskerType === "external"
  //     ).length,
  //     Stuck: tasks.filter(
  //       (t) => t.status === "Stuck" && t.taskerType === "external"
  //     ).length,
  //     Completed: tasks.filter(
  //       (t) => t.status === "Completed" && t.taskerType === "external"
  //     ).length,
  //     Canceled: tasks.filter(
  //       (t) => t.status === "Canceled" && t.taskerType === "external"
  //     ).length,
  //   });
  // }
  return (
    <div className="max-w-[600px] rounded-md border border-gray-200/70 p-6 shadow-sm">
      <h3 className="dark:text-dark-tremor-content-strong text-lg font-medium text-tremor-content-strong">
        Task Breakdown
      </h3>
      <BarChart
        className="mt-6"
        data={data}
        index="name"
        categories={statusGroup.statuses.map(({ name }) => name)}
        colors={colors}
        valueFormatter={(value) => `${value}`}
        yAxisWidth={48}
        allowDecimals={false}
        stack={true}
      />
    </div>
  );
}

function ProjectOwnerCombobox({ phase }: { phase: PhaseData }) {
  const [open, setOpen] = useState(false);
  const updatePhaseMutation = useUpdatePhase();
  const { data: phaseMembers } = useGetProjectMembers(phase._id);
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <button
          className={clsx(
            "group -ml-1.5 flex min-h-[32px] w-full items-center justify-between space-x-2 rounded-md px-1.5 py-1 hover:bg-gray-100",
            open && "bg-gray-100"
          )}
        >
          {
            phaseMembers?.internal.find((m) => m._id === phase.ownerUser._id)
              ?.name
          }
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-0 group-hover:opacity-40" />
        </button>
      </PopoverTrigger>
      <PopoverContent
        className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
        align="start"
      >
        <Command>
          <CommandInput placeholder="Search.." className="h-9" />
          <CommandEmpty>No options found.</CommandEmpty>
          <CommandGroup>
            {phaseMembers?.internal?.map((option) => (
              <CommandItem
                key={option._id}
                value={option.name}
                onSelect={() => {
                  updatePhaseMutation.mutate({
                    phase: {
                      _id: phase._id,
                      ownerUserId: option._id,
                    },
                    update: {
                      _id: phase._id,
                      ownerUser: {
                        _id: option._id,
                        name: option.name,
                        email: option.email,
                      },
                    },
                  });
                  setOpen(false);
                }}
                className="justify-between gap-2"
              >
                <span className="truncate">{option.name}</span>
                {option._id === phase.ownerUser._id && (
                  <CheckIcon className="h-4 w-4" />
                )}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function CustomFieldValue({
  customField,
  phase,
}: {
  customField: CustomFieldValueData;
  phase: PhaseData;
}) {
  switch (customField.type) {
    case "text":
      return <TextCustomFieldValue customField={customField} phase={phase} />;
    case "number":
      return <NumberCustomFieldValue customField={customField} phase={phase} />;
    case "currency":
      return (
        <CurrencyCustomFieldValue customField={customField} phase={phase} />
      );
    case "multi-select":
      return (
        <MultiSelectCustomFieldValue customField={customField} phase={phase} />
      );
    case "date":
      return <DateCustomFieldValue customField={customField} phase={phase} />;
    case "select":
      return <SelectCustomFieldValue customField={customField} phase={phase} />;
    case "boolean":
      return (
        <BooleanCustomFieldValue customField={customField} phase={phase} />
      );
    default:
      return <div className="text-gray-400">Error</div>;
  }
  // return (
  //   <div key={customField._id} className="flex min-h-[32px] items-center gap-6">
  //     <div className="w-32 cursor-default truncate text-[13px] text-gray-400">
  //       {customField.name}
  //     </div>
  //     <div>{customField.value}</div>
  //   </div>
  // );
}

function BooleanCustomFieldValue({
  customField,
  phase,
}: {
  customField: BooleanCustomFieldValueData;
  phase: PhaseData;
}) {
  const [open, setOpen] = useState(false);
  const updatePhaseMutation = useUpdatePhase();
  if (customField.type !== "boolean") return <></>;
  return (
    <div key={customField._id} className="flex min-h-[32px] items-center gap-6">
      <div className="flex w-32 shrink-0 cursor-default items-center gap-1.5 text-[13px] font-light text-gray-500">
        {customField.crmProperty?.integrationId === "hubspot" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="3 3.5 12 12"
            className="w-4"
          >
            <path
              fill="#FF5C35"
              d="M11.54 7.422V6.096a1.02 1.02 0 0 0 .588-.92v-.03c0-.56-.46-1.02-1.02-1.02h-.03c-.561 0-1.02.46-1.02 1.02v.03c0 .407.243.757.589.92v1.326c-.516.08-.986.292-1.375.605l-3.64-2.832a1.14 1.14 0 0 0 .04-.286 1.15 1.15 0 1 0-1.15 1.148c.206 0 .398-.059.566-.154l3.58 2.786a2.892 2.892 0 0 0-.482 1.603c0 .62.196 1.194.527 1.666l-1.089 1.09a.935.935 0 0 0-.27-.045.945.945 0 1 0 .945.945.934.934 0 0 0-.044-.27l1.077-1.077a2.89 2.89 0 0 0 1.761.598 2.905 2.905 0 0 0 .446-5.776Zm-.447 4.36a1.49 1.49 0 1 1 0-2.98 1.49 1.49 0 0 1 0 2.98Z"
            />
          </svg>
        )}
        <div className="min-w-0 flex-1 truncate">{customField.name}</div>
      </div>
      <Select
        value={
          customField.value === null
            ? undefined
            : customField.value
            ? "true"
            : "false"
        }
        disabled={customField.crmProperty?.readyOnly}
        onValueChange={(value) => {
          updatePhaseMutation.mutate({
            phase: {
              _id: phase._id,
              customFields: phase.customFields?.map((f) => {
                if (f._id === customField._id && f.type === "boolean") {
                  return {
                    _id: f._id,
                    value: value === "true",
                  };
                }
                return f;
              }),
            },
            update: {
              _id: phase._id,
              customFields: phase.customFields?.map((f) => {
                if (f._id === customField._id && f.type === "boolean") {
                  return {
                    ...f,
                    value: value === "true",
                  };
                }
                return f;
              }),
            },
          });
        }}
        open={open}
        onOpenChange={setOpen}
      >
        <SelectTrigger
          className={clsx(
            "-ml-1.5 border-none px-0 shadow-none",
            customField.crmProperty?.readyOnly && "cursor-not-allowed"
          )}
          showIcon={false}
        >
          <div
            className={clsx(
              "group flex min-h-[32px] w-full items-center justify-between space-x-2 rounded-md px-1.5 py-1 hover:bg-gray-100",
              open && "bg-gray-100"
            )}
          >
            {customField.value !== null ? (
              <div className="flex flex-wrap items-center gap-1">
                {customField.value ? "Yes" : "No"}
              </div>
            ) : (
              <div className="font-normal text-gray-400">Select value..</div>
            )}
            <div className="flex items-center space-x-2">
              {/* {!!customField.value && (
                <button
                  // tabIndex={-1}
                  className="ml-1 shrink-0 rounded-full p-1 text-gray-400 opacity-0 hover:bg-gray-200 hover:text-gray-600 group-hover:opacity-100"
                  onClick={(e) => {
                    e.stopPropagation();
                    updatePhaseMutation.mutate({
                      _id: phase._id,
                      customFields: phase.customFields?.map((f) => {
                        if (f._id === customField._id && f.type === "select") {
                          return {
                            ...f,
                            value: null,
                          };
                        }
                        return f;
                      }),
                    });
                  }}
                >
                  <XMarkIcon className="h-2.5 w-2.5" />
                </button>
              )} */}
              <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-0 group-hover:opacity-40" />
            </div>
          </div>
        </SelectTrigger>
        <SelectContent className="max-h-[30vh] min-w-[120px]">
          <SelectGroup>
            <SelectItem value={"true"}>Yes</SelectItem>
            <SelectItem value={"false"}>No</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}

function SelectCustomFieldValue({
  customField,
  phase,
}: {
  customField: SelectCustomFieldValueData;
  phase: PhaseData;
}) {
  const [open, setOpen] = useState(false);
  const updatePhaseMutation = useUpdatePhase();
  if (customField.type !== "select") return <></>;
  return (
    <div key={customField._id} className="flex min-h-[32px] items-center gap-6">
      <div className="flex w-32 shrink-0 cursor-default items-center gap-1.5 text-[13px] font-light text-gray-500">
        {customField.crmProperty?.integrationId === "hubspot" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="3 3.5 12 12"
            className="w-4"
          >
            <path
              fill="#FF5C35"
              d="M11.54 7.422V6.096a1.02 1.02 0 0 0 .588-.92v-.03c0-.56-.46-1.02-1.02-1.02h-.03c-.561 0-1.02.46-1.02 1.02v.03c0 .407.243.757.589.92v1.326c-.516.08-.986.292-1.375.605l-3.64-2.832a1.14 1.14 0 0 0 .04-.286 1.15 1.15 0 1 0-1.15 1.148c.206 0 .398-.059.566-.154l3.58 2.786a2.892 2.892 0 0 0-.482 1.603c0 .62.196 1.194.527 1.666l-1.089 1.09a.935.935 0 0 0-.27-.045.945.945 0 1 0 .945.945.934.934 0 0 0-.044-.27l1.077-1.077a2.89 2.89 0 0 0 1.761.598 2.905 2.905 0 0 0 .446-5.776Zm-.447 4.36a1.49 1.49 0 1 1 0-2.98 1.49 1.49 0 0 1 0 2.98Z"
            />
          </svg>
        )}
        <div className="min-w-0 flex-1 truncate">{customField.name}</div>
      </div>
      <Select
        value={customField.value ?? ""}
        disabled={customField.crmProperty?.readyOnly}
        onValueChange={(value) => {
          updatePhaseMutation.mutate({
            phase: {
              _id: phase._id,
              customFields: phase.customFields?.map((f) => {
                if (f._id === customField._id && f.type === "select") {
                  return {
                    _id: f._id,
                    value: value,
                  };
                }
                return f;
              }),
            },
            update: {
              _id: phase._id,
              customFields: phase.customFields?.map((f) => {
                if (f._id === customField._id && f.type === "select") {
                  return {
                    ...f,
                    value: value,
                  };
                }
                return f;
              }),
            },
          });
        }}
        open={open}
        onOpenChange={setOpen}
      >
        <SelectTrigger
          className={clsx(
            "-ml-1.5 border-none px-0 shadow-none",
            customField.crmProperty?.readyOnly && "cursor-not-allowed"
          )}
          showIcon={false}
        >
          <div
            className={clsx(
              "group flex min-h-[32px] w-full items-center justify-between space-x-2 rounded-md px-1.5 py-1 hover:bg-gray-100",
              open && "bg-gray-100"
            )}
          >
            {!!customField.value ? (
              <div className="flex flex-wrap items-center gap-1">
                {
                  customField.options.find((o) => o.value === customField.value)
                    ?.label
                }
              </div>
            ) : (
              <div className="font-normal text-gray-400">Select value..</div>
            )}
            <div className="flex items-center space-x-2">
              {/* {!!customField.value && (
                <button
                  // tabIndex={-1}
                  className="ml-1 shrink-0 rounded-full p-1 text-gray-400 opacity-0 hover:bg-gray-200 hover:text-gray-600 group-hover:opacity-100"
                  onClick={(e) => {
                    e.stopPropagation();
                    updatePhaseMutation.mutate({
                      _id: phase._id,
                      customFields: phase.customFields?.map((f) => {
                        if (f._id === customField._id && f.type === "select") {
                          return {
                            ...f,
                            value: null,
                          };
                        }
                        return f;
                      }),
                    });
                  }}
                >
                  <XMarkIcon className="h-2.5 w-2.5" />
                </button>
              )} */}
              <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-0 group-hover:opacity-40" />
            </div>
          </div>
        </SelectTrigger>
        <SelectContent className="max-h-[30vh] min-w-[120px]">
          <SelectGroup>
            {customField.options?.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}
function CurrencyCustomFieldValue({
  customField,
  phase,
}: {
  customField: CurrencyCustomFieldValueData;
  phase: PhaseData;
}) {
  const formatValue = (value: number | null) => {
    if (value === null) return "";
    // Allow only numbers and a single decimal point
    const cleaned = String(value).replace(/[^\d.]/g, "");
    // .replace(/(\..*)\./g, "$1");
    const parts = cleaned.split(".");
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.length > 1 ? `${integerPart}.${parts[1]}` : integerPart;
  };

  const [value, setValue] = useState(formatValue(customField.value));

  useEffect(() => {
    setValue(formatValue(customField.value));
  }, [customField.value]);

  const updatePhaseMutation = useUpdatePhase();

  return (
    <div key={customField._id} className="flex min-h-[32px] items-center gap-6">
      <div className="flex w-32 shrink-0 cursor-default items-center gap-1.5 text-[13px] font-light text-gray-500">
        {customField.crmProperty?.integrationId === "hubspot" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="3 3.5 12 12"
            className="w-4"
          >
            <path
              fill="#FF5C35"
              d="M11.54 7.422V6.096a1.02 1.02 0 0 0 .588-.92v-.03c0-.56-.46-1.02-1.02-1.02h-.03c-.561 0-1.02.46-1.02 1.02v.03c0 .407.243.757.589.92v1.326c-.516.08-.986.292-1.375.605l-3.64-2.832a1.14 1.14 0 0 0 .04-.286 1.15 1.15 0 1 0-1.15 1.148c.206 0 .398-.059.566-.154l3.58 2.786a2.892 2.892 0 0 0-.482 1.603c0 .62.196 1.194.527 1.666l-1.089 1.09a.935.935 0 0 0-.27-.045.945.945 0 1 0 .945.945.934.934 0 0 0-.044-.27l1.077-1.077a2.89 2.89 0 0 0 1.761.598 2.905 2.905 0 0 0 .446-5.776Zm-.447 4.36a1.49 1.49 0 1 1 0-2.98 1.49 1.49 0 0 1 0 2.98Z"
            />
          </svg>
        )}
        <div className="min-w-0 flex-1 truncate">{customField.name}</div>
      </div>
      <div
        className={clsx(
          "relative -ml-1.5 flex h-8 flex-1 items-center rounded-md focus-within:bg-gray-100 hover:bg-gray-100",
          customField.crmProperty?.readyOnly && "cursor-not-allowed opacity-50"
        )}
      >
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-1.5">
          <div className="text-xs text-gray-600">
            {customField.currencyCode}
          </div>
        </div>
        <input
          className={clsx(
            "w-full flex-1 rounded border-none bg-transparent p-1 px-1.5 pl-10 text-sm outline-none transition-colors placeholder:text-gray-400 focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50"
          )}
          value={value}
          disabled={customField.crmProperty?.readyOnly}
          type="string"
          onChange={(e) => {
            if (e.target.value !== "") {
              const numericValue = e.target.value.includes(".")
                ? parseFloat(e.target.value.replace(/,/g, ""))
                : parseInt(e.target.value.replace(/,/g, ""), 10);

              if (numericValue !== null) {
                setValue(formatValue(numericValue));
              }
            } else {
              setValue("");
            }
          }}
          onBlur={() => {
            if (value !== null && value !== "") {
              const numericValue = value.includes(".")
                ? parseFloat(value.replace(/,/g, ""))
                : parseInt(value.replace(/,/g, ""), 10);
              updatePhaseMutation.mutate({
                phase: {
                  _id: phase._id,
                  customFields: phase.customFields?.map((f) => {
                    if (f._id === customField._id && f.type === "currency") {
                      return {
                        _id: f._id,
                        value: numericValue,
                      };
                    }
                    return f;
                  }),
                },
                update: {
                  _id: phase._id,
                  customFields: phase.customFields?.map((f) => {
                    if (f._id === customField._id && f.type === "currency") {
                      return {
                        ...f,
                        value: numericValue,
                      };
                    }
                    return f;
                  }),
                },
              });
            } else {
              updatePhaseMutation.mutate({
                phase: {
                  _id: phase._id,
                  customFields: phase.customFields?.map((f) => {
                    if (f._id === customField._id && f.type === "currency") {
                      return {
                        _id: f._id,
                        value: null,
                      };
                    }
                    return f;
                  }),
                },
                update: {
                  _id: phase._id,
                  customFields: phase.customFields?.map((f) => {
                    if (f._id === customField._id && f.type === "currency") {
                      return {
                        ...f,
                        value: null,
                      };
                    }
                    return f;
                  }),
                },
              });
            }
          }}
          placeholder="Enter amount.."
        />
      </div>
    </div>
  );
}

function NumberCustomFieldValue({
  customField,
  phase,
}: {
  customField: CustomFieldValueData;
  phase: PhaseData;
}) {
  const [value, setValue] = useState(String(customField.value));

  useEffect(() => {
    setValue(String(customField.value));
  }, [customField.value]);

  const updatePhaseMutation = useUpdatePhase();

  return (
    <div key={customField._id} className="flex min-h-[32px] items-center gap-6">
      <div className="flex w-32 shrink-0 cursor-default items-center gap-1.5 text-[13px] font-light text-gray-500">
        {customField.crmProperty?.integrationId === "hubspot" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="3 3.5 12 12"
            className="w-4"
          >
            <path
              fill="#FF5C35"
              d="M11.54 7.422V6.096a1.02 1.02 0 0 0 .588-.92v-.03c0-.56-.46-1.02-1.02-1.02h-.03c-.561 0-1.02.46-1.02 1.02v.03c0 .407.243.757.589.92v1.326c-.516.08-.986.292-1.375.605l-3.64-2.832a1.14 1.14 0 0 0 .04-.286 1.15 1.15 0 1 0-1.15 1.148c.206 0 .398-.059.566-.154l3.58 2.786a2.892 2.892 0 0 0-.482 1.603c0 .62.196 1.194.527 1.666l-1.089 1.09a.935.935 0 0 0-.27-.045.945.945 0 1 0 .945.945.934.934 0 0 0-.044-.27l1.077-1.077a2.89 2.89 0 0 0 1.761.598 2.905 2.905 0 0 0 .446-5.776Zm-.447 4.36a1.49 1.49 0 1 1 0-2.98 1.49 1.49 0 0 1 0 2.98Z"
            />
          </svg>
        )}
        <div className="min-w-0 flex-1 truncate">{customField.name}</div>
      </div>
      <input
        className="-ml-1.5 h-8 flex-1 rounded border-none px-1.5 text-sm outline-none transition-colors placeholder:text-gray-400 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50"
        // style={{ WebkitAppearance: "none", MozAppearance: "textfield" }}
        value={value}
        disabled={customField.crmProperty?.readyOnly}
        type="number"
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onBlur={() => {
          if (value.trim() !== "") {
            updatePhaseMutation.mutate({
              phase: {
                _id: phase._id,
                customFields: phase.customFields?.map((f) => {
                  if (f._id === customField._id && f.type === "number") {
                    return {
                      _id: f._id,
                      value: Number(value),
                    };
                  }
                  return f;
                }),
              },
              update: {
                _id: phase._id,
                customFields: phase.customFields?.map((f) => {
                  if (f._id === customField._id && f.type === "number") {
                    return {
                      ...f,
                      value: Number(value),
                    };
                  }
                  return f;
                }),
              },
            });
          } else {
            updatePhaseMutation.mutate({
              phase: {
                _id: phase._id,
                customFields: phase.customFields?.map((f) => {
                  if (f._id === customField._id && f.type === "number") {
                    return {
                      _id: f._id,
                      value: null,
                    };
                  }
                  return f;
                }),
              },
              update: {
                _id: phase._id,
                customFields: phase.customFields?.map((f) => {
                  if (f._id === customField._id && f.type === "number") {
                    return {
                      ...f,
                      value: null,
                    };
                  }
                  return f;
                }),
              },
            });
          }
        }}
        placeholder="Enter number.."
      />
    </div>
  );
}

function DateCustomFieldValue({
  customField,
  phase,
}: {
  customField: CustomFieldValueData;
  phase: PhaseData;
}) {
  const updatePhaseMutation = useUpdatePhase();
  const [open, setOpen] = useState(false);
  if (customField.type !== "date") return <></>;

  return (
    <div key={customField._id} className="flex min-h-[32px] items-center gap-6">
      <div className="flex w-32 shrink-0 cursor-default items-center gap-1.5 text-[13px] font-light text-gray-500">
        {customField.crmProperty?.integrationId === "hubspot" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="3 3.5 12 12"
            className="w-4"
          >
            <path
              fill="#FF5C35"
              d="M11.54 7.422V6.096a1.02 1.02 0 0 0 .588-.92v-.03c0-.56-.46-1.02-1.02-1.02h-.03c-.561 0-1.02.46-1.02 1.02v.03c0 .407.243.757.589.92v1.326c-.516.08-.986.292-1.375.605l-3.64-2.832a1.14 1.14 0 0 0 .04-.286 1.15 1.15 0 1 0-1.15 1.148c.206 0 .398-.059.566-.154l3.58 2.786a2.892 2.892 0 0 0-.482 1.603c0 .62.196 1.194.527 1.666l-1.089 1.09a.935.935 0 0 0-.27-.045.945.945 0 1 0 .945.945.934.934 0 0 0-.044-.27l1.077-1.077a2.89 2.89 0 0 0 1.761.598 2.905 2.905 0 0 0 .446-5.776Zm-.447 4.36a1.49 1.49 0 1 1 0-2.98 1.49 1.49 0 0 1 0 2.98Z"
            />
          </svg>
        )}
        <div className="min-w-0 flex-1 truncate">{customField.name}</div>
      </div>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild disabled={customField.crmProperty?.readyOnly}>
          {/* <Button
            variant={"secondary"}
            className={cn(
              "group h-9 w-72 justify-between text-left font-normal",
              !customField.value ? "text-muted-foreground" : ""
            )}
          > */}
          <button
            className={clsx(
              "group -ml-1.5 flex min-h-[32px] w-full items-center justify-between space-x-2 rounded-md px-1.5 py-1 hover:bg-gray-100",
              open && "bg-gray-100",
              customField.crmProperty?.readyOnly &&
                "cursor-not-allowed opacity-50"
            )}
          >
            <div className="inline-flex items-center">
              {/* <CalendarIcon className="mr-2 h-4 w-4" /> */}
              {customField.value ? (
                dayjs(customField.value).format("ddd MMM DD, YYYY")
              ) : (
                <span className="text-gray-400">Select date..</span>
              )}
            </div>
            <div className="flex items-center space-x-2">
              {!!customField.value && (
                <button
                  tabIndex={-1}
                  className="ml-1 shrink-0 rounded-full p-1 text-gray-400 opacity-0 transition-opacity hover:bg-gray-200 hover:text-gray-600 group-hover:opacity-100"
                  onClick={(e) => {
                    e.stopPropagation();
                    updatePhaseMutation.mutate({
                      phase: {
                        _id: phase._id,
                        customFields: phase.customFields?.map((f) => {
                          if (f._id === customField._id && f.type === "date") {
                            return {
                              _id: f._id,
                              value: null,
                            };
                          }
                          return f;
                        }),
                      },
                      update: {
                        _id: phase._id,
                        customFields: phase.customFields?.map((f) => {
                          if (f._id === customField._id && f.type === "date") {
                            return {
                              ...f,
                              value: null,
                            };
                          }
                          return f;
                        }),
                      },
                    });
                  }}
                >
                  <XMarkIcon className="h-2.5 w-2.5 " />
                </button>
              )}
              <CaretSortIcon className="ml-2 h-4 w-4 text-black opacity-0 group-hover:opacity-40" />
            </div>
          </button>
          {/* </Button> */}
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            mode="single"
            selected={
              customField.value ? new Date(customField.value) : undefined
            }
            onSelect={(date) => {
              if (date) {
                updatePhaseMutation.mutate({
                  phase: {
                    _id: phase._id,
                    customFields: phase.customFields?.map((f) => {
                      if (f._id === customField._id && f.type === "date") {
                        return {
                          _id: f._id,
                          value: date.toISOString(),
                        };
                      }
                      return f;
                    }),
                  },
                  update: {
                    _id: phase._id,
                    customFields: phase.customFields?.map((f) => {
                      if (f._id === customField._id && f.type === "date") {
                        return {
                          ...f,
                          value: date.toISOString(),
                        };
                      }
                      return f;
                    }),
                  },
                });
              }
            }}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}

function MultiSelectCustomFieldValue({
  customField,
  phase,
}: {
  customField: MultiSelectCustomFieldValueData;
  phase: PhaseData;
}) {
  const [open, setOpen] = useState(false);
  const updatePhaseMutation = useUpdatePhase();

  if (customField.type !== "multi-select") return <></>;
  return (
    <div key={customField._id} className="flex min-h-[32px] items-center gap-6">
      <div className="flex w-32 shrink-0 cursor-default items-center gap-1.5 text-[13px] font-light text-gray-500">
        {customField.crmProperty?.integrationId === "hubspot" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="3 3.5 12 12"
            className="w-4"
          >
            <path
              fill="#FF5C35"
              d="M11.54 7.422V6.096a1.02 1.02 0 0 0 .588-.92v-.03c0-.56-.46-1.02-1.02-1.02h-.03c-.561 0-1.02.46-1.02 1.02v.03c0 .407.243.757.589.92v1.326c-.516.08-.986.292-1.375.605l-3.64-2.832a1.14 1.14 0 0 0 .04-.286 1.15 1.15 0 1 0-1.15 1.148c.206 0 .398-.059.566-.154l3.58 2.786a2.892 2.892 0 0 0-.482 1.603c0 .62.196 1.194.527 1.666l-1.089 1.09a.935.935 0 0 0-.27-.045.945.945 0 1 0 .945.945.934.934 0 0 0-.044-.27l1.077-1.077a2.89 2.89 0 0 0 1.761.598 2.905 2.905 0 0 0 .446-5.776Zm-.447 4.36a1.49 1.49 0 1 1 0-2.98 1.49 1.49 0 0 1 0 2.98Z"
            />
          </svg>
        )}
        <div className="min-w-0 flex-1 truncate">{customField.name}</div>
      </div>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <button
            className={clsx(
              "group -ml-1.5 flex min-h-[32px] w-full items-center justify-between space-x-2 rounded-md px-1.5 py-1 hover:bg-gray-100",
              open && "bg-gray-100",
              customField.crmProperty?.readyOnly &&
                "cursor-not-allowed hover:opacity-50"
            )}
            disabled={customField.crmProperty?.readyOnly}
          >
            {!!customField.value?.length ? (
              <div className="flex flex-wrap items-center gap-1">
                {customField.value?.map((value) => (
                  <Badge key={value}>
                    {customField.options.find((o) => o.value === value)?.label}
                  </Badge>
                ))}
              </div>
            ) : (
              <div className="font-normal text-gray-400">Select values..</div>
            )}
            <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-0 group-hover:opacity-40" />
          </button>
          {/* <Button
            variant={"secondary"}
            className="h-fit min-h-[36px] w-72 justify-between gap-1 pl-1.5"
          >
            {!!customFieldRecord?.value?.length ? (
              <div className="flex flex-wrap gap-1">
                {customFieldRecord.value.map((value) => (
                  <Badge key={value}>{value}</Badge>
                ))}
              </div>
            ) : (
              <div className="px-1.5 font-normal text-gray-300">
                Select values..
              </div>
            )}
            
          </Button> */}
        </PopoverTrigger>
        <PopoverContent
          className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
          align="start"
        >
          <Command>
            <CommandInput placeholder="Search.." className="h-9" />
            <CommandEmpty>No options found.</CommandEmpty>
            <CommandGroup>
              {customField?.options
                // ?.filter(
                //   (option) => !customFieldRecord.value.includes(option)
                // )
                ?.map((option) => (
                  <CommandItem
                    key={option.value}
                    value={option.label}
                    onSelect={() => {
                      // select or deselect the option
                      if (
                        customField.value &&
                        customField.value.includes(option.value)
                      ) {
                        updatePhaseMutation.mutate({
                          phase: {
                            _id: phase._id,
                            customFields: phase.customFields?.map((cf) => {
                              if (
                                cf._id === customField._id &&
                                cf.type === "multi-select"
                              ) {
                                return {
                                  _id: cf._id,
                                  value:
                                    cf.value?.filter(
                                      (v) => v !== option.value
                                    ) ?? null,
                                };
                              }
                              return cf;
                            }),
                          },
                          update: {
                            _id: phase._id,
                            customFields: phase.customFields?.map((cf) => {
                              if (
                                cf._id === customField._id &&
                                cf.type === "multi-select"
                              ) {
                                return {
                                  ...cf,
                                  value:
                                    cf.value?.filter(
                                      (v) => v !== option.value
                                    ) ?? null,
                                };
                              }
                              return cf;
                            }),
                          },
                        });
                      } else {
                        updatePhaseMutation.mutate({
                          phase: {
                            _id: phase._id,
                            customFields: phase.customFields?.map((cf) => {
                              if (
                                cf._id === customField._id &&
                                cf.type === "multi-select"
                              ) {
                                return {
                                  _id: cf._id,
                                  value: [...(cf.value ?? []), option.value],
                                };
                              }
                              return cf;
                            }),
                          },
                          update: {
                            _id: phase._id,
                            customFields: phase.customFields?.map((cf) => {
                              if (
                                cf._id === customField._id &&
                                cf.type === "multi-select"
                              ) {
                                return {
                                  ...cf,
                                  value: [...(cf.value ?? []), option.value],
                                };
                              }
                              return cf;
                            }),
                          },
                        });
                      }
                      //   newProjectCtx.dispatch({
                      //     type: "CUSTOM_FIELDS_UPDATE",
                      //     customFieldRecords:
                      //       newProjectCtx.state.customFieldRecords.map((cf) => {
                      //         if (
                      //           cf._id === customField._id &&
                      //           cf.type === "multi-select"
                      //         ) {
                      //           return {
                      //             ...cf,
                      //             value:
                      //               cf.value?.filter((v) => v !== option) ??
                      //               null,
                      //           };
                      //         }
                      //         return cf;
                      //       }),
                      //   });
                      // } else {
                      //   newProjectCtx.dispatch({
                      //     type: "CUSTOM_FIELDS_UPDATE",
                      //     customFieldRecords:
                      //       newProjectCtx.state.customFieldRecords.map((cf) => {
                      //         if (
                      //           cf._id === customField._id &&
                      //           cf.type === "multi-select"
                      //         ) {
                      //           return {
                      //             ...cf,
                      //             value: [...(cf.value ?? []), option],
                      //           };
                      //         }
                      //         return cf;
                      //       }),
                      //   });
                      // }
                    }}
                    className="gap-2"
                  >
                    <Checkbox
                      // onClick={(e) => {
                      //   e.stopPropagation();
                      // }}
                      checked={
                        !!customField.value &&
                        customField.value.includes(option.value)
                      }
                      // onCheckedChange={(checked) => {
                      //   if (checked) {
                      //     updatePhaseMutation.mutate({
                      //       _id: phase._id,
                      //       customFields: phase.customFields?.map((cf) => {
                      //         if (
                      //           cf._id === customField._id &&
                      //           cf.type === "multi-select"
                      //         ) {
                      //           return {
                      //             ...cf,
                      //             value: [...(cf.value ?? []), option],
                      //           };
                      //         }
                      //         return cf;
                      //       }),
                      //     });
                      //   } else {
                      //     updatePhaseMutation.mutate({
                      //       _id: phase._id,
                      //       customFields: phase.customFields?.map((cf) => {
                      //         if (
                      //           cf._id === customField._id &&
                      //           cf.type === "multi-select"
                      //         ) {
                      //           return {
                      //             ...cf,
                      //             value:
                      //               cf.value?.filter((v) => v !== option) ??
                      //               null,
                      //           };
                      //         }
                      //         return cf;
                      //       }),
                      //     });
                      //   }
                      //   // if (checked) {
                      //   //   newProjectCtx.dispatch({
                      //   //     type: "CUSTOM_FIELDS_UPDATE",
                      //   //     customFieldRecords:
                      //   //       newProjectCtx.state.customFieldRecords.map(
                      //   //         (cf) => {
                      //   //           if (
                      //   //             cf._id === customField._id &&
                      //   //             cf.type === "multi-select"
                      //   //           ) {
                      //   //             return {
                      //   //               ...cf,
                      //   //               value: [...(cf.value ?? []), option],
                      //   //             };
                      //   //           }
                      //   //           return cf;
                      //   //         }
                      //   //       ),
                      //   //   });
                      //   // } else {
                      //   //   newProjectCtx.dispatch({
                      //   //     type: "CUSTOM_FIELDS_UPDATE",
                      //   //     customFieldRecords:
                      //   //       newProjectCtx.state.customFieldRecords.map(
                      //   //         (cf) => {
                      //   //           if (
                      //   //             cf._id === customField._id &&
                      //   //             cf.type === "multi-select"
                      //   //           ) {
                      //   //             return {
                      //   //               ...cf,
                      //   //               value:
                      //   //                 cf.value?.filter((v) => v !== option) ??
                      //   //                 null,
                      //   //             };
                      //   //           }
                      //   //           return cf;
                      //   //         }
                      //   //       ),
                      //   //   });
                      //   // }
                      // }}
                    />

                    <div className="flex items-center space-x-2">
                      <span className="truncate">{option.label}</span>
                    </div>
                  </CommandItem>
                  // <CommandItem
                  //   key={option}
                  //   onSelect={() => {
                  //     // update the custom field value while keeping the other custom field values the same
                  //     newProjectCtx.dispatch({
                  //       type: "CUSTOM_FIELDS_UPDATE",
                  //       customFieldRecords:
                  //         newProjectCtx.state.customFieldRecords.map(
                  //           (cf) => {
                  //             if (
                  //               cf._id === customField._id &&
                  //               cf.type === "multi-select"
                  //             ) {
                  //               return {
                  //                 ...cf,
                  //                 value: [...cf.value, option],
                  //               };
                  //             }
                  //             return cf;
                  //           }
                  //         ),
                  //     });
                  //     setOpen(false);
                  //   }}
                  //   className="gap-2"
                  // >
                  //   <span className="truncate">{option}</span>
                  // </CommandItem>
                ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}

function TextCustomFieldValue({
  customField,
  phase,
}: {
  customField: CustomFieldValueData;
  phase: PhaseData;
}) {
  const [value, setValue] = useState(
    customField.type === "text" ? customField.value ?? "" : ""
  );
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const updatePhaseMutation = useUpdatePhase();

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "0px"; // Reset height to shrink if needed
      // textAreaRef.current.style.height = "auto";
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + "px";
    }
  }, [value]);

  return (
    <div
      key={customField._id}
      className="relative flex min-h-[32px] items-center gap-6"
    >
      <div className="flex w-32 shrink-0 cursor-default items-center gap-1.5 text-[13px] font-light text-gray-500">
        {customField.crmProperty?.integrationId === "hubspot" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="3 3.5 12 12"
            className="w-4"
          >
            <path
              fill="#FF5C35"
              d="M11.54 7.422V6.096a1.02 1.02 0 0 0 .588-.92v-.03c0-.56-.46-1.02-1.02-1.02h-.03c-.561 0-1.02.46-1.02 1.02v.03c0 .407.243.757.589.92v1.326c-.516.08-.986.292-1.375.605l-3.64-2.832a1.14 1.14 0 0 0 .04-.286 1.15 1.15 0 1 0-1.15 1.148c.206 0 .398-.059.566-.154l3.58 2.786a2.892 2.892 0 0 0-.482 1.603c0 .62.196 1.194.527 1.666l-1.089 1.09a.935.935 0 0 0-.27-.045.945.945 0 1 0 .945.945.934.934 0 0 0-.044-.27l1.077-1.077a2.89 2.89 0 0 0 1.761.598 2.905 2.905 0 0 0 .446-5.776Zm-.447 4.36a1.49 1.49 0 1 1 0-2.98 1.49 1.49 0 0 1 0 2.98Z"
            />
          </svg>
        )}
        <div className="min-w-0 flex-1 truncate">{customField.name}</div>
      </div>
      <textarea
        value={value}
        ref={textAreaRef}
        disabled={customField.crmProperty?.readyOnly}
        onChange={(e) => {
          // do not allow enters
          if (e.target.value.includes("\n")) {
            return;
          }
          setValue(e.target.value);
        }}
        placeholder={"Enter text.."}
        className={clsx(
          "-ml-1.5 w-full resize-none rounded border-none p-1.5 px-1.5 text-sm outline-none transition-colors placeholder:text-gray-400 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none focus:ring-0",
          customField.crmProperty?.readyOnly && "cursor-not-allowed opacity-50"
        )}
        onBlur={(e) => {
          if (e.target.value.trim() !== customField.value) {
            updatePhaseMutation.mutate({
              phase: {
                _id: phase._id,
                customFields: phase.customFields?.map((f) => {
                  if (f._id === customField._id && f.type === "text") {
                    return {
                      _id: f._id,
                      value: e.target.value,
                    };
                  }
                  return f;
                }),
              },
              update: {
                _id: phase._id,
                customFields: phase.customFields?.map((f) => {
                  if (f._id === customField._id && f.type === "text") {
                    return {
                      ...f,
                      value: e.target.value,
                    };
                  }
                  return f;
                }),
              },
            });
          }
        }}
      />
    </div>
  );
}

function ForecastedCompletionDate({
  keyDates,
  phase,
}: {
  keyDates: {
    startedDate: string;
    plannedCompletionDate: string;
    forecastedCompletionDate: string;
    completionDate?: string;
  };
  phase: PhaseData;
}) {
  const delta = getTimeDifference(
    new Date(keyDates.forecastedCompletionDate),
    new Date(keyDates.plannedCompletionDate)
  );

  // const days = Math.ceil(absDifferenceInMs / (1000 * 60 * 60 * 24));

  return (
    <div>
      <div
        className={clsx(
          "flex items-center space-x-2",
          delta.isDate2After ? "text-green-500" : "text-red-500"
        )}
      >
        <div className="text-black">
          {dayjs(keyDates.forecastedCompletionDate).format("DD MMM, YYYY")}
        </div>
        {phase.type === "Customer Onboarding" && (
          <div
            className={clsx(
              "flex items-center text-xs",
              delta.isDate2After ? "text-green-500" : "text-red-500"
            )}
          >
            {delta.isDate2After ? (
              <TriangleUpIcon className="h-4 w-4" />
            ) : (
              <TriangleDownIcon className="h-4 w-4" />
            )}
            {`${delta.absValue} ${delta.unit}`}
          </div>
        )}
        {/* {days > 1 && phase.type !== "Simple" && (
          <div className="flex items-center space-x-1">
            {isDate2After ? (
              <>
                <TriangleUpIcon className="h-4 w-4" />
                {days}
              </>
            ) : (
              <>
                <TriangleUpIcon className="h-4 w-4 rotate-180 transform" />
                {days}
              </>
            )}
          </div>
        )} */}
      </div>
    </div>
  );
}

function ForecastAheadOrBehind({
  keyDates,
}: {
  keyDates: {
    startedDate: string;
    plannedCompletionDate: string;
    forecastedCompletionDate: string;
    completionDate?: string;
  };
}) {
  const delta = getTimeDifference(
    new Date(keyDates.forecastedCompletionDate),
    new Date(keyDates.plannedCompletionDate)
  );
  return (
    <div>
      <div
        className={clsx(
          "text-sm",
          delta.isDate2After ? "text-green-500" : "text-red-500"
        )}
      >{`Project is ${delta.absValue} ${delta.unit} ${
        delta.isDate2After ? "ahead of" : "behind"
      } schedule`}</div>
    </div>
  );
}

export function getPhaseStatus(status: PhaseStatus) {
  switch (status) {
    case "In Progress":
      return (
        <div className="flex items-center space-x-2">
          <div className="h-2 w-2 rounded-full bg-blue-400" />
          <div>In Progress</div>
        </div>
      );
    case "Completed":
      return (
        <div className="flex items-center space-x-2">
          <div className="h-2 w-2 rounded-full bg-green-400" />
          <div>Completed</div>
        </div>
      );
    case "Paused":
      return (
        <div className="flex items-center space-x-2">
          <div className="h-2 w-2 rounded-full border  border-gray-400" />
          <div>Paused</div>
        </div>
      );
    case "Canceled":
      return (
        <div className="flex items-center space-x-2">
          <div className="h-2 w-2 rounded-full bg-gray-400" />
          <div>Canceled</div>
        </div>
      );
  }
}

export function PhaseStatusSelect({ phase }: { phase: PhaseData }) {
  const [status, setStatus] = useState(phase.status);
  const updatePhaseMutation = useUpdatePhase();
  const [open, setOpen] = useState(false);
  return (
    <Select
      open={open}
      onOpenChange={setOpen}
      value={status}
      onValueChange={(value) => {
        setStatus(value as PhaseStatus);
        updatePhaseMutation.mutate({
          phase: {
            _id: phase._id,
            status: value as PhaseStatus,
            journeyId: phase.journey._id,
          },
          update: {
            _id: phase._id,
            status: value as PhaseStatus,
            journeyId: phase.journey._id,
          },
        });
      }}
    >
      <SelectTrigger
        className={clsx("-ml-1.5 border-none px-0 shadow-none")}
        showIcon={false}
      >
        <div
          className={clsx(
            "group flex min-h-[32px] w-full items-center justify-between space-x-2 rounded-md px-1.5 py-1 hover:bg-gray-100",
            open && "bg-gray-100"
          )}
        >
          {getPhaseStatus(phase.status)}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-0 group-hover:opacity-40" />
        </div>
      </SelectTrigger>
      <SelectContent align="end" className="min-w-[120px]">
        <SelectGroup>
          <SelectItem value={"In Progress"}>
            {getPhaseStatus("In Progress")}
          </SelectItem>
          <SelectItem value={"Completed"}>
            {getPhaseStatus("Completed")}
          </SelectItem>
          <SelectItem value={"Paused"}>{getPhaseStatus("Paused")}</SelectItem>
          <SelectItem value={"Canceled"}>
            {getPhaseStatus("Canceled")}
          </SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
    // <Select

    // >
    //   <SelectTrigger
    //     className={`mt-0 h-7 w-fit max-w-full gap-2 bg-white px-2`}
    //   >
    //     <PieChartIcon className="h-4 w-4 shrink-0 text-gray-700" />

    //   </SelectTrigger>

    // </Select>
  );
}

type NextUpBreakdownGroup = "my tasks" | "internal" | "external";

function NextUp({ phase }: { phase: PhaseData }) {
  const { data: tasks } = useGetPhaseTasks(phase._id);
  const app = useRealmApp();
  const [tasker, setTasker] = useState<NextUpBreakdownGroup>("my tasks");

  const nextUpTasks = tasks?.filter(
    (t) =>
      (t.status === "To-do" ||
        t.status === "In Progress" ||
        t.status === "Stuck") &&
      !!t.dueDate
  );

  const myTasks = nextUpTasks?.filter(
    (t) =>
      t.tasker.userType === "fullUser" &&
      t.tasker._id === app.currentUser?.customData?._id
  );

  const internalTasks = nextUpTasks?.filter((t) => t.taskerType === "internal");

  const externalTasks = nextUpTasks?.filter((t) => t.taskerType === "external");

  const filteredTasks = tasks
    ?.filter(
      (t) =>
        (t.status === "To-do" ||
          t.status === "In Progress" ||
          t.status === "Stuck") &&
        !!t.dueDate
    )
    .filter((t) => {
      if (tasker === "my tasks") {
        return (
          t.tasker.userType === "fullUser" &&
          t.tasker._id.toString() === app.currentUser?.customData?._id
        );
      } else if (tasker === "internal") {
        return t.taskerType === "internal";
      } else if (tasker === "external") {
        return t.taskerType === "external";
      }
    });
  const taskerText =
    tasker === "my tasks"
      ? app.currentUser?.customData?.name
      : tasker === "internal"
      ? phase.instance.name
      : phase.customer?.name;
  return (
    <div className="flex flex-1 flex-col space-y-4 pb-12">
      <div className="flex items-center justify-between gap-4">
        <div className="font- text-2xl text-gray-600">Next Up</div>
        {/* <div>
          <Select
            value={tasker}
            onValueChange={(value) => {
              setTasker(value as NextUpBreakdownGroup);
            }}
          >
            <SelectTrigger
              className={`h-7 w-fit max-w-full gap-2 bg-white px-2`}
            >
              <UsersIcon className="h-4 w-4 shrink-0 text-gray-700" />
              <SelectValue className="truncate text-xs">
                {tasker === "my tasks"
                  ? app.currentUser?.customData?.name
                  : tasker === "internal"
                  ? phase.instance.name
                  : phase.customer.name}
              </SelectValue>
            </SelectTrigger>
            <SelectContent align="end" className="min-w-[120px]">
              <SelectGroup>
                <SelectItem value={"my tasks"}>
                  {app.currentUser?.customData?.name}
                </SelectItem>
                <SelectItem value={"external"}>
                  {phase.customer.name}
                </SelectItem>
                <SelectItem value={"internal"}>
                  {phase.instance.name}
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div> */}
      </div>
      <div className="mt-3 grid grid-cols-3 rounded-md shadow">
        <div
          className={clsx(
            "flex cursor-pointer flex-col items-center rounded-l-md border border-r-0 p-3 hover:bg-gray-100",
            tasker === "my tasks" && "border-b-4 border-b-embedPrimary"
          )}
          onClick={() => {
            setTasker("my tasks");
          }}
        >
          <div className="text-sm text-gray-600">
            {app.currentUser.customData.name}
          </div>
          <div className="text-gray-700">
            <span className="text-2xl font-bold">{myTasks?.length}</span>{" "}
            <span className="text-sm font-semibold">
              {myTasks?.length === 1 ? "task" : "tasks"}
            </span>
          </div>
        </div>

        <div
          className={clsx(
            "flex cursor-pointer flex-col items-center border border-x p-3 hover:bg-gray-100",
            tasker === "internal" && "border-b-4 border-b-embedPrimary"
          )}
          onClick={() => {
            setTasker("internal");
          }}
        >
          <div className="text-sm text-gray-600">{phase.instance.name}</div>
          <div className="text-gray-700">
            <span className="text-2xl font-bold">{internalTasks?.length}</span>{" "}
            <span className="text-sm font-semibold">
              {internalTasks?.length === 1 ? "task" : "tasks"}
            </span>
          </div>
        </div>

        <div
          className={clsx(
            "flex cursor-pointer flex-col items-center rounded-r-md border border-l-0 p-3 hover:bg-gray-100",
            tasker === "external" && "border-b-4 border-b-embedPrimary"
          )}
          onClick={() => {
            setTasker("external");
          }}
        >
          <div className="text-sm text-gray-600">{phase.customer?.name}</div>
          {!!externalTasks && (
            <div className="text-gray-700">
              <span className="text-2xl font-bold">
                {externalTasks?.length}
              </span>{" "}
              <span className="text-sm font-semibold">
                {externalTasks?.length === 1 ? "task" : "tasks"}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="space-y-6">
        {!!filteredTasks && (
          <>
            {filteredTasks.length === 0 ? (
              <div className="flex items-center space-x-3 rounded-md border border-blue-500 bg-blue-50 px-3 py-2 text-sm text-blue-500 shadow">
                <InformationCircleIcon className="h-5 w-5" />
                {tasker === "my tasks" ? (
                  <span>{`Currently no active tasks for you!`}</span>
                ) : (
                  <span>{`Currently no active tasks for ${taskerText}!`}</span>
                )}
              </div>
            ) : (
              <>
                {filteredTasks?.map((task) => {
                  return (
                    <NextUpTaskCard key={task._id?.toString()} task={task} />
                  );
                })}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function NextUpTaskCard({ task }: { task: TaskData }) {
  const updateTaskMutation = useUpdateTask();
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className={clsx("rounded-md border px-6 py-5 shadow")}>
      <div className="flex items-start justify-between gap-2 pb-3">
        <div className="text-3xl font-medium">{task.title}</div>
        <TaskTasker task={task} />
        {/* {task.action === "form" && (
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger asChild>
                <DocumentTextIcon
                  className={clsx("h-5 w-5 shrink-0 text-gray-400/80")}
                />
              </TooltipTrigger>
              <TooltipContent side="left" className="p-2 py-1">
                <p>Form task</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )} */}
        {/* {task.action === "fileUpload" && (
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger asChild>
                <CloudArrowUpIcon
                  className={clsx("h-5 w-5 shrink-0 text-gray-400/80")}
                />
              </TooltipTrigger>
              <TooltipContent side="left" className="p-2 py-1">
                <p>File upload task</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )} */}
        {/* {task.action === "meeting" && (
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger asChild>
                <PhoneIcon
                  className={clsx("h-4 w-4 shrink-0 text-gray-400/80")}
                />
              </TooltipTrigger>
              <TooltipContent side="left" className="p-2 py-1">
                <p>Call task</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )} */}
        {/* {!!task.subTaskCount && (
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger asChild>
                <div className="inline-flex items-center space-x-0.5 text-sm text-gray-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M17 16a2.99 2.99 0 0 0-2.816 2H11c-1.654 0-3-1.346-3-3v-3.025A4.954 4.954 0 0 0 11 13h3.184A2.99 2.99 0 0 0 17 15a3 3 0 1 0 0-6a2.99 2.99 0 0 0-2.816 2H11c-1.654 0-3-1.346-3-3v-.184A2.99 2.99 0 0 0 10 5a3 3 0 1 0-6 0a2.99 2.99 0 0 0 2 2.816V15c0 2.757 2.243 5 5 5h3.184A2.99 2.99 0 0 0 17 22a3 3 0 1 0 0-6zm0-5a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zM7 4a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zm10 16a1.001 1.001 0 0 1 0-2a1.001 1.001 0 0 1 0 2z"
                    />
                  </svg>
                  <span>{task.subTaskCount}</span>
                </div>
              </TooltipTrigger>
              <TooltipContent side="left" className="p-2 py-1">
                <p>{`${task.subTaskCount} subtasks`}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )} */}
      </div>
      <div>
        <TaskDescription task={task} />
      </div>
      <div className="flex max-w-full items-end justify-between space-x-2">
        {/* {task.action === "default" && (
          <StatusSelect task={task} disabled={false} />
        )} */}
        <div className="flex items-center space-x-2">
          <StatusSelect
            task={task}
            disabledStatuses={
              task.action === "form" || task.action === "fileUpload"
                ? ["Completed"]
                : []
            }
          />
          {task.action === "fileUpload" && (
            <NewButton
              onClick={() => {
                if (task._id) {
                  const searchParams = new URLSearchParams(location.search);
                  searchParams.set("taskId", task._id);
                  navigate({
                    pathname: location.pathname,
                    search: searchParams.toString(),
                  });
                }
              }}
              variant={"secondary"}
            >
              Upload File
            </NewButton>
          )}
          {task.action === "form" && (
            <NewButton
              onClick={() => {
                if (task._id) {
                  const searchParams = new URLSearchParams(location.search);
                  searchParams.set("taskId", task._id);
                  navigate({
                    pathname: location.pathname,
                    search: searchParams.toString(),
                  });
                }
              }}
              variant={"secondary"}
            >
              Open Form
            </NewButton>
          )}
          {task.action === "meeting" && (
            <>
              <NewButton
                onClick={() => {
                  console.log(task);
                  console.log(task.meeting);
                  if (task.meeting?.link) {
                    window.open(task.meeting?.link, "_blank");
                  }
                }}
                variant={"secondary"}
              >
                Book Meeting
              </NewButton>
            </>
          )}
          <NewButton
            onClick={() => {
              if (task._id) {
                const searchParams = new URLSearchParams(location.search);
                searchParams.set("taskId", task._id);
                navigate({
                  pathname: location.pathname,
                  search: searchParams.toString(),
                });
              }
            }}
            variant={"secondary"}
          >
            <ChatBubbleLeftEllipsisIcon className="mr-2 h-5 w-5" />
            <span>Open Chat</span>
          </NewButton>
        </div>
        <TaskDueDate task={task} />
      </div>
    </div>
  );
}

function TaskTasker({ task }: { task: TaskData }) {
  const taskerName =
    task.tasker.userType === "fullUser"
      ? task.tasker.name
      : task.tasker.userType === "invitedUser"
      ? task.tasker.email
      : "Unassigned";

  return (
    <div
      className={clsx(
        "flex h-7 items-center space-x-1.5 whitespace-nowrap rounded-full border px-2.5 text-xs shadow-sm",
        task.tasker.userType === "persona"
          ? "border-red-600 text-red-500"
          : task.taskerType === "internal"
          ? "border-gray-300 text-gray-600"
          : "border-gray-400 bg-gray-100 text-black"
      )}
    >
      <UserIcon className="h-3.5 w-3.5" />
      <span> {`${taskerName}`}</span>
    </div>
  );
}

function TaskDueDate({ task }: { task: TaskData }) {
  if (!task.dueDate) {
    return <div>This task is not active as yet</div>;
  }
  const { absValue, isDate2After, unit } = getTimeDifference(
    new Date(),
    new Date(task.dueDate)
  );
  return (
    <>
      {isDate2After ? (
        <span className="text-sm text-gray-500">
          {`This task is due in ${absValue} ${unit}.`}
        </span>
      ) : (
        <span className="text-sm text-red-500">
          {`This task is ${absValue} ${unit} overdue.`}
        </span>
      )}
    </>
  );
}

function TaskDescription({ task }: { task: TaskData }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const descriptionRef = useRef<HTMLDivElement>(null);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Typography,
      Link.configure({
        openOnClick: false,
      }),
    ],
    editorProps: {
      attributes: {
        class:
          "prose outline-none ring-0 focus:outline-none focus:ring-0 border-none focus:border-none",
      },
    },
    content: task.description,
    editable: false,
  });

  useLayoutEffect(() => {
    if (descriptionRef.current && descriptionRef.current.scrollHeight > 400) {
      setIsOverflowing(true);
    }
  }, [task.description, editor]);

  return (
    <>
      {!!editor && !editor?.isEmpty && (
        <div
          className={clsx(
            "relative mb-4 origin-top-left rounded-md bg-gray-100 p-3",
            isOverflowing
              ? isExpanded
                ? ""
                : "max-h-[400px] overflow-hidden"
              : ""
          )}
          ref={descriptionRef}
        >
          <EditorContent editor={editor} />
          {isOverflowing && !isExpanded && (
            <div className="absolute inset-x-0 bottom-0 flex w-full items-center justify-center bg-gradient-to-b from-transparent to-white px-3 py-6 text-sm text-gray-500">
              {/* <div className="flex items-center space-x-1 bg-gray-300 border rounded-md px-3 py-1">
                <span>Show more</span>
              </div> */}
              <Button
                variant="secondary"
                onClick={() => {
                  setIsExpanded(!isExpanded);
                }}
              >
                Show more
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
}

const Timeline = ({
  journey,
  setTabToProject,
}: {
  journey: JourneyData;
  setTabToProject?: () => void;
}) => {
  const { stages } = useGetJourneyStages(new ObjectID(journey?._id));
  return (
    <>
      {!!stages ? (
        <>
          {/* <div className="flex gap-4 max-w-full ">
            {[1,2,3,4,5,6,7].map(i => <div key={i} className="h-40 w-40 bg-gray-500 shrink-0"></div>)}
          </div> */}
          <div className="flex max-w-full shrink-0 items-start space-x-3 overflow-x-auto py-3">
            <div className="flex h-24 w-max items-start space-x-3 overflow-x-auto pr-12 pt-2">
              {stages
                .filter((stage) => !!stage.tasks && stage.tasks?.length > 0)
                // .filter(
                //   (stage) =>
                //     !!stage.tasks &&
                //     stage.tasks.filter(
                //       (t) => t.taskerType === "external" || t.isVisible
                //     ).length > 0
                // )
                .map((s, i) => (
                  <Stage
                    key={i}
                    stage={s}
                    isFirst={i === 0}
                    isLast={
                      i ===
                      stages.filter(
                        (stage) => !!stage.tasks && stage.tasks?.length > 0
                      ).length
                    }
                  />
                ))}
            </div>
            <div className="shrink-0 text-3xl font-medium text-embedPrimary">
              {journey.completionPercent.toFixed(0) + "%"}
            </div>
            {/* <div className="text-2xl font-medium">{"Onboarding"}</div> */}
            {/* <div className="text-2xl font-medium">{journey.name}</div> */}
            {/* <button
            className="group flex cursor-pointer space-x-3 rounded-md border border-gray-400 bg-gray-100 px-3 py-2 text-sm hover:border-gray-700 hover:bg-gray-200"
            onClick={setTabToProject}
          >
            <RocketLaunchIcon className="h-5 w-5 text-gray-500 group-hover:text-gray-700" />
            <span className="text-gray-500 group-hover:text-gray-700">
              View Project in Detail
            </span>
          </button> */}
          </div>
        </>
      ) : (
        <div className="my-12 inline-flex">
          <div className="text-gray-400">Loading Timeline...</div>
        </div>
      )}
    </>
  );
};
type TaskBreakdownGroup = "all" | "internal" | "external";

function TasksDonut({ phase }: { phase: PhaseData }) {
  const { data: tasks } = useGetPhaseTasks(phase._id);
  const [value, setValue] = useState<TaskBreakdownGroup>("all");

  const tasksBreakdown: [
    { type: "To Do"; value: number },
    { type: "In Progress"; value: number },
    { type: "Stuck"; value: number },
    { type: "Completed"; value: number },
    { type: "Inactive"; value: number }
  ] = [
    {
      type: "To Do",
      value:
        tasks
          // ?.filter((t) => t.isVisible)
          ?.filter(
            (t) =>
              t.status === "To-do" &&
              !!t.dueDate &&
              (value === "all"
                ? true
                : value === "internal"
                ? t.taskerType === "internal"
                : t.taskerType === "external")
          ).length ?? 0,
    },
    {
      type: "In Progress",
      value:
        tasks
          // ?.filter((t) => t.isVisible)
          ?.filter(
            (t) =>
              t.status === "In Progress" &&
              (value === "all"
                ? true
                : value === "internal"
                ? t.taskerType === "internal"
                : t.taskerType === "external")
          ).length ?? 0,
    },
    {
      type: "Stuck",
      value:
        tasks
          // ?.filter((t) => t.isVisible)
          ?.filter(
            (t) =>
              t.status === "Stuck" &&
              (value === "all"
                ? true
                : value === "internal"
                ? t.taskerType === "internal"
                : t.taskerType === "external")
          ).length ?? 0,
    },
    {
      type: "Completed",
      value:
        tasks
          // ?.filter((t) => t.isVisible)
          ?.filter(
            (t) =>
              t.status === "Completed" &&
              (value === "all"
                ? true
                : value === "internal"
                ? t.taskerType === "internal"
                : t.taskerType === "external")
          ).length ?? 0,
    },
    {
      type: "Inactive",
      value:
        tasks
          // ?.filter((t) => t.isVisible)
          ?.filter(
            (t) =>
              !t.dueDate &&
              (value === "all"
                ? true
                : value === "internal"
                ? t.taskerType === "internal"
                : t.taskerType === "external")
          ).length ?? 0,
    },
  ];

  return (
    <div className="space-y-4">
      <Select
        value={value}
        onValueChange={(value) => {
          setValue(value as TaskBreakdownGroup);
        }}
      >
        <SelectTrigger className={`h-7 w-fit max-w-full gap-2 bg-white px-2`}>
          <UsersIcon className="h-4 w-4 shrink-0 text-gray-700" />
          <SelectValue className="truncate text-xs">
            {value === "all"
              ? "All"
              : value === "internal"
              ? phase.instance.name
              : phase.customer?.name}
          </SelectValue>
        </SelectTrigger>
        <SelectContent className="min-w-[120px]">
          <SelectGroup>
            <SelectItem value={"all"}>All</SelectItem>
            <SelectItem value={"external"}>{phase.customer?.name}</SelectItem>
            <SelectItem value={"internal"}>{phase.instance.name}</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
      <DonutChart
        data={tasksBreakdown}
        category="value"
        index="type"
        // valueFormatter={valueFormatter}
        showAnimation={true}
        colors={["amber", "blue", "red", "green", "gray"]}
      />
      <div className="flex justify-center">
        <Legend
          className="w-60 flex-wrap justify-center"
          categories={[
            "To Do",
            "In Progress",
            "Stuck",
            "Completed",
            "Inactive",
          ]}
          colors={["amber", "blue", "red", "green", "gray"]}
        />
      </div>
    </div>
  );
}

function PhaseDates({
  tasks,
  phaseId,
}: {
  tasks: TaskData[];
  phaseId: string;
}) {
  const { data: keyDates } = useGetKeyDatesForPhase(phaseId);
  const isCompleted = tasks.every((task) => task.completionDate);

  function StartedDate({ startedDate }: { startedDate: Date }) {
    const timeAgo = getTimeDifference(startedDate, new Date());
    return (
      <div className="space-y-1">
        <div className="text-xl">
          {!!keyDates ? dayjs(keyDates.startedDate).format("DD MMM, YYYY") : ""}
        </div>
        <div className="text-sm text-gray-500">{`${timeAgo.absValue} ${timeAgo.unit} ago`}</div>
      </div>
    );
  }

  function ForecastedCompletionDate({
    forecastedCompletionDate,
    plannedCompletionDate,
  }: {
    forecastedCompletionDate: Date;
    plannedCompletionDate: Date;
  }) {
    const delta = getTimeDifference(
      forecastedCompletionDate,
      plannedCompletionDate
    );
    return (
      <div className="space-y-1">
        <div
          className={clsx(
            "text-xl",
            delta.isDate2After ? "text-green-500" : "text-red-500"
          )}
        >
          {!!keyDates
            ? dayjs(keyDates.forecastedCompletionDate).format("DD MMM, YYYY")
            : ""}
        </div>
        <div
          className={clsx(
            "text-sm",
            delta.isDate2After ? "text-green-500" : "text-red-500"
          )}
        >{`${delta.absValue} ${delta.unit} ${
          delta.isDate2After ? "ahead of schedule" : "behind schedule"
        }`}</div>
      </div>
    );
  }

  return (
    <div className="flex justify-between">
      <div className="space-y-2 ">
        <div className="text-sm text-gray-400">{"Start Date"}</div>
        {!!keyDates ? (
          <StartedDate startedDate={new Date(keyDates.startedDate)} />
        ) : (
          <div className="space-y-1">
            <div className="h-6 w-40 rounded bg-gray-200"></div>
            <div className="h-3 w-20 rounded bg-gray-200"></div>
          </div>
        )}
      </div>
      <div className="space-y-2">
        <div className="text-sm text-gray-400">{"Planned End Date"}</div>
        <div className="space-y-1">
          <div className="text-xl">
            {!!keyDates
              ? dayjs(keyDates?.plannedCompletionDate).format("DD MMM, YYYY")
              : ""}
          </div>
          {/* <div className="text-sm text-gray-500">{"23 days ago"}</div> */}
        </div>
      </div>
      <div className="space-y-2">
        {!!keyDates ? (
          <>
            <div className="text-sm text-gray-400">
              {isCompleted ? "Completion Date" : "Forecasted End Date"}
            </div>
            {isCompleted ? (
              <div className="text-xl">
                {!!keyDates
                  ? dayjs(keyDates?.completionDate).format("DD MMM, YYYY")
                  : ""}
              </div>
            ) : (
              <ForecastedCompletionDate
                forecastedCompletionDate={
                  new Date(keyDates.forecastedCompletionDate)
                }
                plannedCompletionDate={new Date(keyDates.plannedCompletionDate)}
              />
            )}
          </>
        ) : (
          <>
            <div className="h-3 w-48 rounded bg-gray-200"></div>
            <div className="space-y-1">
              <div className="h-6 w-40 rounded bg-gray-200"></div>
              <div className="h-3 w-20 rounded bg-gray-200"></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function Progress({ tasks }: { tasks: TaskData[] }) {
  const completedTasks = tasks.filter((t) => !!t.completionDate);
  const completionPercentage = completedTasks.length / tasks.length;
  // console.log(completionPercentage);
  return (
    <div>
      <Flex>
        <Text>{`${(
          completionPercentage * 100
        ).toFixed()}% of onboarding completed`}</Text>
        <Text>{`${completedTasks.length}/${tasks.length} tasks done`}</Text>
      </Flex>
      <ProgressBar
        value={completionPercentage * 100}
        className="mt-2"
        color="green"
      />
    </div>
  );
}

function ProgressLineChart({ journeyId }: { journeyId: string }) {
  const { data: tasksProgress } = useGetTasksProgressForJourney(journeyId);
  return (
    <>
      <Title>Task Completions</Title>
      {<ProgressChart tasksProgress={tasksProgress ?? []} />}
    </>
  );
}

function ProgressChart({
  tasksProgress,
}: {
  tasksProgress: JourneyTaskProgress[];
}) {
  let data = tasksProgress.map((t) => ({
    date: dayjs(t.date).format("MMM DD"),
    Plan: t.plannedTasks,
    Actual: t.completedTasks,
    Forecasted: t.forecastedTasks,
  }));
  if (data.length > 0)
    data.unshift({
      date: "Start",
      Plan: 0,
      Actual: 0,
      Forecasted: undefined,
    });

  return (
    <>
      <LineChart
        curveType="linear"
        noDataText="No tasks in journey"
        className="mt-6"
        data={data}
        index="date"
        categories={["Forecasted", "Actual", "Plan"]}
        colors={["red", "emerald", "gray"]}
        // valueFormatter={dataFormatter}
        allowDecimals={false}
        yAxisWidth={40}
      />
    </>
  );
}
