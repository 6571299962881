// forward div refs

import React from "react";
import { cn } from "src/utils/ui/ui";

const Loading = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({className, ...props}, ref) => {
  return (
    <div ref={ref} className={cn(
      "mx-auto h-8 w-8 animate-spin rounded-full border-4 border-gray-200 border-t-primary",
      className
    )}
    {...props} />
  );
});

export { Loading };
