import { jwtDecode } from "jwt-decode";
import { useRealmApp } from "src/store/RealmApp";

export const useAccessToken = () => {
  const app = useRealmApp();
  const getValidAccessToken = async (): Promise<string | null> => {
    const accessToken = app.currentUser?.accessToken;
    const payload = jwtDecode(accessToken);
    if (payload.exp) {
      const isExpired = payload.exp * 1000 < Date.now();
      // show how much time is left before the token expires
      // if (isExpired) {
      //   console.log("The access token is expired");
      // } else {
      //   console.log(
      //     `The access token will expire in ${new Date(
      //       payload.exp * 1000
      //     ).toLocaleString()}`
      //   );
      // }
      if (isExpired) {
        // refresh the custom data which will also refresh the access token
        await app.currentUser?.refreshCustomData();
        return app.currentUser?.accessToken;
      } else {
        return accessToken;
      }
    } else {
      console.log("Failed to decode the access token");
    }
    return null;
  };

  return getValidAccessToken;
};
