import React, { createContext, useContext, useReducer } from "react";

type Action = {}


type State = {
  isExternal: boolean;
};

type Dispatch = (action: Action) => void;

const ResourcesContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function resourcesReducer(state: State, action: Action): State {
  switch (action) {
    default:
      return state;
  }
}

export function ResourcesContextProvider({
  children,
  isExternal = false,
}: {
  children: React.ReactNode;
  isExternal?: boolean;
}) {
  const [state, dispatch] = useReducer(resourcesReducer, {
    isExternal: isExternal,
  });
  const value = { state, dispatch };
  return (
    <ResourcesContext.Provider value={value}>{children}</ResourcesContext.Provider>
  );
}

export function useResourcesContext() {
  const context = useContext(ResourcesContext);
  if (context === undefined) {
    throw new Error("useResourcesContext must be used within a ResourcesContextProvider");
  }
  return context;
}