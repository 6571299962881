import { useLocation, useNavigate } from "react-router-dom";
import Logo from "src/components/Authentication/Logo";
import { NewPasswordForm } from "src/components/Authentication/PasswordReset/NewPassworddForm";
import { useRealmApp } from "../../store/RealmApp";

type Props = {};

export const NewPassword = (props: Props) => {
  const navigate = useNavigate();
  const app = useRealmApp();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get("token");
  const tokenId = searchParams.get("tokenId");

  return (
    <div className="min-h-screen p-40">
      {/* <div className="w-30 absolute top-0 left-0 h-40 rounded-full bg-primary-400"></div> */}
      <div className="container mx-auto max-w-lg">
        {token && tokenId ? (
          <NewPasswordForm token={token} tokenId={tokenId} />
        ) : (
          <div className="flex flex-col items-center space-y-10 rounded-lg border bg-white py-16 shadow-md">
            <Logo height={80} />
            <div className="space-y-6 flex-col flex items-center max-w-xs text-center">
              <h2 className="text-2xl font-medium">
                Issue with password reset
              </h2>
              <div>
                <span>Please reach out to </span>{" "}
                <a href="mailto:founders@sero.so" className="font-semibold text-primary-600 hover:text-primary-main">
                  founders@sero.so
                </a>
                <span> or go back to </span>
                <button
                  className="font-semibold text-primary-600 hover:text-primary-main"
                  onClick={() => navigate("/login")}
                >
                  login
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
    // <Container maxWidth="sm">
    //   <Grid container alignItems="center" justifyContent={"center"}>
    //     <Grid item xs={12} marginTop={20}>
    //       <Stack spacing={3} alignItems="center">

    //       </Stack>
    //     </Grid>
    //   </Grid>
    // </Container>
  );
};
