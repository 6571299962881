import { ThreadData } from "src/api/Services/Threads/threads";
import { Loading } from "src/components/ui/ApplicationStates/Loading";
import { Threads } from "src/pages/Services/Threads/Threads";

type Props = {
  threads: ThreadData[];
  isInternal?: boolean;
  isSharedSpace?: boolean;
  isEmbed?: boolean;
};

export default function PhaseThreads({ threads,
  isEmbed = false,
  isInternal = true,
  isSharedSpace = true,
}: Props) {
  return (
    <>
      {!!threads ? (
        <Threads
          threads={threads}
          isInternal={isInternal}
          isSharedSpace={isSharedSpace}
          isEmbed={isEmbed}
        />
      ) : (
        <Loading />
      )}
    </>
  );
}
