import {
  Active,
  DndContext,
  DragEndEvent,
  DragOverlay,
  useDndMonitor,
  useDraggable,
  useDroppable,
} from "@dnd-kit/core";
import {
  Bars2Icon,
  Bars4Icon,
  CalendarDaysIcon,
  CalendarIcon,
  CodeBracketIcon,
  EnvelopeIcon,
  ListBulletIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronRightIcon,
  ComponentBooleanIcon,
  DividerHorizontalIcon,
  DragHandleDots2Icon,
  FontBoldIcon,
  FontItalicIcon,
  LetterCaseCapitalizeIcon,
  Link1Icon,
  QuoteIcon,
  StrikethroughIcon,
  TableIcon,
} from "@radix-ui/react-icons";
import Link from "@tiptap/extension-link";
import Typography from "@tiptap/extension-typography";
import { Editor, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import clsx from "clsx";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  ElementType,
  FormTemplateBooleanBlockData,
  FormTemplateData,
  FormTemplateDateBlockData,
  FormTemplateElementBlockData,
  FormTemplateEmailBlockData,
  FormTemplateHeadingBlockData,
  FormTemplateLongTextBlockData,
  FormTemplateRichTextBlockData,
  FormTemplateSectionBlockData,
  FormTemplateShortTextBlockData,
  FormTemplateTableBlockData,
  useDeleteFormTemplate,
  useDeleteFormTemplateConfirmation,
  useGetFormTemplate,
  useUpdateFormTemplate,
} from "src/api/Forms/forms";
import { useGetSpaces } from "src/api/Spaces/spaces";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { Modal } from "src/components/ui/Modals/Modal";
import ModalHeading from "src/components/ui/Modals/ModalHeading";
import Select from "src/components/ui/Selects/Select";
import { VariantToggle } from "src/components/ui/Toggle/VariantToggle";
import { Button, Button as NewButton } from "src/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import { Switch } from "src/components/ui/switch";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/components/ui/tabs";
import { TextInput } from "src/components/ui/text-input";
import { classNames } from "src/utils/functions/classes";
import { SpaceNameAndSymbol } from "../spaces/spaces";
import {
  FormTemplateContextProvider,
  useFormTemplateContext,
} from "./form-template-context";
import "./styles.scss";
var ObjectID = require("bson-objectid");

type Props = {};

export default function FormTemplate({}: Props) {
  const { formTemplateId } = useParams<{ formTemplateId: string }>();
  const { data: formTemplate } = useGetFormTemplate(String(formTemplateId));
  const { data: spaces } = useGetSpaces();
  const space = spaces?.find((s) => s._id === formTemplate?.spaceId);
  return (
    <div className="flex h-full flex-col overflow-auto">
      <div className="flex h-12 shrink-0 items-center border-b border-gray-200/70 px-8">
        <div className="flex items-center space-x-2 text-sm">
          {space && (
            <SpaceNameAndSymbol name={space.name} color={space.color} />
          )}
          <ChevronRightIcon className="h-3 w-3 text-gray-500" />
          <RouterLink to={`/spaces/${formTemplate?.spaceId}/templates`}>
            <div className="text-gray-800">{"Templates"}</div>
          </RouterLink>
          <ChevronRightIcon className="h-3 w-3 text-gray-500" />
          <RouterLink to={`/spaces/${formTemplate?.spaceId}/templates/forms`}>
            <div className="text-gray-800">{"Forms"}</div>
          </RouterLink>
          <ChevronRightIcon className="h-3 w-3 text-gray-500" />
          <div className="cursor-default text-gray-800">
            {formTemplate?.title}
          </div>
        </div>
      </div>
      <Tabs
        defaultValue="builder"
        className="flex flex-1 flex-col overflow-y-auto pt-3"
      >
        <TabsList className="px-8">
          <TabsTrigger value="builder">
            <span>Builder</span>
          </TabsTrigger>
          <TabsTrigger value="settings">
            <span>Settings</span>
          </TabsTrigger>
        </TabsList>
        <div className="flex-1 overflow-auto">
          <TabsContent value="builder" className="h-full overflow-auto">
            <DndContext>
              {!!formTemplate && (
                <FormTemplateContextProvider>
                  <FormBuilder formTemplateId={String(formTemplateId)} />
                </FormTemplateContextProvider>
              )}
            </DndContext>
          </TabsContent>
          <TabsContent value="settings" className="h-full overflow-auto">
            <FormSettings formTemplateId={String(formTemplateId)} />
          </TabsContent>
        </div>
      </Tabs>
    </div>
  );
}

function EditablePageHeading({
  formTemplate,
}: {
  formTemplate: FormTemplateData | undefined;
}) {
  const [heading, setHeading] = useState(formTemplate?.title ?? "");
  const updateFormTemplateMutation = useUpdateFormTemplate();

  useEffect(() => {
    setHeading(formTemplate?.title ?? "");
  }, [formTemplate]);

  return (
    <>
      {!!formTemplate ? (
        <div className="mt-2 flex-1">
          <input
            className="w-full max-w-xl border-b-2 border-transparent text-2xl font-medium leading-7
            tracking-tight text-gray-900 outline-none hover:border-primary-main focus:border-primary-main focus:outline-none focus:ring-0"
            value={heading}
            onChange={(e) => {
              setHeading(e.target.value);
            }}
            onBlur={() => {
              if (
                !!formTemplate &&
                heading.trim() !== formTemplate.title &&
                formTemplate._id
              ) {
                updateFormTemplateMutation.mutate({
                  _id: formTemplate._id,
                  title: heading,
                });
              }
            }}
          />
        </div>
      ) : (
        <div className="mt-2 h-8 w-40 rounded bg-gray-200" />
      )}
    </>
  );
}

function FormSettings({ formTemplateId }: { formTemplateId: string }) {
  const { data: formTemplate } = useGetFormTemplate(formTemplateId);
  const [
    isDeleteFormTemplateConfirmationOpen,
    setIsDeleteFormTemplateConfirmationOpen,
  ] = useState(false);
  const deleteFormTemplateConfirmationMutation =
    useDeleteFormTemplateConfirmation();
  const deleteFormTemplateMutation = useDeleteFormTemplate();
  const [dependencies, setDependencies] = useState<
    { _id: string; title: string }[]
  >([]);

  useEffect(() => {
    if (deleteFormTemplateConfirmationMutation.data) {
      setDependencies(deleteFormTemplateConfirmationMutation.data.dependencies);
    }
  }, [deleteFormTemplateConfirmationMutation.data]);

  useEffect(() => {
    if (deleteFormTemplateMutation.isSuccess) {
      setIsDeleteFormTemplateConfirmationOpen(false);
    }
  }, [deleteFormTemplateMutation.isSuccess]);

  return (
    <div className="w-full divide-y divide-gray-300 px-8 pt-6">
      <div className="space-y-6 pb-6">
        <label className="text-md w-40 shrink-0 font-medium text-gray-600">
          Actions
        </label>

        <div className="flex">
          <label className="w-40 shrink-0 text-sm text-gray-400">Delete</label>
          <Dialog
            open={isDeleteFormTemplateConfirmationOpen}
            onOpenChange={(open) => {
              setIsDeleteFormTemplateConfirmationOpen(open);
            }}
          >
            <DialogTrigger asChild>
              <Button
                variant={"secondary"}
                onClick={() => {
                  setIsDeleteFormTemplateConfirmationOpen(true);
                  deleteFormTemplateConfirmationMutation.mutate({
                    _id: formTemplateId,
                    force: false,
                  });
                }}
              >
                Delete
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Delete Confirmation</DialogTitle>
                {/* <DialogDescription>
              Confirm deletion of stage template
            </DialogDescription> */}
              </DialogHeader>
              <div className="space-y-5 pt-3 text-sm text-gray-600">
                <div>
                  Are you sure you want to delete the form template{" "}
                  <span className="font-medium text-gray-900">
                    {formTemplate?.title}
                  </span>
                  ?
                </div>
                {dependencies.length > 0 && (
                  <div className="space-y-1">
                    <div>
                      <span>
                        The following task templates will no longer have forms
                        attached to them:
                      </span>
                    </div>
                    <ul className="list-disc space-y-1 pl-6">
                      {dependencies.map((d) => (
                        <li key={d._id.toString()}>{d.title}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <DialogFooter>
                <DialogClose asChild className="">
                  <div className="flex justify-end space-x-3">
                    <Button
                      variant={"secondary"}
                      onClick={() => {
                        setIsDeleteFormTemplateConfirmationOpen(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant={"destructive"}
                      onClick={() => {
                        if (formTemplate?._id) {
                          deleteFormTemplateMutation.mutate({
                            _id: formTemplate?._id,
                            force: true,
                          });
                        }
                      }}
                    >
                      {deleteFormTemplateMutation.isLoading
                        ? "Deleting..."
                        : "Delete"}
                    </Button>
                  </div>
                </DialogClose>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

function FormBuilder({ formTemplateId }: { formTemplateId: string }) {
  return (
    <div className="flex h-full overflow-auto">
      <ElementsSidebar />
      <Form />
      <PropertiesSidebar formTemplateId={formTemplateId} />
      <DragOverlayWrapper />
    </div>
  );
}

function PropertiesSidebar({ formTemplateId }: { formTemplateId: string }) {
  const { data: formTemplate } = useGetFormTemplate(formTemplateId);
  const formTemplateCtx = useFormTemplateContext();
  if (!formTemplateCtx.selectedElement) {
    return (
      <div className="w-[450px] shrink-0 py-6">
        <div className="pl-6 pr-8">
          <span className="text-lg font-medium text-gray-700">Properties</span>
          <p className="text-sm text-gray-400">
            Select a field to edit its properties
          </p>
        </div>
      </div>
    );
  } else {
    if (!!formTemplate) {
      if (
        formTemplateCtx.selectedElement.sectionIndex <=
          formTemplate.blocks.length &&
        !!formTemplateCtx.selectedElement.id
      ) {
        const section =
          formTemplate.blocks[formTemplateCtx.selectedElement.sectionIndex];
        const element = section.blocks.find(
          (e) => e.id === formTemplateCtx.selectedElement?.id
        );
        if (element) {
          return (
            <div className="w-[450px] shrink-0 py-6">
              <div className="space-y-4 pl-6 pr-8">
                <span className="text-lg font-medium text-gray-700">
                  Properties
                </span>
                {/* <p className="text-sm text-gray-400">{"Edit the "}</p> */}
                <FormElementProperties
                  element={element}
                  formTemplateId={formTemplateId}
                />
              </div>
            </div>
          );
        }
      }
    }
  }

  return (
    <div className="w-80 py-6">
      <div className="pl-6 pr-8">
        <span className="text-lg font-medium text-gray-700">Properties</span>
        <p className="text-sm text-gray-400">
          Issue with element. Select another element.
        </p>
      </div>
    </div>
  );
}

function FormElementProperties({
  element,
  formTemplateId,
}: {
  element: FormTemplateElementBlockData;
  formTemplateId: string;
}) {
  const { data: formTemplate } = useGetFormTemplate(formTemplateId);
  if (formTemplate) {
    switch (element.type) {
      case "short text":
        return (
          <ShortTextProperties element={element} formTemplate={formTemplate} />
        );
      case "long text":
        return (
          <LongTextProperties element={element} formTemplate={formTemplate} />
        );
      case "heading":
        return (
          <HeadingProperties element={element} formTemplate={formTemplate} />
        );
      case "richText":
        return (
          <RichTextProperties element={element} formTemplate={formTemplate} />
        );
      case "date":
        return <DateProperties element={element} formTemplate={formTemplate} />;
      case "email":
        return (
          <EmailProperties element={element} formTemplate={formTemplate} />
        );
      case "boolean":
        return (
          <BooleanProperties element={element} formTemplate={formTemplate} />
        );
      case "table":
        return (
          <TableProperties element={element} formTemplate={formTemplate} />
        );
      default:
        return <div>Element type not supported</div>;
    }
  }
  return null;
}

function DeleteElementButton({
  formTemplateId,
  sectionIndex,
  elementId,
}: {
  formTemplateId: string;
  sectionIndex: number;
  elementId: string;
}) {
  const { data: formTemplate } = useGetFormTemplate(formTemplateId);
  const updateFormTemplateMutation = useUpdateFormTemplate();
  const formTemplateCtx = useFormTemplateContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <button
        className="group rounded border p-1.5 text-gray-400 hover:border-gray-400 hover:bg-gray-50 hover:text-gray-500"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <TrashIcon className="h-4 w-4" />
      </button>
      <Modal
        isOpen={isModalOpen}
        closeModal={() => {
          setIsModalOpen(false);
        }}
      >
        <div className="w-[400px] p-5">
          <ModalHeading title="Confirm Form Field Deletion" />
          <div className="mt-4 space-y-4">
            <div className="text-sm">
              <span className="text-gray-600">{`Are you sure you want to delete this form field?`}</span>
              {/* <span className="font-medium">{`"${task?.title}"`}</span>
              <span className="font-light text-gray-600">{`?`}</span>
              {otherTasksToDelete.length > 1 && (
                <span className="font-light text-gray-600">{` The following tasks will also be deleted -`}</span>
              )} */}
            </div>
            <div className="flex justify-end space-x-2 pt-2">
              <Button
                onClick={() => {
                  setIsModalOpen(false);
                }}
                variant={"secondary"}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  updateFormTemplateMutation.mutate({
                    _id: formTemplateId,
                    blocks: formTemplate?.blocks.map((section, index) => {
                      if (index === sectionIndex) {
                        return {
                          ...section,
                          blocks: section.blocks.filter(
                            (block) => block.id !== elementId
                          ),
                        };
                      }
                      return section;
                    }),
                  });
                  formTemplateCtx.setSelectedElement(undefined);
                  setIsModalOpen(false);
                }}
                variant={"destructive"}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

function RichTextProperties({
  element,
  formTemplate,
}: {
  element: FormTemplateRichTextBlockData;
  formTemplate: FormTemplateData;
}) {
  const updateFormTemplateMutation = useUpdateFormTemplate();
  const formTemplateCtx = useFormTemplateContext();
  // const [text, setText] = useState(element.properties.text);
  const [url, setUrl] = useState("");
  const [newUrl, setNewUrl] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");

  const editor = useEditor({
    extensions: [
      StarterKit,
      Typography,
      Link.configure({
        openOnClick: false,
        validate: (href) => /^https?:\/\//.test(href),
      }),
    ],
    editorProps: {
      attributes: {
        class:
          "prose outline-none ring-0 focus:border-primary-main focus:ring-0",
      },
    },
    onBlur: ({ editor }) => {
      updateFormTemplateMutation.mutate({
        _id: formTemplate._id,
        blocks: formTemplate.blocks.map((section, sectionIndex) => {
          if (sectionIndex === formTemplateCtx.selectedElement?.sectionIndex) {
            return {
              ...section,
              blocks: section.blocks.map((block) => {
                if (
                  block.id === formTemplateCtx.selectedElement?.id &&
                  block.type === "richText"
                ) {
                  return {
                    ...block,
                    properties: {
                      ...block.properties,
                      text: editor.getHTML(),
                    },
                  };
                }
                return block;
              }),
            };
          }
          return section;
        }),
      });
    },
    content: element.properties.text,
  });

  useEffect(() => {
    if (editor) {
      editor.commands.setContent(element.properties.text);
    }
  }, [element]);

  useEffect(() => {
    if (!isOpen) {
      setUrl("");
      setNewUrl("");
    } else {
    }
  }, [isOpen]);

  return (
    <div className="space-y-4 divide-y">
      <div className="rounded-md border-2 border-gray-200 p-3">
        {editor && (
          <MenuBar
            editor={editor}
            setModalOpen={setIsOpen}
            setUrl={setUrl}
            setNewUrl={setNewUrl}
          />
        )}
        <EditorContent editor={editor} />
        <Dialog open={isOpen} onOpenChange={(value) => setIsOpen(value)}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>URL</DialogTitle>
              <DialogDescription>
                Edit the URL for the selected text
              </DialogDescription>
            </DialogHeader>
            <div>
              <div className="space-y-1">
                <InputHeading heading="URL" />
                <TextInput
                  value={newUrl}
                  onChange={(e) => {
                    setNewUrl(e.target.value);
                    setError("");
                  }}
                />
                <div className="text-xs text-red-500">{error}</div>
              </div>
            </div>
            <DialogFooter>
              <div className="flex space-x-2">
                {!!url && (
                  <Button
                    variant="secondary"
                    onClick={() => {
                      editor?.commands.unsetLink();
                      setIsOpen(false);
                    }}
                  >
                    Remove Link
                  </Button>
                )}
                <Button
                  disabled={!newUrl}
                  onClick={() => {
                    if (!isValidHttpsUrl(newUrl)) {
                      setError(
                        "Please enter a valid URL that starts with 'https://'"
                      );
                      return;
                    }
                    if (editor) {
                      console.log(newUrl);
                      editor?.commands.setLink({
                        href: newUrl,
                        target: "_blank",
                      });
                      setIsOpen(false);
                    }
                  }}

                  // disabled={url}
                >
                  Save
                </Button>
              </div>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
      <div className="pt-4">
        {formTemplateCtx.selectedElement?.sectionIndex !== undefined && (
          <DeleteElementButton
            elementId={element.id}
            formTemplateId={formTemplate._id}
            sectionIndex={formTemplateCtx.selectedElement?.sectionIndex}
          />
        )}
      </div>
    </div>
  );
}

function isValidHttpsUrl(url: string): boolean {
  const regex = /^https:\/\/.+/;
  return regex.test(url);
}

const options: FormTemplateHeadingBlockData["properties"]["size"][] = [
  "small",
  "medium",
  "large",
];

function HeadingProperties({
  element,
  formTemplate,
}: {
  element: FormTemplateHeadingBlockData;
  formTemplate: FormTemplateData;
}) {
  const updateFormTemplateMutation = useUpdateFormTemplate();
  const formTemplateCtx = useFormTemplateContext();
  const [text, setText] = useState(element.properties.text);

  useEffect(() => {
    setText(element.properties.text);
  }, [element.properties.text]);

  const [description, setDescription] = useState(
    element.properties.description
  );

  useEffect(() => {
    setDescription(element.properties.description);
  }, [element.properties.description]);

  let elementIndex = -1;
  if (
    formTemplateCtx.selectedElement?.sectionIndex !== undefined &&
    formTemplateCtx.selectedElement?.sectionIndex <= formTemplate.blocks.length
  ) {
    elementIndex = formTemplate.blocks[
      formTemplateCtx.selectedElement.sectionIndex
    ].blocks.findIndex((block) => block.id === element.id);
  }

  return (
    <>
      <div className="space-y-4 divide-y">
        <div className="space-y-4">
          <div className="space-y-1">
            <div className="text-sm font-medium text-gray-700">Title</div>
            <TextInput
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
              onBlur={() => {
                updateFormTemplateMutation.mutate({
                  _id: formTemplate._id,
                  blocks: formTemplate.blocks.map((section, sectionIndex) => {
                    if (
                      sectionIndex ===
                      formTemplateCtx.selectedElement?.sectionIndex
                    ) {
                      return {
                        ...section,
                        blocks: section.blocks.map((block) => {
                          if (
                            block.id === formTemplateCtx.selectedElement?.id &&
                            block.type === "heading"
                          ) {
                            return {
                              ...block,
                              properties: {
                                ...block.properties,
                                text: text,
                              },
                            };
                          }
                          return block;
                        }),
                      };
                    }
                    return section;
                  }),
                });
              }}
            />
          </div>
          <div className="space-y-1">
            <div className="text-sm font-medium text-gray-700">Description</div>
            <TextInput
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              onBlur={() => {
                updateFormTemplateMutation.mutate({
                  _id: formTemplate._id,
                  blocks: formTemplate.blocks.map((section, sectionIndex) => {
                    if (
                      sectionIndex ===
                      formTemplateCtx.selectedElement?.sectionIndex
                    ) {
                      return {
                        ...section,
                        blocks: section.blocks.map((block) => {
                          if (
                            block.id === formTemplateCtx.selectedElement?.id &&
                            block.type === "heading"
                          ) {
                            return {
                              ...block,
                              properties: {
                                ...block.properties,
                                description: description,
                              },
                            };
                          }
                          return block;
                        }),
                      };
                    }
                    return section;
                  }),
                });
              }}
            />
          </div>
          {elementIndex > 0 && (
            <div className="space-y-1">
              <InputHeading heading="Size" />
              <Select
                options={options}
                displayFn={(value) => {
                  return value[0].toUpperCase() + value.slice(1);
                }}
                keyFn={(value) => {
                  return value;
                }}
                onChange={(value) => {
                  updateFormTemplateMutation.mutate({
                    _id: formTemplate._id,
                    blocks: formTemplate.blocks.map((section, sectionIndex) => {
                      if (
                        sectionIndex ===
                        formTemplateCtx.selectedElement?.sectionIndex
                      ) {
                        return {
                          ...section,
                          blocks: section.blocks.map((block) => {
                            if (
                              block.id ===
                                formTemplateCtx.selectedElement?.id &&
                              block.type === "heading"
                            ) {
                              return {
                                ...block,
                                properties: {
                                  ...block.properties,
                                  size: value,
                                },
                              };
                            }
                            return block;
                          }),
                        };
                      }
                      return section;
                    }),
                  });
                }}
                selected={element.properties.size}
              />
            </div>
          )}
        </div>
        {formTemplateCtx.selectedElement?.sectionIndex !== undefined &&
          elementIndex > 0 && (
            <div className="pt-4">
              <DeleteElementButton
                elementId={element.id}
                formTemplateId={formTemplate._id}
                sectionIndex={formTemplateCtx.selectedElement?.sectionIndex}
              />
            </div>
          )}
      </div>
    </>
  );
}

function ShortTextProperties({
  element,
  formTemplate,
}: {
  element: FormTemplateShortTextBlockData;
  formTemplate: FormTemplateData;
}) {
  const updateFormTemplateMutation = useUpdateFormTemplate();
  const formTemplateCtx = useFormTemplateContext();
  const [label, setLabel] = useState(element.properties.label);

  useEffect(() => {
    setLabel(element.properties.label);
  }, [element.properties.label]);

  const [placeholder, setPlaceholder] = useState(
    element.properties.placeholder
  );

  useEffect(() => {
    setPlaceholder(element.properties.placeholder);
  }, [element.properties.placeholder]);

  const [descriptionRequired, setDescriptionRequired] = useState(
    element.properties.description.trim() !== ""
  );
  useEffect(() => {
    setDescriptionRequired(element.properties.description.trim() !== "");
  }, [element]);

  const [description, setDescription] = useState(
    element.properties.description
  );

  useEffect(() => {
    setDescription(element.properties.description);
  }, [element.properties.description]);

  return (
    <>
      <div className="space-y-6 divide-y">
        <div className="space-y-4">
          <div className="space-y-1">
            <div className="text-sm font-medium text-gray-700">Title</div>
            <TextInput
              value={label}
              onChange={(e) => {
                setLabel(e.target.value);
              }}
              onBlur={() => {
                updateFormTemplateMutation.mutate({
                  _id: formTemplate._id,
                  blocks: formTemplate.blocks.map((section, sectionIndex) => {
                    if (
                      sectionIndex ===
                      formTemplateCtx.selectedElement?.sectionIndex
                    ) {
                      return {
                        ...section,
                        blocks: section.blocks.map((block) => {
                          if (
                            block.id === formTemplateCtx.selectedElement?.id &&
                            block.type === "short text"
                          ) {
                            return {
                              ...block,
                              properties: {
                                ...block.properties,
                                label: label,
                              },
                            };
                          }
                          return block;
                        }),
                      };
                    }
                    return section;
                  }),
                });
              }}
            />
          </div>
          <div className="space-y-1">
            <div className="text-sm font-medium text-gray-700">Placeholder</div>
            <TextInput
              value={placeholder}
              onChange={(e) => {
                setPlaceholder(e.target.value);
              }}
              onBlur={() => {
                updateFormTemplateMutation.mutate({
                  _id: formTemplate._id,
                  blocks: formTemplate.blocks.map((section, sectionIndex) => {
                    if (
                      sectionIndex ===
                      formTemplateCtx.selectedElement?.sectionIndex
                    ) {
                      return {
                        ...section,
                        blocks: section.blocks.map((block) => {
                          if (
                            block.id === formTemplateCtx.selectedElement?.id &&
                            block.type === "short text"
                          ) {
                            return {
                              ...block,
                              properties: {
                                ...block.properties,
                                placeholder: placeholder,
                              },
                            };
                          }
                          return block;
                        }),
                      };
                    }
                    return section;
                  }),
                });
              }}
            />
          </div>
        </div>
        <div className="space-y-4 pt-5">
          <div className="flex justify-between">
            <div className="text-sm font-medium text-gray-700">Required</div>
            <VariantToggle
              size="sm"
              enabled={element.properties.required}
              setEnabled={(required) => {
                updateFormTemplateMutation.mutate({
                  _id: formTemplate._id,
                  blocks: formTemplate.blocks.map((section, sectionIndex) => {
                    if (
                      sectionIndex ===
                      formTemplateCtx.selectedElement?.sectionIndex
                    ) {
                      return {
                        ...section,
                        blocks: section.blocks.map((block) => {
                          if (
                            block.id === formTemplateCtx.selectedElement?.id &&
                            block.type === "short text"
                          ) {
                            return {
                              ...block,
                              properties: {
                                ...block.properties,
                                required: required,
                              },
                            };
                          }
                          return block;
                        }),
                      };
                    }
                    return section;
                  }),
                });
              }}
            />
          </div>
          <div className="space-y-2">
            <div className="flex justify-between">
              <div className="text-sm font-medium text-gray-700">
                Description
              </div>
              <VariantToggle
                size="sm"
                enabled={descriptionRequired}
                setEnabled={(required) => {
                  setDescriptionRequired(required);
                  if (!required) {
                    updateFormTemplateMutation.mutate({
                      _id: formTemplate._id,
                      blocks: formTemplate.blocks.map(
                        (section, sectionIndex) => {
                          if (
                            sectionIndex ===
                            formTemplateCtx.selectedElement?.sectionIndex
                          ) {
                            return {
                              ...section,
                              blocks: section.blocks.map((block) => {
                                if (
                                  block.id ===
                                    formTemplateCtx.selectedElement?.id &&
                                  block.type === "short text"
                                ) {
                                  return {
                                    ...block,
                                    properties: {
                                      ...block.properties,
                                      description: "",
                                    },
                                  };
                                }
                                return block;
                              }),
                            };
                          }
                          return section;
                        }
                      ),
                    });
                  }
                }}
              />
            </div>
            {descriptionRequired && (
              <div className="space-y-1">
                <TextInput
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  onBlur={() => {
                    updateFormTemplateMutation.mutate({
                      _id: formTemplate._id,
                      blocks: formTemplate.blocks.map(
                        (section, sectionIndex) => {
                          if (
                            sectionIndex ===
                            formTemplateCtx.selectedElement?.sectionIndex
                          ) {
                            return {
                              ...section,
                              blocks: section.blocks.map((block) => {
                                if (
                                  block.id ===
                                    formTemplateCtx.selectedElement?.id &&
                                  block.type === "short text"
                                ) {
                                  return {
                                    ...block,
                                    properties: {
                                      ...block.properties,
                                      description: description,
                                    },
                                  };
                                }
                                return block;
                              }),
                            };
                          }
                          return section;
                        }
                      ),
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="pt-4">
          {formTemplateCtx.selectedElement?.sectionIndex !== undefined && (
            <DeleteElementButton
              elementId={element.id}
              formTemplateId={formTemplate._id}
              sectionIndex={formTemplateCtx.selectedElement?.sectionIndex}
            />
          )}
        </div>
      </div>
    </>
  );
}

function LongTextProperties({
  element,
  formTemplate,
}: {
  element: FormTemplateLongTextBlockData;
  formTemplate: FormTemplateData;
}) {
  const updateFormTemplateMutation = useUpdateFormTemplate();
  const formTemplateCtx = useFormTemplateContext();
  const [label, setLabel] = useState(element.properties.label);

  useEffect(() => {
    setLabel(element.properties.label);
  }, [element.properties.label]);

  const [placeholder, setPlaceholder] = useState(
    element.properties.placeholder
  );

  useEffect(() => {
    setPlaceholder(element.properties.placeholder);
  }, [element.properties.placeholder]);

  const [descriptionRequired, setDescriptionRequired] = useState(
    element.properties.description.trim() !== ""
  );

  useEffect(() => {
    setDescriptionRequired(element.properties.description.trim() !== "");
  }, [element]);

  const [description, setDescription] = useState(
    element.properties.description
  );

  useEffect(() => {
    setDescription(element.properties.description);
  }, [element.properties.description]);

  return (
    <>
      <div className="space-y-6 divide-y">
        <div className="space-y-4">
          <div className="space-y-1">
            <div className="text-sm font-medium text-gray-700">Title</div>
            <TextInput
              value={label}
              onChange={(e) => {
                setLabel(e.target.value);
              }}
              onBlur={() => {
                updateFormTemplateMutation.mutate({
                  _id: formTemplate._id,
                  blocks: formTemplate.blocks.map((section, sectionIndex) => {
                    if (
                      sectionIndex ===
                      formTemplateCtx.selectedElement?.sectionIndex
                    ) {
                      return {
                        ...section,
                        blocks: section.blocks.map((block) => {
                          if (
                            block.id === formTemplateCtx.selectedElement?.id &&
                            block.type === "long text"
                          ) {
                            return {
                              ...block,
                              properties: {
                                ...block.properties,
                                label: label,
                              },
                            };
                          }
                          return block;
                        }),
                      };
                    }
                    return section;
                  }),
                });
              }}
            />
          </div>
          <div className="space-y-1">
            <div className="text-sm font-medium text-gray-700">Placeholder</div>
            <TextInput
              value={placeholder}
              onChange={(e) => {
                setPlaceholder(e.target.value);
              }}
              onBlur={() => {
                updateFormTemplateMutation.mutate({
                  _id: formTemplate._id,
                  blocks: formTemplate.blocks.map((section, sectionIndex) => {
                    if (
                      sectionIndex ===
                      formTemplateCtx.selectedElement?.sectionIndex
                    ) {
                      return {
                        ...section,
                        blocks: section.blocks.map((block) => {
                          if (
                            block.id === formTemplateCtx.selectedElement?.id &&
                            block.type === "long text"
                          ) {
                            return {
                              ...block,
                              properties: {
                                ...block.properties,
                                placeholder: placeholder,
                              },
                            };
                          }
                          return block;
                        }),
                      };
                    }
                    return section;
                  }),
                });
              }}
            />
          </div>
        </div>
        <div className="space-y-4 pt-5">
          <div className="flex justify-between">
            <div className="text-sm font-medium text-gray-700">Required</div>
            <VariantToggle
              size="sm"
              enabled={element.properties.required}
              setEnabled={(required) => {
                updateFormTemplateMutation.mutate({
                  _id: formTemplate._id,
                  blocks: formTemplate.blocks.map((section, sectionIndex) => {
                    if (
                      sectionIndex ===
                      formTemplateCtx.selectedElement?.sectionIndex
                    ) {
                      return {
                        ...section,
                        blocks: section.blocks.map((block) => {
                          if (
                            block.id === formTemplateCtx.selectedElement?.id &&
                            block.type === "long text"
                          ) {
                            return {
                              ...block,
                              properties: {
                                ...block.properties,
                                required: required,
                              },
                            };
                          }
                          return block;
                        }),
                      };
                    }
                    return section;
                  }),
                });
              }}
            />
          </div>
          <div className="space-y-2">
            <div className="flex justify-between">
              <div className="text-sm font-medium text-gray-700">
                Description
              </div>
              <VariantToggle
                size="sm"
                enabled={descriptionRequired}
                setEnabled={(required) => {
                  setDescriptionRequired(required);
                  if (!required) {
                    updateFormTemplateMutation.mutate({
                      _id: formTemplate._id,
                      blocks: formTemplate.blocks.map(
                        (section, sectionIndex) => {
                          if (
                            sectionIndex ===
                            formTemplateCtx.selectedElement?.sectionIndex
                          ) {
                            return {
                              ...section,
                              blocks: section.blocks.map((block) => {
                                if (
                                  block.id ===
                                    formTemplateCtx.selectedElement?.id &&
                                  block.type === "long text"
                                ) {
                                  return {
                                    ...block,
                                    properties: {
                                      ...block.properties,
                                      description: "",
                                    },
                                  };
                                }
                                return block;
                              }),
                            };
                          }
                          return section;
                        }
                      ),
                    });
                  }
                }}
              />
            </div>
            {descriptionRequired && (
              <div className="space-y-1">
                <TextInput
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  onBlur={() => {
                    updateFormTemplateMutation.mutate({
                      _id: formTemplate._id,
                      blocks: formTemplate.blocks.map(
                        (section, sectionIndex) => {
                          if (
                            sectionIndex ===
                            formTemplateCtx.selectedElement?.sectionIndex
                          ) {
                            return {
                              ...section,
                              blocks: section.blocks.map((block) => {
                                if (
                                  block.id ===
                                    formTemplateCtx.selectedElement?.id &&
                                  block.type === "long text"
                                ) {
                                  return {
                                    ...block,
                                    properties: {
                                      ...block.properties,
                                      description: description,
                                    },
                                  };
                                }
                                return block;
                              }),
                            };
                          }
                          return section;
                        }
                      ),
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="pt-4">
          {formTemplateCtx.selectedElement?.sectionIndex !== undefined && (
            <DeleteElementButton
              elementId={element.id}
              formTemplateId={formTemplate._id}
              sectionIndex={formTemplateCtx.selectedElement?.sectionIndex}
            />
          )}
        </div>
      </div>
    </>
  );
}

function DateProperties({
  element,
  formTemplate,
}: {
  element: FormTemplateDateBlockData;
  formTemplate: FormTemplateData;
}) {
  const updateFormTemplateMutation = useUpdateFormTemplate();
  const formTemplateCtx = useFormTemplateContext();
  const [label, setLabel] = useState(element.properties.label);

  useEffect(() => {
    setLabel(element.properties.label);
  }, [element.properties.label]);

  const [descriptionRequired, setDescriptionRequired] = useState(
    element.properties.description.trim() !== ""
  );

  useEffect(() => {
    setDescriptionRequired(element.properties.description.trim() !== "");
  }, [element]);

  const [description, setDescription] = useState(
    element.properties.description
  );

  useEffect(() => {
    setDescription(element.properties.description);
  }, [element.properties.description]);

  return (
    <>
      <div className="space-y-6 divide-y">
        <div className="space-y-4">
          <div className="space-y-1">
            <div className="text-sm font-medium text-gray-700">Title</div>
            <TextInput
              value={label}
              onChange={(e) => {
                setLabel(e.target.value);
              }}
              onBlur={() => {
                updateFormTemplateMutation.mutate({
                  _id: formTemplate._id,
                  blocks: formTemplate.blocks.map((section, sectionIndex) => {
                    if (
                      sectionIndex ===
                      formTemplateCtx.selectedElement?.sectionIndex
                    ) {
                      return {
                        ...section,
                        blocks: section.blocks.map((block) => {
                          if (
                            block.id === formTemplateCtx.selectedElement?.id &&
                            block.type === "date"
                          ) {
                            return {
                              ...block,
                              properties: {
                                ...block.properties,
                                label: label,
                              },
                            };
                          }
                          return block;
                        }),
                      };
                    }
                    return section;
                  }),
                });
              }}
            />
          </div>
        </div>
        <div className="space-y-4 pt-5">
          <div className="flex justify-between">
            <div className="text-sm font-medium text-gray-700">Required</div>
            <VariantToggle
              size="sm"
              enabled={element.properties.required}
              setEnabled={(required) => {
                updateFormTemplateMutation.mutate({
                  _id: formTemplate._id,
                  blocks: formTemplate.blocks.map((section, sectionIndex) => {
                    if (
                      sectionIndex ===
                      formTemplateCtx.selectedElement?.sectionIndex
                    ) {
                      return {
                        ...section,
                        blocks: section.blocks.map((block) => {
                          if (
                            block.id === formTemplateCtx.selectedElement?.id &&
                            block.type === "date"
                          ) {
                            return {
                              ...block,
                              properties: {
                                ...block.properties,
                                required: required,
                              },
                            };
                          }
                          return block;
                        }),
                      };
                    }
                    return section;
                  }),
                });
              }}
            />
          </div>
          <div className="space-y-2">
            <div className="flex justify-between">
              <div className="text-sm font-medium text-gray-700">
                Description
              </div>
              <VariantToggle
                size="sm"
                enabled={descriptionRequired}
                setEnabled={(required) => {
                  setDescriptionRequired(required);
                  if (!required) {
                    updateFormTemplateMutation.mutate({
                      _id: formTemplate._id,
                      blocks: formTemplate.blocks.map(
                        (section, sectionIndex) => {
                          if (
                            sectionIndex ===
                            formTemplateCtx.selectedElement?.sectionIndex
                          ) {
                            return {
                              ...section,
                              blocks: section.blocks.map((block) => {
                                if (
                                  block.id ===
                                    formTemplateCtx.selectedElement?.id &&
                                  block.type === "date"
                                ) {
                                  return {
                                    ...block,
                                    properties: {
                                      ...block.properties,
                                      description: "",
                                    },
                                  };
                                }
                                return block;
                              }),
                            };
                          }
                          return section;
                        }
                      ),
                    });
                  }
                }}
              />
            </div>
            {descriptionRequired && (
              <div className="space-y-1">
                <TextInput
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  onBlur={() => {
                    updateFormTemplateMutation.mutate({
                      _id: formTemplate._id,
                      blocks: formTemplate.blocks.map(
                        (section, sectionIndex) => {
                          if (
                            sectionIndex ===
                            formTemplateCtx.selectedElement?.sectionIndex
                          ) {
                            return {
                              ...section,
                              blocks: section.blocks.map((block) => {
                                if (
                                  block.id ===
                                    formTemplateCtx.selectedElement?.id &&
                                  block.type === "date"
                                ) {
                                  return {
                                    ...block,
                                    properties: {
                                      ...block.properties,
                                      description: description,
                                    },
                                  };
                                }
                                return block;
                              }),
                            };
                          }
                          return section;
                        }
                      ),
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="pt-4">
          {formTemplateCtx.selectedElement?.sectionIndex !== undefined && (
            <DeleteElementButton
              elementId={element.id}
              formTemplateId={formTemplate._id}
              sectionIndex={formTemplateCtx.selectedElement?.sectionIndex}
            />
          )}
        </div>
      </div>
    </>
  );
}

function EmailProperties({
  element,
  formTemplate,
}: {
  element: FormTemplateEmailBlockData;
  formTemplate: FormTemplateData;
}) {
  const updateFormTemplateMutation = useUpdateFormTemplate();
  const formTemplateCtx = useFormTemplateContext();
  const [label, setLabel] = useState(element.properties.label);

  useEffect(() => {
    setLabel(element.properties.label);
  }, [element.properties.label]);

  const [placeholder, setPlaceholder] = useState(
    element.properties.placeholder
  );

  useEffect(() => {
    setPlaceholder(element.properties.placeholder);
  }, [element.properties.placeholder]);

  const [descriptionRequired, setDescriptionRequired] = useState(
    element.properties.description.trim() !== ""
  );

  useEffect(() => {
    setDescriptionRequired(element.properties.description.trim() !== "");
  }, [element]);

  const [description, setDescription] = useState(
    element.properties.description
  );

  useEffect(() => {
    setDescription(element.properties.description);
  }, [element.properties.description]);

  return (
    <>
      <div className="space-y-6 divide-y">
        <div className="space-y-4">
          <div className="space-y-1">
            <div className="text-sm font-medium text-gray-700">Title</div>
            <TextInput
              value={label}
              onChange={(e) => {
                setLabel(e.target.value);
              }}
              onBlur={() => {
                updateFormTemplateMutation.mutate({
                  _id: formTemplate._id,
                  blocks: formTemplate.blocks.map((section, sectionIndex) => {
                    if (
                      sectionIndex ===
                      formTemplateCtx.selectedElement?.sectionIndex
                    ) {
                      return {
                        ...section,
                        blocks: section.blocks.map((block) => {
                          if (
                            block.id === formTemplateCtx.selectedElement?.id &&
                            block.type === "email"
                          ) {
                            return {
                              ...block,
                              properties: {
                                ...block.properties,
                                label: label,
                              },
                            };
                          }
                          return block;
                        }),
                      };
                    }
                    return section;
                  }),
                });
              }}
            />
          </div>
          <div className="space-y-1">
            <div className="text-sm font-medium text-gray-700">Placeholder</div>
            <TextInput
              value={placeholder}
              onChange={(e) => {
                setPlaceholder(e.target.value);
              }}
              onBlur={() => {
                updateFormTemplateMutation.mutate({
                  _id: formTemplate._id,
                  blocks: formTemplate.blocks.map((section, sectionIndex) => {
                    if (
                      sectionIndex ===
                      formTemplateCtx.selectedElement?.sectionIndex
                    ) {
                      return {
                        ...section,
                        blocks: section.blocks.map((block) => {
                          if (
                            block.id === formTemplateCtx.selectedElement?.id &&
                            block.type === "email"
                          ) {
                            return {
                              ...block,
                              properties: {
                                ...block.properties,
                                placeholder: placeholder,
                              },
                            };
                          }
                          return block;
                        }),
                      };
                    }
                    return section;
                  }),
                });
              }}
            />
          </div>
        </div>
        <div className="space-y-4 pt-5">
          <div className="flex justify-between">
            <div className="text-sm font-medium text-gray-700">Required</div>
            <VariantToggle
              size="sm"
              enabled={element.properties.required}
              setEnabled={(required) => {
                updateFormTemplateMutation.mutate({
                  _id: formTemplate._id,
                  blocks: formTemplate.blocks.map((section, sectionIndex) => {
                    if (
                      sectionIndex ===
                      formTemplateCtx.selectedElement?.sectionIndex
                    ) {
                      return {
                        ...section,
                        blocks: section.blocks.map((block) => {
                          if (
                            block.id === formTemplateCtx.selectedElement?.id &&
                            block.type === "email"
                          ) {
                            return {
                              ...block,
                              properties: {
                                ...block.properties,
                                required: required,
                              },
                            };
                          }
                          return block;
                        }),
                      };
                    }
                    return section;
                  }),
                });
              }}
            />
          </div>
          <div className="space-y-2">
            <div className="flex justify-between">
              <div className="text-sm font-medium text-gray-700">
                Description
              </div>
              <VariantToggle
                size="sm"
                enabled={descriptionRequired}
                setEnabled={(required) => {
                  setDescriptionRequired(required);
                  if (!required) {
                    updateFormTemplateMutation.mutate({
                      _id: formTemplate._id,
                      blocks: formTemplate.blocks.map(
                        (section, sectionIndex) => {
                          if (
                            sectionIndex ===
                            formTemplateCtx.selectedElement?.sectionIndex
                          ) {
                            return {
                              ...section,
                              blocks: section.blocks.map((block) => {
                                if (
                                  block.id ===
                                    formTemplateCtx.selectedElement?.id &&
                                  block.type === "email"
                                ) {
                                  return {
                                    ...block,
                                    properties: {
                                      ...block.properties,
                                      description: "",
                                    },
                                  };
                                }
                                return block;
                              }),
                            };
                          }
                          return section;
                        }
                      ),
                    });
                  }
                }}
              />
            </div>
            {descriptionRequired && (
              <div className="space-y-1">
                <TextInput
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  onBlur={() => {
                    updateFormTemplateMutation.mutate({
                      _id: formTemplate._id,
                      blocks: formTemplate.blocks.map(
                        (section, sectionIndex) => {
                          if (
                            sectionIndex ===
                            formTemplateCtx.selectedElement?.sectionIndex
                          ) {
                            return {
                              ...section,
                              blocks: section.blocks.map((block) => {
                                if (
                                  block.id ===
                                    formTemplateCtx.selectedElement?.id &&
                                  block.type === "email"
                                ) {
                                  return {
                                    ...block,
                                    properties: {
                                      ...block.properties,
                                      description: description,
                                    },
                                  };
                                }
                                return block;
                              }),
                            };
                          }
                          return section;
                        }
                      ),
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="pt-4">
          {formTemplateCtx.selectedElement?.sectionIndex !== undefined && (
            <DeleteElementButton
              elementId={element.id}
              formTemplateId={formTemplate._id}
              sectionIndex={formTemplateCtx.selectedElement?.sectionIndex}
            />
          )}
        </div>
      </div>
    </>
  );
}

function BooleanProperties({
  element,
  formTemplate,
}: {
  element: FormTemplateBooleanBlockData;
  formTemplate: FormTemplateData;
}) {
  const updateFormTemplateMutation = useUpdateFormTemplate();
  const formTemplateCtx = useFormTemplateContext();
  const [label, setLabel] = useState(element.properties.label);

  useEffect(() => {
    setLabel(element.properties.label);
  }, [element.properties.label]);

  const [descriptionRequired, setDescriptionRequired] = useState(
    element.properties.description.trim() !== ""
  );

  useEffect(() => {
    setDescriptionRequired(element.properties.description.trim() !== "");
  }, [element]);

  const [description, setDescription] = useState(
    element.properties.description
  );

  useEffect(() => {
    setDescription(element.properties.description);
  }, [element.properties.description]);

  const [trueLabel, setTrueLabel] = useState(element.properties.trueLabel);
  useEffect(() => {
    setTrueLabel(element.properties.trueLabel);
  }, [element.properties.trueLabel]);

  const [falseLabel, setFalseLabel] = useState(element.properties.falseLabel);
  useEffect(() => {
    setFalseLabel(element.properties.falseLabel);
  }, [element.properties.falseLabel]);

  return (
    <>
      <div className="space-y-6 divide-y">
        <div className="space-y-4">
          <div className="space-y-1">
            <div className="text-sm font-medium text-gray-700">Title</div>
            <TextInput
              value={label}
              onChange={(e) => {
                setLabel(e.target.value);
              }}
              onBlur={() => {
                updateFormTemplateMutation.mutate({
                  _id: formTemplate._id,
                  blocks: formTemplate.blocks.map((section, sectionIndex) => {
                    if (
                      sectionIndex ===
                      formTemplateCtx.selectedElement?.sectionIndex
                    ) {
                      return {
                        ...section,
                        blocks: section.blocks.map((block) => {
                          if (
                            block.id === formTemplateCtx.selectedElement?.id &&
                            block.type === "boolean"
                          ) {
                            return {
                              ...block,
                              properties: {
                                ...block.properties,
                                label: label,
                              },
                            };
                          }
                          return block;
                        }),
                      };
                    }
                    return section;
                  }),
                });
              }}
            />
          </div>
          <div className="space-y-1">
            <div className="text-sm font-medium text-gray-700">
              True Value Label
            </div>
            <TextInput
              value={trueLabel}
              onChange={(e) => {
                setTrueLabel(e.target.value);
              }}
              onBlur={() => {
                updateFormTemplateMutation.mutate({
                  _id: formTemplate._id,
                  blocks: formTemplate.blocks.map((section, sectionIndex) => {
                    if (
                      sectionIndex ===
                      formTemplateCtx.selectedElement?.sectionIndex
                    ) {
                      return {
                        ...section,
                        blocks: section.blocks.map((block) => {
                          if (
                            block.id === formTemplateCtx.selectedElement?.id &&
                            block.type === "boolean"
                          ) {
                            return {
                              ...block,
                              properties: {
                                ...block.properties,
                                trueLabel: trueLabel,
                              },
                            };
                          }
                          return block;
                        }),
                      };
                    }
                    return section;
                  }),
                });
              }}
            />
          </div>
          <div className="space-y-1">
            <div className="text-sm font-medium text-gray-700">
              False Value Label
            </div>
            <TextInput
              value={falseLabel}
              onChange={(e) => {
                setFalseLabel(e.target.value);
              }}
              onBlur={() => {
                updateFormTemplateMutation.mutate({
                  _id: formTemplate._id,
                  blocks: formTemplate.blocks.map((section, sectionIndex) => {
                    if (
                      sectionIndex ===
                      formTemplateCtx.selectedElement?.sectionIndex
                    ) {
                      return {
                        ...section,
                        blocks: section.blocks.map((block) => {
                          if (
                            block.id === formTemplateCtx.selectedElement?.id &&
                            block.type === "boolean"
                          ) {
                            return {
                              ...block,
                              properties: {
                                ...block.properties,
                                falseLabel: falseLabel,
                              },
                            };
                          }
                          return block;
                        }),
                      };
                    }
                    return section;
                  }),
                });
              }}
            />
          </div>
        </div>
        <div className="space-y-4 pt-5">
          <div className="space-y-2">
            <div className="flex justify-between">
              <div className="text-sm font-medium text-gray-700">
                Description
              </div>
              <VariantToggle
                size="sm"
                enabled={descriptionRequired}
                setEnabled={(required) => {
                  setDescriptionRequired(required);
                  if (!required) {
                    updateFormTemplateMutation.mutate({
                      _id: formTemplate._id,
                      blocks: formTemplate.blocks.map(
                        (section, sectionIndex) => {
                          if (
                            sectionIndex ===
                            formTemplateCtx.selectedElement?.sectionIndex
                          ) {
                            return {
                              ...section,
                              blocks: section.blocks.map((block) => {
                                if (
                                  block.id ===
                                    formTemplateCtx.selectedElement?.id &&
                                  block.type === "boolean"
                                ) {
                                  return {
                                    ...block,
                                    properties: {
                                      ...block.properties,
                                      description: "",
                                    },
                                  };
                                }
                                return block;
                              }),
                            };
                          }
                          return section;
                        }
                      ),
                    });
                  }
                }}
              />
            </div>
            {descriptionRequired && (
              <div className="space-y-1">
                <TextInput
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  onBlur={() => {
                    updateFormTemplateMutation.mutate({
                      _id: formTemplate._id,
                      blocks: formTemplate.blocks.map(
                        (section, sectionIndex) => {
                          if (
                            sectionIndex ===
                            formTemplateCtx.selectedElement?.sectionIndex
                          ) {
                            return {
                              ...section,
                              blocks: section.blocks.map((block) => {
                                if (
                                  block.id ===
                                    formTemplateCtx.selectedElement?.id &&
                                  block.type === "boolean"
                                ) {
                                  return {
                                    ...block,
                                    properties: {
                                      ...block.properties,
                                      description: description,
                                    },
                                  };
                                }
                                return block;
                              }),
                            };
                          }
                          return section;
                        }
                      ),
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="pt-4">
          {formTemplateCtx.selectedElement?.sectionIndex !== undefined && (
            <DeleteElementButton
              elementId={element.id}
              formTemplateId={formTemplate._id}
              sectionIndex={formTemplateCtx.selectedElement?.sectionIndex}
            />
          )}
        </div>
      </div>
    </>
  );
}

function TableProperties({
  element,
  formTemplate,
}: {
  element: FormTemplateTableBlockData;
  formTemplate: FormTemplateData;
}) {
  const updateFormTemplateMutation = useUpdateFormTemplate();
  const formTemplateCtx = useFormTemplateContext();
  const [label, setLabel] = useState(element.properties.label);

  useEffect(() => {
    setLabel(element.properties.label);
  }, [element.properties.label]);

  const [descriptionRequired, setDescriptionRequired] = useState(
    element.properties.description.trim() !== ""
  );

  useEffect(() => {
    setDescriptionRequired(element.properties.description.trim() !== "");
  }, [element]);

  const [description, setDescription] = useState(
    element.properties.description
  );

  useEffect(() => {
    setDescription(element.properties.description);
  }, [element.properties.description]);

  const [columns, setColumns] = useState(element.properties.columns);
  useEffect(() => {
    setColumns(element.properties.columns);
  }, [element.properties.columns]);

  const [initialNumberOfRows, setInitialNumberOfRows] = useState(
    element.properties.initialNumberOfRows
  );

  useEffect(() => {
    setInitialNumberOfRows(element.properties.initialNumberOfRows);
  }, [element.properties.initialNumberOfRows]);

  return (
    <>
      <div className="space-y-6 divide-y">
        <div className="space-y-4">
          <div className="space-y-1">
            <div className="text-sm font-medium text-gray-700">Title</div>
            <TextInput
              value={label}
              onChange={(e) => {
                setLabel(e.target.value);
              }}
              onBlur={() => {
                updateFormTemplateMutation.mutate({
                  _id: formTemplate._id,
                  blocks: formTemplate.blocks.map((section, sectionIndex) => {
                    if (
                      sectionIndex ===
                      formTemplateCtx.selectedElement?.sectionIndex
                    ) {
                      return {
                        ...section,
                        blocks: section.blocks.map((block) => {
                          if (
                            block.id === formTemplateCtx.selectedElement?.id &&
                            block.type === "table"
                          ) {
                            return {
                              ...block,
                              properties: {
                                ...block.properties,
                                label: label,
                              },
                            };
                          }
                          return block;
                        }),
                      };
                    }
                    return section;
                  }),
                });
              }}
            />
          </div>
          <div className="space-y-1">
            <InputHeading heading="Columns" />
            <div className="flex flex-col gap-2">
              {columns.map((column, index) => (
                <TableColumnProperties
                  index={index}
                  column={column}
                  element={element}
                  formTemplate={formTemplate}
                />
              ))}
            </div>
            <div className="pt-2">
              <NewButton
                variant={"secondary"}
                onClick={() => {
                  updateFormTemplateMutation.mutate({
                    _id: formTemplate._id,
                    blocks: formTemplate.blocks.map((section, sectionIndex) => {
                      if (
                        sectionIndex ===
                        formTemplateCtx.selectedElement?.sectionIndex
                      ) {
                        return {
                          ...section,
                          blocks: section.blocks.map((block) => {
                            if (
                              block.id ===
                                formTemplateCtx.selectedElement?.id &&
                              block.type === "table"
                            ) {
                              return {
                                ...block,
                                properties: {
                                  ...block.properties,
                                  columns: [
                                    ...block.properties.columns,
                                    {
                                      name: "New Column",
                                    },
                                  ],
                                },
                              };
                            }
                            return block;
                          }),
                        };
                      }
                      return section;
                    }),
                  });
                }}
              >
                Add Column
              </NewButton>
            </div>
          </div>
        </div>
        <div className="space-y-4 pt-5">
          <div className="space-y-2">
            <div className="flex justify-between">
              <div className="text-sm font-medium text-gray-700">
                Description
              </div>
              <VariantToggle
                size="sm"
                enabled={descriptionRequired}
                setEnabled={(required) => {
                  setDescriptionRequired(required);
                  if (!required) {
                    updateFormTemplateMutation.mutate({
                      _id: formTemplate._id,
                      blocks: formTemplate.blocks.map(
                        (section, sectionIndex) => {
                          if (
                            sectionIndex ===
                            formTemplateCtx.selectedElement?.sectionIndex
                          ) {
                            return {
                              ...section,
                              blocks: section.blocks.map((block) => {
                                if (
                                  block.id ===
                                    formTemplateCtx.selectedElement?.id &&
                                  block.type === "table"
                                ) {
                                  return {
                                    ...block,
                                    properties: {
                                      ...block.properties,
                                      description: "",
                                    },
                                  };
                                }
                                return block;
                              }),
                            };
                          }
                          return section;
                        }
                      ),
                    });
                  }
                }}
              />
            </div>
            {descriptionRequired && (
              <div className="space-y-1">
                <TextInput
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  onBlur={() => {
                    updateFormTemplateMutation.mutate({
                      _id: formTemplate._id,
                      blocks: formTemplate.blocks.map(
                        (section, sectionIndex) => {
                          if (
                            sectionIndex ===
                            formTemplateCtx.selectedElement?.sectionIndex
                          ) {
                            return {
                              ...section,
                              blocks: section.blocks.map((block) => {
                                if (
                                  block.id ===
                                    formTemplateCtx.selectedElement?.id &&
                                  block.type === "table"
                                ) {
                                  return {
                                    ...block,
                                    properties: {
                                      ...block.properties,
                                      description: description,
                                    },
                                  };
                                }
                                return block;
                              }),
                            };
                          }
                          return section;
                        }
                      ),
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="pt-4">
          {formTemplateCtx.selectedElement?.sectionIndex !== undefined && (
            <DeleteElementButton
              elementId={element.id}
              formTemplateId={formTemplate._id}
              sectionIndex={formTemplateCtx.selectedElement?.sectionIndex}
            />
          )}
        </div>
      </div>
    </>
  );
}

function TableColumnProperties({
  index,
  column,
  element,
  formTemplate,
}: {
  index: number;
  column: FormTemplateTableBlockData["properties"]["columns"][0];
  element: FormTemplateTableBlockData;
  formTemplate: FormTemplateData;
}) {
  const [columnName, setColumnName] = useState(column.name);
  const updateFormTemplateMutation = useUpdateFormTemplate();
  const formTemplateCtx = useFormTemplateContext();
  useEffect(() => {
    setColumnName(column.name);
  }, [column.name]);
  return (
    <div className="flex items-center gap-2">
      <input
        key={index}
        value={columnName}
        className="flex-1 rounded-md border px-3 py-2 text-sm ring-primary-main focus:border-primary-main focus:ring-1"
        onChange={(e) => {
          setColumnName(e.target.value);
        }}
        onBlur={() => {
          updateFormTemplateMutation.mutate({
            _id: formTemplate._id,
            blocks: formTemplate.blocks.map((section, sectionIndex) => {
              if (
                sectionIndex === formTemplateCtx.selectedElement?.sectionIndex
              ) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (
                      block.id === formTemplateCtx.selectedElement?.id &&
                      block.type === "table"
                    ) {
                      return {
                        ...block,
                        properties: {
                          ...block.properties,
                          columns: [
                            ...block.properties.columns.slice(0, index),
                            {
                              ...block.properties.columns[index],
                              name: columnName,
                            },
                            ...block.properties.columns.slice(index + 1),
                          ],
                        },
                      };
                    }
                    return block;
                  }),
                };
              }
              return section;
            }),
          });
        }}
      />
      <button
        className="rounded-full bg-gray-50 p-1 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
        onClick={() => {
          updateFormTemplateMutation.mutate({
            _id: formTemplate._id,
            blocks: formTemplate.blocks.map((section, sectionIndex) => {
              if (
                sectionIndex === formTemplateCtx.selectedElement?.sectionIndex
              ) {
                return {
                  ...section,
                  blocks: section.blocks.map((block) => {
                    if (
                      block.id === formTemplateCtx.selectedElement?.id &&
                      block.type === "table"
                    ) {
                      return {
                        ...block,
                        properties: {
                          ...block.properties,
                          columns: [
                            ...block.properties.columns.slice(0, index),
                            ...block.properties.columns.slice(index + 1),
                          ],
                        },
                      };
                    }
                    return block;
                  }),
                };
              }
              return section;
            }),
          });
        }}
      >
        <XMarkIcon className="h-3 w-3" />
      </button>
    </div>
  );
}

function Form() {
  const { formTemplateId } = useParams<{ formTemplateId: string }>();
  const { data: formTemplate } = useGetFormTemplate(String(formTemplateId));
  const formTemplateCtx = useFormTemplateContext();
  const [previewOpen, setPreviewOpen] = useState(false);
  return (
    <div
      className="flex min-w-[600px] flex-1 flex-col overflow-auto border border-gray-300 bg-gray-100"
      onClick={() => {
        formTemplateCtx.setSelectedElement(undefined);
      }}
    >
      <div className="flex justify-end p-4">
        <Dialog>
          <DialogTrigger asChild>
            <NewButton size={"sm"} variant={"secondary"}>
              Preview
            </NewButton>
          </DialogTrigger>
          <DialogContent className="max-h-[calc(100vh-40px)] w-full max-w-5xl overflow-auto">
            <div className="mx-auto w-full max-w-xl py-10">
              {formTemplate?.blocks?.map((section, index) => (
                <FormTemplateSectionPreview section={section} />
              ))}
            </div>
          </DialogContent>
          {/* <DialogContent className="max-h-[calc(100vh-40px)] w-full max-w-5xl overflow-clip p-0">
            <div className="max-h-[calc(100vh-40px)] overflow-auto py-20">
              <div className="mx-auto max-w-xl">
                {formTemplate?.blocks?.map((section, index) => (
                  <FormTemplateSectionPreview section={section} />
                ))}
              </div>
            </div>
            <div className="absolute bottom-0 h-8 w-full bg-gradient-to-t from-gray-400 to-transparent opacity-60" />
          </DialogContent> */}
        </Dialog>
      </div>
      <div className="flex-1 overflow-auto">
        {formTemplate?.blocks?.map((section, index) => (
          <FormTemplateSection
            key={index}
            section={section}
            sectionIndex={index}
          />
        ))}
      </div>
    </div>
  );
}

function getElementPreview(element: FormTemplateElementBlockData) {
  switch (element.type) {
    case "heading":
      return (
        <div className="space-y-1">
          <div className="text-4xl font-bold">{element.properties.text}</div>
          <div className="text-gray-600 ">{element.properties.description}</div>
        </div>
      );
    case "short text":
    case "email":
      return (
        <div className="space-y-1">
          <div>
            <div className="relative w-fit">
              <div className="text-md">{element.properties.label}</div>
              {element.properties.required && (
                <div className="absolute top-0 left-full -mt-0.5 ml-0.5 h-1.5 w-1.5 rounded-full text-red-500">
                  *
                </div>
              )}
            </div>
            <div className="text-sm text-gray-500">
              {element.properties.description}
            </div>
          </div>
          <input
            disabled
            className="w-full max-w-sm rounded-md border border-gray-300 px-3 py-2 text-sm outline-none ring-primary-main placeholder:text-gray-400 focus:border-primary-main focus:outline-none focus:ring-1"
            placeholder={element.properties.placeholder}
          />
        </div>
      );
    case "long text":
      return (
        <div className="space-y-1">
          <div>
            <div className="relative w-fit">
              <div className="text-md">{element.properties.label}</div>
              {element.properties.required && (
                <div className="absolute top-0 left-full -mt-0.5 ml-0.5 h-1.5 w-1.5 rounded-full text-red-500">
                  *
                </div>
              )}
            </div>
            <div className="text-sm text-gray-500">
              {element.properties.description}
            </div>
          </div>
          <textarea
            disabled
            rows={3}
            placeholder={element.properties.placeholder}
            className="w-full rounded-md border border-gray-300 px-3 py-2 text-sm outline-none ring-primary-main focus:border-primary-main focus:outline-none focus:ring-1 disabled:bg-gray-50"
          />
        </div>
      );
    case "table":
      return (
        <div className="w-fit space-y-2 overflow-auto py-3">
          <div>
            <div className="text-md">{element.properties.label}</div>
            <div className="text-sm text-gray-500">
              {element.properties.description}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex">
              {element.properties.columns.map((column) => (
                <div className="w-40 border bg-gray-100 px-3 py-2 text-sm font-medium ring-primary-main focus:border-primary-main focus:ring-1">
                  {column.name}
                </div>
              ))}
            </div>
            {Array.from({ length: element.properties.initialNumberOfRows }).map(
              () => (
                <div className="flex">
                  {element.properties.columns.map((column) => (
                    <div className="w-40 border px-3 py-2 text-sm text-transparent ring-primary-main focus:border-primary-main focus:ring-1">
                      {"Text"}
                    </div>
                  ))}
                </div>
              )
            )}
            <div className="flex border px-3 py-2 text-sm text-gray-500">
              + Add Row
            </div>
          </div>
        </div>
      );
    case "richText":
      return <RichTextPreview element={element} />;
    case "date":
      return (
        <div className="space-y-1">
          <div>
            <div className="relative w-fit">
              <div className="text-md">{element.properties.label}</div>
              {element.properties.required && (
                <div className="absolute top-0 left-full -mt-0.5 ml-0.5 h-1.5 w-1.5 rounded-full text-red-500">
                  *
                </div>
              )}
            </div>
            <div className="text-sm text-gray-500">
              {element.properties.description}
            </div>
          </div>
          <div className="relative w-full max-w-sm">
            <input
              // placeholder={element.properties.placeholder}
              disabled
              className="w-full rounded-md border border-gray-300 px-3 py-2 text-sm outline-none ring-primary-main placeholder:text-gray-400 focus:border-primary-main focus:outline-none focus:ring-1"
              placeholder={dayjs().format("MMM DD, YYYY")}
            />
            <CalendarIcon className="absolute top-1/2 right-2 h-5 w-5 -translate-y-1/2 transform text-gray-400/70" />
          </div>
        </div>
      );
    case "boolean":
      return (
        <div>
          <div className="space-y-1">
            <div>
              <div className="text-md">{element.properties.label}</div>
              {element.properties.description.trim() !== "" && (
                <div className="text-sm text-gray-500">
                  {element.properties.description}
                </div>
              )}
            </div>
            <div className="flex items-center space-x-2">
              <div className="text-sm text-gray-700">
                {element.properties.falseLabel}
              </div>
              <Switch checked={true} disabled />
              <div className="text-sm text-gray-700">
                {element.properties.trueLabel}
              </div>
            </div>
          </div>
        </div>
      );
  }
}

function RichTextPreview({
  element,
}: {
  element: FormTemplateRichTextBlockData;
}) {
  const editor = useEditor({
    extensions: [StarterKit, Typography],
    editorProps: {
      attributes: {
        class:
          "prose outline-none ring-0 focus:outline-none focus:ring-0 border-none focus:border-none",
      },
    },
    content: element.properties.text,
    editable: false,
  });
  return <EditorContent editor={editor} />;
}

function FormTemplateSectionPreview({
  section,
}: {
  section: FormTemplateSectionBlockData;
}) {
  return (
    <div className="space-y-10">
      {section.blocks.map((element, index) => {
        return <>{getElementPreview(element)}</>;
      })}
      <div className="pt-5">
        <Button disabled>Submit</Button>
      </div>
    </div>
  );
}

function FormTemplateSection({
  section,
  sectionIndex,
}: {
  section: FormTemplateSectionBlockData;
  sectionIndex: number;
}) {
  const { formTemplateId } = useParams();
  const { data: formTemplate } = useGetFormTemplate(String(formTemplateId));
  const formTemplateCtx = useFormTemplateContext();
  const updateFormTemplateMutation = useUpdateFormTemplate();
  const droppable = useDroppable({
    id: `section-${sectionIndex}`,
    data: {
      isSection: true,
      sectionIndex: sectionIndex,
    },
  });

  useDndMonitor({
    onDragStart: (e) => {
      console.log("drag start", e);
    },
    onDragCancel: () => {
      console.log("drag cancel");
    },
    onDragEnd: (event: DragEndEvent) => {
      if (!!formTemplate) {
        const { active, over } = event;
        if (!active || !over) return;
        const isDroppingElementButton = active.data?.current?.isElementButton;
        const isDroppingElement = active.data?.current?.isElement;
        const isDroppingOverSection = over.data?.current?.isSection;
        const isDroppingOverElement = over.data.current?.isElement;

        const isDroppingButtonOverSection =
          isDroppingElementButton && isDroppingOverSection;
        const isDroppingButtonOverElement =
          isDroppingElementButton && isDroppingOverElement;
        const isDroppingElementOverElement =
          isDroppingElement && isDroppingOverElement;
        // console.log("drop over section", isDroppingOverSection);
        if (isDroppingButtonOverSection) {
          const newElement = getNewElement(active.data?.current?.type);
          if (!!newElement) {
            updateFormTemplateMutation.mutate({
              _id: String(formTemplateId),
              blocks: formTemplate?.blocks.map((section, index) => {
                if (index === sectionIndex && !!active.data?.current?.type) {
                  return {
                    ...section,
                    blocks: [...section.blocks, newElement],
                  };
                }
                return section;
              }),
            });
            formTemplateCtx.setSelectedElement({
              id: newElement.id,
              sectionIndex: sectionIndex,
            });
          }
        }
        if (isDroppingButtonOverElement) {
          const newElement = getNewElement(active.data?.current?.type);
          if (!!newElement) {
            let elementIndex = over.data?.current?.elementIndex;
            if (over.data?.current?.isBottomHalf) {
              elementIndex = elementIndex + 1;
            }
            updateFormTemplateMutation.mutate({
              _id: String(formTemplateId),
              blocks: formTemplate?.blocks.map((section, index) => {
                if (index === sectionIndex && !!active.data?.current?.type) {
                  return {
                    ...section,
                    blocks: [
                      ...section.blocks.slice(0, elementIndex),
                      newElement,
                      ...section.blocks.slice(elementIndex),
                    ],
                  };
                }
                return section;
              }),
            });
            formTemplateCtx.setSelectedElement({
              id: newElement.id,
              sectionIndex: sectionIndex,
            });
          }
        }

        if (isDroppingElementOverElement) {
          if (!active.data?.current?.id || !over.data?.current?.id) return;
          formTemplateCtx.setSelectedElement({
            id: active.data?.current?.id,
            sectionIndex: sectionIndex,
          });
          const droppingElementId = active.data?.current?.id;
          const sections = [...formTemplate.blocks];
          let section = sections[sectionIndex];
          const droppingElement = section.blocks.find(
            (e) => e.id === droppingElementId
          );
          section = {
            ...section,
            blocks: section.blocks.filter((e) => e.id !== droppingElementId),
          };
          const droppedOnElementId = over.data?.current?.id;
          let droppedOnElementIndex = section.blocks.findIndex(
            (e) => e.id === droppedOnElementId
          );
          if (over.data?.current?.isBottomHalf) {
            droppedOnElementIndex = droppedOnElementIndex + 1;
          }

          if (droppedOnElementIndex && droppingElement) {
            section = {
              ...section,
              blocks: [
                ...section.blocks.slice(0, droppedOnElementIndex),
                droppingElement,
                ...section.blocks.slice(droppedOnElementIndex),
              ],
            };
            updateFormTemplateMutation.mutate({
              _id: String(formTemplateId),
              blocks: [
                ...sections.slice(0, sectionIndex),
                section,
                ...sections.slice(sectionIndex + 1),
              ],
            });
          }
        }
      }
    },
  });

  return (
    <div
      className={clsx("mx-auto min-h-full max-w-lg space-y-4 pb-6")}
      ref={droppable.setNodeRef}
      onClick={() => {
        formTemplateCtx.setSelectedElement(undefined);
      }}
    >
      <div
        className="flex min-h-full flex-col gap-4 rounded-md"
        ref={droppable.setNodeRef}
      >
        {section.blocks.map((block, elementIndex) => (
          <FormTemplateElementWrapper
            key={elementIndex}
            block={block}
            elementIndex={elementIndex}
            sectionIndex={sectionIndex}
          />
        ))}
      </div>
      <div
        className={clsx(
          "h-[100px] w-full rounded-md",
          droppable.isOver && "bg-gray-200"
        )}
      ></div>
    </div>
  );
}

function getNewElement(
  type: FormTemplateElementBlockData["type"]
): FormTemplateElementBlockData | null {
  switch (type) {
    case "short text":
      return {
        type: "short text",
        id: new ObjectID().toString(),
        properties: {
          label: "",
          description: "",
          required: false,
          placeholder: "Placeholder..",
        },
      };
    case "long text":
      return {
        type: "long text",
        id: new ObjectID().toString(),
        properties: {
          label: "",
          description: "",
          required: false,
          placeholder: "Placeholder..",
        },
      };
    case "heading":
      return {
        type: "heading",
        id: new ObjectID().toString(),
        properties: {
          text: "",
          description: "",
          size: "medium",
        },
      };
    case "richText":
      return {
        type: "richText",
        id: new ObjectID().toString(),
        properties: {
          text: "<h1>Add your rich text here</h2><p>It is a great way to present content with more structure</p>",
        },
      };
    case "date":
      return {
        type: "date",
        id: new ObjectID().toString(),
        properties: {
          label: "",
          description: "",
          required: false,
        },
      };
    case "email":
      return {
        type: "email",
        id: new ObjectID().toString(),
        properties: {
          label: "",
          description: "",
          required: false,
          placeholder: "john.doe@gmail.com",
        },
      };
    case "boolean":
      return {
        type: "boolean",
        id: new ObjectID().toString(),
        properties: {
          label: "",
          description: "",
          trueLabel: "Yes",
          falseLabel: "No",
        },
      };
    case "table":
      return {
        type: "table",
        id: new ObjectID().toString(),
        properties: {
          label: "",
          description: "",
          columns: [
            { name: "Column 1" },
            { name: "Column 1" },
            { name: "Column 1" },
            { name: "Column 1" },
          ],
          initialNumberOfRows: 3,
          canAddRows: true,
        },
      };
    default:
      return null;
  }
}

function FormTemplateElementWrapper({
  block,
  elementIndex,
  sectionIndex,
}: {
  block: FormTemplateElementBlockData;
  elementIndex: number;
  sectionIndex: number;
}) {
  const formTemplateCtx = useFormTemplateContext();
  const draggable = useDraggable({
    id: block.id,
    data: {
      id: block.id,
      isElement: true,
      elementIndex: elementIndex,
      sectionIndex: sectionIndex,
      type: block.type,
    },
  });
  const topHalf = useDroppable({
    id: `element-${block.id}-top-half`,
    data: {
      id: block.id,
      isElement: true,
      elementIndex: elementIndex,
      sectionIndex: sectionIndex,
      isTopHalf: true,
    },
  });
  const bottomHalf = useDroppable({
    id: `element-${block.id}-bottom-half`,
    data: {
      id: block.id,
      isElement: true,
      elementIndex: elementIndex,
      sectionIndex: sectionIndex,
      isBottomHalf: true,
    },
  });
  if (draggable.isDragging) return null;

  return (
    <div
      className={clsx(
        "group relative cursor-pointer rounded-md border-2 bg-white hover:border-primary-main",
        formTemplateCtx.selectedElement?.id === block.id &&
          !!block.id &&
          "border-primary-main"
      )}
      onClick={(e) => {
        formTemplateCtx.setSelectedElement({
          sectionIndex: sectionIndex,
          id: block.id,
        });
        e.stopPropagation();
      }}
    >
      {elementIndex !== 0 && (
        <div
          className={clsx(
            "absolute bottom-full mb-[9px] h-0.5 w-full rounded-full",
            topHalf.isOver && "bg-primary-main"
          )}
        />
      )}
      <div
        className={clsx(
          "absolute top-full mt-[9px] h-0.5 w-full rounded-full",
          bottomHalf.isOver && "bg-primary-main"
        )}
      />
      <div
        className="pointer-events-none absolute h-1/2 w-full rounded-t-md"
        ref={topHalf.setNodeRef}
      ></div>
      <div
        className="pointer-events-none absolute bottom-0 h-1/2 w-full rounded-b-md"
        ref={bottomHalf.setNodeRef}
      ></div>
      {elementIndex !== 0 && (
        <div className="absolute right-full -mt-[2px] hidden h-full pr-2 hover:block group-hover:block">
          <div
            className="cursor-move rounded-md p-1 px-0.5 hover:bg-gray-200"
            ref={draggable.setNodeRef}
            {...draggable.attributes}
            {...draggable.listeners}
          >
            <DragHandleDots2Icon className="h-5 w-5 text-gray-400" />
          </div>
        </div>
      )}
      <div className="p-3">
        <FormTemplateElement block={block} />
      </div>
    </div>
  );
}

function FormTemplateElementTitleAndDescription({
  titlePlaceholder,
  required,
  label,
  type,
  description,
}: {
  titlePlaceholder: string;
  required?: boolean;
  label: string;
  type: FormTemplateElementBlockData["type"];
  description?: string;
}) {
  const Icon = getElementIcon(type);
  const color = getElementColor(type);
  return (
    <div className="space-y-0.5">
      <div className="flex justify-between space-x-2">
        <div className="">
          {label.trim() === "" ? (
            <div className="relative">
              <div className="text-gray-400">{titlePlaceholder}</div>
              {required && (
                <div className="absolute top-0 left-full -mt-0.5 ml-0.5 h-1.5 w-1.5 rounded-full text-red-500">
                  *
                </div>
              )}
            </div>
          ) : (
            <div className="relative">
              <div className="max-w-sm truncate">{label}</div>
              {required && (
                <div className="absolute top-0 left-full -mt-0.5 ml-0.5 h-1.5 w-1.5 rounded-full text-red-500">
                  *
                </div>
              )}
            </div>
          )}
        </div>
        <div className={`rounded p-1.5 bg-${color}-300 h-fit`}>
          <Icon className="h-3 w-3" />
        </div>
      </div>
      {!!description && description.trim() !== "" && (
        <div className="-mt-1 text-sm text-gray-400">{description}</div>
      )}
    </div>
  );
}

function FormTemplateElement({
  block,
}: {
  block: FormTemplateElementBlockData;
}) {
  const Icon = getElementIcon(block.type);
  const color = getElementColor(block.type);
  switch (block.type) {
    case "short text":
      return (
        <div className="space-y-4">
          <FormTemplateElementTitleAndDescription
            titlePlaceholder="Question"
            required={block.properties.required}
            label={block.properties.label}
            type="short text"
            description={block.properties.description}
          />
          <div>
            <input
              className="pointer-events-none w-72 rounded-md bg-gray-100 p-1.5 px-2 text-sm placeholder:text-gray-400"
              disabled={true}
              placeholder={block.properties.placeholder}
            />
          </div>
        </div>
      );
    case "long text":
      return (
        <div className="space-y-4">
          <FormTemplateElementTitleAndDescription
            titlePlaceholder="Question"
            required={block.properties.required}
            label={block.properties.label}
            type="long text"
            description={block.properties.description}
          />
          <textarea
            className="pointer-events-none w-full rounded-md border-none bg-gray-100 p-1.5 px-2 text-sm outline-none placeholder:text-gray-400"
            disabled={true}
            rows={3}
            placeholder={block.properties.placeholder}
          />
        </div>
      );
    case "heading":
      return (
        <div className="space-y-2">
          <div className="flex justify-between space-x-2">
            <div
              className={clsx(
                "truncate font-bold",
                block.properties.size === "small" && "text-xl",
                block.properties.size === "medium" && "text-2xl",
                block.properties.size === "large" && "text-3xl"
              )}
            >
              {block.properties.text.trim() === "" ? (
                <div className="text-gray-400">Heading</div>
              ) : (
                <div className="truncate">{block.properties.text}</div>
              )}
            </div>
            <div className={`rounded p-1.5 bg-${color}-200 h-fit`}>
              <Icon className="h-3 w-3" />
            </div>
          </div>
          <div>
            {block.properties.description.trim() === "" ? (
              <div className="text-sm text-gray-400">Description..</div>
            ) : (
              <div className={clsx("text-sm")}>
                {block.properties.description}
              </div>
            )}
          </div>
        </div>
      );
    case "richText":
      return <RichTextElement block={block} />;
    case "email":
      return (
        <div className="space-y-4">
          <FormTemplateElementTitleAndDescription
            titlePlaceholder="Email"
            required={block.properties.required}
            label={block.properties.label}
            type="email"
            description={block.properties.description}
          />
          <div>
            <input
              className="pointer-events-none w-72 rounded-md bg-gray-100 p-1.5 px-2 text-sm placeholder:text-gray-400"
              disabled={true}
              placeholder={block.properties.placeholder}
            />
          </div>
        </div>
      );
    case "date":
      return (
        <div className="space-y-4">
          <FormTemplateElementTitleAndDescription
            titlePlaceholder="Date"
            required={block.properties.required}
            label={block.properties.label}
            type="date"
            description={block.properties.description}
          />
          <div className="relative w-72">
            <input
              className="pointer-events-none w-full rounded-md bg-gray-100 p-1.5 px-2 pr-8 text-sm placeholder:text-gray-400"
              disabled={true}
              placeholder={dayjs().format("MMM DD, YYYY")}
            />
            <CalendarIcon className="absolute top-1/2 right-2 h-5 w-5 -translate-y-1/2 transform text-gray-400/70" />
          </div>
        </div>
      );
    case "boolean":
      return (
        <div className="space-y-4">
          <FormTemplateElementTitleAndDescription
            titlePlaceholder="Question"
            label={block.properties.label}
            type="boolean"
            description={block.properties.description}
          />
          <div className="flex items-center space-x-2">
            <div className="text-sm text-gray-400">
              {block.properties.falseLabel}
            </div>
            <Switch checked={true} disabled />
            <div className="text-sm text-gray-400">
              {block.properties.trueLabel}
            </div>
          </div>
        </div>
      );
    case "table":
      return (
        <div className="pointer-events-auto space-y-4">
          <FormTemplateElementTitleAndDescription
            titlePlaceholder="Table"
            label={block.properties.label}
            type="table"
            description={block.properties.description}
          />
          <div className="overflow-auto pb-4">
            <div className="w-fit">
              <div className="flex">
                {block.properties.columns.map((column, index) => (
                  <div>
                    <div
                      className="w-40 truncate border bg-gray-100 px-2 py-1 text-sm text-gray-500"
                      key={index}
                    >
                      {column.name}
                    </div>
                    {new Array(block.properties.initialNumberOfRows)
                      .fill(0)
                      .map((_, index) => (
                        <div className="h-7 w-40 border border-t-0"></div>
                      ))}
                  </div>
                ))}

                {/* {block.properties.initialNumberOfRows.map((row, index) => (
              ))} */}
              </div>
              {block.properties.canAddRows && (
                <div className="w-full border border-t-0 px-2 py-1 text-sm text-gray-400">
                  + Add Row
                </div>
              )}
            </div>
          </div>
        </div>
      );
    default:
      return (
        <div className="text-sm text-gray-400">{`Block type not supported`}</div>
      );
  }
}

function RichTextElement({ block }: { block: FormTemplateRichTextBlockData }) {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Typography,
      Link.configure({
        openOnClick: true,
        validate: (href) => /^https?:\/\//.test(href),
      }),
    ],
    editorProps: {
      attributes: {
        class:
          "prose outline-none ring-0 focus:outline-none focus:ring-0 border-none focus:border-none",
      },
    },
    content: block.properties.text,
    editable: false,
  });
  useEffect(() => {
    editor?.commands.setContent(block.properties.text);
  }, [block.properties.text]);
  const Icon = getElementIcon(block.type);
  const color = getElementColor(block.type);
  return (
    <div>
      <div className="flex justify-between">
        <EditorContent editor={editor} className="" />
        <div className={`shrink-0 rounded p-1.5 bg-${color}-200 h-fit`}>
          <Icon className="h-3 w-3" />
        </div>
      </div>
    </div>
  );
}

function DragOverlayWrapper() {
  const { data: formTemplate } = useGetFormTemplate(
    String(useParams<{ formTemplateId: string }>().formTemplateId)
  );
  const [draggedItem, setDraggedItem] = useState<Active | null>(null);
  useDndMonitor({
    onDragStart: (e) => {
      setDraggedItem(e.active);
    },
    onDragCancel: () => {
      setDraggedItem(null);
    },
    onDragEnd: () => {
      setDraggedItem(null);
    },
  });
  let node = <div>No overlay</div>;
  const isElementButton = draggedItem?.data?.current?.isElementButton;
  const isElement = draggedItem?.data?.current?.isElement;
  if (isElementButton) {
    node = <ElementButtonDragOverlay type={draggedItem?.data?.current?.type} />;
  }

  if (isElement) {
    const elementIndex = draggedItem?.data?.current?.elementIndex;
    const sectionIndex = draggedItem?.data?.current?.sectionIndex;
    if (
      elementIndex === undefined ||
      sectionIndex === undefined ||
      !formTemplate
    ) {
      return <></>;
    } else {
      if (formTemplate.blocks.length > sectionIndex) {
        const section = formTemplate?.blocks[sectionIndex];
        if (section.blocks.length > elementIndex) {
          const block = section.blocks[elementIndex];
          node = (
            <div className="w-[600px] max-w-lg rounded-md border bg-white p-3 opacity-80">
              <FormTemplateElement block={block} />
            </div>
          );
        }
      }
    }
  }
  // if (isElement) {
  //   node = (
  //     <ElementDragOverlay
  //       type={draggedItem?.data?.current?.type}
  //       elementIndex={draggedItem?.data?.current?.elementIndex}
  //       sectionIndex={draggedItem?.data?.current?.sectionIndex}
  //     />
  //   );
  // }
  return (
    <DragOverlay className="cursor-move" dropAnimation={null}>
      {node}
    </DragOverlay>
  );
}

function ElementsSidebar() {
  const textElementTypes: ElementType[] = ["short text", "long text"];
  const dateElementTypes: ElementType[] = ["date"];
  const infoElementTypes: ElementType[] = ["heading", "richText"];
  const contactElementTypes: ElementType[] = ["email"];
  const selectElementTypes: ElementType[] = ["boolean"];
  const tableElementTypes: ElementType[] = ["table"];

  return (
    <div className="flex w-72 shrink-0 flex-col space-y-4 overflow-y-auto py-6">
      <div className="pl-8 pr-4">
        <span className="text-lg font-medium text-gray-700">Fields</span>
        {/* <p className="text-sm text-gray-400">Add fields to your form</p> */}
      </div>
      <div className="flex-1 space-y-4 overflow-auto">
        <div className="flex flex-col space-y-4">
          <div>
            <div className="pl-8 pr-6 pb-1">
              <span className="text-sm capitalize tracking-wider text-gray-400">
                Information
              </span>
            </div>
            <div className="px-6">
              {infoElementTypes.map((type) => (
                <ElementButton key={type} type={type} />
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <div>
            <div className="pl-8 pr-6 pb-1">
              <span className="text-sm capitalize tracking-wider text-gray-400">
                Text
              </span>
            </div>
            <div className="px-6">
              {textElementTypes.map((type) => (
                <ElementButton key={type} type={type} />
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <div>
            <div className="pl-8 pr-6 pb-1">
              <span className="text-sm capitalize tracking-wider text-gray-400">
                Selection
              </span>
            </div>
            <div className="px-6">
              {selectElementTypes.map((type) => (
                <ElementButton key={type} type={type} />
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <div>
            <div className="pl-8 pr-6 pb-1">
              <span className="text-sm capitalize tracking-wider text-gray-400">
                Contact
              </span>
            </div>
            <div className="px-6">
              {contactElementTypes.map((type) => (
                <ElementButton key={type} type={type} />
              ))}
            </div>
          </div>
        </div>

        <div className="flex flex-col space-y-4">
          <div>
            <div className="pl-8 pr-6 pb-1">
              <span className="text-sm capitalize tracking-wider text-gray-400">
                Table
              </span>
            </div>
            <div className="px-6">
              {tableElementTypes.map((type) => (
                <ElementButton key={type} type={type} />
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <div>
            <div className="pl-8 pr-6 pb-1">
              <span className="text-sm capitalize tracking-wider text-gray-400">
                Date
              </span>
            </div>
            <div className="px-6">
              {dateElementTypes.map((type) => (
                <ElementButton key={type} type={type} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ElementButton({ type }: { type: ElementType }) {
  const Icon = getElementIcon(type);
  const readableType = getReadableElementType(type);
  const color = getElementColor(type);
  const draggable = useDraggable({
    id: type,
    data: {
      type,
      isElementButton: true,
    },
  });

  return (
    <button
      className={clsx(
        "w-full cursor-move rounded-md p-2 hover:bg-gray-100",
        draggable.isDragging && `ring-2 ring-${color}-300`
      )}
      {...draggable.attributes}
      {...draggable.listeners}
      ref={draggable.setNodeRef}
    >
      <div className="flex items-center space-x-2">
        <div className={clsx("rounded p-1", `bg-${color}-300`)}>
          <Icon className="h-4 w-4" />
        </div>
        <span className="text-sm text-gray-700">{readableType}</span>
      </div>
    </button>
  );
}

function ElementButtonDragOverlay({ type }: { type: ElementType }) {
  const Icon = getElementIcon(type);
  const readableType = getReadableElementType(type);
  const color = getElementColor(type);

  return (
    <button
      className={clsx("w-full cursor-move rounded-md p-2 hover:bg-gray-100")}
    >
      <div className="flex items-center space-x-3">
        <div className={clsx("rounded bg-blue-200 p-1.5", `bg-${color}-200`)}>
          <Icon className="h-4 w-4" />
        </div>
        <span className="text-sm text-gray-700">{readableType}</span>
      </div>
    </button>
  );
}

function getElementColor(type: ElementType): string {
  switch (type) {
    case "short text":
    case "long text":
      return "blue";
    case "heading":
    case "richText":
      return "gray";
    case "date":
      return "violet";
    case "email":
      return "yellow";
    case "boolean":
      return "teal";
    case "table":
      return "pink";
    default:
      return "blue";
  }
}

function getElementIcon(type: ElementType): React.ElementType {
  switch (type) {
    case "short text":
      return Bars2Icon;
    case "long text":
      return Bars4Icon;
    case "heading":
      return LetterCaseCapitalizeIcon;
    case "richText":
      return CodeBracketIcon;
    case "boolean":
      return ComponentBooleanIcon;
    case "date":
      return CalendarDaysIcon;
    case "email":
      return EnvelopeIcon;
    case "table":
      return TableIcon;
    default:
      return Bars2Icon;
  }
}

function getReadableElementType(type: ElementType): string {
  switch (type) {
    case "short text":
      return "Short Text";
    case "long text":
      return "Long Text";
    case "heading":
      return "Heading";
    case "richText":
      return "Styled Text";
    case "boolean":
      return "Toggle (Yes / No)";
    case "date":
      return "Date";
    case "email":
      return "Email";
    case "table":
      return "Table";
    default:
      return "";
  }
}

export const MenuBar = ({
  editor,
  setModalOpen,
  setUrl,
  setNewUrl,
}: {
  editor: Editor;
  setModalOpen: (value: boolean) => void;
  setUrl: (value: string) => void;
  setNewUrl: (value: string) => void;
}) => {
  const isActiveClassName = "bg-gray-200/50 text-gray-600";
  const buttons: {
    onClick: () => void;
    disabled: boolean | undefined;
    className: string;
    children: React.ReactNode;
  }[] = [
    {
      onClick: () => editor.chain().focus().toggleBold().run(),
      disabled: !editor.can().chain().focus().toggleBold().run(),
      className: editor.isActive("bold") ? isActiveClassName : "",
      children: <FontBoldIcon className="h-4 w-4" />,
    },
    {
      onClick: () => editor.chain().focus().toggleItalic().run(),
      disabled: !editor.can().chain().focus().toggleItalic().run(),
      className: editor.isActive("italic") ? isActiveClassName : "",
      children: <FontItalicIcon className="h-4 w-4" />,
    },
    {
      onClick: () => editor.chain().focus().toggleStrike().run(),
      disabled: !editor.can().chain().focus().toggleStrike().run(),
      className: editor.isActive("strike") ? isActiveClassName : "",
      children: <StrikethroughIcon className="h-4 w-4" />,
    },
    // {
    //   onClick: () => editor.chain().focus().toggleCode().run(),
    //   disabled: !editor.can().chain().focus().toggleCode().run(),
    //   className: editor.isActive("code") ? isActiveClassName : "",
    //   children: <Link1Icon className="h-5 w-5" />,
    // },
    // {
    //   onClick: () => editor.chain().focus().setParagraph().run(),
    //   disabled: false,
    //   className: editor.isActive("paragraph") ? isActiveClassName : "",
    //   children: < className="h-5 w-5" />,
    // },
    {
      onClick: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
      disabled: false,
      className: editor.isActive("heading", { level: 1 })
        ? isActiveClassName
        : "",
      children: <span className="font-mono">H</span>,
    },
    // {
    //   onClick: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
    //   disabled: false,
    //   className: editor.isActive("heading", { level: 2 })
    //     ? isActiveClassName
    //     : "",
    //   children: <span className="font-mono">H2</span>,
    // },
    // {
    //   onClick: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
    //   disabled: false,
    //   className: editor.isActive("heading", { level: 3 })
    //     ? isActiveClassName
    //     : "",
    //   children: (
    //     <div className="flex items-center">
    //       {/* <HeadingIcon className="h-3 w-3" /> */}
    //       <span className="font-mono">H3</span>
    //     </div>
    //   ),
    // },
    {
      onClick: () => editor.chain().focus().toggleBulletList().run(),
      disabled: false,
      className: editor.isActive("bulletList") ? isActiveClassName : "",
      children: <ListBulletIcon className="h-4 w-4" />,
    },
    {
      onClick: () => editor.chain().focus().toggleOrderedList().run(),
      disabled: false,
      className: editor.isActive("orderedList") ? isActiveClassName : "",
      children: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          // className="bi bi-list-ol"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z"
          />
          <path d="M1.713 11.865v-.474H2c.217 0 .363-.137.363-.317 0-.185-.158-.31-.361-.31-.223 0-.367.152-.373.31h-.59c.016-.467.373-.787.986-.787.588-.002.954.291.957.703a.595.595 0 0 1-.492.594v.033a.615.615 0 0 1 .569.631c.003.533-.502.8-1.051.8-.656 0-1-.37-1.008-.794h.582c.008.178.186.306.422.309.254 0 .424-.145.422-.35-.002-.195-.155-.348-.414-.348h-.3zm-.004-4.699h-.604v-.035c0-.408.295-.844.958-.844.583 0 .96.326.96.756 0 .389-.257.617-.476.848l-.537.572v.03h1.054V9H1.143v-.395l.957-.99c.138-.142.293-.304.293-.508 0-.18-.147-.32-.342-.32a.33.33 0 0 0-.342.338v.041zM2.564 5h-.635V2.924h-.031l-.598.42v-.567l.629-.443h.635V5z" />
        </svg>
      ),
    },
    {
      onClick: () => editor.chain().focus().toggleCodeBlock().run(),
      disabled: false,
      className: editor.isActive("codeBlock") ? isActiveClassName : "",
      children: <CodeBracketIcon className="h-4 w-4" />,
    },
    {
      onClick: () => editor.chain().focus().toggleBlockquote().run(),
      disabled: false,
      className: editor.isActive("blockquote") ? isActiveClassName : "",
      children: <QuoteIcon className="h-4 w-4" />,
    },
    {
      onClick: () => editor.chain().focus().setHorizontalRule().run(),
      disabled: false,
      className: "",
      children: <DividerHorizontalIcon className="h-4 w-4" />,
    },
    {
      onClick: useCallback(() => {
        if (editor) {
          const url = editor.getAttributes("link").href;
          console.log(url);
          setUrl(url);
          setNewUrl(url);
          setModalOpen(true);
          // const url = window.prompt("URL", url);

          // cancelled
          // if (url === null) {
          //   return;
          // }

          // empty
          // if (url === "") {
          //   editor.chain().focus().extendMarkRange("link").unsetLink().run();
          //   return;
          // }

          // update link
        }
      }, [editor]),
      className: editor?.isActive("link") ? isActiveClassName : "",
      children: <Link1Icon className="h-4 w-4" />,
      disabled: false,
    },
  ];

  if (!editor) {
    return null;
  }

  return (
    <>
      <div className="mb-3 flex flex-wrap gap-0.5">
        {buttons.map((button, index) => (
          <button
            key={index}
            onClick={button.onClick}
            disabled={button.disabled}
            className={classNames(
              "inline-flex h-6 w-6 items-center justify-center rounded p-0.5 text-xs text-gray-400 hover:bg-gray-200/50 focus:outline-none focus:ring-1 focus:ring-primary",
              button.className
            )}
          >
            {button.children}
          </button>
        ))}
      </div>
    </>
  );
};
