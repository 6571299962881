import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { JourneyTemplateData } from "src/api/Services/Journeys/journeys";
import { fuzzyFilter } from "src/pages/Services/Projects/ProjectsTable";
import { getTimeDifference } from "src/utils/functions/dateTime";

export const JourneyTemplatesTable = ({
  journeyTemplates,
  search,
  setSearch,
}: {
  search: string;
  setSearch: (search: string) => void;
  journeyTemplates: JourneyTemplateData[];
}) => {
  const navigate = useNavigate();
  const [noOfProjectsAfterSearch, setNoOfProjectsAfterSearch] = useState(0);
  const columnHelper = createColumnHelper<JourneyTemplateData>();
  // columns
  const columns: ColumnDef<JourneyTemplateData>[] = [
    {
      id: "name",
      cell: ({ row }) => {
        return row.original.name;
      },
      accessorFn: ({ name }) => name,
      header: () => <span>Name</span>,
    },
    // columnHelper.accessor("name", {
    //   cell: (info) => {
    //     return <div className="capitalize">{info.getValue()}</div>;
    //   },
    //   header: () => <span>Project Template</span>,

    // }),
    // ...(columnsVisible.customer
    //   ? [
    //       {
    //         id: "Customer",
    //         accessorFn: (row: JourneyData) =>
    //           `${row?.otherDetails?.customer?.name ?? ""}`,
    //       },
    //     ]
    //   : []),
    {
      id: "type",
      cell: ({ row }) => {
        return row.original.type; // capitalize
      },
      accessorFn: ({ type }) => type,
      header: () => <span>Type</span>,
    },
    {
      id: "createdBy",
      cell: ({ row }) => {
        return row.original.createdBy?.name;
      },
      accessorFn: ({ createdBy }) => createdBy?.name,
      header: () => <span>Created By</span>,
    },
    {
      id: "lastModifiedAt",
      cell: ({ row }) => {
        const date1 = row.original.lastModifiedAt;
        if (!date1) {
          return <></>;
        } else {
          const timeDifference = getTimeDifference(date1, new Date());
          return (
            <div>{`${timeDifference.absValue} ${timeDifference.unit} ago`}</div>
          );
        }
      },
      accessorFn: ({ lastModifiedAt }) => lastModifiedAt,
      header: () => <span>Last Modified</span>,
    },
    // columnHelper.accessor("type", {
    //   cell: (info) => {
    //     return <div className="capitalize">{info.getValue()}</div>;
    //   },
    //   header: () => <span>Type</span>,
    // }),
    // columnHelper.accessor("createdBy", {
    //   cell: (info) => {
    //     return <div className="capitalize">{info.getValue()?.name}</div>;
    //   },
    //   header: () => <span>Created By</span>,
    // }),
    // columnHelper.accessor("lastModifiedAt", {
    //   cell: (info) => {
    //     const date1 = info.getValue();
    //     if (!date1) {
    //       return <></>;
    //     } else {
    //       const timeDifference = getTimeDifference(date1, new Date());
    //       return (
    //         <div>{`${timeDifference.absValue} ${timeDifference.unit} ago`}</div>
    //       );
    //     }
    //   },
    //   header: () => <span>Last Edited</span>,
    // }),
  ];

  // useTable
  const table = useReactTable({
    data: journeyTemplates,
    columns,
    state: {
      globalFilter: search,
    },
    onGlobalFilterChange: setSearch,
    getCoreRowModel: getCoreRowModel(),
    globalFilterFn: fuzzyFilter,
    getFilteredRowModel: getFilteredRowModel(),
  });

  useEffect(() => {
    setNoOfProjectsAfterSearch(table.getRowModel().rows.length);
  }, [table.getRowModel().rows]);

  if (table.getRowModel().rows.length === 0) {
    return (
      <div className="flex">
        <NoProjectsMeetFilter />
      </div>
    );
  }

  return (
    <div className="flex-1 overflow-auto">
      <div className="max-h-full max-w-full overflow-auto">
        <table
          style={{ borderCollapse: "separate", borderSpacing: 0 }}
          className="min-w-[600px]"
        >
          <thead className="overflow-auto">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    scope="col"
                    className={
                      "sticky top-0 z-10 h-11 whitespace-nowrap border-b border-r border-gray-200/70 bg-gray-50 px-6 text-left text-xs font-normal uppercase tracking-wide text-gray-400 first:left-0 first:z-20 first:w-full first:pl-8"
                    }
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className="group relative rounded-lg hover:cursor-pointer hover:bg-gray-50"
                  onClick={() => {
                    navigate(`/templates/projects/${row.original._id}`);
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={
                        "whitespace-nowrap border-r border-b border-gray-100 px-6 py-3 text-sm text-gray-500 first:sticky first:left-0 first:z-10 first:bg-white first:pr-6 first:font-medium first:text-gray-900 first:group-hover:bg-gray-50 first:sm:pl-8"
                      }
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </>
          </tbody>
        </table>
      </div>
    </div>
  );
};

function NoProjectsMeetFilter() {
  return (
    <div className="mx-auto mt-[10vh]">
      No project templates meet the filter criteria
    </div>
  );
}
