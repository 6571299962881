import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAccessToken } from "src/api/useAccessToken";
import { useRealmApp } from "src/store/RealmApp";

export const dealKeys = {
  deals: (customerId: string) => ["deals", customerId] as const,
};

export type HubSpotDealData = {
  _id: string;
  name: string;
  source: "hubspot";
  crmProperties?: HubSpotCRMProperty[];
};

type HubSpotCRMProperty =
  | HubSpotBooleanCRMProperty
  | HubSpotDateCRMProperty
  | HubSpotNumberCRMProperty
  | HubSpotSelectCRMProperty
  | HubSpotCheckboxCRMProperty
  | HubSpotStringCRMProperty;

type HubSpotStringCRMProperty = {
  type: "string";
  value: string;
} & BaseHubSpotCRMProperty;

type HubSpotNumberCRMProperty = {
  type: "number";
  value: number;
} & BaseHubSpotCRMProperty;

type HubSpotBooleanCRMProperty = {
  type: "bool";
  value: boolean;
} & BaseHubSpotCRMProperty;

type HubSpotSelectCRMProperty = {
  type: "enumeration";
  fieldType: "select";
  value: string;
} & BaseHubSpotCRMProperty;

type HubSpotCheckboxCRMProperty = {
  type: "enumeration";
  fieldType: "checkbox";
  value: string[];
} & BaseHubSpotCRMProperty;

type HubSpotDateCRMProperty = {
  type: "datetime";
  value: string;
} & BaseHubSpotCRMProperty;

type BaseHubSpotCRMProperty = {
  id: string; // hubspot id
};

export const useGetDealsForCustomer = (customerId: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    dealKeys.deals(customerId),
    async (): Promise<HubSpotDealData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/dealsForCustomer`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            customerId,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};
