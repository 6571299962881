import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useRealmApp } from "src/store/RealmApp";
import { useAccessToken } from "../useAccessToken";

export const companyKeys = {
  companies: (pageNo: number, filter: string) =>
    ["companies", pageNo, filter] as const,
  company: (companyId: string) => ["company", companyId] as const,
};

export type CompanyData = BaseCompanyData & {
  noOfProjects: {
    inProgress: number;
    paused: number;
    completed: number;
    canceled: number;
  };
  projectLatestCreatedAt?: string;
};

type BaseCompanyData = {
  _id: string;
  name: string;
  source?: "hubspot" | "salesforce";
  crmId?: string;
  createdAt: string;
  updatedAt: string;
};

export type PaginatedCompaniesData = {
  companies: CompanyData[];
  totalNoOfPages: number;
  currentPageNo: number;
};

export const useGetCompanies = (pageNo: number, filter: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    companyKeys.companies(pageNo, filter),
    async (): Promise<PaginatedCompaniesData> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/companies`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            instanceId: app.currentUser.customData.instanceId.$oid,
            filter,
            pageNo,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: 1000 * 60 * 2,
    }
  );
};

export const useGetCompany = (_id: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    companyKeys.company(_id),
    async (): Promise<CompanyData> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/company`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            _id,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: 1000 * 60 * 2,
    }
  );
};
