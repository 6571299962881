import { PhaseData } from "src/api/Services/SharedSpace/phases";
import { JourneyContextProvider } from "src/pages/Services/Journeys/Journey/JourneyContextProvider";
import { Stages } from "src/pages/Services/Journeys/Journey/JourneyRun/Stages/Stages";

type Props = {
  phase: PhaseData;
};

export default function Journey({ phase }: Props) {
  return (
    <JourneyContextProvider>
      <Stages journeyId={phase.journey._id} />
    </JourneyContextProvider>
  );
}
