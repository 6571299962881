import { Dialog, Transition } from "@headlessui/react";
import { VariantProps, cva } from "class-variance-authority";
import { Fragment } from "react";

const modalStyles = cva(
  "transform rounded-lg bg-white text-left align-middle shadow-xl transition-all",
  {
    variants: {
      width: {
        fit: "",
        full: "w-[calc(100vw-36px)]",
      },
      padding: {
        large: "p-0",
        small: "p-4",
      },
    },
    defaultVariants: {
      width: "fit",
      padding: "large",
    },
  }
);

const modalHeightStyles = cva("h-fit overflow-visible", {
  variants: {
    height: {
      sm: "min-h-0",
      md: "min-h-[300px]",
      lg: "min-h-[400px]",
      xl: "min-h-[800px]",
      full: "h-[calc(100vh-36px)]",
    },
  },
  defaultVariants: {
    height: "sm",
  },
});

interface Props
  extends VariantProps<typeof modalStyles>,
    VariantProps<typeof modalHeightStyles> {
  isOpen: boolean;
  closeModal: () => void;
  children: React.ReactNode;
}

export const Modal = ({
  isOpen,
  closeModal,
  children,
  width,
  height,
  padding,
}: Props) => {
  return (
    <Transition.Root appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0">
          <div className="flex min-h-screen w-full items-center justify-center overflow-auto">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className={modalStyles({ width, padding })}>
                <div className={modalHeightStyles({ height })}>{children}</div>
                {/* <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Payment successful
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Your payment has been successfully submitted. We’ve sent
                      you an email with all of the details of your order.
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Got it, thanks!
                    </button>
                  </div> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
