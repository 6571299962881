import { createContext, useContext, useReducer } from "react";
import { HubSpotDealData } from "src/api/Deals/deals";
import { CustomerData } from "src/api/General/customers";
import { JourneyTemplateData } from "src/api/Services/Journeys/journeys";
import { InviteEmail } from "src/api/Services/Projects/projects";

export type InternalProjectTaskers =
  | {
      [key: string]: UserData | undefined;
    }
  | undefined;
export type ExternalProjectTaskers =
  | {
      [key: string]: string | undefined;
    }
  | undefined;

export type NewProjectExternalMember = {
  email: string;
  sendInviteEmail: boolean;
  // isNew: boolean;
  // isMember: boolean;
  membershipType: "member" | "subscriber";
};

type ProjectLaunchState = "details" | "members" | "launch";

export type CustomFieldLocalRecord =
  | {
      _id: string;
      id?: string;
      type: "text";
      name: string;
      value: string | null;
    }
  | {
      _id: string;
      id?: string;
      type: "number";
      name: string;
      value: number | null;
    }
  | {
      _id: string;
      id?: string;
      type: "date";
      name: string;
      value: string | null;
    }
  | {
      _id: string;
      id?: string;
      type: "select";
      name: string;
      value: string | null;
    }
  | {
      _id: string;
      id?: string;
      type: "multi-select";
      name: string;
      value: string[] | null;
    }
  | {
      _id: string;
      id?: string;
      type: "boolean";
      name: string;
      value: boolean | null;
    }
  | {
      _id: string;
      id?: string;
      type: "currency";
      name: string;
      value: number | null;
    };

type Action =
  | { type: "STEP_UPDATE"; step: number }
  | { type: "NEXT_STEP" }
  | { type: "PREVIOUS_STEP" }
  // | { type: "PROJECT_TYPE_UPDATE"; phaseType: "Customer Onboarding" }
  // | { type: "PROJECT_IS_ACTIVE_UPDATE"; isActive: boolean }
  | { type: "JOURNEY_TEMPLATE_UPDATE"; journeyTemplate: JourneyTemplateData }
  | { type: "CUSTOMER_UPDATE"; customer: Company }
  | { type: "INTERNAL_TASKERS_UPDATE"; internalTaskers: InternalProjectTaskers }
  | { type: "EXTERNAL_TASKERS_UPDATE"; externalTaskers: ExternalProjectTaskers }
  | { type: "INTERNAL_MEMBERS_UPDATE"; internalMembers: UserData[] }
  | {
      type: "EXTERNAL_MEMBERS_UPDATE";
      externalMembers: NewProjectExternalMember[];
    }
  | { type: "PROJECT_OWNER_UPDATE"; projectOwner: UserData }
  | { type: "PROJECT_NAME_UPDATE"; projectName: string }
  | { type: "SEND_INVITE_EMAILS_UPDATE"; sendInviteEmails: boolean }
  | { type: "INVITE_EMAIL_UPDATE"; inviteEmail: InviteEmail }
  | { type: "PROJECT_TYPE_UPDATE"; projectType: ProjectType }
  | { type: "PROJECT_START_UPDATE"; projectStart: ProjectStart }
  | {
      type: "PROJECT_LAUNCH_STATE_UPDATE";
      projectLaunchState: ProjectLaunchState;
    }
  // | { type: "STATUS_GROUP_ID_UPDATE"; statusGroupId: string }
  | {
      type: "CUSTOM_FIELDS_UPDATE";
      customFieldRecords: CustomFieldLocalRecord[];
    }
  | { type: "DEAL_UPDATE"; deal: HubSpotDealData | undefined }
  | { type: "SPACE_ID_UPDATE"; spaceId: string };

type Dispatch = (action: Action) => void;

export type UserData = {
  _id: string;
  name: string;
  isProjectOwner: boolean;
};

export type ProjectStart = "blank" | "template";

export type ProjectType = "internal" | "external";

type Company = {
  _id: string;
  name: string;
  source?: "hubspot" | "salesforce";
};

type State = {
  step: number;
  // phaseType: "Customer Onboarding" | undefined;
  // isActive: boolean;
  customer: Company | undefined;
  deal: HubSpotDealData | undefined;
  journeyTemplate: JourneyTemplateData | undefined;
  internalMembers: UserData[];
  externalMembers: NewProjectExternalMember[];
  internalTaskers: InternalProjectTaskers;
  externalTaskers: ExternalProjectTaskers;
  inviteEmail: InviteEmail;
  projectOwner: UserData;
  projectName: string;
  sendInviteEmails: boolean;
  projectStart: ProjectStart;
  projectType: ProjectType;
  projectLaunchState: ProjectLaunchState;
  // statusGroupId: string | undefined;
  spaceId: string;
  customFieldRecords: CustomFieldLocalRecord[];
};

const NewProjectContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function newProjectReducer(state: State, action: Action): State {
  switch (action.type) {
    case "STEP_UPDATE": {
      return { ...state, step: action.step };
    }
    case "CUSTOMER_UPDATE": {
      return { ...state, customer: action.customer };
    }
    // case "PROJECT_TYPE_UPDATE": {
    //   return { ...state, phaseType: action.phaseType };
    // }
    // case "PROJECT_IS_ACTIVE_UPDATE": {
    //   return { ...state, isActive: action.isActive };
    // }
    case "JOURNEY_TEMPLATE_UPDATE": {
      return { ...state, journeyTemplate: action.journeyTemplate };
    }
    case "INTERNAL_TASKERS_UPDATE": {
      return { ...state, internalTaskers: action.internalTaskers };
    }
    case "EXTERNAL_TASKERS_UPDATE": {
      return { ...state, externalTaskers: action.externalTaskers };
    }
    case "INTERNAL_MEMBERS_UPDATE": {
      return { ...state, internalMembers: action.internalMembers };
    }
    case "EXTERNAL_MEMBERS_UPDATE": {
      return { ...state, externalMembers: action.externalMembers };
    }
    case "PROJECT_OWNER_UPDATE": {
      return { ...state, projectOwner: action.projectOwner };
    }
    case "PROJECT_NAME_UPDATE": {
      return { ...state, projectName: action.projectName };
    }
    case "SEND_INVITE_EMAILS_UPDATE": {
      return { ...state, sendInviteEmails: action.sendInviteEmails };
    }
    case "INVITE_EMAIL_UPDATE": {
      return { ...state, inviteEmail: action.inviteEmail };
    }
    case "PROJECT_START_UPDATE": {
      return { ...state, projectStart: action.projectStart };
    }
    case "PROJECT_TYPE_UPDATE": {
      return { ...state, projectType: action.projectType };
    }
    case "PROJECT_LAUNCH_STATE_UPDATE": {
      return { ...state, projectLaunchState: action.projectLaunchState };
    }
    // case "STATUS_GROUP_ID_UPDATE": {
    //   return { ...state, statusGroupId: action.statusGroupId };
    // }
    case "SPACE_ID_UPDATE": {
      return { ...state, spaceId: action.spaceId };
    }
    case "CUSTOM_FIELDS_UPDATE": {
      return { ...state, customFieldRecords: action.customFieldRecords };
    }
    case "DEAL_UPDATE": {
      return { ...state, deal: action.deal };
    }
    case "NEXT_STEP": {
      switch (state.step) {
        case -1:
          if (state.projectType === "external") {
            return { ...state, step: 0 };
          } else {
            return { ...state, step: 1 };
          }
        case 0:
          return { ...state, step: 1 };
        case 1:
          return { ...state, step: 2 };
        case 2:
          if (state.projectStart === "blank") {
            return { ...state, step: 4 };
          } else {
            if (!!state.journeyTemplate?.customFields?.length) {
              return { ...state, step: 3 };
            } else {
              return { ...state, step: 4 };
            }
          }
        case 3:
          return { ...state, step: 4 };
        case 4:
          if (state.projectType === "internal") {
            if (state.projectStart === "blank") {
              return { ...state, step: 8 };
            } else {
              return { ...state, step: 6 };
            }
          } else {
            return { ...state, step: 5 };
          }
        case 5:
          const isSendingEmails1 = !!state.externalMembers.filter(
            (em) => em.sendInviteEmail
          ).length;
          if (state.projectStart === "blank") {
            if (isSendingEmails1) {
              return { ...state, step: 7 };
            } else {
              return { ...state, step: 8 };
            }
          } else {
            return { ...state, step: 6 };
          }
        case 6:
          const isSendingEmails2 = !!state.externalMembers.filter(
            (em) => em.sendInviteEmail
          ).length;
          if (isSendingEmails2) {
            return { ...state, step: 7 };
          } else {
            return { ...state, step: 8 };
          }
        case 7:
          return { ...state, step: 8 };
        default:
          return state;
      }
    }
    case "PREVIOUS_STEP": {
      switch (state.step) {
        case 0:
          return { ...state, step: -1 };
        case 1:
          if (state.projectType === "external") {
            return { ...state, step: 0 };
          } else {
            return { ...state, step: -1 };
          }
        case 2:
          return { ...state, step: 1 };
        case 3:
          return { ...state, step: 2 };
        case 4:
          if (
            state.projectStart === "template" &&
            !!state.journeyTemplate?.customFields?.length
          ) {
            return { ...state, step: 3 };
          } else {
            return { ...state, step: 2 };
          }
        case 5:
          return { ...state, step: 4 };
        case 6:
          if (state.projectType === "internal") {
            return { ...state, step: 4 };
          } else {
            return { ...state, step: 5 };
          }
        case 7:
          if (state.projectStart === "blank") {
            return { ...state, step: 5 };
          } else {
            return { ...state, step: 6 };
          }
        case 8:
          const isSendingEmails = !!state.externalMembers.filter(
            (em) => em.sendInviteEmail
          ).length;
          if (isSendingEmails) {
            return { ...state, step: 7 };
          } else {
            if (state.projectStart === "template") {
              return { ...state, step: 6 };
            } else {
              if (state.projectType === "external") {
                return { ...state, step: 5 };
              } else {
                return { ...state, step: 4 };
              }
            }
          }
        default:
          return state;
      }
    }
    default:
      return state;
  }
}

type Props = {
  children: React.ReactNode;
  launcher: UserData;
  spaceId: string;
  step?: number;
  customer?: Company;
  // phaseType: "Customer Onboarding";
  // isActive: boolean;
  // journeyTemplate?: JourneyTemplateData;
};

export const NewProjectContextProvider = ({
  children,
  launcher,
  spaceId,
  step,
  customer,
}: // phaseType,
// isActive,
// journeyTemplate,
Props) => {
  const [state, dispatch] = useReducer(newProjectReducer, {
    step: step ?? -1,
    // phaseType: "Customer Onboarding",
    // isActive: true,
    customer: customer,
    deal: undefined,
    internalMembers: [launcher],
    externalMembers: [],
    journeyTemplate: undefined,
    internalTaskers: undefined,
    externalTaskers: undefined,
    projectOwner: launcher,
    projectName: "",
    sendInviteEmails: true,
    inviteEmail: {
      subject: "",
      message: "",
      signature: "",
    },
    projectStart: "blank",
    projectType: "external",
    projectLaunchState: "details",
    // statusGroupId: undefined,
    customFieldRecords: [],
    spaceId: spaceId,
  });

  const value = { state, dispatch };

  return (
    <NewProjectContext.Provider value={value}>
      {children}
    </NewProjectContext.Provider>
  );
};

export const useNewProjectContext = () => {
  const context = useContext(NewProjectContext);
  if (context === undefined) {
    throw new Error(
      "useNewProjectContext must be used within a NewProjectContextProvider"
    );
  }
  return context;
};

// function getNextStep(currentStep: number): number {
//   switch (currentStep) {
//     case 0:
//       if (newProjectCtx.state.projectType === "internal") {
//         return 2;
//       } else {
//         return 1;
//       }
//     case 1:
//       return 2;
//     case 2:
//       if (newProjectCtx.state.projectStart === "blank") {
//         return 4;
//       } else {
//         return 3;
//       }
//     case 3:
//       return 5;
//     case 4:
//       return 5;
//     case 5:
//       if (newProjectCtx.state.projectType === "internal") {
//         if (newProjectCtx.state.projectStart === "blank") {
//           return 9;
//         } else {
//           return 7;
//         }
//       } else {
//         return 6;
//       }
//     case 6:
//       const isSendingEmails1 = !!newProjectCtx.state.externalMembers.filter(
//         (em) => em.sendInviteEmail
//       ).length;
//       if (newProjectCtx.state.projectStart === "blank") {
//         if (isSendingEmails1) {
//           return 8;
//         } else {
//           return 9;
//         }
//       } else {
//         return 7;
//       }
//     case 7:
//       const isSendingEmails2 = !!newProjectCtx.state.externalMembers.filter(
//         (em) => em.sendInviteEmail
//       ).length;
//       if (isSendingEmails2) {
//         return 8;
//       } else {
//         return 9;
//       }
//     case 8:
//       return 9;
//     default:
//       return currentStep;
//   }
// }

// function getPreviousStep(currentStep: number): number {
//   switch (currentStep) {
//     case 1:
//       return 0;
//     case 2:
//       if (newProjectCtx.state.projectType === "external") {
//         return 1;
//       } else {
//         return 0;
//       }
//     case 3:
//       return 2;
//     case 4:
//       return 2;
//     case 5:
//       if (newProjectCtx.state.projectStart === "blank") {
//         return 4;
//       } else {
//         return 3;
//       }
//     case 6:
//       return 5;
//     case 7:
//       if (newProjectCtx.state.projectType === "internal") {
//         return 5;
//       } else {
//         return 6;
//       }
//     case 8:
//       if (newProjectCtx.state.projectStart === "blank") {
//         return 6;
//       } else {
//         return 7;
//       }
//     case 9:
//       const isSendingEmails = newProjectCtx.state.externalMembers.filter(
//         (em) => em.sendInviteEmail
//       );
//       if (isSendingEmails) {
//         return 8;
//       } else {
//         if (newProjectCtx.state.projectStart === "template") {
//           return 7;
//         } else {
//           if (newProjectCtx.state.projectType === "external") {
//             return 6;
//           } else {
//             return 5;
//           }
//         }
//       }
//     default:
//       return currentStep;
//   }
// }
