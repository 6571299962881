import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { useRealmApp } from "src/store/RealmApp";
import { StatusData, statusGroupKeys } from "../General/status-groups";
import { useAccessToken } from "../useAccessToken";

export const spacesKeys = {
  spaces: () => ["spaces"] as const,
  space: (spaceId: string) => ["spaces", spaceId] as const,
  memberships: (spaceId: string) => ["spaces", spaceId, "memberships"] as const,
};

export type SpaceData = {
  _id: string;
  name: string;
  slug: string;
  color: string;
  instanceId: string;
  statusGroupId: string;
  createdBy?: {
    _id: string;
    name: string;
    email: string;
  };
  seroDefined?: boolean;
  members: {
    _id: string;
    role: "admin" | "member";
    name: string;
    email: string;
  };
  createdAt: string;
  updatedAt: string;
};

export const useGetSpaces = () => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    spacesKeys.spaces(),
    async (): Promise<SpaceData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/spaces`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            instanceId: app.currentUser.customData.instanceId.$oid,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};

export const useGetSpace = (_id: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    spacesKeys.space(_id),
    async (): Promise<SpaceData> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/space`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            _id,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};

export const useCreateSpace = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async (space: {
      name: string;
      slug: string;
      color: string;
      isPrivate: boolean;
      statusGroup: {
        name: string;
        description: "";
        statuses: StatusData[];
      };
      members: {
        _id: string;
        role: "admin" | "member";
      }[];
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/space`,
        {
          instanceId: app.currentUser.customData.instanceId.$oid,
          ...space,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error creating space", "error");
    },
    onSuccess: (data) => {
      navigate(`/spaces/${data.id}/tasks`);
      queryClient.invalidateQueries(spacesKeys.spaces());
      queryClient.invalidateQueries(
        statusGroupKeys.statusGroups(
          app.currentUser?.customData?.instanceId?.$oid
        )
      );
    },
  });
};

export const useUpdateSpace = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async (space: {
      _id: string;
      name?: string;
      color?: string;
    }): Promise<{ message: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/updateSpace`,
        {
          ...space,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error updating space", "error");
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["spaces"]);
    },
  });
};

export type SpaceMembershipData = {
  _id: string;
  user: {
    _id: string;
    name: string;
    email: string;
  };
  spaceId: string;
  instanceId: string;
  role: "admin" | "member";
  createdAt: string;
  updatedAt: string;
};

export const useGetSpaceMemberships = (spaceId: string) => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    spacesKeys.memberships(spaceId),
    async (): Promise<SpaceMembershipData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/spaceMemberships`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            spaceId,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: Infinity,
    }
  );
};

export const useDeleteSpace = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (space: {
      _id: string;
    }): Promise<{ message: string; id: string }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.delete(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/space`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          params: space,
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error deleting space", "error");
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["spaces"]);
      navigate(`/`);
      // if (data.id && variables.sharedSpaceId) {
      //   queryClient.invalidateQueries(
      //     phaseKeys.phases(variables.sharedSpaceId)
      //   );
      // }
      // snackbarCtx.showSnackbar("Successfully duplicated journey template");
    },
  });
};
