import {
  AdjustmentsHorizontalIcon,
  ChevronRightIcon,
  EllipsisVerticalIcon,
  EyeSlashIcon,
  FunnelIcon,
  ListBulletIcon,
  PlusIcon,
  ViewColumnsIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ObjectID } from "bson";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ParentStatus,
  StatusData,
  StatusGroupData,
} from "src/api/General/status-groups";
import {
  useAddStage,
  useGetJourneyStages,
  useUpdateJourneyStage,
} from "src/api/Services/Journeys/journeys";
import { PhaseData, useGetPhase } from "src/api/Services/SharedSpace/phases";
import {
  TaskData,
  useGetJourneyStageTasks,
  useGetPhaseTasks,
} from "src/api/Services/Tasks/tasks";
import { JourneyStageObj } from "src/components/Services/Journeys/Journey/types";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "src/components/ui/command";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
// import Tasker from "src/components/Services/Reusable/Tasks/Elements/Tasker";
import { Pencil2Icon } from "@radix-ui/react-icons";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { Button } from "src/components/ui/button";
import { Checkbox } from "src/components/ui/checkbox";
import { Count } from "src/components/ui/count";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { TextInput } from "src/components/ui/text-input";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "src/components/ui/tooltip";
import { useNewTaskForExternalUser } from "src/pages/Services/Tasks/NewTaskForExternalUser";
import { useNewTaskForInternalUser } from "src/pages/Services/Tasks/NewTaskForInternalUser";
import { TimeLeft } from "src/pages/Services/Tasks/Tables/TasksTable";
import { StatusSelect, getStatusSymbol } from "src/pages/Services/Tasks/Task";
import { useStatusGroups } from "src/pages/status-groups/status-groups-context-provider";
import { useRealmApp } from "src/store/RealmApp";
import { getIdentifier } from "src/utils/functions/tasks";
import { ProjectGroup, useJourney } from "../../JourneyContextProvider";
import { TaskCard } from "../Task/TaskCard";
import NewTaskButton from "./NewTaskButton";

export const Stages = ({ journeyId }: { journeyId: string }) => {
  const { phaseId } = useParams();
  // const { sharedSpace } = useGetSharedSpace(new ObjectID(sharedSpaceId));
  const { data: phase } = useGetPhase(String(phaseId));
  const { stages } = useGetJourneyStages(new ObjectID(journeyId));
  const journeyCtx = useJourney();
  const statusGroupsCtx = useStatusGroups();
  const statusGroup = statusGroupsCtx.state.statusGroups.find(
    (sg) => sg._id === phase?.statusGroup._id
  );
  const { data: tasks } = useGetPhaseTasks(String(phaseId));
  const [newStageName, setNewStageName] = useState("");
  const addStageMutation = useAddStage();
  const searchParams = new URLSearchParams(window.location.search);
  const newTaskForInternalUserCtx = useNewTaskForInternalUser();
  const newTaskForExternalUserCtx = useNewTaskForExternalUser();
  const responsibility = searchParams.get("responsibility");
  const [filterGroup, setFilterGroup] = useState<FilterGroup>(() => {
    const savedFilterGroup = localStorage.getItem(`filter_${phaseId}`);
    return savedFilterGroup
      ? JSON.parse(savedFilterGroup)
      : {
          logic: "AND",
          children: [],
        };
  });

  useEffect(() => {
    if (addStageMutation.isSuccess) {
      setNewStageName("");
    }
  }, [addStageMutation.isSuccess]);

  useEffect(() => {
    if (filterGroup.children.length === 0) {
      localStorage.removeItem(`filter_${phaseId}`);
    } else {
      localStorage.setItem(`filter_${phaseId}`, JSON.stringify(filterGroup));
    }
  }, [filterGroup]);

  useEffect(() => {
    if (responsibility === "my") {
      journeyCtx.dispatch({
        type: "VIEW_TEAM_UPDATE",
        responsibility: "my",
      });
      journeyCtx.dispatch({
        type: "VIEW_SHOW_ACTIVE_AND_INCOMPLETE_TASKS_UPDATE",
        showOnlyActiveAndIncompleteTasks: true,
      });
    }
    if (responsibility === "internal") {
      journeyCtx.dispatch({
        type: "VIEW_TEAM_UPDATE",
        responsibility: "internal",
      });
      journeyCtx.dispatch({
        type: "VIEW_SHOW_ACTIVE_AND_INCOMPLETE_TASKS_UPDATE",
        showOnlyActiveAndIncompleteTasks: true,
      });
    }
    if (responsibility === "external") {
      journeyCtx.dispatch({
        type: "VIEW_TEAM_UPDATE",
        responsibility: "external",
      });
      journeyCtx.dispatch({
        type: "VIEW_SHOW_ACTIVE_AND_INCOMPLETE_TASKS_UPDATE",
        showOnlyActiveAndIncompleteTasks: true,
      });
    }
    // if (overdueTasks) {
    //   journeyCtx.dispatch({
    //     type: "VIEW_TEAM_UPDATE",
    //     responsibility: "overdue",
    //   });
    // }
  }, []);

  const filteredTasks =
    tasks?.filter((t) => {
      if (filterGroup.logic === "AND") {
        for (let i = 0; i < filterGroup.children.length; i++) {
          const child = filterGroup.children[i] as Filter;
          if (child.field === "status") {
            if (child.value.length > 0) {
              if (!child.value.includes(t.customStatus)) {
                return false;
              }
            }
          } else if (child.field === "state" && child.value !== null) {
            if (child.value === "active") {
              if (!t.dueDate) {
                return false;
              }
            }
          } else if (child.field === "company" && child.value !== null) {
            if (child.value) {
              if (t.taskerType === "external") {
                return false;
              }
            } else {
              if (t.taskerType === "internal") {
                return false;
              }
            }
          }
        }
        return true;
      } else {
        for (let i = 0; i < filterGroup.children.length; i++) {
          const child = filterGroup.children[i] as Filter;
          if (child.field === "status") {
            if (child.value.length > 0) {
              if (child.value.includes(t.customStatus)) {
                return true;
              }
            }
          } else if (child.field === "state" && child.value !== null) {
            if (child.value === "active") {
              if (!!t.dueDate) {
                return true;
              }
            }
          } else if (child.field === "company" && child.value !== null) {
            if (child.value) {
              if (t.taskerType === "internal") {
                return true;
              }
            } else {
              if (t.taskerType === "external") {
                return true;
              }
            }
          }
        }
        return false;
      }
    }) ?? [];
  return (
    <>
      {!!tasks && !!phase && !!stages && statusGroup && (
        <div className="flex h-full flex-1 flex-col">
          {(tasks.length > 0 || stages.length > 1) && (
            <div
              className={clsx(
                "flex h-16 items-center gap-4 px-8",
                // journeyCtx.state.view.view === "list" &&
                "border-b border-gray-200/[.65]"
              )}
            >
              <div className="flex-1">
                <div className="flex items-end space-x-2">
                  {/* <div className="space-y-1">
                <h3 className="text-xs text-gray-400">View</h3>
                <ViewFilter />
              </div> */}

                  <Filter
                    filterGroup={filterGroup}
                    setFilterGroup={setFilterGroup}
                    // localStorageKey="project-filter"
                    filterModels={[
                      { field: "status", statuses: statusGroup.statuses },
                      // { field: "dueDate" },
                      { field: "state" },
                      ...(phase.collaborationType === "external"
                        ? [
                            {
                              field: "company" as "company",
                              internalCompany: phase.instance.name,
                              externalCompany: phase.customer?.name ?? "",
                            },
                          ]
                        : []),
                    ]}
                  />
                  <Display />
                  <div className="flex flex-1 items-end justify-end space-x-4">
                    {!journeyCtx.state.isExternal && (
                      <TasksAssignment phaseId={String(phaseId)} />
                    )}
                  </div>
                  <NewTaskButton phase={phase} journeyId={journeyId} />

                  {/* <TaskerFilter
                customerName={phase.customer?.name}
                instanceName={phase.instance?.name}
              />
              <TasksFilter /> */}
                  {/* <SubtasksFilter /> */}
                  {/* <button
                className={clsx(
                  "rounded-md border px-3 py-1 text-sm text-gray-600 hover:bg-gray-100"
                  // journeyCtx.state.view.showActiveAndIncompleteTasks
                  //   ? "border-primary-main bg-primary-100 text-primary-600"
                  //   : ""
                )}
                onClick={() => {
                  journeyCtx.dispatch({
                    type: "VIEW_SHOW_ACTIVE_AND_INCOMPLETE_TASKS_UPDATE",
                    showOnlyActiveAndIncompleteTasks:
                      !journeyCtx.state.view.showOnlyActiveAndIncompleteTasks,
                  });
                }}
              >
                {journeyCtx.state.view.showOnlyActiveAndIncompleteTasks
                  ? "View Inactive Tasks"
                  : "Hide Inactive Tasks"}
              </button> */}
                  {/* <DisplayOptions /> */}
                  {/* <CompletedFilter />
              <SubtasksFilter />
              <InactiveFilter /> */}
                  {/* <div className="space-y-1">
                <h3 className="text-xs text-gray-400">Tasker</h3>
                <TeamFilter
                  customerName={phase.customer?.name}
                  instanceName={phase.instance?.name}
                />
              </div> */}
                  {/* <div className="space-y-1">
                <h3 className="text-xs text-gray-400">Status</h3>
                <TaskStatusFilter />
              </div> */}
                  {/* <div className="h-2 w-2 rounded-full bg-gray-500"></div> */}
                  {/* <div className="space-y-1">
                <h3 className="text-xs text-gray-400">State</h3>
                <TaskStateFilter />
              </div> */}
                </div>
              </div>
            </div>
          )}
          {tasks.length > 0 || stages.length > 1 ? (
            <div className="flex-1 overflow-auto">
              {journeyCtx.state.view.view === "kanban" ? (
                <KanbanView
                  stages={stages}
                  journeyId={journeyId}
                  statusGroup={statusGroup}
                  tasks={filteredTasks}
                  phase={phase}
                />
              ) : (
                <ListView
                  tasks={filteredTasks}
                  stages={stages}
                  journeyId={journeyId}
                  statusGroup={statusGroup}
                />
              )}
            </div>
          ) : (
            <div className="mt-[15vh] flex items-center justify-center">
              <div className="flex w-[520px] max-w-md flex-col rounded-md border p-10 shadow-md">
                <div className="text-xl font-medium leading-none">
                  Get started
                </div>
                <div className="py-7">
                  <div className="text-left font-light leading-relaxed text-gray-500"></div>
                  <div className="text-left font-light leading-relaxed text-gray-500">
                    Begin by creating your first{" "}
                    <span className="font-normal text-gray-800">task</span>.
                    <div className="mt-3 max-w-[340px]">
                      If you have a want to structure tasks into separate
                      groups, you can create a{" "}
                      <span className="font-normal text-gray-800">stage</span>{" "}
                      first.
                    </div>
                    {/* Projects are made up of{" "}
                    <span className="font-normal text-gray-800">stages</span>{" "}
                    and stages are made up of{" "}
                    <span className="font-normal text-gray-800">tasks</span>. */}
                  </div>
                </div>

                <div className="flex space-x-2.5">
                  <Button
                    onClick={() => {
                      if (journeyCtx.state.isExternal) {
                        newTaskForExternalUserCtx.dispatch({
                          type: "open_update",
                          projectId: phase._id,
                        });
                      } else {
                        newTaskForInternalUserCtx.dispatch({
                          type: "open_update",
                          projectId: phase._id,
                        });
                      }
                    }}
                  >
                    New Task
                  </Button>
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button variant={"secondary"}>New Stage</Button>
                    </DialogTrigger>
                    {/* <ModalHeading title="New Task" /> */}
                    <DialogContent
                      className={clsx(
                        "top-[20%] w-full max-w-lg translate-y-0 data-[state=open]:slide-in-from-top-8"
                      )}
                    >
                      <DialogHeader>
                        <DialogTitle>New Stage</DialogTitle>
                      </DialogHeader>
                      <div className="grid gap-4 py-4">
                        <div className="space-y-1">
                          <InputHeading heading="Name" />
                          <TextInput
                            className="max-w-[300px]"
                            value={newStageName}
                            onChange={(e) => {
                              setNewStageName(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <DialogFooter>
                        <DialogClose asChild>
                          <Button
                            disabled={newStageName.trim() === ""}
                            onClick={() => {
                              addStageMutation.mutate({
                                params: {
                                  name: newStageName,
                                  journeyId,
                                },
                              });
                            }}
                          >
                            Create
                          </Button>
                        </DialogClose>
                      </DialogFooter>
                    </DialogContent>
                  </Dialog>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

type Logic = "AND" | "OR";

type FilterGroup = {
  logic: Logic;
  children: Filter[];
};

type IdComparator = "eq" | "in" | "notIn";

type DateComparator = "eq" | "gt" | "gte" | "lt" | "lte";

type StateComparator = "is";

// type Comparator = {
//   boolean: {
//     eq: boolean | null;
//   };
//   string: {
//     in?: string[];
//     notIn?: string[];
//     eq?: string | null;
//   };
//   number: {
//     eq?: number | null;
//     gt?: number | null;
//     gte?: number | null;
//     lt?: number | null;
//     lte?: number | null;
//     in?: number[];
//   };
//   date: {
//     eq?: string | null;
//     gt?: string | null;
//     gte?: string | null;
//     lt?: string | null;
//     lte?: string | null;
//   };
// };

type StatusFilter = {
  field: "status";
  comparator: IdComparator;
  value: string[];
};

type DueDateFilter = {
  field: "dueDate";
  comparator: DateComparator;
  value: string | null;
};

type StateFilter = {
  field: "state";
  comparator: StateComparator;
  value: string | null;
};

type CompanyFilter = {
  field: "company";
  value: boolean | null; // true = internal, false = external
};

type AssigneeFilter = {
  field: "assignee";
  comparator: IdComparator;
  value: string[];
};

type Filter =
  | StatusFilter
  | DueDateFilter
  | StateFilter
  | CompanyFilter
  | {
      field: undefined;
      comparator: null;
      value: null;
    }
  | AssigneeFilter;

function Filter({
  filterModels,
  filterGroup,
  setFilterGroup,
}: {
  filterModels: FilterModel[];
  filterGroup: FilterGroup;
  setFilterGroup: (filterGroup: FilterGroup) => void;
}) {
  const [open, setOpen] = useState(false);
  const journeyCtx = useJourney();
  // const { phaseId } = useParams();
  // const { sharedSpace } = useGetSharedSpace(new ObjectID(sharedSpaceId));
  // const { data: phase } = useGetPhase(String(phaseId));
  // get search params
  // const appliedFiltersCount =
  //   (journeyCtx.state.view.responsibility !== "all" ? 1 : 0) +
  //   (journeyCtx.state.view.showOnlyActiveAndIncompleteTasks ? 1 : 0);
  // const [customFilterOpen, setCustomFilterOpen] = useState(false);

  // const [filterGroup, setFilterGroup] = useState<FilterGroup>({
  //   logic: "AND",
  //   children: [
  //     {
  //       field: "",
  //       comparator: {
  //         string: {
  //           in: [""],
  //         },
  //       },
  //     },
  //   ],
  // });

  const [filterCount, setFilterCount] = useState(0);

  useEffect(() => {
    // count the number of valid filters
    let count = 0;
    for (let i = 0; i < filterGroup.children.length; i++) {
      const child = filterGroup.children[i] as Filter;
      if (child.field === "status") {
        if (child.value.length > 0) {
          count++;
        }
      } else if (child.field === "dueDate") {
        if (child.value) {
          count++;
        }
      } else if (child.field === "state") {
        if (child.value) {
          count++;
        }
      } else if (child.field === "company") {
        if (child.value !== null) {
          count++;
        }
      }
    }
    setFilterCount(count);
  }, [filterGroup]);

  useEffect(() => {
    if (!open) {
      // remove invalid filters
      setFilterGroup({
        ...filterGroup,
        children: filterGroup.children.filter((child) => {
          const currentChild = child;
          if (currentChild.field === "status") {
            return currentChild.value.length > 0;
          } else if (currentChild.field === "dueDate") {
            return !!currentChild.value;
          } else if (!currentChild.field) {
            return false;
          }
          return true;
        }),
      });
    }
  }, [open]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="secondary">
          <FunnelIcon className="mr-2 h-3.5 w-3.5 border-gray-300" />
          Filter
          {filterCount > 0 && (
            <div className="ml-2">
              <Count variant={"primaryLight"}>{filterCount}</Count>
            </div>
          )}
          {filterCount > 0 && (
            <button
              tabIndex={-1}
              className="ml-1.5 shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-600"
              onClick={(e) => {
                e.stopPropagation();
                setFilterGroup({
                  logic: "AND",
                  children: [
                    // {
                    //   field: undefined,
                    //   comparator: null,
                    //   value: null,
                    // },
                  ],
                });
              }}
            >
              <XMarkIcon className="h-2.5 w-2.5 " />
            </button>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="z-40 w-full min-w-[480px] space-y-3 p-5"
      >
        {/* <div>
          <div>Quick Filters</div>
        </div> */}
        {/* <div>
          Custom
        </div> */}
        <FilterGroup
          filterGroup={filterGroup}
          setFilterGroup={setFilterGroup}
          level={0}
          // phase={phase}
          filterModels={filterModels}
        />

        {/* {!!phase && (
          <TaskerFilter
            customerName={phase.customer?.name}
            instanceName={phase.instance?.name}
          />
        )}
        <TasksFilter /> */}
      </PopoverContent>
    </Popover>
  );
}

type FilterModel =
  | StatusFilterModel
  | DueDateFilterModel
  | StateModel
  | CompanyModel
  | AssigneeModel;

type StatusFilterModel = {
  field: "status";
  statuses: StatusData[];
};

type DueDateFilterModel = {
  field: "dueDate";
};

type StateModel = {
  field: "state";
};

type AssigneeModel = {
  field: "assignee";
};

type ProjectTemplateModel = {
  field: "projectTemplate";
};

type TaskerTemplateModel = {
  field: "taskerTemplate";
  taskers: {
    _id: string;
    name: string;
    email: string;
  }[];
};

type CompanyModel = {
  field: "company";
  internalCompany: string;
  externalCompany: string;
};

function FilterGroup({
  filterGroup,
  setFilterGroup,
  level,
  filterModels,
}: // phase,
{
  filterGroup: FilterGroup;
  setFilterGroup: (filterGroup: FilterGroup) => void;
  level: number;
  // phase: PhaseData;
  filterModels: FilterModel[];
}) {
  const handleLogicChange = (logic: Logic) => {
    setFilterGroup({ ...filterGroup, logic });
  };

  // const handleChildGroupChange = (index: number, childGroup: FilterGroup) => {
  //   const newChildren = [...filterGroup.children];
  //   if ("logic" in newChildren[index]) {
  //     newChildren[index] = childGroup;
  //   }
  //   setFilterGroup({ ...filterGroup, children: newChildren });
  // };

  const addFilter = (field: Filter["field"]) => {
    if (field === "status") {
      setFilterGroup({
        ...filterGroup,
        children: [
          ...filterGroup.children,
          {
            field: field,
            comparator: "in",
            value: [],
          },
        ],
      });
    } else if (field === "dueDate") {
      setFilterGroup({
        ...filterGroup,
        children: [
          ...filterGroup.children,
          {
            field: field,
            comparator: "lte",
            value: null,
          },
        ],
      });
    } else if (field === "state") {
      setFilterGroup({
        ...filterGroup,
        children: [
          ...filterGroup.children,
          {
            field: field,
            comparator: "is",
            value: null,
          },
        ],
      });
    } else if (field === "company") {
      setFilterGroup({
        ...filterGroup,
        children: [
          ...filterGroup.children,
          {
            field: field,
            value: null,
          },
        ],
      });
    } else if (field === "assignee") {
      setFilterGroup({
        ...filterGroup,
        children: [
          ...filterGroup.children,
          {
            field: field,
            comparator: "in",
            value: [],
          },
        ],
      });
    }
    // setFilterGroup({
    //   ...filterGroup,
    //   children: [
    //     ...filterGroup.children,
    //     {
    //       field: undefined,
    //       comparator: null,
    //       value: null,
    //     },
    //   ],
    // });
  };

  // const addFilterGroup = () => {
  //   setFilterGroup({
  //     ...filterGroup,
  //     children: [...filterGroup.children, { logic: "AND", children: [] }],
  //   });
  // };

  const [open, setOpen] = useState(filterGroup.children.length === 0);

  return (
    <div className={clsx(level > 0 && "rounded-md border p-4")}>
      <div>
        <div className="flex items-start space-x-3">
          <div className="space-y-0.5">
            <div className="inline-flex h-8 w-[52px] items-center justify-center text-sm text-gray-500">
              Where
            </div>
            {filterGroup.children.length > 1 && (
              <Select
                value={filterGroup.logic}
                onValueChange={(value) => {
                  handleLogicChange(value as Logic);
                }}
              >
                <SelectTrigger
                  className="w-[52px] justify-center bg-white"
                  showIcon={false}
                >
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="AND">AND</SelectItem>
                    <SelectItem value="OR">OR</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            )}
            {/* show uneditable and/or for each of the filters */}
            {Array.from({ length: filterGroup.children.length - 2 }).map(
              (_, index) => (
                <div
                  key={index}
                  className="flex h-8 w-[52px] items-center justify-center text-sm text-gray-400"
                >
                  {filterGroup.logic}
                </div>
              )
            )}
          </div>
          <div className="space-y-0.5">
            {filterGroup.children.map((child, index) =>
              "field" in child ? (
                <>
                  {child.field === "status" && (
                    <StatusField
                      filter={child}
                      index={index}
                      filterGroup={filterGroup}
                      setFilterGroup={setFilterGroup}
                      filterModels={filterModels}
                      // phase={phase}
                    />
                  )}
                  {child.field === "dueDate" && (
                    <DueDateField
                      filter={child}
                      index={index}
                      filterGroup={filterGroup}
                      setFilterGroup={setFilterGroup}
                    />
                  )}
                  {child.field === "state" && (
                    <StateField
                      filter={child}
                      index={index}
                      filterGroup={filterGroup}
                      setFilterGroup={setFilterGroup}
                    />
                  )}
                  {child.field === "company" && (
                    <CompanyField
                      filter={child}
                      index={index}
                      filterGroup={filterGroup}
                      setFilterGroup={setFilterGroup}
                      filterModels={filterModels}
                    />
                  )}
                  {/* <div className="flex items-start space-x-2">
                    <FilterField
                      filter={child}
                      index={index}
                      filterGroup={filterGroup}
                      setFilterGroup={setFilterGroup}
                      phase={phase}
                    />
                  </div> */}
                </>
              ) : (
                // <div key={index} style={{ marginLeft: "20px" }}>
                //   <label>
                //     Field:
                //     <input
                //       type="text"
                //       value={child.field}
                //       onChange={(e) => handleFieldChange(index, e.target.value)}
                //     />
                //   </label>
                //   <label>
                //     Comparator:
                //     <input
                //       type="text"
                //       value={JSON.stringify(child.comparator)}
                //       onChange={(e) =>
                //         handleComparatorChange(
                //           index,
                //           JSON.parse(e.target.value)
                //         )
                //       }
                //     />
                //   </label>
                //   <button onClick={() => removeChild(index)}>Remove</button>
                // </div>
                // <div key={index}>
                //   <FilterGroup
                //     filterGroup={child}
                //     setFilterGroup={(updatedChild: FilterGroup) =>
                //       handleChildGroupChange(index, updatedChild)
                //     }
                //     level={level + 1}
                //   />
                //   <button onClick={() => removeChild(index)}>
                //     Remove Group
                //   </button>
                // </div>
                <></>
              )
            )}
            <div className={clsx(filterGroup.children.length > 0 && "pt-2.5")}>
              <DropdownMenu
                open={open}
                onOpenChange={(isOpen) => {
                  setOpen(isOpen);
                }}
              >
                <DropdownMenuTrigger asChild>
                  <Button
                    variant={"secondary"}
                    className={clsx(
                      filterGroup.children.length === 0 &&
                        "font-normal text-gray-400/70"
                    )}
                  >
                    {filterGroup.children.length > 0
                      ? "Add Filter"
                      : "Select Filter"}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                  <DropdownMenuGroup>
                    {filterModels.map((filterModel, index) => {
                      switch (filterModel.field) {
                        case "status":
                          return (
                            <DropdownMenuItem
                              onSelect={() => {
                                addFilter("status");
                                setOpen(false);
                              }}
                            >
                              Status
                            </DropdownMenuItem>
                          );
                        case "dueDate":
                          return (
                            <DropdownMenuItem
                              onSelect={() => {
                                addFilter("dueDate");
                                setOpen(false);
                              }}
                            >
                              Due Date
                            </DropdownMenuItem>
                          );
                        case "state":
                          return (
                            <DropdownMenuItem
                              onSelect={() => {
                                addFilter("state");
                                setOpen(false);
                              }}
                            >
                              State
                            </DropdownMenuItem>
                          );
                        case "company":
                          return (
                            <DropdownMenuItem
                              onSelect={() => {
                                addFilter("company");
                                setOpen(false);
                              }}
                            >
                              Company
                            </DropdownMenuItem>
                          );
                        default:
                          return null;
                      }
                    })}
                    {/* <DropdownMenuItem
                      onSelect={() => {
                        addFilter("status");
                        setOpen(false);
                      }}
                    >
                      Status
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onSelect={() => {
                        addFilter("dueDate");
                        setOpen(false);
                      }}
                    >
                      Due Date
                    </DropdownMenuItem> */}
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            {/* <Select
              value={undefined}
              onValueChange={(value) => {
                addFilter(value as Filter["field"]);
              }}
              open={open}
              onOpenChange={(isOpen) => {
                setOpen(isOpen);
              }}
            >
              <SelectTrigger
                className={clsx(
                  // "flex h-8 w-32 items-center bg-white px-3",
                  "w-fit"
                )}
                showIcon={false}
              >
                {"Add Filter"}
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="status">Status</SelectItem>
                  <SelectItem value="dueDate">Due Date</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select> */}
            {/* <Button variant={"secondary"} onClick={addFilter}>
              
            </Button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

{
  /* <div className="flex items-center space-x-3 text-sm">
          <div className="w-12 text-right text-gray-500">Where</div>
          <div className="flex items-center space-x-0 text-sm">
            <Select>
              <SelectTrigger
                className="flex h-7 w-fit items-center rounded-r-none border px-2"
                showIcon={false}
              >
                Status
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="status">Status</SelectItem>
                  <SelectItem value="company">Team</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
            <div className="flex h-7 w-fit items-center border-y px-2">is</div>
            <div className="flex h-7 w-fit items-center rounded-r-md border px-2">
              is
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-3 text-sm">
          <div className="w-12 text-right text-gray-500">And</div>
          <div className="flex items-center space-x-1.5 text-sm">
            <div className="flex h-7 w-fit items-center rounded-md border px-2">
              Tasker
            </div>
            <div className="flex h-7 w-fit items-center rounded-md border px-2">
              is not
            </div>
            <div className="flex h-7 w-fit items-center rounded-md border px-2">
              is
            </div>
          </div>
        </div> */
}

// function FilterField({
//   filter,
//   index,
//   filterGroup,
//   setFilterGroup,
//   phase,
// }: {
//   filter: Filter;
//   index: number;
//   filterGroup: FilterGroup;
//   setFilterGroup: (filterGroup: FilterGroup) => void;
//   phase: PhaseData;
// }) {
//   const [open, setOpen] = useState(!filter.field);
//   const [statusOpen, setStatusOpen] = useState(false);
//   const type = filter.field;
//   const statusGroupsCtx = useStatusGroups();
//   const handleFieldChange = (index: number, field: Filter["field"]) => {
//     const currentChild = filterGroup.children[index] as Filter;
//     if (field === currentChild.field) {
//       return;
//     }
//     const newChildren = [...filterGroup.children];
//     // replace the index with new field
//     if (field === "status") {
//       newChildren[index] = {
//         field: field,
//         value: [],
//         comparator: "in",
//       };
//     } else if (field === "dueDate") {
//       newChildren[index] = {
//         field: field,
//         value: null,
//         comparator: "lte",
//       };
//     }
//     setFilterGroup({ ...filterGroup, children: newChildren });
//   };

//   const removeChild = (index: number) => {
//     const newChildren = filterGroup.children.filter((_, i) => i !== index);
//     setFilterGroup({ ...filterGroup, children: newChildren });
//   };

//   const handleComparatorChange = (
//     index: number,
//     // field: Filter["field"],
//     comparator: IdComparator | DateComparator
//   ) => {
//     const newChildren = [...filterGroup.children];
//     let currentChild = newChildren[index] as Filter;

//     if (currentChild.field === "status") {
//       const statusComparator = comparator as IdComparator;
//       currentChild = {
//         ...currentChild,
//         comparator: statusComparator,
//       };
//     } else if (currentChild.field === "dueDate") {
//       const dateComparator = comparator as DateComparator;
//       currentChild = {
//         ...currentChild,
//         comparator: dateComparator,
//       };
//     }
//     newChildren[index] = currentChild;
//     setFilterGroup({ ...filterGroup, children: newChildren });
//   };

//   const handleValueChange = (
//     value: string | number | boolean | null | string[]
//   ) => {
//     if (type === "status") {
//       const newChildren = [...filterGroup.children];
//       const currentChild = newChildren[index] as Filter;
//       if (currentChild.field === "status") {
//         newChildren[index] = {
//           ...currentChild,
//           value: value as string[],
//         };
//       }
//       setFilterGroup({ ...filterGroup, children: newChildren });
//     }
//   };

//   const statusGroup = statusGroupsCtx.state.statusGroups.find(
//     (sg) => sg._id === phase?.statusGroup._id
//   );

//   const statuses = {
//     Backlog:
//       statusGroup?.statuses
//         .filter((s) => s.parentStatus === "Backlog")
//         .map((s) => s.name) ?? [],
//     "To-do":
//       statusGroup?.statuses
//         .filter((s) => s.parentStatus === "To-do")
//         .map((s) => s.name) ?? [],
//     "In Progress":
//       statusGroup?.statuses
//         .filter((s) => s.parentStatus === "In Progress")
//         .map((s) => s.name) ?? [],
//     Stuck:
//       statusGroup?.statuses
//         .filter((s) => s.parentStatus === "Stuck")
//         .map((s) => s.name) ?? [],
//     Completed:
//       statusGroup?.statuses
//         .filter((s) => s.parentStatus === "Completed")
//         .map((s) => s.name) ?? [],
//     Canceled:
//       statusGroup?.statuses
//         .filter((s) => s.parentStatus === "Canceled")
//         .map((s) => s.name) ?? [],
//   };

//   return (
//     <div className="flex items-center space-x-0 text-sm">
//       <Select
//         value={filter.field}
//         onValueChange={(value) => {
//           handleFieldChange(index, value as Filter["field"]);
//           if (value === "status") {
//             setStatusOpen(true);
//           }
//         }}
//         open={open}
//         onOpenChange={(isOpen) => {
//           setOpen(isOpen);
//         }}
//       >
//         <SelectTrigger
//           className={clsx(
//             "flex h-8 w-32 items-center truncate border bg-white px-3",
//             !!filter.field ? "rounded-r-none" : ""
//           )}
//           showIcon={false}
//         >
//           {type === "status" ? "Status" : type === "dueDate" ? "Due Date" : ""}
//         </SelectTrigger>
//         <SelectContent>
//           <SelectGroup>
//             <SelectItem value="status">Status</SelectItem>
//             <SelectItem value="dueDate">Due Date</SelectItem>
//           </SelectGroup>
//         </SelectContent>
//       </Select>

//       {filter.field === "status" ? (
//         <Select
//           value={filter.comparator}
//           onValueChange={(value) => {
//             handleComparatorChange(index, value as IdComparator);
//           }}
//         >
//           <SelectTrigger
//             className="flex h-8 w-20 items-center truncate rounded-none border-y border-x-0 bg-white px-3"
//             showIcon={false}
//           >
//             {filter.comparator === "in"
//               ? "is"
//               : filter.comparator === "notIn"
//               ? "is not"
//               : ""}
//           </SelectTrigger>
//           <SelectContent>
//             <SelectGroup>
//               <SelectItem value="in">is</SelectItem>
//               <SelectItem value="notIn">is not</SelectItem>
//             </SelectGroup>
//           </SelectContent>
//         </Select>
//       ) : filter.field === "dueDate" ? (
//         <Select
//           value={filter.comparator}
//           onValueChange={(value) => {
//             handleComparatorChange(index, value as DateComparator);
//           }}
//         >
//           <SelectTrigger
//             className="flex h-8 w-20 items-center truncate rounded-none border-y border-x-0 bg-white px-3"
//             showIcon={false}
//           >
//             {filter.comparator === "lte"
//               ? "is before"
//               : filter.comparator === "gte"
//               ? "is after"
//               : "s"}
//           </SelectTrigger>
//           <SelectContent>
//             <SelectGroup>
//               <SelectItem value="lte">is before</SelectItem>
//               <SelectItem value="gte">is after</SelectItem>
//             </SelectGroup>
//           </SelectContent>
//         </Select>
//       ) : (
//         <></>
//       )}
//       {filter.field === "status" ? (
//         <Popover modal={true} open={statusOpen} onOpenChange={setStatusOpen}>
//           <PopoverTrigger className="h-8 border-y border-l bg-white px-3 text-sm hover:bg-gray-50 focus:outline-none">
//             {!!filter.value && filter.value.length > 0 ? (
//               <div className="truncate">
//                 {filter.value.length > 1 ? "Multiple" : filter.value[0]}
//               </div>
//             ) : (
//               <div className="text-sm font-normal text-gray-300">
//                 Select Status
//               </div>
//             )}
//           </PopoverTrigger>
//           <PopoverContent
//             className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
//             align="start"
//           >
//             <Command>
//               <CommandInput placeholder="Search status..." className="h-9" />
//               <CommandEmpty>No status found.</CommandEmpty>
//               <CommandGroup>
//                                 {statuses["Backlog"]?.map((customStatus) => (
//                   <StatusCommandItem
//                     parentStatus={"Backlog"}
//                     key={customStatus}
//                     customStatus={customStatus}
//                     checked={filter.value.includes(customStatus)}
//                     onSelect={(customStatus) => {
//                       if (filter.value.includes(customStatus)) {
//                         handleValueChange(
//                           filter.value.filter((v) => v !== customStatus)
//                         );
//                       } else {
//                         handleValueChange([...filter.value, customStatus]);
//                       }
//                     }}
//                   />
//                 ))}
//                 {statuses["To-do"]?.map((customStatus) => (
//                   <StatusCommandItem
//                     parentStatus={"To-do"}
//                     key={customStatus}
//                     customStatus={customStatus}
//                     checked={filter.value.includes(customStatus)}
//                     onSelect={(customStatus) => {
//                       if (filter.value.includes(customStatus)) {
//                         handleValueChange(
//                           filter.value.filter((v) => v !== customStatus)
//                         );
//                       } else {
//                         handleValueChange([...filter.value, customStatus]);
//                       }
//                     }}
//                   />
//                 ))}
//                 {statuses["In Progress"]?.map((customStatus) => (
//                   <StatusCommandItem
//                     parentStatus={"In Progress"}
//                     key={customStatus}
//                     customStatus={customStatus}
//                     checked={filter.value.includes(customStatus)}
//                     onSelect={(customStatus) => {
//                       if (filter.value.includes(customStatus)) {
//                         handleValueChange(
//                           filter.value.filter((v) => v !== customStatus)
//                         );
//                       } else {
//                         handleValueChange([...filter.value, customStatus]);
//                       }
//                     }}
//                   />
//                 ))}
//                 {statuses["Stuck"]?.map((customStatus) => (
//                   <StatusCommandItem
//                     parentStatus={"Stuck"}
//                     key={customStatus}
//                     customStatus={customStatus}
//                     checked={filter.value.includes(customStatus)}
//                     onSelect={(customStatus) => {
//                       if (filter.value.includes(customStatus)) {
//                         handleValueChange(
//                           filter.value.filter((v) => v !== customStatus)
//                         );
//                       } else {
//                         handleValueChange([...filter.value, customStatus]);
//                       }
//                     }}
//                   />
//                 ))}
//                 {statuses["Completed"]?.map((customStatus) => (
//                   <StatusCommandItem
//                     parentStatus={"Completed"}
//                     key={customStatus}
//                     customStatus={customStatus}
//                     checked={filter.value.includes(customStatus)}
//                     onSelect={(customStatus) => {
//                       if (filter.value.includes(customStatus)) {
//                         handleValueChange(
//                           filter.value.filter((v) => v !== customStatus)
//                         );
//                       } else {
//                         handleValueChange([...filter.value, customStatus]);
//                       }
//                     }}
//                   />
//                 ))}
//                 {statuses["Canceled"]?.map((customStatus) => (
//                   <StatusCommandItem
//                     parentStatus={"Canceled"}
//                     key={customStatus}
//                     customStatus={customStatus}
//                     checked={filter.value.includes(customStatus)}
//                     onSelect={(customStatus) => {
//                       if (filter.value.includes(customStatus)) {
//                         handleValueChange(
//                           filter.value.filter((v) => v !== customStatus)
//                         );
//                       } else {
//                         handleValueChange([...filter.value, customStatus]);
//                       }
//                     }}
//                   />
//                 ))}
//               </CommandGroup>
//             </Command>
//           </PopoverContent>
//         </Popover>
//       ) : (
//         <></>
//       )}

//       {filter.field === "status" && (
//         <div
//           className="flex h-8 w-fit cursor-pointer items-center rounded-r-md border bg-white px-1.5 transition-colors hover:bg-gray-100"
//           onClick={() => {
//             removeChild(index);
//           }}
//         >
//           <XMarkIcon className="h-2.5 w-2.5 shrink-0" />
//         </div>
//       )}
//     </div>
//   );
// }

function CompanyField({
  filter,
  index,
  filterGroup,
  setFilterGroup,
  filterModels,
}: {
  filter: CompanyFilter;
  index: number;
  filterGroup: FilterGroup;
  setFilterGroup: (filterGroup: FilterGroup) => void;
  filterModels: FilterModel[];
}) {
  const [open, setOpen] = useState(filter.value === null);
  const companyFilterModel = filterModels.find(
    (fm) => fm.field === "company"
  ) as CompanyModel | undefined;
  const internalCompany = companyFilterModel?.internalCompany;
  const externalCompany = companyFilterModel?.externalCompany;

  const handleValueChange = (value: boolean) => {
    const newChildren = [...filterGroup.children];
    const currentChild = newChildren[index];
    if (currentChild.field === "company") {
      newChildren[index] = {
        ...currentChild,
        value: value,
      };
    }
    setFilterGroup({ ...filterGroup, children: newChildren });
  };
  const removeChild = () => {
    const newChildren = filterGroup.children.filter((_, i) => i !== index);
    setFilterGroup({ ...filterGroup, children: newChildren });
  };

  return (
    <div className="flex items-center space-x-0 text-sm">
      <div className="flex h-8 w-32 items-center truncate rounded-l-md border px-3 text-sm">
        Company
      </div>
      <div className="flex h-8 w-20 items-center truncate border-y border-input px-3 text-sm">
        is
      </div>
      <Select
        value={filter.value === null ? "" : filter.value ? "true" : "false"}
        onValueChange={(value) => {
          handleValueChange(value === "true");
        }}
        open={open}
        onOpenChange={setOpen}
      >
        <SelectTrigger
          className="flex h-8 w-40 items-center truncate rounded-none border-y border-l border-r-0 border-input bg-white px-3"
          showIcon={false}
        >
          {filter.value === null ? (
            <span className="text-gray-400/70">Select State</span>
          ) : !!filter.value ? (
            <div className="truncate">{internalCompany}</div>
          ) : (
            <div className="truncate">{externalCompany}</div>
          )}
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="true">{internalCompany}</SelectItem>
            <SelectItem value="false">{externalCompany}</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
      <div
        className="flex h-8 w-fit cursor-pointer items-center rounded-r-md border bg-white px-2 transition-colors hover:bg-gray-100"
        onClick={() => {
          removeChild();
        }}
      >
        <XMarkIcon className="h-2.5 w-2.5 shrink-0" />
      </div>
    </div>
  );
}

function StateField({
  filter,
  index,
  filterGroup,
  setFilterGroup,
}: {
  filter: StateFilter;
  index: number;
  filterGroup: FilterGroup;
  setFilterGroup: (filterGroup: FilterGroup) => void;
}) {
  const [open, setOpen] = useState(!filter.value);
  const handleValueChange = (value: string) => {
    const newChildren = [...filterGroup.children];
    const currentChild = newChildren[index] as Filter;
    if (currentChild.field === "state") {
      newChildren[index] = {
        ...currentChild,
        value: value,
      };
    }
    setFilterGroup({ ...filterGroup, children: newChildren });
  };
  const removeChild = () => {
    const newChildren = filterGroup.children.filter((_, i) => i !== index);
    setFilterGroup({ ...filterGroup, children: newChildren });
  };
  return (
    <div className="flex items-center space-x-0 text-sm">
      <div className="flex h-8 w-32 items-center truncate rounded-l-md border px-3 text-sm">
        State
      </div>
      <div className="flex h-8 w-20 items-center truncate border-y border-input px-3 text-sm">
        is
      </div>
      <Select
        value={filter.value ?? ""}
        onValueChange={(value) => {
          handleValueChange(value);
        }}
        open={open}
        onOpenChange={setOpen}
      >
        <SelectTrigger
          className="flex h-8 w-40 items-center truncate rounded-none border-y border-l border-r-0 border-input bg-white px-3"
          showIcon={false}
        >
          {filter.value === "active" ? (
            "active"
          ) : filter.value === "inactive" ? (
            "active"
          ) : (
            <span className="text-gray-400/70">Select State</span>
          )}
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="active">active</SelectItem>
            <SelectItem value="inactive">inactive</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
      <div
        className="flex h-8 w-fit cursor-pointer items-center rounded-r-md border bg-white px-2 transition-colors hover:bg-gray-100"
        onClick={() => {
          removeChild();
        }}
      >
        <XMarkIcon className="h-2.5 w-2.5 shrink-0" />
      </div>
    </div>
  );
}

function StatusField({
  filter,
  index,
  filterGroup,
  setFilterGroup,
  filterModels,
}: {
  filter: StatusFilter;
  index: number;
  filterGroup: FilterGroup;
  setFilterGroup: (filterGroup: FilterGroup) => void;
  filterModels: FilterModel[];
}) {
  const [open, setOpen] = useState(!filter.field);
  const [valueOpen, setValueOpen] = useState(!filter.value?.length);
  const statusFilterModel = filterModels.find((fm) => fm.field === "status");
  if (!statusFilterModel) return null;
  const statuses = (statusFilterModel as StatusFilterModel).statuses;
  const type = filter.field;

  const handleFieldChange = (index: number, field: Filter["field"]) => {
    const currentChild = filterGroup.children[index] as Filter;
    if (field === currentChild.field) {
      return;
    }
    const newChildren = [...filterGroup.children];
    // replace the index with new field
    if (field === "status") {
      newChildren[index] = {
        field: field,
        value: [],
        comparator: "in",
      };
    } else if (field === "dueDate") {
      newChildren[index] = {
        field: field,
        value: null,
        comparator: "lte",
      };
    }
    setFilterGroup({ ...filterGroup, children: newChildren });
  };

  const removeChild = (index: number) => {
    const newChildren = filterGroup.children.filter((_, i) => i !== index);
    setFilterGroup({ ...filterGroup, children: newChildren });
  };

  const handleComparatorChange = (comparator: IdComparator) => {
    const newChildren = [...filterGroup.children];
    let currentChild = newChildren[index] as Filter;

    if (currentChild.field === "status") {
      const statusComparator = comparator as IdComparator;
      currentChild = {
        ...currentChild,
        comparator: statusComparator,
      };
    }
    newChildren[index] = currentChild;
    setFilterGroup({ ...filterGroup, children: newChildren });
  };

  const handleValueChange = (
    value: string | number | boolean | null | string[]
  ) => {
    if (type === "status") {
      const newChildren = [...filterGroup.children];
      const currentChild = newChildren[index] as Filter;
      if (currentChild.field === "status") {
        newChildren[index] = {
          ...currentChild,
          value: value as string[],
        };
      }
      setFilterGroup({ ...filterGroup, children: newChildren });
    }
  };

  const transforemdStatuses = {
    Backlog:
      statuses.filter((s) => s.parentStatus === "Backlog").map((s) => s.name) ??
      [],
    "To-do":
      statuses.filter((s) => s.parentStatus === "To-do").map((s) => s.name) ??
      [],
    "In Progress":
      statuses
        .filter((s) => s.parentStatus === "In Progress")
        .map((s) => s.name) ?? [],
    Stuck:
      statuses.filter((s) => s.parentStatus === "Stuck").map((s) => s.name) ??
      [],
    Completed:
      statuses
        .filter((s) => s.parentStatus === "Completed")
        .map((s) => s.name) ?? [],
    Canceled:
      statuses
        .filter((s) => s.parentStatus === "Canceled")
        .map((s) => s.name) ?? [],
  };

  return (
    <div className="flex items-center space-x-0 text-sm">
      {/* <Select
        value={filter.field}
        onValueChange={(value) => {
          handleFieldChange(index, value as Filter["field"]);
          if (value === "status") {
            setValueOpen(true);
          }
        }}
        open={open}
        onOpenChange={(isOpen) => {
          setOpen(isOpen);
        }}
      >
        <SelectTrigger
          className={clsx(
            "flex h-8 w-32 items-center truncate border bg-white px-3",
            !!filter.field ? "rounded-r-none" : ""
          )}
          showIcon={false}
        >
          {type === "status" ? "Status" : type === "dueDate" ? "Due Date" : ""}
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="status">Status</SelectItem>
            <SelectItem value="dueDate">Due Date</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select> */}
      <div className="flex h-8 w-32 items-center truncate rounded-l-md border px-3 text-sm">
        Status
      </div>
      <Select
        value={filter.comparator}
        onValueChange={(value) => {
          handleComparatorChange(value as IdComparator);
        }}
      >
        <SelectTrigger
          className="flex h-8 w-20 items-center truncate rounded-none border-y border-x-0 border-input bg-white px-3"
          showIcon={false}
        >
          {filter.comparator === "in"
            ? "is"
            : filter.comparator === "notIn"
            ? "is not"
            : ""}
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="in">is</SelectItem>
            <SelectItem value="notIn">is not</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>

      <Popover modal={true} open={valueOpen} onOpenChange={setValueOpen}>
        <PopoverTrigger className="flex h-8 w-40 items-center justify-start border-y border-l bg-white px-3 text-sm hover:bg-gray-50 focus:outline-none">
          {!!filter.value && filter.value.length > 0 ? (
            <div className="truncate">
              {filter.value.length > 1 ? "Multiple" : filter.value[0]}
            </div>
          ) : (
            <div className="text-sm font-normal text-gray-300">
              Select Status
            </div>
          )}
        </PopoverTrigger>
        <PopoverContent
          className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
          align="start"
        >
          <Command>
            <CommandInput placeholder="Search status..." className="h-9" />
            <CommandEmpty>No status found.</CommandEmpty>
            <CommandGroup>
              {transforemdStatuses["Backlog"]?.map((customStatus) => (
                <StatusCommandItem
                  parentStatus={"Backlog"}
                  key={customStatus}
                  customStatus={customStatus}
                  checked={filter.value.includes(customStatus)}
                  onSelect={(customStatus) => {
                    if (filter.value.includes(customStatus)) {
                      handleValueChange(
                        filter.value.filter((v) => v !== customStatus)
                      );
                    } else {
                      handleValueChange([...filter.value, customStatus]);
                    }
                  }}
                />
              ))}
              {transforemdStatuses["To-do"]?.map((customStatus) => (
                <StatusCommandItem
                  parentStatus={"To-do"}
                  key={customStatus}
                  customStatus={customStatus}
                  checked={filter.value.includes(customStatus)}
                  onSelect={(customStatus) => {
                    if (filter.value.includes(customStatus)) {
                      handleValueChange(
                        filter.value.filter((v) => v !== customStatus)
                      );
                    } else {
                      handleValueChange([...filter.value, customStatus]);
                    }
                  }}
                />
              ))}
              {transforemdStatuses["In Progress"]?.map((customStatus) => (
                <StatusCommandItem
                  parentStatus={"In Progress"}
                  key={customStatus}
                  customStatus={customStatus}
                  checked={filter.value.includes(customStatus)}
                  onSelect={(customStatus) => {
                    if (filter.value.includes(customStatus)) {
                      handleValueChange(
                        filter.value.filter((v) => v !== customStatus)
                      );
                    } else {
                      handleValueChange([...filter.value, customStatus]);
                    }
                  }}
                />
              ))}
              {transforemdStatuses["Stuck"]?.map((customStatus) => (
                <StatusCommandItem
                  parentStatus={"Stuck"}
                  key={customStatus}
                  customStatus={customStatus}
                  checked={filter.value.includes(customStatus)}
                  onSelect={(customStatus) => {
                    if (filter.value.includes(customStatus)) {
                      handleValueChange(
                        filter.value.filter((v) => v !== customStatus)
                      );
                    } else {
                      handleValueChange([...filter.value, customStatus]);
                    }
                  }}
                />
              ))}
              {transforemdStatuses["Completed"]?.map((customStatus) => (
                <StatusCommandItem
                  parentStatus={"Completed"}
                  key={customStatus}
                  customStatus={customStatus}
                  checked={filter.value.includes(customStatus)}
                  onSelect={(customStatus) => {
                    if (filter.value.includes(customStatus)) {
                      handleValueChange(
                        filter.value.filter((v) => v !== customStatus)
                      );
                    } else {
                      handleValueChange([...filter.value, customStatus]);
                    }
                  }}
                />
              ))}
              {transforemdStatuses["Canceled"]?.map((customStatus) => (
                <StatusCommandItem
                  parentStatus={"Canceled"}
                  key={customStatus}
                  customStatus={customStatus}
                  checked={filter.value.includes(customStatus)}
                  onSelect={(customStatus) => {
                    if (filter.value.includes(customStatus)) {
                      handleValueChange(
                        filter.value.filter((v) => v !== customStatus)
                      );
                    } else {
                      handleValueChange([...filter.value, customStatus]);
                    }
                  }}
                />
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>

      <div
        className="flex h-8 w-fit cursor-pointer items-center rounded-r-md border bg-white px-2 transition-colors hover:bg-gray-100"
        onClick={() => {
          removeChild(index);
        }}
      >
        <XMarkIcon className="h-2.5 w-2.5 shrink-0" />
      </div>
    </div>
  );
}

function DueDateField({
  filter,
  index,
  filterGroup,
  setFilterGroup,
}: {
  filter: DueDateFilter;
  index: number;
  filterGroup: FilterGroup;
  setFilterGroup: (filterGroup: FilterGroup) => void;
}) {
  const [open, setOpen] = useState(!filter.field);
  const [valueOpen, setValueOpen] = useState(!filter.value?.length);
  const statusGroupsCtx = useStatusGroups();
  const handleFieldChange = (field: Filter["field"]) => {
    const currentChild = filterGroup.children[index] as Filter;
    if (field === currentChild.field) {
      return;
    }
    const newChildren = [...filterGroup.children];
    // replace the index with new field
    if (field === "status") {
      newChildren[index] = {
        field: field,
        value: [],
        comparator: "in",
      };
    } else if (field === "dueDate") {
      newChildren[index] = {
        field: field,
        value: null,
        comparator: "lte",
      };
    }
    setFilterGroup({ ...filterGroup, children: newChildren });
  };

  const removeChild = (index: number) => {
    const newChildren = filterGroup.children.filter((_, i) => i !== index);
    setFilterGroup({ ...filterGroup, children: newChildren });
  };

  const handleComparatorChange = (comparator: DateComparator) => {
    const newChildren = [...filterGroup.children];
    let currentChild = newChildren[index] as Filter;

    if (currentChild.field === "dueDate") {
      currentChild = {
        ...currentChild,
        comparator,
      };
    }
    newChildren[index] = currentChild;
    setFilterGroup({ ...filterGroup, children: newChildren });
  };

  const handleValueChange = (
    value: string | number | boolean | null | string[]
  ) => {
    const newChildren = [...filterGroup.children];
    let currentChild = newChildren[index] as DueDateFilter;
    if (currentChild.field === "dueDate") {
      newChildren[index] = {
        ...currentChild,
        value: value as string | null,
      };
      setFilterGroup({ ...filterGroup, children: newChildren });
    }
  };

  return (
    <div className="flex items-center space-x-0 text-sm">
      <Select
        value={filter.field}
        onValueChange={(value) => {
          handleFieldChange(value as Filter["field"]);
          if (value === "dueDate") {
            setValueOpen(true);
          }
        }}
        open={open}
        onOpenChange={(isOpen) => {
          setOpen(isOpen);
        }}
      >
        <SelectTrigger
          className={clsx(
            "flex h-8 w-32 items-center truncate border bg-white px-3",
            !!filter.field ? "rounded-r-none" : ""
          )}
          showIcon={false}
        >
          {"Due Date"}
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="status">Status</SelectItem>
            <SelectItem value="dueDate">Due Date</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
      <Select
        value={filter.comparator}
        onValueChange={(value) => {
          handleComparatorChange(value as DateComparator);
        }}
      >
        <SelectTrigger
          className="flex h-8 w-20 items-center truncate rounded-none border-y border-x-0 bg-white px-3"
          showIcon={false}
        >
          {filter.comparator === "lte"
            ? "is before"
            : filter.comparator === "gte"
            ? "is after"
            : "s"}
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="lte">is before</SelectItem>
            <SelectItem value="gte">is after</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
      <Select
        value={filter.value ?? ""}
        onValueChange={(value) => {
          handleValueChange(value);
        }}
        open={valueOpen}
        onOpenChange={setValueOpen}
      >
        <SelectTrigger
          className="flex h-8 w-40 items-center truncate rounded-none border-r-0 bg-white px-3"
          showIcon={false}
        >
          {filter.value ?? <div className="text-gray-400/70">Select Date</div>}
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="end of today">end of today</SelectItem>
            <SelectItem value="end of tomorrow">end of tomorrow</SelectItem>
            <SelectItem value="end of week">end of week</SelectItem>
            <SelectItem value="end of month">end of month</SelectItem>
            {/* <SelectItem value="custom"></SelectItem> */}
          </SelectGroup>
        </SelectContent>
      </Select>

      {/* <Popover modal={true} open={valueOpen} onOpenChange={setValueOpen}>
        <PopoverTrigger className="h-8 border-y border-l bg-white px-3 text-sm hover:bg-gray-50 focus:outline-none">
          {!!filter.value && filter.value.length > 0 ? (
            <div className="truncate">
              {filter.value.length > 1 ? "Multiple" : filter.value[0]}
            </div>
          ) : (
            <div className="text-sm font-normal text-gray-300">
              Select Date
            </div>
          )}
        </PopoverTrigger>
        <PopoverContent
          className="pointer-events-auto w-fit min-w-[200px] max-w-[700px] p-0"
          align="start"
        >
          <Command>
            <CommandInput placeholder="Search status..." className="h-9" />
            <CommandEmpty>No status found.</CommandEmpty>
            <CommandGroup>
              {statuses["Backlog"]?.map((customStatus) => (
                <StatusCommandItem
                  parentStatus={"Backlog"}
                  key={customStatus}
                  customStatus={customStatus}
                  checked={filter.value.includes(customStatus)}
                  onSelect={(customStatus) => {
                    if (filter.value.includes(customStatus)) {
                      handleValueChange(
                        filter.value.filter((v) => v !== customStatus)
                      );
                    } else {
                      handleValueChange([...filter.value, customStatus]);
                    }
                  }}
                />
              ))}
              {statuses["To-do"]?.map((customStatus) => (
                <StatusCommandItem
                  parentStatus={"To-do"}
                  key={customStatus}
                  customStatus={customStatus}
                  checked={filter.value.includes(customStatus)}
                  onSelect={(customStatus) => {
                    if (filter.value.includes(customStatus)) {
                      handleValueChange(
                        filter.value.filter((v) => v !== customStatus)
                      );
                    } else {
                      handleValueChange([...filter.value, customStatus]);
                    }
                  }}
                />
              ))}
              {statuses["In Progress"]?.map((customStatus) => (
                <StatusCommandItem
                  parentStatus={"In Progress"}
                  key={customStatus}
                  customStatus={customStatus}
                  checked={filter.value.includes(customStatus)}
                  onSelect={(customStatus) => {
                    if (filter.value.includes(customStatus)) {
                      handleValueChange(
                        filter.value.filter((v) => v !== customStatus)
                      );
                    } else {
                      handleValueChange([...filter.value, customStatus]);
                    }
                  }}
                />
              ))}
              {statuses["Stuck"]?.map((customStatus) => (
                <StatusCommandItem
                  parentStatus={"Stuck"}
                  key={customStatus}
                  customStatus={customStatus}
                  checked={filter.value.includes(customStatus)}
                  onSelect={(customStatus) => {
                    if (filter.value.includes(customStatus)) {
                      handleValueChange(
                        filter.value.filter((v) => v !== customStatus)
                      );
                    } else {
                      handleValueChange([...filter.value, customStatus]);
                    }
                  }}
                />
              ))}
              {statuses["Completed"]?.map((customStatus) => (
                <StatusCommandItem
                  parentStatus={"Completed"}
                  key={customStatus}
                  customStatus={customStatus}
                  checked={filter.value.includes(customStatus)}
                  onSelect={(customStatus) => {
                    if (filter.value.includes(customStatus)) {
                      handleValueChange(
                        filter.value.filter((v) => v !== customStatus)
                      );
                    } else {
                      handleValueChange([...filter.value, customStatus]);
                    }
                  }}
                />
              ))}
              {statuses["Canceled"]?.map((customStatus) => (
                <StatusCommandItem
                  parentStatus={"Canceled"}
                  key={customStatus}
                  customStatus={customStatus}
                  checked={filter.value.includes(customStatus)}
                  onSelect={(customStatus) => {
                    if (filter.value.includes(customStatus)) {
                      handleValueChange(
                        filter.value.filter((v) => v !== customStatus)
                      );
                    } else {
                      handleValueChange([...filter.value, customStatus]);
                    }
                  }}
                />
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover> */}

      <div
        className="flex h-8 w-fit cursor-pointer items-center rounded-r-md border bg-white px-2 transition-colors hover:bg-gray-100"
        onClick={() => {
          removeChild(index);
        }}
      >
        <XMarkIcon className="h-2.5 w-2.5 shrink-0" />
      </div>
    </div>
  );
}

function StatusCommandItem({
  parentStatus,
  customStatus,
  onSelect,
  checked,
}: {
  parentStatus: ParentStatus;
  customStatus: string;
  onSelect: (customStatus: string) => void;
  checked: boolean;
}) {
  return (
    <CommandItem
      key={customStatus}
      onSelect={() => {
        onSelect(customStatus);
      }}
      className="gap-2"
    >
      <Checkbox
        onClick={(e) => {
          e.stopPropagation();
        }}
        checked={checked}
        onCheckedChange={(_) => {
          onSelect(customStatus);
        }}
      />

      <div className="flex items-center space-x-2">
        {getStatusSymbol(parentStatus, false)}
        <span className="truncate">{customStatus}</span>
      </div>
    </CommandItem>
  );
}

function FieldComparator({
  filter,
  index,
  filterGroup,
  setFilterGroup,
}: {
  filter: Filter;
  index: number;
  filterGroup: FilterGroup;
  setFilterGroup: (filterGroup: FilterGroup) => void;
}) {}

function Display() {
  const [open, setOpen] = useState(false);
  const journeyCtx = useJourney();
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="secondary">
          <AdjustmentsHorizontalIcon className="mr-1.5 h-3.5 w-4" />
          Display
        </Button>
      </PopoverTrigger>
      <PopoverContent align="start" className="p-0">
        <div className="space-y-3 p-4">
          <div className="flex items-center justify-between">
            <div className="text-sm">Layout</div>
            <div className="inline-flex cursor-pointer items-center rounded-lg">
              <button
                className={clsx(
                  "rounded-l-md border py-1 px-2.5",
                  journeyCtx.state.view.view === "kanban"
                    ? "border-gray-400 bg-gray-100"
                    : // ? journeyCtx.state.isExternal
                      //   ? "border-r border-embedPrimary bg-embedPrimary/10 text-embedPrimary"
                      //   : "border-r border-primary-main bg-primary-100 text-primary-main"
                      "border-r-0 text-gray-400 hover:bg-gray-50"
                )}
                onClick={() => {
                  journeyCtx.dispatch({
                    type: "VIEW_VIEW_UPDATE",
                    view: "kanban",
                  });
                }}
              >
                <ViewColumnsIcon className={clsx("h-5 w-5")} />
              </button>
              <button
                className={clsx(
                  "rounded-r-md border py-1 px-2.5",
                  journeyCtx.state.view.view === "list"
                    ? "border-gray-400 bg-gray-100"
                    : // ? journeyCtx.state.isExternal
                      //   ? "border-l border-embedPrimary bg-embedPrimary/10 text-embedPrimary"
                      //   : "border-l border-primary-main bg-primary-100 text-primary-main"
                      "border-l-0 text-gray-400 hover:bg-gray-50"
                )}
                onClick={() => {
                  journeyCtx.dispatch({
                    type: "VIEW_VIEW_UPDATE",
                    view: "list",
                  });
                }}
              >
                <ListBulletIcon className={clsx("h-5 w-5")} />
              </button>
            </div>
          </div>
          {journeyCtx.state.view.view === "kanban" && (
            <div className="flex items-center justify-between">
              <div className="text-sm">Group by</div>
              <Select
                value={journeyCtx.state.group}
                onValueChange={(value) => {
                  journeyCtx.dispatch({
                    type: "GROUP_UPDATE",
                    group: value as ProjectGroup,
                  });
                }}
              >
                <SelectTrigger className="h-7 w-28 px-2.5">
                  {journeyCtx.state.group.charAt(0).toUpperCase() +
                    journeyCtx.state.group.slice(1)}
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="stage">Stage</SelectItem>
                    <SelectItem value="status">Status</SelectItem>
                    {/* <SelectItem value="tasker">Tasker</SelectItem> */}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          )}
          {/* <div className="flex items-center justify-between">
              <div className="text-sm">Sort by</div>
              <Select
                value={sort.property}
                onValueChange={(value) => {
                  if (value === "lastCreatedAt") {
                    setSort({
                      property: "createdAt",
                      order: "desc",
                    });
                  } else if (value === "dueDate") {
                    setSort({
                      property: "dueDate",
                      order: "asc",
                    });
                  }
                }}
              >
                <SelectTrigger className="h-7 w-32 px-2.5">
                  {sort.property === "createdAt" ? "Last Created" : "Due Date"}
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="dueDate">Due Date</SelectItem>
                    <SelectItem value="lastCreatedAt">Last Created</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div> */}
        </div>
      </PopoverContent>
    </Popover>
  );
}

function InactiveFilter() {
  const journeyCtx = useJourney();

  return (
    <div className="flex space-x-3">
      {/* <button
        className={clsx(
          "rounded-md border px-2 py-1 text-sm",
          journeyCtx.state.view.responsibility === "my" &&
            "border-primary-main text-primary-main",
          journeyCtx.state.view.responsibility === "all" &&
            "border-gray-300 text-gray-500 hover:bg-gray-50"
        )}
        onClick={() => {
          journeyCtx.dispatch({
            type: "VIEW_TEAM_UPDATE",
            responsibility:
              journeyCtx.state.view.responsibility === "my" ? "all" : "my",
          });
        }}
      >
        {journeyCtx.state.view.responsibility === "all"
          ? "Show My Tasks"
          : "Show All Tasks"}
      </button> */}
      <div className="space-y-1">
        <h3 className="text-xs text-gray-400">Inactive Tasks</h3>
        <div className="flex max-w-[330px]">
          <button
            className={clsx(
              "rounded-l-md border py-1 px-2.5 text-sm",
              journeyCtx.state.view.showInactive
                ? journeyCtx.state.isExternal
                  ? "border-embedPrimary bg-embedPrimary/10 text-embedPrimary"
                  : "border-primary-main bg-primary-100 text-primary-600"
                : "text-gray-500 hover:bg-gray-100 hover:text-gray-700",
              !journeyCtx.state.view.showInactive && "border-r-0"
            )}
            onClick={() => {
              journeyCtx.dispatch({
                type: "VIEW_SHOW_INACTIVE_UPDATE",
                showInactive: true,
              });
            }}
          >
            Show
          </button>

          <button
            className={clsx(
              "truncate rounded-r-md border py-1 px-2.5 text-sm",
              !journeyCtx.state.view.showInactive
                ? journeyCtx.state.isExternal
                  ? "border-embedPrimary bg-embedPrimary/10 text-embedPrimary"
                  : "border-primary-main bg-primary-100 text-primary-600"
                : "text-gray-500 hover:bg-gray-100 hover:text-gray-700",
              journeyCtx.state.view.showInactive && "border-l-0"
            )}
            onClick={() => {
              journeyCtx.dispatch({
                type: "VIEW_SHOW_INACTIVE_UPDATE",
                showInactive: false,
              });
            }}
          >
            Hide
            {/* This is a really long customer name */}
          </button>
        </div>
      </div>
    </div>
  );
}
function CompletedFilter() {
  const journeyCtx = useJourney();

  return (
    <div className="flex space-x-3">
      {/* <button
        className={clsx(
          "rounded-md border px-2 py-1 text-sm",
          journeyCtx.state.view.responsibility === "my" &&
            "border-primary-main text-primary-main",
          journeyCtx.state.view.responsibility === "all" &&
            "border-gray-300 text-gray-500 hover:bg-gray-50"
        )}
        onClick={() => {
          journeyCtx.dispatch({
            type: "VIEW_TEAM_UPDATE",
            responsibility:
              journeyCtx.state.view.responsibility === "my" ? "all" : "my",
          });
        }}
      >
        {journeyCtx.state.view.responsibility === "all"
          ? "Show My Tasks"
          : "Show All Tasks"}
      </button> */}
      <div className="space-y-1">
        <h3 className="text-xs text-gray-400">Completed Tasks</h3>
        <div className="flex max-w-[330px]">
          <button
            className={clsx(
              "rounded-l-md border py-1 px-2.5 text-sm",
              journeyCtx.state.view.showCompleted
                ? journeyCtx.state.isExternal
                  ? "border-embedPrimary bg-embedPrimary/10 text-embedPrimary"
                  : "border-primary-main bg-primary-100 text-primary-600"
                : "text-gray-500 hover:bg-gray-100 hover:text-gray-700",
              !journeyCtx.state.view.showCompleted && "border-r-0"
            )}
            onClick={() => {
              journeyCtx.dispatch({
                type: "VIEW_COMPLETED_UPDATE",
                showCompleted: true,
              });
            }}
          >
            Show
          </button>

          <button
            className={clsx(
              "truncate rounded-r-md border py-1 px-2.5 text-sm",
              !journeyCtx.state.view.showCompleted
                ? journeyCtx.state.isExternal
                  ? "border-embedPrimary bg-embedPrimary/10 text-embedPrimary"
                  : "border-primary-main bg-primary-100 text-primary-600"
                : "text-gray-500 hover:bg-gray-100 hover:text-gray-700",
              journeyCtx.state.view.showCompleted && "border-l-0"
            )}
            onClick={() => {
              journeyCtx.dispatch({
                type: "VIEW_COMPLETED_UPDATE",
                showCompleted: false,
              });
            }}
          >
            Hide
            {/* This is a really long customer name */}
          </button>
        </div>
      </div>
    </div>
  );
}

function SubtasksFilter() {
  const journeyCtx = useJourney();

  return (
    <div className="flex space-x-3">
      {/* <button
        className={clsx(
          "rounded-md border px-2 py-1 text-sm",
          journeyCtx.state.view.responsibility === "my" &&
            "border-primary-main text-primary-main",
          journeyCtx.state.view.responsibility === "all" &&
            "border-gray-300 text-gray-500 hover:bg-gray-50"
        )}
        onClick={() => {
          journeyCtx.dispatch({
            type: "VIEW_TEAM_UPDATE",
            responsibility:
              journeyCtx.state.view.responsibility === "my" ? "all" : "my",
          });
        }}
      >
        {journeyCtx.state.view.responsibility === "all"
          ? "Show My Tasks"
          : "Show All Tasks"}
      </button> */}
      <div className="space-y-1">
        <h3 className="text-xs text-gray-400">Subtasks</h3>
        <div className="flex max-w-[330px]">
          <button
            className={clsx(
              "rounded-l-md border py-1 px-2.5 text-sm",
              journeyCtx.state.view.showSubtasks
                ? "border-gray-400 bg-gray-100"
                : // ? journeyCtx.state.isExternal
                  //   ? "border-embedPrimary bg-embedPrimary/10 text-embedPrimary"
                  //   : "border-primary-main bg-primary-100 text-primary-600"
                  "text-gray-500 hover:bg-gray-100 hover:text-gray-700",
              !journeyCtx.state.view.showSubtasks && "border-r-0"
            )}
            onClick={() => {
              journeyCtx.dispatch({
                type: "VIEW_SHOW_SUBTASKS_UPDATE",
                showSubtasks: true,
              });
            }}
          >
            Show
          </button>

          <button
            className={clsx(
              "truncate rounded-r-md border py-1 px-2.5 text-sm",
              !journeyCtx.state.view.showSubtasks
                ? journeyCtx.state.isExternal
                  ? "border-embedPrimary bg-embedPrimary/10 text-embedPrimary"
                  : "border-primary-main bg-primary-100 text-primary-600"
                : "text-gray-500 hover:bg-gray-100 hover:text-gray-700",
              journeyCtx.state.view.showSubtasks && "border-l-0"
            )}
            onClick={() => {
              journeyCtx.dispatch({
                type: "VIEW_SHOW_SUBTASKS_UPDATE",
                showSubtasks: false,
              });
            }}
          >
            Hide
            {/* This is a really long customer name */}
          </button>
        </div>
      </div>
    </div>
  );
}

function TaskerFilter({
  instanceName,
  customerName,
}: {
  instanceName: string;
  customerName: string;
}) {
  const journeyCtx = useJourney();
  return (
    <div className="flex space-x-3">
      {/* <button
        className={clsx(
          "rounded-md border px-2 py-1 text-sm",
          journeyCtx.state.view.responsibility === "my" &&
            "border-primary-main text-primary-main",
          journeyCtx.state.view.responsibility === "all" &&
            "border-gray-300 text-gray-500 hover:bg-gray-50"
        )}
        onClick={() => {
          journeyCtx.dispatch({
            type: "VIEW_TEAM_UPDATE",
            responsibility:
              journeyCtx.state.view.responsibility === "my" ? "all" : "my",
          });
        }}
      >
        {journeyCtx.state.view.responsibility === "all"
          ? "Show My Tasks"
          : "Show All Tasks"}
      </button> */}
      <div className="space-y-1">
        <h3 className="text-xs text-gray-400">Assigned To</h3>
        <div className="flex max-w-[330px]">
          <button
            className={clsx(
              "rounded-l-md border py-1 px-2.5 text-sm",
              journeyCtx.state.view.responsibility === "all"
                ? "border-gray-400 bg-gray-100"
                : // ? journeyCtx.state.isExternal
                  //   ? "border-embedPrimary bg-embedPrimary/10 text-embedPrimary"
                  //   : "border-primary-main bg-primary-100 text-primary-600"
                  // ? "border-gray-500 bg-gray-00 text-gray-600"
                  "text-gray-500 hover:bg-gray-100 hover:text-gray-700",
              journeyCtx.state.view.responsibility === "my" && "border-r-0"
            )}
            onClick={() => {
              journeyCtx.dispatch({
                type: "VIEW_TEAM_UPDATE",
                responsibility: "all",
              });
            }}
          >
            All
          </button>
          <button
            className={clsx(
              "border-t border-b py-1 px-2.5 text-sm",
              journeyCtx.state.view.responsibility === "my"
                ? journeyCtx.state.isExternal
                  ? "border-l border-r border-embedPrimary bg-embedPrimary/10 text-embedPrimary"
                  : "border-l border-r border-primary-main bg-primary-100 text-primary-600"
                : "text-gray-500 hover:bg-gray-100 hover:text-gray-700",
              journeyCtx.state.view.responsibility === "all" && "border-r"
            )}
            onClick={() => {
              journeyCtx.dispatch({
                type: "VIEW_TEAM_UPDATE",
                responsibility: "my",
              });
            }}
          >
            Me
          </button>
          <button
            className={clsx(
              "truncate border-t border-b py-1 px-2.5 text-sm",
              journeyCtx.state.view.responsibility === "internal"
                ? journeyCtx.state.isExternal
                  ? "border-r border-l border-embedPrimary bg-embedPrimary/10 text-embedPrimary"
                  : "border-r border-l border-primary-main bg-primary-100 text-primary-600"
                : "text-gray-500 hover:bg-gray-100 hover:text-gray-700",
              journeyCtx.state.view.responsibility === "external" && "border-l"
            )}
            onClick={() => {
              journeyCtx.dispatch({
                type: "VIEW_TEAM_UPDATE",
                responsibility: "internal",
              });
            }}
          >
            {instanceName}
            {/* First Resonance erfergergergergegergerg */}
          </button>
          <button
            className={clsx(
              "truncate rounded-r-md border py-1 px-2.5 text-sm",
              journeyCtx.state.view.responsibility === "external"
                ? journeyCtx.state.isExternal
                  ? "border-embedPrimary bg-embedPrimary/10 text-embedPrimary"
                  : "border-primary-main bg-primary-100 text-primary-600"
                : "text-gray-500 hover:bg-gray-100 hover:text-gray-700",
              journeyCtx.state.view.responsibility === "internal" &&
                "border-l-0"
            )}
            onClick={() => {
              journeyCtx.dispatch({
                type: "VIEW_TEAM_UPDATE",
                responsibility: "external",
              });
            }}
          >
            {customerName}
            {/* This is a really long customer name */}
          </button>
        </div>
      </div>
    </div>
  );
}

function TasksFilter() {
  const journeyCtx = useJourney();
  return (
    <div className="flex space-x-3">
      <div className="space-y-1">
        <h3 className="text-xs text-gray-400">Status</h3>
        <div className="flex max-w-[330px]">
          <button
            className={clsx(
              "rounded-l-md border py-1 px-2.5 text-sm",
              !journeyCtx.state.view.showOnlyActiveAndIncompleteTasks
                ? "border-gray-400 bg-gray-100"
                : // ? journeyCtx.state.isExternal
                  //   ? "border-embedPrimary bg-embedPrimary/10 text-embedPrimary"
                  //   : "border-primary-main bg-primary-100 text-primary-600"
                  "text-gray-500 hover:bg-gray-100 hover:text-gray-700",
              journeyCtx.state.view.showOnlyActiveAndIncompleteTasks &&
                "border-r-0"
            )}
            onClick={() => {
              journeyCtx.dispatch({
                type: "VIEW_SHOW_ACTIVE_AND_INCOMPLETE_TASKS_UPDATE",
                showOnlyActiveAndIncompleteTasks: false,
              });
            }}
          >
            All
          </button>
          <button
            className={clsx(
              "truncate rounded-r-md border py-1 px-2.5 text-sm",
              journeyCtx.state.view.showOnlyActiveAndIncompleteTasks
                ? journeyCtx.state.isExternal
                  ? "border-embedPrimary bg-embedPrimary/10 text-embedPrimary"
                  : "border-primary-main bg-primary-100 text-primary-600"
                : "text-gray-500 hover:bg-gray-100 hover:text-gray-700",
              !journeyCtx.state.view.showOnlyActiveAndIncompleteTasks &&
                "border-l-0"
            )}
            onClick={() => {
              journeyCtx.dispatch({
                type: "VIEW_SHOW_ACTIVE_AND_INCOMPLETE_TASKS_UPDATE",
                showOnlyActiveAndIncompleteTasks: true,
              });
            }}
          >
            Currently Active
          </button>
        </div>
      </div>
    </div>
  );
}

// function SubtasksFilter() {
//   const journeyCtx = useJourney();
//   return (
//     <div className="flex space-x-3">
//       <div className="space-y-1">
//         <h3 className="text-xs text-gray-400">Subtasks</h3>
//         <div className="flex max-w-[330px]">
//           <button
//             className={clsx(
//               "rounded-l-md border py-1 px-2.5 text-sm",
//               journeyCtx.state.view.showSubtasks
//                 ? journeyCtx.state.isExternal
//                   ? "border-embedPrimary bg-embedPrimary/10 text-embedPrimary"
//                   : "border-primary-main bg-primary-100 text-primary-600"
//                 : "text-gray-500 hover:bg-gray-100 hover:text-gray-700",
//               journeyCtx.state.view.showSubtasks &&
//                 "border-r-0"
//             )}
//             onClick={() => {
//               journeyCtx.dispatch({
//                 type: "VIEW_SHOW_SUBTASKS_UPDATE",
//                 showSubtasks:true,
//               });
//             }}
//           >
//             All
//           </button>
//           <button
//             className={clsx(
//               "truncate rounded-r-md border py-1 px-2.5 text-sm",
//               !journeyCtx.state.view.showSubtasks
//                 ? journeyCtx.state.isExternal
//                   ? "border-embedPrimary bg-embedPrimary/10 text-embedPrimary"
//                   : "border-primary-main bg-primary-100 text-primary-600"
//                 : "text-gray-500 hover:bg-gray-100 hover:text-gray-700",
//               journeyCtx.state.view.showSubtasks &&
//                 "border-l-0"
//             )}
//             onClick={() => {
//               journeyCtx.dispatch({
//                 type: "VIEW_SHOW_SUBTASKS_UPDATE",
//                 showSubtasks: false,
//               });
//             }}
//           >
//             Hide
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }

function ListView({
  stages,
  journeyId,
  statusGroup,
  tasks,
}: {
  stages: JourneyStageObj[];
  tasks: TaskData[];
  journeyId: string;
  statusGroup: StatusGroupData;
}) {
  const journeyCtx = useJourney();
  return (
    <div className="overflow-auto pb-6">
      {stages
        .filter((s) => {
          if (journeyCtx.state.isExternal) {
            return (
              !s.tasks?.filter((t) => !t.isVisible).length ||
              s.tasks.filter((t) => t.isVisible).length > 0
            );
          }
          return true;
        })
        .map((stage, index) => (
          <TaskList
            tasks={tasks.filter(
              (t) => t.journeyStage?._id === stage._id?.toString()
            )}
            title={stage.name}
            index={index}
          />
        ))}
      {/* {stages
        .filter((s) => {
          if (journeyCtx.state.isExternal) {
            return (
              !s.tasks?.filter((t) => !t.isVisible).length ||
              s.tasks.filter((t) => t.isVisible).length > 0
            );
          }
          return true;
        })
        .map((stage) => (
          <>
            {stage._id && (
              <StageTaskList
                key={stage._id?.toString() ?? Math.random()}
                stage={stage}
                stageId={stage._id.toString()}
              />
            )}
          </>
        ))} */}
    </div>
  );
}

function TaskList({
  tasks,
  title,
  index,
}: {
  tasks: TaskData[];
  title: string;
  index: number;
}) {
  const app = useRealmApp();
  const journeyCtx = useJourney();
  let sortedTasks = [...tasks];
  if (journeyCtx.state.group === "status") {
    sortedTasks = sortedTasks.sort((a, b) => {
      if (a.dueDate && b.dueDate) {
        if (a.updatedAt && b.updatedAt) {
          return (
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          );
        } else if (b.updatedAt) {
          return -1;
        } else if (a.updatedAt) {
          return 1;
        }
      } else if (a.dueDate) {
        return -1;
      }
      return 1;
      return 0;
    });
  }
  return (
    <div>
      <div
        className={clsx(
          "flex h-[38px] min-w-0 items-center space-x-2 border-b border-gray-100 bg-gray-50 px-8",
          index !== 0 && "border-t border-gray-100"
        )}
      >
        <div className="min-w-0 max-w-full shrink-0 truncate">{title}</div>
        {journeyCtx.state.group === "status" && (
          <div className="flex min-w-[16px] justify-center rounded bg-gray-100 px-1 py-0.5 text-[10px] text-gray-600">
            {tasks.length}
          </div>
        )}
      </div>

      <div className="divide-y divide-gray-100">
        {sortedTasks
          .filter((t) => !t.parentTask)
          .filter((t) => {
            if (journeyCtx.state.view.responsibility === "all") {
              return true;
            } else if (journeyCtx.state.view.responsibility === "internal") {
              return t.taskerType === "internal";
            } else if (journeyCtx.state.view.responsibility === "external") {
              return t.taskerType === "external";
            } else if (journeyCtx.state.view.responsibility === "my") {
              return (
                t.tasker.userType === "fullUser" &&
                t.tasker._id.toString() === app.currentUser.id
              );
            }
          })
          .filter((t) => {
            if (journeyCtx.state.view.showOnlyActiveAndIncompleteTasks) {
              // console.log(t.title, !!t.dueDate, t.status !== "Completed");
              return (
                !!t.dueDate &&
                t.status !== "Completed" &&
                t.status !== "Canceled"
              );
            } else {
              return true;
            }
          })
          // .filter((t) => {
          //   if (journeyCtx.state.view.showInactive) {
          //     return true;
          //   } else {
          //     return !!t.dueDate;
          //   }
          // })
          // .filter((t) => {
          //   if (journeyCtx.state.view.showCompleted) {
          //     return true;
          //   } else {
          //     return t.status !== "Completed";
          //   }
          // })
          .filter((t) => {
            if (!journeyCtx.state.isExternal) return true;
            return t.isVisible;
          })
          // .filter((t) => {
          //   if (journeyCtx.state.view.showSubtasks) {
          //     return !t.parentTask;
          //   } else {
          //     return true;
          //   }
          // })
          .map((task) => (
            <>
              {!!task.parentTask?._id ? (
                <div className="pl-6">
                  <TaskWithSubTasks task={task} allTasks={tasks} />
                </div>
              ) : (
                <TaskWithSubTasks task={task} allTasks={tasks} />
              )}
            </>
          ))}
      </div>
    </div>
  );
}

function StageTaskList({
  stage,
  stageId,
}: {
  stage: JourneyStageObj;
  stageId: string;
}) {
  const { data: tasks } = useGetJourneyStageTasks(
    stage.journeyId.toString(),
    stageId
  );
  const app = useRealmApp();
  const journeyCtx = useJourney();
  return (
    <div>
      {!!tasks && tasks.length > 0 ? (
        <div className="divide-y divide-gray-100">
          <div className="flex items-center space-x-4 px-8 pb-2">
            <div className="text-lg font-medium">{stage.name}</div>
            {/* <div className="inline-flex items-center space-x-2 rounded-full border border-gray-400 px-2 py-1 text-xs text-gray-600">
              <>
                <WrenchIcon className="h-4 w-4 text-gray-500" />
              </>
              <div>
                <TotalTasksDuration tasks={tasks} />
              </div>
            </div> */}
          </div>
          {tasks
            .filter((t) => !t.parentTask)
            .filter((t) => {
              if (journeyCtx.state.view.responsibility === "all") {
                return true;
              } else if (journeyCtx.state.view.responsibility === "internal") {
                return t.taskerType === "internal";
              } else if (journeyCtx.state.view.responsibility === "external") {
                return t.taskerType === "external";
              } else if (journeyCtx.state.view.responsibility === "my") {
                return (
                  t.tasker.userType === "fullUser" &&
                  t.tasker._id.toString() === app.currentUser.id
                );
              }
            })
            .filter((t) => {
              if (journeyCtx.state.view.showOnlyActiveAndIncompleteTasks) {
                // console.log(t.title, !!t.dueDate, t.status !== "Completed");
                return !!t.dueDate && t.status !== "Completed";
              } else {
                return true;
              }
            })
            .filter((t) => {
              if (journeyCtx.state.view.showInactive) {
                return true;
              } else {
                return !!t.dueDate;
              }
            })
            .filter((t) => {
              if (journeyCtx.state.view.showCompleted) {
                return true;
              } else {
                return t.status !== "Completed";
              }
            })
            .filter((t) => {
              if (!journeyCtx.state.isExternal) return true;
              return t.isVisible;
            })
            .filter((t) => {
              if (journeyCtx.state.view.showSubtasks) {
                return !t.parentTask;
              } else {
                return true;
              }
            })
            .map((task) => {
              return <TaskWithSubTasks task={task} allTasks={tasks} />;
            })}
          <div />
        </div>
      ) : (
        <div className="divide-y divide-gray-100">
          <div className="px-8 pb-2 text-lg font-medium">{stage.name}</div>
          <div className="px-8 py-2 text-sm text-gray-400">
            No tasks in this stage
          </div>
        </div>
      )}
    </div>
  );
}

function TaskWithSubTasks({
  task,
  allTasks,
}: {
  task: TaskData;
  allTasks: TaskData[];
}) {
  const journeyCtx = useJourney();
  return (
    <div className="divide-y divide-gray-100">
      <div>
        <TaskRow task={task} isSubTask={false} />

        {journeyCtx.state.view.showSubtasks && (
          <>
            {task.subTaskCount && task.subTaskCount > 0 && (
              <div className="">
                {allTasks
                  .filter(
                    (t) => t.parentTask?._id.toString() === task._id?.toString()
                  )
                  .filter((t) => {
                    if (!journeyCtx.state.isExternal) return true;
                    return t.isVisible;
                  })
                  .filter((t) => {
                    if (
                      journeyCtx.state.view.showOnlyActiveAndIncompleteTasks
                    ) {
                      return !!t.dueDate && t.status !== "Completed";
                    } else {
                      return true;
                    }
                  })
                  .map((t) => (
                    <TaskRow
                      task={t}
                      isSubTask={true}
                      key={t._id?.toString() ?? Math.random()}
                    />
                  ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function TaskRow({
  task,
  isSubTask = false,
}: {
  task: TaskData;
  isSubTask?: boolean;
}) {
  const journeyCtx = useJourney();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div
      className={clsx(
        "relative flex h-10 items-center py-1.5 px-8",
        "cursor-pointer hover:bg-gray-50"
      )}
      key={task._id ? task._id.toString() : Math.random()}
    >
      {task.taskerType === "external" && (
        <div className="absolute right-0 mr-3 h-2 w-2 rounded-full bg-gray-400" />
      )}
      <div className="ml-0 flex items-center space-x-2">
        {isSubTask && <div className="w-8"></div>}
        {task.dueDate ? (
          <StatusSelect
            isProjectView={true}
            task={task}
            isSymbol={true}
            disabled={
              task.taskerType === "internal" && journeyCtx.state.isExternal
            }
            disabledStatuses={[
              ...(task.action === "form" || task.action === "fileUpload"
                ? ["Completed" as TaskData["status"]]
                : []),
              ...(journeyCtx.state.isExternal
                ? ["Canceled" as TaskData["status"]]
                : []),
              "Backlog",
            ]}
            isTag={true}
          />
        ) : (
          // <Status
          //   disabled={
          //     task.taskerType === "internal" && journeyCtx.state.isExternal
          //   }
          //   isButtonIcon={true}
          //   onChange={(status) => {
          //     if (task.journeyStage?._id && task.journey?._id) {
          //       if (!task._id) return;
          //       updateTaskMutation.mutate({
          //         update: { _id: task._id, status: status },
          //         metadata: {
          //           phaseId: task?.phase?._id,
          //           journeyId: task?.journey?._id,
          //           journeyStageId: task?.journeyStage?._id,
          //         },
          //       });
          //     }
          //   }}
          //   status={task.status}
          //   isBacklogAvailable={false}
          // />
          <>
            {!task.dueDate && (
              <div
                className="flex flex-shrink items-center truncate rounded-full bg-gray-100 px-2 py-0.5 text-xs text-gray-500"
                onClick={() => {
                  if (task._id) {
                    const searchParams = new URLSearchParams(location.search);
                    searchParams.set("taskId", task._id);
                    navigate({
                      pathname: location.pathname,
                      search: searchParams.toString(),
                    });
                  }
                }}
              >
                {/* <span
                  className={clsx(
                    "font-medium",
                    !journeyCtx.state.isExternal
                      ? "text-primary-main"
                      : "font-bold text-embedPrimary"
                  )}
                >
                  {"Dependent"}
                </span> */}
                <span className="whitespace-pre font-light">{`Dependent on completion of `}</span>
                {journeyCtx.state.isExternal ? (
                  <span className="flex-shrink truncate font-light">
                    another task
                  </span>
                ) : (
                  <>
                    <span className="whitespace-pre font-light">{`${
                      task.dependentOn?.type === "stage" ? "stage" : "task"
                    } `}</span>
                    <span className="flex-shrink truncate font-medium">
                      {task.dependentOn?.name}
                    </span>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>

      <div
        className="flex min-w-0 items-center space-x-4 pl-2"
        onClick={() => {
          if (task._id) {
            const searchParams = new URLSearchParams(location.search);
            searchParams.set("taskId", task._id);
            navigate({
              pathname: location.pathname,
              search: searchParams.toString(),
            });
          }
        }}
      >
        <div
          className={clsx(
            "truncate text-sm",
            isSubTask ? "text-gray-500" : "text-gray-700"
          )}
        >
          {task.title}
        </div>
        {/* {task.isMarkedForToday && (
      <CalendarIcon className="h-5 w-5 text-primary-main" />
    )} */}
        {task?.subTaskCount && task.subTaskCount > 0 && (
          <>
            <div className="inline-flex items-center space-x-0.5 rounded-full border border-gray-400 py-0.5 px-1.5 text-gray-400">
              <span className="text-xs">{task.completedSubTaskCount}</span>
              <span className="text-xs">{`/`}</span>
              <span className="text-xs">{task.subTaskCount}</span>
              {/* <BoltIcon className="h-3 w-3" /> */}
            </div>
          </>
        )}
        {/* {task.subTaskCount && (
      <div
        onClick={() => {
          
          setOpen && setOpen(!open);
        }}
        className="border"
      >
        <ChevronDownIcon className="h-3 w-3 text-primary-main" />
      </div>
    )} */}
        {task.parentTask && !isSubTask && (
          <div className="flex items-center space-x-4">
            <ChevronRightIcon className="h-3 w-3 text-gray-400" />
            <span className="text-sm text-gray-400">
              {task.parentTask.title}
            </span>
          </div>
        )}
      </div>
      <div
        className="flex flex-grow items-center justify-end space-x-4"
        onClick={() => {
          if (task._id) {
            const searchParams = new URLSearchParams(location.search);
            searchParams.set("taskId", task._id);
            navigate({
              pathname: location.pathname,
              search: searchParams.toString(),
            });
          }
        }}
      >
        {/* {task.estimate && (
      <div className="inline-flex items-center space-x-2 rounded-full border px-2 py-1 text-xs text-gray-600">
        <>
          <WrenchIcon className="h-4 w-4 text-gray-500" />
        </>
        <div>{task.estimate}</div>
      </div>
    )} */}

        {/* {task.phase && (
      <div
        className="rounded-full border border-gray-400 px-2 py-1 text-xs text-gray-500 hover:border-gray-700 hover:bg-gray-700 hover:text-white"
        onClick={(e) => {
          e.stopPropagation();
          navigate(`/projects/${task.phase?._id.toString()}/journey`);
        }}
      >
        {task.journey?.name}
      </div>
    )} */}
        {/* {task.journey && (
      <Chip label={task.journey.name} size="small" variant="outlined" />
    )} */}

        {task.dueDate && (
          <TimeLeft
            dueDate={task.dueDate}
            completionDate={task.completionDate}
            task={task}
          />
        )}
        <div className="w-5">
          {!task.isVisible && (
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger asChild>
                  <EyeSlashIcon
                    className={clsx(
                      "h-[18px] w-[18px] shrink-0 text-gray-400/80"
                    )}
                  />
                </TooltipTrigger>
                <TooltipContent sideOffset={8}>
                  <p>Externally hidden</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
        {/* <div className="w-12">
          {task.estimate && !task.subTaskCount && (
            <div className="text-sm">
              <span className="text-gray-600">
                {task.estimate.unit === "hour"
                  ? task.estimate.value * 60
                  : task.estimate.value}
              </span>
              <span className="text-xs text-gray-500"> min</span>
            </div>
          )}
        </div> */}

        {task.dueDate ? (
          <span className="w-14 text-right text-sm text-gray-500">
            {dayjs(task.dueDate).format("DD MMM")}
          </span>
        ) : (
          <div className="flex w-14 justify-end">
            {!!task.durations?.forecasted && (
              <span className="w-14 text-right text-sm text-gray-300">
                {dayjs(new Date().getTime() + task.durations.forecasted).format(
                  "DD MMM"
                )}
              </span>
            )}
          </div>
        )}
        <div className="flex w-32 justify-end">
          <div
            className={clsx(
              "w-fit truncate whitespace-nowrap rounded-full border px-2 py-0.5 text-xs",
              task.tasker.userType === "persona"
                ? "border-red-400 text-red-600"
                : task.taskerType === "internal"
                ? "border-gray-400 bg-white text-gray-500"
                : "border-gray-400 bg-gray-200 text-black"
            )}
          >
            {task.tasker.userType === "persona"
              ? "Unassigned"
              : getIdentifier(task.tasker).identifier}
          </div>
          {/* <Avatar
            name={getIdentifier(task.tasker).identifier}
            size="sm"
            color={task.taskerType === "external" ? "slate" : "gray"}
            fill={task.taskerType === "external" ? "solid" : "outline"}
          /> */}
        </div>
      </div>
    </div>
  );
}

function KanbanView({
  stages,
  journeyId,
  statusGroup,
  tasks,
  phase,
}: {
  tasks: TaskData[];
  stages: JourneyStageObj[];
  journeyId: string;
  statusGroup: StatusGroupData;
  phase: PhaseData;
}) {
  const journeyCtx = useJourney();
  const [newStageName, setNewStageName] = useState("");
  const addStageMutation = useAddStage();
  const statuses: Record<ParentStatus, string[]> = {
    "To-do": statusGroup.statuses
      .filter((s) => s.parentStatus === "To-do")
      .map((s) => s.name),
    "In Progress": statusGroup.statuses
      .filter((s) => s.parentStatus === "In Progress")
      .map((s) => s.name),
    Stuck: statusGroup.statuses
      .filter((s) => s.parentStatus === "Stuck")
      .map((s) => s.name),
    Completed: statusGroup.statuses
      .filter((s) => s.parentStatus === "Completed")
      .map((s) => s.name),
    Canceled: statusGroup.statuses
      .filter((s) => s.parentStatus === "Canceled")
      .map((s) => s.name),
    Backlog: statusGroup.statuses
      .filter((s) => s.parentStatus === "Backlog")
      .map((s) => s.name),
  };

  useEffect(() => {
    if (addStageMutation.isSuccess) {
      setNewStageName("");
    }
  }, [addStageMutation.isSuccess]);

  if (journeyCtx.state.group === "stage") {
    return (
      <div className="flex h-full gap-4 overflow-auto px-8 pt-4">
        {stages
          .filter((s) => {
            if (journeyCtx.state.isExternal) {
              return (
                !s.tasks?.filter((t) => !t.isVisible).length ||
                s.tasks.filter((t) => t.isVisible).length > 0
              );
            }
            return true;
          })
          .map((stage) => (
            <TasksGroup
              tasks={tasks.filter(
                (t) => t.journeyStage?._id === stage._id?.toString()
              )}
              title={stage.name}
              stage={stage}
              phase={phase}
            />
          ))}
        {stages.length > 0 && (
          <div className="min-w-[312px]">
            <Dialog>
              <DialogTrigger asChild>
                <Button
                  size="lg"
                  className="h-10 w-full border-dashed"
                  variant={"secondary"}
                >
                  {/* <PlusIcon className="h-5 w-5 text-gray-500" /> */}
                  <span className="text-sm text-gray-500">Add Stage</span>
                </Button>
              </DialogTrigger>
              <DialogContent
                className={clsx(
                  "top-[20%] w-full max-w-lg translate-y-0 data-[state=open]:slide-in-from-top-8"
                )}
              >
                <DialogHeader>
                  <DialogTitle>New Stage</DialogTitle>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                  <div className="space-y-1">
                    <InputHeading heading="Name" />
                    <TextInput
                      className="max-w-[300px]"
                      value={newStageName}
                      onChange={(e) => {
                        setNewStageName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <DialogFooter>
                  <DialogClose asChild>
                    <Button
                      disabled={!newStageName}
                      onClick={() => {
                        // todo: add stage
                        addStageMutation.mutate({
                          params: {
                            name: newStageName,
                            journeyId,
                          },
                        });
                      }}
                    >
                      Create
                    </Button>
                  </DialogClose>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </div>
        )}
      </div>
    );
  } else if (journeyCtx.state.group === "status") {
    // iterate through each status
    let groups: JSX.Element[] = [];
    for (const [key, value] of Object.entries(statuses)) {
      groups.push(
        ...value.map((customStatus) => {
          return (
            <TasksGroup
              tasks={tasks?.filter(
                (t) => t.customStatus === customStatus && t.status === key
              )}
              title={customStatus}
              phase={phase}
            />
          );
        })
      );
    }
    return (
      <div className="flex h-full gap-4 overflow-auto px-8 pt-4">{groups}</div>
    );
  } else {
    return <></>;
  }

  return (
    <div className="relative z-0 flex h-full flex-1 overflow-x-auto overflow-y-auto px-2 pt-6">
      {!!stages &&
        stages
          .filter((s) => {
            if (journeyCtx.state.isExternal) {
              return (
                !s.tasks?.filter((t) => !t.isVisible).length ||
                s.tasks.filter((t) => t.isVisible).length > 0
              );
            }
            return true;
          })
          .map((stage) => (
            <div
              key={stage._id ? stage._id.toString() : Math.random()}
              className="flex shrink-0 flex-col overflow-y-auto"
            >
              <JourneyStage stage={stage} />
            </div>
          ))}
    </div>
  );
}

function TasksGroup({
  tasks,
  title,
  stage,
  phase,
}: {
  tasks: TaskData[];
  title: string;
  stage?: JourneyStageObj;
  phase: PhaseData;
}) {
  const app = useRealmApp();
  const [open, setOpen] = useState(false);
  const { slug } = useParams();
  const journeyCtx = useJourney();
  const newTaskForExternalUserCtx = useNewTaskForExternalUser();
  const newTaskForInternalUserCtx = useNewTaskForInternalUser();
  const [newStageName, setNewStageName] = useState(stage?.name ?? "");
  const [isEditing, setIsEditing] = useState(false);
  const updateJourneyStageMutation = useUpdateJourneyStage();
  let sortedTasks = [...tasks];

  useEffect(() => {
    if (stage?.name) {
      setNewStageName(stage?.name);
    }
  }, [stage?.name]);

  if (stage?.isUnassigned && tasks.length === 0) {
    return null;
  }
  if (journeyCtx.state.group === "status") {
    sortedTasks = sortedTasks.sort((a, b) => {
      if (a.dueDate && b.dueDate) {
        if (a.updatedAt && b.updatedAt) {
          return (
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          );
        } else if (b.updatedAt) {
          return -1;
        } else if (a.updatedAt) {
          return 1;
        }
      } else if (a.dueDate) {
        return -1;
      }
      return 1;
      return 0;
    });
  }

  return (
    <div className="flex h-full min-w-[312px] max-w-[312px] flex-1 flex-col rounded-t-lg bg-gradient-to-b from-gray-50 to-gray-50/50">
      <div className="">
        <div className="group flex items-center justify-between gap-1 rounded-md pl-5 pr-3.5 pt-3.5 pb-3.5">
          <div className="flex min-w-0 items-center space-x-2">
            <div className="min-w-0 max-w-full shrink-0 truncate text-sm">
              {title}
            </div>
            {journeyCtx.state.group === "status" && (
              <div className="flex min-w-[16px] justify-center rounded bg-gray-100 px-1 py-0.5 text-[10px] text-gray-600">
                {tasks.length}
              </div>
            )}
          </div>
          <div className="group flex items-center">
            {!!stage?._id && !stage.isUnassigned && (
              <DropdownMenu
                open={open}
                onOpenChange={(isOpen) => {
                  setOpen(isOpen);
                }}
              >
                <DropdownMenuTrigger asChild>
                  <Button
                    variant={"ghost"}
                    className={clsx(
                      "h-7 w-7 rounded p-0 hover:bg-gray-200/60 group-hover:visible",
                      !open ? "invisible" : "bg-gray-200/60"
                    )}
                  >
                    <EllipsisVerticalIcon className="h-3.5 w-3.5" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-44" align="end">
                  <DropdownMenuGroup>
                    <DropdownMenuItem
                      // className="py-1 focus:bg-gray-50 focus:text-red-600"
                      onClick={(e) => {
                        setIsEditing(true);
                      }}
                    >
                      <Pencil2Icon className="h-3.5 w-3.5 shrink-0" />
                      <span className="ml-2 text-sm">Edit name</span>
                    </DropdownMenuItem>

                    {/* <DropdownMenuItem>Delete</DropdownMenuItem> */}
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            )}

            <Button
              variant={"ghost"}
              className="h-7 w-7 rounded hover:bg-gray-200/60"
              onClick={() => {
                if (journeyCtx.state.isExternal) {
                  newTaskForExternalUserCtx.dispatch({
                    type: "open_update",
                    projectId: phase._id,
                    stageId: stage?._id?.toString(),
                  });
                } else {
                  newTaskForInternalUserCtx.dispatch({
                    type: "open_update",
                    projectId: phase._id,
                    stageId: stage?._id?.toString(),
                  });
                }
              }}
            >
              <PlusIcon className="h-3.5 w-3.5 shrink-0" />
            </Button>
            <Dialog
              open={isEditing}
              onOpenChange={(open) => {
                setIsEditing(open);
              }}
            >
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Editing Stage</DialogTitle>
                </DialogHeader>
                <div className="space-y-4 py-6">
                  <div className="space-y-1">
                    <InputHeading heading="Name" />
                    <TextInput
                      value={newStageName}
                      onChange={(e) => {
                        setNewStageName(e.target.value);
                      }}
                      onBlur={() => {
                        if (newStageName.trim() === "") {
                          setNewStageName(stage?.name ?? "");
                        } else if (newStageName.trim() !== stage?.name.trim()) {
                          if (stage && stage._id) {
                            // update stage name
                            updateJourneyStageMutation.mutate({
                              params: {
                                _id: stage._id,
                                name: newStageName,
                              },
                              metadata: {
                                journeyId: stage.journeyId.toString(),
                              },
                            });
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
      <div className="flex-1 space-y-1 overflow-auto px-1 pb-6">
        {sortedTasks
          // .filter((t) =>
          //   journeyCtx.state.view.view === "list" ? !t.parentTask : true
          // )
          .filter((t) => {
            if (journeyCtx.state.view.responsibility === "all") {
              return true;
            } else if (journeyCtx.state.view.responsibility === "internal") {
              return t.taskerType === "internal";
            } else if (journeyCtx.state.view.responsibility === "external") {
              return t.taskerType === "external";
            } else if (journeyCtx.state.view.responsibility === "my") {
              return (
                t.tasker.userType === "fullUser" &&
                t.tasker._id.toString() === app.currentUser.id
              );
            }
          })
          .filter((t) => {
            if (journeyCtx.state.view.showOnlyActiveAndIncompleteTasks) {
              // console.log(t.title, !!t.dueDate, t.status !== "Completed");
              return (
                !!t.dueDate &&
                t.status !== "Completed" &&
                t.status !== "Canceled"
              );
            } else {
              return true;
            }
          })
          // .filter((t) => {
          //   if (journeyCtx.state.view.showInactive) {
          //     return true;
          //   } else {
          //     return !!t.dueDate;
          //   }
          // })
          // .filter((t) => {
          //   if (journeyCtx.state.view.showCompleted) {
          //     return true;
          //   } else {
          //     return t.status !== "Completed";
          //   }
          // })
          .filter((t) => {
            if (!journeyCtx.state.isExternal) return true;
            return t.isVisible;
          })
          // .filter((t) => {
          //   if (journeyCtx.state.view.showSubtasks) {
          //     return !t.parentTask;
          //   } else {
          //     return true;
          //   }
          // })
          .map((task) => (
            <>
              {!!task.parentTask?._id ? (
                <div className="pl-6">
                  <TaskCard task={task} />
                </div>
              ) : (
                <TaskCard task={task} />
              )}
            </>
          ))}
      </div>
    </div>
  );
}

function TasksAssignment({ phaseId }: { phaseId: string }) {
  const { data: tasks } = useGetPhaseTasks(phaseId);
  return <></>;
  // return <div className="bg-red-500">unassigned tasks button</div>;
  // return <>{!!tasks && <TasksAssignmentButton tasks={tasks} />}</>;
}

// function TasksAssignmentButton({ tasks }: { tasks: TaskData[] }) {
//   const { slug } = useParams();
//   let unassginedTasks = tasks.filter((t) => t.tasker.userType === "persona");
//   if (!!slug) {
//     unassginedTasks = unassginedTasks.filter(
//       (t) => t.taskerType === "external"
//     );
//   }
//   const [isOpen, setIsOpen] = useState(false);
//   return (
//     <>
//       {unassginedTasks.length > 0 && (
//         <button
//           className="inline-flex space-x-2 rounded-md border border-red-500 px-2.5 py-1.5 text-xs font-medium text-red-500 hover:bg-red-50"
//           onClick={() => {
//             setIsOpen(true);
//           }}
//         >
//           <ExclamationCircleIcon className="h-4 w-4" />
//           <span>{`${unassginedTasks.length} Tasks Unassigned`}</span>
//         </button>
//       )}
//       <Modal
//         closeModal={() => {
//           setIsOpen(false);
//         }}
//         isOpen={isOpen}
//       >
//         <div className="p-6">
//           <ModalHeading title="Unassigned Tasks" />
//           <div className="mt-6 space-y-4">
//             <div className="flex gap-6 text-sm font-semibold">
//               <div className="w-80">{"Task"}</div>
//               <div className="w-32">{"Persona"}</div>
//               <div className="w-32">{"Tasker"}</div>
//             </div>
//             {unassginedTasks.map((task) => (
//               <UnassignedTaskCard
//                 key={task._id?.toString() ?? Math.random()}
//                 task={task}
//               />
//             ))}
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// }

// function UnassignedTaskCard({ task }: { task: TaskData }) {
//   const updateTaskMutation = useUpdateTask();

//   return (
//     <div className="flex items-center gap-6 text-sm text-gray-600">
//       <div className="w-80 truncate">{task.title}</div>
//       <div className="w-32">
//         {task.tasker.userType === "persona" && (
//           <div
//             className={clsx(
//               "w-fit max-w-full truncate rounded-full px-2.5 py-1 text-xs",
//               task.taskerType === "external"
//                 ? "bg-slate-700 text-white"
//                 : "border-gray-500 bg-white text-gray-500"
//             )}
//           >
//             {task.tasker.persona}
//           </div>
//         )}
//       </div>
//       <div className="w-[200px]">
//         <Tasker
//           task={task}
//           onChange={({ task, taskerType, taskerEmail, taskerId }) => {
//             if (taskerId) {
//               // console.log({
//               //   _id: task._id,
//               //   taskerId: taskerId,
//               //   taskerType: taskerType,
//               // });
//               if (!task._id) return;
//               updateTaskMutation.mutate({
//                 update: {
//                   _id: task._id,
//                   taskerId: taskerId.toString(),
//                   taskerType: taskerType,
//                 },
//                 metadata: {
//                   phaseId: task?.phase?._id,
//                   journeyId: task?.journey?._id,
//                 },
//               });
//             } else if (taskerEmail) {
//               // console.log({
//               //   _id: task._id,
//               //   taskerEmail: taskerEmail,
//               //   taskerType: taskerType,
//               // });
//               if (!task._id) return;
//               updateTaskMutation.mutate({
//                 update: {
//                   _id: task._id,
//                   taskerEmail: taskerEmail,
//                   taskerType: taskerType,
//                 },
//                 metadata: {
//                   phaseId: task?.phase?._id,
//                   journeyId: task?.journey?._id,
//                 },
//               });
//             }
//           }}
//           // hasLabel={false}
//         />
//       </div>
//     </div>
//   );
// }

// const elements: { text: string; onClick: () => void }[] = [
//   { text: "Due Date", onClick: () => {} },
//   { text: "Estimates", onClick: () => {} },
//   { text: "Time Left", onClick: () => {} },
// ];

// function TeamFilter({
//   instanceName,
//   customerName,
// }: {
//   instanceName: string;
//   customerName: string;
// }) {
//   const journeyCtx = useJourney();
//   return (
//     <Pop
//       isActive={journeyCtx.state.view.responsibility !== "all"}
//       onCancelClick={() => {
//         journeyCtx.dispatch({
//           type: "VIEW_TEAM_UPDATE",
//           responsibility: "all",
//         });
//       }}
//       openTo={"right"}
//       button={
//         <span>
//           {journeyCtx.state.view.responsibility === "all"
//             ? "All"
//             : journeyCtx.state.view.responsibility === "my"
//             ? "Me"
//             : journeyCtx.state.view.responsibility === "internal"
//             ? instanceName
//             : customerName}
//         </span>
//       }
//       options={
//         <div className="space-y-3">
//           <p className="font-medium text-gray-700">
//             Whose tasks do you want to see?
//           </p>
//           <div className="flex gap-2">
//             <Pill
//               active={journeyCtx.state.view.responsibility === "all"}
//               onClick={() => {
//                 journeyCtx.dispatch({
//                   type: "VIEW_TEAM_UPDATE",
//                   responsibility: "all",
//                 });
//               }}
//               text="All"
//             />
//             <Pill
//               active={journeyCtx.state.view.responsibility === "my"}
//               onClick={() => {
//                 journeyCtx.dispatch({
//                   type: "VIEW_TEAM_UPDATE",
//                   responsibility: "my",
//                 });
//               }}
//               text={"Me"}
//             />
//             <Pill
//               active={journeyCtx.state.view.responsibility === "internal"}
//               onClick={() => {
//                 journeyCtx.dispatch({
//                   type: "VIEW_TEAM_UPDATE",
//                   responsibility: "internal",
//                 });
//               }}
//               text={instanceName}
//             />
//             <Pill
//               active={journeyCtx.state.view.responsibility === "external"}
//               onClick={() => {
//                 journeyCtx.dispatch({
//                   type: "VIEW_TEAM_UPDATE",
//                   responsibility: "external",
//                 });
//               }}
//               text={customerName}
//             />
//           </div>
//         </div>
//       }
//     />
//   );
// }

// function TaskStatusFilter() {
//   const journeyCtx = useJourney();
//   return (
//     <Pop
//       isActive={journeyCtx.state.view.status !== "All"}
//       onCancelClick={() => {
//         journeyCtx.dispatch({
//           type: "VIEW_STATUS_UPDATE",
//           status: "All",
//         });
//       }}
//       openTo={"right"}
//       button={<span>{journeyCtx.state.view.status}</span>}
//       options={
//         <div className="space-y-3">
//           <p className="font-medium  text-gray-700">
//             Which tasks do you want to see?
//           </p>
//           <div className="flex flex-wrap gap-2">
//             <Pill
//               active={journeyCtx.state.view.status === "All"}
//               onClick={() => {
//                 journeyCtx.dispatch({
//                   type: "VIEW_STATUS_UPDATE",
//                   status: "All",
//                 });
//               }}
//               text="All"
//             />
//             <Pill
//               active={journeyCtx.state.view.status === "To Do"}
//               onClick={() => {
//                 journeyCtx.dispatch({
//                   type: "VIEW_STATUS_UPDATE",
//                   status: "To Do",
//                 });
//               }}
//               text="To Do"
//             />
//             <Pill
//               active={journeyCtx.state.view.status === "In Progress"}
//               onClick={() => {
//                 journeyCtx.dispatch({
//                   type: "VIEW_STATUS_UPDATE",
//                   status: "In Progress",
//                 });
//               }}
//               text="In Progress"
//             />
//             <Pill
//               active={journeyCtx.state.view.status === "Completed"}
//               onClick={() => {
//                 journeyCtx.dispatch({
//                   type: "VIEW_STATUS_UPDATE",
//                   status: "Completed",
//                 });
//               }}
//               text="Completed"
//             />
//             <Pill
//               active={journeyCtx.state.view.status === "Canceled"}
//               onClick={() => {
//                 journeyCtx.dispatch({
//                   type: "VIEW_STATUS_UPDATE",
//                   status: "Canceled",
//                 });
//               }}
//               text="Canceled"
//             />
//           </div>
//         </div>
//       }
//     />
//   );
// }

// function TaskStateFilter() {
//   const journeyCtx = useJourney();
//   return (
//     <Pop
//       isActive={journeyCtx.state.view.state !== "all"}
//       onCancelClick={() => {
//         journeyCtx.dispatch({
//           type: "VIEW_STATE_UPDATE",
//           state: "all",
//         });
//       }}
//       openTo={"right"}
//       button={
//         <span>
//           {journeyCtx.state.view.state === "active"
//             ? "Active"
//             : journeyCtx.state.view.state === "dependent"
//             ? "Dependent"
//             : "All"}
//         </span>
//       }
//       options={
//         <div className="space-y-3">
//           <p className="font-medium text-gray-700">
//             Which tasks do you want to see?
//           </p>
//           <div className="flex gap-2">
//             <Pill
//               active={journeyCtx.state.view.state === "all"}
//               onClick={() => {
//                 journeyCtx.dispatch({
//                   type: "VIEW_STATE_UPDATE",
//                   state: "all",
//                 });
//               }}
//               text="All"
//             />
//             <Pill
//               active={journeyCtx.state.view.state === "active"}
//               onClick={() => {
//                 journeyCtx.dispatch({
//                   type: "VIEW_STATE_UPDATE",
//                   state: "active",
//                 });
//               }}
//               text="Active"
//             />
//             <Pill
//               active={journeyCtx.state.view.state === "dependent"}
//               onClick={() => {
//                 journeyCtx.dispatch({
//                   type: "VIEW_STATE_UPDATE",
//                   state: "dependent",
//                 });
//               }}
//               text="Dependent"
//             />
//           </div>
//         </div>
//       }
//     />
//   );
// }

// function Pop({
//   button,
//   options,
//   openTo = "right",
//   isActive,
//   onCancelClick,
// }: {
//   button: JSX.Element;
//   options: JSX.Element;
//   openTo?: "left" | "right";
//   isActive: boolean;
//   onCancelClick: () => void;
// }) {
//   return (
//     <Popover className="relative text-sm">
//       {({ open }) => (
//         <>
//           <Popover.Button
//             className={clsx(
//               "flex items-center space-x-2 rounded-md border px-3 py-1 text-gray-600",
//               isActive && "border-primary-main text-primary-main"
//             )}
//           >
//             {button}
//             {isActive && (
//               <button
//                 onClick={(e) => {
//                   onCancelClick();
//                   e.stopPropagation();
//                 }}
//                 className="rounded-full p-1 hover:bg-primary-100"
//               >
//                 <XMarkIcon className="h-3 w-3 text-primary-main" />
//               </button>
//             )}
//             <ChevronUpIcon
//               className={clsx(
//                 open ? "rotate-180 text-gray-500" : "text-gray-400",
//                 "h-3 w-3 transition duration-300 ease-in-out"
//               )}
//               aria-hidden="true"
//             />
//           </Popover.Button>
//           <Transition.Child
//             as={Fragment}
//             enter="ease-out duration-300"
//             enterFrom="opacity-0"
//             enterTo="opacity-100"
//             leave="ease-in duration-200"
//             leaveFrom="opacity-100"
//             leaveTo="opacity-0"
//           >
//             <div className="fixed inset-0 z-10 bg-black bg-opacity-25" />
//           </Transition.Child>
//           <Transition
//             as={Fragment}
//             enter="transition ease-out duration-200"
//             enterFrom="opacity-0 translate-y-1"
//             enterTo="opacity-100 translate-y-0"
//             leave="transition ease-in duration-150"
//             leaveFrom="opacity-100 translate-y-0"
//             leaveTo="opacity-0 translate-y-1"
//           >
//             <Popover.Panel
//               className={clsx(
//                 "absolute z-10 mt-3 w-72",
//                 openTo === "left" ? "right-0" : "left-0"
//               )}
//             >
//               <div className="divide-y rounded-lg bg-white px-4 py-4 shadow-md">
//                 {options}
//               </div>
//             </Popover.Panel>
//           </Transition>
//         </>
//       )}
//     </Popover>
//   );
// }

function DisplayOptions() {
  // const displayOptions = getLocalStorageDisplaySettings();
  const journeyCtx = useJourney();
  return (
    <></>
    // <Popover className="relative text-sm">
    //   {({ open }) => (
    //     <>
    //       <Popover.Button
    //         className={
    //           "flex items-center space-x-2 rounded-md border px-3 py-1 text-gray-600"
    //         }
    //       >
    //         <span>Filters</span>
    //         <div className="w-4">
    //           {[
    //             journeyCtx.state.view.showSubtasks,
    //             journeyCtx.state.view.showCompleted,
    //             journeyCtx.state.view.showInactive,
    //           ].filter((e) => !e).length > 0 && (
    //             <span
    //               className={clsx(
    //                 "w-4 font-medium",
    //                 journeyCtx.state.isExternal
    //                   ? "text-embedPrimary"
    //                   : "text-primary-main"
    //               )}
    //             >
    //               {
    //                 [
    //                   journeyCtx.state.view.showSubtasks,
    //                   journeyCtx.state.view.showCompleted,
    //                   journeyCtx.state.view.showInactive,
    //                 ].filter((e) => !e).length
    //               }
    //             </span>
    //           )}
    //         </div>
    //         <ChevronDownIcon className={"h-3 w-3"} aria-hidden="true" />
    //       </Popover.Button>
    //       <Transition.Child
    //         as={Fragment}
    //         enter="ease-out duration-300"
    //         enterFrom="opacity-0"
    //         enterTo="opacity-100"
    //         leave="ease-in duration-200"
    //         leaveFrom="opacity-100"
    //         leaveTo="opacity-0"
    //       >
    //         <div className="fixed inset-0 z-10 bg-black bg-opacity-10" />
    //       </Transition.Child>
    //       <Transition
    //         as={Fragment}
    //         enter="transition ease-out duration-200"
    //         enterFrom="opacity-0 translate-y-1"
    //         enterTo="opacity-100 translate-y-0"
    //         leave="transition ease-in duration-150"
    //         leaveFrom="opacity-100 translate-y-0"
    //         leaveTo="opacity-0 translate-y-1"
    //       >
    //         <Popover.Panel className="absolute left-0 z-10 mt-3 w-72">
    //           <div className="divide-y rounded-lg bg-white px-4 py-4 shadow-md">
    //             <div className="space-y-3">
    //               <div className="text-md font-semibold">View</div>
    //               <div className="flex items-center justify-between">
    //                 <div className="text-gray-600">Hide Subtasks</div>
    //                 <VariantToggle
    //                   size="sm"
    //                   enabled={!journeyCtx.state.view.showSubtasks}
    //                   setEnabled={() => {
    //                     journeyCtx.dispatch({
    //                       type: "VIEW_SHOW_SUBTASKS_UPDATE",
    //                       showSubtasks: !journeyCtx.state.view.showSubtasks,
    //                     });
    //                   }}
    //                   color={journeyCtx.state.isExternal ? "embed" : "primary"}
    //                   focus={false}
    //                 />
    //               </div>
    //               <div className="flex items-center justify-between">
    //                 <div className="text-gray-600">Hide Completed Tasks</div>
    //                 <VariantToggle
    //                   size="sm"
    //                   enabled={!journeyCtx.state.view.showCompleted}
    //                   setEnabled={() => {
    //                     journeyCtx.dispatch({
    //                       type: "VIEW_COMPLETED_UPDATE",
    //                       showCompleted: !journeyCtx.state.view.showCompleted,
    //                     });
    //                   }}
    //                   focus={false}
    //                   color={journeyCtx.state.isExternal ? "embed" : "primary"}
    //                 />
    //               </div>
    //               <div className="flex items-center justify-between">
    //                 <div className="text-gray-600">Hide Inactive Tasks</div>
    //                 <VariantToggle
    //                   size="sm"
    //                   enabled={!journeyCtx.state.view.showInactive}
    //                   setEnabled={() => {
    //                     journeyCtx.dispatch({
    //                       type: "VIEW_SHOW_INACTIVE_UPDATE",
    //                       showInactive: !journeyCtx.state.view.showInactive,
    //                     });
    //                   }}
    //                   color={journeyCtx.state.isExternal ? "embed" : "primary"}
    //                   focus={false}
    //                 />
    //               </div>
    //               {/* <div className="flex items-center justify-between">
    //                 <div>View</div>
    //                 <div className="flex items-center space-x-2">
    //                   <Pill text="Indented" onClick={() => {}} active={true} />
    //                   <Pill
    //                     text="Collapsible"
    //                     onClick={() => {}}
    //                     active={false}
    //                   />
    //                   <div className="rounded border px-2 py-1 text-xs">
    //                     Collapsible
    //                   </div>
    //                 </div>
    //               </div> */}
    //             </div>
    //             {/* <div className="mt-3 pt-3">
    //               <div className="space-y-3">
    //                 <div className="text-md font-semibold">All Tasks</div>
    //                 <div className="flex gap-2">
    //                   {elements.map((element) => (
    //                     <Pill
    //                       text={element.text}
    //                       onClick={element.onClick}
    //                       active={false}
    //                     />
    //                   ))}
    //                 </div>
    //               </div>
    //             </div> */}
    //           </div>
    //         </Popover.Panel>
    //       </Transition>
    //     </>
    //   )}
    // </Popover>
  );
}

function Pill({
  text,
  onClick,
  disabled = false,
  active,
}: {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  active: boolean;
}) {
  return (
    <div
      className={clsx(
        "cursor-default rounded border px-2 py-1 text-xs text-gray-600",
        disabled
          ? "bg-gray-200"
          : active
          ? "border-primary-400 text-primary-main"
          : "hover:border-primary-400  cursor-pointer hover:text-primary-main"
      )}
      onClick={onClick}
    >
      {text}
    </div>
  );
}

const JourneyStage = ({ stage }: { stage: JourneyStageObj }) => {
  return (
    <div className="flex w-[360px] flex-1 flex-col overflow-auto">
      {/* <div className="px-4"> */}
      {!!stage._id && (
        <JourneyStageTasks stage={stage} stageId={stage._id.toString()} />
      )}
      {/* </div> */}
    </div>
  );
};

const JourneyStageTasks = ({
  stage,
  stageId,
}: {
  stage: JourneyStageObj;
  stageId: string;
}) => {
  // get journey tasks
  const { data: tasks } = useGetJourneyStageTasks(
    stage.journeyId.toString(),
    stageId
  );
  const journeyCtx = useJourney();
  const app = useRealmApp();
  const { slug } = useParams();
  // console.log(tasks);
  // console.log(app.currentUser)
  return (
    <>
      {tasks && (
        <div className="flex flex-1 flex-col space-y-5 overflow-auto">
          <StageMetaInfo tasks={tasks} stage={stage} />
          <div className="flex-1 space-y-4 overflow-auto pb-6">
            {tasks?.length === 0 && (
              <div className="flex justify-center px-6">
                <p className="text-sm text-gray-400">No tasks in this stage</p>
              </div>
            )}
            {tasks
              .filter((t) => {
                if (journeyCtx.state.view.responsibility === "all") {
                  return true;
                } else if (
                  journeyCtx.state.view.responsibility === "internal"
                ) {
                  return t.taskerType === "internal";
                } else if (
                  journeyCtx.state.view.responsibility === "external"
                ) {
                  return t.taskerType === "external";
                } else if (journeyCtx.state.view.responsibility === "my") {
                  return (
                    t.tasker.userType === "fullUser" &&
                    t.tasker._id.toString() === app.currentUser.id
                  );
                }
              })
              .filter((t) => {
                if (journeyCtx.state.view.showOnlyActiveAndIncompleteTasks) {
                  // console.log(t.title, !!t.dueDate, t.status !== "Completed");
                  return !!t.dueDate && t.status !== "Completed";
                } else {
                  return true;
                }
              })
              .filter((t) => {
                if (journeyCtx.state.view.showInactive) {
                  return true;
                } else {
                  return !!t.dueDate;
                }
              })
              .filter((t) => {
                if (journeyCtx.state.view.showCompleted) {
                  return true;
                } else {
                  return t.status !== "Completed";
                }
              })
              .filter((t) => {
                if (!journeyCtx.state.isExternal) return true;
                return t.isVisible;
              })
              .filter((t) => {
                if (journeyCtx.state.view.showSubtasks) {
                  return true;
                } else {
                  return !t.parentTask;
                }
              })
              .map((task) => (
                <TaskCardWithSubtasks
                  allTasks={tasks}
                  key={task._id ? task._id.toString() : Math.random()}
                  task={task}
                />
                // <JourneyTask
                //   key={task._id ? task._id.toString() : Math.random()}
                //   task={task}
                //   onCellClick={onCellClick}
                // />
              ))}
          </div>
        </div>
      )}
    </>
  );
};

const TaskCardWithSubtasks = ({
  task,
  allTasks,
}: {
  task: TaskData;
  allTasks: TaskData[];
}) => {
  const journeyCtx = useJourney();
  return (
    <div className="space-y-2">
      <TaskCard task={task} />
      {task.subTaskCount && task.subTaskCount > 0 && (
        <div className="space-y-2">
          {allTasks
            .filter(
              (t) => t.parentTask?._id.toString() === task._id?.toString()
            )
            .filter((t) => {
              if (!journeyCtx.state.isExternal) return true;
              return t.isVisible;
            })
            .filter((t) => {
              if (journeyCtx.state.view.showOnlyActiveAndIncompleteTasks) {
                return !!t.dueDate && t.status !== "Completed";
              } else {
                return true;
              }
            })
            .map((t) => (
              <div key={t._id?.toString() ?? Math.random()} className="pl-6">
                <TaskCard
                  task={t}
                  // isSubTask={true}
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

const StageMetaInfo = ({
  tasks,
  stage,
}: {
  tasks: TaskData[];
  stage: JourneyStageObj;
}) => {
  const minDate = getMinDate({ tasks });
  const maxDate = getMaxDate({ tasks });

  const hasTasksActive = tasks.filter((t) => t.dueDate).length > 0;
  const completedTasks = tasks.filter((t) => t.completionDate);
  const progressPercentage =
    tasks.length > 0 ? (completedTasks.length / tasks.length) * 100 : 0;
  // console.log(progressPercentage);
  return (
    <div className="mx-6 space-y-2">
      <div className="flex items-center justify-between gap-4">
        <div className="truncate whitespace-nowrap border border-transparent text-lg font-medium capitalize tracking-wide">
          {stage.name}
        </div>
        <div className="flex items-center space-x-2">
          <span className="whitespace-nowrap text-xs text-gray-500">{`${completedTasks.length}/${tasks.length}`}</span>

          {/* <Button variant={"secondaryLink"} className="px-0">
            <PlusIcon className="h-4 w-4 text-gray-500" />
          </Button> */}
        </div>
      </div>
      {/* <div className="flex items-end justify-between">
        <div className="flex justify-between">
          {minDate && maxDate  (
            <p className="text-xs text-gray-500">
              {`${dayjs(minDate).format("MMM DD")} - ${dayjs(maxDate).format(
                "MMM DD"
              )}`}
            </p>
          ) : (
            <p className="text-xs text-gray-500">{`Waiting on Dependency`}</p>
          )}
        </div>
      </div> */}
      <div
        className={clsx(
          "w-full rounded-sm",
          hasTasksActive ? "bg-green-200/70" : "bg-gray-100"
        )}
      >
        <div
          className={clsx(
            "h-2 rounded-sm",
            progressPercentage === 100 ? "bg-green-500" : "bg-green-500"
          )}
          style={{
            width: `${progressPercentage}%`,
          }}
        />
      </div>
    </div>
  );
};

function getMinDate({ tasks }: { tasks: TaskData[] }) {
  let startingMinDate: undefined | Date = undefined;
  for (let i = 0; i < tasks.length; i++) {
    const task = tasks[i];
    if (task.dueDate) {
      if (!startingMinDate) {
        startingMinDate = new Date(task.dueDate);
        break;
      }
    }
  }
  if (!startingMinDate) {
    return undefined;
  } else {
    return tasks.reduce(
      (min, p) =>
        p.dueDate
          ? new Date(p.dueDate) < min
            ? new Date(p.dueDate)
            : min
          : min,
      startingMinDate
    );
  }
}

function getMaxDate({ tasks }: { tasks: TaskData[] }) {
  let startingMaxDate: undefined | Date = undefined;
  for (let i = 0; i < tasks.length; i++) {
    const task = tasks[i];
    if (task.dueDate) {
      if (!startingMaxDate) {
        startingMaxDate = new Date(task.dueDate);
        break;
      }
    }
  }
  if (!startingMaxDate) {
    return undefined;
  } else {
    return tasks.reduce(
      (max, p) =>
        p.dueDate
          ? new Date(p.dueDate) > max
            ? new Date(p.dueDate)
            : max
          : max,
      startingMaxDate
    );
  }
}
