import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import axios from "axios";
import { useSnackBar } from "src/components/Reusable/CustomSnackbarProvider";
import { useRealmApp } from "src/store/RealmApp";
import { ProjectsFilter } from "../Services/Projects/projects";
import { PhaseStatus } from "../Services/SharedSpace/phases";
import { Status } from "../Services/Tasks/tasks";
import { useAccessToken } from "../useAccessToken";

const viewKeys = {
  views: () => ["views"] as const,
  projectViews: () => ["views", "project"] as const,
};

export type ViewData = ProjectViewData | TaskViewData | AnalyticsViewData;

type BaseViewData = {
  _id: string;
  name: string;
  isShared: boolean;
  isDefault: boolean;
  createdAt: string;
  userId: string;
  instanceId: string;
  updatedAt: string;
};

type ProjectViewData = BaseViewData & {
  type: "project";
  filter: ProjectsFilter;
};

type toDueDateFilter =
  | toDueDateFilterWithDate
  | toDueDateFilterWithRealtiveDate;

type toDueDateFilterWithDate = {
  type: "date";
  value: string;
};

type toDueDateFilterWithRealtiveDate = {
  type: "relative";
  value: "today" | "next week" | "next month";
};

type TaskViewData = BaseViewData & {
  type: "task";
  filter: {
    taskerIds: string[];
    status: Status;
    toDueDate: toDueDateFilter;
    fromCreatedAt: string;
    toCreatedAt: string;
  };
};

type AnalyticsViewData = BaseViewData & {
  type: "analytics";
  guide: "pipeline-review";
  filter: {
    ownerUserIds: string[];
  };
  startDate: string;
};

export const useGetViews = () => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    viewKeys.views(),
    async (): Promise<ViewData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/views`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          params: {
            instanceId: app.currentUser.customData.instanceId.$oid,
          },
        }
      );
      return res.data;
    },
    {
      staleTime: 1000 * 60 * 60,
    }
  );
};

export const useGetProjectViews = () => {
  const app = useRealmApp();
  const getValidAccessToken = useAccessToken();
  return useQuery(
    viewKeys.projectViews(),
    async (): Promise<ProjectViewData[]> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.get(
        // `https://fb8xf9wmy6.execute-api.us-east-1.amazonaws.com/development/feedback/getAll`,
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/projectViews`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
        }
      );
      return res.data;
    },
    {
      staleTime: 1000 * 60 * 60,
    }
  );
};

export const useCreateProjectView = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (params: {
      name: string;
      filter: {
        customerIds?: string[];
        internalMemberIds?: string[];
        status: PhaseStatus[];
        ownerUserIds?: string[];
      };
      isShared: boolean;
    }): Promise<{ message: string; status: number }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/projectView`,
        params,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error saving view", "error");
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(viewKeys.views());
      snackbarCtx.showSnackbar("View saved", "success");
    },
  });
};

export const useUpdateProjectView = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (params: {
      _id: string;
      name?: string;
      filter?: {
        customerIds?: string[];
        internalMemberIds?: string[];
        status: PhaseStatus[];
        ownerUserIds?: string[];
      };
      isShared?: boolean;
    }): Promise<{ message: string; status: number }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.post(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/updateProjectView`,
        params,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error updating view", "error");
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(viewKeys.views());
    },
  });
};

export const useDeleteProjectView = () => {
  const app = useRealmApp();
  const snackbarCtx = useSnackBar();
  const queryClient = useQueryClient();
  const getValidAccessToken = useAccessToken();

  return useMutation({
    mutationFn: async (params: {
      _id: string;
    }): Promise<{ message: string; status: number }> => {
      const accessToken = await getValidAccessToken();
      const res = await axios.delete(
        `https://us-east-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_APP_ID}/endpoint/projectView`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          params,
        }
      );
      return res.data;
    },
    onError: () => {
      snackbarCtx.showSnackbar("Error deleting view", "error");
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(viewKeys.views());
      // snackbarCtx.showSnackbar("View saved", "success");
    },
  });
};
