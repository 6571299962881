import { FunnelIcon } from "@heroicons/react/24/outline";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
  SlashIcon,
} from "@radix-ui/react-icons";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetCompanies } from "src/api/Companies/companies";
import { useCreateCustomer } from "src/api/General/customers";
import InputHeading from "src/components/ui/Headings/InputHeading";
import { Button } from "src/components/ui/button";
import { Count } from "src/components/ui/count";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import { Loading } from "src/components/ui/loading";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import { TextInput } from "src/components/ui/text-input";
import { classNames } from "src/utils/functions/classes";
import CompaniesTable from "./companies-table";

type Logic = "AND" | "OR";

type Filter = {
  field: "name";
  comparator: "eq";
  value: string | null;
};

type FilterGroup = {
  logic: Logic;
  children: Filter[];
};

export default function Companies() {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  // const [value, setValue] = useState("");
  const [actualPageNo, setActualPageNo] = useState(1);
  const [displayedPageNo, setDisplayedPageNo] = useState(1);

  const [filter, setFilter] = useState<FilterGroup>(() => {
    return {
      logic: "AND",
      children: [],
    };
    // const savedFilter = localStorage.getItem("companies_filter");
    // if (savedFilter) {
    //   return JSON.parse(savedFilter);
    // } else {
    //   return {
    //     logic: "AND",
    //     children: [],
    //   };
    // }
  });

  // Debouncing the search term
  useEffect(() => {
    setActualPageNo(1);
    const handler = setTimeout(() => {
      if (search.trim() === "") {
        setFilter({
          logic: "AND",
          children: [],
        });
        return;
      } else {
        if (filter.children.find((f) => f.field === "name")) {
          setFilter({
            logic: "AND",
            children: [
              ...filter.children.filter((f) => f.field !== "name"),
              {
                field: "name",
                comparator: "eq",
                value: search,
              },
            ],
          });
        } else {
          setFilter({
            logic: "AND",
            children: [
              ...filter.children,
              {
                field: "name",
                comparator: "eq",
                value: search,
              },
            ],
          });
        }
      }
    }, 300); // 300ms debounce time

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  const { data: paginatedCompanies, isLoading } = useGetCompanies(
    actualPageNo,
    JSON.stringify(filter)
  );

  useEffect(() => {
    if (!isLoading) {
      setDisplayedPageNo(actualPageNo);
    }
  }, [isLoading]);

  const [totalNoOfPages, setTotalNoOfPages] = useState(
    paginatedCompanies?.totalNoOfPages ?? 1
  );

  useEffect(() => {
    if (!!paginatedCompanies) {
      setTotalNoOfPages(paginatedCompanies.totalNoOfPages);
    }
  }, [paginatedCompanies?.totalNoOfPages]);
  // useEffect(() => {
  //   localStorage.setItem("companies_filter", JSON.stringify(filter));
  // }, [filter]);
  const [publicGroupsOpen, setPublicGroupsOpen] = useState(
    localStorage.getItem("publicGroupsOpen") === "true"
  );
  const [privateGroupsOpen, setPrivateGroupsOpen] = useState(
    localStorage.getItem("privateGroupsOpen") === "true"
  );

  useEffect(() => {
    localStorage.setItem("publicGroupsOpen", String(publicGroupsOpen));
  }, [publicGroupsOpen]);

  useEffect(() => {
    localStorage.setItem("privateGroupsOpen", String(privateGroupsOpen));
  }, [privateGroupsOpen]);

  const [newCompanyName, setNewCompanyName] = useState("");
  const createCustomerMutation = useCreateCustomer();
  return (
    <div className="flex h-full flex-col overflow-y-auto">
      <div className="flex h-12 shrink-0 items-center border-b border-gray-200/70 px-8">
        <div className="cursor-default text-sm text-gray-800">Companies</div>
      </div>

      <div className="flex flex-1 overflow-auto">
        {/* <div className="h-full w-60 border-r border-gray-200/70 px-3 pt-3">
          <div className="group cursor-pointer select-none pb-0.5">
            <div
              className={classNames(
                false
                  ? "bg-gray-100 text-gray-800"
                  : "text-gray-700 group-hover:bg-gray-100 group-hover:text-gray-800",
                "group flex h-[30px] items-center space-x-2 rounded-md px-3 text-sm"
              )}
            >
              <div className="">{"All Companies"}</div>
            </div>
          </div>
          <div className="pt-3">
            <div
              className="group flex h-6 cursor-pointer items-center space-x-1.5 rounded-md px-3 font-light text-gray-500 transition-colors hover:text-gray-700"
              onClick={() => {
                setPrivateGroupsOpen((prev) => !prev);
              }}
            >
              <div className="text-xs">My Hubs</div>
              <TriangleRightIcon
                className={clsx(
                  "h-3.5 w-3.5 text-gray-300 transition-all group-hover:text-gray-400/80",
                  privateGroupsOpen && "rotate-90 transition-transform"
                )}
              />
            </div>
            {privateGroupsOpen && (
              <div className="pt-0.5">
                <div className="group cursor-pointer select-none pb-0.5">
                  <div
                    className={classNames(
                      false
                        ? "bg-gray-100 text-gray-800"
                        : "text-gray-700 group-hover:bg-gray-100 group-hover:text-gray-800",
                      "group flex h-[30px] items-center space-x-2 rounded-md px-3 text-sm"
                    )}
                  >
                    
                    <div className="">{"SMB"}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="pt-3">
            <div
              className="group flex h-6 cursor-pointer items-center space-x-1.5 rounded-md px-3 font-light text-gray-500 transition-colors hover:text-gray-700"
              onClick={() => {
                setPublicGroupsOpen((prev) => !prev);
              }}
            >
              <div className="text-xs">All Hubs</div>
              <TriangleRightIcon
                className={clsx(
                  "h-3.5 w-3.5 text-gray-300 transition-all group-hover:text-gray-400/80",
                  publicGroupsOpen && "rotate-90 transition-transform"
                )}
              />
            </div>
            {publicGroupsOpen && (
              <div className="pt-0.5">
                <div className="group cursor-pointer select-none pb-0.5">
                  <div
                    className={classNames(
                      false
                        ? "bg-gray-100 text-gray-800"
                        : "text-gray-700 group-hover:bg-gray-100 group-hover:text-gray-800",
                      "group flex h-[30px] items-center space-x-2 rounded-md px-3 text-sm"
                    )}
                  >
                    <div className="">{"Enterprise Customers"}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div> */}
        <div className="flex flex-1 flex-col overflow-auto">
          <div className="flex h-16 shrink-0 items-center justify-between border-b border-gray-200/70 px-8">
            <div className="flex items-center space-x-2">
              {/* <Filters /> */}
              {/* <TextInput
            className="h-8 w-72 pl-8"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Search.."
            autoFocus
          /> */}
              <div className="relative">
                <TextInput
                  className="h-8 w-72 pl-8"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  placeholder="Search.."
                  autoFocus
                />
                <div className="absolute inset-y-0 left-0 flex items-center pl-2.5">
                  <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" />
                </div>
              </div>
              <div
                className={clsx(
                  "flex h-8 items-center space-x-2 overflow-hidden rounded-md border border-input shadow-sm",
                  isLoading && "opacity-50"
                )}
              >
                <div
                  className={clsx(
                    "flex h-full w-8 items-center justify-center border-r border-input transition-all",
                    displayedPageNo === 1
                      ? "cursor-not-allowed text-gray-300"
                      : "cursor-pointer text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                  )}
                  onClick={() => {
                    if (displayedPageNo === 1) return;
                    setDisplayedPageNo((prev) => prev - 1);
                    setActualPageNo((prev) => prev - 1);
                  }}
                >
                  <ChevronLeftIcon className="h-4 w-4" />
                </div>
                <div className="flex select-none items-center space-x-1 px-1 text-sm text-gray-600">
                  <div className="min-w-[12px] text-center">
                    {displayedPageNo}
                  </div>
                  <SlashIcon className="h-3 w-3 text-gray-500" />
                  <div className="min-w-[12px] text-center">
                    {totalNoOfPages}
                  </div>
                </div>
                <div
                  className={clsx(
                    "flex h-full w-8 items-center justify-center border-l border-input transition-all",
                    displayedPageNo === totalNoOfPages
                      ? "cursor-not-allowed text-gray-300"
                      : "cursor-pointer text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                  )}
                  onClick={() => {
                    if (displayedPageNo === totalNoOfPages) return;
                    setDisplayedPageNo((prev) => prev + 1);
                    setActualPageNo((prev) => prev + 1);
                  }}
                >
                  <ChevronRightIcon className="h-4 w-4" />
                </div>
                {/* <Button variant={"ghost"} size={"icon"} className="h-6 w-6">
                  <ChevronLeftIcon className="h-4 w-4" />
                </Button>
                <div>{pageNo}</div>
                <div>/</div>
                <div>{paginatedCompanies?.totalNoOfPages}</div>
                <Button variant={"ghost"} size={"icon"} className="h-6 w-6">
                  <ChevronRightIcon className="h-4 w-4" />
                </Button> */}
              </div>
              {/* <Filters view={view} setFilter={setFilter} filter={filter} />
          <Display setSort={setSort} sort={sort} />
          <ViewSelector view={view} setView={setView} options={viewOptions} /> */}
              {/* <Button variant="secondary" onClick={() => {}} size="sm">
            <ArrowsUpDownIcon className="mr-1.5 h-3.5 w-4" />
            Sort
          </Button> */}
            </div>
            <div>
              <Dialog>
                <DialogTrigger asChild>
                  <Button variant="secondary" onClick={() => {}}>
                    New Company
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>New Company</DialogTitle>
                  </DialogHeader>
                  <div className="space-y-6 pt-4">
                    <div className="space-y-0.5">
                      <InputHeading heading="Name" />
                      <TextInput
                        className="w-72"
                        placeholder="Company Name"
                        value={newCompanyName}
                        onChange={(e) => {
                          setNewCompanyName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <Button
                      onClick={() => {
                        createCustomerMutation.mutate({
                          params: {
                            name: newCompanyName,
                            onChange: (customer) => {
                              navigate(`/companies/${customer._id}`);
                            },
                          },
                        });
                      }}
                      disabled={createCustomerMutation.isLoading}
                    >
                      {createCustomerMutation.isLoading
                        ? "Creating.."
                        : "Create"}
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          </div>

          {!!paginatedCompanies ? (
            <div className="flex flex-1 flex-col overflow-y-auto">
              <CompaniesTable paginatedCompanies={paginatedCompanies} />
            </div>
          ) : (
            <Loading className="mt-[20vh]" />
          )}
        </div>
      </div>
    </div>
  );
}

function Tab({
  // Icon,
  tab,
}: {
  // Icon: React.ReactNode;
  tab: {
    name: string;
    // route: string;
  };
}) {
  // const location = useLocation();
  const selected = false;
  return (
    <div className="group cursor-pointer select-none pb-0.5">
      <div
        className={classNames(
          selected
            ? "bg-gray-100 text-gray-800"
            : "text-gray-700 group-hover:bg-gray-100 group-hover:text-gray-800",
          "group flex h-[28px] items-center rounded-md px-2 text-sm"
        )}
      >
        <div className="">{tab.name}</div>
      </div>
    </div>
  );
}

function Filters() {
  const [open, setOpen] = useState(false);
  const filterCount = 2;
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="secondary" className={""}>
          <FunnelIcon className="mr-1.5 h-3.5 w-3.5" />
          Filter
          {filterCount > 0 && (
            <div className="ml-2">
              <Count variant={"primaryLight"}>{filterCount}</Count>
            </div>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent align="start" className="p-0"></PopoverContent>
    </Popover>
  );
}
