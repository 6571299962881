import { useQuery } from "@tanstack/react-query";
import { ObjectID } from "bson";
import { buildFetcher } from "..";
import { useRealmApp } from "../../store/RealmApp";
import { HubSpotProperty } from "../Integrations/hubspot";

export const useGetInstance = (): {
  instance:
    | {
        name: string;
        _id: ObjectID;
        slug: string;
        crmIntegration?: {
          integrationId: "hubspot" | "salesforce";
          oAuth: true;
          initialImportStatus: "Not Started" | "In Progress" | "Completed";
          initialImportStartedAt?: Date;
          initialImportCompletedAt?: Date;
        };
      }
    | undefined;
} => {
  const app = useRealmApp();
  const _id = app.currentUser.customData.instanceId;
  const meta = {
    functionName: "getInstanceDetails",
    parameters: {
      _id: _id,
    },
  };

  const { data } = useQuery<any>(["instances", _id], buildFetcher(app, meta), {
    staleTime: Infinity,
  });

  return {
    instance: data,
  };
};

export const useGetInstanceCRMStatus = (): {
  instance:
    | {
        name: string;
        _id: ObjectID;
        slug: string;
        crmIntegration?: {
          integrationId: "hubspot" | "salesforce";
          oAuth: true;
          initialImportStatus: "Not Started" | "In Progress" | "Completed";
          initialImportStartedAt?: Date;
          initialImportCompletedAt?: Date;
          companyProperties?: HubSpotProperty[];
          dealProperties?: HubSpotProperty[];
        };
      }
    | undefined;
} => {
  const app = useRealmApp();
  const _id = app.currentUser.customData.instanceId;
  const meta = {
    functionName: "getInstanceDetails",
    parameters: {
      _id: _id,
    },
  };

  const { data } = useQuery<any>(["crmStatus", _id], buildFetcher(app, meta));

  return {
    instance: data,
  };
};

export const useInstanceHasCustomers = (): {
  instanceHasCustomers: boolean | undefined;
  isLoadingInstanceHasCustomers: boolean;
} => {
  const app = useRealmApp();
  const _id = app.currentUser.customData.instanceId;
  const meta = {
    functionName: "instanceHasCustomers",
    parameters: {
      _id: _id,
    },
  };

  const { data, isLoading } = useQuery<any>(
    ["instanceHasCustomers"],
    buildFetcher(app, meta),
    {
      staleTime: Infinity,
    }
  );

  return {
    instanceHasCustomers: data,
    isLoadingInstanceHasCustomers: isLoading,
  };
};
