import "@tanstack/react-table";
import {
  Column,
  ColumnDef,
  FilterFn,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";
import { rankItem } from "@tanstack/match-sorter-utils";
import clsx from "clsx";
import dayjs from "dayjs";
import {
  CompanyData,
  PaginatedCompaniesData,
} from "src/api/Companies/companies";

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

type Props = {
  // filter: ProjectsFilter;
  // search: string;
  // setSearch: (search: string) => void;
  paginatedCompanies: PaginatedCompaniesData;
};

// type ProjectsUser =
//   | {
//       type: "all" | "user";
//       user?: UserResponseObj;
//     }
//   | undefined;

export default function CompaniesTable({ paginatedCompanies }: Props) {
  const [sorting, setSorting] = useState<SortingState>([]);
  // only projects with names that match the search string
  // const filteredProjects = projects?.filter((p) =>
  //   p.name.toLowerCase().includes(search.toLowerCase())
  // );
  const navigate = useNavigate();
  const table = useReactTable({
    data: paginatedCompanies?.companies ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      // globalFilter: search,
    },
    globalFilterFn: fuzzyFilter,
    onSortingChange: setSorting,
    // onGlobalFilterChange: setSearch,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  // const table = useReactTable({
  //   data:
  //     projects ?? [],
  //   columns,
  //   getCoreRowModel: getCoreRowModel(),
  //   state: {
  //     sorting,
  //   },
  //   onSortingChange: setSorting,
  //   getSortedRowModel: getSortedRowModel(),
  // });

  return (
    <>
      <div className="flex-1 overflow-auto">
        <div className="max-h-full max-w-full overflow-auto">
          <table
            style={{ borderCollapse: "separate", borderSpacing: 0 }}
            className="w-full"
          >
            <thead className="overflow-auto">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="border-b">
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      scope="col"
                      className={
                        "sticky top-0 z-10 h-11 whitespace-nowrap border-b border-r border-gray-200/70 bg-gray-50 px-6 text-left text-xs font-normal uppercase tracking-wide text-gray-400 first:left-0 first:z-20 first:pl-8 first:w-full"
                      }
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              <>
                {paginatedCompanies?.companies.length === 0 ? (
                  <tr>
                    <div className="sticky left-0 z-10 whitespace-nowrap p-4 pl-8 text-gray-400">
                      No companies
                    </div>
                    {/* <td className="inset-0 flex items-center justify-center p-4">
                    </td> */}
                  </tr>
                ) : (
                  <>
                    {table.getRowModel().rows.map((row) => (
                      <tr
                        key={row.id}
                        className="group relative rounded-lg hover:cursor-pointer hover:bg-gray-50"
                        onClick={() => {
                          navigate(`${row.original._id}`);
                        }}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            className={
                              "whitespace-nowrap border-r border-b border-gray-100 px-6 py-3 text-sm text-gray-500 first:sticky first:left-0 first:z-10 first:bg-white first:pr-6 first:font-medium first:text-gray-900 first:group-hover:bg-gray-50 first:sm:pl-8"
                            }
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </>
                )}
              </>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export const columns: ColumnDef<CompanyData>[] = [
  {
    id: "name",
    header: () => {
      return <div className="w-80">Name</div>;
    },
    cell: ({ row }) => {
      return <div className="h-full w-80 truncate">{row.original.name}</div>;
    },
    accessorFn: ({ name }) => name,
  },
  {
    id: "active-projects",
    header: () => {
      return <div className="w-40">Active Projects</div>;
    },
    cell: ({ row }) => {
      return (
        <div className="h-full w-40 truncate">
          {row.original.noOfProjects.inProgress}
        </div>
      );
    },
    accessorFn: ({ noOfProjects }) => {
      return noOfProjects.inProgress;
    },
  },
  {
    id: "project-last-created-at",
    header: () => {
      return <div className="w-60">Last Project Created On</div>;
    },
    cell: ({ row }) => {
      return (
        <div className="h-full w-60 truncate">
          {!!row.original.projectLatestCreatedAt
            ? dayjs(row.original.projectLatestCreatedAt).format("MMM DD, YYYY")
            : ""}
        </div>
      );
    },
    accessorFn: ({ projectLatestCreatedAt }) => {
      return projectLatestCreatedAt;
    },
  },
  // {
  //   id: "projectCount",
  //   header: () => {
  //     return <div className="w-72">Projects</div>;
  //   },
  //   cell: ({ row }) => {
  //     return (
  //       <div className="h-full w-72 truncate">{row.original.projectCount}</div>
  //     );
  //   },
  //   accessorFn: ({ projectCount }) => projectCount,
  // },
];

function SortToggle({ column }: { column: Column<CompanyData> }) {
  return (
    <button
      className="inline-flex rounded-md px-1 py-1 hover:bg-gray-300/20"
      onClick={() => {
        if (column.getIsSorted() === "asc") {
          column.toggleSorting(true);
        } else {
          column.toggleSorting(false);
        }
      }}
    >
      <ArrowUpIcon
        className={clsx(
          "h-3 w-3 stroke-2 text-gray-300",
          column.getIsSorted() === "asc" && "text-primary-main"
        )}
        // onClick={() => {
        //   column.toggleSorting(false);
        // }}
      />
      <ArrowDownIcon
        className={clsx(
          "h-3 w-3 stroke-2 text-gray-300",
          column.getIsSorted() === "desc" && "text-primary-main"
        )}
        onClick={() => {
          column.toggleSorting(true);
        }}
      />
      {/* <ChevronUpDownIcon className="h-4 w-4 text-gray-400" /> */}
    </button>
  );
}
